import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent implements OnInit, OnDestroy {
  @Output() onZoomFit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onZoomIn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onZoomOut: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public zoomRatio: number;

  private elem: any;
  public isFullScreen = false;
  private _currentLang: string;
  public translatedTexts: any;
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _translateService: TranslateService,
  ) {
    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this.translatedTexts = event.translations;
    });

    this._translateService
      .get(['zoombar'])
      .pipe(takeUntil(this._destroy$))
      .subscribe(texts => (this.translatedTexts = texts));
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onZoomFit.unsubscribe();
    this.onZoomIn.unsubscribe();
    this.onZoomOut.unsubscribe();
    this._destroy$.next();
  }

  handleZoomFit(e) {
    e.preventDefault();
    this.onZoomFit.emit();
  }

  handleZoomIn(e) {
    e.preventDefault();
    this.onZoomIn.emit();
  }

  handleZoomOut(e) {
    e.preventDefault();
    this.onZoomOut.emit();
  }

  changeFullscreen(e) {
    this.elem = document.documentElement;
    this.isFullScreen ? this.closeFullscreen() : this.openFullscreen();
    this.isFullScreen = !this.isFullScreen;
  }
  openFullscreen() {
    if (this.elem && this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem && this.elem.mozRequestFullScreen) {
      /* to Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem && this.elem.webkitRequestFullscreen) {
      /* to Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem && this.elem.msRequestFullscreen) {
      /* to IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.elem && this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.elem && this.document.mozCancelFullScreen) {
      /* to Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.elem && this.document.webkitExitFullscreen) {
      /* to Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.elem && this.document.msExitFullscreen) {
      /* to IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
