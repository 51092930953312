<div #element id="panel-duotone" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-image"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.image.title' | translate }}
      </h2>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.image.effects.types.filters' | translate }}
      </h2>
    </div>

    <div class="panel-scroller-close" (click)="close()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-full__container">
    <div class="panel-background">
      <div class="panel-space">
        <trakto-button
          button--fill
          button--white
          [style.marginBottom.px]="20"
          (onClick)="setFilter('empty')"
        >
          <i class="trakto-icon-empty"></i>
          {{ 'property_panel.image.effects.remove' | translate }}
        </trakto-button>

        <div class="panel-duotone__content">
          <div *ngFor="let filter of filters" (click)="setFilter(filter.value)">
            <div
              [ngClass]="{
                '--active':
                  pageSelected?.filter === filter.value ||
                  elementSelected?.filter === filter.value
              }"
            >
              <svg viewBox="0 0 160 160">
                <image
                  [traktoSvgFilter]="filter.value"
                  [id]="'filter-image-' + filter.value"
                  width="160"
                  height="160"
                  [attr.xlink:href]="
                    pageSelected?.backgroundImage ||
                    elementSelected?.href ||
                    isEmoji
                  "
                  preserveAspectRatio="xMidYMin slice"
                ></image>
              </svg>

              <small>{{ filter.label }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
