<figure #render class="trakto-media trakto-media-background">
  <span class="trakto-media__duration" *ngIf="item.type === 'video'">
    {{ item.duration }}
  </span>

  <figcaption
    class="trakto-media__label"
    *ngIf="item.type === 'image' && item.label && item.author"
  >
    <div class="trakto-media__label--blur"></div>

    <span>
      {{ item.label }}
      <span
        class="trakto-media__author"
        *ngIf="item.author"
        (click)="openExternalLink()"
      >
        {{ item.author }}
      </span>
    </span>
  </figcaption>

  <div class="trakto-media__button">
    <trakto-button button--fill button--primary (click)="click()">
      {{ translated.apply }}
    </trakto-button>
  </div>

  <div class="trakto-media__actions">
    <trakto-button
      *ngIf="item.provider && item.provider === 'internal'"
      button--fill
      button--inverse
      (click)="removeImageByMyGallery(item)"
    >
      <i class="trakto-icon-trash"></i>
    </trakto-button>
  </div>
</figure>

<div class="trakto-media__overlay"></div>
