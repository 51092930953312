import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './input-field.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '@app/editor/shared/material.module';



@NgModule({
  declarations: [
    InputFieldComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
  ],
  exports: [
    InputFieldComponent,
  ]
})
export class InputFieldModule { }
