import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectionEngine } from '@editor/components/preview-2/svg-editor/shared/selection.engine';
import { SelectionEvent } from '@editor/components/preview-2/svg-editor/shared/selection.model';
import { VideoModalComponent } from '@editor/components/video-modal/video-modal.component';
import {
  BLOCKED_ICON,
  BoundBoxModel,
  ElementModelGroupEngine,
  getCutMarginConfig,
  GuideLineConfigModel,
  makeCirclePath,
  makeRectPath,
  PointModel,
  ResizePointModel,
  ResizePointsConfigModel,
} from '@trakto/core-editor';

import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';

import { copyObject } from '@app/state/state.util';
import { Drawable } from '@editor/components/preview-2/svg-editor/shared/drawable.model';
import { DrawablesEngine } from '@editor/components/preview-2/svg-editor/shared/drawables.engine';
import { EditorConfigs } from '@editor/components/preview-2/svg-editor/shared/EditorConfigs';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { FontsService } from '@services/fonts.service';
import { ElementModelService, } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import { NewModelService } from '@services/new-model.service';
import { TextElementService } from '@services/text-element.service';
import { YoutubeService } from '@services/youtube/youtube.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { ThemeService } from '../../../services/theme.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '@app/auth/shared/auth.service';
import { environment } from '@env/environment';
import {
  IElementModel,
  IEventModel,
  IMouseSelection,
  ITextElementModel,
  IYoutubeElementModel,
  PageModel,
} from '@trakto/models';
import { PathSvgService } from '@services/path-svg.service';
import { ShapeElementService } from '@services/shape-element.service';
import { IElementModelChange } from '@editor/model/element-model-change.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';
import { selectHighlightElement } from '@app/state/document.selector';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';


const PAGE_KEY_CONTEXT = 'previewkeys';
const TOOLBAR_KEYS = 'toolbarkeys';
const PROPERTIES_PANEL_KEYS = 'propertiesPanelKeys';

// Para ignorar o clique, evitando assim a perda do foco
const IGNORE_CLASS_CHECK = [
  'trakto-modal__wrapper',
  'page-actions',
  'trakto-header',
  'cdk-overlay-backdrop',
  'text-overlay',
  'template-tagger__page__content',
  'modal-recurrence',
  'click-ignore',
];
const IGNORE_TAG_CHECK = [
  'trakto-toolbar',
  'trakto-modal',
  'trakto-item-renderer',
  'trakto-properties-panel',
  'tkt-modal-background-removal',
  'mat-option',
  'tkt-modal-base',
  'tkt-modal-recurrence',
  'trakto-text-menu-inline',
];

interface ISvgEditorChange {
  selection: IElementModel;
  changes: IElementModelChange[];
  elements: IElementModel[];
}

@Component({
  selector: 'trakto-svg-editor',
  templateUrl: './svg-editor.component.html',
  styleUrls: ['./svg-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgEditorComponent implements OnInit, OnChanges, OnDestroy {
  blockedPathIcon: string;
  blockedPathBBox: { x; y; width; height };

  // Tamanho dos pontos de suporte
  supportBorderSize = 1;
  supportFontSize = 12;

  @Input()
  isActivated: boolean;

  @Input()
  isPreviewDoc = false;

  @Input()
  printableMode = false;

  @Input()
  page: PageModel;

  @Input()
  ref: any;

  private _elements: IElementModel[];
  private _currentLang: string;
  public translatedTexts: any;

  @Input()
  public set elements(value: IElementModel[]) {
    this._elements = value;
  }

  public get elements(): IElementModel[] {
    return this._elements;
  }

  @Input()
  zoomRatio = -1;

  @Input()
  hasWaterMask = true;

  @Output()
  onPageClicked: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onPageAdditionRequest: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onPageCloneRequest: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onPageDeletionRequest: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onSelectedElement: EventEmitter<IElementModel> = new EventEmitter();

  @Output()
  onFocusedElement: EventEmitter<any> = new EventEmitter();

  @Output()
  onElementDeletionRequest: EventEmitter<IElementModel> = new EventEmitter();

  @Output()
  changed: EventEmitter<ISvgEditorChange> = new EventEmitter();

  @Output()
  historyUndo: EventEmitter<IEventModel> = new EventEmitter();

  @Output()
  historyRedo: EventEmitter<IEventModel> = new EventEmitter();

  @Output()
  onInitClipEdit: EventEmitter<IEventModel> = new EventEmitter<IEventModel>();

  @Output()
  onFinishClipEdit: EventEmitter<IEventModel> = new EventEmitter<IEventModel>();

  visibleElements: Drawable[];

  /**
   * Elementos selecionandos
   */
  selection: ElementModelGroupEngine;

  dragOffsetPoint: PointModel = undefined;

  selectedResizePoint: ResizePointModel = undefined;

  moving = false;

  pageFocused = false;

  drawableConfigs = new EditorConfigs('#11B5FF', '#8492A6');

  cutMarginConfig: { path; lines; margin };

  @ViewChild('chart')
  chart: ElementRef;

  @ViewChild('textBackground')
  textBackground: ElementRef;

  selectedTextElement: ITextElementModel;

  selectedTextBySingleClick: ITextElementModel;

  /**
   * Informações da seleção por mouse
   */
  mouseSelection: IMouseSelection = undefined;

  mousePosition: { x; y; supportPoint; transform; href; angule };

  private youtubeService: YoutubeService;

  private mouseMoveFunc: any;

  public handlerBlurInSafari: boolean;
  public urlFilterReference: string;

  private lastFocusedElement: IElementModel;

  /**
   * Elementos a serem removidos no localMouseudown
   */
  private toRemove: Drawable[] = [];

  public selectionEngine: SelectionEngine;
  public drawablesEngine: DrawablesEngine;
;
  private cropSubscription: Subscription;
  private textEditionSubscription: Subscription;
  private highlightedElement$: Observable<Drawable> = this._store.select(selectHighlightElement);
  private highlightedElement: Drawable;

  public isB2C = false;
  public isMobile = false;

  private _platformId: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private element: ElementRef,
    private hotkeyService: HotkeyService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private newModelService: NewModelService,
    private newShapeService: ShapeElementService,
    private pathSvgService: PathSvgService,
    private newImageElementService: ImageElementService,
    private newFontService: TextElementService,
    private newElementService: ElementModelService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private signalsService: SignalsService,
    private translateService: TranslateService,
    private _userService: UserService,
    private _deviceDetector: DeviceDetectorService,
    private _store: Store,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this._platformId = platformId;
    if (isPlatformBrowser(platformId)) {
      this._currentLang = translateService.currentLang;
      this.blockedPathIcon = this.pathSvgService.toCenter(
        this.pathSvgService.normalizePath(BLOCKED_ICON),
      );
      this.blockedPathBBox = this.pathSvgService.getBBox(this.blockedPathIcon);
      this.translateService
        .get('general')
        .subscribe(texts => (this.translatedTexts = texts));
      this.translateService.onLangChange.subscribe((e: LangChangeEvent) => {
        this._currentLang = e.lang;
        this.translatedTexts = e.translations['general'];
      });
    }
    this.isMobile = this._deviceDetector.isMobile();
  }

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.mousePosition = {
        x: -1000,
        y: -1000,
        supportPoint: undefined,
        transform: undefined,
        href: undefined,
        angule: undefined,
      };
      this.pageFocused = this.isActivated;
      this.youtubeService = new YoutubeService();
      this.themeService
        .getObservableTheme()
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: value => {
            if (value) {
              this.drawableConfigs.themeBorderColor = value.color.secondary;
              this.cdr.detectChanges();
            }
          },
        });
      this.mouseMoveFunc = this.mouseMove.bind(this);

      if (!this.isPreviewDoc && document.querySelector('.text-overlay')) {
        document
          .querySelector('.text-overlay')
          .addEventListener('click', () => {
            this.finishTextEdition();
          });
      }
      this.handleBlurSafariFilterCompatibility();
      this.isB2C = this._userService.isB2c;

      this.cropSubscription = this.signalsService.connect(enumSignals.ON_CLIP_MODE, () => {
        this.showMore();
        this.cdr.detectChanges();
      });
      this.textEditionSubscription = this.signalsService.connect(enumSignals.ON_TEXT_EDITION_MODE, () => {
        this.showMore();
        this.cdr.detectChanges();
      });
    }

    this.highlightedElement$.pipe(takeUntil(this._destroy$)).subscribe(element => {
      this.highlightedElement = (element as Drawable);
      this.isElementHighlighted()
    });
  }

  isElementHighlighted( ) {
    this.visibleElements?.some(el => {
      if (el.element.id === this.highlightedElement?.element.id) {
        el.hover = this.highlightedElement?.hover;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this._platformId)) {
      if (!this.drawablesEngine || !this.selectionEngine) {
        this.initEngines();
      }
      this.toRemove = [];
      this.configDimension();
      this.initKeyboard();
      if (this.selectionEngine) {
        this.selectionEngine.updateElements(this.elements);
      }
      if (this.drawablesEngine) {
        this.drawablesEngine.updateElements(this.elements, this.isPreviewDoc);
      }
      if (
        this.selectionEngine &&
        ((changes.isActivated && !this.isActivated) ||
          this.isPreviewDoc === true ||
          this.isPageChange(changes))
      ) {
        this.selectionEngine.changePage(this.page);
      }

      if (
        changes.isActivated &&
        !changes.isActivated.previousValue &&
        this.isActivated
      ) {
        this.pageFocused = true;
      }
    }

    this.isElementHighlighted();
  }

  ngOnDestroy() {
    this._destroy$.next();
    if (isPlatformBrowser(this._platformId)) {
      this.signalsService.disconnect(enumSignals.ON_CLIP_MODE, this.cropSubscription);
      this.signalsService.disconnect(enumSignals.ON_TEXT_EDITION_MODE, this.textEditionSubscription);
      window.document.removeEventListener('mousemove', this.mouseMoveFunc);
      window.document.removeEventListener('touchmove', this.mouseMoveFunc);
    }
  }

  public handleBlurSafariFilterCompatibility(): void {
    if (!this.hotkeyService.isSafari()) {
      this.handlerBlurInSafari = false;
      return;
    }

    this.handlerBlurInSafari = true;
    this.urlFilterReference = `${window.location.pathname.replace('/', '')}`;
  }

  configDimension() {
    if (this.page) {
      this.cutMarginConfig = getCutMarginConfig(this.page, this.printableMode);
      this.supportBorderSize = 1 / this.zoomRatio;
      this.supportFontSize = 12 / this.zoomRatio;

      if (this.selection) {
        this.selection.resizeEngine.createResizePoints(
          this.createResizePointsConfig(),
        );
      }
    }
  }

  configKeyboard() {
    this.hotkeyService.addContext(PAGE_KEY_CONTEXT, ($event: KeyboardEvent) => {
      if (
        $event.which === KeyEnum.backspace ||
        $event.which === KeyEnum.delete
      ) {
        if (!this.selection.isEmpty()) {
          this.onElementDeletionRequest.emit(this.selection.element);
        }
      }

      if (
        this.hotkeyService.SHIFT_KEY($event) &&
        $event.which === KeyEnum.n
      ) {
        this.onPageAdditionRequest.emit(true);
        this.pageFocused = false;
      }

      if (
        this.hotkeyService.CMD_KEY($event) &&
        $event.which === KeyEnum.d &&
        this.selection.isEmpty()
      ) {
        this.onPageCloneRequest.emit(true);
        this.pageFocused = false;
      }

      if (this.hotkeyService.CMD_KEY($event)) {
        if ($event.which === KeyEnum.a) {
          this.selectionEngine.resetSelection(this.elements);
          this.cdr.detectChanges();
        } else if ($event.which === KeyEnum.z) {
          this.hotkeyService.stopPropagation($event);
          this.historyUndo.emit({
            event: 'HISTORY-UNDO',
            data: this.page.id,
            bubbles: false,
          });
        } else if ($event.which === KeyEnum.y) {
          this.hotkeyService.stopPropagation($event);
          this.historyRedo.emit({
            event: 'HISTORY-REDO',
            data: this.page.id,
            bubbles: false,
          });
        }
      }

      if (!this.selection.isEmpty()) {
        this.handleElementsByKeyboard($event);
      }
    });
  }

  selectElementByClick($event, drawableElement: Drawable) {
    if (
      !this.hotkeyService.isGroupSelection($event) &&
      !this.selection.isEmpty()
    ) {
      this.selectionEngine.resetSelection();
    }
    this.lastFocusedElement = drawableElement.element;
    this.selectionEngine.addElement(
      drawableElement.element,
      drawableElement.group,
    );
    this.initSelectionMove($event);
  }

  handleTap($event, drawable?: Drawable) {
    this.initSelectionMove($event, drawable);
  }

  initSelectionMove($event, drawable?: Drawable) {
    this.checkDragCursorCompatibility();
    if (this.hotkeyService.isGroupSelection($event) && drawable) {
      this.toRemove.push(drawable);
      return;
    }

    const point = this.convertPoint($event);
    this.dragOffsetPoint = new PointModel(
      point.x - this.selection.cx(),
      point.y - this.selection.cy(),
    );
    this.moving = true;
  }

  updateMousePosition($event) {
    const point = this.convertPoint($event);

    this.mousePosition = Object.assign(this.mousePosition, point);

    if (this.mousePosition.supportPoint) {
      const isRotation = this.mousePosition.supportPoint.type === 'rotation';

      const rotation = isRotation
        ? this.selection.element.rotation
        : this.mousePosition.supportPoint.shape.rotation;

      /**
       * Armazenando o valor truncado do elemento rotacionado
       * @const {angle}
       */
      const angle = this.selection.element.rotation;

      /**
       * Setando a saída do angulo para a propriedade de mousePosition
       */
      this.mousePosition.angule = `${angle} º`;

      this.mousePosition.transform =
        `translate( ${this.mousePosition.x}, ${this.mousePosition.y}) ` +
        `rotate(${rotation}) ` +
        `scale(${1 / this.zoomRatio})`;
      this.mousePosition.href = isRotation ? '' : '';
    }
  }

  @HostListener('window:mouseup', ['$event'])
  @HostListener('window:touchend', ['$event'])
  checkFinishEdition(mouseUp = true) {
    window.document.removeEventListener('mousemove', this.mouseMoveFunc);
    window.document.removeEventListener('touchmove', this.mouseMoveFunc);
    if (this.moving || this.selectedResizePoint) {
      const selection = this.selection;
      Promise.resolve().then(() => {
        const elementsCopy = copyObject(this.page.elements);
        if (this.drawablesEngine.isClipMode()) {
          this.newElementService.updateGroupsShape(this.page);
        }
        const selectionChanges =
          this.newElementService.getChangesBySelection(selection);
        const groupChanges = this.newElementService.getChangesByElements(
          elementsCopy,
          this.page.elements,
        );
        this.selection.updateElementsReference();
        if (selectionChanges.length > 0) {
          this.cdr.detectChanges();
          const selection = this.selectionEngine.getSelectionElement();
          const event: ISvgEditorChange = {
            selection,
            changes: selectionChanges.concat(groupChanges),
            elements: this._getChangedRawElements(),
          } as ISvgEditorChange;
          this.changed.emit(event);
          this.signalsService.emit(enumSignals.SELECTION_CHANGE, event);
        }
      });
      selection.commitChanges();
      this.selection.resizeEngine.createResizePoints(
        this.createResizePointsConfig(),
      );
      this.selection.guideLinesEngine.createGuideLines(
        this.createGuideLineConfig(),
      );
      this.moving = false;
      this.selectedResizePoint = undefined;
      if (this.mousePosition) {
        this.mousePosition.supportPoint = undefined;
      }
    }

    if (mouseUp && this.mouseSelection) {
      this.selectElementsByMouseSelection();
    }
    this.mouseSelection = undefined;

    if (this.visibleElements) {
      this.visibleElements.forEach(ve => (ve.hover = false));
    }

    this.checkDragCursorCompatibility(false);
  }

  private _getChangedRawElements() {
    const clipDrawable = this.drawablesEngine.clipEditionInfo?.source?.drawable;
    return !this.drawablesEngine.isClipMode() ? this.selectionEngine.selection.getRawElementModels() : [ clipDrawable.group || {
      ...this.drawablesEngine.clipEditionInfo.metaImage.element,
      clipped: true
    } ];
  }

  private initKeyboard() {
    if (this.isActivated && !this.selectedTextElement) {
      this.configKeyboard();
    }
  }

  requestTextEdition($event: Event) {
    if (!this.selectedTextElement) {
      this.signalsService.emit(enumSignals.ON_TEXT_EDITION_MODE, {});
    } else {
      this.finishTextEdition();
    }
    $event.preventDefault();
  }

  showMore() {
    if (
      !this.lastFocusedElement ||
      !this.selection.hasElement(this.lastFocusedElement)
    ) {
      return;
    }

    const isYoutubeElement = this.newElementService.isVideo(
      this.lastFocusedElement,
    );
    const isTextElement = this.newElementService.isText(
      this.lastFocusedElement,
    );
    const isClipElement = this.newElementService.isClip(
      this.lastFocusedElement,
      true,
    );

    if (
      (!isYoutubeElement && !isTextElement && !isClipElement) ||
      !this.lastFocusedElement.edit ||
      this.lastFocusedElement.lockedByTemplate ||
      this.selection.getRawElementModels().length > 1
    ) {
      return;
    }

    if (isYoutubeElement) {
      this.openOnPreviewDoc(this.lastFocusedElement as IYoutubeElementModel);
    } else if (isTextElement) {
      this.selectedTextElement = this.lastFocusedElement as ITextElementModel;
      this.hotkeyService.disableContext(TOOLBAR_KEYS);
      this.hotkeyService.disableContext(PAGE_KEY_CONTEXT);
      this.hotkeyService.disableContext(PROPERTIES_PANEL_KEYS);
    } else if (isClipElement) {
      this.drawablesEngine.startClipEdition(this.lastFocusedElement);
    }
  }

  openOnPreviewDoc(element: IElementModel) {
    if (!this.isPreviewDoc) {
      return;
    }

    return this.dialog.open(VideoModalComponent, {
      width: '640px',
      height: '400px',
      data: { url: this.youtubeService.getYoutubeUrl(element as IYoutubeElementModel) },
    } as MatDialogConfig);
  }

  selectResizePointByClick(supportPoint: ResizePointModel) {
    this.checkDragCursorCompatibility();
    this.selectedResizePoint = supportPoint;
    this.mousePosition.supportPoint = supportPoint;
    this.finishTextEdition();
  }

  private updateSelection($event: any) {
    const mousePoint = this.convertPoint($event);
    if (this.moving && this.dragOffsetPoint) {
      const newCenter = {
        cx: mousePoint.x - this.dragOffsetPoint.x,
        cy: mousePoint.y - this.dragOffsetPoint.y,
      };
      this.selection.move(newCenter, !this._deviceDetector.isMobile());
    } else if (this.selectedResizePoint) {
      this.selection.handleResizePoint(
        mousePoint,
        this.selectedResizePoint,
        !this.hotkeyService.SHIFT_KEY($event as KeyboardEvent),
      );
    }
  }

  localMousedown($event: MouseEvent) {
    if (this.isPreviewDoc) {
      return;
    }

    this.updateMousePosition($event);
    this.checkPageFocus($event);

    // Se o evento do mouse ocorre sobre algum elemento selecionado do canvas
    const inSelection = this.inSelection($event);

    if (!inSelection && !this.selectedResizePoint) {
      this.mouseSelection = {
        initPoint: this.convertPoint($event),
        endPoint: this.convertPoint($event),
        basePoint: this.convertPoint($event),
        width: 0,
        height: 0,
      };
    }

    if (this.isActivated && !this.selectedResizePoint && !inSelection) {
      this.finishTextEdition();
      this.drawablesEngine.finishClipEdition();
      this.selectionEngine.resetSelection();
    }

    this.zone.runOutsideAngular(() => {
      window.document.addEventListener('mousemove', this.mouseMoveFunc);
      window.addEventListener('touchmove', this.mouseMoveFunc, false);
    });

    if (this.toRemove.length > 0) {
      this.toRemove.forEach(tr =>
        this.selectionEngine.removeElement(tr.element, tr.group),
      );
      this.toRemove = [];
    }
  }

  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  mousedown($event: MouseEvent) {
    this.checkPageFocus($event);
    const inContainer = this.inContainer($event);
    const ignoreClick = this.isIgnoredClick($event);

    // Evita perder foco em elementos de edição
    if (!inContainer && !ignoreClick) {

      if (this.isActivated) {
        this.finishTextEdition();
        this.drawablesEngine.finishClipEdition();
        this.notifySelectionChange(undefined);
        this.selectionEngine.resetSelection();
      }
    } else if (inContainer && !this.inSelection($event)) {
      this.onPageClicked.emit(this.isActivated);
    }

    const el = $event.target as HTMLElement;

    const hasPageBackground =
      el.classList.contains('trakto-icon-background') ||
      el.classList.contains('trakto-page-background');

    if (ignoreClick && hasPageBackground && this.isActivated) {
      this.selectionEngine.resetSelection();
    }
  }

  public getStrokeDasharrayLocked(): string {
    if (this.selection) {
      if (this.selection.element.lockedByTemplate === true) {
        return '8, 4';
      } else if (this.selection.hasSingleGroupElement()) {
        return '8, 4';
      }
    }
    return '0, 0';
  }

  private emitTextChange() {
    const oldElements = this.newElementService.getAllElements(
      [copyObject(this.elements)],
      true,
    );
    this.newElementService.updateGroupsShape(this.page);
    const elements = this.newElementService.getAllElements(this.elements, true);
    const changes = this.newElementService.getChangesByElements(
      oldElements,
      elements,
    );

    const $event: ISvgEditorChange = {
      elements: this._getChangedRawElements(),
      selection: this.selectedTextElement,
      changes: [
        ...changes,
        {
          elementId: this.selectedTextElement.id,
          oldValue: null,
          newValue: this.selectedTextElement.textEncoded2,
          property: 'textEncoded2',
          after: this.selectedTextElement,
        },
        {
          elementId: this.selectedTextElement.id,
          oldValue: null,
          newValue: this.selectedTextElement.textQuillEncoded,
          property: 'textQuillEncoded',
          after: this.selectedTextElement,
        },
        {
          elementId: this.selectedTextElement.id,
          oldValue: null,
          newValue: this.selectedTextElement.text,
          property: 'text',
          after: this.selectedTextElement,
        },
      ],
    } as ISvgEditorChange;
    this.changed.emit($event);
    this.signalsService.emit(enumSignals.SELECTION_CHANGE, $event);
  }

  /**
   * When user finish text edtions with no content, 'preventEmptyText' will set
   * a default text to prevent user lost the text element on the ui
   */
  private preventEmptyText() {
    this.selectedTextElement.textQuillEncoded = this.newFontService.createQuillText(
      this.selectedTextElement,
      this.translatedTexts.placeholder_text_component,
    );
    this.newFontService.fitTextDimension(this.selectedTextElement);
    this.emitTextChange();
  }

  private finishTextEdition() {
    if (this.selectedTextElement) {
      this.hotkeyService.enableContext(PAGE_KEY_CONTEXT);
      this.hotkeyService.enableContext(TOOLBAR_KEYS);
      this.hotkeyService.enableContext(PROPERTIES_PANEL_KEYS);

      if (this.selectedTextElement.text.trim().length === 0) {
        this.preventEmptyText();
      } else {
        this.emitTextChange();
      }
      this.signalsService.emit(enumSignals.ON_EXIT_TEXT_EDITION_MODE, {});
    }
    this.selectedTextElement = undefined;
  }

  /**
   * Capta todos eventos de movimento do canvas
   * Usando método para reduzir o número de cálculos
   */
  mouseMove($event: any) {
    if (!this.isActivated) {
      return;
    }

    this.updateMousePosition($event);

    if (this.moving || this.selectedResizePoint) {
      this.updateSelection($event);
      this.selection.commitChanges();
      this.selection.guideLinesEngine.refresh();
      this.cdr.detectChanges();
    }

    this.handleMouseSelection($event);
  }

  // Evita que o cursor se torne do tipo 'text' durante o drag dos elementos no safari.
  // https://stackoverflow.com/questions/47295211/safari-wrong-cursor-when-dragging
  private checkDragCursorCompatibility(start = true) {
    if (this.hotkeyService.isSafari()) {
      if (start) {
        document['onselectstart'] = () => false;
      } else {
        document['onselectstart'] = () => true;
      }
    }
  }

  private handleElementsByKeyboard($event: KeyboardEvent) {
    if (this.hotkeyService.SHIFT_KEY($event)) {
      const delta = 10;
      if ($event.which === KeyEnum.up) {
        this.moveSelection('up', delta);
      }
      if ($event.which === KeyEnum.down) {
        this.moveSelection('down', delta);
      }
      if ($event.which === KeyEnum.left) {
        this.moveSelection('left', delta);
      }
      if ($event.which === KeyEnum.right) {
        this.moveSelection('right', delta);
      }
    }

    if (this.hotkeyService.KEY($event)) {
      const delta = 1;
      if ($event.which === KeyEnum.up) {
        this.hotkeyService.stopPropagation($event);
        this.moveSelection('up', delta);
      }
      if ($event.which === KeyEnum.down) {
        this.hotkeyService.stopPropagation($event);
        this.moveSelection('down', delta);
      }
      if ($event.which === KeyEnum.left) {
        this.hotkeyService.stopPropagation($event);
        this.moveSelection('left', delta);
      }
      if ($event.which === KeyEnum.right) {
        this.hotkeyService.stopPropagation($event);
        this.moveSelection('right', delta);
      }
    }
  }

  private moveSelection(direction: string, delta: number) {
    this.selection.moveByDirection(direction, delta);
    this.selection.commitChanges();
    this.selection.guideLinesEngine.createGuideLines(
      this.createGuideLineConfig(),
    );
    this.selection.resizeEngine.createResizePoints(
      this.createResizePointsConfig(),
    );
    this.cdr.detectChanges();
  }

  private handleMouseSelection($event) {
    if (!this.mouseSelection) {
      return;
    }
    this.mouseSelection.endPoint = this.convertPoint($event);
    this.mouseSelection.width = Math.abs(
      this.mouseSelection.endPoint.x - this.mouseSelection.initPoint.x,
    );
    this.mouseSelection.height = Math.abs(
      this.mouseSelection.endPoint.y - this.mouseSelection.initPoint.y,
    );
    if (this.mouseSelection.initPoint.x > this.mouseSelection.endPoint.x) {
      this.mouseSelection.basePoint.x = this.mouseSelection.endPoint.x;
    } else {
      this.mouseSelection.basePoint.x = this.mouseSelection.initPoint.x;
    }

    if (this.mouseSelection.initPoint.y > this.mouseSelection.endPoint.y) {
      this.mouseSelection.basePoint.y = this.mouseSelection.endPoint.y;
    } else {
      this.mouseSelection.basePoint.y = this.mouseSelection.initPoint.y;
    }
    this.cdr.detectChanges();
  }

  private selectElementsByMouseSelection() {
    if (!this.visibleElements || !this.mouseSelection) {
      return;
    }
    const boundBox = BoundBoxModel.make(
      this.mouseSelection.basePoint.x,
      this.mouseSelection.basePoint.y,
      this.mouseSelection.width,
      this.mouseSelection.height,
    );
    this.visibleElements
      .filter(ve => boundBox.in(new PointModel(ve.element.cx, ve.element.cy)))
      .forEach(se => this.selectionEngine.addElement(se.element, se.group));
  }

  private checkPageFocus($event?) {
    this.pageFocused =
      $event &&
      ((this.inContainer($event) &&
        !this.inSelection($event) &&
        !this.selectedResizePoint) ||
        (this.pageFocused && this.isIgnoredClick($event)));
  }

  private inSelection($event, selection?: ElementModelGroupEngine): boolean {
    if (!$event) {
      return false;
    }
    if (!selection) {
      selection = this.selection;
    }
    return selection.in(
      this.convertPoint($event),
      this.drawablesEngine.isClipMode(),
    );
  }

  private convertPoint($event: MouseEvent) {
    return {
      x: this.getMousePositionX($event),
      y: this.getMousePositionY($event),
    };
  }

  private getMousePositionX($event: any): number {
    return (
      (($event.clientX || $event.touches[0]?.clientX) - this.element.nativeElement.getBoundingClientRect().x) /
      this.zoomRatio
    );
  }

  private getMousePositionY($event: any): number {
    return (
      (($event.clientY || $event.touches[0]?.clientY) - this.element.nativeElement.getBoundingClientRect().y) /
      this.zoomRatio
    );
  }

  private inContainer($event): boolean {
    return (
      $event &&
      (this.getClickEventPath($event).indexOf(this.chart.nativeElement) >= 0 ||
      this.getClickEventPath($event).indexOf(this.textBackground.nativeElement) >= 0)
    );
  }

  private isIgnoredClick($event): boolean {
    if (!$event) {
      return false;
    }
    const eventPath = this.getClickEventPath($event);
    return !eventPath.every(element => {
      let ignoreClick = false;
      if (typeof element.className === 'string') {
        ignoreClick =
          IGNORE_CLASS_CHECK.filter(
            ic => element.className.toLowerCase().indexOf(ic) >= 0,
          ).length > 0;
      }
      if (!ignoreClick && typeof element.tagName === 'string') {
        ignoreClick =
          IGNORE_TAG_CHECK.indexOf(element.tagName.toLowerCase()) >= 0;
      }
      return !ignoreClick;
    });
  }

  /**
   * Método utilitário para recuperar o caminho de um evento de clique
   * TODO colocar em uma classe utilitária
   */
  private getClickEventPath($event) {
    if (!$event['path'] && !$event.composedPath) {
      const path = [];
      let currentElem = $event.target;
      while (currentElem) {
        path.push(currentElem);
        currentElem = currentElem.parentElement;
      }
      if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
        path.push(document);
      }
      if (path.indexOf(window) === -1) {
        path.push(window);
      }
      return path;
    } else {
      return $event['path'] || $event.composedPath();
    }
  }

  private createGuideLineConfig(sourceSelection?: ElementModelGroupEngine) {
    return new GuideLineConfigModel(1, 5, 2, this.zoomRatio, this.page);
  }

  private createResizePointsConfig(sourceSelection?: ElementModelGroupEngine) {
    const selection = sourceSelection || this.selection;
    const strokeWidth = 1;
    const strokeColor =
      selection.element.edit && !selection.element.lockedByTemplate
        ? this.drawableConfigs.themeBorderColor
        : this.drawableConfigs.lockedEditionColor;
    const rotationPointDistance = 30;
    const verticalPointPath = makeRectPath(24, 8);
    const horizontalPointPath = makeRectPath(8, 24);
    const diagonalPointPath = makeCirclePath(7);
    const rotationPointPath = this.pathSvgService.makeRotationIconPath(0, 0);

    return new ResizePointsConfigModel(
      this.zoomRatio,
      strokeWidth,
      strokeColor,
      rotationPointDistance,
      verticalPointPath,
      horizontalPointPath,
      diagonalPointPath,
      rotationPointPath,
    );
  }

  // selection.element.edit && !selection.element.lockedByTemplate ? drawableConfigs.themeBorderColor : drawableConfigs.lockedEditionColor
  public getStrokeColorLocked(): string {
    if (
      this.selection &&
      (this.selection.element.lockedByTemplate === true ||
        this.selection.element.edit !== true)
    ) {
      return this.drawableConfigs.lockedEditionColor;
    }
    return this.drawableConfigs.themeBorderColor;
  }

  private initEngines() {
    if (this.selectionEngine || this.drawablesEngine) {
      return;
    }
    this.selectionEngine = new SelectionEngine(
      this.newModelService,
      this.newShapeService,
      this.newFontService,
      this.newElementService,
    );
    this.drawablesEngine = new DrawablesEngine(
      this.newElementService,
      this.newShapeService,
      this.newImageElementService,
    );

    this.selectionEngine.init(
      this.page,
      this.createResizePointsConfig.bind(this),
      this.createGuideLineConfig.bind(this),
    );
    this.drawablesEngine.init(this.drawableConfigs);

    this.selectionEngine.onChangeSelection.subscribe(
      (selectionEvent: SelectionEvent) => {
        if (selectionEvent.isElementsChanged()) {
          this.lastFocusedElement = undefined;
        }
        this.selection = selectionEvent.selection;
        this.changedSelection(this.selectionEngine.getSelectionElement());
        if (!selectionEvent.isReferenceChanged()) {
          this.notifySelectionChange(
            this.selectionEngine.getSelectionElement(),
          );
        }
      },
    );

    this.drawablesEngine.onChangeDrawableElements.pipe(takeUntil(this._destroy$)).subscribe(
      (drawables: Drawable[]) => {
        this.visibleElements = drawables;
        this.drawablesEngine.updateSelectedDrawableElements(
          this.lastFocusedElement,
          this.selection,
        );
      },
    );
    this.drawablesEngine.onStartClipEdition.pipe(takeUntil(this._destroy$)).subscribe(clipInfo => {
      this.selectionEngine.resetSelection();
      this.selectionEngine.addElement(clipInfo.metaShape.element);
      this.signalsService.emit(enumSignals.ON_CLIP_EDITABLE, true);
      this.onInitClipEdit.emit({
        event: 'initClipEdit',
        data: clipInfo,
        bubbles: false,
      });
      this.cdr.detectChanges();
    });
    this.drawablesEngine.onFinishClipEdition.pipe(takeUntil(this._destroy$)).subscribe(clipInfo => {
      this.signalsService.emit(enumSignals.ON_CLIP_EDITABLE, false);
      this.onFinishClipEdit.emit({
        event: 'finishClipEdit',
        data: clipInfo,
        bubbles: false,
      });
    });

    this.selectionEngine.resetSelection();
  }

  private changedSelection(element: IElementModel) {
    this.pageFocused = this.isActivated && !element && !this.lastFocusedElement;
    this.drawablesEngine.updateSelectedDrawableElements(
      this.lastFocusedElement,
      this.selection,
    );
    if (element && !this.drawablesEngine.isClipPart(element)) {
      this.drawablesEngine.finishClipEdition();
    }

    if (!element) {
      this.lastFocusedElement = undefined;
      this.dragOffsetPoint = undefined;
    }

    if (element && !this.lastFocusedElement) {
      this.lastFocusedElement = this.newElementService.getAllElements(
        [element],
        false,
        false,
      )[0];
    }

    if (this.selection && this.selection.hasSingleTextElement()) {
      this.selectedTextBySingleClick = this
        .lastFocusedElement as ITextElementModel;
    } else {
      this.selectedTextBySingleClick = undefined;
    }
  }

  private notifySelectionChange(element: IElementModel) {
    if (element && this.selectedTextElement === element) {
      return;
    }

    this.onSelectedElement.emit(element);
    this.onFocusedElement.emit(this.lastFocusedElement);
  }

  private isPageChange(changes: SimpleChanges) {
    // FIXME - Use variable to calc and return the variable;
    return (
      changes.page &&
      ((!changes.page.previousValue && changes.page.currentValue) ||
        (changes.page.previousValue && !changes.page.currentValue) ||
        changes.page.previousValue.id !== changes.page.currentValue.id)
    );
  }
}
