import { DOCUMENT } from '@angular/common';
import { ElementRef, Injectable, Inject } from '@angular/core';
import {
  fitTypeEnum,
  IDocumentModel,
  IEventModel,
  PageModel,
} from '@trakto/models';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoomService {
  pageZoom = -1;
  zoomRatio = 1;
  zoomMax = 300;
  zoomSnap = 3;
  minZoomWidth = 50;
  currentZoomSize: number = 1000;

  onPageZoomChange: Subject<number>;
  onZoomRatioChange: Subject<number>;
  onCurrentZoomSizeChange: Subject<number>;

  paddingWidth = 32;
  paddingHeight = 32;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this.onPageZoomChange = new ReplaySubject<number>(1);
    this.onZoomRatioChange = new ReplaySubject<number>(1);
    this.onCurrentZoomSizeChange = new ReplaySubject<number>(1);
  }

  public fitWidthZoom(page: PageModel, document?: IDocumentModel) {
    const event = {
      event: 'ZoomChanged',
      data: fitTypeEnum.f,
      bubbles: false,
    };
    this.changeZoom(event, page, document);
  }

  public handleZoomOut(page: PageModel, document: IDocumentModel) {
    let currentZoom = this.zoomRatio;
    const zoomDown = currentZoom - this.zoomSnap / 100;
    const zoomMin = this.minZoomWidth / page.width;
    currentZoom = zoomDown <= zoomMin ? zoomMin : zoomDown;

    const event = {
      event: 'ZoomChanged',
      data: currentZoom,
      bubbles: false,
    };

    this.changeZoom(event, page, document);
  }

  public handleZoomIn(page: PageModel, document: IDocumentModel) {
    let currentZoom = this.zoomRatio;
    const zoomUp = (currentZoom + this.zoomSnap / 100) * 100;

    currentZoom = (zoomUp >= this.zoomMax ? this.zoomMax : zoomUp) / 100;

    const event = {
      event: 'ZoomChanged',
      data: currentZoom,
      bubbles: false,
    };

    this.changeZoom(event, page, document);
  }

  handleZoomFit(page?: PageModel, document?: IDocumentModel) {
    const event: IEventModel = {
      event: 'ZoomChanged',
      data: fitTypeEnum.f,
      bubbles: true,
    };

    this.changeZoom(event, page, document);
  }

  changeZoom(
    event: IEventModel,
    page?: PageModel,
    document?: IDocumentModel,
  ): any {
    const zoom: number = event.data;
    this.setPageZoom(zoom);
    this.initZoomRatio(page, document);
  }

  initZoomRatio(pageSelected?: PageModel, document?: IDocumentModel): any {
    if (document?.body?.length === 0) {
      this.setZoomRatio(1);
      return;
    }

    const page: PageModel = pageSelected || document?.body[0];

    switch (this.pageZoom) {
      case fitTypeEnum.f:
        this.fitZoom(page, document);
        break;
      case fitTypeEnum.w:
        this.setZoomRatio(this.getZoomRatioByWidth(page));
        break;
      case fitTypeEnum.h:
        this.setZoomRatio(this.getZoomRatioByHeight(page));
        break;
      default:
        if (this.pageZoom > 0) {
          this.setZoomRatio(this.pageZoom);
        }
        break;
    }

    if (this.pageZoom !== fitTypeEnum.f) {
      this.setCurrentZoomSize(Math.floor(this.zoomRatio * 100));
    }
  }

  fitZoom(page: PageModel, document?: IDocumentModel): void {
    if (!page) {
      return;
    }

    let event: IEventModel;
    event = this.makeZoomChangeEvent(fitTypeEnum.h, false);

    Promise.resolve().then(() => {
      const { offsetWidth, offsetHeight } = this.getPagesContainerSize();

      const area = {
        width: Math.floor(offsetWidth) - this.paddingWidth * 2,
        height: Math.floor(offsetHeight) - this.paddingHeight * 2,
      };

      const ratio = {
        area: area.width / area.height,
        page: page.width / page.height,
      };

      if (ratio.area < ratio.page) {
        event = this.makeZoomChangeEvent(fitTypeEnum.w, false);
      }

      this.setPageZoom(event.data);

      this.initZoomRatio(page, document);
    });
  }

  private makeZoomChangeEvent(
    data: fitTypeEnum,
    bubbles: boolean = true,
  ): IEventModel {
    return { event: 'ZoomChanged', data, bubbles };
  }

  private getZoomRatioByWidth(page: PageModel): number {
    const { offsetWidth } = this.getPagesContainerSize();
    const pagesContainerWidth = offsetWidth - this.paddingWidth * 2;
    return pagesContainerWidth / page.width;
  }

  private getZoomRatioByHeight(page: PageModel): number {
    const { offsetHeight } = this.getPagesContainerSize();
    const pagesContainerHeight = offsetHeight - this.paddingHeight * 2;
    return pagesContainerHeight / page.height;
  }

  setPageZoom(val: number) {
    this.pageZoom = val;
    this.onPageZoomChange.next(this.pageZoom);
  }

  setZoomRatio(val: number) {
    this.zoomRatio = val < 0 ? 1 : val;
    this.onZoomRatioChange.next(this.zoomRatio);
  }

  setCurrentZoomSize(val: number) {
    this.currentZoomSize = val;
    this.onCurrentZoomSizeChange.next(this.currentZoomSize);
  }

  getPagesContainerSize(): HTMLElement | null {
    return this._document.getElementById('pagesContainer');
  }

  setPadding(metric: 'width' | 'height', value: number) {
    metric === 'width'
      ? (this.paddingWidth = value)
      : (this.paddingHeight = value);
  }
}
