<div #element id="panel-color" class="panel">
  <div class="panel-fixed__header" *ngIf="!isMobile">
    <div class="panel-header">
      <i class="trakto-icon-documents"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.general.header_color' | translate }}
        {{ headerTitle }}
      </h2>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.color.title' | translate }}
      </h2>
    </div>

    <button class="panel-scroller-close psc-button" (click)="close()">
      <i class="trakto-icon-arrow-right"></i>
    </button>
  </div>

  <div class="panel-full-color__container">
    <trakto-color-panel
      [elementSelected]="elementSelected"
      [pageSelected]="pageSelected"
      (colorChange)="changeColor($event)"
    >
    </trakto-color-panel>
  </div>
</div>
