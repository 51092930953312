<div class="uploader">
  <trakto-button-uploader [img]="'assets/editor-v3/images/tkt-gif-small.svg'" [label]="'Envie um gif'" [isGif]="true">
  </trakto-button-uploader>
</div>

<div class="image-grid" [ngClass]="{ loading: loading }" *ngIf="stickersDataProvider?.list.length > 0">
  <div class="image-grid-item" *ngFor="let image of stickersDataProvider.list">
    <div class="image-grid-item-inner">
      <div class="gif" [style.backgroundImage]="'url(' + image.thumb + ')'" (click)="insertImage(image)"></div>
    </div>

    <div class="action" *ngIf="loading || hasNextPage">
      <trakto-button-loader [isLoading]="loading" (action)="listStickByUser()" label="Carregar mais"></trakto-button-loader>
    </div>
  </div>


  <trakto-empty *ngIf="stickersDataProvider?.list.length === 0" image="/assets/images/no-results.svg" [margin]="35"
    [style.marginTop.px]="'30'" title="{{ 'property_panel.general.empty_results' | translate }}" description="{{
  'property_panel.general.description_results' | translate
}}">
  </trakto-empty>
</div>
