export const MAGIC_ELEMENT_TEXT_STYLES = [
    {
      id: 1,
      title: 'Fonte Primária',
    },
    {
      id: 2,
      title: 'Fonte Secundária',
    },
    {
      id: 3,
      title: 'Cor Principal',
    },
    {
      id: 4,
      title: 'Cor Secundária',
    },
    {
      id: 5,
      title: 'Cor Terciária',
    },
  ]