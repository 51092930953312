import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'trakto-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styleUrls: ['./menu-navbar.component.scss'],
})
export class MenuNavbarComponent implements OnInit {
  @ViewChild('nav', { static: true }) nav: ElementRef<HTMLDivElement>;

  @Input() menus: [string?, string?, string?] = [];
  @Output() onSideNavChange = new EventEmitter<any>();

  public navWidth = 360;
  public lineWidth = 0;
  public translateLine = 0;
  public selectedIndex = 0;

  constructor() {}

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.lineWidth =
      Math.floor(this.navWidth / this.menus.length) ??
      Math.floor(
        this.nav.nativeElement.getBoundingClientRect().width /
          this.menus.length,
      );
  }

  public activeItemMenu(item: HTMLDivElement, i: number) {
    const { width } = item.getBoundingClientRect();
    this.translateLine = width * i;
    this.selectedIndex = i;
    this.onSideNavChange.emit(i);
  }
}
