import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICheckboxControlValueAccessor } from './checkbox.model';

@Component({
  selector: 'trakto-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  implements OnInit, OnChanges, ICheckboxControlValueAccessor {
  private _id: string;
  private _name: string;
  private _disabled: boolean;
  private _checked: boolean;
  public isSelected: boolean;

  @ViewChild('checkbox', { static: true }) checkbox;

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || null;
  }

  @Input()
  public set checked(checked: boolean) {
    this._checked = checked;
  }

  public get checked() {
    return this._checked || null;
  }

  @Input()
  public set id(id: string) {
    this._id = id;
  }

  public get id() {
    return this._id || '';
  }

  @Input()
  public set name(name: string) {
    this._name = name;
  }

  public get name() {
    return this._name || '';
  }

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _onChange = (value: boolean) => {};
  private _onTouched = () => {};

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this.toggleClass();

    if (!this.id) {
      throw new Error(
        `Checkbox id is required. eg.: <trakto-checkbox [id]="myId"></trakto-checkbox>`,
      );
    }

    if (!this.name) {
      throw new Error(
        `Checkbox name is required. eg.: <trakto-checkbox [name]="myName"></trakto-checkbox>`,
      );
    }
  }

  ngOnChanges() {
    this._getHostElement().disabled = this.disabled ? true : false;
    this.toggleClass();
  }

  _getHostElement(): any {
    return this.checkbox.nativeElement;
  }

  writeValue(value: boolean): void {
    setTimeout(() => {
      this.isSelected = value;
      this._getHostElement().checked = value;
    }, 0);
  }

  registerOnChange(fn: (p?: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (p?: any) => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  toggle() {
    this.isSelected = !this.isSelected;

    this._onChange(this.isSelected);
    this.onChange.emit(this.isSelected);
  }

  toggleClass() {
    const element: any = this._elementRef.nativeElement.children[0];
    this.disabled
      ? element.classList.add('--disabled')
      : element.classList.remove('--disabled');
  }
}
