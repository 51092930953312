import { Component, Input, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { IElementModel, IShapeElementModel, PageModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';

@Component({
  selector: 'trakto-panel-color-mobile',
  templateUrl: './panel-color-mobile.component.html',
  styleUrls: ['./panel-color-mobile.component.scss']
})
export class PanelColorMobileComponent extends AbstractComponent<IShapeElementModel> {

  @Input()
  isBackground = false;

  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
    _documentStateManagerService: DocumentStateManagerService,
  ) {
    super(_documentStateManagerService)
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  public changeColor(color: string) {
    this._elementsService.changeColor(color, this.isBackground);
    this.closePanel();
  }

}
