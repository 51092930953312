import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DocumentService } from '@app/editor/services/document.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  NotificationService
} from '@app/shared/notification/notification.service';
import { SelectComponent } from '@app/shared/select/select.component';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';

import { ImageElementModel, INotificationMessage } from '@trakto/models';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Subscription } from 'rxjs';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-link-whatsapp',
  templateUrl: './panel-link-whatsapp.component.html',
})
export class PanelLinkWhatsappComponent  extends AbstractComponent<ImageElementModel> {
  public selectedCountry: any = 'BR';
  public selectPhoneNumber: string;
  public mensagemBody: string;
  public isSelectActive = false;
  public countries: any[];
  public form: FormGroup;
  public subscription: Subscription;
  public notifyMSGs: INotificationMessage;

  @ViewChild('phone', { static: true }) phone: SelectComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _hotkeyService: HotkeyService,
    private _documentService: DocumentService,
    private _panelStackService: PanelStackService,
    private _notificationService: NotificationService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super(documentStateManagerService);
    this.notifyMSGs = this._notificationService.notificationsMSGs;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormBuilder();
    this.fetchCountryList();
    this.setValuesInput();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public activeSelecetTheme(value: boolean) {
    this.isSelectActive = value;
  }

  public changeCountry(event): void {
    if (!this.isSelectActive) {
      return;
    }

    this.selectedCountry = event.value;
    this.form.controls[`phone`].setValue('');
    this.form.controls[`message`].setValue('');

    this.activeSelecetTheme(true);
  }

  public handleSubmit(): void {
    const {
      value: { phone, message },
    } = this.form;
    const tel = phone.replace('+', '').replace(/ /g, '');
    if (!this.form.valid) {
      return;
    }

    const linkWhatsapp = `https://wa.me/${tel}?text=${this.scapeURI(message)}`;
    const flagCountry = this.selectedCountry;

    const fakeElement: ImageElementModel = { ...this.elementSelected };
    fakeElement.whatsapp = !this.elementSelected.whatsapp && linkWhatsapp ? linkWhatsapp : null;
    fakeElement.flagCountry = !this.elementSelected.flagCountry ? flagCountry : null;
    this._elementChangeFacadeService.persistElementChanges(fakeElement);

    this.notificationMessage();
  }

  public scapeURI(string: string): string {
    return encodeURIComponent(string);
  }

  public unescapeURI(string: string): string {
    return decodeURIComponent(string);
  }

  private setValuesInput(): void {
    const element = !!this.elementSelected.whatsapp
      ? this.elementSelected.whatsapp.split('https://wa.me/')
      : null;
    const sliptNumber =
      element !== null && element[1] ? element[1].split('?') : null;
    const sliptMessage =
      sliptNumber !== null && sliptNumber[1]
        ? sliptNumber[1].replace('text=', '')
        : null;

    const numberWhatsapp =
      sliptNumber && sliptNumber[0] !== null ? sliptNumber[0] : '';
    const { flagCountry } = this.elementSelected;

    if (numberWhatsapp && sliptMessage === null) {
      const phone: any = parsePhoneNumberFromString(
        numberWhatsapp,
        this.selectedCountry,
      );
      this.form.controls[`phone`].setValue(phone.formatInternational());
      this.selectedCountry = flagCountry ? flagCountry : this.selectedCountry;
      this.mensagemBody = '';
      this._notificationService.warn(this.notifyMSGs.update_your_link_info, {
        isSimple: false,
        hasIcon: false,
        time: 4000,
      });

      return;
    }

    const phoneNumber: any = parsePhoneNumberFromString(
      numberWhatsapp,
      flagCountry ? flagCountry : this.selectedCountry,
    );

    if (phoneNumber || sliptMessage) {
      this.selectedCountry = flagCountry ? flagCountry : this.selectedCountry;
      this.form.controls[`phone`].setValue(
        phoneNumber !== undefined ? phoneNumber.formatInternational() : '',
      );
      this.mensagemBody = this.unescapeURI(sliptMessage);
    }
  }

  private notificationMessage(): void {
    if (this.elementSelected.whatsapp) {
      this._notificationService.success(this.notifyMSGs.link_added_success, {
        isSimple: false,
        hasIcon: false,
        time: 3000,
      });
    } else {
      this.form.controls[`phone`].setValue('');
      this.form.controls[`message`].setValue('');
      this._notificationService.success(
        this.notifyMSGs.link_removed_successfully,
        { isSimple: false, hasIcon: false, time: 3000 },
      );
    }
  }

  private fetchCountryList(): void {
    this.subscription = this._documentService
      .getCountries()
      .pipe(takeUntil(this._destroy$))
      .subscribe((country: any) => {
        this.countries = country;
      });
  }

  private initFormBuilder(): void {
    this.form = this._formBuilder.group({
      phone: ['', [Validators.required, this._validatePhoneInput.bind(this)]],
      message: ['', [Validators.required]],
    });
  }

  _validatePhoneInput(phone: AbstractControl): object {
    const inputValue: string =
      phone.value !== null ? phone.value.toString() : '';
    const phoneNumber: any = parsePhoneNumberFromString(
      inputValue,
      this.selectedCountry,
    );
    if (phoneNumber) {
      if (phoneNumber.isValid()) {
        return null;
      } else {
        return {
          phoneNumber: {
            valid: false,
          },
        };
      }
    } else {
      return {
        phoneNumber: {
          valid: false,
        },
      };
    }
  }

  public formatPhone(event: any): void {
    const inputValue: any = this.form.controls[`phone`].value;
    const phoneNumber: any = parsePhoneNumberFromString(
      inputValue,
      this.selectedCountry,
    );
    if (phoneNumber) {
      this.selectPhoneNumber = phoneNumber.getURI();
      this.form.controls[`phone`].setValue(phoneNumber.formatInternational());
    }
  }

  public closePanelLink(): void {
    this._panelStackService.pop(500);
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }
}
