import { Injectable } from '@angular/core';
import { ImageService } from '@app/editor/services/image.service';
import { UnsplashService } from '@app/editor/services/unsplash.service';
import { IElementModel, ImageElementModel } from '@trakto/models';
import { ElementsService } from './elements.service';
import { ResolutionsModel } from '@trakto/graphics-resources/dist/src/models/image.model';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { ElementChangeFacadeService } from '@app/editor/services/element-change-facade.service';

const PAGE_LIMIT = 20;

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  //obter de forma dinâmica
  tags = ['Flores', 'Natureza', 'Árvores', 'Plantação', 'Pessoas', 'Comida'];

  isImageToPageBackground = false;

  constructor(
    private _unsplashService: UnsplashService,
    private _imageService: ImageService,
    private _elementsService: ElementsService,
    private _documentManagerService: DocumentManagerService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
  ) {}

  async listImages(query: string = '', page: number = 1): Promise<any> {
    return await this._unsplashService.searchPhotos(
      query,
      `${PAGE_LIMIT}`,
      page.toString(),
    );
  }

  async backgroundRemoval(element: IElementModel): Promise<boolean> {
    try {
      const imageElement = element as ImageElementModel;
      const data = await this._imageService.cutImageBackground(
        imageElement.href,
      );
      await this._elementsService.changeImageLink(data);

      return true;
    } catch (error) {
      return false;
    }
  }

  async insertImageUrlToPageBackground(imageUrl: string) {
    const page = await this._documentManagerService.getSelectedPage();

    this._elementChangeFacadeService.changePageBackgroundUrlByUrl(
      page,
      imageUrl,
    );
  }

  async insertImageUrlsToPageBackground(image: ResolutionsModel) {
    const page = await this._documentManagerService.getSelectedPage();

    this._elementChangeFacadeService.changePageBackgroundUrlByUrls(page, image);
  }
}
