import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageModel } from '@trakto/models';
import { IChildFormat, IParentFormat } from '../services/formats.service';

@Component({
  selector: 'trakto-grid-formats',
  templateUrl: './grid-formats.component.html',
  styleUrls: ['./grid-formats.component.scss'],
})
export class GridFormatsComponent {
  @Input() isVisible: boolean;
  @Input() lang = 'pt-BR';
  @Input() formats: IParentFormat[] = [];
  @Input() pageSelected: PageModel;

  @Output() onGridClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSelectFormat: EventEmitter<IChildFormat> =
    new EventEmitter<IChildFormat>();

  closeGrid() {
    this.onGridClose.emit();
  }

  selectFormat(format: IChildFormat) {
    this.onSelectFormat.emit(format);
  }
}
