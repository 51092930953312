import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthFirebaseService } from '@app/auth/shared/auth-firebase.service';
import { IDocumentModel, PageModel } from '@trakto/models';
import { ToUnfold, UnfoldDesignService } from '@app/editor-v3/services/design/unfold-design/unfold-design.service';
import { UNFOLD_CONTENTS, UnfoldDesignName } from './unfold-contents';
import { TeamTemplateService } from '@app/editor-v3/services/admin/team/template-publish/team-template.service';
import { NotificationService } from '@app/shared/notification/notification.service';
import { ConfirmationService } from '@app/editor-v3/services/confirmation.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { TemplateCampaignPublished } from '@app/editor/services/admin-api-white-label.service';

@Component({
  selector: 'trakto-modal-unfold-template',
  templateUrl: './modal-unfold-template.component.html',
  styleUrls: ['./modal-unfold-template.component.scss'],
})
export class ModalUnfoldTemplateComponent implements OnInit {

  public unfolds = UNFOLD_CONTENTS;
  public showModal: boolean = false;

  document: IDocumentModel;
  pageSelected: PageModel;
  @Output() onModalShareChange = new EventEmitter<any>();
  public loading = false;


  constructor(
    private _authFirebaseService: AuthFirebaseService,
    private _unfoldDesignservice: UnfoldDesignService,
    private _templatePublishService: TeamTemplateService,
    private _notificationService: NotificationService,
    private _confirmationService: ConfirmationService,
    private _documentService: DocumentManagerService,
  ) { }

  ngOnInit(): void { }


  changeFormarts(i) {
    const designName = this.unfolds[i].name;
    const unfoldMap = {
      [UnfoldDesignName['square']]: [true, false, false],
      [UnfoldDesignName['vertical']]: [false, true, false],
      [UnfoldDesignName['horizontal']]: [false, false, true],
    };

    const checkboxStates = unfoldMap[designName];

    if (checkboxStates) {
      this.unfolds[0].checked = checkboxStates[0];
      this.unfolds[1].checked = checkboxStates[1];
      this.unfolds[2].checked = checkboxStates[2];
    }
  }

  async doneUnfoldTemplates() {
    try {
      this.loading = true;
      this._notificationService.info('Redimensionando o template ...', { time: 0 });
      this.document = await this._documentService.getSelectedDocument();
      this.pageSelected = await this._documentService.getSelectedPage();
      const element = this.unfolds.find((element) => element.checked).value;
      if (!element) this._notificationService.error('Selecione um formato para redimensionar');
      const { design, cover } = await this._unfoldDesign(element);
      await this._publishOnCampaign(design);
      this._continueEditingOrNot(design.id, cover);
    } catch (err) {
      this._notificationService.error('Não foi possível redimensionar o design. Por favor, tente novamente');
      this.loading = false;
      console.error(err);
    } finally {
      this._notificationService.close();
      this.loading = false;
    }
  }

  private _continueEditingOrNot(designId: string, cover: string) {
    this.loading = false;
    this.showModal = false;
    const inputs = {
      icon: cover,
      title: 'Redimensionado e publicado!',
      description: 'Você pode continuar editando ou ir até o seu design redimensionado.',
      cancel: 'Continuar editando',
      confirm: 'Editar o novo design',
      cssClass: "width: 300px; 'padding': 24px;",
    };

    const outputs = {
      action: {
        emit: () => this._redirectToUnfoldDesign(designId),
      },
    };

    this._confirmationService.show(inputs, outputs);
  }

  private async _publishOnCampaign(newDesign: IDocumentModel) {
    this._notificationService.info('Publicando o template na campanha ...', { time: 7000 });
    await this._publishUnfoldedDesign({
      ...newDesign,
    });
  }

  private async _unfoldDesign(newDimension: { width: number; height: number; }): Promise<{ design: IDocumentModel, cover: string }> {
    const { design, cover } = await this._unfoldDesignservice.unfold(
      {
        page: this.pageSelected,
        width: newDimension.width,
        height: newDimension.height,
        title: this.document.title,
        pageFormatId: this.document.page_format_reference,
      } as ToUnfold,
      this.document.title,
      this.document.author,
    );

    return { design, cover };
  }

  private _publishUnfoldedDesign(unfoldDesign: IDocumentModel): Promise<TemplateCampaignPublished> {
    if (!this._templatePublishService.lastTemplatePublished) {
      this._notificationService.error('Não foi possível publicar o design na campanha');
      return;
    }
    const { campaignId } = this._templatePublishService.lastTemplatePublished;
    return this._templatePublishService.publishOnCampaign(
      unfoldDesign,
      campaignId,
    );
  }

  private _redirectToUnfoldDesign(designId: string) {
    const url = window.location.href.split('/editor/')[0];
    const finalUrl = `${url}/editor/${designId}/${this._authFirebaseService.accessToken}`;
    const newWindow = window.open(finalUrl, '_blank');
    if (newWindow.closed || typeof newWindow.closed == 'undefined') {
      this._notificationService.error('Verifique se o seu navegador está bloqueando pop-ups e tente novamente.');
      return;
    }
  }

  toggle() {
    this.showModal = !this.showModal;
    this.onModalShareChange.emit(this.showModal);
  }
}
