import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ImagesService } from '@app/editor-v3/services/images.service';
import { debounce } from 'lodash';
import { DocumentManagerService } from '@services/document-manager.service';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { ImageElementModel } from '@trakto/models';

@Component({
  selector: 'trakto-search-tab-mobile',
  templateUrl: './search-tab-mobile.component.html',
  styleUrls: ['./search-tab-mobile.component.scss'],
})
export class SearchTabMobileComponent implements OnInit {
  query = '';
  inputText = '';
  tags = [];
  loading = false;
  images = [];
  page = 1;
  maxPages = 1;

  @Output()
  private clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _imagesService: ImagesService,
    private _documentManagerService: DocumentManagerService,
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
  ) {
    this.fetchInputtedTag = debounce(this.fetchInputtedTag, 1000);
  }

  ngOnInit(): void {
    this.listImages();
    this.listTags();
  }

  resetParameters() {
    this.images = [];
    this.page = 1;
    this.maxPages = 1;
  }

  async listImages() {
    this.loading = true;
    this.resetParameters();

    //verificar query vazia
    try {
      const images = await this._imagesService.listImages(this.query);
      this.images = images.results;
      this.maxPages = images.pages;
    } finally {
      this.loading = false;
    }
  }

  async loadNextPage() {
    if (this.loading && this.page === this.maxPages) return false;

    try {
      this.loading = true;
      const nextPage = this.page + 1;
      const nextImages = await this._imagesService.listImages(
        this.query,
        nextPage,
      );

      this.images = [...this.images, ...nextImages.results];
    } finally {
      this.loading = false;
    }
  }

  listTags() {
    this.tags = this._imagesService.tags;
  }

  selectTag(tag: string, resetInput = true) {
    if (resetInput) this.inputText = '';
    this.query = tag;
    this.listImages();
  }

  setInputText(val: string) {
    this.inputText = val;
    this.fetchInputtedTag();
  }

  fetchInputtedTag() {
    this.selectTag(this.inputText, false);
  }

  insertImage(image: any) {
    this._insertImage(image).then();
    this.clicked.emit(true);
  }

  async _insertImage(image: any) {
    if (this._imagesService.isImageToPageBackground) {
      await this._imagesService.insertImageUrlToPageBackground(image.photo_urls.full);
      return true;
    }

    const selectedElement = await this._documentManagerService.getSelectedElement();
    if (selectedElement) {
      await this._elementChangeFacadeService.changeImageUrl(
        selectedElement as ImageElementModel,
        image.photo_urls.full,
      );
    } else {
      await this._elementFactoryFacadeService.createAndAddImageByUrl(
        image.photo_urls.full,
      );
    }
  }
}
