import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { DropdownSelectorComponent } from './dropdown-selector.component';

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: [DropdownSelectorComponent],
  exports: [DropdownSelectorComponent],
})
export class DropdownSelectorModule {}
