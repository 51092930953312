import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraktoLinksRepository } from '@editor/repository/trakto-links.repository';
import { environment } from '@env/environment';
import { IDocumentModel } from '@trakto/models';
import { DocumentManagerService } from '../document-manager.service';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { UserService } from '@app/editor-v3/services/user.service';

export class TraktoLinkSeo {
  thumbUrl?: string | any;
  title?: string = '';
  description?: string = '';

  constructor(partial: Partial<TraktoLinkSeo>) {
    if (partial.thumbUrl && !this._urlIsValid(partial.thumbUrl)) {
      throw 'Invalid Thumb URL';
    }
    Object.assign(this, partial)
  }

  private _urlIsValid(url: string) {
    const regex = new RegExp('^(http|https)://', 'i');
    return regex.test(url);
  }
};


interface TraktoLinkBase {
  link_name?: string;
  link_web?: string;
  pausedLink?: boolean;
  seo?: TraktoLinkSeo;
  slug?: string;
  link_qrcode?: string;
  title?: string;
  description?: string;
  image?: string;
  linkWeb?: string;
}

export class TraktoLink {
  slug: string;
  link_name?: string;
  link_web?: string;
  pausedLink?: boolean;
  linkWeb?: string;
  seo: TraktoLinkSeo;

  constructor(partial: Partial<TraktoLink>) {
    Object.assign(this, partial);
  }
}
export interface IMetadata {
  link_name?: string;
  link_web?: string;
  pausedLink?: boolean;
  link_qrcode?: string;
  title?: string;
  description?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TraktoLinksService {
  private _metadata: any;

  private _link: string;
  private _linkName: string;
  private _linkWeb = '';
  private _metaImage: string;
  private _metaTitle: string;
  private _metaDescription: string;
  private _domain: string;
  private traktoLink: TraktoLink;

  public get link() {
    return this._link;
  }

  public set link(linkName: string) {
    this._link = linkName;
  }

  public get linkName() {
    return this._linkName;
  }

  public set linkName(linkName: string) {
    this._linkName = linkName;
  }

  public get isLink() {
    return !!this._link;
  }

  public get linkWeb() {
    return this._linkWeb;
  }

  public get metaImage() {
    return this._metaImage;
  }

  public get metaTitle() {
    return this._metaTitle;
  }

  public get metaDescription() {
    return this._metaDescription;
  }

  public get metadata() {
    return this._metadata;
  }

  public currentDoc: IDocumentModel;

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _repository: TraktoLinksRepository,
    private _userService: UserService,
    private _authFirebaseService: AuthFirebaseService,
    private _httpClient: HttpClient,
    private _uploadStorageService: UploadStorageService,
  ) { }

  public async init() {
    const document: any = await this._documentStateManagerService.documentSnapshot$.toPromise();

    let model: IMetadata = {
      title: '',
      link_name: '',
      description: '',
      link_web: '',
      image: ''
    }

    if (document.metadata) {
      model = {
        title: document.metadata.title,
        link_name: document.metadata.link_name,
        description: document.metadata.description,
        link_web: document.metadata.link_web,
        image: document.metadata.image
      } = document.metadata;
    }

    const metadata: TraktoLink = new TraktoLink({
      slug: model.link_name,
      link_web: model.link_web,
      seo: new TraktoLinkSeo({
        thumbUrl: model.image,
        title: model.title,
        description: model.description,
      })
    });

    this._domain = this._userService.isB2c ? 'https://trakto.link' : 'https://olink.pro';

    this._linkName = metadata.slug;
    this._link = this._buildLink(metadata.slug);
    this._updateGlobalMetadata(metadata);

    return true;
  }

  public setTraktoLink(traktoLink) {
    if (!traktoLink) {
      return
    }
    this.traktoLink = new TraktoLink({
      slug: traktoLink.metadata?.link_name ? traktoLink.metadata?.link_name : traktoLink.link_name,
      link_web: traktoLink.metadata?.link_name ? traktoLink.metadata?.link_web : traktoLink.link_web,
      seo: new TraktoLinkSeo({
        thumbUrl: traktoLink.metadata?.image ? traktoLink.metadata?.image : traktoLink.image,
        title: traktoLink.metadata?.title ? traktoLink.metadata?.title : traktoLink.title,
        description: traktoLink.metadata?.description ? traktoLink.metadata?.description : traktoLink.description,
      })
    })
  }

  public getTraktoLink() {
    return this.traktoLink;
  }

  /**
   * This function will find if linkName exist in firebase and set if not exist.
   * @param linkName: string
   *
   * @returns full url if success or false if already exist;
   */
  public async updateLink(linkName: string) {

    try {
      const metadata: IMetadata = {
        link_name: linkName,
        link_web: this.linkWeb,
        image: this.metaImage,
        title: this.metaTitle,
        description: this.metaDescription,
      };
      this._updateGlobalMetadata({
        link_name: linkName,
        link_web: this.linkWeb,
        image: this.metaImage,
        title: this.metaTitle,
        description: this.metaDescription,
      });

      await this._createOrUpdateLink(metadata);

      this._link = this._buildLink(linkName);
      await this.init();

      return this._link;
    } catch (e) {
      throw new Error(e);
    }
  }

  private async getDocumentId() {
    return (await this._documentManagerService.getSelectedDocument()).firebaseId ||
      (await this._documentManagerService.getSelectedDocument()).id
  }

  private async _createOrUpdateLink(metadata: IMetadata) {
    const documentId = await this.getDocumentId();
    const saved = await this._repository.createOrUpdateLink(
      metadata,
      documentId,
    );
    this._documentManagerService.updateMetadata(saved.metadata);
  }

  /**
   * @description save web link, the responsive link of Trakto Links
   */
  public async updateWebLink(linkWeb: string) {
    try {

      const metadata = {
        ...this._metadata,
        link_web: linkWeb,
      };

      this._updateGlobalMetadata(metadata);

      await this._createOrUpdateLink(metadata);

    } catch (error) {
      throw new Error(error);
    }
  }

  public async createOrUpdateMetaData(link: IMetadata): Promise<IMetadata> {
    try {
      const savedMetadata: IMetadata = this._updateGlobalMetadata(link);
      await this._createOrUpdateLink(savedMetadata);

      this._linkName = link.link_name;
      this._linkWeb = link.link_web;
      this._metaDescription = link.description;
      this._metaTitle = link.title;
      this._metaImage = link.image;

      return savedMetadata;
    } catch (error) {
      throw new Error(`Erro em gravar customização', ${error.message}`);
    }
  }

  /**
   * Build a trakto links url
   *
   * @param linkName: string
   *
   * @returns a string with full url link
   */
  private _buildLink(linkName: string): string {
    return this._domain
      ? `${this._domain}/${linkName || ''}`
      : `${environment.static.traktoLinksDefaultUrl}${linkName || ''}`;
  }

  private _updateGlobalMetadata(link: TraktoLinkBase): IMetadata {

    const metadata: IMetadata = {
      link_name: link.link_name ? link.link_name : link.slug,
      title: link.title ? link.title : link.seo.title,
      description: link.description ? link.description : link.seo.description,
      link_web: link.link_web,
      image: link.image ? link.image : link.seo.thumbUrl,
      pausedLink: link.pausedLink
    };
    this._metadata = metadata;

    this._metaImage = link.image,
      this._metaTitle = link.title,
      this._metaDescription = link.description,
      this._linkWeb = link.link_web
    this._linkName = link.link_name;
    this._documentManagerService.updateMetadata(metadata);
    return metadata;
  }

  /**
   * Method responsible for obtaining the file image and
   *  returning to the cloudinary url
   * @param file File
   * @param user User
   * @returns
   */
  public async preparingImageForUpload(file: File): Promise<string> {
    return (await this._uploadStorageService.uploadFile(file)).resolutions.raw.secure_url;
  }

  public async createMetadataAndPublish(link: TraktoLink, isB2C: boolean): Promise<TraktoLink> {
    try {
      await this.createOrUpdateMetaData(link);
      await this.publishTraktoLInk(link.slug, isB2C);
      this._documentManagerService.updateMetadata({
        title: link.seo.title,
        link_name: link.slug,
        description: link.seo.description,
        link_web: link.link_web,
        image: link.seo.thumbUrl
      });
      return link;
    } catch (err) {
      throw new Error(`Error to create and publish trakto link: ${err}`)
    }
  }

  public async publishTraktoLInk(slug: string, isB2C = true): Promise<any> {
    const productType = (isB2C === true) ? 'b2c' : 'b2b';
    const httpOptions = await this._getHttpHeaderOfTraktoLinkAPI();
    return this._httpClient.get(`${environment.api.BASE_URL_TRAKTO_LINK_PUBLISH_API}/${slug}/publish?type=${productType}`, httpOptions).toPromise();
  }

  public getTraktoLinkFromApi(slug: string) {
    return this._httpClient.get(`${environment.api.BASE_URL_TRAKTO_LINK_PUBLISH_API}/${slug}`);
  }

  public async deleteTraktoLink(slug: string) {
    const httpOptions = await this._getHttpHeaderOfTraktoLinkAPI();
    return this._httpClient.delete(`${environment.api.BASE_URL_TRAKTO_LINK_PUBLISH_API}/${slug}`, httpOptions).toPromise();
  }

  private async _getHttpHeaderOfTraktoLinkAPI() {
    const token = await this._authFirebaseService.getAccessToken();

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };
    return httpOptions;
  }

  public async linkIsAvaliable(slug: string): Promise<boolean> {
    return this._repository.linkIsAvailable(slug);
  }

  public async pauseTraktoLink(slug: string, pause: boolean) {
    const httpOptions = await this._getHttpHeaderOfTraktoLinkAPI();
    return this._httpClient.put(`${environment.api.BASE_URL_TRAKTO_LINK_PUBLISH_API}/${slug}/pause`, { pause }, httpOptions).toPromise();
  }
}
