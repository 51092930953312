import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';

import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { TooltipPlacementEnum } from './tooltip-placement.enum';
import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[traktoTooltip]' })
export class TooltipDirective implements OnChanges, OnDestroy {
  tooltipRef: ComponentRef<TooltipComponent>;

  private _tooltipPlacement: TooltipPlacementEnum;
  private _tooltipWidth: string;
  private _tooltipBackgroundColor?: string;
  private _tooltipColor?: string;
  private _padding?: string;

  @Input()
  traktoTooltip: string;

  @Input()
  ref: any;

  @Input('traktoTooltipBackgroundColor')
  set tooltipBackgroundColor(value: string) {
    if (value) {
      this._tooltipBackgroundColor = value;
    }
  }

  get tooltipBackgroundColor(): string {
    return this._tooltipBackgroundColor || '#0D2226';
  }

  @Input('traktoTooltipColor')
  set tooltipColor(value: string) {
    if (value) {
      this._tooltipColor = value;
    }
  }

  get tooltipColor(): string {
    return this._tooltipColor || '#FCFCFE';
  }

  @Input('traktoTooltipPadding')
  set padding(value: string) {
    if (value) {
      this._padding = value;
    }
  }

  get padding(): string {
    return this._padding || '7px 7px';
  }

  @Input('traktoTooltipPlacement')
  set tooltipPlacement(value: TooltipPlacementEnum) {
    if (value) {
      this._tooltipPlacement = value;
    }
  }

  get tooltipPlacement(): TooltipPlacementEnum {
    return this._tooltipPlacement || TooltipPlacementEnum.top;
  }

  @Input('width')
  set tooltipWidth(value: string) {
    if (value) {
      this._tooltipWidth = value;
    }
  }

  get tooltipWidth(): string {
    return this._tooltipWidth || '160';
  }

  constructor(
    private _elementRef: ElementRef,
    private _appRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _hotkeyService: HotkeyService,
  ) {}

  ngOnChanges(changes) {
    // workaround para o firefox que não estava removendo o tooltip.
    if (changes.ref && this._hotkeyService.isFirefox()) {
      this.destroyTooltip();
      this.forceTooltipRemove();
    }
  }

  @HostListener('mouseenter')
  showTooltip() {
    this.tooltipRef = this._componentFactoryResolver
      .resolveComponentFactory(TooltipComponent)
      .create(this._injector);
    this._appRef.attachView(this.tooltipRef.hostView);
    const domElem = (this.tooltipRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.tooltipRef.instance.text = this.traktoTooltip;
    this.tooltipRef.instance.setPosition({
      placement: this.tooltipPlacement,
      width: this.tooltipWidth,
      targetElement: this._elementRef.nativeElement,
      backgroundColor: this.tooltipBackgroundColor,
      color: this.tooltipColor,
      padding: this.padding,
    });
  }

  @HostListener('mouseleave')
  @HostListener('click')
  destroyTooltip() {
    if (this.tooltipRef) {
      this.tooltipRef.destroy();
      this.tooltipRef = null;
    }
  }

  forceTooltipRemove() {
    const tooltips = document.getElementsByTagName('trakto-tooltip');
    for (let i = tooltips.length - 1; i >= 0; i--) {
      tooltips[i].remove();
    }
  }

  ngOnDestroy() {
    this.destroyTooltip();
  }
}
