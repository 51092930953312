import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FilterModel } from '@editor/model/filter.model';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { SVG } from '@svgdotjs/svg.js';

@Directive({
  selector: '[traktoSvgFilter]',
})
export class SvgFilterDirective implements OnInit, AfterViewInit, OnChanges {
  @Input()
  traktoSvgFilter: string;
  lastFilter: string;

  currentFilter: FilterModel;
  private _platformId;
  constructor(
    private elementRef: ElementRef,
    private hotkeyService: HotkeyService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this._platformId = platformId;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.updateFilter();
  }

  ngOnChanges() {
    if (
      this.traktoSvgFilter &&
      (!this.lastFilter || this.lastFilter !== this.traktoSvgFilter)
    ) {
      this.lastFilter = this.traktoSvgFilter;
      this.updateFilter();
    }
  }

  updateFilter() {
    if (isPlatformBrowser(this._platformId)) {
      const svgReference = SVG(this.elementRef.nativeElement);
      if (svgReference) {
        this.currentFilter = new FilterModel(svgReference);
        if (this.traktoSvgFilter) {
          this.currentFilter.apply(this.traktoSvgFilter);
        } else {
          this.currentFilter.unfilter();
        }
        setTimeout(() => this.handleSafariFilterCompatibility(), 0);
      }
    }
  }

  handleSafariFilterCompatibility() {
    if (!this.hotkeyService.isSafari()) {
      return;
    }
    const filterId = this.getFilterId();

    if (filterId) {
      const filter = SVG(filterId).node;
      if (filter) {
        // https://stackoverflow.com/questions/29036829/safari-svg-gaussian-blur-fegaussianblur-wont-render-stddeviation-less-than-1
        filter.setAttribute('color-interpolation-filters', 'sRGB');
      }
      const filterReference = `url(${window.location.pathname.replace(
        '/',
        '',
      )}${filterId})`;
      this.elementRef.nativeElement.removeAttribute('filter');
      setTimeout(
        () =>
          this.elementRef.nativeElement.setAttribute('filter', filterReference),
        0,
      );
    }
  }

  private getFilterId() {
    let filterId = this.elementRef.nativeElement.getAttribute('filter');
    if (filterId) {
      filterId = filterId.replace('url(', '').replace(')', '');
    }
    return filterId;
  }
}
