import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { ElementNewApiService } from '@services/element-new-api.service';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { ElementsGalleryService } from '@shared/elements-gallery/elements-gallery.service';
import { NewEmojiElementService } from '@services/new-emoji-element.service';

@Component({
  selector: 'trakto-elements-list',
  templateUrl: './elements-list.component.html',
  styleUrls: ['./elements-list.component.scss'],
  host: {
    'class': 'click-ignore',
  }
})
export class ElementsListComponent implements OnInit, OnChanges {
  @Input()
  searchEvent: ISearchEvent = null;

  loading: boolean = false;
  page = 1;
  pageSize = 40;
  hasNextPage = true;
  elements: IElementNewAPI[] = [];

  constructor(
    private _panelService: PanelService,
    private _elementsGalleryService: ElementsGalleryService,
    private _elementsService: ElementsService,
    private _elementNewApiService: ElementNewApiService,
    private _newEmojiElementService: NewEmojiElementService
  ) {}

  ngOnInit(): void {
    this.fetchElements();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchEvent) {
      this.elements = [];
      this.page = 1;
      this.fetchElements();
    }
  }

  loadNextPage() {
    this.page++;
    this.fetchElements();
  }

  async fetchElements() {
    if (!this.searchEvent) {
      return;
    }
    try {
      this.loading = true;

      const response = await this._elementNewApiService.fetchElements(
        { ...this.searchEvent },
        this.pageSize,
        this.page - 1,
      );
      this.hasNextPage = response.nextPage !== null
      this.page = response.nextPage;
      this.elements = this.elements.concat(response.elements);
    } finally {
      this.loading = false;
    }
  }

  resetSearch() {
    this.elements = [];
    this.page = 1;
    this.fetchElements();
  }

  async insertElement(element: IElementNewAPI) {
    await this._elementsService.createByElement(element);
    this._elementsGalleryService.resetPanelStore();
  }
}
