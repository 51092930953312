<div
  *ngIf="elementSelected"
  class="panel-actions">
  <div
    id="panel-lock"
    class="panel-lock__users"
    *ngIf="allowLockElementsByTemplate"
  >
    <span class="panel-subtitle__user">{{
      'property_panel.lock_template.title' | translate
    }}</span>

    <div class="panel-lock__button">
      <trakto-button
        button--medium
        traktoTooltip="{{ 'property_panel.lock_template.lock' | translate }}"
        traktoTooltipPlacement="left"
        width="150px"
        [disabled]="isClipEditable"
        (onClick)="lockElement()"
      >
        <i class="trakto-icon-lock"></i>
      </trakto-button>
      <trakto-button
        button--medium
        traktoTooltip="{{ 'property_panel.lock_template.unlock' | translate }}"
        traktoTooltipPlacement="left"
        width="150px"
        [disabled]="isClipEditable"
        (onClick)="lockElement(false)"
      >
        <i class="trakto-icon-unlock"></i>
      </trakto-button>
    </div>
  </div>

  <div class="panel-actions__content">
    <div class="panel-actions__buttons">
      <trakto-dropdown-content
        title="{{ 'property_panel.general.order' | translate }}"
        placement="left"
        icon="trakto-icon-forward"
        [disabled]="
          !elementSelected.edit ||
          elementSelected.lockedByTemplate ||
          isTextEditable ||
          isClipEditable ||
          inGroup ||
          isGroup
        "
        [width]="175"
        [button]="{ classes: ['button--outline', 'button--small'] }"
      >
        <div class="trakto-dropdown__content">
          <trakto-button
            [style.marginBottom.px]="10"
            button--small
            button--inline
            button--outline
            (onClick)="moveDown()"
          >
            <i class="trakto-icon-backward"></i>
            <span>{{ 'property_panel.general.back' | translate }}</span>
          </trakto-button>
          <trakto-button
            button--small
            button--inline
            button--outline
            (onClick)="moveUp()"
          >
            <i class="trakto-icon-forward"></i>
            <span>{{ 'property_panel.general.front' | translate }}</span>
          </trakto-button>
        </div>
      </trakto-dropdown-content>
    </div>

    <div class="panel-actions__buttons">
      <trakto-dropdown-content
        title="{{ 'property_panel.general.align' | translate }}"
        placement="left"
        icon="trakto-icon-align-content-x-center"
        [width]="175"
        [disabled]="
          !elementSelected.edit ||
          elementSelected.lockedByTemplate ||
          isTextEditable ||
          isClipEditable
        "
        [button]="{ classes: ['button--outline', 'button--small'] }"
      >
        <div class="trakto-dropdown__content">
          <div class="trakto-dropdown__align--column">
            <div class="trakto-dropdown__align--row">
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('horizontal', 'left')"
              >
                <i class="trakto-icon-align-content-left"></i>
              </trakto-button>
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('horizontal', 'center')"
              >
                <i class="trakto-icon-align-content-x-center"></i>
              </trakto-button>
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('horizontal', 'right')"
              >
                <i class="trakto-icon-align-content-right"></i>
              </trakto-button>
            </div>
            <div class="trakto-dropdown__align--row">
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('vertical', 'top')"
              >
                <i class="trakto-icon-align-content-top"></i>
              </trakto-button>
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('vertical', 'center')"
              >
                <i class="trakto-icon-align-content-y-center"></i>
              </trakto-button>
              <trakto-button
                button--small
                button--outline
                (onClick)="alignTo('vertical', 'bottom')"
              >
                <i class="trakto-icon-align-content-bottom"></i>
              </trakto-button>
            </div>

            <trakto-checkbox
              *ngIf="elementSelected.type === 'group'"
              id="alignToPage"
              name="alignToPage"
              [(ngModel)]="alignToPage"
              (onChange)="(!alignToPage)"
            >
              {{ 'property_panel.alignment.to_page' | translate }}
            </trakto-checkbox>
          </div>
        </div>
      </trakto-dropdown-content>
    </div>

    <div class="panel-actions__buttons">
      <trakto-dropdown-content
        title="{{ 'property_panel.general.transparency' | translate }}"
        placement="right"
        icon="trakto-icon-opacity"
        [disabled]="
          !elementSelected.edit ||
          elementSelected.lockedByTemplate ||
          isTextEditable ||
          isClipEditable ||
          isGroup ||
          isVideo
        "
        [width]="215"
        [button]="{ classes: ['button--outline', 'button--small'] }"
      >
        <div class="trakto-dropdown__content">
          <mat-slider
            #opacitySlider
            required
            min="0"
            max="1"
            step="0.001"
            name="opacity"
            placeholder="opacity"
            [ngModel]="elementSelected.opacity"
            (ngModelChange)="changeOpacity($event)"
          >
          </mat-slider>
          <span [style.paddingLeft.px]="8"
            >{{ opacitySlider.value * 100 | number: '1.0-0' }}%</span
          >
        </div>
      </trakto-dropdown-content>
    </div>
  </div>
</div>

<div
  *ngIf="elementSelected"
  class="panel-actions__topbar">
  <trakto-button
    *ngIf="!elementSelected.lockedByTemplate"
    button--large
    (onClick)="lock()"
    [disabled]="isTextEditable || isClipEditable"
  >
    <i
      [ngClass]="{
        'trakto-icon-lock': !elementSelected.edit,
        'trakto-icon-unlock': elementSelected.edit
      }"
    ></i>
  </trakto-button>
  <trakto-button
    button--large
    (onClick)="deleteElement()"
    [disabled]="
      !elementSelected.edit ||
      elementSelected.lockedByTemplate ||
      isTextEditable ||
      isClipEditable
    "
  >
    <i class="trakto-icon-trash"></i>
  </trakto-button>
</div>
