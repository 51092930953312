import { Component, Input, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'trakto-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true,
    },
  ],
})
export class InputFieldComponent implements OnInit, ControlValueAccessor {

  private onChange: (chips: string) => void = () => {};
  private onTouched: () => void = () => {};

  @Input() placeholder: string;
  @Input() value: string;

  public inputValue: string; 
  
  constructor(
    public hotkeyService: HotkeyService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {}

  writeValue(value: string): void {
    if (value === null) {
      this.inputValue = '';
    }
    if (this.value && value) {
      this.inputValue = value;
    }
  }

  onValueChanged(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
