import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'trakto-manual-save-indicator',
  templateUrl: './manual-save-indicator.component.html',
  styleUrls: ['./manual-save-indicator.component.scss'],
})
export class ManualSaveIndicatorComponent {
  @Output() onClick = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  fireOnClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.activatedRoute.snapshot.params.document) {
      this.onClick.emit(() => {
        this.onDone.emit();
      });
    }
  }
}
