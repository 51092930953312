import { Injectable } from '@angular/core';
import {
  UtilMagicTemplateService
} from '@shared/magic-template/shared/util-magic-template.service';
import { TextElementService } from '@services/text-element.service';
import { TextMagicTemplateGenerator } from '@trakto/magic-templates';
import { ElementModelService } from '@services/element-model.service';

@Injectable()
export class TextMagicTemplateService extends TextMagicTemplateGenerator {
  constructor(
    utilMagicTemplateService: UtilMagicTemplateService,
    textService: TextElementService,
    elementModelService: ElementModelService,
  ) {
    super (
      utilMagicTemplateService,
      textService,
      elementModelService,
    );
  }

}
