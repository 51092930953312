import { Injectable } from '@angular/core';
import { IElementDropdown } from './dropdown';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  dropdownsActive: IElementDropdown;

  constructor() {}

  active(dropdown: IElementDropdown): void {
    this.dropdownsActive = dropdown;
  }

  disable(): void {
    if (this.dropdownsActive) {
      this.dropdownsActive.close();
      this.dropdownsActive = null;
    }
  }
}
