<div #element id="panel-publish" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <div class="formats-results__back">
        <img src="assets/images/formats-elements/goback.png" alt="back" (click)="onClosePanelClick()" />
      </div>
      <div class="panel-header-group">
        <div>
          <i class="trakto-icon-link-drop"></i>
          <h2 class="panel-header__title">
            {{ 'sidebar_publish.title' | translate }}
          </h2>
        </div>
        <i class="trakto-icon-info"></i>
      </div>
    </div>
  </div>

  <trakto-stepper-progress [color]="progressBarColor" [currentStep]="currentStep$" [totalSteps]="totalSteps$">
  </trakto-stepper-progress>

  <div class="panel-full__container">
    <div class="step-container">
      <ng-container>
        <ng-container *ngIf="(templateType$ | async) as templateType">
          <ng-container *ngIf="templateType === 'default'">
            <trakto-default-template></trakto-default-template>
          </ng-container>
          <ng-container *ngIf="templateType === 'magic'">
            <trakto-magic-template></trakto-magic-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>