import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalMenuItemComponent } from './components/modal-menu-item/modal-menu-item.component';
import { ModalMenuComponent } from './modal-menu.component';

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule],
  declarations: [ModalMenuComponent, ModalMenuItemComponent],
  exports: [ModalMenuComponent, ModalMenuItemComponent],
})
export class ModalMenuModule {}
