import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ElementNewApiService } from '@services/element-new-api.service';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import {
  ISearchEvent,
} from '@editor/components/properties-panel/panel-elements/model/model';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { elementTypeEnum } from '@trakto/models';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';

@Component({
  selector: 'trakto-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent {
  @Output() isSearchbarFocused: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() searchTag: EventEmitter<ISearchEvent> =
    new EventEmitter<ISearchEvent>();

  @Input() universeTags: ILocaleTag[] = [];
  @Input() searchType: SearchTypeEnum = SearchTypeEnum.MAIN;
  @Input() elementType: elementTypeEnum;

  public searchQuery = '';
  public foundTags: ILocaleTag[] = [];
  public isSearching = false;
  public isLoading = false;

  constructor(
    private _hotkeyService: HotkeyService,
    private _elementNewApiService: ElementNewApiService,
  ) {}

  /**
   * Quando input de pesquisa está em foco,
   * shortkeys são desativadas, overlay é exibido,
   * e sugestões de tags são exibidas.
   */
  inputLinkFocus(): void {
    this._hotkeyService.disableHotkeys();
    this.isSearchbarFocused.emit(true);
    this.isSearching = true;
  }

  /**
   * Quando input de pesquisa é abandonado,
   * ações acima são revertidas, após
   * um ligeiro timeout.
   */
  inputLinkBlur(): void {
    setTimeout(() => {
      this.isSearchbarFocused.emit(false);
      this.isSearching = false;
    }, 100);
  }


  editSearch(query = null) {
    this.searchQuery = query || '';
  }

  applySearch(event?: any) {
    const query = event ? event.target?.value : this.searchQuery;
    if(query) {
      this.searchTag.emit({
        tags: [{
              name: query,
              languageISOCode: 'pt-BR',          
        }],
        type: this.searchType,
        elementType: this._elementNewApiService.getElementTypeByContentType(this.elementType as any),
      });
    }
    this.inputLinkBlur();
  }
}
