import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { isEmailValid } from '@app/editor-v3/utils/validators';

@Component({
  selector: 'trakto-panel-email-mobile',
  templateUrl: './panel-email-mobile.component.html',
  styleUrls: ['./panel-email-mobile.component.scss'],
})
export class PanelEmailMobileComponent implements OnInit {
  sender: string = '';
  subject: string = '';
  message: string = '';

  isFormValid: boolean = false;

  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {
    this._fillFields();
  }

  changeSenderValue(sender: string) {
    this.sender = sender;
    this.setIsFormValid();
  }

  changeSubjectValue(subject: string) {
    this.subject = subject;
    this.setIsFormValid();
  }

  changeMessageValue(message: string) {
    this.message = message;
    this.setIsFormValid();
  }

  setIsFormValid() {
    const isSenderValid = isEmailValid(this.sender);
    const isSubjectValid = this.subject.length > 0;
    const isMessageValid = this.message.length > 0;

    this.isFormValid = isSenderValid && isSubjectValid && isMessageValid;
  }

  setEmailLink() {
    if (this.isFormValid)
      this._elementsService.applyEmailLinkToElement(
        this.sender,
        this.subject,
        this.message,
      );
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  private _fillFields() {
    const element = this._elementsService.selectedElement;
    const splittedEmail = (element.email || '').split('?');
    const splittedBody = (splittedEmail[1] || '').split('&');

    const mailTo = (splittedEmail[0] || '').replace('mailto:', '');
    const subject = (splittedBody[0] || '').replace('subject=', '');
    const body = (splittedBody[1] || '').replace('body=', '')
    this.changeSenderValue(mailTo);
    this.changeSubjectValue(decodeURI(subject));
    this.changeMessageValue(decodeURI(body));
  }
}
