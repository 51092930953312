import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    TemplateCampaignPublished,
    TemplateCampaignUnpublished
} from "@app/editor/services/admin-api-white-label.service";
import { MagicTemplateService } from "@app/shared/magic-template/shared/magic-template.service";
import { environment } from "@env/environment";
import { ICampaign, IDocumentModel } from "@trakto/models";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ApiTeamTemplatePublishService {
    constructor(
        private _http: HttpClient,
        private _magicTemplateService: MagicTemplateService,
    ) { }

    publish(template: IDocumentModel, campaignId: string, thumbnail?: string): Observable<TemplateCampaignPublished> {
        const payload = {
            campaign_id: campaignId,
            template_id: template.id,
            template_body: template.body,
            content_tags: this._magicTemplateService.getTagsInDocument(template).map(tag => tag.descriptor.label),
            thumbnail: thumbnail || template.cover.medium
        };
        return this._http.post<TemplateCampaignPublished>(
            `${environment.api.templateUrl}campaigns/template/publish`, payload);
    }

    getTemplate(templateId: string, campaignId: string): Observable<TemplateCampaignPublished> {
        return this._http.get<TemplateCampaignPublished>(
            `${environment.api.templateUrl}campaigns/template/${campaignId}/${templateId}`);
    }

    unpublishTemplate(template: IDocumentModel, campaign: ICampaign): Observable<TemplateCampaignUnpublished> {
        return this._http.get<TemplateCampaignUnpublished>(
            `${environment.api.templateUrl}campaigns/template/unpublish?campaign_id=${campaign.id}&template_id=${template.id}`);
    }
}
