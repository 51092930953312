import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

export interface TKTAnalyticsEvent {
    user_id: string;
    event_type: string;
    event_properties: {};
}

@Injectable({
    providedIn: 'root',
})
export class TKTAnalytics {
    private _apiURL = environment.api.tkAnalytics.url;
    private _user = environment.api.tkAnalytics.user;
    private _pass = environment.api.tkAnalytics.pass;

    constructor(
        private readonly _http: HttpClient,
    ) {}

    track(event: TKTAnalyticsEvent): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${this._apiURL}/v1/event/track`;
                const headers = this._getHeaders();
                const response = await this._http.post(url, event, headers).toPromise();
                resolve(!!response);
            } catch (error) {
                reject(error);
            }
        });
    }

    identify(userId: string, userData: any): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const { user_properties } = userData;
                const url = `${this._apiURL}/v1/user/identify`;
                const headers = this._getHeaders();
                const payload = {
                    user_id: userId,
                    user_properties,
                }
                const response = await this._http.post(url, payload, headers).toPromise();
                resolve(!!response);
            } catch (error) {
                reject(error);
            }
        });
    }

    private _getHeaders() {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this._user + ':' + this._pass),
            },
        };
    }
}