import {
  Component,
  HostBinding,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StorageService } from '@app/shared/storage/storage-service.service';
import { DrawableComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable.component';
import { ImageUtilService } from '@services/image-util.service';
import { ElementModelService } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import { IElementModel, ImageElementModel } from '@trakto/models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-image]',
  template: `
    <svg:clipPath *ngIf="element['clipped'] && shapeElement" [attr.id]="clipId">
      <svg:path
        [attr.d]="shapeElement['path']"
        [attr.transform]="shapeElement.finalMatrixStr"
      ></svg:path>
    </svg:clipPath>

    <svg:image
      [attr.href]="element['href']"
      [attr.width]="element.supportWidth"
      [attr.height]="element.supportHeight"
      [attr.transform]="element.finalMatrixStr"
      [attr.opacity]="element.opacity"
      x="0"
      y="0"
      fill="#000000'"
    ></svg:image>

    <svg
      *ngIf="isLoading()"
      [attr.x]="(element['submask'] ? element['submask'].cx : element.cx) - 50 / zoomRatio"
      [attr.y]="(element['submask'] ? element['submask'].cy : element.cy) - 50 / zoomRatio"
      [attr.width]="100 / zoomRatio"
      [attr.height]="100 / zoomRatio"
      id="trakto_image_element_loader"
      class="not-printable"
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
    >
      <defs>
        <filter id="loaderDropShaddow" x="0" y="0">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <path
        fill="#fff"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        transform="rotate(230.041 50 50)"
        [attr.filter]="loadingClipUrl"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        ></animateTransform>
      </path>
    </svg>
  `,
})
export class DrawableImageComponent
  extends DrawableComponent
  implements OnChanges, OnInit {
  @HostBinding('attr.clip-path')
  public clipUrl: string;
  public loadingClipUrl: string;

  public clipId: string;

  public shapeElement: IElementModel;

  public updateClipId = false;

  constructor(
    private elementService: ElementModelService,
    private imageElementService: ImageElementService,
    private imageUtilService: ImageUtilService,
    private storageService: StorageService
  ) {
    super();
  }

  replaceUrlToCloudfront(item: any): any {

    const element = item;
    const href: string = element['href'];
    const urlFrom = 'https://s3.us-west-2.amazonaws.com/assets-v3.trakto.io';
    const urlTo = 'https://assets.storage.trakto.io';

    if (href.startsWith(urlFrom)) {
      element['href'] = element['href'].replace(urlFrom, urlTo);
      element['raw'] = element['raw'].replace(urlFrom, urlTo);
      element['low'] = element['low'].replace(urlFrom, urlTo);
      element['medium'] = element['medium'].replace(urlFrom, urlTo);
      element['high'] = element['high'].replace(urlFrom, urlTo);
    }

    return element;
  }

  ngOnInit(): void {
    this.element = this.replaceUrlToCloudfront(this.element);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.shapeElement = undefined;
    if (this.elementService.isClip(this.element)) {
      this.updateClipId = !this.updateClipId;
      const proClipId = this.updateClipId ? '-0' : '-1';
      this.clipId = `${this.id}${proClipId}__clippath`;
      this.clipUrl = `url('${this.getHostPath()}#${this.clipId}')`;
      this.loadingClipUrl = `url('${this.getHostPath()}#loaderDropShaddow')`;
      this.shapeElement = this.element['submask'];
    }
  }

  public isLoading(): boolean {
    if (
      this.element['href'].includes('firebasestorage') ||
      this.element['href'].includes('amazonaws') ||
      this.element['href'].includes('trakto.io') ||
      this.imageUtilService.isStaticUrl(this.element as ImageElementModel) ||
      this.storageService.isAllowedCDN((this.element as ImageElementModel).href)
    ) {
      return false;
    }
    return this.element.loading === true || this.element['href'].includes('cloudinary.com');
  }

  private getHostPath(): string {
    return window.location.pathname + window.location.search;
  }
}
