import { isPlatformBrowser } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { TraktoUser } from '@app/auth/shared/auth.model';
import { AuthService } from '@app/auth/shared/auth.service';
import { CohereSupportService } from '@app/editor/services/cohere/cohere.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type TraktUserWithTeam = TraktoUser & {
  team?: { role: string; id: string };
};
@Injectable()
export abstract class BaseComponent implements OnDestroy {
  isGlobalUser: boolean;
  isTeamManager: boolean = false;
  isAdmin: boolean;
  isUser: boolean;
  hasUser: boolean;
  isIframeSdk: boolean;
  currentUser: TraktUserWithTeam;
  cohere = new CohereSupportService(environment.cohereKey);
  _destroy$ = new Subject<void>();

  constructor(
    protected _authService: AuthService,
    private _platformId: string,
  ) {
    this._checkPermissions();
    this._checkEmbeddedEditor();

    if (
      isPlatformBrowser(this._platformId) &&
      environment.externalIntegrations.cohere
    ) {
      this._startCohere(this.currentUser);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private _checkPermissions() {
    this._authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this._destroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.isTeamManager = this.currentUser.team?.role === 'manager';
        switch (user.role.value) {
          case 'global':
          case 'global_designer':
            this.isGlobalUser = true;
            break;
          case 'admin_whitelabel':
          case 'owner_whitelabel':
          case 'designer':
            this.isAdmin = true;
            break;
          case 'comercial':
          case 'user':
            this.isUser = true;
            break;
          default:
            this.isGlobalUser = false;
            this.isUser = true;
            break;
        }

        this.hasUser = true;
      });
  }

  public _checkEmbeddedEditor(): void {
    this.isIframeSdk = window.top !== window.self;
  }

  private _startCohere(user?: any): void {
    if (user && user.id) {
      this.cohere.identifyUser({
        id: user.id,
        displayName: user.firstname || 'Anonymous',
        email: user.email,
      });
    }
  }
}
