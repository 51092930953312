<div class="trakto-dropdown-select">
  <trakto-button
    button-font
    [config]="config.buttonConfig"
    [disabled]="disabled"
    (onClick)="toggle()"
  >
    <span #text>{{ selectedItem?.label }}</span>
    <i class="trakto-icon-arrow-drop-down"></i>
  </trakto-button>

  <div class="trakto-dropdown-select__content" [style.width.px]="config.width">
    <div [style.maxHeight.px]="config.height ? config.height : 300">
      <trakto-dropdown-select-item
        *ngFor="let option of config.options; let i = index"
        [option]="option"
        (click)="change(option.value)"
      >
        {{ option.label }}
      </trakto-dropdown-select-item>
    </div>
  </div>

  <select
    #select
    [(value)]="selected"
    (load)="change($event.target.value)"
    (change)="change($event.target.value)"
    [hidden]="true"
  >
    <option
      *ngFor="let option of config.options; let i = index"
      [value]="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</div>
