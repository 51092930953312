import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MainModule } from './main.module';

import { TraktoDirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '../editor/pipes/pipes.module';
import { ButtonPremiumModule } from './button-premium/button-premium.module';
import { ButtonPreviewModule } from './button-preview/button-preview.module';
import { ButtonToggleModule } from './button-toggle/button-toggle.module';
import { ButtonModule } from './button/button.module';
import { CollapseModule } from './collapse/collapse.module';
import { ColorModule } from './color/color.module';
import { DialogModule } from './dialog/dialog.module';
import {
  DropdownSelectorModule
} from './dropdown-selector/dropdown-selector.module';
import {
  DropdownContentModule
} from './dropdown/dropdown-content/dropdown-content.module';
import {
  DropdownMenuHeaderModule
} from './dropdown/dropdown-menu-header/dropdown-menu-header.module';
import {
  DropdownMenuModule
} from './dropdown/dropdown-menu/dropdown-menu.module';
import {
  DropdownSelectModule
} from './dropdown/dropdown-select/dropdown-select.module';
import { EmptyModule } from './empty/empty.module';
import { FileUploadingModule } from './file-uploading/file-uploading.module';
import { FirebaseClientModule } from './firebase-client/firebase-client.module';
import { CheckboxModule } from './forms/checkbox/checkbox.module';
import { FileModule } from './forms/file/file.module';
import { InputModule } from './forms/input/input.module';
import { RadioModule } from './forms/radio/radio.module';
import { GroupButtonModule } from './group/group-button.module';
import { LayoutModule } from './layouts/layout.module';
import { LightLoaderModule } from './light-loader/light-loader.module';
import { ListModule } from './list/list.module';
import { LoaderModule } from './loader/loader.module';
import { LocalStorageModule } from './local-storage/local-storage.module';
import { MenuNavbarModule } from './menu-navbar/menu-navbar.module';
import {
  ModalConfirmationModule
} from './modal-confirmation/modal-confirmation.module';
import { ModalDocumentsModule } from './modal-documents/modal-documents.module';
import { ModalLoaderModule } from './modal-loader/modal-loader.module';
import { ModalMenuModule } from './modal-menu/modal-menu.module';
import { ModalProgressModule } from './modal-progress/modal-progress.module';
import { ModalSedingModule } from './modal-sending/modal-sending.module';
import { ModalModule } from './modal/modal.module';
import { PopoverModule } from './popover/popover.module';
import { ItemRendererdModule } from './renderers/item-renderer.module';
import { SelectModule } from './select/select.module';

import { SubscriptionModule } from './subscription/subscription.module';
import { TooltipModule } from './tooltip/tooltip.module';
import {
  ModalRemoveBgComponent
} from './modal-remove-bg/modal-remove-bg.component';
import { OnboardingModule } from './onboading/onboarding.module';
import { StepperProgressModule } from './stepper-progress/stepper-progress.module';
import { SelectFieldModule } from './forms-v2/select-field/select-field.module';
import { InputChipFieldModule } from './forms-v2/input-chip-field/input-chip-field.module';
import { InputFieldModule } from './forms-v2/input-field/input-field.module';
import { TemplateTypeButtonModule } from './forms-v2/template-type-button/template-type-button.module';
import { TitleWithLineModule } from './forms-v2/title-with-line/title-with-line.module';
import { UploadDesignModule } from './forms-v2/upload-design/upload-design.module';
import { ExpansionPanelModule } from './forms-v2/expansion-panel/expansion-panel.module';
import { DragDropListModule } from './forms-v2/drag-drop/drag-drop-list.module';

const Modules = [
  MainModule,
  ListModule,
  FileModule,
  InputModule,
  RadioModule,
  ModalModule,
  ColorModule,
  EmptyModule,
  PipesModule,
  ButtonModule,
  LayoutModule,
  LoaderModule,
  DialogModule,
  SelectModule,
  SelectFieldModule,
  TooltipModule,
  PopoverModule,
  CollapseModule,
  CheckboxModule,
  TranslateModule,
  ModalMenuModule,
  GroupButtonModule,
  ModalSedingModule,
  ModalLoaderModule,
  LightLoaderModule,
  ButtonToggleModule,
  ModalProgressModule,
  ModalConfirmationModule,
  DropdownMenuModule,
  DropdownMenuHeaderModule,
  LocalStorageModule,
  SubscriptionModule,
  ButtonPremiumModule,
  ItemRendererdModule,
  ButtonPreviewModule,
  DropdownSelectModule,
  ModalDocumentsModule,
  FirebaseClientModule,
  DropdownContentModule,
  TraktoDirectivesModule,
  DropdownSelectorModule,
  MenuNavbarModule,
  FileUploadingModule,
  OnboardingModule,
  StepperProgressModule,
  InputChipFieldModule,
  InputFieldModule,
  TemplateTypeButtonModule,
  TitleWithLineModule,
  UploadDesignModule,
  ExpansionPanelModule,
  DragDropListModule,
];

@NgModule({
  imports: Modules,
  exports: Modules,
  declarations: [ModalRemoveBgComponent],
})
export class SharedModule {
  constructor(private translate: TranslateService) {}
}
