<div
  class="trakto-color-item-renderer"
  [ngClass]="classes"
  (click)="select()"
  [style.background]="internalItem.value"
  [style.width.px]="internalItem.size || defaultSize"
  [style.height.px]="internalItem.size || defaultSize"
>
  <i
    class="trakto-icon-plus-circle"
    *ngIf="internalItem.type === 'color-add'"
  ></i>
  <i
    class="trakto-icon-check trakto-color-item-renderer__selected"
    *ngIf="internalItem.value && internalItem.type !== 'color-add' && selected"
    [style.color]="selectedIconColor"
  ></i>
</div>
<span
  class="trakto-color-item-renderer__remove"
  *ngIf="removable"
  (click)="remove()"
  >-</span
>
