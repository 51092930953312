import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../dropdown.model';

@Component({
  selector: 'trakto-dropdown-select-item',
  templateUrl: './dropdown-select-item.component.html',
  styleUrls: ['./dropdown-select-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownSelectItemComponent {
  @Input() option: IDropdownOption;

  constructor() {}
}
