<div class="link" [ngClass]="{ disabled: disabled, active: active }">
  <span>
      <img
        *ngIf="image"
        [src]="'assets/editor-v3/images/' + image + '.svg'"
        class="img"
      />
    <img
        *ngIf="url"
        [src]="url"
        class="img"
      />
      <i *ngIf="icon" [class]="'icon icon-' + icon"></i>
      <ng-content></ng-content>
    </span>
    <span *ngIf="label" class="label">{{ label }}</span>
</div>
