import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../shared/material.module';
import { FormsModule } from '@angular/forms';
import { RenameComponent } from '../rename/rename.component';
import { AutoSaveIndicatorComponent } from '../save-indicator/auto-save-indicator/auto-save-indicator.component';
import { ManualSaveIndicatorComponent } from '../save-indicator/manual-save-indicator/manual-save-indicator.component';
import { ShareComponent } from '../share/share.component';
import { HeaderComponent } from './header.component';

import { ModalShareModule } from '../share/modal-share.module';
import { LocaleSelectorModule } from './locale-selector/locale-selector.module';

import {
  SidebarModule,
  SupportButtonModule,
  SupportListItemModule,
} from '@trakto/support-sidebar';
import { KmlModalModule } from '@trakto/trakto-kamaleon';
import { PromoBannerComponent } from './promo-banner/promo-banner.component';
import { TraktoLinkModalModule } from '../trakto-link-modal/trakto-link-modal.module';
import { HeaderV2Component } from './header-v2/header-v2.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    KmlModalModule,
    ModalShareModule,
    LocaleSelectorModule,
    SidebarModule,
    FormsModule,
    SupportButtonModule,
    SupportListItemModule,
    TraktoLinkModalModule
  ],
  entryComponents: [ShareComponent, RenameComponent],
  declarations: [
    HeaderComponent,
    ShareComponent,
    RenameComponent,
    AutoSaveIndicatorComponent,
    ManualSaveIndicatorComponent,
    PromoBannerComponent,
    HeaderV2Component,
  ],
  exports: [
    HeaderComponent,
    ShareComponent,
    RenameComponent,
    HeaderV2Component,
    AutoSaveIndicatorComponent,
    ManualSaveIndicatorComponent,
  ],
})
export class HeaderModule {}
