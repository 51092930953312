import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { idUserSnapEnum } from '@trakto/models';

@Injectable({
  providedIn: 'root',
})
export class UserSnap {
  private _apiKey: string;

  constructor() {}

  public get apiKey(): string {
    if (!this._apiKey) {
      throw new Error(`ApiKey isn't declared`);
    }
    return this._apiKey;
  }

  public initialize(apiKey: string, userData: any) {
    this._apiKey = apiKey;
    const tagScript = document.createElement('script');
    tagScript.innerHTML = `
      window.onUsersnapCXLoad = function(api) {
          api.init({
            button: null,
            user: {
              userId: '${userData.id}',
              firstname: '${userData.firstname}',
              email: '${userData.email}',
            },
          });
          window.Usersnap = api;
      };
      var script = document.createElement('script');
      script.defer = 1;
      script.src =
      'https://widget.usersnap.com/global/load/${apiKey}?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);
    `;
    // document.body.appendChild(tagScript);
    document.getElementsByTagName('head')[0].appendChild(tagScript);
  }

  public hide() {
    const userSnap = this._getInstance();
    userSnap.hide(this._apiKey);
  }

  private _getInstance(): any {
    const userSnap = window['Usersnap'];
    return userSnap;
  }

  public identifyUser(email: string, userID: string) {
    setTimeout(() => {
      const userSnap = this._getInstance();
      userSnap.on('open', (event: any) => {
        event.api.setValue('custom', { userID, email });
      });
    }, 3000);
  }

  public setCustomAttributes(attributes: any) {
    const userSnap = this._getInstance();
    userSnap.setValue(`custom`, { ...attributes });
  }

  public async showReviewModal(lang: string = environment.defaultLocale) {
    try {
      await this._showReviewModal(lang);
    } catch (e) {
      console.log('snap error', e);
    }
  }

  private _showReviewModal(lang: string) {
    const { keyEditorStarReview } = environment.api.userSnap;
    const apiKey =
      keyEditorStarReview[lang] ||
      keyEditorStarReview[environment.defaultLocale];
    const userSnap = this._getInstance();
    const isReviewMarked =
      localStorage.getItem('editor-star-review') === apiKey;

    if (!isReviewMarked) {
      userSnap.show(apiKey).then(wid => {
        wid.open();
        wid.on('submit', () => {
          if (!isReviewMarked) {
            localStorage.setItem('editor-star-review', apiKey);
          }
        });
      });
    }
  }

  public async showWidget(
    idEnum: idUserSnapEnum,
    lang: string = environment.defaultLocale,
  ) {
    const userSnap = this._getInstance();
    const id =
      environment.api.userSnap[idEnum][lang] ||
      environment.api.userSnap[idEnum][environment.defaultLocale];
    userSnap?.show(id).then(wid => wid.open());
  }
}
