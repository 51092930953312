import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Observer, of } from 'rxjs';

import { TraktoUser } from '../../auth/shared/auth.model';
import { FirebaseService } from '../firebase-client/firebase.service';
import { extensions, IFile } from './models/file';

import { mediaProviderEnum } from '@trakto/models';

export enum EnumCDNJustOnly {
  FIREBASESTORAGE = 'FIREBASESTORAGE',
  CLOUDINARY = 'CLOUDINARY',
  AMAZONAWS = 'AMAZONAWS',
  TRAKTO_ASSETS = 'TRAKTO_ASSETS',
}

export const AMAZON_IMAGE_BUCKET = 'https://trakto-elements.s3.us-west-2.amazonaws.com';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private readonly fbs: FirebaseService) {}

  private get _uploadFile(): (data: any) => Observable<any> {
    return this.fbs.callable('uploadFile');
  }

  private get _uploadFile2(): (data: any) => Observable<any> {
    return this.fbs.callable('uploadFile2');
  }

  private get _syncFile(): (data: any) => Observable<any> {
    return this.fbs.callable('syncFile');
  }

  private get _cloneFile(): (data: IFile) => Observable<IFile> {
    return this.fbs.callable('cloneFile');
  }

  private get _deleteFile(): (data: IFile) => Observable<IFile> {
    return this.fbs.callable('deleteFile');
  }

  private get _downloadFile(): (data: IFile) => Observable<IFile> {
    return this.fbs.callable('downloadFile');
  }

  private get _listFiles(): (directory: any) => Observable<any> {
    return this.fbs.callable('listFiles');
  }

  public uploadFile(file: any): Promise<any> {
    return this._uploadFile(file).toPromise();
  }

  public uploadFile2(file: any): Promise<any> {
    return this._uploadFile2(file).toPromise();
  }

  public syncFile(file: any): Promise<any> {
    return this._syncFile(file).toPromise();
  }

  public cloneFile(file: IFile): Promise<IFile> {
    return this._cloneFile(file).toPromise();
  }

  public deleteFile(file: IFile): Promise<IFile> {
    return this._deleteFile(file).toPromise();
  }

  public downloadFile(file: IFile): Promise<IFile> {
    return this._downloadFile(file).toPromise();
  }

  public listFiles(directory: string): Promise<any> {
    return this._listFiles({ directory }).toPromise();
  }

  public getFileInstanceByUrl(
    user: TraktoUser,
    url: string,
    provider: string,
    isThumbnail?: boolean,
  ): IFile {
    const extension = this.getExtension(url);
    const userId: string = user && user.id ? user.id : environment.freeUserId;

    return {
      filename: 'i',
      provider,
      extension: extension.replace('.', ''),
      directory: 'my_gallery/' + userId,
      isThumbnail,
      url,
      user_reference: userId,
    };
  }

  public getExtension(url: string): string {
    let extension = url.substring(url.lastIndexOf('.'), url.length);
    if (extensions.indexOf(extension + '.') === -1) {
      extension = extensions.filter(row => url.indexOf(row) >= 0)[0];
    }
    return extension ? extension.replace('.', '') : '';
  }

  public getFileInstanceByBase64(
    user: TraktoUser,
    base64: string,
    filename: string,
    extension: string,
    provider: string,
    isThumbnail?: boolean,
  ): IFile {
    const userId: string = user && user.id ? user.id : environment.freeUserId;

    return {
      filename,
      provider,
      extension,
      directory: 'my_gallery/' + userId,
      isThumbnail,
      base64,
      user_reference: userId,
    };
  }

  public isAsset(url: string): boolean {
    return url.indexOf('assets') >= 0;
  }

  public isBase64(url: string): boolean {
    return url.indexOf('base64') >= 0;
  }

  public downloadFromUrl(
    url: string,
    provider: string = mediaProviderEnum.external,
    user: TraktoUser,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.downloadFile(
        this.getFileInstanceByUrl(
          user,
          url,
          provider,
          this.getExtension(url) !== 'gif',
        ),
      )
        .then((resultFile: any) => resolve(resultFile))
        .catch(e => reject(e));
    });
  }

  updateFirestoreCacheControl(url): Observable<any> {
    if (!url || url.indexOf('firebasestorage') === -1) {
      return of(null);
    }
    return new Observable((observer: Observer<any>) => {
      const storageRef = this.fbs.storage.storage.refFromURL(url);
      storageRef.getMetadata().then(result => {
        if (!result.cacheControl) {
          const newMetadata = { cacheControl: 'private,max-age=3000' };
          storageRef.updateMetadata(newMetadata).then();
        }
        observer.next(result);
        observer.complete();
      });
    });
  }

  /**
   * Return if url is from cloudinary or firebasestorage
   * @param url String with image url
   * @param only EnumJustOnly: FIREBASESTORAGE or CLOUDINARY
   */
  public isAllowedCDN(url: string, only?: EnumCDNJustOnly) {
    if (only === EnumCDNJustOnly.FIREBASESTORAGE) {
      return url.includes('firebasestorage');
    }

    if (only === EnumCDNJustOnly.AMAZONAWS) {
      return url.includes('amazonaws');
    }

    if (only === EnumCDNJustOnly.TRAKTO_ASSETS) {
      return url.includes('trakto.io');
    }

    return url.includes('firebasestorage') || url.includes('amazonaws') || url.includes('trakto.io');
  }
}
