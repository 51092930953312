import { Routes } from '@angular/router';

import { MobilePresentationComponent } from '@app/mobile-presentation/mobile-presentation.component';
import { AppAuthComponent } from './auth/app-auth/app-auth.component';
import { CookieGuard } from './auth/shared/cookie.guard';
import {
  DesignCloneComponent
} from './editor/components/design-clone/design-clone.component';
import { EditorComponent } from './editor/editor.component';
import { EditorV3Component } from './editor-v3/editor-v3.component';
import { EmbedComponent } from './embed/embed.component';
import { PresentationComponent } from './presentation/presentation.component';
import { PreviewDocComponent } from './preview/preview.doc.component';
import { AppAuthV3ComponentComponent } from './auth/app-auth-v3-component/app-auth-v3-component.component';
import { EmbedIntegrationGuard } from './auth/shared/embed-integration.guard';

export const ROUTES: Routes = [
  {
    path: 'editor',
    children: [
      {
        path: 'v3/:document',
        component: EditorV3Component,
        canActivate: [CookieGuard],
      },
      {
        path: ':document',
        component: EditorComponent,
        canActivate: [CookieGuard],
      },
      {
        path: ':document/:access_token',
        component: EditorComponent,
        canActivate: [CookieGuard],
      },
      {
        path: 'dropbox/:document/:integrationToken/:fileId',
        pathMatch: 'full',
        component: EditorComponent,
        canActivate: [CookieGuard],
      },
      {
        path: 'embedded/:document',
        component: EditorComponent,
        canActivate: [CookieGuard],
      },
    ],
  },
  {
    path: 'app-auth',
    component: AppAuthComponent,
  },
  {
    path: 'app-auth-v3',
    component: AppAuthV3ComponentComponent,
  },
  {
    path: 'embedded',
    children: [
      {
        path: 'auth',
        component: EmbedComponent,
      },
      {
        path: 'imagine/:document',
        component: EditorComponent,
        canActivate: [EmbedIntegrationGuard],
        data: {
          embedded: true,
        },
      },
      {
        path: 'legacy/:document',
        component: EditorComponent,
        canActivate: [CookieGuard],
        data: {
          embedded: true,
        },
      }
    ]
  },
  {
    path: 'presentation/:slug',
    component: PresentationComponent,
  },
  {
    path: 'presentation/:premium/:slug',
    component: PresentationComponent,
  },
  {
    path: 'mobile-presentation/:slug',
    component: MobilePresentationComponent,
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('./integrations/integrations.module').then(
        m => m.IntegrationsModule,
      ),
  },
  {
    path: 'design/copy/:designId',
    component: DesignCloneComponent,
    canActivate: [CookieGuard],
  },
  {
    path: ':document',
    component: PreviewDocComponent,
  },
];
