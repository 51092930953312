import { Injectable } from '@angular/core';
import { ElementModelFinderService } from '@services/element-model-finder.service';
import { FontsService } from '@services/fonts.service';
import { MediaService } from '@services/media.service';
import { TextService } from '@services/text.service';
import { ColorService } from '@shared/color/services/color.service';
import { TextElementOperations } from '@trakto/core-editor';
import { ITextElementModel } from '@trakto/models';
import { ElementFactoryService } from '@services/element-factory.service';
import { TextEncodedModel } from '@trakto/models/src/shared/stored/document/text.element.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TextElementService extends TextElementOperations {
  constructor(
    private _textService: TextService,
    private _elementFactoryService: ElementFactoryService,
    private _translateService: TranslateService,
    fontsService: FontsService,
    colorService: ColorService,
    mediaService: MediaService,
    elementModelFinderService: ElementModelFinderService,
  ) {
    super(
      fontsService,
      colorService,
      _textService,
      mediaService,
      elementModelFinderService,
    );
  }

  public createTextElementByText(text?: string, fontSize?: number): ITextElementModel {
    const finalLines = this._createLinesByText(
      text || this._translateService.instant('general.placeholder_text_component')
    );
    const selectedFontSize = fontSize || (text ? 14 : 36);
    return this._elementFactoryService.makeTextModel(finalLines, selectedFontSize);
  }

  public refreshTextElement(
    elementText: ITextElementModel,
  ) {
    if (!elementText.textConverted) {
      elementText.textConverted = true;
      this._textService.updateTextEncoded(elementText);
    }
    this.initFontSizeMode(elementText);
  }


  private _createLinesByText(text: string): TextEncodedModel[] {
    const linesParsed = [];
    const lines = text.split(/\n/);
    const numLines = lines.length;

    lines.forEach((lineText: any, index: number) => {
      linesParsed.push({
        text: lineText,
        style: '',
        class: [],
        color: '#000000',
      });
      if (index < numLines - 1) {
        linesParsed.push({
          text: '\n',
          style: '',
          class: [],
          color: '#000000',
        });
      }
    });
    return linesParsed;
  }

}
