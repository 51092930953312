<div class="modal-mobile__overlay"></div>

<section class="modal-mobile">
  <div class="modal-mobile__container">
    <div [ngSwitch]="typeModal">
      <div class="modal-mobile__close" (click)="closeModal()">
        <i class="trakto-icon-close"></i>
      </div>
      <div *ngSwitchCase="'error'" class="modal-mobile__content">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill="#FE4A23" />
          <g clip-path="url(#clip0)">
            <path
              d="M33.5947 31.8379C33.5754 31.8187 33.5601 31.7958 33.5496 31.7706C33.5392 31.7455 33.5338 31.7185 33.5338 31.6913C33.5338 31.664 33.5392 31.637 33.5496 31.6119C33.5601 31.5867 33.5754 31.5639 33.5947 31.5446L41.2689 23.8712C41.5019 23.6379 41.6327 23.3215 41.6324 22.9918C41.6322 22.662 41.501 22.3458 41.2676 22.1128C41.0343 21.8798 40.7179 21.749 40.3881 21.7492C40.0584 21.7495 39.7422 21.8807 39.5092 22.114L31.8358 29.7841C31.8166 29.8034 31.7937 29.8187 31.7685 29.8291C31.7434 29.8396 31.7164 29.845 31.6892 29.845C31.6619 29.845 31.6349 29.8396 31.6098 29.8291C31.5846 29.8187 31.5618 29.8034 31.5425 29.7841L23.8692 22.114C23.7538 21.9986 23.6168 21.907 23.466 21.8445C23.3152 21.782 23.1536 21.7499 22.9904 21.7498C22.6608 21.7497 22.3447 21.8806 22.1115 22.1136C21.8784 22.3467 21.7474 22.6627 21.7473 22.9924C21.7472 23.322 21.8781 23.6381 22.1111 23.8712L29.7837 31.5446C29.803 31.5639 29.8183 31.5867 29.8287 31.6119C29.8391 31.637 29.8445 31.664 29.8445 31.6913C29.8445 31.7185 29.8391 31.7455 29.8287 31.7706C29.8183 31.7958 29.803 31.8187 29.7837 31.8379L22.1111 39.5121C21.9957 39.6275 21.9042 39.7646 21.8418 39.9154C21.7794 40.0662 21.7473 40.2278 21.7473 40.391C21.7474 40.7206 21.8784 41.0367 22.1115 41.2697C22.227 41.3851 22.364 41.4766 22.5148 41.539C22.6656 41.6015 22.8272 41.6336 22.9904 41.6335C23.32 41.6335 23.6361 41.5024 23.8692 41.2693L31.5425 33.5951C31.5618 33.5758 31.5846 33.5605 31.6098 33.5501C31.6349 33.5396 31.6619 33.5343 31.6892 33.5343C31.7164 33.5343 31.7434 33.5396 31.7685 33.5501C31.7937 33.5605 31.8166 33.5758 31.8358 33.5951L39.5092 41.2693C39.7422 41.5024 40.0583 41.6335 40.3879 41.6335C40.7175 41.6336 41.0337 41.5027 41.2668 41.2697C41.4999 41.0367 41.6309 40.7206 41.631 40.391C41.6311 40.0614 41.5002 39.7452 41.2672 39.5121L33.5947 31.8379Z"
              fill="white"
            />
          </g>
        </svg>
        <p>{{ txtError }}</p>
        <button (click)="closeModal()">Ok</button>
      </div>

      <div *ngSwitchCase="'success'" class="modal-mobile__content">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="31.5" fill="#1DC763" stroke="#1DC763" />
          <g clip-path="url(#clip0)">
            <path
              d="M41.1123 21.9853C40.8444 21.7933 40.5113 21.7155 40.1861 21.7691C39.8609 21.8227 39.5703 22.0033 39.3783 22.2712L27.7506 38.4828L23.8692 34.5981C23.6361 34.365 23.32 34.234 22.9904 34.2339C22.6608 34.2338 22.3447 34.3647 22.1115 34.5977C21.8784 34.8307 21.7474 35.1468 21.7473 35.4764C21.7472 35.806 21.8781 36.1222 22.1111 36.3553L27.0281 41.2715C27.1572 41.3957 27.3113 41.4911 27.48 41.5512C27.6488 41.6114 27.8284 41.6349 28.007 41.6204C28.1855 41.6058 28.3589 41.5534 28.5157 41.4667C28.6725 41.38 28.809 41.2609 28.9162 41.1174L41.3981 23.7193C41.5901 23.4515 41.6679 23.1183 41.6143 22.7931C41.5607 22.468 41.3801 22.1774 41.1123 21.9853Z"
              fill="white"
            />
          </g>
        </svg>
        <p>{{ txtSuccess }}</p>
        <button (click)="closeModal()">Ok</button>
      </div>

      <div *ngSwitchCase="'loading'" class="modal-mobile__content">
        <img src="assets/icons/download-mobile.svg" alt="" />
        <p class="modal-mobile__title">{{ txtProgress }}</p>

        <div class="modal-mobile__progress-line"></div>
      </div>
    </div>
  </div>
</section>
