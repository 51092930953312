<iframe
  *ngIf="video"
  width="100%"
  height="100%"
  [src]="video"
  frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
>
</iframe>
