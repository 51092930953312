import { NgModule } from '@angular/core';
import { GalleryGifsService } from './gallery.gifs.service';
import { GalleryIconsService } from './gallery.icons.service';
import { GalleryImageService } from './gallery.images.service';

@NgModule({
    providers:[
        GalleryIconsService,
        GalleryImageService,
        GalleryIconsService,
        GalleryGifsService
    ],
})
export class GalleryModule {
    constructor() {}
}
