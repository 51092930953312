import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SwipeBoxComponent } from './swipe-box.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SwipeBoxComponent],
  exports: [SwipeBoxComponent],
})
export class SwipeBoxModule {}
