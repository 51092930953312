<div class="first_time_modal">
  <button class="btn-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <img src="../../../../assets/images/first-time-share-modal.png" alt="">
  <div class="content">
    <h3>A sua edição foi publicada e agora o seu link está atualizado!😍</h3>
    <div class="input-content">
      <div class="input-link">
        <div class="subdomain">
          <label for="subdomain"><i class="trakto-icon-link-drop"></i>trakto.link/</label>
          <input type="text" placeholder="{{data?.linkWeb}}" id="subdomain" [value]="data?.linkWeb ? data?.linkWeb : data?.link_web"
          (keyup)="onLinkTyping($event)"/>
        </div>
        <div class="trakto-icon-link">
          <img class="trakto-icon-link-edit" *ngIf="link.typing" src="../../../../../assets/icons/edit-modal.svg"
          (click)="selectionRange()" >
          <img *ngIf="!link.typing && !link.success" src="../../../../../assets/icons/info-circle.svg">
          <img *ngIf="!link.typing && link.success" src="../../../../../assets/icons/tick-circle.svg">
        </div>
      </div>
      <button class="btn" (click)="copyLinkToClipboard()" [ngClass]="link.copied === true ? 'copied' : 'copy'">
        {{link.copied === true ? 'Copiado!' : 'Copiar Link' }}
      </button>
    </div>
    <div class="warning">
      <span class="warning-success" *ngIf="!link.typing && link.success">
        <p>Eba! Este domínio está disponível!</p>
      </span>
      <span class="warning-error" *ngIf="!link.typing && !link.success">
        <p>Ops! Esse domínio já está sendo utilizado. Tente outra opção!</p>
      </span>
    </div>
  </div>
</div>
