<ng-container *ngIf="showModal">
<div class="modal">
  <div class="modal_content">
    <div class="header">
      <img src="../../../../../../../assets/icons/templates/desdobramentoTemplates.png" alt="">
      <h3>Redimensionar designs</h3>
      <p>Redimensione seu design para os formatos recomendados pelo Google</p>
    </div>
    <div class="actions">
      <div class="title">
        <h5>Formato automático</h5>
        <p>Selecione as proporções desejadas</p>
      </div>
      <div class="formats" *ngFor="let unfold of unfolds; let i = index">
        <div class="icon_name">
          <img [src]="unfold.icon" alt="" style="margin-right: 8px;">
          <h5>{{unfold.name}}</h5>
        </div>
        <input type="radio" [checked]="unfold.checked" (change)="changeFormarts(i)" style="width: 24px; height: 24px; cursor: pointer;">
      </div>
    </div>
    <div class="btns">
      <button class="cancel" (click)="toggle()">Cancelar</button>
      <button [ngClass]="loading ? 'disabled' : 'done'" (click)="doneUnfoldTemplates()" [disabled]="loading">Redimensionar</button>
    </div>
  </div>
</div>
<div class="overlay" (click)="toggle()"></div>
</ng-container>
