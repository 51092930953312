import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'trakto-grid-flex',
  templateUrl: './grid-flex.component.html',
  styleUrls: ['./grid-flex.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridLayoutFlexComponent {
  constructor() {}
}
