import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgViewerModule } from '@app/shared/svg-viewer/svg-viewer.module';
import { VerticalPageNavComponent } from '@shared/vertical-page-nav/vertical-page-nav.component';

@NgModule({
  imports: [CommonModule, SvgViewerModule],
  declarations: [VerticalPageNavComponent],
  exports: [VerticalPageNavComponent],
  providers: [],
})
export class VerticalPageNavModule {}
