import { Directive, HostListener } from '@angular/core';
import { ScrollListenerService } from '@app/editor/services/scroll-listener.service';

@Directive({
  selector: '[traktoScrollListener]',
})
export class ScrollListenerDirective {
  constructor(private _scrollListenerService: ScrollListenerService) {}

  @HostListener('scroll', ['$event.target'])
  onScroll(ev: HTMLElement) {
    const width = ev.scrollTop;
    this._scrollListenerService.toggleScrollUpVisibility(width >= 700);
  }
}
