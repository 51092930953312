<div class="banner">
  <div
    class="banner__background"
    [ngClass]="{ 'banner__background--show': isPanelExpanded }"
  ></div>
  <p class="banner__description">
    {{ 'sidebar_elements.banner.description' | translate }}
  </p>
  <p class="banner__title">{{ 'sidebar_elements.banner.title' | translate }}</p>
</div>
