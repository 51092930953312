import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
  ],
  exports: [
    CommonModule,
    DragDropModule,
  ],
  declarations: [],
})
export class SharedDragDropModule {}
