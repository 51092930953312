import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { ROUTES } from '@app/app.routes';
import { EmbedModule } from '@app/embed/embed.module';
import {
  MobilePresentationModule
} from '@app/mobile-presentation/mobile-presentation.module';
import { PresentationModule } from '@app/presentation/presentation.module';
import { PreviewDocModule } from '@app/preview/preview.doc.module';
import { AuthModule } from '@auth/auth.module';
import { AuthGuard } from '@auth/shared/auth.guard';
import { AuthService } from '@auth/shared/auth.service';
import { CookieGuard } from '@auth/shared/cookie.guard';
import { EditorModule } from '@editor/editor.module';
import { EditorV3Module } from './editor-v3/editor-v3.module';
import { DateStringPipe } from '@editor/pipes/date-string';
import { BoldspanPipe } from './editor/pipes/boldspan.pipe';
import { ExportService } from '@editor/services/export/export.service';
import { FontsService } from '@editor/services/fonts.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { YoutubeService } from '@editor/services/youtube/youtube.service';
import { environment } from '@env/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AudioService } from '@shared/audio/audio.service';
import { SharedModule } from '@shared/shared.module';
import { SignalsModule } from '@shared/signals/signals.module';
import { SignalsService } from '@shared/signals/signals.service';
import { KmlModalService } from '@trakto/trakto-kamaleon';
import { TraktoPremiumModule } from '@trakto/trakto-premium';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { CohereSupportService } from './editor/services/cohere/cohere.service';
import { UnsplashService } from './editor/services/unsplash.service';
import { IntegrationsModule } from './integrations/integrations.module';
import { GalleryModule } from './editor/services/gallery/gallery.module';
import {
  ElementsGalleryModule
} from './shared/elements-gallery/elements-gallery.module';
import {
  ElementsGalleryService
} from './shared/elements-gallery/elements-gallery.service';
import { OnboardingModule, OnboardingService } from '@trakto/onboarding';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { StoreModule } from '@ngrx/store';
import undoable, { excludeAction } from 'redux-undo';
import { documentReducer } from '@app/state/document.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  ExportUtilModel
} from '@editor/components/preview-2/shared/export-util.model';
import {
  clickElementNoTrackable,
  persistDocumentChangesNoTrackable,
  persistElementsChangesNotTrackable,
  persistPageChangesNoTrackable,
  selectElementNoTrackable,
  selectPageNoTrackable
} from '@app/state/document.actions';
import { EffectsModule } from '@ngrx/effects';
import { EmbedIntegrationGuard } from './auth/shared/embed-integration.guard';
import { AccessTokenInterceptor } from './editor-v3/services/design/unfold-design/access-token-interceptor';
import { TraktoSendGoogleAdsModule } from './trakto-send-google-ads/trakto-send-google-ads.module';

const ROUTE_GUARDS = [
  AuthGuard,
  CookieGuard,
  EmbedIntegrationGuard,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.api.google.firebase),
    RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' }),
    SharedModule,
    GalleryModule,
    EditorModule,
    EditorV3Module,
    AngularFireDatabaseModule,
    AuthModule,
    EmbedModule,
    PreviewDocModule,
    PresentationModule,
    MobilePresentationModule,
    TraktoSendGoogleAdsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DeviceDetectorModule.forRoot(),
    SignalsModule,
    IntegrationsModule,
    TraktoPremiumModule,
    OnboardingModule,
    ElementsGalleryModule,
    StoreModule.forRoot(
      {
        editor: undoable(documentReducer, {
          limit: 10,
          filter: excludeAction([
            persistDocumentChangesNoTrackable.type,
            persistPageChangesNoTrackable.type,
            persistElementsChangesNotTrackable.type,
            selectPageNoTrackable.type,
            selectElementNoTrackable.type,
            clickElementNoTrackable.type,
          ]),
          ignoreInitialState: true,
        }),
      },
      {},
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    ...ROUTE_GUARDS,
    DatePipe,
    DateStringPipe,
    BoldspanPipe,
    HotkeyService,
    FontsService,
    YoutubeService,
    ExportService,
    ExportUtilModel,
    AuthService,
    CookieService,
    SignalsService,
    AudioService,
    KmlModalService,
    CohereSupportService,
    UnsplashService,
    OnboardingService,
    ElementsGalleryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
