import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'trakto-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownMenuItemComponent {
  private _dropdown: any;

  @Input() public set dropdown(dropdown: any) {
    this._dropdown = dropdown;
  }

  public get dropdown(): any {
    return this._dropdown;
  }

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  select(event: MouseEvent): void {
    this.dropdown.close();
    this.onClick.emit(event);
  }
}
