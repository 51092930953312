import { Injectable } from '@angular/core';
import { UtilMagicTemplateService } from '@shared/magic-template/shared/util-magic-template.service';
import { MediaService } from '@services/media.service';
import { ElementModelService } from '@services/element-model.service';
import { ShapeElementService } from '@services/shape-element.service';
import { ShapeMagicTemplateGenerator } from '@trakto/magic-templates';

@Injectable()
export class ShapeMagicTemplateService extends ShapeMagicTemplateGenerator {
  constructor(
    utilMagicTemplateService: UtilMagicTemplateService,
    newShapeService: ShapeElementService,
    newElementService: ElementModelService,
    mediaService: MediaService,
  ) {
    super (
      utilMagicTemplateService,
      newElementService,
      mediaService,
      newShapeService,
    );
  }

}
