import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Positioning } from 'positioning';
import { PopoverOptions } from './popover-options.provider';
import { PopoverWindowOptions } from './popover-window-options.provider';
import { PopoverWindowComponent } from './popover-window.component';

export interface IConfirmCancelEvent {
  clickEvent: MouseEvent;
}

@Directive({
  selector: '[traktoPopover]',
})
export class TraktoPopoverDirective implements OnDestroy, OnChanges, OnInit {
  @Input() popoverTitle: string;
  @Input() popoverMessage: string;
  @Input() confirmText: string;
  @Input() cancelText: string;
  @Input() placement: string;
  @Input() confirmButtonType: string;
  @Input() cancelButtonType: string;
  @Input() focusButton: string;
  @Input() hideConfirmButton: boolean;
  @Input() hideCancelButton: boolean;
  @Input() isDisabled: boolean;
  @Input() isOpen: boolean;
  @Input() customTemplate: TemplateRef<any>;

  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter(true);
  @Output() confirm: EventEmitter<IConfirmCancelEvent> = new EventEmitter();
  @Output() cancel: EventEmitter<IConfirmCancelEvent> = new EventEmitter();

  @Input() popoverClass: string;
  @Input() appendToBody: boolean;
  @Input() reverseButtonOrder: boolean;
  @Input() closeOnOutsideClick: boolean;

  popover: ComponentRef<PopoverWindowComponent>;

  private eventListeners: (() => void)[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private elm: ElementRef,
    private defaultOptions: PopoverOptions,
    private cfr: ComponentFactoryResolver,
    private position: Positioning,
    private renderer: Renderer2,
  ) {
    this.isDisabled = false;
    this.isOpen = false;
  }

  ngOnInit(): void {
    this.isOpenChange.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen) {
      if (changes.isOpen.currentValue === true) {
        this.showPopover();
      } else {
        this.hidePopover();
      }
    }
  }

  ngOnDestroy() {
    this.hidePopover();
  }

  onConfirm(event: IConfirmCancelEvent) {
    this.confirm.emit(event);
    this.hidePopover();
  }

  onCancel(event: IConfirmCancelEvent) {
    this.cancel.emit(event);
    this.hidePopover();
  }

  @HostListener('click')
  togglePopover(): void {
    if (!this.popover) {
      this.showPopover();
    } else {
      this.hidePopover();
    }
  }

  private onDocumentClick(event: Event): void {
    const closeOnOutsideClick =
      typeof this.closeOnOutsideClick !== 'undefined'
        ? this.closeOnOutsideClick
        : this.defaultOptions.closeOnOutsideClick;
    if (
      this.popover &&
      !this.elm.nativeElement.contains(event.target) &&
      !this.popover.location.nativeElement.contains(event.target) &&
      closeOnOutsideClick
    ) {
      this.hidePopover();
    }
  }

  private showPopover(): void {
    if (!this.popover && !this.isDisabled) {
      Promise.resolve().then(() => {
        this.eventListeners = [
          this.renderer.listen('document', 'click', (event: Event) =>
            this.onDocumentClick(event),
          ),
          this.renderer.listen('document', 'touchend', (event: Event) =>
            this.onDocumentClick(event),
          ),
          this.renderer.listen('window', 'resize', () =>
            this.positionPopover(),
          ),
        ];
      });

      const options = new PopoverWindowOptions();
      Object.assign(options, this.defaultOptions, {
        onConfirm: (event: IConfirmCancelEvent): void => {
          this.onConfirm(event);
        },
        onCancel: (event: IConfirmCancelEvent): void => {
          this.onCancel(event);
        },
        onAfterViewInit: (item): void => {
          if (!item) return;
          this.positionPopover();
        },
      });

      const optionalParams: (keyof TraktoPopoverDirective)[] = [
        'confirmText',
        'cancelText',
        'placement',
        'confirmButtonType',
        'cancelButtonType',
        'focusButton',
        'hideConfirmButton',
        'hideCancelButton',
        'popoverClass',
        'appendToBody',
        'customTemplate',
        'reverseButtonOrder',
        'popoverTitle',
        'popoverMessage',
      ];
      optionalParams.forEach(param => {
        if (typeof this[param] !== 'undefined') {
          (options as any)[param] = this[param];
        }
      });

      const componentFactory: ComponentFactory<PopoverWindowComponent> = this.cfr.resolveComponentFactory(
        PopoverWindowComponent,
      );

      // FIXME - Corrigir
      // create is deprecated: from v5 use the
      // new signature Injector.create(options) (deprecation)tslint(1)
      // tslint:disable-next-line: deprecation
      const childInjector = Injector.create(
        [
          {
            provide: PopoverWindowOptions,
            useValue: options,
          },
        ],
        // tslint:disable-next-line: deprecation
        this.viewContainerRef.parentInjector,
      );

      this.popover = this.viewContainerRef.createComponent(
        componentFactory,
        this.viewContainerRef.length,
        childInjector,
      );

      if (options.appendToBody) {
        document.body.appendChild(this.popover.location.nativeElement);
      }
      this.isOpenChange.emit(true);
    }
  }

  private positionPopover(): void {
    if (this.popover) {
      const popoverElement = this.popover.location.nativeElement.children[0];
      const popoverPosition = this.position.positionElements(
        this.elm.nativeElement,
        popoverElement,
        this.placement || this.defaultOptions.placement,
        this.appendToBody || this.defaultOptions.appendToBody,
      );
      this.renderer.setStyle(popoverElement, 'top', `${popoverPosition.top}px`);
      this.renderer.setStyle(
        popoverElement,
        'left',
        `${popoverPosition.left}px`,
      );
    }
  }

  private hidePopover(): void {
    if (this.popover) {
      this.popover.destroy();
      delete this.popover;
      this.isOpenChange.emit(false);
      this.eventListeners.forEach(fn => fn());
      this.eventListeners = [];
    }
  }
}
