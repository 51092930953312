import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnsplashService {

  private apiUrl = 'https://api.vecteezy.com/v1/';
  private apiKey = 'aEi3WnorheDewvmHY2svKkt5';

  constructor(private _http: HttpClient) { }

  resultModel = {
    "page": 0,
    "last_page": 0,
    "per_page": 0,
    "total_resources": 0,
    "resources": [
      {
        "id": 0,
        "content_type": "string",
        "tags": [
          "string"
        ],
        "title": "string",
        "file_types": [
          "string"
        ],
        "thumbnail_dimensions": {
          "width": 0,
          "height": 0
        },
        "thumbnail_url": "string",
        "thumbnail_2x_url": "string",
        "preview_dimensions": {
          "width": 0,
          "height": 0
        },
        "preview_url": "string",
        "preview_2x_url": "string"
      }
    ],
    "message": "string"
  };

  private _randomKeywords = [
    'people',
    'fitness',
    'food',
    'drinks',
    'backgrounds',
    'texture',
    'nature',
    'business',
    'technology',
    'objects',
  ];

  private _unsplashApi = {
    url: 'https://api.unsplash.com',
    accessKey: '60da88acc8cb597340ea660947c3e7427d2ffb13232eadc81371cf6fe999c0cc',
    secretKey: '5784a6579f5fd807beccc6be134ed95ca79f3f26d72cb5928f24bb23bf7cc9f3',
  };

  public async trackPhotoDownloadOld(photoUrl: string) {
    try {
      console.log('GET DATA FROM IMAGEM', photoUrl);
      const unsplashResult = await this._http
        .get(
          `${photoUrl}&client_id=${this._unsplashApi.accessKey}`,
        )
        .toPromise();

      if (unsplashResult) {
        return true;
      }

      throw new Error('Image download was not computed!!');
    } catch (error) {
      throw error.message;
    }
  }

  public async trackPhotoDownload(photoId: number) {
    try {
      const downloadString = `${this.apiUrl}/resources/${photoId}/download?file_type=jpg`;
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        }
      };

      const downloadResult: any = await this._http.get(downloadString, config).toPromise();
      return downloadResult.url;

    } catch (error) {
      console.error('[imagesApi] download photo error ->' + error);
      throw error.message;
    }
  }

  public async searchPhotos(keyword: string, perPage = '10', page = '1', lang?: string) {

    try {
      const searchString = `${this.apiUrl}/resources?term=${keyword}&content_type=photo&page=${page}&per_page=${perPage}&sort_by=relevance`;
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        }
      };

      const searchResult: any = await this._http.get(searchString, config).toPromise();
      const result = await this.formatResultSeach(searchResult);

      return result;

    } catch (error) {
      console.error('[imagesApi] search photos error ->' + error);
      throw error.message;
    }

  }

  private async formatResultSeach(result: any) {
    if (result && result.resources) {
      const resultObj: any = {};

      resultObj.pages = result.last_page;
      resultObj.total = result.total_resources;
      resultObj.results = [];
      resultObj.tags = [];

      for (const photo of result.resources) {

        resultObj.tags = resultObj.tags.concat(photo.tags);
        resultObj.results.push({
          id: photo.id,
          description: photo.title,
          created_at: null,
          width: photo.preview_dimensions.width,
          height: photo.preview_dimensions.height,
          photographer: {
            id: '',
            name: '',
            username: '',
            portfolio_url: '',
            bio: '',
            links: '',
          },
          unsplash_photo_url_download_tracking: '',
          photo_urls: {
            full: photo.preview_2x_url,
            raw: photo.preview_2x_url,
            regular: photo.preview_url,
            small: photo.thumbnail_url,
            thumb: photo.thumbnail_url
          },
          photo_links: {
            download: '',
            download_location: '',
            html: '',
            self: ''
          },
        });
      }

      return resultObj;
    }

    throw new Error('Not found');
  }

  public async searchPhotosOld(
    keyword: string,
    perPage = '10',
    page = '1',
    lang?: string,
  ) {
    try {
      const searchPattern = !keyword ? this._getRandomKeyword() : keyword;

      const unsplashResult: any = await this._http
        .get(
          `${this._unsplashApi.url}/search/photos?query=${searchPattern}&client_id=${this._unsplashApi.accessKey}&per_page=${perPage}&page=${page}`,
          {
            headers: {
              'X-Per-Page': '30',
            },
          },
        )
        .toPromise();

      if (unsplashResult && unsplashResult.results) {
        const resultObj: any = {};

        resultObj.pages = unsplashResult.total_pages;
        resultObj.total = unsplashResult.total;
        resultObj.results = [];
        resultObj.tags = [];

        for (const photo of unsplashResult.results) {
          resultObj.tags = resultObj.tags.concat(photo.tags);

          resultObj.results.push({
            id: photo.id,
            description: photo.description,
            created_at: photo.created_at,
            width: photo.width,
            height: photo.height,
            photographer: {
              id: photo.user.id,
              name: photo.user.name,
              username: photo.user.username,
              portfolio_url: photo.user.portfolio_url,
              bio: photo.user.bio,
              links: photo.user.links,
            },
            unsplash_photo_url_download_tracking: photo.links.download_location,
            photo_urls: photo.urls,
            photo_links: photo.links,
          });
        }

        resultObj.tags = await this._getTranslatedTags(resultObj.tags, lang);

        return resultObj;
      }

      throw new Error('Not found');
    } catch (error) {
      console.error('[imagesApi] Unsplash search photos error ->' + error);
      throw error.message;
    }
  }

  public async searchCollections(keyword: string, perPage = '10', page = '1') {
    try {
      const searchPattern = !keyword ? this._getRandomKeyword() : keyword;

      let unsplashResult: any = await this._http.get(
        `${this._unsplashApi.url}/search/collections?query=${searchPattern}&client_id=${this._unsplashApi.accessKey}&per_page=${perPage}&page${page}`,
        {
          headers: {
            'X-Per-Page': '30',
          },
        },
      );

      unsplashResult = JSON.parse(unsplashResult);

      if (unsplashResult) {
        const resultObj: any = {};

        resultObj.pages = unsplashResult.total_pages;
        resultObj.total = unsplashResult.total;

        resultObj.results = unsplashResult.results.map(collection => {
          return {
            title: collection,
            description: collection.description,
            created_at: collection.created_at,
            published_at: collection.published_at,
            featured: collection.featured,
            total_photos: collection.total_photos,
            photographer: {
              id: collection.user.id,
              name: collection.user.name,
              username: collection.user.username,
              portfolio_url: collection.user.portfolio_url,
              bio: collection.user.bio,
              links: collection.user.links,
            },
            photo_urls: collection.urls,
          };
        });

        return resultObj;
      }

      throw new Error('Not found');
    } catch (error) {
      console.error('[imagesApi] Unsplash search collections error ->' + error);
      throw error.message;
    }
  }

  private _getRandomKeyword(): string {
    const randomIndex = Math.floor(Math.random() * this._randomKeywords.length);

    return this._randomKeywords[randomIndex];
  }

  private async _getTranslatedTags(tags, lang) {
    try {
      if (tags && tags.length) {
        return tags.map(tag => tag.title);
      }
      return [];
    } catch (error) {
      throw error.message;
    }
  }
}
