import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalSendingComponent } from './modal-sending.component';

import {
  KmlCardNotificationModule,
  KmlLoaderModule,
} from '@trakto/trakto-kamaleon';

@NgModule({
  imports: [CommonModule, KmlLoaderModule, KmlCardNotificationModule],
  declarations: [ModalSendingComponent],
  exports: [ModalSendingComponent],
})
export class ModalSedingModule {}
