import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductRepository } from '@app/editor/repository/product.repository';

import { LocaleService } from '@app/editor/services/locale.service';
import { PremiumService } from '@app/editor/services/premium.service';
import { ModalConfirmationComponent } from '@app/shared/modal-confirmation/modal-confirmation.component';

import { ThumbService } from '@app/shared/svg-viewer/shared/thumb.service';
import { TraktoUser } from '@auth/shared/auth.model';
import { AuthService } from '@auth/shared/auth.service';
import { AutoSaveIndicatorComponent } from '@editor/components/save-indicator/auto-save-indicator/auto-save-indicator.component';
import { EditorComponent } from '@editor/editor.component';
import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DocumentManagerService } from '@services/document-manager.service';
import { DocumentService } from '@services/document.service';

import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import {
  TraktoLink,
  TraktoLinkSeo,
  TraktoLinksService,
} from '@services/traktoLinks/trakto-link.service';
import { UserSnap } from '@services/userSnap/userSnap.service';
import { BaseComponent } from '@shared/base/base.component';
import { ModalProgressComponent } from '@shared/modal-progress/modal-progress.component';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { PlanConfigService } from '@shared/subscription/plan-config.service';
import { SubscriptionService } from '@shared/subscription/subscription.service';
import {
  FileQualityEnum,
  fitTypeEnum,
  IAvalableLocales,
  IDocumentModel,
  IDropdownOption,
  IDropdownSelectConfig,
  idUserSnapEnum,
  IElementModel,
  PageModel,
} from '@trakto/models';
import { KmlModalService } from '@trakto/trakto-kamaleon';
import {
  EnumModalComponent,
  SubscriptionService as TraktoPremiumSubscriptionService,
  TraktoPremiumTrackingService,
} from '@trakto/trakto-premium';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAnalyticsService } from '@app/editor/services/analytics/wrapper/user.analytics-wrapper.service';
import { PanelMagicTemplatesComponent } from '../../properties-panel/panel-magic-templates/panel-magic-templates.component';
import { PanelDownloadComponent } from '../../properties-panel/panel-download/panel-download.component';
import { DocumentStateManagerService } from '@services/document-state-manager.service';
import { TraktoPopoverDirective } from '@app/shared/popover/popover.directive';
import { NotificationService } from '@app/shared/notification/notification.service';
import { Store } from '@ngrx/store';
import { TraktoLinkState } from '../../trakto-link-modal/store/trakto-link.reducer';
import * as fromTraktoLink from '../../trakto-link-modal/store/trakto-link.selectors';
import * as fromTraktoLinkActions from '../../trakto-link-modal/store/trakto-link.actions';
import { select } from '@ngrx/store';
import { PanelPublishComponent } from '@editor/components/properties-panel/panel-publish/panel-publish.component';
import { TraktoSendGoogleAdsComponent } from '@app/trakto-send-google-ads/trakto-send-google-ads.component';
import { Acount } from '@app/editor/model/google-ads.interface';
import { GoogleAdsService } from '@app/trakto-send-google-ads/components/google-ads.service';

moment.locale('pt-BR');

const IMAGE_TRAKTO_ORAGE = '/assets/images/logo-trakto-orange.svg';
const IMAGE_TRAKTO_PREMIUM = '/assets/images/logo-premium.svg';
const IMAGE_TRAKTO_HEADER_V2 = '/assets/images/logo-header-v2.svg';

@Component({
  selector: 'trakto-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
})
export class HeaderV2Component
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() editor: EditorComponent;
  @Input() document: IDocumentModel;
  @Input() pageSelected: PageModel;
  @Input() elementSelected: IElementModel;
  @Input() documentId: string;
  @Input() b2c: boolean;

  @ViewChild(AutoSaveIndicatorComponent, { static: true })
  private _autoSaveIndicatorComponent: AutoSaveIndicatorComponent;
  @ViewChild(ModalProgressComponent, { static: true })
  private _modalProgressComponent: ModalProgressComponent;
  @ViewChild(TraktoPopoverDirective) popoverDirective: TraktoPopoverDirective;

  public savingStatus: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private _exportResolutions: FileQualityEnum[];
  private _exportFileType: string;

  public user: TraktoUser;
  public canPublish = false;
  public documentModel: IDocumentModel;

  public supportAvailable = false;
  public shareAsTemplateAvailable = false;
  public shareAsLinkAvailable = false;
  public canCreateLink = false;
  public shareAsPresentation = false;
  public exportAvailable = false;

  public zoomSelected: IDropdownOption;
  public configZoom: IDropdownSelectConfig;
  public zoomIndex: number;
  public logo: string;
  public dashboardURL: string;
  public imageTrakto: string;
  public traktoPremiumLogo: string;
  public loginPage: string;
  public title: string;
  public originalTitle: string;
  public productId: string;
  public documentChangeChecked = false;
  private debounceTimer: any = null;

  public modalOpen: boolean = false;
  public panelOpen: boolean = false;
  public isRequesting: boolean = false;
  public isPremiumUser = true;
  public isAutoSaveProgress = false;
  public isExportThumbProgress = false;
  public renewPlan = false;
  public isEmbeddedInstance = false;
  public isDropboxInstance = false;
  public availableLocales: IAvalableLocales[];
  public currentLang: string;
  public form: FormGroup;
  public focusIcon: boolean;
  public emptyInput = false;

  public translatedTexts: any;
  public isRedoAvailable = true;
  public isUndoAvailable = true;
  private _onHistoryChange: Subscription = null;
  private _subscriptions: Subscription[] = [];
  public trakto_links: any;

  private _isLinksOpen = false;

  public embedDownloadType;
  public embedSaveButtonTitle;
  public saveButtonMessage;

  public isMagicTemplate = false;
  public isLoading: boolean = false;
  public doc: any;
  public documentSucess: any = false;
  public traktoLink: TraktoLink;
  public firstTime: boolean = false;
  public isB2C: boolean;
  public link = {
    status: false,
    url: 'www.trakto.io',
    copied: false,
    loading: false,
    success: false,
    typing: true,
  };
  url: string = 'trakto.link/';
  public traktolink$: Observable<TraktoLink>;

  public refresh_token: string;

  /**
   * get isLinksOpen
   */
  public get isLinksOpen(): boolean {
    return this._isLinksOpen;
  }

  public set isLinksOpen(val: boolean) {
    this._isLinksOpen = val || false;
  }

  constructor(
    public elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private hotkeyService: HotkeyService,
    private documentService: DocumentService,
    private planConfigService: PlanConfigService,
    private dialog: MatDialog,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private signalsService: SignalsService,
    private _panelStackService: PanelStackService,
    private _localeService: LocaleService,
    private _translateService: TranslateService,
    private _thumbService: ThumbService,
    private _subscriptionService: SubscriptionService,
    private _userSnapService: UserSnap,
    private _kmlModal: KmlModalService,
    private _formBuilder: FormBuilder,
    private _traktoLinksService: TraktoLinksService,
    private _productService: WhitelabelProductService,
    private _premiumService: PremiumService,
    private _tktPremiumSubscriptionService: TraktoPremiumSubscriptionService,
    private _checkoutTrackService: TraktoPremiumTrackingService,
    private _productRepository: ProductRepository,
    private _userAnalyticsService: UserAnalyticsService,
    private _alertService: NotificationService,
    @Inject(PLATFORM_ID) platformId: string,
    private cdr: ChangeDetectorRef,
    private _traktoLinkStore: Store<TraktoLinkState>,
    private googleAds: GoogleAdsService,
  ) {
    super(authService, platformId);

    this._productService.featuresAvailable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(features => {
        if (features) {
          this._identifyFeaturesAvailable(features);
        }
      });

    this.currentLang = this._localeService.currentLang;

    this.dashboardURL = environment.externalDashboardPage;
    this.loginPage = environment.externalLoginPage;

    this.zoomIndex = 1;

    this.configZoom = {
      options: [
        { value: fitTypeEnum.w, label: 'Ajustar', selected: false },
        { value: fitTypeEnum.h, label: 'Preenc.', selected: false },
        { value: 25, label: '25%', selected: false },
        { value: 50, label: '50%', selected: false },
        { value: 75, label: '75%', selected: false },
        { value: 100, label: '100%', selected: false },
        { value: 125, label: '125%', selected: false },
        { value: 150, label: '150%', selected: false },
        { value: 200, label: '200%', selected: false },
        { value: 300, label: '300%', selected: false },
      ],
      buttonConfig: {
        width: 100,
        classes: ['button--outline', 'button--white'],
      },
    };

    this.isDropboxInstance = this.authService.isDropboxInstance();

    this._translateService
      .get('modal_download')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(texts => {
        this.translatedTexts = texts;
      });

    this._translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: LangChangeEvent) => {
        this.currentLang = event.lang;
        this.translatedTexts = event.translations['modal_download'];
      });

    this._checkoutTrackService.traktoPremiumTrackEventEmiter
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {});

    this._tktPremiumSubscriptionService.paymentSuccess
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this._subscriptionService.updateUserSubscriptionData(data);
      });
  }

  /**
   * Baseado nas permissões de uso de funcionalidades presentes no produto,
   * define se os componentes que dão acesso a essas features estarão disponíveis
   * @private
   */
  private _identifyFeaturesAvailable(features) {
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.shareAsTemplateAvailable = features[ProductFeatures.SHARE_AS_TEMPLATE];
    this.shareAsLinkAvailable = features[ProductFeatures.LINKS_PREVIEW];
    this.shareAsPresentation = features[ProductFeatures.PRESENTATION_PREVIEW];
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.exportAvailable =
      features[ProductFeatures.CUSTOMER_SUPPORT] ||
      features[ProductFeatures.EXPORT_PDF] ||
      features[ProductFeatures.EXPORT_PNG] ||
      features[ProductFeatures.EXPORT_VIDEO];
  }

  /**
   * Método que retorna permissão para exibir/ocultar a marca de corte.
   * O método existe para prever as regras de funcionalidades específicas
   * por cliente/produto/app
   */
  public canToggleCutMark(): boolean {
    return true;
  }

  ngOnInit(): void {
    this._mapSDKDownloadResolutions();
    this._createEventsSubscribers();
    this._refreshPremiumStatus();
    this._initFormInput();
    this.initSubscriptions();

    this.authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        const { id } = user.current_profile?.product;
        this.isB2C = (id || '') === environment.productIdB2C;
        this.user = user;
      });
    this._userSnapService.showWidget(idUserSnapEnum.templatesRequestWidget);
    this.hotkeyService.addContext('headerkeys', (e: KeyboardEvent) => {
      if (this.hotkeyService.CMD_KEY(e)) {
        if (e.which === KeyEnum.p) {
          this.hotkeyService.stopPropagation(e);
          this.goToPreview();
        } else if (e.which === KeyEnum.s) {
          this.hotkeyService.stopPropagation(e);
          this.manualSave();
        }
      }
    });
    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (!this._documentManagerService.savedChangeLast) {
        setTimeout(async () => {
          await this._thumbService.changeAndPersistThumbnail(this.document);
          this.manualSave(false);
        }, 0);
        event.returnValue = true;
        return true;
      }
    });

    this._shortcutKeyActivatePublicationPanel();
    this.getIsMagicTemplate();

    this.activatedRoute.queryParamMap.subscribe(params => {
      const from = params.get('from');
      if (from && from === 'bella') {
        this.dashboardURL = environment.externalTraktoStudioPage;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked() {
    this.getDocumentTitle();
    if (!this.documentChangeChecked) {
      this.documentChangeChecked = true;
      this.checkDocumentChange();
    }
  }

  getDocumentTitle() {
    if (this.document && !this.title && this.title?.trim() !== '') {
      this.title = this.document?.title || '';
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkDocumentChange();
  }

  checkDocumentChange() {
    this.savingStatus =
      this._documentManagerService.savedChangeLast === false ? false : true;
    this.getIsMagicTemplate();
  }

  getIsMagicTemplate(): void {
    this.isMagicTemplate = this.document?.is_magic ?? this.isMagicTemplate;
  }

  initSubscriptions() {
    this._onHistoryChange = this._documentStateManagerService.allChanges$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((historyState: any) => {
        this.isRedoAvailable = historyState.future.length > 0;
        this.isUndoAvailable = historyState.past.length > 1;
      });

    this._subscriptions.push(this._onHistoryChange);
  }

  unpublish(event) {
    this.savingStatus = false;
    this.popoverDirective.onCancel(event);
  }

  async setAutomaticallyTraktoLink() {
    this.traktolink$ = this._traktoLinkStore.pipe(
      select(fromTraktoLink.selectTraktoLink),
    );

    this.traktolink$.pipe(takeUntil(this._destroy$)).subscribe(link => {
      if (link === undefined || link === null) {
        this.createNewTraktoLink();
        return;
      }
      this.traktoLink = link;
    });
  }

  private async createNewTraktoLink() {
    const createSlug = this.user
      ? (this.user.firstname + new Date()).replace(/\s/g, '')
      : 'SeuNome';
    const traktoLink: TraktoLink = new TraktoLink({
      slug: createSlug.replace(/\:/g, '').split('GMT')[0],
      linkWeb: createSlug.replace(/\:/g, '').split('GMT')[0],
      pausedLink: this.document?.metadata?.pausedLink
        ? this.document?.metadata?.pausedLink
        : false,
      seo: new TraktoLinkSeo({
        thumbUrl:
          this.document?.cover?.low.url || this.document?.thumbs.low.url,
        title: this.document.title,
        description:
          this.translatedTexts?.trakto_links?.seo.default_description,
      }),
    });

    await this._traktoLinksService
      .createMetadataAndPublish(traktoLink, this.isB2C)
      .then(link => {
        this.traktoLink = link;
        this.isLoading = false;
      });
  }

  public _shortcutKeyActivatePublicationPanel(): void {
    setTimeout(() => {
      if (this.isAdmin || this.isGlobalUser) {
        this.hotkeyService.addContext('headerKeys', (e: KeyboardEvent) => {
          if (this.hotkeyService.CMD_KEY(e)) {
            if (e.which === KeyEnum.e) {
              this.openPanelStack('publish');
            }
          }
        });
      }
    }, 2000);
  }

  private _initFormInput(): void {
    this.form = this._formBuilder.group({
      title: [null, [Validators.required]],
    });
  }

  public enableHotkeys(event): void {
    this.focus(event);
    this.hotkeyService.enableHotkeys();
  }

  public focus(event): void {
    event && event.type === 'focus'
      ? (this.focusIcon = true)
      : (this.focusIcon = false);
  }

  private _refreshPremiumStatus() {
    this.authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user: any) => {
        if (!user) {
          Promise.resolve().then(() => (location.href = this.loginPage));
        }

        this._userAnalyticsService.makeIdentifyUser();

        const premiumPlans = await this.planConfigService.getPlanPremium();
        this._checkPaymentStatus();
        const plan = await this.planConfigService.getUserPlanId();
        this.isPremiumUser = premiumPlans.includes(plan);

        this.useOfPremiumTemplate();

        let productId = '';

        if (user.current_profile) {
          const { product } = user.current_profile;

          productId =
            product.id || user.products[0].id || environment.productIdB2C;
        } else {
          productId = user.products[0].id || environment.productIdB2C;
        }

        const productFromDB = await this._productRepository.findById(productId);
        const { logo, id } = productFromDB;

        this.productId = id;
        this.b2c = productFromDB.id === environment.productIdB2C;

        this.logo = this.b2c ? IMAGE_TRAKTO_ORAGE : logo.colored.medium.url;
        this.imageTrakto = this.b2c
          ? IMAGE_TRAKTO_ORAGE
          : logo.colored.medium.url;
        this.traktoPremiumLogo = this.b2c
          ? IMAGE_TRAKTO_HEADER_V2
          : logo.colored.medium.url;

        this.availableLocales = await this._localeService.getAvailableLocales(
          productFromDB,
        );
      });
  }

  /**
   * Função que verifica se o usuário realizou ou não o último pagamento da assinatura
   * @param userID ID do usuário
   */
  private async _checkPaymentStatus() {
    const paymentPaid: any =
      this.planConfigService.getStatusSubscriptionPayment();
    this.renewPlan = paymentPaid;
  }

  public stripePlanRenewalPortal() {
    this.authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user: any) => {
        if (user && !user?.customer_id) {
          return 'Usuário sem customer ID';
        }

        const { customer_id } = user;
        const currentUrl = this.router.url;

        const { portal_url } =
          await this.planConfigService.getSubscriptionPortalUrl(
            customer_id,
            currentUrl,
          );
        return window.open(portal_url, '_blank');
      });
  }

  /**
   * Método que verifica se o plano do usuário permite abrir template premium,
   * caso não permita, deve abrir a modal de template premium
   */
  public async useOfPremiumTemplate() {
    this._documentStateManagerService.document$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (document: any) => {
        if (!document?.is_premium) {
          return this._translateService
            .get('notifications')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(notification => {
              notification.error_check_premium_template;
            });
        }

        const { is_premium } = document as any;

        if (!is_premium) {
          return;
        }

        const hasPermission = await this.planConfigService.getPermissionPromise(
          'use_template_premium',
        );
        const checkoutData = await this._premiumService.getCheckoutConfig();

        if (!hasPermission && !this.authService.isDropboxInstance()) {
          this._premiumService.openModalBlocking(
            EnumModalComponent.MODAL_TEMPLATES,
            checkoutData,
          );
        }
      });
  }

  private _createEventsSubscribers() {
    /**
     * Subscriber for use the document JSON, used for save content
     */
    this._documentStateManagerService.document$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(doc => (this.documentModel = doc));

    /**
     * Subscriber used for create thumbnails for page navigator on Editor and design managment on Dashboard
     */
    this.signalsService.connect(
      enumSignals.ON_DOCUMENT_GENERATE_THUMB,
      event => {
        this.isExportThumbProgress = event.data;
      },
    );

    /**
     * Subscriber used for recognize that status of design save. Action helps export design process
     */
    this.signalsService.connect(enumSignals.ON_AUTO_SAVE_PROGRESS, event => {
      this.isAutoSaveProgress = event.data;
    });

    /**
     * Subscriber used for update view and user profile after subscription changes
     */
    this._subscriptionService.planChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this._refreshPremiumStatus();
      });
  }

  private _mapSDKDownloadResolutions() {
    if (this.isEmbeddedInstance) {
      const resolutionsParam =
        this.activatedRoute.snapshot.queryParamMap.get('resolutions');

      this._exportResolutions = resolutionsParam
        ? (resolutionsParam.split(',') as FileQualityEnum[])
        : [FileQualityEnum.medium];

      this._exportFileType =
        this.activatedRoute.snapshot.queryParamMap.get('filetype') || 'url';
    }
  }

  public openToPreview() {
    const url = environment.documentPreviewURL;
    window.open(
      `${url}/p/${this.document.slug || this.document.firebaseId}`,
      '_blank',
    );
  }

  public manualSave(requestThumbGeneration = true): void {
    this._documentManagerService
      .save(true, requestThumbGeneration)
      .then()
      .catch(err => console.error(err));

    if (this.autoSaveIndicatorComponent) {
      this.autoSaveIndicatorComponent.restart();
    }
  }

  public goToPreview(): void {
    if (this.document.body.length) {
      this.router.navigate(['/editor/preview'], {
        skipLocationChange: true,
        queryParams: { template: JSON.stringify(this.document) },
      });
    } else {
      console.warn('Não existe elementos para a pré-visualização');
    }
  }

  public get autoSaveIndicatorComponent(): AutoSaveIndicatorComponent {
    return this._autoSaveIndicatorComponent;
  }

  public openPanelStack(context: string): any {
    switch (context) {
      case 'download':
        this._panelStackService.closeOthers();
        this._panelStackService.stack(PanelDownloadComponent, {
          inputs: {
            b2c: this.b2c,
            document: this.document,
            pageSelected: this.pageSelected,
            elementSelected: this.elementSelected,
          },
        });
        break;
      case 'publish':
        if (!this._panelStackService.canOpenPanel) {
          this._documentManagerService.forceOpeningElementsPanel();
        } else {
          this._panelStackService.closeOthers();
          this._panelStackService.stack(PanelPublishComponent, {
            inputs: {
              user: this.user,
              document: this.documentModel,
            },
          });
          this._panelStackService.blockPanelOpening();
        }
        break;
      case 'magic_template':
        this._panelStackService.closeOthers();
        this._panelStackService.stack(PanelMagicTemplatesComponent, {
          inputs: {},
        });
        break;
      default:
        break;
    }
  }

  public disablehotkeys() {
    const PREVIEW_KEY_CONTEXT = 'previewkeys';

    if (this.modalOpen) {
      this.hotkeyService.disableHotkeys();
      this.hotkeyService.disableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.disableContext('toolbarkeys');
    } else {
      this.hotkeyService.enableHotkeys();
      this.hotkeyService.enableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.enableContext('toolbarkeys');
    }
  }

  shareModalChange(event) {
    this.modalOpen = event;
  }

  public backToEditor(evt): void {
    if (!this.isEmbeddedInstance) {
      evt.preventDefault();

      const modalRef = this._kmlModal.open(
        ModalConfirmationComponent,
        {
          data: {
            title: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.title',
            )}`,
            description: `${this.getTranslations(
              'trakto_links.modals-confirmation.description-modal',
            )}`,
            buttonPrimary: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.continue-edition',
            )}`,
            buttonSecondary: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.go-to-start',
            )}`,
          },
        },
        { width: '624px', height: '220px' },
      );

      modalRef.afterClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(modalData => {
          if (modalData) {
            window.location.href = `${this.dashboardURL}`;
          }
        });
    }
  }

  public getTranslations(key: string): string {
    let translate = '';
    this._translateService
      .get(key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(texts => (translate = texts));

    return translate;
  }

  public changeTitleDocument() {
    this.document = {
      ...this.document,
      title: this.title,
    };
    this.isRequesting = true;    
    this.documentService
      .save(this.document)
      .then(() => {
        this._documentStateManagerService.persistDocumentChangesNoTrackable(
          this.document,
        );
        this.isRequesting = false;
        this.autoSaveIndicatorComponent.loading = false;
      })
      .catch(err => {
        console.error(err);
        this.isRequesting = false;
        this.autoSaveIndicatorComponent.loading = false;
      });
  }

  public inputChange() {
    this.autoSaveIndicatorComponent.loading = true;
    clearTimeout(this.debounceTimer);
    if (this.document.title !== this.title && this.title.trim() !== '') {
      this.debounceTimer = setTimeout(() => {
        this.changeTitleDocument();
      }, 2000);
    }
  }

  toggleRedo() {
    this._documentStateManagerService.redo();
  }

  toggleUndo() {
    this._documentStateManagerService.undo();
  }

  public disableHotkeys(event): void {
    this.focus(event);
    this.hotkeyService.disableHotkeys();
  }

  /**
   * Obtem a URL de autorização do usuário para utilizar API do google service
   */
  public async getUrlAuthorization() {
    try {
      const response = await this.googleAds.getUrlAuthorizeUser();
      this.autorizationUser(response.auth_url);
    } catch (err) {
      console.log(err);
    }
  }

  // Abre a janela de autorização do Google service
  public autorizationUser(authUrl: string) {
    const authWindow = window.open(authUrl, '_blank', 'width=500,height=600');

    const authInterval = setInterval(() => {
      if (authWindow?.closed) {
        clearInterval(authInterval);
        this.getRefreshToken();
      }
    }, 1000);
  }

  public async getRefreshToken() {
    try {
      const response = await this.googleAds.getRefreshToken();
      this.refresh_token = response.refresh_token;
      this.googleAds.setRefreshToken(response.refresh_token);
      this.openGoogleAdsModal();
    } catch (err) {
      console.log(err);
    }
  }

  validateRefreshToken() {
    const refreshToken = this.googleAds.getLocalRefreshToken();
    if (refreshToken) {
      this.googleAds
        .getAccounts(refreshToken)
        .then(response => {
          if (response?.customers) {
            const accounts = response?.customers;
            this.refresh_token = refreshToken;
            this.openGoogleAdsModal(accounts);
          } else {
            this.getUrlAuthorization();
          }
        })
        .catch(err => {
          this.getUrlAuthorization();
          console.error(err);
        });
    } else {
      this.getUrlAuthorization();
    }
  }

  /**
   * Abre o modal de enviar para Google ADS
   */
  openGoogleAdsModal(accounts?: Acount): void {
    this.dialog.open(TraktoSendGoogleAdsComponent, {
      width: '431px',
      height: '475px',
      data: {
        refresh_token: this.refresh_token,
        accounts: accounts ? accounts : undefined,
        document: this.document,
      },
    } as MatDialogConfig);
  }
}
