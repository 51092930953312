import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { environment } from '@env/environment';
import { traktoLinksPanelsEnum as panelsEnum } from '@trakto/models';
import html2canvas from 'html2canvas';
import { TraktoLinksAnalyticsService } from '@services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-trakto-link-mobile',
  templateUrl: './panel-trakto-link-mobile.component.html',
  styleUrls: ['./panel-trakto-link-mobile.component.scss']
})
export class PanelTraktoLinkMobileComponent implements OnInit, OnDestroy {
  @ViewChild('inputlink') inputLinkElement: ElementRef;
  @ViewChild('qrCodeTemplate') qrCodeTemplate: ElementRef;
  public bellaDisplay: boolean = true;
  public isQRCode = false;
  public panelsEnum = panelsEnum;
  public linkPlaceholder = 'https://trakto.link/';
  public isActive = false;
  public button = {
    copy: {
      disabled: false,
    },
    edit: {
      show: false,
      disabled: false,
    },
  };
  public link = {
    url: '',
    name: '',
    status: panelsEnum.LINK_EMPTY,
    loading: false,
    copied: false,
  };
  private _failedLink() {
    this.link.url = '';
    this.isQRCode = false;
    this.link.status = panelsEnum.LINK_FAILED;
    this.link.loading = false;
  }

  public linkLength = {
    min: 1,
    max: 100,
  };

  public inputValue: string = '';

  private _inputTyping = {
    timer: undefined,
    intervalSecond: 1,
  };

  public published = false;
  public isB2C = false;
  private TRAKTO_LOGO = '';
  public profileImage: any = undefined;
  public downloadLoading = false;
  public continueEdit = false;
  public isLoadingSave = false;
  public hasImageSrc = '';
  public imageSrcPreview: any = '';
  public isLoadingImage = false;
  public _maxUploadImageSize = environment.maxUploadFile || 3.5;
  public hasChangeImage = false;
  public metaTitle = '';
  public metaDescription = '';
  public showModal = false;
  public isLoading = false;
  public isAvaliable = true;
  private _destroy$ = new Subject<void>();


  constructor(
    private _panelService: PanelService,
    private _traktoLinksService: TraktoLinksService,
    private _dataTrackingService: TraktoLinksAnalyticsService,
    private _userService: UserService,
  ) {
    this.isB2C = this._userService.isB2c;

    this.linkPlaceholder = this.isB2C
      ? 'https://trakto.link/'
      : 'https://olink.pro/';
    this.TRAKTO_LOGO = this.isB2C ? environment.static.traktoIsotipo : '';
    this._getUserProfilePicture().then(
      profileImage => (this.profileImage = profileImage),
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this._traktoLinksService.init().then(() => {
      if (this._traktoLinksService.link
        && this._traktoLinksService.linkName
        && this._traktoLinksService.linkName !== '') {
        this.metaTitle = this._traktoLinksService.metaTitle;
        this.metaDescription = this._traktoLinksService.metaDescription;
        this.imageSrcPreview = this._traktoLinksService.metaImage;

        this._successLink(
          this._traktoLinksService.linkName,
          this._traktoLinksService.link,
        );

        this._traktoLinksService.getTraktoLinkFromApi(this._traktoLinksService.linkName).pipe(takeUntil(this._destroy$)).subscribe(
          (data: any) => { },
          (error: any) => {

            if (error.url === this._traktoLinksService.link) {
              this.published = true;
              this.startLink();
            } else {
              this.published = false;
            }
          }
        );

        this.inputValue = this._traktoLinksService.linkName;

        this.button.edit.show = true;
        this.button.edit.disabled = false;
      } else {
        this.button.edit.disabled = true;
        this.button.copy.disabled = true;
      }
    });
  }


  /**
   * Checks whether the image to be uploaded
   * to the bank has not exceeded the maximum limit
   * @p0aram file File
   */
  private async _checksImageUploadSize(file: File) {
    if (file) {
      const fileSize = file.size || 0;
      const fileSizeMb = fileSize / 1024 / 1024;

      if (fileSizeMb <= this._maxUploadImageSize) {
        this._traktoLinksService
          .preparingImageForUpload(file)
          .then(urlImage => {
            this.hasImageSrc = urlImage;
            this.isLoadingImage = false;
          });
      } else {
        this.isLoadingSave = false;
        this.hasImageSrc = '';
        this.imageSrcPreview = '';
      }
    }
  }

  clickButton() {
    document.getElementById('file1').click();
  }

  /**
 * Responsible method of obtaining the
 * image and rendering in the preview.
 * @param event
 */
  public temporaryPreviewImage(event: any): void {
    if (event.target.files) {
      this.isLoadingImage = true;
      this.hasChangeImage = true;
      const targetInput: any = event.target;
      const file: File = targetInput.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrcPreview = reader.result);

      reader.readAsDataURL(file);

      this._checksImageUploadSize(file);
    }
  }

  public removeTemporaryPreviewImage() {
    this.imageSrcPreview = '';
    this.hasChangeImage = true;
  }

  async handleSaveMetaTags() {
    this.isLoadingSave = true;
    this.hasChangeImage = false;

    this.isLoadingSave = false;
    this.publishTraktoLink();
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  activeLink() {
    this.isActive = true;
    this.startLink();
  }

  startLink() {
    this.bellaDisplay = false;
  }

  public async onLinkTyping(event: any) {
    const linkName: string = event.target.value;
    const linkNameLength = linkName.length;
    const charMin = this.linkLength.min;
    const charMax = this.linkLength.max;

    if (!this._isValidKey(event.keyCode)) return false;

    clearTimeout(this._inputTyping.timer);

    if ((linkNameLength > charMin && linkNameLength <= charMax) || linkNameLength === 0) {
      this._inputTyping.timer = setTimeout(async () => {
        this.isAvaliable = await this._traktoLinksService.linkIsAvaliable(linkName);
        if (this.isAvaliable) {
          this.link.url = this.linkPlaceholder + linkName;
          this.link.name = linkName;

        }
        this.link.status = this.isAvaliable ? panelsEnum.LINK_SUCCESS : panelsEnum.LINK_FAILED;
        return this.isAvaliable;
      }, this._inputTyping.intervalSecond * 1000);
    }
  }

  private _isValidKey(keyCode: any) {
    const validFastKeys = [8, 13]; // backspace && enter
    if (validFastKeys.indexOf(keyCode) > -1) {
      return true;
    }

    if (
      !(keyCode > 47 && keyCode < 58) && // numeric (0-9)
      !(keyCode > 64 && keyCode < 91) && // upper alpha (A-Z)
      !(keyCode > 96 && keyCode < 123) && // lower alpha (a-z)
      !(keyCode > 189) // - and _
    ) {
      return false;
    }

    return true;
  }

  private _successLink(linkName, link) {
    this.link.url = link;
    this.link.name = linkName;
    this.isQRCode = true;
    this.link.status = panelsEnum.LINK_SUCCESS;
    this.link.loading = false;
    this.inputValue = linkName;


    this.disableInputLink();
  }

  public continue() {
    this.continueEdit = true;
  }

  public disableInputLink() {
    if (this._traktoLinksService.linkName) {
      this.button.edit.show = true;
      this.button.edit.disabled = false;
    }

    this.button.copy.disabled = false;
  }

  public enableInputLink() {
    this.button.edit.disabled = true;
    this.button.copy.disabled = true;
    setTimeout(() => {
      this.inputLinkElement.nativeElement.focus();
    }, 0);
  }

  public async publishTraktoLink() {
    try {
      this.isLoading = true;
      if (this.published) {
        await this._traktoLinksService.deleteTraktoLink(this._traktoLinksService.linkName);
      }

      const isB2C = this.isB2C;
      await this._traktoLinksService.createMetadataAndPublish({
        slug: this.link.name,
        seo: new TraktoLinkSeo({
          thumbUrl: this.hasImageSrc || this.imageSrcPreview,
          title: this.metaTitle,
          description: this.metaDescription
        })
      }, isB2C);
      this.isLoading = false;
      this.published = true;
    } catch (err) {
      this.isLoading = false;
      this.published = false;
    }
  }

  public copyLinkToClipboard() {
    const elem = document.createElement('input');
    elem.value = `${this.link.url}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 2000);

    this._dataTrackingService.traktoLinksCopiedLink({
      linkName: this.link.url,
    });
  }

  private async _getUserProfilePicture() {
    const profile = this._userService.user.logo?.low?.url;

    if (!profile) return this.TRAKTO_LOGO;
    return await this._convertImageToBase64(profile);
  }

  private _convertImageToBase64(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);

      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  public downloadQRCode() {
    let successDownload = false;
    this.downloadLoading = true;

    html2canvas(this.qrCodeTemplate.nativeElement)
      .then(canvas => {
        document.body.appendChild(canvas);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `qrcode_${this.link.url}.png`;
        a.click();
        (window as any).callDownloadPng(dataUrl);
        successDownload = true;
      })
      .catch(error => {
        successDownload = false;
        console.error('error:', error);
      })
      .finally(() => {
        this._dataTrackingService.traktoLinksDownloadQR({
          link_name: this.link.url,
          success: successDownload,
        });
        setTimeout(() => {
          this.downloadLoading = false;
        }, 500);
      });
  }

  toggle() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.showModal = false;
  }

  async deleteLink() {
    await this._traktoLinksService.deleteTraktoLink(this.link.name);
    this._traktoLinksService.linkName = '';
    this.showModal = false;
    this.bellaDisplay = true;
    this.isActive = false;
    this.published = false;
    this.inputValue = '';
    this.closePanel();
  }
}
