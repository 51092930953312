export enum UnfoldDesignName {
  square = 'Quadrado (1.200px x 1.200px)',
  vertical = 'Vertical (960px x 1.200px)',
  horizontal = 'Horizontal (1.200px x 628px)'
}

export const UNFOLD_CONTENTS = [
  {
    icon: '../../../../../../../assets/icons/templates/quadrado-icon.svg',
    name: UnfoldDesignName['square'],
    checked: false,
    value: {
      "width": 1200,
      "height": 1200
    }
  },
  {
    icon: '../../../../../../../assets/icons/templates/vertical-icon.svg',
    name: UnfoldDesignName['vertical'],
    checked: true,
    value: {
      "width": 960,
      "height": 1200
    }
  },
  {
    icon: '../../../../../../../assets/icons/templates/horizontal-icon.svg',
    name: UnfoldDesignName['horizontal'],
    checked: false,
    value: {
      "width": 1200,
      "height": 628
    }
  }
]
