import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'trakto-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  public data: any[] = [];
  public container: any;
  public emptyResult: boolean;
  public isOpened: boolean;

  private _current: any;
  private _actived: any;
  private _search: boolean;
  private _customBorder: boolean;
  private _miniSelect: boolean;
  private _placeholder: string;

  @ViewChild('inputSearch', { static: true }) inputSearch: ElementRef;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public set search(value: boolean) {
    this._search = value;
  }

  public get search(): boolean {
    return this._search || false;
  }

  @Input() public set active(value: string) {
    if (!value) {
      return;
    }
    this._actived = value.toLocaleLowerCase();
  }

  public get active(): string {
    return this._actived || { label: ' ', value: null };
  }

  @Input() public set placeholder(value: string) {
    this._placeholder = value;
  }

  public get placeholder(): string {
    return this._placeholder;
  }

  public set current(value: any) {
    this._current = value;
    this.onChange.emit(value);
  }

  public get current(): any {
    return this._current || { label: ' ', value: null };
  }

  public get custom(): boolean {
    return this._customBorder;
  }

  @Input() public set custom(value: boolean) {
    this._customBorder = value;
  }

  public get customMini(): boolean {
    return this._miniSelect;
  }

  @Input() public set customMini(value: boolean) {
    this._miniSelect = value;
  }

  public get element() {
    return this._element.nativeElement.children[0];
  }

  @HostListener('document:click', ['$event'])
  out(event: Event) {
    if (
      !this._element.nativeElement.contains(event.target) &&
      this.element.classList.contains('active')
    ) {
      this.toggle();
    }
  }

  constructor(private _element: ElementRef) {
    this.isOpened = false;
    this.emptyResult = false;
  }

  public toggle() {
    this.isOpened = !this.isOpened;

    if (this.inputSearch && !this.isOpened) {
      this.cleanSearch();
    }

    this.element.classList.contains('active')
      ? this.element.classList.remove('active')
      : this.element.classList.add('active');
  }

  public cleanSearch() {
    this.inputSearch.nativeElement.value = '';
    this.sortBySearch(this.inputSearch.nativeElement.value);
  }

  public sortBySearch(value: string) {
    const actives = this.data.filter(item => {
      const option = item.element.nativeElement;
      const optionContainer = option.parentNode;

      if (!optionContainer) {
        return;
      }
      const optgroup = optionContainer.parentNode.parentNode;

      this.container = option.parentNode.classList.contains('scroll-content')
        ? option.parentNode.parentNode
        : optgroup.parentNode.parentNode;

      const parent = optionContainer.classList.contains('scroll-content')
        ? null
        : optionContainer;
      const hasActive = parent
        ? Array.from(optionContainer.children).filter(
            (child: HTMLElement) => !child.hasAttribute('hidden'),
          ).length > 0
        : true;
      const hasOption = item.label.toLowerCase().includes(value.toLowerCase());

      hasActive
        ? optgroup.removeAttribute('hidden')
        : optgroup.setAttribute('hidden', '');
      hasOption
        ? option.removeAttribute('hidden')
        : option.setAttribute('hidden', '');

      return hasOption;
    });

    actives.length > 0
      ? this.container.removeAttribute('hidden')
      : this.container.setAttribute('hidden', '');

    this.emptyResult = actives.length === 0;
  }
}
