import { Injectable } from '@angular/core';
import { ShapeSVGConversionService } from '@shared/svg-viewer/shared/shape-svg-conversion.service';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';
import { ShapeElementService } from '@services/shape-element.service';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import { TextSVGConverter } from '@trakto/svg-converter';

@Injectable()
export class TextSVGConversionService extends TextSVGConverter {
  constructor(
    textElementService: TextElementService,
    textService: TextService,
    shapeService: ShapeElementService,
    shapeConversionService: ShapeSVGConversionService,
    hotkeyService: HotkeyService,
  ) {
    super(
      textElementService,
      textService,
      shapeService,
      shapeConversionService,
      hotkeyService,
    );
  }
}
