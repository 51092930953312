import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'trakto-modal-menu-item',
  templateUrl: './modal-menu-item.component.html',
  styleUrls: ['./modal-menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalMenuItemComponent {
  private _value: any;
  private _itemIdex: number;
  private _active: boolean;

  @Input() customModal: boolean;

  @Input()
  public set active(active: boolean) {
    this._active = active;
  }

  public get active(): boolean {
    return this._active;
  }

  @Input()
  public set value(value: any) {
    this._value = value;
  }

  public get value(): any {
    return this._value;
  }

  @Input()
  public set itemIndex(value: number) {
    this._itemIdex = value;
  }

  public get itemIndex(): number {
    return this._itemIdex;
  }
}
