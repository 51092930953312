import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { ElementsGalleryService } from '@app/shared/elements-gallery/elements-gallery.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import {
  ISearchEvent,
} from '@editor/components/properties-panel/panel-elements/model/model';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { ElementsListComponent } from '../elements-list/elements-list.component';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { ElementNewApiService } from '@app/editor/services/element-new-api.service';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-formats-results',
  templateUrl: './formats-results.component.html',
  styleUrls: ['./formats-results.component.scss'],
})
export class FormatsResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isActive: boolean = false;
  @Input() currentSearch: ISearchEvent;

  @Output() requestClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assetSelected: EventEmitter<IElementNewAPI> =
    new EventEmitter<IElementNewAPI>();

  @ViewChild(ElementsListComponent)
  private _elementsListComponent: ElementsListComponent;

  public universeTags: ILocaleTag[] = [];
  public isSearching = false;
  public currentTag: ILocaleTag;
  public searchType = SearchTypeEnum.FOLDER;
  public isPanelExpanded = false;
  public currentLang = 'pt-BR';
  private _destroy$ = new Subject<void>();

  @ViewChild(SearchbarComponent)
  private _searchbarComponent: SearchbarComponent;

  constructor(
    private _elementsGalleryService: ElementsGalleryService,
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
    private _elementNewApiService: ElementNewApiService
  ) { }

  /**
   * Modifica valor da variável isPanelExpanded
   * quando painel é expandido ou retraído
   */
  ngOnInit(): void {

      this._panelStackService.onExpandPanelChange.pipe(takeUntil(this._destroy$)).subscribe(isExpanded => {
        this.isPanelExpanded = isExpanded;
      });

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = event.lang;
      },
    );
  }

  /**
   * Se painel for exibido, ele obtém a tradução do texto
   * do formato, e o utiliza para obter a lista de elementos
   * e de tags do formato a ser visualizado. Também verifica
   * a tag pesquisada anteriormente, e a aplica na pesquisa atual
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (this._elementsGalleryService.compareChanges(changes.isActive)) {
      this.universeTags = await this._elementNewApiService.getTags(this.currentSearch);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Dentro de todos os elementos do formato em questão,
   * ele filtra LOCALMENTE com base na tag passada.
   * Esse filtro deve ficar por aqui mesmo, sendo que a
   * requisição de API pega todos os elementos, a responsabilidade
   * é de filtrar dentro desse universo conforme a demanda.
   *
   * @param tag string - Tag a ser pesquisada
   */
  filterTags(tag: ILocaleTag = null) {
    this.editSearch(tag?.name);
    this.currentTag = tag;
    const type = this.currentSearch.elementType || 'PATH';
    this.currentSearch = {
      ...this.currentSearch,
      elementType: type,
      tags: [tag]
    } as ISearchEvent;
    this._elementsGalleryService.setElementsPanelStore(this.currentSearch);
  }

  /**
   * Captura o evento de pesquisa disparado pela Searchbar,
   * e efetua a pesquisa dentro do universo de elementos
   * a serem visualizados dentro do painel.
   *
   * @param val: string - Texto da tag a ser pesquisada
   * @param type: enumSearchTypes - Local onde a pesquisa está sendo executada
   */
  searchTagInSearchBar({
    tags,
    type = SearchTypeEnum.FOLDER,
  }: ISearchEvent): void {
    if (type === SearchTypeEnum.FOLDER) {
      this.filterTags(tags[0]);
    }
  }

  /**
   * Define se a Searchbar está em foco, estilizando-a
   * e exibindo o overlay abaixo dela.
   *
   * @param val boolean - Se input está focado
   */
  isSearchbarFocused(val: boolean): void {
    this.isSearching = val;
  }

  /**
   * Fecha o painel de elementos por formatos.
   */
  hidePanel() {
    this.requestClose.emit(true);
  }

  /**
   * Chama o evento para inserir o elemento no documento.
   *
   * @param element any - Elemento a ser inserido
   */
  emitElement(element: IElementNewAPI) {
    this.assetSelected.emit(element);
  }

  editSearch(query = null) {
    this._searchbarComponent.editSearch(query);
  }

  scrollToNextPage() {
    this._elementsListComponent.scrollToNextPage();
  }
}
