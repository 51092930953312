import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragDropDirective } from '../directives/dragdrop.directive';

import { FileUploadingComponent } from './file-uploading.component';

@NgModule({
  declarations: [FileUploadingComponent, DragDropDirective],
  imports: [CommonModule],
  exports: [FileUploadingComponent],
})
export class FileUploadingModule {}
