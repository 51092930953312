import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-format-card',
  templateUrl: './format-card.component.html',
  styleUrls: ['./format-card.component.scss'],
})
export class FormatCardComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() width = 85;
  @Input() selected = false;
}
