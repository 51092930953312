<ng-container *ngFor="let drawable of visibleElements; trackBy: trackByFn">
  <svg:a
    *ngIf="drawable.hasLink || drawable.hasWhatsapp || drawable.hasEmail"
    [attr.xlink:href]="
      drawable.element['link'] ||
      drawable.element['whatsapp'] ||
      drawable.element['email'] ||
      drawable.element['href']
    "
    [attr.xlink:show]="drawable.element['show']"
    [attr.target]="drawable.element['target']"
  >
    <ng-container
      *ngTemplateOutlet="renderElement; context: { drawable: drawable }"
    ></ng-container>
  </svg:a>
  <ng-container
    *ngIf="!drawable.hasLink && !drawable.hasWhatsapp && !drawable.hasEmail"
  >
    <ng-container
      *ngTemplateOutlet="renderElement; context: { drawable: drawable }"
    ></ng-container>
  </ng-container>
</ng-container>

<!-- the filters are added here -->
<svg:defs></svg:defs>

<svg:g
  *ngIf="
    hasWaterMask &&
    isB2C &&
    !isDropboxProduct &&
    !isEmbedInstance &&
    !isPresentation
  "
  [page]="page"
  [ref]="visibleElements"
  [zoomRatio]="zoomRatio"
  drawable-badge
></svg:g>

<ng-template #renderElement let-metaElement="drawable">
  <svg:g
    [traktoSvgFilter]="metaElement.element.filter"
    [attr.id]="metaElement.element.id + '__container'"
  >
    <g
      *ngIf="
        metaElement.element.type === 'image' ||
        metaElement.element.type === 'gif'
      "
      drawable-image
      [element]="metaElement.element"
      [zoomRatio]="zoomRatio"
      [ref]="
        (moving || resizing) && safari
          ? metaElement.element.finalMatrixStr
          : visibleElements
      "
    ></g>

    <g
      *ngIf="metaElement.element.type === 'text'"
      drawable-text
      [element]="metaElement.element"
      [zoomRatio]="zoomRatio"
      [hiddenText]="metaElement.element === selectedTextElement"
      [ref]="visibleElements"
      [ref1]="metaElement.element.fontSize + metaElement.element.lineSpace"
    ></g>

    <g
      *ngIf="metaElement.element.type === 'shape'"
      drawable-shape
      [element]="metaElement.element"
      [zoomRatio]="zoomRatio"
      [ref]="visibleElements"
    ></g>

    <g
      *ngIf="metaElement.element.type === 'youtube'"
      drawable-youtube
      [element]="metaElement.element"
      [zoomRatio]="zoomRatio"
      [ref]="visibleElements"
      (clickedVideo)="clickedVideo.emit(metaElement)"
    ></g>

    <g
      *ngIf="metaElement.element.type === 'emoji'"
      drawable-gif
      [element]="metaElement.element"
      [zoomRatio]="zoomRatio"
      [ref]="visibleElements"
    ></g>
  </svg:g>
</ng-template>
