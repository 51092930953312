import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@app/editor/services/theme.service';

@Component({
  selector: 'trakto-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss'],
})
export class SeeMoreComponent implements OnInit {
  primaryColor = '#0079ff';

  constructor(private _themeService: ThemeService) {}

  ngOnInit(): void {
    const themePrimaryColor = this._themeService.getTheme().color.primary;

    if (themePrimaryColor) this.primaryColor = themePrimaryColor;
  }
}
