import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, HistoryState } from '@app/state/document.reducer';
import { IElementModel, PageModel } from '@trakto/models';
import { Drawable } from '@app/editor/components/preview-2/svg-editor/shared/drawable.model';

export const selectEditorState = createFeatureSelector<Readonly<HistoryState>>('editor');

export const selectPresentEditorState = createSelector(
  selectEditorState,
  (state: HistoryState): EditorState => state.present
);

export const selectSelectedDocument = createSelector(
  selectEditorState,
  (state: HistoryState) => state.present.document
);

export const selectSelectedPage = createSelector(
  selectEditorState,
  (state: HistoryState): PageModel => state.present.selectedPage
);

export const selectSelectedElement = createSelector(
  selectEditorState,
  (state: HistoryState): IElementModel => state.present.selectedElement
);

export const selectClickedElement = createSelector(
  selectEditorState,
  (state: HistoryState): IElementModel => state.present.clickedElement
);

export const selectHighlightElement = createSelector(
  selectEditorState,
  (state: HistoryState): Drawable => state.present.highlightElement
);

export const selectUndo = createSelector(
  selectEditorState,
  (state: HistoryState): EditorState => state.present
);
