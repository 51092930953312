<button
  class="button"
  [ngClass]="{ 'button-content__wrapper': hasLoader }"
  (click)="press($event)"
  [style.width.px]="config?.width"
  [style.height.px]="config?.height"
  [type]="type"
>
  <ng-content></ng-content>

  <div class="button__spinner" *ngIf="isLoading">
    <div
      style.borderColor="{{ loaderColor }} transparent"
      [style.width.px]="loaderSize"
      [style.height.px]="loaderSize"
    ></div>
  </div>
</button>
