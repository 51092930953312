import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';

type IImageTabTypes = 'search' | 'library';

@Component({
  selector: 'trakto-panel-image-mobile',
  templateUrl: './panel-image-mobile.component.html',
  styleUrls: ['./panel-image-mobile.component.scss'],
})
export class PanelImageMobileComponent implements OnInit {
  activeTab: IImageTabTypes = 'search';

  constructor(private _panelService: PanelService) {}

  ngOnInit(): void {}

  setActiveTab(tab: IImageTabTypes) {
    this.activeTab = tab;
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
