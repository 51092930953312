import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuNavbarComponent } from './menu-navbar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MenuNavbarComponent],
  exports: [MenuNavbarComponent],
})
export class MenuNavbarModule {}
