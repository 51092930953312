import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentStep,
  selectTemplateSubType,
  selectTemplateType,
  selectTotalSteps
} from '../store/stepper.selectors';
import {
  decrementCurrentStep,
  incrementCurrentStep,
  setTemplateSubType
} from '../store/stepper.actions';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  IApp,
  IAvalableLocales,
  ICampaign,
  IDocumentModel,
  IElementModel,
  IFormats,
  IProducts,
  ITextElementModel,
  IThemeData,
  MTTag,
  PageModel
} from '@trakto/models';
import { TranslateService } from '@ngx-translate/core';
import { TraktoUser } from '@app/auth/shared/auth.model';
import { environment } from '@env/environment';
import { ProductRepository } from '@app/editor/repository/product.repository';
import { ThemeRepository } from '@app/editor/repository/theme.repository';
import { AppRepository } from '@app/editor/repository/app.repository';
import {
  DocumentManagerService
} from '@app/editor/services/document-manager.service';
import {
  NotificationService
} from '@app/shared/notification/notification.service';
import {
  PageFormatRepository
} from '@app/editor/repository/page-format.repository';
import { combineLatest } from 'rxjs';
import { BaseComponent } from '@app/shared/base/base.component';
import { AuthService } from '@app/auth/shared/auth.service';
import {
  ElementModelService
} from '@app/editor/services/element-model.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { ThumbService } from '@shared/svg-viewer/shared/thumb.service';

import {
  Drawable
} from '@app/editor/components/preview-2/svg-editor/shared/drawable.model';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { CampaignService } from '@app/editor-v3/services/admin/team/campaign/team-campaign.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { TeamTemplateService } from '@app/editor-v3/services/admin/team/template-publish/team-template.service';
import { ApiCampaignService } from '@app/editor-v3/services/admin/team/campaign/api-team-campaign.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const DEFAULT_LANGUAGE = 'pt-BR';


interface ExtendedIDocumentModel extends IDocumentModel {
  expertises: string[];
  campaign: string;
  is_premium: boolean;
}

interface ICampaignModel {
  campaign_id: string
  content_tags: Array<[]>
  from_creative: boolean
  id: string
  published_at: Date
  team_id: string
  thumbnail: string
}

@Component({
  selector: 'trakto-magic-template',
  templateUrl: './magic-template.component.html',
  styleUrls: ['./magic-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MagicTemplateComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('selectField') selectField: ElementRef;

  @Input() user: TraktoUser;

  @Output() onRename: EventEmitter<string> = new EventEmitter<string>();

  public currentStep$ = this._store.select(selectCurrentStep);
  private totalSteps$ = this._store.select(selectTotalSteps);
  public templateType$ = this._store.select(selectTemplateType);
  public templateSubType$ = this._store.select(selectTemplateSubType);

  private currentStep: number;
  private totalSteps: number;
  public form = new FormGroup({});
  public image: string;
  public thumbnailUrl: SafeResourceUrl;
  public isPublic: boolean;
  public isMagic: boolean;
  public document: ExtendedIDocumentModel;
  public apps: IApp[] = [];
  public currentApp: any;
  public products: IProducts[] = [];
  public themes: IThemeData[] = [];
  public formats: IFormats[] = [];
  public availableLocales: IAvalableLocales[] = [];
  public lang = 'pt-BR';
  public isLoading = false;
  public uploadSucceeded = false;
  public isCampaignSelected = false;
  public isNextButtonValid = true;
  public tags: MTTag[] = [];
  public campaigns: ICampaign[] = [];
  public selectdCampaign: ICampaign;
  public magicTemplateOptions = {};
  public mainURL: string;
  public traktoLink: string;
  public shortLink: string;
  public isB2C: boolean;

  private _fileUploading: boolean;
  private notifyMSGs: any;

  public set fileUploading(value: boolean) {
    this._fileUploading = value;
  }

  public get fileUploading(): boolean {
    return this._fileUploading || false;
  }

  public expertises: string[] = [];

  public link = {
    copied: false,
    minlinkLength: 1,
    maxlinkLength: 100,
    success: false,
    typing: true,
  }

  public productLabel: string;
  public localeLabel: string;
  public authorLabel: string;
  public formatLabel: string;
  public titleLabel: string;
  public whichTemplateToUse: string;
  public importDesign: string;
  public submitYourPdfFile: string;
  public designTrakto: string;
  public useArtOpenedInTheEditor: string;
  public correctFile: string;
  public fillInTheFields: string;
  public keywords: string;
  public informTheCampaign: string;
  public campaign: string;
  public defineTheVariableElements: string;
  public checkTheFields: string;
  public publicationCompleted: string;
  public setTagText: string;
  public modalOpen: boolean = false;
  public pageSelected: PageModel;

  constructor(
    _authService: AuthService,
    private _userService: UserService,
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _alertService: NotificationService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _elementModelService: ElementModelService,
    private _campaignService: CampaignService,
    private _thumbService: ThumbService,
    private hotkeyService: HotkeyService,
    private _teamTemplateService: TeamTemplateService,
    private _apiCampaignService: ApiCampaignService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    super(_authService, platformId);
    this.loadTranslations();
    this._userService.getProfile().pipe(takeUntil(this._destroy$)).subscribe(user => {
      this.isB2C = this._userService.isB2c;
      this.user = user;
    })
  }

  ngOnInit() {
    this.getCampaigns();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  highlightedElement(isHover: boolean, highlightedElement: Drawable) {
    this._documentStateManagerService.setHighlightedElement({ hover: isHover, ...highlightedElement });
  }

  loadTranslations(): void {
    this._translateService.get('notifications').pipe(takeUntil(this._destroy$)).subscribe(texts => {
      this.notifyMSGs = texts;
    });
    this._translateService.get('sidebar_publish').pipe(takeUntil(this._destroy$)).subscribe(translations => {
      this.productLabel = translations.label['product'];
      this.localeLabel = translations.label['locale'];
      this.authorLabel = translations.label['author'];
      this.formatLabel = translations.label['format'];
      this.titleLabel = translations.label['template_title'];
      this.whichTemplateToUse = translations['which-template-to-use'];
      this.importDesign = translations['import-design'];
      this.submitYourPdfFile = translations['submit-your-PDF-file'];
      this.designTrakto = translations['design-trakto'];
      this.useArtOpenedInTheEditor = translations['use-art-opened-in-the-editor'];
      this.correctFile = translations['correct-file'];
      this.fillInTheFields = translations['fill-in-the-fields'];
      this.keywords = translations['keywords'];
      this.informTheCampaign = translations['inform-the-campaign'];
      this.campaign = translations['campaign'];
      this.defineTheVariableElements = translations['define-the-variable-elements'];
      this.checkTheFields = translations['check-the-fields'];
      this.publicationCompleted = translations['publication-completed'];
      this.setTagText = translations['set-tag'];
    });
  }

  public getCampaigns() {
    this._campaignService.listCampaigns().pipe(takeUntil(this._destroy$)).subscribe(res => {
      this.campaigns = res.campaigns;
      this.getDocument();
    });
  }

  private async getDocument() {
    const res = await this._documentManagerService.getSelectedDocument();

    if (!res) return;
    this.document = res as ExtendedIDocumentModel;
    this._documentManagerService.getSelectedPage().then(
      (page) => {
        this.pageSelected = page;
      }
    );

    this.initForm();
    this.isValidStep();
    this.getAllElementsByDocument();
    await this.resetFormFromTemplate();
    this.setExpertisesInForm();
    this.switchMagic();
  }

  initForm() {
    this.form = this._formBuilder.group({
      app_reference: [null],
      author: this._formBuilder.group({
        name: [this.document.author.name || null,],
      }),
      campaign: [null, [Validators.required]],
      expertises: [this.document.expertises || null],
      is_premium: [this.document.is_premium || false],
      is_magic: [this.document.is_magic || true],
      locale: [null],
      page_format_reference: [null],
      products: [null],
      published: [this.document.published || true],
      published_at: [this.document.published_at || null],
      tags: this._formBuilder.array([]),
      theme_reference: [null, []],
      title: [this.document.title || null],
      magicTemplate: this._formBuilder.array([])
    });
  }

  private async resetFormFromTemplate() {
    this.form.controls.products.setValue(this.currentUser.products);

    await this._loadAvailableLocales(this.products);
    this.form.controls.locale.setValue(this.document['locale']);

    this.getCampaignByTemplateId();
    this.isCampaignSelected = true;
    this.getTags(this.form.get('campaign')?.value?.id);

    this.setTagsInForm(this.document);

    this.image = this.document.thumbs?.medium || this.document.cover?.medium;
    await this._thumbService.changeThumbnail(this.document)
  }

  private async _loadAvailableLocales(selectedProducts) {
    let locales = [];
    this.availableLocales = [];

    selectedProducts.forEach(product => {
      locales = locales.concat(product.locale.available);

    });
    this.availableLocales = [...new Set(locales)];
  }

  getCampaignByTemplateId() {
    this._teamTemplateService.findCampaignByTemplateId(this.document.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((campaigns: ICampaignModel[]) => {
        if (campaigns) {
          const mostRecentCampaign = this.findMostRecentCampaign(campaigns);
          this.filterCampaign(mostRecentCampaign);
        } else {
          this.form.get('campaign').setValue(undefined);
        }
      });
  }

  findMostRecentCampaign(campaigns: ICampaignModel[]): ICampaignModel | undefined {
    return campaigns.reduce((last, current) => {
      const lastDate = new Date(last.published_at);
      const currentDate = new Date(current.published_at);
      return currentDate > lastDate ? current : last;
    });
  }

  public getTags(campaignId) {
    if (!campaignId) return;
    this._campaignService.getTags(campaignId).pipe(takeUntil(this._destroy$)).subscribe(tags => {
      this.tags = tags;
      this.updateMagicTemplateOptions();
    })
  }

  private getAllElementsByDocument() {
    this.document.body.forEach((page: PageModel) => {
      this._elementModelService
        .getAllElements(page.elements, false, false)
        .forEach(el => {
          this.magicTemplateOptions[el.id] = this.filterTagsForElements(el);
          const cleanedText = (el as ITextElementModel)?.text ? (el as any)?.text.replace(/\n/g, '') : '';
          const formGroup = this._formBuilder.group({
            tags: this._formBuilder.control([]),
            element: [{ ...el, text: cleanedText }],
            pageId: page.id,
          });
          (this.form.get('magicTemplate') as FormArray).push(formGroup);
        });
    });
  }

  filterCampaign(campaign: any) {
    const id = campaign.campaign_id || campaign.id
    this._apiCampaignService.findById(id.toString())
      .pipe(takeUntil(this._destroy$))
      .subscribe(campaign => {
        this.selectedCampaign(campaign);
      })
  }

  filterTagsForElements(element?: IElementModel) {
    return this.tags.filter(tag => tag.elementTarget === element?.type);
  }

  changeTemplateType(templateSubType: 'upload' | 'trakto'): void {
    this._store.dispatch(setTemplateSubType({ templateSubType }));
    this.navigateToNextStep();
  }

  private updateMagicTemplateOptions() {
    const magicControl = this.form.get('magicTemplate') as FormArray;
    magicControl.controls.forEach(control => {
      const elementId = control.get('element').value.id;
      this.magicTemplateOptions[elementId] = this.filterTagsForElements(control.get('element').value);
      control.get('tags').setValue(control.get('element').value?.magicTemplate?.tags);
    });
  }

  selectedCampaign(campaign: ICampaign) {
    this.form.get('campaign').setValue(campaign.id);
    this.getTags(campaign.id);
    this.selectdCampaign = campaign;
  }

  change(control: string, changed: any): void {
    this.isCampaignSelected = !!changed?.value;
    this.form.get(control).setValue(changed?.value ?? null);
  }

  onSelectionTag(event: any, controller: FormControl) {
    const tagFormCtrl = controller.get('tags');
    tagFormCtrl.setValue([]);
    event.value.forEach((tag: MTTag) => {
      tagFormCtrl.setValue(this._formBuilder.control({...tag}));
    });
  }

  shareModalChange(event) {
    this.modalOpen = event;
  }

  uploadAgain() {
    this.uploadSucceeded = false;
  }

  switchMagic(): any {
    this.isMagic = this.form.get('is_magic').value;
  }

  switchToTrakto() {
    this._store.dispatch(setTemplateSubType({ templateSubType: 'trakto' }));
  }

  navigateToBackStep() {
    this._store.dispatch(decrementCurrentStep());
  }

  navigateToNextStep() {
    this._store.dispatch(incrementCurrentStep());
  }

  isValidStep() {
    combineLatest([this.currentStep$, this.totalSteps$, this.form.valueChanges])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([currentStep, totalSteps]) => {
        this.currentStep = currentStep;
        this.totalSteps = totalSteps;
        const isStepValid = this.isCurrentStepValid();

        if (this.currentStep === this.totalSteps) {
          this.isNextButtonValid = this.form.valid;
        } else {
          this.isNextButtonValid = isStepValid;
        }
      });
  }

  isCurrentStepValid(): boolean {
    if (this.currentStep === 3) {
      const tagsArray = this.form.controls.tags.value as any[];
      return tagsArray.length > 0;
    } else if (this.currentStep === 4) {
      return this.form.valid;
    }

    return true;
  }

  close(): void {
    this._documentManagerService.forceOpeningElementsPanel();
  }

  goToBellaAds() {
    window.open(`https://google.trakto.io/dashboard/campaign/${this.selectdCampaign.id}`, "_blank");
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      const hasTag: boolean = this.form.controls['tags'].value.indexOf(value.trim()) !== -1;

      if (!hasTag) {
        const tags = this.form.get('tags') as FormArray;
        tags.push(this._formBuilder.control(value.trim()));
        this.form.patchValue({ tags: tags.value });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as FormArray;

    if (index >= 0) {
      tags.removeAt(index);
      this.form.patchValue({ tags: tags.value });
    }
  }

  public removePrefix(str: string, prefix: string | null = null): string {
    const remove =
      prefix === null ? (this.getActualPrefix() as string) : prefix;

    return str.replace(remove, '');
  }

  loadExpertisesInApp(): void {
    this.setExpertisesInForm();
    this.setTagsInForm(this.document);
  }

  /**
   * verifica se tag está dentro das expertises,
   * e seleciona ela nas áreas de atuação
   */
  setExpertisesInForm(): void {
    let previousExpertises = [];
    this.expertises = this.getActualExpertisesList();

    this.document.tags.forEach((tag: string) => {
      if (this.expertises.indexOf(tag) >= 0) {
        previousExpertises.push(tag);
      }
    });

    this.form.controls.expertises.setValue([...previousExpertises]);
  }

  /**
   * se tags não estiverem dentro das expertises,
   * são adicionadas como tags mesmo no form
   */
  setTagsInForm(template: IDocumentModel): void {
    if (template.tags) {
      let finalTags = [...template.tags];
      const allPrefixes = this.getActualPrefix(true) as string[];

      template.tags.forEach(tag => {
        allPrefixes.forEach(prefix => {
          if (this.expertises.indexOf(this.removePrefix(tag, prefix)) !== -1) {
            const index = finalTags.indexOf(tag);
            if (index > -1) finalTags.splice(index, 1);
          }
        });
      });

      finalTags.forEach(tag => {
        this.getFormData('tags').push(this._formBuilder.control(tag.trim()));
      });
    }
  }

  public copyLinkToClipboard() {
    const elem = document.createElement('input');
    elem.value = `${this.traktoLink}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 3000);
  }

  setCopyTemplateLink() {
    this.mainURL = `${window.location.origin}/design/copy`;
    this.traktoLink = this.mainURL + '/' + this.document.id;

    const base64Identifier = btoa(this.document.id);
    this.shortLink = this.mainURL + '/' + base64Identifier.slice(0, 8);
  }

  redirectToFullLink() {
    window.open(this.traktoLink, '_blank');
  }

  async submit(event: Event) {
    this.form.controls.published_at.setValue(
      this.form.get('published').value ? new Date() : null,
    );
    const values = this.form.value;
    values.available_locales = values.locale;

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    try {
      if (this.form.valid) {
        this.isLoading = true;
        await this._publishOnTeamCampaign(values);
        await this.getDocument();
        const newTitle = this.form.get('title').value;
        this._documentManagerService.rename(newTitle);
        this.onRename.emit(newTitle);
        this.isLoading = false;
      }
    } catch (err) {
      console.log(err);
      this.isLoading = false;
      this._alertService.error(this.notifyMSGs.template_publication_error);
      this.navigateToBackStep();
    }
  }

  private async _publishOnTeamCampaign(values) {
    await this._documentManagerService.saveMagicTemplateInfos(values.magicTemplate);
    const updatedDocument = await this._documentManagerService.getSelectedDocument();

    this.thumbnailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.document.cover.medium);

    const templatePublished = await this._teamTemplateService.publishOnCampaign(
      {
        ...updatedDocument,
        tags: values.tags,
        updated_at: new Date(),
      },
      values.campaign,
      this.document.cover.medium,
    );

    this.setCopyTemplateLink();
    this.document.updated_at = new Date(templatePublished.template_body.updated_at);
  }

  public disablehotkeys() {
    const PREVIEW_KEY_CONTEXT = 'previewkeys';

    if (this.modalOpen) {
      this.hotkeyService.disableHotkeys();
      this.hotkeyService.disableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.disableContext('toolbarkeys');
    } else {
      this.hotkeyService.enableHotkeys();
      this.hotkeyService.enableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.enableContext('toolbarkeys');
    }
  }

  getFormData(name: string): any {
    return this.form.get(name) as FormArray;
  }

  getActualApp(): IApp {
    const actualId = this.form.get('app_reference').value?.id;
    return this.apps.filter(app => app.id === actualId)[0];
  }

  getActualPrefix(all = false): string | string[] {
    const prefixes = {
      [environment.api.WHITELABELS.ifood]: 'iFood: ',
      default: 'Bella: ',
    };

    if (all) return Object.keys(prefixes).map(key => prefixes[key]);

    const actualApp = this.getActualApp();

    return prefixes[actualApp?.id] || prefixes.default;
  }

  getActualExpertisesList(): string[] {
    const actualApp = this.getActualApp();
    const actualPrefix = this.getActualPrefix();

    const expertises = {
      [environment.api.WHITELABELS.ifood]: [
        'Pizzaria',
        'Hamburgueria',
        'Japonesa',
        'Chinesa',
        'Brasileira',
        'Doceria',
        'Padaria',
        'Churrascaria',
        'Lanchonete',
        'Cafeteria',
      ],
      default: [
        'Default',
        'Academia e fitness',
        'Administrador e Analista',
        'Advocacia',
        'Arquitetura e urbanismo',
        'Confeitaria e sorveteria',
        'Coach e palestrante',
        'Criador de conteúdo',
        'Educação e ensino',
        'Engenharia e Imobiliária',
        'Automotivos e seguro',
        'Estética e Cosméticos',
        'Salão de beleza e barbearia',
        'Fisioterapia',
        'Marketing digital',
        'Moda e Vestuário',
        'Nutrição',
        'Odontologia',
        'Pet Shop e veterinária',
        'Religiosos',
        'Restaurantes e alimentos',
        'Saúde e bem-estar',
        'South Summit',
        'Tecnologia',
        'Viva Technology',
        'Trakto Show',
        'Orange',
        'SAP',
        'Cisco',
        'Stellantis',
        'Fujitsu',
        'Decathlon',
        'Valeo',
        'JCDecaux',
        'Madeira Madeira',
        'Quinto Andar',
        'Quinto Andar - Inicial',
        'Google',
        'Magazine Luiza',
        'Madeira Madeira Display',
        'Magazine Luiza Produtos',
        'Nubank',
        'Gran Cursos',
        'Google In Feed',
        'Loja do Mecânico',
        'Livelo',
        'Santander',
        'Quero Passagem',
        'Oi',
        'Gran Cursos Preparatórios',
        'Gran Cursos Superior',
        'Gran Cursos - Pós Graduação',
        'Loja do Mecânico - Frete Grátis',
        'Santillana'
      ],
    };

    return (expertises[actualApp?.id] || expertises.default).map(
      (expertise: string) => actualPrefix + expertise,
    );
  }

  /**
   * Obtem apenas as campanhas de estáticas
   */
  filterStaticCampaigns(): ICampaign[]{
    const result = this.campaigns.filter((campaign) => campaign.is_video === false)
    return result;
  }
}
