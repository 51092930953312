import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'trakto-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollapseComponent implements OnInit, AfterViewInit {
  public elementNative: any;
  public maxHeight: number;

  private _icon: string;
  private _title: string;
  private _active: boolean;
  private _disabled: boolean;

  @ViewChild('body', { static: true }) body;
  @ViewChild('bodyContent', { static: true }) bodyContent;

  @Input()
  public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon() {
    return this._icon || '';
  }

  @Input()
  public set title(title: string) {
    this._title = title;
  }

  public get title() {
    return this._title || '';
  }

  @Input()
  public set active(active: boolean) {
    this._active = active;
  }

  public get active() {
    return this._active || false;
  }

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || false;
  }

  @Output() onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {
    this.maxHeight = 0;
  }

  ngOnInit() {
    this.elementNative = this._elementRef.nativeElement.children[0];

    if (!this.title) {
      throw new Error(
        `Collapse title is required. eg.: <trakto-collapse [title]="myTitle"></trakto-collapse>`,
      );
    }

    if (this.active) {
      this.elementNative.classList.add('--onload-active');
      this.elementNative.classList.add('--overflow');
    }

    if (this.disabled) {
      this.elementNative.classList.add('--disabled');
    }
  }

  ngAfterViewInit() {
    if (this.active) {
      this.maxHeight = this.bodyContent.nativeElement.offsetHeight;
      this.cdRef.detectChanges();
    }
  }

  toggle(): void {
    !this.active ? this.open() : this.close();

    if (this.elementNative.classList.contains('--onload-active')) {
      this.elementNative.classList.remove('--overflow');
      this.elementNative.classList.remove('--onload-active');
    }
  }

  open(): void {
    this.active = true;
    this.elementNative.classList.add('--active');
    this.maxHeight = this.bodyContent.nativeElement.offsetHeight;
    this.onOpen.emit(this.active);

    delay(150);
    Promise.resolve().then(() =>
      this.elementNative.classList.add('--overflow'),
    );
  }

  close(): void {
    this.elementNative.classList.remove('--overflow');

    this.active = false;
    this.elementNative.classList.remove('--active');
    this.maxHeight = 0;
    this.onClose.emit(this.active);
  }
}
