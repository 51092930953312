import { Injectable } from '@angular/core';
import { ElementModelService } from '@services/element-model.service';
import { IElementModel, PageModel } from '@trakto/models';

@Injectable()
export class MediaCacheService {
  private cachedUrls: string[] = [];

  constructor(private elementService: ElementModelService) {}

  public cacheMedias(page: PageModel) {
    this.getMedias(page).forEach(element => {
      this.createHiddenImage(element['href']);
      if (this.elementService.isVideo(element)) {
        this.createHiddenImage(element['thumbUrl']);
      }
    });
  }

  private getMedias(page: PageModel): IElementModel[] {
    const allElements = this.elementService.getAllElements(
      page.elements,
      false,
      false,
    );
    return allElements.filter(
      element =>
        this.elementService.isImage(element) ||
        this.elementService.isVideo(element),
    );
  }

  private createHiddenImage(url: string) {
    if (this.cachedUrls.indexOf(url) !== -1) {
      return;
    }
    const img = document.createElement('img');
    img.setAttribute('src', url);
    img.style.display = 'none';
    document.body.appendChild(img);
    this.cachedUrls.push(url);
  }
}
