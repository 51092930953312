<div *ngIf="elementSelected" id="panel-text" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-toolbar-title"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.text.title' | translate }}
      </h2>
    </div>
  </div>

  <div class="flex flex-1 flex-direction-column overflow-hidden">
    <trakto-panel-actions
      [b2c]="b2c"
      [allowClip]="false"
      [isClipEditable]="false"
      [pageSelected]="pageSelected"
      [elementSelected]="elementSelected"
      [groupContainer]="groupSelected"
      [allowLockElementsByTemplate]="allowLockElementsByTemplate"
    >
    </trakto-panel-actions>

    <div class="panel-scroll__container">
      <div class="panel-background panel-scroll__space">
        <!--@ TEXT -->
        <div class="panel-text__content">
          <div class="panel-text__controls">
            <div [style.width.px]="97" class="margin-right-10">
              <trakto-color-button
                [id]="elementSelected.id"
                [icon]="'trakto-icon-color-fill'"
                [height]="'109px'"
                [color]="selectedColor"
                [content]="
                  'property_panel.text.text_style.color_title' | translate
                "
                (openColorPanel)="openPanelStack('color', $event)"
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isTextEditable
                "
              >
              </trakto-color-button>
            </div>

            <div class="flex-1">
              <div class="panel-text__font" [style.marginBottom.px]="5">
                <trakto-button
                  button--outline
                  button--light
                  [config]="{ height: 35 }"
                  [disabled]="
                    !elementSelected.edit ||
                    elementSelected.lockedByTemplate ||
                    isTextEditable
                  "
                  (onClick)="openPanelStack('font')"
                >
                  {{ selectedFontFamily }}
                </trakto-button>
              </div>

              <div class="flex" [style.marginBottom.px]="10">
                <div
                  class="trakto-input--inline padding-right-10"
                  [style.width.%]="70"
                >
                  <trakto-dropdown-select
                    [config]="dropdownFontWeights"
                    [truncate]="true"
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [(ngModel)]="fontOptionChanged"
                    (onChange)="changeFontOption()"
                  >
                  </trakto-dropdown-select>
                </div>

                <div class="trakto-input--inline" [style.width.%]="30">
                  <trakto-input
                    id="fontSize"
                    name="fontSize"
                    type="number"
                    [ngModel]="
                      elementSelected.fontSize * elementSelected.scaleY
                    "
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    (onBlur)="enableHotkeys()"
                    (onFocus)="disableHotkeys()"
                    (keyup.enter)="$event.target.blur()"
                    (input)="setValueFontSize($event.target.value)"
                  >
                  </trakto-input>
                </div>
              </div>

              <!-- @TEXT ALIGN-->
              <div class="panel-text__alignment flex-space-between">
                <div>
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': isAlignLeft() }"
                    (onClick)="setAlignment('left')"
                  >
                    <i class="trakto-icon-align-left"></i>
                  </trakto-button>
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': isAlignCenter() }"
                    (onClick)="setAlignment('center')"
                  >
                    <i class="trakto-icon-align-center"></i>
                  </trakto-button>
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': isAlignRight() }"
                    (onClick)="setAlignment('right')"
                  >
                    <i class="trakto-icon-align-right"></i>
                  </trakto-button>
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': isAlignJustify() }"
                    (onClick)="setAlignment('justify')"
                  >
                    <i class="trakto-icon-align-justify"></i>
                  </trakto-button>
                </div>

                <div>
                  <trakto-dropdown-content
                    placement="center"
                    icon="trakto-icon-line-height"
                    [width]="175"
                    iconSize="1.35"
                    [button]="{ classes: ['button--large', 'button--light'] }"
                  >
                    <div class="trakto-dropdown__content">
                      <mat-slider
                        #elementLineHeight
                        required
                        min="0.5"
                        max="3"
                        step="0.1"
                        name="lineSpace"
                        placeholder="lineSpace"
                        [disabled]="
                          !elementSelected.edit ||
                          elementSelected.lockedByTemplate ||
                          isTextEditable
                        "
                        [ngModel]="elementSelected.lineSpace"
                        (change)="changeLineHeight($event.value)"
                      >
                      </mat-slider>
                      <span>{{
                        (elementLineHeight.value | number: '1.0-1') === '1.2'
                          ? 'Auto'
                          : (elementLineHeight.value | number: '1.0-1')
                      }}</span>
                    </div>
                  </trakto-dropdown-content>
                </div>

                <div class="trakto-input--inline">
                  <div class="flex">
                    <trakto-button
                      button--large
                      button--light
                      [ngClass]="{ '--active': hasBoldActive }"
                      [disabled]="
                        !bold?.exists || !elementSelected.edit || isTextEditable
                      "
                      (onClick)="toggleBold()"
                    >
                      <i class="trakto-icon-bold"></i>
                    </trakto-button>
                    <trakto-button
                      button--large
                      button--light
                      [ngClass]="{ '--active': hasItalicActive }"
                      [disabled]="
                        !italic?.exists ||
                        !elementSelected.edit ||
                        isTextEditable
                      "
                      (onClick)="toggleItalic()"
                    >
                      <i class="trakto-icon-italic"></i>
                    </trakto-button>
                  </div>
                </div>

                <div *ngIf="false">
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': false }"
                    (onClick)="setFontSizeIncreaseOrDecrease('minus')"
                  >
                    <i
                      [style.fontSize.px]="20"
                      class="trakto-icon-text-lower"
                    ></i>
                  </trakto-button>
                  <trakto-button
                    button--large
                    button--light
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isTextEditable
                    "
                    [ngClass]="{ '--active': false }"
                    (onClick)="setFontSizeIncreaseOrDecrease('plus')"
                  >
                    <i
                      [style.fontSize.px]="26"
                      class="trakto-icon-text-plus"
                    ></i>
                  </trakto-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--@ EFFECT -->
        <div class="panel__effects">
          <h2 class="panel__title">
            <i class="trakto-icon-icon-effect"></i>
            {{ 'property_panel.text.effects.title' | translate }}
          </h2>

          <div class="panel-filter">
            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isTextEditable
                "
                (onClick)="setFilter('empty')"
              >
                <i class="trakto-icon-empty"></i>
                <span>{{
                  'property_panel.text.effects.types.no_filter' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isTextEditable
                "
                (onClick)="setFilter('textblur')"
              >
                <i class="trakto-icon-filter-blur"></i>
                <span>{{
                  'property_panel.text.effects.types.blur' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isTextEditable
                "
                (onClick)="setFilter('dropshadow')"
              >
                <i class="trakto-icon-filter-shadow"></i>
                <span>{{
                  'property_panel.text.effects.types.shadow' | translate
                }}</span>
              </trakto-button>
            </div>
          </div>
        </div>

        <div *ngIf="!isEmbeddedInstance">
          <h2 class="panel__title">
            <i class="trakto-icon-link"></i>
            {{ 'property_panel.general.add_link_title' | translate }}
          </h2>

          <div class="panel-background">
            <div class="panel__effects">
              <div class="panel-filter" [style.paddingBottom.px]="0">
                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="
                      elementSelected.whatsapp || elementSelected.email
                    "
                    (onClick)="openPanelStack('panel-website')"
                  >
                    <i class="trakto-icon-worldwide"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.website' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="elementSelected.link || elementSelected.email"
                    (onClick)="openPanelStack('panel-whatsapp')"
                  >
                    <i class="trakto-icon-social-whatsapp"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.whatsapp' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="
                      elementSelected.link || elementSelected.whatsapp
                    "
                    (onClick)="openPanelStack('panel-email')"
                  >
                    <i class="trakto-icon-email"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.e-mail' | translate
                    }}</span>
                  </trakto-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
