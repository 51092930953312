import { Component, OnInit, Inject } from "@angular/core";
import { FBTraktoTracking } from "@app/editor/services/analytics/facebook/fbTraktoTracking.analytics";
import { PremiumService } from "@app/editor/services/premium.service";
import { TraktoPremiumService, EnumModalComponent } from "@trakto/trakto-premium";
import { PlanConfigService } from "@app/shared/subscription/plan-config.service";
import { UserService } from "@app/editor-v3/services/user.service";

@Component({
  selector: 'tkt-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
})
export class PromoBannerComponent implements OnInit {
  public isB2C = true;
  public isPremium = true;

  constructor(
    @Inject(TraktoPremiumService)
    private _traktoPremiumService: TraktoPremiumService,
    private _premiumService: PremiumService,
    private readonly _fbTrack: FBTraktoTracking,
    private readonly _userService: UserService,
    private readonly _planService: PlanConfigService
  ) { }

  ngOnInit() {
    this._loadPlanInfos();
  }

  private async _loadPlanInfos(): Promise<void> {
    this.isB2C = this._userService.isB2c;
    this.isPremium = await this._planService.isPremiumUser();
  }

  public openSubscriptionModal(): void {
    this._premiumService.configure().then(() => {
      this._fbTrack.openCheckout();
      this._traktoPremiumService.premiumModal(EnumModalComponent.MODAL_PLANS);
    });
  }
}
