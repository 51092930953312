import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { DocumentStateManagerService } from '@app/editor/services/document-state-manager.service';
import { PageService } from '@services/page.service';
import { PageUtilModel } from '@trakto/core-editor';
import { IDocumentModel, PageModel } from '@trakto/models';

const THUMB_MARGIN_LEFT = 37;
const SCROLL_AREA_PADDING_LEFT = 20;
const SCROLL_AREA_PADDING_RIGHT = 30;

@Component({
  selector: 'trakto-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss'],
})
export class PageNavigationComponent implements OnChanges {
  @Input()
  document: IDocumentModel;

  @Input()
  public pagesThumbList: PageModel[];

  @Input()
  public selectedPageIndex: number;

  @Input()
  public ref: any;

  @Output()
  public onPageFocus: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('scrollArea')
  private scrollArea: ElementRef;

  public visibleThumbs = [];
  private thumbsEnds = [];

  public leftWidth = -1;
  public rightWidth = -1;
  private visibleThumbStartIndex = -1;
  private visibleThumbEndIndex = -1;

  constructor(
    private documentManagerService: DocumentStateManagerService,
    private pageService: PageService,
  ) {}

  ngOnChanges(): void {
    if (!this.pagesThumbList) {
      return;
    }

    this.thumbsEnds = [];

    let thumbsWidthsSum = SCROLL_AREA_PADDING_LEFT;

    this.pagesThumbList.forEach((page: PageModel, i: number) => {
      thumbsWidthsSum += this.getThumbWidth(i);
      this.thumbsEnds.push(thumbsWidthsSum);
    });

    this.scroll();
  }

  @HostListener('window:resize')
  public resize() {
    if (!this.pagesThumbList) {
      return;
    }
    this.scroll();
  }

  public scroll() {
    const { scrollLeft, clientWidth } = this.scrollArea.nativeElement;
    const visibleAreaFinalWidth = scrollLeft + clientWidth;

    this.visibleThumbStartIndex = this.thumbsEnds.findIndex(
      thumbEnd => scrollLeft < thumbEnd,
    );
    this.visibleThumbEndIndex = this.thumbsEnds.findIndex(
      thumbEnd => visibleAreaFinalWidth < thumbEnd,
    );

    this.visibleThumbStartIndex = Math.max(0, this.visibleThumbStartIndex);
    this.visibleThumbEndIndex = Math.min(
      this.visibleThumbEndIndex,
      this.thumbsEnds.length - 1,
    );
    this.visibleThumbStartIndex =
      this.visibleThumbStartIndex !== -1 ? this.visibleThumbStartIndex : 0;
    this.visibleThumbEndIndex =
      this.visibleThumbEndIndex !== -1
        ? this.visibleThumbEndIndex
        : this.pagesThumbList.length - 1;

    this.visibleThumbs = this.pagesThumbList.slice(
      this.visibleThumbStartIndex,
      this.visibleThumbEndIndex + 1,
    );

    this.initLeftWidth();
    this.initRightWidth();
  }

  private initLeftWidth() {
    if (!this.thumbsEnds[this.visibleThumbStartIndex]) {
      return;
    }
    this.leftWidth =
      this.thumbsEnds[this.visibleThumbStartIndex] -
      this.getThumbWidth(this.visibleThumbStartIndex) -
      SCROLL_AREA_PADDING_LEFT;
  }

  private initRightWidth() {
    if (!this.thumbsEnds[this.visibleThumbEndIndex]) {
      return;
    }
    const invisibleThumbs = this.pagesThumbList
      .map((p, i) => i)
      .slice(this.visibleThumbEndIndex + 1, this.pagesThumbList.length - 1);
    this.rightWidth =
      invisibleThumbs.reduce((p, c) => p + this.getThumbWidth(c), 0) +
      SCROLL_AREA_PADDING_RIGHT;
  }

  public getThumbZoomRatio(page: PageModel): number {
    return PageUtilModel.getThumbZoomRatio(page);
  }

  public addPage(order: number = -1) {
    this.documentManagerService.addPage(
      this.pageService.cloneClearPage(
        this.document.body[order] || this.document.body[order - 1],
      ),
      order,
    );
  }

  private getThumbWidth(pageIndex: number) {
    const page = this.pagesThumbList[pageIndex];
    const last = pageIndex === this.pagesThumbList.length - 1;
    return (
      this.getThumbZoomRatio(page) * page.width +
      THUMB_MARGIN_LEFT * (last ? 2 : 1)
    );
  }
}
