import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trakto-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent {
  @Input() actived = false;
  @Input() className: string;

  @Output() changed = new EventEmitter<boolean>();

  public toggle(): void {
    this.actived = !this.actived;
    this.changed.emit(this.actived);
  }
}
