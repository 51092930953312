import { environment } from "@env/environment";

export default class ProductAnalyticsFactory {

    static applicationModel(currentProduct, currentApp) {
        return {
            origin_application: 'editor',
            provider: environment.userProvider,
            target_app: currentApp?.title || '',
            target_app_id: currentApp.id || currentApp.reference.id,
            target_product: currentProduct.title || '',
            target_product_id: currentProduct.id || currentProduct.refence.id,
        };
    }
}