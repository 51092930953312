import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'trakto-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true,
    },
  ],
})
export class SelectFieldComponent implements OnInit, ControlValueAccessor {

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  @Input() placeholder: string;
  @Input() options: Array<any>;
  @Input() multiple: boolean;

  public selectedValue: string[] = [];

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  onOpenedChange(event: any): void {
    this.openedChange.emit(event);
  }

  onSelectionChange(event: any): void {
    this.selectionChange.emit(event);
    this.selectedValue = event.value;
    this.onChange(event.value);
  }

  onValueChange(value: string[]): void {
    this.selectedValue = value;
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value: string[]): void {
    if (this.options && value) {
      this.selectedValue = value;
      this.onChange(value);
    }
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  compareByValue(f1: any, f2: any): any {
    if (f1.descriptor && f2.descriptor) {
      return f1.descriptor.label === f2.descriptor.label
    } 
    return f1 && f2 && (f1.id === f2.id || f1.id === f2);
  }

}