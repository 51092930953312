<div class="tlinks-qrcode-container">
  <!-- QRCode container -->
  <div class="tlinks-qrcode-container__qrcode">
    <!-- QRCode -->
    <qrcode
      *ngIf="qrcodeLink"
      [qrdata]="qrcodeLink"
      [width]="246"
      [margin]="2"
      [errorCorrectionLevel]="'L'"
    ></qrcode>

    <!-- Profile image -->
    <i *ngIf="profileImage" class="tlinks-qrcode-container__profile">
      <div [style.backgroundImage]="'url(' + profileImage + ')'" alt=""></div>
    </i>
  </div>

  <!-- Button download QRCode -->
  <div class="tlinks-qrcode-container__button-download">
    <div *ngIf="loading">
      <i class="spinner">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </i>
    </div>

    <kml-button
      *ngIf="!loading"
      type="secondary"
      icon="trakto-icon-download-drop"
      [label]="'trakto_links.personalize.button_qrcode_download' | translate"
      (click)="startDownload($event)"
    ></kml-button>
  </div>
</div>
