import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputChipFieldComponent } from './input-chip-field.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '@app/editor/shared/material.module';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    InputChipFieldComponent,
  ],
  imports: [
    CommonModule,    
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatChipsModule,
    ReactiveFormsModule,    
  ],
  exports: [
    InputChipFieldComponent,
  ]
})
export class InputChipFieldModule { }
