import { Component, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import {
  FormatsService,
  IChildFormat,
  IParentFormat,
} from './services/formats.service';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
import { SwiperComponent } from 'swiper/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  IDocumentModel,
  IElementModel,
  PageModel,
  pageUnitEnum
} from '@trakto/models';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { IUnitOptions } from './inputs-settings/inputs-settings.component';
import { Subject, Subscription } from 'rxjs';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { ZoomService } from '@app/editor/services/zoom.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { takeUntil } from 'rxjs/operators';

SwiperCore.use([Navigation]);

export enum EnumSizeProperty {
  WIDTH = 'width',
  HEIGHT = 'height',
}

const conversions = {
  [pageUnitEnum.cm]: 37.7952755906,
  [pageUnitEnum.mm]: 3.77952755906,
  [pageUnitEnum.inch]: 95.999999998601,
  [pageUnitEnum.px]: 1,
};

export const baseCmToPx = 37.7952755906;

@Component({
  selector: 'trakto-panel-page',
  templateUrl: './panel-page.component.html',
  styleUrls: ['./panel-page.component.scss'],
})
export class PanelPageComponent extends AbstractComponent<IElementModel> implements OnInit {
  @ViewChild('swiperInstance', { static: false })
  swiperInstance?: SwiperComponent;

  @Input() pageSelected: PageModel;

  document: IDocumentModel;
  formats: IParentFormat[] = [];
  allFormats: IChildFormat[] = [];
  lang = 'pt-BR';
  isFormatsGridVisible = false;

  swiperConfig: SwiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 24,
    centeredSlides: true,
    direction: 'horizontal',
    loop: true,
    navigation: true,
    preloadImages: true,
  };

  private _convertedWidth: number;
  private _convertedHeight: number;

  public set convertedWidth(value: number) {
    if (value) {
      this.convertSizeWithoutApply(EnumSizeProperty.WIDTH, value);
    }
  }

  public get convertedWidth(): number {
    return (
      this._convertedWidth ||
      this.getConvertedSize(EnumSizeProperty.WIDTH, this.pageSelected.unit)
    );
  }

  public set convertedHeight(value: number) {
    if (value) {
      this.convertSizeWithoutApply(EnumSizeProperty.HEIGHT, value);
    }
  }

  public get convertedHeight(): number {
    return (
      this._convertedHeight ||
      this.getConvertedSize(EnumSizeProperty.HEIGHT, this.pageSelected.unit)
    );
  }

  public isLoading = false;

  constructor(
    private _formatsService: FormatsService,
    private _translateService: TranslateService,
    private _signalsService: SignalsService,
    private _documentManagerService: DocumentManagerService,
    private _zoomService: ZoomService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.initSubscriptions();
    this.lang = this._translateService.currentLang;
    this.formats = await this._formatsService.getOrdenedFormats();

    this.formats.forEach(parent => {
      this.allFormats.push(...parent.formats);
    });
  }

  initSubscriptions() {
    // this.subscriptions.push(
      this._formatsService.onLoadingChange.pipe(takeUntil(this._destroy$),).subscribe(isLoading => this.isLoading = isLoading)
    // );
  }

  toggleFormatsVisibility() {
    this.isFormatsGridVisible = !this.isFormatsGridVisible;
  }

  onSwiper() {
    this.updateCarousel();
  }

  updateCarousel() {
    const index = this.pageSelected.format?.id
      ? this.allFormats.findIndex(
          child => child.id === this.pageSelected.format.id,
        ) + 4
      : 3;

    this.swiperInstance?.swiperRef.slideTo(index);
  }

  async changeFormat(format: IChildFormat) {
    const formatData = await this._formatsService.getFormat(format.id);
    const { width, height, unit } = format.canva_dimension;

    const fakePage = {
      id: this.pageSelected.id,
      format: { ...formatData },
      lockSize: false,
      unit: unit as pageUnitEnum,
      width: this.getConvertSizeWithoutApply(EnumSizeProperty.WIDTH, width),
      height: this.getConvertSizeWithoutApply(EnumSizeProperty.HEIGHT, height),
    } as PageModel;
    await this._elementChangeFacadeService.changePage(fakePage);

    this.updateCarousel();
  }

  changeProperty(...args: string[]): void {
    args.forEach(property => {
      this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
        elementId: this.pageSelected.id,
        key: property,
        value: this.pageSelected[property],
        obj: this.pageSelected,
      });
    });

    this.handleZoomFit();
  }

  async convertSizeWithoutApply(property: EnumSizeProperty, value: number) {
    await this._elementChangeFacadeService.changePageProperty(this.pageSelected, property, this.getConvertSizeWithoutApply(property, value), true);
  }

  getConvertSizeWithoutApply(property: EnumSizeProperty, value: number): number {
    const convertedSize = parseFloat(value.toFixed(2));

    if (property === EnumSizeProperty.HEIGHT)
      this._convertedHeight = convertedSize;
    if (property === EnumSizeProperty.WIDTH)
      this._convertedWidth = convertedSize;

    return value * conversions[this.pageSelected.unit] || 0;
  }

  changeDimensions(event: {
    property: EnumSizeProperty;
    value: string;
  }): boolean {
    const { property, value } = event;

    if (value === null || value === undefined) {
      return false;
    }

    const size = parseInt(value, 10);

    if (this.pageSelected.lockSize) {
      switch (property) {
        case 'width':
          this.convertedHeight *= size / this.convertedWidth;
          this.convertedWidth = size;
          break;
        case 'height':
          this.convertedWidth *= size / this.convertedHeight;
          this.convertedHeight = size;
          break;
      }

      this.changeProperty('width', 'height');
    } else {
      switch (property) {
        case 'width':
          this.convertedWidth = size;
          break;
        case 'height':
          this.convertedHeight = size;
          break;
      }

      this.changeProperty(property);
    }

    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'format', null, true);
    this.updateCarousel();
  }

  invertDimensions() {
    const width = this.convertedWidth;
    const height = this.convertedHeight;

    this.convertedWidth = height;
    this.convertedHeight = width;

    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'format', null, true);
    this.updateCarousel();
    this.changeProperty('width', 'height');
  }

  changeUnit(unit: IUnitOptions) {
    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'unit', unit.value, true);

    switch (unit.value) {
      case pageUnitEnum.cm:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.cm,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.cm,
        );
        break;
      case pageUnitEnum.mm:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.mm,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.mm,
        );
        break;
      case pageUnitEnum.inch:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.inch,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.inch,
        );
        break;
      default:
        this.convertedWidth = this.pageSelected.width;
        this.convertedHeight = this.pageSelected.height;
        break;
    }

    this.changeProperty('unit');
  }

  applyToAll() {
    this._documentManagerService.changeAllPageSizes(this.pageSelected);
  }

  getConvertedSize(property: EnumSizeProperty, unit: pageUnitEnum): number {
    const originalSize = this.pageSelected[property];
    const division = conversions[unit] || 1;

    return parseFloat((originalSize / division).toFixed(2));
  }

  handleZoomFit() {
    this._zoomService.handleZoomFit(
      this.pageSelected,
      this.document,
    );
  }
}
