import { Pipe, PipeTransform } from '@angular/core';
import { enumTextType } from '@app/shared/elements-gallery/elements-gallery.service';
import { enumCardTypes } from '@shared/elements/pack.entity';

@Pipe({
  name: 'stringlimiter',
})
export class StringlimiterPipe implements PipeTransform {
  transform(value: string, type: enumCardTypes, text: enumTextType): string {
    const size = this.getStringSize(type, text);

    return value.length >= size ? value.slice(0, size).trim() + '...' : value;
  }

  getStringSize(type: enumCardTypes, text: enumTextType): number {
    let size = 20;

    switch (type) {
      case enumCardTypes.HORIZONTAL:
        if (text === enumTextType.TITLE) size = 35;
        if (text === enumTextType.AUTHOR) size = 40;
        break;

      case enumCardTypes.SINGLE:
      case enumCardTypes.PANEL:
        if (text === enumTextType.TITLE) size = 30;
        if (text === enumTextType.AUTHOR) size = 15;
        break;

      case enumCardTypes.SQUARE:
        if (text === enumTextType.TITLE) size = 55;
        if (text === enumTextType.AUTHOR) size = 45;
        break;

      default:
        break;
    }

    return size;
  }
}
