<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-website.svg"
    label="Website"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper">
    <trakto-input
      [value]="website"
      label="Link do site"
      placeholder="Insira seu link aqui"
      (onChange)="changeWebsiteValue($event)"
      icon="link-element"
      iconColor="#66bfff"
    ></trakto-input>

    <trakto-button-loader
      [isDisabled]="!isUrlValid"
      (action)="applyLinkToElement()"
      label="Adicionar link"
    ></trakto-button-loader>
  </div>
</div>
