<div class="image-grid" [ngClass]="{ loading: loading }">

  <trakto-button-uploader [img]="'assets/editor-v3/images/panel-images.svg'"
  [label]="'Envie uma imagem'" [isGif]="false"
  ></trakto-button-uploader>

  <trakto-grid-images
    (action)="insertImage($event)"
    [images]="images"
  ></trakto-grid-images>

  <div class="action" *ngIf="loading || hasNextPage">
    <trakto-button-loader
      [isLoading]="loading"
      (action)="loadUserImages()"
      label="Carregar mais"
    ></trakto-button-loader>
  </div>
</div>
