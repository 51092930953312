<div class="trakto-popover bottom" [style.display]="'block'">
  <div class="trakto-popover-arrow arrow"></div>

  <div class="trakto-popover-title popover-header">
    <i class="trakto-icon-warn" aria-hidden="true"></i>
  </div>

  <div class="trakto-popover-content popover-body">
    <p>{{ 'popover.message' | translate }}</p>

    <div class="trakto-confirm-btns">
      <div class="cancel-btn-container">
        <button
          type="button"
          class="button button__orange"
          (click)="cancelAction()"
        >
          {{ 'popover.button_cancel' | translate }}
        </button>
      </div>
      <div class="confirm-btn-container">
        <button
          type="button"
          class="button button__orange"
          (click)="confirmAction()"
        >
          {{ 'popover.button_confirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
