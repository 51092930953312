import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { ITextElementModel } from '@trakto/models';

@Component({
  selector: 'trakto-panel-text-mobile',
  templateUrl: './panel-text-mobile.component.html',
  styleUrls: ['./panel-text-mobile.component.scss']
})
export class PanelTextMobileComponent implements OnInit {

  text: ITextElementModel;
  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
    private _documentManagerService: DocumentManagerService,
  ) { }

  ngOnInit(): void {
  }

  addText(text: string) {
    switch (text) {
      case 'title':
        this._addText(36, 800);
        break;
      case 'description':
        this._addText(32, 500);
        break;
      case 'text':
        this._addText(30, 400);
        break;
      default:
        this._addText(36, 400);
    }
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  private async _addText(fontSize: number, fontWeight: number) {
    const text = await this._documentManagerService.getSelectedElement() as ITextElementModel;
    if (text) {
      const fontScale = (await this._documentManagerService.getSelectedPage()).width / 500;
      this._elementsService.changeFontSize(fontSize * fontScale);
      this._elementsService.changeFontStyleByWeight(fontWeight);
    } else {
      await this._elementFactoryFacadeService.createAndAddText(undefined, fontSize, fontWeight);
    }
    this.closePanel();
  }
}
