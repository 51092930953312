import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IElementNewAPI } from '@shared/elements/element.entity';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { ThemeService } from '@app/editor/services/theme.service';
import { ElementNewApiService } from '@app/editor/services/element-new-api.service';
import { ElementDatasource } from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import { ElementSource } from '@app/editor/types/elements/elements.type';

@Component({
  selector: 'trakto-elements-list',
  templateUrl: './elements-list.component.html',
  styleUrls: ['./elements-list.component.scss'],
})
export class ElementsListComponent implements OnInit, OnChanges {
  @Input() currentSearch: ISearchEvent;
  @Input() isPanelExpanded = false;
  @Input() isBigger = false;

  expandGrid = false;
  hasBigGrid = false;

  @Output() requestElementInsertion: EventEmitter<IElementNewAPI> =
    new EventEmitter<IElementNewAPI>();

  elements: IElementNewAPI[];

  currentPage: number;
  hasNextPage = true;
  loading = false;
  primaryColor = '#0079ff';

  private _currentElementDatasource: ElementSource = ElementDatasource.TRAKTO;
  private _elementsPerPage = 42;

  constructor(
    private _themeService: ThemeService,
    private _elementNewApiService: ElementNewApiService
  ) { }

  ngOnInit(): void {
    const themePrimaryColor = this._themeService.getTheme().color.primary;

    if (themePrimaryColor) this.primaryColor = themePrimaryColor;

    this.hasBigGrid = this.isBigger;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.currentSearch && this.currentSearch) {
      this._currentElementDatasource = changes.currentSearch?.currentValue.datasource || 'trakto';
      this.currentPage = 0;
      this.hasNextPage = true;
      this.elements = [];
      await this.nextPage();
    }

    if (changes.isPanelExpanded) {
      this.expandGrid = this.isPanelExpanded;
    }
  }

  async nextPage() {
    try {
      if (!this.hasNextPage) return;
      this.loading = true;
      const result = await this._elementNewApiService.fetchElements(this.currentSearch, this._elementsPerPage, this.currentPage, this._currentElementDatasource);
      this.hasNextPage = result.nextPage !== null;
      this.elements = this.elements.concat(result.elements);
      this._currentElementDatasource = result.nextSource;
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  emitElement(element: IElementNewAPI) {
    this.requestElementInsertion.emit(element);
  }

  scrollToNextPage() {
    this.currentPage++;
    this.nextPage();
  }

  loadMore() {
    this.currentPage++;
    this.nextPage();
  }
}
