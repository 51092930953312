import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FirebaseService } from '@shared/firebase-client/firebase.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'trakto-share',
  templateUrl: './share.component.html',
})
export class ShareComponent implements OnInit, OnDestroy {
  @ViewChild('inputSlug', { static: true }) inputSlug: any;

  private readonly _formGroup: FormGroup;
  public editorURL: string;
  public preSlug: string;
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private database: FirebaseService,
    private dialogRef: MatDialogRef<ShareComponent>,
  ) {
    this._formGroup = this.formBuilder.group({
      slug: [null],
      allow_preview: [false],
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this.preSlug = `${this.data.docId.substring(0, 5)}-`;
    this.editorURL = `${(window.location as any).origin}/doc/${this.preSlug}`;

    const slug = this.data.slug
      ? this.data.slug.split('-').splice(1, this.data.slug.length).join('-')
      : null;

    this.formGroup.get('slug').setValue(slug);
    this.formGroup.get('allow_preview').setValue(this.data.allow_preview);
  }

  /**
   * Método para checar se o documento existe e retornar os dados ao fechar modal
   */
  public async doCheck() {
    const hasDoc = await this.checkDoc();
    const hasSlug = await this.checkSlug();

    this.dialogRef.close({
      exists: hasDoc || hasSlug ? true : false,
      slug: this.formGroup.get('slug').value
        ? this.preSlug + this.formGroup.get('slug').value
        : null,
      allow_preview: this.formGroup.get('allow_preview').value,
    });
  }

  /**
   * Método para copiar URL completa com `slug`
   */
  public copy(): void {
    if (this.inputSlug.nativeElement.value) {
      this.inputSlug.nativeElement.focus();
      this.inputSlug.nativeElement.select();
      document.execCommand('copy');
    }
  }

  /**
   * Método para verificar a existência de um documento à partir de um `id`
   * @return {Promise<boolean>}
   */
  public async checkDoc() {
    return new Promise((resolve, reject) => {
      this.database.firestoreModule
        .collection('document')
        .doc(this.preSlug + this.formGroup.get('slug').value)
        .snapshotChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          docs => resolve(docs.payload.exists),
          err => reject(err),
        );
    });
  }

  /**
   * Método para verificar a chave `slug` na collection de `document`
   * @return {Promise<boolean>}
   */
  public async checkSlug() {
    return new Promise((resolve, reject) => {
      this.database.firestoreModule
        .collection('document', ref =>
          ref.where(
            'slug',
            '==',
            this.preSlug + this.formGroup.get('slug').value,
          ),
        )
        .snapshotChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          docs => resolve(docs.length > 0),
          err => reject(err),
        );
    });
  }

  /**
   * Método contendo regex para limpar acentos e espaços no `slug`
   * @param value {String}
   */
  public getCleanWord(value: string) {
    let word = value.toLowerCase();

    word = word.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    word = word.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    word = word.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    word = word.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    word = word.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    word = word.replace(new RegExp('[Ç]', 'gi'), 'c');
    word = word.replace(new RegExp(' ', 'gi'), '-');

    this.formGroup.get('slug').setValue(word);
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }
}
