import {
  Component,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { TraktoLink, TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { traktoLinksPanelsPageEnum as panelPageEnums } from '@trakto/models';
import { Subscription } from 'rxjs';
import { TraktoLinkState } from './store/trakto-link.reducer';
import { Store } from '@ngrx/store';
import * as fromTraktoLinkActions from './store/trakto-link.actions';
import { UserService } from '@app/editor-v3/services/user.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'trakto-trakto-link-modal',
  templateUrl: './trakto-link-modal.component.html',
  styleUrls: ['./trakto-link-modal.component.scss']
})
export class TraktoLinkModalComponent implements OnDestroy {

  public showModal: boolean = false;
  public panelPageEnums = panelPageEnums;
  public currentPanel: string = panelPageEnums.PERSONALIZE;
  @Output() onPanelChange = new EventEmitter<any>();
  @Output() onModalShareChange = new EventEmitter<any>();
  public doc: any;
  public isB2C: boolean;
  public user: any;
  public documentSucess: any = false;
  public traktoLink: TraktoLink;
  public link = {
    status: false,
    url: 'www.trakto.io',
    copied: false,
    loading: false,
    success: false,
    typing: true
  };
  loading = true;
  documentSubscription: Subscription;

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _traktoLinksService: TraktoLinksService,
    private _traktoLinkStore: Store<TraktoLinkState>,
    private _userService: UserService,
    private dialog: MatDialog,
  ) {
    this.isB2C = this._userService.isB2c;
    this.user = this._userService.user;
  }

  init(): void {

    this._documentManagerService.getSelectedDocument().then((doc) => {
      this.doc = doc;

      if (doc && doc.metadata && Object.keys(doc.metadata).length !== 0 && doc.metadata.link_name !== '') {
        let link: any = {
          description: this.doc?.metadata.description ? this.doc?.metadata.description : 'Trakto - Crie facilmente artes para redes sociais, link na bio, mini-sites, ebooks, apresentações e muito mais',
          link_name: this.doc?.metadata.link_name,
          link_web: this.doc?.metadata.link_web,
          pausedLink: this.doc?.metadata.pausedLink,
          title: this.doc?.metadata.title ? this.doc?.metadata.title : this.doc?.title,
          image: this.doc?.metadata.image ? this.doc?.metadata.image : this.doc?.cover?.low || this.doc?.thumbs?.low,
        }

        this._traktoLinkStore.dispatch(fromTraktoLinkActions.addTraktoLink({
          traktoLink: link
        }))
        this.showModal = !this.showModal;
        this.loading = false;
      } else {
        this.setAutomaticallyTraktoLink();
      }
    });
  }


  ngOnDestroy() {
    this.documentSubscription?.unsubscribe();
  }

  toggle() {
    this.init();
    this.onModalShareChange.emit(this.showModal);
  }

  setAutomaticallyTraktoLink() {

    if (!this.doc) {
      return;
    }

    const createSlug = this.user ? this.user.firstname.split(' ').join('') + this.formatDate() + this.doc.id.split('').slice(0, 5).join('') : 'SeuNome';

    const traktoLink: TraktoLink = new TraktoLink({
      slug: createSlug,
      link_web: createSlug,
      pausedLink: false,
      seo: new TraktoLinkSeo({
        thumbUrl: this.doc?.cover?.low || this.doc?.thumbs?.low,
        title: this.doc?.title,
        description: 'Trakto - Crie facilmente artes para redes sociais, link na bio, mini-sites, ebooks, apresentações e muito mais'
      })
    });

    this.showModal = !this.showModal;
    try {
      this._traktoLinksService.createMetadataAndPublish(traktoLink, this.isB2C).then(
        (link) => {
          this._traktoLinkStore.dispatch(fromTraktoLinkActions.addTraktoLink({
            traktoLink: link
          }));
          this.loading = false;
        })
    } catch (error) {
      this.loading = false;
    }
  }


  public changePanel(panel: panelPageEnums) {
    this.currentPanel = panel;
    this.onPanelChange.emit(panel);
  }

  deleteTraktoLink(event) {
    if (event) {
      this.showModal = !this.showModal;
      this.onModalShareChange.emit(this.showModal);
    }
  }

  private formatDate() {
    // Obter a data atual
    const currentDate = new Date();

    // Obter o dia, mês e ano
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Os meses começam do zero, então adicionamos 1
    const year = currentDate.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    // Construir a string no formato DDMMYYYY
    return `${formattedDay}${formattedMonth}${year}`;
  }
}
