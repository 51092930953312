<div
  id="panel-page"
  class="panel new-panel"
  [ngStyle]="{ overflow: isFormatsGridVisible ? 'hidden' : 'unset' }"
>
  <div class="new-panel__header">
    <img src="assets/images/panel/page-dimensions.svg" alt="" />
    <span> {{ 'property_panel.page.title' | translate }} </span>
  </div>

  <div class="new-panel__loading" *ngIf="isLoading">
    <i class="trakto-icon-stream-cog"></i>
  </div>

  <div class="new-panel__carousel" *ngIf="allFormats.length > 0">
    <div class="new-panel__see-more" (click)="toggleFormatsVisibility()">
      <span>{{ 'property_panel.general.load_more_modal' | translate }}</span>
    </div>
    <swiper (swiper)="onSwiper()" [config]="swiperConfig" #swiperInstance>
      <ng-template swiperSlide>
        <div class="new-panel__slide">
          <img src="assets/images/panel/custom-size.svg" alt="" width="85" />
          <p>{{ 'property_panel.general.custom' | translate }}</p>
        </div>
      </ng-template>

      <ng-container *ngFor="let childFormat of allFormats">
        <ng-template swiperSlide>
          <trakto-format-card
            [label]="
              childFormat.titles ? childFormat.titles[lang] : childFormat.title
            "
            [icon]="childFormat.file"
            [selected]="childFormat.id === pageSelected.format?.id"
            (click)="changeFormat(childFormat)"
          ></trakto-format-card>
        </ng-template>
      </ng-container>

      <div slot="container-end">
        <div class="whiteshadow prev"></div>
        <div class="whiteshadow next"></div>
      </div>
    </swiper>
  </div>

  <div class="new-panel__divider" *ngIf="allFormats.length > 0"></div>

  <trakto-inputs-settings
    [width]="convertedWidth"
    [height]="convertedHeight"
    [pageSelected]="pageSelected"
    (onChangeDimensions)="changeDimensions($event)"
    (onInvertDimensions)="invertDimensions()"
    (onChangeUnit)="changeUnit($event)"
    (onApplyAll)="applyToAll()"
  ></trakto-inputs-settings>

  <trakto-grid-formats
    [isVisible]="isFormatsGridVisible"
    [formats]="formats"
    [lang]="lang"
    [pageSelected]="pageSelected"
    (onGridClose)="toggleFormatsVisibility()"
    (onSelectFormat)="changeFormat($event)"
  ></trakto-grid-formats>
</div>
