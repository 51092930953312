<div class="trakto-colorpicker">
  <div class="trakto-colorpicker__palette">
    <trakto-colorpicker-input
      class="trakto-colorpicker__input"
      (onChange)="inputColorChange($event)"
      [color]="color"
    ></trakto-colorpicker-input>
    <trakto-colorpicker-palette
      #colorPickerPalette
      class="trakto-color-panel__colorpicker"
      [color]="color"
      (onChange)="inputColorChange($event)"
    ></trakto-colorpicker-palette>
  </div>
  <trakto-colorpicker-slider
    (changedHue)="colorPickerPalette.changeHue($event)"
  ></trakto-colorpicker-slider>
</div>
