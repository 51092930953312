<div class="trakto-checkbox">
  <label>
    <div class="trakto-checkbox__content">
      <input
        type="checkbox"
        #checkbox
        [id]="id"
        [name]="name"
        [value]="isSelected"
        [checked]="checked"
        (change)="toggle()"
        class="trakto-checkbox__field"
      />

      <span>
        <i class="trakto-icon-check"></i>
      </span>

      <span class="trakto-checkbox__label">
        <ng-content></ng-content>
      </span>
    </div>
  </label>
</div>
