import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseCloudFunctionsService } from './functions/firebase-cloud-functions.service';

export abstract class FirebaseService {
  abstract callable(name: string): any;

  abstract get authorizationModule(): AngularFireAuth;

  abstract get firestoreModule(): AngularFirestore;

  abstract get functions(): FirebaseCloudFunctionsService;

  abstract get storage(): AngularFireStorage;
}
