import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IPlanConfigModel } from '@app/shared/subscription/plan-config.model';
import { PlanConfigService } from '@app/shared/subscription/plan-config.service';
import { traktoLinksPanelsPageEnum as panelPageEnums } from '@trakto/models';

@Component({
  selector: 'trakto-links-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() onHelperClick = new EventEmitter<any>();
  @Output() onPanelChange = new EventEmitter<any>();
  @Output() onImageTempPreview = new EventEmitter<any>();

  private _currentPlan: IPlanConfigModel;
  public canCreateLink = false;

  public panelPageEnums = panelPageEnums;
  public currentPanel: string = panelPageEnums.PERSONALIZE;

  constructor(
    private _planService: PlanConfigService,
  ) {}

  ngOnInit() {
    this._planService.loadUserPlanConfig().then(plan => {
      this._currentPlan = plan;
      this.canCreateLink = (this._currentPlan.document_publish_online === true);
    });
  }

  public changePanel(panel: panelPageEnums) {
    this.currentPanel = panel;
    this.onPanelChange.emit(panel);
  }

  public getImagePreview(metaData: any): void {
    this.onImageTempPreview.emit(metaData);
  }

  /**
   * handleClickHelper
   */
  public handleClickHelper() {
    this.onHelperClick.emit();
  }
}
