<div style="height: 100%; overflow: auto" #fontListContainer>
  <div class="searchbar-fonts">
    <trakto-input
      prefix="trakto-icon-tkt-icon"
      enableKeypress="true"
      id="fillFont"
      name="fillFont"
      placeholder="{{
        'property_panel.text.text_style.gallery.search_fonts_placehorlder'
          | translate
      }}"
      type="search"
      (onBlur)="enableHotkeys()"
      (onFocus)="disableHotkeys()"
      [(value)]="searchFontText"
      [(ngModel)]="searchFontText"
    ></trakto-input>
  </div>

  <div class="panel-full__container">
    <div class="panel-simple">
      <trakto-font-list
        *ngIf="fonts.length > 0"
        [searchFontText]="searchFontText"
        [fonts]="fonts"
        [elementSelected]="elementSelected"
        [pageSelected]="pageSelected"
        [container]="fontListContainer"
        [selectorColor]="'#7251E7'"
        (selectedFont)="selectedFont.emit($event)"
      >
      </trakto-font-list>
    </div>
  </div>
</div>
