import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UnsplashService } from "../unsplash.service";
import {
  FileRepository,
  IAssetResponse
} from '@editor/repository/file.repository';

@Injectable()
export class GalleryImageService {

  constructor(
    private _filesRepository: FileRepository,
    private _unsplashService: UnsplashService,) { }

  public listUserImages(limit = 30, startAfter?: string): Promise<IAssetResponse> {
    return this._filesRepository.findImagesByLoggedUser(limit, startAfter);
  }

  public listImages(
    query: string = '',
    page: number = 1,
    per_page: number = 50,
    lang: string,
  ): Observable<any> {
    return new Observable<any>(observer => {
      this._unsplashService
        .searchPhotos(query, `${per_page}`, `${page}`, lang)
        .then(images => {
          observer.next(images);
          observer.complete();
        });
    });
  }

  public removeUserImage(uuid: string): Promise<any> {
    return this._filesRepository.removeUserImage(uuid);
  }
}
