export abstract class DialogService {
  public static readonly YES = 'YES';
  public static readonly CANCEL = 'CANCEL';
  public static readonly NO = 'NO';
  public static readonly OK = 'OK';

  abstract showSimpleSnackMessage(text: string): void;

  abstract showSimpleOkMessage(text: string, afterClosed?: any): void;

  abstract showYesOrCancelMessage(text: string, afterClosed?: any): void;

  abstract showYesOrNoMessage(text: string, afterClosed?: any): void;

  abstract showOptionsMessage(
    text: string,
    options: any,
    afterClosed?: any,
  ): void;

  abstract showModalWidth(
    component: any,
    width: string,
    data?: any,
    afterClosed?: any,
  ): void;
}
