import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import interact from 'interactjs';
import { Subscription } from 'rxjs';
import { PanelComponent } from '../components/panel/panel.component';
import { PanelService } from '../services/panel.service';
import * as ScreenUtils from '../utils/screen';

const MOBILE_BREAK = 768;

@Directive({
  selector: '[traktoResizable]',
})
export class ResizableDirective implements OnInit, AfterViewInit, OnDestroy {
  public isInteractStarted = false;
  public tierIndex = 0;
  public tiers: number[] = [];

  private _onChangeTiers: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _panelElement: ElementRef,
    private _panelComponent: PanelComponent,
    private _panelService: PanelService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this._panelService.resetTiers();
  }

  ngAfterViewInit(): void {
    this.handleInteractionFire();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onChangeTiers = this._panelService.onChangeTiers.subscribe(tiers => {
      this.tiers = [...tiers];
      this._panelComponent.toggleOverlay(false);
      this.handleInteractionFire();
    });

    this._subscriptions.push(this._onChangeTiers);
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  startInteraction(target: HTMLElement): void {
    const getTierToSticky = (event: any) => this.tierToStickyGetter(event);

    if (target) {
      interact(target).resizable({
        edges: { top: true },
        listeners: {
          move: function (event) {
            let { x, y } = event.target.dataset;

            x = (parseFloat(x) || 0) + event.deltaRect.left;
            y = (parseFloat(y) || 0) + event.deltaRect.top;

            Object.assign(event.target.style, {
              height: `${event.rect.height}px`,
              transition: '',
            });

            Object.assign(event.target.dataset, { x, y });
          },
          end(event) {
            const tier = getTierToSticky(event);

            Object.assign(event.target.style, {
              height: `${tier}px`,
              transition: 'height 0.1s ease-in;',
            });
          },
        },
      });

      this.isInteractStarted = true;
    }
  }

  destroyInteraction(target: HTMLElement): void {
    interact(target).unset();
    target.style.height = '';
    this.isInteractStarted = false;
    this._panelComponent.toggleOverlay(false);
    this._panelService.closeAllPanels();
  }

  tierToStickyGetter(event: any) {
    const height = event.rect.height;
    const direction = event.velocity.y < 0 ? 'toUp' : 'toDown';

    const indexFound = this.tiers.findIndex(tierHeight => tierHeight >= height);
    const finalIndex = this._getTierIndex(direction, indexFound, this.tiers);

    this._panelComponent.toggleOverlay(finalIndex === this.tiers.length - 1);

    return this.tiers[finalIndex];
  }

  private _getTierIndex(
    direction: 'toUp' | 'toDown',
    indexFound: number,
    tiers: number[],
  ) {
    if (direction === 'toUp') {
      return indexFound >= tiers.length || indexFound === -1
        ? tiers.length - 1
        : indexFound;
    }

    return indexFound <= 0 ? 0 : indexFound - 1;
  }

  @HostListener('window:resize')
  handleInteractionFire() {
    const viewportWidth = ScreenUtils.getViewportWidth();

    if (
      viewportWidth < MOBILE_BREAK &&
      this.tiers.length > 1 &&
      !this.isInteractStarted
    ) {
      this.startInteraction(this._panelElement.nativeElement);
    }

    if (
      (viewportWidth >= MOBILE_BREAK && this.isInteractStarted) ||
      this.tiers.length === 1
    ) {
      this.destroyInteraction(this._panelElement.nativeElement);
    }
  }
}
