import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Trakto Modal List
 *
 * @usageNotes
 * Two types of data structure  are allow:
 *
 * 1) With sections
 * ```
 * dataProvider = [
 *   {
 *     name: 'Section 1',
 *     items: [
 *      { icon: 'one or more class-icon', label: '', value: '' },
 *       { icon: 'one or more class-icon', label: '', value: '' }
 *     ]
 *   },
 *   { //Without Secion Name
 *     items: [
 *       { icon: 'one or more class-icon', label: '', value: '' },
 *       { icon: 'one or more class-icon', label: '', value: '' }
 *     ]
 *   },
 *   {
 *    name: 'Section 3',
 *    items: [
 *      { icon: 'one or more class-icon', label: '', value: '' },
 *      { icon: 'one or more class-icon', label: '', value: '' }
 *    ]
 *   }
 * ];
 * ```
 *
 * 2) Without sections
 * ```
 * dataProvider = [
 *      { icon: 'one or more class-icon', label: '', value: '' },
 *      { icon: 'one or more class-icon', label: '', value: '' }
 * ];
 *   ```
 * Access the selectedItem with the `$event` argument passed to the output onChange event
 * handler or by selectedItem two-way databinding:
 *
 * ```
 * <trakto-modal-menu [dataProvider]="" [(selectedItem)]="selectedItem" (onChange)="changeInput($event)"></trakto-modal-menu>
 * ```
 */
@Component({
  selector: 'trakto-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalMenuComponent {
  private _selected: string;
  private _selectedItem;
  private _dataProvider: any[];
  private _hasSection: boolean;

  @Input() customModal: boolean;

  @Input()
  set selectedItem(value) {
    this._selectedItem = value;
    this.onChange.emit(this._selectedItem);
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @Input()
  set dataProvider(value: any[]) {
    this._dataProvider = value || [];
    this._hasSection = this._checkIfHasSection(this._dataProvider);
  }

  get dataProvider(): any[] {
    return this._dataProvider;
  }

  @Input()
  set selected(value: string) {
    this._selected = value;
  }

  get selected(): string {
    return this._selected || '';
  }

  set hasSection(value: boolean) {
    this._hasSection = value;
  }

  get hasSection(): boolean {
    return this._hasSection;
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onSelectItem(item) {
    this.selectedItem = item;
  }

  private _checkIfHasSection(list: any[]): boolean {
    return list.some(item => item.hasOwnProperty('items'));
  }
}
