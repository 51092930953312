import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ItemRenderer } from '@shared/renderers/item-renderer.model';

import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';

import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { CopyService } from '@services/copy.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { ElementModelService } from '@services/element-model.service';
import { ModalService } from '@shared/modal/modal.service';

import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  IEventModel,
  ImageElementModel,
  PageModel,
} from '@trakto/models';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-properties-panel',
  templateUrl: './properties-panel.component.html',
  styleUrls: ['./properties-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertiesPanelComponent implements OnInit, OnDestroy {
  private _selectedPage: PageModel;
  private _selectedElement: IElementModel;
  private _clickedElement: IElementModel = null;
  private _currentElement: IElementModel = null;

  @Input() b2c: boolean;

  private onOpenModalSignal: Subscription;

  public isPanelExpanded = false;
  public showExpandIcon = true;
  private _destroy$ = new Subject<void>();

  constructor(
    private _copyService: CopyService,
    private _modalService: ModalService,
    private _hotkeyService: HotkeyService,
    private _newElementService: ElementModelService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
    private _panelStackService: PanelStackService,
    private _elementChangeFacadeService: ElementChangeFacadeService
  ) {
  }

  ngOnInit() {
    this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(
      page => (this._selectedPage = page),
    );
    this._documentStateManagerService.element$.pipe(takeUntil(this._destroy$)).subscribe(element => {
      this._selectedElement = element || null;
      this._currentElement = element || null;
      if (this._isSingleGroup()) {
        this._currentElement = this._clickedElement || null;
      }
    });
    this._documentStateManagerService.clickedElement$.pipe(takeUntil(this._destroy$)).subscribe(element => {
      this._clickedElement = element || null;

      if (this._isSingleGroup()) {
        this._currentElement = this._clickedElement;
      }
    });

    this._hotkeyService.addContext('propertiesPanelKeys', (e: KeyboardEvent) => {
      if (this._hotkeyService.CMD_KEY(e)) {
        if (this._selectedPage) {
          this._copyService.focus();
        }
        if (e.which === KeyEnum.c) {
          if (this._newElementService.canHandleElement(this._selectedElement)) {
            this._copyService.copy(
              this._newElementService.copySelectedElement(this._selectedPage, this._selectedElement)
            );
          }
        }

        if (e.which === KeyEnum.l) {
          this._hotkeyService.stopPropagation(e);
          this._elementChangeFacadeService.lockElement(this._selectedElement);
        }

        if (e.which === KeyEnum.d) {
          this._hotkeyService.stopPropagation(e);
          this._documentManagerService.cloneSelectedElementsAndAdd();
        }

        if (e.which === KeyEnum.g) {
          this._hotkeyService.stopPropagation(e);
          this._documentManagerService.group(this._selectedElement);
        }
      }

      if (this._hotkeyService.CMD_SHIFT_KEY(e)) {
        if (e.which === KeyEnum.up) {
          this._hotkeyService.stopPropagation(e);
          this._documentManagerService.moveSelectedElementUp();
          this._documentManagerService.moveSelectedPageUp();
        }
        if (e.which === KeyEnum.down) {
          this._hotkeyService.stopPropagation(e);
          this._documentManagerService.moveSelectedElementDown();
          this._documentManagerService.moveSelectedPageDown();
        }
        if (e.which === KeyEnum.g) {
          this._hotkeyService.stopPropagation(e);
          this._documentManagerService.ungroup(this._selectedElement);
        }
      }

      if (this._hotkeyService.KEY(e)) {
        if (this._hotkeyService.isFirefox()) {
          this._hotkeyService.disableContext('toolbarkeys');
        }
      }
    });

    this.onOpenModalSignal = this._signalsService.connect(
      enumSignals.ON_OPEN_MODAL,
      (change: IEventModel) => this._openModal(change.data),
    );

      this._panelStackService.onExpandIconChange.pipe(takeUntil(this._destroy$)).subscribe(showExpandIcon => {
        this.showExpandIcon = showExpandIcon;
      });

      this._panelStackService.onExpandPanelChange.pipe(takeUntil(this._destroy$)).subscribe(isPanelExpanded => {
        this.isPanelExpanded = isPanelExpanded;
      });
  }

  ngOnDestroy() {
    this._signalsService.disconnect(
      enumSignals.ON_OPEN_MODAL,
      this.onOpenModalSignal,
    );
    this.onOpenModalSignal.unsubscribe();
  }

  private _openModal($event) {
    const id = $event.id;
    if (this._selectedPage && !this._currentElement) {
      this._modalService.open(id, true, (item: any) => {
        if (item && item.value) {
          this._elementChangeFacadeService.changePageBackgroundUrlByUrl(this._selectedPage, item.value as string);
        }
      });
    } else if (this._currentElement) {
      this._modalService.open(id, true, (item: ItemRenderer) =>
        this._handleModalChoice(item, $event.addSubmask),
      );
    }
  }

  private _handleModalChoice(item: ItemRenderer, submaskChange: boolean) {
    if (!item || (item && !item.value && !item.svg)) {
      return;
    }
    switch (this._currentElement.type) {
      case elementTypeEnum.image:
        this._elementChangeFacadeService.changeImageUrl(
          this._currentElement as ImageElementModel,
          (item.high as string) || (item.value as string),
        );
        break;
      case elementTypeEnum.gif:
        this._elementChangeFacadeService.changeGifUrl(
          this._currentElement as ImageElementModel,
          (item.high as string) || (item.value as string)
        );
        break;
    }
  }

  private _isSingleGroup() {
    return this._newElementService.isSingleGroupSelection(this._selectedElement);
  }

  public expandPanel() {
    this._panelStackService.expandPanel();
  }
}
