import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

const margin = 8;

@Directive({
  selector: '[traktoTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltipText = '';
  @Input() tooltipPosition: 'up' | 'right' | 'down' | 'left' = 'up';

  private _myPopup: any;
  private _timer: any;

  constructor(private _el: ElementRef) {}

  ngOnDestroy(): void {
    if (this._myPopup) this._myPopup.remove();
  }

  @HostListener('mouseenter') onMouseEnter() {
    this._timer = setTimeout(() => {
      const [x, y] = this.getTooltipCoordinates();

      this.createTooltipPopup(x, y);
    }, 50);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this._timer) clearTimeout(this._timer);
    if (this._myPopup) {
      this._myPopup.remove();
    }
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement('div');

    popup.innerHTML = this.tooltipText;
    popup.setAttribute(
      'class',
      'tooltip-container tooltip-container-' + this.tooltipPosition,
    );
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';

    document.body.appendChild(popup);
    this._myPopup = popup;

    setTimeout(() => {
      if (this._myPopup) this._myPopup.remove();
    }, 5000);
  }

  private getTooltipCoordinates(): number[] {
    let x: number;
    let y: number;

    switch (this.tooltipPosition) {
      case 'up':
        x =
          this._el.nativeElement.getBoundingClientRect().left +
          this._el.nativeElement.offsetWidth / 2;

        y =
          this._el.nativeElement.getBoundingClientRect().top -
          (this._el.nativeElement.offsetHeight + margin * 3);
        break;
      case 'right':
        x =
          this._el.nativeElement.getBoundingClientRect().left +
          this._el.nativeElement.offsetWidth +
          margin * 2;

        y =
          this._el.nativeElement.getBoundingClientRect().top +
          this._el.nativeElement.offsetHeight / 2;
        break;
      case 'down':
        x =
          this._el.nativeElement.getBoundingClientRect().left +
          this._el.nativeElement.offsetWidth / 2;

        y = this._el.nativeElement.getBoundingClientRect().bottom + margin * 2;
        break;
      case 'left':
        x = this._el.nativeElement.getBoundingClientRect().left - margin * 2;

        y =
          this._el.nativeElement.getBoundingClientRect().top +
          this._el.nativeElement.offsetHeight / 2;
        break;

      default:
        x =
          this._el.nativeElement.getBoundingClientRect().left +
          this._el.nativeElement.offsetWidth / 2;

        y =
          this._el.nativeElement.getBoundingClientRect().top -
          this._el.nativeElement.offsetHeight * 2;
        break;
    }

    return [x, y];
  }
}
