<div
  class="links-options"
  [ngClass]="{ 'links-options--highlighted': isInputLinkFocused }"
>
  <input
    type="text"
    placeholder="{{ 'text-menu-inline.links.placeholder' | translate }}"
    class="links-options__input"
    [(ngModel)]="inputedLink"
    (focus)="inputLinkFocus()"
    (blur)="inputLinkBlur()"
    (keyup)="addMaskUrl($event)"
  />
  <button
    class="links-options__button"
    [ngClass]="{
      'links-options__button--disabled': !isInputLinkActive && !isPreviousLink,
      'links-options__button--to-remove': isPreviousLink
    }"
    (click)="applyLink()"
  >
    {{
      isPreviousLink
        ? ('text-menu-inline.links.remove' | translate)
        : ('text-menu-inline.links.apply' | translate)
    }}
  </button>
</div>
