import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import {
  textElementPropertiesEnum
} from '@app/editor/enums/editor-elements/text-element-properties';
import {
  ElementModelFinderService
} from '@services/element-model-finder.service';
import { FontsService } from '@services/fonts.service';
import { ColorService } from '@shared/color/services/color.service';
import {
  DimensionModel,
  FONT_SIZES,
  getFontStyle,
  TextOperations,
  weightList,
} from '@trakto/core-editor';
import {
  alignEnum,
  IDocumentModel,
  IFontModel,
  IFontOptions,
  ITextDecorationModel,
  ITextElementModel,
  PageModel,
} from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IQuillTextFormat {
  align?: string;
  font?: string;
  lh?: number;
  list?: string;
  color?: string | string[];
  weight?: string;
  italic?: boolean;
  bold?: boolean;
  underline?: boolean;
  strike?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TextService extends TextOperations implements OnDestroy {
  private readonly _auxiliaryCanvas: any;
  private readonly _tempComponent: HTMLElement;
  private readonly _wrapper: HTMLElement;
  private readonly _quill: any;
  private _docFontList: IFontModel[];
  private _destroy$ = new Subject<void>();

  constructor(
    private _fontService: FontsService,
    private _colorService: ColorService,
    private _elementModelFinderService: ElementModelFinderService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    super(_elementModelFinderService);
    if (isPlatformBrowser(platformId)) {
      this._auxiliaryCanvas = document.createElement('canvas');
      this._tempComponent = document.createElement('div');
      this._quill = new globalThis.Quill(this._tempComponent);
      this._wrapper = document.createElement('div');
      this._wrapper.style.overflowWrap = 'break-word';
      this._wrapper.style.whiteSpace = 'pre-wrap';
      this._wrapper.style.overflow = 'visible';
      this._wrapper.style.visibility = 'hidden';
      this._wrapper.style.height = 'auto';
      this._wrapper.classList.add('ql-editor');
      document.body.appendChild(this._wrapper);
      this._initQuill();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public setDocFontList(document: IDocumentModel): boolean {
    const allFontModels = document.body.reduce(
      (previous, current) => previous.concat(current.fontModels || []),
      [],
    );
    this._setDocFontList(allFontModels);
    return true;
  }

  public appendFontList(page: PageModel): boolean {
    this._setDocFontList(page.fontModels);
    return true;
  }

  public getTextHeight(textElement: ITextElementModel): number {
    return this.getBoxHeight(textElement);
  }

  public getParsedHTML(
    textElement: ITextElementModel,
    applyFontSize = true,
    applyShadow = true,
    applyBorder = true,
  ): string {
    this._quill.setContents(textElement.textQuillEncoded);
    if (applyFontSize) {
      this._applyFontSize(this._quill.root, textElement);
    }
    if (applyShadow) {
      this._applyShadow(this._quill.root, textElement);
    }
    if (applyBorder) {
      this._applyBorder(this._quill.root, textElement);
    }
    return this._quill.root.innerHTML;
  }

  public getBoxHeight(textElement: ITextElementModel, full = false): number {
    this._quill.setContents(textElement.textQuillEncoded);
    this.applyHtmlTextChanges(this._quill.root, textElement);
    this._wrapper.innerHTML = this._quill.root.innerHTML;
    this._wrapper.style.width = `${textElement.supportWidth}px`;
    return (
      this._wrapper.offsetHeight - this.getFontHalfLeading(textElement)
    );
  }

  public getFormat(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
  ): IQuillTextFormat {
    this._configQuillContent(textElement);
    return this._getQuill(quill).getFormat(start, end);
  }

  public updateTextEncoded(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
  ): void {
    this.updateAlign(textElement, quill, start, end);
    this.updateColor(textElement, quill, start, end);
    this.updateFontFamily(textElement, quill, start, end);
    this.updateFontStyle(textElement, quill, start, end);
    this.updateLineHeight(textElement, quill, start, end);
  }

  public updateTextEncodedByProperty(
    textElement: ITextElementModel,
    property: textElementPropertiesEnum,
  ): void {
    switch (property) {
      case textElementPropertiesEnum.align:
        this.updateAlign(textElement);
        break;
      case textElementPropertiesEnum.fontStyle:
        this.updateFontStyle(textElement);
        break;
      case textElementPropertiesEnum.font:
        this.updateFontFamily(textElement);
        break;
      case textElementPropertiesEnum.lineSpace:
        this.updateLineHeight(textElement);
        break;
      case textElementPropertiesEnum.color:
        this.updateColor(textElement);
        break;
    }
  }
  public updateLineHeight(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    lineHeight?: number,
  ): void {
    this._configQuillContent(textElement);
    this._getQuill(quill).formatText(
      start,
      end,
      'lh',
      lineHeight || textElement.lineSpace,
    );
    this._updateTextElement(textElement);
  }

  public updateColor(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    color?: string,
  ): void {
    this._configQuillContent(textElement);
    this._getQuill(quill).formatText(
      start,
      end,
      'color',
      color || textElement.color,
    );
    this._updateTextElement(textElement);
  }

  public updateBullets(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    bullets = true,
  ) {
    this._configQuillContent(textElement);

    bullets
      ? this._getQuill(quill).formatText(start, end, 'list', 'bullet')
      : this._getQuill(quill).formatText(start, end, 'list', false);

    this._updateTextElement(textElement);
  }

  public updateFontFamily(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    fontFamily?: string,
  ): void {
    this._configQuillContent(textElement);
    this._getQuill(quill).formatText(
      start,
      end,
      'font',
      fontFamily || textElement.fontFamily,
    );
    this._updateTextElement(textElement);
  }

  public updateUnderline(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    underline = false,
  ): void {
    this._configQuillContent(textElement);
    this._quill.formatText(start, end, 'underline', underline);
    this._updateTextElement(textElement);
  }

  public updateLineThrough(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    lineThrough = false,
  ): void {
    this._configQuillContent(textElement);
    this._getQuill(quill).formatText(
      start,
      end,
      'strike',
      lineThrough,
    );
    this._updateTextElement(textElement);
  }

  public updateAlign(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    align?: alignEnum,
  ): void {
    this._configQuillContent(textElement);
    this._getQuill(quill).formatText(
      start,
      end,
      'align',
      align || textElement.align,
    );
    this._updateTextElement(textElement);
  }

  public updateFontStyle(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    fontStyle?: IFontOptions,
  ): void {
    this._configQuillContent(textElement);
    const decodedFontStyle = getFontStyle(fontStyle || textElement.fontStyle);
    this._getQuill(quill).formatText(
      start,
      end,
      'weight',
      decodedFontStyle.weight !== 'normal' ? decodedFontStyle.weight : false,
    );
    this._quill.formatText(
      start,
      end,
      'italic',
      decodedFontStyle.style === 'italic',
    );
    this._updateTextElement(textElement);
  }

  public updateTextDecoration(
    textElement: ITextElementModel,
    quill?: any,
    start = 0,
    end = 10000000,
    textDecoration?: ITextDecorationModel,
  ): void {
    this._configQuillContent(textElement);
    const currentTextDecoration = textDecoration || textElement.decoration;
    this._getQuill(quill).formatText(
      start,
      end,
      'underline',
      !!currentTextDecoration.underline,
    );
    this._getQuill(quill).formatText(
      start,
      end,
      'strike',
      !!currentTextDecoration.lineThrough,
    );
    this._updateTextElement(textElement);
  }

  public applyHtmlTextChanges(
    htmlContainer: Element,
    textElement: ITextElementModel,
  ): void {
    // Previne que o 'Google translate' exclua o texto digitado
    htmlContainer.classList.add('notranslate');

    this._applyFontSize(htmlContainer, textElement);
    this._applyShadow(htmlContainer, textElement);
    this._applyBorder(htmlContainer, textElement);
  }

  // Baseado em https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
  public getTextWidth(text: string, textElement: ITextElementModel) {
    const metrics =
      this._getConfiguredCanvasContext(textElement).measureText(text);
    return metrics.width;
  }

  public getLineDimension(
    textElement: ITextElementModel,
    text: string,
  ): DimensionModel {
    const decodedFontStyle = getFontStyle(textElement.fontStyle);
    const div = document.createElement('div');
    div.innerHTML = text;
    div.style.position = 'absolute';
    div.style.top = '-9999px';
    div.style.left = '-9999px';
    div.style.font = `${decodedFontStyle.style} ${decodedFontStyle.weight}  ${textElement.fontSize}pt ${textElement.fontFamily}`;
    document.body.appendChild(div);
    const size = new DimensionModel(div.offsetWidth, div.offsetHeight);
    document.body.removeChild(div);
    return size;
  }

  private _initQuill() {
    const Size = globalThis.Quill.import('attributors/style/size');
    const FontFamily = globalThis.Quill.import('attributors/style/font');
    const FontWeight = globalThis.Quill.import('attributors/style/weight');
    const LineHeight = globalThis.Quill.import('attributors/style/lineheight');
    const Aligns = globalThis.Quill.import('formats/align');
    const Block = globalThis.Quill.import('blots/block');
    const sizes = FONT_SIZES;

    Size.whitelist = [];
    FontWeight.whitelist = weightList;
    Aligns.whitelist = ['right', 'left', 'center', 'justify'];
    Block.tagName = 'DIV';
    sizes.map((value: number) => {
      Size.whitelist.push(value.toString() + 'pt');
    });

    globalThis.Quill.register(Size, true);
    globalThis.Quill.register(FontFamily, true);
    globalThis.Quill.register(FontWeight, true);
    globalThis.Quill.register(LineHeight, true);
    globalThis.Quill.register(Aligns, true);
    globalThis.Quill.register(Block, true);

    this._fontService.listAllFontsFamilies().pipe(takeUntil(this._destroy$)).subscribe(fontsFamilysList => {
      FontFamily.whitelist = fontsFamilysList.concat(
        (this._docFontList || []).map(fm => fm.family),
      );
    });
  }

  private _setDocFontList(fontModels: IFontModel[]) {
    this._docFontList = fontModels;
    const FontFamily = globalThis.Quill.import('attributors/style/font');
    FontFamily.whitelist = FontFamily.whitelist.concat(
      fontModels?.map(fm => fm.family).filter(family => !FontFamily.whitelist.includes(family)),
    );
  }

  private _configQuillContent(textElement: ITextElementModel) {
    this._quill.setContents(textElement.textQuillEncoded);
  }

  private _updateTextElement(textElement: ITextElementModel) {
    textElement.text = this._quill.getText();
    textElement.textQuillEncoded = this._quill.getContents().ops;
  }

  private _getConfiguredCanvasContext(textElement: ITextElementModel): any {
    const canvas = this._auxiliaryCanvas;
    const context = canvas.getContext('2d');
    const decodedFontStyle = getFontStyle(textElement.fontStyle);
    context.font = `${decodedFontStyle.style} ${decodedFontStyle.weight}  ${textElement.fontSize}pt ${textElement.fontFamily}`;
    return context;
  }

  private _getFontBaseLine(textElement: ITextElementModel) {
    const div = document.createElement('div');
    const body = document.getElementsByTagName('body')[0];
    const decodedFontStyle = getFontStyle(textElement.fontStyle);

    // REVIEW - User array join();
    div.setAttribute(
      'style',
      ` font: ${decodedFontStyle.style} ${decodedFontStyle.weight}  ${textElement.fontSize}pt ${textElement.fontFamily}; line-height: ${textElement.lineSpace}em`,
    );
    body.appendChild(div);
    const result = this._calcFontBaseline(div);
    body.removeChild(div);
    return result;
  }

  // https://github.com/georgecrawford/font-baseline/blob/master/index.js
  private _calcFontBaseline(container) {
    const div = document.createElement('div');
    const style = document.createElement('style');
    const strut = document.createElement('span');
    let computedStyle;
    let baselineHeight;
    let strutHeight;
    let fontSize;
    let lineHeight;

    container = container || document.body;

    style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    style.sheet['insertRule'](
      '.font-baseline{visibility:hidden;height:100px;}',
      0,
    );
    style.sheet['insertRule'](
      `.font-baseline span:after{content:'';height:100%;display:inline-block;}`,
      1,
    );

    strut.textContent = 'T';
    div.appendChild(strut);
    div.classList.add('font-baseline');
    container.appendChild(div);

    computedStyle = window.getComputedStyle(strut);
    fontSize = parseInt(computedStyle.fontSize, 10);
    lineHeight = parseInt(computedStyle.lineHeight, 10);

    strut.style.lineHeight = `0`;

    strutHeight = strut.offsetHeight;
    baselineHeight =
      strut.offsetTop + strutHeight - div.offsetHeight - div.offsetTop;

    lineHeight = lineHeight || strutHeight;

    div.parentNode.removeChild(div);
    style.parentNode.removeChild(style);

    return {
      baseline: baselineHeight,
      content: strutHeight,
      font: fontSize,
      line: lineHeight,
      multiplier: fontSize / strutHeight,
      offset: (lineHeight - strutHeight) / 2 + baselineHeight,
    };
  }

  private _applyFontSize(
    htmlContainer: Element,
    textElement: ITextElementModel,
  ) {
    const nodes = Array.from(htmlContainer.querySelectorAll('div')).concat(
      Array.from(htmlContainer.querySelectorAll('div *')),
    );

    nodes.forEach((value: HTMLElement) => {
      value.style.fontSize = `${textElement.fontSize}pt`;
    });
  }

  /**
   * seguindo a lógica do https://css3gen.com/text-shadow/
   * textElement.shadow = {
   * distance: 0.3,
   * angle: 90,
   * opacity: 0.4,
   * blur: 0.1,
   * color: "#9a2727",
   * }
   */
  private _applyShadow(htmlContainer: Element, textElement: ITextElementModel) {
    const container = htmlContainer.querySelector('div');

    if (!textElement.shadow) {
      return;
    }
    const color = this._colorService.convertHexToRGBA(
      textElement.shadow.color,
      textElement.shadow.opacity,
    );
    const distance = textElement.shadow.distance * 8;
    const blur = textElement.shadow.blur * 8;
    const angle = (textElement.shadow.angle * Math.PI) / 180;
    const x = distance * Math.cos(angle);
    const y = distance * Math.sin(angle);
    container['style'].textShadow = `${color} ${x}px ${y}px ${blur}px`;
  }

  /**
   * textElement.stroke = {
   * width: 0.1,
   * color: "#000",
   * };
   */
  private _applyBorder(htmlContainer: Element, textElement: ITextElementModel) {
    const container = htmlContainer.querySelector('div');

    if (!textElement.stroke) {
      return;
    }
    const color = textElement.stroke.color;
    const width = (textElement.stroke.width * textElement.fontSize) / 20;
    container['style'].caretColor = color;
    container['style'].webkitTextStroke = `${width}px ${color}`;
    container['style'].webkitTextFillColor = 'transparent';
  }

  private _getQuill(quill?: any) {
    return quill || this._quill;
  }

}
