<div class="panel-elements">
  <div
    *ngIf="isSearching"
    class="blackout"
    [ngStyle]="{
      backgroundColor: isSearching ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)'
    }"
  ></div>

  <trakto-banner></trakto-banner>

  <div class="panel-elements__content">
    <trakto-searchbar
      (isSearchbarFocused)="isSearchbarFocused($event)"
      (searchTag)="searchTag($event)"
    ></trakto-searchbar>

    <trakto-formats-folders
      (searchTag)="searchTag($event)"
    ></trakto-formats-folders>

    <trakto-themes-folders
      (searchTag)="searchTag($event)"
    ></trakto-themes-folders>
  </div>

  <trakto-search-results
    [isActive]="currentSearch?.type === 'main'"
    [currentSearch]="currentSearch"
    (requestClose)="searchTag()"
    (assetSelected)="assetSelected($event)"
    [ngStyle]="{ 'z-index': currentSearch?.type === 'main' ? '805' : '802' }"
  ></trakto-search-results>

  <trakto-formats-results
    [isActive]="currentSearch?.type === 'folder'"
    [currentSearch]="currentSearch"
    (requestClose)="searchTag()"
    (assetSelected)="assetSelected($event)"
    [ngStyle]="{ 'z-index': currentSearch?.type === 'folder' ? '805' : '802' }"
  ></trakto-formats-results>

  <trakto-theme-results
    [isActive]="currentSearch?.type === 'theme'"
    [currentSearch]="currentSearch"
    (requestClose)="searchTag()"
    (assetSelected)="assetSelected($event)"
    [ngStyle]="{ 'z-index': currentSearch?.type === 'theme' ? '805' : '802' }"
  ></trakto-theme-results>

  <trakto-scroll-up
    *ngIf="isScrollUpVisible"
    [currentSearch]="currentSearch"
  ></trakto-scroll-up>

</div>
