import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelColorComponent } from '@app/editor/components/properties-panel/panel-color/panel-color.component';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { isHexadecimalValid } from '@app/shared/color/validators/color.validator';
import { ITextElementModel, PageModel } from '@trakto/models';

@Component({
  selector: 'trakto-inline-color-picker',
  templateUrl: './inline-color-picker.component.html',
  styleUrls: ['./inline-color-picker.component.scss'],
})
export class InlineColorPickerComponent {
  @Input() current: string;
  @Input() showNullColor = false;
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  @Input() private textElement: ITextElementModel;
  @Input() page: PageModel;

  public colors = [
    { color: '#fe6e46' },
    { color: '#7251e7' },
    { color: '#fe43d1' },
    { color: '#ffc13d' },
    { color: '#1dc763' },
    { color: '#d0daef' },
    { color: '#04183e' },
  ];

  constructor(
    private hotkeyService: HotkeyService,
    private panelStackService: PanelStackService,
  ) {}

  public inputLinkFocus(): void {
    this.hotkeyService.disableHotkeys();
  }

  public inputLinkBlur(): void {
    this.hotkeyService.enableHotkeys();
  }

  changeColor(event: any): boolean {
    const value = event.target.value;

    if (!isHexadecimalValid(value)) {
      return false;
    }

    this.changed.emit(value);

    return true;
  }

  openColorPanel() {
    this.panelStackService.stack(PanelColorComponent, {
      inputs: {
        pageSelected: this.page,
        elementSelected: this.textElement,
      },
      outputs: {
        colorChange: {
          emit: (color: string) => {
            this.changed.emit(color);
          },
        },
      },
    });
  }
}
