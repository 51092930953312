import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/shared/auth.service';
import { LocalStorageService } from '@app/shared/local-storage/local-storage-service.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { UserService } from '@app/editor-v3/services/user.service';

const STORAGE_KEY = 'trakto_bella_welcome';

@Component({
  selector: 'trakto-modal-bella',
  templateUrl: './modal-bella.component.html',
  styleUrls: ['./modal-bella.component.scss'],
})
export class ModalBellaComponent implements OnInit, OnDestroy {
  timeToShowCta = 3000;
  isCtaVisible = false;
  isBellaVisible = false;
  isBellaWorking = false;
  isBellaSuccedded = false;
  bellaAppUrl = '';
  pagesCount = 0;
  windowHeight = 1000;
  windowLimit = 540;
  username = '';
  private _destroy$ = new Subject<void>();

  constructor(
    private _documentStateManagerService: DocumentStateManagerService,
    private _authService: AuthService,
    private _userService: UserService,
    private _authFirebaseService: AuthFirebaseService,
    private _localStorageService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getAccessTokenToIframe();
    this.showDelayedCta();
    this.watchPostedMessages();
    this.initSubscriptions();

    this.windowHeight = window.innerHeight;
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  async initSubscriptions() {
    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(doc => {
      if (doc.body) this.pagesCount = doc.body.length;
    });
  }

  destroySubscriptions() {
    this._destroy$.next();
  }

  getBellaIcon(isBellaSuccedded: boolean, isBellaWorking: boolean): string {
    if (isBellaSuccedded)
      return 'assets/images/modal-bella/bella-icon-success.png';

    if (isBellaWorking) return 'assets/images/modal-bella/bella-icon-empty.png';

    return 'assets/images/modal-bella/bella-icon-default.png';
  }

  setIsCtaVisible(val: boolean) {
    this.isCtaVisible = val;
  }

  showDelayedCta() {
    if (this.isFirstAccess()) {
      setTimeout(() => {
        this.setIsCtaVisible(true);
        this.writeLocalStorage();
      }, this.timeToShowCta);
    }
  }

  handleBellaVisibility(val: boolean) {
    this.setIsCtaVisible(false);
    this.isBellaVisible = val;

    this.isBellaSuccedded = false;
    this.isBellaWorking = false;
  }

  watchPostedMessages() {
    window.addEventListener('message', e => {
      this.updateBellaStatus(e);
    });
  }

  updateBellaStatus(message: any): boolean {
    const { data, origin } = message;

    const isMessageFromIframeUrl = this.bellaAppUrl.indexOf(origin) > -1;

    if (!isMessageFromIframeUrl) return false;

    if (data.key === 'isBellaWorking' && typeof data.value === 'boolean') {
      this.isBellaWorking = data.value;
      this.isBellaSuccedded = false;
    }

    if (data.key === 'isBellaSuccedded' && typeof data.value === 'boolean') {
      this.isBellaWorking = false;
      this.isBellaSuccedded = data.value;
    }

    return true;
  }

  @HostListener('window:resize', ['$event'])
  public getWindowHeight(event: any) {
    this.windowHeight = event.target.innerHeight;
    this.windowLimit = this.pagesCount > 1 ? 670 : 540;
  }

  async getAccessTokenToIframe(): Promise<boolean> {
    const access_token = await this._authFirebaseService.getAccessToken();
    this.bellaAppUrl =
      environment.api.webBella + '?accessToken=' + access_token;
    return true;
  }

  isFirstAccess(): boolean {
    return !this._localStorageService.getObject(STORAGE_KEY);
  }

  writeLocalStorage() {
    this._localStorageService.setObject(STORAGE_KEY, true);
  }
}
