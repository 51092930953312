import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import Scrollbar from 'smooth-scrollbar';

@Directive({ selector: '[traktoScrollBar]' })
export class ScrollbarDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    Scrollbar.init(this.el.nativeElement, {
      alwaysShowTracks: false,
      plugins: {
        damping: 0.5,
        overscroll: false,
        maxOverscroll: 150,
        renderByPixels: true,
      },
    });
  }

  ngOnDestroy() {
    Scrollbar.destroy(this.el.nativeElement);
  }
}
