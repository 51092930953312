import { Injectable } from '@angular/core';
import { FilterModel } from '@editor/model/filter.model';
import { ElementModelService } from '@services/element-model.service';
import { SVG } from '@svgdotjs/svg.js';
import { ISVGOperations } from '@trakto/core-editor';
import { PageModel } from '@trakto/models';

@Injectable({
  providedIn: 'root',
})
export class SVGService implements ISVGOperations {
  constructor(private _elementService: ElementModelService) {}

  public applyFilters(page: PageModel, svg: string): string {
    return new XMLSerializer().serializeToString(
      this._applyFilter(page, this._htmlToElement(svg)).firstChild,
    );
  }

  private _applyFilter(
    page: PageModel,
    node: DocumentFragment,
  ): DocumentFragment {
    const allElements = this._elementService.getAllElements(
      page.elements,
      false,
      false,
    );
    const elementsWithFilter = allElements.filter(
      element => element.filter && element.filter !== 'empty',
    );
    elementsWithFilter.forEach(element => {
      const elementWithFilter = SVG(node.querySelector(`g#${element.id}`));
      const filter = new FilterModel(elementWithFilter);
      filter.apply(element.filter);
    });
    const background = SVG(node.querySelector(`g#${page.id}__bg`));
    if (
      page.backgroundImage &&
      background &&
      page.filter &&
      page.filter !== 'empty'
    ) {
      new FilterModel(background).apply(page.filter);
    }
    return node;
  }

  private _htmlToElement(html: string): DocumentFragment {
    const template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content;
  }
}
