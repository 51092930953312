import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { IDocumentModel, PageModel } from '@trakto/models';
import { ZoomService as ZoomServiceV2 } from '@app/editor/services/zoom.service';
import { DOCUMENT } from '@angular/common';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoomService implements OnDestroy {
  private _document: IDocumentModel;
  private _page: PageModel;

  private _documentElement: any;
  public isFullScreen = false;
  private _destroy$ = new Subject<void>();


  constructor(
    @Inject(DOCUMENT) private _documentInstance: any,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _zoomServiceV2: ZoomServiceV2,
  ) {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  initSubscriptions() {
    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(
      doc => (this._document = doc),
    );

    this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(page => {
      this._page = page;
      this.fitPage();
    });
  }

  fitPage() {
    this._zoomServiceV2.handleZoomFit(this._page, this._document);
  }

  applyZoomPercentage(zoom: number) {
    const event = {
      event: 'ZoomChanged',
      data: zoom,
      bubbles: false,
    };

    this._zoomServiceV2.changeZoom(event, this._page, this._document);
  }

  toggleFullscreen() {
    this._documentElement = this._documentInstance.documentElement;
    this.isFullScreen ? this.closeFullscreen() : this.openFullscreen();
    this.isFullScreen = !this.isFullScreen;
  }

  openFullscreen(): boolean {
    if (this._documentElement) {
      if (this._documentElement.requestFullscreen) {
        this._documentElement.requestFullscreen();
        return true;
      }

      if (this._documentElement.mozRequestFullScreen) {
        this._documentElement.mozRequestFullScreen();
        return true;
      }

      if (this._documentElement.webkitRequestFullscreen) {
        this._documentElement.webkitRequestFullscreen();
        return true;
      }

      if (this._documentElement.msRequestFullscreen) {
        this._documentElement.msRequestFullscreen();
        return true;
      }
    }

    return false;
  }

  closeFullscreen(): boolean {
    if (this._documentElement) {
      if (this._documentInstance.exitFullscreen) {
        this._documentInstance.exitFullscreen();
        return true;
      }

      if (this._documentInstance.mozCancelFullScreen) {
        this._documentInstance.mozCancelFullScreen();
        return true;
      }

      if (this._documentInstance.webkitExitFullscreen) {
        this._documentInstance.webkitExitFullscreen();
        return true;
      }

      if (this._documentInstance.msExitFullscreen) {
        this._documentInstance.msExitFullscreen();
        return true;
      }
    }

    return false;
  }
}
