import { Injectable } from "@angular/core";
import { TraktoApiService } from "../trakto-api.service";
import { IProducts } from "@trakto/models";

@Injectable({
    providedIn: 'root'
})
export class ApiWhitelabelService {

    constructor(
        private _apiService: TraktoApiService,
    ) {}

    products(): Promise<IProducts[]> {
        return this._apiService.executeGet<IProducts[]>('product/all');
    }
}