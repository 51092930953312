import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export abstract class FacebookPixel {
    abstract trackPageView(): void;
    abstract trackCustomEvent(eventName: string): void;
    abstract trackPurchase(value: number, currency: string): void;
    constructor() {
      this._addScript();
    }
  
    protected getInstance() {
      return window['fbq'];
    }
  
    public async init() {
      const fbq = this.getInstance();
      if (fbq) {
        await fbq('init', '931490927737557');
      }
    }
  
    private _addScript() {
      const fbPixelScript = document.createElement('script');
      fbPixelScript.innerHTML = `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');`;
      document.body.appendChild(fbPixelScript);
    }
  }
  