import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[traktoLoader]',
})
export class LoaderDirective implements OnInit {
  _active: boolean;
  _message: string;

  container: any;
  loader: HTMLElement;
  spinner: HTMLElement;
  message: HTMLElement;

  load: HTMLElement;
  dot: HTMLElement;
  outline: HTMLElement;
  span: HTMLElement;

  @Input()
  set traktoLoader(value: boolean) {
    this._active = value;
    this.displayLoader();
  }

  get traktoLoader(): boolean {
    return this._active;
  }

  @Input()
  set traktoLoaderMessage(value: string) {
    this._message = value;
  }

  get traktoLoaderMessage(): string {
    return this._message || 'Loading';
  }

  constructor(private el: ElementRef) {
    this.createLoader();
  }

  ngOnInit() {
    this.displayLoader();
  }

  createLoader() {
    this.container = this.el.nativeElement;

    this.loader = document.createElement('div');
    this.loader.setAttribute('class', 'trakto-loader');

    this.spinner = document.createElement('div');
    this.spinner.setAttribute('class', 'trakto-loader__spinner');

    this.message = document.createElement('p');
    this.message.setAttribute('class', 'trakto-loader__message');

    this.loader.appendChild(this.spinner);
    this.loader.appendChild(this.message);
    this.container.appendChild(this.loader);
  }

  displayLoader() {
    this.loader.style.display = this.traktoLoader ? 'block' : 'none';
    this.message.innerHTML = this.traktoLoaderMessage;
  }
}
