<div
  class="panel-mobile"
  [ngStyle]="{ height: height ? height + 'px' : 'unset' }"
>
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/title-pages.svg"
    label="Páginas"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="grid">
      <div
        *ngFor="let page of document?.body; index as i"
        class="page"
        [attr.data-id]="page.id"
        [ngClass]="{
          selected: pageSelectMode
            ? pagesSelected.indexOf(i) > -1
            : pageSelectedIndex === i
        }"
        (click)="selectPage(i)"
        [traktoDragDrop]="page"
      >
        <div class="icon">
          <trakto-render-svg-icon [svg]="thumbs[i]"></trakto-render-svg-icon>
        </div>
        <p class="number">{{ page.order }}</p>
      </div>

      <div class="page empty" (click)="addPage()">
        <div class="icon">+</div>
        <p class="number"></p>
      </div>
    </div>
  </div>

  <div class="actions options-grid">
    <trakto-panel-option-mobile
      icon="select"
      [label]="
        pageSelectMode ? 'Selec. (' + pagesSelected.length + ')' : 'Selecionar'
      "
      [active]="pageSelectMode"
      (click)="togglePageSelectMode()"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      icon="delete"
      [label]="
        'Excluir' + (pageSelectMode ? ' (' + pagesSelected.length + ')' : '')
      "
      (click)="deleteConfirmation()"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      icon="copy"
      [label]="
        'Duplicar' + (pageSelectMode ? ' (' + pagesSelected.length + ')' : '')
      "
      (click)="handleDuplicate()"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      icon="adjust"
      label="Ajustes"
      [disabled]="pageSelectMode"
      (click)="openPropertiesPagePanel()"
    ></trakto-panel-option-mobile>
  </div>
</div>
