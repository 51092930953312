import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomTokenService {

  private customToken: string = '';

  constructor() { }

  public setCustomToken(token: string) {
    this.customToken = token;
  }

  public getCustomToken() {
    return this.customToken;
  }
}
