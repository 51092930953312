<div class="drag-drop-container" cdkDropList
#list="cdkDropList"
[cdkDropListData]="items"
[cdkDropListConnectedTo]="[list]"
(cdkDropListDropped)="drop($event)">
  <div class="drag-box" *ngFor="let item of items; let i = index" cdkDrag>
    <div cdkDragHandle class="drag-icon">
      <mat-icon>more_vert</mat-icon>
    </div>
    <ng-container *ngTemplateOutlet="template; context: { itemName: item }"></ng-container>
  </div>
</div>
