import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationTokenService {
  private readonly _baseUrlAPI = environment.api.BASE_URL_TOKEN_AUTH_API;
  constructor(
    private _httpClient: HttpClient,
    public afAuth: AngularFireAuth,
  ) {}

  // When working with tokens, you need to differentiate each token and what it is used for, also each token has related properties.
  // Custom Tokens: These tokens will be generated in the server (as you are doing) and are used in the client side to authenticate the client. These tokens expire after one hour.
  // Session ID Token/idToken: (I'm calling it "Session ID Token" just to differentiate it) When the client autheticates with any of the authentication providers, the SDK will exchange the information for an ID token used for the session. The same applies for custom tokens, where the SDK exchanges the custom token for an ID Token. ID Tokens are also short live and will expire after one hour. When getting the ID Token, the SDK also receives a refresh Token which is used for refreshing the session ID Token. The ID token is used when doing authenticated requests to Firebase.
  // https://stackoverflow.com/questions/49956621/firebase-admin-sdk-idtoken-or-custom-token-verification-for-authorization

  public createCookieByIdToken(data: { idToken: string }): Observable<any> {
    return new Observable((observer: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      this._httpClient
        .post(
          `${this._baseUrlAPI}/cookie/create/by_id_token`,
          { data },
          httpOptions,
        )
        .toPromise()
        .then((response: any) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public authTokenByCookie(data: any): Observable<any> {
    return new Observable((observer: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      this._httpClient
        .post(`${this._baseUrlAPI}/auth_token/by_cookie`, { data }, httpOptions)
        .toPromise()
        .then((response: any) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public getUserByIdTokenCookie(cookie: string): Observable<any> {
    return this._httpClient
      .post(
        `${this._baseUrlAPI}/user/list/by_cookie_id_token`,
        { data: { cookie } },
      ).pipe(map((response: any) => response.user));
  }

  public userByIdToken(data: { idToken: string }): Observable<any> {
    return new Observable((observer: any) => {
      this._httpClient
        .post(`${this._baseUrlAPI}/user/list/by_id_token`, { data })
        .toPromise()
        .then((response: any) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public async idToken(): Promise<string> {
    return await this.afAuth.auth.currentUser.getIdToken();
  }

  public getCurrentTokenId(): Observable<string> {
    return this.afAuth.idToken;
  }

  public async customTokenFromAccessToken(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const { data } = await this._httpClient
      .get<{ data: string }>(`${environment.api.baseUrl}auth/firebase-token`, { headers })
      .toPromise();
    return data;
  }
}
