import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  public userStore: any;

  constructor(
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public configOnboarding(w: Window, user: any): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      this.userStore = user;

      if (environment.api.productFruits.ennabled) {
        const onBoardingCode = environment.api.productFruits.code;
        const onBoardingLang = this.userStore.current_profile.current_locale || 'pt-BR';
        const langs = {
          'en-US': 'en',
          en: 'en',
          'pt-BR': 'pt',
        };

        w['productFruitsUser'] = {
          username: this.userStore.id,
          email: this.userStore.email,
          firstname: this.userStore.firstname,
          lastname: this.userStore.lastname
        };

        w['productFruits'] = w['productFruits'] || {};
        w['productFruits'].language = langs[onBoardingLang];
        w['productFruits'].code = onBoardingCode;

        let containerElement = this._document.getElementsByTagName('head')[0];
        let scriptElement = this._document.createElement('script');

        scriptElement.async = false;
        scriptElement.defer = true;
        scriptElement.onload = (event) => {
          resolve(event);
        };
        scriptElement.onerror = (event) => {
          reject(event);
        }
        scriptElement.src = `https://app.productfruits.com/static/script.js?c=${onBoardingCode}`;
        containerElement.appendChild(scriptElement);
      } else {
        resolve(false);
      }
    });
  }

  public updateOnboardingInfo(w: any, url: string): void {

    if (environment.api.productFruits.ennabled) {

      if (w['productFruits'].identifyUser) {
        if (environment.api.productFruits.logger) {
          w['productFruits'].services.setLoggerLevel('verbose');
        } else {
          w['productFruits'].services.resetLoggerLevel();
        }
  
        const currentUser = {
          username: this.userStore.id,
          email: this.userStore.email,
          firstname: this.userStore.firstname,
          lastname: this.userStore.lastname,
          props: {
            url,
          },
        };
  
        w['productFruits'].pageChanged();
        w['productFruits'].identifyUser(currentUser);
      }
    }
  }
}
