import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { ImageElementModel } from '@trakto/models';
import { GalleryGifsService } from '@app/editor/services/gallery/gallery.gifs.service';

@Component({
  selector: 'trakto-search-stickers-tab-mobile',
  templateUrl: './search-stickers-tab-mobile.component.html',
  styleUrls: ['./search-stickers-tab-mobile.component.scss'],
})
export class SearchStickersTabMobileComponent implements OnInit {

  inputText = '';
  tags = [
    'Animação',
    'Aplausos',
    'Natureza',
    'Feliz',
    'Comidas',
    'Internet',
    'Televisão',
    'Memes',
    'Trabalho',
    'Marketing',
    'Criatividade',
    'Séries',
    'Cinema',
    'Futebol',
    'Artes',
    'Musica',
  ];
  loading = false;
  images = [];
  page = 1;
  maxPages = 1;

  @Output()
  private clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextPage: any;
  hasNextPage: boolean;

  constructor(
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
    private _galleryGifsService: GalleryGifsService,
  ) {
    this.fetchInputtedTag = debounce(this.fetchInputtedTag, 1000);
  }

  ngOnInit(): void {
    this.getGifs();
  }

  async getGifs() {
    this.loading = true;
    try {
      const nextPage = this.nextPage;
      this.hasNextPage = false;
      this.nextPage = undefined;
      const result = await this._galleryGifsService.listStickers(this.inputText === '' ? 'trakto' : this.inputText, nextPage);
      this.images = result.results;
      this.hasNextPage = result.hasNextPage > 0 ? true : false;
      this.nextPage += result.pagination;
    } catch (e) {
      console.log('error', e);
    } finally {
      this.loading = false;
    }
  }

  resetParameters() {
    this.images = [];
  }

  selectTag(tag: string, resetInput = true) {
    if (resetInput) this.inputText = '';
    this.inputText = tag;
    this.getGifs();
  }

  setInputText(val: string) {
    this.inputText = val;
    this.fetchInputtedTag();
  }

  fetchInputtedTag() {
    this.selectTag(this.inputText, false);
  }

  insertImage(image: any) {
    this._elementFactoryFacadeService.createAndAddGifByUrl(image.url, image.thumb);
    this.clicked.emit(true);
  }
}
