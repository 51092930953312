<trakto-modal
  id="trakto-modal-documents"
  class="trakto-modal-documents"
  [allowMinimize]="allowMinimize"
  (onClose)="closeModal()"
>
  <div class="trakto-modal-documents__body" body>
    <header class="trakto-modal-documents__header">
      <h1>
        <i class="trakto-icon-documents"></i>
        Meus documentos
      </h1>
    </header>

    <trakto-document-list
      class="trakto-modal-documents__list"
      #documentList
      [dataProvider]="dataProvider"
      (onClick)="click($event)"
      (onEdit)="edit($event)"
      (onSelect)="select($event)"
      (onCopyLink)="copyLink($event)"
      (onDownload)="download($event)"
      (onVisualize)="visualize($event)"
      (onClone)="clone($event)"
      (onRemoveMultiple)="removeMultiple($event)"
      (onRemove)="remove($event)"
    >
    </trakto-document-list>
  </div>
</trakto-modal>
