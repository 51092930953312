import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Particle } from './Particle';

@Component({
  selector: 'trakto-light-loader',
  templateUrl: './light-loader.component.html',
  styleUrls: ['./light-loader.component.scss'],
})
export class LightLoaderComponent implements OnInit {
  private _canvas: HTMLCanvasElement;
  private _loaderSpeed: number;
  private _particleRate: number;
  private _hueStart: number;
  private _hueEnd: number;
  private _dpr: number;

  public height: number;
  public width: number;

  public ctx: CanvasRenderingContext2D;
  public loaded: number;
  public loaderWidth: number;
  public loaderHeight: number;
  public loaderX: number;
  public loaderY: number;
  public particles: any[];
  public particleLift: number;
  public hue: number;
  public gravity: number;

  constructor(private _renderer: Renderer2, private _element: ElementRef) {}

  ngOnInit() {
    this._createCanvas();
    this._loop();
  }

  public rand(rMi: number, rMa: number) {
    // FIXME - Use Math.floor() instead double tilde(~~)
    // ~2 === -3; //true
    // ~1 === -2; //true
    // ~~2 === Math.floor(2); //true, 2

    // tslint:disable-next-line: no-bitwise
    return ~~(Math.random() * (rMa - rMi + 1) + rMi);
  }

  private _createCanvas() {
    this._canvas = this._renderer.createElement('canvas');
    this.ctx = this._canvas.getContext('2d');
    this.width = 210;
    this.height = 40;
    this.loaded = 0;
    this._loaderSpeed = 0.6;
    this.loaderWidth = 184;
    this.loaderHeight = 17;
    this.loaderX = this.width / 2 - this.loaderWidth / 2;
    this.loaderY = this.height / 2 - this.loaderHeight / 2;
    this.particles = [];
    this.particleLift = 120;
    this._particleRate = 3;
    this._hueStart = 0;
    this._hueEnd = 120;
    this.hue = 0;
    this.gravity = 0.12;
    this._dpr = window.devicePixelRatio;

    this._renderer.appendChild(this._element.nativeElement, this._canvas);
    this._canvas.width = this.width;
    this._canvas.height = this.height;
    this._canvas.style.width = '100%';
    this._canvas.style.height = '100%';
  }

  private _updateLoader() {
    this.loaded = this.loaded < 100 ? this.loaded + this._loaderSpeed : 0;
  }

  private _renderLoader() {
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fillRect(
      this.loaderX,
      this.loaderY,
      this.loaderWidth,
      this.loaderHeight,
    );

    this.hue =
      this._hueStart + (this.loaded / 100) * (this._hueEnd - this._hueStart);

    const newWidth = (this.loaded / 100) * this.loaderWidth;

    this.ctx.fillStyle = '#bba9ff';
    this.ctx.fillRect(this.loaderX, this.loaderY, newWidth, this.loaderHeight);
  }

  private _createParticles() {
    let i = this._particleRate;

    while (i--) {
      this.particles.push(new Particle(this));
    }
  }

  private _updateParticles() {
    let i = this.particles.length;

    while (i--) {
      const particle = this.particles[i];
      particle.update(i);
    }
  }

  private _renderParticles() {
    let i = this.particles.length;

    while (i--) {
      const particle = this.particles[i];
      particle.render();
    }
  }

  private _clearCanvas() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  private _loop() {
    requestAnimationFrame(this._loop.bind(this));

    this._clearCanvas();
    this._createParticles();
    this._updateLoader();
    this._updateParticles();
    this._renderLoader();
    this._renderParticles();
  }
}
