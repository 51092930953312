<div class="tkt-links-configure tkt-link-panel-container">
  <!-- Input Link -->
  <section #container class="tkt-links-configure__create-link">
    <p>
      <span>
        {{ 'trakto_links.configure.input_title' | translate }}
      </span>
      <i class="trakto-icon-question tooltip-info">
        <span>
          {{ 'trakto_links.configure.tooltip' | translate }}
        </span>
      </i>
    </p>

    <label #inputLabel>
      <i class="trakto-icon-link-drop"></i>
      <!-- Link da versão web -->
      <input
        #inputlink
        type="text"
        disabled="{{ !hasMobileLink }}"
        placeholder="{{
          'trakto_links.configure.input_placeholder' | translate
        }}"
        (focus)="focus($event)"
        (blur)="blur($event)"
        [(ngModel)]="inputValue"
      />
    </label>

    <!-- Notification Link Status -->
    <section
      [ngClass]="{
        'tkt-links-configure__link-status': true,
        loading: loading,
        success: hasWebLink && hasMobileLink && !loading,
        failed: !hasWebLink && !hasMobileLink && !loading
      }"
    >
      <p *ngIf="loading">
        <i class="spinner">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </i>
      </p>

      <p *ngIf="!hasWebLink && hasMobileLink && !loading">
        {{ 'trakto_links.configure.link_alert_available' | translate }}
      </p>

      <p *ngIf="status === panelsEnum.LINK_EMPTY && !hasMobileLink && !loading">
        {{ 'trakto_links.configure.link_alert_empty' | translate }}
      </p>

      <p
        *ngIf="
          status === panelsEnum.LINK_SUCCESS &&
          hasWebLink &&
          hasMobileLink &&
          !loading
        "
      >
        <i class="trakto-icon-success-ball"></i>
        {{ 'trakto_links.configure.link_alert_published' | translate }}
      </p>
    </section>
  </section>

  <section class="tkt-links-configure__button-link">
    <kml-button
      type="primary"
      label="{{
        (loading
          ? 'trakto_links.configure.button.saving'
          : hasWebLink
          ? 'trakto_links.configure.button.edit'
          : 'trakto_links.configure.button.save'
        ) | translate
      }}"
      [disabled]="loading || !hasMobileLink"
      (click)="handleSaveWebLink()"
    >
    </kml-button>
    <kml-button
      type="secondary"
      [label]="'trakto_links.configure.button.remove' | translate"
      [disabled]="!hasWebLink"
      (click)="removeWebLink()"
    ></kml-button>
  </section>
</div>
