<div class="trakto-empty">
  <img
    *ngIf="image"
    [src]="image"
    class="trakto-empty__image"
    [style.marginBottom.px]="margin"
  />
  <h3 class="trakto-empty__title">{{ title }}</h3>
  <p class="trakto-empty__description">{{ description }}</p>
</div>
