import { createReducer, on } from '@ngrx/store';
import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  IGroupElementModel,
  PageModel
} from '@trakto/models';
import {
  addElement,
  addPage,
  clickElementNoTrackable,
  deleteAndAddElements,
  deleteElement,
  deletePage,
  highlightElement,
  moveElementDown,
  moveElementUp,
  movePageDown,
  movePageUp,
  persistDocumentChangesNoTrackable,
  persistElementsChanges,
  persistElementsChangesNotTrackable,
  persistPageChanges,
  persistPageChangesNoTrackable,
  selectDocument,
  selectElementNoTrackable,
  selectPageNoTrackable,
} from '@app/state/document.actions';
import { Drawable } from '@app/editor/components/preview-2/svg-editor/shared/drawable.model';

export interface EditorState {
  document?: IDocumentModel,
  selectedPage?: PageModel,
  selectedElement?: IElementModel, // salva a página que o elemento selecionado se encontra
  clickedElement?: IElementModel, // salva a página que o elemento selecionado se encontra
  highlightElement?: Drawable,
}

export interface HistoryState {
  past: EditorState[],
  present: EditorState,
  future: EditorState[]
}

export const initialState: Readonly<EditorState> = { };


function isGroup(element: IElementModel) {
  return element && element.type === elementTypeEnum.group && (element as IGroupElementModel).elements !== undefined;
}

function findElementIndex(state: Readonly<EditorState>, pageId: string, element: IElementModel) {
  const page = state.document.body.find(p => p.id === pageId);
  return page.elements.findIndex((p: IElementModel) => p.id === element.id);
}

function findPageIndex(state: Readonly<EditorState>, pageId: string) {
  return state.document.body.findIndex(p => p.id === pageId);
}

function findElementIndexByElements(elements: IElementModel[], element: IElementModel) {
  return elements.findIndex((p: IElementModel) => p.id === element.id);
}

function getPage(state: Readonly<EditorState>, pageId: string) {
  return state.document.body.find(p => p.id === pageId);
}

function updateElementsOrders(page: PageModel) {
  if (!page || !page.elements) {
    return;
  }
  page.elements.forEach((element: IElementModel, index: number) => {
    element.depth = index + 1;
  });
}

function updateStateByElementOrderChange(state: Readonly<EditorState>, pageId: string, newPage) {
  return {
    ...state,
    selectedPage: state.selectedPage?.id === pageId ? newPage : state.selectedPage,
    selectedElement: newPage.elements.find(el => el.id === state.selectedElement?.id) || state.selectedElement,
    clickedElement: newPage.elements.find(el => el.id === state.clickedElement?.id) || state.clickedElement,
    document: {
      ...state.document,
      body: state.document?.body?.map(page => page.id === newPage.id ? newPage : page),
    },
  };
}

function updateStateByPageOrderChange(state: Readonly<EditorState>, document: IDocumentModel, source: PageModel, target: PageModel) {
  return {
    ...state,
    selectedPage: state.selectedPage?.id === source.id ? source : target,
    document,
  };
}


function isGroupOutsidePage(element, state: Readonly<EditorState>, pageId) {
  return isGroup(element) && findElementIndex(state, pageId, element) === -1;
}

function persistElementChange(state: Readonly<EditorState>, { elements, pageId }): EditorState {
  const finalElements = elements.reduce((current, element) =>
    current.concat(
      isGroupOutsidePage(element, state, pageId) ? (element as IGroupElementModel).elements :  [element]
    ),
    []
  );
  const page = state.document.body.find(p => p.id === pageId);
  const newPage = {
    ...page,
    elements: page?.elements.map(element => {
      const found = finalElements?.find(toChange => element.id === toChange.id);
      return found ? { ...element, ...found } : element;
    }),
  }
  return {
    ...state,
    selectedPage: page.id === state.selectedPage.id ? newPage : state.selectedPage,
    selectedElement: elements.find(e => state.selectedElement?.id === e.id) || state.selectedElement,
    clickedElement: finalElements.find(e => state.clickedElement?.id === e.id) || state.clickedElement,
    document: {
      ...state.document,
      body: state.document?.body?.map(page => page.id === newPage.id ? newPage : page),
    },
  };
}

function persistPageChange(state: Readonly<EditorState>, page: PageModel): EditorState {
  const targetPage = state.document.body.find(p => p.id === page.id);
  const newPage = {
    ...targetPage,
    ...page,
  };
  return {
    ...state,
    selectedPage:  page.id === state.selectedPage.id ? newPage : state.selectedPage,
    document: {
      ...state.document,
      body: state.document?.body?.map(page => page.id === newPage.id ? newPage : page),
    },
  };
}

function doAddPage(state: Readonly<EditorState>, page, targetIndex?: number) {
  const body = [...state.document.body];
  if (!body[targetIndex]) {
    body.push(page);
  } else {
    body.splice(targetIndex, 0, page);
  }

  return {
    ...state,
    selectedPage: page,
    selectedElement: null,
    clickedElement: null,
    document: {
      ...state.document,
      body: body.map((page, i) => ({ ...page, order: i + 1})),
    },
  };
}

function doDeletePage(state: Readonly<EditorState>, pageId: string) {
  const pageIndex = state.document.body.findIndex((p: PageModel) => p.id === pageId);
  const tempBody = [...state.document.body];
  tempBody.splice(pageIndex, 1);
  const body = tempBody.map((page, i) => ({ ...page, order: i + 1}));

  const selectedPage = pageId === state.selectedPage.id ? body[pageIndex < body.length ? pageIndex : pageIndex - 1] : state.selectedPage;
  const selectedElement = pageId === state.selectedPage.id ? null : state.selectedElement;
  const clickedElement = pageId === state.selectedPage.id ? null : state.clickedElement;

  return {
    ...state,
    selectedPage,
    selectedElement,
    clickedElement,
    document: {
      ...state.document,
      body,
    },
  };
}

function doAddElement(state: Readonly<EditorState>, pageId: string, element: IElementModel) {
  const page = state.document.body.find(p => p.id === pageId);
  const newPage = {
    ...page,
    elements: [element, ...page.elements],
  };
  return {
    ...state,
    selectedPage: state.selectedPage?.id === pageId ? newPage : state.selectedPage,
    selectedElement: element.id === state.selectedElement?.id ? null : state.selectedElement,
    clickedElement: element.id === state.clickedElement?.id ? null : state.clickedElement,
    document: {
      ...state.document,
      body: state.document?.body?.map(page => page.id === newPage.id ? newPage : page),
    },
  };
}

function doDeleteElement(state: Readonly<EditorState>, pageId: string, element: IElementModel) {
  const toRemove = isGroup(element) && findElementIndex(state, pageId, element) === -1 ? (element as IGroupElementModel).elements :  [element];
  const page = getPage(state, pageId);
  const elements = [...page.elements];
  toRemove.forEach(element => {
    elements.splice(findElementIndexByElements(elements, element), 1)
  });
  const newPage = { ...page, elements };
  return {
    ...state,
    selectedPage: state.selectedPage?.id === pageId ? newPage : state.selectedPage,
    selectedElement: toRemove.find(el => el.id === state.selectedElement?.id) ? null : state.selectedElement,
    clickedElement: toRemove.find(el => el.id === state.clickedElement?.id) ? null : state.clickedElement,
    document: {
      ...state.document,
      body: state.document?.body?.map(page => page.id === newPage.id ? newPage : page),
    },
  };
}

function doDeleteAndAddElements(state: Readonly<EditorState>, toDelete, pageId, toAdd) {
  let finalState = state;
  toDelete.forEach(e => finalState = doDeleteElement(finalState, pageId, e));
  toAdd.forEach(e => finalState = doAddElement(finalState, pageId, e));
  return finalState;
}


function doMoveElementUp(state: Readonly<EditorState>, pageId: string, element: IElementModel) {
  const index = findElementIndex(state, pageId, element);
  const page = getPage(state, pageId);
  const newPage = { ...page, elements: page.elements.map(e => ({ ...e })) };
  if (index > 0) {
    const source = newPage.elements[index];
    const destination = newPage.elements[index - 1];

    const sourceIsYoutube = source.type === elementTypeEnum.youtube;
    const destinationIsYoutube = destination.type === elementTypeEnum.youtube;

    if (
      (sourceIsYoutube && destinationIsYoutube) ||
      (!sourceIsYoutube && !destinationIsYoutube)
    ) {
      newPage.elements[index] = destination;
      newPage.elements[index - 1] = source;
      updateElementsOrders(newPage);
    }
  }
  return updateStateByElementOrderChange(state, pageId, newPage);
}

function doMoveElementDown(state: Readonly<EditorState>, pageId: string, element: IElementModel) {
  const index = findElementIndex(state, pageId, element);
  const page = getPage(state, pageId);
  const newPage = { ...page, elements: page.elements.map(e => ({ ...e })) };
  if (index < newPage.elements.length - 1) {
    const source = newPage.elements[index];
    const destination = newPage.elements[index + 1];

    const sourceIsYoutube = source.type === elementTypeEnum.youtube;
    const destinationIsYoutube = destination.type === elementTypeEnum.youtube;

    if (
      (sourceIsYoutube && destinationIsYoutube) ||
      (!sourceIsYoutube && !destinationIsYoutube)
    ) {
      newPage.elements[index] = destination;
      newPage.elements[index + 1] = source;
      updateElementsOrders(newPage);
    }
  }
  return updateStateByElementOrderChange(state, pageId, newPage);
}

function doMovePageUp(state: Readonly<EditorState>, pageId: string) {
  const document: IDocumentModel = { ...state.document, body: state.document.body.map(body => ({...body}))  };
  const pageIndex = findPageIndex(state, pageId);
  const source: PageModel = document.body[pageIndex];
  const destination: PageModel = document.body[pageIndex - 1];

  if (pageIndex > 0) {
    document.body[pageIndex - 1] = { ...source, order:  pageIndex };
    document.body[pageIndex] = { ...destination, order:  pageIndex + 1};
  }
  return updateStateByPageOrderChange(state, document, source, destination);
}

function doMovePageDown(state: Readonly<EditorState>, pageId: string) {
  const document: IDocumentModel = { ...state.document, body: state.document.body.map(body => ({...body})) };
  const pageIndex = findPageIndex(state, pageId);
  const source: PageModel = document.body[pageIndex];
  const destination: PageModel = document.body[pageIndex + 1];

  if (pageIndex < document.body.length - 1) {
    document.body[pageIndex + 1] = { ...source, order:  pageIndex + 1};
    document.body[pageIndex] = { ...destination, order:  pageIndex };
  }
  return updateStateByPageOrderChange(state, document, source, destination);
}

export const documentReducer = createReducer(
  initialState,
  on(selectDocument, (state: Readonly<EditorState>, { document }) => ({ ...state, document, })),
  on(selectPageNoTrackable, (state: Readonly<EditorState>, { pageId }) => ({ ...state, selectedPage: state.document.body.find(page => page.id === pageId) })),
  on(selectElementNoTrackable, (state: Readonly<EditorState>, { element }) => ({ ...state, selectedElement: element })),
  on(clickElementNoTrackable, (state: Readonly<EditorState>, { element }) => ({ ...state, clickedElement: element })),
  on(highlightElement, (state: Readonly<EditorState>, { element }) => ({ ...state, highlightElement: element})),
  on(persistDocumentChangesNoTrackable, (state: Readonly<EditorState>, { document }) => ({ ...state, document: {...state.document, ...document } })),
  on(persistPageChanges, (state: Readonly<EditorState>, { page }) => persistPageChange(state, page)),
  on(persistPageChangesNoTrackable, (state: Readonly<EditorState>, { page }) => persistPageChange(state, page)),
  on(addPage, (state: Readonly<EditorState>, { page, toIndex }) => doAddPage(state, page, toIndex)),
  on(deletePage, (state: Readonly<EditorState>, { pageId }) => doDeletePage(state, pageId)),
  on(movePageUp, (state: Readonly<EditorState>, { pageId }) => doMovePageUp(state, pageId)),
  on(movePageDown, (state: Readonly<EditorState>, { pageId }) => doMovePageDown(state, pageId)),
  on(addElement, (state: Readonly<EditorState>, { element, pageId }) => doAddElement(state, pageId, element)),
  on(deleteElement, (state: Readonly<EditorState>, { element, pageId }) => doDeleteElement(state, pageId, element)),
  on(persistElementsChanges, (state: Readonly<EditorState>, { elements, pageId }) => persistElementChange(state, {elements, pageId})),
  on(persistElementsChangesNotTrackable, (state: Readonly<EditorState>, { elements, pageId }) => persistElementChange(state, {elements, pageId})),
  on(moveElementUp, (state: Readonly<EditorState>, { element, pageId }) => doMoveElementUp(state, pageId, element)),
  on(moveElementDown, (state: Readonly<EditorState>, { element, pageId }) => doMoveElementDown(state, pageId, element)),
  on(deleteAndAddElements, (state: Readonly<EditorState>, { toDelete, toAdd, pageId }) => doDeleteAndAddElements(state, toDelete, pageId, toAdd)),
);
