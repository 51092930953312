import { Injectable, TemplateRef } from '@angular/core';

import { PopoverOptions } from './popover-options.provider';
import { IConfirmCancelEvent } from './popover.directive';

@Injectable()
export class PopoverWindowOptions extends PopoverOptions {
  public onConfirm: (event: IConfirmCancelEvent) => void;
  public onCancel: (event: IConfirmCancelEvent) => void;
  public onAfterViewInit: () => void;
  public customTemplate: TemplateRef<any>;
}
