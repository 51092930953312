import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PreviewDocModule } from '@app/preview/preview.doc.module';
import {
  PanelConfigureComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-configure/panel-configure.component';
import {
  PanelPersonalizeComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-personalize/panel-personalize.component';
import {
  QRCodeDownloadComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-personalize/qrcode/qrcode-download/qrcode-download.component';
import {
  QRCodeEmptyComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-personalize/qrcode/qrcode-empty/qrcode-empty.component';
import {
  QrcodePageDownloadComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-personalize/qrcode/qrcode-page-download/qrcode-page-download.component';
import {
  SocialSliderModule
} from '@editor/components/properties-panel/panel-trakto-links/panel-personalize/social-slider/social-slider.module';
import {
  PanelSEOComponent
} from '@editor/components/properties-panel/panel-trakto-links/panel-seo/panel-seo.component';
import {
  SidebarComponent
} from '@editor/components/properties-panel/panel-trakto-links/sidebar.component';
import { SharedModule } from '@shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';

import {
  KmlButtonModule,
  KmlModalModule,
  KmlModalService
} from '@trakto/trakto-kamaleon';
import { IMaskModule } from 'angular-imask';
import { QRCodeModule } from 'angularx-qrcode';
import {
  VerticalPageNavModule
} from '@app/shared/vertical-page-nav/vertical-page-nav.module';

const COMPONENTS = [
  SidebarComponent,
  PanelPersonalizeComponent,
  PanelConfigureComponent,
  PanelSEOComponent,
  QRCodeEmptyComponent,
  QRCodeDownloadComponent,
  QrcodePageDownloadComponent,
];

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    RouterModule,
    PreviewDocModule,
    SharedModule,
    SocialSliderModule,
    QRCodeModule,
    KmlButtonModule,
    KmlModalModule,
    IMaskModule,
    VerticalPageNavModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [KmlModalService],
})
export class TraktoLinksModule {}
