import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StepperState } from './stepper.state';

export const selectStepperState = createFeatureSelector<StepperState>('stepper');

export const selectCurrentStep = createSelector(
  selectStepperState,
  state => state?.currentStep
);

export const selectTemplateType = createSelector(
  selectStepperState,
  state => state?.templateType
);

export const selectTemplateSubType = createSelector(
  selectStepperState,
  state => state?.templateSubType
);

export const selectTotalSteps = createSelector(
  selectStepperState,
  state => state?.totalSteps
);
