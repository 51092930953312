import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { IDocumentModel, IEventModel, PageModel } from '@trakto/models';

import { AuthService } from '@auth/shared/auth.service';
import { YoutubeService } from '@services/youtube/youtube.service';

import { copyObject } from '@app/state/state.util';
import { environment } from '@env/environment';
import { ElementModelService } from '@services/element-model.service';
import { takeUntil } from 'rxjs/operators';

declare var download: any;

@Injectable()
export class ExportService implements OnDestroy {
  private documentReference: Document;
  private documentModel: IDocumentModel;

  private pages64: any;
  private pagesTotal: number;
  private pagesLoaded: number;
  private loading: Subject<IEventModel>;
  private _destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private youtube: YoutubeService,
    private authService: AuthService,
    private newElementService: ElementModelService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public config(
    documentReference: Document,
    documentModel: IDocumentModel,
  ): Subject<IEventModel> {
    this.documentReference = documentReference;
    this.documentModel = documentModel;

    this.loading = new Subject<IEventModel>();
    this.pagesTotal = this.documentModel.body.length;
    this.pagesLoaded = 0;
    this.pages64 = {};

    return this.loading;
  }

  public saveAsVideo(
    pageModel: PageModel,
    pageSvg: string,
    type: string = 'mp4',
  ): any {
    const userId = new Date().getTime().toString();
    const h: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const urlService = '/export/video';
    const downloadFileName =
      type === 'mp4' ? 'trakto-video.mp4' : 'trakto-gif.gif';
    const downloadMimeType = type === 'mp4' ? 'video/mp4' : 'image/gif';

    if (pageModel.format) {
      delete pageModel.format;
    }

    const clonePage = copyObject(pageModel);
    clonePage.elements = this.newElementService.getAllElements(
      clonePage.elements,
      false,
      false,
    );

    this.http
      .post(
        environment.api.export_service + urlService,
        {
          userId,
          page: clonePage,
          svg: pageSvg,
          type,
        },
        {
          responseType: 'blob',
          headers: h,
        },
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          this.loading.next({
            event: 'complete',
            data,
            bubbles: false,
          });

          download(data, downloadFileName, downloadMimeType);
        },
        error => {
          this.loading.error(error);
        },
      );
  }
}
