import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { HeaderComponent } from '@app/editor-v3/components/header/header.component';
import { PanelComponent } from '@app/editor-v3/components/panel/panel.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-editor-v3',
  templateUrl: './editor-v3.component.html',
  styleUrls: ['./editor-v3.component.scss'],
})
export class EditorV3Component implements OnInit, OnDestroy {

  @ViewChild('header', { static: true })
  headerComponent: HeaderComponent;
  @ViewChild('panel', { static: true })
  panelComponent: PanelComponent;

  @HostBinding('class.hidden-panel')
  hiddenPanel = false;
  private _destroy$ = new Subject<void>();


  get workspaceHeight() {
    return this._elementRef?.nativeElement?.offsetHeight
      - this.headerComponent?.elementRef?.nativeElement?.offsetHeight
      - this.panelComponent?.elementRef?.nativeElement?.offsetHeight;
  }

  constructor(
    private _elementRef: ElementRef,
    private _signalService: SignalsService,
    ) {}

    ngOnDestroy(): void {
      this._destroy$.next();
    }

  ngOnInit(): void {
    this._signalService.getSignal(enumSignals.ON_TEXT_EDITION_MODE).pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.hiddenPanel = true;
    });

    this._signalService.getSignal(enumSignals.ON_EXIT_TEXT_EDITION_MODE).pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.hiddenPanel = false;
    });
  }
}
