import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

export interface IStackConfig {
  inputs?: any;
  outputs?: any;
}

@Injectable({
  providedIn: 'root',
})
export class PanelStackService {
  private _baseIndex = 270;
  private _currentIndex = this._baseIndex;
  private _childComponentRef: ComponentRef<any>;

  public stacks: any[] = [];
  public isPanelExpanded = false;
  public showExpandIcon = true;

  public onExpandPanelChange: Subject<boolean>;
  public onExpandIconChange: Subject<boolean>;

  public componentsToMaintain = ['PanelElementsComponent'];
  private _canOpenPanel = true;

  private _lastStackAfterPanelBlocking: {
    panel: any,
    config: IStackConfig,
  } = null;

  public get canOpenPanel() {
    return this._canOpenPanel;
  }

  public get hasLastStackAfterPanelBlocking(): boolean {
    return !!this._lastStackAfterPanelBlocking;
  }

  public get currentStack() {
    return this.stacks.length > 0 ? this.stacks[this.stacks.length - 1] : null;
  }

  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.onExpandPanelChange = new ReplaySubject<boolean>(1);
    this.onExpandIconChange = new ReplaySubject<boolean>(1);
  }


  blockPanelOpening() {
    this._canOpenPanel = false;
  }

  allowPanelOpening() {
    this._canOpenPanel = true;
  }

  openLastStackBeforePanelBlocking() {
    if (!this._lastStackAfterPanelBlocking) {
      return;
    }
    this.stack(
      this._lastStackAfterPanelBlocking.panel,
      this._lastStackAfterPanelBlocking.config,
    );
    this._lastStackAfterPanelBlocking = null;
  }

  public stack(panel: any, config?: IStackConfig): boolean {
    if (!this._canOpenPanel) {
      this._lastStackAfterPanelBlocking = { panel, config, };
      return false;
    }
    this._childComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(panel)
      .create(this.injector);

    if (
      this.componentsToMaintain.includes(
        this._childComponentRef.componentType.name,
      ) &&
      this.stacks.length > 0
    ) {
      return false;
    }

    this.stacks.push(this._childComponentRef);

    this.refreshExpandStatus();

    const configStackdefault = { inputs: {}, outputs: {}, ...config };

    this._attachConfig(configStackdefault, this._childComponentRef);

    this.appRef.attachView(this._childComponentRef.hostView);

    const childDomElem = (
      this._childComponentRef.hostView as EmbeddedViewRef<any>
    ).rootNodes[0] as HTMLElement;

    this._currentIndex++;
    childDomElem.style.zIndex = this._currentIndex.toString();

    const panels = document.getElementById('panels');
    if (panels) {
      panels.appendChild(childDomElem);
    }

    return true;
  }

  public pop(delay?: number): void {
    if (!this._canOpenPanel) {
      this._lastStackAfterPanelBlocking = null;
      return;
    }
    if (this.currentStack) {
      if (this._currentIndex - 1 >= this._baseIndex) {
        this._currentIndex--;
      }

      if (!delay) {
        this._unstack(this.currentStack);
      } else {
        const component = this.currentStack;
        component.location.nativeElement.classList.add('closed');
        setTimeout(() => this._unstack(component), delay);
      }
    }
  }

  public popAll(removeProtected = false): void {
    if (!this._canOpenPanel) {
      this._lastStackAfterPanelBlocking = null;
      return;
    }
    if (this.stacks.length > 0) {
      this.stacks
        .slice(0)
        .reverse()
        .map(stack => {
          if (
            !this.componentsToMaintain.includes(stack.componentType.name) ||
            removeProtected
          ) {
            if (this._currentIndex - 1 >= this._baseIndex) {
              this._currentIndex--;
            }

            this._unstack(stack);
          }
        });

      this.refreshExpandStatus();
    }
  }

  public closeOthers(): void {
    if (!this._canOpenPanel) {
      return;
    }
    while (this.stacks.length > 1) {
      this.pop();
      this.refreshExpandStatus();
    }
  }

  /**
   * Verifica se o painel vigente pode ser expandido
   * (se não puder, o retrai) e se o ícone de
   * expansão deve ser exibido
   */
  public refreshExpandStatus(): void {
    this.canShowExpandIcon();

    if (!this.showExpandIcon) this.expandPanel(false);
  }

  /**
   * Define se o ícone de expansão do painel
   * pode ser exibido
   */
  public canShowExpandIcon(): void {
    const allowedTagNames = ['TRAKTO-PANEL-ELEMENTS'];
    const lastStackIndex = this.stacks.length - 1;
    const search = this.stacks[lastStackIndex]?.location.nativeElement.tagName;

    this.showExpandIcon = allowedTagNames.indexOf(search) > -1;
    this.onExpandIconChange.next(this.showExpandIcon);
  }

  /**
   * Expande ou contrai o painel vigente
   *
   * @param expanded null | boolean - Status da expansão do painel vigente
   */
  public expandPanel(expanded: null | boolean = null): void {
    this.isPanelExpanded = expanded === null ? !this.isPanelExpanded : expanded;
    this.onExpandPanelChange.next(this.isPanelExpanded);
  }

  private _attachConfig(
    config: IStackConfig,
    componentRef: ComponentRef<any>,
  ): void {
    const { inputs, outputs } = config;

    for (const [key, value] of Object.entries(inputs)) {
      componentRef.instance[key] = value;
    }

    for (const [key, value] of Object.entries(outputs)) {
      componentRef.instance[key] = value;
    }
  }

  private _unstack(stacked: ComponentRef<any> = this._childComponentRef): void {
    if (stacked) {
      this.appRef.detachView(stacked.hostView);
      this.stacks.pop();
      this.refreshExpandStatus();
      stacked.destroy();
    }
  }
}
