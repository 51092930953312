import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from './../modal/modal.module';

import { ListModule } from '../list/list.module';
import { ModalDocumentsComponent } from './modal-documents.component';

@NgModule({
  imports: [CommonModule, ListModule, ModalModule],
  declarations: [ModalDocumentsComponent],
  exports: [ModalDocumentsComponent],
})
export class ModalDocumentsModule {}
