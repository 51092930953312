import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent implements OnInit {
  @Input() icon: string = '';
  @Input() label: string = '';

  @Output() action = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleAction() {
    this.action.emit();
  }
}
