<div class="trakto-radio">
  <label>
    <div class="trakto-radio__content">
      <input
        type="radio"
        #radio
        [id]="id"
        [name]="name"
        [value]="value"
        [checked]="isSelected"
        (change)="toggle()"
        class="trakto-radio__field"
      />

      <span>
        <span></span>
      </span>

      <span class="trakto-radio__label">
        <ng-content></ng-content>
      </span>
    </div>
  </label>
</div>
