export enum KeyEnum {
  q = 81,
  w = 87,
  e = 69,
  r = 82,
  t = 84,
  y = 89,
  u = 85,
  i = 73,
  o = 79,
  p = 80,

  a = 65,
  s = 83,
  d = 68,
  f = 70,
  g = 71,
  h = 72,
  j = 74,
  k = 75,
  l = 76,

  z = 90,
  x = 88,
  c = 67,
  v = 86,
  b = 66,
  n = 78,
  m = 77,

  esc = 27,
  tab = 9,
  ctrl = 17,
  enter = 13,
  alt = 18,
  shift = 16,

  metaLeft = 91,
  metaRight = 93,

  zero = 48,
  one = 49,
  two = 50,
  three = 51,
  four = 52,
  five = 53,
  six = 54,
  seven = 55,
  eight = 56,
  nine = 57,

  minus = 189,
  equal = 187,
  backspace = 8,
  delete = 46,
  space = 32,
  dead = 192,

  dot = 190,
  comma = 188,
  slash = 191,

  left = 37,
  up = 38,
  right = 39,
  down = 40,
}

export enum KeyEnumFirefox {
  minus = 173,
  equal = 61,
}
