<div class="trakto-nav" #nav [style.--navWidth]="navWidth + 'px'">
  <div
    class="trakto-nav__item"
    [class.--active]="selectedIndex === i"
    #item
    *ngFor="let nav of menus; index as i"
    (click)="activeItemMenu(item, i)"
  >
    <p class="trakto-nav__link">{{ nav.name }}</p>
  </div>
  <div
    class="trakto-nav__line"
    [ngClass]="{
      '--line-one': selectedIndex === 0,
      '--line-two': selectedIndex === 1,
      '--line-three': selectedIndex === 2
    }"
    [style.--lineWidth]="lineWidth + 'px'"
    [style.--translateLine]="translateLine + 'px'"
  ></div>
</div>
