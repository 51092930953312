<div class="options">
  <div
    class="option"
    #action
    (click)="openPageBackgroundColorPanel()"
    [traktoTooltip]="optionsTooltips.background_color"
    traktoTooltipPlacement="right"
  >
    <img src="assets/images/page-options/background_color.svg" alt="" />
  </div>
  <div
    class="option"
    #action
    (click)="openPageBackgroundImagePanel()"
    [traktoTooltip]="optionsTooltips.background_image"
    traktoTooltipPlacement="right"
  >
    <img src="assets/images/page-options/background_image.svg" alt="" />
  </div>
  <div
    class="option"
    #action
    (click)="movePageUp()"
    *ngIf="docLength > 1 && allowNewPages"
    [traktoTooltip]="optionsTooltips.move_up"
    traktoTooltipPlacement="right"
  >
    <img src="assets/images/page-options/move_up.svg" alt="" />
  </div>
  <div class="option option--page" *ngIf="allowNewPages">
    <span>{{ selectedPageIndex + 1 }}</span>
  </div>
  <div
    class="option"
    #action
    (click)="movePageDown()"
    *ngIf="docLength > 1 && allowNewPages"
    [traktoTooltip]="optionsTooltips.move_down"
    traktoTooltipPlacement="right"
  >
    <img src="assets/images/page-options/move_down.svg" alt="" />
  </div>
  <div class="option" (click)="toggleTransparentBackgroundInPage()">
    <img
      *ngIf="isTransparencyToggleActive; else inactiveIcon"
      src="assets/images/page-options/background_transparent_hover.svg"
      alt=""
      [traktoTooltip]="optionsTooltips.apply_transparent"
      traktoTooltipPlacement="right"
    />
    <ng-template #inactiveIcon>
      <img
        src="assets/images/page-options/background_transparent.svg"
        [traktoTooltip]="optionsTooltips.apply_transparent"
        traktoTooltipPlacement="right"
        alt=""
      />
    </ng-template>
  </div>
  <div class="option" #action (click)="emitNewSelectedPage()">
    <img
      src="assets/images/page-options/page_dimensions.svg"
      alt=""
      [traktoTooltip]="optionsTooltips.page_adjusts"
      traktoTooltipPlacement="right"
    />
  </div>
</div>
