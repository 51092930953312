<div
  id="pageNavigation"
  [class.navigation--active]="document?.body && document?.body.length > 1"
>
  <div #scrollArea (scroll)="scroll()" class="scroll-area">
    <div [style.min-width.px]="leftWidth" [style.height.px]="50"></div>
    <div
      class="scroll-item__container"
      *ngFor="let page of visibleThumbs; let i = index"
    >
      <button class="navigation__button" (click)="addPage(i)">
        <i class="trakto-icon-plus"></i>
      </button>

      <div class="scroll-item__page">
        <span class="scroll-item__page__label">
          {{ visibleThumbStartIndex + i + 1 }}
        </span>
        <div
          class="scroll-item"
          [class.scroll-item--active]="
            selectedPageIndex === visibleThumbStartIndex + i
          "
          (click)="onPageFocus.emit(visibleThumbStartIndex + i)"
        >
          <!--          <trakto-svg-editor-->
          <!--            [isPreviewDoc]="true"-->
          <!--            [zoomRatio]="getThumbZoomRatio(page)"-->
          <!--            [page]="page"-->
          <!--            [elements]="page.elements"-->
          <!--            [isActivated]="false"-->
          <!--            [printableMode]="false">-->
          <!--          </trakto-svg-editor>-->
          <trakto-svg-viewer
            [page]="page"
            [document]="document"
            [selectedPage]="selectedPageIndex === visibleThumbStartIndex + i"
            [ref]="
              selectedPageIndex === visibleThumbStartIndex + i
                ? ref
                : page.elements
            "
            [zoomRatio]="getThumbZoomRatio(page)"
          ></trakto-svg-viewer>
        </div>
      </div>

      <button
        class="navigation__button"
        *ngIf="
          pagesThumbList &&
          pagesThumbList.length === visibleThumbStartIndex + i + 1
        "
        (click)="addPage(visibleThumbStartIndex + i + 1)"
      >
        <i class="trakto-icon-plus"></i>
      </button>
    </div>
    <div [style.min-width.px]="rightWidth" [style.height.px]="50"></div>
  </div>
</div>
