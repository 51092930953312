import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { DocumentStateManagerService } from '@app/editor/services/document-state-manager.service';
import { IMetadata, TraktoLink, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {

  showDialog: boolean = false;
  @Input() traktolink: Observable<TraktoLink>;
  showNotification: boolean = false;
  deleteSuccess: boolean = false;
  @Output() onCloseConfirmationDialog = new EventEmitter<boolean>();
  slug: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _traktoLinksService: TraktoLinksService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
  ) { }


  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this.traktolink.pipe(takeUntil(this._destroy$)).subscribe(
      (traktoLink: any) => {
        if (!traktoLink) return;
        this.slug = traktoLink.link_name ? traktoLink.link_name : traktoLink.link_web;
      }
    );
  }

  trigger() {
    this.showDialog = !this.showDialog
  }

  cancel() {
    this.trigger();
  }

  close() {
    this.showNotification = false;
    this.trigger();
    this.onCloseConfirmationDialog.emit(this.deleteSuccess);
  }

  delete() {
    this._traktoLinksService.deleteTraktoLink(
      (this.slug)).then(
        async () => {
          this.showNotification = !this.showNotification;
          this.deleteSuccess = true;
          const model: IMetadata = {
            title: '',
            link_name: '',
            description: '',
            link_web: '',
            image: ''
          }

          const document = await this._documentManagerService.getSelectedDocument();
          document.metadata = model;
          this._documentStateManagerService.persistDocumentChangesNoTrackable(document);
        }
      ).catch(
        (err) => {
          this.showNotification = !this.showNotification;
          this.deleteSuccess = false;
        }
      )
  }
}
