import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';

import { NgColorModel } from '@shared/color/classes/color.model';
import { ColorService } from '../../../services/color.service';

@Component({
  selector: 'trakto-colorpicker-slider',
  templateUrl: './colorpicker-slider.component.html',
  styleUrls: ['./colorpicker-slider.component.scss'],
})
export class ColorPickerSliderComponent implements AfterViewInit {
  @ViewChild('canvas')
  private _canvas: ElementRef<HTMLCanvasElement>;

  private _ctx: CanvasRenderingContext2D;
  private _mousedown = false;
  private _selectedWidth: number;
  private _borderColor = 'rgba(239, 241, 247, 1)';
  private _thumbRadius = 6.5;
  private _trackWidth: number;
  private _trackHeight = 13;
  private _originYTrack: number;
  private _originYThumb: number;

  @Output()
  changedHue: EventEmitter<NgColorModel> = new EventEmitter();

  constructor(private _colorService: ColorService) {}

  ngAfterViewInit() {
    if (!this._ctx) {
      this._ctx = this._canvas.nativeElement.getContext('2d');
      this._trackWidth = this._canvas.nativeElement.width;
      this._originYTrack =
        this._canvas.nativeElement.height / 2 - this._trackHeight / 2;
      this._originYThumb = this._canvas.nativeElement.height / 2 - 0.5;
    }

    this._draw();
    setTimeout(() => this._onChangeSelectedColor(), 0);
  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent) {
    this._mousedown = false;
  }

  onMouseUp(event: MouseEvent) {
    this._mousedown = false;
    this._getColorAtPosition(event.offsetX, event.offsetY);
  }

  onMouseDown(event: MouseEvent) {
    this._mousedown = true;
    this._onChangeSlider(event);
  }

  onMouseMove(event: MouseEvent) {
    if (this._mousedown) {
      this._onChangeSlider(event);
    }
  }

  private _onChangeSelectedColor() {
    this._selectedWidth = null;
    this._draw();
  }

  private _draw() {
    this._ctx.clearRect(
      0,
      0,
      this._trackWidth,
      this._canvas.nativeElement.height,
    );

    const gradient = this._ctx.createLinearGradient(this._trackWidth, 0, 0, 0);
    gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');

    this._renderRoundRect(
      this._ctx,
      0,
      this._originYTrack,
      this._trackWidth,
      this._trackHeight,
      5,
      gradient,
      this._borderColor,
    );
    this._ctx.save();

    if (this._selectedWidth) {
      this._renderThumb(this._originYThumb);
    }

    this._ctx.restore();
  }

  private _renderRoundRect(ctx, x, y, width, height, radius = 5, fill, stroke) {
    let radiusObj: any;

    if (typeof radius === 'number') {
      radiusObj = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (const side in defaultRadius) {
        if (!radiusObj[side]) {
          radiusObj[side] = defaultRadius[side];
        }
      }
    }

    ctx.beginPath();
    ctx.moveTo(x + radiusObj.tl, y);
    ctx.lineTo(x + width - radiusObj.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radiusObj.tr);
    ctx.lineTo(x + width, y + height - radiusObj.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radiusObj.br,
      y + height,
    );
    ctx.lineTo(x + radiusObj.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radiusObj.bl);
    ctx.lineTo(x, y + radiusObj.tl);
    ctx.quadraticCurveTo(x, y, x + radiusObj.tl, y);
    ctx.closePath();

    if (fill) {
      ctx.fillStyle = fill;
      ctx.fill();
    }

    if (stroke) {
      ctx.strokeStyle = stroke;
      ctx.stroke();
    }
  }

  private _renderThumb(originYThumb: number) {
    this._ctx.beginPath();
    this._ctx.arc(this._selectedWidth, originYThumb, this._thumbRadius, 0, 360);
    this._ctx.strokeStyle = this._borderColor;
    this._ctx.lineWidth = 2;
    this._ctx.shadowColor = 'rgba(0,0,0,.4)';
    this._ctx.shadowBlur = 1;
    this._ctx.shadowOffsetX = 0;
    this._ctx.shadowOffsetY = 1;
    this._ctx.imageSmoothingEnabled = true;
    this._ctx.closePath();
    this._ctx.stroke();
  }

  private _onChangeSlider(event: MouseEvent) {
    if (this._checkCanvasLimits(event)) {
      this._selectedWidth = event.offsetX - 1.5;
      this._draw();
    }
  }

  private _getColorAtPosition(x: number, y: number) {
    const imageData = this._ctx.getImageData(
      x + 2,
      this._originYThumb,
      1,
      1,
    ).data;
    const rgbaColor = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;

    this.changedHue.emit(new NgColorModel(rgbaColor));
  }

  private _checkCanvasLimits(event: MouseEvent): boolean {
    if (event.offsetX >= 8 && event.offsetX <= this._trackWidth - 6) {
      return true;
    }

    return false;
  }
}
