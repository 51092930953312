import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isUrlValid } from '@app/editor/components/properties-panel/panel-link-website/link.validator';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { ITextElementModel } from '@trakto/models';

@Component({
  selector: 'trakto-inline-link-input',
  templateUrl: './inline-link-input.component.html',
  styleUrls: ['./inline-link-input.component.scss'],
})
export class InlineLinkInputComponent implements OnInit {
  @Input() private textElement: ITextElementModel;
  @Output() apply: EventEmitter<string> = new EventEmitter<string>();

  public isInputLinkFocused = false;
  public isInputLinkActive = false;
  public isPreviousLink = false;
  public inputedLink = '';

  constructor(private hotkeyService: HotkeyService) {}

  ngOnInit(): void {
    this.inputedLink = this.textElement.link || '';
    this.compareLinks();
  }

  public inputLinkFocus(): void {
    this.isInputLinkFocused = true;
    this.hotkeyService.disableHotkeys();
  }

  public inputLinkBlur(): void {
    this.isInputLinkFocused = false;
    this.hotkeyService.enableHotkeys();
  }

  public addMaskUrl(event: KeyboardEvent): void {
    if (
      this.inputedLink.indexOf('http://') === -1 &&
      this.inputedLink.indexOf('https://') === -1
    ) {
      const previous = this.inputedLink;
      this.inputedLink = 'http://' + previous;
    }

    this.compareLinks();
    if (event.key === 'Enter') {
      this.applyLink();
    }
  }

  applyLink(): boolean {
    if (this.isPreviousLink) {
      this.emitLink(null);
      return false;
    }

    if (!this.isInputLinkActive) {
      return false;
    }

    this.emitLink(this.inputedLink);

    return true;
  }

  compareLinks(): void {
    this.isPreviousLink = this.textElement.link === this.inputedLink;
    this.isInputLinkActive = isUrlValid(this.inputedLink);
  }

  emitLink(link: string | null): void {
    this.apply.emit(link);
    if (!link) {
      this.inputedLink = '';
    }
    this.compareLinks();
  }
}
