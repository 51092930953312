<div style="height: 100%; overflow: auto" #fontListContainer>
  <div class="searchbar-fonts">
    <trakto-input
      prefix="trakto-icon-tkt-icon"
      enableKeypress="true"
      id="favorite"
      name="fillFont"
      placeholder="{{
        'property_panel.text.text_style.gallery.search_fonts_placehorlder'
          | translate
      }}"
      type="search"
      (onBlur)="enableHotkeys()"
      (onFocus)="disableHotkeys()"
      [(ngModel)]="searchFontText"
    ></trakto-input>
  </div>
  <div class="panel-full__container" *ngIf="fonts.length === 0">
    <div class="trakto-favorite__empty">
      <img src="assets/images/trakto-favorite-empty.svg" alt="Empty" />
      <p>
        Você ainda não favoritou <br />
        nenhuma fonte.
      </p>
    </div>
  </div>
  <div class="panel-simple" *ngIf="fonts.length > 0">
    <trakto-font-list
      *ngIf="fonts.length > 0"
      [fonts]="fonts"
      [searchFontText]="searchFontText"
      [elementSelected]="elementSelected"
      [pageSelected]="pageSelected"
      [container]="fontListContainer"
      [selectorColor]="'#FE6E46'"
      (selectedFont)="selectedFont.emit($event)"
    >
    </trakto-font-list>
  </div>
</div>
