import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IDocumentModel } from "@trakto/models";

@Injectable({
    providedIn: 'root'
})
export class UpdateDesignApi {
    constructor(
        private _http: HttpClient,
    ) {}

    async updateDesign(id: string, designToUpdate: {
        title: string;
        pageFormatId: string;
        author?: string;
        body: any;
    }): Promise<IDocumentModel> {
        const {
            title,
            body,
            pageFormatId
        } = designToUpdate;
        const payload: any = {
            id,
            title,
            body,
            page_format_reference: pageFormatId,
        };
        const designUpdated = await this._http.put<IDocumentModel>(
            `${environment.api.baseUrl}document/${id}`,
            payload
        ).toPromise();
        return { ...designUpdated, body };
    }
}