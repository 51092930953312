import { Injectable } from '@angular/core';
import { ProductRepository } from '@app/editor/repository/product.repository';
import { environment } from '@env/environment';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppRepository } from '@editor/repository/app.repository';
import { IApp } from '@trakto/models';

@Injectable({
  providedIn: 'root',
})
export class WhitelabelProductService {
  public productsData;
  public currentApp: IApp;
  public currentProduct;
  public currentProductId;

  private _updatedAvailableFeatures = new BehaviorSubject(
    'Update Available Features',
  );
  public featuresAvailable = this._updatedAvailableFeatures.asObservable();

  constructor(
    private _cookieService: CookieService,
    private _productRepository: ProductRepository,
    private _appRepository: AppRepository,
  ) {}

  async init() {
    if (!this.currentProduct) {
      const data: any = this._cookieService.get('trakto-loader');
      if (data && data !== '') {
        this.currentProductId = JSON.parse(data).product_id;
        await this._initProduct(this.currentProductId);
      }
    }
    if (!this.currentApp) {
      await this.getAppFromProduct();
    }
  }


  /**
   * Configura as informações do produto atual que são utilizdas para a UI e outros
   * recursos da plataforma
   */
  private async _initProduct(productId: string) {
    const product = await this._productRepository.findById(productId);
    this._updatedAvailableFeatures.next(product['available_features']);
    this.currentProduct = product;
    this.currentProductId = product.id;
  }

  public async getAppFromProduct() {
    if (!this.currentApp) {
      this.currentApp = await this._appRepository.findById(
        this.currentProduct.app_reference.id,
      );
    }
    return this.currentApp;
  }

  public async getCurrentProduct() {
    if (this.currentProduct && this.currentApp) {
      return {
        currentProduct: this.currentProduct,
        currentApp: this.currentApp,
      };
    }

    return {
      currentProduct: this.currentProduct,
      currentApp: this.currentApp,
    };
  }
  public async setCurrentProductFromId(appId: string, productId: string) {
    try {
      this.currentApp = await this._appRepository.findById(appId);
      this.currentProduct = await this._productRepository.findById(productId);
    } catch (error) {
      throw new Error(error);
    }
  }

  public featureIsAvailableOnCurrentProduct(feature: ProductFeatures): boolean {
    return this.currentProduct
      ? this.currentProduct.available_features[feature]
      : true;
  }

  public isB2C() {
    return this.currentProduct
      ? this.currentProduct.id === environment.productIdB2C
      : false;
  }
}
