import { Injectable, OnDestroy } from '@angular/core';
import { delay, takeUntil } from 'rxjs/operators';

import { Subject, of } from 'rxjs';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private _notificationsMSGs: any;
  private _destroy$ = new Subject<void>();

  constructor(private _translateService: TranslateService) {
    this.createContainer();
    this.fetchNotificationTexts();

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this.fetchNotificationTexts();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  fetchNotificationTexts() {
    this._translateService.get('notifications').pipe(takeUntil(this._destroy$)).subscribe(texts => {
      this.notificationsMSGs = texts;
    });
  }

  public set notificationsMSGs(value: any) {
    this._notificationsMSGs = value;
  }

  public get notificationsMSGs(): any {
    return this._notificationsMSGs;
  }

  success(message: string, options?: any, callback?: (p?: any) => any): any {
    const instance: any = this.createAlert(
      message,
      options,
      'success',
      callback,
    );
    this.show(instance, callback);
  }

  dark(message: string, options?: any, callback?: (p?: any) => any): any {
    const instance: any = this.createAlert(message, options, 'dark', callback);
    this.show(instance);
  }

  info(message: string, options?: any, callback?: (p?: any) => any): any {
    const instance: any = this.createAlert(message, options, 'info', callback);
    this.show(instance, callback);
  }

  error(message: string, options?: any, callback?: (p?: any) => any): any {
    const instance: any = this.createAlert(message, options, 'error', callback);
    this.show(instance, callback);
  }

  warn(message: string, options?: any, callback?: (p?: any) => any): any {
    const instance: any = this.createAlert(message, options, 'warn', callback);
    this.show(instance, callback);
  }

  show(instance: any, callback?: (p: boolean) => any): void {
    document
      .querySelector('#container-notification')
      .appendChild(instance.element);

    if (!callback) {
      const {
        options: { time },
      } = instance;

      if (time > 0) {
        const delayObservable = of('dummy').pipe(delay(time));
        delayObservable.pipe(takeUntil(this._destroy$)).subscribe(data => this.hide(instance.element));
      } else {
        return;
      }
    } else {
      const containerButtons: HTMLDivElement = document.createElement('div');
      containerButtons.classList.add('container-buttons');
      instance.element.classList.add('normalize-padding');
      instance.element.appendChild(containerButtons);

      const cancel: HTMLButtonElement = document.createElement('button');
      cancel.classList.add('button', 'button--outline', 'button--white');
      cancel.style.minWidth = '72px';
      cancel.innerHTML = 'Não';

      const confirm: HTMLButtonElement = document.createElement('button');
      confirm.classList.add('button', 'button--fill', 'button--white');
      confirm.style.minWidth = '72px';
      confirm.innerHTML = 'Sim';

      confirm.addEventListener('click', () => {
        callback(true);
        this.hide(instance.element);
      });
      cancel.addEventListener('click', () => {
        callback(false);
        this.hide(instance.element);
      });

      containerButtons.appendChild(cancel);
      containerButtons.appendChild(confirm);
    }
  }

  hide(element: HTMLDivElement): void {
    element.remove();
  }

  close(): void {
    const containerNotification = document.querySelector('#container-notification');
    if (containerNotification) {
      const notifications: Element[] = Array.from(
        containerNotification.getElementsByClassName('notification'),
      );
      notifications.forEach((notification) => {
        notification.remove();
      });
    }
  }

  createContainer(): any {
    const _container: HTMLDivElement = document.createElement('div');
    _container.classList.add('bottom-right');
    _container.setAttribute('id', 'container-notification');

    return document.body.appendChild(_container);
  }

  createAlert(
    message: string,
    options: any,
    type: string,
    callback?: (p?: any) => any,
  ): any {
    const _element: HTMLDivElement = document.createElement('div');
    const _options = this.setOptions(options);

    _element.classList.add('notification', `notificaton--${type}`, 'full');
    _element.setAttribute('data-icon', `trakto-icon-${type}`);
    _element.innerHTML = this.setTemplate(
      _element,
      message,
      this.setOptions(options),
      callback,
    );

    return {
      element: _element,
      options: _options,
    };
  }

  setOptions(options: any): any {
    return Object.assign(
      {
        showOkButton: false,
        isSimple: true,
        hasClose: true,
        hasIcon: true,
        time: 5000,
      },
      options,
    );
  }

  setTemplate(
    element: HTMLDivElement,
    message: string,
    options: any,
    callback?: (p?: any) => any,
  ): string {
    let template: string;

    template = '';

    if (!options.isSimple) {
      element.classList.remove('full');
    } else {
      const remove = document.querySelector('#container-notification');
      remove.classList.add('full-bottom');
      document.querySelector('.full-bottom').classList.remove('bottom-right');
    }

    if (options.hasClose && !callback) {
      const notifications: Element[] = Array.from(
        document.getElementsByClassName('notification'),
      );

      notifications.map(notification => {
        notification.addEventListener('click', event => {
          const tagName: string = (event.target as any).tagName;

          if (tagName === 'BUTTON') {
            notification.remove();
          }
        });
      });

      template += '<i class="trakto-icon-close close"></i>';

      element.addEventListener('click', () => {
        this.hide(element);
      });
    }

    if (options.hasIcon) {
      const icon: string = element.getAttribute('data-icon');
      template += `<i class="${icon} icon"></i>`;
    }

    template += `<span>${message}</span>`;

    if (options.showOkButton && !callback) {
      template += '<button class="button--white button-margin">Ok</button>';
    }

    return template;
  }
}
