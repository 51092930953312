import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { environment } from '@env/environment';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';
import { StorageService } from '@shared/storage/storage-service.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import {
  BoundBoxModel,
  ColorModel,
  DimensionModel,
  ImageOperations,
  IUrlInfo,
} from '@trakto/core-editor';
import { ResolutionsModel } from '@trakto/graphics-resources';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import {
  ResolutionModel
} from '@trakto/graphics-resources/dist/src/models/image.model';

@Injectable({
  providedIn: 'root',
})
export class ImageUtilService extends ImageOperations {

  private readonly _cacheKey = 'trakto-image-dimensions';
  private readonly _dimensionsByUrl = {};
  constructor(
    private _authService: AuthService,
    private _storageService: StorageService,
    private _uploadStorageService: UploadStorageService,
    private _hotkeyService: HotkeyService,
    private _httpClient: HttpClient,
  ) {
    super(
      `${window.location.origin}/${environment.static.image}`,
      `${window.location.origin}/${environment.static.loading}`,
      `${window.location.origin}/${environment.static.error}`,
    );
    this._dimensionsByUrl = JSON.parse(sessionStorage.getItem(this._cacheKey) || '{}');
    if (Object.keys(this._dimensionsByUrl).length > 100) {
      sessionStorage.setItem(this._cacheKey, JSON.stringify({}));
    }
  }

  createTempResolutions(url: string): ResolutionsModel {
    const media = this._createTempResolutionModel(url);
    return {
      raw: media,
      high: media,
      medium: media,
      low: media,
    };
  }

  public isExternalUrl(urlInfo: IUrlInfo) {
    return (
      urlInfo.href &&
      !urlInfo.href.includes('blob') &&
      !urlInfo.href.includes('images_prevent') &&
      !urlInfo.href.includes('images_upload') &&
      !this.isStaticUrl(urlInfo) &&
      !this._storageService.isAllowedCDN(urlInfo.raw || urlInfo.href) &&
      !this._storageService.isAsset(urlInfo.raw || urlInfo.href) &&
      !this._storageService.isBase64(urlInfo.raw || urlInfo.href)
    ) || urlInfo?.href?.includes('cloudinary.com');
  }

  public getImageDimension(url: string): Observable<DimensionModel> {
    if (!url.includes('data:image/png;base64') && this._dimensionsByUrl[url]) {
      return of(this._dimensionsByUrl[url]);
    }
    return this._loadImage(url, false).pipe(
      map(img => new DimensionModel(img.width, img.height)),
      tap(dimension => {
        if (url.includes('http') && !url.includes('data:image/png;base64')) {
          this._dimensionsByUrl[url] = dimension;
          sessionStorage.setItem(this._cacheKey, JSON.stringify(this._dimensionsByUrl));
        }
      })
    );
  }

  public loadAndMakeBase64(
    url: string,
    dimension: DimensionModel,
  ): Observable<string> {
    return this._loadImage(url, true).pipe(
      delay(this._hotkeyService.isSafari() ? 100 : 0),
      switchMap(img => from(this._makeBase64(img, dimension))),
      catchError(() => of('')),
    );
  }

  public svgToBase64(svg: string): string {
    return `data:image/svg+xml;utf8,${encodeURIComponent(svg.trim())}`;
  }

  public async getAverageRGB(
    url: string,
    section?: BoundBoxModel,
  ): Promise<ColorModel> {
    const image = await this._loadImage(url, false).toPromise();
    return this._getAverageRGB(image, section);
  }

  private addRandomNumberOnUrl(url: string): string {
    if (!url || url.startsWith('data:image') || url.startsWith('blob') || url.startsWith('trakto.io') || url.includes('files.vecteezy.com')) {
      return url;
    }

    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}random=${Math.random()}`;
  }

  private _loadImage(
    url: string,
    anonymousMode: boolean = false,
  ): Observable<any> {
    return new Observable<any>(observer => {
      const img = new Image();

      // It's necessary for render images locally, used on navigator pages (technacally on Canvas).
      if (anonymousMode) {
        img.crossOrigin = 'anonymous';
      }

      img.onload = () => {
        observer.next(img);
        observer.complete();
      };
      img.onerror = error => {
        observer.error(`imageNotLoaded: ${url}`);
        observer.complete();
      };

      img.src = this.addRandomNumberOnUrl(url);
    });
  }

  private async _makeBase64(img, dimension: DimensionModel): Promise<string> {
    if (
      (await this.isSvg(img.src)) &&
      img.src.indexOf('data:image/svg+xml;') === -1
    ) {
      const svg = await this.getSvgContent(img.src);
      return this.svgToBase64(svg);
    }
    const canvas = document.createElement('canvas');
    if (
      !dimension ||
      (dimension &&
        img.width < dimension.width &&
        img.height < dimension.height)
    ) {
      canvas.width = img.width;
      canvas.height = img.height;
    } else {
      canvas.width = dimension.width;
      canvas.height = (img.height * dimension.width )/ img.width;
    }
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    canvas.remove();
    return canvas.toDataURL('image/png');
  }

  private async isSvg(url: string): Promise<boolean> {
    const response = await this._httpClient
      .get(url, { observe: 'response', responseType: 'text' })
      .toPromise();
    return response.headers.get('content-type') === 'image/svg+xml';
  }

  private async getSvgContent(url: string): Promise<string> {
    const response = await fetch(url);
    return response.text();
  }

  // https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript
  private _getAverageRGB(image, box?: BoundBoxModel): ColorModel {
    const defaultRGB = { r: 0, g: 0, b: 0 };
    const rgb = { r: 0, g: 0, b: 0 };
    const canvas = document.createElement('canvas');
    const context = canvas.getContext && canvas.getContext('2d');
    const blockSize = 5; // only visit every 5 pixels
    let count = 0;
    let data;
    let width;
    let height;
    let length;

    if (!context) {
      return this._makeColor(defaultRGB);
    }

    height = canvas.height = image.height;
    width = canvas.width = image.width;

    context.drawImage(image, 0, 0);

    if (!box) {
      box = BoundBoxModel.make(0, 0, width, height);
    }

    try {
      data = context.getImageData(box.x, box.y, box.width, box.height);
    } catch (e) {
      /* security error, img on diff domain */
      return this._makeColor(defaultRGB);
    }

    length = data.data.length;

    for (let i = -4; i < length; i += blockSize * 4) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    // tslint:disable-next-line
    rgb.r = ~~(rgb.r / count);
    // tslint:disable-next-line
    rgb.g = ~~(rgb.g / count);
    // tslint:disable-next-line
    rgb.b = ~~(rgb.b / count);

    return this._makeColor(rgb);
  }

  private _makeColor(rgb: { r: number; g: number; b: number }): ColorModel {
    return new ColorModel(`rgb(${rgb.r},${rgb.g},${rgb.b})`);
  }

  private _createTempResolutionModel(url: string): ResolutionModel {
    return {
      url,
      secure_url: url,
      size: '',
      filename: '',
      extension: '',
      dimensions: {
        width: 0,
        height: 0,
      },
      uuid: '',
      bytes: 0,
      provider: '',
      directory: '',
    };
  }
}
