import { Injectable, SimpleChange } from '@angular/core';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { IPack } from '@shared/elements/pack.entity';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { ReplaySubject, Subject } from 'rxjs';
import { ElementNewApiService } from '@app/editor/services/element-new-api.service';
import { GalleryElementType } from '@app/editor/enums/editor-elements/gallery-type.enum';
import { ISearchElementType } from '@app/editor/types/elements/element-search.type';

export enum enumTextType {
  TITLE = 'title',
  AUTHOR = 'author',
}

@Injectable({ providedIn: 'root' })
export class ElementsGalleryService {
  public store: ISearchEvent = {};
  public storeObservable: Subject<ISearchEvent> = new ReplaySubject<ISearchEvent>(1);

  constructor(
    private _elementNewApiService: ElementNewApiService
  ) {}

  /**
   * Armazena um objeto com o último estado
   * de pesquisa do painel
   *
   * @param store IPanelStore - Novo estado
   */
  setElementsPanelStore(store: ISearchEvent) {
    this.store = {
      ...store
    } as ISearchEvent;
    this.storeObservable.next(this.store);
  }

  /**
   * Limpa o estado de pesquisa do painel
   */
  resetPanelStore() {
    this.store = {};
  }

  findDefaultTags(): ILocaleTag[] {
    const defaultTags = {
      default: [
        {
          name: 'shape',
          id: '1',
          languageISOCode: 'PT_BR'
        },
        {
          name: 'emoji',
          id: '2',
          languageISOCode: 'PT_BR'
        },
        {
          name: 'illustration',
          id: '3',
          languageISOCode: 'PT_BR'
        },
      ],
    };

    return defaultTags.default;
  }

  /**
   * Obtém todos os pacotes temáticos de elementos.
   * Deve ser transferido para a API.
   *
   * @returns IPack[] - Array com informações dos temas
   */
  getPacks(): Promise<IPack[]> {
    return this._elementNewApiService.getPacks();
  }


  /**
   * Define se mudança na propriedade de determinado
   * painel é suficiente para atualizá-lo
   *
   * @param change SimpleChange - Mudança de propriedade
   * @returns boolean - Se painel deve ser atualizado
   */
  compareChanges(change: SimpleChange): boolean {
    return (
      change?.currentValue &&
      (!change?.previousValue || change?.previousValue === undefined)
    );
  }

  private _getValidElementType(store: ISearchEvent) {
    let elementType: GalleryElementType;
    if (!store?.elementType) {
      elementType = GalleryElementType.EMOJI;
    } else {
      elementType = this._isEmoji(store.elementType) ?
        GalleryElementType.EMOJI : GalleryElementType.SHAPE;
    }
    return this._elementNewApiService.getElementTypeByContentType(elementType);
  }

  private _isEmoji(elementType: string): boolean {
    return (elementType === GalleryElementType.EMOJI) || (elementType as any === ISearchElementType.SVG);
  }
}
