import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { FontsService } from '@app/editor/services/fonts.service';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';

import { IFontModel, ITextElementModel, PageModel } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FontsComponent implements OnInit, OnDestroy {
  public searchFontText: string;
  public currentNavPanel = 0;

  public fonts: IFontModel[] = [];

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Output()
  selectedFont: EventEmitter<IFontModel> = new EventEmitter<IFontModel>();

  private _destroy$ = new Subject<void>();

  constructor(
    private hotkeyService: HotkeyService,
    private _fontsService: FontsService,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    setTimeout(() => this.listFonts(), 500);
  }

  public enableHotkeys() {
    this.hotkeyService.enableHotkeys();
  }

  public disableHotkeys() {
    this.hotkeyService.disableHotkeys();
  }

  public listFonts() {
    this._fontsService.listTraktoFonts().pipe(takeUntil(this._destroy$)).subscribe(
      () => this.fonts = [...this._fontsService.collectionsFonts.traktoFonts],
      error => console.error(error),
    );
  }
}
