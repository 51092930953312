import { Injectable } from '@angular/core';
import {
    DataTracking,
    DataTrackingEvents as DTEventTypes,
} from '@trakto/data-tracking';
import { downloadTypeEnum } from '@trakto/models';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { TKTAnalytics } from '../custom/tkt-analytics.service';
import { FBTraktoTracking } from '../facebook/fbTraktoTracking.analytics';
import { GoogleAnalyticsService } from '../google/google-analytics.service';
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class DesignDownloadAnalyticsService extends DataTracking {

    constructor(
        private _googleAnalyticsService: GoogleAnalyticsService,
        private _fbTrack: FBTraktoTracking,
        private _tktAnalytics: TKTAnalytics,
        private _productService: WhitelabelProductService,
        private _userService: UserService,
    ) {
        super();
    }

    public designDownloaded(type: string) {
        this._fbTrack.designDownload(type);
        this._googleAnalyticsService.trackEvent(
            `Download do design no formato ${type}`,
            {
                category: `Download`,
                label: `O usuário realizou o download do design utilizando o editor web`,
                value: 1,
            },
            () => { }
        );
        this._tktAnalytics.track({
            user_id: this._userService.user.id || 'anonymous',
            event_type: 'Design Baixado',
            event_properties: {
                tipo: type,
                id_app: this._productService.currentApp?.id || 'anonymous',
                id_produto: this._productService.currentProduct?.id || 'anonymous',
            }
        });
    }

    public _trackProjectDownloaded(
        props,
        type: downloadTypeEnum,
        success: boolean,
        error?: any,
    ) {
        if (success) {
            this._googleAnalyticsService.trackEvent(
                `Download do design no formato ${downloadTypeEnum[type]}`,
                {
                    category: `Download`,
                    label: `O usuário realizou o download do design utilizando o editor web`,
                    value: 1,
                },
                () => { }
            );
        }
    }
}
