import { Component, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { IEventModel } from '@trakto/models';
import { delay, takeUntil } from 'rxjs/operators';
import { BlurService } from '../../editor/services/blur.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalLoaderComponent implements OnDestroy {
  private element: any;

  public error: boolean;
  public finished: boolean;
  public label: string;
  public timerOnFinish: number;

  public message_download: string;
  public message_error: string;

  private _destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private _elementRef: ElementRef,
    private blurService: BlurService,
  ) {
    this.translate.get('modal_loader').pipe(takeUntil(this._destroy$)).subscribe(key => {
      this.message_download = key['message']['title'];
      this.message_error = key['message']['title_error'];
    });

    this.element = this._elementRef.nativeElement;

    this.finished = false;
    this.timerOnFinish = 3000;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public show(label?: string): void {
    this.label = label ? label : `${this.message_download}`;
    this.element.setAttribute('class', '--active');
    this.blurService.apply();
  }

  public showError(label?: string): void {
    this.error = true;
    this.label = label ? label : `${this.message_error}`;
    this.finished = false;
    this.timerOnFinish = 4000;

    delay(this.timerOnFinish);
    Promise.resolve().then(() => {
      this.blurService.remove();
      this.element.removeAttribute('class');
      this.error = false;
    });
  }

  public hide(event?: IEventModel): any {
    if (event && !event.data) {
      this.error = true;

      delay(this.timerOnFinish);
      Promise.resolve().then(() => {
        this.blurService.remove();
        this.element.removeAttribute('class');
        this.error = false;
      });

      return;
    }

    this.finished = true;

    delay(this.timerOnFinish);
    Promise.resolve().then(() => {
      this.blurService.remove();
      this.element.removeAttribute('class');
      this.finished = false;
    });
  }
}
