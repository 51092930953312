<div class="tlinks-personalize tkt-link-panel-container">
  <!-- Input Link -->
  <section class="tlinks-personalize__create-link">
    <p>
      {{ 'trakto_links.personalize.input_title_create' | translate }}
    </p>

    <label [class]="{ 'input-disabled': !button.edit.disabled }">
      <i class="trakto-icon-link-drop"></i>
      <span>
        {{ linkPlaceholder }}

        <strong *ngIf="!button.edit.disabled">
          {{ inputValue }}
        </strong>
      </span>
      <input
        #inputlink
        type="text"
        [(ngModel)]="inputValue"
        [placeholder]="'trakto_links.personalize.input_placeholder' | translate"
        (keyup)="onLinkTyping($event)"
        [imask]="inputMask"
        [disabled]="!canCreateLink"
        (focusout)="disableInputLink()"
        maxlength="linkLength.max"
      />
      <i *ngIf="link.url.length" class="trakto-icon-success-star"></i>
    </label>
  </section>

  <section
    [ngClass]="{
      'tlinks-personalize__link-status': true,
      loading: link.loading,
      success: link.status === panelsEnum.LINK_SUCCESS && !link.loading,
      failed: link.status === panelsEnum.LINK_FAILED && !link.loading
    }"
  >
    <!-- Loading -->
    <p *ngIf="link.loading">
      <i class="spinner">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </i>
    </p>

    <!-- Empty  -->
    <p *ngIf="link.status === panelsEnum.LINK_EMPTY && !link.loading">
      {{ 'trakto_links.personalize.link_alert_empty' | translate }}
    </p>

    <!-- Success -->
    <p *ngIf="link.status === panelsEnum.LINK_SUCCESS && !link.loading">
      <i class="trakto-icon-success-ball"></i>
      {{ 'trakto_links.personalize.link_alert_available' | translate }}
    </p>

    <!-- Failed -->
    <p *ngIf="link.status === panelsEnum.LINK_FAILED && !link.loading">
      <i class="trakto-icon-failed-ball"></i>
      {{ 'trakto_links.personalize.link_alert_unavailable' | translate }}
    </p>
  </section>

  <!-- Button copy link -->
  <section class="tlinks-personalize__button-link">
    <kml-button
      [disabled]="link.copied || link.status !== panelsEnum.LINK_SUCCESS || !canCreateLink"
      type="primary"
      [label]="
        (link.copied === false
          ? 'trakto_links.personalize.button_copy'
          : 'trakto_links.personalize.button_copy_finish'
        ) | translate
      "
      (click)="copyLinkToClipboard()"
    ></kml-button>
  </section>

  <!-- Button edit link -->
  <section class="tlinks-personalize__button-edit" *ngIf="button.edit.show">
    <kml-button
      type="secondary"
      [label]="'trakto_links.personalize.button_edit' | translate"
      [disabled]="button.edit.disabled || null"
      (click)="enableInputLink()"
    ></kml-button>
  </section>

  <!-- QR Code show -->
  <section class="tlinks-personalize__qrcode">
    <trakto-links-qrcode-empty *ngIf="!isQRCode"></trakto-links-qrcode-empty>

    <trakto-links-qrcode-download
      #qrLinksDownload
      *ngIf="isQRCode"
      [profileImage]="profileImage"
      [qrcodeLink]="link.url"
      [loading]="downloadLoading"
      (download)="downloadQRCode()"
    ></trakto-links-qrcode-download>
  </section>

  <div
    class="tlinks-personalize__qrcode-page"
    #qrCodeTemplate
    id="qrCodeTemplate"
  >
    <trakto-qrcode-page-download
      [qrcodeLink]="link.url"
      [qrcodeName]="link.name"
      [profileImage]="profileImage"
      [isB2C]="isB2C"
      [mainLink]="linkPlaceholder"
    ></trakto-qrcode-page-download>
  </div>
</div>
