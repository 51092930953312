<trakto-button-preview
  [isColor]="true"
  [disabled]="disabled"
  [url]="internalColor?.rgba || internalColor?.gradientSafe"
  [height]="height"
  [icon]="icon"
  [iconColor]="hexaLabelColor"
  [content]="content"
  (onClick)="onClickButtonPreview()"
>
</trakto-button-preview>
