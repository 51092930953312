import { IPlanConfigModel } from '@app/shared/subscription/plan-config.model';
import { EntityReference } from '@auth/shared/entity-reference.model';
import { IApp, IProducts } from '@trakto/models';
import { ISubscription } from '@trakto/models/src/plan/subscription.model';

// TODO converter para camelcase
export class TraktoUser {
  public id: string;
  public firstname: string;
  public lastname: string;
  public created_at: any;
  public email: string;
  public phonenumber?: string;
  public company?: string;
  public role: any;
  public app_reference: EntityReference;
  public products: IProducts[];
  public user_reference: EntityReference;
  public logo?: any;
  public limits?: IPlanConfigModel;
  public plan_offered_reference?: EntityReference;
  public subscription_active?: boolean;
  public subscription_payment_status?: string;
  public subscription_plan_name?: string;
  public subscription_plan_reference?: EntityReference;
  public subscription_plan?: ISubscription;
  public subscription_reference?: EntityReference;
  public subscription_rules?: any;
  public subscription_status?: string;
  public is_legacy: boolean;
  public is_premium: boolean;
  public current_profile: any;

}
