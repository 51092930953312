import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { elementTypeEnum } from '@trakto/models';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { AssetsGalleryAnalyticsService } from '@services/analytics/wrapper/elements/assetsGallery.analytics-wrapper.service';
import { FileRepository } from '@editor/repository/file.repository';

@Component({
  selector: 'trakto-library-gif-tab-mobile',
  templateUrl: './library-gif-tab-mobile.component.html',
  styleUrls: ['./library-gif-tab-mobile.component.scss'],
})
export class LibraryGifTabMobileComponent implements OnInit {
  loading = false;
  images = [];
  nextPage: any = undefined;
  hasNextPage = false;
  public stickersDataProvider: {
    list: any[];
    page?: number;
    pageSize: number;
    hasNextPage: boolean;
    nextRange?: string;
  };

  private _defaultLimit = 30;

  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _filesRepository: FileRepository,
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
    private _dataTrackingService: AssetsGalleryAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.listStickByUser(true);
  }


  listStickByUser(initialPage = false) {
    let query: Promise<any>;

    if (initialPage) {
      this.stickersDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
      query = this._filesRepository.findGifsByLoggedUser(
        this.stickersDataProvider.pageSize,
      );
    } else {
      this.stickersDataProvider.page++;
      query = this._filesRepository.findGifsByLoggedUser(
        this.stickersDataProvider.pageSize,
        this.stickersDataProvider.nextRange,
      );
    }

    query
      .then((data: any) => {
        const list: any[] = [];

        if (data.length > this.stickersDataProvider.pageSize) {
          this.stickersDataProvider.hasNextPage = true;
          data.pop();
          this.stickersDataProvider.nextRange =
            data[data.length - 1].created_at;
        } else {
          this.stickersDataProvider.hasNextPage = false;
        }

        data.forEach(doc => {
          list.push({
            type: elementTypeEnum.image,
            thumb: doc.url.url || doc.url.raw.url,
            value: doc.url.url || doc.url.raw.url,
            label: null,
            author: null,
            width: doc.url.raw ? doc.url.raw.dimensions.width : 500,
            height: doc.url.raw ? doc.url.raw.dimensions.height : 500,
          });
        });

        this.stickersDataProvider.list =
          this.stickersDataProvider.list.concat(list);

        this._dataTrackingService.trackProjectAssetSearch({
          search_query: 'my-gallery',
          element_type: 'sticker',
          success: true,
          total_displayed: this.stickersDataProvider.list.length,
          total_found: data.length,
        });

        return this.stickersDataProvider;

      })
      .catch(error => {
        this._dataTrackingService.trackProjectAssetSearch({
          search_query: 'my-gallery',
          element_type: 'sticker',
          success: false,
          last_error: error,
        });

      });
  }

  insertImage(image: any) {
    this._elementFactoryFacadeService.createAndAddGifByUrl(image.url, image.thumb);
    this.clicked.emit(true);
  }

}
