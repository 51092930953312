<div #element id="panel-link-website" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-social-whatsapp"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.link.panel-whatsapp.header' | translate }}
      </h2>
    </div>

    <div class="panel-scroller-close" (click)="closePanelLink()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-full__container">
    <div class="panel-full__content">
      <div class="panel-full__overlay-up"></div>
      <div class="panel-full__overlay-down"></div>
      <div class="panel-full__header" [class.--whatsapp]="true">
        <figure>
          <img src="assets/images/whatsapp-logo.png" alt="whatsapp-logo" />
        </figure>
      </div>

      <div class="panel-full__form" (submit)="handleSubmit()">
        <form [formGroup]="form">
          <div class="panel-full__form-field">
            <label for="Phone" [class.--whatsapp]="true">
              {{
                'property_panel.link.panel-whatsapp.input_number.label'
                  | translate
              }}
            </label>
            <div class="panel-full__dropdown">
              <ng-container *ngIf="countries">
                <div class="panel-full__dropdown-select">
                  <trakto-select
                    #phone
                    [search]="false"
                    [customMini]="true"
                    [active]="selectedCountry"
                    placeholder="Pais"
                    (click)="activeSelecetTheme(true)"
                    (onChange)="changeCountry($event)"
                  >
                    <trakto-select-option
                      [select]="phone"
                      [value]="country.code"
                      *ngFor="let country of countries"
                    >
                      {{ country.name }}
                    </trakto-select-option>
                  </trakto-select>
                </div>
              </ng-container>
              <div class="panel-full__dropdown-input">
                <input
                  type="text"
                  placeholder="82 99999 9999"
                  (blur)="enableHotkeys()"
                  (focus)="disableHotkeys()"
                  (input)="formatPhone($event)"
                  formControlName="phone"
                />
              </div>
            </div>
          </div>

          <div class="panel-full__form-field">
            <label for="message" [class.--whatsapp]="true">
              {{
                'property_panel.link.panel-whatsapp.input_msg.label' | translate
              }}
            </label>
            <textarea
              formControlName="message"
              placeholder="{{
                'property_panel.link.panel-whatsapp.input_msg.placeholder'
                  | translate
              }}"
              name="body"
              id="message"
              cols="26"
              rows="10"
              [ngModel]="mensagemBody"
            ></textarea>
          </div>

          <trakto-button
            button--custom
            button--paddin-link
            button--xxlarge
            type="submit"
            [disabled]="form.invalid"
          >
            <span>{{
              (elementSelected && elementSelected.whatsapp
                ? 'property_panel.link.remove_link_button'
                : 'property_panel.link.generate_link_button'
              ) | translate
            }}</span>
          </trakto-button>
        </form>
      </div>
    </div>
  </div>
</div>
