import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { ImagesService } from '@app/editor-v3/services/images.service';

@Component({
  selector: 'trakto-background-removal-card',
  templateUrl: './background-removal-card.component.html',
  styleUrls: ['./background-removal-card.component.scss'],
})
export class BackgroundRemovalCardComponent implements OnInit {
  loading = false;
  success = false;

  constructor(
    private _elementsService: ElementsService,
    private _imagesService: ImagesService,
  ) {}

  ngOnInit(): void {}

  async removeImageBackground() {
    this.loading = true;

    try {
      await this._imagesService.backgroundRemoval(
        this._elementsService.selectedElement,
      );
    } finally {
      this.successTimeout();
    }
  }

  successTimeout() {
    this.loading = false;
    this.success = true;

    setTimeout(() => {
      this.success = false;
    }, 3000);
  }
}
