import { NgModule } from '@angular/core';
import { GifMagicTemplateService } from '@shared/magic-template/shared/gif-magic-template.service';
import { IconMagicTemplateService } from '@shared/magic-template/shared/icon-magic-template.service';
import { ImageMagicTemplateService } from '@shared/magic-template/shared/image-magic-template.service';
import { MagicTemplateService } from '@shared/magic-template/shared/magic-template.service';
import { ShapeMagicTemplateService } from '@shared/magic-template/shared/shape-magic-template.service';
import { TextMagicTemplateService } from '@shared/magic-template/shared/text-magic-template.service';
import { UtilMagicTemplateService } from '@shared/magic-template/shared/util-magic-template.service';
import {
  FontMagicTemplateService
} from '@shared/magic-template/shared/font-magic-template.service';

@NgModule({
  providers: [
    IconMagicTemplateService,
    ImageMagicTemplateService,
    GifMagicTemplateService,
    MagicTemplateService,
    ShapeMagicTemplateService,
    TextMagicTemplateService,
    FontMagicTemplateService,
    UtilMagicTemplateService,
  ],
})
export class MagicTemplateModule {}
