import { Component, OnDestroy, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-opacity-mobile',
  templateUrl: './panel-opacity-mobile.component.html',
  styleUrls: ['./panel-opacity-mobile.component.scss'],
})
export class PanelOpacityMobileComponent implements OnInit, OnDestroy {
  elementOpacity = 100;

  subscription: Subscription;

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this._documentStateManagerService.element$.subscribe(element => {
      this.elementOpacity = (element?.opacity || 1) * 100;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  setOpacity(val: number) {
    this.elementOpacity = val;
    this._elementsService.changeElementOpacity(val / 100);
  }
}
