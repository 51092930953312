import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IElementDropdown } from '../dropdown';

import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { DropdownService } from '../dropdown.service';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IDropdownControlValueAccessor,
  IDropdownOption,
  IDropdownSelectConfig,
} from '@trakto/models';
import { PlacementEnum } from '../placement.model';

@Component({
  selector: 'trakto-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownSelectComponent
  implements
    OnInit,
    OnChanges,
    IElementDropdown,
    IDropdownControlValueAccessor {
  @ViewChild('select', { static: true }) select;
  @ViewChild('text', { static: true }) text;

  private _disabled: boolean;
  private _truncate: boolean;
  private _selected: any;
  private _selectedItem: IDropdownOption;

  public isOpen: boolean;
  public elementNative: any;
  public baseClass: string;

  @Input() config: IDropdownSelectConfig;

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled(): boolean {
    return this._disabled || false;
  }

  @Input()
  public set truncate(truncate: boolean) {
    this._truncate = truncate;
  }

  public get truncate(): boolean {
    return this._truncate || false;
  }

  @Input()
  public set selected(selected: any) {
    this._selected = selected;
  }

  public get selected() {
    return this._selected || null;
  }

  public set selectedItem(selected: IDropdownOption) {
    this._selectedItem = selected;
    this.setTextActive(selected.label);
  }

  public get selectedItem() {
    return this._selectedItem || null;
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _onChange = (value: any) => {};
  private _onTouched = () => {};

  constructor(
    private _elementRef: ElementRef,
    private dropdownService: DropdownService,
    private _signalService: SignalsService,
  ) {
    this.isOpen = false;
    this.baseClass = 'trakto-dropdown-select';
  }

  @HostListener('document:click', ['$event'])
  out(event: Event) {
    if (!this._elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.close();
    }
  }

  ngOnInit() {
    this.elementNative = this._elementRef.nativeElement.children[0];
    this.loadPlacement();

    if (this.selected) {
      this.config.options.map((option, index) => {
        if (option.value === this.selected) {
          this.deselectAll();
          this.config.options[index].selected = true;

          this.selected = option.value;
          this.selectedItem = option;
        }
      });
    } else {
      const opt = this.config.options.filter(
        option => option.selected === true,
      );

      if (opt.length > 0) {
        this.selected = opt[0].value;
        this.selectedItem = opt[0];
      } else {
        this.config.options.map(option => {
          const { value } = option;

          this.selected = value;
          this.selectedItem = option;
        });
      }
    }
  }

  ngOnChanges() {
    this.getEmitFont();

    this.select.disabled = this.disabled ? true : false;

    if (this.select.disabled) {
      this.close();
    }
  }

  public getEmitFont(): void {
    this._signalService.connect(
      enumSignals.PROPERTY_FONT_OPTIONS,
      fontOptions => {
        const { data } = fontOptions;

        if (fontOptions.hasOwnProperty('data')) {
          if (data.options.length === 1) {
            data.options.map(oneOption => {
              const { value } = oneOption;

              this.selected = value;
              this.selectedItem = oneOption;
            });
          } else {
            const opt = data.options.filter(option => option.selected === true);

            if (opt.length > 0) {
              this.selected = opt[0].value;
              this.selectedItem = opt[0];
            }
          }
        }
        return;
      },
    );
  }

  _getHostElement(): any {
    return this._elementRef.nativeElement;
  }

  change(value: string, bubbles: boolean = true) {
    if (this.selected !== value) {
      this._onChange(value);

      if (this.config) {
        this.config.options.map((option, index) => {
          if (option.value === value) {
            this.deselectAll();
            this.config.options[index].selected = true;

            this.selected = option.value;
            this.selectedItem = option;
          }
        });
      }

      if (bubbles) {
        this.onChange.emit(value);
      }
    }

    this.close();
  }

  writeValue(selected: any): void {
    this.selected = selected;

    this.config.options.map((option, index) => {
      if (option.value === this.selected) {
        this.deselectAll();
        this.config.options[index].selected = true;

        this.selectedItem = option;
      }
    });
  }

  registerOnChange(fn: (p?: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (p?: any) => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  toggle() {
    !this.isOpen ? this.open() : this.close();
  }

  open() {
    this.dropdownService.disable();
    this.isOpen = true;

    if (this.elementNative) {
      this.elementNative.classList.add('--active');
    }

    this.onOpen.emit(this.isOpen);
    this.dropdownService.active(this);
  }

  close() {
    this.isOpen = false;

    if (this.elementNative) {
      this.elementNative.classList.remove('--active');
    }

    this.onClose.emit(this.isOpen);
  }

  onSelect(item: IDropdownOption, index: number, bubbles = true): void {
    if (this.selected !== item.value && this.config) {
      this.deselectAll();
      this.config.options[index].selected = true;
      this.change(item.value, bubbles);
    }

    this.close();
  }

  deselectAll(): void {
    this.config.options.map(option => (option.selected = false));
  }

  loadPlacement(): void {
    if (!this.config.placement) {
      this.config.placement = PlacementEnum.right;
    }

    switch (this.config.placement) {
      case PlacementEnum.center:
        this.setPlacement(PlacementEnum.center);
        break;
      case PlacementEnum.left:
        this.setPlacement(PlacementEnum.left);
        break;
      default:
        this.setPlacement(PlacementEnum.right);
        break;
    }
  }

  setPlacement(position: PlacementEnum): void {
    this.elementNative.removeAttribute('class');
    this.elementNative.setAttribute('class', `${this.baseClass} --${position}`);
  }

  setTextActive(text: string): any {
    if (this.truncate) {
      const label: string = text.replace(/-/g, ' ');
      const limit: string[] = label.split(' ');

      if (limit.length > 1) {
        const truncated = `${limit[0].substring(0, 1)}.`;
        this.text.nativeElement.innerHTML = `${truncated} ${limit[1]}`;
        return;
      }
    }

    this.text.nativeElement.innerHTML = text;
  }
}
