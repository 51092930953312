export const extensions = ['.jpg', '.jpeg', '.png', '.svg', '.gif'];

export interface IFile {
  filename: string;
  extension: string;
  directory: string;
  provider?: string;
  isThumbnail?: boolean;
  base64?: string;
  uuid?: string;
  url?: string;
  user_reference?: any;
}
