import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { IUser } from '@trakto/models';
import { PlanConfigService } from './plan-config.service';
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable()
export class SubscriptionService {
  planChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private _planConfigService: PlanConfigService,
    private _productService: WhitelabelProductService,
    private _userService: UserService,
  ) {}

  /**
   * updateSubscription
   */
  public async updateUserSubscriptionData({
    planId,
    profile,
  }: {
    userId: any;
    planId: any;
    profile: any;
  }) {
    const plan = await this._planConfigService.getPlan(planId);

    // Update limit for usage
    await this._planConfigService.updateUserLimits(plan.rules);

    // Emit Event that identify a new subscription
    this.planChanged.emit({ profile, plan });
  }

  public async create(
    planId: string,
    token: string,
    cupponId?: string,
  ): Promise<any> {
    const url = `${environment.api.stripe.url}/subscription`;
    const body = {
      user_id: this._userService.user.id,
      plan_id: planId,
      card_token: token,
      product_id: this._productService.currentProductId,
    };

    if (cupponId) {
      body['coupon_id'] = cupponId;
    }

    const h: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const profile: any = await this.http
      .post(url, body, { headers: h })
      .toPromise();
    const user: IUser = profile.data;
    const resultPlan = await this._planConfigService.getPlan(planId);
    await this._planConfigService.updateUserLimits(resultPlan.rules);
    this.planChanged.emit({ profile: user });

    return profile;
  }
}
