import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseService } from './firebase.service';
import { FirebaseCloudFunctionsService } from './functions/firebase-cloud-functions.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseServiceImpl extends FirebaseService {
  constructor(
    private _auth: AngularFireAuth,
    private _storage: AngularFireStorage,
    private _firestore: AngularFirestore,
    private _functions: FirebaseCloudFunctionsService,
  ) {
    super();
  }

  public callable(name: string): any {
    return this.functions.callable(name);
  }

  public get authorizationModule(): AngularFireAuth {
    return this._auth;
  }

  public get firestoreModule(): AngularFirestore {
    return this._firestore;
  }

  public get functions(): FirebaseCloudFunctionsService {
    return this._functions;
  }

  public get storage(): AngularFireStorage {
    return this._storage;
  }
}
