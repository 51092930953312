import { Injectable } from '@angular/core';
import { PageIMGConversionService } from '@app/shared/svg-viewer/shared/page-img-conversion.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import {
  IUser,
  mediaProviderEnum,
  PageModel,
} from '@trakto/models';
import {
  ImageService as ImageServiceGR,
  ResolutionsModel
} from '@trakto/graphics-resources';
import { ConversionParams } from '@trakto/svg-converter';
import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { AuthService } from '@auth/shared/auth.service';
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public isLoadingBG: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _uploadStorageService: UploadStorageService,
    private _pageIMGConversionService: PageIMGConversionService,
    private _userService: UserService,
  ) {}

  /**
   * Enviar a imagem para remoção de fundo;
   *
   * @param url: imagem que terá o background removido
   * @returns uma Promise com a imagem sem background.
   */
  public async cutImageBackground(url: string): Promise<ResolutionsModel> {
    try {
      const base64 = await ImageServiceGR.removeBackground(url);

      const config = {
        file: base64,
        user: this._userService.user as IUser,
        prefix: 'user_image',
        directory: 'my_gallery',
        provider: mediaProviderEnum.external,
        isThumbnail: true,
      };
      return (await this._uploadStorageService.uploadImage(config)).resolutions;
    } catch (err) {
      throw new Error('Erro ao tentar remover background da imagem.');
    }
  }

  public createImage(page: PageModel, maxSize = 2000): Observable<string> {
    return this._pageIMGConversionService.toBase64(
      page,
      ConversionParams.makeConversionParams(
        false,
        Math.min(1, maxSize / Math.max(page.width, page.height)),
      ),
    );
  }

  public createImageBlob(page: PageModel, maxSize = 3000): Observable<any> {
    return this.createImage(page, maxSize).pipe(
      map(data => this.convertBase64ToBlob(data)),
    );
  }

  public convertBase64ToBlob(base64: string): Blob {
    const byteCharacters = atob(
      base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
    );
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: 'image/png' });
  }

  public getExtensionFile(filename: string): string {
    if (filename) {
      return filename.split(/\#|\?/)[0].split('.').pop().trim();
    }

    return '';
  }
}
