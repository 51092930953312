import {
  Component,
  EventEmitter,
  Injectable,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DrawableComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable.component';
import { ShapeElementService } from '@services/shape-element.service';
import { IElementModel } from '@trakto/models';
import { IShapeElementModel } from '@trakto/models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-youtube]',
  template: `
    <svg:path
      [traktoPath]="backgroundShape"
      [attr.transform]="element.finalMatrixStr"
      [ref]="ref"
    ></svg:path>

    <svg:g
      [attr.transform]="element.positionMatrixStr"
      (click)="clickedVideo.emit(element)"
    >
      <svg:image
        [attr.width]="element.finalWidth"
        [attr.height]="element.finalHeight"
        [attr.href]="element['thumbUrl']"
        [attr.opacity]="element.opacity"
        x="0"
        y="0"
        fill="#000000'"
      ></svg:image>

      <svg:image
        [attr.width]="youtubeIcon.width * element.scaleX"
        [attr.height]="youtubeIcon.height * element.scaleY"
        [attr.x]="
          element.finalWidth / 2 - (youtubeIcon.width * element.scaleX) / 2
        "
        [attr.y]="
          element.finalHeight / 2 - (youtubeIcon.height * element.scaleY) / 2
        "
        href="./assets/images/youtube.svg"
      ></svg:image>
    </svg:g>
  `,
})
@Injectable()
export class DrawableYoutubeComponent
  extends DrawableComponent
  implements OnChanges {
  @Output()
  clickedVideo: EventEmitter<IElementModel> = new EventEmitter<IElementModel>();

  public backgroundShape: IShapeElementModel;
  public youtubeIcon = { width: 68, height: 48 };

  constructor(private shapeService: ShapeElementService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!this.element) {
      return;
    }
    this.backgroundShape = this.shapeService.createShapeByElement(this.element);
    this.backgroundShape.backgroundColor = 'transparent';
  }
}
