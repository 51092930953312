<div class="chip-container">
  <mat-form-field>
    <input matInput type="text" placeholder="{{ placeholder }}" [matChipInputFor]="tags"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="onChipsChanged($event)"
      (keydown.enter)="onChipsChanged($event.target.value); $event.target.value = ''"
      (focus)="hotkeyService.disableHotkeys()" (blur)="hotkeyService.enableHotkeys()">
  </mat-form-field>
  <mat-chip-list #tags>
    <mat-chip *ngFor="let tag of options; let i = index" [selectable]="selectable"
    [removable]="removable" (removed)="onChipsRemoved(i)">
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>