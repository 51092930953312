<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-shape.svg"
    label="Ajustes da forma"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="secondary-options">
      <trakto-panel-secondary-option-mobile
        icon="delete"
        (click)="deleteElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="block"
        [active]="!selectedElement?.edit"
        (click)="blockElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="copy"
        (click)="duplicateElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="order"
        (click)="orderElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="align"
        (click)="alignElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="transparency"
        (click)="setElementOpacity()"
      ></trakto-panel-secondary-option-mobile>
    </div>

    <div class="primary-options">
      <div class="grid">
        <trakto-panel-option-mobile
          icon="change"
          label="Trocar forma"
          (click)="changeShape()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          icon="color"
          label="Cor"
          (click)="changeShapeColor()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          [active]="selectedElement?.filter === 'dropshadow'"
          icon="shadow"
          label="Sombra"
          (click)="applyShadow()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          [active]="selectedElement?.filter === 'gaussianblur'"
          icon="blur"
          label="Desfocado"
          (click)="applyBlur()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          icon="link-element"
          label="Adicionar link"
          (click)="openLinksPanel()"
        ></trakto-panel-option-mobile>
      </div>
    </div>
  </div>
</div>
