<button
  #button
  class="trakto-button-preview trakto-button-preview--transparent"
  [style.minHeight]="height"
  (click)="press($event)"
>
  <span>
    {{ content }}
  </span>

  <div *ngIf="icon">
    <i [class]="icon" [style.color]="iconColor ? iconColor : '#2E2E54'"></i>
  </div>
</button>
