import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonPreviewModule } from '@shared/button-preview/button-preview.module';
import { LayoutModule } from '../layouts/layout.module';
import { ItemRendererdModule } from '../renderers/item-renderer.module';
import { ColorButtonComponent } from './components/color-button/color-button.component';
import { ColorPanelSeparatorComponent } from './components/color-panel/color-panel-separator/color-panel-separator.component';
import { ColorPanelComponent } from './components/color-panel/color-panel.component';
import { ColorPickerInputComponent } from './components/colorpicker/colorpicker-input/colorpicker-input.component';
import { ColorPickerPaletteComponent } from './components/colorpicker/colorpicker-palette/colorpicker-palette.component';
import { ColorPickerSliderComponent } from './components/colorpicker/colorpicker-slider/colorpicker-slider.component';
import { ColorPickerComponent } from './components/colorpicker/colorpicker.component';
import { GradientSafePipe } from './pipes/gradient-safe.pipe';
import { ColorItemRendererComponent } from './renderers/color-item-renderer/color-item-renderer.component';

import { ColorService } from './services/color.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LayoutModule,
    ItemRendererdModule,
    ButtonPreviewModule,
    TranslateModule,
  ],
  providers: [GradientSafePipe, ColorService],
  declarations: [
    ColorPanelComponent,
    ColorPickerPaletteComponent,
    ColorPickerInputComponent,
    ColorPickerSliderComponent,
    ColorPickerComponent,
    ColorItemRendererComponent,
    ColorButtonComponent,
    GradientSafePipe,
    ColorPanelSeparatorComponent,
  ],
  exports: [
    ColorPanelComponent,
    ColorPickerPaletteComponent,
    ColorPickerInputComponent,
    ColorPickerSliderComponent,
    ColorPickerComponent,
    ColorItemRendererComponent,
    ColorButtonComponent,
    GradientSafePipe,
    ColorPanelSeparatorComponent,
    TranslateModule,
  ],
  entryComponents: [ColorPanelComponent],
})
export class ColorModule {}
