import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

// Services
import { LocaleService } from '@app/editor/services/locale.service';

// Component
import { BaseComponent } from '@app/shared/base/base.component';
import { NotificationService } from '@app/shared/notification/notification.service';
import { PlanConfigService } from '@app/shared/subscription/plan-config.service';

// Models
import { TraktoUser } from '@auth/shared/auth.model';
import { AuthService } from '@auth/shared/auth.service';
import { ModalType } from '@editor/components/toolbar/modalType.enum';

import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';

// Others
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CopyService } from '@services/copy.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { ElementModelService } from '@services/element-model.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { InputComponent } from '@shared/forms/input/input.component';
import { ModalService } from '@shared/modal/modal.service';

// Component
import { ItemRenderer } from '@shared/renderers/item-renderer.model';
import {
  EnumCDNJustOnly,
  StorageService,
} from '@shared/storage/storage-service.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import { DimensionModel } from '@trakto/core-editor';

import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  ImageElementModel,
  INotificationMessage,
  inputTypeEnum,
  mediaProviderEnum,
  PageModel,
  shapePathEnum,
} from '@trakto/models';
import { from, Subscription } from 'rxjs';
import * as x2js from 'x2js';
import { GalleryImageService } from '@app/editor/services/gallery/gallery.images.service';
import { GalleryIconsService } from '@app/editor/services/gallery/gallery.icons.service';
import { GalleryGifsService } from '@app/editor/services/gallery/gallery.gifs.service';
import { GalleryFavoritesRepository } from '@editor/repository/gallery-favorites.repository';
import { ElementsGalleryService } from '@app/shared/elements-gallery/elements-gallery.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { PanelElementsComponent } from '../properties-panel/panel-elements/panel-elements.component';
import { IPack } from '@app/shared/elements/pack.entity';
import { ScrollListenerService } from '@app/editor/services/scroll-listener.service';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { AssetsGalleryAnalyticsService } from '@app/editor/services/analytics/wrapper/elements/assetsGallery.analytics-wrapper.service';
import { IAssetResponse } from '@editor/repository/file.repository';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { ISearchElementType } from '@editor/types/elements/element-search.type';
import { UserService } from '@app/editor-v3/services/user.service';
import { DocumentStateManagerService } from '@app/editor/services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';
import { UnsplashService } from '@app/editor/services/unsplash.service';

interface IAssetProvider {
  list: any[];
  page?: number;
  pageSize: number;
  hasNextPage: boolean;
  nextRange?: any;
}

@Component({
  selector: 'trakto-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends BaseComponent implements OnInit {
  @Input() actived = false;
  @ViewChild('searchGif', { static: false }) searchGif: InputComponent;
  @ViewChild('searchSticker', { static: false }) searchSticker: InputComponent;
  @ViewChild('searchImage', { static: false }) searchImage: InputComponent;
  @ViewChild('searchShape', { static: false }) searchShape: InputComponent;
  @ViewChild('searchEmoji', { static: false }) searchEmoji: InputComponent;

  public user: TraktoUser;
  private _defaultLimit = 30;
  private _currentLang: string;

  public isEmbeddedInstance: boolean;

  public hasPermissionBySearch: any;

  public document: IDocumentModel;
  public pageSelected: PageModel;
  public elementSelected: IElementModel;
  defaultCategoryShape: string;
  public notifyMSGs: INotificationMessage;
  translatedTexts: any;

  public customModal: boolean;

  public isStickerOrGifs = false;
  private _isB2C: boolean;

  @Input() showBannerToUser: boolean;

  highlightElementId: string;
  modalElementId: string;

  menuProviderText: any[];
  menuProviderImage: any[];
  menuProviderGif: any[];
  menuProviderSticker: any[];
  menuProviderShape: any[];
  menuProviderEmoji: any[];

  dataCategoryImage: string;
  dataCategoryGif: string;
  dataCategorySticker: string;
  dataCategoryShape: string;
  dataCategoryEmoji: string;

  modalDataShape: any[];
  modalDataEmoji: any[];
  imagesDataProvider: IAssetProvider;
  gifsDataProvider: IAssetProvider;
  stickersDataProvider: IAssetProvider;
  emojisDataProvider: IAssetProvider;
  shapesDataProvider: IAssetProvider;

  modalColumns: number;
  modalGap: number;
  modalTags: any[];

  loadingContent: boolean;
  defaultGalleryCategories: any;

  public iconsPagination = {
    ignoreClean: false,
    term: '',
    currentPage: 1,
    pageSize: 50,
    total: 0,
    hasNext: false,
    hasPrevious: false,
  };

  hasUser = false;

  private paragraphText =
    'Use this text box to write one or more paragraphs. You can use it to describe something, ' +
    'talk a little about yourself or just write some phrase you like.';

  private imageSubscription: Subscription = undefined;
  private shapeSubscription: Subscription = undefined;
  private emojiSubscription: Subscription = undefined;

  public textElementIsAvailable = false;
  public imageElementIsAvaliable = false;
  public videoElementIsAvailable = false;
  public gifElementIsAvailable = false;
  public shapeElementIsAvailable = false;
  public iconElementIsAvailable = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    authService: AuthService,
    private _copyService: CopyService,
    private _hotkeyService: HotkeyService,
    private _storageService: StorageService,
    private _uploadStorageService: UploadStorageService,
    private _modalService: ModalService,
    private _alertService: NotificationService,
    private _translateService: TranslateService,
    private _elementsFactoryFacadeService: ElementFactoryFacadeService,
    private _newElementService: ElementModelService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _planConfigService: PlanConfigService,
    private _localeService: LocaleService,
    private _productService: WhitelabelProductService,
    private _galleryImageService: GalleryImageService,
    private _galleryIconsService: GalleryIconsService,
    private _galleryGifsService: GalleryGifsService,
    private _galleryFavoritesService: GalleryFavoritesRepository,
    private _elementsGalleryService: ElementsGalleryService,
    private _panelStackService: PanelStackService,
    private _scrollListenerService: ScrollListenerService,
    private _assetsAnalyticsService: AssetsGalleryAnalyticsService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    private _userService: UserService,
    private _unsplashService: UnsplashService,
  ) {
    super(authService, platformId);

    this._isB2C = this._userService.isB2c;
    this._currentLang = this._translateService.currentLang;
    this.defaultGalleryCategories =
      this._localeService.translations['default_gallery_categories'];
    this.notifyMSGs = this._alertService.notificationsMSGs;
    this.hasPermissionBySearch = {
      image: false,
      gif: false,
      emoji: false,
      shape: false,
      sticker: false,
    };

    this.loadingContent = false;

    this.menuProviderText = [{ label: 'Todos', value: '' }];

    this.modalDataShape = [];
    this.modalDataEmoji = [];

    if (this._isB2C) {
      this.dataCategoryShape = this.getDefaultCategoryGallery('shape');
      this.dataCategoryGif = this.getDefaultCategoryGallery('gif');
      this.dataCategorySticker = this.getDefaultCategoryGallery('sticker');

      this.dataCategoryImage = this.getDefaultCategoryGallery('image');
      this.dataCategoryEmoji = this.getDefaultCategoryGallery('emoji');
    }

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.dataCategoryShape = this.getDefaultCategoryGallery('shape');
      this.dataCategoryGif = this.getDefaultCategoryGallery('gif');
      this.dataCategorySticker = this.getDefaultCategoryGallery('sticker');
      this.dataCategoryImage = this.getDefaultCategoryGallery('image');
      this.dataCategoryEmoji = this.getDefaultCategoryGallery('emoji');
    });

    this.getTranslatedTexts();
    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this._currentLang = event.lang;
      this.getTranslatedTexts();
    });

    this._productService.featuresAvailable.pipe(takeUntil(this._destroy$)).subscribe(features => {
      if (features) {
        this._identifyAvailableFeatures(features);
      }
    });
  }

  ngOnInit() {
    this.user = this._userService.user;
    this.isEmbeddedInstance = this._authService.isEmbeddedInstance();
    const searchs = [
      { key: 'image_stock', permission: 'image' },
      { key: 'gif_stock', permission: 'gif' },
      { key: 'icon_stock', permission: 'emoji' },
      { key: 'shape_stock', permission: 'shape' },
      { key: 'sticker_stock', permission: 'sticker' },
    ];

    searchs.map(search => {
      this.verifyPermissionAndCallToAction(
        search.key,
        () => (this.hasPermissionBySearch[search.permission] = true),
      );
    });

    this._copyService
      .getInput()
      .addEventListener('paste', (event: ClipboardEvent) =>
        this.handlePaste(event),
      );

    this._hotkeyService.addContext('toolbarkeys', (e: KeyboardEvent) => {
      this.addKeyListeners(e);
    });

    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(
      document => (this.document = document),
    );
    this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(
      page => (this.pageSelected = page),
    );
    this._documentStateManagerService.element$.pipe(takeUntil(this._destroy$)).subscribe(
      element => (this.elementSelected = element),
    );
  }

  private _identifyAvailableFeatures(features) {
    this.gifElementIsAvailable = features[ProductFeatures.GIF_GALLERY];
    this.shapeElementIsAvailable = features[ProductFeatures.SHAPE_GALLERY];
    this.iconElementIsAvailable = features[ProductFeatures.ICON_GALLERY];
    this.textElementIsAvailable = features[ProductFeatures.TEXT_GALLERY];
    this.imageElementIsAvaliable = features[ProductFeatures.IMAGE_GALLERY];
    this.videoElementIsAvailable = features[ProductFeatures.VIDEO_GALLERY];
  }

  public toggleStickerOrGif(stickerOrGif: boolean): void {
    this.isStickerOrGifs = stickerOrGif;
    stickerOrGif
      ? this._isB2C
        ? (this.setDataGifCategory('Trakto'),
          this.setHighlightElement('toolbar-button-gif', 'toolbar-button-gif'))
        : (this.setDataGifCategory(this.dataCategoryGif),
          this.setHighlightElement('toolbar-button-gif', 'toolbar-button-gif'))
      : this._isB2C
      ? (this.setDataStickerCategory('Trakto'),
        this.setHighlightElement('toolbar-button-gif', 'toolbar-button-gif'))
      : (this.setDataStickerCategory(this.dataCategorySticker),
        this.setHighlightElement('toolbar-button-gif', 'toolbar-button-gif'));
  }

  public toggleCustomModal(customModal: boolean): void {
    this.customModal = customModal;
  }

  getTranslatedTexts() {
    this._translateService
      .get([
        'default_categories_image_gallery',
        'default_categories_gif_gallery',
        'default_categories_sticker_gallery',
        'default_categories_shape_gallery',
        'default_categories_emoji_gallery',
        'categories_image_gallery',
        'categories_gif_gallery',
        'categories_sticker_gallery',
        'categories_shape_gallery',
        'categories_emoji_gallery',
        'toolbar',
        'general',
      ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(texts => {
        const { toolbar } = texts;
        this.translatedTexts = {
          ...texts,
          modal_my_images_title: toolbar.image.modal_my_images_title,
        };
        this.menuProviderImage = [];

        this.populateDataProviderShape();
        this.populateDataCategoryGif();
        this.populateDataCategorySticker();
        this.populateDataCategoryImagem();
        this.populateDataCategoryEmoji();
      });
  }

  // Preenche categorias utlizada na modal de Shape
  populateDataProviderShape() {
    this.menuProviderShape = this.translatedTexts.categories_shape_gallery;
  }

  // Preenche categorias utlizada na modal de Gif
  populateDataCategoryGif() {
    this.menuProviderGif = this.translatedTexts.categories_gif_gallery;
  }

  // Preenche categorias utlizada na modal de Sticker
  populateDataCategorySticker() {
    this.menuProviderSticker = this.translatedTexts.categories_sticker_gallery;
  }

  // Preenche categorias utlizada na modal de Imagem
  populateDataCategoryImagem() {
    this.menuProviderImage = this.translatedTexts.categories_image_gallery;
    const default_section =
    this.translatedTexts.toolbar.image.modal_my_images_title;
    this.hasUser = true;

    if (
      !this.menuProviderImage[0].items.find(e => e.value === 'my-gallery')
    ) {
      this.menuProviderImage[0].items.push({
        label: default_section,
        icon: 'trakto-icon-tkt-folder',
        value: 'my-gallery',
        asset_icon: 'assets/icons/tkt-folder.svg',
      });
    }
  }

  // Preenche categorias utlizada na modal de Emoji
  populateDataCategoryEmoji() {
    this.menuProviderEmoji = this.translatedTexts.categories_emoji_gallery;
  }

  openModal(id: ModalType, highlightElement: string, selectedElement: string) {

    if (!!this.modalElementId) {
      this._modalService.close(this.modalElementId);
    }

    this.modalElementId = id;
    switch (id) {
      case ModalType.image:
        const imageCategory = this.dataCategoryImage
          ? this.dataCategoryImage
          : this.getDefaultCategoryGallery('image');
        this.setDataImageCategory(imageCategory);

        this._modalService.open(id, true, (item: ItemRenderer) => {
          if (item && item.value) {
            this.createAndAddImageElement(
              `${item.value}`.replace('http://', 'https://'),
            );
          }
        });
        break;
      case ModalType.gif:
        const gifCategory = this.dataCategoryGif
          ? this.dataCategoryGif
          : this.getDefaultCategoryGallery('gif');
        this.setDataGifCategory(gifCategory);

        this._modalService.open(id, true, (item: ItemRenderer) => {
          if (item && item.value) {
            this.createAndAddGifElement(item.value as string);
          }
        });
        break;
      case ModalType.sticker:
        const stickerCategory = this.dataCategorySticker
          ? this.dataCategorySticker
          : this.getDefaultCategoryGallery('sticker');
        this.setDataStickerCategory(stickerCategory);

        this._modalService.open(
          ModalType.sticker,
          true,
          (item: ItemRenderer) => {
            if (item && item.value) {
              this.createAndAddGifElement(item.value as string);
            }
          },
        );
        break;
      default:
        break;
    }

    this.setHighlightElement(highlightElement, selectedElement);
  }

  async closeModal(id: string, event?: any) {
    try {
      if (event.provider === 'external' && id === ModalType.image) {
        const photo = await this._unsplashService.trackPhotoDownload(event.id);
        event.high = photo;
        event.low = photo;
        event.medium = photo;
        event.raw = photo;
        event.value = photo;
      }
      if (!!this.highlightElementId) {
        this.removeHighlight(this.highlightElementId);
      }
      this._modalService.close(id, event);
    } catch (error) {
      console.log(error);
    }
  }

  onRemoveHighlight() {
    if (!!this.highlightElementId) {
      this.removeHighlight(this.highlightElementId);
    }

    switch (this.modalElementId) {
      case ModalType.emoji:
        this.dataCategoryEmoji = this.getDefaultCategoryGallery('emoji');
        if (this.searchEmoji) {
          this.searchEmoji.value = this.getDefaultCategoryGallery('emoji');
        }
        break;
      case ModalType.image:
        this.dataCategoryImage = this.getDefaultCategoryGallery('image');
        if (this.searchImage) {
          this.searchImage.value = this.getDefaultCategoryGallery('image');
        }
        break;
      case ModalType.gif:
        this.dataCategoryGif = this.getDefaultCategoryGallery('gif');
        if (this.searchGif) {
          this.searchGif.value = this.getDefaultCategoryGallery('gif');
        }
        break;
      case ModalType.sticker:
        this.dataCategorySticker = this.getDefaultCategoryGallery('sticker');
        if (this.searchSticker) {
          this.searchSticker.value = this.getDefaultCategoryGallery('sticker');
        }
        break;
      case ModalType.shape:
        this.dataCategoryShape = this.getDefaultCategoryGallery('shape');
        if (this.searchShape) {
          this.searchShape.value = this.getDefaultCategoryGallery('shape');
        }
        break;
    }
  }

  setHighlightElement(highlightElement: string, selectedElement: string) {
    if (!!highlightElement) {
      this.removeHighlight(highlightElement);
    }

    this.highlightElementId = selectedElement;
    this.addHighlight(selectedElement);
  }

  addHighlight(element) {
    document.getElementById(`${element}`).classList.add('--active');
  }

  removeHighlight(element) {
    document.getElementById(`${element}`)?.classList?.remove('--active');
  }

  setShapeData(term?: string, initialPage = false): any {
    switch (term) {
      case 'favorite':
        this.listFavoriteShapes(initialPage)
        break;
      case 'formas':
        this.listFixedShapes();
        break;
      default:
        this.listShapes(term, initialPage);
        break;
    }
  }

  listShapes(term: string, initialPage = true) {
    this.checkShapeLoad();
    if (initialPage) {
      this.shapesDataProvider = {
        list: [],
        page: 1,
        pageSize: 50,
        hasNextPage: false,
      };
    } else {
      this.shapesDataProvider.page++;
    }

    this.enableLoading();

    this.shapeSubscription = from(
      this._galleryIconsService.listShapes(
        term,
        this.shapesDataProvider.page,
        this.shapesDataProvider.pageSize,
      ),
    ).pipe(takeUntil(this._destroy$)).subscribe(
      (result: any) => {
        const list: any[] = [];
        const meta = result.metadata;
        this.shapesDataProvider.hasNextPage = meta.hasNextPage;

        result.data.forEach(item => {
          list.push({
            type: 'shape',
            value: item.preview,
            width: 50,
            height: 50,
            svg: item.preview,
            id: item.id,
          });
        });

        this.shapesDataProvider.list =
          this.shapesDataProvider.list.concat(list);
        // Segment Tracking
        this._assetsAnalyticsService.trackProjectAssetSearch({
          element_type: 'shape',
          search_query: term,
          success: true,
          total_displayed: this.shapesDataProvider.list.length,
          total_found: meta.total || 0,
        });
        this.shapeSubscription.unsubscribe();
        this.disableLoading();
      },
      error => {
        // Segment Tracking
        this._assetsAnalyticsService.trackProjectAssetSearch({
          element_type: 'shape',
          search_query: term,
          success: false,
          last_error: error,
        });
        this.shapeSubscription.unsubscribe();
        this.disableLoading();
      },
    );
  }

  listFixedShapes() {
    this.checkShapeLoad();
    this.shapesDataProvider = { list: [], hasNextPage: false, pageSize: 50, };

    this.enableLoading();
    // FIXME - isso deveria estar no DB ou ser cadastrado no adminONde v
    const dataList = [
      { type: 'svg', value: shapePathEnum.arrow },
      { type: 'svg', value: shapePathEnum.arrowTwoWay },
      { type: 'svg', value: shapePathEnum.chat },
      { type: 'svg', value: shapePathEnum.dialog },
      { type: 'svg', value: shapePathEnum.ellipse },
      { type: 'svg', value: shapePathEnum.hexagon },
      { type: 'svg', value: shapePathEnum.octagon },
      { type: 'svg', value: shapePathEnum.pentagon },
      { type: 'svg', value: shapePathEnum.rectRounded },
      { type: 'svg', value: shapePathEnum.rectVertically },
      { type: 'svg', value: shapePathEnum.rect },
      { type: 'svg', value: shapePathEnum.star5 },
      { type: 'svg', value: shapePathEnum.star8 },
      { type: 'svg', value: shapePathEnum.triangleVertically },
      { type: 'svg', value: shapePathEnum.triangle },
      { type: 'svg', value: shapePathEnum.custom1 },
      { type: 'svg', value: shapePathEnum.custom2 },
      { type: 'svg', value: shapePathEnum.custom3 },
      { type: 'svg', value: shapePathEnum.custom4 },
      { type: 'svg', value: shapePathEnum.custom5 },
      { type: 'svg', value: shapePathEnum.custom6 },
      { type: 'svg', value: shapePathEnum.custom7 },
      { type: 'svg', value: shapePathEnum.custom8 },
      { type: 'svg', value: shapePathEnum.custom9 },
      { type: 'svg', value: shapePathEnum.custom10 },
      { type: 'svg', value: shapePathEnum.custom11 },
      { type: 'svg', value: shapePathEnum.custom12 },
      { type: 'svg', value: shapePathEnum.custom13 },
      { type: 'svg', value: shapePathEnum.custom14 },
      { type: 'svg', value: shapePathEnum.custom15 },
      { type: 'svg', value: shapePathEnum.custom16 },
      { type: 'svg', value: shapePathEnum.custom17 },
      { type: 'svg', value: shapePathEnum.custom18 },
    ];

    this.shapesDataProvider.list = dataList;

    // Segment Tracking
    this._assetsAnalyticsService.trackProjectAssetSearch({
      element_type: 'shape',
      search_query: 'formas',
      total_displayed: this.shapesDataProvider.list.length,
      total_found: dataList.length,
      success: true,
    });
    this.disableLoading();
  }

  listFavoriteShapes(initialPage = false) {
    this.checkShapeLoad();
    let query: Promise<any>;

    if (initialPage) {
      this.shapesDataProvider = {
        list: [],
        page: 0,
        pageSize: 50,
        hasNextPage: false,
      };
      query = this._galleryFavoritesService.listFavorite(
        elementTypeEnum.shape,
        this.shapesDataProvider.pageSize,
      );
    } else {
      this.shapesDataProvider.page++;
      query = this._galleryFavoritesService.findMoreFavorites(
        elementTypeEnum.shape,
        this.shapesDataProvider.pageSize,
        this.shapesDataProvider.nextRange,
      );
    }

    this.enableLoading();

    this.shapeSubscription = from(query).pipe(takeUntil(this._destroy$)).subscribe(
      data => {
        const list: any[] = [];

        if (data.length > this.shapesDataProvider.pageSize) {
          this.shapesDataProvider.hasNextPage = true;
          data.pop();
          this.shapesDataProvider.nextRange = data[data.length - 1].id;
        } else {
          this.shapesDataProvider.hasNextPage = false;
        }

        const convert: x2js = new x2js();

        data.forEach(item => {
          try {
            const json: any = convert.xml2js(item.xml);
            const path: string = this.getSvgValidPath(json);

            list.push({
              type: 'svg',
              value: path,
              width: 50,
              height: 50,
              svg: path,
            });
          } catch (error) {
            console.error(`Ocorreu um erro no item: ${item.id}`);
          }
        });

        this.shapesDataProvider.list =
          this.shapesDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'favorite',
          element_type: 'shape',
          success: true,
          total_displayed: this.shapesDataProvider.list.length,
          total_found: data.length,
        });
        this.disableLoading(initialPage);
      },
      error => {
        this.disableLoading();

      this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'favorite',
          element_type: 'shape',
          success: false,
          last_error: error,
        });
      },
    );
  }

  private getSvgValidPath(svgJson) {
    if (svgJson.svg.path) {
      return svgJson.svg.path._d;
    }

    if (svgJson.svg.g && svgJson.svg.g.path) {
      return svgJson.svg.g.path._d;
    }

    if (svgJson.svg.g.g && svgJson.svg.g.g.path) {
      return svgJson.svg.g.g.path._d;
    }
  }

  async onInputFileChange(event: Event, isGif = false) {
    const file: File = (event.target as any).files[0];
    if (!file) {
      return;
    }
    try {
      const isPageChange = this.pageSelected && !this.elementSelected && !this.isFromToolbar();
      this._modalService.closeAll();
       if (isPageChange) {
         this._elementChangeFacadeService.changePageBackgroundUrlByFile(this.pageSelected, file);
       } else if (!isGif){
         this._elementsFactoryFacadeService.createAndAddImageByFile(file);
       } else {
         this._elementsFactoryFacadeService.createAndAddGifByFile(file);
       }
    } catch (error) {
      this._alertService.warn(error.message);
    }
  }


  private getDefaultCategoryGallery(
    galleryType: 'image' | 'gif' | 'shape' | 'emoji' | 'sticker',
  ) {
    this.defaultGalleryCategories =
      this._localeService.translations['default_gallery_categories'];
    const texts = {
      image: this.defaultGalleryCategories.image_gallery,
      gif: this._isB2C
        ? this.defaultGalleryCategories.gif_gallery
        : this.defaultGalleryCategories.gif_gallery_b2b,
      shape: this.defaultGalleryCategories.shape_gallery,
      sticker: this._isB2C
        ? this.defaultGalleryCategories.sticker_gallery
        : this.defaultGalleryCategories.sticker_gallery_b2b,
      emoji: this.defaultGalleryCategories.emoji_gallery,
    };
    return texts[galleryType].value;
  }

  private isFromToolbar() {
    const toolbarButton = document.getElementById(this.highlightElementId);
    return toolbarButton && toolbarButton.classList.contains('--active');
  }

  isCategory(category: string): boolean {
    return category !== 'my-gallery' && category !== 'favorite';
  }

  loadMoreImages(category: string) {
    switch (category) {
      case 'my-gallery':
        this.listImageByUser(false);
        break;
      case 'favorite':
        this.listFavoriteImages(false);
        break;
      default:
        this.getImages();
        break;
    }
  }

  getHigherValuesInArray(
    arr: string[],
    quantity: number = 3,
    search: string = '',
  ): any[] {
    const obj = arr
      .map((name: string) => {
        return { count: 1, name };
      })
      .reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        return a;
      }, {});

    return this.processHigherValues(obj, quantity, search);
  }

  processHigherValues(obj: any, quantity: number, search: string): any[] {
    const array = Object.keys(obj)
      .map((key: string) => {
        return { name: key, value: obj[key] };
      })
      .filter(item => item.name !== search);

    const numbers: number[] = [];
    const items: any[] = [];

    array.map(item => numbers.push(item.value));

    while (quantity > 0) {
      const currentMax: number = Math.max.apply(null, numbers);

      array.map((item: any, index: number) => {
        const hasExist: boolean =
          items.findIndex(i => i.name === item.name) !== -1;
        const canAddItem: boolean =
          item.value === currentMax && !hasExist && quantity > 0;

        if (canAddItem) {
          items.push(item);
          numbers.splice(index, 1);
          array.splice(index, 1);
          quantity--;
        }
      });

      if (!array.length || items.length === array.length) {
        break;
      }
    }

    return items;
  }

  setImageData(term?: string): void {
    switch (term) {
      case 'my-gallery':
        this.listImageByUser(true)
        break;
      case 'favorite':
        this.listFavoriteImages();
        break;
      default:
        this.getImages(true);
        break;
    }
  }

  resetSearch(searchInput) {
    searchInput.value = '';
    searchInput.setFocus();
  }

  getImages(initialPage = false, randomPage = false): void {
    this.checkImageLoad();
    if (initialPage) {
      this.imagesDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    }

    if (randomPage) {
      return;
    }

    this.imagesDataProvider.page++;
    this.enableLoading();
    const queryTerm =
      this.searchImage.value === ''
        ? this.dataCategoryImage
        : this.searchImage.value;

    this.imageSubscription = this._galleryImageService
      .listImages(
        queryTerm,
        this.imagesDataProvider.page,
        30,
        this._currentLang,
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          const results: any[] = data['results'] || [];
          this.searchImage.value = queryTerm;
          this.imagesDataProvider.hasNextPage = this.imagesDataProvider.page < data['pages'];

          const list = results.map(item => {
            return {
              type: elementTypeEnum.image,
              provider: mediaProviderEnum.external,
              tags: item.tags,
              id: item.id,
              value: item.photo_urls.regular,
              thumb: item.photo_urls.medium,
              low: item.photo_urls.small,
              medium: item.photo_urls.regular,
              high: item.photo_urls.full,
              raw: item.photo_urls.raw,
              label: 'Image by',
              author: item.photographer.name,
              link: item.photographer.links.html,
              width: item.width,
              height: item.height,
              filesize: item.imageSize,
              unsplash_photo_url_download_tracking:
                item.unsplash_photo_url_download_tracking || '',
            };
          });

          this.modalTags = this.getHigherValuesInArray(
            data['tags'],
            5,
            this.searchImage.value,
          );
          this.imagesDataProvider.list =
            this.imagesDataProvider.list.concat(list);

          this._assetsAnalyticsService.trackProjectAssetSearch({
            search_query: queryTerm,
            element_type: 'image',
            success: true,
            total_displayed: this.imagesDataProvider.list.length,
            total_found: (data as any).total,
          });
          this.disableLoading(initialPage);
        },
        error => {
          this._assetsAnalyticsService.trackProjectAssetSearch({
            search_query: queryTerm,
            element_type: 'image',
            success: false,
            last_error: error,
          });
          this.disableLoading(initialPage);
        },
      );
  }

  // TO-DO: Function to replace fixed url to cloudfront
  replaceUrlS3(item: any): any {
    const urlFrom = 'https://s3.us-west-2.amazonaws.com/assets-v3.trakto.io';
    const urlTo = 'https://assets.storage.trakto.io';

    item.thumb = item.thumb.replace(urlFrom, urlTo);
    item.link = item.link.replace(urlFrom, urlTo);
    item.value = item.value.replace(urlFrom, urlTo);

    return item;
  }

  listImageByUser(initialPage = false) {
    this.checkImageLoad();
    let query: Promise<any>;

    if (initialPage) {
      this.imagesDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
      query = this._galleryImageService.listUserImages(
        this.imagesDataProvider.pageSize,
      );
    } else {
      this.imagesDataProvider.page++;
      query = this._galleryImageService.listUserImages(
        this.imagesDataProvider.pageSize,
        this.imagesDataProvider.nextRange,
      );
    }

    this.enableLoading();

    this.imageSubscription = from(query).pipe(takeUntil(this._destroy$)).subscribe(
      (response: IAssetResponse) => {
        const list: any[] = [];

        this.imagesDataProvider.hasNextPage = response.hasNextPage;
        this.imagesDataProvider.nextRange = response.nextCursor;

        response.data.forEach(item => {

          const { provider, url, uuid, created_at } = item;

          list.push(this.replaceUrlS3({
            created_at,
            uuid,
            provider,
            type: elementTypeEnum.image,
            thumb: url.medium ? url.medium.url : url.raw.url,
            value: url.raw.url,
            label: '',
            author: '',
            link: url.raw.url,
            width: 500,
            height: 500,
          }));
        });

        this.imagesDataProvider.list =
          this.imagesDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'my-gallery',
          element_type: 'image',
          success: true,
          total_displayed: this.imagesDataProvider.list.length,
          total_found: response.data.length,
        });

        this.disableLoading(initialPage);
      },
      error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'my-gallery',
          element_type: 'image',
          success: false,
          last_error: error,
        });

        this.disableLoading();
      },
    );
  }

  listFavoriteImages(initialPage = true) {
    this.checkImageLoad();
    let query: Promise<any>;

    if (initialPage) {
      this.imagesDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
      query = this._galleryFavoritesService.listFavorite(
        elementTypeEnum.image,
        this.imagesDataProvider.pageSize,
      );
    } else {
      this.imagesDataProvider.page++;
      query = this._galleryFavoritesService.findMoreFavorites(
        elementTypeEnum.image,
        this.imagesDataProvider.pageSize,
        this.imagesDataProvider.nextRange,
      );
    }
    this.enableLoading();

    this.imageSubscription = from(query).pipe(takeUntil(this._destroy$)).subscribe(
      data => {
        const list: any[] = [];

        if (data.length > this.imagesDataProvider.pageSize) {
          this.imagesDataProvider.hasNextPage = true;
          data.pop();
          this.imagesDataProvider.nextRange = data[data.length - 1].id;
        } else {
          this.imagesDataProvider.hasNextPage = false;
        }

        data.forEach(doc => {
          list.push({
            type: elementTypeEnum.image,
            thumb: doc.file.medium.url,
            value: doc.file.raw.url,
            label: '',
            author: '',
            link: doc.file.raw.url,
            width: 500,
            height: 500,
          });
        });

        this.imagesDataProvider.list =
          this.imagesDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'my-gallery',
          element_type: 'image',
          success: true,
          total_displayed: this.imagesDataProvider.list.length,
          total_found: data.length,
        });

        this.disableLoading(initialPage);
      },
      error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'favorites',
          element_type: 'image',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      },
    );
  }

  setGifData(term?: string, initialPage = false): void {
    switch (term) {
      case 'highlights':
        this._listHighlightGifs(initialPage, term);
        break;
      case 'favorite':
        this._listHighlightGifs(initialPage, term);
        break;
      case 'my-gallery':
        this.listGifsByLoggedUser(initialPage)
        break;
      default:
        this._listGifs(term, initialPage);
        break;
    }
  }

  setStickerData(term?: string, initialPage = false): void {
    switch (term) {
      case 'highlights':
        this._listHighlightStickers(initialPage, term);
        break;
      case 'favorite':
        this._listHighlightStickers(initialPage, term);
        break;
      case 'my-gallery':
        this.listStickByLoggedUser(initialPage)
        break;
      default:
        this._listStickers(term, initialPage);
        break;
    }
  }

  private _listHighlightGifs(initialPage, term?: string) {
    if (initialPage) {
      this.gifsDataProvider = {
        list: [],
        nextRange: null,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    }

    this.enableLoading();

    this._galleryGifsService
      .listHighlightGifs(
        Number(this.gifsDataProvider.nextRange),
        this._defaultLimit,
      )
      .then((data: any) => {
        const list = data.results.map((item: any) => ({
          type: elementTypeEnum.image,
          provider: mediaProviderEnum.external,

          thumb: item.thumb,
          value: item.url,
          label: '',
          author: '',
          link: item.url,
          width: item.width,
          height: item.height,
        }));

        this.gifsDataProvider.hasNextPage = data.next !== '0';
        this.gifsDataProvider.nextRange = data.next;
        this.gifsDataProvider.list = this.gifsDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'gif',
          success: true,
          total_displayed: this.gifsDataProvider.list.length,
          total_found: data.resutls.length,
        });
        this.disableLoading(initialPage);
      })
      .catch((error: any) => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'gif',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      });
  }

  private _listHighlightStickers(initialPage, term?: string) {
    if (initialPage) {
      this.stickersDataProvider = {
        list: [],
        nextRange: null,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    }

    this.enableLoading();

    this._galleryGifsService
      .listHighlightStickers(
        Number(this.stickersDataProvider.nextRange),
        this._defaultLimit,
      )
      .then((data: any) => {
        const list = data.results.map((item: any) => ({
          type: elementTypeEnum.image,
          provider: mediaProviderEnum.external,

          thumb: item.thumb,
          value: item.url,
          label: '',
          author: '',
          link: item.url,
          width: item.width,
          height: item.height,
        }));

        this.stickersDataProvider.hasNextPage = data.next !== '0';
        this.stickersDataProvider.nextRange = data.next;
        this.stickersDataProvider.list =
          this.stickersDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'sticker',
          success: true,
          total_displayed: this.stickersDataProvider.list.length,
          total_found: data.resutls.length,
        });
        this.disableLoading(initialPage);
      })
      .catch((error: any) => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'sticker',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      });
  }

  private _listGifs(term, initialPage = false) {
    if (initialPage) {
      this.gifsDataProvider = {
        list: [],
        nextRange: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    }

    this.enableLoading();

    this._galleryGifsService
      .listGifs(term, this.gifsDataProvider.nextRange, this._defaultLimit)
      .then(data => {
        const list = data.results.map((item: any) => ({
          type: elementTypeEnum.image,
          provider: mediaProviderEnum.external,

          thumb: item.thumb,
          value: item.url,

          label: '',
          author: '',
          link: item.url,
          width: item.width,
          height: item.height,
        }));

        this.gifsDataProvider.hasNextPage = data.nextPagination !== '0';
        this.gifsDataProvider.nextRange = data.nextPagination;
        this.gifsDataProvider.list = this.gifsDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'gif',
          success: true,
          total_displayed: this.gifsDataProvider.list.length,
          total_found: data.length,
        });

        this.disableLoading(initialPage);
      })
      .catch(error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'gif',
          success: false,
          last_error: error,
        });
        this.disableLoading(initialPage);
      });
  }

  private _listStickers(term, initialPage = false) {
    if (initialPage) {
      this.stickersDataProvider = {
        list: [],
        nextRange: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    }

    this.enableLoading();

    this._galleryGifsService
      .listStickers(
        term,
        this.stickersDataProvider.nextRange,
        this._defaultLimit,
      )
      .then(data => {
        const list = data.results.map((item: any) => ({
          type: elementTypeEnum.image,
          provider: mediaProviderEnum.external,

          thumb: item.thumb,
          value: item.url,

          label: '',
          author: '',
          link: item.url,
          width: item.width,
          height: item.height,
        }));

        this.stickersDataProvider.hasNextPage = data.nextPagination !== '0';
        this.stickersDataProvider.nextRange = data.nextPagination;
        this.stickersDataProvider.list =
          this.stickersDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'sticker',
          success: true,
          total_displayed: this.stickersDataProvider.list.length,
          total_found: data.length,
        });

        this.disableLoading(initialPage);
      })
      .catch(error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'sticker',
          success: false,
          last_error: error,
        });
        this.disableLoading(initialPage);
      });
  }

  listGifsByLoggedUser(initialPage = false) {
    if (initialPage) {
      this.gifsDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    } else {
      this.gifsDataProvider.page++;
    }
    this._listGifsByLoggedUser(this.gifsDataProvider, 'gif').then();
  }

  listStickByLoggedUser(initialPage = false) {
    if (initialPage) {
      this.stickersDataProvider = {
        list: [],
        page: 0,
        pageSize: this._defaultLimit,
        hasNextPage: false,
      };
    } else {
      this.stickersDataProvider.page++;
    }
    this._listGifsByLoggedUser(this.stickersDataProvider, 'sticker').then();
  }

  async _listGifsByLoggedUser(provider: IAssetProvider, type: 'gif' | 'sticker') {
    try {
      const start = !provider.nextRange;
      this.enableLoading();
      const response = await this._galleryGifsService.listUserGifs(
        provider.pageSize,
        provider.nextRange
      );

      provider.hasNextPage = response.hasNextPage;
      provider.nextRange = response.nextCursor;

      const list = response.data.map(doc => ({
        type: elementTypeEnum.image,
        thumb: doc.url.url || doc.url.raw.url,
        value: doc.url.url || doc.url.raw.url,
        label: null,
        author: null,
        width: doc.url.raw ? doc.url.raw.dimensions.width : 500,
        height: doc.url.raw ? doc.url.raw.dimensions.height : 500,
      }));

      provider.list =
        provider.list.concat(list);

      await this._assetsAnalyticsService.trackProjectAssetSearch({
        search_query: 'my-gallery',
        element_type: type,
        success: true,
        total_displayed: provider.list.length,
        total_found: response.data.length,
      });
      this.disableLoading(start);
    } catch (error) {
      await this._assetsAnalyticsService.trackProjectAssetSearch({
        search_query: 'my-gallery',
        element_type: type,
        success: false,
        last_error: error,
      });
      this.disableLoading();
    }
  }

  setEmojiData(term?: string, initialPage = true): void {
    switch (term) {
      case 'highlights':
        this.listHighlightEmoji(initialPage);
        break;
      case 'favorite':
        this.listFavoriteEmojis(initialPage);
        break;
      default:
        this.listEmojis(term, initialPage);
        break;
    }
  }

  listHighlightEmoji(initialPage = false): void {
    if (initialPage) {
      this.emojisDataProvider = {
        list: [],
        page: 1,
        pageSize: 50,
        hasNextPage: false,
      };
    } else {
      this.emojisDataProvider.page++;
    }

    this.enableLoading();

    this._galleryIconsService
      .listEmojisHighlights(
        this.emojisDataProvider.page,
        this.emojisDataProvider.pageSize,
      )
      .then((result: any) => {
        const list: any[] = [];
        const meta = result.metadata;

        this.emojisDataProvider.hasNextPage =
          meta.count === this.emojisDataProvider.pageSize &&
          meta.total > meta.count * meta.page;

        result.data.map(icon => {
          list.push({
            type: 'emoji',
            value: icon.svg,
            width: 50,
            height: 50,
            svg: icon.svg,
          });
        });

        this.emojisDataProvider.list =
          this.emojisDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'highlights',
          element_type: 'emoji',
          success: true,
          total_displayed: this.emojisDataProvider.list.length,
          total_found: result.metadata.total,
        });
        this.disableLoading();
      })
      .catch(error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'highlights',
          element_type: 'emoji',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      });
  }

  listEmojis(term, initialPage = false) {
    this.checkEmojiLoad();
    if (initialPage) {
      this.emojisDataProvider = {
        list: [],
        page: 1,
        pageSize: 50,
        hasNextPage: false,
      };
    } else {
      this.emojisDataProvider.page++;
    }

    this.enableLoading();

    this.emojiSubscription = from(
      this._galleryIconsService.listEmojis(
        term,
        this.emojisDataProvider.page,
        this.emojisDataProvider.pageSize,
      ),
    ).pipe(takeUntil(this._destroy$)).subscribe(
      (result: any) => {
        const list: any[] = [];
        const meta = result.metadata;

        this.emojisDataProvider.hasNextPage = meta.hasNextPage;

        result.data.map(icon => {
          list.push({
            type: 'emoji',
            value: icon.preview,
            width: 50,
            height: 50,
            svg: icon.preview,
            id: icon.id,
          });
        });

        this.emojisDataProvider.list =
          this.emojisDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'emoji',
          success: true,
          total_displayed: this.emojisDataProvider.list.length,
          total_found: result.metadata.total,
        });
        this.disableLoading();
      },
      error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: term,
          element_type: 'emoji',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      },
    );
  }

  listFavoriteEmojis(initialPage = true) {
    this.checkEmojiLoad();
    let query: Promise<any>;

    if (initialPage) {
      this.emojisDataProvider = {
        list: [],
        page: 0,
        pageSize: 50,
        hasNextPage: false,
      };
      query = this._galleryFavoritesService.listFavorite(
        elementTypeEnum.emoji,
        this.emojisDataProvider.pageSize,
      );
    } else {
      this.emojisDataProvider.page++;
      query = this._galleryFavoritesService.findMoreFavorites(
        elementTypeEnum.emoji,
        this.emojisDataProvider.pageSize,
        this.emojisDataProvider.nextRange,
      );
    }

    this.enableLoading();

    this.emojiSubscription = from(query).pipe(takeUntil(this._destroy$)).subscribe(
      (data: any[]) => {
        const list: any[] = [];

        if (data.length > this.emojisDataProvider.pageSize) {
          this.emojisDataProvider.hasNextPage = true;
          data.pop();
          this.emojisDataProvider.nextRange = data[data.length - 1].id;
        } else {
          this.emojisDataProvider.hasNextPage = false;
        }

        data.forEach(doc => {
          list.push({
            type: 'emoji',
            value: doc.file.medium.url,
            width: 50,
            height: 50,
            svg: doc.file.raw.url,
          });
        });

        this.emojisDataProvider.list =
          this.emojisDataProvider.list.concat(list);

        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'favorite',
          element_type: 'emoji',
          success: true,
          total_displayed: this.emojisDataProvider.list.length,
          total_found: data.length,
        });
        this.disableLoading(initialPage);
      },
      error => {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: 'favorite',
          element_type: 'emoji',
          success: false,
          last_error: error,
        });
        this.disableLoading();
      },
    );
  }

  cleanInput(input: any): void {
    input.value = '';
  }

  setDataImageCategory(category: string): void {
    const defaultCategory = this.getDefaultCategoryGallery('image');
    const currentCategory = !category ? defaultCategory : category;
    this.searchImage.value = currentCategory;
    this.dataCategoryImage = currentCategory;
    this.setImageData(currentCategory);
  }

  setDataGifCategory(category: string): void {
    const defaultCategory = this.getDefaultCategoryGallery('gif');
    const currentCategory = !category ? defaultCategory : category;
    this.searchGif.value = currentCategory;
    this.dataCategoryGif = currentCategory;
    this.setGifData(currentCategory, true);
  }

  setDataStickerCategory(category: string): void {
    // TODO: category está vindo como 'my-gallery' quando selecionado a opção 'Meus stickers' no modal.
    // Deve-se verificar uma forma de trazer apenas os stickers, pois está vindo os gifs no lugar.
    const defaultCategory = this.getDefaultCategoryGallery('sticker');
    const currentCategory = !category ? defaultCategory : category;
    this.searchSticker.value = currentCategory;
    this.dataCategorySticker = currentCategory;
    this.setStickerData(currentCategory, true);
  }

  setDataShapeCategory(category: string): void {
    const defaultCategory = this.getDefaultCategoryGallery('shape');
    const currentCategory = !category ? defaultCategory : category;
    this.searchShape.value = currentCategory;
    this.dataCategoryShape = currentCategory;
    this.setShapeData(currentCategory, true);
  }

  setDataEmojiCategory(category: string): void {
    const defaultCategory = this.getDefaultCategoryGallery('emoji');
    const currentCategory = !category ? defaultCategory : category;
    this.searchEmoji.value = currentCategory;
    this.dataCategoryEmoji = currentCategory;
    this.setEmojiData(currentCategory, true);
  }

  setConfigColumnGap(columns: number, gap: number): void {
    this.modalColumns = columns;
    this.modalGap = gap;
  }

  cleanData(data: any[]): any {
    if (data.length > 0) {
      data.splice(0, data.length);
    }

    return data;
  }

  private enableLoading(data?: any[]) {
    if (data) {
      this.cleanData(data);
    }

    this.contentOverflowHidden(true);
    this.loadingContent = true;
  }

  private disableLoading(resetScroll?: boolean) {
    this.loadingContent = false;
    this.contentOverflowHidden(false);

    if (resetScroll) {
      this.resetScrollPosition();
    }
  }

  private contentOverflowHidden(hidden: boolean) {
    const contentDiv = document.querySelector(
      `#${this.modalElementId} .trakto-modal__content`,
    ) as HTMLElement;

    if (contentDiv) {
      contentDiv.style.overflowY = hidden ? 'hidden' : 'auto';
    }

    // FIXME
    // Ao iniciar o modal de imagem pela primeira vez, após o loadingContent for setado False (line:1897)
    // no HTML o ngIf do ngx-skeleton-loader não é atualizado. (line: 299)
    // o click é para forçar a atualização da doom.
    // Refatorar fluxo de loading.
    contentDiv?.click();
  }

  private resetScrollPosition() {
    const contentDiv = document.querySelector(
      `#${this.modalElementId} .trakto-modal__content`,
    ) as HTMLElement;
    if (contentDiv) {
      contentDiv.scrollTop = 0;
    }
  }

  public async handlePaste(event: ClipboardEvent): Promise<any> {
    const clipboard: any = event.clipboardData.getData('Text');

    try {
      const element: IElementModel = JSON.parse(clipboard);
      const sourceDimension = element.pageDimension;
      this._newElementService.scaleElements(
        element,
        sourceDimension,
        new DimensionModel(this.pageSelected.width, this.pageSelected.height),
      );
      delete element.pageDimension;

      if (element.type === elementTypeEnum.group) {
        const elements: IElementModel[] = element['elements'];

        elements.forEach((el: IElementModel) => {
          el.inputType = inputTypeEnum.pasted;
          el.pageId = null;
        });
      }

      element.inputType = inputTypeEnum.pasted;
      element.pageId = null;
      let clone;

      if (this._newElementService.isGroup(element)) {
        clone = await this._newElementService.newGroupElement(
          await this._documentManagerService.cloneElementsAndAdd(element['elements']),
        );
      } else {
        clone = await this._documentManagerService.cloneElementAndAdd(element);
      }
      this._copyService.copy(JSON.stringify(clone));
    } catch (error) {
      switch (this.getTypeMedia(clipboard)) {
        case elementTypeEnum.image:
          await this.createAndAddImageElement(clipboard);
          break;
        case elementTypeEnum.gif:
          await this.createAndAddGifElement(clipboard);
          break;
        case elementTypeEnum.youtube:
          await this.addYoutubeElement(clipboard);
          break;
        default:
          this.createAndAddTextElement(clipboard);
          break;
      }
    }
  }

  async createAndAddTextElement(text?: string): Promise<void> {
    this._modalService.closeAll();
    await this._elementsFactoryFacadeService.createAndAddText(text);
  }

  public getTypeMedia(paste: string): string {
    const isYoutube: any[] = paste.match(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
    );
    const isImage: any[] = paste.match(
      /(https?:\/\/.*\.(?:png|jpg|jpeg|svg))/gi,
    );
    const isGif: any[] = paste.match(/(https?:\/\/.*\.gif)/gi);

    if (isYoutube && isYoutube.length > 0) {
      return elementTypeEnum.youtube;
    } else if (isImage && isImage.length > 0) {
      return elementTypeEnum.image;
    } else if (isGif && isGif.length > 0) {
      return elementTypeEnum.gif;
    }

    return elementTypeEnum.title;
  }

  async createAndAddImageElement(href: string): Promise<ImageElementModel> {
    return this._elementsFactoryFacadeService.createAndAddImageByUrl(href);
  }

  createAndAddGifElement(url: string): Promise<ImageElementModel> {
    return this._elementsFactoryFacadeService.createAndAddGifByUrl(url);
  }

  addYoutubeElement(videoUrl?: string): void {
    this._elementsFactoryFacadeService.createAndAddVideo(videoUrl);
  }

  verifyPermissionAndCallToAction(
    featureName: string,
    featureEmiter: () => any,
  ) {
    this._planConfigService.getPermission(
      featureName,
      data => {
        if (data) {
          featureEmiter();
        } else {
        }
      },
      err => console.warn('err', err),
    );
  }

  public removeImage(item: ItemRenderer) {
    this._translateService.get('toolbar.buttons').pipe(takeUntil(this._destroy$)).subscribe(value => {
      this._alertService.warn(value['remove_message'], {}, (next: boolean) => {
        if (next) {
          this._galleryImageService
            .removeUserImage(item.uuid)
            .then(() => {
              if (
                this._storageService.isAllowedCDN(
                  item.link,
                  EnumCDNJustOnly.CLOUDINARY,
                )
              ) {
                this._uploadStorageService.removeImage({
                  public_id: item.uuid,
                  user: this.user,
                });
              }

              const index: number = this.imagesDataProvider.list.findIndex(
                element => element.uuid === item.uuid,
              );
              if (index !== -1) {
                this.imagesDataProvider.list.splice(index, 1);
              }
              this._alertService.success(value['remove_success']);
            })
            .catch(err => console.error(err));
        }
      });
    });
  }

  private addKeyListeners(e: KeyboardEvent) {
    if (this._hotkeyService.active) {
      if (this._hotkeyService.SHIFT_KEY(e)) {
        if (e.which === KeyEnum.t) {
          this.createAndAddTextElement(this.paragraphText);
        }
      }

      if (this._hotkeyService.KEY(e)) {
        if (e.which === KeyEnum.t) {
          this.createAndAddTextElement();
        }
        if (e.which === KeyEnum.i) {
          this.createAndAddImageElement(`${window.location.origin}/${environment.static.image}`);
        }
        if (e.which === KeyEnum.v && !this.isEmbeddedInstance) {
          this.addYoutubeElement();
        }

        if (e.which === KeyEnum.r) {
          this._elementsFactoryFacadeService.createAndAddShapeByPath(
            shapePathEnum.rect,
          );
        }
        if (e.which === KeyEnum.e) {
          this._elementsFactoryFacadeService.createAndAddShapeByPath(
            shapePathEnum.ellipse,
          );
        }
        if (e.which === KeyEnum.p) {
          this._elementsFactoryFacadeService.createAndAddShapeByPath(
            shapePathEnum.pentagon,
          );
        }
        if (e.which === KeyEnum.s) {
          this._elementsFactoryFacadeService.createAndAddShapeByPath(
            shapePathEnum.star5,
          );
        }
      }
    }
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  private checkImageLoad() {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
  }

  private checkShapeLoad() {
    if (this.shapeSubscription) {
      this.shapeSubscription.unsubscribe();
    }
  }

  private checkEmojiLoad() {
    if (this.emojiSubscription) {
      this.emojiSubscription.unsubscribe();
    }
  }

  public async openElementsPanel(type: string) {
    let store = {};

    if (type === 'emoji')
      store = {
        type: SearchTypeEnum.FOLDER,
        query: null,
        elementType: ISearchElementType.SVG,
        name: 'emoji',
      };

    if (type === 'shape') {
      const pack = await this.getShapesPack();

      store = {
        pack,
        type: SearchTypeEnum.THEME,
      };
    }

    this._documentManagerService.selectElement(null);

    this._elementsGalleryService.setElementsPanelStore({ ...store });

    this._panelStackService.popAll(true);
    this._panelStackService.stack(PanelElementsComponent);
    this._scrollListenerService.toggleScrollUpVisibility(false);
  }

  async getShapesPack(): Promise<IPack> {
    const shapesPackId = 5;
    const packs = await this._elementsGalleryService.getPacks();
    return packs.find(pack => pack.id === shapesPackId);
  }
}
