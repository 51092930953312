import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IButtonElementModel } from '@trakto/models';

@Component({
  selector: 'trakto-panel-button',
  templateUrl: './panel-button.component.html',
})
export class PanelButtonComponent {
  @Input() elementSelected: IButtonElementModel;

  @Output() onChangeProperty: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  public changeProperty(property: string): void {
    this.onChangeProperty.emit(property);
  }
}
