import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ElementsGalleryService } from '@app/shared/elements-gallery/elements-gallery.service';
import { IElementNewAPI, } from '@shared/elements/element.entity';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import {
  elementTypeEnum,
  IElementModel,
  ImageElementModel,
  IShapeElementModel,
  ISvgElementModel,
} from '@trakto/models';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { NotificationService } from '@app/shared/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormatsResultsComponent } from './formats-results/formats-results.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { Subject, Subscription } from 'rxjs';
import { ScrollListenerService } from '@app/editor/services/scroll-listener.service';
import { PanelStackService } from '@services/panel-stack.service';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { ElementNewApiService } from '@services/element-new-api.service';
import { ElementDatasource } from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import { GalleryElementType } from '@app/editor/enums/editor-elements/gallery-type.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-elements',
  templateUrl: './panel-elements.component.html',
  styleUrls: ['./panel-elements.component.scss'],
})
export class PanelElementsComponent implements OnInit, OnDestroy {
  public isSearching = false;
  public currentSearch: ISearchEvent;
  public currentLang = 'pt-BR';
  public refreshSelection = false;
  public isScrollUpVisible = false;
  public isPanelOpen: boolean = false;
  private _destroy$ = new Subject<void>();
  @ViewChild(FormatsResultsComponent, { static: true })
  private _formatsResultsComponent: FormatsResultsComponent;

  @ViewChild(SearchbarComponent, { static: true })
  private _searchbarComponent: SearchbarComponent;

  constructor(
    private _elementsGalleryService: ElementsGalleryService,
    private _documentManagerService: DocumentManagerService,
    private _alertService: NotificationService,
    private _translateService: TranslateService,
    private _elementsFactoryService: ElementFactoryFacadeService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    private _scrollListenerService: ScrollListenerService,
    private _panelStackService: PanelStackService,
    private _elementService: ElementNewApiService,
  ) { }

  async ngOnInit() {
    this.startObservableOfData();
    this.currentLang = this._translateService.currentLang;

    this._scrollListenerService.onVisibilityToggle.pipe(takeUntil(this._destroy$)).subscribe(
      isScrollUpVisible => {
        this.isScrollUpVisible = isScrollUpVisible;
      },
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Busca no ElementsGalleryService o painel e
   * filtro que estavam selecionados anteriormente,
   * e refaz a busca em questão
   */
  startObservableOfData() {
    this._elementsGalleryService.storeObservable.pipe(takeUntil(this._destroy$)).subscribe(store => this.currentSearch = store);
  }

  /**
   * Armazena condicional que diz se input de
   * pesquisa está em foco. Ele é responsável por
   * exibir o overlay abaixo do input, e por
   * estilizar o input e suas sugestões.
   *
   * @param val boolean - Se input de pesquisa está em foco
   */
  isSearchbarFocused(val: boolean): void {
    this.isSearching = val;
  }

  /**
   * Exibe os painéis desejados, trata os tipos
   * de pesquisa e chama a pesquisa de elementos
   *
   * @param val: string - Texto da tag a ser pesquisada
   * @param type: enumSearchTypes - Local onde a pesquisa está sendo executada
   */
  searchTag($event?: ISearchEvent): void {
    if (!$event && this.currentSearch.backComponentInfo) {
      this._panelStackService.stack(
        this.currentSearch.backComponentInfo.component,
        this.currentSearch.backComponentInfo.params,
      );
    }
    this.clearSearches();
    this._elementsGalleryService.setElementsPanelStore($event);
    this.currentSearch = $event;
  }

  clearSearches() {
    this._formatsResultsComponent?.editSearch();
    this._searchbarComponent?.editSearch();
  }

  /**
   * Centraliza todas as inserções de elementos
   *
   * @param asset any - Elemento a ser inserido no documento
   */
  async assetSelected(asset: IElementNewAPI): Promise<boolean> {
    // FIXME: Realizar a tipagem correta do elemento
    const selectedElement = await this._documentManagerService.getSelectedElement() as any;

    if (!selectedElement) {
      await this._addNewElement(asset);
      return;
    }

    if (selectedElement?.type === elementTypeEnum.image && selectedElement.source !== ElementDatasource.FLATICON) {

      if (this._elementService.getGalleryTypeOfElement(asset.contentType) !== GalleryElementType.SHAPE) {
        this._handleMaskError();
        return false;
      }

      await this._elementChangeFacadeService.changeImageSubmaskByUrl(
        selectedElement as ImageElementModel,
        asset.elementUrl,
      );
      return true;
    }
    await this._elementChangeFacadeService.changeElementAsset(selectedElement, asset);
    return true;
  }

  private async _addNewElement(element: IElementNewAPI) {
    const type = this._elementService.getGalleryTypeOfElement(element.contentType);
    switch (type) {
      case GalleryElementType.EMOJI:
        await this._elementsFactoryService.createAndAddEmojiByUrl(element.elementUrl);
        break;
      case GalleryElementType.SHAPE:
        await this._elementsFactoryService.createAndAddShapeByUrl(
          element.elementUrl,
        );
        break;
      case GalleryElementType.IMAGE:
        await this._elementsFactoryService.createAndAddImageByUrl(element.thumbUrls.high, element.source);
        break;
    }
  }

  private _handleMaskError() {
    this._alertService.error(
      this._translateService.translations[this.currentLang].sidebar_elements
        .mask_error,
    );
  }
}
