<div
  class="trakto-panel"
  [ngStyle]="{ width: isPanelExpanded ? '720px' : '360px' }"
>
  <div id="panels"></div>
  <div class="shadow"></div>
  <div class="text-overlay"></div>
</div>
<div class="expand-panel" (click)="expandPanel()" *ngIf="showExpandIcon">
  <i
    class="trakto-icon-arrow-left"
    [ngStyle]="{ transform: isPanelExpanded ? 'rotate(180deg)' : 'none' }"
  ></i>
  <span>
    {{
      (isPanelExpanded
        ? 'sidebar_elements.contract'
        : 'sidebar_elements.expand'
      ) | translate
    }}
  </span>
</div>
