import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer, Subject } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '@auth/shared/auth.service';
import { environment } from '@env/environment';
import { CustomTokenService } from './custom-token.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Se não houver usuário logado e existir o cookie trakto-session, é realizada uma tentativa de login com o cookie
 */
@Injectable()
export class CookieGuard implements CanActivate, OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private customTokenService: CustomTokenService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const canActivateFunc = () => {
        observer.next(true);
        observer.complete();
      };

      const { access_token } = route.params;
      if (access_token && access_token !== '') {
        this.authService.signinByAccessToken(access_token).then(
          (success: any) => {
            if (!success) this._redirect();
            canActivateFunc();
            return;
          }).catch(() => {
            this._redirect()
          });
      } else {
        this.authService.hasValidUserSession().then(isValidSession => {
          if (isValidSession) {
            canActivateFunc();
            return;
          }
          const cookieSessionExists = this.cookieService.check(
            environment.cookieSessionKey,
          );

          if (this.customTokenService.getCustomToken()) {
            this.authService.login(this.customTokenService.getCustomToken()).pipe(
              takeUntil(this._destroy$),
            ).subscribe(
              (result: any) => {
                if (result) {
                  observer.next(true);
                  observer.complete();
                } else {
                  /**
                  * Se não possuir cookie de sessão e ela for inválida deve
                  * ser redirecionado para o login com a dashboard
                  */
                  if (
                    !isValidSession &&
                    !cookieSessionExists &&
                    !route.routeConfig.data?.embedded
                  ) {
                    this._redirect();
                  }
                }
              }
            );
          } else {
            /**
             * Se não possuir cookie de sessão e ela for inválida deve
             * ser redirecionado para o login com a dashboard
             */
            if (
              !isValidSession &&
              !cookieSessionExists &&
              !route.routeConfig.data?.embedded
            ) {
              this._redirect();
            }
          }
          /**
           * Se o cookie de sessão existir, porém a sessão for inválida é
           * realizada a tentativa de revalidação
           */
          if (!isValidSession && cookieSessionExists) {
            const traktoSession = this.cookieService.get(
              environment.cookieSessionKey,
            );
            this.authService
              .loginByCookie(traktoSession)
              .subscribe(canActivateFunc, () => this._redirect());
          } else {
            /***
             * Se A sessão for válida e o cookie de sessão existir, o
             * acesso a rota é liberada
             */
            canActivateFunc();
          }
        });
      }
    });
  }

  private _redirect() {
    this.cookieService.delete(environment.cookieSessionKey);
    let url = window.location.href;
    window.open(environment.externalLoginPage + `?redirect=${url}`, '_self');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
