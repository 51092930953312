import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'trakto-yes-or-cancel',
  templateUrl: './yes-or-cancel.component.html',
  styleUrls: ['./yes-or-cancel.component.scss'],
})
export class YesOrCancelComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
