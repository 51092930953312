import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ItemRenderer } from './../../../renderers/item-renderer.model';

import { AuthService } from '@app/auth/shared/auth.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';
import { TextEditorService } from '@services/text-editor.service';
import { NgColorModel } from '@shared/color/classes/color.model';
import { ITextElementModel, PageModel } from '@trakto/models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThemeService } from '@services/theme.service';

interface IColorTexts {
  gallery: { default: { title: string; subtitle: string }; title: string };
  title: string;
  my_colors: string;
}
interface IColorPalete {
  title: string;
  colors: string[];
}

interface IProviderColor {
  titlePalette: string;
  titles: { [key: string]: string };
  palettes: IColorPalete[];
}

@Component({
  selector: 'trakto-color-panel',
  templateUrl: './color-panel.component.html',
  styleUrls: ['./color-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColorPanelComponent implements OnInit, OnDestroy {
  private _isBasicColorEnabled = true;
  private _translatedTexts: IColorTexts;
  public currentLang: string;
  public colorsProvider: IProviderColor[];
  public color: NgColorModel;
  private _destroy$ = new Subject<void>();

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Output()
  colorChange: EventEmitter<NgColorModel> = new EventEmitter(true);

  private _changeListener: Subscription;

  constructor(
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _textEditorService: TextEditorService,
    private _hotkeyService: HotkeyService,
    private _themeService: ThemeService,
  ) {
    this.currentLang = this._translateService.currentLang;
    this._translateService.get('property_panel').pipe(takeUntil(this._destroy$)).subscribe(text => {
      this._translatedTexts = text.color;
      this._getColorsProvider();
    });

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this._translatedTexts = event.translations.property_panel.color;
      this.currentLang = event.lang;
      this._getColorsProvider();
    });
  }

  ngOnInit(): void {
    this._updateColor();
    this._changeListener = this._textEditorService.selectionChange.pipe(takeUntil(this._destroy$)).subscribe(
      () => {
        const selectedColor = this._textEditorService.getSelectedColor(
          this.elementSelected,
        );
        if (selectedColor) {
          this._updateColor();
        }
      },
    );
    this._hotkeyService.disableHotkeys();
  }

  ngOnDestroy(): void {
    this._changeListener.unsubscribe();
    this._destroy$.next();
  }

  colorpickerChanged(color: NgColorModel) {
    this.color = color;
    this.colorChange.emit(this.color);
  }

  selectColor(color: ItemRenderer) {
    if (color.type === 'color') {
      this.color = new NgColorModel(null, color.value as string);
    } else if (color.type === 'gradient') {
      this.color = new NgColorModel();
      this.color.gradient = color.value as string;
    }
    this.colorChange.emit(this.color);
  }

  private _getColorsProvider() {
    const brandColorsPalette = this._themeService.getBrandColorsPalette();
    const basicPalette = [
      {
        titlePalette: this._translatedTexts.gallery.default.title,
        palettes: [
          {
            title: this._translatedTexts.gallery.default.subtitle,
            colors: [
              '#23B450',
              '#CADB0F',
              '#F5E90C',
              '#F7B500',
              '#FF8C00',
              '#F2380A',
              '#ED020E',
              '#A30A38',
              '#751A8F',
              '#2D1D8A',
              '#1E4FA8',
              '#00AEFF',
              '#000000',
              '#6E6E6E',
              '#AAAAAA',
              '#E6E6E6',
              '#FFFFFF',
              null,
            ],
          },
        ],
      },
    ];

    this._isBasicColorEnabled = this._themeService.getDefaultColorsEnable();
    this.colorsProvider = this._isBasicColorEnabled
      ? [].concat(basicPalette, brandColorsPalette)
      : [].concat(brandColorsPalette);
  }

  private _updateColor() {
    const hexadecimalColor =
      this.elementSelected?.color ||
      this.elementSelected?.backgroundColor ||
      this.pageSelected?.backgroundColor;

    this.color = new NgColorModel(null, hexadecimalColor);
  }
}
