<div
  class="trakto-colorpicker-input"
  *ngIf="!color?.gradient"
  [ngClass]="{ 'trakto-colorpicker-input--null': !color?.rgba }"
  [style.backgroundColor]="color?.rgba"
>
  <input
    [formControl]="hexaLabelColorControl"
    type="text"
    class="trakto-colorpicker-input__hex"
    [style.color]="hexaLabelColor"
    [style.borderColor]="hexaLabelColor"
    name="color"
    minlength="7"
    maxlength="7"
    *ngIf="color?.hexadecimal"
    [ngModel]="color?.hexadecimal"
  />
</div>
<div
  *ngIf="color?.gradient"
  class="trakto-colorpicker-input"
  [style.background]="color?.gradientSafe"
></div>
