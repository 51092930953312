import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthFirebaseService {

  private _localStorageKey = 'trakto:accessToken';
  private _accessToken: string;

  set accessToken(value: string) {
    this._accessToken = value;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private _httpClient: HttpClient,
  ) {
    this._accessToken = localStorage.getItem(this._localStorageKey);
  }

  createCustomTokenFromAccessToken(
    accessToken: string,
  ): Observable<any> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return this._httpClient
      .get<string>(`${environment.api.baseUrl}auth/firebase-token`, { headers })
      .pipe(map((response: any) => response.data));
  }

  async getAccessToken(): Promise<string> {
    if (!this._accessToken) {
      const token = await this._refreshAccessTokenFromIdToken();
      if (!token) throw new Error('Error getting access token');
      return token;
    }
    return this._accessToken;
  }

  clearAccessToken() {
    this._accessToken = null;
    localStorage.setItem(this._localStorageKey, null);
  }


  login(customToken: string): Observable<boolean> {
    return from(this.afAuth.auth.signInWithCustomToken(customToken)).pipe(
      map(() => true),
    );
  }

  logout(): Observable<boolean> {
    return from(this.afAuth.auth.signOut()).pipe(map(() => true));
  }

  public getCurrentTokenId(): Observable<string> {
    return this.afAuth.idToken;
  }

  private async _refreshAccessTokenFromIdToken(): Promise<any> {
    try {
      const idToken = await this.getCurrentTokenId().pipe(first()).toPromise();
      if (idToken) {
        localStorage.removeItem(this._localStorageKey);
        const response: any = await this._httpClient
        .post(`${environment.api.baseUrl}auth/signin-token`, {
          token: idToken,
        })
        .toPromise();
        this._accessToken = response?.access_token;
        localStorage.setItem(this._localStorageKey, this._accessToken);
        return response?.access_token;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  setAccessNewAccessTokenOnLocalStorage(accessToken: string) {
    this._accessToken = accessToken;
    return localStorage.setItem(this._localStorageKey, accessToken);
  }
}
