<div class="grid-formats" [ngStyle]="{ left: isVisible ? '0px' : '100%' }">
  <div class="grid-formats__back">
    <img src="assets/images/panel/back.svg" alt="" (click)="closeGrid()" />
    <span>{{
      'property_panel.page.dimensions_page.formats_dropdown.label' | translate
    }}</span>
  </div>

  <div class="grid-formats__grid">
    <div class="format" *ngFor="let parentFormat of formats">
      <div class="format__header">
        <trakto-render-svg-icon
          [svg]="parentFormat.icon"
          [width]="16"
        ></trakto-render-svg-icon>
        <span>{{
          parentFormat.titles ? parentFormat.titles[lang] : parentFormat.title
        }}</span>
      </div>

      <div class="format__grid">
        <ng-container *ngFor="let childFormat of parentFormat.formats">
          <trakto-format-card
            [label]="
              childFormat.titles ? childFormat.titles[lang] : childFormat.title
            "
            [icon]="childFormat.file"
            [width]="68"
            [selected]="pageSelected.format?.id === childFormat.id"
            (click)="selectFormat(childFormat)"
          ></trakto-format-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
