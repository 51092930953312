import { Injectable } from '@angular/core';
import { ElementModelService } from '@services/element-model.service';
import { PageIMGConversionService } from '@shared/svg-viewer/shared/page-img-conversion.service';
import { PageSVGConversionService } from '@shared/svg-viewer/shared/page-svg-conversion.service';
import { MediaService } from '@services/media.service';
import { ConversionParams, PagePDFConverter } from '@trakto/svg-converter';
import { IDocumentModel, PageModel } from '@trakto/models';
import { concat, Observable } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';
import { PageUtilModel } from '@trakto/core-editor';

declare var download: any;

@Injectable()
export class PagePDFConversionService extends PagePDFConverter {
  constructor(
    private _pageIMGConverter: PageIMGConversionService,
    private _pageSVGConverter: PageSVGConversionService,
    private _mediaService: MediaService,
    private _newElementService: ElementModelService,
  ) {
    super();
  }

  formatPrintRange(range: number[], document: IDocumentModel, selectedPage: PageModel): number[] {
    if (range && range.length === 0) {
      const pageIndex = document.body.findIndex(
        page => page.id === selectedPage.id,
      );
      return [pageIndex + 1, pageIndex + 1];
    }
    return (range || [1, document.body.length]).map(value => +value);
  }

  public downloadPDF(
    documentModel: IDocumentModel,
    params: ConversionParams,
    fileName: string,
    range?: number[],
  ): Observable<boolean> {
    return this.makePDF(documentModel, params, fileName, range).pipe(
      tap((pdf) => {
        const stream = pdf.pipe(globalThis.blobStream());
        stream.on('finish', () => {
          const blob = stream.toBlob('application/pdf');
          download(blob, fileName, 'application/pdf');
        });
        pdf.end();
      }),
      map(() => true),
    );
  }

  public doMakePDF(params: ConversionParams, pages: PageModel[], fileName: string): Observable<any> {
    const pdf = new globalThis.PDFDocument({
      compress: false,
      size: params.getPageSize(pages[0]),
      margin: 0,
    });
    return concat(
      ...pages.map(page => this._pageIMGConverter.toBase64(page, params)),
    ).pipe(
      toArray(),
      map(result => {
        pages.forEach((page, i) => {
          const div = document.createElement('div');
          const clonePage = PageUtilModel.copy(page);
          const paramsCopy = ConversionParams.copy(params);
          paramsCopy.oldTextRenderMode = true;
          div.innerHTML = this._pageSVGConverter.toSVG(clonePage, paramsCopy);
          const dimension = params.getPageSize(pages[i]);
          globalThis.SVGtoPDF(pdf, div.firstChild, 0, 0, {
            useCSS: true,
            size: dimension,
          });
          pdf.image(result[i], 0, 0, {
            width: dimension[0],
            height: dimension[1],
          });
          if (i !== result.length - 1) {
            pdf.addPage({ size: params.getPageSize(pages[i + 1]), margin: 0 });
          }
        });
        return pdf;
      }),
    );
  }


}
