import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ElementFactoryFacadeService } from '@app/editor/services/element-factory-facade.service';

@Component({
  selector: 'trakto-button-uploader',
  templateUrl: './button-uploader.component.html',
  styleUrls: ['./button-uploader.component.scss']
})
export class ButtonUploaderComponent implements OnInit {

  @Input() img: string = '';
  @Input() label: string = '';
  @Input() isGif: boolean = false;
  isLoading: boolean = false;

  constructor(private _elementsFactoryFacadeService: ElementFactoryFacadeService) { }

  ngOnInit(): void { }

  uploadFile($event) {
    this.isLoading = true;
    if (this.isGif) {
      this._elementsFactoryFacadeService.createAndAddGifByFile($event.target.files[0]).finally(() => {
        this.isLoading = false;
      });
    } else {
      this._elementsFactoryFacadeService.createAndAddImageByFile($event.target.files[0]).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
