import { IElementModel } from '@trakto/models';
import { Drawable } from './drawable.model';

export class ClipEditionInfo {
  constructor(
    public source: { element: IElementModel; position: number, drawable: Drawable },
    public metaShape: Drawable,
    public metaImage: Drawable,
  ) {}
}
