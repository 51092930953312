import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';

import { PopoverWindowOptions } from './popover-window-options.provider';

@Component({
  selector: 'trakto-popover',
  styleUrls: ['./popover.component.scss'],
  template: `
    <ng-template #defaultTemplate let-options="options">
      <div
        [ngClass]="[
          'trakto-popover',
          options.placement,
          'popover-' + options.placement,
          'bs-popover-' + options.placement,
          options.popoverClass
        ]"
      >
        <div class="trakto-popover-arrow arrow"></div>
        <div
          class="trakto-popover-title popover-header"
          [innerHTML]="options.popoverTitle"
        ></div>
        <div class="trakto-popover-content popover-body">
          <p [innerHTML]="options.popoverMessage"></p>
          <div
            class="trakto-confirm-btns"
            [class.confirm-btns-reversed]="options.reverseButtonOrder"
          >
            <div class="cancel-btn-container" *ngIf="!options.hideCancelButton">
              <button
                type="button"
                [traktoFocus]="options.focusButton === 'cancel'"
                [class]="'button button__' + options.cancelButtonType"
                (click)="options.onCancel({ clickEvent: $event })"
                [innerHtml]="options.cancelText"
              ></button>
            </div>
            <div
              class="confirm-btn-container"
              *ngIf="!options.hideConfirmButton"
            >
              <button
                type="button"
                [traktoFocus]="options.focusButton === 'confirm'"
                [class]="'button  button__' + options.confirmButtonType"
                (click)="options.onConfirm({ clickEvent: $event })"
                [innerHtml]="options.confirmText"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="options.customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{ options: options }"
    >
    </ng-template>
  `,
})
export class PopoverWindowComponent implements AfterViewInit {
  constructor(public options: PopoverWindowOptions) {}

  ngAfterViewInit(): void {
    this.options.onAfterViewInit();
  }
}
