<section
  class="mobile-presentation__swipe"
  showDownload
  [class.actived]="actived"
>
  <div
    *ngIf="!downloadMultiPages || downloadOnePage"
    (click)="downloadType('png')"
    class="mobile-presentation__button"
  >
    <i class="trakto-icon-tkt-png" [style.color]="'#0079FF'"></i>
    <p>{{ translatePNG }}</p>
  </div>

  <div (click)="downloadType('pdf')" class="mobile-presentation__button">
    <i class="trakto-icon-tkt-pdf" [style.color]="'#7251E7'"></i>
    <p>{{ translatePDF }}</p>
  </div>

  <div
    *ngIf="!downloadMultiPages || downloadOnePage"
    (click)="downloadType('mp4')"
    class="mobile-presentation__button"
  >
    <i class="trakto-icon-tkt-movie" [style.color]="'#FE6E46'"></i>
    <p>{{ translateMP4 }}</p>
  </div>
</section>
