<div class="options">
  <div class="options__page-name" (click)="emitNewSelectedPage()">
    <span>
      {{ translatedTexts.artboard.label_page }}
      <span *ngIf="selectedPage?.title">
        - {{ doTruncateStr(selectedPage?.title, 40) }}
      </span>
    </span>
  </div>
  <div
    class="options__page-actions"
    [ngClass]="{ 'options__page-actions--no-label': !showLabels }"
    *ngIf="!isDropboxInstance && allowNewPages"
  >
    <div class="options__page-action" #action (click)="addPage()">
      <img
        src="assets/images/page-options/page_add.svg"
        alt="Adicionar página"
      />
      <span>{{ 'page-options.add' | translate }}</span>
    </div>
    <div class="options__page-divider"></div>
    <div class="options__page-action" #action (click)="clonePage()">
      <img src="assets/images/page-options/page_duplicate.svg" alt="Duplicar" />
      <span>{{ 'page-options.duplicate' | translate }}</span>
    </div>
    <div class="options__page-divider" *ngIf="docLength > 1"></div>
    <div
      class="options__page-action"
      #action
      *ngIf="docLength > 1"
      (click)="askForDeletePage()"
    >
      <img
        src="assets/images/page-options/page_delete.svg"
        alt="Excluir página"
      />
      <span>{{ 'page-options.delete' | translate }}</span>
    </div>
    <trakto-popover
      *ngIf="popoverDeleteInfo.selectedPage === selectedPage"
      (confirm)="deletePage()"
      (cancel)="emitNewSelectedPage()"
    ></trakto-popover>
  </div>
</div>
