<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-email.svg"
    label="Link para e-mail"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper">
    <trakto-input
      [value]="sender"
      label="Remetente"
      placeholder="E-mail"
      (onChange)="changeSenderValue($event)"
      icon="email"
      iconColor="#fe9474"
    ></trakto-input>

    <trakto-input
      [value]="subject"
      label="Assunto do e-mail"
      placeholder="Assunto"
      (onChange)="changeSubjectValue($event)"
      icon="email-sender"
      iconColor="#fe9474"
    ></trakto-input>

    <trakto-input
      [value]="message"
      label="Sua mensagem"
      placeholder="Digite sua mensagem aqui"
      (onChange)="changeMessageValue($event)"
      icon="email-message"
      iconColor="#fe9474"
    ></trakto-input>

    <trakto-button-loader
      [isDisabled]="!isFormValid"
      label="Adicionar link"
      (action)="setEmailLink()"
    ></trakto-button-loader>
  </div>
</div>
