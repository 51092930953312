export interface StepperState {
  currentStep: number;
  templateType: 'default' | 'magic' | null;
  templateSubType?: 'upload' | 'trakto' | null;
  totalSteps: number;
}

export const defaultTemplateSteps = 3;
export const magicTemplateSteps = 5;

export const initialState: StepperState = {
  currentStep: 1,
  templateType: null,
  templateSubType: null,
  totalSteps: 1,
};