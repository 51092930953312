<canvas
  [ngStyle]="{ height: '100%' }"
  #canvasPalette
  class="trakto-colorpicker-palette"
  width="220"
  height="88"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp($event)"
>
</canvas>
