import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PageSVGConversionService } from '@shared/svg-viewer/shared/page-svg-conversion.service';
import { ConversionParams } from '@trakto/svg-converter';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoModalComponent } from '@editor/components/video-modal/video-modal.component';
import { FontsService } from '@services/fonts.service';
import { ElementModelService } from '@services/element-model.service';
import { TextService } from '@services/text.service';
import { YoutubeService } from '@services/youtube/youtube.service';
import {
  IDocumentModel,
  IYoutubeElementModel,
  PageModel,
} from '@trakto/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-svg-viewer',
  templateUrl: './svg-viewer.component.html',
  styleUrls: ['./svg-viewer.component.scss'],
})
export class SvgViewerComponent implements OnChanges, OnDestroy {
  @Input()
  public page: PageModel;

  @Input()
  public selectedPage: boolean;

  @Input()
  private document: IDocumentModel;

  @Input()
  public zoomRatio = 1;

  @Input()
  private ref: any;

  @Input()
  public fullViewMode = false;

  @Input()
  public loadFonts = false;

  @Input()
  public openYoutube = false;

  public svg: SafeHtml;

  private listenerByEl = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private _elRef: ElementRef,
    private _sanitizer: DomSanitizer,
    private _fonts: FontsService,
    private _textService: TextService,
    private _newElementService: ElementModelService,
    private _pageConversionService: PageSVGConversionService,
    private _youtubeService: YoutubeService,
    private _dialog: MatDialog,
  ) {}

  ngOnDestroy() {
    this.deleteYoutubeElementsListeners();
    this._destroy$.next();
  }

  ngOnChanges() {
    if (!this.page) {
      return;
    }
    this.initSvg();
    if (this.loadFonts) {
      this._fonts.loadByPage(this.page).pipe(takeUntil(this._destroy$)).subscribe();
    }
  }

  private initSvg() {
    if (this.fullViewMode) {
      this._pageConversionService.hasWaterMask = false;
      this.svg = this._sanitizer.bypassSecurityTrustHtml(
        this._pageConversionService.toSVG(
          this.page,
          ConversionParams.makeConversionParams(false, this.zoomRatio),
        ),
      );
    } else {
      this.svg = undefined;
    }
    if (this.openYoutube) {
      setTimeout(() => {
        this.deleteYoutubeElementsListeners();
        this.initYoutubeElementsListeners();
      }, 0);
    }
  }

  private initYoutubeElementsListeners() {
    this._elRef.nativeElement.querySelectorAll('[youtube]').forEach(el => {
      this.listenerByEl[el] = this.makeYoutubeClickListener(el);
      el.addEventListener('click', this.listenerByEl[el]);
    });
  }

  private deleteYoutubeElementsListeners() {
    this._elRef.nativeElement.querySelectorAll('[youtube]').forEach(el => {
      if (!this.listenerByEl[el]) {
        return;
      }
      el.removeEventListener('click', this.listenerByEl[el]);
    });
  }

  private makeYoutubeClickListener(element) {
    const elementId = element.getAttribute('youtube');
    return event => {
      const elements = this._newElementService.getAllElements(
        this.page.elements,
        false,
        false,
      );
      const youtubeElement: IYoutubeElementModel = elements.find(
        elementModel => elementModel.id === elementId,
      ) as IYoutubeElementModel;
      this.openOnPreviewDoc(youtubeElement);
      event.preventDefault();
    };
  }

  openOnPreviewDoc(element: IYoutubeElementModel) {
    return this._dialog.open(VideoModalComponent, {
      width: '640px',
      height: '400px',
      data: { url: this._youtubeService.getYoutubeUrl(element) },
    } as MatDialogConfig);
  }
}
