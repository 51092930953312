import { Injectable } from '@angular/core';
import { IFormats } from '@trakto/models';
import { TraktoApiService } from "@services/trakto-api.service";

interface IMenuPageFormatModel {
  app_reference: { id: string };
  formats: IFormats[];
}

@Injectable({
  providedIn: 'root',
})
export class PageFormatRepository {

  private _formats: IFormats[];

  constructor(
    private _traktoApiService: TraktoApiService,
  ) {}

  public async findByLoggedUser(productIds: Array<string>, includesGlobals?: boolean): Promise<IFormats[]> {
    const params = {
      products: productIds
    };
    const query = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    const menuFormats = await this._traktoApiService.executeGet<IMenuPageFormatModel[]>(`menu-of-formats/by_products?${query}`);
    const formats = (menuFormats || []).reduce(
      (prev, curr) => prev.concat(curr.formats.filter(f => !prev.find(p => p.id === f.id))),
      []
    );
    return formats;
  }

  public async findByAppId(appId: string, productsIds: Array<string>): Promise<IFormats[]> {
    const includingGlobal = true;
    const formats = await this.findByLoggedUser(productsIds, includingGlobal);
    return formats.filter(theme => theme.app_reference?.id === appId);
  }

}
