<div #element id="panel-image-settings" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-settings"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.page.page_background_settings.title' | translate }}
      </h2>
    </div>

    <div class="panel-scroller-close" (click)="close()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-text__container">
    <div class="panel-background">
      <div class="panel-image-settings__content panel-space">
        <div [style.width.px]="98">
          <trakto-button-preview
            *ngIf="isGif"
            height="106px"
            [isGif]="true"
            [disabled]="!pageSelected"
            [url]="pageSelected?.backgroundImage"
            [icon]="'trakto-icon-image-simple'"
            [content]="'toolbar.gif.title' | translate"
            (onClick)="openModal('trakto-modal-gif')"
          >
          </trakto-button-preview>

          <trakto-button-preview
            *ngIf="!isGif"
            height="106px"
            [disabled]="!pageSelected"
            [url]="pageSelected?.backgroundImage"
            [icon]="'trakto-icon-image-simple'"
            [content]="'toolbar.image.title' | translate"
            (onClick)="openModal('trakto-modal-image')"
          >
          </trakto-button-preview>
        </div>

        <div>
          <span>{{ 'property_panel.image.position' | translate }}</span>

          <trakto-dropdown-select
            *ngIf="pageSelected"
            [disabled]="pageSelected && !pageSelected.backgroundImage"
            [config]="dropdownBackgroundImagePosition"
            [(ngModel)]="pageSelected.backgroundImagePosition"
            (onChange)="changeProperty('backgroundImagePosition')"
          >
          </trakto-dropdown-select>

          <trakto-button
            button--fill
            button--white
            [disabled]="
              (pageSelected && !pageSelected.backgroundImage) || isLoadingBG
            "
            (onClick)="removeBackgroundOnPage()"
          >
            <i class="trakto-icon-empty"></i>
            {{ isGif ? isTranslate.remove_gif : isTranslate.remove_image }}
          </trakto-button>
        </div>
      </div>

      <div>
        <h2
          class="panel__title"
          [class.panel__title--disabled]="
            !pageSelected || (pageSelected && !pageSelected.backgroundImage)
          "
        >
          <i class="trakto-icon-icon-effect"></i>
          {{ 'property_panel.image.effects.title' | translate }}
        </h2>

        <div class="panel-filter">
          <div class="panel-filter__item">
            <trakto-button
              button--xxlarge
              button--outline
              [disabled]="
                (pageSelected && !pageSelected.backgroundImage) || isGif
              "
              (onClick)="setFilter('empty')"
            >
              <i class="trakto-icon-filter-none"></i>
              <span>{{
                'property_panel.image.effects.types.no_filter' | translate
              }}</span>
            </trakto-button>
          </div>

          <div class="panel-filter__item">
            <trakto-button
              button--xxlarge
              button--outline
              [disabled]="
                (pageSelected && !pageSelected.backgroundImage) || isGif
              "
              (onClick)="openPanelStack('duotone')"
            >
              <i class="trakto-icon-filter-duotone"></i>
              <span>{{
                'property_panel.image.effects.types.duotone' | translate
              }}</span>
            </trakto-button>
          </div>

          <div class="panel-filter__item">
            <trakto-button
              button--xxlarge
              button--outline
              [disabled]="
                (pageSelected && !pageSelected.backgroundImage) || isGif
              "
              (onClick)="openPanelStack('filter')"
            >
              <i class="trakto-icon-filters"></i>
              <span>{{
                'property_panel.image.effects.types.filters' | translate
              }}</span>
            </trakto-button>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-simple" *ngIf="pageSelected">
      <h2
        class="panel__title"
        [class.panel__title--disabled]="
          pageSelected && !pageSelected.backgroundImage
        "
      >
        <i class="trakto-icon-opacity"></i>
        {{ 'property_panel.image.opacity' | translate }}
      </h2>

      <div class="box-simple panel-space">
        <mat-slider
          #backgroundPageImageOpacitySlider
          required
          min="0"
          max="1"
          step="0.001"
          name="backgroundImageOpacity"
          [disabled]="pageSelected && !pageSelected.backgroundImage"
          placeholder="backgroundImageOpacity"
          [ngModel]="pageSelected.backgroundImageOpacity"
          (ngModelChange)="changeBackgroundImageOpacity($event)"
        >
        </mat-slider>
        <span
          >{{
            backgroundPageImageOpacitySlider.value * 100 | number: '1.0-0'
          }}%</span
        >
      </div>
    </div>
  </div>
</div>

<trakto-modal-loader></trakto-modal-loader>
