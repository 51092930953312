<div
  class="search-results"
  [ngStyle]="{ right: isActive ? '0px' : '-800px' }"
  [ngClass]="{ 'search-results--expanded': isPanelExpanded }"
  infiniteScroll
  (scrolled)="scrollToNextPage()"
  [scrollWindow]="false"
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="100"
  [infiniteScrollContainer]="'.search-results'"
  [fromRoot]="true"
  traktoScrollListener
>
  <div class="search-results__header">
    <div class="search-results__back">
      <img
        src="assets/images/formats-elements/goback.png"
        alt="back"
        (click)="hideSearch()"
      />
    </div>
    <div class="search-results__title">
      {{ 'sidebar_elements.search_results' | translate }} "{{
        (currentSearch?.tags || [])[0]?.name
      }}"
    </div>
  </div>

  <div class="tags-list">
    <div
      class="tags-list__tag"
      *ngFor="let tag of universeTags; index as i"
      (click)="filterTags(tag)"
      [ngClass]="{ 'tags-list__tag--active': tag.id === currentTag?.id }"
    >
      <div
        class="tags-list__background"
        [ngStyle]="{ backgroundColor: getColor(i) }"
      ></div>
      <span [ngStyle]="{ color: getColor(i) }">{{
        'sidebar_elements.' + tag?.name | translate
      }}</span>
    </div>
  </div>

  <trakto-elements-list
    *ngIf="isActive"
    [currentSearch]="currentSearch"
    [isPanelExpanded]="isPanelExpanded"
    (requestElementInsertion)="assetSelected.emit($event)"
  >
  </trakto-elements-list>
</div>
