import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { FiltersService } from '@app/editor-v3/services/filters.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IElementModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-properties-gif-mobile',
  templateUrl: './panel-properties-gif-mobile.component.html',
  styleUrls: ['./panel-properties-gif-mobile.component.scss']
})
export class PanelPropertiesGifMobileComponent implements OnInit {
  selectedElement: IElementModel;
  private elementSubscription: Subscription;

  constructor(
    private _documentStateManagerService: DocumentStateManagerService,
    private _panelService: PanelService,
    private _elementsService: ElementsService,
    private _filterService: FiltersService,
  ) {}

  ngOnInit(): void {
    this.elementSubscription =
      this._documentStateManagerService.element$.subscribe(element => {
        this.selectedElement = element;
      });
  }

  ngOnDestroy() {
    if (this.elementSubscription) {
      this.elementSubscription.unsubscribe();
    }
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  orderElement() {
    this._panelService.openPanel('order');
  }

  alignElement() {
    this._panelService.openPanel('align');
  }

  setElementOpacity() {
    this._panelService.openPanel('opacity');
  }

  deleteElement() {
    this._elementsService.deleteElement();
  }

  blockElement() {
    this._elementsService.blockElement();
  }

  duplicateElement() {
    this._elementsService.duplicateElement();
  }

  cropImage() {
    this._elementsService.cropImage();
  }

  setPageBackground() {
    this._elementsService.setPageBackground();
  }

  changeImage() {
    this._panelService.openPanel('gif');
  }

  removeImageBackground() {
    console.log('remove');
  }

  openMaskPanel() {
    this._panelService.openPanel('mask');
  }

  openFiltersPanel() {
    this._panelService.openPanel('filters');
  }

  openLinksPanel() {
    this._panelService.openPanel('links');
  }

  applyBlur() {
    this._filterService.toggleFilter('gaussianblur');
  }
}
