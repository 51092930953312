import { Injectable, OnDestroy } from '@angular/core';

import { TraktoUser } from '@app/auth/shared/auth.model';
import { IPlanConfigModel } from './plan-config.model';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseService } from '@app/shared/firebase-client/firebase.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@app/editor-v3/services/user.service';

export const EXPORT_PNG_LOW = 'export_png_low';
export const EXPORT_PNG_MEDIUM = 'export_png_medium';
export const EXPORT_PNG_HIGH = 'export_png_high';
export const EXPORT_PDF_LOW = 'export_pdf_low';
export const EXPORT_PDF_MEDIUM = 'export_pdf_medium';
export const EXPORT_PDF_HIGH = 'export_pdf_high';

@Injectable()
export class PlanConfigService implements OnDestroy{
  public user: TraktoUser;
  public limits: any;

  public EXPORT_PNG_LOW = 'export_png_low';
  public EXPORT_PNG_MEDIUM = 'export_png_medium';
  public EXPORT_PNG_HIGH = 'export_png_high';
  public EXPORT_PDF_LOW = 'export_pdf_low';
  public EXPORT_PDF_MEDIUM = 'export_pdf_medium';
  public EXPORT_PDF_HIGH = 'export_pdf_high';
  public EXPORT_VIDEO = 'export_video';
  public EXPORT_GIF = 'export_gif';
  public REMOVE_BG_COUNT = 'remove_bg_count';

  private _removeBgLimit: { remaining: number; total: number } = { remaining: 0, total: 0 };
  private _premiumPlan: any;
  private _destroy$ = new Subject<void>();

  constructor(
    private _userService: UserService,
    private firebase: FirebaseService,
    private _http: HttpClient,
  ) {
    this.limits = this.getStaticPlanConfig();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
  
  private getStaticPlanConfig(): IPlanConfigModel {
    return {
      remove_bg: true,
      remove_bg_count: 1,
      use_template_premium: true,
      use_template_premium_count: 1,
      create_document: true,
      create_document_count: 1,
      document_publish_online: true,
      image_stock: true,
      image_upload: true,
      shape_stock: true,
      icon_stock: true,
      gif_stock: true,
      export_pdf_count: 1,
      export_png_count: 1,
      export_with_watermark: true,
      export_pdf_low: true,
      export_pdf_medium: true,
      export_pdf_high: true,
      export_png_low: true,
      export_png_medium: true,
      export_png_high: true,
      export_video: true,
      export_gif: true,
    };
  }

  /**
   * isPremiumUser
   */
  public async isPremiumUser() {
    const plan = await this.getUserPlanId();
    const premiumPlans = await this.getPlanPremium();
    return premiumPlans.includes(plan);
  }

  public loadUserPlanConfig(): Promise<IPlanConfigModel> {
    return new Promise<IPlanConfigModel>((resolve, reject) => {
      const user = this._userService.user;
      if (!this._userService.user) {
        resolve(this.limits);
        return;
      }

      if (user.limits) {
        this.limits = { ...this.limits, ...user.limits };
        this.updateUserLimits(this.limits)
          .then(() => resolve(this.limits))
          .catch(err => reject(err));
      } else if (user['current_profile'].subscription) {
        this.limits = { ...this.limits, ...user['current_profile'].subscription['rules'] };
        this.updateUserLimits(this.limits)
          .then(() => resolve(this.limits))
          .catch(err => reject(err));
      } else {
        this.limits = this.getStaticPlanConfig();
        this.updateUserLimits(this.limits)
          .then(result => resolve(this.limits))
          .catch(err => reject(err));
      }
    });
  }

  public getPermissionPromise(functionality: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPermission(
        functionality,
        (data: any) => {
          resolve(data);
        },
        (data: any) => {
          reject(data);
        }
      );
    });
  }

  public getPermission(
    functionality: string,
    onSuccess: (data: any) => void,
    onError: (data: any) => void,
  ): any {
    const featuresWithLimit = {
      export_pdf_low: 'export_pdf_low',
      export_pdf_medium: 'export_pdf_medium',
      export_pdf_high: 'export_pdf_high',

      export_png_low: 'export_png_low',
      export_png_medium: 'export_png_medium',
      export_png_high: 'export_png_high',

      document_publish_online: 'document_publish_online',

      remove_bg_count: 'remove_bg_count',
      use_template_premium: 'use_template_premium',
      export_pdf_count: 'export_pdf_count',
      export_png_count: 'export_png_count',
      export_video: 'export_video',

      shape_stock: 'shape_stock',
      gif_stock: 'gif_stock',
      sticker_stock: 'sticker_stock',
      icon_stock: 'icon_stock',
      image_stock: 'image_stock',

      font_premium: 'use_template_premium',
      upload_font: 'use_template_premium',

      export_with_watermark: 'export_with_watermark',
    };

    const counters = [
      'remove_bg_count',
      'use_template_premium_count',
      'export_pdf_count',
      'export_png_count',
    ];

    // FIXME: Remover quando o 'sticker_stock' estiver nos limits.
    if (functionality === 'sticker_stock') {
      onSuccess(true);
    }

    if (this.limits && this.limits[functionality] === false) {
      onSuccess(false);
      return;
    }

    if (Object.keys(featuresWithLimit).indexOf(functionality) === -1) {
      onSuccess(this.limits && this.limits[functionality] === true);
    } else if (Object.keys(featuresWithLimit).indexOf(functionality) > -1) {
      const isCounter = counters.indexOf(functionality) > -1;
      this.verifyAndUpdatePermissionLimit(
        featuresWithLimit[functionality],
        isCounter,
      )
        .then(success => {
          onSuccess(success.data);
        })
        .catch(err => {
          onError(err);
        });
    }
  }

  public getPermissionLimitCounter(functionality: string): number {
    return this.limits[functionality] || 0;
  }

  public getPermissionLimitBool(functionality: string): boolean {
    return this.limits[functionality] || false;
  }

  public verifyAndUpdatePermissionLimit(
    functionality: string,
    isCounter: boolean = false,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!isCounter) {
        if (this.getPermissionLimitBool(functionality)) {
          resolve({
            data: this.getPermissionLimitBool(functionality),
            msg: functionality,
          });
          return;
        }
        resolve({
          data: false,
          msg: functionality,
        });
        return;
      }

      const limit = this.getPermissionLimitCounter(functionality);

      if (limit <= 0) {
        resolve({ data: false, msg: functionality });
      } else {
        if (!this._userService.user.limits) {
          const limits = {
            remove_bg_count: this.limits.remove_bg_count,
            use_template_premium_count:
              this.limits.use_templatxe_premium_count,
            create_document_count: this.limits.create_document_count,
            export_pdf_count: this.limits.export_pdf_count,
            export_png_count: this.limits.export_png_count,
          };

          limits[functionality] -= 1;
          this.updateUserLimits(limits)
            .then(result =>
              resolve({
                data: true,
                msg: 'Limite atualizado',
              }),
            )
            .catch(err =>
              reject({
                data: false,
                msg: 'Algo deu errado ao atualizar seus limites',
              }),
            );
        } else if (this._userService.user.limits[functionality] > 0) {
          this._userService.user.limits[functionality] -= 1;
          this.updateUserLimits(this._userService.user.limits)
            .then(result =>
              resolve({
                data: true,
                msg: 'Limite atualizado',
              }),
            )
            .catch(err =>
              reject({
                data: false,
                msg: 'Algo deu errado ao atualizar seus limites',
              }),
            );
        } else {
          resolve({
            data: false,
            msg: functionality,
          });
        }
      }
    });
  }

  public async getLimitRemoveBg(): Promise<{ remaining; total }> {
    const { limits, subscription_rules: rules } = this._userService.user;
    this._removeBgLimit = {
      remaining:
        limits && limits.remove_bg_count ? limits.remove_bg_count : 0,
      total: rules && rules.remove_bg_count ? rules.remove_bg_count : 0,
    };
    return this._removeBgLimit;
  }

  public listPlans(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firebase.firestoreModule
        .collection('plan_offered', ref => ref.where('is_deleted', '==', false))
        .get()
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          snapshot => {
            if (!snapshot.empty) {
              const result = [];
              snapshot
                .docChanges()
                .map(item =>
                  result.push({ ...item.doc.data(), id: item.doc.id }),
                );
              resolve(result);
            } else {
              reject('Sem planos cadastrados.');
            }
          },
          err => reject(err),
        );
    });
  }

  public getPlan(planId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firebase.firestoreModule
        .collection('plan_offered')
        .doc(planId)
        .get()
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          snapshot => {
            snapshot.exists
              ? resolve(Object.assign({}, snapshot.data(), { id: snapshot.id }))
              : reject('Plano não encontrado.');
          },
          err => reject(err),
        );
    });
  }

  public async getPlanPremium() {
    if (this._premiumPlan) {
      return this._premiumPlan;
    }
    const snapshot = await this.firebase.firestoreModule
      .collection('plan_offered', ref =>
        ref
          .where('is_free', '==', false)
          .where('is_deleted', '==', false),
      )
      .get()
      .toPromise();
    this._premiumPlan = [];
    if (!snapshot.empty) {
      const data = snapshot.docChanges().filter((plan :any) => !plan.is_trial || plan.is_trial === false);
      this._premiumPlan = data.map(item => item.doc.id);
    }
    return this._premiumPlan;
  }

  public updateUserLimits(limits: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userDoc = this.firebase.firestoreModule
        .collection('user')
        .doc(this._userService.user.id);
      userDoc
        .update({ limits })
        .then(() => {
          resolve(true);
        })
        .catch(err => reject(err));
    });
  }

  /**
   * getUserPlan
   */
  public async getUserPlanId() {

    const userPlanId =
    this._userService.user.current_profile.subscription?.product_subscribed?.id ||
    this._userService.user.subscription_reference?.id;
    if (!userPlanId) {
      return this._userService.user.subscription_plan_reference?.id || '';
    }
    return userPlanId;
  }

  public getStatusSubscriptionPayment() {
    return this._userService.user.subscription_payment_status !== 'paid';
  }

  /**
   * Get subscription portal url
   *
   * @param customerId: string
   * @param callbackUrl: string
   */
  public getSubscriptionPortalUrl(
    customerId: string,
    callbackUrl: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${environment.api.stripe.url}/subscription/payments/`;

      // Required
      const body = {
        customer_id: customerId,
        callback_url: 'https://dashboard.trakto.io' + callbackUrl,
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      this._http.post(url, body, httpOptions).pipe(takeUntil(this._destroy$)).subscribe(
        success => resolve(success),
        error => reject(error),
      );
    });
  }
}
