import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  FunctionsRegionToken,
} from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';
import { FirebaseService } from './firebase.service';
import { FirebaseServiceImpl } from './firebase.service.impl';
import { FirebaseCloudFunctionsService } from './functions/firebase-cloud-functions.service';
import { FirebaseCloudFunctionsServiceImpl } from './functions/firebase-cloud-functions.service.impl';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.api.google.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
  ],
  declarations: [],
  providers: [
    {
      provide: FirebaseCloudFunctionsService,
      useClass: FirebaseCloudFunctionsServiceImpl,
    },
    {
      provide: FirebaseService,
      useClass: FirebaseServiceImpl,
    },
    {
      provide: FunctionsRegionToken,
      useValue: 'us-central1',
    },
  ],
})
export class FirebaseClientModule {}
