import {
  SelectionEvent,
  SelectionEventType,
} from '@editor/components/preview-2/svg-editor/shared/selection.model';
import { ElementModelService } from '@services/element-model.service';
import { NewModelService } from '@services/new-model.service';
import { ShapeElementService } from '@services/shape-element.service';
import { TextElementService } from '@services/text-element.service';
import {
  ElementModelGroupEngine,
  GuideLineConfigModel,
  ResizePointsConfigModel,
} from '@trakto/core-editor';
import { IElementModel, PageModel } from '@trakto/models';
import { Subject } from 'rxjs';

export class SelectionEngine {
  /**
   * Elementos selecionandos
   */
  public selection: ElementModelGroupEngine;

  private page: PageModel;
  private elements: IElementModel[];

  private lastElements: IElementModel[];

  private createResizePointsConfig: (
    selection: ElementModelGroupEngine,
  ) => ResizePointsConfigModel;
  private createGuideLineConfig: (
    selection: ElementModelGroupEngine,
  ) => GuideLineConfigModel;

  public onChangeSelection: Subject<SelectionEvent> = new Subject<SelectionEvent>();

  constructor(
    private newModelService: NewModelService,
    private shapeService: ShapeElementService,
    private fontService: TextElementService,
    private elementService: ElementModelService,
  ) {}

  public init(
    page: PageModel,
    createResizePointsConfig: () => ResizePointsConfigModel,
    createGuideLineConfig: () => GuideLineConfigModel,
  ) {
    this.page = page;
    this.createResizePointsConfig = createResizePointsConfig;
    this.createGuideLineConfig = createGuideLineConfig;
    this.buildSelection([]);
  }

  public updateElements(elements: IElementModel[]) {
    this.elements = elements;

    const hasNewElement = this.hasNewElement();
    const hasRemovedElement = this.hasRemovedElement();
    const oldSelection = this.selection;

    if (hasNewElement || hasRemovedElement) {
      const selectedElements = hasRemovedElement
        ? this.getFirstElement()
        : this.getNewElements();
      this.buildSelection(selectedElements);
      this.notifySelectionChange(
        hasNewElement
          ? SelectionEventType.ADDED_NEW_ELEMENT
          : SelectionEventType.REMOVED_ELEMENT,
        oldSelection,
      );
    } else if (!this.selection.isEmpty()) {
      this.buildSelection(elements.filter(e => this.selection.getRawElementModels().find(re => re.id === e.id)));
      this.notifySelectionChange(
        SelectionEventType.REFERENCE_CHANGE,
        oldSelection,
      );
    }

    this.lastElements = Object.assign([], this.elements);
  }

  resetReference(elements: IElementModel[]) {
    const oldSelection = this.selection;
    this.buildSelection(elements.filter(e => this.selection.getRawElementModels().find(re => re.id === e.id)));
    this.notifySelectionChange(
      SelectionEventType.RESET,
      oldSelection,
    );
  }

  public addElement(element: IElementModel, group?: IElementModel) {
    if (this.selection.hasElement(group || element)) {
      return;
    }
    this.selection.addElementModel(group || element);
    this.initSelectionEngines();
    this.notifySelectionChange(SelectionEventType.SELECTED_ELEMENT);
  }

  public removeElement(element: IElementModel, group?: IElementModel) {
    if (!this.selection.hasElement(group || element)) {
      return;
    }
    this.selection.removeElementModel(group || element);
    this.initSelectionEngines();
    this.notifySelectionChange(SelectionEventType.DESELECTED_ELEMENT);
  }

  public changePage(page: PageModel, emit = true) {
    this.page = page;
    this.resetSelection([], emit);
  }

  public resetSelection(elements: IElementModel[] = [], emit = true) {
    const oldSelection = this.selection;
    this.buildSelection(elements);
    if (emit) {
      this.notifySelectionChange(SelectionEventType.RESET, oldSelection);
    }
  }

  public getSelectionElement(): IElementModel {
    const selectedElements = this.selection.getRawElementModels();
    if (selectedElements.length === 1) {
      return selectedElements[0];
    } else if (selectedElements.length > 1) {
      return this.selection.element;
    }
    return undefined;
  }

  private buildSelection(elements: IElementModel[]) {
    this.selection = ElementModelGroupEngine.build(
      elements,
      this.page,
      this.elementService,
      this.shapeService,
      this.fontService,
    );
    this.selection.element.lockedByTemplate = this.selection
      .getElementModels()
      .some(e => e.lockedByTemplate);
    this.selection.element.edit = !this.selection
      .getElementModels()
      .some(e => !e.edit);
    this.initSelectionEngines();
  }

  private notifySelectionChange(
    event: SelectionEventType,
    oldSelection?: ElementModelGroupEngine,
  ) {
    this.onChangeSelection.next(
      new SelectionEvent(event, this.selection, oldSelection),
    );
  }

  private getFirstElement(): IElementModel[] {
    return this.elements.slice(0, 1);
  }

  private getNewElements(): IElementModel[] {
    return this.elements.filter(
      value => this.lastElements.findIndex(e => e.id === value.id) === -1,
    );
  }

  private hasNewElement(): boolean {
    if (
      this.elements &&
      this.lastElements &&
      this.elements.length > this.lastElements.length
    ) {
      return true;
    }
    return this.lastElements && this.elements
      ? this.lastElements.some(
          (pe: IElementModel, i) =>
            this.elements.findIndex(e => e.id === pe.id) === -1,
        )
      : false;
  }

  private hasRemovedElement(): boolean {
    return (
      this.elements &&
      this.lastElements &&
      this.lastElements.length > this.elements.length
    );
  }

  private initSelectionEngines() {
    if (!this.selection.isEmpty()) {
      this.selection.resizeEngine.createResizePoints(
        this.createResizePointsConfig(this.selection),
      );
      this.selection.guideLinesEngine.createGuideLines(
        this.createGuideLineConfig(this.selection),
      );
    }
  }
}
