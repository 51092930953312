import { Component, OnInit } from '@angular/core';
import { ElementsService, } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';

@Component({
  selector: 'trakto-panel-align-mobile',
  templateUrl: './panel-align-mobile.component.html',
  styleUrls: ['./panel-align-mobile.component.scss'],
})
export class PanelAlignMobileComponent implements OnInit {
  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {}

  closePanel() {
    this._panelService.closeLastPanel();
  }

  public alignTo(axis: string, direction: string): void {
    this._elementsService.alignElement(axis, direction);
  }
}
