<!-- <tkt-promo-banner></tkt-promo-banner> -->
<header id="trakto-header" class="trakto-header">
  <section class="trakto-header__logo">
    <a (click)="backToEditor($event)">
      <!-- B2B Logo  -->
      <ng-container *ngIf="!b2c || isDropboxInstance">
        <img [src]="logo" alt="Logo image" />
      </ng-container>

      <!-- if b2c && isPremiumUser && !isDropboxInstance // B2C Logo Premium -->
      <ng-container *ngIf="b2c && isPremiumUser && !isDropboxInstance">
        <img [src]="traktoPremiumLogo" alt="Logo Premium Trakto" />
      </ng-container>

      <!-- if b2c && !isPremiumUser // B2C Logo -->
      <ng-container *ngIf="b2c && !isPremiumUser && !isDropboxInstance">
        <img [src]="imageTrakto" alt="Logo image" />
      </ng-container>
    </a>
  </section>

  <section class="trakto-header__content">
    <div class="trakto-header__content-left">
      <trakto-dropdown-menu-header #menuFile *ngIf="hasUser" [title]="'header.button_file' | translate"
        [placement]="'left'" class="trakto-header__button-links"
        [button]="{ classes: ['tkt-button__DS terciary color-white'] }">
        <div class="trakto-header__content-input-title" [ngStyle]="{
            border: emptyInput ? '1px dashed #fd3e1e' : '1px dashed #d9dcdd'
          }">
          <form [formGroup]="form">
            <div class="trakto-header__content-input" [ngStyle]="{ width: maxWidthInput === true ? '100%' : '152px' }">
              <input type="text" (focus)="disableHotkeys($event)" (blur)="enableHotkeys($event)"
                (input)="renameDoc($event.target.value)" formControlName="title" spellcheck="false" />
              <i [ngStyle]="{ display: !focusIcon ? 'block' : 'none' }" class="trakto-icon-stream-pencil"></i>
            </div>
          </form>
        </div>
        <!--
          fixme: Corrigir Herarquia de Classes
          how do: trazer a classe 'trakto-dropdown-menu-header-item__menu-file' do componente para o header
        -->
        <trakto-dropdown-menu-header-item (click)="AutoSaveIndicator.handleSave()"
          [classe]="'trakto-dropdown-menu-header-item__menu-file'">
          <i class="trakto-icon-save-local"></i>
          <span> {{ 'header.button_save' | translate }}</span>
        </trakto-dropdown-menu-header-item>

        <trakto-dropdown-menu-header-item *ngIf="isAdmin || isGlobalUser" (onClick)="openPanelStack('publish')"
          [classe]="'trakto-dropdown-menu-header-item__menu-file'">
          <i class="trakto-icon-publish"></i>
          <span>{{ 'header.button_publish' | translate }}</span>

          <strong>CTRL + E</strong>
        </trakto-dropdown-menu-header-item>

        <trakto-dropdown-menu-header-item *ngIf="isMagicTemplate && isGlobalUser"
          (onClick)="openPanelStack('magic_template')" [classe]="'trakto-dropdown-menu-header-item__menu-file'">
          <i class="trakto-icon-star"></i>
          <span>{{ 'header.button_magic' | translate }}</span>
        </trakto-dropdown-menu-header-item>

        <trakto-dropdown-menu-header-item *ngIf="false" [classe]="'trakto-dropdown-menu-header-item__menu-file'">
          <i class="trakto-icon-stream-help"></i>
          <span>{{ 'header.button_help' | translate }}</span>
        </trakto-dropdown-menu-header-item>

        <div class="divider divider-horizontal color-clear tkt-margin"></div>
        <a *ngIf="!isDropboxInstance" [href]="dashboardURL">
          <trakto-dropdown-menu-header-item *ngIf="!isEmbeddedInstance"
            [classe]="'trakto-dropdown-menu-header-item__menu-file'">
            <i [style.fontSize.px]="'10'" class="trakto-icon-to-back"></i>
            <span>{{ 'header.button_back_home' | translate }}</span>
          </trakto-dropdown-menu-header-item>
        </a>
      </trakto-dropdown-menu-header>

      <trakto-auto-save-indicator #AutoSaveIndicator>
      </trakto-auto-save-indicator>

      <!-- Desfazer -->
      <button class="
          tkt-button__DS
          tooltip
          hover-color-white hover-tkt-dark
          trakto-header__undo-button
        " (click)="undo()">
        <i class="trakto-icon-undo-round"></i>

        <div class="tkt-button__DS span-tooltip">
          {{ 'header.tooltip_undo' | translate }}
        </div>
      </button>
      <!-- end -->
    </div>

    <div class="trakto-header__content-center">
      <div class="trakto-header__content-item"></div>
      <trakto-button-premium *ngIf="b2c && !isPremiumUser" lang="{{ currentLang }}">
        {{ 'header.button_be_pro' | translate }}
      </trakto-button-premium>
      <trakto-button *ngIf="renewPlan && isPremiumUser" (click)="stripePlanRenewalPortal()" button--plan-renewal
        [config]="{ height: 32, classes: ['button--plan-renewal'] }">
        <span>{{ 'payments.plan_renewal.span' | translate }} |
          <strong>{{
            'payments.plan_renewal.strong' | translate
            }}</strong></span>
      </trakto-button>
      <!-- end -->
    </div>
  </section>

  <section class="trakto-header__actions">
    <div class="trakto-header__actions-group" *ngIf="isLinksOpen">
      <!-- Botão Tkt Links publish -->
      <button *ngIf="shareAsLinkAvailable" class="
          tkt-button__DS
          tkt-light
          color-white
          btn-icon-left
          hover-color-white hover-tkt-dark
        " (click)="publishTraktoLink()">
        <i [style.fontSize.px]="17" class="trakto-icon-link-drop" aria-hidden="true"></i>
        <span>{{ 'trakto_links.button_publish' | translate }}</span>
      </button>
    </div>
    <div class="trakto-header__actions-group" *ngIf="!isLinksOpen">
      <!-- Botão Tkt shared -->
      <button
        *ngIf="shareAsTemplateAvailable"
        (click)="onClickShareOption($event)"
        class="tkt-button__DS tooltip tkt-light color-white btn-icon-left hover-color-white hover-tkt-dark"
      >
        <i [style.fontSize.px]="16" class="trakto-icon-shared"></i>

        <div class="tkt-button__DS span-tooltip">
          {{ 'header.tooltip' | translate }}
        </div>
      </button>
      <!-- end -->

      <!-- Botão Tkt Links-->
      <button *ngIf="shareAsLinkAvailable" class="
          tkt-button__DS
          tkt-light
          color-white
          btn-icon-left
          hover-color-white hover-tkt-dark
        " (click)="modal.toggle(); disablehotkeys()">
        <i [style.fontSize.px]="16" class="trakto-icon-shared"></i>
        <!-- <span>{{ 'header.button_link' | translate }}</span> -->
        <!-- TROCAR HARDCODE PARA i18n -->
        <span>Compartilhar</span>
      </button>

      <trakto-trakto-link-modal #modal (onModalShareChange)="shareModalChange($event)"></trakto-trakto-link-modal>

      <!-- Modo Aprensetação -->
      <button *ngIf="shareAsPresentation" class="
          tkt-button__DS
          tkt-light
          color-white
          btn-icon-left
          hover-color-white hover-tkt-dark
        " (click)="openToPreview()">
        <i [style.fontSize.px]="15" class="trakto-icon-presentation-drop"></i>
        <span>{{ 'header.button_presentation' | translate }}</span>
      </button>
      <!-- end -->

      <!-- Botão de Download -->
      <button *ngIf="
          (!isEmbeddedInstance && !isDropboxInstance && exportAvailable) ||
          embedDownloadType === 'fullDownloadOnBrowser' ||
          embedDownloadType === 'saveButtonAndFullDownload'
        " class="
          tkt-button__DS
          tkt-download-button
          btn-icon-left
          white
          color-balck
        " (click)="openPanelStack('download')">
        <i class="trakto-icon-download-drop"></i>
        <span [style.color]="'#0D2226'">{{
          'header.button_download' | translate
          }}</span>
      </button>
      <!-- end -->

      <!-- Botão save DropBox -->
      <trakto-button *ngIf="!isEmbeddedInstance && isDropboxInstance" button--fill button--bold button--special
        button--dropbox [style.width.px]="170" [config]="{ height: 40, classes: ['button--dropbox'] }"
        (onClick)="saveInDropbox()">
        <i class="trakto-icon-dropbox-modal"></i>
        <span>{{ 'header.button_save_dropbox' | translate }}</span>
      </trakto-button>

      <!-- Botão Embedded -->
      <button *ngIf="
          isEmbeddedInstance &&
          (embedDownloadType === 'saveButtonAndFullDownload' ||
            embedDownloadType === 'png' ||
            embedDownloadType === 'pdf' ||
            embedDownloadType === 'mp4')
        " button--fill button--bold button--special button--download class="
          tkt-button__DS
          tkt-light
          color-white
          hover-color-white hover-tkt-dark
        " [style.width.px]="100" (click)="intentExportToClient()">
        {{ embedSaveButtonTitle || ('header.button_save' | translate) }}
      </button>
      <!-- end -->
    </div>
  </section>
</header>
<trakto-modal-progress> </trakto-modal-progress>
<trakto-modal-loader> </trakto-modal-loader>

<!-- Start of Async Drift Code -->
<script>
  'use strict';

  !(function () {
    var t = (window.driftt = window.drift = window.driftt || []);
    if (!t.init) {
      if (t.invoked)
        return void (
          window.console &&
          console.error &&
          console.error('Drift snippet included twice.')
        );
      (t.invoked = !0),
        (t.methods = [
          'identify',
          'config',
          'track',
          'reset',
          'debug',
          'show',
          'ping',
          'page',
          'hide',
          'off',
          'on',
        ]),
        (t.factory = function (e) {
          return function () {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }),
        t.methods.forEach(function (e) {
          t[e] = t.factory(e);
        }),
        (t.load = function (t) {
          var e = 3e5,
            n = Math.ceil(new Date() / e) * e,
            o = document.createElement('script');
          (o.type = 'text/javascript'),
            (o.async = !0),
            (o.crossorigin = 'anonymous'),
            (o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js');
          var i = document.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(o, i);
        });
    }
  })();
  drift.SNIPPET_VERSION = '0.3.1';
  drift.load('bu69nv4cmsm3');
</script>
<!-- End of Async Drift Code -->

<!-- Botão Suporte -->
<lib-sidebar #sidebar [opened]="false" [topGap]="56" [hasBackdrop]="true" title="{{
    'support_journey.support_sidebar.title'
      | translate: { userName: user?.firstname || '🥰' }
  }}" description="{{ 'support_journey.support_sidebar.description' | translate }}" style="z-index: 50000">
  <div>
    <supoprt-list-item title="{{
        'support_journey.support_sidebar.button_attendance.title' | translate
      }}" description="{{
        'support_journey.support_sidebar.button_attendance.description'
          | translate
      }}" (clicked)="sidebar.onClose(); handleOpenChat()" bgIcon="rgba(152, 126, 237, 0.3)">
      <!-- <i iconLeft class="trakto-icon-stream-cog"></i> -->
      <i iconLeft><img style="width: 24px; height: 24px" src="/assets/images/support_balloon_icon.svg"
          alt="balloon icon" /></i>
    </supoprt-list-item>
    <supoprt-list-item title="{{
        'support_journey.support_sidebar.button_error.title' | translate
      }}" description="{{
        'support_journey.support_sidebar.button_error.description' | translate
      }}" (clicked)="sidebar.onClose(); handleUserSnapBugReport()" bgIcon="rgba(254, 148, 116, 0.2)">
      <i iconLeft><img style="width: 24px; height: 24px" src="/assets/images/support_warnning_icon.svg"
          alt="balloon icon" /></i>
    </supoprt-list-item>
    <supoprt-list-item title="{{
        'support_journey.support_sidebar.button_template_request.title'
          | translate
      }}" description="{{
        'support_journey.support_sidebar.button_template_request.description'
          | translate
      }}" (clicked)="sidebar.onClose(); handleUserSnapTemplateRequest()" bgIcon="rgba(255, 207, 96, 0.3)">
      <i iconLeft><img style="width: 24px; height: 24px" src="/assets/images/support_star_icon.svg"
          alt="balloon icon" /></i>
    </supoprt-list-item>
    <supoprt-list-item title="{{
        'support_journey.support_sidebar.button_feedback.title' | translate
      }}" description="{{
        'support_journey.support_sidebar.button_feedback.description'
          | translate
      }}" (clicked)="sidebar.onClose(); handleUserSnapFeedBack()" bgIcon="rgba(102, 175, 255, 0.3)">
      <i iconLeft><img style="width: 24px; height: 24px" src="/assets/images/support_like_icon.svg"
          alt="balloon icon" /></i>
    </supoprt-list-item>
  </div>
</lib-sidebar>
