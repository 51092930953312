import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { TraktoElementDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-element.directive';
import { PageModel } from '@trakto/models';

@Directive({
  selector: '[traktoPageBgRect]',
})
export class TraktoPageBgRectDirective
  extends TraktoElementDirective
  implements OnChanges {
  @Input()
  traktoPageBgRect: PageModel;

  @Input()
  printable: boolean;

  @HostBinding('attr.x')
  x = 0;

  @HostBinding('attr.y')
  y = 0;

  @HostBinding('attr.width')
  width: number;

  @HostBinding('attr.height')
  height: number;

  @HostBinding('attr.fill')
  fill: string;

  @HostBinding('attr.stroke-width')
  strokeWidth: number;

  @HostBinding('attr.fill-opacity')
  fillOpacity: number;

  constructor() {
    super();
  }

  ngOnChanges() {
    if (!this.traktoPageBgRect) {
      return;
    }
    const page = this.traktoPageBgRect;

    this.id = `${page.id}__canvas`;
    this.width = page.width;
    this.height = page.height;
    this.fill = page.backgroundColor ? page.backgroundColor : '#E6E6E6';
    this.strokeWidth = 1 / this.zoomRatio;
    this.fillOpacity = page.backgroundColor ? 1 : 0;
  }
}
