import { Injectable } from '@angular/core';
import { ElementFactoryService } from '@services/element-factory.service';
import { IconSVGConversionService } from '@shared/svg-viewer/shared/icon-svg-conversion.service';
import { ImageSVGConversionService } from '@shared/svg-viewer/shared/image-svg-conversion.service';
import { ShapeSVGConversionService } from '@shared/svg-viewer/shared/shape-svg-conversion.service';
import { TextSVGConversionService } from '@shared/svg-viewer/shared/text-svg-conversion.service';
import { YoutubeSVGConversionService } from '@shared/svg-viewer/shared/youtube-svg-conversion.service';
import { ElementModelService } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import { ShapeElementService } from '@services/shape-element.service';
import { SVGService } from '@services/svg.service';
import { PageModel } from '@trakto/models';
import { ConversionParams, PageSVGConverter } from '@trakto/svg-converter';
import { Subject } from 'rxjs';
import {
  VideoSVGConversionService
} from '@shared/svg-viewer/shared/video-svg-conversion.service';

@Injectable()
export class PageSVGConversionService extends PageSVGConverter {
  private _hasWaterMask = true;
  waterMaskValueChange: Subject<boolean> = new Subject<boolean>();

  public get hasWaterMask() {
    return this._hasWaterMask;
  }
  public set hasWaterMask(value) {
    this._hasWaterMask = value;
  }

  public toggleWaterMask() {
    this._hasWaterMask = !this._hasWaterMask;
    this.waterMaskValueChange.next(this.hasWaterMask);
  }

  public setWaterMask(val: boolean) {
    this.hasWaterMask = val;
    this.waterMaskValueChange.next(this.hasWaterMask);
  }

  constructor(
    elementsModelService: ElementFactoryService,
    iconConversionService: IconSVGConversionService,
    imageConversionService: ImageSVGConversionService,
    shapeConversionService: ShapeSVGConversionService,
    textConversionService: TextSVGConversionService,
    youtubeConversionService: YoutubeSVGConversionService,
    videoConversionService: VideoSVGConversionService,
    elementService: ElementModelService,
    shapeService: ShapeElementService,
    imageService: ImageElementService,
    svgService: SVGService,
  ) {
    super(
      elementsModelService,
      iconConversionService,
      imageConversionService,
      shapeConversionService,
      textConversionService,
      youtubeConversionService,
      videoConversionService,
      elementService,
      shapeService,
      imageService,
      svgService,
    );
  }

  toSVG(page: PageModel, params: ConversionParams): string {
    params.hasWaterMask = false;
    params.currentLang = params.currentLang || 'pt-BR';
    return super.toSVG(page, params);
  }
}
