import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IRadioControlValueAccessor } from './radio.model';

@Component({
  selector: 'trakto-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent
  implements OnInit, OnChanges, IRadioControlValueAccessor {
  @ViewChild('radio', { static: true }) private radio;

  private _id: string;
  private _name: string;
  private _value: string;
  private _disabled: boolean;

  public isSelected: boolean;

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || null;
  }

  @Input()
  public set id(id: string) {
    this._id = id;
  }

  public get id() {
    return this._id || '';
  }

  @Input()
  public set name(name: string) {
    this._name = name;
  }

  public get name() {
    return this._name || '';
  }

  @Input()
  public set value(value: string) {
    this._value = value;
  }

  public get value() {
    return this._value || '';
  }

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  private _onChange = (value: boolean) => {};
  private _onTouched = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit() {
    this.toggleClass();

    if (!this.id) {
      throw new Error(
        `Radio id is required. eg.: <trakto-radio [id]="myId"></trakto-radio>`,
      );
    }

    if (!this.name) {
      throw new Error(
        `Radio name is required. eg.: <trakto-radio [name]="myName"></trakto-radio>`,
      );
    }

    if (!this.value) {
      throw new Error(
        `Radio value is required. eg.: <trakto-radio [value]="myValue"></trakto-radio>`,
      );
    }
  }

  ngOnChanges() {
    this._getHostElement().disabled = this.disabled ? true : false;
    this.toggleClass();
  }

  _getHostElement(): any {
    return this.radio.nativeElement;
  }

  writeValue(value: boolean): void {
    this.isSelected = value === this.radio.nativeElement.value;
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'checked',
      this.isSelected,
    );
  }

  registerOnChange(fn: (p?: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (p?: any) => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  toggle() {
    this._onChange(this.radio.nativeElement.value);
    this.onChange.emit(this.radio.nativeElement.value);
  }

  toggleClass() {
    const element: HTMLElement = this._elementRef.nativeElement.children[0];
    this.disabled
      ? element.classList.add('--disabled')
      : element.classList.remove('--disabled');
  }
}
