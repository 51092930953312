<div class="empty-state">
  <img
    src="assets/images/formats-elements/empty-cloud.png"
    class="empty-state__image"
    alt="{{ 'sidebar_elements.empty' | translate }}"
  />
  <p class="empty-state__description">
    {{ 'sidebar_elements.empty' | translate }}
  </p>
</div>
