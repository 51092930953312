import { Component, OnDestroy, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IFontOptions, ITextElementModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import { FontsService } from '@services/fonts.service';
import { getFontStyle } from '@trakto/core-editor';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-style-mobile',
  templateUrl: './panel-style-mobile.component.html',
  styleUrls: ['./panel-style-mobile.component.scss']
})
export class PanelStyleMobileComponent implements OnInit, OnDestroy {

  currentText: ITextElementModel;
  options: { weight: string, italic: boolean, option: IFontOptions }[] = [];

  private _subscriptionText: Subscription;

  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _fontsService: FontsService,
  ) { }

  ngOnInit(): void {
    this._subscriptionText = this._documentStateManagerService.element$
      .subscribe((element) => {
      this.currentText = element as ITextElementModel;
      this.initFontOptions();
    })
  }

  ngOnDestroy(): void {
    if (this._subscriptionText) {
      this._subscriptionText.unsubscribe();
    }
  }

  setStyle(fontStyle: IFontOptions) {
    this._elementsService.changeFontStyle(fontStyle);
    this.closePanel();
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  async initFontOptions () {
    const fontModel = await this._fontsService.findFont(this.currentText.fontFamily)
      .toPromise();
    this.options = fontModel.options.map(option => {
        const decodedFontStyle = getFontStyle(option);
        return {
          weight: decodedFontStyle.weight !== 'normal' ? decodedFontStyle.weight : '',
          italic: decodedFontStyle.style === 'italic',
          option,
        }
    });
  }
}
