import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'trakto-input-suggestions',
  templateUrl: './input-suggestions.component.html',
  styleUrls: ['./input-suggestions.component.scss'],
})
export class InputSuggestionsComponent implements OnInit, OnChanges {
  @Input() isVisible: boolean = false;
  @Input() tags: string[] = [];

  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

  finalTags: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tags && this.tags) {
      this.finalTags = [...new Set(this.tags)];
    }
  }

  selectTag(tag: string) {
    this.onSelect.emit(tag);
  }
}
