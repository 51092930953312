import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer, Subject } from 'rxjs';

import { TraktoUser } from '@auth/shared/auth.model';
import { UserService } from '@app/editor-v3/services/user.service';

/**
 * Limita o acesso a usuários não logados
 */
@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(
    private _userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this._userService.getProfile().subscribe((user: TraktoUser) => {
        if (!user) {
          observer.next(true);
          observer.complete();
        }
    },
        error => {
          observer.error(true);
          observer.complete();
          console.error(error);
        },
      );
    });
  }
}
