import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageService } from '@app/editor-v3/services/page.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import {
  FormatsService,
  IChildFormat,
  IParentFormat,
} from '@app/editor/components/properties-panel/panel-page/services/formats.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { PageModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-formats-page-mobile',
  templateUrl: './panel-formats-page-mobile.component.html',
  styleUrls: ['./panel-formats-page-mobile.component.scss'],
})
export class PanelFormatsPageMobileComponent implements OnInit, OnDestroy {
  formats: IParentFormat[] = [];
  lang = 'pt-BR';
  pageSelected: PageModel = null;

  private _onPageChange: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _panelService: PanelService,
    private _pageService: PageService,
    private _formatsService: FormatsService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,

  ) {}

  ngOnInit(): void {
    this.getFormats();
    this.initSubscriptions();
  }

  initSubscriptions() {
    this._onPageChange = this._documentStateManagerService.page$.subscribe(
      page => {
        this.pageSelected = page;
      },
    );

    this._subscriptions.push(this._onPageChange);
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  destroySubscriptions() {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  async getFormats() {
    this.formats = await this._formatsService.getOrdenedFormats();
  }

  selectFormat(format: IChildFormat) {
    this._pageService.changePageFormat(format);
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
