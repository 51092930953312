import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/auth/shared/auth.service";
import { PlatformLoadingService } from "@app/editor/services/platform-loading.service";

@Component({
  template: `<div>{{ loggedIn }}</div>`,
})
export class ExternalAuthComponent implements OnInit {
  public loggedIn: string = 'Connecting';
  constructor(
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _platformLoaderService: PlatformLoadingService,
  ) {
    this._platformLoaderService.hide();
    (window as any).sendEventToApp('true');
  }

  ngOnInit() {
    const { firebaseToken, docId } = this._route.snapshot.params;
    this._authService.login(firebaseToken).toPromise().then(async (data) => {
      this._router.navigateByUrl(`light-editor/${docId}`);
      (window as any).sendEventToApp('false');
    })
      .catch(err => {
        this.loggedIn = 'Error while connecting, please try again.';
        console.error('Login err', err);
      });
  }


}
