import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UploadStorageService
} from '@app/shared/storage/upload.storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TraktoApiService } from '@services/trakto-api.service';
import { IDocumentModel } from '@trakto/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InitService } from '@app/editor/services/init.service';
import { DocumentStateManagerService } from '@app/editor/services/document-state-manager.service';

@Component({
  selector: 'trakto-pdf-upload-design',
  templateUrl: './upload-design.component.html',
  styleUrls: ['./upload-design.component.scss']
})
export class PDFUploadDesignComponent implements OnInit, OnDestroy {

  private _fileUploading: boolean;

  public set fileUploading(value: boolean) {
    this._fileUploading = value;
  }

  public get fileUploading(): boolean {
    return this._fileUploading || false;
  }

  public uploadYourFile: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _traktoApiService: TraktoApiService,
    private _translateService: TranslateService,
    private _documentManagerService: DocumentStateManagerService,
    private _initService: InitService
  ) {
    this.loadTranslations();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  loadTranslations(): void {
    this._translateService.get('sidebar_publish.upload-file').pipe(takeUntil(this._destroy$)).subscribe(translations => {
      this.uploadYourFile = translations['upload-your-file'];
    });
  }

  async uploadFile(event: any): Promise<any> {
    this.fileUploading = true;
    try {
      this.fileUploading = true;
      const file = event.target.files[0];
      const document = await this.uploadPDF(file);
      document.body
        .reverse()
        .forEach(page => this._documentManagerService.addPage(page));

      await this._genNewThumbs(document);
      this.fileUploading = false;
    } catch (error) {
      this.fileUploading = false;
      throw error;
    }
  }

  private async _genNewThumbs(document: IDocumentModel) {
    const promises = document.body.map(
      (page) => this._initService.initImagesByPage(page));
    return Promise.all(promises);
  }

  async uploadPDF(pdf: File): Promise<IDocumentModel> {
    const formData: FormData = new FormData();
    formData.append('file', pdf, pdf.name)
    const result: { newDocument: IDocumentModel } = await this._traktoApiService.executeBellaPost(`convert/pdf?merge_text_lines=true`, formData, false);
    return result.newDocument;
  }

}
