<div class="default-template-loader" *ngIf="isLoading">
  <div class="default-template-loader-spinner">
    <div class="tkt-loader__spinner"></div>
  </div>
  <p class="magic-template-text">
    {{ 'sidebar_publish.loader-text' | translate }}
  </p>
</div>
<div class="default-template">
  <ng-container *ngIf="(currentStep$ | async) === 2">
    <form class="form-group" [formGroup]="form" (submit)="submit($event)">
      
      <trakto-title-with-line [title]="fillInTheFields"></trakto-title-with-line>
      
      <div class="select-container">
        <div class="select-row">
          <div class="select-column" *ngIf="!isPublic">
            <trakto-select-field class="select-row" [placeholder]="'App'"
            formControlName="app_reference" [options]="apps"></trakto-select-field>
          </div>
          <div class="select-column" *ngIf="!isPublic">
            <trakto-select-field class="select-row" [placeholder]="productLabel"
            formControlName="products" [options]="products" [multiple]="true"
            (openedChange)="onChangeProducts($event)"
            (selectionChange)="loadFormatsByProducts()"></trakto-select-field>
          </div>
        </div>
        <div class="select-row">
          <div class="select-column">
            <trakto-select-field class="select-row" [placeholder]="localeLabel" [options]="availableLocales"
            formControlName="locale" [multiple]="true"></trakto-select-field>
          </div>
          <div class="select-column">
            <trakto-select-field class="select-row" [placeholder]="themeLabel" [options]="themes"
            formControlName="theme_reference"
            (selectionChange)="change('theme_reference', $event)"></trakto-select-field>
          </div>
        </div>
        <div class="select-row">
          <div class="select-column">
            <ng-container formGroupName="author">
              <trakto-input-field [placeholder]="authorLabel"
              formControlName="name" [value]="form.get('author.name')"></trakto-input-field>
            </ng-container>
          </div>
          <div class="select-column">
            <trakto-select-field class="select-row" [placeholder]="formatLabel" [options]="formats"
            formControlName="page_format_reference"
            (selectionChange)="change('page_format_reference', $event)"></trakto-select-field>
          </div>
        </div>

        <trakto-input-field class="select-row" [placeholder]="titleLabel"
        formControlName="title" [value]="form.get('title').value"></trakto-input-field>

        <trakto-input-chip-field class="select-row" [placeholder]="'Palavras-chave'"
        [options]="form.get('tags').value"
        formArrayName="tags" (valueChange)="addTag($event)"
        (chipRemoved)="removeTag($event)"></trakto-input-chip-field>

        <div class="toggle">
          <mat-slide-toggle
          formControlName="published"
          [color]="'accent'"
          [checked]="getFormData('published').controls">
            {{ 'sidebar_publish.switches.publish_gallery' | translate }}
          </mat-slide-toggle>
        </div>
      </div>
    </form>

    <div>
      <trakto-title-with-line [title]="visualization"></trakto-title-with-line>

      <div class="select-container">
        <div class="img-content" *ngIf="image">
          <img [src]="image">

          <trakto-button
          button--fill
          button--primary
          (onClick)="removeFile()">
            {{ 'sidebar_publish.buttons.remove_image' | translate }}
          </trakto-button>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="(currentStep$ | async) === 3 && !isLoading">
    <div class="step">
      <div class="step-icon">
        <img src="../../../../../../assets/icons/rocket-success.svg" alt="">
      </div>

      <trakto-title-with-line [title]="publicationCompleted"></trakto-title-with-line>

      <div class="step-text">
        <p>
          {{ 'sidebar_publish.template-successfully-published-and-available-in-the-gallery' | translate }}
        </p>
      </div>
      <div class="step-img">
        <div class="step-line">
          <hr>
        </div>
        <div class="img-content" *ngIf="image">
          <img [src]="image">
        </div>
      </div>
      <div class="default-template-success">
        <div class="default-template-input-link">
          <div class="default-template-subdomain">
            <label for="subdomain"><i class="trakto-icon-link-drop"></i>trakto.link/</label>
          </div>
          <div class="trakto-icon-link">
            <a class="default-template-btn-copy" (click)="copyLinkToClipboard()">
              <img *ngIf="!link.copied" src="../../../../../../assets/icons/document-copy.svg">
              <img *ngIf="link.copied" src="../../../../../../assets/icons/tick-circle.svg">
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="navigation-buttons" *ngIf="(currentStep$ | async) === 2 && !isLoading">
  <button class="btn back" mat-button [disabled]="(currentStep$ | async) === 1"
  (click)="navigateToBackStep()">
    {{ 'sidebar_publish.buttons.go_back' | translate }}
  </button>
  <button class="btn save" mat-button type="submit" [ngClass]="{'disabled': !form.valid}"
  [disabled]="!form.valid" (click)="navigateToNextStep(); submit($event)">
    {{ 'sidebar_publish.buttons.save_template' | translate }}
  </button>
</div>
<div class="navigation-buttons" *ngIf="(currentStep$ | async) === 3 && !isLoading">
  <button class="btn finish" mat-button (click)="close()">
    {{ 'sidebar_publish.buttons.complete' | translate }}
  </button>
</div>