import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  NotificationService
} from '@app/shared/notification/notification.service';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';

import { ImageElementModel, INotificationMessage } from '@trakto/models';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-link-email',
  templateUrl: './panel-link-email.component.html',
})
export class PanelLinkEmailComponent extends AbstractComponent<ImageElementModel> {
  public form: FormGroup;
  public replaceEmailTo: string;
  public replaceSubject: string;
  public replaceBody: string;
  public notifyMSGs: INotificationMessage;

  public get email() {
    return this.form.get('email');
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _hotkeyService: HotkeyService,
    private _panelStackService: PanelStackService,
    private _notificationService: NotificationService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super(documentStateManagerService);
    this.notifyMSGs = this._notificationService.notificationsMSGs;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.configFormBuild();
    this.replaceEmail();
  }

  private configFormBuild(): void {
    this.form = this._formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      subject: [null, [Validators.required]],
      messageBody: [null, [Validators.required]],
    });
  }

  public handleSubmit(): void {
    const {
      value: { email, subject, messageBody },
    } = this.form;
    if (!this.form.valid && !this.checkRegexEmailTo(email)) {
      return;
    }
    const emailTo = `mailto:${email}?subject=${this.scapeURI(
      subject,
    )}&body=${this.scapeURI(messageBody)}`;

    this._elementChangeFacadeService.changeElementProperty(
      this.elementSelected,
      'email',
      !this.elementSelected.email && emailTo ? emailTo : null,
    );

    this.resetForm();
  }

  public scapeURI(string: string): string {
    return encodeURI(string);
  }

  public unescapeURI(string: string): string {
    return decodeURI(string);
  }

  private resetForm(): void {
    if (this.elementSelected.email) {
      this._notificationService.success(this.notifyMSGs.link_added_success, {
        isSimple: false,
        hasIcon: false,
        time: 3000,
      });
    } else {
      this.form.reset();
      this._notificationService.success(
        this.notifyMSGs.link_removed_successfully,
        { isSimple: false, hasIcon: false, time: 3000 },
      );
    }
  }

  private checkRegexEmailTo(email: string): boolean {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email) ? true : false;
  }

  private replaceEmail(): void {
    const splitEmail =
      this.elementSelected.email !== undefined
        ? this.elementSelected.email.split('?')
        : null;
    const removeMailTo = splitEmail
      ? splitEmail[0].replace('mailto:', '')
      : null;

    const sliptSubject =
      splitEmail !== null && splitEmail[1] ? splitEmail[1].split('&') : null;
    const removeSubject = sliptSubject
      ? sliptSubject[0].replace('subject=', '')
      : null;
    const removeBody = sliptSubject
      ? sliptSubject[1].replace('body=', '')
      : null;

    this.replaceEmailTo = removeMailTo;
    this.replaceSubject = this.unescapeURI(removeSubject);
    this.replaceBody = this.unescapeURI(removeBody);
    this.feedFileds(sliptSubject, removeMailTo, removeBody);
  }

  private feedFileds(
    sliptSubject: string[],
    removeMailTo: string,
    removeBody: string,
  ): void {
    if (sliptSubject === null && removeBody == null && removeMailTo) {
      this.replaceSubject = '';
      this.replaceBody = '';
      this._notificationService.warn(this.notifyMSGs.update_email_link_info, {
        isSimple: false,
        hasIcon: false,
        time: 4000,
      });
    } else if (
      sliptSubject === null &&
      removeBody == null &&
      removeMailTo == null
    ) {
      this.replaceSubject = '';
      this.replaceBody = '';
    }
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public closePanelLink(): void {
    this._panelStackService.pop(500);
  }
}
