import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  private _gsInstance() {
    return window['gtag'];
  }

  public trackEvent(
    eventName: string,
    eventParams: { category: string; label?: string; value: number },
    callback: any,
  ) {
    const gtag = this._gsInstance();
    if (gtag) {
      gtag('event', eventName, {
        event_category: eventParams.category,
        event_label: eventParams.label,
        event_value: eventParams.value,
        event_callback: () => callback('Added'),
      });
    }
  }
}
