import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LightLoaderComponent } from './light-loader.component';

@NgModule({
  declarations: [LightLoaderComponent],
  imports: [CommonModule],
  exports: [LightLoaderComponent],
})
export class LightLoaderModule {}
