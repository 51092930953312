import { Injectable } from '@angular/core';
import {
  fontSizeScaleModeEnum,
  IElementModel,
  ImageElementModel,
  ISvgElementModel,
  ITextElementModel,
  IYoutubeElementModel,
  PageModel
} from '@trakto/models';
import { DocumentManagerService } from '@services/document-manager.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { MediaService } from '@services/media.service';
import { PathSvgService } from '@services/path-svg.service';
import { ElementFactoryService } from '@services/element-factory.service';
import {
  INotificationMessage,
  IShapeElementModel
} from '@trakto/models/dist/shared';
import { NotificationService } from '@shared/notification/notification.service';
import { ResolutionsModel } from '@trakto/graphics-resources';
import { ImageElementService } from '@services/image-element.service';
import { ImageUtilService } from '@services/image-util.service';
import { NewModelService } from '@services/new-model.service';
import { TextElementService } from '@services/text-element.service';
import { TextEditorService } from '@services/text-editor.service';
import { FontsService } from '@services/fonts.service';
import { NewEmojiElementService } from '@services/new-emoji-element.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import {
  ElementDatasource
} from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ElementFactoryFacadeService {
  public notifyMSGs: INotificationMessage;
  constructor(
    private _elementsModelService: ElementFactoryService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
    private _mediaService: MediaService,
    private _pathSvgService: PathSvgService,
    private _notificationService: NotificationService,
    private _imageElementService: ImageElementService,
    private _imageUtilService: ImageUtilService,
    private _newModelService: NewModelService,
    private _textElementService: TextElementService,
    private _textEditorService: TextEditorService,
    private _fontsService: FontsService,
    private _newEmojiElementService: NewEmojiElementService,
    private _uploadStorageService: UploadStorageService,
  ) {
    this.notifyMSGs = this._notificationService.notificationsMSGs;
  }

  public async createAndAddText(text?: string, fontSize?: number, fontWeight: number | string = 'regular'): Promise<ITextElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    const textElement = this._newModelService.convertElementModel(
      page,
      this._textElementService.createTextElementByText(text, fontSize),
    ) as ITextElementModel;
    textElement.fontSizeScaleMode = fontSizeScaleModeEnum.manual;
    const fontModel = await this._fontsService.getDefaultFontModel().toPromise();
    const style = fontModel.options.find(o => o.weight === `${fontWeight}`) || fontModel.options[0];
    this._textEditorService.changeFontStyle(style, textElement, false, false);
    this._textElementService.fitTextDimension(textElement);
    this._textElementService.refreshTextElement(textElement);
    const fakePage = { id: page.id, fontModels: [ ...(page.fontModels || []) ], elements: [...page.elements] } as PageModel;
    await this._textElementService.addFontModelsByTextElement(fakePage, textElement).toPromise();
    this._documentStateManagerService.persistPageChangesNoTrackable(fakePage);
    return this._addText(textElement);
  }

  public async createAndAddShapeByUrl(url: string): Promise<IShapeElementModel> {
    const path = await this._mediaService.getSVGPathByUrl(url).toPromise();
    return this.createAndAddShapeByPath(path);
  }

  public async createAndAddShapeByPath(path: string): Promise<IShapeElementModel> {
    if (!path || (path && !this._pathSvgService.isValidPath(path))) {
      throw new Error(this.notifyMSGs.shape_with_problem)
    }
    const shape = Object.assign(
      {},
      this._elementsModelService.makeShapeModel(),
      this._getPathParams(path),
    );
    return this._addShape(
      this._newModelService.convertElementModel(await this._documentManagerService.getSelectedPage(), shape) as IShapeElementModel
    );
  }

  public async createAndAddVideo(videoUrl: string): Promise<IYoutubeElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    const video: IYoutubeElementModel = this._newModelService.convertElementModel(
      page,
      { ...this._elementsModelService.makeYoutubeModel(), href: videoUrl } as IElementModel,
    ) as IYoutubeElementModel;
    await this._imageElementService.initYoutubeModel(video, page, true).toPromise();
    return this._addYoutube(video);
  }

  public async createAndAddImageByUrl(url: string, source?: ElementDatasource): Promise<ImageElementModel> {
    const resolutions = this._imageUtilService.createTempResolutions(url);
    const page = await this._documentManagerService.getSelectedPage();
    const image: any = await this._imageElementService.createClippedImageByUrls(
      resolutions,
      page,
    );

    image.loading = true;

    if (source === ElementDatasource.FLATICON) {
      image.source = ElementDatasource.FLATICON;
    }

    this._addNewImage(image);
    await this._documentStateManagerService.changeImageUrl(page, image, url);
    this._onChangeProperty(image, 'href');
    return image;
  }

  public async createAndAddImageByFile(file: File): Promise<ImageElementModel> {
    const blobImageUrl = this._uploadStorageService.getBlobImageUrl(file);
    const resolutions = this._imageUtilService.createTempResolutions(blobImageUrl);
    const page = await this._documentManagerService.getSelectedPage();
    const image = await this._imageElementService.createClippedImageByUrls(
      resolutions,
      page,
    );
    image.loading = true;
    this._addNewImage(image);
    const newImage = await this._documentStateManagerService.changeImageUrlByFile(page, image, file);
    this._onChangeProperty(newImage, 'href');
    return newImage;
  }

  public async createAndAddImageByUrls(resolutions: ResolutionsModel): Promise<ImageElementModel> {
    const image = await this._imageElementService.createClippedImageByUrls(
      resolutions,
      await this._documentManagerService.getSelectedPage()
    );
    return this._addNewImage(image);
  }

  public async createAndAddGifByFile(file: File): Promise<ImageElementModel> {
    const urls = await this._mediaService.uploadImageByFile(file);
    return this.createAndAddGifByUrls(urls.resolutions);
  }

  public async createAndAddGifByUrl(url: string, lowQualityUrl?: string): Promise<ImageElementModel> {
    const resolutions = this._imageUtilService.createTempResolutions(lowQualityUrl || url);
    const page = await this._documentManagerService.getSelectedPage();
    const gif = await this._imageElementService.createGifByUrls(
      resolutions,
      page,
    );
    gif.loading = true;
    this._addGif(gif);
    const newGif = await this._documentStateManagerService.changeImageUrl(page, gif, url);
    this._onChangeProperty(newGif, 'href');
    return newGif;
  }

  public async createAndAddGifByUrls(resolutions: ResolutionsModel): Promise<ImageElementModel> {
    const gif = await this._imageElementService.createGifByUrls(
      resolutions,
      await this._documentManagerService.getSelectedPage()
    );
    return this._addGif(gif);
  }

  public async createAndAddEmojiByFile(file: File): Promise<ISvgElementModel> {
    const blobImageUrl = this._uploadStorageService.getBlobImageUrl(file);
    return await this.createAndAddEmojiByUrl(
      blobImageUrl
    );
  }

  public async createAndAddEmojiByUrl(url: string): Promise<ISvgElementModel> {
    const svg = this._newEmojiElementService.normalizeSVG(await this._mediaService.getSVG(url));
    const icon: ISvgElementModel = Object.assign(
      this._elementsModelService.makeEmojiModel(),
      {
        svg: svg,
        width: 50,
        height: 50,
      },
    );
    return this._addEmojiElement(
      this._newModelService.convertElementModel(await this._documentManagerService.getSelectedPage(), icon) as ISvgElementModel
    );
  }

  private _addText(text: ITextElementModel): ITextElementModel {
    this._documentManagerService.addNewElement(text);
    return text;
  }

  private _addEmojiElement(icon: ISvgElementModel): ISvgElementModel {
    this._documentManagerService.addNewElement(icon);
    return icon;
  }

  private _addNewImage(image: ImageElementModel): ImageElementModel {
    this._documentManagerService.addNewElement(image);
    return image;
  }


  private _addGif(gif: ImageElementModel): ImageElementModel {
    this._documentManagerService.addNewElement(gif);
    return gif;
  }

  private _addShape(shape: IShapeElementModel): IShapeElementModel {
    this._documentManagerService.addNewElement(shape);
    return shape;
  }

  private _addYoutube(youtube: IYoutubeElementModel): IYoutubeElementModel {
    this._documentManagerService.addNewElement(youtube);
    return youtube;
  }

  private _getPathParams(path: string): { path, width, height } {
    let params = { path: path, width: 50, height: 50 };

    const bbox = globalThis.Snap.path.getBBox(path);
    const maxSize = 50;
    const scale = maxSize / Math.min(bbox.width, bbox.height);
    params.path = globalThis.Snap.path
      .map(
        path,
        new globalThis.Snap.Matrix()
          .scale(scale, scale)
          .translate(bbox.x * -1, bbox.y * -1),
      )
      .toString();
    params.width = bbox.width * scale;
    params.height = bbox.height * scale;

    return params;
  }

  private _onChangeProperty(element: IElementModel | PageModel, prop: string) {
    const model = {
      elementId: element.id,
      key: prop,
      obj: element,
    };
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, model);
  }
}
