<button
  class="button__premium tkt-button__DS tkt-brand color-white hover-tkt-dark btn-icon-right"
  (click)="openCheckout()"
>
  <!--

    // NOTE:
    // O texto de internacionalização está centralizado no componente a fim de
    // modularizar e torná-lo independente do contexto. Desse modo ele não fica
    // dependente do arquivo i18n;

   -->
  <span class="btn-premium-label" *ngIf="lang === 'pt-BR'">
    Clique aqui e <strong>tenha acesso completo</strong>
  </span>
  <span class="btn-premium-label" *ngIf="lang === 'en-US'">
    Click here and <strong>get more resources</strong>
  </span>
  <i><img src="/assets/images/diamond.svg" alt="diamond icon" /></i>
</button>
