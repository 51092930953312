<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-mask.svg"
    label="Máscara"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="search click-ignore">
    <trakto-input
      [value]="searchText"
      [clearIcon]="true"
      placeholder="Pesquise por algo"
      (onChange)="setInputText($event)"
      (onBlur)="setShowSuggestions(false)"
      (onSelect)="selectTag($event)"
    ></trakto-input>
  </div>

  <trakto-tag-list
    [tags]="tags"
    [selected]="filteredText"
    (onSelect)="selectTag($event)"
    class="click-ignore"
  ></trakto-tag-list>

  <trakto-elements-list
    *ngIf="filteredText.length > 0"
    [searchEvent]="search"
  ></trakto-elements-list>

  <div *ngIf="filteredText.length === 0" class="wrapper click-ignore">
    <div class="grid">
      <div class="shape" (click)="removeMask()">
        <i class="icon icon-null"></i>
      </div>

      <ng-container>
        <div class="shape" (click)="applyQuickMask('square')">
          <img
            src="/assets/images/shapes/rect.svg"
            class="element-image"
            height="40px"
          />
        </div>
        <div class="shape" (click)="applyQuickMask('star')">
          <img
            src="/assets/images/shapes/start.svg"
            class="element-image"
            height="40px"
          />
        </div>
        <div class="shape" (click)="applyQuickMask('hexagon')">
          <img
            src="/assets/images/shapes/hexagon.svg"
            class="element-image"
            height="40px"
          />
        </div>
        <div class="shape" (click)="applyQuickMask('circle')">
          <img
            src="/assets/images/shapes/elipse.svg"
            class="element-image"
            height="40px"
          />
        </div>
        <div class="shape" (click)="applyQuickMask('triangle')">
          <img
            src="/assets/images/shapes/triangle.svg"
            class="element-image"
            height="40px"
          />
        </div>
        <div class="shape" (click)="applyQuickMask('diamond')">
          <img
            src="/assets/images/shapes/diamond.svg"
            class="element-image"
            height="40px"
          />
        </div>
      </ng-container>
    </div>
  </div>
</div>
