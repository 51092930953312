import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ModalLoaderComponent } from './modal-loader.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ModalLoaderComponent],
  exports: [ModalLoaderComponent],
})
export class ModalLoaderModule {}
