import { Component, Input } from '@angular/core';
import { FBTraktoTracking } from '@app/editor/services/analytics/facebook/fbTraktoTracking.analytics';
import { PremiumService } from '@app/editor/services/premium.service';
import { IFeatureBlockerData } from '@trakto/data-tracking/dist/interfaces/DataTracking.model';

@Component({
  selector: 'trakto-button-premium',
  templateUrl: './button-premium.component.html',
  styleUrls: ['./button-premium.component.scss'],
})
export class ButtonPremiumComponent {
  @Input() lang: string;

  constructor(
    private _premiumService: PremiumService,
    private _fbTrack: FBTraktoTracking
  ) {}

  public openCheckout() {
    const dataEvent: IFeatureBlockerData = {
      reason: 'header notification',
      actionBlocked: '',
      startedByBlocker: false,
    };
    this._fbTrack.openCheckout();
    this._premiumService.openPremiumCheckout();
  }
}
