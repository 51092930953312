import { Injectable } from '@angular/core';
export type ErrorHandlerType = {
    message: string;
    stack?: any;
};

@Injectable({
    providedIn: 'root'
})
export class ErrorClientHandlerService {
    triggerError(error: ErrorHandlerType) {        
        (window as any).sendErrorToApp(JSON.stringify(error));
    }
}