import { Inject, Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { CookieThemeLoaderEnum } from '../enums/ui/cookie-theme-loader';

import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class CookieLoaderService {
  public defaultThemeLoaderCookie: any = {
    title: 'B2C',
    product_id: 'fbvZngcBbaFZeUK8h0il',
    logo: {
      high: { url: './assets/images/brand-original.svg' },
      low: { url: './assets/images/brand-original.svg' },
      medium: { url: './assets/images/brand-original.svg' },
    },
    color: {
      primary: '#7648ff',
      secondary: '#7d5aff',
      third: '#7648ff',
      quaternary: '#b788ff',
      background_logo: '#ffffff',
      background_menu: '#6e44ec',
      menu_icon_color: '#ffffff',
      menu_text_color: '#ffffff',
      menu_profile_text_color: '#ffffff',
      menu_logout_text_color: '#ffffff',
      menu_hover_color: '#f14e77',
      menu_text_hover_color: '#ffffff',
    },
  };

  constructor(
    @Inject(DOCUMENT) private _DOM,
    private _cookiesService: CookieService,
  ) {}

  public setCookieThemeLoader(themeCurrent: any) {
    const expire = new Date(new Date().getTime() + 432000000);
    const expireTime = expire.toUTCString();
    const currentProductTheme = this._safelyParseJSON(themeCurrent);

    const cookieSession = [
      `${CookieThemeLoaderEnum.cookieKeyThemeLoader}=${currentProductTheme};`,
      `expires=${expireTime};`,
      `SameSite=None;`,
      `Secure;`,
    ].join('');
    this._cookiesService.deleteAll(CookieThemeLoaderEnum.cookieKeyThemeLoader);
    this._DOM.cookie = cookieSession;
  }

  /**
   * Metodo responsavel em garantir a transformação do objeto
   * em um String JSON, caso ocorra erro na conversão, a função
   * retorna um objeto default trakto.
   * @param themeCurrent - Tema do produto atual.
   */
  private _safelyParseJSON(themeCurrent): any {
    let themeProduct: any;
    try {
      themeProduct = JSON.stringify(themeCurrent);

      if (
        typeof themeProduct !== 'string' ||
        Object.entries(themeCurrent).length === 0
      ) {
        throw new TypeError('Erro ao converter objeto em String JSON');
      }
    } catch (error) {
      console.warn('Error em _safelyParseJSON() ==>', error.message);
      return JSON.stringify(this.defaultThemeLoaderCookie);
    }

    return themeProduct;
  }
}
