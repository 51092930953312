import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';

import { AudioService } from '@app/shared/audio/audio.service';
import { NotificationService } from '@app/shared/notification/notification.service';
import { ExportService } from '@editor/services/export/export.service';
import { FontsService } from '@editor/services/fonts.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { ThemeService } from '@editor/services/theme.service';
import { YoutubeService } from '@editor/services/youtube/youtube.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@shared/storage/storage-service.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';

import { ExportUtilModel } from '@editor/components/preview-2/shared/export-util.model';
import {
  IDocumentModel,
  IYoutubeElementModel,
  PageModel,
} from '@trakto/models';

import { ModalProgressComponent } from '@app/shared/modal-progress/modal-progress.component';
import { VideoModalComponent } from '@editor/components/video-modal/video-modal.component';
import { delay, takeUntil } from 'rxjs/operators';

import { PageSVGConversionService } from '@app/shared/svg-viewer/shared/page-svg-conversion.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';

import { AuthService } from '@app/auth/shared/auth.service';
import { PagePDFConversionService } from '@app/shared/svg-viewer/shared/page-pdf-conversion.service';
import { environment } from '@env/environment';
import { ElementModelService } from '@services/element-model.service';
import { PlatformLoadingService } from '@services/platform-loading.service';
import { TextService } from '@services/text.service';
import { ConversionParams } from '@trakto/svg-converter';
import { Subject, forkJoin } from 'rxjs';
import { DocumentService } from '@services/document.service';
import { UserService } from '@app/editor-v3/services/user.service';

@Component({
  selector: 'trakto-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PresentationComponent implements OnInit, OnDestroy {
  @ViewChild(ModalProgressComponent, { static: true })
  protected modalProgressComponent: ModalProgressComponent;
  @ViewChild('previewDocument', { static: true }) public previewDocument: any;

  @Output() onDownloadEnded: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShare: EventEmitter<any> = new EventEmitter<any>();
  public isB2C = false;

  public document: IDocumentModel = null;
  public currentPage = null;
  public zoomRatio = 100;
  public zoomRatioByPage = {};
  public dashboardURL: string;
  public tooltipTranslate: any;
  public currentDialogRef = null;
  public isFullscreen: boolean;
  public hoverLogo = true;
  public themeMode = false;
  public offSetWidthMobile = false;
  private _youtubeService: YoutubeService;

  private PADDING_X = 70;
  private PADDING_Y = 80;
  private REST_OF_PAGE = 40;
  public _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) public DOM: Document,
    protected _productService: WhitelabelProductService,
    protected newElementService: ElementModelService,
    protected readonly _audioService: AudioService,
    protected readonly _hotkeyService: HotkeyService,
    protected readonly _notificationService: NotificationService,
    protected readonly _renderer: Renderer2,
    protected readonly _themeService: ThemeService,
    protected readonly _translateService: TranslateService,
    protected readonly documentService: DocumentService,
    protected readonly dialog: MatDialog,
    protected readonly exportService: ExportService,
    protected readonly fonts: FontsService,
    protected readonly textService: TextService,
    protected readonly hotkeyService: HotkeyService,
    protected readonly realtime: AngularFireDatabase,
    protected readonly route: ActivatedRoute,
    protected readonly storageService: StorageService,
    protected readonly themeService: ThemeService,
    protected readonly uploadStorageService: UploadStorageService,
    protected readonly _pageSvgConversion: PageSVGConversionService,
    protected readonly _pagePDFConversionService: PagePDFConversionService,
    protected readonly _authService: AuthService,
    protected readonly _loaderService: PlatformLoadingService,
    protected readonly _exportUtil: ExportUtilModel,
    private _userService: UserService,
  ) {}

  async ngOnInit() {
    this.listeningToMouseScrollEvent();
    this.listeningFullScreenChangeEvent();
    this.listeningPresentationHotKeysEvent();
    this.translationOfTheStockTooltip();
    this.isB2C = this._userService.isB2c;
    this.dashboardURL = environment.externalUrlTrakto;
    const { premium, slug } = this.route.snapshot.params;
    this._startDocumentLoad(slug);

    if (premium) {
      this._pageSvgConversion.hasWaterMask = false;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private async _startDocumentLoad(slug: string) {
    try {
      this._youtubeService = new YoutubeService();
      this.themeService.getObservableTheme();
      const userDocument = await this._getDocumentBySlug(slug);
      await this.newElementService
        .initFontModelsByDocument(userDocument)
        .toPromise();
      await this.newElementService.initAllQuillModel(userDocument).toPromise();
      await this._loadInfoByDocument(userDocument);

      this._trackingData(
        userDocument.app_reference,
        userDocument.user_reference,
      );
    } catch (error) {
      await this._getFullDocument(slug);
    }
  }

  private async _trackingData(appReference: any, userReference: any) {}

  private async _trackingLoadError(error: any) {}

  public listeningPresentationHotKeysEvent(): void {
    this.hotkeyService.addContext('presentation_keys', (e: KeyboardEvent) => {
      if (this.hotkeyService.KEY(e)) {
        this.hotkeyService.stopPropagation(e);

        switch (e.which) {
          case KeyEnum.up:
          case KeyEnum.left:
            this.prevPage();
            break;
          case KeyEnum.down:
          case KeyEnum.right:
            this.nextPage();
            break;
          case KeyEnum.enter:
            this.openFullscreen();
            break;
        }
      }
    });
  }

  public listeningFullScreenChangeEvent(): void {
    this.DOM.addEventListener(
      'fullscreenchange',
      () => {
        this.isFullscreen = !this.DOM.fullscreenElement ? false : true;
        this.applyZoom(this.currentPage.page);
      },
      false,
    );
  }

  public listeningToMouseScrollEvent(): void {
    this.DOM.addEventListener('wheel', event => {
      const direction = this.detectMouseWheelDirection(event);
      switch (direction) {
        case 'up':
          this.nextPage();
          break;
        case 'down':
          this.prevPage();
          break;
      }
    });
  }

  public detectMouseWheelDirection(event) {
    const { offsetWidth } = this.previewDocument.nativeElement;
    if (offsetWidth && offsetWidth <= 768) {
      return;
    }

    let delta = null;
    let direction: string;

    if (!event) {
      event = window.event;
    }

    if (event.wheelDelta) {
      delta = event.wheelDelta / 60;
    } else if (event.detail) {
      delta = -event.detail / 2;
    }

    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  public translationOfTheStockTooltip(): void {
    this.tooltipTranslate = { white_mode: '', black_mode: '' };
    this._translateService
      .get('presentation.tooltip')
      .pipe(takeUntil(this._destroy$))
      .subscribe(translations => {
        this.tooltipTranslate.white_mode = translations['white_mode'];
        this.tooltipTranslate.black_mode = translations['black_mode'];
      });
  }

  private _getFullDocument(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._getDocumentBySlug(id)
        .then(doc2 => this._loadInfoByDocument(doc2))
        .then(() => resolve())
        .catch(err => {
          this._trackingLoadError(err);
          reject(err);
        });
    });
  }

  // FIXME: Move to repository layer (class service)
  public _getDocumentBySlug(slug: string): Promise<IDocumentModel> {
    try {
      return this.documentService.findPublicById(slug);
    } catch (e) {
      window.location.href = environment.static.traktoLinksNotFound;
    }
  }

  // FIXME: Move to repository layer (class service)
  public _getDocumentRealtime(document: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.realtime
        .object(`documents/${document.docId}`)
        .query.once('value')
        .then(value => resolve({ ...document, body: value.val() }))
        .catch(err => reject(err));
    });
  }

  // FIXME: Move to domain layer (class service)
  private _loadInfoByDocument(doc: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.document = doc;

        const index = 0;
        const hasVideo = this._hasVideoOnPageIndex(index);

        this.currentPage = {
          index,
          page: this.document.body[index],
          hasVideo,
          videoElement: this._getVideoOnPageIndex(index),
          videoPlaying: false,
          videoHasClosed: false,
        };

        forkJoin(
          this.document.body.map(page => this.fonts.loadByPage(page)),
        ).pipe(takeUntil(this._destroy$)).subscribe(
          () => {
            this.finishLoading();
            resolve();
          },
          e => reject(e),
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  private finishLoading() {
    this._loaderService.hide();
    this.applyZoom();
  }

  // FIXME: Move to domain layer (class service)
  private _getZoomRatioByWidth(page: PageModel): number {
    const container =
      this.previewDocument.nativeElement.offsetWidth - this.PADDING_X * 2;
    return (container / page.width) * 100;
  }

  // FIXME: Move to domain layer (class service)
  private _getZoomRatioByHeight(page: PageModel): number {
    const container =
      this.previewDocument.nativeElement.offsetHeight - this.PADDING_Y * 2;
    return (container / page.height) * 100;
  }

  private _getScreenPadding(): void {
    const { offsetWidth } = this.previewDocument.nativeElement;
    this.offSetWidthMobile = offsetWidth <= 768;
    this.PADDING_X = offsetWidth >= 768 ? 70 : 0;
    this.PADDING_Y = offsetWidth >= 768 ? 70 : 0;
    this.REST_OF_PAGE = offsetWidth >= 768 ? 40 : 0;
  }

  // FIXME: Move to interface layer (class service)
  private _hasVideoOnPageIndex(index: number): boolean {
    if (
      this.document &&
      this.document.body &&
      this.document.body[index] &&
      this.document.body[index].elements
    ) {
      return (
        this.document.body[index].elements.findIndex(
          (item: any) => item.type === 'youtube',
        ) !== -1
      );
    }

    return false;
  }

  // FIXME: Move to interface layer (class service)
  private _getVideoOnPageIndex(index: number): IYoutubeElementModel {
    if (
      this.document &&
      this.document.body &&
      this.document.body[index] &&
      this.document.body[index].elements
    ) {
      const videoIndex = this.document.body[index].elements.findIndex(
        (item: any) => item.type === 'youtube',
      );

      return videoIndex !== -1
        ? this.document.body[index].elements[videoIndex]
        : null;
    }

    return null;
  }

  // FIXME: Move to interface layer (class service)
  // FIXME: Remove duplicated code
  public applyZoom(pageForZoom?: PageModel) {
    const { offsetWidth, offsetHeight } = this.previewDocument.nativeElement;

    const pages: PageModel[] = this.document ? this.document.body : [];
    const page: PageModel = pageForZoom || pages[0] || null;

    this._getScreenPadding();

    if (page) {
      if (this.isFullscreen) {
        this.PADDING_X = 0;
        this.PADDING_Y = 0;
      }

      this.zoomRatio = this._getZoomRatioByHeight(page);
      const additionalSizeLogo = this.isFullscreen ? 86 : 0;

      const area = {
        width: Math.floor(offsetWidth) - this.PADDING_X * 2,
        height:
          Math.floor(offsetHeight) - this.PADDING_Y * 2 + additionalSizeLogo,
      };

      const ratio = {
        area: area.width / area.height,
        page: page.width / page.height,
      };

      if (ratio.area < ratio.page) {
        this.zoomRatio = this._getZoomRatioByWidth(page);
      }

      this.zoomRatioByPage = pages.reduce(
        (previous, current) => ({
          [current.id]: this._getZoomRatioByWidth(current),
          ...previous,
        }),
        {},
      );
    }
  }

  public downloadPresentation(): void {
    const resolution = 'medium';

    this._translateService.get('presentation.notification').pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.modalProgressComponent.show(value['download_pdf']);
      delay(100);
      this._pagePDFConversionService
        .downloadPDF(
          this.document,
          ConversionParams.makeConversionParamsByResolution(
            this.document.is_printable,
            resolution,
          ),
          this.document.title,
          null,
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            const event = this._exportUtil.downloadComplete();
            this.modalProgressComponent.hide(event, () =>
              this.onDownloadEnded.emit({ data: true, event }),
            );
            this._audioService.play('download');
          },
          error => {
            const event = this._exportUtil.downloadError();
            this.modalProgressComponent.hide(event, () =>
              this.onDownloadEnded.emit({ data: true, event }),
            );
          },
        );
    });
  }

  public sharedPresentation(): void {
    const handler = event => {
      event.clipboardData.setData(
        'text/plain',
        `${environment.editor}/presentation/${this.document['docId']}`,
      );
      event.preventDefault();
      document.removeEventListener('copy', handler, true);
    };

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');

    this._translateService.get('presentation.notification').pipe(takeUntil(this._destroy$)).subscribe(value => {
      this._notificationService.success(value['share_link'], {
        isSimple: false,
        hasIcon: false,
        time: 3000,
      });
    });
  }

  public whiteAndBlackThemeApplication(): void {
    this.themeMode = !this.themeMode;

    this.themeMode
      ? this._themeService.setThemePresentation(
          this._themeService.whiteThemeForPresentationMode,
        )
      : this._themeService.setThemePresentation(
          this._themeService.blackThemeForPresentationMode,
        );
  }

  public openVideo(element: IYoutubeElementModel) {
    return this.dialog.open(VideoModalComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: { url: this._youtubeService.getYoutubeUrl(element) },
    } as MatDialogConfig);
  }

  public openFullscreen() {
    const fullScreeAction = this.isFullscreen
      ? 'fullscreen_closed'
      : 'fullscreen_open';

    !this.isFullscreen
      ? this.DOM.documentElement.requestFullscreen()
      : this.DOM.exitFullscreen();
  }

  public restartPresentation(): void {
    if (this.currentPage.index) {
      this.currentPage.index = 0;
      this.applyZoom();
    }
  }

  public goToPage(value: string): void {
    const pageNumber = Number(`${value}`);
    this.document.body
      .map(page => (page && page.order) === pageNumber)
      .filter((order, index) => {
        if (order) {
          this.currentPage.index = index;
        }
      });
  }

  public nextPage() {
    if (this.currentPage.hasVideo && !this.currentPage.videoPlaying) {
      this.currentPage.videoPlaying = true;

      this.currentDialogRef = this.openVideo(this.currentPage.videoElement);
      const el = this.DOM.createElement('button');
      el.innerHTML = '<i class="trakto-icon-close"></i>';
      el.classList.add('close-button-presentation');

      setTimeout(() => {
        this.DOM.getElementsByClassName('cdk-overlay-pane')[0].appendChild(el);
        el.addEventListener('click', () => {
          if (this.currentDialogRef) {
            this.currentPage.videoHasClosed = true;
            this.currentDialogRef.close();
            this.currentDialogRef = null;
          }
          el.remove();
        });
      }, 1000);

      this.currentDialogRef
        .keydownEvents()
        .pipe(takeUntil(this._destroy$))
        .subscribe((event: KeyboardEvent) => {
          switch (event.which) {
            case KeyEnum.left:
              this.currentPage.videoPlaying = false;
              this.currentDialogRef.close();
              this.currentDialogRef = null;
              el.remove();
              break;
            case KeyEnum.right:
              if (this.currentDialogRef) {
                this.currentPage.videoHasClosed = true;
                this.currentDialogRef.close();
                this.currentDialogRef = null;
              }
              el.remove();
              this.nextPage();
              break;
          }
        });

      return;
    }

    if (this.document.body[this.currentPage.index + 1]) {
      if (this.currentPage.index !== this.document.body.length) {
        this.currentPage.index++;
      }

      this.currentPage.hasVideo = this._hasVideoOnPageIndex(
        this.currentPage.index,
      );
      this.currentPage.videoElement = this._getVideoOnPageIndex(
        this.currentPage.index,
      );
      this.currentPage.videoPlaying = false;
      this.currentPage.page = this.document.body[this.currentPage.index];
      this.applyZoom(this.currentPage.page);
    }
  }

  public prevPage() {
    if (
      this.currentPage.hasVideo &&
      this.currentPage.videoPlaying &&
      !this.currentPage.videoHasClosed
    ) {
      return;
    }

    if (this.document.body[this.currentPage.index - 1]) {
      if (this.currentPage.index !== 0) {
        this.currentPage.index--;
      }

      this.currentPage.hasVideo = this._hasVideoOnPageIndex(
        this.currentPage.index,
      );
      this.currentPage.videoElement = this._getVideoOnPageIndex(
        this.currentPage.index,
      );
      this.currentPage.videoPlaying = false;
      this.currentPage.page = this.document.body[this.currentPage.index];
      this.applyZoom(this.currentPage.page);
    }
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }
}
