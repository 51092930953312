import { ElementModelService } from '@services/element-model.service';
import {
  IElementModel,
  ImageElementModel,
  IShapeElementModel,
} from '@trakto/models';

import { ClipEditionInfo } from '@editor/components/preview-2/svg-editor/shared/ClipEditionInfo';
import { Drawable } from '@editor/components/preview-2/svg-editor/shared/drawable.model';
import { EditorConfigs } from '@editor/components/preview-2/svg-editor/shared/EditorConfigs';
import { ImageElementService } from '@services/image-element.service';
import { ShapeElementService } from '@services/shape-element.service';
import { ElementModelGroupEngine } from '@trakto/core-editor';
import { Subject } from 'rxjs';

export class DrawablesEngine {
  private editorConfig: EditorConfigs;

  private isPreviewDoc: boolean;
  private elements: IElementModel[];
  private drawableElements: Drawable[] = [];
  public clipEditionInfo: ClipEditionInfo;

  public onChangeDrawableElements: Subject<Drawable[]> = new Subject<
    Drawable[]
  >();
  public onStartClipEdition: Subject<ClipEditionInfo> = new Subject<ClipEditionInfo>();
  public onFinishClipEdition: Subject<ClipEditionInfo> = new Subject<ClipEditionInfo>();

  constructor(
    private elementService: ElementModelService,
    private shapeElementService: ShapeElementService,
    private imageElementService: ImageElementService,
  ) {}

  public init(editorConfig: EditorConfigs) {
    this.editorConfig = editorConfig;
  }

  public updateElements(elements: IElementModel[], isPreviewDoc: boolean) {
    this.elements = elements;
    this.isPreviewDoc = isPreviewDoc;
    this.updateDrawableElements();
  }

  public updateDrawableElements() {
    this.drawableElements = [];

    if (this.elements) {
      this.elements.forEach(
        (element: IElementModel) =>
          (this.drawableElements = this.drawableElements.concat(
            this.makeDrawableElementsByElement(element),
          )),
      );

      this.drawableElements = this.drawableElements.reverse();

      if (this.clipEditionInfo) {
        this.drawableElements.splice(
          this.clipEditionInfo.source.position,
          1,
          this.clipEditionInfo.metaImage,
          this.clipEditionInfo.metaShape,
        );
      }
    }
    this.onChangeDrawableElements.next(this.drawableElements);
  }

  public updateSelectedDrawableElements(
    lastClickedElement: IElementModel,
    selection: ElementModelGroupEngine,
  ) {
    if (!this.drawableElements || !selection) {
      return;
    }
    const isSingleSelectionGroup = selection.hasSingleGroupElement();
    this.drawableElements.forEach(de => {
      de.selected =
        selection.hasElement(de.element) &&
        (!isSingleSelectionGroup || de.element === lastClickedElement);
      if (selection.hasElement(de.element)) {
        de.updateClickableElement(isSingleSelectionGroup);
      }
    });
  }

  public isClipPart(element: IElementModel): boolean {
    return (
      this.clipEditionInfo &&
      (this.clipEditionInfo.metaImage.element === element ||
        this.clipEditionInfo.metaShape.element === element)
    );
  }

  public isClipMode(): boolean {
    return this.clipEditionInfo !== undefined;
  }

  public startClipEdition(source: IElementModel) {
    const clipPosition = this.drawableElements.findIndex(
      ve => ve.element.id === source.id,
    );
    const unclip = this.imageElementService.unclip(source as ImageElementModel);
    const shape = unclip.shape;
    const image = unclip.image;
    this.clipEditionInfo = new ClipEditionInfo(
      { element: source, position: clipPosition, drawable: this.drawableElements[clipPosition] },
      new Drawable(shape, false, this.editorConfig),
      new Drawable(image, false, this.editorConfig),
    );

    this.shapeElementService.resetShapeAppearance(shape as IShapeElementModel);
    this.updateDrawableElements();
    this.onStartClipEdition.next(this.clipEditionInfo);
  }

  public finishClipEdition() {
    if (!this.clipEditionInfo) {
      return;
    }
    this.mergeClipChanges();
    const clipEditionInfo = this.clipEditionInfo;
    this.clipEditionInfo = undefined;
    this.updateDrawableElements();
    this.onFinishClipEdition.next(clipEditionInfo);
  }

  private mergeClipChanges() {
    if (this.clipEditionInfo) {
      this.imageElementService.mergeClipChange(
        this.clipEditionInfo.source.element,
        this.clipEditionInfo.metaImage.element,
        this.clipEditionInfo.metaShape.element,
      );
    }
  }

  private makeDrawableElementsByElement(
    element: IElementModel,
    group?: IElementModel,
  ): Drawable[] {
    let result = [];
    if (this.elementService.isGroup(element)) {
      element['elements'].forEach(
        e =>
          (result = result.concat(
            this.makeDrawableElementsByElement(e, group || element),
          )),
      );
    } else {
      result.push(
        new Drawable(
          element,
          this.elementService.isClip(element),
          this.editorConfig,
          group,
          this.isPreviewDoc,
        ),
      );
    }
    return result;
  }
}
