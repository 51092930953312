import {
  elementTypeEnum,
  IElementModel, IGroupElementModel,
  ImageElementModel, IShapeElementModel
} from '@trakto/models';

declare var Flatted: any;

export const copyObject = (source: any): any => {
  return Flatted.parse(Flatted.stringify(source));
};

export const  assignObject = (target: any, source: any) => {
  if (!target || !source || typeof target !== 'object') {
    return;
  }
  Object.keys(source).forEach((key) => {
    if (typeof source[key] === 'object') {
      if (source[key] && source[key].length != undefined) {
        const reference = [];
        source[key].forEach((entry, i) => {
          reference.push(target[key][i] || (typeof reference[i] === 'object' ? {} : ''));
          if (typeof reference[i] === 'object') {
            assignObject(reference[i], source[key][i]);
          } else {
            reference[i] = source[key][i];
          }
        });
        target[key] = reference;
      } else {
        assignObject(target[key], source[key]);
      }
      return;
    }
    target[key] = source[key];
  });
}

export function lightCopyElement(el: IElementModel): IElementModel {
  const copy = ({
    ...el,
    magicTemplate: { tags: [ ...(el.magicTemplate?.tags || []) ] }
  }) as IElementModel;

  if (copy.type === elementTypeEnum.image) {
    (copy as ImageElementModel).submask = lightCopyElement((copy as ImageElementModel).submask) as IShapeElementModel;
  }

  if (copy.type === elementTypeEnum.group) {
    (copy as IGroupElementModel).elements = (copy as IGroupElementModel).elements.map(el => lightCopyElement(el));
  }
  return copy;
}
