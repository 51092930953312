import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  private _value: string;
  public input: HTMLInputElement;

  public set value(value: string) {
    this._value = value;
  }

  public get value(): string {
    return this._value;
  }

  constructor() {
    this.input = this.getInput();
  }

  public getInput(): HTMLInputElement {
    return document.getElementById('auxiliary-field') as HTMLInputElement;
  }

  public copy(content?: string): void {
    if (content) {
      this.value = content;
      this.getInput().value = content;
    }

    this.focus();
    this.select();
    document.execCommand('copy');
    this.getInput().value = '';
  }

  public disable(): void {
    this.getInput().removeAttribute('readonly');
    this.blur();
  }

  public enable(): void {
    this.focus();
    this.getInput().setAttribute('readonly', '');
  }

  public focus(): void {
    this.getInput().focus();
  }

  public blur(): void {
    this.getInput().blur();
  }

  public select(): void {
    this.getInput().select();
  }
}
