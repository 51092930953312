import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';

import { SelectComponent } from '../select.component';

@Component({
  selector: 'trakto-select-option',
  templateUrl: './option.component.html',
  styleUrls: ['./../select.component.scss'],
})
export class SelectOptionComponent implements OnInit {
  private _value: string;
  private _icon: string;

  @ViewChild('content', { static: true }) content: ElementRef;

  @Input() select: SelectComponent;

  @Input() public set value(value: string) {
    this._value = value;
  }

  public get value(): string {
    return this._value || null;
  }

  @Input() public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon(): string {
    return this._icon;
  }

  constructor(private _element: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this._element.nativeElement.setAttribute('data-filter', this.value);
      this.select.data.push({
        value: this.value,
        label: this.content.nativeElement.innerHTML.trim(),
        element: this._element,
      });
    });

    if (
      this._element.nativeElement.parentNode.parentNode.classList.contains(
        'tkt-selectr__options',
      )
    ) {
      this.onClick(false);
    }
  }

  public onClick(toggleMenu: boolean = true) {
    this.select.current = {
      value: this.value,
      label: this.content.nativeElement.innerHTML.trim(),
    };

    this.select.data.map(option =>
      option.element.nativeElement.children[0].classList.remove(
        'tkt-selectr__option--active',
      ),
    );
    this._element.nativeElement.children[0].classList.add(
      'tkt-selectr__option--active',
    );

    if (toggleMenu) {
      this.select.toggle();
    }
  }
}
