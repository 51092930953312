<div class="header-mobile">
  <div
    *ngIf="showBackLabel"
    class="back"
    (click)="emitClose()"
    [class.click-ignore]="ignoreBackClick"
  >
    <i class="icon icon-back"></i>
    <span class="label">Voltar</span>
  </div>

  <div class="title click-ignore">
    <img *ngIf="img" [src]="img" class="icon" />
    <span *ngIf="label" class="label">{{ label }}</span>
  </div>
  <div class="close">
    <img
      src="assets/editor-v3/images/close.svg"
      class="icon"
      (click)="emitClose()"
    />
  </div>
</div>
<div class="body"></div>
