import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PageModel, pageUnitEnum } from '@trakto/models';

export interface IUnitOptions {
  label: string;
  value: pageUnitEnum;
}

export interface IChangeDimensions {
  property: string;
  value: string;
}

export enum EnumOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

@Component({
  selector: 'trakto-inputs-settings',
  templateUrl: './inputs-settings.component.html',
  styleUrls: ['./inputs-settings.component.scss'],
})
export class InputsSettingsComponent implements OnInit, OnChanges {
  @Input() width: number;
  @Input() height: number;
  @Input() pageSelected: PageModel;

  @Output() onChangeDimensions: EventEmitter<IChangeDimensions> =
    new EventEmitter<IChangeDimensions>();

  @Output() onTogglePageProportion: EventEmitter<void> =
    new EventEmitter<void>();

  @Output() onInvertDimensions: EventEmitter<void> = new EventEmitter<void>();

  @Output() onChangeUnit: EventEmitter<IUnitOptions> =
    new EventEmitter<IUnitOptions>();

  orientations = EnumOrientation;
  orientation: EnumOrientation | null;

  unitOptions: IUnitOptions[] = [
    {
      label: 'pixel',
      value: pageUnitEnum.px,
    },
    {
      label: 'centimeter',
      value: pageUnitEnum.cm,
    },
    {
      label: 'millimeter',
      value: pageUnitEnum.mm,
    },
    {
      label: 'inch',
      value: pageUnitEnum.inch,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.defineOrientation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.width !== undefined || changes.height !== undefined) {
      this.defineOrientation();
    }
  }

  defineOrientation() {
    const { width, height } = this.pageSelected;

    if (width > height) this.orientation = EnumOrientation.HORIZONTAL;
    if (width < height) this.orientation = EnumOrientation.VERTICAL;
    if (width === height) this.orientation = null;
  }

  toggleLock() {
    this.onTogglePageProportion.emit();
  }

  changeDimensions(value: string, property: string) {
    this.onChangeDimensions.emit({ property, value });
  }

  invertDimensions(orientation: EnumOrientation) {
    if (this.orientation && orientation !== this.orientation) {
      this.onInvertDimensions.emit();
    }
  }

  changeUnit(option: IUnitOptions) {
    this.onChangeUnit.emit(option);
  }
}
