import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import Cohere from 'cohere-js';
import { debug } from 'console';

@Injectable({
  providedIn: 'root',
})
export class CohereSupportService {
  constructor(private _apiKey: string) {
    this._startApi(this._apiKey);
  }

  private _startApi(apiKey: string) {
    try {
      if (environment.externalIntegrations.cohere) {
        Cohere.init(apiKey);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public identifyUser(user: {
    id: string;
    displayName: string;
    email: string;
  }) {
    try {
      Cohere.identify(user.id, {
        displayName: user.displayName || 'Anonymous',
        email: user.email,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public startAndIdentifyUser(
    user: {
      id: string;
      displayName: string;
      email: string;
    },
    apiKey: string,
  ) {
    try {
      this._startApi(apiKey);
      this.identifyUser(user);
    } catch (error) {
      console.error(error);
    }
  }
}
