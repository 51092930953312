import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';

import {
  LangChangeEvent,
  TranslateService,
} from '../../../../node_modules/@ngx-translate/core';
import { map, takeUntil } from '../../../../node_modules/rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocaleService implements OnDestroy{
  private _locales: any[];
  private _translations: any;
  private _currentLang: string;
  private _availableLanguages = ['pt-BR', 'de', 'en-US', 'es', 'ko'];
  private _destroy$ = new Subject<void>();
  /**
   * get and set translations
   */
  public set translations(value: any) {
    this._translations = value;
  }
  public get translations(): any {
    return this._translations;
  }

  constructor(
    private _database: AngularFirestore,
    private _translateService: TranslateService,
  ) {
    this.translations =
      this._translateService.translations[this._translateService.currentLang];

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this.translations = event.translations;
      this._currentLang = event.lang;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * get currentLang
   */
  public get currentLang(): string {
    return this._currentLang || this._translateService.currentLang;
  }

  init(initialLocale = environment.defaultLocale) {
    if (localStorage.getItem('trakto-lang')) {
      localStorage.removeItem('trakto-lang');
    }
    const locale = this._hasLocale(initialLocale)
      ? initialLocale
      : environment.defaultLocale;
    this.setApplicationLocale(locale);
  }

  getLocaleFromUser(user: any) {
    if (user.current_profile) {
      return user.current_profile.current_locale || environment.defaultLocale;
    }
    return (
      user.current_locale[`${Object.keys(user.current_locale)}`] ||
      environment.defaultLocale
    );
  }

  setApplicationLocale(isoLang) {
    localStorage.setItem('trakto-lang', isoLang);
    this._translateService.use(isoLang);
  }

  getLocaleObject(isoLang: string) {
    return this.getLocalesProvider([isoLang], this._locales)[0];
  }

  /**
   * Get all available locales of product
   * @param product Objetc of Product from database
   * @returns Array of isoLang locales
   */
  async getAvailableLocales(product: any): Promise<any[]> {
    try {
      if (!this._locales) {
        this._locales = await this._database
          .collection('locale')
          .get()
          .pipe(map(result => result.docs.map(doc => doc.data())))
          .toPromise();
      }

      if (product && product.locale && product.locale.available) {
        return this.getLocalesProvider(product.locale.available, this._locales);
      }

      const appDoc = await product.app_reference.get();
      const appData = appDoc.data();

      const availableLocales =
        appData.locale && appData.locale.available
          ? appData.locale.available
          : [environment.defaultLocale];

      return this.getLocalesProvider(availableLocales, this._locales);
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  getLocalesProvider(availableLocalesApp, localesCollection) {
    if (availableLocalesApp && localesCollection) {
      return localesCollection.filter(locale =>
        availableLocalesApp.includes(locale.iso_lang),
      );
    }
  }

  private _hasLocale(locale: string): boolean {
    return this._availableLanguages.some(available => available === locale);
  }
}
