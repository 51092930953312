<div [class.--custom-modal]="customModal" class="trakto-modal-menu">
  <div class="trakto-modal-menu__header">
    <ng-content></ng-content>
  </div>

  <div class="trakto-modal-menu__overflow">
    <div>
      <ng-container *ngIf="hasSection">
        <div
          class="trakto-modal-menu__section"
          *ngFor="let section of dataProvider"
        >
          <h1 class="trakto-modal-menu__name" *ngIf="section.name">
            {{ section.name }} ppp
          </h1>

          <trakto-modal-menu-item
            *ngFor="let item of section.items; let i = index"
            [value]="item"
            [active]="item.value === selected"
            [customModal]="customModal"
            [itemIndex]="i"
            (click)="onSelectItem(item)"
          >
          </trakto-modal-menu-item>
          <hr class="trakto-modal-menu__separator" />
        </div>
      </ng-container>

      <ng-container *ngIf="!hasSection">
        <trakto-modal-menu-item
          *ngFor="let item of dataProvider"
          [value]="item"
          [active]="item.value === selected"
          (click)="onSelectItem(item)"
        ></trakto-modal-menu-item>
      </ng-container>
    </div>
  </div>
</div>
