<div id="tkt-loader">
  <div class="tkt-loader__container">
    <div class="tkt-loader__loader">
      <svg *ngIf="b2c" class="tkt-loader__brand" viewBox="18 1210 30 33.037">
        <g transform="translate(18.143 1209.905)">
          <g transform="translate(-0.143 0.095)">
            <path
              fill="#7651FF"
              d="M21.93,8.139c-1.407.067-2.881.134-4.355.134a.757.757,0,0,1-.737-1.005c.536-1.608,1.474-4.556,1.742-5.36A1.339,1.339,0,0,0,17.374.1c-1.206-.067-2.546.8-3.752,1.474A16.175,16.175,0,0,1,9.6,2.845a1.054,1.054,0,0,0-.737.67c-.2.536-.938,3.015-1.139,3.685a1.008,1.008,0,0,1-.938.67c-.938-.067-4.623-.536-5.092-.536a1.069,1.069,0,0,0-.938.536,10.58,10.58,0,0,0-.871,4.556,1.006,1.006,0,0,0,.737.8c.4.067,3.685.536,4.02.6a.9.9,0,0,1,.737,1.139C2.7,25.9,3.1,33.132,12.684,33.132a11.909,11.909,0,0,0,8.041-3.551,6.587,6.587,0,0,1-2.01-2.479,7.2,7.2,0,0,1-3.819,1.943c-4.355.67-2.68-7.17-.4-14.875a.726.726,0,0,1,.737-.536,37.9,37.9,0,0,0,6.03-.469.852.852,0,0,0,.6-.469,13.738,13.738,0,0,0,.871-3.886A.649.649,0,0,0,21.93,8.139Z"
              transform="translate(0.143 -0.095)"
            ></path>
          </g>

          <g transform="translate(20.168 19.203)">
            <path
              fill="#F14E77"
              d="M36.823,28.939a4.845,4.845,0,0,1-1.34,9.314h0a5.393,5.393,0,0,1-2.278-.335,4.916,4.916,0,0,1-2.881-5.762h0c.067-.2.134-.335.2-.536A4.894,4.894,0,0,1,36.823,28.939Z"
              transform="translate(-30.17 -28.612)"
            ></path>
          </g>
        </g>
      </svg>

      <svg class="tkt-loader__circular" viewBox="25 25 50 50">
        <circle
          class="tkt-loader__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        ></circle>
      </svg>
    </div>
  </div>
</div>
