<p *ngIf="label" class="label">{{label}}</p>

<div class="input-container">
  <i [class]="'icon icon-' + icon" [ngStyle]="{color: iconColor}"
     (click)="value?.length > 1 ? onSelect.emit(value): null"
  ></i>
  <input
    type="text"
    [value]="value"
    (keyup)="changeInputValue($event)"
    (focus)="emitFocus()"
    (blur)="emitBlur()"
    (keydown.enter)="value?.length > 1 ? onSelect.emit(value): null"
    [placeholder]="placeholder"
  />
  <img
    *ngIf="clearIcon && value.length > 0"
    src="assets/editor-v3/images/close.svg"
    class="clear-icon"
    (click)="clear()"
  />
</div>
