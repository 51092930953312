<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<div class="auto-scale-help__header">
  <img src="/assets/images/menu-inline/auto-ajuste.gif" alt="Auto adjust animation">
</div>

<div class="auto-scale-help__body">
  <h1>
    {{ 'text-menu-inline.help-modal.title' | translate }}
    <span>{{ 'text-menu-inline.help-modal.new' | translate }}</span>
  </h1>
  <p>
    {{ 'text-menu-inline.help-modal.description' | translate }}
  </p>
  <span
    class="auto-scale-help__body__cool-button"
    (click)="close()"
  >
    {{ 'text-menu-inline.help-modal.ok' | translate }}
  </span>
</div>
