import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { PageModel } from '@trakto/models';
import { DragDropService } from '../services/drag-drop.service';

@Directive({
  selector: '[traktoDragDrop]',
})
export class DragDropDirective implements AfterViewInit, OnDestroy {
  @Input() traktoDragDrop: PageModel = null;

  constructor(
    private _page: ElementRef,
    private _dragDropService: DragDropService,
  ) {
    this.init();
  }

  init() {
    this.setDraggable();
  }

  ngAfterViewInit(): void {
    this._dragDropService.storePage(
      this._page.nativeElement,
      this.traktoDragDrop,
    );
  }

  ngOnDestroy(): void {
    this._dragDropService.removePage(this.traktoDragDrop);
  }

  setDraggable() {
    this._page.nativeElement.draggable = true;
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(e: DragEvent) {
    const el = e.target as HTMLElement;
    this._dragDropService.setDraggedPage(el);
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(e: DragEvent) {
    e.preventDefault();
    this._dragDropService.hoverEffect(
      e.target as HTMLElement,
      this.traktoDragDrop,
    );
  }

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent) {
    e.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(e: DragEvent) {
    e.preventDefault();
  }

  @HostListener('dragend', [])
  onDragEnd() {
    this._dragDropService.clearAllEffects();
    this._dragDropService.clearDraggedPage();
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    this._dragDropService.handlePagePosition(e);
    this._dragDropService.clearDraggedPage();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e: TouchEvent) {
    this._dragDropService.handleTouchStart(e);
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e: TouchEvent) {
    if (e.cancelable) e.preventDefault();
    this._dragDropService.handleTouchMove(e);
  }

  @HostListener('touchend', [])
  onTouchEnd() {
    this._dragDropService.handleTouchEnd();
  }
}
