import { Injectable, OnDestroy } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  selectClickedElement,
  selectEditorState,
  selectPresentEditorState,
  selectSelectedDocument,
  selectSelectedElement,
  selectSelectedPage
} from '@app/state/document.selector';
import {
  addElement,
  addPage,
  clickElementNoTrackable,
  deleteAndAddElements,
  deleteElement,
  deletePage,
  highlightElement,
  moveElementDown,
  moveElementUp,
  movePageDown,
  movePageUp,
  persistDocumentChangesNoTrackable,
  persistElementsChanges,
  persistElementsChangesNotTrackable,
  persistPageChanges,
  persistPageChangesNoTrackable,
  selectDocument,
  selectElementNoTrackable,
  selectPageNoTrackable
} from '@app/state/document.actions';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreators } from 'redux-undo';
import {
  IDocumentModel,
  IElementModel,
  ImageElementModel,
  PageModel
} from '@trakto/models';
import { copyObject } from '@app/state/state.util';
import { HistoryState } from '@app/state/document.reducer';
import { MediaService } from '@services/media.service';
import { ImageUtilService } from '@services/image-util.service';
import { ImageElementService } from '@services/image-element.service';
import {
  ImageModel
} from '@trakto/graphics-resources/dist/src/models/image.model';
import { Subject } from 'rxjs';
import {
  ElementModelFinderService
} from '@services/element-model-finder.service';
import { PageService } from '@services/page.service';
import { ZoomService as ZoomServiceV2 } from '@app/editor/services/zoom.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentStateManagerService {

  private _workingPage: PageModel = null;

  public document$ = this._store.select(selectSelectedDocument).pipe(filter(doc => !!doc), map(doc => ({...doc, updated_at: new Date(doc.updated_at)})));
  public documentSnapshot$ = this._store.select(selectSelectedDocument).pipe(take(1), map(doc => ({...doc, updated_at: new Date(doc.updated_at)})));
  public page$ = this._store.select(selectSelectedPage).pipe(filter(page => !!page), map(() => this._workingPage));
  public pageSnapshot$ = this.page$.pipe(filter(page => !!page), take(1));
  public element$ = this._store.select(selectSelectedElement).pipe(map(selected => this._workingPage?.elements.find(el => el.id === selected?.id) || selected));
  public elementSnapshot$ = this.element$.pipe(take(1));
  public clickedElement$ = this._store.select(selectClickedElement).pipe(map(selected => this._elementFinderService.getAllElements(this._workingPage?.elements || []).find(el => el.id === selected?.id) || selected));

  public change$ = this._actions.pipe(
    ofType(
      addElement,
      deleteElement,
      addPage,
      deletePage,
      persistElementsChanges,
      persistElementsChangesNotTrackable,
      persistPageChanges,
      moveElementUp,
      moveElementDown,
      deleteAndAddElements,
    ),
    switchMap(() => this._store.select(selectSelectedDocument).pipe(take(1)))
  );

  public undoAndRedo$ = this._actions.pipe(
    ofType(ActionCreators.undo().type, ActionCreators.redo().type),
    switchMap(() => this._store.select(selectPresentEditorState).pipe(take(1)))
  );

  public allChanges$= this._store.select(selectEditorState);

  private _destroy$ = new Subject<void>();

  constructor(
    private _store: Store,
    private _actions: Actions,
    private _mediaService: MediaService,
    private _imageUtilService: ImageUtilService,
    private _imageElementService: ImageElementService,
    private _elementFinderService: ElementModelFinderService,
    private _pageService: PageService,
    private _zoomService: ZoomServiceV2,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  selectDocument(document: IDocumentModel) {
    this._store.dispatch(selectDocument({ document: copyObject(document) }));
  }

  selectPage(page: PageModel) {
    if (page.id !== this._workingPage?.id) {
      this._workingPage = copyObject(page);
    }
    this._store.dispatch(selectPageNoTrackable({ pageId: page.id }));
    this._zoomService.fitZoom(page);
  }

  selectElement(element: IElementModel) {
    this._store.dispatch(selectElementNoTrackable({ element: copyObject(element) }));
  }

  clickElement(element: IElementModel) {
    this._store.dispatch(clickElementNoTrackable({ element: copyObject(element) }));
  }

  setHighlightedElement(element: any) {
    this._store.dispatch(highlightElement({ element: copyObject(element) }));
  }

  addPage(page: PageModel, toIndex?: number) {
    this._workingPage = copyObject(page);
    this._store.dispatch(addPage({ page, toIndex }));
    this._zoomService.fitZoom(page);
  }

  async deletePage(page: PageModel) {
    const document = await this.documentSnapshot$.toPromise();
    if (page.id === this._workingPage.id) {
      const oldPageIndex = document.body.findIndex(p => p.id === page.id);
      const newWorkingPageIndex = Math.max(oldPageIndex > 0 ? oldPageIndex - 1 : 1, 0);
      this._workingPage = copyObject(document.body[newWorkingPageIndex]);
    }
    this._store.dispatch(deletePage({ pageId: page?.id }));
  }

  movePageUp(page: PageModel) {
    this._store.dispatch(movePageUp({ pageId: page.id }));
  }

  movePageDown(page: PageModel) {
    this._store.dispatch(movePageDown({ pageId: page.id }));
  }

  persistDocumentChangesNoTrackable(document: IDocumentModel) {
    this._store.dispatch(persistDocumentChangesNoTrackable({ document }));
  }

  persistPageChanges(page: PageModel) {
    this._persistPageChange(page);
    this._store.dispatch(persistPageChanges({ page }));
  }

  persistPageChangesNoTrackable(page: PageModel) {
    this._persistPageChange(page);
    this._store.dispatch(persistPageChangesNoTrackable({ page }));
  }

  addElement(page: PageModel, element: IElementModel) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => this._pageService.addNewElement(target, copyObject(element)),
    );
    this._store.dispatch(addElement({ element, pageId: page.id }));
  }

  deleteElement(page: PageModel, element: IElementModel) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => {
        this._elementFinderService.getAllElements([element], true)
          .filter(el => page.elements.find(el2 => el2.id === el.id))
          .forEach(el => this._pageService.deleteElement(target, copyObject(el)));
      },
    );
    this._store.dispatch(deleteElement({ pageId: page?.id, element: copyObject(element) }));
  }

  deleteAndAddElements(page: PageModel, toDelete: IElementModel[], toAdd: IElementModel[]) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => {
        toDelete.forEach(el => this._pageService.deleteElement(target, copyObject(el)));
        toAdd.forEach(el => this._pageService.addNewElement(target, copyObject(el)));
      },
    );
    this._store.dispatch(deleteAndAddElements({ pageId: page?.id, toDelete, toAdd }));
  }

  persistElementChanges(page: PageModel, elements: IElementModel[]) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => {
        elements.forEach(el => this._persistElementChange(target, el));
      },
    );
    this._store.dispatch(persistElementsChanges({ elements: copyObject(elements), pageId: page.id }));
  }

  persistElementChangesNoTrackable(page: PageModel, elements: IElementModel[]) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => {
        elements.forEach(el => this._persistElementChange(target, el));
      },
    );
    this._store.dispatch(persistElementsChangesNotTrackable({ elements: copyObject(elements), pageId: page.id }));
  }

  moveElementUp(page: PageModel, element: IElementModel) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => this._pageService.moveElementUp(target, element),
    );
    this._store.dispatch(moveElementUp({ pageId: page.id, element: copyObject(element) }));
  }

  moveElementDown(page: PageModel, element: IElementModel) {
    this._applyWorkingPageChange(
      page,
      (target: PageModel) => this._pageService.moveElementDown(target, element),
    );
    this._store.dispatch(moveElementDown({ pageId: page.id, element: copyObject(element) }));
  }

  undo() {
    this._store.select(selectEditorState).pipe(
      take(1),
      tap((r: HistoryState) => {
        const pageId = this._workingPage?.id;
        if (r?.past?.length > 1) {
          const editorState = r?.past[r?.past.length - 1];
          this._workingPage = copyObject(editorState.document.body.find(page => page.id === pageId) || editorState.document.body[0]);
          this._store.dispatch(ActionCreators.undo());
          if (r?.past?.length == 2) {
            this._store.dispatch(selectPageNoTrackable({ pageId: r?.past[1].document.body[0].id }));
          }
          this.selectPage(editorState.document.body.find(page => page.id === pageId) || editorState.document.body[0]);

        }
      })
    ).pipe(takeUntil(this._destroy$)).subscribe();
  }

  redo() {
    this._store.select(selectEditorState).pipe(
      take(1),
      tap((r: HistoryState) => {
          const pageId = this._workingPage.id;
          if (r?.future?.length > 0) {
            const editor = r?.future[0];
            const toSelect  = editor.document.body.find(page => page.id === pageId) || editor.document.body[0];
            this._workingPage = copyObject(toSelect);
            this._store.dispatch(ActionCreators.redo());
            this.selectPage(toSelect);
          }
        }
      )
    ).subscribe();
  }

  async changeImageUrl(page: PageModel, image: ImageElementModel, url: string): Promise<ImageElementModel> {
    const urls = await this._mediaService.uploadImageByUrl(url);
    return this._changeImageUrls(page, image, urls);
  }

  async changeImageUrlByFile(page: PageModel, image: ImageElementModel, file: File): Promise<ImageElementModel> {
    const urls = await this._mediaService.uploadImageByFile(file);
    return this._changeImageUrls(page, image, urls);
  }

  private async _changeImageUrls(page: PageModel, image: ImageElementModel, urls: ImageModel): Promise<ImageElementModel> {
    const dimension = await this._imageUtilService.getImageDimension(urls.resolutions.medium.secure_url).toPromise();
    const updatedDocument = await this.document$.pipe(take(1)).toPromise();
    const updatedPage: PageModel = this._workingPage.id === page.id ? this._workingPage : updatedDocument.body.find(p => p.id === page.id);
    const foundImage: ImageElementModel = updatedPage.elements.find(e => e.id === image.id) as ImageElementModel;
    const imageClone: ImageElementModel = { ...foundImage, submask: { ...foundImage.submask } };
    await this._imageElementService.changeImageUrlByResolutions(imageClone, urls.resolutions, false, dimension);
    imageClone.loading = false;
    this.persistElementChangesNoTrackable(page, [imageClone]);
    return imageClone;
  }

  private _persistPageChange(page: PageModel): boolean {
    delete page.elements;
    if (!!this._workingPage && page?.id === this._workingPage?.id) {
      Object.assign(this._workingPage, copyObject(page));
      return true;
    }
    return false;
  }

  private _applyWorkingPageChange(page: PageModel, callback: (target: PageModel) => void) {
    if (!!this._workingPage && page?.id === this._workingPage?.id) {
      callback(this._workingPage);
    }
  }

  private _persistElementChange(page: PageModel, el: IElementModel) {
    const targetEl = this._elementFinderService.getAllElements(page.elements, true).find(targetEl => el.id === targetEl.id);
    if (targetEl && el) {
      Object.assign(targetEl, copyObject(el));
    }
  }
}
