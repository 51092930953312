import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'trakto-grid-layout',
  templateUrl: './grid-layout.component.html',
  styleUrls: ['./grid-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridLayoutComponent implements OnChanges {
  @ViewChild('gridLayoutContainer', { static: true })
  gridLayoutContainer: ElementRef;
  @ViewChild('gridLayout', { static: true }) gridLayout: ElementRef;

  private _gap: number;
  private _columns: number;

  @Input()
  set gap(value: number) {
    this._gap = value;
  }

  get gap(): number {
    return this._gap || 20;
  }

  @Input()
  set columns(value: number) {
    this._columns = value;
  }

  get columns(): number {
    return this._columns || 4;
  }

  public render(): any {
    return this.gridLayoutContainer.nativeElement;
  }

  ngOnChanges() {
    let item = 0;
    let columns = '';

    while (item < this.columns) {
      columns += '1fr ';
      item++;
    }

    this.gridLayout.nativeElement.setAttribute(
      'style',
      `grid-gap: ${this.gap}px; grid-template-columns: ${columns}`,
    );
  }
}
