import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorService } from './../../services/color.service';

import { ColorModel } from '@trakto/core-editor';
import { ItemRenderer } from '../../../renderers/item-renderer.model';
import { GradientSafePipe } from '../../pipes/gradient-safe.pipe';

@Component({
  selector: 'trakto-color-item-renderer',
  templateUrl: './color-item-renderer.component.html',
  styleUrls: ['./color-item-renderer.component.scss'],
})
export class ColorItemRendererComponent {
  classes: string[] = [];
  selectedIconColor: string;

  @Input()
  removable: boolean;

  @Output()
  onSelect: EventEmitter<ItemRenderer> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<ItemRenderer> = new EventEmitter();

  private _item: ItemRenderer;
  private _defaultSize: number;
  private _internalItem: ItemRenderer;
  private _selected: boolean;

  @Input()
  set item(value: ItemRenderer) {
    this.internalItem = Object.assign({}, value);
    this._item = value;
  }

  get item(): ItemRenderer {
    return this._item;
  }

  @Input()
  set defaultSize(value: number) {
    this._defaultSize = value;
  }

  get defaultSize(): number {
    return this._defaultSize || 44;
  }

  @Input()
  set selected(value: boolean) {
    this._selected = value;
    if (this._selected) {
      this.selectedIconColor = this._colorService.getContrastColor(
        new ColorModel(null, this._item.value as string),
      );
    }
  }

  get selected(): boolean {
    return this._selected;
  }

  set internalItem(value: ItemRenderer) {
    if (value.type === 'gradient') {
      value.value = this._gradientSafePipe.transform(value.value as string);
    }

    this._internalItem = value;
    this._renderClasses();
  }

  get internalItem(): ItemRenderer {
    return this._internalItem;
  }

  constructor(
    private _gradientSafePipe: GradientSafePipe,
    private _colorService: ColorService,
  ) {}

  select() {
    this.onSelect.emit(this.item);
  }

  remove() {
    this.onRemove.emit(this.item);
  }

  private _renderClasses() {
    if (this.internalItem.type === 'color' && !this.internalItem.value) {
      this.classes.push('trakto-color-item-renderer--null');
    }

    if (this.internalItem.type === 'color-slot') {
      this.classes.push('trakto-color-item-renderer--slot');
    }
  }
}
