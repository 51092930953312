import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { ElementModelGroupEngine, weightList } from '@trakto/core-editor';

import { fontSizeScaleModeEnum, ITextElementModel } from '@trakto/models';

import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { FontsService } from '@services/fonts.service';
import { TextElementService } from '@services/text-element.service';
import { TextEditorService } from '@services/text-editor.service';
import { TextService } from '@services/text.service';
import { ElementModelService } from '@services/element-model.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { copyObject } from '@app/state/state.util';

@Directive({
  selector: '[traktoSvgTextEditor]',
})
export class SvgTextEditorDirective
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  private textElement: ITextElementModel;

  @Input()
  private selection: ElementModelGroupEngine;

  @Output()
  textUpdate: EventEmitter<boolean> = new EventEmitter();

  @Input()
  private ref: any;

  fontList = undefined;

  weightList = weightList;

  @HostBinding('style.border')
  border = 0;

  @HostBinding('style.opacity')
  opacity: number;

  @HostBinding('style.color')
  color: string;

  @HostBinding('style.background-color')
  backgroundColor: string;

  @HostBinding('style.text-align')
  textAlign = 'left';

  @HostBinding('attr.tabindex')
  tabIndex = 0;

  @HostBinding('attr.spellcheck')
  spellCheck = false;

  @HostBinding('style.--bullet-color')
  bulletColor: string;

  private _quill: any;

  private shortCuts = [
    66,
    98, // ctrl+B or ctrl+b
    73,
    105, // ctrl+I or ctrl+i
    85,
    117, // ctrl+U or ctrl+u
  ];
  private _destroy$ = new Subject<void>();
  private _previousTextElement: ITextElementModel;

  constructor(
    private _elementRef: ElementRef,
    private _elementModelService: ElementModelService,
    private _newTextElementService: TextElementService,
    private _textEditorService: TextEditorService,
    private _textService: TextService,
    private _hotkeyService: HotkeyService,
    private _fontsService: FontsService,
  ) {}

  ngOnInit() {
    this._fontsService
      .listTraktoFontsFamilies()
      .pipe(takeUntil(this._destroy$))
      .subscribe((familyNames: string[]) => (this.fontList = familyNames));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private _hasFormatChange() {
    if(!this.textElement || !this._previousTextElement || this.textElement.id !== this._previousTextElement.id) {
      return false;
    }
    const families = this._textService.getFontFamiliesByElement(this.textElement);
    const previousFamilies = this._textService.getFontFamiliesByElement(this._previousTextElement);
    const deltas = this.textElement.textQuillEncoded;
    const previousDeltas = this._previousTextElement.textQuillEncoded;
    return families.length !== previousFamilies.length
      || families.some(family => !previousFamilies.includes(family))
      || deltas.length !== previousDeltas.length
      || deltas.some((delta, i) => !(previousDeltas[i]?.attributes.italic === delta.attributes.italic && previousDeltas[i]?.attributes.weight === delta.attributes.weight && previousDeltas[i]?.attributes.color === delta.attributes.color && previousDeltas[i]?.attributes.underline === delta.attributes.underline))
      || this.textElement.lineSpace != this._previousTextElement.lineSpace
      || this.textElement.align != this._previousTextElement.align
      || this.textElement.color != this._previousTextElement.color;
  }

  ngOnChanges(changes) {
    if (changes.selection && !this._hasFormatChange()) {
      return;
    }
    if (!this._quill) {
      return;
    }

    if (this.textElement) {
      this._previousTextElement = copyObject(this.textElement);
      this.bulletColor = this.textElement.color;
      this._quill.setContents(this.textElement.textQuillEncoded);
      this._textEditorService.configureSelection(
        this.textElement,
        this._quill.getSelection(),
      );
      this._applyFontSize();
    } else if (changes.textElement) {
      this._previousTextElement = null;
      this._quill.setContents(
        this._newTextElementService.createQuillText(undefined, ''),
      );
      this._textEditorService.configureSelection();
    }
  }

  ngAfterViewInit() {
    if (!this._quill) {
      this.initQuill();
    }
  }

  @HostListener('keydown', ['$event'])
  keydown($event) {
    if (
      this._hotkeyService.CMD_KEY($event) &&
      this.shortCuts.indexOf($event.keyCode) !== -1
    ) {
      setTimeout(() => {
        this._updateBoxContainerDimension(false);
        this.textUpdate.emit(true);
      }, 0);
    }
    return true;
  }

  private initQuill() {
    const Size = globalThis.Quill.import('attributors/style/size');
    const FontFamily = globalThis.Quill.import('attributors/style/font');
    const Delta = globalThis.Quill.import('delta');

    const toolbarOptions = [
      [{ size: Size.whitelist }, { font: FontFamily.whitelist }],
    ];
    // Configs do Quill
    const textEditorConf = {
      debug: 'error',
      modules: {
        toolbar: toolbarOptions,
      },
      scrollingContainer: `#${this._elementRef.nativeElement.parentNode.id}`,
      bounds: `#${this._elementRef.nativeElement.parentNode.id} `,
      readOnly: false,
      theme: 'snow',
    };

    this._quill = new globalThis.Quill(
      `#${this._elementRef.nativeElement.id} `,
      textEditorConf,
    );
    delete this._quill.keyboard.bindings[' '];
    delete this._quill.keyboard.bindings['b'];
    delete this._quill.keyboard.bindings['i'];
    delete this._quill.keyboard.bindings['u'];

    this._configClipboard(Delta);
    this._configTextChange();
    this._configSelectionChange();
  }

  private _configClipboard(Delta) {
    this._quill.clipboard.onPaste = (range, pastedText) => {
      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(pastedText.text);
      const index = pastedText.text.length + range.index;
      const length = 0;
      this._quill.updateContents(delta);
      this._textService.updateTextEncoded(
        this.textElement,
        this._quill,
        range.index,
        index,
      );
      this._quill.setSelection(index, length);
      this._quill.scrollIntoView();
      this._updateBoxContainerDimension(true);
      this._normalizeFontSize();
      this._applyFontSize();
      this.textUpdate.emit(true);
    };
  }

  private _configTextChange() {
    this._quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
      if (!this.textElement) {
        return;
      }

      if (!this._quill.hasFocus()) {
        this._quill.focus();
      }

      const hasAttributes = delta.ops
        .filter(op => op.insert && op.insert !== '\n')
        .every(
          op =>
            op.attributes && op.attributes['font'] && op.attributes['color'],
        );

      const hasInsert = delta.ops.some(op => op.insert);
      const hasDelete = delta.ops.some(op => op.delete);

      if (!hasAttributes && source === 'user') {
        this._textService.updateTextEncoded(this.textElement, this._quill);
      }

      if ((hasInsert || hasDelete) && source === 'user') {
        this._updateBoxContainerDimension(hasInsert);
        this._normalizeFontSize();
      }
      this._applyFontSize();

      const selectionElements = this.selection.getRawElementModels();
      if (selectionElements.length === 1 && this._elementModelService.isGroup(selectionElements[0])) {
        this._elementModelService.updateGroup(selectionElements[0]);
      }
      this.textUpdate.emit(true);
      this._previousTextElement = copyObject(this.textElement);
    });
  }

  private _configSelectionChange() {
    this._quill.on('selection-change', range => {
      if (range) {
        this._textEditorService.configureSelection(this.textElement, range);
      }
    });
  }

  private _applyFontSize(): void {
    if (!this.textElement) {
      return;
    }
    this._textService.applyHtmlTextChanges(this._quill.root, this.textElement);
  }

  private _updateBoxContainerDimension(insert: boolean): void {
    this.textElement.text = this._quill.getText();
    this.textElement.textQuillEncoded = this._quill.getContents().ops;

    if ((this.textElement.fontSize < 10 && insert)
      || this.textElement.fontSizeScaleMode === fontSizeScaleModeEnum.manual) {
      this._newTextElementService.fitTextDimension(this.textElement);
      return;
    }

    if (this.canDecrease() && insert) {
      this.textElement.fontSize -= this._getFontSizeDelta();
      this._updateBoxContainerDimension(insert);
    } else if (this.canIncrease() && !insert) {
      this.textElement.fontSize += this._getFontSizeDelta();
      this._updateBoxContainerDimension(insert);
    }
  }

  private canIncrease() {
    const cloned = Object.assign({}, this.textElement);
    const lineHeight = this._textService.getLineInfo(cloned).lineHeight;
    const textHeight = this._textService.getBoxHeight(cloned, false);
    return cloned.supportHeight - textHeight >= lineHeight;
  }

  private canDecrease() {
    const cloned = Object.assign({}, this.textElement);
    const textHeight = this._textService.getBoxHeight(cloned, false);
    return cloned.supportHeight < textHeight;
  }

  private _getFontSizeDelta() {
    return this.textElement.fontSize / 100;
  }

  private _normalizeFontSize() {
    this.textElement.fontSize =
      +(this.textElement.fontSize * this.textElement.scaleY).toFixed(2) /
      this.textElement.scaleY;
  }
}
