import { SafeStyle } from '@angular/platform-browser';
import { ColorModel } from '@trakto/core-editor';

export class NgColorModel extends ColorModel {
  gradientSafe: SafeStyle;

  constructor(rgba?: string, hexadecimal?: string) {
    super(rgba, hexadecimal);
  }
}
