import { Injectable } from '@angular/core';
import { UtilMagicTemplateService } from '@shared/magic-template/shared/util-magic-template.service';
import { MediaService } from '@services/media.service';
import { GifMagicTemplateGenerator } from '@trakto/magic-templates';

@Injectable()
export class GifMagicTemplateService extends GifMagicTemplateGenerator {
  constructor(
    utilMagicTemplateService: UtilMagicTemplateService,
    mediaService: MediaService,
  ) {
    super (utilMagicTemplateService, mediaService);
  }

}
