import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { LocaleService } from '@services/locale.service';

@Component({
  selector: 'trakto-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
})
export class LocaleSelectorComponent implements OnInit {
  currentLocale: any;
  dropdownData: any[] = [];

  private _lang: string;

  @Output() onLocaleChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() className: string;
  @Input() availableLocales: any;

  @Input() public set lang(value: string) {
    this._lang = value;
  }

  public get lang(): string {
    return this._lang;
  }

  constructor(private _localeService: LocaleService) {}

  ngOnInit() {
    this._configLocale();
  }

  // FIXME: Melhorar o tratamento de atributos não inicializados para a troca de idioma da aplicação.
  private _configLocale() {
    const localStorageLocale = localStorage.getItem('trakto-lang');
    const initialLang =
      this.lang || localStorageLocale || environment.defaultLocale;

    this.currentLocale = this.availableLocales.filter(
      item => item.iso_lang === initialLang,
    )[0];

    this.dropdownData = this.availableLocales.map(item => ({
      text: item.title,
      value: item.iso_lang,
      selected: item.iso_lang === this.currentLocale.iso_lang,
    }));

    localStorage.setItem('trakto-lang', this.currentLocale.iso_lang);
    this.handleLocaleChange({ value: this.currentLocale.iso_lang });
  }

  //
  handleLocaleChange(event: any) {
    this.onLocaleChange.emit(event.value);
    // try {
    //   this.currentLocale = this.availableLocales.filter(item => item.iso_lang === event.value)[0];
    //   this._localeService.setApplicationLocale(this.currentLocale);
    // } catch (error) {
    //   console.log(error);
    // }
  }
}
