import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IDocumentItem, IDocumentItemEvent } from './document-item.model';

@Component({
  selector: 'trakto-document-item-renderer',
  templateUrl: './document-item-renderer.component.html',
  styleUrls: ['./document-item-renderer.component.scss'],
})
export class DocumentItemRendererComponent {
  public editMode: boolean;

  private _editInput: ElementRef;

  private _item: IDocumentItem;

  @Input()
  set item(value: IDocumentItem) {
    this._item = value;
  }

  get item(): IDocumentItem {
    return this._item;
  }

  @ViewChild('editInput')
  set editInput(value: ElementRef) {
    this._editInput = value;

    if (value) {
      this._setEditInputFocus();
    }
  }

  @Output()
  public onAction: EventEmitter<IDocumentItemEvent> = new EventEmitter();

  actionHandler(eventName) {
    this.onAction.emit({ type: eventName, item: this.item });
  }

  editTitle() {
    this.editMode = true;
  }

  editModeCompleted(value: string) {
    this.editMode = false;
    this.item.title = value;
    this.actionHandler('edit');
  }

  private _setEditInputFocus() {
    this._editInput.nativeElement.focus();
    Promise.resolve().then(() => this._editInput.nativeElement.select());
  }
}
