<div class="container_panel">
  <div class="document">
    <img
      [src]="traktoLink?.image ? traktoLink?.image  : traktoLink?.seo.thumbUrl"
      alt="">
  </div>
  <div class="info" style="margin-left: 12px;">
    <h3>
      Compartilhe o seu arquivo em formato de link editável
    </h3>
    <p>
      Dessa forma, quem receber seu o link poderá fazer uma cópia do seu design e modificar como quiser!
    </p>

    <div class="input_button">
      <span>
        <label for="subdomain"><i class="trakto-icon-link-drop" style="color: #0095FF; margin-left: 5px; margin-right: 5px;"></i></label>
        <input type="text" placeholder="" id="subdomain" [value]="inputValue" readonly/>
      </span>

      <button (click)="copyLinkToClipboard()" [ngClass]="link.copied === true ? 'copied' : 'copy'">{{link.copied === true ? 'Copiado!' : 'Copiar Documento' }}</button>
    </div>
  </div>
</div>
