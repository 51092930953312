<div id="panel-text-font" class="panel">
  <div *ngIf="showCloseButton" class="panel-text__button-back" (click)="close()">
    <i class="trakto-icon-arrow-right"></i>
  </div>

  <trakto-menu-navbar [menus]="navList" (onSideNavChange)="changePanel($event)">
  </trakto-menu-navbar>

  <section class="flex flex-1 flex-direction-column overflow-hidden">
    <div class="panel-scroll__container">
      <div class="panel-scroll__animate" *ngIf="currentNavPanel == 0">
        <trakto-fonts
          [elementSelected]="elementSelected"
          [pageSelected]="pageSelected"
          (selectedFont)="selectedFont.emit($event)"
        ></trakto-fonts>
      </div>
      <div class="panel-scroll__animate" *ngIf="currentNavPanel == 1">
        <trakto-my-fonts
          [elementSelected]="elementSelected"
          [pageSelected]="pageSelected"
          (selectedFont)="selectedFont.emit($event)"
        ></trakto-my-fonts>
      </div>
      <div class="panel-scroll__animate" *ngIf="currentNavPanel == 2">
        <trakto-font-favorite
          [elementSelected]="elementSelected"
          [pageSelected]="pageSelected"
          (selectedFont)="selectedFont.emit($event)"
        ></trakto-font-favorite>
      </div>
    </div>
  </section>
</div>
