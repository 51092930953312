<div
  class="bella-input"
  [ngClass]="{
    '--selected': isInputSelected,
    '--tagged': isInputAlreadyTagged
  }"
>
  <div
    class="bella-input__signal tooltip-main"
    [ngClass]="{ '--true': isInputAlreadyTagged }"
  >
    <span class="tooltip-main__content --right">
      {{ isInputAlreadyTagged ? 'Tag aplicada' : 'Tag pendente' }}
    </span>
  </div>
  <div class="bella-input__info">
    <div class="bella-input__label">
      {{ input.label }}
    </div>
    <div class="bella-input__element">
      Tag do tipo:
      {{ input.properties[0].inputType }}
    </div>
    <div class="bella-input__element">
      Tipos de elementos:
      {{ input.elements.join(', ') }}
    </div>
  </div>
  <div
    class="bella-input__status tooltip-main"
    *ngIf="!isInputAlreadyTagged; else options"
  >
    <i class="trakto-icon-info"></i>
    <span class="tooltip-main__content --left">
      Selecione um elemento (ou clique em "Aplicar tags na página") e vincule
      essa tag
    </span>
  </div>
  <ng-template #options>
    <div class="bella-input__status">
      <i
        class="trakto-icon-trash tooltip-main"
        (click)="clearElementEmitter(input)"
      >
        <span class="tooltip-main__content --left">
          {{
            isDeleteSpecific
              ? 'Remover tag da página/elemento selecionado'
              : 'Remover tag de todos os lugares que ela foi aplicada'
          }}
        </span>
      </i>
    </div>
  </ng-template>
</div>
