export enum EventsTrack {
    authentication_create_account = 'Authentication - create account',
    authentication_signin_success = 'Authentication - login success',
    authentication_signin_fail = 'Authentication - login fail',
    authentication_signup_fail = 'Authentication - signup fail',
    authentication_account_recover = 'Authentication - recovered password started',
    authentication_account_logout = 'Authentication - logout',
  
    design_created = 'Design - created an design',
    design_opened = 'Design - design opened',
    design_duplicated = 'Design - design duplicated',
    design_deleted = 'Design - design deleted',
  
    checkout_feature_blocker_opened = 'Checkout - feature blocker opened',
    checkout_opened = 'Checkout - opened checkout subscription plans',
    checkout_subscription_plan_selected = 'Checkout - subscription plan selected',
    checkout_plan_purchase_success = 'Checkout - plan purchased',
    checkout_plan_purchase_fail = 'Checkout - purchased failed',
    checkout_plan_credit_card_error = 'Checkout - credit card inserted is invalid',
    checkout_plan_monthly_viewed = 'Checkout - Monthly plan was seen',
    checkout_plan_yearly_viewed = 'Checkout - Annual plan was seen',
    checkout_plan_boleto = 'Checkout - Boleto was chosen',
    checkout_credit_card_wizard_opened = 'Checkout - Credit Card Wizard started',
  
    feature_blocked = 'Checkout - feature blocker opened',
  
    download_pdf = 'Editor - PDF Exported',
    download_pdf_error = 'Editor - Error PDF Exported',
    download_png = 'Editor - PNG Exported',
    download_png_error = 'Editor - Error PNG Exported',
    download_video = 'Editor - Video Exported',
    download_video_error = 'Editor - Error Video Exported',
  
    share_document_opened = 'Editor - Share document opened',
    share_document_link_copied = 'Editor - Link from modal share document has copied',
    share_document_link_opened = 'Editor - Link for share document has opened',
  
    text_element_added = 'Editor - Text element added',
    image_element_added = 'Editor - Image element added',
    shape_element_added = 'Editor - Shape element added',
    icon_element_added = 'Editor - Icon element added',
    video_element_added = 'Editor - Video element added',
    gif_element_added = 'Editor - Gif element added',
    sticker_element_added = 'Editor - Sticker element added',
  
    gallery_search_image_triggered = 'Editor - Search image has triggered',
    gallery_search_gif_triggered = 'Editor - Search gif has triggered',
    gallery_search_sticker_triggered = 'Editor - Search sticker has triggered',
    gallery_search_icon_triggered = 'Editor - Search icon has triggered',
    gallery_search_icon_triggered_error = 'Editor - Error on search icon has triggered',
    gallery_search_shape_triggered = 'Editor - Search shapes has triggered',
    gallery_search_shape_triggered_error = 'Editor - Error on search shapes has triggered',
    gallery_search_featured_gif_triggered = 'Editor - Search featured gifs has triggered',
    gallery_search_featured_sticker_triggered = 'Editor - Search featured stickers has triggered',
    gallery_search_featured_icon_triggered = 'Editor - Search featured icons has triggered',
    gallery_search_featured_icon_triggered_error = 'Editor - Error on search featured icons has triggered',
    gallery_image_deleted = 'Editor - User`s image from gallery was deleted',
    gallery_image_deleted_error = 'Editor - Error while image of gallery is deleted',
    image_remove_background_used = 'Editor - Remove image background was used',
    image_remove_background_used_error = 'Editor - Error while the remove image background was used',
  
    document_save_error_warning = 'Error while connect with service for save document',
    document_save_error_fatal = 'Error while save document. The editor is full blocked because max save attempts is reached',
    document_save_error_update_process = 'Error while is starting save or update document',
    document_save_error_document_empty = 'Error while save document because the metadata (body) is empty.',
    document_save_error_max_page_save = 'Add new pages has blocked, the max number of pages is reached.',
    document_save_error_auth_user_not_found = 'Error while save document because the user isnt authenticated',
    document_save_error_not_found_id = 'Error while save document because isnt found in database',
    document_save_error_outdated = 'Error while save document because its outdated',
    document_save_error_integraty = 'Error while save document because the metadata is corrupted',
  
    document_generate_cover_error = 'Error while generate cover',
  }