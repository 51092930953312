import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CallbackComponent } from '@auth/callback/callback.component';
import { LogoutComponent } from '@auth/logout/logout.component';
import { AuthGuard } from '@auth/shared/auth.guard';
import { AuthService } from '@auth/shared/auth.service';
import { CookieGuard } from '@auth/shared/cookie.guard';
import { LoggedGuard } from '@auth/shared/logged.guard';
import { SharedModule } from '../shared/shared.module';
import { AppAuthComponent } from './app-auth/app-auth.component';
import { AppAuthV3ComponentComponent } from './app-auth-v3-component/app-auth-v3-component.component';

@NgModule({
  declarations: [
    CallbackComponent,
    LogoutComponent,
    AppAuthComponent,
    AppAuthV3ComponentComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents: [
  ],
  providers: [
    LoggedGuard,
    AuthService,
    AuthGuard,
    CookieGuard,
  ],
})
export class AuthModule {}
