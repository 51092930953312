import { createReducer, on } from '@ngrx/store';
import { decrementCurrentStep, incrementCurrentStep, setCurrentStep, setTemplateSubType, setTemplateType, setTotalSteps } from './stepper.actions';
import { initialState, defaultTemplateSteps, magicTemplateSteps } from './stepper.state';

export const stepperReducer = createReducer(
  initialState,
  on(setCurrentStep, (state, { step }) => ({
    ...state,
    currentStep: step
  })),
  on(setTemplateType, (state, { templateType }) => ({
    ...state,
    templateType,
    totalSteps: templateType === 'default' ? defaultTemplateSteps : magicTemplateSteps,
  })),
  on(setTemplateSubType, (state, { templateSubType }) => {
    return ({
      ...state,
      templateSubType,
    });
  }),
  on(setTotalSteps, (state, { totalSteps }) => ({ ...state, totalSteps })),
  on(incrementCurrentStep, state => ({ ...state, currentStep: state.currentStep + 1 })),
  on(decrementCurrentStep, state => ({ ...state, currentStep: state.currentStep - 1 })),
  
);
