import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { BlurService } from '@app/editor/services/blur.service';
import { IEventModel } from '@trakto/models';
import { enumSignals, SignalsService } from './../signals/signals.service';

@Component({
  selector: 'trakto-modal-progress',
  templateUrl: './modal-progress.component.html',
  styleUrls: ['./modal-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalProgressComponent implements OnInit, OnDestroy {
  public progress: number;
  public error: boolean;
  public errorMsg: string;

  private _title: string;
  private _progressSubscription: any;
  private _enableBroadcastProgressEvent = true;

  private unsubscribe$ = new Subject<void>();

  private _destroy$ = new Subject<void>();

  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title || '';
  }

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _blurService: BlurService,
    private _signalsService: SignalsService,
  ) {}

  ngOnInit(): void {
    this.progress = 0;
    this.error = undefined;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public enableBroadcastProgressEvent(value = true) {
    this._enableBroadcastProgressEvent = value;

    if (value) {
      this._signalsService.emit(enumSignals.ON_APPLICATION_IS_PROCESSING, true);
    }
  }

  public makePregress(): void {
    let countTime = 0;
    this.progress = Math.floor(Math.random() * 20) + 10;

    const time = setInterval(() => {
      countTime += 250;

      if (this.progress < 100) {
        if (countTime === 750) {
          this.progress = Math.floor(Math.random() * 30) + 21;
        }
        if (countTime === 1250) {
          this.progress = Math.floor(Math.random() * 40) + 31;
        }
        if (countTime === 2000) {
          this.progress = Math.floor(Math.random() * 70) + 41;
        }
      } else {
        countTime = 0;
        clearInterval(time);
      }
    }, 500);
  }

  public show(label?: string, enableBroadcastProgressEvent = true) {
    this.title = label;
    this.enableBroadcastProgressEvent(enableBroadcastProgressEvent);
    this._renderer.setAttribute(
      this._elementRef.nativeElement,
      'class',
      '--active',
    );
    this._blurService.apply();

    if (this._enableBroadcastProgressEvent) {
      this._signalsService.emit(enumSignals.ON_APPLICATION_IS_PROCESSING, true);
    }

    this.makePregress();
  }

  public hide(event?: IEventModel, cb?: (p?: any) => any): void {
    if (event && event.data.success === true) {
      this.progress = 100;

      if (typeof cb === 'function') {
        const delay_cb = of('').pipe(takeUntil(this.unsubscribe$), delay(150));
        delay_cb.pipe(takeUntil(this._destroy$)).subscribe(() => cb());
      }

      this.error = false;

      const delay_finished = of('').pipe(
        takeUntil(this.unsubscribe$),
        delay(4000),
      );
      delay_finished.pipe(takeUntil(this._destroy$)).subscribe(() => {
        this._blurService.remove();
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'class');
        this.title = '';
        this.error = undefined;
        this.progress = 0;
        if (this._enableBroadcastProgressEvent) {
          this._signalsService.emit(
            enumSignals.ON_APPLICATION_IS_PROCESSING,
            false,
          );
        }
      });
      return;
    }

    this.errorMsg =
      event && event.data && event.data.error
        ? event.data.error.message
        : 'Não conseguimos criar seu arquivo.';

    /**
     * TODO - Analisar esse a condicional abaixo pois quando o editor está
     * sendo aberto no modo embed, ao finalizar a exportação,
     * a mensagem de erro está sendo emitada a exibindo o modal.
     * Também ocorre no modo embed: o event.data está vindo como undefined,
     * assim quebrando a aplicação
     */

    // if (event.data && (!event.data.error || event.data.error.statusText === 'Unknown Error')) {
    //     this.errorMsg = 'Parece que nosso servidor caiu 😬, ou sua internet tá bem ruim.';
    // } else {
    //     this.errorMsg = (event && event.data && event.data.error) ? event.data.error.message : 'Não conseguimos criar seu arquivo.';
    // }

    this.progress = 100;
    this.error = true;

    const delay_error = of('').pipe(takeUntil(this.unsubscribe$), delay(2000));
    delay_error.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._blurService.remove();
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'class');
      this.title = '';
      this.error = undefined;
      this.progress = 0;
      if (this._enableBroadcastProgressEvent) {
        this._signalsService.emit(
          enumSignals.ON_APPLICATION_IS_PROCESSING,
          false,
        );
      }
    });
  }
}
