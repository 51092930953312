import { Injectable } from '@angular/core';
import { PageSVGConversionService } from '@shared/svg-viewer/shared/page-svg-conversion.service';
import { FontsService } from '@services/fonts.service';
import { ElementModelService } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import { Base64CacheService } from '@shared/base64-cache/base64-cache.service';
import { ConversionParams, PageIMGConverter } from '@trakto/svg-converter';
import { PageModel } from '@trakto/models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

declare var download: any;

@Injectable()
export class PageIMGConversionService extends PageIMGConverter {
  constructor(
    pageConversionService: PageSVGConversionService,
    newElementService: ElementModelService,
    newImageService: ImageElementService,
    fontsService: FontsService,
    base64CacheService: Base64CacheService,
  ) {
    super(
      pageConversionService,
      newElementService,
      newImageService,
      base64CacheService,
      fontsService,
    );
  }

  public downloadPGN(
    page: PageModel,
    params: ConversionParams,
    fileName: string,
  ): Observable<boolean> {
    return this.toBase64(page, params).pipe(
      tap(canvasUrl => {
        download(canvasUrl, fileName, 'image/png');
      }),
      map(() => true),
    );
  }
}
