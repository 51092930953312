import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PageModel } from '@trakto/models';
import { IUnitOptions } from '../inputs-settings/inputs-settings.component';

@Component({
  selector: 'trakto-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() options: IUnitOptions[] = [];
  @Input() pageSelected: PageModel;
  @Output() onChangeUnit: EventEmitter<IUnitOptions> =
    new EventEmitter<IUnitOptions>();

  @ViewChild('dropdownOptions', { static: false })
  dropdownOptionsContainer: ElementRef;

  isExpanded = false;
  reference: 'top' | 'bottom' = 'top';

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  out(event: Event) {
    if (
      !this._elementRef.nativeElement.contains(event.target) &&
      this.isExpanded
    ) {
      this.toggleExpandedStatus();
    }
  }

  toggleExpandedStatus() {
    this.isExpanded = !this.isExpanded;

    this.setDropdownPosition();
  }

  setDropdownPosition() {
    const optionsHeight = 300;

    const { y } = (
      this.dropdownOptionsContainer.nativeElement as HTMLElement
    ).getBoundingClientRect();

    const { availHeight } = window.screen;

    this.reference = availHeight - y < optionsHeight ? 'bottom' : 'top';
  }

  getLabel() {
    return this.options.find(option => option.value === this.pageSelected.unit)
      .label;
  }

  changeUnit(option: IUnitOptions) {
    this.isExpanded = false;
    this.onChangeUnit.emit(option);
  }
}
