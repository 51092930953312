<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-order.svg"
    label="Ordenar"
    [ignoreBackClick]="true"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="actions-grid click-ignore">
    <trakto-panel-action-mobile
      [height]="74"
      icon="order-to-front"
      label="Para frente"
      (click)="orderToFront()"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="74"
      icon="order-to-back"
      label="Para trás"
      (click)="orderToBack()"
    ></trakto-panel-action-mobile>
  </div>
</div>
