import { TraktoApiService } from './trakto-api.service';
import { Injectable } from '@angular/core';
import { IDocumentModel } from '@trakto/models';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import { HttpClient } from '@angular/common/http';
import { MagicTemplateService } from '@app/shared/magic-template/shared/magic-template.service';

export class TemplateToPublish {
  author: string;
  content_tags: any;
  tags: Array<string>;
  published: boolean;
  products: Array<string>;
  themeId: string;
  locales: Array<string>;
  pageFormatId: string;
  isMagic: boolean;
  thumbnail: {
    high: string;
    medium: string;
    low: string;
    raw: string;
  };
  isPremium: boolean;
  bella?: {
    tags: Array<string>;
    skill: string;
  }
  constructor(partial: Partial<TemplateToPublish>) {
    Object.assign(this, partial);
  }
};

export class TemplatePublished extends TemplateToPublish {
  id: string;
  updated_at: Date;
  constructor(publishedTemplate: TemplateToPublish) {
    super(publishedTemplate);
    Object.assign(this, publishedTemplate);
  }
}

export interface TemplateCampaignPublished {
  campaign_id: string;
  template_id: string;
  template_body: IDocumentModel;
  content_tags: string[];
  thumbnail: string;
}

export interface TemplateCampaignUnpublished {
  campaign_id: string;
  template_id: string;
  unpublished_at: string | Date;
}

@Injectable({
  providedIn: 'root',
})
export class TraktoAdminApiWhiteLabelService extends TraktoApiService {

  constructor(
    _authFirebaseService: AuthFirebaseService,
    private _magicTemplateService: MagicTemplateService,
    _httpClient: HttpClient,
  ) {
    super(_authFirebaseService, _httpClient);
  }


  async publishTemplate(templateId: string, info: TemplateToPublish): Promise<TemplatePublished> {
    // FIXME: Remover hard coded id do tema
    const payload = {
      author: info.author,
      tags: info.tags,
      published: info.published,
      is_premium: info.isPremium,
      products: info.products,
      theme_id: info.themeId || 'WzzK5RxjmMZZkR0Te0Zd',
      locales: info.locales,
      page_format_id: info.pageFormatId,
      is_magic: info.isMagic,
      thumbnail: info.thumbnail,
    };
    if (payload.is_magic) {
      payload['bella'] = {
        skill: info.bella.skill,
        expertises: info.bella.tags
      }
    }

    return this.executePut<TemplatePublished>(`admin/template/on_publish/${templateId}`, payload);
  }

}
