<div class="confirmation">
  <ng-container *ngIf="icon">
    <img [src]="icon" class="{{cssClass || icon}}" />
  </ng-container>
  <p class="title">{{ title }}</p>
  <p class="description">{{ description }}</p>
  <div class="divider"></div>
  <div
    (click)="close()"
    class="button button-primary"
    style="margin-bottom: 12px"
  >
    {{ cancel }}
  </div>
  <div (click)="execAction()" class="button button-secondary">
    {{ confirm }}
  </div>
</div>
