import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';

@Component({
  selector: 'trakto-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss'],
})
export class RenameComponent implements OnInit {
  private readonly _formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private hotkeyService: HotkeyService,
  ) {
    this._formGroup = this.formBuilder.group({
      title: [null, Validators.required],
      is_renamed: [false],
    });
  }

  ngOnInit() {
    this.formGroup.setValue({
      title: this.data.title,
      is_renamed: this.data.is_renamed ? this.data.is_renamed : false,
    });
  }

  disableHotkeys(): void {
    this.hotkeyService.disableHotkeys();
  }

  enableHotkeys(): void {
    this.hotkeyService.enableHotkeys();
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }
}
