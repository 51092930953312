import { Injectable } from '@angular/core';

import { TraktoUser } from '@auth/shared/auth.model';
import { IUploadImageModel } from '@trakto/core-editor';
import {
  enumImageFolder,
  FolderFactory,
  ImageService,
} from '@trakto/graphics-resources';
import { ImageModel } from '@trakto/graphics-resources/dist/src/models/image.model';
import { IUser, mediaProviderEnum } from '@trakto/models';
import { environment } from '@env/environment';
import { AuthService } from '@auth/shared/auth.service';
import { NotificationService } from '@shared/notification/notification.service';
import {
  FileRepository,
  IAssetUploadRequest
} from '@editor/repository/file.repository';

export interface IRemoveImageModel {
  public_id: string;
  user?: TraktoUser;
  folder?: enumImageFolder | enumImageAlternativeFolder | string;
}

export enum enumImageAlternativeFolder {
  THUMBNAIL = 'thumbnail',
}
@Injectable({
  providedIn: 'root',
})
export class UploadStorageService {
  private _maxUploadSize = environment.maxUploadFile || 3.5;

  constructor(
    private _fileRepository: FileRepository,
    private _authService: AuthService,
    private _alertService: NotificationService,
  ) {}

  async uploadFile(file: File): Promise<ImageModel> {
    const isSvg = file.type === 'image/svg+xml';
    const isGif = file.type === 'image/gif';
    const isThumbnail = !(isSvg || isGif);

    const config: IUploadImageModel = {
      file,
      folder: 'my_gallery',
      provider: mediaProviderEnum.internal,
      isThumbnail,
    };

    if (this._getFileSize(file) > this._maxUploadSize) {
      throw new Error(
        `${this._alertService.notificationsMSGs.preview_file_size_exceeded} (${this._maxUploadSize}MB).`
      );
    } else if (!config.file.type.includes('image')) {
      throw new Error(
        `${this._alertService.notificationsMSGs.file_unsupported} ${file.type}.${this._alertService.notificationsMSGs.file_supported_types}.`
      );
    }

    return this.uploadImage({
      ...config,
      folder: enumImageFolder.UPLOAD,
    });
  }

  /**
   * Connection to upload image method using @trakto/graphics-resources package;
   *
   * @param payload UploadImageModel
   */
  public async uploadImage(payload: IUploadImageModel): Promise<ImageModel> {
    try {
      const saveInGallery = typeof payload.file === 'object';
      const image = (await this._fileToBase64(payload.file)).toString();
      
      const newPayload: IAssetUploadRequest = {
        isThumbnail: payload.isThumbnail || true,
        imageData: image,
        fileType: 'base64',
      };

      return await this._fileRepository.uploadImage(newPayload, saveInGallery).then(data => ({
        resolutions: data.url,
      } as ImageModel));
      
    } catch (error) {
      console.error('upload error', error);
      throw error;
    }
  }

  /**
   * Connection to remove image method using @trakto/graphics-resources package;
   *
   * @param public_id Image id on repository
   * @param userId User Id to build a pubic_id path
   * @param folder Folder where image was stored
   */
  public removeImage(payload: IRemoveImageModel): Promise<any> {
    try {
      const { user, public_id, folder } = payload;
      let _public_id = public_id;

      if (user?.id) {
        _public_id =
          FolderFactory.getFolder(folder || enumImageFolder.UPLOAD, user.id) +
          `/${public_id}`;
      }

      if (folder) {
        _public_id = `${folder}/${public_id}`;
      }

      return ImageService.remove(_public_id);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Convert files to blog
   *
   * @param file from files object
   */
  public getBlobImageUrl(file: any): string {
    return window.URL.createObjectURL(file);
  }

  /**
   * Convert file image to base64
   *
   * @param file Upload local file
   */
  private _fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      if (!this._isStringBlob(file) && typeof file === 'object') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      } else {
        resolve(file);
      }
    });
  }

  /**
   * Verify if file is a string blob
   * @param file Upload local file
   */
  private _isStringBlob(file: any) {
    if (typeof file === 'string') {
      if (file.includes('base64')) {
        return false;
      }

      if (file.includes('blob')) {
        throw new Error('A blob string URL cannot be sent!');
      }
    }

    return false;
  }

  private _getFileSize(file: File) {
    return (file?.size || 0) / 1024 / 1024;
  }

}
