import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IColorEvent } from '@shared/color/events/color.event';

import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { FontsService } from '@services/fonts.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';

import {
  alignEnum,
  elementTypeEnum,
  IDropdownSelectConfig,
  IFontOptions,
  ITextElementModel,
} from '@trakto/models';

import {
  ColorModel,
  FONT_SIZES,
  FontOptionUtilModel,
} from '@trakto/core-editor';

import { TextEditorService } from '@services/text-editor.service';
import { Subscription } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { PanelColorComponent } from '../panel-color/panel-color.component';
import {
  PanelLinkEmailComponent
} from '../panel-link-email/panel-link-email.component';
import {
  PanelLinkWebsiteComponent
} from '../panel-link-website/panel-link-website.component';
import {
  PanelLinkWhatsappComponent
} from '../panel-link-whatsapp/panel-link-whatsapp.component';
import {
  PanelTextFontComponent
} from '../panel-text-font/panel-text-font.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { UserService } from '@app/editor-v3/services/user.service';

@Component({
  selector: 'trakto-panel-text',
  templateUrl: './panel-text.component.html',
})
export class PanelTextComponent extends AbstractComponent<ITextElementModel> implements OnInit, OnDestroy {
  public isGlobal: boolean;
  public isEmbeddedInstance: boolean;

  public dropdownLinkTo: IDropdownSelectConfig;
  public dropdownFont: IDropdownSelectConfig;
  public dropdownFontWeights: IDropdownSelectConfig;
  public dropdownFontSizes: IDropdownSelectConfig;

  public fontOptionsList: IFontOptions[];
  public fontOptionChanged: any;

  public italic: any;
  public hasItalicActive: boolean;
  public bold: any;
  public hasBoldActive: boolean;
  public headerTitle: string;

  public selectedFontFamily: string;
  public selectedColor: string;
  public selectedFontStyle: IFontOptions;

  private _selectionListener: Subscription;

  @Input() b2c: boolean;
  @Input() isTextEditable: boolean;
  @Input() allowLockElementsByTemplate: boolean;

  private propertyChangeSubscription: Subscription;
  private weightChangeSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private _userService: UserService,
    private fontService: FontsService,
    private _translate: TranslateService,
    private _panelStackService: PanelStackService,
    private _signalsService: SignalsService,
    private _hotkeyService: HotkeyService,
    private _textEditorService: TextEditorService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService
  ) {
    super (documentStateManagerService);
    this.fontOptionChanged = [];
    this.fontOptionsList = [];
    this.isGlobal = false;

    this.isEmbeddedInstance = this.authService.isEmbeddedInstance();

    const sizes = [];
    FONT_SIZES.forEach(element => {
      sizes.push({ label: `${element}px`, value: element, selected: false });
    });

    this.dropdownLinkTo = {
      options: [
        { label: 'Website', value: 'website', selected: true },
        { label: 'Page', value: 'page', selected: false },
      ],
      buttonConfig: {
        width: 161,
        classes: ['button--outline', 'button--light'],
      },
    };

    this.dropdownFont = {
      options: [],
      buttonConfig: {
        classes: ['button--outline', 'button--light'],
      },
    };

    this.dropdownFontSizes = {
      height: 300,
      options: sizes,
      buttonConfig: {
        classes: ['button--outline', 'button--light'],
      },
    };

    this.dropdownFontWeights = {
      width: 126,
      options: [],
      buttonConfig: {
        classes: ['button--outline', 'button--light'],
      },
    };

    this._translate
      .get('property_panel.text.panel_color.title')
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.headerTitle = value));
  }

  ngOnInit() {
    super.ngOnInit();
    this.connectEmitFont();
    this.isGlobal = this._userService.isGlobalAdmin;
    this._selectionListener = this._textEditorService.selectionChange.subscribe(
      () => {
        this._initSelections();
      },
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (
      this.weightChangeSubscription &&
      !this.weightChangeSubscription.closed
    ) {
      this.weightChangeSubscription.unsubscribe();
    }
    if (
      this.propertyChangeSubscription &&
      !this.propertyChangeSubscription.closed
    ) {
      this.propertyChangeSubscription.unsubscribe();
    }

    if (this._selectionListener) {
      this._selectionListener.unsubscribe();
    }
  }

  public onElementSelected(value: ITextElementModel) {
    if (value.type !== elementTypeEnum.text) {
      return;
    }
    this.isTextEditable = false;

    this.fontService.listFontOptions(value.fontFamily ? value.fontFamily : value.fontStyle.font).pipe(takeUntil(this._destroy$)).subscribe(options => {
      options = options || [];
      const hasItalic =
        options.filter((option: any) => FontOptionUtilModel.isItalic(option))
          .length > 0;
      const hasBold =
        options.filter((option: any) => FontOptionUtilModel.isBold(option))
          .length > 0;

      this.italic = {
        exists: hasItalic,
        value: hasItalic
          ? options.filter((option: any) =>
            FontOptionUtilModel.isItalic(option),
          )[0]
          : this.fontService.getRegularOrFirstFont(options),
      };

      this.bold = {
        exists:
          options.filter((option: any) => FontOptionUtilModel.isBold(option))
            .length > 0,
        value: hasBold
          ? options.filter((option: any) =>
            FontOptionUtilModel.isBold(option),
          )[0]
          : this.fontService.getRegularOrFirstFont(options),
      };

      if (value && value.fontStyle) {
        this.fontOptionChanged = value.fontStyle.weight;
        this.hasItalicActive = FontOptionUtilModel.isItalic(
          this.elementSelected.fontStyle,
        );
        this.hasBoldActive = FontOptionUtilModel.isBold(
          this.elementSelected.fontStyle,
        );

        this.setFontOptions(options);
      }
    });
    this._initSelections();
  }

  public connectEmitFont(): void {
    this.weightChangeSubscription = this._signalsService.connect(
      enumSignals.PROPERTY_FONT_WEIGHT,
      font => {
        this.fontService
          .listFontOptions(this.elementSelected.fontFamily ? this.elementSelected.fontFamily : this.elementSelected.fontStyle.font)
          .pipe(takeUntil(this._destroy$))
          .subscribe(options => {
            this.setFontOptions(options);
            this._initSelections();
          });
        return true;
      },
    );

    this.propertyChangeSubscription = this._signalsService.connect(
      enumSignals.PROPERTY_CHANGE,
      () => {
        this._initSelections();
      },
    );
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public changeProperty(property: string): void {
    if (property === 'fontFamily') {
      this.elementSelected['fontFamily'] = this.elementSelected[property];
      this.fontService
        .listFontOptions(this.elementSelected.fontFamily)
        .pipe(takeUntil(this._destroy$))
        .subscribe(options => {
          this.setFontOptions(options);
          this.fontOptionChanged = this.fontOptionsList[0].weight;
          this.changeFontOption(false);
        });
    }

    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: this.elementSelected.id,
      key: property,
      value: this.elementSelected[property],
      obj: this.elementSelected,
    });
  }

  private _initSelections() {
    this._initSelectedColor();
    this._initSelectedFontFamily();
    this._initSelectedFontStyle();
  }

  private _initSelectedColor() {
    this.selectedColor = this._textEditorService.getSelectedColor(
      this.elementSelected as ITextElementModel,
    );
  }

  private _initSelectedFontFamily() {
    this.selectedFontFamily = this._textEditorService.getSelectedFontFamily(
      this.elementSelected as ITextElementModel,
    );
  }

  private _initSelectedFontStyle() {
    this.selectedFontStyle = this._textEditorService.getSelectedFontStyle(
      this.elementSelected as ITextElementModel,
    );
    if (!this.selectedFontStyle) {
      return;
    }
    this.fontOptionChanged = this.selectedFontStyle.weight;
    if (this.selectedFontStyle) {
      this.hasItalicActive = FontOptionUtilModel.isItalic(
        this.selectedFontStyle,
      );
      this.hasBoldActive = FontOptionUtilModel.isBold(this.selectedFontStyle);
    }
  }

  public changeLineHeight($event) {
    this._elementChangeFacadeService.changeLineHeight(
      this.elementSelected as ITextElementModel,
      $event,
    );
  }

  public openPanelStack(context: string, event?: any) {
    switch (context) {
      case 'font':
        this._panelStackService.stack(PanelTextFontComponent, {
          inputs: {
            elementSelected: this.elementSelected,
            pageSelected: this.pageSelected,
          },
        });
        break;
      case 'color':
        event.currentService.defaultColor = new ColorModel(
          null,
          this._textEditorService.getSelectedColor(this.elementSelected),
        );

        this._panelStackService.stack(PanelColorComponent, {
          inputs: {
            pageSelected: this.pageSelected,
            elementSelected: this.elementSelected,
            currentService: event.currentService,
            headerIcon: null,
            headerTitle: this.headerTitle,
          },
          outputs: {
            colorChange: {
              emit: (color: string) => {
                this._elementChangeFacadeService.changeTextColor(
                  this.elementSelected as ITextElementModel,
                  color,
                );
                this.selectedColor = color;
              },
            },
          },
        });
        break;
      case 'panel-website':
        this._panelStackService.stack(PanelLinkWebsiteComponent, {
          inputs: {
            elementSelected: this.elementSelected,
          },
        });
        break;
      case 'panel-email':
        this._panelStackService.stack(PanelLinkEmailComponent, {
          inputs: {
            elementSelected: this.elementSelected,
          },
        });
        break;

      case 'panel-whatsapp':
        this._panelStackService.stack(PanelLinkWhatsappComponent, {
          inputs: {
            elementSelected: this.elementSelected,
          },
        });
        break;

      default:
        break;
    }
  }

  public openColorPicker(event: IColorEvent): void {
    event.targetSettings = {
      title: `${this.headerTitle}`,
      icon: 'title',
    };

    this.openPanelStack('color', event);
  }

  private setFontOptions(fontOptionsList: any[]): void {
    if (fontOptionsList) {
      this.fontOptionsList = fontOptionsList;
      this.dropdownFontWeights.options.splice(
        0,
        this.dropdownFontWeights.options.length,
      );

      this.fontOptionsList.map((option: any, index: number) => {
        this.dropdownFontWeights.options.push({
          label: option.label,
          value: option.weight,
          selected: option.weight === this.elementSelected.fontStyle.weight,
        });
      });

      this._signalsService.emit(
        enumSignals.ON_RENAME_PUBLISH,
        this.dropdownFontWeights,
      );

      const italicExists: boolean =
        this.fontOptionsList.filter((option: IFontOptions) =>
          FontOptionUtilModel.isItalic(option),
        ).length > 0;
      const boldExists: boolean =
        this.fontOptionsList.filter((option: IFontOptions) =>
          FontOptionUtilModel.isBold(option),
        ).length > 0;

      this.italic = {
        exists: italicExists,
        value: italicExists
          ? this.fontOptionsList.filter((option: IFontOptions) =>
              FontOptionUtilModel.isItalic(option),
            )[0]
          : this.fontService.getRegularOrFirstFont(this.fontOptionsList),
      };

      this.bold = {
        exists:
          this.fontOptionsList.filter((option: any) =>
            FontOptionUtilModel.isBold(option),
          ).length > 0,
        value: boldExists
          ? this.fontOptionsList.filter((option: any) =>
              FontOptionUtilModel.isBold(option),
            )[0]
          : this.fontService.getRegularOrFirstFont(this.fontOptionsList),
      };
    }
  }

  public changeFontOption(emitEvent = true): any {
    let fontStyleSelected = this.fontOptionsList[0];

    this.fontService
      .listFontOptions(this.elementSelected.fontFamily)
      .pipe(takeUntil(this._destroy$))
      .subscribe(options => {
        this.fontOptionsList = options;
        this.fontOptionsList.map((fontOption: any, index: number) => {
          if (fontOption.weight === this.fontOptionChanged) {
            fontStyleSelected = fontOption;
          }
        });
        this._elementChangeFacadeService.changeFontStyle(
          this.elementSelected,
          fontStyleSelected,
        );
        this.hasItalicActive = FontOptionUtilModel.isItalic(
          this.elementSelected.fontStyle,
        );
        this.hasBoldActive = FontOptionUtilModel.isBold(
          this.elementSelected.fontStyle,
        );
      });
  }

  public changeFontFamilyAndStyle(): any {}

  public setAlignment(direction: string): void {
    if (this.elementSelected) {
      this._elementChangeFacadeService.changeAlign(
        this.elementSelected,
        alignEnum[direction as alignEnum]
      );
    }
  }

  public setValueFontSize(value: any): void {
    delay(350);
    Promise.resolve().then(() => {
      if (value === null || value === undefined) {
        return;
      }
      const val = +Number(value).toFixed(2) / this.elementSelected.scaleY;
      this._elementChangeFacadeService.changeTextFontSize(this.elementSelected, Number(val));
    });
  }

  public setFontSizeIncreaseOrDecrease(type: string): void {
    if (this.elementSelected) {
      type === 'plus'
        ? ++this.elementSelected.fontSize
        : --this.elementSelected.fontSize;

      delay(350);
      Promise.resolve().then(() => this.changeProperty('fontSize'));
    }
  }

  public updateSize(value: any): any {
    if (value === null || value === undefined) {
      return;
    }

    value === ''
      ? this.elementSelected.fontSize++
      : this.elementSelected.fontSize.toFixed(2);
    this.changeProperty('fontSize');
  }

  public isAlignLeft(): boolean {
    return this.elementSelected['align'] === alignEnum.left;
  }

  public isAlignCenter(): boolean {
    return this.elementSelected['align'] === alignEnum.center;
  }

  public isAlignRight(): boolean {
    return this.elementSelected['align'] === alignEnum.right;
  }

  public isAlignJustify(): boolean {
    return this.elementSelected['align'] === alignEnum.justify;
  }

  public toggleItalic(): void {
    if (this.elementSelected) {
      this.fontService
        .listFontOptions(this.elementSelected.fontFamily)
        .pipe(takeUntil(this._destroy$))
        .subscribe(options => {
          const listItalicBold = FontOptionUtilModel.getListItalicBold(options);
          const listItalic = FontOptionUtilModel.getListItalic(options);
          const listBold = FontOptionUtilModel.getListBold(options);

          if (!this.hasItalicActive) {
            this.hasItalicActive = true;

            if (this.hasBoldActive && listItalicBold.length > 0) {
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                listItalicBold[0],
              );
            } else {
              this._elementChangeFacadeService.changeFontStyle(
                listItalic.length > 0 ? listItalic[0] : this.italic.value,
                this.elementSelected,
              );
            }
          } else {
            this.hasItalicActive = false;

            if (this.hasBoldActive && listBold.length > 0) {
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                listBold[0],
              );
            } else {
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                options.filter((option: IFontOptions) =>
                  FontOptionUtilModel.isRegular(option),
                )[0],
              );
            }
          }

          this.hasBoldActive = FontOptionUtilModel.isBold(
            this.elementSelected.fontStyle,
          );
          this.fontOptionChanged = this.elementSelected.fontStyle.weight;
        });
    }
  }

  public toggleBold(): void {
    if (this.elementSelected) {
      this.fontService
        .listFontOptions(this.elementSelected.fontFamily)
        .pipe(takeUntil(this._destroy$))
        .subscribe(options => {
          const listItalicBold = FontOptionUtilModel.getListItalicBold(options);
          const listItalic = FontOptionUtilModel.getListItalic(options);
          const listBold = FontOptionUtilModel.getListBold(options);

          if (!this.hasBoldActive) {
            this.hasBoldActive = true;

            if (this.hasItalicActive && listItalicBold.length > 0) {
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                listItalicBold[0],
              );
            } else {
              const style = listBold.length > 0 ? listBold[0] : this.bold.value;
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                style
              );
            }
          } else {
            this.hasBoldActive = false;

            if (this.hasItalicActive && listItalic.length > 0) {
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                listItalic[0],
              );
            } else {
              const style = options.filter((option: any) =>
                FontOptionUtilModel.isRegular(option),
              )[0];
              this._elementChangeFacadeService.changeFontStyle(
                this.elementSelected,
                style,
              );
            }
          }

          this.hasItalicActive = FontOptionUtilModel.isItalic(
            this.elementSelected.fontStyle,
          );
          this.fontOptionChanged = this.elementSelected.fontStyle.weight;
          this.changeProperty('fontStyle');
        });
    }
  }

  public setFilter(filter: string): void {
    this.elementSelected.filter = filter || 'empty';
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: this.elementSelected.id,
      key: 'filter',
      value: this.elementSelected.filter,
      obj: this.elementSelected,
    });
  }

  private resetSelectedFontWeight() {
    this.fontService
      .listFontOptions(this.elementSelected.fontFamily ? this.elementSelected.fontFamily : this.elementSelected.fontStyle.font)
      .pipe(takeUntil(this._destroy$))
      .subscribe(options => {
        const option = this.fontService.getRegularOrFirstFont(options);
        this._elementChangeFacadeService.changeFontStyle(
          this.elementSelected,
          option,
        );
        this._initSelectedFontStyle();
      });
  }
}
