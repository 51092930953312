<div class="trakto-document-list">
  <div class="trakto-document-list__toolbar">
    <trakto-checkbox
      id="selectAll"
      #toggle
      name="selectAll"
      [(ngModel)]="toggleSelectValue"
      (onChange)="toggleSelecAll(toggle.isSelected)"
      >Selecionar todos</trakto-checkbox
    >

    <trakto-button
      class="trakto-document-list__exclude"
      button--outline
      button--light
      *ngIf="selectedItems.length"
      (onClick)="removeSelectedItems()"
    >
      <i class="trakto-icon-trash"></i> Apagar selecionados
    </trakto-button>
  </div>

  <div class="trakto-document-list__items">
    <trakto-document-item-renderer
      class="trakto-document-list__item"
      *ngFor="let item of dataProvider; index as i"
      [item]="item"
      (onAction)="actionHandler($event, i)"
    ></trakto-document-item-renderer>
  </div>
</div>
