<section class="tkt-toggle" (click)="toggle()">
  <input type="checkbox" class="tkt-toggle__input" />
  <button
    class="tkt-toggle__btn {{ className }}"
    [ngClass]="{ 'tkt-toggle__on': actived, 'tkt-toggle__off': !actived }"
    [attr.aria-expanded]="actived"
  >
    <p [class.--active]="!actived">Stickers</p>
    <p [class.--active]="actived">GIFs</p>
  </button>
</section>
