<div class="magic-template-loader" *ngIf="isLoading">
  <div class="magic-template-loader-spinner">
    <div class="tkt-loader__spinner"></div>
  </div>
  <p class="magic-template-text">
    {{ 'sidebar_publish.loader-text' | translate }}
  </p>
</div>
<div class="magic-template">
  <form class="form-group" [formGroup]="form" (submit)="submit($event)">
    <ng-container *ngIf="(currentStep$ | async) === 2">
      <trakto-title-with-line [title]="whichTemplateToUse"></trakto-title-with-line>
      <div class="magic-template-type" *ngIf="(templateType$ | async) === 'magic'; let templateType">
        <trakto-template-type-button [iconUrl]="'../../../../../../assets/icons/upload-design.svg'"
          [title]="importDesign" [text]="submitYourPdfFile" [color]="'#66BFFF14'"
          (click)="changeTemplateType('upload')">
        </trakto-template-type-button>
        <trakto-template-type-button [iconUrl]="'../../../../../../assets/icons/trakto-design.svg'"
          [title]="designTrakto" [text]="useArtOpenedInTheEditor" [color]="'#4743FF14'"
          (click)="changeTemplateType('trakto')">
        </trakto-template-type-button>
      </div>
    </ng-container>

    <ng-container *ngIf="(currentStep$ | async) === 3">
      <ng-container *ngIf="(templateSubType$ | async) === 'upload'; let templateSubType">
        <ng-container *ngIf="!uploadSucceeded">
          <trakto-pdf-upload-design></trakto-pdf-upload-design>
        </ng-container>
        <ng-container *ngIf="uploadSucceeded">
          <div>
            <trakto-title-with-line [title]="correctFile"></trakto-title-with-line>
            <p class="magic-template-text">
              {{ 'sidebar_publish.confirm-entered-design' | translate }}
            </p>
          </div>
          <div class="magic-template-step-img">
            <div class="magic-template-step-line">
              <hr>
            </div>
            <div class="magic-template-img-content" *ngIf="image">
              <img [src]="image">
            </div>
          </div>
          <div class="magic-template-content-text">
            <p>{{ 'sidebar_publish.some-problem' | translate }}</p>
            <p class="content-text-link">{{ 'sidebar_publish.we-help-you' | translate }}</p>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="(templateSubType$ | async) === 'trakto'; let templateSubType">
        <ng-container>
          <div>
            <trakto-title-with-line [title]="fillInTheFields"></trakto-title-with-line>
            <p class="magic-template-text">
              {{ 'sidebar_publish.select-campaign-and-check-common-tags' | translate }}
            </p>
          </div>
          <div class="select-container">
            <div class="select-container-row">
              <trakto-select-field #selectField class="select-container-panel" [placeholder]="campaign" [options]="filterStaticCampaigns()"
                formControlName="campaign" (selectionChange)="filterCampaign($event.value)"></trakto-select-field>
            </div>
            <div class="select-container-row position-content">
              <trakto-input-chip-field class="select-container-panel" [placeholder]="keywords"
                [options]="form.get('tags')?.value" formArrayName="tags" (valueChange)="addTag($event)"
                (chipRemoved)="removeTag($event)"></trakto-input-chip-field>
              <div class="position-icon">
                <i class="trakto-icon-info"></i>
              </div>
            </div>
            <div class="select-container-row">
              <div class="select-container-info">
                <div class="select-container-info-header">
                  <i class="trakto-icon-info"></i>
                  <p class="select-container-info-title">{{keywords}}</p>
                </div>
                <p class="select-container-info-text">{{ 'sidebar_publish.select-the-tags-to-be-used' | translate }}</p>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(currentStep$ | async) === 4">
      <div>
        <trakto-title-with-line [title]="defineTheVariableElements"></trakto-title-with-line>
        <p class="magic-template-text">
          {{ 'sidebar_publish.select-the-tag-to-its-respective-element' | translate }}
        </p>
        <p class="magic-template-text">
          {{ 'sidebar_publish.leave-blank-if-element-is-fixed' | translate }}
        </p>
      </div>
      <div class="select-container">
        <div class="select-container-panel"
          *ngFor="let elementControl of form.get('magicTemplate').controls; let i = index"
          (mouseover)="highlightedElement(true, elementControl.value)"
          (mouseleave)="highlightedElement(false, elementControl.value)">
          <div class="select-container-panel-thumb">
            <ng-container *ngIf="elementControl.value.element.type === 'text'">
              <span class="select-container-panel-text">{{ elementControl.value.element['text'].charAt(0) }}</span>
            </ng-container>

            <ng-container *ngIf="elementControl.value.element.type === 'image'">
              <img class="select-container-panel-img" [src]="elementControl.value.element['low']" alt="">
            </ng-container>

            <ng-container *ngIf="elementControl.value.element.type === 'shape'">
              <svg width="32" height="32" viewBox="0 0 50 50">
                <path [attr.d]="elementControl.value.element['path']"
                  [attr.fill]="elementControl.value.element['backgroundColor']"></path>
              </svg>
            </ng-container>
          </div>
          <div class="select-container-panel-select">
            <trakto-select-field class="select-row" [placeholder]="setTagText"
              [formControl]="elementControl.get('tags')"
              [multiple]="true" [options]="magicTemplateOptions[elementControl.value.element.id]"
              (selectionChange)="onSelectionTag($event, elementControl)">
            </trakto-select-field>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(currentStep$ | async) === 5 && !isLoading">
      <div class="magic-template-icon">
        <img src="../../../../../../assets/icons/rocket-success.svg" alt="">
      </div>

      <trakto-title-with-line [title]="publicationCompleted"></trakto-title-with-line>

      <div class="magic-template-text">
        <p>
          {{ 'sidebar_publish.template-successfully-published-and-available-in-the-gallery' | translate }}
        </p>
      </div>

      <button type="button" class="btn_unfold" (click)="modal.toggle();">
        <img *ngIf="!link.copied" src="../../../../../../assets/icons/ruler&pen.svg" style="width: 16px;">
        Redimensionar template
      </button>


      <button type="button" class="btn_copy" (click)="copyLinkToClipboard()">
        <img *ngIf="!link.copied" src="../../../../../../assets/icons/document-copy.svg">
        <img *ngIf="link.copied" src="../../../../../../assets/icons/tick-circle.svg">
        Copiar Link
      </button>

      <div class="magic-template-thumb">
        <img class="magic-template-thumb-img" [src]="thumbnailUrl">
      </div>
      <div class="magic-template-success">
        <div class="magic-template-input-link">
          <div class="magic-template-subdomain">
            <label class="magic-template-subdomain-link" (click)="redirectToFullLink()">
              <i class="trakto-icon-link-drop"></i>
              {{shortLink}}
            </label>
          </div>
          <!-- <div class="trakto-icon-link">
            <a class="magic-template-btn-copy" (click)="copyLinkToClipboard()">
              <img *ngIf="!link.copied" src="../../../../../../assets/icons/document-copy.svg">
              <img *ngIf="link.copied" src="../../../../../../assets/icons/tick-circle.svg">
            </a>
          </div> -->
        </div>
      </div>
      <div>
        <p class="magic-template-title">{{ 'sidebar_publish.publication-information' | translate }}</p>
        <div class="magic-template-content">
          <p class="magic-template-subtitle">{{ 'sidebar_publish.responsible' | translate }}</p>
          <span>{{ form.value.author.name}}</span>
        </div>
        <div class="magic-template-content">
          <p class="magic-template-subtitle">{{ 'sidebar_publish.campaign' | translate }}</p>
          <span>{{ selectdCampaign.title }}</span>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<div class="navigation-buttons" *ngIf="(currentStep$ | async) === 2">
  <button class="btn full" mat-button (click)="navigateToBackStep()" [disabled]="(currentStep$ | async) === 1">
    {{ 'sidebar_publish.buttons.go_back' | translate }}
  </button>
</div>

<div class="navigation-buttons" *ngIf="(currentStep$ | async) > 2 && (currentStep$ | async) < 4">
  <ng-container *ngIf="(templateSubType$ | async) === 'upload'">
    <ng-container *ngIf="!uploadSucceeded || fileUploading">
      <button class="btn full" mat-button (click)="navigateToBackStep()">
        {{ 'sidebar_publish.buttons.go_back' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="uploadSucceeded">
      <button class="btn back" mat-button (click)="uploadAgain()">
        {{ 'sidebar_publish.try-again' | translate }}
      </button>
      <button class="btn save" mat-button (click)="switchToTrakto()">
        {{ 'sidebar_publish.is-correct' | translate }}
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="(templateSubType$ | async) === 'trakto'">
    <button class="btn back" mat-button (click)="navigateToBackStep()" [disabled]="(currentStep$ | async) === 1">
      {{ 'sidebar_publish.buttons.go_back' | translate }}
    </button>
    <button class="btn save" #nextButton mat-button [ngClass]="{'disabled': !isNextButtonValid}"
      (click)="navigateToNextStep()" [disabled]="!isNextButtonValid">
      {{ 'sidebar_publish.buttons.continue' | translate }}
    </button>
  </ng-container>
</div>

<div class="navigation-buttons" *ngIf="(currentStep$ | async) === 4">
  <button class="btn back" mat-button (click)="navigateToBackStep()" [disabled]="(currentStep$ | async) === 1">
    {{ 'sidebar_publish.buttons.go_back' | translate }}
  </button>
  <button class="btn save" #nextButton mat-button [ngClass]="{'disabled': !isNextButtonValid}"
    (click)="navigateToNextStep(); submit($event)" [disabled]="!isNextButtonValid">
    {{ 'sidebar_publish.buttons.save_template' | translate }}
  </button>
</div>

<div class="navigation-buttons" *ngIf="(currentStep$ | async) === 5 && !isLoading">
  <button class="btn back" mat-button  (click)="close()" [disabled]="(currentStep$ | async) === 1">
    {{ 'sidebar_publish.buttons.complete' | translate }}
  </button>
  <button class="btn save" mat-button (click)="goToBellaAds()">
    Ir para a campanha
  </button>
</div>


<trakto-modal-unfold-template
  #modal
  (onModalShareChange)="shareModalChange($event)">
</trakto-modal-unfold-template>
