import { Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';

import { ThemeService } from '@editor/services/theme.service';
import { LocalStorageService } from '@shared/local-storage/local-storage-service.service';
import { CookieService } from 'ngx-cookie-service';

import { TraktoUser } from './auth.model';

import { IThemeStorageModel } from '@trakto/models';

import { environment } from '@env/environment';
import { AuthenticationTokenService } from './auth-token.service';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import { UserService } from '@app/editor-v3/services/user.service';


@Injectable()
export class AuthService {

  private loginPage = `${environment.externalLoginPage}?appId=${environment.appId}&sdkToken=${environment.sdkToken}`;
  private loginDashboard = environment.externalDashboardPage;
  private _currentUser: any;

  public get currentUser(): any {
    return this._currentUser;
  }

  constructor(
    private database: AngularFirestore,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private themeService: ThemeService,
    private _tokenService: AuthenticationTokenService,
    private _authFirebaseService: AuthFirebaseService,
    private _cookieService: CookieService,
    private _userService: UserService,
  ) { }

  login(customToken: string): Observable<boolean> {
    return this._authFirebaseService.login(customToken).pipe(tap(
      () => this._userService.getProfile().toPromise(),
    ));
  }

  loginByCookie(cookie: string): Observable<boolean> {
    return this.getAuthTokenByCookie(cookie).pipe(
      flatMap(authToken => this.login(authToken)),
    );
  }

  private getAuthTokenByCookie(cookie: string): Observable<string> {
    return this._tokenService
      .authTokenByCookie({
        cookie,
        appId: environment.appId,
        sdkToken: environment.sdkToken,
      })
      .pipe(map(result => result.authToken));
  }

  async signinByAccessToken(newAccessToken: string): Promise<boolean> {
    let success = true;
    this.invalidateAuthCredentialsFromStorage();

    this._authFirebaseService.setAccessNewAccessTokenOnLocalStorage(newAccessToken);
    this._authFirebaseService.accessToken = newAccessToken;

    const customToken = await this._tokenService.customTokenFromAccessToken(newAccessToken);
    success = await this._authFirebaseService.login(customToken).toPromise();
    await this._userService.getProfile().toPromise();
    return success;
  }

  // TODO: Realizar o logout direto no ecossistema de autenticação, no Firebase. Assim fazendo que todas as plataformas onde o usuário esteja conectado possam ser deslogadas
  logout(): Observable<boolean> {
    return this._authFirebaseService.logout().pipe(
      tap(() => {
        this.localStorageService.removeObject('theme');

        if (!window.location.href.includes('localhost')) {
          this.cookieService.delete(
            environment.cookieSessionKey,
            environment.cookiePath,
            environment.cookieDomain,
          );
        } else {
          this.cookieService.delete(
            environment.cookieSessionKey,
            environment.cookiePath,
          );
        }
      }),
      map(() => true),
    );
  }

  public getCurrentTraktoUser(): Observable<TraktoUser> {
   return this._userService.getProfile();
  }

  // FIXME - Não está verificando se outras plataformas realizaram o logout,
  // assim a sessão continua válida
  public async hasValidUserSession(): Promise<boolean> {
    try {
      const token = (await this._authFirebaseService.getAccessToken());
      if (!token) return false;
      const currentUser = await this._userService.getProfile().toPromise();
      const userFromCookieSession = await this.getUserByCookie();
      return userFromCookieSession &&
        currentUser &&
        userFromCookieSession.id === currentUser.id;
    } catch (e) {
      return false;
    }
  }

  private async getUserByCookie() {
    try {
      if (!this.cookieService.check(environment.cookieSessionKey)) {
        return;
      }
      return await this._tokenService.getUserByIdTokenCookie(
        this.cookieService.get(environment.cookieSessionKey)
      ).toPromise();
    } catch (e) {
      throw new Error(e);
    }
  }

  // TODO: Mover para serviço de redirecionamento de rotas
  public redirectToIndex() {
    this.router.navigate(environment.indexPage);
  }

  // TODO: Mover para serviço de redirecionamento de rotas
  public redirectToLogoutPage() {
    this.router.navigate(environment.logoutPage);
  }

  // TODO: Mover para serviço de redirecionamento de rotas
  public getLoginPage() {
    return this.loginPage;
  }

  // TODO: Mover para serviço de redirecionamento de rotas
  public redirectToLoginPage() {
    location.href = this.loginPage;
  }

  // TODO: Mover para serviço de redirecionamento de rotas
  public redirectToDashboard() {
    location.href = this.loginDashboard;
  }

  // TODO: Mover para serviço de controle de estado de usuário
  public async updateUserLocale(user: any, productId: string, isoLang: string) {
    const newLocale = user.current_locale || {};
    newLocale[productId] = isoLang;

    return await this.database
      .doc(`user/${user.id}`)
      .set({ current_locale: newLocale }, { merge: true });
  }

  // TODO: Mover para serviço de controle de permissões de usuário
  public isGlobal(user) {
    return user && user.role && user.role.value === 'global';
  }

  // TODO: Mover para serviço de controle de permissões de usuário
  public isAdmin(user) {
    return user && user.role && user.role.value === 'admin';
  }

  // TODO: Mover para o serviço da Editor SDK
  public isEmbeddedInstance(): boolean {
    return (window.location.href || '').indexOf('embed') > -1;
  }

  // TODO: Mover para o serviço da Trakto Dropbox
  public isDropboxInstance(): boolean {
    return this.router.url.indexOf('dropbox') > -1;
  }

  // TODO: Mover para o serviço de Apresentação
  public isPresentationInstance(): boolean {
    return this.router.url.indexOf('presentation') > -1;
  }

  // TODO: Mover para serviço de cookies de autenticação
  invalidateAuthCredentialsFromStorage() {
    localStorage.removeItem('trakto:accessToken');
    localStorage.removeItem('trakto-lang');
    this._cookieService.delete('trakto:loader');
    this._cookieService.delete('trakto-session');
  }
}
