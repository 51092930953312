import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { isUrlValid } from '@app/editor-v3/utils/validators';

@Component({
  selector: 'trakto-panel-website-mobile',
  templateUrl: './panel-website-mobile.component.html',
  styleUrls: ['./panel-website-mobile.component.scss'],
})
export class PanelWebsiteMobileComponent implements OnInit {
  website: string = '';
  isUrlValid: boolean = false;

  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {
    this.changeWebsiteValue(this._elementsService.selectedElement.link || '');
  }

  changeWebsiteValue(val: string) {
    this.website = val;
    this.isUrlValid = isUrlValid(this.website);
  }

  applyLinkToElement() {
    this._elementsService.applyWebsiteLinkToElement(this.website);
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
