<div class="trakto-preview" (window:resize)="initZoomRatio()">
  <div
    id="pagesContainer"
    class="pages-container"
    [class.navigation--active]="
      document && document.body && document.body.length > 1
    "
  >
    <div
      *ngIf="document && selectedPage"
    >
      <trakto-superior-page-options
        [selectedPage]="selectedPage"
        [selectedPageIndex]="selectedPageIndex"
        [zoomRatio]="zoomService.zoomRatio"
        [pageWidth]="selectedPage.width"
        [docLength]="document?.body?.length || 1"
        [allowNewPages]="allowNewPages"
        [isDropboxInstance]="isDropboxInstance"
        [popoverDeleteInfo]="popoverDeleteInfo"
        (onSelectedPage)="openBasePanel(selectedPage, true)"
        (onDeleteAsked)="popoverDeleteInfo.selectedPage = $event"
      ></trakto-superior-page-options>

      <div class="setGradientTransparent">
        <trakto-svg-editor
          #svgEditor
          *ngIf="selectedPage && !hiddenPage"
          [ref]="refToUpdateSvgEditor"
          [page]="selectedPage"
          [elements]="selectedPage?.elements"
          [zoomRatio]="zoomService.zoomRatio"
          [isActivated]="true"
          [hasWaterMask]="hasWaterMask"
          [printableMode]="document?.is_printable"
          (onSelectedElement)="newSelection($event)"
          (onFocusedElement)="handleFocusedElement($event)"
          (onPageClicked)="openBasePanel(selectedPage)"
          (onPageAdditionRequest)="
            documentManagerService.addPage(cloneClearPage(selectedPage), selectedPageIndex + 1)
          "
          (onPageCloneRequest)="
            documentManagerService.addPage(clonePage(selectedPage), selectedPageIndex + 1)
          "
          (onPageDeletionRequest)="popoverDeleteInfo.selectedPage = selectedPage"
          (onElementDeletionRequest)="
            documentManagerService.deleteElement($event)
          "
          (drop)="drop($event, selectedPage)"
          (dragover)="dragOver($event)"
          (historyUndo)="historyUndo()"
          (historyRedo)="historyRedo()"
          (changed)="persistElementsChanges($event)"
        >
        </trakto-svg-editor>
      </div>

      <trakto-lateral-page-options
        [selectedPageIndex]="selectedPageIndex"
        [docLength]="document.body.length || 1"
        [allowNewPages]="allowNewPages"
        (onSelectedPage)="openBasePanel(selectedPage, true)"
      ></trakto-lateral-page-options>
    </div>
  </div>
  <trakto-zoom
    (onZoomIn)="zoomService.handleZoomIn(selectedPage, document)"
    (onZoomOut)="zoomService.handleZoomOut(selectedPage, document)"
    (onZoomFit)="zoomService.handleZoomFit(selectedPage, document)"
    [zoomRatio]="zoomService.currentZoomSize"
  >
  </trakto-zoom>
  <trakto-page-navigation
    *ngIf="allowNewPages"
    [document]="document"
    [pagesThumbList]="document?.body"
    [selectedPageIndex]="selectedPageIndex"
    [ref]="refToUpdateSvgEditor"
    (onPageFocus)="goToPage($event)"
  >
  </trakto-page-navigation>
</div>

<trakto-modal-progress> </trakto-modal-progress>

<trakto-modal-loader> </trakto-modal-loader>
