<section
  traktoDragDrop
  (fileDropped)="onFileDropped($event)"
  class="trakto-file"
>
  <input
    #fileRef
    type="file"
    id="fileRef"
    multiple
    (change)="onFileDropped($event.target.files)"
  />
  <p>Arraste e solte sua fonte aqui <span>ou</span></p>
  <label for="fileRef"
    ><i class="trakto-icon-tkt-uploading"></i>
    <p>Escolha o arquivo</p></label
  >
</section>
