import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

import { DocumentManagerService } from '@services/document-manager.service';

import { PageService } from '@services/page.service';
import { IDocumentModel, PageModel } from '@trakto/models';

@Component({
  selector: 'trakto-vertical-page-nav',
  templateUrl: './vertical-page-nav.component.html',
  styleUrls: ['./vertical-page-nav.component.scss'],
})
export class VerticalPageNavComponent implements OnChanges {
  @Input()
  document: IDocumentModel;

  @Input()
  public pages: PageModel[];

  @Input()
  public loadFonts = true;

  @ViewChild('scrollArea')
  private scrollArea: ElementRef;

  public visiblePages = [];
  private thumbsEnds = [];

  public topHeight = -1;
  public bottomHeight = -1;
  private visibleThumbStartIndex = -1;
  private visibleThumbEndIndex = -1;

  constructor(
  ) {}

  ngOnChanges(): void {
    if (!this.pages) {
      return;
    }
    setTimeout(() => {
      this.changed();
      this.scroll();
      setTimeout(() => {
        this.changed();
        this.scroll();
      });
    }, 0);
  }

  @HostListener('window:resize')
  public resize() {
    this.changed();
    this.scroll();
  }

  private changed() {
    this.thumbsEnds = [];

    let thumbsWidthsSum = 0;

    this.pages.forEach((page: PageModel, i: number) => {
      thumbsWidthsSum += this.getPageHeight(i);
      this.thumbsEnds.push(thumbsWidthsSum);
    });
  }

  /**
   * This method calculates the visible page on user screen.
   * It only shows the visible pages, avoiding render all pages on browser.
   */
  public scroll() {
    const { scrollTop, clientHeight } = this.scrollArea.nativeElement;
    const visibleAreaFinalHeight = scrollTop + clientHeight;
    this.visibleThumbStartIndex = this.thumbsEnds.findIndex(
      thumbEnd => scrollTop <= thumbEnd,
    );

    this.visibleThumbEndIndex = this.thumbsEnds.findIndex(
      (thumbEnd, i) =>
        visibleAreaFinalHeight <= thumbEnd || i === this.thumbsEnds.length - 1,
    );

    this.visibleThumbStartIndex = Math.max(0, this.visibleThumbStartIndex - 3);
    this.visibleThumbEndIndex = Math.min(
      this.visibleThumbEndIndex + 2,
      this.thumbsEnds.length - 1,
    );
    this.visibleThumbStartIndex =
      this.visibleThumbStartIndex !== -1 ? this.visibleThumbStartIndex : 0;
    this.visibleThumbEndIndex =
      this.visibleThumbEndIndex >= this.visibleThumbStartIndex &&
      this.visibleThumbEndIndex < this.pages.length
        ? this.visibleThumbEndIndex
        : this.pages.length - 1;

    this.visiblePages = this.pages.slice(
      this.visibleThumbStartIndex,
      this.visibleThumbEndIndex + 1,
    );

    this.initTopHeight();
    this.initBottomHeight();
  }

  private initTopHeight() {
    if (!this.thumbsEnds[this.visibleThumbStartIndex]) {
      return;
    }
    this.topHeight =
      this.thumbsEnds[this.visibleThumbStartIndex] -
      this.getPageHeight(this.visibleThumbStartIndex);
  }

  private initBottomHeight() {
    if (!this.thumbsEnds[this.visibleThumbEndIndex]) {
      return;
    }
    const invisibleThumbs = this.pages
      .map((p, i) => i)
      .slice(this.visibleThumbEndIndex + 1, this.pages.length - 1);
    this.bottomHeight = invisibleThumbs.reduce(
      (p, c) => p + this.getPageHeight(c),
      0,
    );
  }

  public getZoomRatio(page: PageModel): number {
    return this.scrollArea.nativeElement.clientWidth / page.width;
  }

  private getPageHeight(pageIndex: number) {
    const page = this.pages[pageIndex];
    return this.getZoomRatio(page) * page.height;
  }
}
