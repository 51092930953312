<div #render>
  <svg
    *ngIf="item.type === 'svg'"
    xmlns="http://www.w3.org/2000/svg"
    class="trakto-item-renderer svg"
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    (click)="click()"
    (load)="onLoadMedia()"
  >
    <path [attr.d]="item.value" />
  </svg>

  <img
    *ngIf="item.type === 'image' && internalItem?.value"
    [src]="internalItem?.value"
    class="trakto-item-renderer"
    (click)="select(internalItem)"
    (load)="onLoadMedia()"
  />

  <img
  *ngIf="item.type === 'shape'"
  [src]="item.value"
  class="trakto-item-renderer"
  (load)="onLoadMedia()"
  (click)="select(item)"
/>

  <img
    *ngIf="item.type === 'emoji'"
    [src]="item.value"
    class="trakto-item-renderer"
    (load)="onLoadMedia()"
    (click)="select(item)"
    alt="item.type"
  />

  <div
    *ngIf="item.type === 'emoji-svg'"
    [innerHTML]="internalItem.value"
    (click)="select(item)"
    class="trakto-item-renderer"
  >
  </div>

  <img
    *ngIf="item.type === 'emoji-fav'"
    [src]="item.value"
    class="trakto-item-renderer"
    (click)="select(item)"
    (load)="onLoadMedia()"
  />
</div>
