import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import {
  IDocumentItem,
  IDocumentItemEvent,
} from './../../renderers/document-item-renderer/document-item.model';
import { IDocumentActions } from './document-actions.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Trakto Document List
 *
 * @usageNotes
 * One ype of data structure is allow:
 *
 * ```
 * this.dataProvider: DocumentItem[] = [
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   },
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   },
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   }
 * ];
 * ```
 * Use
 *
 * ```
 *  <trakto-document-list
 *      dataProvider]="dataProvider"
 *      (onClick)="onAction($event)"
 *      (onEdit)="onAction($event)"
 *      (onSelect)="onAction($event)"
 *      (onCopyLink)="onAction($event)"
 *      (onDownload)="onAction($event)"
 *      (onVisualize)="onAction($event)"
 *      (onClone)="onAction($event)"
 *      (onRemoveMultiple)="onAction($event)"
 *      (onRemove)="onAction($event)"></trakto-document-list>
 * ```
 */
@Component({
  selector: 'trakto-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements IDocumentActions, OnDestroy {
  public toggleSelectValue: boolean;
  public selectedItems: IDocumentItem[] = [];

  private _title: string;
  private _dataProvider: IDocumentItem[];

  @Input()
  public set dataProvider(value: IDocumentItem[]) {
    this._dataProvider = value;
  }

  public get dataProvider(): IDocumentItem[] {
    return this._dataProvider || [];
  }

  @Input()
  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title || 'Meus documentos';
  }

  @Output()
  public onClick: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onSelect: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onEdit: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onVisualize: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onCopyLink: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onDownload: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onRemove: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onClone: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onRemoveMultiple: EventEmitter<IDocumentItem[]> = new EventEmitter();

  private _destroy$ = new Subject<void>();

  constructor(private _httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  actionHandler(event: IDocumentItemEvent) {
    this[`_${event.type}`](event.item);
  }

  toggleSelecAll(selected: boolean) {
    this.selectedItems = [];

    this.dataProvider.forEach((item, index) => {
      this.dataProvider[index].selected = selected;
      this._toggleSelect(this.dataProvider[index]);
    });
  }

  removeSelectedItems() {
    this.selectedItems.forEach(item => {
      this.dataProvider.splice(this.dataProvider.indexOf(item), 1);
    });
    this.onRemoveMultiple.emit(this.selectedItems);
    this.resetItemSelection();
  }

  resetItemSelection() {
    this.selectedItems = [];
    this.toggleSelecAll(false);
  }

  private _toggleSelect(item: IDocumentItem) {
    if (item.selected) {
      this.selectedItems.push(item);
      this.onSelect.emit(item);
    } else {
      const index: number = this.selectedItems.indexOf(item);

      this.selectedItems.splice(index, 1);
      this.toggleSelectValue = false;
    }
  }

  private _click(item: IDocumentItem) {
    this.onClick.emit(item);
  }

  private _select(item: IDocumentItem) {
    this._toggleSelect(item);
  }

  private _edit(item: IDocumentItem) {
    this.onEdit.emit(item);
  }

  private _visualize(item: IDocumentItem) {
    window.open(item.link_path, '_blank');
    this.onVisualize.emit(item);
  }

  private _copyLink(item: IDocumentItem) {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = item.link_path;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.onCopyLink.emit(item);
  }

  private _download(item: IDocumentItem) {
    this._httpClient
      .get(item.link_path, {
        headers: new HttpHeaders().append(
          'Content-Type',
          'application/octet-stream',
        ),
        responseType: 'blob',
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        res => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = item.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        error => {
          console.error('download error:', JSON.stringify(error));
        },
        () => {
          this.onDownload.emit(item);
        },
      );
  }

  private _remove(item: IDocumentItem) {
    this.dataProvider.splice(this.dataProvider.indexOf(item), 1);
    this.onRemove.emit(item);
  }

  private _clone(item: IDocumentItem) {
    this.onClone.emit(item);
  }
}
