import { Injectable } from '@angular/core';
import { ElementModelService } from '@services/element-model.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import { UtilMagicTemplateGenerator } from '@trakto/magic-templates';

@Injectable()
export class UtilMagicTemplateService extends UtilMagicTemplateGenerator {
  constructor(
    private _newElementService: ElementModelService,
    private _uploadService: UploadStorageService,
    ) {
    super (_newElementService);
  }
}
