import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { Subject, Subscription } from 'rxjs';
import {
  ISearchEvent,
} from '@editor/components/properties-panel/panel-elements/model/model';
import { ElementNewApiService } from '@app/editor/services/element-new-api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IPack } from '@shared/elements/pack.entity';
import { ElementDatasource } from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { ISearchElementType } from '@app/editor/types/elements/element-search.type';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-formats-folders',
  templateUrl: './formats-folders.component.html',
  styleUrls: ['./formats-folders.component.scss'],
})
export class FormatsFoldersComponent implements OnInit, OnDestroy {
  @Output() searchTag: EventEmitter<ISearchEvent> =
    new EventEmitter<ISearchEvent>();
  public currentLang = 'pt-BR';
  public formatsFolders: IPack[];
  public isPanelExpanded = false;
  private _destroy$ = new Subject<void>();

  constructor(
    private _panelStackService: PanelStackService,
    private _elementNewApiService: ElementNewApiService,
    private _translateService: TranslateService,
  ) { }

  /**
   * Modifica valor da variável isPanelExpanded
   * quando painel é expandido ou retraído
   */
  ngOnInit(): void {

    this._elementNewApiService.getFamilies()
      .then((families) => this.formatsFolders = families);

    this._translateService.onLangChange.pipe(
      takeUntil(this._destroy$)).subscribe(
        (event: LangChangeEvent) => {
          this.currentLang = event.lang;
        },
      );

    this._panelStackService.onExpandPanelChange.pipe(
      takeUntil(this._destroy$)).subscribe(isExpanded => {
        this.isPanelExpanded = isExpanded;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Abre o painel de formatos com o formato desejado
   *
   * @param name string - Nome da tag que representa o formato
   */
  openFolder(info: IPack) {
    // FIXME: Ajustar a API para disponibilizar o tipo de elemento para que seja renderizada a listagem de acordo.
    this.searchTag.emit({
      query: null,
      type: SearchTypeEnum.FOLDER,
      name: info.name,
      familyId: info.id,
      datasource: ElementDatasource.TRAKTO,
      elementType: info.name.toLowerCase() === 'formas' ? ISearchElementType.PATH : ISearchElementType.SVG,
    });
  }
}
