<input
  type="range"
  name="zoom"
  id="zoom"
  [min]="min"
  [max]="max"
  [value]="value"
  class="range"
  (input)="change(range.value)"
  #range
/>
