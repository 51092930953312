import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ModalProgressComponent } from './modal-progress.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ModalProgressComponent],
  exports: [ModalProgressComponent],
})
export class ModalProgressModule {}
