import { Component, ElementRef, OnChanges, ViewChild, } from '@angular/core';

import { IDuotonesFilters, ImageElementModel, } from '@trakto/models';

import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { TranslateService } from '@ngx-translate/core';

import { FilterModel } from '@editor/model/filter.model';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-duotone',
  templateUrl: './panel-duotone.component.html',
})
export class PanelDuotoneComponent extends AbstractComponent<ImageElementModel> implements OnChanges {
  public isTranslate: any;
  public duotones: IDuotonesFilters[];
  public filter: FilterModel;
  public elementEmoji: string;

  @ViewChild('element') element: ElementRef;

  public set isEmoji(value: string) {
    this.elementEmoji = value;
  }

  public get isEmoji(): string {
    return this.elementEmoji;
  }

  constructor(
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
    // NOTE - Em caso de adição de Duotone adicionar também no serviço de datatracking.service
    this.duotones = [
      { label: 'Candy', value: 'candy' },
      { label: 'Miracle', value: 'miracle' },
      { label: 'Moonlight', value: 'moonlight' },
      { label: 'Nature', value: 'nature' },
      { label: 'Party', value: 'party' },
      { label: 'Sunset', value: 'sunset' },
      { label: 'Supreme', value: 'supreme' },
      { label: 'Premium', value: 'premium' },
      { label: 'Baby', value: 'baby' },
      { label: 'Millennium', value: 'millennium' },
      { label: 'New Life', value: 'new-life' },
      { label: 'Show', value: 'show' },
      { label: 'Wood', value: 'wood' },
      { label: 'Purple', value: 'purple' },
      { label: 'Mars Party', value: 'mars-party' },
      { label: 'Trakto', value: 'trakto' },
      { label: 'Plus', value: 'plus' },
      { label: 'Extra', value: 'extra' },
      { label: 'Fashion', value: 'fashion' },
      { label: 'Elegance', value: 'elegance' },
      { label: 'Summer', value: 'summer' },
      { label: 'Kiss', value: 'kiss' },
      { label: 'Night Party', value: 'night-party' },
      { label: 'Happy Memories', value: 'happy-memories' },
      { label: 'Soft', value: 'soft' },
      { label: 'Snow', value: 'snow' },
      { label: 'Wild', value: 'wild' },
      { label: 'Dirty Beauty', value: 'dirty-beauty' },
      { label: 'Grapes', value: 'grapes' },
      { label: 'Green Tritone', value: 'green-tritone' },
    ];

    this.makeTranslate();
  }

  ngOnInit() {
    super.ngOnInit();
    this.makeSvgEmoji();
  }

  public close() {
    this._panelStackService.pop(500);
  }

  ngOnChanges() {
    this.makeSvgEmoji();
  }

  public makeSvgEmoji(): any {
    if (this.elementSelected) {
      const parseSvg = new DOMParser();
      const svgElement = parseSvg.parseFromString(
        this.elementSelected.svg,
        'image/svg+xml',
      );

      const xml = new XMLSerializer().serializeToString(
        svgElement.documentElement,
      );
      const svg64 = btoa(xml);
      const b64start = 'data:image/svg+xml;base64,';
      const image64 = b64start + svg64;

      this.isEmoji = image64;
    }
  }

  private makeTranslate(): void {
    this.isTranslate = {
      title_config_image: '',
      title_config_page: '',
    };

    this._translateService.get('property_panel').pipe(takeUntil(this._destroy$)).subscribe((key: any) => {
      this.isTranslate.title_config_image =
        key['image']['effects']['gallery_title_image'];
      this.isTranslate.title_config_page = key['page']['title'];
    });
  }

  public setFilter(filter: string): void {
    if (!this.elementSelected && !this.pageSelected) {
      return;
    }
    if (this.elementSelected) {
      this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'filter', filter || 'empty');
    } else {
      this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'filter', filter || 'empty');
    }
  }
}
