import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { MetaTag } from './meta-tags';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  private urlMeta = 'og:url';
  private imageMeta = 'og:image';
  private titleMeta = 'og:title';
  private descriptionMeta = 'og:description';

  constructor(private _metaService: Meta) {}

  public setMetaTags(props: any): void {
    const metadata = {
      title: props.title || 'Trakto Design',
      description:
        props.description ||
        'Esse design foi criado na Trakto.io, crie um você também!',
      image: props.image || './assets/images/metatags_thumbs/big.png',
    };
    if (metadata) {
      const { title, description, image } = props;
      const tags = [
        new MetaTag(this.titleMeta, title),
        new MetaTag(this.descriptionMeta, description),
        new MetaTag(this.imageMeta, image),
      ];
      this.setTags(tags);
    }
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      this._metaService.updateTag({
        property: siteTag.name,
        content: siteTag.value,
      });
    });
  }
}
