import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-font-progress',
  templateUrl: './font-progress.component.html',
  styleUrls: ['./font-progress.component.scss'],
})
export class FontProgressComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
