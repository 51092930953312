import {
  Component, EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FontsService } from '@app/editor/services/fonts.service';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { PremiumService } from '@app/editor/services/premium.service';
import { PlanConfigService } from '@app/shared/subscription/plan-config.service';
import { IFontModel, ITextElementModel, PageModel } from '@trakto/models';
import { EnumModalComponent } from '@trakto/trakto-premium';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-my-fonts',
  templateUrl: './my-fonts.component.html',
  styleUrls: ['./my-fonts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyFontsComponent implements OnDestroy {
  public searchFontText: string;

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Output()
  selectedFont: EventEmitter<IFontModel> = new EventEmitter<IFontModel>();

  public fileDropped = false;
  public fonts: any[] = [];

  fontsSubscription: Subscription;

  constructor(
    private hotkeyService: HotkeyService,
    private _fontsService: FontsService,
    private _premiumService: PremiumService,
    private _planService: PlanConfigService,
    private _planConfigService: PlanConfigService,
  ) {
    this.listFonts();
  }

  ngOnDestroy(): void {
    this.fontsSubscription.unsubscribe();
  }

  public async onFile(files: any[]): Promise<void> {

    // FIXME: Criar a permissão correta.
    const hasPermission = await this._planConfigService.getPermissionPromise('use_template_premium');

    if (hasPermission) { //  TO-DO: Criar a permissão de fontes próprias
      this.fileDropped = true;
      try {
        await this._fontsService.uploadFonts(files);
        this.listFonts();
      } catch (e) {
        console.log(e);
      } finally {
        this.fileDropped = false;
      }
      return ;
    }

    const checkoutData = await this._premiumService.getCheckoutConfig();

    this._premiumService.openModalBlocking(
      EnumModalComponent.MODAL_FONTS,
      checkoutData
    );
  }

  public enableHotkeys() {
    this.hotkeyService.enableHotkeys();
  }

  public disableHotkeys() {
    this.hotkeyService.disableHotkeys();
  }

  public listFonts() {
    this.fontsSubscription = this._fontsService.listAllFonts().subscribe(
      () => (this.fonts = [...this._fontsService.collectionsFonts.userFonts]),
      error => console.error(error),
    );
  }
}
