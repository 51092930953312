import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFont' })
export class SearchFontPipe implements PipeTransform {
  transform(value: any, searchValue): any {
    const match = () =>
      value.filter(v => {
          const family = v.family || v.family_name;
          if (!family) { return false; }
          return family.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
        }
      );

    return !searchValue ? value : match();
  }
}
