import { Pipe, PipeTransform } from '@angular/core';
import { IPack } from '@app/shared/elements/pack.entity';

@Pipe({
  name: 'packname',
})
export class PacknamePipe implements PipeTransform {
  currentLang = 'pt-BR';

  transform(pack: IPack): string {
    if (!pack.languageNames) {
      return '-'
    }
    return pack.languageNames.find(
      p =>
        p.languageISOCode.toLowerCase() ===
        this.currentLang.toLowerCase().replace('-', '_'),
    )?.name || '-'
  }
}
