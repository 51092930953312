<div
  class="theme-results"
  [ngStyle]="{ right: isActive ? '0px' : '-800px' }"
  *ngIf="currentSearch?.pack"
  infiniteScroll
  (scrolled)="scrollToNextPage()"
  [scrollWindow]="false"
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="100"
  [infiniteScrollContainer]="'.theme-results'"
  [fromRoot]="true"
  traktoScrollListener
>
  <div
    class="theme-results__header"
  >
    <div class="theme-results__infos">
      <div class="theme-results__back" (click)="hideSearch()">
        <i
          class="trakto-icon-arrow-left"
        ></i>
      </div>
      <div
        class="theme-results__title"
      >
        <p class="theme-results__name">
          {{ currentSearch.pack.name | stringlimiter: enumCardTypes.PANEL:enumTextType.TITLE }}
        </p>
        <p class="theme-results__author">
          By
            {{ getAuthorsName(currentSearch.pack.authors)}}
        </p>
      </div>
    </div>
    <div class="theme-results__image">
      <img [src]="currentSearch.pack.thumbUrl" alt="" />
    </div>
  </div>

  <div class="theme-results__body">
    <trakto-tags-list
      [tags]="universeTags"
      [currentPanel]="searchType"
      (filterTag)="filterTags($event)"
      *ngIf="universeTags.length > 0"
    ></trakto-tags-list>

    <trakto-elements-list
      *ngIf="isActive"
      [currentSearch]="currentSearch"
      [isPanelExpanded]="isPanelExpanded"
      (requestElementInsertion)="assetSelected.emit($event)"
      [isBigger]="currentSearch.pack.big_grid_status"
    >
    </trakto-elements-list>
  </div>
</div>
