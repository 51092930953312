import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { PageModel } from "@trakto/models";
import { Observable } from "rxjs";

export interface IUnfold {
    page: PageModel;
    newDimension: {
        width: number,
        height: number
    };
    title: string;
    author: string;
    page_format_reference: any;
    locale?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UnfoldApiService {
    constructor(
        private httpCliente: HttpClient,
    ) { }

    unfold(toUnfold: IUnfold): Promise<any> {
        return this.httpCliente.post(
            `${environment.api.bellaUrl}/document/change-dimensions?quality=low&to_png=true`,
            [toUnfold]
        ).toPromise();
    }
}