<div class="search">
  <trakto-input
    [value]="inputText"
    placeholder="Pesquise por algo"
    (onChange)="setInputText($event)"
  ></trakto-input>
</div>

<trakto-tag-list
  [tags]="tags"
  (onSelect)="selectTag($event, false)"
></trakto-tag-list>

<div class="image-grid" [ngClass]="{ loading: loading }">
  <div class="image-grid-item" *ngFor="let image of images">
    <div class="image-grid-item-inner">
      <div class="gif" [style.backgroundImage]="'url(' + image.thumb + ')'" (click)="insertImage(image)"></div>
    </div>

    <div class="action" *ngIf="loading || hasNextPage">
      <trakto-button-loader [isLoading]="loading" (action)="getGifs()" label="Carregar mais"></trakto-button-loader>
    </div>
  </div>
</div>
