<div id="panel-button" class="panel">
  <mat-form-field>
    <mat-select
      [(value)]="elementSelected.text.fontFamily"
      placeholder="fontFamily"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (selectionChange)="changeProperty('text.fontFamily')"
    >
      <mat-option value="arial">Arial</mat-option>
      <mat-option value="verdana">Verdana</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="text"
      placeholder="text"
      name="text"
      [(ngModel)]="elementSelected.text.label"
      required
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('text.label')"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="number"
      placeholder="fontSize"
      name="fontSize"
      [(ngModel)]="elementSelected.text.fontSize"
      required
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('text.fontSize')"
    />
  </mat-form-field>

  <mat-checkbox
    [(ngModel)]="elementSelected.text.bold"
    [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
    (change)="changeProperty('text.bold')"
    >bold</mat-checkbox
  >
  <mat-checkbox
    [(ngModel)]="elementSelected.text.italic"
    [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
    (change)="changeProperty('text.italic')"
    >italic</mat-checkbox
  >

  <mat-form-field>
    <input
      matInput
      type="color"
      placeholder="color"
      name="color"
      [(ngModel)]="elementSelected.text.color"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('text.color')"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="text"
      placeholder="href"
      name="href"
      [(ngModel)]="elementSelected.href"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('href')"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-select
      [(value)]="elementSelected.target"
      placeholder="target"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (selectionChange)="changeProperty('target')"
    >
      <mat-option value="_self">Self</mat-option>
      <mat-option value="_blank">Blank</mat-option>
      <mat-option value="_top">Top</mat-option>
      <mat-option value="_parent">Parent</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="number"
      placeholder="radius"
      name="radius"
      min="0"
      [(ngModel)]="elementSelected.radius"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('radius')"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="color"
      placeholder="backgroundColor"
      name="backgroundColor"
      [(ngModel)]="elementSelected.backgroundColor"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('backgroundColor')"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="color"
      placeholder="strokeColor"
      name="strokeColor"
      [(ngModel)]="elementSelected.strokeColor"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('strokeColor')"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-select
      [(value)]="elementSelected.strokeStyle"
      placeholder="strokeStyle"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (selectionChange)="changeProperty('strokeStyle')"
    >
      <mat-option value="0, 0">Solid</mat-option>
      <mat-option value="5, 5">Dashed</mat-option>
      <mat-option value="3, 3">Dotted</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="number"
      placeholder="strokeWidth"
      name="strokeWidth"
      min="0"
      max="15"
      [(ngModel)]="elementSelected.strokeWidth"
      [disabled]="!elementSelected.edit || elementSelected.lockedByTemplate"
      (change)="changeProperty('strokeWidth')"
    />
  </mat-form-field>
</div>
