<div id="panel-group" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-documents"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.general.page_select' | translate }}
      </h2>
    </div>
  </div>

  <div class="flex flex-1 flex-direction-column overflow-hidden">
    <trakto-panel-actions
      [b2c]="b2c"
      [allowClip]="false"
      [isClipEditable]="false"
      [pageSelected]="pageSelected"
      [elementSelected]="groupSelected"
      [groupContainer]="groupSelected"
      [allowLockElementsByTemplate]="allowLockElementsByTemplate"
    >
    </trakto-panel-actions>

    <!-- <div *ngIf="!isEmbeddedInstance && b2c" class="panel-scroll__container">
      <div *ngIf="elementSelected.type === 'group'" class="panel-actions__desc">
        <div class="panel-actions__title-selections" style="padding: 90px 0; text-align: center;">
          <h3 style="font-family: 'Roboto-Regular'; font-size: 12px; font-weight: 400; margin: 0;">
            Quer fazer ainda mais com o Trakto? Assine <br> agora!
          </h3>
        </div>
        <div class="panel-actions__content">
          <trakto-button-premium>
            Quero ser Premium
          </trakto-button-premium>

          <img src="assets/images/image-premium-selection.svg" [style.marginTop.px]="16">
        </div>
      </div>
    </div> -->
  </div>
</div>
