<div class="trakto-preview-renderer" (click)="click($event)">
  <ng-container *ngIf="format.thumb">
    <img *ngIf="format.thumb.low" [src]="format.thumb.low" />
  </ng-container>

  <ng-container *ngIf="!format.thumb">
    <img src="https://dummyimage.com/100x100/000000/00000&amp;text=+" />
  </ng-container>

  <h4>{{ format.title }}</h4>
  <span>{{ format.width }} x {{ format.height }}px</span>

  <ul *ngIf="format.compability">
    <li *ngIf="format.compability.instagram">
      <i class="trakto-icon-social-instagram"></i>
    </li>
    <li *ngIf="format.compability.facebook">
      <i class="trakto-icon-social-facebook"></i>
    </li>
    <li *ngIf="format.compability.whatsapp">
      <i class="trakto-icon-social-whatsapp"></i>
    </li>
    <li *ngIf="format.compability.youtube">
      <i class="trakto-icon-social-youtube"></i>
    </li>
  </ul>
</div>
