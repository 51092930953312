<section class="tkt-social-slider">
  <div #socialSliderContent class="tkt-social-slider__content">
    <div
      *ngFor="let step of slideSteps; let idx = index"
      class="tkt-social-slider__content__step step-{{ idx }}"
      [ngClass]="{ 'active-step': idx === currentStep }"
      (click)="handleSliderClickTrack(step.cardType)"
    >
      <div class="tkt-social-slider__content__step__media">
        <!-- [ngStyle]="{ 'background-image': 'url(' + step.background + ')' }" -->
        <img [src]="step.background" alt="" />

        <!-- Facebook Card -->
        <div
          class="social-card-link__facebook"
          *ngIf="step.cardType === 'facebook'"
        >
          <div class="thumb">
            <img [src]="metaImage || defaultFBMetaImage || ''" alt="" />
          </div>
          <div class="link-metadata">
            <span class="link-name">{{ link }}</span>
            <span class="link-title">{{ metaTitle }}</span>
            <span class="link-description">
              {{ metaDescription }}
            </span>
          </div>
        </div>

        <!-- LinkedIn Card -->
        <div
          class="social-card-link__linkedin"
          *ngIf="step.cardType === 'linkedin'"
        >
          <div class="thumb">
            <img [src]="metaImage || defaultLinkedInMetaImage || ''" alt="" />
          </div>
          <div class="link-metadata">
            <span class="link-title">{{ metaTitle }}</span>
            <span class="link-description">{{ metaDescription }}</span>
          </div>
        </div>

        <!-- twitter card -->
        <div
          class="social-card-link__twitter"
          *ngIf="step.cardType === 'twitter'"
        >
          <div class="social-card-link__twitter__link-data">
            <img [src]="metaImage || defaultTwitterMetaImage || ''" alt="" />
            <div class="link-info">
              <span class="link-info__title"> {{ metaTitle }} </span>
              <span class="link-info__description">
                {{ metaDescription }}
              </span>
            </div>
          </div>
        </div>

        <!-- whatsapp card -->
        <div
          class="social-card-link__whatsapp"
          *ngIf="step.cardType === 'whatsapp'"
          (click)="handleSliderIconClickTrack(step.cardType)"
        >
          <div class="social-card-link__whatsapp__link-data">
            <img [src]="metaImage || defaultWPPMetaImage || ''" alt="" />
            <div class="link-info">
              <span class="link-info__title"> {{ metaTitle }} </span>
              <span class="link-info__description">
                {{ metaDescription }}
              </span>
            </div>
          </div>
          <p class="social-card-link__whatsapp__link-text">
            <span class="social-card-link__whatsapp__link-name">{{
              link
            }}</span>
            <span class="social-card-link__whatsapp__hour-check">
              <span class="hour">10:10</span>
              <span class="check">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.501 0.108955L14 0.59573L8.60042 8L6.41166 5.83835L7.33857 4.56645L8.60042 5.30239L13.501 0.108955ZM9.11925 0L9.61822 0.486775L4.21865 7.89105L0.5 4.23358L1.33033 3.42354L4.21865 5.19343L9.11925 0Z"
                    fill="#3497F9"
                  />
                </svg>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="tkt-social-slider__footer">
    <ul class="slider-controler">
      <li
        *ngFor="let step of slideSteps; let idx = index"
        [style.backgroundColor]="
          idx === currentStep ? step.iconBg : 'transparent'
        "
      >
        <i
          class="{{ step.icon }}"
          (click)="
            handleSliderIconClick(idx);
            handleSliderClickTrack(step.cardType, true)
          "
        ></i>
      </li>
    </ul>
  </div>
</section>
