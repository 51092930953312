import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TraktoSendGoogleAdsComponent } from './trakto-send-google-ads.component';
import { TranslateModule } from '@ngx-translate/core';
import { ResultUploadModalComponent } from './components/result-upload-modal/result-upload-modal.component';

@NgModule({
  declarations: [TraktoSendGoogleAdsComponent, ResultUploadModalComponent],
  imports: [CommonModule, TranslateModule],
  exports: [TraktoSendGoogleAdsComponent],
})
export class TraktoSendGoogleAdsModule {}
