<div *ngIf="elementSelected" id="panel-shape" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-shape"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.shape.title' | translate }}
      </h2>
    </div>
  </div>

  <div class="flex flex-1 flex-direction-column overflow-hidden">
    <trakto-panel-actions
      [b2c]="b2c"
      [allowClip]="false"
      [isClipEditable]="isCliping"
      [pageSelected]="pageSelected"
      [elementSelected]="elementSelected"
      [groupContainer]="groupSelected"
      [allowLockElementsByTemplate]="allowLockElementsByTemplate"
    >
    </trakto-panel-actions>

    <div class="panel-scroll__container">
      <div class="panel-background">
        <!-- Adicionar Efeitos -->
        <div class="panel__effects" *ngIf="!isCliping">
          <div class="panel-filter">
            <div class="panel-filter__item">
              <div class="panel-text__filter">
                <trakto-button-preview
                  height="109px"
                  [url]="svg"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  content="{{ 'property_panel.shape.change' | translate }}"
                  (onClick)="openElementsPanel()"
                >
                  <span>Insert</span>
                </trakto-button-preview>
              </div>
            </div>
            <div class="panel-filter__item">
              <trakto-color-button
                #colorpickerBackground
                *ngIf="!isCliping"
                [id]="elementSelected.id"
                [icon]="'trakto-icon-color-fill'"
                [height]="'109px'"
                [color]="elementSelected.backgroundColor"
                content="{{ 'property_panel.shape.color' | translate }}"
                (openColorPanel)="openPanelStack('color', $event)"
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isCliping
                "
              >
              </trakto-color-button>
            </div>
            <div *ngIf="!isEmbeddedInstance" class="panel-filter__item">
              <trakto-button-preview
                height="109px"
                [disabled]="true"
                content="{{ 'property_panel.shape.image' | translate }}"
                icon="trakto-icon-image-simple"
                (onClick)="openModal({ id: 'trakto-modal-emoji' })"
              >
                <span>Insert</span>
              </trakto-button-preview>
            </div>
          </div>
        </div>

        <!--@ EFFECT -->
        <div class="panel__effects">
          <h2 class="panel__title" [class.panel__title--disabled]="isCliping">
            <i class="trakto-icon-icon-effect"></i>
            {{ 'property_panel.text.effects.title' | translate }}
          </h2>

          <div class="panel-filter">
            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isCliping
                "
                (onClick)="setFilter('empty')"
              >
                <i class="trakto-icon-empty"></i>
                <span>{{
                  'property_panel.shape.effects.types.no_filter' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isCliping
                "
                (onClick)="setFilter('gaussianblur')"
              >
                <i class="trakto-icon-filter-blur"></i>
                <span>{{
                  'property_panel.shape.effects.types.blur' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--xxlarge
                button--outline
                [disabled]="
                  !elementSelected.edit ||
                  elementSelected.lockedByTemplate ||
                  isCliping
                "
                (onClick)="setFilter('dropshadow')"
              >
                <i class="trakto-icon-filter-shadow"></i>
                <span>{{
                  'property_panel.shape.effects.types.shadow' | translate
                }}</span>
              </trakto-button>
            </div>
          </div>
        </div>

        <div class="panel__effects">
          <h2 class="panel__title" *ngIf="elementLinkIsAvailable">
            <i class="trakto-icon-link"></i>
            {{ 'property_panel.general.add_link_title' | translate }}
          </h2>

          <div
            class="panel-filter"
            [style.paddingBottom.px]="20"
            *ngIf="elementLinkIsAvailable"
          >
            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--small
                button--outline
                [disabled]="elementSelected.whatsapp || elementSelected.email"
                (onClick)="openPanelStack('panel-website')"
              >
                <i class="trakto-icon-worldwide"></i>
                <span>{{
                  'property_panel.icon.panel.add_link.website' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--small
                button--outline
                [disabled]="elementSelected.link || elementSelected.email"
                (onClick)="openPanelStack('panel-whatsapp')"
              >
                <i class="trakto-icon-social-whatsapp"></i>
                <span>{{
                  'property_panel.icon.panel.add_link.whatsapp' | translate
                }}</span>
              </trakto-button>
            </div>

            <div class="panel-filter__item">
              <trakto-button
                button--custom
                button--small
                button--outline
                [disabled]="elementSelected.link || elementSelected.whatsapp"
                (onClick)="openPanelStack('panel-email')"
              >
                <i class="trakto-icon-email"></i>
                <span>{{
                  'property_panel.icon.panel.add_link.e-mail' | translate
                }}</span>
              </trakto-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
