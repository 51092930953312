import { Injectable } from '@angular/core';
import { DocumentService } from '@app/editor-v3/services/DocumentService';
import { PageModel } from '@trakto/models';

export const CLASSES = {
  DRAGGED: 'dragged',
  HOVERED: 'hovered',
};

interface IPageElement {
  page: PageModel;
  element: HTMLElement;
}

@Injectable({
  providedIn: 'root',
})
export class DragDropService {
  private _pages: IPageElement[] = [];

  draggedPageElement: HTMLElement = null;
  touchedPageElement: HTMLElement = null;
  hoveredPageElement: HTMLElement = null;

  shouldVerifyMovePressing: boolean = false;
  shouldAllowEffects: boolean = false;
  timeoutVerification: any = null;

  constructor(private _documentService: DocumentService) {}

  storePage(element: HTMLElement, page: PageModel) {
    this._pages.push({
      page,
      element,
    });
  }

  removePage(page: PageModel) {
    const newList = this._pages.filter(
      pageElement => pageElement.page.id !== page.id,
    );
    this._pages = [...newList];
  }

  setDraggedPage(page: HTMLElement) {
    this.draggedPageElement = page;
    page.classList.add(CLASSES.DRAGGED);
  }

  clearDraggedPage() {
    if (this.draggedPageElement) {
      this.draggedPageElement.classList.remove(CLASSES.DRAGGED);
      this.draggedPageElement = null;
    }
  }

  clearAllEffects() {
    this._pages.forEach(pageElement => this.clearEffects(pageElement.element));
  }

  clearEffects(el: HTMLElement) {
    if (el.classList) el.classList.remove(CLASSES.HOVERED);
  }

  hoverEffect(el: HTMLElement, page: PageModel) {
    if (el.classList.contains('page')) {
      this._pages.forEach(pageElement => {
        if (pageElement.page.id !== page.id) {
          this.clearEffects(pageElement.element);
        }
      });

      el.classList.add(CLASSES.HOVERED);
    }
  }

  findPageModel(page: HTMLElement): IPageElement {
    return this._pages.find(pageElement => pageElement.element === page);
  }

  getTouchedPageElement(x: number, y: number): IPageElement | false {
    const elements = document.elementsFromPoint(x, y);
    const page = elements.find(el => el.classList.contains('page'));

    return page ? this.findPageModel(page as HTMLElement) : false;
  }

  handlePagePosition(e: DragEvent): boolean {
    const { clientX, clientY } = e;
    const droppedIntoPage = this.getTouchedPageElement(clientX, clientY);

    if (!droppedIntoPage) return false;

    const draggedPageModel = this.findPageModel(this.draggedPageElement);

    if (!draggedPageModel) return false;

    if (droppedIntoPage.element === draggedPageModel.element) return false;

    this.changePages(draggedPageModel.page, droppedIntoPage.page);
  }

  handleTouchStart(e: TouchEvent) {
    const { clientX, clientY } = e.changedTouches[0];
    const touchedElement = this.getTouchedPageElement(clientX, clientY);

    if (!touchedElement) return false;

    this.touchedPageElement = touchedElement.element;
    this.hoveredPageElement = touchedElement.element;

    this.timeoutVerification = setTimeout(() => {
      this.verifyDelayedTouch();

      if (this.shouldAllowEffects) {
        this.setDraggedPage(this.touchedPageElement);
      }
    }, 1000);
  }

  handleTouchMove(e: TouchEvent) {
    const { clientX, clientY } = e.changedTouches[0];
    const hoveredElement = this.getTouchedPageElement(clientX, clientY);
    if (hoveredElement) this.hoveredPageElement = hoveredElement.element;

    this.clearAllEffects();

    if (this.shouldVerifyMovePressing && hoveredElement) {
      if (this.isTouchingInSamePage()) {
        this.hoverEffect(this.hoveredPageElement, hoveredElement.page);
      } else {
        this.shouldAllowEffects
          ? this.hoverEffect(this.hoveredPageElement, hoveredElement.page)
          : this.clearTouchAnalysis();
      }
    }
  }

  handleTouchEnd() {
    if (this.shouldAllowEffects && this.hoveredPageElement) {
      const from = this.findPageModel(this.draggedPageElement).page;
      const to = this.findPageModel(this.hoveredPageElement).page;
      this.changePages(from, to);
    }

    this.clearTouchAnalysis();
  }

  clearTouchAnalysis() {
    this.shouldVerifyMovePressing = false;
    this.touchedPageElement = null;
    this.hoveredPageElement = null;
    this.shouldAllowEffects = false;
    this.clearDraggedPage();
    this.clearAllEffects();

    if (this.timeoutVerification) {
      clearTimeout(this.timeoutVerification);
      this.timeoutVerification = null;
    }
  }

  verifyDelayedTouch() {
    this.shouldVerifyMovePressing = true;
    if (this.isTouchingInSamePage()) this.shouldAllowEffects = true;
  }

  isTouchingInSamePage() {
    return this.hoveredPageElement === this.touchedPageElement;
  }

  changePages(from: PageModel, to: PageModel) {
    this._documentService.changePagesPositions(from, to);
  }
}
