import { Injectable } from '@angular/core';
import { elementTypeEnum } from '@trakto/models';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: ModalComponent[] = [];

  constructor() {}

  public callback?: (p?: any) => any;

  add(modal: ModalComponent) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(
    id: string,
    maximized: boolean = false,
    callback?: (p?: any) => any,
  ): any {
    const modalElementsTypes = {
      'trakto-modal-emoji': elementTypeEnum.emoji,
      'trakto-modal-gif': elementTypeEnum.gif,
      'trakto-modal-image': elementTypeEnum.image,
      'trakto-modal-shape': elementTypeEnum.shape,

      // FIXME: adicionar no elementTypeEnum em @trakto/models
      'trakto-modal-sticker': 'sticker',
    };

    this.callback = callback;

    const modal: any = this.findModal(id);

    if (modal) {
      modal.open();
      if (maximized) {
        modal.maximize();
      }

      return;
    }

    console.warn(`Nenhum modal foi encontrado com esse id: #${id}.`);
  }

  close(id: string, event?: any) {
    const modal: any = this.findModal(id);

    if (this.callback) {
      this.callback(event);
    }

    modal.close();
  }

  closeAll(): void {
    this.modals.map((modal: ModalComponent) => modal.close());
  }

  findModal(id: string): any {
    if (this.modals.filter(x => x.id === id).length !== 1) {
      return;
    }

    return this.modals.filter(x => x.id === id)[0];
  }
}
