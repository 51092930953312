import { NgModule } from '@angular/core';

import { BadgeDirective } from '@shared/directives/badge.directive';
import { HoverUtilDirective } from '@shared/directives/hover-util.directive';
import { ScrollbarDirective } from '@shared/directives/scroll.directive';
import { SvgFilterDirective } from '@shared/directives/svg-filter.directive';
import { SvgTextEditorDirective } from '@shared/directives/svg-text-editor.directive';
import { SvgTextEditorContainerDirective } from '@shared/directives/SvgTextEditorContainerDirective';

const Modules = [
  BadgeDirective,
  HoverUtilDirective,
  SvgFilterDirective,
  ScrollbarDirective,
  SvgTextEditorDirective,
  SvgTextEditorContainerDirective,
];

@NgModule({
  declarations: Modules,
  exports: Modules,
})
export class TraktoDirectivesModule {}
