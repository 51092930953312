import { IDocumentModel } from '@trakto/models';

export class SaveFinishedEventModel {
  constructor(
    public document: IDocumentModel,
    public saved: boolean,
    public isNew: boolean,
    public firstPageChanged: boolean,
    public requestCoverGeneration: boolean,
  ) {}
}
