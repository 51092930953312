<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/ajust_text.svg"
                              label="Cores"
                              class="click-ignore"
                              [showBackLabel]="true"
                              (onClose)="closePanel()"></trakto-panel-header-mobile>

  <trakto-panel-color
    class="click-ignore"
    (colorChange)="changeColor($event)"
    [isMobile]="true">
  </trakto-panel-color>
</div>
