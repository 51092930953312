<div class="workspace" id="pagesContainer">
  <div class="design">
    <div class="canva">
      <div class="background-canva">
        <trakto-svg-editor
          *ngIf="selectedPage"
          [ref]="ref"
          [page]="selectedPage"
          [elements]="selectedPage?.elements"
          [zoomRatio]="zoomRatio"
          [isActivated]="true"
          [hasWaterMask]="hasWaterMask"
          [printableMode]="document?.is_printable"
          (onSelectedElement)="selectElement($event)"
          (onFocusedElement)="focusElement($event)"
          (onPageClicked)="openPagePanelProperties()"
          (onPageAdditionRequest)="addPage()"
          (onPageCloneRequest)="clonePage()"
          (onPageDeletionRequest)="deletePage()"
          (onElementDeletionRequest)="deleteElement($event)"
          (drop)="drop($event, selectedPage)"
          (dragover)="dragOver($event)"
          (historyUndo)="historyUndo($event)"
          (changed)="persistElementsChanges($event)"
        >
        </trakto-svg-editor>
      </div>
    </div>
  </div>
</div>

<trakto-page-navigator></trakto-page-navigator>
