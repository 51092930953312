import { Injectable } from '@angular/core';
import { TraktoApiService } from './trakto-api.service';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { LocaleService } from './locale.service';
import {
  ElementsResponse,
  IElementNewAPI
} from '@shared/elements/element.entity';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { TranslateService } from '@ngx-translate/core';
import { ElementDatasource } from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import {
  FlaticonElementColor,
  FlaticonElementType,
  FlaticonStyle
} from '@editor/types/elements/flaticons.type';
import { ISearchElementType } from '@editor/types/elements/element-search.type';
import { GalleryElementType } from '@editor/enums/editor-elements/gallery-type.enum';
import {
  FlaticonAPIElement,
  FlaticonAPIResponse
} from '@editor/model/property-panels/elements/flaticon-api.model';
import { enumTabs } from '../components/properties-panel/panel-elements/search-results/search-results.component';

@Injectable({
  providedIn: 'root',
})
export class ElementNewApiService {
  constructor(
    private _traktoApiService: TraktoApiService,
    private _translateService: TranslateService,
    private _localeService: LocaleService,
  ) {
  }

  async getPacks(): Promise<any> {
    return this._traktoApiService.executeGet(
      'v2/integrations/assets/packs',
      {
        lang: this._getLangISOCode(),
        publishLanguage: this._getLangISOCode(),
      },
    );
  }

  getElementByPackId(packId, page_size = 42, current_page = 0): Promise<IElementNewAPI[]> {
    return this._traktoApiService.executeGet(`v2/integrations/assets/elements?count=${page_size}&page=${current_page}&packId=${packId}`);
  }

  getElementByTags(tags: any[]): Promise<IElementNewAPI[]> {
    return this._traktoApiService.executeGet(`v2/integrations/assets/elements?tags=${tags}`);
  }

  getElementByElementId(elementId): Promise<IElementNewAPI[]> {
    return this._traktoApiService.executeGet(`v2/integrations/assets/elements/${elementId}`);
  }

  getFamilies(): Promise<any> {
    return this._traktoApiService.executeGet(
      'integrations/assets/families',
      { lang: this._getLangISOCode() },
    );
  }

  async fetchElements(search: ISearchEvent, page_size = 42, current_page = 0, currentSource = ElementDatasource.TRAKTO): Promise<ElementsResponse> {
    if (search.type === 'theme') {
      const elements = await this.getElementByPackId(search.pack.id, page_size, current_page);
      return {
        elements,
        nextSource: ElementDatasource.TRAKTO,
        nextPage: elements.length >= page_size ? current_page + 1 : null,
      };
    }
    if (!currentSource || currentSource === ElementDatasource.TRAKTO) {
      const elements =  await this._fetchElementsFromTrakto(search, page_size, current_page);
      if (elements.length !== page_size) {
        const flaticonsElements = await this._doFetchElementsFromFlaticons(search, page_size, current_page);
        return {
          nextSource: ElementDatasource.FLATICON,
          nextPage: flaticonsElements.length >= page_size ? 2 : null,
          elements: elements.concat(flaticonsElements)
        }
      }
      return {
        elements,
        nextSource: ElementDatasource.TRAKTO,
        nextPage: current_page + 1,
      };
    } else {
      const elements = await this._doFetchElementsFromFlaticons(search, page_size, current_page);
      return {
        elements,
        nextSource: ElementDatasource.FLATICON,
        nextPage: elements.length >= page_size ? current_page + 1 : null,
      }
    }
  }

  getGalleryTypeOfElement(contentType: ISearchElementType): GalleryElementType {
    if (contentType === ISearchElementType.SVG)  return GalleryElementType.EMOJI
    if (contentType === ISearchElementType.PATH) return GalleryElementType.SHAPE;

    return GalleryElementType.IMAGE;
  }

  getElementTypeByContentType(contentType: GalleryElementType): ISearchElementType {
    switch (contentType) {
      case GalleryElementType.EMOJI: return ISearchElementType.SVG;
      case GalleryElementType.SHAPE: return ISearchElementType.PATH;
      case GalleryElementType.IMAGE: return ISearchElementType.IMAGE;
      default: return ISearchElementType.IMAGE;
    }
  }

  getTags(searchEvent: ISearchEvent): Promise<ILocaleTag[]> {
    const query = [`lang=${this._getLangISOCode()}`];

    if (searchEvent.query) {
      query.push(`search=${searchEvent.query}`);
    }

    if (searchEvent.elementType) {
      query.push(`element_type=${searchEvent.elementType}`);
    }

    if (searchEvent.pack) {
      query.push(`pack_id=${searchEvent.pack.id}`);
    }

    return this._traktoApiService
      .executeGet(`v2/integrations/assets/tags?count=42&${query.join('&')}`)
      .then((result: ILocaleTag[]) => (result || []).filter(tag => tag.name));
  }

  private async _fetchElementsFromTrakto(params: ISearchEvent, page_size = 42, current_page = 0): Promise<IElementNewAPI[]> {
    const query = [
      `count=${page_size}`,
      `page=${current_page}`
    ];

    const { elementType = ISearchElementType.SVG, tags = [], familyId } = params;
    const payload = {
      elementType: elementType,
      tags,
      familyId
    } as ISearchEvent;

    if (payload.familyId) delete payload.elementType;

    if (payload.tags?.length > 0 && payload.tags[0]?.name) query.push(`search=${payload.tags[0].name}`);
    if (payload.familyId) query.push(`familyId=${payload.familyId}`);
    if (payload.elementType) query.push(`contentType=${payload.elementType}`);

    const response: Array<any> = await this._traktoApiService.executeGet(`v2/integrations/assets/elements?${query.join('&')}`);
    return response.map((element: IElementNewAPI) => ({
      ...element,
      source: ElementDatasource.TRAKTO,
    }));
  }

  getTypeFromFlaticons(elementType) {
    switch (elementType) {
      case ISearchElementType.IMAGE:
      case ISearchElementType.SVG: elementType = enumTabs.ICONS; break;
      case ISearchElementType.PATH: elementType = enumTabs.SHAPES; break;
      default: elementType = enumTabs.ICONS; break;
    }
    return elementType;
  }

  private _doFetchElementsFromFlaticons(search: ISearchEvent, page_size = 42, current_page = 0): Promise<IElementNewAPI[]> {
    const page = current_page == 0 ? 1 : current_page;
    // TODO: Remove this when flaticon will support svg to download
    // const elementType = this.currentSearch.elementType === 'emoji' ? 'icon' : 'shape';
    // const color = this.currentSearch.elementType === ISearchElementType.SVG ? 'color' : 'black';
    const elementType = this.getTypeFromFlaticons(search.elementType);
    const color = 'color';
    return this._fetchFlaticonElements(
      search.tags[0].name,
      page,
      page_size,
      'fill',
      elementType,
      color).catch(() => []);
  }

  private async _fetchFlaticonElements(
    keyword: string,
    page: number,
    limit: number,
    style: FlaticonStyle,
    elementType: FlaticonElementType,
    color: FlaticonElementColor): Promise<IElementNewAPI[]> {
    const query = [
      `keyword=${keyword}`,
      `style=${style}`,
      `element_type=${elementType}`,
      `page=${page++}`,
      `limit=${limit}`,
      `color=${color}`,
    ];
    const response: FlaticonAPIResponse = await this._traktoApiService
      .executeGet(`/integrations/assets/flaticons/elements/search?${query.join('&')}`);
    const { elements } = response;
    return elements.map((element: FlaticonAPIElement) => ({
      id: element.id,
      elementUrl: elementType !== 'icon' ? element.download_url.svg : element.download_url.image,
      visual_tags: element.tags,
      created_at: element.created_at,
      contentType: ISearchElementType.IMAGE, // TODO: Change this when we have SVG support
      thumbUrls: {
        low: element.download_url.image,
        medium: element.download_url.image,
        high: element.download_url.image,
      },
      source: ElementDatasource.FLATICON
    }));
  }

  private _getLangISOCode() {
    return this._localeService.currentLang.replace('-', '_').toUpperCase();
  }
}
