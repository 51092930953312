import { Component, OnInit } from '@angular/core';
import { LoadModalService } from '@app/editor-v3/services/loader-modal.service';
import { PanelService } from '@app/editor-v3/services/panel.service';

type IGifTabTypes = 'search' | 'library' | 'search-stickers';


@Component({
  selector: 'trakto-panel-gif-mobile',
  templateUrl: './panel-gif-mobile.component.html',
  styleUrls: ['./panel-gif-mobile.component.scss']
})
export class PanelGifMobileComponent implements OnInit {
  activeTab: IGifTabTypes = 'search';

  constructor(
    private _panelService: PanelService,
    private _loadModalService: LoadModalService,
  ) { }

  ngOnInit(): void { }

  setActiveTab(tab: IGifTabTypes) {
    this.activeTab = tab;
  }

  openModalLoadGif() {
    this._loadModalService.show({
      title: 'Carregando'
    });
  }


  closePanel() {
    this._panelService.closeLastPanel();
  }
}
