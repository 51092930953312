<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile img="assets/editor-v3/images/insert_text.svg" label="Inserir texto"
    [showBackLabel]="true" (onClose)="closePanel()"></trakto-panel-header-mobile>

  <div class="wrapper">
    <div class="option" (click)="addText('title')">
      <span class="label" style="font-size: 18px; font-weight: 700;">Título</span>
    </div>
    <div class="option" (click)="addText('description')">
      <span class="label" style="font-size: 14px; font-weight: 500;">Descrição</span>
    </div>
    <div class="option" (click)="addText('text')">
      <span class="label" style="font-size: 12px; font-weight: 400;">Parágrafo</span>
    </div>
  </div>
</div>
