import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { ElementNewApiService } from '@services/element-new-api.service';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { ElementsGalleryService } from '@shared/elements-gallery/elements-gallery.service';

@Component({
  selector: 'trakto-formats-results',
  templateUrl: './formats-results.component.html',
  styleUrls: ['./formats-results.component.scss'],
})
export class FormatsResultsComponent implements OnInit {
  searchEvent: ISearchEvent = null;

  searchText: string = '';
  queryText: string = '';
  showSuggestions: boolean = false;
  filteredTags: string[] = [];
  tags: string[] = [];

  constructor(
    private _panelService: PanelService,
    private _elementsGalleryService: ElementsGalleryService,
    private _elementsService: ElementsService,
    private _elementNewApiService: ElementNewApiService
  ) {}

  ngOnInit(): void {
    this.searchEvent = this._elementsGalleryService.store;
    this.initTags();
  }

  async initTags() {
    const tags = await this._elementNewApiService.getTags(this.searchEvent);
    this.tags = tags.map(tag => tag.name);
    this.filteredTags = [];
  }

  setInputText(val: string) {
    this.searchText = val;
    this.setFilteredTags();
    this.showSuggestions = val.length > 0;

    if (val.length === 0) this.selectTag('');
  }

  setShowSuggestions(val: boolean) {
    setTimeout(() => {
      this.showSuggestions = val;
    }, 50);
  }

  setFilteredTags() {
    this.filteredTags = this.tags.filter(
      tag => tag.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1,
    );
  }

  selectTag(tag: string) {
    this.searchText = this.queryText === tag ? '' : tag;
    this.queryText = this.searchText;
    this.searchEvent.tags = [{
      name: tag,
      languageISOCode: 'pt-BR',
    }];
    this.setShowSuggestions(false);
    this.searchEvent = {...this.searchEvent};
  }

  closePanel() {
    this._elementsGalleryService.resetPanelStore();
    this._panelService.closeLastPanel();
  }
}
