<div class="button button-gradient alt" (click)="uploader.click()" type="file">
  <img src="{{img}}" alt="">

  <p *ngIf="isLoading"><i class="icon icon-loading"></i></p>
  <p *ngIf="!isLoading">{{label}}</p>
</div>

<input hidden type="file" #uploader (change)="uploadFile($event)" />

<trakto-modal-loader *ngIf="isLoading"></trakto-modal-loader>
