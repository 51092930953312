<div
  class="formats-folders"
  [ngClass]="{ 'formats-folders--expanded': isPanelExpanded }"
>
  <div
    class="format-folder"
    *ngFor="let folder of formatsFolders"
    (click)="openFolder(folder)"
  >
    <img
      class="format-folder__icon"
      [src]="folder.thumbUrl"
      alt=""
    />
    <span class="format-folder__title">
      {{folder.name}}
    </span>
  </div>
</div>
