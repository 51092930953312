import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-render-svg-icon',
  templateUrl: './render-svg-icon.component.html',
  styleUrls: ['./render-svg-icon.component.scss'],
})
export class RenderSvgIconComponent {
  @Input() svg: string;

  constructor(private _element: ElementRef) {}
}
