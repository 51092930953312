<div #scrollArea (scroll)="scroll()" class="scroll-area">
  <div [style.min-height.px]="topHeight"></div>
  <trakto-svg-viewer
    *ngFor="let page of visiblePages; let i = index"
    [page]="page"
    [fullViewMode]="true"
    [loadFonts]="loadFonts"
    [openYoutube]="true"
    [document]="document"
    [ref]="page.elements"
    [zoomRatio]="getZoomRatio(page)"
    class="scroll-item__page"
  ></trakto-svg-viewer>
  <div [style.min-height.px]="bottomHeight"></div>
</div>
