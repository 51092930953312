import { Injectable } from '@angular/core';

import { IProducts, IThemeStorageModel } from '@trakto/models';
import { BehaviorSubject, Subject } from 'rxjs';

import { PresentationModeThemeEnum } from '../enums/presentation-mode/presentation-mode-theme';
import { WhitelabelService, WhitelabelChange } from './whitelabel-config/whitelabel.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public brandColorsPalette = [];
  public hasDefaultColorPalette = true;

  private _theme: IThemeStorageModel;
  private _style: HTMLStyleElement;

  private subject: Subject<any>;

  public default: IThemeStorageModel = {
    title: 'Default',
    logo: {
      black: './assets/images/trakto-minimal.svg',
      grey: './assets/images/trakto-minimal.svg',
      colored: './assets/images/trakto-minimal.svg',
    },
    color: {
      primary: '#282828',
      secondary: '#00b0ff',
      third: '#0098dd',
      quaternary: '#000000',
      background_logo: '#ffffff',
    },
  };

  public whiteThemeForPresentationMode: IThemeStorageModel = {
    title: PresentationModeThemeEnum.whiteTheme,
    logo: {
      black: './assets/images/trakto-minimal.svg',
      grey: './assets/images/trakto-minimal.svg',
      colored: './assets/images/trakto-minimal.svg',
    },
    color: {
      primary: '#FFFFFF',
      secondary: '#F5F8FC',
      third: '#12002E',
      quaternary: '#DAE9FD',
      background_logo: '#ffffff',
    },
  };

  public blackThemeForPresentationMode: IThemeStorageModel = {
    title: PresentationModeThemeEnum.blackTheme,
    logo: {
      black: './assets/images/trakto-minimal.svg',
      grey: './assets/images/trakto-minimal.svg',
      colored: './assets/images/trakto-minimal.svg',
    },
    color: {
      primary: '#16003B',
      secondary: '#12002E',
      third: '#FFFFFF',
      quaternary: '#DAE9FD',
      background_logo: '#ffffff',
    },
  };

  constructor(
    private _whitelabelService: WhitelabelService,
  ) {
    if (!this._style) {
      this._style = document.createElement('style');
      this._style.setAttribute('id', 'theme');

      document.getElementsByTagName('head')[0].appendChild(this._style);
    }
    this.subject = new BehaviorSubject(undefined);
    this._listenWhitelabelChanges();
  }

  private _listenWhitelabelChanges() {
    this._whitelabelService.whitelabelChange.subscribe((change: WhitelabelChange) => {
      this.setTheme({
        title: change.currentProduct.title,
        logo: change.currentProduct.logo,
        color: change.currentProduct.color
      } as IThemeStorageModel);
      this.configUserProducts(change.currentProduct);
    });
  }

  private configUserProducts(product: IProducts) {
    const colors = product.brand_colors || [];
    const hasDefaultColorsPalette = product.hasOwnProperty(
      'has_default_colors_palette',
    )
      ? !!product['has_default_colors_palette']
      : true;

    this.setDefaultColorsEnable(hasDefaultColorsPalette);
    this.setBrandColorsPalette(colors);
  }

  public getBrandColorsPalette() {
    return this.brandColorsPalette;
  }


  public setBrandColorsPalette(value: any[]) {
    this.brandColorsPalette = [].concat(this.brandColorsPalette, value);
  }

  public getDefaultColorsEnable(): boolean {
    return this.hasDefaultColorPalette;
  }

  public setDefaultColorsEnable(value: boolean) {
    this.hasDefaultColorPalette = value;
  }

  public setTheme(value: IThemeStorageModel) {
    this._theme = value;
    this.apply();
    this.subject.next(value);
  }

  public setThemePresentation(value: IThemeStorageModel) {
    this._theme = value;
    this.applyThemePresentation();
  }

  public getTheme(): IThemeStorageModel {
    return this._theme;
  }

  public getObservableTheme(): Subject<any> {
    return this.subject;
  }

  /**
   * Metodo responsável por obter tema do produto atual no cookie do navegador.
   * @param keyCookie - Chave da descrição do cookie.
   */
  public getThemeByCookie(keyCookie: string): IThemeStorageModel {
    const getThemeCookie = document.cookie.match(
      new RegExp(keyCookie + '=([^;]+)'),
    );
    const safeTheme = getThemeCookie
      ? JSON.parse(getThemeCookie[1])
      : this.default;
    const productTheme = this._buildThemeStructure(safeTheme);

    return productTheme;
  }

  private apply(): void {
    const color = this._theme.color;
    const styles = `
      .trakto-header { background-color: ${color.primary} !important; }
      .trakto-template-product__title { color: ${color.quaternary} !important; }
      .trakto-header__logo { background: ${color.background_logo} !important; }
      .trakto-header__interactions .button--download { background: #FFFFFF !important; color: ${color.primary}; display: flex;  }
      .trakto-header__interactions .button--outline { border-color: #FFFFFF !important; }
      .button--checkout { background: #FFE282 !important; box-shadow: 0 2px 0 #FFAB64; color: #263056 !important; border-radius: 5px !important; transition: .4s cubic-bezier(.685,.0473,.346,1); }
      .button--checkout:active { box-shadow: 0 2px #FFB600; transform: translateY(4px); }

      .panel-actions__buttons .button--small:hover:not([disabled]) { background-color: ${color.secondary} !important; box-shadow: 0px 3px 0px ${color.primary} !important; }

      button.button.button-theme--modify.button--download.button--bold { background: ${color.primary} !important; }
      .button.button--icon-large.button--outline.button--white:hover,
      .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb,
      .remove-bg__progress > div { background-color: ${color.secondary} !important;}
      #panel-background__content-image { fill: ${color.secondary}; }

      #panel-background__remove button { background: ${color.primary} !important; border-bottom: ${color.secondary} !important; }
      .button--paddin-link { background-color: ${color.secondary} !important; box-shadow: 0px 3px 0px ${color.primary} !important; }
      .button--paddin-link:hover { background-color: ${color.primary} !important; box-shadow: 0px 3px 0px ${color.secondary} !important; }

      .mat-ripple-element { background-color: rgba(118, 82, 255, 0.4) !important; }

      .trakto-checkbox__field:checked + span,
      .trakto-toolbar__button.--active,
      .button--fill.button--primary { background-color: ${color.secondary} !important; }
      .download-item--active{ border-color: ${color.primary} !important; }

      .trakto-modal-menu-item.--active:not(.--custom-modal) {
        background-color: ${color.secondary} !important;
      }

      .button--fill.button--inverse,
      .panel-image__quick button.--special { color: ${color.secondary} !important; }

      trakto-button.button-icon button.button--medium,
      .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
      .mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: ${color.secondary} !important; }

      .trakto-checkbox__field:checked + span,
      .trakto-input.--has-focus .trakto-input__field,
      .button--outline.button--primary,
      #pageNavigation .scroll-area .scroll-item--active::before,
      .download-input__radio--active { border-color: ${color.secondary} !important; }

      .panel-actions__buttons .button--small:hover:not([disabled]) { box-shadow: 0px 3px 0px #7652ff !important; }

      .trakto-dropdown-select-item.--active > i,
      .trakto-input.--has-focus > i:first-child,
      .trakto-dropdown-menu__label,
      .button--outline.button--primary,
      #pagesContainer > div .page-actions > button:not(.page-number):hover,
      .page-title--active,
      .page-title--active > span:not(:empty)::before,
      .panel-default h3,
      .panel-default span,
      #pagesContainer > div .page-add:hover,
      .trakto-toolbar__button > i,
      trakto-button.--active .button > i,
      .navigation__button:hover { color: ${color.secondary} !important; }

      .trakto-toolbar__label { color: ${color.primary} !important; }

      .points-group circle, .points-group rect,
      .element-border,
      .element-temp { stroke: ${color.secondary} !important; }

      .trakto-toolbar__button.--active > i,
      .trakto-toolbar__button.--active > .trakto-toolbar__label { color: #ffffff !important; }

      #__selection,
      .trakto-logo__dot { fill: ${color.secondary} !important; }

      #__selection,
      .page-canvas--active { stroke: ${color.secondary} !important; }
      .trakto-loader__spinner, .trakto-modal-loader__spinner { border-left-color: ${color.secondary} !important; }

      .mat-accent .mat-slider-track-fill,
      .mat-accent .mat-slider-thumb-label { background: ${color.secondary} !important; }

      .trakto-modal-loader > i { color: ${color.secondary} !important; }

      .trakto-input-file-loader::before { background: ${color.secondary} !important;}
      .panel__title i { color: ${color.secondary} !important; }

      .trakto-load:before { background: ${color.primary}!important; }
      .trakto-load.trakto-load__dot { background: ${color.secondary}!important; }

      .element-border-locked,
      .points-group-locked circle,
      .points-group-locked rect { stroke: #8492A6 !important; }

      .points-group-locked circle, .points-group-locked path { cursor: pointer; }
      .points-group-locked rect { cursor: not-allowed; }

      .trakto-load:after { background: ${color.primary} !important}
      .trakto-load:before { background: ${color.primary} !important}

      .trakto-modal-loader__spinner, .trakto-loader__spinner { background-color:  ${color.primary} !important }

      .print-checkout-dialog .mat-dialog-container { border-radius: .5em !important; }
      .print-checkout-dialog .modal-header { color: ${color.primary}; }

      .form-wizard-stepper * {
        color: ${color.primary};
      }

      .form-wizard-stepper a.mat-primary,
      .form-wizard-stepper button {
        border-radius: 0.4em;
      }

      .form-wizard-stepper button.exec-form-button[disabled] .mat-spinner circle {
        stroke: ${color.background_logo};
      }

      .form-wizard-stepper a.mat-primary,
      .form-wizard-stepper a.mat-primary span,
      .form-wizard-stepper button.mat-primary,
      .form-wizard-stepper button.mat-primary span {
        color: white;
        background-color: ${color.primary} !important;
      }
      .form-wizard-stepper .mat-step-header {
        color: ${color.primary};
      }
      .form-wizard-stepper .mat-step-header .mat-step-icon .mat-icon {
        background: ${color.primary} !important;
      }
      .form-wizard-stepper .mat-step-header .mat-step-icon {
        background: ${color.primary} !important;
      }
      .form-wizard-stepper .mat-step-header .mat-step-icon span::after {
        background: ${color.secondary} !important;
      }
      .form-wizard-stepper .mat-step-header .mat-step-icon span::before {
        background: ${color.background_logo} !important;
      }

      .form-wizard-stepper .mat-stepper-vertical-line::before {
        border-left-color: rgba(195, 195, 195, 0.4);
        z-index: 1;
        border-width: 2px;
        left: -1px;
      }

      .form-wizard-stepper .mat-step-header .mat-step-label.mat-step-label-selected {
        background: ${color.primary} !important;
        color: ${color.background_logo} !important;
        width: 100% !important;
        position: absolute !important;
        z-index: 0 !important;
        padding: 2em 1em;
        padding-left: 4.5em !important;
        right: 0em !important;
        border-radius: 0.4em !important;
      }

      .form-wizard-stepper .mat-step-label-selected + .mat-step-header-ripple {
        background-color: ${color.primary};
      }
      .form-wizard-stepper .mat-step-label-selected,
      .form-wizard-stepper .mat-step-label-active {
        color: ${color.primary} !important;
      }

      .form-wizard-stepper .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
        border-color: ${color.secondary} !important;
      }

      .form-wizard-stepper .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle,
      .form-wizard-stepper .mat-radio-button.mat-accent .mat-ripple-element,
      .form-wizard-stepper .mat-checkbox-checked.mat-accent .mat-checkbox-background,
      .form-wizard-stepper .mat-checkbox-ripple .mat-ripple-element {
        background-color: ${color.secondary} !important;
      }

      .form-wizard-stepper input:-webkit-autofill,
      .form-wizard-stepper input:-webkit-autofill:hover,
      .form-wizard-stepper input:-webkit-autofill:focus,
      .form-wizard-stepper input:-internal-autofill-selected {
        -webkit-text-fill-color: ${color.primary};
        background: red !important;
      }

      .form-wizard-stepper .mat-radio-button.mat-accent .mat-ripple-element,
      .form-wizard-stepper .mat-checkbox-ripple .mat-ripple-element {
        opacity: 0.2 !important;
      }

      .trakto-modal-menu__header .button--fill.button--upload { background-color: ${color.primary} !important; }
      .font-list__item__custom__galery.--active::before {
        background-color: ${color.primary} !important;
        border-color: ${color.primary} !important; ;
      }

      .button.button--icon-large.button--outline.button--white:hover, .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb, .remove-bg__progress > div {
        background-color: ${color.primary} !important;
      }

      .trakto-nav__line.--line-one { background-color: #7251E7 !important; width: 51px !important; left: 35px !important;}
      .trakto-nav__line.--line-two { background-color: #0095FF !important; width: 108px !important; left: 126px !important;}
      .trakto-nav__line.--line-three { background-color: #FE6E46 !important; width: 69px !important; left: 267px !important;}

      .searchbar-fonts > trakto-input#fillFont div.--has-focus input { border: 1px solid ${color.secondary} !important;}
      .searchbar-fonts > trakto-input#fillFont div.--has-focus div.trakto-input__label > i { color: ${color.secondary} !important;}

      .trakto-container__search-fonts > trakto-input#my-fonts div.--has-focus input { border: 1px solid ${color.primary} !important;}
      .trakto-container__search-fonts > trakto-input#my-fonts div.--has-focus  div.trakto-input__label > i { color: ${color.primary} !important;}
    `;
    this._style.innerHTML = styles;
  }

  // .font-list__item.--active::before,

  private applyThemePresentation(): void {
    const colorMode = this._theme.color;

    const stylesModePresentation = `
    .preview-doc { background-color: ${colorMode.secondary} !important; }
    .preview-pages__controls { background: ${colorMode.primary} !important; }
    .preview-pages__controls { box-shadow: 0 0px 20px rgba(79, 50, 121, 0.1) !important; }
    .preview-pages__actions-counter span { color: ${colorMode.third} !important; }
    .preview-pages__actions-content > button > i, .preview-pages__actions-counter { color: ${colorMode.third} !important; }
    .preview-pages__button-left, .preview-pages__button-right { border: 1px solid rgba(218, 233, 253, 0.58) !important; }
    .preview-pages__button-left, .preview-pages__button-right { box-shadow: 0 0px 20px rgba(79, 50, 121, 0.1) !important; }
    .preview-pages__actions-counter input { background-color: ${colorMode.secondary} !important; }
    .preview-pages__actions-counter input { color: ${colorMode.third} !important; }
    .preview-pages__actions-counter input:focus { color: ${colorMode.third} !important; }
    `;

    this._style.innerHTML = stylesModePresentation;
  }

  private _buildThemeStructure(theme: any): IThemeStorageModel {
    const hasMediiumLogo = !!theme.logo?.medium; // same of !!theme.logo && !!theme.logo.bar

    return {
      color: theme.color,
      logo: {
        black: hasMediiumLogo ? theme.logo.medium.url : theme.logo.black,
        colored: hasMediiumLogo ? theme.logo.medium.url : theme.logo.colored,
        grey: hasMediiumLogo ? theme.logo.medium.url : theme.logo.grey,
      },
      title: theme.title,
    };
  }
}
