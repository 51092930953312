import { NgModule } from '@angular/core';

import { SelectOptgroupComponent } from './optgroup/optgroup.component';
import { SelectOptionComponent } from './option/option.component';
import { SelectComponent } from './select.component';

import { CommonModule } from '@angular/common';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { TraktoDirectivesModule } from '../directives/directives.module';

const Modules: any[] = [
  CommonModule,
  NgxFlagIconCssModule,
  TraktoDirectivesModule,
];

const Components: any[] = [
  SelectComponent,
  SelectOptionComponent,
  SelectOptgroupComponent,
];

@NgModule({
  declarations: Components,
  imports: Modules,
  exports: [...Modules, ...Components],
})
export class SelectModule {}
