import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'trakto-auto-scale-help-modal',
  templateUrl: './auto-scale-help.component.html',
  styleUrls: ['./auto-scale-help.component.scss'],
})
export class AutoScaleHelpComponent {

  constructor(
    public dialogRef: MatDialogRef<AutoScaleHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
  ) {}

  close() {
    this.dialogRef.close({});
  }
}
