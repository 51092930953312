import { Injectable, OnDestroy } from '@angular/core';
import { FontsService } from '@services/fonts.service';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import {
  FontOptionUtilModel,
  removeUndefinedProperties
} from '@trakto/core-editor';
import {
  alignEnum,
  fontSizeScaleModeEnum,
  IElementModel,
  IFontModel,
  IFontOptions,
  ITextElementModel,
  PageModel,
} from '@trakto/models';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { PageService } from './page.service';
import { copyObject } from '@app/state/state.util';

export interface ISelectionRange {
  index: number;
  length: number;
}

export interface ISelectionChange {
  element: ITextElementModel;
  range: ISelectionRange;
}

@Injectable({
  providedIn: 'root',
})
export class TextEditorService implements OnDestroy {
  private _element: ITextElementModel;
  private _cursorPosition: number;
  private _startSelection: number;
  private _lengthSelection: number;

  public selectionChange: Subject<ISelectionChange> =
    new Subject<ISelectionChange>();

  private _destroy$ = new Subject<void>();

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
    private _textService: TextService,
    private _fontsService: FontsService,
    private _textElementService: TextElementService,
    private _pageService: PageService
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public configureSelection(
    element?: ITextElementModel,
    range?: ISelectionRange,
  ) {
    this._element = element;
    this._startSelection = undefined;
    this._cursorPosition = range ? range.index : undefined;
    this._lengthSelection = undefined;
    if (
      range &&
      range.index !== range.index + range.length &&
      element.text.length !== range.length
    ) {
      this._startSelection = range.index;
      this._lengthSelection = range.length;
    }
    this.selectionChange.next({ element, range });
  }

  public changeColor(color: string, element?: ITextElementModel, emit = true) {
    const target = { ...(element || this._element) };
    this._textService.updateColor(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      color,
    );
    if (
      this._startSelection === undefined &&
      this._lengthSelection === undefined
    ) {
      target.color = color;
    }
    if (emit) {
      this._emitChange(target, 'color', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public changeFontSizeScaleMode(scaleMode: fontSizeScaleModeEnum, element?: ITextElementModel, emit = true) {
    const target = { ...(element || this._element) };
    target.fontSizeScaleMode = scaleMode;
    if (scaleMode === fontSizeScaleModeEnum.auto) {
      const toDecrease = target.supportHeight < this._textService.getBoxHeight(target);
      this._textElementService.fitFontSize(target, toDecrease)
    }
    if (emit) {
      this._emitChange(target, 'fontSizeScaleMode', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public changeBackgroundColor(color: string, element?: ITextElementModel, emit = true) {
    const target = { ...(element || this._element) };
    target.backgroundColor = color || null;
    if (emit) {
      this._emitChange(target, 'backgroundColor', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public changeLink(link: string, element?: ITextElementModel) {
    const target = { ...(element || this._element) };
    target.link = link || null;
    this._emitChange(target, 'link', !!this._element);
    this._persistElementChanges(target);
  }

  public changeBullets(bullets: boolean, element?: ITextElementModel) {
    const target = { ...(element || this._element) };
    this._textService.updateBullets(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      bullets,
    );
    this._persistElementChanges(target);
  }

  public changeLineHeight(lineHeight: number, element?: ITextElementModel) {
    const target = { ...(element || this._element) };
    target.lineSpace = lineHeight;
    this._textService.updateLineHeight(target);
    this._fitText(target);
    this._emitChange(target, 'lineSpace', !!this._element);
    this._persistElementChanges(target);
  }

  public changeFontSize(fontSize: number, element?: ITextElementModel, emit = true) {
    const target = { ...(element || this._element) };
    target.fontSize = fontSize;
    this._fitText(target);
    if (emit) {
      this._emitChange(target, 'fontSize', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public changeAlign(align: alignEnum, element?: ITextElementModel) {
    const target = { ...(element || this._element) };
    target.align = align;
    this._textService.updateAlign(target);
    this._emitChange(target, 'align', !!this._element);
    this._persistElementChanges(target);
  }

  public changeText(text: string, element?: ITextElementModel) {
    const target = { ...(element || this._element) };
    target.text = text;
    target.textQuillEncoded = undefined;
    this._fitText(target);
    this._emitChange(target, 'textQuillEncoded', !!this._element);
    this._persistElementChanges(target);
  }

  public changeFontFamily(
    fontModel: IFontModel,
    element: ITextElementModel,
    page: PageModel,
    emit = true,
  ) {
    removeUndefinedProperties(fontModel);
    const target = element || this._element;
    this._textService.updateFontFamily(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      fontModel.family,
    );
    const quillFamilies = this._getQuillFontFamilies(element);
    if (
      (this._startSelection === undefined &&
        this._lengthSelection === undefined) ||
      (quillFamilies || []).length === 1
    ) {
      target.fontFamily = fontModel.family;
    }
    this._fitText(target);

    this._addFontModel(fontModel, page);

    if (emit) this._emitChange(target, 'font', !!this._element);
    this._signalsService.emit(enumSignals.PROPERTY_FONT_WEIGHT, {});
    this._persistElementChanges(target);
    this._documentStateManagerService.persistPageChangesNoTrackable(
      { id: page.id, fontModels: copyObject(page.fontModels) } as PageModel
    );
  }

  public changeFontStyle(
    fontStyle: IFontOptions,
    element?: ITextElementModel,
    emit = true,
    persist = true,
  ): ITextElementModel {
    const target = { ...(element || this._element) };
    this._textService.updateFontStyle(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      fontStyle,
    );
    if (!this._startSelection && !this._lengthSelection) {
      target.fontStyle = fontStyle || null;
    }
    if (emit) {
      this._emitChange(target, 'fontStyle', !!this._element);
    }
    if (persist) {
      this._persistElementChanges(target);
    }
    return target;
  }

  public changeUnderline(
    underline: boolean,
    element?: ITextElementModel,
    emit = true,
  ) {
    const target = { ...(element || this._element) };
    this._textService.updateUnderline(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      underline,
    );
    if (emit) {
      this._emitChange(target, 'underline', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public changeLineThrough(
    lineThrough: boolean,
    element?: ITextElementModel,
    emit = true,
  ) {
    const target = { ...(element || this._element) };
    this._textService.updateLineThrough(
      target,
      null,
      this._startSelection,
      this._lengthSelection,
      lineThrough,
    );
    if (emit) {
      this._emitChange(target, 'underline', !!this._element);
    }
    this._persistElementChanges(target);
  }

  public async toggleStyles(
    activeBold = false,
    activeItalic = false,
    element?: ITextElementModel,
    emit = true,
  ): Promise<ITextElementModel> {
    const selectedFamily = this.getSelectedFontFamily(element || this._element);
    const options = await this._fontsService.listFontOptions(selectedFamily).pipe(take(1)).toPromise();
    const listItalicBold = FontOptionUtilModel.getListItalicBold(options);
    const listItalic = FontOptionUtilModel.getListItalic(options);
    const listBold = FontOptionUtilModel.getListBold(options);

    if (activeBold && activeItalic) {
      return this.changeFontStyle(listItalicBold[0], element, emit);
    } else if (activeBold) {
      return this.changeFontStyle(listBold[0], element, emit);
    } else if (activeItalic) {
      return this.changeFontStyle(listItalic[0], element, emit);
    } else {
      return this.changeFontStyle(
        this._fontsService.getRegularOrFirstFont(options),
        element,
        emit,
      );
    }
  }

  public getSelectedColor(element?: ITextElementModel): string {
    const target = element || this._element;
    if (this._cursorPosition === undefined) {
      return target.color;
    }
    const format = this._textService.getFormat(
      target,
      null,
      this._cursorPosition,
      this._lengthSelection || 0,
    );
    return typeof format.color === 'string' ? format.color : '';
  }

  public getSelectedBullets(element?: ITextElementModel) {
    const target = element || this._element;

    const bullets = this._textService.getFormat(
      target,
      null,
      this._cursorPosition,
      this._lengthSelection || 0,
    );

    return bullets.list === 'bullet';
  }

  public getSelectedFontFamily(element?: ITextElementModel): string {
    const target = element || this._element;
    if (this._cursorPosition === undefined) {
      return this._getDefaultFontFamily(target);
    }
    const format = this._textService.getFormat(
      target,
      null,
      this._cursorPosition,
      this._lengthSelection || 0,
    );
    return typeof format.font === 'string' ? format.font : this._getDefaultFontFamily(target);
  }

  public hasUnderline(element?: ITextElementModel): boolean {
    const target = element || this._element;
    let start = this._cursorPosition;
    let end = this._lengthSelection || 0;
    if (this._cursorPosition === undefined) {
      start = 0;
      end = 100000;
    }
    const format = this._textService.getFormat(target, null, start, end);
    return format.underline;
  }

  public hasLineThrough(element?: ITextElementModel): boolean {
    const target = element || this._element;
    let start = this._cursorPosition;
    let end = this._lengthSelection || 0;
    if (this._cursorPosition === undefined) {
      start = 0;
      end = 100000;
    }
    const format = this._textService.getFormat(target, null, start, end);
    return format.strike;
  }

  public hasAutoScale(element?: ITextElementModel): boolean {
    return element?.fontSizeScaleMode === fontSizeScaleModeEnum.auto;
  }

  public getSelectedFontStyle(element?: ITextElementModel): {
    italic?;
    weight?;
    hasBold?;
  } {
    const target = element || this._element;
    if (this._cursorPosition === undefined) {
      const fontStyle = target.fontStyle || { weight: '' };
      return {
        italic: fontStyle.italic,
        weight: fontStyle.weight,
        hasBold: FontOptionUtilModel.isBold(fontStyle),
      };
    }
    const format = this._textService.getFormat(
      target,
      null,
      this._cursorPosition,
      this._lengthSelection || 0,
    );
    return {
      italic: format.italic,
      weight: (format.weight || 'regular') + (format.italic ? 'italic' : ''),
      hasBold: format.bold || +format.weight > 600,
    };
  }

  public getAllowedStylesToEdition(
    element?: ITextElementModel,
  ): Observable<{ canItalic; canBold }> {
    const selectedFamily = this.getSelectedFontFamily(element);
    return this._fontsService.listFontOptions(selectedFamily).pipe(
      map(options => ({
        canItalic:
          (
            FontOptionUtilModel.getListItalicBold(options) ||
            FontOptionUtilModel.getListItalic(options) ||
            []
          ).length > 0,
        canBold: (FontOptionUtilModel.getListBold(options) || []).length > 0,
      })),
    );
  }

  private _emitChange(
    element: ITextElementModel,
    property: string,
    ignoreHistory: boolean,
  ) {
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: element.id,
      key: property,
      value: element[property],
      obj: element,
      ignoreHistory,
    });
  }

  private _getQuillFontFamilies(textElement: ITextElementModel): string[] {
    const allFamilies = textElement.textQuillEncoded
      .map(encoded => (encoded.attributes || { font: undefined }).font)
      .filter(font => font);
    return Array.from(new Set(allFamilies));
  }

  private _addFontModel(fontModel: IFontModel, page: PageModel) {
    page.fontModels = page.fontModels || [];
    delete fontModel.premium;
    this._textElementService.addFontModel(page, fontModel);
  }

  private _fitText(target: ITextElementModel) {
    if (target.fontSizeScaleMode !== fontSizeScaleModeEnum.manual) {
      this._textElementService.fitTextDimension(target);
    } else {
      this._textElementService.fitTextDimension(target);
    }
  }

  private _getDefaultFontFamily(target: ITextElementModel) {
    return target.fontFamily ? target.fontFamily : target.fontStyle.font;
  }

  private async _persistElementChanges(element: IElementModel) {
    if (!element) {
      return;
    }
    const selectedPage = await this._documentManagerService.getSelectedPage();
    this._documentStateManagerService.persistElementChanges(
      selectedPage,
      [{ ...element }],
    );
  }

}
