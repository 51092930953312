import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ICampaign, IDocumentModel } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ResultUploadModalComponent } from './components/result-upload-modal/result-upload-modal.component';
import { GoogleAdsService } from './components/google-ads.service';
import {
  Acount,
  GoogleUploadPayload,
} from '@app/editor/model/google-ads.interface';
import { ImageService } from '@app/editor/services/image.service';
import { environment } from '@env/environment';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { CampaignService } from '@app/editor-v3/services/admin/team/campaign/team-campaign.service';

@Component({
  selector: 'trakto-trakto-send-google-ads',
  templateUrl: './trakto-send-google-ads.component.html',
  styleUrls: ['./trakto-send-google-ads.component.scss'],
})
export class TraktoSendGoogleAdsComponent implements OnInit {
  // Contas dos Google ADS
  public accounts: Acount[] = [];
  public newArrayAccounts: Acount[] = [];
  public selectedAccount: Acount;

  // Campanhas
  campaigns: ICampaign[] = [];
  newArraycampaigns: ICampaign[] = [];
  selectedCampaign: ICampaign;

  public step: number = 0;

  public loading: boolean = false;

  public loadingButton: boolean = false;

  public uploadSuccess: boolean = false;

  private refresh_token: string = '';

  private document: IDocumentModel;

  private file: any;

  private linkGoogleAds: string;

  _destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<TraktoSendGoogleAdsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private googleAds: GoogleAdsService,
    private _imageService: ImageService,
    private readonly hotkeyService: HotkeyService,
    private _campaignService: CampaignService,
  ) {
    this.linkGoogleAds = environment.googleAds;
  }

  ngOnInit(): void {
    if (this.data?.document) {
      this.document = this.data?.document;
    }

    if (this.data?.refresh_token) {
      this.refresh_token = this.data.refresh_token;
    }
    this.getAccounts();
    // this.getCampaigsn();
  }

  private getAccounts() {
    if (this.data?.accounts) {
      if (this.data.accounts?.length === 0) {
        return this.redirectGoogleAds();
      }
      this.accounts = this.data.accounts;
      this.newArrayAccounts = this.accounts;
      return;
    }

    this.loading = true;

    this.googleAds
      .getAccounts(this.refresh_token)
      .then(response => {
        if (response?.customers.length > 0) {
          this.accounts = response.customers;
          this.newArrayAccounts = this.accounts;
        } else {
          this.redirectGoogleAds();
        }
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
      })
      .finally(() => (this.loading = false));
  }

  async getCampaigsn() {
    this._campaignService
      .listCampaigns()
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(res => {
        this.campaigns = res.campaigns;
        this.newArraycampaigns = this.campaigns;
      });
  }

  redirectGoogleAds() {
    const link = document.createElement('a');
    link.href = `${this.linkGoogleAds}intl/pt-BR_br/home/`;
    link.target = 'blank';
    link.click();
  }

  public disablehotkeys() {
    this.hotkeyService.disableHotkeys();
  }

  /**
   * Obtém o Blob da imagem a partir do documento
   */
  async getFile() {
    const blobFile = await this._imageService
      .createImageBlob(this.document.body[0])
      .toPromise();

    const file = this.blobToFile(
      blobFile,
      `${this.document.body[0]?.title}.png`,
    );

    this.file = file;

    this.uploadFile(this.file);
  }

  async uploadFile(file: any) {
    const params: GoogleUploadPayload = {
      customer_id: this.selectedAccount.id,
      refresh_token: this.refresh_token,
    };

    const formData = new FormData();
    formData.append('file', file, `${file.name}.png`);

    this.step = 2;
    this.loading = true;
    this.googleAds.uploadFile(params, formData).subscribe({
      next: () => {
        this.openModalConfirm(true);
      },
      error: err => {
        this.openModalConfirm(false);
        console.error(err);
      },
    });
  }

  /**
   * Atribui a conta selecionada
   */
  selectAcount(index: number) {
    this.selectedAccount = this.accounts[index];
  }

  /**
   * Faz a busca de contas de acordo com o nome digitado
   */
  filterAccounts(text: string): void {
    if (text === '') {
      this.newArrayAccounts = this.accounts;
      return;
    }
    this.newArrayAccounts = this.accounts.filter(account =>
      account.descriptive_name.toLowerCase().includes(text.toLowerCase()),
    );
  }

  /**
   * Fecha o modal
   */
  closeModal() {
    this.dialogRef.close();
  }

  nextStep() {
    this.step = this.step + 1;
  }

  backStep() {
    this.step = this.step - 1;
  }

  cancelShipping() {
    this.googleAds.cancelUpload();
    this.step = 0;
    this.loading = false;
    this.loadingButton = false;
  }

  finish() {
    this.loadingButton = true;
    this.getFile();
  }

  /**
   * Convert Blob to File
   */
  blobToFile(blob: Blob, fileName: string) {
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });
    return file;
  }

  /**
   * Abre o modal de enviar para Google ADS
   */
  openModalConfirm(success: boolean) {
    const modalRef = this.dialog.open(ResultUploadModalComponent, {
      width: '477px',
      height: '308px',
      data: {
        result: success,
      },
    } as MatDialogConfig);

    modalRef.afterClosed().subscribe(value => {
      if (value) {
        this.uploadFile(this.file);
      } else {
        this.closeModal();
      }
    });
  }

  get buttomDisabled() {
    if (this.step === 0) return !this.selectedAccount;
    return false;
  }
}
