import { Injectable } from '@angular/core';
import { duotones, filters, IFilterData } from '../utils/filters';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { ImageElementModel } from '@trakto/models';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  filters: IFilterData[] = [...filters, ...duotones];

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _elementChangeFacadeService: ElementChangeFacadeService
  ) {
  }

  async setFilter(filter: string) {
    this._elementChangeFacadeService.changeFilter(
      await this._documentManagerService.getSelectedElement(),
      filter,
    );
  }

  async toggleFilter(filter: string) {
    const element = await this._documentManagerService.getSelectedElement();
    if (filter === element.filter) {
      await this.setFilter('empty');
    } else {
      await this.setFilter(filter);
    }
  }

  async removeFilter() {
    await this.setFilter('empty');
  }

}
