import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() label: string | null = null;
  @Input() value: string = '';
  @Input() icon: string = 'search';
  @Input() iconColor: string = '#7183a6';
  @Input() placeholder: string = '';
  @Input() clearIcon: boolean = false;

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  changeInputValue(event: any) {
    this.onChange.emit(event.target.value);
  }

  clear() {
    this.onChange.emit('');
  }

  emitFocus() {
    this.onFocus.emit();
  }

  emitBlur() {
    this.onBlur.emit();
  }
}
