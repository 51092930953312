import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from '@app/editor-v3/services/confirmation.service';

@Component({
  selector: 'trakto-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @Input() icon: string = null;
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() cancel: string = '';
  @Input() confirm: string = '';

  @Output() action: EventEmitter<any> = new EventEmitter(true);

  constructor(private _confirmationService: ConfirmationService) {}

  ngOnInit(): void {}

  close() {
    this._confirmationService.destroy();
  }

  execAction() {
    if (this.action) this.action.emit();
    this._confirmationService.destroy();
  }
}
