<div class="container_dialog" *ngIf="showDialog && !showNotification">
  <h3>Confirmar exclusão?</h3>
  <p>Pense com cuidado! Você tem certeza que deseja excluir este link de compartilhamento?</p>
  <div class="btns">
    <button class="cancel" (click)="cancel()">Cancelar</button>
    <button class="delete" (click)="delete()">Sim, desejo excluir</button>
  </div>
</div>

<!-- <trakto-notification #notification [traktoLink]="traktoLink"></trakto-notification> -->


<div class="container_notification" *ngIf="showNotification">

  <div class="title">
    <img
      src="{{deleteSuccess ? '../../../../../../assets/icons/notification-success.svg' : '../../../../../../assets/icons/notification-error.svg'}}"
      alt="" style="margin-right: 5px;">
    <h3>{{deleteSuccess ? 'Seu link foi excluído com sucesso!' : 'Ops! Ocorrou um erro.'}}</h3>
    <p class="close" (click)="close()">x</p>
  </div>

  <p>{{ deleteSuccess ? 'Para gerar um novo link, clique em Compartilhar.' : 'Não conseguimos realizar esta ação. Por
    favor, tente novamente.'}}</p>

</div>
