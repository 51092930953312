import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'trakto-input-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileComponent {
  private _id: string;
  private _name: string;
  private _disabled: boolean;
  private _loading: boolean;
  private _mimes: string;

  public uploaded = false;

  private hasChanged = false;
  private maxCharactersName = 10;

  @ViewChild('input', { static: true }) input;

  @Input()
  public set id(id: string) {
    this._id = id;
  }

  public get id(): string {
    return this._id || null;
  }

  public set name(name: string) {
    this._name = name;

    if (name && name.length > this.maxCharactersName) {
      this._name = this.getShortName(name);
    }
  }

  public get name(): string {
    return this._name || 'noname';
  }

  @Input()
  public set loading(loading: boolean) {
    this._loading = loading;
    this.hasUploaded();
  }

  public get loading() {
    return this._loading || false;
  }

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || false;
  }

  @Input()
  public set mimes(value: string) {
    this._mimes = value;
  }

  public get mimes(): string {
    return this._mimes || '';
  }

  @Output() onChange: EventEmitter<Event> = new EventEmitter<Event>();

  getShortName(str: string, interval: string = '...', end: number = 9): string {
    return `${str.substr(0, 3)}${interval} ${str.substr(str.length - end)}`;
  }

  change(event: Event) {
    if (!this.disabled) {
      const target: any = event.target;
      const file: File = target.files[0];
      if (
        (file && this.mimes.indexOf(file.type) > -1 && file.type.length > 0) ||
        (file && this.mimes === '')
      ) {
        this.name = file.name;
        this.onChange.emit(event);
        this.hasChanged = true;
        this.input.nativeElement.value = '';
      }
    }
  }

  open(event: MouseEvent) {
    if (!this.disabled && !this.loading) {
      this.input.nativeElement.click(event);
    }
  }

  hasUploaded(): void {
    if (!this.loading && this.hasChanged) {
      this.uploaded = true;

      delay(1000);
      Promise.resolve().then(() => (this.uploaded = false));
    }
  }
}
