import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IElementModel } from '@trakto/models';

@Directive()
export class DrawableComponent implements OnChanges {
  /**
   * Elemento com as características visuais
   */
  @Input()
  public element: IElementModel;

  /**
   * Elemento para ser usado no posicionamento. Se não foi passado por parâmetro, será atribuído como o element.
   */
  @Input()
  public positioningElement: IElementModel;

  @Input()
  public zoomRatio: number;

  @Input()
  public ref: any;

  @HostBinding('attr.id')
  public id: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.element) {
      return;
    }
    this.id = this.element.id;

    if (!this.positioningElement) {
      this.positioningElement = this.element;
    }
  }
}
