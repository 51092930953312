import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFirebaseService } from '@app/auth/shared/auth-firebase.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  private baseUrl: string;
  constructor(
    private _httpClient: HttpClient,
    private _authFirebaseService: AuthFirebaseService,
  ) {
    this.baseUrl = environment.googleAdsService;
  }

  async executeGet<E>(
    url: string,
    params?: any,
    ignoreAuthToken = false,
  ): Promise<E> {
    const httpOptions = await this._makeHttpOptions(
      true,
      params,
      ignoreAuthToken,
    );
    return this._httpClient
      .get<E>(`${this.baseUrl}${url}`, httpOptions)
      .toPromise();
  }

  async executePost<E>(url: string, body: any, isJson = true): Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient
      .post<E>(`${this.baseUrl}${url}`, body, httpOptions)
      .toPromise();
  }

  private async _makeHttpOptions(isJson = true, params?: any, ignoreAuthToken = false): Promise<{ headers: HttpHeaders }> {
    const token = await this._authFirebaseService.getAccessToken();
    if (!token && !ignoreAuthToken) {
      throw new Error('Token is not defined');
    }
    const headers = {
    };
    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
    }
    if (isJson) {
      headers['Content-Type'] = 'application/json'
    }
    const httpOptions: { headers: HttpHeaders, params?: any } = {
      headers: new HttpHeaders(headers),
    };
    if (params) {
      httpOptions.params = params;
    }
    return httpOptions;
  }
}
