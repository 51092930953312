<div [style.padding.px]="15">
  <h1 mat-dialog-title>Compartilhar documento</h1>

  <div mat-dialog-content>
    <p>Como deseja que sua URL seja compartilhada:</p>

    <input
      #inputSlug
      readonly
      style="opacity: 0; position: absolute; z-index: -1;"
      autocomplete="off"
      matInput
      [placeholder]="data.docId"
      [value]="
        formGroup.get('slug').value
          ? editorURL + formGroup.get('slug').value
          : null
      "
      type="input"
    />

    <form [formGroup]="formGroup">
      <div style="display: flex; justify-content: center; align-items: center;">
        <mat-form-field
          floatLabel="never"
          appearance="outline"
          style="width: 100%;"
        >
          <span matPrefix>{{ editorURL }}</span>
          <input
            #input
            autocomplete="off"
            matInput
            placeholder="meu-documento"
            formControlName="slug"
            type="input"
            (input)="getCleanWord(input.value)"
            [style.color]="'red'"
          />
        </mat-form-field>

        <button mat-raised-button matSuffix color="primary" (click)="copy()">
          Copiar
        </button>
      </div>

      <mat-checkbox formControlName="allow_preview" labelPosition="after">
        Habilitar visualização
      </mat-checkbox>
    </form>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      [disabled]="!formGroup.valid"
      (click)="doCheck()"
      cdkFocusInitial
    >
      Alterar
    </button>
    <button mat-button [mat-dialog-close]="null">Cancelar</button>
  </div>

  <br />
</div>
