import { Component, Input, OnChanges } from '@angular/core';
import { ElementFactoryService } from '@services/element-factory.service';
import { ISvgElementModel, PageModel } from '@trakto/models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-badge]',
  template: `
    <svg:g
      drawable-gif
      [element]="badge"
      [zoomRatio]="zoomRatio"
      [ref]="ref"
    ></svg:g>
  `,
})
export class DrawableBadgeComponent implements OnChanges {
  @Input() ref: any;

  @Input() page: PageModel;
  @Input() zoomRatio: number;

  badge: ISvgElementModel;

  constructor(private elementsModelService: ElementFactoryService) {}

  ngOnChanges() {
    this.badge = this.elementsModelService.getBadgeTrakto(this.page);
  }
}
