import { Directive, HostBinding, Input, OnChanges } from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TraktoElementDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-element.directive';
import { getPreserveAspectRatio } from '@trakto/core-editor';
import { PageModel } from '@trakto/models';

@Directive({
  selector: '[traktoPageBgImage]',
})
export class TraktoPageBgImageDirective
  extends TraktoElementDirective
  implements OnChanges {
  @Input()
  traktoPageBgImage: PageModel;

  @Input()
  printable: boolean;

  @HostBinding('attr.x')
  x = 0;

  @HostBinding('attr.y')
  y = 0;

  @HostBinding('attr.width')
  width: number;

  @HostBinding('attr.height')
  height: number;

  @HostBinding('attr.href')
  href: SafeUrl;

  @HostBinding('attr.opacity')
  opacity: number;

  @HostBinding('attr.preserveAspectRatio')
  preserveAspectRatio: string;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnChanges() {
    if (!this.traktoPageBgImage) {
      return;
    }
    const page = this.traktoPageBgImage;

    const backgroundImagePosition = page.backgroundImagePosition
      ? page.backgroundImagePosition
      : 'centerCenter';
    const backgroundImageAspect = page.backgroundImageAspect
      ? page.backgroundImageAspect
      : 'slice';

    this.id = `${page.id}__bg`;
    this.width = page.width;
    this.height = page.height;
    this.href = this.sanitizer.bypassSecurityTrustUrl(page.backgroundImage);
    this.opacity = page.backgroundImageOpacity;
    this.preserveAspectRatio = getPreserveAspectRatio(
      backgroundImagePosition,
      backgroundImageAspect,
    );
  }
}
