<div class="panel-mobile" *ngIf="pageSelected">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-properties-page.svg"
    label="Ajuste de página"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="formats-carousel">
    <div class="loader" *ngIf="loading; else formatsCarousel">
      <i class="icon icon-loading"></i>
    </div>

    <ng-template #formatsCarousel>
      <div class="see-more" (click)="openFormatsPanel()">Ver todos</div>
      <div class="carousel">
        <swiper [config]="swiperConfig" #swiperInstance>
          <ng-template swiperSlide>
            <div class="slide">
              <img
                src="assets/images/panel/custom-size.svg"
                alt=""
                width="85"
              />
              <p class="slide-label">
                {{ 'property_panel.general.custom' | translate }}
              </p>
            </div>
          </ng-template>

          <ng-container *ngFor="let childFormat of allFormats">
            <ng-template swiperSlide>
              <trakto-format-card
                [label]="
                  childFormat.titles
                    ? childFormat.titles[lang]
                    : childFormat.title
                "
                [icon]="childFormat.file"
                [selected]="childFormat.id === pageSelected.format?.id"
                (click)="changeFormat(childFormat)"
              ></trakto-format-card>
            </ng-template>
          </ng-container>

          <div slot="container-end">
            <div class="whiteshadow prev"></div>
            <div class="whiteshadow next"></div>
          </div>
        </swiper>
      </div>
    </ng-template>
  </div>

  <div class="divider" *ngIf="allFormats.length > 0"></div>

  <trakto-inputs-settings
    [width]="width"
    [height]="height"
    [pageSelected]="pageSelected"
    (onChangeDimensions)="changeDimensions($event)"
    (onTogglePageProportion)="toggleLockPageProportion()"
    (onInvertDimensions)="invertDimensions()"
    (onChangeUnit)="changeUnit($event)"
  ></trakto-inputs-settings>

  <div class="background-settings">
    <div class="setting">
      <span class="label">Adicionar imagem de fundo</span>

      <div class="selector" (click)="openImagesPanel()">
        <div class="image">
          <img src="assets/editor-v3/images/image.svg" alt="" />
        </div>
        <div class="thumb">
          <img
            [src]="
              pageSelected?.backgroundImage?.length > 0
                ? pageSelected?.backgroundImage
                : 'assets/editor-v3/images/transparent.svg'
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="setting">
      <span class="label">Alterar cor de fundo</span>

      <div
        class="selector"
        (click)="changePageBackground()"
      >
        <div class="image">
          <img src="assets/editor-v3/images/color.svg" alt="" />
        </div>
        <div
          class="thumb"
          [ngStyle]="{
            backgroundColor:
              pageSelected?.backgroundColor?.length > 0 &&
              pageSelected?.backgroundColor?.length < 8
                ? pageSelected?.backgroundColor
                : 'unset'
          }"
        >
          <img
            *ngIf="
              !pageSelected?.backgroundColor ||
              pageSelected?.backgroundColor?.length >= 8
            "
            src="assets/editor-v3/images/transparent.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div class="apply-all">
    <trakto-button-loader
      (action)="applyToAll()"
      label="Aplicar a todas as páginas"
    ></trakto-button-loader>
  </div>
</div>
