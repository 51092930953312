<div
  class="container"
  [ngStyle]="{
    height: isPageGridOpen ? (pageView === 'grid' ? '592px' : '88px') : '0px'
  }"
>
  <div class="line">
    <div
      *ngFor="let page of document?.body"
      class="page"
      [ngClass]="{ selected: page.order === pageSelectedIndex + 1 }"
      (click)="selectPage(page.order - 1)"
    >
      <p class="number">{{ page.order }}</p>
      <img
        class="thumb"
        *ngIf="page.base64 !== undefined; else defaultThumb"
        [src]="defineUrl(page.base64)"
      />
      <ng-template #defaultThumb>
        <div class="default thumb"></div>
      </ng-template>
    </div>

    <div class="page-add" (click)="addPage()">
      <i class="icon icon-add"></i>
    </div>
  </div>
</div>
