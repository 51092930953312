import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  PageBase64ManagerService
} from '@app/shared/svg-viewer/shared/page-base64-manager.service';
import { AuthService } from '@auth/shared/auth.service';
import {
  PanelElementsComponent
} from '@editor/components/properties-panel/panel-elements/panel-elements.component';
import {
  PanelPageComponent
} from '@editor/components/properties-panel/panel-page/panel-page.component';
import {
  PanelImageComponent
} from '@editor/components/properties-panel/panel-image/panel-image.component';
import {
  PanelShapeComponent
} from '@editor/components/properties-panel/panel-shape/panel-shape.component';
import {
  PanelTextComponent
} from '@editor/components/properties-panel/panel-text/panel-text.component';
import {
  PanelVideoComponent
} from '@editor/components/properties-panel/panel-video/panel-video.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '@services/document.service';
import { ElementFactoryService } from '@services/element-factory.service';
import { ElementModelService } from '@services/element-model.service';
import { PageService } from '@services/page.service';
import { NotificationService } from '@shared/notification/notification.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  IEventModel,
  PageModel,
} from '@trakto/models';
import { forkJoin, from, merge, ReplaySubject, Subject } from 'rxjs';

import {
  SaveStaredEventModel
} from '@editor/model/document-manager/editor-events.model';
import {
  SaveErrorEventModel
} from '@editor/model/document-manager/save-error-event.model';
import {
  SaveFinishedEventModel
} from '@editor/model/document-manager/save-finished-event.model';
import { DocumentMetricsModel, DocumentUtilModel } from '@trakto/core-editor';
import {
  PanelGroupComponent
} from '../components/properties-panel/panel-group/panel-group.component';
import { HotkeyService } from './hotkeys/hotkeys.service';
import { PanelStackService } from './panel-stack.service';
import { InitService } from '@services/init.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  ElementDatasource
} from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import {
  ElementsGalleryService
} from '@shared/elements-gallery/elements-gallery.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { copyObject } from '@app/state/state.util';
import {
  IMTElementInfo
} from '@shared/magic-template/shared/model/mt-element-info.model';
import { UserService } from '@app/editor-v3/services/user.service';
import { take, takeUntil } from 'rxjs/operators';
import { IMetadata } from './traktoLinks/trakto-link.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentManagerService implements OnDestroy {

  public onSaveStarted: Subject<SaveStaredEventModel>;
  public onSaveFinished: Subject<SaveFinishedEventModel>;
  public onSaveError: Subject<SaveErrorEventModel>;

  public firstPageChanged = false;
  public b2c: boolean;
  public allowLockElementsByTemplate: boolean;

  public isClipEditable: boolean;

  public savedChangeLast = true;
  public translatedTexts: any;
  public currentLang: any;
  public saveErrors: any;
  public notifyMSGs: any;

  public lastDocumentMetrics: DocumentMetricsModel;
  private saveErrorCount = 0;
  private saving = false;
  private lastUpdatedAt: Date;
  private timeTolerance: number = 20;
  private _destroy$ = new Subject<void>();

  constructor(
    private _authService: AuthService,
    private _pageService: PageService,
    private _hotkeyService: HotkeyService,
    private _userService: UserService,
    private _signalsService: SignalsService,
    private _documentService: DocumentService,
    private _elementModelService: ElementModelService,
    private _alertService: NotificationService,
    private _panelStackService: PanelStackService,
    private _elementFactoryService: ElementFactoryService,
    private _pageBase64ManagerService: PageBase64ManagerService,
    private _translateService: TranslateService,
    private _router: Router,
    private _docInitService: InitService,
    private _deviceDetectorService: DeviceDetectorService,
    private _elementsGalleryService: ElementsGalleryService,
    private _documentStageManager: DocumentStateManagerService,
  ) {
    this.reset();

    this._userService.getProfile().subscribe(() => {
      this._translateService.onLangChange.subscribe(() => {
        this._configTranslate();
      });
      const user = this._userService.user;
      this.allowLockElementsByTemplate =
        user.role.value === 'global' ||
        user.role.value === 'designer' ||
        user.role.value === 'global_designer' ||
        user.role.value === 'owner_whitelabel' ||
        user.role.value === 'admin_whitelabel';

      this.notifyMSGs = this._alertService.notificationsMSGs;
      this._signalsService.connect(
        enumSignals.ON_CLIP_EDITABLE,
        (signal: IEventModel) => {
          this.isClipEditable = signal.data;
        },
      );

      this.b2c = this._userService.isB2c;
      this._documentStageManager.undoAndRedo$.subscribe(async state => {
        if (!state.selectedPage) {
          return;
        }
        await this._docInitService.initImagesByPage(state.selectedPage);
        await this._docInitService.initFontsByPage(state.selectedPage);
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  getSelectedDocument(): Promise<IDocumentModel> {
    return this._documentStageManager.documentSnapshot$.toPromise();
  }

  getSelectedPage(): Promise<PageModel> {
    return this._documentStageManager.pageSnapshot$.toPromise();
  }

  getSelectedElement(): Promise<IElementModel> {
    return this._documentStageManager.elementSnapshot$.toPromise();
  }

  public reset() {
    this.onSaveStarted = new ReplaySubject<SaveStaredEventModel>(1);
    this.onSaveFinished = new ReplaySubject<SaveFinishedEventModel>(1);
    this.onSaveError = new ReplaySubject<SaveErrorEventModel>(1);
  }

  public loadDocument(documentId: string): Promise<void> {
    return this._selectDocument(documentId);
  }

  public async updateMetadata(metadata: IMetadata) {
    const document = await this.getSelectedDocument();
    const updatedDocument = await this._documentService.getDocumentHeader(document.id);
    this.lastUpdatedAt = updatedDocument.updated_at;

    document.metadata = metadata;
    await this._documentService.save(document);
  }

  public async updateDocumentData(updatedDocument: IDocumentModel): Promise<IDocumentModel> {
    try {
      this.lastUpdatedAt = updatedDocument.updated_at;
      this._documentStageManager.persistDocumentChangesNoTrackable({
        updated_at: updatedDocument.updated_at,
        is_magic: updatedDocument.is_magic,
        tags: updatedDocument.tags,
        theme: updatedDocument.theme_reference,
        app: updatedDocument.app_reference,
        product: updatedDocument.products,
      } as IDocumentModel);
      return updatedDocument;
    } catch (error) {
      console.error('Ocorreu um erro ao atualizar o template', error);
      throw error;
    }
  }

  public async selectDocument(document: IDocumentModel) {
    this._documentStageManager.selectDocument(document);
    this.lastDocumentMetrics = DocumentMetricsModel.build(document);
    this.detectDocumentChanges();
  }

  public async save(
    forcedSave = false,
    requestThumbGeneration = true,
  ): Promise<{ saved: boolean; saving?: boolean }> {
    const toSaveDocument = await this.getSelectedDocument();
    try {
        const {
          isValid,
          fatalError,
          message
        } = this._validateToSave(toSaveDocument ,forcedSave);

        if (!isValid) {
          this.onSaveError.next(new SaveErrorEventModel(
              toSaveDocument,
              message,
              fatalError,
            ),
          );
          return { saved: false, saving: this.saving };
        }

        this.saving = true;
        const updatedHeader = await this._documentService.getDocumentHeader(toSaveDocument.firebaseId);
        DocumentUtilModel.normalizeDates(updatedHeader);

        if (!updatedHeader && toSaveDocument.firebaseId) {
          throw new Error(this.saveErrors.document_not_found);
        }

        this.onSaveStarted.next(
          new SaveStaredEventModel(
            toSaveDocument,
            this.savedChangeLast,
            forcedSave,
          ),
        );

        const isNew = toSaveDocument.firebaseId === undefined;
        const saved = await this._documentService.save(toSaveDocument);

        this.lastUpdatedAt = saved.updated_at;
        this._documentStageManager.persistDocumentChangesNoTrackable({ updated_at: saved.updated_at } as IDocumentModel);
        this.saving = false;
        this.savedChangeLast = true;
        this.resetSaveErrorCount();
        this.onSaveFinished.next(
          new SaveFinishedEventModel(
            toSaveDocument,
            true,
            isNew,
            this.firstPageChanged,
            requestThumbGeneration
          )
        );
        return { saved: true };
      } catch(error) {
        this.saving = false;
        this.savedChangeLast = false;
        this.onSaveError.next(
          new SaveErrorEventModel(
            toSaveDocument,
            error.message || this.saveErrors.firebase,
            true,
          ),
        );
      }
  }

  private _validateToSave(toSaveDocument: IDocumentModel, toForceSave: boolean): {
    isValid: boolean,
    fatalError: boolean,
    message: string,
  } {
    if (this._documentService.isEmpty(toSaveDocument)) {
      this.onSaveError.next(
        new SaveErrorEventModel(
          toSaveDocument,
          this.saveErrors.empty,
          true,
        ),
      );
      return { isValid: false, message: this.saveErrors.empty, fatalError: true };
    }
    if (this.saving && !toForceSave) return { isValid: false, message: 'Salvando', fatalError: false };
    if (
      !DocumentMetricsModel.build(toSaveDocument).equals(
        this.lastDocumentMetrics,
      )
    ) {
      return { isValid: false, message: this.saveErrors.integrity, fatalError: true };
    }

    if (!this._documentService.hasAllowedPagesNumber(toSaveDocument)) {
      return { isValid: true, message: this.saveErrors.max_page, fatalError: false };
    }
    return { isValid: true, message: 'Documento válido', fatalError: false };
  }

  public rename(title: string): void {
    this._documentStageManager.persistDocumentChangesNoTrackable({
      title,
      is_renamed: true,
    });
  }

  public async changeAllPageSizes(samplePage: PageModel, emit = true) {
    (await this.getSelectedDocument()).body.map((page: PageModel) => {
      page.width = samplePage.width || 0;
      page.height = samplePage.height || 0;
      if (page.lockSize) {
        page.lockSize = samplePage.lockSize || null;
      }
      if (samplePage.unit) {
        page.unit = samplePage.unit;
      }
      if (samplePage.format) {
        page.format = samplePage.format;
      }
    });
  }

  public async selectPage(page: PageModel) {
    this._hotkeyService.enableHotkeys();
    this._documentStageManager.selectPage(page);
    this.showPagePanel(page, false);
    if (page) {
      await this._docInitService.initImagesByPage(page);
      await this._docInitService.initFontsByPage(page);
    }
  }

  showPagePanel(page: PageModel, showPanelPage = false) {
    if (this.canStackPanel(page, showPanelPage) && !this._deviceDetectorService.isMobile()) {
      this._panelStackService.popAll();

      showPanelPage ? this._stackPage(page) : this._stackDefault();
    }
  }

  public canStackPanel(page: PageModel, showPanelPage: boolean): boolean {
    return (
      page &&
      !this.isPanelMagicTemplatesOpen() &&
      (this.refreshSelection() || showPanelPage)
    );
  }

  public async addPage(page: PageModel, targetIndex: number = -1): Promise<void> {
    const document = await this.getSelectedDocument();
    if (!this._documentService.hasAllowedAddPage(document)) {
      this._alertService.warn(this.notifyMSGs.page_limit_reached);
      return;
    }
    const clonedPage = this._pageService.clonePage(document, page);
    this._documentStageManager.addPage(clonedPage, targetIndex);
    await this.save(true, true);
  }

  public async changePositions(from: PageModel, to: PageModel): Promise<void> {
    const document = await this.getSelectedDocument();
    const fromIndex = this._pageService.getPageIndex(document, from);
    const toIndex = this._pageService.getPageIndex(document, to);
    await this.changePositionsByIndexes(fromIndex, toIndex);
  }

  public async changePositionsByIndexes(fromIndex: number, toIndex: number): Promise<void> {
    const document = await this.getSelectedDocument();
    const from = document.body[fromIndex];
    const to = document.body[toIndex];
    document.body[fromIndex] = to;
    document.body[toIndex] = from;
    this._pageService.updatePagesOrders(document);
    this.selectPage(document.body[toIndex]);

  }

  public async clonePages(indexes: number[]): Promise<void> {
    const document = await this.getSelectedDocument();
    const pages = Array.from(new Set(indexes)).map(i => document.body[i]);
    const clonedPages = pages.map(page => this._pageService.clonePage(document, page));
    await Promise.all(clonedPages.map(page => this.addPage(page, -1)));
  }

  public async deletePages(indexes: number[]): Promise<void> {
    const document = await this.getSelectedDocument();
    const pages = Array.from(new Set(indexes)).map(i => document.body[i]);
    if (pages.length === document.body.length) {
      await this.addPage(this._pageService.cloneClearPage(document.body[0]));
    }
    pages.forEach(page => this.deletePage(page));
  }

  public async deletePage(page: PageModel): Promise<void> {
    const document = await this.getSelectedDocument();
    if (!this._pageService.canDeletePage(document)) {
      this._alertService.warn(this.notifyMSGs.page_delete_all_blocker);
      return;
    }
    this._documentStageManager.deletePage(page);
  }

  public async moveSelectedPageUp(): Promise<void> {
    const selectedPage = await this.getSelectedPage();
    if (selectedPage) {
      this.movePageUp(selectedPage);
    }
  }

  public async moveSelectedPageDown(): Promise<void> {
    const selectedPage = await this.getSelectedPage();
    if (selectedPage) {
      this.movePageDown(selectedPage);
    }
  }

  public async movePageUp(page: PageModel): Promise<void> {
    if (!this._pageService.canMovePageUp(await this.getSelectedDocument(), page)) {
      return;
    }
    this._documentStageManager.movePageUp(page);
  }

  public async movePageDown(page: PageModel): Promise<void> {
    if (!this._pageService.canMovePageDown(await this.getSelectedDocument(), page)) {
      return;
    }
    this._documentStageManager.movePageDown(page);
  }

  public async selectElement(element: IElementModel): Promise<void> {
    this._documentStageManager.selectElement(element)
    const selectedPage = await this.getSelectedPage();
    const oldSelection = await this.getSelectedElement();
    if (element) {
      this.openPropertiesPanel(element);
    } else {
      this.showPagePanel(selectedPage);
      if ([elementTypeEnum.emoji, elementTypeEnum.shape].includes(oldSelection?.type)) {
        this._elementsGalleryService.setElementsPanelStore({});
      }
    }
  }

  public async clickElement(element: IElementModel): Promise<void> {
    const page = await this.getSelectedPage();
    const selectedElement = await this.getSelectedElement(); // to keep the synchrony
    if (
      element &&
      (this._elementModelService.isSingleGroupSelection(element) || element.type !== elementTypeEnum.group)
      && !!page.elements.find(element => element.id === selectedElement?.id)
    ) {
      this.openPropertiesPanel(element);
    }

    const toFocus = element ? element : selectedElement && selectedElement.type !== elementTypeEnum.group ? selectedElement : null;
    this._documentStageManager.clickElement(toFocus);

  }

  // FIXME: Move to right service
  private openPropertiesPanel(
    element: IElementModel,
  ): void {
    if (this._deviceDetectorService.isMobile()) {
      return;
    }

    if (this.isPanelMagicTemplatesOpen()) return;

    this._hotkeyService.enableHotkeys();
    this._panelStackService.popAll();

    switch (element.type) {
      case elementTypeEnum.title:
        this._stackText();
        break;
      case elementTypeEnum.text:
        this._stackText();
        break;
      case elementTypeEnum.image:
        if ((element as any).source === ElementDatasource.FLATICON) {
          this._stackEmoji();
          break;
        }
        this._stackImage();
        break;
      case elementTypeEnum.emoji:
        this._stackEmoji();
        break;
      case elementTypeEnum.gif:
        this._stackGif();
        break;
      case elementTypeEnum.shape:
        this._stackShape();
        break;
      case elementTypeEnum.youtube:
        this._stackVideo();
        break;
      case elementTypeEnum.group:
        this._stackSelection();
        break;
      default:
        console.error(element.type, element);
        break;
    }
  }

  // FIXME: Move to right service
  private _stackDefault() {
    this._panelStackService.stack(PanelElementsComponent);
  }

  // FIXME: Move to right service
  private _stackPage(pageSelected: PageModel) {
    this._panelStackService.stack(PanelPageComponent, {
      inputs: {
        pageSelected,
        b2c: this.b2c,
        pageFormats: null,
        printableMode: false,
      },
    });
  }

  // FIXME: Move to right service
  private _stackText() {
    this._panelStackService.stack(PanelTextComponent, {
      inputs: {
        b2c: this.b2c,
        isTextEditable: false,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
        allowClip: false,
      },
    });
  }

  // FIXME: Move to right service
  private _stackImage() {
    this._panelStackService.stack(PanelImageComponent, {
      inputs: {
        b2c: this.b2c,
        isImage: true,
        isEmoji: false,
        isGif: false,
        isRemoving: false,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
      },
    });
  }

  // FIXME: Move to right service
  private _stackEmoji() {
    this._panelStackService.stack(PanelImageComponent, {
      inputs: {
        b2c: this.b2c,
        isImage: false,
        isEmoji: true,
        isGif: false,
        isRemoving: false,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
      },
    });
  }

  // FIXME: Move to right service
  private _stackGif() {
    this._panelStackService.stack(PanelImageComponent, {
      inputs: {
        b2c: this.b2c,
        isImage: false,
        isEmoji: false,
        isGif: true,
        isRemoving: false,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
      },
    });
  }

  // FIXME: Move to right service
  private _stackShape() {
    this._panelStackService.stack(PanelShapeComponent, {
      inputs: {
        b2c: this.b2c,
        isCliping: this.isClipEditable,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
      },
    });
  }

  // FIXME: Move to right service
  private _stackVideo() {
    this._panelStackService.stack(PanelVideoComponent, {
      inputs: {
        b2c: this.b2c,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
      },
    });
  }

  // FIXME: Move to right service
  private _stackSelection() {
    this._panelStackService.stack(PanelGroupComponent, {
      inputs: {
        b2c: this.b2c,
        allowLockElementsByTemplate: this.allowLockElementsByTemplate,
        isEmbeddedInstance: this._authService.isEmbeddedInstance(),
      },
    });
  }

  public async addNewElement(element: IElementModel): Promise<void> {
    const selectedPage = await this._documentStageManager.pageSnapshot$.toPromise();
    if (!selectedPage) {
      this._alertService.warn(this.notifyMSGs.page_select_to_add_element);
      throw new Error('Sem página selecionada');
    }
    this._documentStageManager.addElement(selectedPage, element);
    await this.selectElement(element);
    await this.clickElement(element);
  }

  public async group(element: IElementModel): Promise<void> {
    if (
      !this._elementModelService.isValidGroup(element) ||
      !this._elementModelService.canHandleElement(element)
    ) {
      // Mostrar mensagem de que não pode ser agrupado
      return;
    }
    const page = await this.getSelectedPage();
    const elements = element['elements'].map(groupElement => page.elements.find(el => groupElement.id === el.id));
    const group = this._elementModelService.newGroupElement(copyObject(elements));
    this._documentStageManager.deleteAndAddElements(page, elements, [group]);
  }

  // FIXME: Move to right service
  public async ungroup(element: IElementModel): Promise<void> {
    if (
      !this._elementModelService.isValidGroup(element) ||
      !this._elementModelService.canHandleElement(element)
    ) {
      // Mostrar mensagem de que não pode ser desagrupado
      return;
    }
    const page = await this.getSelectedPage();
    const group = copyObject(element);
    const groupElements = group['elements'];
    this._documentStageManager.deleteAndAddElements(
      page,
      [group],
      groupElements.reverse(),
    );
  }

  public requestClipMode() {
    this._signalsService.emit(enumSignals.ON_CLIP_MODE, {});
  }

  public async cloneSelectedElementsAndAdd() {
    const selectedElement = await this.getSelectedElement();
    if (!this._elementModelService.canHandleElement(selectedElement)) {
      return;
    }
    if (this._elementModelService.isGroup(selectedElement)) {
      await this.cloneElementsAndAdd(selectedElement['elements']);
    } else {
      await this.cloneElementAndAdd(selectedElement);
    }
  }

  public async cloneElementsAndAdd(
    elements: IElementModel[],
  ): Promise<IElementModel[]> {
    const clonedElements =
      await Promise.all(
        elements.map(element => this.cloneElementAndAdd(element))
      );
    await this.selectElement(null);
    return clonedElements;
  }

  public async cloneElementAndAdd(
    element: IElementModel,
  ): Promise<IElementModel> {
    if (!element.edit || element.lockedByTemplate) {
      throw new Error('Elemento bloqueado');
    }

    const clonedElement = this._pageService.cloneElement(
      await this.getSelectedDocument(),
      await this.getSelectedPage(),
      element,
    );
    await this.addNewElement(clonedElement);

    return clonedElement;
  }

  public async deleteElements(elements: IElementModel[]): Promise<void> {
    await Promise.all(elements.map(e => this.deleteElement(e)));
  }

  // FIXME: Move to right service
  public async deleteElement(element: IElementModel): Promise<void> {
    if (!element.edit) {
      return;
    }
    const page = await this.getSelectedPage();
    if (!page) {
      return;
    }

    this._documentStageManager.deleteElement(page, element);
  }

  public async moveSelectedElementUp(): Promise<void> {
    this._moveElement(await this.getSelectedElement(), element => this.moveUp(element));
  }

  public async moveSelectedElementDown(): Promise<void> {
    this._moveElement(await this.getSelectedElement(), element => this.moveDown(element));
  }

  // FIXME: Move to right service
  public async moveUp(element: IElementModel): Promise<void> {
    const page = await this.getSelectedPage()
    const canMoveUp = this._pageService.canMoveElementUp(page, element);
    if (canMoveUp) {
      this._documentStageManager.moveElementUp(page, element);
    }

    if (!canMoveUp && element.type === elementTypeEnum.youtube) {
      this._alertService.warn(this.notifyMSGs.video_order_limit);
    }
  }

  public async moveDown(element: IElementModel): Promise<void> {
    const page = await this.getSelectedPage();
    const canMoveDown = this._pageService.canMoveElementDown(
      await this.getSelectedPage(),
      element,
    );

    if (canMoveDown) {
      this._documentStageManager.moveElementDown(page, element);
    }

    if (!canMoveDown && element.type === elementTypeEnum.youtube) {
      this._alertService.warn(this.notifyMSGs.element_order_limit_to_video);
    }
  }

  // FIXME: Move to right service
  private detectDocumentChanges() {

    merge(
      this._documentStageManager.change$,
      this._documentStageManager.undoAndRedo$,
    ).pipe(takeUntil(this._destroy$)).subscribe(async event => {
      const document = await this.getSelectedDocument();
      const page = await this.getSelectedPage();
      this.firstPageChanged = await this._isPageAt(page, 0);
      this.savedChangeLast = false;
      this.lastDocumentMetrics = DocumentMetricsModel.build(
        document,
      );
      if (page) {
        this._pageBase64ManagerService.lazyUpdateBase64(
          page,
          document.is_printable,
        );
      }
    });
  }

  private async _isPageAt(page: PageModel, index = 0) {
    return page && page.id === (await this.getSelectedDocument()).body[index].id;
  }

  private async _selectDocument(id: string) {
    if (id) {
      try {
        const document = await this._documentService.findById(id);
        document.firebaseId = id;
        await this.importTemplate(document);
      } catch (e) {
        console.error(e);
        await this._router.navigateByUrl(environment.static.traktoLinksNotFound)
      }
    } else {
      await this.selectDocumentAddPageEmpty();
    }
  }

  private async importTemplate(document: IDocumentModel) {
    const newDocument = this._docInitService.initDocument(
      this._pageService.copyDocument(document),
    );
    await this.selectDocument(newDocument);
    this.lastUpdatedAt = newDocument.updated_at;
    setTimeout(() => this.selectPage(newDocument.body[0])); // já vai ser inicializado pelo método select
  }

  // FIXME: Move to right service
  private async selectDocumentAddPageEmpty() {
    const document = this._elementFactoryService.makeDocumentModel();
    document.body = [this._elementFactoryService.makePageModel()];
    await this.importTemplate(document);
  }

  // FIXME: Move to right service
  private async emitSaveErrorEvent(reject: any, message: string, fatalError = true) {
    if (this.saveErrors.firebase === message) {
      this.incrementSaveError();
      fatalError = this.isSaveFatalError();
    }
    reject({
      message,
      fatal_error: fatalError,
    });

    this.onSaveError.next(
      new SaveErrorEventModel(await this.getSelectedDocument(), message, fatalError),
    );
  }

  // FIXME: Move to right service
  private _configTranslate() {
    this.translatedTexts = this._translateService.translations;
    this.currentLang = this._translateService.currentLang;
    this.saveErrors = {
      firebase:
        this.translatedTexts[this.currentLang].auto_save.errors.firebase,
      empty: this.translatedTexts[this.currentLang].auto_save.errors.empty,
      integrity:
        this.translatedTexts[this.currentLang].auto_save.errors.integrity,
      max_page:
        this.translatedTexts[this.currentLang].auto_save.errors.max_page,
      auth_user:
        this.translatedTexts[this.currentLang].auto_save.errors.auth_user,
      document_not_found:
        this.translatedTexts[this.currentLang].auto_save.errors
          .document_not_found,
      document_outdated:
        this.translatedTexts[this.currentLang].auto_save.errors
          .document_outdated,
    };
  }

  // FIXME: Move to right service
  private isSaveFatalError() {
    return environment.maxSaveAttempts <= this.saveErrorCount;
  }

  // FIXME: Move to right service
  private resetSaveErrorCount() {
    this.saveErrorCount = 0;
  }
  // FIXME: Move to right service
  private incrementSaveError() {
    this.saveErrorCount++;
  }

  private isPanelMagicTemplatesOpen(): boolean {
    const currentStack =
      this._panelStackService.currentStack?.instance.selectedBellaSkill;
    return currentStack !== undefined;
  }

  private refreshSelection() {
    const refreshSelection =
      this._panelStackService.currentStack?.instance.refreshSelection;

    return refreshSelection === undefined ? true : refreshSelection;
  }

  private _moveElement(
    element: IElementModel,
    moveAction: (element: IElementModel) => void,
  ) {
    if (!element) {
      return;
    }

    if (
      this._elementModelService.isGroup(element) &&
      element['elements'].length === 1
    ) {
      moveAction(element['elements'][0]);
    } else if (!this._elementModelService.isGroup(element)) {
      moveAction(element);
    }
  }

  async saveMagicTemplateInfos(infos: IMTElementInfo[]): Promise<IDocumentModel> {
    const document = await this.getSelectedDocument();
    const selectedPage = await this.getSelectedPage();
    const changesByPage: { page: PageModel, elements: IElementModel[] }[] = [];
    infos.forEach(info => {
      const page = info.pageId === selectedPage.id ? selectedPage : document.body.find((page: PageModel) => page.id === info.pageId);
      const element = page.elements.find(el => el.id === info.element.id);
      const change = changesByPage.find(change => change.page.id === page.id) || { page, elements: [] };
      if (!element) {
        return;
      }
      if (change.elements.length === 0) {
        changesByPage.push(change);
      }
      change.elements.push({ ...element, magicTemplate: { tags: info.tags } });
    });
    changesByPage.forEach(change => {
      this._documentStageManager.persistElementChangesNoTrackable(change.page, change.elements);
    });
    await this.save(true);
    return this.getSelectedDocument();
  }

  async forceOpeningElementsPanel() {
    const clickedElement = await this._documentStageManager.clickedElement$.pipe(take(1)).toPromise();
    this._panelStackService.allowPanelOpening();
    this._panelStackService.closeOthers();
    if (clickedElement) {
      this.openPropertiesPanel(clickedElement);
    } else if (this._panelStackService.hasLastStackAfterPanelBlocking) {
      this._panelStackService.openLastStackBeforePanelBlocking();
    } else {
      this._stackDefault();
    }
  }
}

