<div
  class="tkt-selectr"
  [ngClass]="{ 'tkt-select-border': custom, 'tkt-select-mini': customMini }"
>
  <div class="tkt-selectr__current" (click)="toggle()">
    <flag-icon country="{{ active }}" squared></flag-icon>
    <i class="trakto-icon-arrow-down"></i>
  </div>

  <div class="tkt-selectr__options">
    <input
      #inputSearch
      *ngIf="search"
      class="tkt-selectr__search"
      type="text"
      [placeholder]="placeholder"
      (input)="sortBySearch($event.target.value)"
    />

    <div *ngIf="emptyResult" class="tkt-selectr__empty">
      Não foram encontrados resultados...
    </div>

    <div traktoScrollBar>
      <ng-content></ng-content>
    </div>
  </div>
</div>
