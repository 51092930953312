import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';

import { AuthService } from '@app/auth/shared/auth.service';
import { Drawable } from '@editor/components/preview-2/svg-editor/shared/drawable.model';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';

import { IElementModel, ITextElementModel, PageModel } from '@trakto/models';

@Component({
  // REVIEW - Há outro meio de fazer isso, sem usar g[selector-name] ?
  // tslint:disable-next-line: component-selector
  selector: 'g[trakto-drawables-container]',
  templateUrl: './drawables-container.component.html',
})
export class DrawablesContainerComponent implements OnInit {
  @Input()
  public page: PageModel;

  @Input()
  public isB2C: boolean;

  @Input()
  public hasWaterMask = true;

  @Input()
  public elements: IElementModel[];

  @Input()
  public visibleElements: Drawable[];

  @Input()
  public selectedTextElement: ITextElementModel;

  @Input()
  public zoomRatio: number;

  @Input()
  public moving: boolean;

  @Input()
  public resizing: boolean;

  @Output()
  public clickedVideo: EventEmitter<Drawable> = new EventEmitter<Drawable>();

  public safari: boolean;
  public isDropboxProduct = false;
  public isEmbedInstance = false;
  public isPresentation = false;
  private _plataformId;
  constructor(
    private _authService: AuthService,
    private _hotkeyService: HotkeyService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this._plataformId = platformId;
    this.isDropboxProduct = this._authService.isDropboxInstance();
    this.isEmbedInstance = this._authService.isEmbeddedInstance();
    this.isPresentation = this._authService.isPresentationInstance();
  }

  public ngOnInit() {
    if (isPlatformBrowser(this._plataformId)) {
      this.safari = this._hotkeyService.isSafari();
    }
  }

  public trackByFn(index, ve) {
    return ve.element.id; // or item.id
  }
}
