<div class="searchbar">
  <img
    src="assets/icons/tkt-search-elements.svg"
    alt="{{ 'sidebar_elements.placeholder_search' | translate }}"
    class="searchbar__icon"
    (click)="applySearch()"
  />
  <input
    class="searchbar__input"
    type="text"
    placeholder="{{ 'sidebar_elements.placeholder_search' | translate }}"
    (focus)="inputLinkFocus()"
    (blur)="inputLinkBlur()"
    [(ngModel)]="searchQuery"
    (keydown.enter)="applySearch($event)"
  />
  <i
    class="trakto-icon-error searchbar__clear"
    *ngIf="searchQuery.length > 0"
    (click)="editSearch()"
  ></i>

  <div class="searchbar__suggestions" *ngIf="isSearching && isLoading">
    <div class="searchbar__suggestion">
      {{ 'sidebar_elements.loading' | translate }}
    </div>
  </div>
</div>
