import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer } from 'rxjs';

import { AuthService } from '@auth/shared/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.authService.hasValidUserSession().then(isValidSession => {
        if (isValidSession) {
          observer.next(true);
        } else {
          this.authService.redirectToLoginPage();
          observer.next(false);
        }
        observer.complete();
      });
    });
  }
}
