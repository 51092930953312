import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

const IMAGE_BACKGROUND_DEFAULT = '/assets/images/links-background-qrcode.svg';

@Component({
  selector: 'trakto-qrcode-page-download',
  templateUrl: './qrcode-page-download.component.html',
  styleUrls: ['./qrcode-page-download.component.scss'],
})
export class QrcodePageDownloadComponent implements OnInit {
  @Input() profileImage = '';
  @Input() qrcodeLink = '';
  @Input() qrcodeName = '';
  @Input() isB2C;
  @Input() mainLink;

  public imageBackground = IMAGE_BACKGROUND_DEFAULT;

  constructor() {}

  ngOnInit() {}
}
