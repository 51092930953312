<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/ajust_text.svg" label="Fontes" [showBackLabel]="true"
                              (onClose)="closePanel()"
                              class="click-ignore"
  >

  </trakto-panel-header-mobile>

  <trakto-panel-text-font
    [showCloseButton]="false"
    [elementSelected]="elementSelected" [pageSelected]="pageSelected"
    (selectedFont)="closePanel()"
    class="click-ignore"
  >
  </trakto-panel-text-font>
</div>
