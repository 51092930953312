import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-title-with-line',
  templateUrl: './title-with-line.component.html',
  styleUrls: ['./title-with-line.component.scss']
})
export class TitleWithLineComponent implements OnInit {

  @Input() title: string;

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit(): void {}
}
