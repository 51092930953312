import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TraktoLink, TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { SlideStepsConfig } from '../../properties-panel/panel-trakto-links/panel-personalize/social-slider/slideStepsConfigs';
import { Store, select } from '@ngrx/store';
import { TraktoLinkState } from '../store/trakto-link.reducer';
import { Observable, Subject } from 'rxjs';
import * as fromTraktoLink from '../store/trakto-link.selectors';
import * as fromTraktoLinkActions from '../store/trakto-link.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-trakto-link-configure-panel',
  templateUrl: './trakto-link-configure-panel.component.html',
  styleUrls: ['./trakto-link-configure-panel.component.scss']
})
export class TraktoLinkConfigurePanelComponent implements OnInit, OnDestroy {


  @Input() doc: any;
  // @Input() traktoLink: any;
  public traktolink: Observable<TraktoLink>;
  @Input() isB2C: any;
  @Output() onDeleteTraktoLink = new EventEmitter<boolean>();

  public currentStep = 'whatsapp';
  @Input() public slidConfigs: any = SlideStepsConfig;
  @Input() public slideSteps: any[] = this.slidConfigs.data;
  public metaTitle: string;
  public metaDescription: string;
  public link: string;
  public metaImage: string;
  public linkPlaceholder = 'https://trakto.link/';
  public loading = false;
  public saveDone: boolean = false;
  private _destroy$ = new Subject<void>();
  public pausedLink: boolean;

  constructor(
    private _traktoLinksService: TraktoLinksService,
    private _traktoLinkStore: Store<TraktoLinkState>,
  ) {
    this.traktolink = this._traktoLinkStore.pipe(select(fromTraktoLink.selectTraktoLink));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this.traktolink.pipe(takeUntil(this._destroy$)).subscribe(
      (traktoLink: any) => {
        if (!traktoLink) return;

        this.pausedLink = traktoLink.pausedLink;
        this.metaDescription = traktoLink.seo?.description ? traktoLink.seo?.description : traktoLink.description;
        this.metaImage = traktoLink.seo?.thumbUrl ? traktoLink.seo?.thumbUrl : traktoLink.image;
        this.metaTitle = traktoLink.seo?.title ? traktoLink.seo?.title : traktoLink.title;
        this.link = traktoLink.link_name ? traktoLink.link_name : traktoLink.link_web;

      }
    )

    this.linkPlaceholder = this.isB2C ? 'https://trakto.link/' : 'https://olink.pro/';
  }

  handleSliderIconClick(event) {
    this.currentStep = event.cardType;
  }

  save() {
    this.loading = true;
    const traktoLink: TraktoLink = new TraktoLink({
      slug: this.link,
      linkWeb: this.link,
      link_name: this.link,
      link_web: this.link,
      pausedLink: this.pausedLink,
      seo: new TraktoLinkSeo({
        thumbUrl: this.metaImage,
        title: this.metaTitle,
        description: this.metaDescription
      })
    });

    this._traktoLinksService.createOrUpdateMetaData(traktoLink).then().finally(() => {
      this.saveDone = true;
      this.loading = false;

      this._traktoLinkStore.dispatch(fromTraktoLinkActions.editTraktoLink({
        traktoLink: traktoLink
      }))
      setTimeout(() => {
        this.saveDone = false;
      }, 3500);
    }
    )
  }

  closeConfirmationDialog(event) {
    if (event) {
      this.onDeleteTraktoLink.emit(event);
    }
  }
}
