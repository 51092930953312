import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TraktoLinksService } from '@services/traktoLinks/trakto-link.service';
import { traktoLinksPanelsEnum } from '@trakto/models';

@Component({
  selector: 'trakto-links-panel-configure',
  templateUrl: './panel-configure.component.html',
  styleUrls: ['./panel-configure.component.scss'],
})
export class PanelConfigureComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { static: true }) containerInput: ElementRef;
  @ViewChild('inputLabel', { static: true }) label: ElementRef;

  public inputValue = '';
  public loading = false;
  public hasWebLink = false;
  public hasMobileLink = false;
  public status = '';
  public panelsEnum = traktoLinksPanelsEnum;

  constructor(
    private _traktoLinkService: TraktoLinksService,
    private _renderer2: Renderer2,
  ) {}

  ngOnInit() {
    this.updateLinksValues();
  }

  ngAfterViewInit() {
    this.addClassDisabledInputs();
  }

  private addClassDisabledInputs(): void {
    !this.hasMobileLink
      ? this._renderer2.addClass(
          this.containerInput.nativeElement,
          'input-disabled',
        )
      : this._renderer2.removeClass(
          this.containerInput.nativeElement,
          'input-disabled',
        );
  }

  public focus(event: FocusEvent): void {
    this._renderer2.addClass(this.label.nativeElement, '--has-focus');

    this._renderer2.addClass(this.containerInput.nativeElement, '--has-focus');
  }

  public blur(event: FocusEvent): void {
    this._renderer2.removeClass(this.label.nativeElement, '--has-focus');

    this._renderer2.removeClass(
      this.containerInput.nativeElement,
      '--has-focus',
    );
  }

  updateLinksValues() {
    // trun string into Boolean to compare
    this.inputValue = !!this._traktoLinkService.linkWeb
      ? this._traktoLinkService.linkWeb
      : '';

    // if is empty string them will be false (!!'' === false)
    this.hasWebLink = !!this._traktoLinkService.linkWeb;
    this.hasMobileLink = !!this._traktoLinkService.linkName;

    if (!this.hasMobileLink && !this.hasWebLink) {
      this.status = this.panelsEnum.LINK_EMPTY;
    }

    if (this.hasMobileLink && this.hasWebLink) {
      this.status = this.panelsEnum.LINK_SUCCESS;
    }
  }

  async handleSaveWebLink() {
    this.loading = true;

    await this._traktoLinkService.updateWebLink(this.inputValue);

    this.hasWebLink = true;
    this.status = this.panelsEnum.LINK_SUCCESS;
    this.loading = false;
  }

  async removeWebLink() {
    this.loading = true;

    this.inputValue = null;
    await this._traktoLinkService.updateWebLink(this.inputValue);

    this.hasWebLink = false;
    this.status = this.panelsEnum.LINK_EMPTY;
    this.loading = false;
  }
}
