<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-whatsapp.svg"
    label="Link para whatsapp"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper">
    <trakto-input
      [value]="number"
      label="Número do telefone"
      placeholder="+55 (DDD) 0 0000 - 0000"
      (onChange)="formatPhone($event)"
      icon="whatsapp"
      iconColor="#1dc763"
    ></trakto-input>

    <trakto-input
      [value]="message"
      label="Sua Mensagem"
      placeholder="Digite sua mensagem aqui"
      (onChange)="changeMessageValue($event)"
      icon="email-message"
      iconColor="#1dc763"
    ></trakto-input>

    <trakto-button-loader
      [isDisabled]="!isFormValid"
      (action)="applyLinkToElement()"
      label="Adicionar link"
    ></trakto-button-loader>
  </div>
</div>
