<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select placeholder="{{placeholder}}" [compareWith]="compareByValue" [multiple]="multiple"
    (openedChange)="onOpenedChange($event)" (selectionChange)="onSelectionChange($event)"
    [value]="selectedValue">
    <mat-option *ngIf="!multiple" [value]="null">
      {{ 'sidebar_publish.none_options' | translate }}
    </mat-option>
    <mat-option *ngFor="let option of options" [value]="option || option.id">
      {{ option?.title || option?.label || option?.descriptor?.label || option }}
    </mat-option>
  </mat-select>
</mat-form-field>