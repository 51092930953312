import { Injectable, OnDestroy } from '@angular/core';
import {
  elementTypeEnum,
  IElementModel,
  IShapeElementModel,
  ITextElementModel,
  IYoutubeElementModel,
  PageModel,
} from '@trakto/models';
import { Observable, Subject, of } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ElementModelService } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import { ShapeElementService } from '@services/shape-element.service';
import { TextElementService } from '@services/text-element.service';
import { generateUUID, makeRectPath } from '@trakto/core-editor';
import { removeUndefinedProperties } from '@trakto/core-editor/dist/operations/util.operations';
import { takeUntil } from 'rxjs/operators';

/**
 * Métodos úteis para conversão do ElementModel para o novo modelo considerando rotação
 */
@Injectable({
  providedIn: 'root',
})
export class NewModelService implements OnDestroy{
  private _currentLang: string;
  translatedTexts: any;
  private _destroy$ = new Subject<void>();

  constructor(
    private newShapeService: ShapeElementService,
    private newFontService: TextElementService,
    private newImageService: ImageElementService,
    private translateService: TranslateService,
    private newElementService: ElementModelService,
  ) {
    this._currentLang = translateService.currentLang;
    this.translateService
      .get('general')
      .pipe(takeUntil(this._destroy$))
      .subscribe(texts => (this.translatedTexts = texts));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Cria um novo elementModel com as novas propriedades definidas. Somente inicializa os novos atributos se não
   * o tiverem sido.
   */
  public convertElementModel(
    container: PageModel,
    element: IElementModel,
  ): IElementModel {
    removeUndefinedProperties(element);

    if (!element.id) {
      element.id = generateUUID();
    }

    if (!element.supportWidth) {
      element.supportWidth = element.width;
    }

    if (!element.supportHeight) {
      element.supportHeight = element.height;
    }

    if (!element.rotation) {
      element.rotation = 0;
    }

    if (!element.scaleX) {
      element.scaleX = 1;
    }

    if (!element.scaleY) {
      element.scaleY = 1;
    }

    if (!element.cx) {
      element.cx = container.width / 2 || 0;
    }

    if (!element.cy) {
      element.cy = container.height / 2 || 0;
    }

    if (element['submask']) {
      Object.assign(element, element['initials']);
    }

    if (element.type === elementTypeEnum.shape) {
      element['sourcePath'] = element['path'];
      this.newShapeService.initSyncShapeModel(
        element as IShapeElementModel,
        container,
        true,
        true,
      );
    }

    if (element.type === elementTypeEnum.text) {
      const convertedTextElement = element as ITextElementModel;
      this.newElementService.configInitialScale(element, container);
      if (convertedTextElement.textEncoded) {
        let newText = '';
        convertedTextElement.textEncoded.forEach(t => (newText += t.text));
        convertedTextElement.text = newText + '\n';
      } else if (!element['text']) {
        convertedTextElement.text = this.translatedTexts.general.placeholder_text_component;
      }

      this.newFontService.fitTextDimension(element as ITextElementModel);
      this.newElementService.forceUpdate(element);
    }

    if (element.type === elementTypeEnum.emoji && !element.finalMatrixStr) {
      element.supportWidth = 70;
      element.supportHeight = 70;
      this.newElementService.configInitialScale(element, container);
    }

    if (!element.supportPath) {
      element.supportPath = makeRectPath(
        element.supportWidth,
        element.supportHeight,
      );
    }

    element.converted = true;

    // Atualiza os campos derivados (containerPath, supportPath, ...)
    this.newElementService.forceUpdate(element);

    return element;
  }
}
