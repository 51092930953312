import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';

@Injectable()
export class EmbedIntegrationGuard implements CanActivate {
    constructor(
        private _authService: AuthService,
    ) { }
    
    canActivate(): Promise<boolean> {
        const accessToken = localStorage.getItem('trakto:accessToken');
        if (!accessToken) throw 'Check if your SDK instance authenticated with Trakto Editor.';
        return this._authService.signinByAccessToken(accessToken).then((success: boolean) => {
            return success;
        })
        .catch((err: Error) => {
            throw err;
        });
    }
}