import { Injectable, OnDestroy } from '@angular/core';

import { ElementModelService } from '@services/element-model.service';
import { ShapeElementService } from '@services/shape-element.service';
import { StorageService } from '@shared/storage/storage-service.service';

import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  ImageElementModel,
  PageModel
} from '@trakto/models';

import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, delay, flatMap, map, takeUntil, tap } from 'rxjs/operators';

import { ImageUtilService } from '@services/image-util.service';
import { MediaService } from '@services/media.service';
import { YoutubeService } from '@services/youtube/youtube.service';
import {
  DimensionModel,
  ImageElementOperations,
  lightweightElementUpdate
} from '@trakto/core-editor';
import { ResolutionsModel } from '@trakto/graphics-resources';

/**
 * Métodos úteis para conversão do ElementModel para o novo modelo considerando rotação
 */
@Injectable({
  providedIn: 'root',
})
export class ImageElementService extends ImageElementOperations implements OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    newElementModelService: ElementModelService,
    newShapeElementService: ShapeElementService,
    imageUtilService: ImageUtilService,
    youtubeService: YoutubeService,
    private _mediaService: MediaService,
    private _storageService: StorageService,
  ) {
    super(
      newElementModelService,
      newShapeElementService,
      imageUtilService,
      youtubeService,
      _mediaService,
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public loadFullImageQuality(
    image: ImageElementModel,
    page: PageModel,
    placeholder = this.errorImage,
  ): Observable<ImageElementModel> {
    return this._mediaService.uploadImageByRequest(image).pipe(
      tap(result => {
        [ 'low', 'medium', 'high', 'raw', 'href' ].forEach(key => {
          if (result[key]) result[key] = this._replaceOriginalUrl(result[key]);
        });
      }),
      flatMap(() =>
        this.initMediaDimension(image, page, false, true, true).pipe(
          map(element => element as ImageElementModel),
        ),
      ),
      catchError(() => {
        if (image.href.includes('cloudinary.com')) {
          return of(image);
        }
        return this.initMediaDimension(
          { ...image, href: placeholder } as IElementModel,
          page,
          false,
          true,
          true,
        ).pipe(map(result => Object.assign(image, result)));
      }),
    );
  }

  public async createClippedImageByUrl(url: string, container: PageModel): Promise<ImageElementModel> {
    const uploadResult = await this._mediaService.uploadImageByUrl(url)
    return this.createClippedImageByUrls(uploadResult.resolutions, container);
  }

  public async createClippedImageByUrls(urls: ResolutionsModel, container: PageModel): Promise<ImageElementModel> {
    const fixedUrls = this._fixResolutionModel(urls);
    const media = await this._createImageByUrls(fixedUrls, container);
    const shape = this.elementOperations.checkId(
      this.shapeElementOperations.createShapeByElement(media),
    );
    return this.clip(media, shape, media.id);
  }

  public async createGifByUrl(url: string, container: PageModel): Promise<ImageElementModel> {
    const uploadResult = await this._mediaService.uploadImageByUrl(url)
    return this.createGifByUrls(uploadResult.resolutions, container);
  }

  public async createGifByUrls(urls: ResolutionsModel, container: PageModel): Promise<ImageElementModel> {
    return {
      ...(await this.createClippedImageByUrls(urls, container)),
      type: elementTypeEnum.gif,
    }
  }

  public async changeSubmaskBySvgUrl(image: ImageElementModel, url: string): Promise<ImageElementModel> {
    const fixedElement = this._fixUrlsFromImageElement(image);
    const path = await this._mediaService.getSVGPathByUrl(url).toPromise();
    this.resetClip(fixedElement, path);
    return fixedElement;
  }

  public async changeImageUrl(image: ImageElementModel, url: string, changeDimension: boolean = false): Promise<ImageElementModel> {
    const urls = await this._mediaService.uploadImageByUrl(url)
    return await this.changeImageUrlByResolutions(image, urls.resolutions, changeDimension);
  }

  public async changeImageUrlByResolutions(image: ImageElementModel, resolutions: ResolutionsModel, changeDimension: boolean = false, imageDimension?: DimensionModel): Promise<ImageElementModel> {
    image.low = resolutions.low.secure_url;
    image.medium = resolutions.medium.secure_url;
    image.high = resolutions.high.secure_url;
    image.raw = resolutions.raw.secure_url;
    image.href = resolutions.high.secure_url;
    const dimension = imageDimension || await this.imageOperations.getImageDimension(image.medium).toPromise();
    this.elementOperations.changeDimension(image, dimension, changeDimension);
    lightweightElementUpdate(image);
    return image;
  }

  public updateFirestoreCacheControl(document: IDocumentModel): void {
    let observables = [];
    this.elementOperations
      .getAllElementsByDocument(document)
      .filter(
        element =>
          this.elementOperations.isImage(element) && !element['cached'],
      )
      .forEach(element => {
        const metaImageObservables = [
          'href',
          'low',
          'medium',
          'high',
          'raw',
        ].map(quality => {
          return this._storageService
            .updateFirestoreCacheControl(element[quality])
            .pipe(
              delay(1000),
              tap(result => {
                if (result) {
                  element['cached'] = true;
                }
              }),
            );
        });
        observables = observables.concat(metaImageObservables);
      });
    concat(...observables).pipe(takeUntil(this._destroy$)).subscribe();
  }

  public clearBlobRefs(page: PageModel): void {
    this.getNonUploadedImagesByPage(page)
      .map(element => element as ImageElementModel)
      .forEach(image => {
        if (this._isBlob(image.href)) {
          image.href = this.errorImage;
        }
        if (this._isBlob(image.high)) {
          image.high = this.errorImage;
        }
        if (this._isBlob(image.low)) {
          image.low = this.errorImage;
        }
        if (this._isBlob(image.medium)) {
          image.medium = this.errorImage;
        }
        if (this._isBlob(image.raw)) {
          image.raw = this.errorImage;
        }
      });
    if (this._isBlob(page.backgroundImage)) {
      page.backgroundImage = this.errorImage;
    }
    if (this._isBlob(page.backgroundImageLow)) {
      page.backgroundImageLow = this.errorImage;
    }
    if (this._isBlob(page.backgroundImageMedium)) {
      page.backgroundImageMedium = this.errorImage;
    }
    if (this._isBlob(page.backgroundImageHigh)) {
      page.backgroundImageHigh = this.errorImage;
    }
    if (this._isBlob(page.backgroundImageRaw)) {
      page.backgroundImageRaw = this.errorImage;
    }
  }

  private async _createImageByUrls(urls: ResolutionsModel, container: PageModel): Promise<ImageElementModel> {
    const fixedUrls = this._fixResolutionModel(urls);
    const dimension = await this.imageOperations.getImageDimension(fixedUrls.medium.secure_url).toPromise();
    const media = this.createImage({
      width: dimension.width,
      height: dimension.height,
      cx: container.width / 2,
      cy: container.height / 2,
      href: fixedUrls.medium.secure_url,
      rotation: 0,
    });
    media.opacity = 1;
    media.raw = fixedUrls.raw.secure_url;
    media.low = fixedUrls.low.secure_url;
    media.medium = fixedUrls.medium.secure_url;
    this.elementOperations.convertDimension(media, dimension, container, true);
    return media;
  }

  private _isBlob(href: string) {
    return href && href.includes('blob');
  }

  private _replaceOriginalUrl(url: string) {
    if (url?.startsWith('https://assets.storage.trakto.io')) {
      return url.replace(
        'https://assets.storage.trakto.io',
        'https://s3.us-west-2.amazonaws.com/assets-v3.trakto.io',
      ) || url;
    }
    return url;
  }

  private _fixResolutionModel(resolutions: ResolutionsModel): ResolutionsModel {
    ['low', 'medium', 'high', 'raw'].forEach(key => {
      if (!resolutions[key]) {
        resolutions[key] = {
          url: resolutions[key].url ? this._replaceOriginalUrl(resolutions[key].url) : "",
          secure_url: resolutions[key].secure_url ? this._replaceOriginalUrl(resolutions[key].secure_url) : "",
        };
      }
    });
    return resolutions;
  }

  private _fixUrlsFromImageElement(image: ImageElementModel): ImageElementModel {
    ['low', 'medium', 'high', 'raw', 'href'].forEach(key => {
      if (image[key]) {
        image[key] = this._replaceOriginalUrl(image[key]);
      }
    });
    return image;
  }
}
