import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgColorModel } from '@shared/color/classes/color.model';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'trakto-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
})
export class ColorPickerComponent {
  @Input()
  color: NgColorModel;

  @Output()
  onChange: EventEmitter<NgColorModel> = new EventEmitter();

  constructor(private _colorService: ColorService) {
    this.color = this.color || new NgColorModel('rgba(255,255,255,1)');
  }

  inputColorChange(color: NgColorModel) {
    if (color && this.color && color.hexadecimal === this.color.hexadecimal) {
      return;
    }
    this.color = color;
    this.onChange.emit(color);
  }
}
