import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ConfirmationService
} from '@app/editor-v3/services/confirmation.service';
import { DocumentService } from '@app/editor-v3/services/DocumentService';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { ProductService } from '@app/editor-v3/services/product.service';
import {
  EnumSavingStatus,
  SavingService,
} from '@app/editor-v3/services/saving.service';
import { environment } from '@env/environment';
import { IDocumentModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { PlanConfigService } from '@app/shared/subscription/plan-config.service';

@Component({
  selector: 'trakto-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('documentInput') documentInput?: ElementRef;

  public isPremiumUser = false;
  public logoPath = 'assets/editor-v3/images/trakto.svg';
  public logoAlt = 'Trakto';

  public isRedoAvailable = true;
  public isUndoAvailable = true;
  private _onHistoryChange: Subscription = null;

  public documentName = 'Design sem nome';
  public isInputFocused = false;

  public savingStatus: EnumSavingStatus = EnumSavingStatus.SAVED;
  public savingStatusLabel: string = 'Salvar';
  public document: IDocumentModel;
  private _onSavingStatusChange: Subscription = null;
  private _onSelectDocument: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    public elementRef: ElementRef,
    private _savingService: SavingService,
    private _documentService: DocumentService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _confirmationService: ConfirmationService,
    private _productService: ProductService,
    private _panelService: PanelService,
    private _planConfig: PlanConfigService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initTheme();
    this.initSubscriptions();
    this.isPremiumUser = await this._planConfig.isPremiumUser();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onSavingStatusChange =
      this._savingService.onSavingStatusChange.subscribe(status => {
        this.savingStatus = status;
        this.setSavingStatusLabel();
      });

    this._onSelectDocument =
      this._documentStateManagerService.document$.subscribe(doc => {
        this.document = doc;
        this.documentName = doc.title;
      });

    this._onHistoryChange =
      this._documentStateManagerService.allChanges$.subscribe((historyState: any) => {
        this.isRedoAvailable = historyState.future.length > 0;
        this.isUndoAvailable = historyState.past.length > 1;
      });

    this._subscriptions.push(
      this._onSavingStatusChange,
      this._onSelectDocument,
      this._onHistoryChange,
    );
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  async initTheme() {
    const { logo, title, id } = await this._productService.getCurrentProduct();

    if (environment.productIdB2C !== id) {
      if (logo.colored?.low?.url) this.logoPath = logo.colored.low.url;
      if (title) this.logoAlt = title;
    }
  }

  handleSave() {
    this._savingService.saveDocument();
  }

  setSavingStatusLabel() {
    switch (this.savingStatus) {
      case EnumSavingStatus.JUST_SAVED:
        this.savingStatusLabel = 'Salvo!';
        break;

      case EnumSavingStatus.SAVED:
        this.savingStatusLabel = 'Salvar';
        break;

      case EnumSavingStatus.SAVING:
        this.savingStatusLabel = 'Salvando...';
        break;

      case EnumSavingStatus.ERROR:
        this.savingStatusLabel = 'Erro!';
        break;

      default:
        break;
    }
  }

  toggleRedo() {
    this._documentStateManagerService.redo();
  }

  toggleUndo() {
    this._documentStateManagerService.undo();
  }

  toggleInputFocus(val: boolean): boolean {
    this.isInputFocused = val;

    if (this.isInputFocused) {
      setTimeout(() => {
        this.documentInput?.nativeElement.focus();
      }, 50);

      return true;
    }

    this._documentService.renameDocument(
      this.documentName || 'Design sem nome',
      this.document,
    );
  }

  navigateToDashboard() {
    // const url = environment.externalDashboardPage;
    // window.location.href = url;
    (window as any).callAppFunction(true);
  }

  backHomeConfirmation() {
    const inputs = {
      icon: 'assets/editor-v3/images/home.svg',
      title: 'Você tem certeza que deseja voltar para o início?',
      description: 'Certifique-se de salvar o seu documento antes disso.',
      cancel: 'Continuar editando',
      confirm: 'Voltar para a galeria',
    };

    const outputs = {
      action: {
        emit: () => this.navigateToDashboard(),
      },
    };

    this._confirmationService.show(inputs, outputs);
  }

  downloadDocument() {
    this._panelService.openPanel('download');
  }
}
