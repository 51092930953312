import { TKT_PLAN_PERIODICITY, TKT_PLAN_PERIODICITY_PARSED } from '@app/editor/enums/analytics/tkt-analytics-sub-plans.enum';
import { TKTAnalyticsAccessEvent } from '@app/editor/model/analytics/tkt-analytics-access-event.model';
import { TKTAnalyticsIdentify } from '@app/editor/model/analytics/tkt-analytics-identify.model';
import { TKT_SUBSCRIPTION_TYPE } from '@app/editor/model/analytics/tkt-analytics-subscription.model';
import { IUser } from '@trakto/models';

export default class TKTAnalyticsUserFactory {

  static createIdentifyPayload(user: IUser): TKTAnalyticsIdentify {
    const { email, current_profile, profiles } = user;
    const { gateway } = current_profile?.subscription || {};

    const { product } = current_profile;
    const productTitles = profiles
      .map(profile => {
        const { product } = profile;
        if (product.title && product.title.length !== undefined) {
          return profile.product.title;
        }
      });
    const payload = {
      userId: user.id,
      user_properties: {
        email,
        id_do_app_atual: user.app_reference?.id || 'unknown',
        id_do_produto_atual: product.id,
        id_dos_produtos_vinculados: profiles.map(profile => profile.product.id).toString(),
        nome_do_produto_atual: product.title,
        tipo_de_assinatura: TKTAnalyticsUserFactory._getSubscriptionType(current_profile),
        nome_dos_produtos_vinculados: productTitles.toString(),
        periodicidade_de_assinatura: this.parsePeriodicityPlan(
          gateway?.interval as TKT_PLAN_PERIODICITY,
        ),
        nome_do_plano_da_assinatura: gateway?.product.title['pt-BR'] || 'unknown',
      },
    };
    if (productTitles.length === 0) {
      delete payload.user_properties.nome_dos_produtos_vinculados;
    }

    return payload;
  }

  static parsePeriodicityPlan(
    period: TKT_PLAN_PERIODICITY,
  ): TKT_PLAN_PERIODICITY_PARSED {
    return (
      TKT_PLAN_PERIODICITY_PARSED[period] ||
      TKT_PLAN_PERIODICITY_PARSED.lifetime
    );
  }

  static createPlatformAccessPayload(user: IUser): TKTAnalyticsAccessEvent {
    return {
      userId: user.id,
      productId: user.current_profile?.product.id || 'unknown',
      appId: user.app_reference?.id || 'unknown',
    };
  }

  private static _getSubscriptionType(currentProfile){
    return currentProfile?.product.type === 'B2B' ||
      currentProfile?.product.type === 'SDK' ||
      currentProfile?.subscription?.payment_status !== 'free' ? 
        TKT_SUBSCRIPTION_TYPE.PAID : TKT_SUBSCRIPTION_TYPE.FREE
  }
}
