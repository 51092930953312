<div
  class="trakto-input"
  [ngClass]="{ '--has-prefix': prefix, '--has-suffix': suffix }"
>
  <div
    *ngIf="prefix"
    class="trakto-input__label"
    [ngClass]="{ '--link': prefix === 'trakto-icon-link' }"
  >
    <i [class]="prefix"></i>
  </div>

  <input
    #input
    [type]="type"
    [(value)]="value"
    [placeholder]="placeholder"
    (input)="change($event.target.value)"
    (focus)="focus($event)"
    (blur)="blur($event)"
    (keyup)="keyPress($event)"
    (keyup.enter)="keyChange($event)"
    min="1"
    [pattern]="pattern"
    class="trakto-input__field"
  />

  <ng-content></ng-content>

  <i *ngIf="suffix" [class]="suffix"></i>
</div>
