import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { DocumentListComponent } from '../list/document-list/document-list.component';
import { IDocumentActions } from './../list/document-list/document-actions.interface';
import { IDocumentItem } from './../renderers/document-item-renderer/document-item.model';

/**
 * Trakto Modal Documents
 *
 * @usageNotes
 * One ype of data structure is allow:
 *
 * ```
 * this.dataProvider: DocumentItem[] = [
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   },
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   },
 *   {
 *     title: 'Nome do documento',
 *     dimensions: '21 x 29.7cm',
 *     link_path: 'http://localhost:4200/assets/images/trakto-minimal.svg',
 *     filename: 'marca.svg'
 *   }
 * ];
 * ```
 * Use
 *
 * ```
 *  <trakto-modal-documents
 *      dataProvider]="dataProvider"
 *      (onClick)="onAction($event)"
 *      (onEdit)="onAction($event)"
 *      (onSelect)="onAction($event)"
 *      (onCopyLink)="onAction($event)"
 *      (onDownload)="onAction($event)"
 *      (onVisualize)="onAction($event)"
 *      (onClone)="onAction($event)"
 *      (onRemoveMultiple)="onAction($event)"
 *      (onRemove)="onAction($event)"></trakto-modal-documents>
 * ```
 */
@Component({
  selector: 'trakto-modal-documents',
  templateUrl: './modal-documents.component.html',
  styleUrls: ['./modal-documents.component.scss'],
})
export class ModalDocumentsComponent implements OnInit, IDocumentActions {
  @Input()
  public id: string;

  @ViewChild('documentList')
  private _documentList: DocumentListComponent;

  private _dataProvider: IDocumentItem[];
  private _allowMinimize: boolean;

  @Input()
  public set dataProvider(value: IDocumentItem[]) {
    this._dataProvider = value;
  }

  public get dataProvider(): IDocumentItem[] {
    return this._dataProvider || [];
  }

  @Input()
  public set allowMinimize(value: boolean) {
    this._allowMinimize = value;
  }

  public get allowMinimize(): boolean {
    return !this._allowMinimize ? false : true;
  }

  @Output()
  public onClick: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onSelect: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onEdit: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onVisualize: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onCopyLink: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onDownload: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onRemove: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onClone: EventEmitter<IDocumentItem> = new EventEmitter();

  @Output()
  public onRemoveMultiple: EventEmitter<IDocumentItem[]> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this._standardizeCreateModal();
  }

  removeMultiple(items: IDocumentItem[]) {
    this.onRemoveMultiple.emit(items);
  }

  select(item: IDocumentItem) {
    this.onSelect.emit(item);
  }

  click(item: IDocumentItem) {
    this.onClick.emit(item);
  }

  edit(item: IDocumentItem) {
    this.onEdit.emit(item);
  }

  visualize(item: IDocumentItem) {
    this.onVisualize.emit(item);
  }

  copyLink(item: IDocumentItem) {
    this.onCopyLink.emit(item);
  }

  download(item: IDocumentItem) {
    this.onDownload.emit(item);
  }

  remove(item: IDocumentItem) {
    this.onRemove.emit(item);
  }

  clone(item: IDocumentItem) {
    this.onClone.emit(item);
  }

  closeModal() {
    if (this._documentList) {
      this._documentList.resetItemSelection();
    }
  }

  private _standardizeCreateModal() {
    const modalElement: HTMLElement = this._querySelector(
      '#trakto-modal-documents .trakto-modal',
    );

    const contentElement: HTMLElement = this._querySelector(
      `#trakto-modal-documents .trakto-modal__content`,
    );

    modalElement.style.borderRadius = '10px';
    contentElement.style.padding = '0 50px';
  }

  private _querySelector(selector) {
    return document.querySelector(`${selector}`) as HTMLElement;
  }
}
