import { Component, Input, ViewChild } from '@angular/core';

import { IYoutubeElementModel } from '@trakto/models';

import { YoutubeService } from '@editor/services/youtube/youtube.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-video',
  templateUrl: './panel-video.component.html',
})
export class PanelVideoComponent extends AbstractComponent<IYoutubeElementModel> {
  public buttonValidation: boolean;
  public showIcon: boolean;

  public isTranslateButtonLink: any;
  public isHeaderTranslateLink: string;

  public preview: string;
  public url: string;

  @ViewChild('input', { static: true }) input: any;
  @ViewChild('buttonChangePageVideo', { static: true })
  buttonYoutubePreview: any;

  @Input() b2c: boolean;
  @Input() allowLockElementsByTemplate: boolean;

  get href() {
    return this.elementSelected.href;
  }

  set href(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'href', value);
  }

  get autoplay() {
    return this.elementSelected.autoplay;
  }

  set autoplay(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'autoplay', value);
  }


  get mute() {
    return this.elementSelected.mute;
  }

  set mute(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'mute', value);

  }

  get controls() {
    return this.elementSelected.controls;
  }

  set controls(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'controls', value);
  }

  get loop() {
    return this.elementSelected.loop;
  }

  set loop(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'loop', value);
  }

  get showinfo() {
    return this.elementSelected.showinfo;
  }

  set showinfo(value) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'showinfo', value);
  }

  constructor(
    public hotkeyService: HotkeyService,
    private _translateService: TranslateService,
    private _youtubeService: YoutubeService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService)
    this.showIcon = true;
    this.makeTranslateAddAndRemoveLink();
    this.makeTranslateHeaderLinkElement();

    this.preview = null;
  }

  public makeTranslateAddAndRemoveLink(): void {
    this.isTranslateButtonLink = {
      add: '',
      remove: '',
    };

    this._translateService
      .get('property_panel.shape.button_link')
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.isTranslateButtonLink.add = value['add_link'];
        this.isTranslateButtonLink.remove = value['remove_link'];
      });
  }

  public makeTranslateHeaderLinkElement(): void {
    this._translateService
      .get('property_panel.general.add_link_title')
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.isHeaderTranslateLink = value));
  }

  public async setYoutubeButtonPreview() {
    this.preview = null;
    this.input.value = '';

    await this._elementChangeFacadeService.changeYoutubeUrl(
      this.elementSelected,
      this.url
    )
  }

  public setYoutubePreview(value: string, input): void {
    this.isValidLink(input)
      ? (this.buttonValidation = false)
      : (this.buttonValidation = true);
    const url: string = value
      ? this._youtubeService.getYoutubeThumb(value)
      : null;
    this.url = value;
    this.preview = url;
  }

  private isValidLink(link: string): boolean {
    const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return link.match(pattern) && link.match(pattern).length > 0 ? true : false;
  }
}
