import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { IEventModel } from '@trakto/models';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum enumSignals {
  CONVERSION_FINISHED = 'conversion-finished',
  DOCUMENT_PROPERTY_CHANGE = 'document-property-change',
  HISTORY_UNDO = 'history-undo',
  ON_APPLICATION_IS_PROCESSING = 'on-application-is-processing',
  ON_AUTO_SAVE_PROGRESS = 'on-auto-save-progress',
  ON_CHANGE_ALL_PAGES_SIZE = 'on-change-all-pages-size',
  ON_CHANGE_YOUTUBE = 'on-change-youtube',
  ON_TEXT_EDITION_MODE = 'on-text-edition-mode',
  ON_EXIT_TEXT_EDITION_MODE = 'on-exit-text-edition-mode',
  ON_CLIP_MODE = 'on-clip-mode',
  ON_CLIP_EDITABLE = 'on-clip-editable',
  ON_DOCUMENT_GENERATE_THUMB = 'on-document-generate-thumb',
  ON_DOWNLOAD_SIGNAL = 'on-download-signal',
  ON_OPEN_MODAL = 'on-open-modal',
  ON_QUICK_MASK = 'on-quick-mask',
  ON_RENAME_PUBLISH = 'on-rename-publish',
  PROPERTY_FONT_WEIGHT = 'property-font-weight',
  PROPERTY_CHANGE = 'property-change',
  SELECTION_CHANGE = 'selection-change',
  UPLOAD_IMAGE_STARTED = 'upload-image-started',
  PROPERTY_FONT_OPTIONS = 'property-font-options',
  ON_TRAKTO_LINK_PUBLISH = 'on-trakto-link-publish'
}

@Injectable({
  providedIn: 'root',
})
export class SignalsService implements OnDestroy {
  private signals: any;
  private _destroy$ = new Subject<void>();

  constructor() {
    this.signals = {};
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public createSignal(signal: enumSignals): Subject<IEventModel> {
    if (!this.exists(signal)) {
      this.signals[signal] = new Subject<IEventModel>();
    }
    return this.signals[signal];
  }

  public destroySignal(signal: enumSignals): void {
    if (!this.exists(signal)) {
      this.signals[signal].observers.map((s: Subscription, index: number) => {
        s.unsubscribe();
      });
      delete this.signals[signal];
    }
  }

  public exists(signal: enumSignals): boolean {
    return !!this.signals[signal];
  }

  public connect(signal: enumSignals, callback: (p: any) => any): Subscription {
    return this.getSignal(signal).pipe(takeUntil(this._destroy$)).subscribe(result => callback(result));
  }

  public getSignal(signal: enumSignals) {
    if (!this.exists(signal)) {
      this.createSignal(signal);
    }
    return this.signals[signal];
  }

  public disconnect(signal: enumSignals, subs: Subscription): void {
    if (this.exists(signal)) {
      subs.unsubscribe();
    }
  }

  public emit(signal: enumSignals, data: any): void {
    if (!this.exists(signal)) {
      this.createSignal(signal);
    }
    this.signals[signal].next({
      event: signal,
      data,
      bubbles: false,
    });
  }
}
