import { Component, Injectable } from '@angular/core';
import { DrawableComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-shape]',
  template: `
    <svg:path
      *ngIf="!element.loading"
      [traktoPath]="element"
      [zoomRatio]="zoomRatio"
      [ref]="ref"
      [attr.transform]="element.finalMatrixStr"
    ></svg:path>
    <svg:image
      *ngIf="element.loading"
      [attr.href]="element.svg"
      [attr.width]="element.supportWidth"
      [attr.height]="element.supportHeight"
      [attr.transform]="element.finalMatrixStr"
      opacity="0.5"
      x="0"
      y="0"
      fill="#000000'"
    ></svg:image>
  `,
})
@Injectable()
export class DrawableShapeComponent extends DrawableComponent {}
