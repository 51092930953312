import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  DataTracking,
} from '@trakto/data-tracking';
import { GoogleAnalyticsService } from '../../google-analytics.service';
import { FBTraktoTracking } from '../facebook/fbTraktoTracking.analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum projectModifiedTriggerEnum {
  color_panel = 'color_panel',
  duotone_panel = 'duotone_panel',
  filter_panel = 'filter_panel',
}

export enum projectModifiedFeatureTypeEnum {
  align = 'align',
  backgroundColor = 'collor_pallet',
  color = 'collor_pallet',
  color_panel = 'collor_pallet',
  delete = 'removed',
  duotone = 'duotone',
  email = 'link_email',
  filter = 'filter',
  fontFamily = 'fontFamily',
  gallery = 'gallery',
  href = 'link',
  link = 'link_web',
  lock = 'lock',
  opacity = 'opacity',
  order = 'order',
  text = 'insert',
  unlock = 'unlock',
  video = 'video',
  whatsapp = 'link_whatsapp',
  youtube = 'video',
}

@Injectable({
  providedIn: 'root',
})
export class DataTrackingService extends DataTracking implements OnDestroy {
  private _currentLang: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _fbTrack: FBTraktoTracking,
  ) {
    super();

    this._currentLang = this._translateService.currentLang;
    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(
      (event: LangChangeEvent) => (this._currentLang = event.lang),
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public presentationViewed() {
    this._fbTrack.presentationModeViewed();
    this._googleAnalyticsService.trackEvent(
      'No editor, o design foi visualizado no modo apresentação',
      {
        label: 'O usuário realizou a pré-visualização do design no modo apresentação',
        category: 'Modo apresentação',
        value: 1,
      },
      () => {});
  }
}
