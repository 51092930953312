import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TraktoLinksModule
} from '@editor/components/trakto-links-preview/trakto-links.module';
import { PipesModule } from './pipes/pipes.module';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { EditorComponent } from './editor.component';
import { MaterialModule } from './shared/material.module';

import { HeaderModule } from './components/header/header.module';
import {
  PropertiesPanelModule
} from './components/properties-panel/properties-panel.module';

import {
  Preview2Component
} from '@editor/components/preview-2/preview2.component';
import {
  SvgEditorModule
} from './components/preview-2/svg-editor/svg-editor.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import {
  VideoModalComponent
} from './components/video-modal/video-modal.component';

import { SvgViewerModule } from '@app/shared/svg-viewer/svg-viewer.module';
import {
  PageNavigationComponent
} from '@editor/components/preview-2/page-navigation/page-navigation.component';
import {
  PanelColorComponent
} from './components/properties-panel/panel-color/panel-color.component';
import {
  PanelDownloadComponent
} from './components/properties-panel/panel-download/panel-download.component';
import {
  PanelDuotoneComponent
} from './components/properties-panel/panel-duotone/panel-duotone.component';
import {
  PanelFilterComponent
} from './components/properties-panel/panel-filter/panel-filter.component';
import {
  PanelGroupComponent
} from './components/properties-panel/panel-group/panel-group.component';
import {
  PanelImageSettingsComponent
} from './components/properties-panel/panel-image-settings/panel-image-settings.component';
import {
  PanelImageComponent
} from './components/properties-panel/panel-image/panel-image.component';
import {
  PanelLinkEmailComponent
} from './components/properties-panel/panel-link-email/panel-link-email.component';
import {
  PanelLinkWebsiteComponent
} from './components/properties-panel/panel-link-website/panel-link-website.component';
import {
  PanelLinkWhatsappComponent
} from './components/properties-panel/panel-link-whatsapp/panel-link-whatsapp.component';
import {
  PanelPageComponent
} from './components/properties-panel/panel-page/panel-page.component';
import {
  PanelElementsComponent
} from './components/properties-panel/panel-elements/panel-elements.component';
import {
  PanelPublishComponent
} from './components/properties-panel/panel-publish/panel-publish.component';
import {
  PanelShapeComponent
} from './components/properties-panel/panel-shape/panel-shape.component';
import {
  PanelTextFontComponent
} from './components/properties-panel/panel-text-font/panel-text-font.component';
import {
  PanelTextComponent
} from './components/properties-panel/panel-text/panel-text.component';
import {
  PanelVideoSettingsComponent
} from './components/properties-panel/panel-video-settings/panel-video-settings.component';
import {
  PanelVideoComponent
} from './components/properties-panel/panel-video/panel-video.component';
import { ZoomModule } from './components/zoom/zoom.module';

import {
  BannerDiscountComponent
} from './components/banner-discount/banner-discount.component';
import {
  SuperiorPageOptionsComponent
} from './components/preview-2/page-options/superior-page-options/superior-page-options.component';
import {
  LateralPageOptionsComponent
} from './components/preview-2/page-options/lateral-page-options/lateral-page-options.component';
import {
  PopoverComponent
} from './components/preview-2/page-options/superior-page-options/popover/popover.component';
import { ModalUnfoldTemplateComponent } from './components/properties-panel/panel-publish/magic-template/modal-unfold-template/modal-unfold-template.component';
import { ModalBellaComponent } from './components/bella/modal-bella/modal-bella.component';

@NgModule({
  imports: [
    TraktoLinksModule,
    FormsModule,
    PipesModule,
    CommonModule,
    SharedModule,
    HeaderModule,
    MaterialModule,
    SvgEditorModule,
    HttpClientModule,
    ReactiveFormsModule,
    PropertiesPanelModule,
    NgxSkeletonLoaderModule,
    SvgViewerModule,
    ZoomModule,
  ],
  entryComponents: [
    VideoModalComponent,
    PanelElementsComponent,
    PanelPageComponent,
    PanelTextComponent,
    PanelShapeComponent,
    PanelVideoComponent,
    PanelImageComponent,
    PanelColorComponent,
    PanelGroupComponent,
    PanelFilterComponent,
    PanelDuotoneComponent,
    PanelPublishComponent,
    PanelDownloadComponent,
    PanelTextFontComponent,
    PanelLinkEmailComponent,
    PanelLinkWebsiteComponent,
    PanelLinkWhatsappComponent,
    PanelImageSettingsComponent,
    PanelVideoSettingsComponent,
    ModalUnfoldTemplateComponent
  ],
  exports: [EditorComponent],
  declarations: [
    EditorComponent,
    ToolbarComponent,
    Preview2Component,
    VideoModalComponent,
    PageNavigationComponent,
    ModalBellaComponent,
    BannerDiscountComponent,
    SuperiorPageOptionsComponent,
    LateralPageOptionsComponent,
    PopoverComponent,
  ],
})
export class EditorModule {}
