import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent implements OnInit {
  @Input() image: string;
  @Input() margin: number;
  @Input() title: string;
  @Input() description: string;

  constructor() {}
  ngOnInit() {}
}
