import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
})
export class ButtonLoaderComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() label: string = '';

  @Output() action = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleAction() {
    if(!this.isDisabled) this.action?.emit();
  }
}
