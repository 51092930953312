<div class="trakto-modal-loader__overlay"></div>

<div class="trakto-modal-loader">
  <ng-container
    class="trakto-modal-loader__container"
    *ngIf="!finished && !error"
  >
    <div class="trakto-modal-loader__spinner"></div>
    <h3 class="trakto-modal-loader__title">{{ label }}</h3>
  </ng-container>

  <ng-container class="trakto-modal-loader__container" *ngIf="finished">
    <i class="trakto-icon-check"></i>
  </ng-container>

  <ng-container class="trakto-modal-loader__container" *ngIf="error">
    <i class="trakto-icon-close"></i>
    <h3 class="trakto-modal-loader__title">{{ label }}</h3>
  </ng-container>
</div>
