<div
  class="{{ className }} {{
    isFocused ? 'focus' : ''
  }} dropdown-wrapper dropdown_selector"
  [ngClass]="{ 'is-open': isOpen, disabled: isReadOnly }"
  *ngIf="opts"
  (contextmenu)="$event.stopPropagation()"
>
  <div class="box" (click)="toggle($event)">
    <ng-container>
      <div class="dropdown-selected" *ngIf="hasActiveItem">
        <span>{{ activeItem.text }}</span>
      </div>
      <div class="dropdown-selected" *ngIf="!hasActiveItem">
        <span>{{ placeholder }}</span>
      </div>
    </ng-container>
    <i class="tkt-icon trakto-icon-arrow-drop-down"></i>
  </div>
  <ul class="dropdown-options  {{ activePosition }}" *ngIf="opts">
    <!-- <li *ngIf="placeholder" (click)="$event.stopPropagation(); toggle($event);">
      {{placeholder}}
    </li> -->
    <ng-container>
      <li
        id="li{{ i }}"
        *ngFor="let option of opts; let i = index"
        [class.active]="hasActiveItem && activeItem.value === option.value"
        (click)="optionSelect(option, $event)"
      >
        {{ option.text }}
      </li>
    </ng-container>
  </ul>
</div>
