import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-render-svg-icon',
  templateUrl: './render-svg-icon.component.html',
})
export class RenderSvgIconComponent implements OnInit {
  @Input() svg: string;
  @Input() width: number;

  constructor(private _element: ElementRef) {}

  ngOnInit(): void {
    this.buildSvg();
  }

  buildSvg() {
    if (!this.svg) {
      return;
    }

    const parser = new DOMParser();
    const svg = parser.parseFromString(this.svg, 'image/svg+xml')
      .firstChild as Element;

    const xml = new XMLSerializer().serializeToString(svg);
    const svgParsed = btoa(xml);

    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + svgParsed;

    if (this.width !== undefined) {
      img.width = this.width;
    }

    this._element.nativeElement.innerHTML = '';
    this._element.nativeElement.append(img);
  }
}
