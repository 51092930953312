import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IDocumentModel, IFormats, PageModel, } from '@trakto/models';

import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth/shared/auth.service';
import { environment } from '@env/environment';
import { TraktoApiService } from '@services/trakto-api.service';
import {
  PageFormatRepository
} from '@editor/repository/page-format.repository';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { UserService } from '@app/editor-v3/services/user.service';
import { WhitelabelProductService } from './whitelabel-config/whitelabel-product.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService implements OnDestroy {
  public documentId: string;

  private _formats: IFormats[];

  public rtDocRef: AngularFireObject<any>;
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private _httClient: HttpClient,
    private _authService: AuthService,
    private _pageFormatRepository: PageFormatRepository,
    private _http: HttpClient,
    private _apiService: TraktoApiService,
    private _rtdb: AngularFireDatabase,
    private _userService: UserService,
    private _whitelabelService: WhitelabelProductService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public async save(document: IDocumentModel): Promise<IDocumentModel> {
    if (!document.firebaseId) {
      throw new Error('Document not saved');
    }
    const toSave: IDocumentModel = { ...document, pages: document.pages.map(page => ({ ...page })), body: document.body.map(page => ({ ...page })) };
    toSave.provider = this._userService.isGlobalAdmin ? 'admin' : 'user';
    toSave.page_format_reference = document.page_format_reference || (this._formats && this._formats.length > 0 ? { id: this._formats[0].id } : null);
    toSave.app_demo = document.app_demo || false;

    const documentSaved: IDocumentModel = await this._apiService.executePut<IDocumentModel>(`document/header/${document.firebaseId}`, this.initDocumentPages(toSave));
    await this.updateRealtimeDocument(document.firebaseId, document.body);
    documentSaved.body = toSave.body;
    this.initPagesFormats(documentSaved);
    documentSaved.updated_at = new Date(documentSaved.updated_at);
    return documentSaved;
  }

  public async clone(id: string): Promise<IDocumentModel> {
    const result = await this.findById(id);
    return this._doCloneByDocument(result);
  }

  public delete(id: string): Promise<void> {
    return this._apiService.executeDelete(`document/${id}`);
  }


  public async findById(id: string): Promise<IDocumentModel> {
    const document: IDocumentModel = await this._apiService.executeGet(`document/${id}`, {}, true);
    document.updated_at = document.updated_at ? new Date(document.updated_at) : null;
    document.body = await this.getDesignPages(id);
    return document;
  }

  public async findPublicById(id: string): Promise<IDocumentModel> {
    return this._apiService.executeGet(`public/document/${id}/full`, {}, true);
  }

  public getDocumentHeader(id: string): Promise<IDocumentModel> {
    return this._apiService.executeGet(`document/${id}`)
      .then((data: IDocumentModel) => ({
        ...data,
        updated_at: data.updated_at ? new Date(data.updated_at) : null,
      }));
  }

  public castPublishedDocumentToEmbeddedPage(processedPages: any, documentId: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!processedPages || !processedPages.length || processedPages.length === 0) {
          reject('No pages!!');
        }
        const docData = await this.findById(documentId);
        const docPublished = {
          user_id: this._authService.currentUser?.id,
          title: docData.title || '',
          document_id: this.documentId,
          created_at: docData.created_at,
          pages: processedPages
            ? processedPages.map((page, index) => {
              return {
                page_index: index,
                page_format_id:
                  docData[index] && docData[index].format
                    ? docData[index].format.id
                    : null,
                thumbs: this._mapSDKResolutionModel(page.resolutions),
              };
            })
            : [],
        };
        resolve(docPublished);
      } catch (e) {
        reject(e);
      }
    });
  }

  public _mapSDKResolutionModel(resolutions: any): any {
    const resolutionTransformed = {};
    for (const key in resolutions) {
      if (Object.prototype.hasOwnProperty.call(resolutions, key)) {
        resolutionTransformed[key] = {
          ...resolutions[key],
          dimensions: {
            width: resolutions[key].dimensions.width,
            height: resolutions[key].dimensions.height,
          },
        };
      }
    }
    return resolutionTransformed;
  }

  // TODO: Mover para outro serviço relacionado a link de elementos
  public getCountries() {
    return this._httClient.get(`assets/json/countries.json`);
  }

  public async copyDocumentToUser(documentId: string): Promise<IDocumentModel> {
    return this._apiService.executeGet<IDocumentModel>(`document/design-copy/${documentId}`, {});
  }

  public async _doCloneByDocument(document: IDocumentModel): Promise<IDocumentModel> {
    const createdDocument = await this._apiService.executePost<IDocumentModel>(`/document/create-blank`, {});
    return this._apiService.executePut<IDocumentModel>(`document/${createdDocument.id}`, { ...document, id: createdDocument.id });
  }

  // TODO: Alterar fluxo de uso. Não precisa ter dependência de listagem de formatos de páginas.
  private async init() {
   this._pageFormatRepository
    .findByLoggedUser([this._whitelabelService.currentProduct])
    .then((formats) => this._formats = formats);
  }

  // TODO: Pode ser movido para classe de validações do documento
  private initDocumentPages(document: IDocumentModel): IDocumentModel {
    document.pages = [];
    document.body.forEach((page: PageModel) => {
      document.pages.push({
        format: page.format || null,
      });

      delete page.format;
    });
    return document;
  }

  // TODO: Mover para o pageformats service
  private initPagesFormats(document: IDocumentModel): void {
    document.body.forEach((page: PageModel, index: number) => {
      const pageFormat = document.pages[index];
      page.format = pageFormat ? pageFormat.format : null;
    });
  }

  // TODO: Pode ser movido para classe de validações do documento
  public isEmpty(doc: IDocumentModel) {
    return !doc.body || doc.body.length === 0;
  }

  // TODO: Pode ser movido para classe de validações do documento
  public hasAllowedPagesNumber(doc: IDocumentModel) {
    return (doc.body || []).length <= environment.maxPages;
  }

  // TODO: Pode ser movido para classe de validações do documento
  public hasAllowedAddPage(doc: IDocumentModel) {
    return (doc.body || []).length < environment.maxPages;
  }
  /**
   * Using an external API, to get the metadata from a link path.
   * @param linkName link path registered by the user
   */
  public getPublicMetadata(linkName: string): Observable<any> {
    return this._http.get<any>(
      `${environment.api.baseUrl}document/social-metadata/${linkName}`,
    );
  }

  public updateRealtimeDocument(id: string, pages: PageModel[]): Promise<any> {
    if (pages && pages.length > 0) {
      if (!this.rtDocRef) {
        this.rtDocRef = this._rtdb.object(`documents/${id}`);
      }
      return this.rtDocRef.set(pages);
    }
    return new Promise<any>((resolve, reject) => {
      reject('Não pode salvar um documento vazio');
    });
  }

  public getDesignPages(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._rtdb
        .object(`documents/${id}`)
        .query.once('value')
        .then(value => {
          resolve(value.val());
        })
        .catch(error => reject(error));
    });
  }
}
