<div class="tags">
  <div
    *ngFor="let tag of finalTags"
    class="tag"
    [ngClass]="{ active: tag.toLowerCase() === selected.toLowerCase() }"
    (click)="selectTag(tag)"
  >
    {{ tag }}
  </div>
</div>
