import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';

@Component({
  selector: 'trakto-input-chip-field',
  templateUrl: './input-chip-field.component.html',
  styleUrls: ['./input-chip-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputChipFieldComponent),
      multi: true,
    },
  ],
})
export class InputChipFieldComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder: string;  
  @Input() options: string[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  private onChange: (chips: string[]) => void = () => {};
  private onTouched: () => void = () => {};
  
  addOnBlur: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  
  @Output() valueChange = new EventEmitter<string[]>();
  @Output() chipRemoved = new EventEmitter<number>();

  constructor(
    public hotkeyService: HotkeyService,
  ) { }

  ngOnInit(): void {}

  onChipsChanged(chips: string[]): void {
    this.valueChange.emit(chips);
  }
  
  onChipsRemoved(chips: number): void {
    this.chipRemoved.emit(chips);
  }
  
  writeValue(value: string[]): void {
    if (this.options && value) {
      this.options = value || [];
    }
  }

  registerOnChange(fn: (chips: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
