<div class="trakto-dropdown-menu">
  <trakto-button [config]="button" (click)="toggle()">
    <i [style.marginRight.px]="8" *ngIf="icon" [class]="icon"></i>
    <span *ngIf="title" [style.width.px]="width">{{ title }}</span>
    <i
      [style.marginLeft.px]="8"
      *ngIf="caret"
      class="trakto-icon-arrow-drop-down"
    ></i>
  </trakto-button>

  <div class="trakto-dropdown-menu__content">
    <ng-content></ng-content>
  </div>
</div>
