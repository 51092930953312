import { Injectable } from '@angular/core';
import { ElementModelService } from '@services/element-model.service';
import { VideoSVGConverter } from '@trakto/svg-converter';

@Injectable()
export class VideoSVGConversionService extends VideoSVGConverter {
  constructor(private elementService: ElementModelService) {
    super(elementService);
  }
}
