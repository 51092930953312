import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';

@Component({
  selector: 'trakto-panel-links-mobile',
  templateUrl: './panel-links-mobile.component.html',
  styleUrls: ['./panel-links-mobile.component.scss'],
})
export class PanelLinksMobileComponent implements OnInit {
  constructor(private _panelService: PanelService) {}

  ngOnInit(): void {}

  openWebsitePanel() {
    this._panelService.openPanel('website');
  }

  openWhatsappPanel() {
    this._panelService.openPanel('whatsapp');
  }

  openEmailPanel() {
    this._panelService.openPanel('email');
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
