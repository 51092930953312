import {
  Authors,
  ILocaleInfo
} from '@app/editor/components/properties-panel/panel-elements/model/model';

export enum enumCardTypes {
  SINGLE = 'single',
  SQUARE = 'square',
  HORIZONTAL = 'horizontal',
  PANEL = 'panel',
}

export interface IPackStyle {
  primary: string;
  background: string;
}

export interface IPack  {
  author: Authors[];
  bigGrid?: boolean;
  createdAt: string;
  createdBy: string;
  id: number;
  languageNames: ILocaleInfo[];
  name: string;
  productsIds: string[];
  publishLanguages: string[];
  thumbKey: string;
  thumbUrl: string;
  updatedAt: string;
  updatedBy: string;
}
