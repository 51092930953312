import { Injectable } from '@angular/core';
import {
  DocumentManagerService
} from '@app/editor/services/document-manager.service';
import { IDocumentModel, PageModel, } from '@trakto/models';
import {
  PagePDFConversionService
} from '@shared/svg-viewer/shared/page-pdf-conversion.service';
import { TraktoApiService } from '@services/trakto-api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

  get document(): Promise<IDocumentModel> {
    return this._documentManagerService.getSelectedDocument();
  }

  get selectedPage(): Promise<PageModel> {
    return this._documentManagerService.getSelectedPage();
  }

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _pagePdfConversionService: PagePDFConversionService,
    private _apiService: TraktoApiService,
  ) {
  }

  public async saveAsPng(quality: string) {
    try {
      const results: {result: string}[] = await this._apiService.executeBellaPost(`generate-png?quality=${quality}`, {
        pages: [await this.selectedPage]
      });
      const url = `${environment.api.bellaUrl}${results[0].result}`;
      (window as any).callDownloadPng(url);
    } catch (er) {
      // TODO show error
      console.log(er);
    }
  }

  public async saveAsPdf(quality: string, range: number[]) {
    try {
      const document = await this.document;
      const validRange = this._pagePdfConversionService.formatPrintRange(
        range,
        document,
        await this.selectedPage,
      ).sort();
      const pages =
        validRange && validRange.length === 2
          ? document.body.slice(validRange[0] - 1, validRange[1])
          : document.body;
      const results: {result: string} = await this._apiService.executeBellaPost(`generate-pdf`, {
        pages,
      });
      const url = `${environment.api.bellaUrl}${results.result}`;
      (window as any).callDownloadPdf(url);
    } catch (er) {
      // TODO show error
      console.log(er);
    }
  }

  public async saveAsVideo() {
    try {
      const results: {result: string}[] = await this._apiService.executeBellaPost(`generate-mp4`, {
        pages: [await this.selectedPage]
      });
      const url = `${environment.api.bellaUrl}${results[0].result}`;

      (window as any).callDownloadVideo(url);
    } catch (er) {
      // TODO show error
      console.log(er);
    }
  }
}
