import { Component, OnDestroy, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IElementModel, PageModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-font-mobile',
  templateUrl: './panel-font-mobile.component.html',
  styleUrls: ['./panel-font-mobile.component.scss']
})
export class PanelFontMobileComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  elementSelected: IElementModel;
  pageSelected: PageModel;

  constructor(
    private _documentStateManagerService: DocumentStateManagerService,
    private _panelService: PanelService,
    private _elementsService: ElementsService,
    ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this._documentStateManagerService.page$.subscribe((page) => {
        this.pageSelected = page;
      })
    );
    this.subscriptions.push(
      this._documentStateManagerService.element$.subscribe((element) => {
        this.elementSelected = element;
      })
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }


}
