import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { SvgEditorComponent } from './svg-editor.component';

import { DrawablesContainerComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables-container.component';
import { DrawableGifComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable-gif.component';
import { DrawableImageComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable-image.component';
import { DrawableShapeComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable-shape.component';
import { DrawableTextComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable-text.component';
import { SafeDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/safe.directive';
import { TraktoPageBgImageDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-page-bg-image.directive';
import { TraktoPageBgRectDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-page-bg-rect.directive';
import { TraktoPageContainerDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-page-container.directive';
import { TraktoPathDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-path.directive';
import { InlineColorPickerComponent } from '@editor/components/preview-2/svg-editor/text-menu-inline/inline-color-picker/inline-color-picker.component';
import { MenuInlinePositionerDirective } from '@editor/components/preview-2/svg-editor/text-menu-inline/menu-inline-positioner.directive';
import { TextMenuInlineComponent } from '@editor/components/preview-2/svg-editor/text-menu-inline/text-menu-inline.component';
import { DrawableYoutubeComponent } from './drawables-container/drawables/drawable-youtube.component';
import { DrawableBadgeComponent } from './drawables-container/drawables/drawable.badge.component';
import { LoaderSpinnerComponent } from './loader/loader-spinner.component';
import { InlineLinkInputComponent } from './text-menu-inline/inline-link-input/inline-link-input.component';
import { AutoScaleHelpComponent } from '@editor/components/preview-2/svg-editor/text-menu-inline/auto-scale-help-modal/auto-scale-help.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TextEditionButtonComponent } from '@editor/components/preview-2/svg-editor/text-edition-button/text-edition-button.component';

@NgModule({
  declarations: [
    SvgEditorComponent,
    LoaderSpinnerComponent,

    TraktoPathDirective,
    TraktoPageBgRectDirective,
    TraktoPageBgImageDirective,
    TraktoPageContainerDirective,

    DrawableGifComponent,
    DrawableShapeComponent,
    DrawableImageComponent,
    DrawableTextComponent,
    DrawableBadgeComponent,
    DrawableYoutubeComponent,
    DrawablesContainerComponent,
    SafeDirective,

    TextMenuInlineComponent,
    InlineColorPickerComponent,
    MenuInlinePositionerDirective,
    InlineLinkInputComponent,
    AutoScaleHelpComponent,

    TextEditionButtonComponent,
  ],
  imports: [ SharedModule, MatIconModule, MatButtonModule ],
  exports: [ SvgEditorComponent, SafeDirective ],
})
export class SvgEditorModule {}
