import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFUploadDesignComponent } from './upload-design.component';
import { TitleWithLineModule } from '../title-with-line/title-with-line.module';
import { FileModule } from '@app/shared/forms/file/file.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    PDFUploadDesignComponent,
  ],
  imports: [
    CommonModule,
    TitleWithLineModule,
    FileModule,
    TranslateModule,
  ],
  exports: [
    PDFUploadDesignComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class UploadDesignModule { }
