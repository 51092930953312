import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { TraktoElementDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-element.directive';
import { PageModel } from '@trakto/models';

@Directive({
  selector: '[traktoPageContainer]',
})
export class TraktoPageContainerDirective
  extends TraktoElementDirective
  implements OnChanges, OnInit {
  @Input()
  traktoPageContainer: PageModel;

  @Input()
  pageFocused: boolean;

  @HostBinding('attr.width')
  width: number | string;

  @HostBinding('attr.height')
  height: number | string;

  @HostBinding('style.cursor')
  cursor = 'initial';

  @HostBinding('attr.viewBox')
  viewBox: string;

  @HostBinding('attr.xml:base')
  baseUrl: string;

  @HostBinding('attr.xmlns:xlink')
  xlink = 'http://www.w3.org/1999/xlink';

  @HostBinding('attr.preserveAspectRatio')
  preserveAspectRatio = 'none';

  constructor() {
    super();
  }

  ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.search, '');
  }

  ngOnChanges() {
    if (!this.traktoPageContainer) {
      return;
    }
    const page = this.traktoPageContainer;
    this.id = `${page.id}`;
    this.width = this.zoomRatio !== -1 ? page.width * this.zoomRatio : '100%';
    this.height = this.zoomRatio !== -1 ? page.height * this.zoomRatio : '100%';
    this.viewBox = `${0} ${0} ${page.width} ${page.height}`;
  }
}
