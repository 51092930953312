import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelStackService } from './../../../services/panel-stack.service';

import { IFontModel, ITextElementModel, PageModel } from '@trakto/models';

@Component({
  selector: 'trakto-panel-text-font',
  templateUrl: './panel-text-font.component.html',
  styleUrls: ['./panel-text-font.component.scss'],
})
export class PanelTextFontComponent {
  public currentNavPanel: any = 0;
  public navList = [
    { name: 'Fontes' },
    { name: 'Minhas fontes' },
    { name: 'Favoritas' },
  ];

  @Input()
  showCloseButton = true;

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Output()
  selectedFont: EventEmitter<IFontModel> = new EventEmitter<IFontModel>();

  constructor(private _panelStackService: PanelStackService) {}

  public close() {
    this._panelStackService.pop(500);
  }

  public changePanel(tabIndex: number): void {
    this.currentNavPanel = tabIndex;
  }
}
