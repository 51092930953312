<div *ngIf="elementSelected" id="panel-video" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-video"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.video.title' | translate }}
      </h2>
    </div>
  </div>

  <div class="flex flex-1 flex-direction-column overflow-hidden">
    <trakto-panel-actions
      [b2c]="b2c"
      [allowClip]="false"
      [isClipEditable]="false"
      [pageSelected]="pageSelected"
      [elementSelected]="elementSelected"
      [groupContainer]="groupSelected"
      [allowLockElementsByTemplate]="allowLockElementsByTemplate"
    >
    </trakto-panel-actions>

    <div class="panel-scroll__container">
      <div class="panel-space">
        <div
          class="panel-background panel-scroll__space"
          [style.paddingTop.px]="0"
        >
          <div class="panel-video__content">
            <trakto-button-preview
              [style.width.px]="100"
              height="109px"
              #buttonChangePageVideo
              [url]="href"
              [content]="'property_panel.video.insert' | translate"
              icon="trakto-icon-video"
            >
            </trakto-button-preview>

            <div class="panel-video__controls">
              <div [style.marginBottom.px]="5">
                <trakto-checkbox
                  id="autoplay"
                  name="autoplay"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  [(ngModel)]="autoplay"
                >
                  {{ 'property_panel.video.autoplay' | translate }}
                </trakto-checkbox>
              </div>

              <div [style.marginBottom.px]="5">
                <trakto-checkbox
                  id="mute"
                  name="mute"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  [(ngModel)]="mute"
                >
                  {{ 'property_panel.video.mute' | translate }}
                </trakto-checkbox>
              </div>

              <div [style.marginBottom.px]="5">
                <trakto-checkbox
                  id="controls"
                  name="controls"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  [(ngModel)]="controls"
                >
                  {{ 'property_panel.video.controls' | translate }}
                </trakto-checkbox>
              </div>

              <div>
                <trakto-checkbox
                  id="loop"
                  name="loop"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  [(ngModel)]="loop"
                >
                  {{ 'property_panel.video.repeat' | translate }}
                </trakto-checkbox>
              </div>

              <div [style.marginBottom.px]="5">
                <trakto-checkbox
                  id="showinfo"
                  name="showinfo"
                  [disabled]="
                    !elementSelected.edit || elementSelected.lockedByTemplate
                  "
                  [(ngModel)]="showinfo"
                >
                  {{ 'property_panel.video.show_infos' | translate }}
                </trakto-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-background">
          <div>
            <div class="panel-video__header">
              <span class="panel-subtitle">
                <img src="/assets/images/icon-youtube.svg" />
                {{ 'property_panel.video.add_youtube' | translate }}
              </span>

              <img
                *ngIf="preview && elementSelected.href"
                [src]="preview"
                [style.maxWidth.%]="100"
                [style.marginTop.px]="10"
                [style.marginBottom.px]="10"
              />

              <div class="panel-video-settings__group">
                <trakto-input
                  #input
                  id="backgroundVideo"
                  name="backgroundVideo"
                  placeholder="{{
                    'property_panel.video.insert_youtube_link' | translate
                  }}"
                  [value]="elementSelected.href"
                  (onBlur)="hotkeyService.enableHotkeys()"
                  (onFocus)="hotkeyService.disableHotkeys()"
                  (onChange)="setYoutubePreview($event, input.value)"
                >
                </trakto-input>

                <trakto-button
                  button--fill
                  button--secundary
                  [disabled]="buttonValidation"
                  (onClick)="setYoutubeButtonPreview()"
                >
                  {{
                    elementSelected.link
                      ? isTranslateButtonLink.add
                      : isTranslateButtonLink.add
                  }}
                </trakto-button>
              </div>
              <ng-container *ngIf="buttonValidation && input.value">
                <span class="panel-simple__error">{{
                  'property_panel.link.invalid_link' | translate
                }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
