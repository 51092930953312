<div #element id="panel-link-website" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-worldwide"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.link.panel-website.header' | translate }}
      </h2>
    </div>

    <div class="panel-scroller-close" (click)="closePanelLink()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-full__container">
    <div class="panel-full__content">
      <div class="panel-full__header" [class.--website]="true">
        <h1 [ngStyle]="{ width: '70%' }">
          {{ 'property_panel.link.panel-website.subtitle' | translate }}
        </h1>
      </div>

      <div class="panel-full__link">
        <form [formGroup]="form" (submit)="handleSubmit()">
          <div class="panel-full__field">
            <label for="link">
              {{
                'property_panel.link.panel-website.input_link.label' | translate
              }}
            </label>
            <trakto-input
              #inputWebsite
              autofocus
              id="urlWebsite"
              name="urlWebsite"
              type="url"
              value="http://"
              prefix="trakto-icon-link"
              typeOnLive="true"
              placeholder="https://www.site.com"
              formControlName="link"
              [ngModel]="elementSelected.link"
              (keypress)="addMaskUrl(inputWebsite.value)"
              (onBlur)="enableHotkeys()"
              (onFocus)="disableHotkeys()"
              (onChange)="validateUrl(inputWebsite.value)"
            >
            </trakto-input>
            <small [ngClass]="{ '--hide': form.controls.link.valid }">
              {{
                'property_panel.link.panel-website.input_link.hint' | translate
              }}
            </small>
          </div>

          <trakto-button
            button--custom
            button--paddin-link
            button--xxlarge
            type="submit"
            [disabled]="form.invalid"
          >
            <span>
              {{
                (elementSelected && elementSelected.link
                  ? 'property_panel.link.remove_link_button'
                  : 'property_panel.link.generate_link_button'
                ) | translate
              }}
            </span>
          </trakto-button>
        </form>
      </div>
    </div>
  </div>
</div>
