import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  PageModel
} from '@trakto/models';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({ template: '' })
export class AbstractComponent<E extends IElementModel> implements OnInit, OnDestroy {

  document: IDocumentModel;
  elementSelected: E;
  groupSelected: E;
  pageSelected: PageModel;
  _destroy$ = new Subject<void>();

  constructor(
    protected _documentStateManagerService: DocumentStateManagerService
  ) {
  }

  ngOnInit() {
      this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$),filter(document => !!document)).subscribe(doc => this.document = doc)
      this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$),filter(page => !!page)).subscribe(page => {
        this.pageSelected = page;
        this.onPageSelected(this.pageSelected);
      })
      this._documentStateManagerService.element$.pipe(takeUntil(this._destroy$),filter(element => !!element)).subscribe(element => {
        this.groupSelected = element.type === elementTypeEnum.group ? element as E : null;
      })
      this._documentStateManagerService.clickedElement$.pipe(takeUntil(this._destroy$),filter(element => !!element)).subscribe(element => {
        this.elementSelected = element as E;
        this.onElementSelected(this.elementSelected);
      })
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  onElementSelected(element: E) {}

  onPageSelected(pageSelected: PageModel) {}
}
