import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonPremiumComponent } from './button-premium.component';

@NgModule({
  declarations: [ButtonPremiumComponent],
  imports: [CommonModule],
  exports: [ButtonPremiumComponent],
})
export class ButtonPremiumModule {}
