export enum TKT_PLAN_PERIODICITY {
    monthly = 'monthly',
    yearly = 'yearly',
    semestral = 'semestral',
    trimestral = 'trimestral',
    lifetime = 'lifetime',
}

export enum TKT_PLAN_PERIODICITY_PARSED {
    monthly = 'Mensal',
    yearly = 'Anual',
    semestral = 'Semestral',
    trimestral = 'Trimestral',
    lifetime = 'Lifetime',
}