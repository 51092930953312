import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  ElementsGalleryService,
  enumTextType,
} from '@app/shared/elements-gallery/elements-gallery.service';
import { Subscription } from 'rxjs';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  ISearchEvent,
} from '@editor/components/properties-panel/panel-elements/model/model';
import { enumCardTypes, IPack } from '@shared/elements/pack.entity';
import { ThemeService } from '@app/editor/services/theme.service';
import {
  ElementNewApiService
} from '@app/editor/services/element-new-api.service';
import {
  SearchTypeEnum
} from '@app/editor/enums/editor-elements/search-element.enum';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'trakto-themes-folders',
  templateUrl: './themes-folders.component.html',
  styleUrls: ['./themes-folders.component.scss'],
})
export class ThemesFoldersComponent implements OnInit {
  @Output() searchTag: EventEmitter<ISearchEvent> =
    new EventEmitter<ISearchEvent>();

  private onLangChangeSubscription: Subscription = null;
  public onExpandPanelChange: Subscription;
  private _subscriptions: Subscription[] = [];

  public packs: IPack[] = [];
  public enumCardTypes = enumCardTypes;
  public enumTextType = enumTextType;
  public currentLang = 'pt-BR';
  public isPanelExpanded = false;
  public isPacksLoading = false;
  public primaryColor = '#0079ff';
  public packName = '';

  constructor(
    private _elementsGalleryService: ElementsGalleryService,
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
    private _themeService: ThemeService,
    private _elementNewApiService: ElementNewApiService
  ) {}

  /**
   * Obtém o acerto de temas a serem exibidos, e
   * observa o idioma atual, e modifica o valor
   * da variável isPanelExpanded quando painel
   * é expandido ou retraído
   */
  async ngOnInit() {
    await this._themeService.getObservableTheme().pipe(filter(r => !!r), take(1)).toPromise();
    const themePrimaryColor = this._themeService.getTheme().color?.primary;
    if (themePrimaryColor) this.primaryColor = themePrimaryColor;

    this.isPacksLoading = true;
    this.packs = [...(await this._elementNewApiService.getPacks())];
    this.isPacksLoading = false;
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.onLangChangeSubscription =
      this._translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.currentLang = event.lang;
        },
      );

    this.onExpandPanelChange =
      this._panelStackService.onExpandPanelChange.subscribe(isExpanded => {
        this.isPanelExpanded = isExpanded;
      });

    this._subscriptions.push(this.onLangChangeSubscription);
    this._subscriptions.push(this.onExpandPanelChange);
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  /**
   * Abre o painel temático de elementos, com base
   * no tema desejado.
   *
   * @param name string - Nome do tema a ser visualizado
   */
  openFolder(pack: IPack) {
    this.searchTag.emit({ pack: pack, type: SearchTypeEnum.THEME });
  }

  getAuthorsName(authors: any): string {
    return authors.map(p => p.name).join(', ');
  }
}
