import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PlatformLoadingService } from '@app/editor/services/platform-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../editor/services/locale.service';
import { DropboxService } from './dropbox.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropboxComponent implements OnInit, OnDestroy {
  public translatedTexts: any;
  public processStatus: string;
  public authenticateDropbox = {
    authenticate: 'authenticate',
    errorAuthenticate: 'errorAuthenticate',
    errorCreateDoc: 'errorCreateDoc',
  };
  public isAuthValid: string;

  public imageBackground = 'assets/images/editor-dropbox.svg';
  private _destroy$ = new Subject<void>();

  constructor(
    private _loaderPlatformService: PlatformLoadingService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dropboxService: DropboxService,
    private _translate: TranslateService,
    private _localeService: LocaleService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async ngOnInit(): Promise<void> {
    this._loaderPlatformService.hide();

    this._localeService.init('en_us');

    this._getTranslations();

    this.startDropboxProcess();
  }

  private catchError(error: any) {
    this.isAuthValid = this.authenticateDropbox[error];
  }

  public async startDropboxProcess() {
    try {
      this.isAuthValid = this.authenticateDropbox.authenticate;
      const { accessToken, fileId } = this._activatedRoute.snapshot.params;
      const file = await this._dropboxService.getSession(accessToken, fileId);

      if (!accessToken) {
        throw this.authenticateDropbox.errorAuthenticate;
      }

      this._getTranslations().then(
        translated => (this.processStatus = translated?.creating_document),
      );

      if (!file) {
        const newDocument: any = await this._dropboxService.createDocument();

        this._dropboxService.trackCreateDocument(newDocument);

        return this._router.navigate([
          'editor/dropbox',
          newDocument.id,
          accessToken,
          file,
        ]);
      }

      const img = new Image();
      img.onload = async () => {
        const newDocument: any = await this._dropboxService.createDocument(
          file.link,
          img.width,
          img.height,
        );

        if (!newDocument.id) {
          throw this.catchError(this.authenticateDropbox.errorCreateDoc);
        }

        this._dropboxService.trackCreateDocument(newDocument);

        this._router.navigate([
          'editor/dropbox',
          newDocument.id,
          accessToken,
          fileId,
        ]);
      };

      img.src = file.link;
    } catch (error) {
      console.error('Erro====>', error);
      this.catchError(error);
    }
  }

  public goToDropbox(): void {
    window.location.href = 'https://www.dropbox.com/h';
  }

  private _getTranslations(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._translate.get('dropbox').pipe(takeUntil(this._destroy$)).subscribe(texts => {
        const { loader } = texts;
        this.processStatus = loader?.authenticating;

        resolve(loader);
        reject('Tradução não encontrada');
      });
    });
  }
}
