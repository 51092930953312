import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NotificationService } from '@app/shared/notification/notification.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { environment } from '@env/environment';
import { FirebaseService } from '@shared/firebase-client/firebase.service';
import { KmlModalConfig, KmlModalRef } from '@trakto/trakto-kamaleon';
import { DocumentService } from '@services/document.service';

@Component({
  selector: 'trakto-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss'],
})
export class ModalShareComponent implements OnInit {
  @ViewChild('inputSlug', { static: true }) inputSlug: any;

  private readonly _formGroup: FormGroup;
  public previewURL: string;
  public preSlug: string;
  public mainURL: string;
  public slug: string;

  public linkCopied = false;

  constructor(
    public kmlData: KmlModalConfig,
    private _kmlModalRef: KmlModalRef,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService,
    public _hotkeyService: HotkeyService,
    private _notificationService: NotificationService,
    private _documentService: DocumentService,
  ) {
    this._formGroup = this.formBuilder.group({
      slug: [null],
      allow_preview: [true],
    });
  }

  ngOnInit() {
    const { slug, docId } = this.kmlData.data;

    this.mainURL = `${window.location.origin}/design/copy`;
    this.slug = slug ? slug : docId;
    this.previewURL = `${environment.documentShareURL}/${this.slug}`;
    this.formGroup.get('slug').setValue(this.slug);
    // Por padrão, todos os documentos possuem permissão de visualização por outras pessoas
    // this.formGroup.get('allow_preview').setValue(this.data.allow_preview);
  }

  disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  /**
   * Método para checar se o documento existe e retornar os dados ao fechar modal
   */
  public async createAndCopyLink() {
    const hasSlug = await this.hasSlugRegistered();

    if (
      this.formGroup.get('slug').value.isNull ||
      this.formGroup.get('slug').value.trim() === ''
    ) {
      this.inputSlug.nativeElement.focus();
      this.inputSlug.nativeElement.select();
      this._notificationService.warn(
        this._notificationService.notificationsMSGs.link_invalid,
        { isSimple: false, hasIcon: false, time: 5000 },
      );
    } else if (!hasSlug) {
      this.copy();
    } else {
      this.inputSlug.nativeElement.focus();
      this.inputSlug.nativeElement.select();
      this._notificationService.warn(
        this._notificationService.notificationsMSGs.link_used,
        { isSimple: false, hasIcon: false, time: 5000 },
      );
    }
  }

  /**
   * Método para copiar URL completa com `slug`
   */
  public copy(): void {
    if (!this.inputSlug.nativeElement.value) {
      this.inputSlug.nativeElement.value = `${environment.documentPreviewURL}/${this.kmlData.data.docId}`;
    }

    this.inputSlug.nativeElement.focus();
    this.inputSlug.nativeElement.select();
    document.execCommand('copy');
    this.linkCopied = true;
  }

  /**
   * Função para verificar o slug informado para o link já foi registrado por outro usuário.
   * @return {Promise<boolean>}
   */
  private async hasSlugRegistered(): Promise<boolean> {
    if (this.slug !== this.formGroup.get('slug').value) {
      const snapshot = await this.firebaseService.firestoreModule
        .collection('document', ref =>
          ref.where('slug', '==', this.formGroup.get('slug').value),
        )
        .get()
        .toPromise();
      if (snapshot.empty) {
        return false;
      } else {
        const docFromSlug = snapshot.docs.filter(
          doc => doc.id === this.kmlData.data.docId,
        );
        if (docFromSlug.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Método contendo regex para limpar acentos e espaços no `slug`
   * @param value {String}
   */
  public getCleanWord(value: string) {
    let word = value.toLowerCase();

    word = word.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    word = word.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    word = word.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    word = word.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    word = word.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    word = word.replace(new RegExp('[Ç]', 'gi'), 'c');
    word = word.replace(new RegExp(' ', 'gi'), '-');

    this.formGroup.get('slug').setValue(word);
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public closeModal(): void {
    this._kmlModalRef.close();
  }
}
