import { Injectable } from '@angular/core';
import { PathSvgService } from '@services/path-svg.service';
import { ISvgElementModel } from '@trakto/models';
import { MediaService } from '@services/media.service';
import { ItemRenderer } from '@shared/renderers/item-renderer.model';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { generateUUID } from '@trakto/core-editor';

@Injectable({
  providedIn: 'root',
})
export class NewEmojiElementService {
  constructor(
    private _mediaService: MediaService,
    private _pathSvgService: PathSvgService,
    private _signalsService: SignalsService,
  ) {}

  public async changeIconSvg(
    icon: ISvgElementModel,
    item: ItemRenderer,
  ): Promise<void> {
    icon.svg = this._pathSvgService.makeTempSvg(item);
    this._emitSvgChange(icon);
    icon.svg = this.normalizeSVG(await this._mediaService.downloadSVGByItemRenderer(item));
    this._emitSvgChange(icon);
  }

  public async changeIconSvgByUrl(icon: ISvgElementModel, url: string) {
    icon.svg = this.normalizeSVG(await this._mediaService.getSVG(url));
    this._emitSvgChange(icon);
    return true;
  }

  normalizeSVG(svg: string) {
    const div = document.createElement("div");
    div.innerHTML = svg
    const children = div.getElementsByTagName("*");
    const idsToChange = []
    let classesToChange = []

    for (let i = 0; i < children.length; i++) {
      if (children[i].id) {
        idsToChange.push(children[i].id);
      }
      if (children[i].classList) {
        classesToChange = classesToChange.concat([...children[i].classList]);
      }
    }
    idsToChange.forEach(id => svg = svg.replace(new RegExp(id, 'g'), generateUUID()));
    classesToChange.forEach(_class => svg = svg.replace(new RegExp(_class, 'g'), generateUUID()));
    return svg;
  }

  private _emitSvgChange(emoji: ISvgElementModel) {
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      obj: emoji,
      property: 'svg',
    });
  }

}
