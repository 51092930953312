import { EventEmitter, Injectable } from "@angular/core";
import { AppRepository } from "@app/editor/repository/app.repository";
import { BehaviorSubject } from 'rxjs';
import { ApiWhitelabelService } from "./api-whitelabel.service";
import { IApp, IProducts } from "@trakto/models";
import { UserService } from "@app/editor-v3/services/user.service";
import { TraktoUser } from "@app/auth/shared/auth.model";

export type WhitelabelChange = {
    app: IApp;
    products: Array<IProducts>;
    currentProduct: IProducts;
};

@Injectable({
    providedIn: 'root'
})
export class WhitelabelService {
    private _currentProduct: IProducts;
    private _products: Array<IProducts>;
    private _app: IApp;
    private _updatedAvailableFeatures = new BehaviorSubject(
        'Update Available Features',
    );
    public whitelabelChange: EventEmitter<WhitelabelChange> = new EventEmitter();


    get app() {
        return this._app;
    }

    get products() {
        return this._products;
    }

    constructor(
        private _appRepository: AppRepository,
        private _apiWhitelabelService: ApiWhitelabelService,
        private _userService: UserService,
    ) {
        this.listenProductProfile();
    }

    private listenProductProfile() {
        this._userService
            .getProfile()
            .subscribe((profile) => this._emitWhitelabelChange(profile))
    }

    private _emitWhitelabelChange(profile: TraktoUser) {
        //
        //
        //
        // Header novo não está sendo inicializado
        //
        this._apiWhitelabelService.products().then(async (products) => {
            const currentProduct = this._setCurrentProduct(profile, products);
            const app = await this._setApp();
            this._updatedAvailableFeatures.next(currentProduct['available_features']);
            this.whitelabelChange.emit({
                app,
                currentProduct,
                products,
            });
        });
    }

    private async _setApp(): Promise<IApp> {
        this._app = await this._appRepository.findById(this._currentProduct.app_reference.id);
        if (!this._app) throw 'Não foi possível encontrar o app do usuário';
        return this._app;
    }

    private _setCurrentProduct(profile: TraktoUser, products: Array<IProducts>) {
        this._products = products;
        this._currentProduct = products.find((product: IProducts) =>
                product.id === profile.current_profile?.product.id)
        if (!this._currentProduct) this._currentProduct = products[0];
        return this._currentProduct;

    }
}
