import { EventEmitter, Injectable } from '@angular/core';
import { TraktoUser } from '@app/auth/shared/auth.model';
import { TraktoApiService } from '@app/editor/services/trakto-api.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TraktoUserWithTeam } from '@app/editor-v3/types/user-with-team.type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: TraktoUserWithTeam = null;
  private _isPremium: boolean = false;
  private _isB2c: boolean = false;
  private _isGlobalAdmin: boolean = false;
  private _isTeamManager: boolean = false;
  private _observable: BehaviorSubject<TraktoUser> = new BehaviorSubject(null);

  constructor(private _apiService: TraktoApiService) {}
  get user() {
    return this._user;
  }

  get isPremium() {
    return this._isPremium;
  }

  get isB2c() {
    return this._isB2c;
  }

  get isGlobalAdmin() {
    return this._isGlobalAdmin;
  }

  get isTeamManager() {
    return this._isTeamManager || this.isGlobalAdmin;
  }

  async init() {
    await this._fetchUserProfile();
  }

  getProfile(): Observable<TraktoUser> {
    return this._observable.asObservable().pipe(
      filter(r => !!r),
      take(1),
    );
  }

  private _fetchUserProfile() {
    this._apiService
      .executeGet<TraktoUserWithTeam>('user/profile')
      .then(async user => {
        this._user = user;
        this._isGlobalAdmin = user.role.value === 'global';
        this._isTeamManager = user.team?.role === 'manager';
        this._isB2c =
          this.user.current_profile?.product.id === environment.productIdB2C;
        this._observable.next(user);
      });
  }
}
