import { Injectable } from '@angular/core';

import {
  ElementModelFinderService
} from '@services/element-model-finder.service';
import { FontsService } from '@services/fonts.service';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import { generateUUID, } from '@trakto/core-editor';
import {
  IDocumentModel,
  IElementModel,
  ImageElementModel,
  ITextElementModel,
  PageModel
} from '@trakto/models';
import { concat, from } from 'rxjs';
import { delay, take, tap, toArray } from 'rxjs/operators';
import { ElementModelService } from '@services/element-model.service';
import { ImageElementService } from '@services/image-element.service';
import {
  PageBase64ManagerService
} from '@app/shared/svg-viewer/shared/page-base64-manager.service';
import { NewModelService } from '@services/new-model.service';
import { ShapeElementService } from '@services/shape-element.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Injectable({
  providedIn: 'root',
})
export class InitService {

  constructor(
    private _newElementService: ElementModelService,
    private _newImageElementService: ImageElementService,
    private _textElementService: TextElementService,
    private _fontsService: FontsService,
    private _elementModelFinderService: ElementModelFinderService,
    private _textService: TextService,
    private _pageBase64ManagerService: PageBase64ManagerService,
    private _newModelService: NewModelService,
    private _shapeService: ShapeElementService,
    private _documentStateManager: DocumentStateManagerService
  ) {
  }

  initDocument(document: IDocumentModel): IDocumentModel {
    document.body.forEach(page => {
      this._textService.appendFontList(page);
      this._elementModelFinderService.getAllElements(page.elements, false, false).forEach(element => {
        if (!element.id || typeof (+element.id[0]) === 'number') {
          element.id = generateUUID();
        }
        // caso o clip tenha sido salvo sem voltar ao estado 'clipado'
        if (element['submask']) {
          element['clipped'] = true;
        }
        if (!element.converted) {
          this._convertElement(page, element);
        }
        this._newElementService.updateFinalDimension(element);
      })
    });
    return document;
  }

  initImagesByPage(page: PageModel): Promise<any> {
    this._newImageElementService.clearBlobRefs(page);
    let loadObservables = [];
    loadObservables = loadObservables.concat(
      this._newImageElementService
        .getNonUploadedImagesByPage(page)
        .map((image: ImageElementModel) => from(this._documentStateManager.changeImageUrl(page, image, image.href))),
    );

    if (this._newImageElementService.canUploadBackgroundPage(page)) {
      loadObservables.push(
        this._newImageElementService.loadNonUploadedBackgroundImages({
          id: page.id,
          type: page.type,
          backgroundImageLow: page.backgroundImageLow,
          backgroundImageMedium: page.backgroundImageMedium,
          backgroundImageHigh: page.backgroundImageHigh,
          backgroundImageRaw: page.backgroundImageRaw,
          backgroundImage: page.backgroundImage,
        } as PageModel)
          .pipe(tap(pageChanges  => this._documentStateManager.persistPageChangesNoTrackable(pageChanges as PageModel)))
      );
    }

    if (!page.base64) {
      loadObservables.push(
        this._pageBase64ManagerService.updateBase64(
          page,
          false,
        )
      );
    }

    return concat(
      ...loadObservables.map(o => o.pipe(delay(100)))
    ).pipe(
      toArray(),
    ).toPromise();
  }

  initFontsByPage(page: PageModel): Promise<any> {
    const fakePage = { id: page.id, fontModels: [ ...(page.fontModels || []) ].filter(fm => !!fm), elements: [...page.elements] } as PageModel;
    let loadObservables = [];

    loadObservables.push(
      this._newElementService.initFontModelsByPage(fakePage)
        .pipe(tap(() => this._documentStateManager.persistPageChangesNoTrackable({ id: page.id, fontModels: fakePage.fontModels } as PageModel)))
    );

    loadObservables = loadObservables.concat(
      this._fontsService.loadByPage(fakePage).pipe(
        tap(async () => {
          const document = await this._documentStateManager.document$.pipe(take(1)).toPromise();
          const updatePage = document.body.find(p => p.id === page.id);
          const tempTexts = this._newElementService.getAllTextElementsByPage(updatePage)
            .map(text => ({...text, textEncoded: [...text.textEncoded], textEncoded2: [...text.textEncoded2], textQuillEncoded: [...text.textQuillEncoded]}));
          tempTexts.forEach((text: ITextElementModel) => this._textElementService.refreshTextElement(text));
          this._documentStateManager.persistElementChangesNoTrackable(updatePage, tempTexts);
        }),
      )
    );
    return concat(...loadObservables).pipe(toArray()).toPromise();
  }

  private _convertElement(page: PageModel, element: IElementModel) {

    this._newModelService.convertElementModel(page, element);
    this._newElementService.updateFinalDimension(element);

    if (this._newElementService.isImage(element) && !this._newElementService.isClip(element)) {
      this._maskImage(element);
    }
  }

  private _maskImage(element: IElementModel): void {
    const image = element as ImageElementModel;
    const shape = this._newElementService.checkId(
      this._shapeService.createShapeByElement(image),
    );
    const clippedImage = this._newImageElementService.clip(
      image,
      shape,
      image.id,
    );
    Object.assign(image, clippedImage);
  }


}
