import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-grid-images',
  templateUrl: './grid-images.component.html',
  styleUrls: ['./grid-images.component.scss'],
})
export class GridImagesComponent implements OnInit {
  @Input() images: any[] = [];
  @Output() action = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleAction(image: any) {
    this.action?.emit(image);
  }
}
