import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontListComponent } from './font-list/font-list.component';
import { PanelTextFontComponent } from './panel-text-font.component';

import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { KmlModalModule } from '@trakto/trakto-kamaleon';
import { FontFavoriteComponent } from './font-favorite/font-favorite.component';
import { FontsComponent } from './fonts/fonts.component';
import { FontProgressComponent } from './my-fonts/font-progress/font-progress.component';
import { MyFontsComponent } from './my-fonts/my-fonts.component';
import { SearchFontPipe } from './search-font.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, KmlModalModule],
  declarations: [
    PanelTextFontComponent,
    FontListComponent,
    SearchFontPipe,
    FontsComponent,
    MyFontsComponent,
    FontProgressComponent,
    FontFavoriteComponent,
  ],
  bootstrap: [PanelTextFontComponent],
  exports: [PanelTextFontComponent],
})
export class PanelTextFontModule {}
