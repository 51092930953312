import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';

import { PreviewDocComponent } from './preview.doc.component';

import { SvgEditorModule } from '@editor/components/preview-2/svg-editor/svg-editor.module';
import { LoaderModule } from '@shared/loader/loader.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TraktoDirectivesModule } from '@app/shared/directives/directives.module';
import { VerticalPageNavModule } from '@app/shared/vertical-page-nav/vertical-page-nav.module';
import { UniversalInterceptor } from './universal-interceptor.interceptor';

@NgModule({
  declarations: [PreviewDocComponent],
  imports: [
    FormsModule,
    CommonModule,
    LoaderModule,
    RouterModule,
    MatSliderModule,
    SvgEditorModule,
    TraktoDirectivesModule,
    VerticalPageNavModule,
  ],
  exports: [PreviewDocComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      /* Multi is important or you will delete all the other interceptors */
      multi: true,
    },
  ],
})
export class PreviewDocModule {}
