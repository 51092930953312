import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-select-optgroup',
  templateUrl: './optgroup.component.html',
  styles: [
    ':host { display: block; padding-bottom: 15px } :host:not(hidden):not(:last-child) { border-bottom: 2px solid #F5F5FA; margin-bottom: 15px; margin-top: 15px; }',
  ],
})
export class SelectOptgroupComponent {
  private _label: string;
  private _labelIcon: string;

  @Input() public set label(value: string) {
    this._label = value;
  }

  public get label(): string {
    return this._label;
  }

  @Input() public set labelIcon(value: string) {
    this._labelIcon = value;
  }

  public get labelIcon(): string {
    return this._labelIcon;
  }
}
