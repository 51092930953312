import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldspan',
})
export class BoldspanPipe implements PipeTransform {

  transform(value: string, regex: string): string {
    return this.replace(value, regex);
  }

  replace(str: string, regex: string): string {
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }
}
