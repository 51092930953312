<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-align.svg"
    label="Alinhar"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="actions-grid click-ignore">
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-left"
      (click)="alignTo('horizontal', 'left')"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-vertically"
      (click)="alignTo('horizontal', 'center')"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-right"
      (click)="alignTo('horizontal', 'right')"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-bottom"
      (click)="alignTo('vertical', 'top')"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-horizontally"
      (click)="alignTo('vertical', 'center')"
    ></trakto-panel-action-mobile>
    <trakto-panel-action-mobile
      [height]="62"
      icon="align-top"
      (click)="alignTo('vertical', 'bottom')"
    ></trakto-panel-action-mobile>
  </div>
</div>
