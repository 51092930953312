import { Injectable } from '@angular/core';
import {
  UtilMagicTemplateService
} from '@shared/magic-template/shared/util-magic-template.service';
import { TextElementService } from '@services/text-element.service';
import { FontMagicTemplateGenerator } from '@trakto/magic-templates';
import { ElementModelService } from '@services/element-model.service';

@Injectable()
export class FontMagicTemplateService extends FontMagicTemplateGenerator {
  constructor(
    utilMagicTemplateService: UtilMagicTemplateService,
    textService: TextElementService,
    elementModelService: ElementModelService
  ) {
    super (
      utilMagicTemplateService,
      textService,
      elementModelService,
    );
  }

}
