import { Injectable } from '@angular/core';
import { TraktoApiService } from '@services/trakto-api.service';
import { IDocumentModel } from '@trakto/models';
import { IMetadata } from '../services/traktoLinks/trakto-link.service';


export interface IDocumentMetadata {
  id: string;
  link_name: string;
  image: string;
  title: string;
  description?: string;
  link_web: string;
}

@Injectable({
  providedIn: 'root',
})
export class TraktoLinksRepository {
  constructor(
    private _traktoApiService: TraktoApiService,
  ) {}

  /**
   * Create a new Trakto Link for user's design
   * @param metadata Represents the link metadata
   * @param documentId Document ID that will receive or update the Trakto Link
   */
  public async createOrUpdateLink(metadata: IMetadata, documentId: string): Promise<IDocumentModel> {
    return this._traktoApiService.executePut(`trakto-link/${documentId}`, metadata);
  }

  /**
   * Check if the link is available to use
   * @param link Represent link's path to check
   */
  public async linkIsAvailable(link: string): Promise<boolean> {
    return this.findByMetadataLinkName(link).then(link => !link).catch(() => true);
  }

  public async findByMetadataLinkName(linkName: string): Promise<IDocumentMetadata> {
    return this._traktoApiService.executeGet<IDocumentMetadata>(`trakto-link/social-metadata/${linkName}`);
  }
}
