<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/ajust_text.svg"
                              label="Ajustes de texto"
                              class="click-ignore"
                              [showBackLabel]="true"
                              (onClose)="closePanel()">

  </trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="primary-options">
      <div class="grid">
        <div class="link" *ngFor="let opt of options" (click)="setStyle(opt.option)">
          <span class="label"
                [style.fontFamily]="currentText.fontFamily"
                [style.fontWeight]="opt.weight"
                [style.fontStyle]="opt.italic ? 'italic' : ''"
                style="font-size: 12px;">
            {{ opt.option.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
