import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'trakto-group-button',
  templateUrl: './group-button.component.html',
  styleUrls: ['./group-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupButtonComponent {
  constructor() {}
}
