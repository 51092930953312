import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { AudioService } from '@app/shared/audio/audio.service';
import { NotificationService } from '@app/shared/notification/notification.service';
import { ExportService } from '@editor/services/export/export.service';
import { FontsService } from '@editor/services/fonts.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { ThemeService } from '@editor/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@shared/storage/storage-service.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';

import { PresentationComponent } from '@app/presentation/presentation.component';
import { PageIMGConversionService } from '@app/shared/svg-viewer/shared/page-img-conversion.service';
import { PageSVGConversionService } from '@app/shared/svg-viewer/shared/page-svg-conversion.service';
import { ElementModelService } from '@services/element-model.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ConversionParams } from '@trakto/svg-converter';

import { ExportUtilModel } from '@app/editor/components/preview-2/shared/export-util.model';
import { PagePDFConversionService } from '@app/shared/svg-viewer/shared/page-pdf-conversion.service';
import {
  downloadTypeEnum,
  IDocumentModel,
  IEventModel,
  PageModel,
} from '@trakto/models';
import { forkJoin } from 'rxjs';
import { ModalComponent } from './modal/modal.component';

import { AuthService } from '@app/auth/shared/auth.service';
import { GoSquaredService } from '@app/editor/services/gosquared/gosquared.service';
import { PlatformLoadingService } from '@services/platform-loading.service';
import { TextService } from '@services/text.service';
import { DesignDownloadAnalyticsService } from '@app/editor/services/analytics/wrapper/designDownload.analytics-wrapper.service';
import { DocumentService } from '@services/document.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';

export enum EnumSlideDirection {
  NEXT = 'NEXT',
  PREVIEW = 'PREVIEW',
}

@Component({
  selector: 'trakto-mobile-presentation',
  templateUrl: './mobile-presentation.component.html',
  styleUrls: ['./mobile-presentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobilePresentationComponent
  extends PresentationComponent
  implements OnInit
{
  @ViewChild(ModalComponent, { static: true })
  protected modalProgress: ModalComponent;
  @ViewChild('containerPages') public containerPages: ElementRef;
  @ViewChild('previewPage') public sectionPreview: ElementRef;
  @ViewChild('buttonDownload')
  public buttonDownload: ElementRef<HTMLButtonElement>;
  @ViewChildren('singleDownload')
  public singleDownload: QueryList<HTMLButtonElement>;

  @Output() onDownloadEnded: EventEmitter<any> = new EventEmitter<any>();

  public showContainer = false;
  public downloSinglePage = false;
  public document = null;
  public zoomRatioDoc = 100;
  public zoomRatioByDoc = {};

  private MARGIN_X = 70;
  private MARGIN_Y = 80;
  private REST_OF_DOC = 40;

  public downloadMultiPages = false;

  public currentDoc = null;

  public pageRef: any;

  @HostListener('document:click', ['$event']) onClick(evt) {
    const containsButtonElement = this.singleDownload
      .toArray()
      .filter((e: any) => e.nativeElement === evt.target);

    this.buttonDownload.nativeElement.contains(evt.target) ||
    containsButtonElement.length > 0
      ? (this.showContainer = true)
      : (this.showContainer = false);
  }

  constructor(
    @Inject(DOCUMENT) public DOM: Document,
    productService: WhitelabelProductService,
    userService: UserService,
    private _goSquaredService: GoSquaredService,
    newElementService: ElementModelService,
    audioService: AudioService,
    hotkeyService: HotkeyService,
    notificationService: NotificationService,
    renderer: Renderer2,
    themeService: ThemeService,
    translateService: TranslateService,
    documentService: DocumentService,
    dialog: MatDialog,
    exportService: ExportService,
    fonts: FontsService,
    textService: TextService,
    realtime: AngularFireDatabase,
    route: ActivatedRoute,
    storageService: StorageService,
    uploadStorageService: UploadStorageService,
    pageSvgConversion: PageSVGConversionService,
    pagePDFConversionService: PagePDFConversionService,
    authService: AuthService,
    loaderService: PlatformLoadingService,
    exportUtil: ExportUtilModel,
    private _pageIMGConversionService: PageIMGConversionService,
    protected readonly _translateService: TranslateService,
    private _downloadAnalyticsService: DesignDownloadAnalyticsService
  ) {
    super(
      DOM,
      productService,
      newElementService,
      audioService,
      hotkeyService,
      notificationService,
      renderer,
      themeService,
      translateService,
      documentService,
      dialog,
      exportService,
      fonts,
      textService,
      hotkeyService,
      realtime,
      route,
      storageService,
      themeService,
      uploadStorageService,
      pageSvgConversion,
      pagePDFConversionService,
      authService,
      loaderService,
      exportUtil,
      userService
    );

    this._closeChat();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  // @ts-ignore
  async ngOnInit() {
    this.onLoadDocumentBySlug();
  }

  public downloadPage(page: PageModel): void {
    this.modalProgress.showModal('loading');
    const downloadParams = ConversionParams.makeConversionParamsByResolution(
      false,
      'medium',
    );

    this._pageIMGConversionService
      .downloadPGN(page, downloadParams, this.document.title)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          const event = this._exportUtil.downloadComplete();
          this.modalProgress.showModal('success');
          this.onDownloadEnded.emit({ data: true, event });
        },
        error => {
          const event = this._exportUtil.downloadError();
          this.modalProgress.showModal('error');
          this.onDownloadEnded.emit({ data: true, event });
        },
      );
  }

  public showSwipe(downloadSinglePage = false, page?: PageModel): void {
    this.showContainer = true;
    this.downloSinglePage = downloadSinglePage;
    this.pageRef = page;
  }

  private _closeChat(): void {
    this._goSquaredService.showAssistant(false);
  }

  public nextSlide(element) {
    this._scrollToElement(
      this._getParentSlide(element, EnumSlideDirection.NEXT),
    );
  }

  public previewSlide(element) {
    this._scrollToElement(
      this._getParentSlide(element, EnumSlideDirection.PREVIEW),
    );
  }

  public downloadType(props: { type: string; page?: PageModel }): void {
    const { type, page } = props;

    const downloadParams = ConversionParams.makeConversionParamsByResolution(
      false,
      'medium',
    );

    switch (type) {
      case 'png':
        this.downloadPNG(downloadParams, page);
        break;
      case 'pdf':
        this.downloadPDF(downloadParams, page);
        break;
      case 'mp4':
        this.downloadMP4(page);
        break;
    }
  }

  // FIXME: Move to repository layer (Service class)
  private async onLoadDocumentBySlug() {
    try {
      const { slug } = this.route.snapshot.params;
      const document = await this._getDocumentBySlug(slug);
      await this.newElementService.initFontModelsByDocument(document).toPromise();
      await this.newElementService.initAllQuillModel(document).toPromise();
      await this._readyPage(document);
    } catch (error) {
      console.warn('Erro ao carregar Doc.');
    }
  }

  // FIXME: Move to domain layer (Service class)
  private async _readyPage(document: IDocumentModel): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.document = document;

        const index = 0;

        this.currentDoc = {
          index,
          page: this.document.body[index],
          videoPlaying: false,
          videoHasClosed: false,
        };

        const pages = this.document.body;
        forkJoin(pages.map(page => this.fonts.loadByPage(page))).pipe(takeUntil(this._destroy$)).subscribe(
          () => {
            resolve({});
            this._hideLoader();
          },
          e => reject(e),
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  private adaptPage(pageForZoom?: any): void {
    const { offsetWidth, offsetHeight } = this.sectionPreview.nativeElement;

    const pages = this.document ? this.document.body : [];
    const page = pageForZoom || pages[0] || null;

    this.downloadMultiPages = this.document.body.length > 1 ? true : false;

    this._pageMargins();

    if (page) {
      this.zoomRatioDoc = this._getZoomRatioHeight(page);

      const additionalSizeLogo = 50;

      const area = {
        width: Math.floor(offsetWidth) - this.MARGIN_X * 2,
        height:
          Math.floor(offsetHeight) - this.MARGIN_Y * 2 + additionalSizeLogo,
      };

      const ratio = {
        area: area.width / area.height,
        page: page.width / page.height,
      };

      if (ratio.area < ratio.page) {
        this.zoomRatioDoc = this._getZoomRatioWidth(page);
      }

      this.zoomRatioByDoc = pages.reduce(
        (previous, current) => ({
          [current.id]: this._getZoomRatioWidth(current),
          ...previous,
        }),
        {},
      );
    }
  }

  private _hideLoader() {
    this._loaderService.hide();
    this.adaptPage();
  }

  private _getZoomRatioWidth(page): number {
    const container =
      this.sectionPreview.nativeElement.offsetWidth - this.MARGIN_X * 2;

    return (container / page.width) * 100;
  }

  private _getZoomRatioHeight(page): number {
    const container =
      this.sectionPreview.nativeElement.offsetHeight - this.MARGIN_Y * 2;

    return (container / page.height) * 100;
  }

  private _pageMargins(): void {
    const { offsetWidth } = this.sectionPreview.nativeElement;

    this.MARGIN_X = offsetWidth >= 768 ? 70 : 20;
    this.MARGIN_Y = offsetWidth >= 768 ? 70 : 20;
    this.REST_OF_DOC = offsetWidth >= 768 ? 40 : 20;
  }

  private downloadPNG(params: any, page?: PageModel): void {
    this.modalProgress.showModal('loading');
    this._pageIMGConversionService
      .downloadPGN(
        page ? page : this.currentDoc.page,
        params,
        this.document.title,
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this._downloadAnalyticsService._trackProjectDownloaded(
            this.document,
            downloadTypeEnum.png,
            true,
          );

          this._downloadAnalyticsService.designDownloaded(downloadTypeEnum.png);
          const event = this._exportUtil.downloadComplete();
          this.modalProgress.showModal('success');
          this.onDownloadEnded.emit({ data: true, event });
        },
        error => {
          this._downloadAnalyticsService._trackProjectDownloaded(
            this.document,
            downloadTypeEnum.png,
            false,
            error,
          );
          const event = this._exportUtil.downloadError();
          this.modalProgress.showModal('error');
          this.onDownloadEnded.emit({ data: true, event });
        },
      );
  }

  private downloadPDF(params, page?: PageModel): void {
    this.modalProgress.showModal('loading');

    this._pagePDFConversionService
      .downloadPDF(
        this.document,
        params,
        this.document.title,
        page ? this._pagePDFConversionService.formatPrintRange([], this.document, page) : null,
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this._downloadAnalyticsService.designDownloaded(downloadTypeEnum.pdf);
          this._downloadAnalyticsService._trackProjectDownloaded(
            this.document,
            downloadTypeEnum.pdf,
            true,
          );
          const event = this._exportUtil.downloadComplete();
          this.modalProgress.showModal('success');
          this.onDownloadEnded.emit({ data: true, event });
        },
        error => {
          this._downloadAnalyticsService._trackProjectDownloaded(
            this.document,
            downloadTypeEnum.pdf,
            error,
          );
          const event = this._exportUtil.downloadError();
          this.modalProgress.showModal('error');
          this.onDownloadEnded.emit({ data: true, event });
        },
      );
  }

  private downloadMP4(page: PageModel): void {
    this.modalProgress.showModal('loading');

    this._exportUtil
      .saveAsVideo(this.document, page ? page : this.currentDoc.page)
      .then((event: IEventModel) => {
        this._downloadAnalyticsService.designDownloaded(downloadTypeEnum.mp4);
        this._downloadAnalyticsService._trackProjectDownloaded(
          this.document,
          downloadTypeEnum.mp4,
          true,
        );
        this.modalProgress.showModal('success');
      })
      .catch((error: IEventModel) => {
        this._downloadAnalyticsService._trackProjectDownloaded(
          this.document,
          downloadTypeEnum.mp4,
          false,
        );
        this.modalProgress.showModal('error');
      });
  }

  private _getParentSlide(element, direction: EnumSlideDirection) {
    const parent = element.closest('.mobile-presentation__container-pages');

    if (direction === EnumSlideDirection.NEXT) {
      return parent.nextElementSibling;
    } else if (direction === EnumSlideDirection.PREVIEW) {
      return parent.previousElementSibling;
    }
  }

  private _scrollToElement(element) {
    if (element) {
      const elementHeight = element.offsetHeight;
      const windowHeight = window.innerHeight;
      let topOffset = 0;

      if (elementHeight < windowHeight) {
        topOffset = windowHeight / 2 - elementHeight / 2 - 30;
      }

      this.containerPages.nativeElement.scrollTo({
        top: element.offsetTop - topOffset,
        behavior: 'smooth',
      });
    }
  }
}
