import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { TraktoElementDirective } from '@editor/components/preview-2/svg-editor/drawables-container/shared/trakto-element.directive';
import { ShapeElementService } from '@services/shape-element.service';
import { IShapeElementModel } from '@trakto/models';

@Directive({
  selector: '[traktoPath]',
})
export class TraktoPathDirective
  extends TraktoElementDirective
  implements OnChanges {
  @Input()
  traktoPath: IShapeElementModel;

  @Input()
  resizeMode = false;

  @Input()
  clickableMode = true;

  @Input()
  strokeColor: string;

  @HostBinding('attr.d')
  d: string;

  @HostBinding('attr.fill')
  fill: string;

  @HostBinding('attr.fill-opacity')
  fillOpacity: number;

  @HostBinding('attr.stroke-width')
  strokeWidth: number;

  @HostBinding('attr.stroke-dasharray')
  @Input()
  strokeDasharray: string;

  @HostBinding('attr.stroke')
  stroke: string;

  @HostBinding('attr.stroke-opacity')
  strokeOpacity: number;

  @HostBinding('attr.stroke-linejoin')
  strokeLinejoin = 'round';

  @HostBinding('attr.stroke-linecap')
  strokeLinecap = 'square';

  @HostBinding('attr.vector-effect')
  vectorEffect = 'non-scaling-stroke';

  @HostBinding('attr.pointer-events')
  pointerEvents = 'all';

  constructor(private newShapeService: ShapeElementService) {
    super();
  }

  ngOnChanges() {
    if (!this.traktoPath) {
      return;
    }

    if (!this.resizeMode) {
      this.d = this.traktoPath.path;
      this.fill = this.traktoPath.backgroundColor;
      this.stroke = this.traktoPath.strokeColor;
      this.strokeOpacity = this.traktoPath.strokeOpacity;

      // Prevents to lost component when it has no background and no border
      const strokeWidth =
        this.traktoPath.backgroundColor === null &&
        this.traktoPath.strokeWidth === 0
          ? 1
          : this.traktoPath.strokeWidth;
      this.fillOpacity = this.traktoPath.backgroundColor
        ? this.traktoPath.opacity
        : 0;
      this.strokeWidth = strokeWidth * this.zoomRatio;
      this.strokeDasharray = this.newShapeService.normalizeShapeDasharray(
        this.traktoPath,
        this.zoomRatio,
      );
    } else {
      this.d = this.traktoPath.supportPath;
      this.fill = 'transparent';
      this.fillOpacity = 0;
      this.stroke = this.strokeColor; // vai depender
      this.strokeWidth = 1;
      this.strokeOpacity = 1;
      // this.strokeDasharray = strokeStyleEnum.solid;
      this.notPrintable = true;
    }

    if (this.clickableMode) {
      this.pointerEvents = 'all';
    } else {
      this.pointerEvents = 'none';
    }
  }
}
