import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { ConfirmationComponent } from '../components/shared/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private _component: ComponentRef<any> = null;

  constructor(
    private _appRef: ApplicationRef,
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {}

  show(inputs: any, outputs: any = {}): boolean {
    if (this._component) return false;

    const componentRef = this._resolver
      .resolveComponentFactory(ConfirmationComponent)
      .create(this._injector);

    this._attachConfig(inputs, outputs, componentRef);

    this._appRef.attachView(componentRef.hostView);

    const container = document.querySelector('body');

    container.appendChild(
      (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement,
    );

    this._component = componentRef;

    return true;
  }

  private _attachConfig(
    inputs: any,
    outputs: any,
    componentRef: ComponentRef<any>,
  ): void {
    for (const [key, value] of Object.entries(inputs)) {
      componentRef.instance[key] = value;
    }

    for (const [key, value] of Object.entries(outputs)) {
      componentRef.instance[key] = value;
    }
  }

  destroy() {
    this._appRef.detachView(this._component.hostView);
    this._component.destroy();
    this._component = null;
  }
}
