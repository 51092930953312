import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@app/shared/button/button.module';

import { DropdownContentComponent } from './dropdown-content.component';

@NgModule({
  declarations: [DropdownContentComponent],
  imports: [FormsModule, CommonModule, ButtonModule],
  exports: [DropdownContentComponent],
})
export class DropdownContentModule {}
