import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { IElementDropdown } from '../dropdown';
import { DropdownService } from '../dropdown.service';
import { PlacementEnum } from '../placement.model';

import { IButtonConfig } from '@shared/button/button.model';

@Component({
  selector: 'trakto-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss'],
})
export class DropdownContentComponent implements OnInit, IElementDropdown {
  private _width: number;
  private _icon: string;
  private _title: string;
  private _caret: boolean;
  private _isOpen: boolean;
  private _baseClass: string;
  private _disabled: boolean;
  private _elementNative: any;
  private _placement: PlacementEnum;

  private _iconSize: number;
  private _button: IButtonConfig;

  @Input() public set title(title: string) {
    this._title = title;
  }

  public get title(): string {
    return this._title;
  }

  @Input() public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon(): string {
    return this._icon;
  }

  @Input() public set caret(caret: boolean) {
    this._caret = caret;
  }

  public get caret(): boolean {
    return this.icon === undefined && this._caret === undefined
      ? true
      : this._caret;
  }

  @Input() public set width(width: number) {
    this._width = width;
  }

  public get width(): number {
    return this._width || 175;
  }

  @Input() public set iconSize(iconSize: number) {
    this._iconSize = iconSize;
  }

  public get iconSize(): number {
    return this._iconSize;
  }

  @Input() public set placement(placement: PlacementEnum) {
    this._placement = placement;
  }

  public get placement(): PlacementEnum {
    return this._placement || PlacementEnum.right;
  }

  @Input() public set button(button: IButtonConfig) {
    this._button = button;
  }

  public get button(): IButtonConfig {
    return this._button || { classes: ['button--white'] };
  }

  @Input() public set disabled(value: boolean) {
    this._disabled = value;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _elementRef: ElementRef,
    private dropdownService: DropdownService,
  ) {
    this._isOpen = false;
    this._baseClass = 'trakto-dropdown-content';
  }

  @HostListener('document:click', ['$event'])
  out(event: Event) {
    if (
      !this._elementRef.nativeElement.contains(event.target) &&
      this._isOpen
    ) {
      this.close();
    }
  }

  ngOnInit(): void {
    this._elementNative = this._elementRef.nativeElement.children[0];
    this.loadPlacement();
  }

  public toggle(): void {
    !this._isOpen ? this.open() : this.close();
  }

  public open(): void {
    if (!this._disabled) {
      this.dropdownService.disable();
      this._isOpen = true;
      this._elementNative.classList.add('--active');
      this.onOpen.emit(this._isOpen);
      this.dropdownService.active(this);
    }
  }

  public close(): void {
    this._isOpen = false;
    this._elementNative.classList.remove('--active');
    this.onClose.emit(this._isOpen);
  }

  select(): void {
    this.onClick.emit();
    this.close();
  }

  public loadPlacement(): void {
    switch (this.placement) {
      case PlacementEnum.center:
        this.setPlacement(PlacementEnum.center);
        break;
      case PlacementEnum.left:
        this.setPlacement(PlacementEnum.left);
        break;
      default:
        this.setPlacement(PlacementEnum.right);
        break;
    }
  }

  public setPlacement(position: PlacementEnum): void {
    this._elementNative.removeAttribute('class');
    this._elementNative.setAttribute(
      'class',
      `${this._baseClass} --${position}`,
    );
  }
}
