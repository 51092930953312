import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '@app/auth/shared/auth.service';
import { environment } from '@env/environment';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { map } from 'rxjs/operators';
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable({ providedIn: 'root' })
export class DropboxService {
  private _docsApiUrl = environment.api.docs.url;
  private _dbxUser;
  private _dbxProduct;
  private _dbxApp;

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private _productService: WhitelabelProductService,
    private _firestore: AngularFirestore,
    private _userService: UserService,
  ) {}

  getSession(dropboxToken: string, fileId: string): Promise<any> {
    let url = `${this._docsApiUrl}integrations/dropbox/session?accessToken=${dropboxToken}`;

    if (fileId !== 'false') {
      url += `&fileId=${fileId}`;
    }

    return this._httpClient
      .get<{ authToken: string; file: any }>(url)
      .pipe(
        map(async (result: any) => {
          await this._authService.login(result.authToken).toPromise();

          if (!result.file) {
            return false;
          }

          return result.file;
        }),
      )
      .toPromise();
  }

  async createDocument(link?: string, width?: number, height?: number) {
    const headers: any = await this._getHeaderAuthDocsApi();

    if (!link) {
      return this._httpClient
        .post(`${this._docsApiUrl}documents/create-blank`, null, { headers })
        .toPromise();
    }

    return this._httpClient
      .post(
        `${this._docsApiUrl}documents/create-blank`,
        {
          elementImageUrl: link,
          width,
          height,
        },
        { headers },
      )
      .toPromise();
  }

  async uploadFile(
    accessToken: string,
    originalFileId: string,
    file: any,
  ): Promise<string> {
    const dropboxApiV1Url = `${environment.api.dropbox.urls.v1}/files/upload`;
    const dropboxApiV2Url = `${environment.api.dropbox.urls.v2}/sharing/get_file_metadata`;
    let headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });

    const pathStringValue: any = {
      mode: 'add',
      autorename: true,
      mute: false,
      strict_conflict: false,
    };

    if (originalFileId !== 'false') {
      const metadata: any = await this._httpClient
        .post(dropboxApiV2Url, { file: originalFileId }, { headers })
        .toPromise();

      pathStringValue.path = metadata.path_lower.replace(
        /\.(?=[^.]*$)/,
        '(edited - by Trakto).',
      );
      pathStringValue.path = String(pathStringValue.path).replace(
        '.jpg',
        '.png',
      );
    } else {
      pathStringValue.path = `/Created with Trakto.png`;
    }

    headers = headers.append(
      'Dropbox-API-Arg',
      JSON.stringify(pathStringValue),
    );
    headers = headers.append('Content-Type', 'application/octet-stream');

    const resultFile: any = await this._httpClient
      .post(dropboxApiV1Url, file, { headers })
      .toPromise();

    const previewUrl = resultFile.path_lower
      ? `https://dropbox.com/home${resultFile.path_lower}`
      : `https://dropbox.com/h`;

    return previewUrl;
  }

  private async _getHeaderAuthDocsApi() {
    const user = this._userService.user;

    this._dbxUser = user;
    this._productService.currentProductId = user.current_profile.product.id;

    await this.refreshProducServiceData();

    return new HttpHeaders({
      'User-Token': user.id,
      'Product-Key': user.current_profile.product.id,
    });
  }

  public async trackCreateDocument(newDocument: any) {}

  public async refreshProducServiceData() {
    try {
      if (
        !this._productService.currentApp ||
        !this._productService.currentProduct
      ) {
        await this._productService.setCurrentProductFromId(
          this._dbxUser.app_reference.id,
          this._dbxUser.current_profile.product.id,
        );
      }
      this._dbxApp = this._productService.currentApp;
      this._dbxProduct = this._productService.currentProduct;
    } catch (error) {
      throw new Error(error);
    }
  }

  public async getDropboxDefaultLocale() {
    const defaultLang = 'en-US';
    try {
      const dropbox = await this._firestore
        .collection('app')
        .doc(this.getDropboxStaticProduct().app.id)
        .ref.get();
      if (dropbox.data()) {
        return dropbox.data().locale.default
          ? dropbox.data().locale.default
          : defaultLang;
      }
    } catch (error) {
      return defaultLang;
    }
  }

  public getDropboxStaticProduct() {
    return {
      app: {
        id: environment.production
          ? 'oAYhKVHiqb3zNKF8dXZh'
          : 'Ds0klsUHyLyCF8ImSlk3',
      },
      product: {
        id: environment.production
          ? 'MmXpRRIIUk3uy3pFQLXi'
          : 'XAjVeR6l4HfLuNCWWGGJ',
      },
    };
  }
}
