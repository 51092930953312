import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { Positioning } from 'positioning';
import { FocusDirective } from './focus.directive';
import {
  IPopoverOptionsInterface,
  PopoverOptions,
} from './popover-options.provider';
import { PopoverWindowComponent } from './popover-window.component';
import { TraktoPopoverDirective } from './popover.directive';

export const USER_OPTIONS: InjectionToken<string> = new InjectionToken(
  'confirmation popover user options',
);

export function optionsFactory(userOptions: PopoverOptions): PopoverOptions {
  const options: PopoverOptions = new PopoverOptions();
  Object.assign(options, userOptions);
  return options;
}

@NgModule({
  declarations: [
    TraktoPopoverDirective,
    PopoverWindowComponent,
    FocusDirective,
  ],
  imports: [CommonModule],
  exports: [TraktoPopoverDirective, FocusDirective],
  entryComponents: [PopoverWindowComponent],
})
export class PopoverModule {
  static forRoot(options: IPopoverOptionsInterface = {}): ModuleWithProviders<any> {
    return {
      ngModule: PopoverModule,
      providers: [
        {
          provide: USER_OPTIONS,
          useValue: options,
        },
        {
          provide: PopoverOptions,
          useFactory: optionsFactory,
          deps: [USER_OPTIONS],
        },
        Positioning,
      ],
    };
  }
}
