import { Injectable } from "@angular/core";
import { GifService, StickerService } from "@trakto/graphics-resources";
import {
  FileRepository,
  IAssetResponse
} from '@editor/repository/file.repository';

@Injectable()
export class GalleryGifsService {

  constructor(
    private _filesRepository: FileRepository,
  ) {
  }

  public listGifs(
    query: string = '',
    offset?: any,
    limit?: number,
  ): Promise<any> {
    return GifService.list({query, limit, offset,});
  }

  public listStickers(
    query: string = '',
    offset?: any,
    limit?: number,
  ): Promise<any> {
    return StickerService.list({query, limit, offset,});
  }

  public listHighlightGifs(offset?: number, limit?: number): Promise<any> {
    return GifService.list({
      limit,
      offset,
      highlight: true,
    });
  }

  public listHighlightStickers(offset?: number, limit?: number): Promise<any> {
    return StickerService.list({
      limit,
      offset,
      highlight: true,
    });
  }

  public listUserGifs(limit = 30, startAfter?: string): Promise<IAssetResponse> {
    return this._filesRepository.findGifsByLoggedUser(limit, startAfter);
  }
}
