import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-modal-app',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  public typeModal: string;
  public txtSuccess: string;
  public txtProgress: string;
  public txtError: string;

  public errorMessage: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getTranslateModal();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public showModal(type: string): void {
    this.typeModal = type;
    this._renderer.setAttribute(
      this._elementRef.nativeElement,
      'class',
      '--active',
    );
  }

  public closeModal(): void {
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'class');
  }

  private getTranslateModal(): void {
    this._translateService
      .get('mobile-presentation.modal')
      .pipe(takeUntil(this._destroy$))
      .subscribe(translate => {
        const { error, progress, success } = translate;
        this.txtProgress = progress;
        this.txtSuccess = success;
        this.txtError = error;
      });
  }
}
