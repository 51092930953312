import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentStep,
  selectTemplateType,
  selectTotalSteps
} from './store/stepper.selectors';
import {
  incrementCurrentStep,
  setCurrentStep,
  setTemplateType
} from './store/stepper.actions';
import { TranslateService } from '@ngx-translate/core';
import { PanelStackService } from "@app/editor/services/panel-stack.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentManagerService } from '@services/document-manager.service';

@Component({
  selector: 'trakto-panel-publish',
  templateUrl: './panel-publish.component.html',
  styleUrls: ['./panel-publish.component.scss'],
})
export class PanelPublishComponent implements OnInit {

  currentStep$ = this._store.select(selectCurrentStep);
  templateType$ = this._store.select(selectTemplateType);
  totalSteps$ = this._store.select(selectTotalSteps);

  private destroy$ = new Subject<void>();
  public progressBarColor: string = '#4743FF';

  templateColors = {
    default: {
      1: '#4743FF',
      2: '#F6AE2B',
      3: '#1DC763',
    },
    magic: {
      1: '#4743FF',
      2: '#F6AE2B',
      3: '#FE6E46',
      4: '#FE4A23',
      5: '#1DC763',
    },
  };

  public selectTemplateType: string;
  public defaultTemplate: string;
  public magicTemplate: string;

  constructor(
    private _store: Store,
    private _translateService: TranslateService,
    private _documentManagerService: DocumentManagerService,
  ) {
    this.loadTranslations();
  }

  ngOnInit(): void {
    this._store.dispatch(setCurrentStep({ step: 2 }));
    this._store.dispatch(setTemplateType({ templateType: 'magic' }));
    this.currentStep$.pipe(takeUntil(this.destroy$)).subscribe(step => {
      this.templateType$.subscribe(template => {
        if (step && template) {
          this.onStepColorChange(step, template)
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadTranslations(): void {
    this._translateService.get('sidebar_publish').pipe(takeUntil(this.destroy$)).subscribe(translations => {
      this.selectTemplateType = translations['select-template-type'];
      this.defaultTemplate = translations['default-template'];
      this.magicTemplate = translations['magic-template'];
    });
  }

  onStepColorChange(step: number, template: string) {
    this.progressBarColor = this.templateColors[template][step];
  }

  navigateToNextStep() {
    this._store.dispatch(incrementCurrentStep());
  }

  onClosePanelClick() {
    this._documentManagerService.forceOpeningElementsPanel();
  }

}
