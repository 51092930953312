import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditorComponent } from '@editor/editor.component';

import { Title } from '@angular/platform-browser';

import { GoSquaredService } from '@app/editor/services/gosquared/gosquared.service';
import { MetaTagsService } from '@app/editor/services/meta-tags/meta-tags.service';
import { PlatformLoadingService } from '@app/editor/services/platform-loading.service';
import { environment } from '@env/environment';
import { DocumentService } from '@services/document.service';
import { ElementModelService } from '@services/element-model.service';
import ScrollFix from '../../assets/scripts/scrollFix';
import { TraktoLinksAnalyticsService } from '../editor/services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { IDocumentModel } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-preview-doc',
  templateUrl: './preview.doc.component.html',
  styleUrls: ['./preview.doc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewDocComponent implements OnInit {
  @ViewChild('previewDocument', { static: true }) public previewDocument: any;

  public dashboardURL: string;
  public document: IDocumentModel;
  private _platformId;
  isTraktoLinks: any;
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) public DOM: Document,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly _route: ActivatedRoute,
    private _goSquaredService: GoSquaredService,
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
    private _metaTagsService: MetaTagsService,
    private _titleService: Title,
    private _documentService: DocumentService,
    private _loaderService: PlatformLoadingService,
    private _newElementService: ElementModelService,
  ) {
    this._platformId = platformId;
    if (!isPlatformBrowser(platformId)) {
      const { document } = this._route.snapshot.params;
      this._documentService.getPublicMetadata(document).pipe(takeUntil(this._destroy$)).subscribe(
        header => {
          this._metaTagsService.setMetaTags(header);
        },
        err => {
          this._metaTagsService.setMetaTags({});
        },
      );
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }


  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.dashboardURL = environment.externalDashboardPage;
      this._goSquaredService.showAssistant(false);
      this._initializeContent().then();
      this._showOrHideLoadingLogo();
    }
  }

  /**
   * Loads all design content into the component.
   * If the content does not exist, the user is redirected to page 404.
   */
  private async _initializeContent() {
    const { document } = this._route.snapshot.params;
    if (this._route.component === EditorComponent) {
      this.document = await this._documentService.findById(document);
      this._loadContent(document);
    }

    if (this._route.component === PreviewDocComponent) {
      try {
        this._documentService.getPublicMetadata(document).pipe(takeUntil(this._destroy$)).subscribe(
          header => {
            this._redirectOrNot(header);
            this._titleService.setTitle(header?.title || ' ');
            this._loadContent(document);
          },
          err => {
            this._triggerNotFoundLink(document);
          },
        );
      } catch (error) {
        this._triggerNotFoundLink(document);
      }
    }
  }

  /**
   * Fill the content from design/document laoded
   * @param documentId design's/document id
   */
  private async _loadContent(documentId: string) {
    try {
      await this._newElementService.initFontModelsByDocument(this.document).toPromise();
      await this._newElementService.initAllQuillModel(this.document).toPromise();

    } catch (e) {
      this._triggerNotFoundLink(documentId);
    }
    this.finishLoading();
    ScrollFix(this.previewDocument.nativeElement);
  }

  /**
   * Redirect the user to 404 page
   * @param linkName Path requested accessed by user
   */
  private _triggerNotFoundLink(linkName: string) {
    window.location.href = environment.static.traktoLinksNotFound;
  }

  /**
   * Hides the loader presented on view
   */
  private finishLoading() {
    const tktLoader = this.DOM.getElementById('tkt-loader');

    if (!tktLoader) {
      return;
    }
    tktLoader.remove();
    this._loaderService.hide();
  }

  /**
   * @description Idenifify if device is mobile
   */
  private _deviceHasBigScreenSize() {
    return window.innerWidth >= 1024;
  }

  /**
   * Checks if is necessary redirect the user to another url rendered for big screens
   * @param header Document's metadata provided by an user
   */
  private _redirectOrNot(header: any) {
    if (this._deviceHasBigScreenSize() && header.link_web) {
      // If is a desktop and has a web_link them redirect to web link
      window.location.href = header.link_web.startsWith('http')
        ? header.link_web
        : `https://${header.link_web}`;
    }
  }

  private _showOrHideLoadingLogo(): void {
    if (window.location.href.indexOf('editor') === -1) {
      this._loaderService.init(true, true);
    }
  }
}
