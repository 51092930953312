import {
  DEFAULT_THUMB_COVER,
  DROPBOX,
  MAX_COVER_DIMENSION_PX,
  MAX_UPLOAD_SIZE,
  PRODUCT_B2C_ID,
  GOOGLE_PRODUCT_IDS,
  USERSNAP,
  SEGMENTIO_KEY,
  IMAGES_API,
  SHAPES_API,
  ICONS_API,
  AZURE_TRANSLATION_API,
  GOOGLE_API,
  REMOVEBG_API,
  CRISP_API,
  PREMIUM_PLANS,
  COLLECTIONS_NOMENCLATURE,
  DEFAULT_LOCALES,
  DEFAULT_LOCALE,
  UNSPLASH_API,
  STRIPE_API,
  templateUrl,
  bellaUrl,
  STARTER_PLANS,
  COHERE_KEY,
  GO_SQUARED,
  ELASTIC,
  BASE_URL_TOKEN_AUTH_API,
  BASE_URL_TRAKTO_LINK_PUBLISH_API,
  PRODUCT_FRUITS,
  WHITELABELS,
  JIVO_CHAT,
  CHECKOUT,
  TKT_ANALYTICS,
  TOP_BANNER_CONFIG,
  googleAdsUrl,
  googleServiceUrl,
} from './constants.prod';

const WEB_BELLA = 'https://web-bella.web.app/';

const userSnapConfigs = USERSNAP;
const segmentWriteKey = SEGMENTIO_KEY;

export const environment = {
  production: true,
  userProvider: 'hosted',
  segmentWriteKey,
  cohereKey: COHERE_KEY,
  externalIntegrations: {
    amplitude: true,
    driftSupport: false,
    facebookPixel: true,
    gtm: false,
    segmentTracking: false,
    siteKit: true,
    stripe: true,
    userSnap: true,
    cohere: true,
    gosquared: false,
    jivochat: false,
  },
  api: {
    export_service: 'https://export.trakto.io',
    baseUrl: 'https://api.trakto.io/',
    templateUrl,
    bellaUrl,
    BASE_URL_TOKEN_AUTH_API,
    BASE_URL_TRAKTO_LINK_PUBLISH_API,
    WHITELABELS,
    docs: {
      url: 'https://us-central1-trakto-production.cloudfunctions.net/docsApiBeta/api/v1/',
    },
    tkAnalytics: TKT_ANALYTICS,
    webBella: WEB_BELLA,
    dropbox: DROPBOX,
    userSnap: userSnapConfigs,
    goSquared: GO_SQUARED,
    elastic: ELASTIC,
    images: IMAGES_API,
    shapes: SHAPES_API,
    icons: ICONS_API,
    translation: AZURE_TRANSLATION_API,
    google: GOOGLE_API,
    removeBg: REMOVEBG_API,
    crisp: CRISP_API,
    stripe: {
      ...STRIPE_API,
      ...STARTER_PLANS,
      plans: PREMIUM_PLANS,
    },
    productFruits: PRODUCT_FRUITS,
    jivoChat: JIVO_CHAT,
    checkout: CHECKOUT,
  },
  static: {
    image: 'assets/images/image-placeholder.jpg',
    loading: 'assets/images/uploading.png',
    error: 'assets/images/error.png',
    traktoIsotipo: '/assets/images/trakto-t.svg',
    traktoLinksDefaultUrl: 'https://beta.trakto.link/',
    traktoLinksNotFound: 'https://beta.trakto.link/404',
  },
  googleTagManagerCode: 'GTM-MBD686D',
  defaultLocale: DEFAULT_LOCALE,
  availableLocales: DEFAULT_LOCALES,
  indexPage: ['/'],
  logoutPage: ['/auth', 'logout'],
  documentPreviewURL: 'https://editor.beta.trakto.io/presentation',
  documentShareURL: 'https://editor.beta.trakto.io/editor',
  externalLoginPage: 'https://beta.trakto.io/',
  externalDashboardPage: 'https://beta.trakto.io/',
  externalTraktoStudioPage: 'https://google.trakto.io/',
  externalDashboardPrintableShop: 'https://dashboard.trakto.io/printable',
  externalDashboardUserOrders: 'http://dashboard.trakto.io/order',
  externalUrlTrakto: 'https://www.trakto.io',
  editor: 'https://editor.beta.trakto.io',
  appId: '3a3aa6ce-6603-4417-bdb9-efed90371f77',
  sdkToken: '83a1baad-8f05-4643-8d9b-8f4c28096735',
  apiBase: UNSPLASH_API.url,
  ...COLLECTIONS_NOMENCLATURE,
  cookieSessionKey: 'trakto-session',
  cookiePath: '/',
  cookieDomain: '.trakto.io',
  globalLogout: true,
  freeUserId: '9MOoOmUwemOYI8F4IMVN7NPQsG12',
  maxUploadFile: MAX_UPLOAD_SIZE,
  preview2: true,
  showHistoryComponent: false,
  maxPages: 300,
  defaultThumbCover: DEFAULT_THUMB_COVER,
  productIdB2C: PRODUCT_B2C_ID,
  googleProducts: GOOGLE_PRODUCT_IDS,
  maxSaveAttempts: 8,
  maxCoverDimensionPx: MAX_COVER_DIMENSION_PX,
  topBannerConfig: TOP_BANNER_CONFIG,
  googleAdsService: googleServiceUrl,
  googleAds: googleAdsUrl,
};
