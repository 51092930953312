import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
// import { SharedModule } from '@app/shared/shared.module';
import { DropdownSelectorModule } from '@app/shared/dropdown-selector/dropdown-selector.module';
import { LocaleSelectorComponent } from './locale-selector.component';

@NgModule({
  imports: [CommonModule, DropdownSelectorModule],
  declarations: [LocaleSelectorComponent],
  exports: [LocaleSelectorComponent],
})
export class LocaleSelectorModule {}
