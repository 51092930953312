import { TraktoLink } from "@app/editor/services/traktoLinks/trakto-link.service";
import * as TraktoLinksActions from './trakto-link.actions';
import { createReducer, on } from "@ngrx/store";

export interface TraktoLinkState {
  traktoLink: TraktoLink;
}

export const initialState: TraktoLinkState = {
  traktoLink: null
};

const reducer = createReducer<TraktoLinkState>(
  initialState,
  on(TraktoLinksActions.addTraktoLink, (state, { traktoLink }) =>
    {
      return {
        ...state,
        traktoLink: traktoLink
      };
    }),


  on(TraktoLinksActions.editTraktoLink, (state, { traktoLink }) =>
  {
    return {
      ...state,
      traktoLink: traktoLink
    };
  })
);

export function traktoLinkReducer(state: TraktoLinkState | undefined, action: any) {
  return reducer(state, action);
}
