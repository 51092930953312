<div
  class="themes-folders"
  [ngClass]="{ 'themes-folders--expanded': isPanelExpanded }"
>
  <div class="themes-folders__loading" *ngIf="isPacksLoading; else isLoaded">
    <i class="trakto-icon-stream-cog" [ngStyle]="{ color: primaryColor }"></i>
  </div>
  <ng-template #isLoaded>
    <div
      class="theme"
      *ngFor="let pack of packs"
      (click)="openFolder(pack)"
    >
      <div
        class="theme__card"
        [ngStyle]="{ backgroundColor: 'aliceblue' }"
      >
        <div class="theme__content">
          <div class="theme__image">
            <img [src]="pack.thumbUrl" />
          </div>
          <div class="theme__title">
            <p [ngStyle]="{ color: '#555555' }">
              {{ pack.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="theme__author">
        <p>
          By
          <strong>
            {{ getAuthorsName(pack.authors)}}
          </strong>
        </p>
      </div>
    </div>
  </ng-template>
</div>
