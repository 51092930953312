import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../shared/material.module';
import { BrowserModule } from '@angular/platform-browser';

import { PanelActionsComponent } from './panel-actions/panel-actions.component';
import { PanelButtonComponent } from './panel-button/panel-button.component';
import { PanelColorComponent } from './panel-color/panel-color.component';
import { PanelDownloadComponent } from './panel-download/panel-download.component';
import { PanelDuotoneComponent } from './panel-duotone/panel-duotone.component';
import { PanelFilterComponent } from './panel-filter/panel-filter.component';
import { PanelGroupComponent } from './panel-group/panel-group.component';
import { PanelImageSettingsComponent } from './panel-image-settings/panel-image-settings.component';
import { PanelImageComponent } from './panel-image/panel-image.component';
import { PanelLinkEmailComponent } from './panel-link-email/panel-link-email.component';
import { PanelLinkWebsiteComponent } from './panel-link-website/panel-link-website.component';
import { PanelLinkWhatsappComponent } from './panel-link-whatsapp/panel-link-whatsapp.component';
import { PanelPageComponent } from './panel-page/panel-page.component';
import { PanelPublishComponent } from './panel-publish/panel-publish.component';
import { PanelShapeComponent } from './panel-shape/panel-shape.component';
import { PanelTextComponent } from './panel-text/panel-text.component';
import { PanelVideoSettingsComponent } from './panel-video-settings/panel-video-settings.component';
import { PanelVideoComponent } from './panel-video/panel-video.component';
import { PanelZeroComponent } from './panel-zero/panel-zero.component';
import { PropertiesPanelComponent } from './properties-panel.component';

import { PopoverModule } from '@app/shared/popover/popover.module';
import { PipesModule } from '../../../editor/pipes/pipes.module';

import { TranslateModule } from '@ngx-translate/core';

import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';

import { PanelTextFontModule } from './panel-text-font/panel-text-font.module';
import { PanelMagicTemplatesComponent } from './panel-magic-templates/panel-magic-templates.component';
import { MagicInputComponent } from './panel-magic-templates/magic-input/magic-input.component';
import { PanelElementsComponent } from './panel-elements/panel-elements.component';
import { SearchbarComponent } from './panel-elements/searchbar/searchbar.component';
import { FormatsFoldersComponent } from './panel-elements/formats-folders/formats-folders.component';
import { ThemesFoldersComponent } from './panel-elements/themes-folders/themes-folders.component';
import { SearchResultsComponent } from './panel-elements/search-results/search-results.component';
import { FormatsResultsComponent } from './panel-elements/formats-results/formats-results.component';
import { TagsListComponent } from './panel-elements/tags-list/tags-list.component';
import { ThemeResultsComponent } from './panel-elements/theme-results/theme-results.component';
import { ElementsListComponent } from '@editor/components/properties-panel/panel-elements/elements-list/elements-list.component';
import { EmptyStateComponent } from './panel-elements/elements-list/empty-state/empty-state.component';
import { SeeMoreComponent } from './panel-elements/elements-list/see-more/see-more.component';
import { BannerComponent } from './panel-elements/banner/banner.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InlineSVGModule } from 'ng-inline-svg';
import { ScrollUpComponent } from './panel-elements/scroll-up/scroll-up.component';
import { ScrollListenerDirective } from '@app/shared/directives/scroll-listener.directive';
import { SwiperModule } from 'swiper/angular';
import { RenderSvgIconComponent } from './panel-page/render-svg-icon/render-svg-icon.component';
import { InputsSettingsComponent } from './panel-page/inputs-settings/inputs-settings.component';
import { DropdownComponent } from './panel-page/dropdown/dropdown.component';
import { GridFormatsComponent } from './panel-page/grid-formats/grid-formats.component';
import { FormatCardComponent } from './panel-page/format-card/format-card.component';
import { InputDimensionsComponent } from './panel-page/inputs-settings/input-dimensions/input-dimensions.component';
import { MagicTemplateModule } from '@shared/magic-template/magic-template.module';
import { DefaultTemplateComponent } from './panel-publish/default-template/default-template.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StoreModule } from '@ngrx/store';
import { stepperReducer } from './panel-publish/store/stepper.reducer';
import { MagicTemplateComponent } from './panel-publish/magic-template/magic-template.component';
import { ModalUnfoldTemplateComponent } from './panel-publish/magic-template/modal-unfold-template/modal-unfold-template.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    PanelTextFontModule,
    MaterialModule,
    NgxFlagIconCssModule,
    ReactiveFormsModule,
    PopoverModule.forRoot({
      focusButton: 'confirm',
    }),
    TranslateModule,
    PipesModule,
    BrowserModule,
    InfiniteScrollModule,
    InlineSVGModule.forRoot(),
    SwiperModule,
    MagicTemplateModule,
    MatStepperModule,
    CdkStepperModule,
    StoreModule.forFeature('stepper', stepperReducer),
  ],
  declarations: [
    PropertiesPanelComponent,
    PanelPageComponent,
    PanelTextComponent,
    PanelZeroComponent,
    PanelShapeComponent,
    PanelVideoComponent,
    PanelImageComponent,
    PanelButtonComponent,
    PanelFilterComponent,
    PanelDuotoneComponent,
    PanelActionsComponent,
    PanelPublishComponent,
    PanelMagicTemplatesComponent,
    PanelDownloadComponent,
    PanelLinkEmailComponent,
    PanelLinkWebsiteComponent,
    PanelLinkWhatsappComponent,
    PanelImageSettingsComponent,
    PanelVideoSettingsComponent,
    PanelGroupComponent,
    PanelColorComponent,
    MagicInputComponent,
    PanelElementsComponent,
    SearchbarComponent,
    FormatsFoldersComponent,
    ThemesFoldersComponent,
    SearchResultsComponent,
    FormatsResultsComponent,
    TagsListComponent,
    ThemeResultsComponent,
    ElementsListComponent,
    EmptyStateComponent,
    SeeMoreComponent,
    BannerComponent,
    ScrollUpComponent,
    ScrollListenerDirective,
    RenderSvgIconComponent,
    InputsSettingsComponent,
    DropdownComponent,
    GridFormatsComponent,
    FormatCardComponent,
    InputDimensionsComponent,
    DefaultTemplateComponent,
    MagicTemplateComponent,
    ModalUnfoldTemplateComponent
  ],
  exports: [
    PropertiesPanelComponent,
    PanelPageComponent,
    PanelTextComponent,
    PanelZeroComponent,
    PanelShapeComponent,
    PanelVideoComponent,
    PanelImageComponent,
    PanelButtonComponent,
    PanelFilterComponent,
    PanelDuotoneComponent,
    PanelActionsComponent,
    PanelPublishComponent,
    PanelMagicTemplatesComponent,
    PanelDownloadComponent,
    PanelLinkEmailComponent,
    PanelLinkWebsiteComponent,
    PanelLinkWhatsappComponent,
    PanelImageSettingsComponent,
    PanelVideoSettingsComponent,
    PanelGroupComponent,
    TranslateModule,
    PanelColorComponent,
    FormatsFoldersComponent,
    MagicTemplateComponent,
    ModalUnfoldTemplateComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PropertiesPanelModule {}
