import { Injectable } from '@angular/core';
import { GifMagicTemplateService } from '@shared/magic-template/shared/gif-magic-template.service';
import { IconMagicTemplateService } from '@shared/magic-template/shared/icon-magic-template.service';
import { ImageMagicTemplateService } from '@shared/magic-template/shared/image-magic-template.service';
import { ShapeMagicTemplateService } from '@shared/magic-template/shared/shape-magic-template.service';
import { TextMagicTemplateService } from '@shared/magic-template/shared/text-magic-template.service';
import { UtilMagicTemplateService } from '@shared/magic-template/shared/util-magic-template.service';
import { MagicTemplateGenerator } from '@trakto/magic-templates';
import {
  MTSkillsType,
  MTTagInputType,
  MTUserInputDescriptor
} from '@trakto/models';
import {
  FontMagicTemplateService
} from '@shared/magic-template/shared/font-magic-template.service';

@Injectable()
export class MagicTemplateService extends MagicTemplateGenerator {

  private advertisementPhotosLabels = [
    { tag: 'product_photo_1_1', label: 'Foto 1 do produto (primeira página)'},
    { tag: 'product_photo_1_2', label: 'Foto 2 do produto (primeira página)'},
    { tag: 'product_photo_2_1', label: 'Foto do produto (segunda página)'},
    { tag: 'product_photo_2_2', label: 'Foto 2 do produto (segunda página)'},
    { tag: 'product_photo_3_1', label: 'Foto do produto (terceira página)'},
    { tag: 'product_photo_3_2', label: 'Foto 3 do produto (terceira página)'},
  ];

  private advertisementTextsLabels = [
    { tag: 'product_name_1_1', label: 'Nome do produto (primeira página)'},
    { tag: 'product_name_1_2', label: 'Nome mais descrito do produto (primeira página)'},
    { tag: 'footer_text_1', label: 'Texto de rodapé (primeira página)'},
    { tag: 'total_value_1', label: 'Total do produto (primeira página)'},
    { tag: 'amount_times_1', label: 'Quantidade de parcelas (primeira página)'},
    { tag: 'integer_installment_value_1', label: 'Parte inteira da parcela (primeira página)'},
    { tag: 'decimal_installment_value_1', label: 'Parte decimal (primeira página)'},


    { tag: 'product_name_2_1', label: 'Nome do produto (segunda página)'},
    { tag: 'product_name_2_2', label: 'Nome mais descrito do produto (segunda página)'},
    { tag: 'footer_text_2', label: 'Texto de rodapé (segunda página)'},
    { tag: 'total_value_2', label: 'Total do produto (segunda página)'},
    { tag: 'amount_times_2', label: 'Quantidade de parcelas (segunda página)'},
    { tag: 'integer_installment_value_2', label: 'Parte inteira da parcela (segunda página)'},
    { tag: 'decimal_installment_value_2', label: 'Parte decimal (segunda página)'},

    { tag: 'product_name_3_1', label: 'Nome do produto (terceira página)'},
    { tag: 'product_name_3_2', label: 'Nome mais descrito do produto (terceira página)'},
    { tag: 'footer_text_3', label: 'Texto de rodapé (terceira página)'},
    { tag: 'total_value_3', label: 'Total do produto (terceira página)'},
    { tag: 'amount_times_3', label: 'Quantidade de parcelas (terceira página)'},
    { tag: 'integer_installment_value_3', label: 'Parte inteira da parcela (terceira página)'},
    { tag: 'decimal_installment_value_3', label: 'Parte decimal (terceira página)'},


    { tag: 'original_title', label: 'Título original'},
    { tag: 'version_title', label: 'Título versão'},
    { tag: 'duration', label: 'Duração'},
    { tag: 'product', label: 'Produto'},
    { tag: 'advertiser', label: 'Anunciante'},
    { tag: 'agency', label: 'Agência'},
    { tag: 'direction', label: 'Direção'},
    { tag: 'type', label: 'Tipo'},
    { tag: 'segment', label: 'Segmento'},
    { tag: 'crt', label: 'crt'},
    { tag: 'producer', label: 'Produtora'},
    { tag: 'cnpj', label: 'CNPJ'},
    { tag: 'production_year', label: 'Ano de produção'},
    { tag: 'register_date', label: 'Data de registro'},
    { tag: 'audio_producer', label: 'Produtora de áudio'},
    { tag: 'closed_caption', label: 'Closed caption'},
    { tag: 'key_sap', label: 'Tecla SAP'},
  ];

  private visitCardLabels = [
    { tag: 'name', label: 'Nome' },
    { tag: 'jobtitle', label: 'Profissão' },
    { tag: 'cellphone', label: 'Telefone' },
    { tag: 'email', label: 'Email' },
    { tag: 'address1', label: 'Endereço 1' },
    { tag: 'address2', label: 'Endereço 2' },
    { tag: 'address3', label: 'Endereço 3' },
    { tag: 'instagram', label: 'Instagram' },
  ];

  private advertisementMetaInputs: MTUserInputDescriptor[] = [
    ...this.advertisementPhotosLabels.map(text =>
      MagicTemplateGenerator.createMTMetaInput(
        text.label,
        text.tag,
        MTTagInputType.href,
        [
          'advertisement' as MTSkillsType,
        ],
      ),
    ),
    ...this.advertisementTextsLabels.map(text =>
      MagicTemplateGenerator.createMTMetaInput(
        text.label,
        text.tag,
        MTTagInputType.text,
        [
          'advertisement' as MTSkillsType,
        ],
      ),
    )
  ];

  private visitCardMetaInputs: MTUserInputDescriptor[] = [
    ...this.visitCardLabels.map(text =>
      MagicTemplateGenerator.createMTMetaInput(
        text.label,
        text.tag,
        MTTagInputType.href,
        ['visit_card' as MTSkillsType],
      ),
    ),
    ...this.visitCardLabels.map(text =>
      MagicTemplateGenerator.createMTMetaInput(
        text.label,
        text.tag,
        MTTagInputType.text,
        ['visit_card' as MTSkillsType],
      ),
    ),
  ];

  private defaultMetaInputs: MTUserInputDescriptor[] = [
    MagicTemplateGenerator.createMTMetaInput(
      'Segunda imagem',
      'second_image',
      MTTagInputType.href,
      [
        MTSkillsType.bioLink,
        MTSkillsType.profileImage,
        MTSkillsType.instagramFeed,
        MTSkillsType.instagramStory,
        MTSkillsType.logo,
        'advertisement' as MTSkillsType,
        'visit_card' as MTSkillsType,
      ],
    ),
    MagicTemplateGenerator.createMTMetaInput(
      'Terceira imagem',
      'third_image',
      MTTagInputType.href,
      [
        MTSkillsType.bioLink,
        MTSkillsType.profileImage,
        MTSkillsType.instagramFeed,
        MTSkillsType.instagramStory,
        MTSkillsType.logo,
        'advertisement' as MTSkillsType,
        'visit_card' as MTSkillsType,
      ],
    ),
    MagicTemplateGenerator.createMTMetaInput(
      'Quarta imagem',
      'fourth_image',
      MTTagInputType.href,
      [
        MTSkillsType.bioLink,
        MTSkillsType.profileImage,
        MTSkillsType.instagramFeed,
        MTSkillsType.instagramStory,
        MTSkillsType.logo,
        'advertisement' as MTSkillsType,
        'visit_card' as MTSkillsType,
      ],
    ),
  ];

  constructor(
    imageMagicTemplateService: ImageMagicTemplateService,
    gifMagicTemplateService: GifMagicTemplateService,
    textMagicTemplateService: TextMagicTemplateService,
    fontMagicTemplateService: FontMagicTemplateService,
    iconMagicTemplateService: IconMagicTemplateService,
    shapeMagicTemplateService: ShapeMagicTemplateService,
    utilMagicTemplateService: UtilMagicTemplateService,
  ) {
    super (
      iconMagicTemplateService,
      imageMagicTemplateService,
      gifMagicTemplateService,
      shapeMagicTemplateService,
      textMagicTemplateService,
      fontMagicTemplateService,
      utilMagicTemplateService,
    );
  }


  public async getAllMetaInputs(): Promise<MTUserInputDescriptor[]> {
    const allMetaInputs = await super.getAllMetaInputs();
    return allMetaInputs.concat(this.defaultMetaInputs).concat(this.advertisementMetaInputs).concat(this.visitCardMetaInputs);
  }
}
