import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'trakto-result-upload-modal',
  templateUrl: './result-upload-modal.component.html',
  styleUrls: ['./result-upload-modal.component.scss'],
})
export class ResultUploadModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ResultUploadModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  closeModal(tryAgain = false) {
    if (tryAgain) {
      this.dialogRef.close(true);
      return;
    }
    this.dialogRef.close(false);
  }
}
