import { Injectable, OnDestroy } from '@angular/core';
import { PageIMGConversionService } from '@shared/svg-viewer/shared/page-img-conversion.service';
import { ImageElementService } from '@services/image-element.service';
import { PageUtilModel } from '@trakto/core-editor';
import { FileQualityEnum, IDocumentModel, PageModel } from '@trakto/models';
import { ConversionParams } from '@trakto/svg-converter';
import { concat, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Injectable()
export class PageBase64ManagerService implements OnDestroy{
  private timeoutIdByPageId = {};
  private _destroy$ = new Subject<void>();

  constructor(
    private _newImageService: ImageElementService,
    private _pageImgConversionService: PageIMGConversionService,
    private _documentStateManagerService: DocumentStateManagerService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public lazyUpdateBase64(page: PageModel, printable = false): void {
    if (this.timeoutIdByPageId[page.id]) {
      clearTimeout(this.timeoutIdByPageId[page.id]);
    }
    this.timeoutIdByPageId[page.id] = setTimeout(() => {
      delete this.timeoutIdByPageId[page.id];
      this.updateBase64(page, printable).pipe(takeUntil(this._destroy$)).subscribe();
    }, 5000);
  }

  public updateBase64(page: PageModel, printable = false): Observable<string> {
    if (
      this._newImageService.canUploadBackgroundPage(page) ||
      this._newImageService.getNonUploadedImagesByPage(page).length > 0
    ) {
      return of(undefined);
    }
    const conversionParams = ConversionParams.makeConversionParams(
      printable,
      PageUtilModel.getThumbZoomRatio(page),
      '',
      FileQualityEnum.high,
    );
    return this._pageImgConversionService
      .toBase64(page, conversionParams)
      .pipe(tap(base64 => (this._documentStateManagerService.persistPageChangesNoTrackable({ id: page.id, base64 } as PageModel))));
  }
}
