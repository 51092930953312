import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../button/button.module';

import { DropdownMenuHeaderComponent } from './dropdown-menu-header.component';
import { DropdownMenuHeaderItemComponent } from './item/dropdown-menu-header-item.component';

@NgModule({
  declarations: [DropdownMenuHeaderComponent, DropdownMenuHeaderItemComponent],
  imports: [CommonModule, ButtonModule],
  exports: [DropdownMenuHeaderComponent, DropdownMenuHeaderItemComponent],
})
export class DropdownMenuHeaderModule {}
