import { Injectable, OnDestroy } from '@angular/core';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { PageService } from '@app/editor/services/page.service';
import { IDocumentModel, PageModel } from '@trakto/models';
import { ReplaySubject, Subject } from 'rxjs';
import { DocumentStateManagerService } from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentService implements OnDestroy {
  private _document: IDocumentModel;
  private _page: PageModel;

  private _pageSelectedIndex = 0;
  public onPageSelectedIndexChange: Subject<number>;
  private _destroy$ = new Subject<void>();

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateService: DocumentStateManagerService,
    private _pageService: PageService
  ) {
    this.onPageSelectedIndexChange = new ReplaySubject<number>(1);
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  initSubscriptions() {
    this._documentStateService.document$.pipe(takeUntil(this._destroy$)).subscribe(
      doc => (this._document = doc)
    );

    this._documentStateService.page$.subscribe(page => {
      this.findPageIndex(page);
      this._page = page;
    });
  }

  findPageIndex(page: PageModel) {
    this._pageSelectedIndex = this._document.body.findIndex(
      p => p.id === page.id
    );
    this.onPageSelectedIndexChange.next(this._pageSelectedIndex);
  }

  renameDocument(newName: string, document: IDocumentModel) {
    document.title = newName;
    document.is_renamed = true;
  }

  selectPageIndex(index: number) {
    try {
      this._documentManagerService.selectPage(this._document.body[index]);
    } finally {
      this._documentManagerService.selectElement(undefined);
    }
  }

  addPageEnd() {
    this._documentManagerService.addPage(
      this._pageService.cloneClearPage(this._page),
      this._document.body.length + 1
    );
  }

  deletePagesIndexes(indexes: number[]) {
    this._documentManagerService.deletePages(indexes);
  }

  duplicatePagesIndexes(indexes: number[]) {
    this._documentManagerService.clonePages(indexes);
  }

  changePagesPositions(from: PageModel, to: PageModel) {
    this._documentManagerService.changePositions(from, to);
  }
}
