import { LightLoaderComponent } from './light-loader.component';

export class Particle {
  private _x: number;
  private _y: number;
  private _vx: number;
  private _vy: number;
  private _width: number;
  private _height: number;
  private _hue: number;

  constructor(private _lightLoader: LightLoaderComponent) {
    this._x =
      this._lightLoader.loaderX +
      (this._lightLoader.loaded / 100) * this._lightLoader.loaderWidth -
      this._lightLoader.rand(0, 1);
    this._y =
      this._lightLoader.height / 2 +
      this._lightLoader.rand(0, this._lightLoader.loaderHeight) -
      this._lightLoader.loaderHeight / 2;

    this._vx = (this._lightLoader.rand(0, 4) - 2) / 100;
    this._vy =
      (this._lightLoader.rand(0, this._lightLoader.particleLift) -
        this._lightLoader.particleLift * 2) /
      100;
    this._width = this._lightLoader.rand(1, 4) / 2;
    this._height = this._lightLoader.rand(1, 4) / 2;
    this._hue = this._lightLoader.hue;
  }

  public render() {
    this._lightLoader.ctx.fillStyle = '#e7e0ff';
    this._lightLoader.ctx.fillRect(this._x, this._y, this._width, this._height);
  }

  public update(i: number) {
    this._vx += (this._lightLoader.rand(0, 6) - 3) / 100;
    this._vy += this._lightLoader.gravity;
    this._x += this._vx;
    this._y += this._vy;

    if (this._y > this._lightLoader.height) {
      this._lightLoader.particles.splice(i, 1);
    }
  }
}
