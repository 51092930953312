import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { KmlButtonModule } from '@trakto/trakto-kamaleon';
import { MobilePresentationComponent } from './mobile-presentation.component';

import { SharedModule } from '@app/shared/shared.module';
import { SvgViewerModule } from '@app/shared/svg-viewer/svg-viewer.module';
import { SvgEditorModule } from '@editor/components/preview-2/svg-editor/svg-editor.module';
import { LoaderModule } from '@shared/loader/loader.module';
import { ModalModule } from './modal/modal.module';
import { SwipeBoxModule } from './swipe-box/swipe-box.module';

@NgModule({
  declarations: [MobilePresentationComponent],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    KmlButtonModule,
    LoaderModule,
    RouterModule,
    MatSliderModule,
    SvgEditorModule,
    SvgViewerModule,
    ModalModule,
    SwipeBoxModule,
  ],
})
export class MobilePresentationModule {}
