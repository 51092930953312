import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-loader-spinner',
  templateUrl: './loader-spinner.component.html',
})
export class LoaderSpinnerComponent {
  @Input()
  public x: number;

  @Input()
  public y: number;

  @Input()
  zoomRatio: number;

  constructor() {}
}
