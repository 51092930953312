import { Injectable, OnDestroy } from '@angular/core';
import {
  FormatsService,
  IChildFormat,
} from '@app/editor/components/properties-panel/panel-page/services/formats.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { IDocumentModel, PageModel, pageUnitEnum } from '@trakto/models';
import { ReplaySubject, Subject } from 'rxjs';
import { IUnitOptions } from '../components/panel/components/mobile/panel-properties-page-mobile/components/inputs-settings/inputs-settings.component';
import { ZoomService } from './zoom.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

export enum EnumSizeProperty {
  WIDTH = 'width',
  HEIGHT = 'height',
}

const conversions = {
  [pageUnitEnum.cm]: 37.7952755906,
  [pageUnitEnum.mm]: 3.77952755906,
  [pageUnitEnum.inch]: 95.999999998601,
  [pageUnitEnum.px]: 1,
};

@Injectable({
  providedIn: 'root',
})
export class PageService implements OnDestroy {
  private _convertedWidth: number;
  private _convertedHeight: number;
  public onWidthChange: Subject<number>;
  public onHeightChange: Subject<number>;
  public onPageUpdated: Subject<void>;

  public set convertedWidth(value: number) {
    if (value) {
      this.convertSizeWithoutApply(EnumSizeProperty.WIDTH, value);
      this.onWidthChange.next(this.convertedWidth);
    }
  }

  public get convertedWidth(): number {
    return (
      this._convertedWidth ||
      this.getConvertedSize(EnumSizeProperty.WIDTH, this.pageSelected.unit)
    );
  }

  public set convertedHeight(value: number) {
    if (value) {
      this.convertSizeWithoutApply(EnumSizeProperty.HEIGHT, value);
      this.onHeightChange.next(this.convertedHeight);
    }
  }

  public get convertedHeight(): number {
    return (
      this._convertedHeight ||
      this.getConvertedSize(EnumSizeProperty.HEIGHT, this.pageSelected.unit)
    );
  }

  pageSelected: PageModel = null;
  document: IDocumentModel = null;
  private _destroy$ = new Subject<void>();


  constructor(
    private _formatsService: FormatsService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
    private _zoomService: ZoomService,
  ) {
    this.initSubscriptions();
    this.initObservables();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  initSubscriptions() {
    this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(page => {
      this.pageSelected = page;
    });

    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(document => {
      this.document = document;
    });
  }

  initObservables() {
    this.onWidthChange = new ReplaySubject<number>(1);
    this.onHeightChange = new ReplaySubject<number>(1);
    this.onPageUpdated = new ReplaySubject<void>(1);
  }

  convertSizeWithoutApply(property: EnumSizeProperty, value: number): void {
    const convertedSize = parseFloat(value.toFixed(2));

    if (property === EnumSizeProperty.HEIGHT)
      this._convertedHeight = convertedSize;
    if (property === EnumSizeProperty.WIDTH)
      this._convertedWidth = convertedSize;

    this.pageSelected[property] =
      value * conversions[this.pageSelected.unit] || 0;
  }

  getConvertedSize(property: EnumSizeProperty, unit: pageUnitEnum): number {
    const originalSize = this.pageSelected[property];
    const division = conversions[unit] || 1;

    return parseFloat((originalSize / division).toFixed(2));
  }

  async getAllFormats(): Promise<IChildFormat[]> {
    const formats = await this._formatsService.getOrdenedFormats();

    let allFormats = [];

    formats.forEach(parent => {
      allFormats.push(...parent.formats);
    });

    return allFormats;
  }

  getPageSelectedFormat(): string | null {
    return this.pageSelected.format?.id || null;
  }

  async changePageFormat(format: IChildFormat): Promise<boolean> {
    const formatData = await this._formatsService.getFormat(format.id);

    this.pageSelected.format = { ...formatData };
    //aplicar formato corretamente
    this.pageSelected.lockSize = false;

    const { width, height, unit } = format.canva_dimension;
    this.pageSelected.unit = unit as pageUnitEnum;
    this.convertedWidth = width;
    this.convertedHeight = height;

    this.changeProperty('width', 'height');
    this.onPageUpdated.next();

    return true;
  }

  changeProperty(...args: string[]): void {
    args.forEach(property => {
      this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
        elementId: this.pageSelected.id,
        key: property,
        value: this.pageSelected[property],
        obj: this.pageSelected,
      });
    });

    this._zoomService.fitPage();
  }

  async changePageDimensions(event: {
    property: EnumSizeProperty;
    value: string;
  }): Promise<boolean> {
    const { property, value } = event;

    if (value === null || value === undefined) {
      return false;
    }

    const size = parseInt(value, 10);

    if (this.pageSelected.lockSize) {
      switch (property) {
        case 'width':
          this.convertedHeight *= size / this.convertedWidth;
          this.convertedWidth = size;
          break;
        case 'height':
          this.convertedWidth *= size / this.convertedHeight;
          this.convertedHeight = size;
          break;
      }

      this.changeProperty('width', 'height');
    } else {
      switch (property) {
        case 'width':
          this.convertedWidth = size;
          break;
        case 'height':
          this.convertedHeight = size;
          break;
      }

      this.changeProperty(property);
    }

    this.pageSelected.format = null;
  }

  toggleLockPageProportion() {
    this.pageSelected.lockSize = !this.pageSelected.lockSize;
  }

  async invertPageDimensions(): Promise<boolean> {
    const width = this.convertedWidth;
    const height = this.convertedHeight;

    this.convertedWidth = height;
    this.convertedHeight = width;

    this.pageSelected.format = null;
    this.changeProperty('width', 'height');

    return true;
  }

  changePageDimensionsUnit(unit: IUnitOptions) {
    this.pageSelected.unit = unit.value;

    switch (this.pageSelected.unit) {
      case pageUnitEnum.cm:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.cm,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.cm,
        );
        break;
      case pageUnitEnum.mm:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.mm,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.mm,
        );
        break;
      case pageUnitEnum.inch:
        this.convertedWidth = this.getConvertedSize(
          EnumSizeProperty.WIDTH,
          pageUnitEnum.inch,
        );
        this.convertedHeight = this.getConvertedSize(
          EnumSizeProperty.HEIGHT,
          pageUnitEnum.inch,
        );
        break;
      default:
        this.convertedWidth = this.pageSelected.width;
        this.convertedHeight = this.pageSelected.height;
        break;
    }

    this.changeProperty('unit');
  }

  changeAllPageSizes() {
    this.document.body.map((page: PageModel) => {
      page.width = this.pageSelected.width || 0;
      page.height = this.pageSelected.height || 0;

      if (page.lockSize) {
        page.lockSize = this.pageSelected.lockSize || null;
      }

      if (this.pageSelected.unit) {
        page.unit = this.pageSelected.unit;
      }

      if (this.pageSelected.format) {
        page.format = this.pageSelected.format;
      }
    });
  }
}
