import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { ModalService } from './modal.service';

@Component({
  selector: 'trakto-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  private _id: string;
  private _selectedElement: string;
  private _allowMinimize: boolean;
  private _allowClose: boolean;
  private _maximized: boolean;
  private _borderHeight: string;
  private _sideNavWidth: string;
  private _element: HTMLElement;
  private _config: any;

  @Input() customModal: boolean;

  @Input()
  public set id(value: string) {
    this._id = value;
  }

  public get id(): string {
    return this._id;
  }

  @Input()
  public set selectedElement(value: string) {
    this._selectedElement = value;
  }

  public get selectedElement(): string {
    return this._selectedElement;
  }

  @Input()
  public set allowMinimize(value: boolean) {
    this._allowMinimize = value;
  }

  public get allowMinimize(): boolean {
    return this._allowMinimize !== false ? true : false;
  }

  @Input()
  public set allowClose(value: boolean) {
    this._allowClose = value;
  }

  public get allowClose(): boolean {
    return this._allowClose !== false ? true : false;
  }

  public set maximized(value: boolean) {
    this._maximized = value;
  }

  public get maximized(): boolean {
    return this._maximized || false;
  }

  public set borderHeight(value: string) {
    this._borderHeight = value;
  }

  public get borderHeight(): string {
    return this._borderHeight || '';
  }

  public set sideNavWidth(value: string) {
    this._sideNavWidth = value;
  }

  public get sideNavWidth(): string {
    return this._sideNavWidth || '';
  }

  @Output() onMaximize: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMinimize: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private _elementRef: ElementRef,
  ) {
    this._element = this._elementRef.nativeElement;
    this._element.style.display = 'none';
    this.maximized = false;

    this._config = {
      classes: {
        modal: '.trakto-modal',
        header: '.trakto-header',
        preview: '.trakto-preview',
        panel: '.trakto-panel',
        toolbar: '.trakto-toolbar',
        overlay: '.trakto-modal__overlay',
      },
    };
  }

  ngOnInit(): void {
    const modal = this;

    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    document.body.appendChild(this._element);

    this._element.addEventListener('click', (event: MouseEvent) => {
      if (event.target['className'] === 'trakto-modal__overlay') {
        modal.close();
      }
    });

    this.modalService.add(modal);
  }

  ngAfterContentInit() {
    const hasInput = document
      .querySelector(`#${this.id} .trakto-modal__input`)
      .hasChildNodes();
    const hasSideNav = document
      .querySelector(`#${this.id} .trakto-modal__side-nav`)
      .hasChildNodes();

    if (!hasInput) {
      this.borderHeight = '0';
    }

    if (!hasSideNav) {
      this.sideNavWidth = '0';
      this._setAttribute(
        `#${this.id} ${this._config.classes.modal} > .--anchor`,
        'background-color',
        'white',
      );
    }
  }

  ngOnChanges() {
    if (!!this.selectedElement) {
      const { top, height } = document
        .getElementById(`${this.selectedElement}`)
        .getBoundingClientRect();

      if (!!top) {
        const modalDistanceTop = 100;
        const anchorHeight = 20;

        const topValue = top - modalDistanceTop + (height - anchorHeight) / 2;
        this._setAttribute(
          `#${this.id} ${this._config.classes.modal} > .--anchor`,
          'top',
          `${topValue}px`,
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this._element.remove();
  }

  open(): void {
    this._element.style.display = 'block';

    this._setBlur(this._config.classes.header, 2);
    this._setBlur(this._config.classes.preview, 2);
    this._setBlur(this._config.classes.panel, 2);

    this._addClass(`#${this.id} ${this._config.classes.modal}`, '--open');
    this._setAttribute(
      `#${this.id} ${this._config.classes.modal}.--open > .--anchor`,
      'display',
      'block',
    );

    this._setZIndex(`${this._config.classes.header}`, 90);
    this._setZIndex(`#${this.id} ${this._config.classes.overlay}`, 99);

    this.onOpen.emit();
  }

  close(): void {
    this.maximized = false;
    this._element.style.display = 'none';

    this._setBlur(this._config.classes.header, 0);
    this._setBlur(this._config.classes.preview, 0);
    this._setBlur(this._config.classes.panel, 0);
    this._setBlur(this._config.classes.toolbar, 0);

    this._removeClass(
      `#${this.id} ${this._config.classes.modal}`,
      '--maximize',
    );
    this._removeClass(`#${this.id} ${this._config.classes.modal}`, '--open');

    this._setZIndex(`${this._config.classes.header}`, 101);
    this._setZIndex(`#${this.id} ${this._config.classes.overlay}`, '');

    this.onClose.emit();
  }

  maximize(): void {
    this.maximized = true;

    this._setBlur(this._config.classes.toolbar, 2);

    this._addClass(
      `#${this.id} ${this._config.classes.modal}.--open`,
      '--maximize',
    );
    this._setAttribute(
      `#${this.id} ${this._config.classes.modal}.--open > .--anchor`,
      'display',
      'none',
    );

    this._setZIndex(`#${this.id} ${this._config.classes.overlay}`, 149);

    this.onMaximize.emit();
  }

  minimize(): void {
    this.maximized = false;

    this._setBlur(this._config.classes.toolbar, 0);

    this._removeClass(
      `#${this.id} ${this._config.classes.modal}.--open`,
      '--maximize',
    );
    this._setAttribute(
      `#${this.id} ${this._config.classes.modal}.--open > .--anchor`,
      'display',
      'block',
    );

    this._setZIndex(`#${this.id} ${this._config.classes.overlay}`, '');

    this.onMinimize.emit();
  }

  _addClass(selector: string, cssClass: string): void {
    document.querySelector(selector).classList.add(`${cssClass}`);
  }

  _removeClass(selector: string, cssClass: string): void {
    document.querySelector(selector).classList.remove(`${cssClass}`);
  }

  _setBlur(selector: string, quantity: number): void {
    (document.querySelector(selector) as HTMLElement).style.filter =
      quantity > 0 ? `blur(${quantity}px)` : `none`;
  }

  _setZIndex(selector: string, quantity: number | string): void {
    (
      document.querySelector(selector) as HTMLElement
    ).style.zIndex = `${quantity}`;
  }

  _setAttribute(selector: string, attribute: string, status: number | string) {
    const element = document.querySelector(selector) as HTMLElement;

    if (!!element) {
      element.style[`${attribute}`] = `${status}`;
    }
  }
}
