import { Component, OnDestroy, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { IDocumentModel, PageModel } from '@trakto/models';
import { DownloadService } from '@app/editor-v3/services/download-service.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-panel-download-mobile',
  templateUrl: './panel-download-mobile.component.html',
  styleUrls: [ './panel-download-mobile.component.scss' ]
})
export class PanelDownloadMobileComponent implements OnInit, OnDestroy {

  public loading = false;
  public error = false;

  public downloadTarget: 'png' | 'pdf' | 'mp4' = 'png';
  public startPage = 1;
  public endPage = 0;
  public quality: 'medium' | 'raw' | 'premium' = 'medium';
  public pageSelectionType: 'current' | 'all' | 'selected' = 'all';

  public document: IDocumentModel;
  public selectedPage: IDocumentModel;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _panelService: PanelService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _downloadService: DownloadService,
  ) {
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._documentStateManagerService.document$.subscribe(document => {
        this.document = document;
        this.endPage = this.document.body.length;
      })
    );
    this._subscriptions.push(
      this._documentStateManagerService.page$.subscribe(page => {
        this.selectedPage = page;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(su => su.unsubscribe());
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  selectTab(file:  'png' | 'pdf' | 'mp4') {
    this.downloadTarget = file;
  }

  async downloadFile() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.error = false;
    try {
      await this._downloadFile();
    } catch (e) {
      this.error = true;
      setTimeout(() => this.error = false, 3000);
    }
    this.loading = false;
  }
  async _downloadFile() {
    switch (this.downloadTarget) {
      case 'pdf':
        let range = this.pageSelectionType === 'current' ? [] : [ 1, this.document.body.length ];
        if (this.pageSelectionType === 'selected') {
          range = [ +this.startPage, +this.endPage ];
        }
        await this._downloadService.saveAsPdf(this.quality, range);
        break;
      case 'mp4':
        await this._downloadService.saveAsVideo();
        break;
      case 'png':
        await this._downloadService.saveAsPng(this.quality);
        break;
    }
  }
}
