import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from '@app/editor-v3/services/user.service';
import { TraktoUser } from '@auth/shared/auth.model';
import { FirebaseService } from '@shared/firebase-client/firebase.service';
import { elementTypeEnum } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GalleryFavoritesRepository implements OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    private _firebaseService: FirebaseService,
    private _userService: UserService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public listFavorite(
    type: elementTypeEnum,
    limit: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const appRef = this._firebaseService.firestoreModule
        .collection('app')
        .doc(this._userService.user.app_reference.id)
        .ref;
      this._firebaseService.firestoreModule
        .collection('gallery', ref =>
          ref
            .where('app_reference', '==', appRef)
            .where('category', '==', type)
            .where('is_deleted', '==', false)
            .orderBy('created_at', 'desc'),
        )
        .get()
        .pipe(takeUntil(this._destroy$))
        .subscribe(snapshot =>
          resolve(
            this._processFavoriteList(snapshot.docChanges(), limit + 1),
          ),
        );
    });
  }

  public findMoreFavorites(
    type: elementTypeEnum,
    limit: number,
    startAfter: any,
  ): Promise<any> {
    return new Promise((resolve) => {
      this._firebaseService.firestoreModule
        .collection('gallery', ref =>
          ref
            .where('app_reference', '==', this._userService.user.app_reference)
            .where('category', '==', type)
            .where('is_deleted', '==', false)
            .orderBy('created_at', 'desc'),
        )
        .get()
        .pipe(takeUntil(this._destroy$))
        .subscribe(snapshot =>
          resolve(
            this._processFavoriteList(
              snapshot.docChanges(),
              limit + 1,
              startAfter,
            ),
          ),
        );
    });
  }

  private _processFavoriteList(
    firebaseResult,
    limit,
    startAfter?: string,
  ) {
    const result = firebaseResult.map(data => {
      const docData = data.doc.data();
      return {
        id: data.doc.id,
        ...docData,
      };
    });
    const filtered = this._filterByUserProducts(result, this._userService.user);
    let start = 0;
    let end = limit;
    if (startAfter) {
      start = 1 + filtered.findIndex(d => d.id === startAfter);
      end += start;
    }
    return filtered.slice(start, end);
  }

  private _filterByUserProducts(data, user: TraktoUser): any[] {
    const productsIds = user.products.map(
      userProduct => userProduct.id,
    );
    return data.filter(entry => {
      return (
        entry.products &&
        entry.products.some(product =>
          productsIds.includes(product.app_product_reference.id),
        )
      );
    });
  }

}
