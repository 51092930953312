import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { getColor } from '@app/editor/shared/colors.util';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';

@Component({
  selector: 'trakto-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent implements OnChanges {
  @Output() filterTag: EventEmitter<ILocaleTag> =
    new EventEmitter<ILocaleTag>();

  @Input() tags: ILocaleTag[] = [];
  @Input() currentTag: ILocaleTag;
  @Input() currentPanel: SearchTypeEnum;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags?.currentValue) {
      this.setTags();
    }
  }

  /**
   * Ao selecionar alguma tag, evento de seleção é acionado.
   *
   * @param tag string - Tag selecionada
   */
  selectTag(tag: ILocaleTag = null): void {
    this.compareCurrentTag(tag)
      ? this.filterTag.emit(null)
      : this.filterTag.emit(tag);
  }

  /**
   * Centraliza a comparação entre
   * tag nova e tag vigente na pesquisa
   *
   * @param tag string | null - Nova tag definida para pesquisa
   * @returns boolean - Se houve alteração de tag
   */
  compareCurrentTag(tag: ILocaleTag | null): boolean {
    return tag?.id === this.currentTag?.id || tag === null;
  }

  /**
   * Define a lista de tags no componente,
   * e caso no estado do painel contenha
   * alguma tag prévia, ela é selecionada
   */
  setTags() {
    if (this.currentTag?.name?.length > 0) {
      this.selectTag(this.currentTag);
    }
  }

  getColor(idx: number): string {
    return getColor(idx);
  }
}
