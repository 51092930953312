import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { LocaleService } from '@app/editor/services/locale.service';
import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { environment } from '@env/environment';
import { Base64CacheService } from '@shared/base64-cache/base64-cache.service';
import { TraktoUser } from './auth/shared/auth.model';
import {
  GoSquaredService
} from './editor/services/gosquared/gosquared.service';
import { ChatService } from './shared/chat/chat.service';
import { OnboardingService } from './shared/onboading/onboarding.service';
import FacebookPixelService
  from './editor/services/analytics/facebook/facebookPixel.service';
import { PlatformLoadingService } from '@services/platform-loading.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import {
  WhitelabelProductService
} from '@services/whitelabel-config/whitelabel-product.service';

@Component({
  selector: 'trakto-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  public initializing = false;
  private _currentUser: TraktoUser;
  private _destroy$ = new Subject<void>();

  constructor(
    private _localeService: LocaleService,
    private _base64CacheService: Base64CacheService,
    private _hotkeyService: HotkeyService,
    private _goSquaredService: GoSquaredService,
    private _authService: AuthService,
    private _userService: UserService,
    private _authFirebaseService: AuthFirebaseService,
    private _whitelabelProductService: WhitelabelProductService,
    private _ngZone: NgZone,
    @Inject(PLATFORM_ID) private _platformId: string,
    private _onboardingService: OnboardingService,
    private _chatService: ChatService,
    private _router: Router,
    private _fbTrack: FacebookPixelService,
    private _platformLoadingService: PlatformLoadingService,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async ngOnInit() {
    this.initializing = !this._authService.isEmbeddedInstance();

    await this._startApp();

    this.initializing = false;

    this._setupOnboard();

    if (environment.externalIntegrations.facebookPixel) {
      this._fbTrack.init().then();
    }
  }

  private async _startApp(): Promise<void> {
    if (!isPlatformBrowser(this._platformId)) {
      return null;
    }
    return new Promise((resolve, reject) => {
      const sub = this._authFirebaseService.getCurrentTokenId().subscribe(async (idToken) => {
        if (!!idToken) {
          sub.unsubscribe();
          await this._doStartApp();
          resolve()
        }
      })
    })
  }
  private async _doStartApp(): Promise<void> {
    await this._userService.init();
    await this._platformLoadingService.init();
    await this._whitelabelProductService.init();
    this._base64CacheService.clearIndexedDB();
    this._localeService.init();

    if (environment.externalIntegrations.gosquared) {
      this._goSquaredService.configure(
        environment.api.goSquared.projectToken,
        environment.production,
      );
      this._goSquaredService.showAssistant(false);
    }
  }

  /**
   * Setup onboard platform
   */
  private _setupOnboard(): void {
    this._router.events.pipe(
      takeUntil(this._destroy$),
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url.toString();
        this._userService.getProfile().subscribe(user => {
          this._currentUser = user;
          this._setupChat();
          this._onboardingService.configOnboarding(window, this._currentUser)
            .then(() => {
              setTimeout(() => {
                this._onboardingService.updateOnboardingInfo(window, currentUrl);
              }, 1000);
            });
        });
      }
    });
  }

  /**
   * Setup chat platform
   */
  private _setupChat(): void {
    this._chatService.configChat(this._currentUser);
  }

  /**
   * Used to listen the related events from hotkeys
   * @param event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this._hotkeyService.setCombination(event);
  }

  /**
   * Used to listen the related events from mouse click
   * @param event
   */
  @HostListener('document:click', ['$event'])
  handleClick(event: KeyboardEvent) {
    this._hotkeyService.setCombination(event);
  }
}
