import { Component, Input, OnInit } from '@angular/core';

const IMAGE_BACKGROUND_DEFAULT = '/assets/images/links-background-qrcode.svg';

@Component({
  selector: 'trakto-qrcode-download',
  templateUrl: './qrcode-download.component.html',
  styleUrls: ['./qrcode-download.component.scss']
})
export class QrcodeDownloadComponent implements OnInit {
  @Input() profileImage = '';
  @Input() qrcodeLink = '';
  @Input() qrcodeName = '';
  @Input() isB2C;
  @Input() mainLink;

  public imageBackground = IMAGE_BACKGROUND_DEFAULT;

  constructor() {}

  ngOnInit(): void {}

}
