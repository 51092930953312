import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { IButtonConfig } from './button.model';

const BUTTON_HOST_MODIFIERS = [
  'button--icon',
  'button--icon-medium',
  'button--icon-large',
  'button--loader',
  'button--bold',
  'button--outline',
  'button--accent',
  'button--sample',
  'searchInput',
  'button--nopadding',
  'button--outline--modify',
  'button--shadow',
  'button--fill',
  'button--light',
  'button--primary',
  'button--secundary',
  'button--small',
  'button--inline',
  'button--inverse',
  'button--special',
  'button--white',
  'button--white-hover',
  'button--plan-renewal',
  'button--remove-filter',
  'button-font',
  'button--download',
  'button--dropbox',
  'button--opacity',
  'button--paddin-link',
  'button--margin',
  'button--medium',
  'button--large',
  'button--xxlarge',
];

@Component({
  selector: 'trakto-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit, OnChanges {
  private _type: string;
  private _disabled: boolean;

  @Input() config: IButtonConfig;
  @Input() minWidth: number;
  @Input() isLoading: boolean;
  @Input() loaderDiameter = 28;
  @Input() loaderSize = 16;
  @Input() loaderColor = 'white';

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || null;
  }

  @Input()
  public set type(type: string) {
    this._type = type;
  }

  public get type(): string {
    return this._type || 'button';
  }

  hasLoader: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this._setHostModifiers(BUTTON_HOST_MODIFIERS);
  }

  ngOnChanges() {
    this._getHostElement().children[0].disabled = this.disabled ? true : false;
  }

  _getHostElement(): any {
    return this._elementRef.nativeElement;
  }

  _hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some(attribute =>
      this._getHostElement().hasAttribute(attribute),
    );
  }

  _setHostModifiers(HOST_MODIFIERS): void {
    const element: HTMLElement = this._getHostElement()
      .children[0] as HTMLElement;

    HOST_MODIFIERS.map(attribute => {
      if (this._hasHostAttributes(attribute)) {
        element.classList.add(attribute);
      }

      if (this.config && this.config.classes) {
        this.config.classes.map(classItem => element.classList.add(classItem));
      }
    });

    this.hasLoader = this._hasHostAttributes('button--loader');
  }

  press(event): void {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }
}
