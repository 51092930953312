import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';

@Injectable({
  providedIn: 'root',
})
export class TraktoApiService {

  constructor(
    private _authFirebaseService: AuthFirebaseService,
    private _httpClient: HttpClient,
  ) {
  }

  async executeTemplateApiGet<E>(url: string):  Promise<E> {
    const httpOptions = await this._makeHttpOptions();
    return this._httpClient.get<E>(`${environment.api.templateUrl}${url}`, httpOptions).toPromise();
  }

  async executeTemplateApiPost<E>(url: string, body: any, isJson = true):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient.post<E>(`${environment.api.templateUrl}${url}`, body, httpOptions).toPromise();
  }

  async executeTemplateApiDelete<E>(url: string, isJson = true):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient.delete<E>(`${environment.api.templateUrl}${url}`, httpOptions).toPromise();
  }

  async executeBellaGet<E>(url: string):  Promise<E> {
    const httpOptions = await this._makeHttpOptions();
    return this._httpClient.get<E>(`${environment.api.bellaUrl}/${url}`, httpOptions).toPromise();
  }

  async executeBellaPost<E>(url: string, body: any, isJson = true):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient.post<E>(`${environment.api.bellaUrl}/${url}`, body, httpOptions).toPromise();
  }

  async executeGet<E>(url: string, params?: any, ignoreAuthToken = false):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(true, params, ignoreAuthToken);
    return this._httpClient.get<E>(`${environment.api.baseUrl}${url}`, httpOptions).toPromise();
  }

  async executePost<E>(url: string, body: any, isJson = true):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient.post<E>(`${environment.api.baseUrl}${url}`, body, httpOptions).toPromise();
  }

  async executePut<E>(url: string, body: any, isJson = true):  Promise<E> {
    const httpOptions = await this._makeHttpOptions(isJson);
    return this._httpClient.put<E>(`${environment.api.baseUrl}${url}`, body, httpOptions).toPromise();
  }

  async executeDelete<E>(url: string):  Promise<E> {
    const httpOptions = await this._makeHttpOptions();
    return this._httpClient.delete<E>(`${environment.api.baseUrl}${url}`, httpOptions).toPromise();
  }

  private async _makeHttpOptions(isJson = true, params?: any, ignoreAuthToken = false): Promise<{ headers: HttpHeaders }> {
    const token = await this._authFirebaseService.getAccessToken();
    if (!token && !ignoreAuthToken) {
      throw new Error('Token is not defined');
    }
    const headers = {
    };
    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
    }
    if (isJson) {
      headers['Content-Type'] = 'application/json'
    }
    const httpOptions: { headers: HttpHeaders, params?: any } = {
      headers: new HttpHeaders(headers),
    };
    if (params) {
      httpOptions.params = params;
    }
    return httpOptions;
  }


}
