import { Component, Input, OnInit } from '@angular/core';
import { selectCurrentStep, selectTotalSteps } from '@app/editor/components/properties-panel/panel-publish/store/stepper.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'trakto-stepper-progress',
  templateUrl: './stepper-progress.component.html',
  styleUrls: ['./stepper-progress.component.scss']
})
export class StepperProgressComponent implements OnInit {

  @Input() color: string;

  fixedWidth: number = 25;
  isProgressFixed: boolean = false;

  currentStep$: Observable<number> = this.store.select(selectCurrentStep);
  totalSteps$: Observable<number> = this.store.select(selectTotalSteps);
  fillWidth$: Observable<number> = this.totalSteps$.pipe(
    switchMap(totalSteps => this.currentStep$.pipe(
      map(currentStep => {
        if (currentStep === 1 ) {
          return this.fixedWidth;
        } else {
          const variableSteps = totalSteps - 1;
          const variableStepWidth = (100 - this.fixedWidth) / variableSteps;
          return this.fixedWidth + (variableStepWidth * (currentStep - 1));
        }
      })
    ))
  );

  constructor(
    private store: Store,
  ) {}

  ngOnInit(): void {}

}
