<section class="trakto-dropdown-content">
  <trakto-button [disabled]="disabled" (click)="toggle()" [config]="button">
    <i *ngIf="icon" [class]="icon" [style.fontSize.rem]="iconSize"></i>
    <span *ngIf="title">{{ title }}</span>
    <i *ngIf="caret" class="trakto-icon-arrow-drop-down"></i>
  </trakto-button>

  <div class="trakto-dropdown-content__container" [style.width.px]="width">
    <ng-content></ng-content>
  </div>
</section>
