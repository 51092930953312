import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSVGConversionService } from '@shared/svg-viewer/shared/icon-svg-conversion.service';
import { ImageSVGConversionService } from '@shared/svg-viewer/shared/image-svg-conversion.service';
import { MediaCacheService } from '@shared/svg-viewer/shared/media-cache.service';
import { PageBase64ManagerService } from '@shared/svg-viewer/shared/page-base64-manager.service';
import { PageIMGConversionService } from '@shared/svg-viewer/shared/page-img-conversion.service';
import { PagePDFConversionService } from '@shared/svg-viewer/shared/page-pdf-conversion.service';
import { PageSVGConversionService } from '@shared/svg-viewer/shared/page-svg-conversion.service';
import { ShapeSVGConversionService } from '@shared/svg-viewer/shared/shape-svg-conversion.service';
import { TextSVGConversionService } from '@shared/svg-viewer/shared/text-svg-conversion.service';
import { ThumbService } from '@shared/svg-viewer/shared/thumb.service';
import { YoutubeSVGConversionService } from '@shared/svg-viewer/shared/youtube-svg-conversion.service';
import { SvgViewerComponent } from './svg-viewer.component';
import {
  VideoSVGConversionService
} from '@shared/svg-viewer/shared/video-svg-conversion.service';

@NgModule({
  imports: [CommonModule],
  declarations: [SvgViewerComponent],
  exports: [SvgViewerComponent],
  providers: [
    IconSVGConversionService,
    ImageSVGConversionService,
    PageSVGConversionService,
    PageIMGConversionService,
    ShapeSVGConversionService,
    TextSVGConversionService,
    YoutubeSVGConversionService,
    VideoSVGConversionService,
    MediaCacheService,
    ThumbService,
    PageBase64ManagerService,
    PagePDFConversionService,
  ],
})
export class SvgViewerModule {}
