import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigService,
  EnumModalComponent,
  TraktoPremiumService,
} from '@trakto/trakto-premium';

import { PlanConfigService } from '@app/shared/subscription/plan-config.service';
import { GoSquaredService } from './gosquared/gosquared.service';
import { HotkeyService } from './hotkeys/hotkeys.service';
import { WhitelabelProductService } from './whitelabel-config/whitelabel-product.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { IUser } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PremiumService implements OnDestroy {

  private preview_key_context = 'previewkeys';
  private _destroy$ = new Subject<void>();

  constructor(
    private _productService: WhitelabelProductService,
    private _configService: ConfigService,
    private _userService: UserService,
    private _translateService: TranslateService,
    private _planConfigService: PlanConfigService,
    private _hotkeyService: HotkeyService,
    private _traktoPremiumService: TraktoPremiumService,
    private _goSquaredService: GoSquaredService,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Configure trakto premium and send to it the fundamental data
   */
  public async configure() {
    const user = this._userService.user;
    const plans = await this._getPlans();
    this._configService.setUser(user as unknown as IUser);
    this._configService.setCurrentProductId(
      this._productService.currentProductId,
    );
    this._configService.setPlan(plans);
    this._configService.setCallSupport(
      this._goSquaredService.startConversation,
    );

    return true;
  }

  /**
   * openPremium
   */
  public openPremiumCheckout() {
    const modal = this._traktoPremiumService.premiumModal(
      EnumModalComponent.MODAL_PLANS,
    );
    this.setOpenFunction();
    modal.afterClosed.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.setCloseFunction();
    });
  }

  public getCheckoutConfig(): Promise<any> {
    const checkoutParams = environment.api.checkout;
    const user = this._userService.user;
    return new Promise<any>((resolve, reject) => {
      const data = {
        modal: 'trakto-link', 
        name: `${user.firstname} ${user?.lastname}`, 
        email: user?.email,
        checkout_url: `${checkoutParams.prefix}?${checkoutParams.name_param}=${user?.firstname || ''}%20${user?.lastname || ''}&${checkoutParams.email_param}=${user?.email || ''}`
      };
      resolve(data);
    });
  }

  /**
   * openModalBlocking
   */
  public openModalBlocking(type: EnumModalComponent, data?: any) {
    let config = {
      paywallType: type,
      paywallConfigs: null
    };

    if (data) {
      config.paywallConfigs = {
        data: data
      }
    }

    const modal = this._traktoPremiumService.premiumModal(
      EnumModalComponent.MODAL_BLOCKING,
      config,
    );

    this.setOpenFunction();
    modal.afterClosed.pipe(takeUntil(this._destroy$)).subscribe(data => {
      // this.setOpenFunction()
      this.setCloseFunction();
    });
  }

  /**
   * openRemoveBGBlocking
   */
  public openModalBlockingRemoveBG(bgLimits, data: any = null, callBack: () => 0) {

    let configData = {
      removeBgCount: bgLimits.total,
      remainingRemoveBg: bgLimits.remaining,
    };

    Object.assign(configData, data);

    const modal = this._traktoPremiumService.premiumModal(
      EnumModalComponent.MODAL_BACKGROUND_REMOVAL,
      {
        paywallConfigs: {
          data: configData,
        },
      },
    );

    this.setOpenFunction();
    modal.afterClosed.pipe(takeUntil(this._destroy$)).subscribe(data => {
      // this.setOpenFunction()
      this.setCloseFunction();
      if (data) {
        callBack();
      }
    });
  }

  // NOTE: Move to PlanService
  /**
   * Get plan list from Stripe with environment data
   */
  private async _getPlans() {
    const plans = [];

    try {
      const _genPlanPrice = (price: any) =>
        price.toFixed(2).toString().replace('.', ',');
      const currentLang = this._translateService.currentLang;
      const strypeIdPlans = environment.api.stripe.plans[currentLang];
      const response = await this._planConfigService.listPlans();

      plans.push(
        response.find((plan: any) => plan.id === strypeIdPlans.monthlyPlanId),
      );
      plans.push(
        response.find((plan: any) => plan.id === strypeIdPlans.yearlyPlanId),
      );

      plans[0]['planPrice'] = _genPlanPrice(plans[0].amount);
      plans[1]['planPrice'] = _genPlanPrice(plans[1].amount);
      plans[1]['planPricePerMonth'] = _genPlanPrice(plans[1].amount);
    } catch (error) {
      console.error(error);
    }

    this.setOpenFunction();
    this.setCloseFunction();

    return plans;
  }

  private setOpenFunction() {
    this.disableHotKeys();
  }

  private setCloseFunction() {
    this.enableHotKeys();
  }

  private disableHotKeys() {
    this._hotkeyService.disableHotkeys();
    this._hotkeyService.disableContext(this.preview_key_context);
    this._hotkeyService.disableContext('toolbarkeys');
  }

  private enableHotKeys() {
    this._hotkeyService.enableHotkeys();
    this._hotkeyService.enableContext(this.preview_key_context);
    this._hotkeyService.enableContext('toolbarkeys');
  }
}
