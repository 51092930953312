import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { IThemeStorageModel } from '@trakto/models';

import { environment } from '@env/environment';
import { ProductRepository } from '../repository/product.repository';
import { CookieLoaderService } from './cookie-loader.service';
import { UserService } from '@app/editor-v3/services/user.service';

const TRAKTO_DEFAULT_LOGO = '/assets/images/logo-trakto-orange-mini.svg';

@Injectable({ providedIn: 'root' })
export class PlatformLoadingService {
  private _renderer2: Renderer2;
  private _isLoading = false;
  private _showOrHide = true;
  private _logo = './assets/images/logo-trakto-orange-mini.svg';
  private _loader: any = {};

  public get hasLoader(): HTMLElement {
    return this._DOM.getElementById('tkt-loader');
  }

  public set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public set logo(logo: string) {
    this._logo = logo;
  }

  public get logo(): string {
    return this._logo;
  }

  public themeTrakto: IThemeStorageModel = {
    title: 'B2C',
    logo: {
      black: './assets/images/logo-trakto-orange-mini.svg',
      grey: './assets/images/logo-trakto-orange-mini.svg',
      colored: './assets/images/logo-trakto-orange-mini.svg',
    },
    color: {
      primary: '#0079ff',
      secondary: '#0079ff',
      third: '#005ec7',
      quaternary: '#66afff',
      background_logo: '#0079ff',
    },
    loader: {
      main_color: '#0079ff',
      background_color: '#f0f7ff',
    },
  };

  public themeEmbed: IThemeStorageModel = {
    title: 'Generic',
    logo: {
      black: './assets/images/generic-logo-embed.svg',
      grey: './assets/images/generic-logo-embed.svg',
      colored: './assets/images/generic-logo-embed.svg',
    },
    color: {
      primary: '#0079ff',
      secondary: '#0079ff',
      third: '#005ec7',
      quaternary: '#66afff',
      background_logo: '#0079ff',
    },
  };

  constructor(
    @Inject(DOCUMENT) private _DOM: Document,
    @Inject(PLATFORM_ID) private _platformId: string,
    private _renderer: RendererFactory2,
    private _userService: UserService,
    private _productRepository: ProductRepository,
    private _cookieLoaderService: CookieLoaderService,
  ) {
    if (isPlatformBrowser(this._platformId)) {
      this._renderer2 = _renderer.createRenderer(null, null);
    }
  }

  private _getLogoProduct(themeCookie): string {
    const defaultLogo =
      environment.userProvider === 'sdk' ||
      environment.userProvider === 'hosted'
        ? ''
        : TRAKTO_DEFAULT_LOGO;
    const logo =
      themeCookie && themeCookie !== undefined
        ? themeCookie.logo?.low.url
        : defaultLogo;
    return logo;
  }

  public _initializeStyleInHead(theme: IThemeStorageModel): void {
    // Used for whitelabels and integrated applications
    const defaultTheme =
      environment.userProvider === 'sdk' ||
      environment.userProvider === 'hosted'
        ? this.themeEmbed
        : this.themeTrakto;
    const keyframes = `
    .tkt-loader__spinner-box {
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
    }

    .tkt-loader__spinner-box img {
      width: 23px;
      height: 23px;
      position: absolute;
      animation: pulsate 2.1s cubic-bezier(0.390000,0.575000,0.565000,1) infinite normal forwards;
    }

    .tkt-loader__circle-border {
      width: 60px;
      height: 60px;
      padding: 2.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80%;
      background:  ${
        theme && theme.loader && theme.loader.background_color
          ? theme.loader.background_color
          : defaultTheme.color.secondary
      };
      background: linear-gradient(0deg, ${
        theme && theme.loader && theme.loader.background_color
          ? theme.loader.background_color
          : defaultTheme.color.secondary
      } 50%, ${
      theme && theme.loader && theme.loader.main_color
        ? theme.loader.main_color
        : defaultTheme.color.primary
    } 100%);
      animation: spinner-smooth infinite linear 1.1s;
    }

    .tkt-loader__circle-core {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 50%;
    }

    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to{
        transform: rotate(359deg);
      }
    }

    @keyframes pulsate {
      0% {transform: scale(0.99, 0.99);}
      50% {transform: scale(1.1, 1.1);}
      100% {transform: scale(0.99, 0.99);}
    }

    .tkt-loader__spinner { width: 85px; height: 85px; border-radius: 50%; border: 4px solid; border-color: ${
      theme && theme.loader && theme.loader.main_color
        ? theme.loader.main_color
        : defaultTheme.color.primary
    }; border-top-color: ${
      theme && theme.loader && theme.loader.background_color
        ? theme.loader.background_color
        : defaultTheme.color.secondary
    } }
    .tkt-loader__spinner { animation: spinner-smooth infinite ease 1.1s; }
    @keyframes spinner-smooth {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }`;

    let loadingStyle: HTMLElement = this._DOM.getElementById('theme-platform');
    if (loadingStyle) {
      loadingStyle.remove();

    }
    loadingStyle = this._renderer2.createElement('style');
    this._renderer2.setAttribute(loadingStyle, 'id', 'theme-platform');
    this._renderer2.appendChild(this._DOM.head, loadingStyle);

    loadingStyle.append(this._DOM.createTextNode(keyframes));
    this._renderer2.appendChild(this._DOM.head, loadingStyle);
  }

  /**
   * Método responsável por inicialização do loader
   * @param fullScreenEnabled Se o loader deve iniciar fullscreen.
   */
  public async init(
    fullScreenEnabled: boolean = true,
    showOrHideLogo: boolean = true,
  ) {
    this._showOrHide = showOrHideLogo;
    // inicializando sem tema enquanto informações do usário é recuperada
    this._initializeStyleInHead({  });
    this._insertLoaderViaDOM(fullScreenEnabled);
    const loader: any = await this._prepareUI();
    this._initializeStyleInHead(loader);
  }

  private _insertLoaderViaDOM(fullScreenEnabled: boolean) {
    if (!this.hasLoader && !fullScreenEnabled) {
      this.isLoading = true;
      this._DOM
        .getElementById('workspace__route')
        .insertAdjacentHTML('beforeend', this.templateLoaderTrakto);
    } else {
      this._DOM.body.insertAdjacentHTML('beforeend', this.templateLoaderTrakto);
    }
  }

  public get templateLoaderTrakto() {
    return `
    <section id="tkt-loader">
      <div class="tkt-loader__container-b2c">
        <div class="tkt-loader__spinner-box">
          <div class="tkt-loader__circle-border">
            <div class="tkt-loader__circle-core"></div>
          </div>
          <img src="${
            !this._showOrHide
              ? this.logo
              : 'assets/images/background-transparent.png'
          }" />
        </div>
      </div>
    </section>
    `;
  }

  public hide(): void {
    if (this.hasLoader) {
      this.isLoading = false;
      this._DOM.getElementById('tkt-loader').remove();
    }
  }

  private _factoryLoaderObject(product: any) {
    return {
      product_id: product.id,
      title: product.title,
      color: {
        ...product.color,
      },
      loader: {
        main_color:
          product.settings.ui_main_components?.loader?.main_color ||
          product.color.primary,
        background_color:
          product.settings.ui_main_components.loader?.background_color ||
          product.color.secondary,
        icon: product.settings.ui_main_components.loader?.image.low.url || '',
      },
      logo: {
        ...product.logo.colored,
      },
    };
  }

  private async _prepareUI() {
    const productId = await this._userService.user?.current_profile.product.id || environment.productIdB2C;
    const productFromDb = await this._productRepository.findById(productId);
    this._loader = this._factoryLoaderObject(productFromDb);
    this.logo = this._loader.loader?.icon || this._getLogoProduct(this._loader);
    this._cookieLoaderService.setCookieThemeLoader(this._loader);
    return this._loader;
  }
}
