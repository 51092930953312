<div class="tags-list">
  <div
    class="tags-list__tag"
    *ngFor="let tag of tags; index as i"
    (click)="selectTag(tag)"
    [ngClass]="{ 'tags-list__tag--active': tag.id === currentTag?.id }"
  >
    <div
      class="tags-list__background"
      [ngStyle]="{ backgroundColor: getColor(i) }"
    ></div>
    <span [ngStyle]="{ color: getColor(i) }">{{ tag?.name }}</span>
  </div>
</div>
