import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit, Component,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2, SimpleChanges,
} from '@angular/core';
import { TextService } from '@services/text.service';
import { ElementModelGroupEngine } from '@trakto/core-editor';
import { ITextElementModel } from '@trakto/models';

const DIFF_WIDTH = 10; // Em px

@Component({
  selector: 'trakto-text-edition-button',
  templateUrl: './text-edition-button.component.html',
  styleUrls: ['./text-edition-button.component.scss'],
})
export class TextEditionButtonComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  private textElement: ITextElementModel;

  @Input()
  private ref: any;

  @Input()
  private selection: ElementModelGroupEngine;

  @Input()
  changing = false;

  @HostBinding('style.position')
  position = 'fixed';

  @HostBinding('style.top.px')
  top = -10000;

  @HostBinding('style.left.px')
  left = 0;

  @HostBinding('style.display')
  display = 'block';

  onScroll: EventListenerObject;

  constructor(
    private textService: TextService,
    private elementRef: ElementRef,
    private render2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.onScroll = this.calcTransform.bind(this);
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngAfterViewInit(): void {
    this.top = -10000;
    setTimeout(() => this.calcTransform(), 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ref && !changes.ref.firstChange) {
    this.calcTransform();
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  private calcTransform() {
    this.display = 'display';
    if (!this.textElement || !this._getElementRect() || !this._getTextEditionButtonRect()) {
      this.display = 'none';
      return;
    }

    const { x, y } = this._calcBoundBox();

    this.left = Math.min(Math.max(x, DIFF_WIDTH), this._calcMaxX());
    this.top = Math.min(y, this._calcMaxY());

    this.render2.setStyle(
      this.elementRef.nativeElement,
      'left',
      `${this.left}px`,
    );
    this.render2.setStyle(
      this.elementRef.nativeElement,
      'top',
      `${this.top}px`,
    );
  }

  private _calcBoundBox(): {x, y} {
    const textBoundBox = this._getElementRect();
    const y = textBoundBox.y + textBoundBox.height;
    return {
      x: textBoundBox.x + textBoundBox.width / 2 - this._getButtonWidth() / 2,
      y: y + DIFF_WIDTH
    };
  }

  private _calcMinX() {
    return DIFF_WIDTH;
  }

  private _calcMaxX() {
    const headerRect = this._getTraktoHeaderRect();
    return headerRect.width - DIFF_WIDTH - this._getButtonWidth();
  }

  private _calcMaxY() {
    const y = !this.changing ? this._getTraktoPanelRect().y : this.document.body.offsetHeight;
    return y - DIFF_WIDTH - this._getButtonHeight();
  }

  private _getElementRect(): DOMRect {
    return this._getRect(`#${ this.textElement.id }__container`)
      || this._getRect(`#${ this.textElement.id }__text-editor-scrolling-container`);
  }

  private _getTraktoHeaderRect(): DOMRect {
    return this._getRect('trakto-header');
  }

  private _getTraktoPanelRect(): DOMRect {
    return this._getRect('trakto-panel');
  }

  private _getTextEditionButtonRect(): DOMRect {
    return this._getRect('trakto-text-edition-button');
  }

  private _getRect(query: string): DOMRect {
    const ref = this.document.querySelector(query);
    return ref ? ref.getBoundingClientRect() : undefined;
  }

  private _getButtonWidth() {
    return this.elementRef.nativeElement.offsetWidth;
  }

  private _getButtonHeight() {
    return this.elementRef.nativeElement.offsetHeight;
  }
}
