import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridLayoutFlexComponent } from './grid-flex/grid-flex.component';
import { GridLayoutComponent } from './grid-layout/grid-layout.component';

@NgModule({
  imports: [CommonModule],
  declarations: [GridLayoutComponent, GridLayoutFlexComponent],
  exports: [GridLayoutComponent, GridLayoutFlexComponent],
})
export class LayoutModule {}
