import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../forms/checkbox/checkbox.module';

import { DocumentItemRendererComponent } from './document-item-renderer/document-item-renderer.component';
import { ItemRendererComponent } from './item-renderer/item-renderer.component';
import { MediaComponent } from './media/media.component';
import { PreviewRendererComponent } from './preview-renderer/preview-renderer.component';

@NgModule({
  imports: [CommonModule, ButtonModule, FormsModule, CheckboxModule],
  declarations: [
    ItemRendererComponent,
    PreviewRendererComponent,
    MediaComponent,
    DocumentItemRendererComponent,
  ],
  exports: [
    ItemRendererComponent,
    PreviewRendererComponent,
    MediaComponent,
    DocumentItemRendererComponent,
  ],
})
export class ItemRendererdModule {}
