<header class="header">
  <div class="main">
    <img [src]="logoPath" [alt]="logoAlt" class="logo" />

    <trakto-button-link
      icon="icon icon-undo"
      label="Início"
      (click)="backHomeConfirmation()"
    ></trakto-button-link>

    <trakto-button-link
      [icon]="'save-' + savingStatus"
      [label]="savingStatusLabel"
      (action)="handleSave()"
    ></trakto-button-link>

    <div class="premium" *ngIf="!isPremiumUser">
      <span class="label">Seja <strong>Premium</strong></span>
      <img src="assets/editor-v3/images/premium.svg" class="icon" />
    </div>
  </div>

  <div class="title">
    <div class="history temp-hidden">
      <i
        class="icon icon-undo"
        (click)="toggleUndo()"
        [ngClass]="{ disabled: !isUndoAvailable }"
      ></i>
      <i
        class="icon icon-redo"
        (click)="toggleRedo()"
        [ngClass]="{ disabled: !isRedoAvailable }"
      ></i>
    </div>

    <span
      class="name"
      (click)="toggleInputFocus(true)"
      [ngStyle]="{ display: isInputFocused ? 'none' : 'block' }"
    >
      {{ documentName }}
    </span>
    <input
      type="text"
      [(ngModel)]="documentName"
      [ngStyle]="{ display: isInputFocused ? 'block' : 'none' }"
      (blur)="toggleInputFocus(false)"
      (keyup.enter)="toggleInputFocus(false)"
      class="name-input"
      #documentInput
    />
  </div>

  <div class="actions">
    <div class="link">
      <div class="container">
        <i class="icon icon-link"></i>
        <span class="label">
          <strong>Trakto Links</strong>
        </span>
      </div>
    </div>

    <div *ngIf="!isPremiumUser" class="premium-mobile">
      <img src="assets/editor-v3/images/premium.svg" class="icon" />
    </div>

    <trakto-button-link
      icon="present"
      label="Apresentar"
      class="hide-mobile"
    ></trakto-button-link>
    <trakto-button-link
      icon="share"
      label="Compartilhar"
      class="hide-mobile"
    ></trakto-button-link>
    <trakto-button-link
      icon="download"
      label="Baixar"
      (click)="downloadDocument()"
    ></trakto-button-link>
  </div>
</header>
