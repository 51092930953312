import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { PageService } from '@app/editor/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { ITranslatePopoverDelete, PageModel } from '@trakto/models';
import { generateUUID } from '@trakto/core-editor';
import { watchHover } from '../hover.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentStateManagerService } from '@app/editor/services/document-state-manager.service';

@Component({
  selector: 'trakto-superior-page-options',
  templateUrl: './superior-page-options.component.html',
  styleUrls: ['./superior-page-options.component.scss'],
})
export class SuperiorPageOptionsComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChildren('action') action: QueryList<ElementRef<HTMLElement>>;

  @Input() selectedPage: PageModel;
  @Input() selectedPageIndex: number;
  @Input() zoomRatio: number;
  @Input() pageWidth: number;
  @Input() docLength: number;
  @Input() allowNewPages: boolean;
  @Input() isDropboxInstance: boolean;
  @Input() popoverDeleteInfo: ITranslatePopoverDelete;

  @Output()
  onSelectedPage: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onDeleteAsked: EventEmitter<PageModel> = new EventEmitter();

  translatedTexts: any;
  showLabels = true;
  private _destroy$ = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _documentManagerService: DocumentStateManagerService,
    private _pageService: PageService,
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngAfterViewInit(): void {
    const elements = this.action.map(el => el.nativeElement);
    watchHover(elements);
    this.handleOptionsResponsiveness();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.zoomRatio || changes.pageWidth) {
      this.handleOptionsResponsiveness();
    }
  }

  getTranslations() {
    this._translateService
      .get(['artboard', 'brand_trakto'])
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(texts => {
        this.translatedTexts = texts;
      });
  }

  doTruncateStr(title: any, size: any): string {
    if (
      title === undefined ||
      title === 'undefined' ||
      title === '' ||
      size === undefined ||
      size === 'undefined' ||
      size === ''
    ) {
      return title;
    }

    let shortText = title;
    if (title.length >= size + 3) {
      shortText = title.substring(0, size).concat('...');
    }
    return shortText;
  }

  emitNewSelectedPage(): void {
    this.onSelectedPage.emit(true);
  }

  addPage() {
    this._documentManagerService.addPage(
      this._pageService.cloneClearPage(this.selectedPage),
      this.selectedPageIndex + 1,
    );
  }

  clonePage() {
    const pageCloned = Object.assign({}, this.selectedPage);
    pageCloned.id = generateUUID();

    this._documentManagerService.addPage(
      pageCloned,
      this.selectedPageIndex + 1,
    );
  }

  askForDeletePage() {
    this.onDeleteAsked.emit(this.selectedPage);
  }

  deletePage() {
    this._documentManagerService.deletePage(this.selectedPage);
  }

  handleOptionsResponsiveness() {
    setTimeout(() => {
      const optionsDiv = document.querySelector('div.options');
      const rect = optionsDiv.getBoundingClientRect();

      this.showLabels = rect.width >= 350;
    }, 300);
  }
}
