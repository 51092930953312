import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from '@app/editor/services/document.service';
import { environment } from '@env/environment';

@Component({
  selector: 'trakto-design-clone',
  templateUrl: './design-clone.component.html',
  styleUrls: [],
})
export class DesignCloneComponent implements OnInit {
  constructor(
    private _designService: DocumentService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this._activatedRoute.snapshot.params.designId) {
      this._router.navigateByUrl(environment.static.traktoLinksNotFound);
    }
    try {
      const design = await this._designService
        .copyDocumentToUser(this._activatedRoute.snapshot.params.designId);
      this._router.navigateByUrl(`editor/${ design.id }`);
    } catch (e) {
      this._router.navigateByUrl(environment.static.traktoLinksNotFound);
    }
  }
}
