import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { ISearchEvent, } from '@app/editor/components/properties-panel/panel-elements/model/model';
import { ElementsGalleryService } from '@shared/elements-gallery/elements-gallery.service';

@Component({
  selector: 'trakto-theme-results',
  templateUrl: './theme-results.component.html',
  styleUrls: ['./theme-results.component.scss'],
})
export class ThemeResultsComponent implements OnInit {
  packInfo: ISearchEvent = null;

  constructor(
    private _panelService: PanelService,
    private _elementsGalleryService: ElementsGalleryService,
  ) {}

  ngOnInit(): void {
    this.packInfo = this._elementsGalleryService.store;
  }

  closePanel() {
    this._elementsGalleryService.resetPanelStore();
    this._panelService.closeLastPanel();
  }
}
