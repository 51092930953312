import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss'],
})
export class LoadComponent {
  public appB2c: boolean;

  @Input() public set b2c(value: boolean) {
    this.appB2c = value;
  }

  public get b2c(): boolean {
    return this.appB2c;
  }
}
