import { Injectable } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { TraktoApiService } from '@services/trakto-api.service';
import { ResolutionsModel } from '@trakto/graphics-resources';

export interface IAssetResponse {
  data: any[];
  nextCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IAssetUploadRequest {
  fileType: 'base64' | 'url';
  imageData: string;
  isThumbnail?: boolean;
  provider?: string;
}

export interface IAssetUploadResponse {
  url: ResolutionsModel;
  format: string;
  isThumbnail: boolean;
  provider: string;
  uuid: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileRepository {

  private readonly _cacheKey = 'trakto-uploads';
  private readonly _newUrlByUrl = {};

  constructor(
    private _authUser: AuthService,
    private _traktoApiService: TraktoApiService,
  ) {
    this._newUrlByUrl = JSON.parse(sessionStorage.getItem(this._cacheKey) || '{}');
    if (Object.keys(this._newUrlByUrl).length > 100) {
      sessionStorage.setItem(this._cacheKey, JSON.stringify({}));
    }
  }

  public async uploadImage(data: IAssetUploadRequest, saveGallery = false): Promise<IAssetUploadResponse> {
    if (data.imageData.includes('http') && this._newUrlByUrl[data.imageData]) {
      return this._newUrlByUrl[data.imageData];
    }
    const result: IAssetUploadResponse = await this._traktoApiService.executePost(`v2/upload/${saveGallery ? 'user/' : ''}image`, data);
    if (data.imageData.includes('http') && !data.imageData.includes('data:image/png;base64')) {
      this._newUrlByUrl[data.imageData] = result;
      sessionStorage.setItem(this._cacheKey, JSON.stringify(this._newUrlByUrl));
    }
    return result;
  }

  public async findGifsByLoggedUser(limit: number, nextCursor?: string): Promise<IAssetResponse> {
    return this._doFindAssetsByType(limit, nextCursor, 'gif');
  }

  public async findImagesByLoggedUser(limit: number, nextCursor?: string): Promise<IAssetResponse> {
    return this._doFindAssetsByType(limit, nextCursor, 'image');
  }

  public removeUserImage(uuid: string): Promise<any> {
    return this._traktoApiService.executeDelete(`gallery/asset/${uuid}`);
  }

  private _doFindAssetsByType(limit: number, nextCursor: string, resourceType: string): Promise<IAssetResponse> {
    const params = [
      `total_per_page=${ limit }`,
    ];
    if (nextCursor) {
      params.push(`nextCursor=${ nextCursor }`);
    }
    return this._traktoApiService.executeGet<IAssetResponse>(`gallery/${ resourceType }?${ params.join('&') }`);
  }

}
