import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  IDropdownSelectConfig,
  IElementModel,
  PageModel
} from '@trakto/models';

import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { ImageService } from '@services/image.service';
import {
  PanelDuotoneComponent
} from '../panel-duotone/panel-duotone.component';
import { PanelFilterComponent } from '../panel-filter/panel-filter.component';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-image-settings',
  templateUrl: './panel-image-settings.component.html',
})
export class PanelImageSettingsComponent extends AbstractComponent<IElementModel> {
  @ViewChild('element', { static: true }) element: ElementRef;

  private _isGif: boolean;
  private _translatedTexts: any;

  public allowedCutBg: boolean;
  public dropdownBackgroundImagePosition: IDropdownSelectConfig;
  public isTranslate: any;
  public isLoadingBG = false;

  public get isGif(): boolean {
    return this._isGif;
  }

  constructor(
    private _imageService: ImageService,
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
    private _signalsService: SignalsService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    _documentStateManagerService: DocumentStateManagerService
  ) {
    super (_documentStateManagerService);
    this._translateService
      .get(['toolbar', 'property_panel'])
      .pipe(takeUntil(this._destroy$))
      .subscribe(texts => {
        this._translatedTexts = texts;

        this.buildDropdown(this._translatedTexts.property_panel.image_bg);
        this.makeTranslate();
      });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._imageService.isLoadingBG.pipe(takeUntil(this._destroy$)).subscribe(data => (this.isLoadingBG = data));
  }

  public onPageSelected(page: PageModel) {
    if (page && page.backgroundImage) {
      this._checkBackgroundImage();
    }
  }

  private buildDropdown(translations) {
    const texts = translations.dropdown_position;

    this.dropdownBackgroundImagePosition = {
      options: [
        { label: texts.top_left, value: 'topLeft', selected: false },
        { label: texts.top_right, value: 'topRight', selected: false },
        { label: texts.top_center, value: 'topCenter', selected: false },
        { label: texts.center_left, value: 'centerLeft', selected: false },
        { label: texts.center_right, value: 'centerRight', selected: false },
        { label: texts.center_center, value: 'centerCenter', selected: true },
        { label: texts.bottom_left, value: 'bottomLeft', selected: false },
        { label: texts.bottom_right, value: 'bottomRight', selected: false },
        { label: texts.bottom_center, value: 'bottomCenter', selected: false },
      ],
      buttonConfig: {
        classes: ['button--outline', 'button--light'],
      },
    };
  }

  public setFilter(filter: string): void {
    if (this.pageSelected) {
      this.pageSelected.filter = filter || 'empty';
      this.changeProperty('filter');
    }
  }

  public changeProperty(property: string): void {
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: this.pageSelected.id,
      key: property,
      value: this.pageSelected[property],
      obj: this.pageSelected,
    });
  }

  public removeBackgroundOnPage() {
    this.pageSelected.backgroundImage = null;
    this.pageSelected.backgroundImageLow = null;
    this.pageSelected.backgroundImageMedium = null;
    this.pageSelected.backgroundImageHigh = null;
    this.pageSelected.backgroundImageRaw = null;

    this.changeProperty('backgroundImage');
  }

  public openModal(id: string): void {
    this._signalsService.emit(enumSignals.ON_OPEN_MODAL, { id });
  }

  public close() {
    this._panelStackService.pop(500);
  }

  public openPanelStack(context: string) {
    switch (context) {
      case 'duotone':
        this._panelStackService.stack(PanelDuotoneComponent, {
          inputs: { pageSelected: this.pageSelected },
        });
        break;
      case 'filter':
        this._panelStackService.stack(PanelFilterComponent, {
          inputs: { pageSelected: this.pageSelected },
        });
        break;
      default:
        break;
    }
  }

  public makeTranslate(): void {
    const { property_panel, toolbar } = this._translatedTexts;

    this.isTranslate = {
      image: toolbar.image.title || '',
      gif: toolbar.gif.title || '',
      remove_image: property_panel.image.remove_image || '',
      remove_gif: property_panel.gif.remove_gif || '',
    };
  }

  private _checkBackgroundImage() {
    this.allowedCutBg =
      this._imageService.getExtensionFile(this.pageSelected.backgroundImage) !==
      'gif';
  }

  changeBackgroundImageOpacity(opacity: number) {
    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'backgroundImageOpacity', opacity);

  }
}
