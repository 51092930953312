import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TraktoLink, TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-publish-trakto-link',
  templateUrl: './publish-trakto-link.component.html',
  styleUrls: ['./publish-trakto-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PublishTraktoLinkComponent implements OnInit, OnDestroy {

  public link = {
    copied: false,
    minlinkLength: 1,
    maxlinkLength: 100,
    success: false,
    typing: true,
  }

  public inputValue = '';
  public isLoading: boolean = false;
  private url: string = 'trakto.link/';
  private linkNameSubject = new Subject<string>();
  private _destroy$ = new Subject<void>();

  constructor(
    private _traktoLinksService: TraktoLinksService,
    public dialogRef: MatDialogRef<PublishTraktoLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {

    this.linkNameSubject
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(1 * 1000),
        distinctUntilChanged()
      )
      .subscribe(linkName => {
        if (
          (linkName.length > this.link.minlinkLength &&
            linkName.length <= this.link.maxlinkLength) ||
          linkName.length === 0
        ) {
          this.editLinkName(linkName);
        }
      });
  }

  public onLinkTyping(event: any) {
    if (!this._isValidKey(event.keyCode)) {
      this.link.typing = false;
      this.link.success = false;
      return false
    };

    const linkNameInput = event.target.value;
    this.linkNameSubject.next(linkNameInput);
  }

  private _isValidKey(keyCode: any) {
    const validFastKeys = [8, 13]; // backspace && enter
    if (validFastKeys.indexOf(keyCode) > -1) {
      return true;
    }

    if (
      !(keyCode > 47 && keyCode < 58) && // numeric (0-9)
      !(keyCode > 64 && keyCode < 91) && // upper alpha (A-Z)
      !(keyCode > 96 && keyCode < 123) && // lower alpha (a-z)
      !(keyCode > 189) // - and _
    ) {
      return false;
    }

    return true;
  }

  editLinkName(linkName: string) {
    if (this._hasChanged(linkName)) {
      this.isLoading = true;
      this.inputValue = linkName;

      let updateLink: TraktoLink = new TraktoLink({
        slug: this.inputValue,
        linkWeb: this.inputValue,
        link_web: this.data?.link_web,
        seo: new TraktoLinkSeo({
          thumbUrl: this.data.seo.image,
          title: this.data.seo.title,
          description: this.data.seo.description
        })
      });

      this._traktoLinksService.createMetadataAndPublish(updateLink, this.data.isB2C)
        .then(
          (link) => {
            this.data.linkWeb = link.linkWeb
            this.link.typing = false;
            this.link.success = true;
            this.isLoading = false;
          }
        ).catch(
          (err) => {
            this.link.typing = false;
            this.link.success = false;
            this.isLoading = false;
          }
        )
    }
  }

  private _hasChanged(linkName) {
    if ((this.data.linkWeb ? this.data.linkWeb : this.data?.link_web)  === linkName) {
      return false;
    }
    return true;
  }

  public copyLinkToClipboard() {
    const element = document.createElement('input');
    element.value = this.url + `${this.data?.linkWeb ? this.data?.linkWeb : this.data?.link_web }`;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 3000);
  }

  close(event?: any) {
    this.dialogRef.close(event);
  }

  selectionRange() {
    document.getElementById('subdomain').focus();
  }

}
