<div class="dropdown">
  <div
    class="dropdown__label"
    (click)="toggleExpandedStatus()"
    #dropdownOptions
  >
    <span>{{ 'property_panel.page.unit.' + getLabel() | translate }}</span>
    <img src="assets/images/panel/arrow-down.svg" alt="" />
  </div>
  <div
    class="dropdown__options"
    *ngIf="isExpanded"
    [style]="reference + ': 48px'"
  >
    <div
      *ngFor="let option of options"
      class="dropdown__option"
      (click)="changeUnit(option)"
    >
      {{ 'property_panel.page.unit.' + option.label | translate }}
    </div>
  </div>
</div>
