import { Component, OnDestroy, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IPack } from '@app/shared/elements/pack.entity';
import { Subscription } from 'rxjs';
import { ElementNewApiService } from '@services/element-new-api.service';
import { SearchTypeEnum } from '@editor/enums/editor-elements/search-element.enum';
import { ElementDatasource } from '@editor/enums/editor-elements/elements-datatasource.enum';
import { ElementsGalleryService } from '@shared/elements-gallery/elements-gallery.service';
import { GalleryElementType } from '@editor/enums/editor-elements/gallery-type.enum';

@Component({
  selector: 'trakto-panel-elements-mobile',
  templateUrl: './panel-elements-mobile.component.html',
  styleUrls: ['./panel-elements-mobile.component.scss'],
})
export class PanelElementsMobileComponent implements OnInit, OnDestroy {
  searchText: string = '';
  queryText: string = '';
  tags: string[] = [];
  filteredTags: string[] = [];
  showSuggestions: boolean = false;

  packs: IPack[] = [];
  isPacksLoading: boolean = false;

  families: IPack[];

  private _onClearSearchedTag: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _panelService: PanelService,
    private _elementNewApiService: ElementNewApiService,
    private _elementsGalleryService: ElementsGalleryService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.initRequests();
  }

  initSubscriptions() {
    this._onClearSearchedTag =
      this._elementsGalleryService.storeObservable.subscribe(() => {
        this.setInputText('');
      });

    this._subscriptions.push(this._onClearSearchedTag);
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  async initRequests() {
    this.fetchTags();
    this.fetchFolders();
    this.initFamilies();
  }

  async fetchTags() {
    const tags = await this._elementNewApiService.getTags({
      query: this.queryText,
    });

    this.tags = tags.map(tag => tag.name);
    this.filteredTags = [];
  }

  async fetchFolders() {
    try {
      this.isPacksLoading = true;
      this.packs = [...(await this._elementNewApiService.getPacks())];
    } finally {
      this.isPacksLoading = false;
    }
  }

  async initFamilies() {
    this.families = await this._elementNewApiService.getFamilies();
  }

  setInputText(val: string) {
    this.searchText = val;
    this.setFilteredTags();
    this.showSuggestions = val.length > 0;

    if (val.length === 0) this.selectTag('');
  }

  setFilteredTags() {
    this.filteredTags = this.tags.filter(
      tag => tag.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1,
    );
  }

  selectTag(tag: string) {
    this.searchText = this.queryText === tag ? '' : tag;
    this.queryText = this.searchText;
    this.setShowSuggestions(false);

    if (tag.length > 0) {
      this._elementsGalleryService.setElementsPanelStore(
        {
          tags: [{
            name: tag,
            languageISOCode: 'pt-BR',
          }],
          elementType: this._elementNewApiService.getElementTypeByContentType(GalleryElementType.IMAGE)
        },
      );
      this._panelService.openPanel('elements-search-results');
    }
  }

  selectFamily(family: IPack) {
    this._elementsGalleryService.setElementsPanelStore({
      query: null,
      type: SearchTypeEnum.FOLDER,
      name: family.name,
      familyId: family.id,
      family: family,
      datasource: ElementDatasource.TRAKTO,
    });
    this._panelService.openPanel('elements-formats-results');
  }

  selectPack(pack: IPack) {
    this._elementsGalleryService.setElementsPanelStore(
      { pack: pack, type: SearchTypeEnum.THEME }
    );
    this._panelService.openPanel('elements-theme-results');
  }

  setShowSuggestions(val: boolean) {
    setTimeout(() => {
      this.showSuggestions = val;
    }, 50);
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
