import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentMetricsModel } from '@trakto/core-editor';
import { IDocumentModel } from '@trakto/models';

import { DocumentManagerService } from '@services/document-manager.service';
import { DocumentService as DocumentServiceV2 } from '@services/document.service';
import { ReplaySubject, Subject } from 'rxjs';
import { CoverService } from '@app/editor/services/design/cover.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from './user.service';

export enum EnumSavingStatus {
  SAVED = 'default',
  SAVING = 'loading',
  JUST_SAVED = 'done',
  ERROR = 'default',
}

@Injectable({
  providedIn: 'root',
})
export class SavingService implements OnDestroy {
  public lastChangeWasSaved = true;
  public savingStatus: EnumSavingStatus = EnumSavingStatus.SAVED;
  public onSavingStatusChange: Subject<EnumSavingStatus>;

  private _selectedDocument: IDocumentModel;
  private _timeCounter = 0;
  private _marginError = 3000;
  private _updateCoverTime = 4 * 15000;
  private _saveTime = 15000;
  private _forcedSaveTime = 15000 * 8;
  private _toUpdateCover = false;
  private _destroy$ = new Subject<void>();

  constructor(
    private _documentServiceV2: DocumentServiceV2,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _translateService: TranslateService,
    private _userService: UserService,
    private _coverService: CoverService,
    private _deviceDetectorService: DeviceDetectorService,
  ) {
    this.onSavingStatusChange = new ReplaySubject<EnumSavingStatus>(1);
    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(document => {
      this._selectedDocument = document;
    });
    if (this._deviceDetectorService.isMobile()) {
      this._startSaveTimer();
      this._startForcedSaveTimer();
      this._startCoverTimer();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }


  documentIsSaving() {
    return this.savingStatus === EnumSavingStatus.SAVING;
  }

  updateSavingStatus(value: EnumSavingStatus) {
    this.savingStatus = value;
    this.onSavingStatusChange.next(value);

    if (value === EnumSavingStatus.JUST_SAVED) {
      this.resetStatus();
    }
  }

  resetStatus() {
    setTimeout(() => {
      this.updateSavingStatus(EnumSavingStatus.SAVED);
    }, 3000);
  }

  private _startSaveTimer() {
    setInterval(() => {
      if (!this._documentManagerService.savedChangeLast) {
        this.saveDocument(true).then();
      }
    }, this._saveTime);
  }

  private _startCoverTimer() {
    setInterval(() => {
      if (!this._toUpdateCover) {
        return;
      }
      this._coverService.generateThumb(this._selectedDocument).then();
      this._toUpdateCover = false;
    }, this._updateCoverTime);
  }

  private _startForcedSaveTimer() {
    setInterval(() => {
      this._timeCounter += this._forcedSaveTime;
      if (!this._selectedDocument) {
        return;
      }
      const currentDate: Date = new Date();
      const timeDiff =
        currentDate.getTime() -
        (new Date(this._selectedDocument.updated_at).getTime() || new Date().getTime());
      const saveTime = this._forcedSaveTime - this._marginError;

      if (!this._selectedDocument.updated_at || timeDiff >= saveTime) {
        this.saveDocument(true).then();
      }
    }, this._forcedSaveTime);
  }

  async saveDocument(forcedSave = false): Promise<{
    saved: boolean;
    saving?: boolean;
    message?: string;
    fatal_error?: boolean;
  }> {
    try {
      if (this.documentIsSaving()) return;

        if (
          !forcedSave &&
          (this.documentIsSaving() || !this.lastChangeWasSaved)
        ) {
          return { saved: false, saving: this.documentIsSaving() };
        }

        const selectedDocument = await this._documentManagerService.getSelectedDocument();
        const checkDocument = this._checkDocument(selectedDocument);

        if (checkDocument.rejected) {
          this.updateSavingStatus(EnumSavingStatus.ERROR);
          throw new Error(this._getErrorMessage(checkDocument.message));
        }

        this.updateSavingStatus(EnumSavingStatus.SAVING);
        await this._documentManagerService.save(true);
        this.updateSavingStatus(EnumSavingStatus.JUST_SAVED);
        this.lastChangeWasSaved = true;
        this._toUpdateCover = true;
        return { saved: true, saving: false };
    } catch(err) {
        this.updateSavingStatus(EnumSavingStatus.ERROR);
        return {
          saved: false,
          saving: false,
          message: `${this._getErrorMessage('firebase')}: ${err}`,
          fatal_error: true,
        };
      }
  }

  private _getErrorMessage(
    key:
      | 'firebase'
      | 'empty'
      | 'integrity'
      | 'max_page'
      | 'auth_user'
      | 'document_not_found'
      | 'document_outdated',
  ): string {
    const translatedTexts = this._translateService.translations;
    const currentLang = this._translateService.currentLang;

    const errors = {
      firebase: translatedTexts[currentLang].auto_save.errors.firebase,
      empty: translatedTexts[currentLang].auto_save.errors.empty,
      integrity: translatedTexts[currentLang].auto_save.errors.integrity,
      max_page: translatedTexts[currentLang].auto_save.errors.max_page,
      auth_user: translatedTexts[currentLang].auto_save.errors.auth_user,
      document_not_found:
        translatedTexts[currentLang].auto_save.errors.document_not_found,
      document_outdated:
        translatedTexts[currentLang].auto_save.errors.document_outdated,
    };

    return errors[key];
  }

  private _checkDocument(document: IDocumentModel): {
    rejected: boolean;
    message?:
      | 'firebase'
      | 'empty'
      | 'integrity'
      | 'max_page'
      | 'auth_user'
      | 'document_not_found'
      | 'document_outdated';
    isFatal?: boolean;
  } {
    if (this._documentServiceV2.isEmpty(document)) {
      return {
        rejected: true,
        message: 'empty',
        isFatal: true,
      };
    }

    const lastDocumentMetrics =
      this._documentManagerService.lastDocumentMetrics;

    if (!DocumentMetricsModel.build(document).equals(lastDocumentMetrics)) {
      return {
        rejected: true,
        message: 'integrity',
        isFatal: true,
      };
    }

    if (!this._documentServiceV2.hasAllowedPagesNumber(document)) {
      return {
        rejected: true,
        message: 'max_page',
        isFatal: false,
      };
    }

    return { rejected: false };
  }
}
