import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { uuid } from 'uuidv4';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  constructor(private _httpClient: HttpClient) {}

  translate(
    text: string,
    targetLang: string = 'en',
    provider: string = 'google',
    throwError: boolean = true,
  ): Observable<string> {
    try {
      switch (provider) {
        case 'azure':
          return this._azureTranslate(text, targetLang);
        case 'google':
          return this._googleTranslate(text, targetLang);
        default:
          break;
      }
    } catch (error) {
      if (throwError) {
        console.error('[Translation Service] translate error ' + error);
        throw error.message;
      }
      return new Observable(observer => {
        observer.next(text);
        observer.complete();
      });
    }
  }

  private _azureTranslate(text: string, targetLang = 'en'): Observable<string> {
    const endpoint = environment.api.translation.azure.url;
    const azureKey = environment.api.translation.azure.secret_key;
    const options = {
      method: 'POST',
      baseUrl: endpoint,
      params: {
        'api-version': '3.0',
        to: targetLang,
      },
      headers: {
        'Ocp-Apim-Subscription-Key': azureKey,
        'Content-type': 'application/json',
        'X-ClientTraceId': uuid(),
      },
    };

    return this._httpClient
      .post(
        `${endpoint}translate`,
        [
          {
            text,
          },
        ],
        options,
      )
      .pipe(map(result => result[0].translations[0].text));
  }

  private _googleTranslate(text: string, targetLang = 'en') {
    const url = `${environment.api.google.translation.url}?key=${environment.api.google.translation.apiKey}`;

    return this._httpClient
      .post(url, {
        q: text,
        target: targetLang,
      })
      .pipe(
        map((result: any) => {
          return result.data &&
            result.data.translations &&
            result.data.translations.length
            ? result.data.translations[0].translatedText
            : '';
        }),
      );
  }
}
