import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateStringPipe } from './date-string';
import { BoldspanPipe } from './boldspan.pipe';
import { StringlimiterPipe } from './stringlimiter.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [DateStringPipe, BoldspanPipe, StringlimiterPipe, SafeUrlPipe],
  declarations: [DateStringPipe, BoldspanPipe, StringlimiterPipe, SafeUrlPipe],
})
export class PipesModule {}
