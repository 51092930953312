<div class="container-modal">
    <img [src]="data?.result ? '../../../../assets/images/feedback_success.svg' : '../../../../assets/images/feedback_failure.svg'"
        alt="Ícone" class="icon">
    <h1 class="title" *ngIf="data?.result">{{ "googleAds.adsSent" | translate }}!</h1>
    <h1 class="title" *ngIf="!data?.result">{{ "googleAds.uploadFailureTitle" | translate }}!</h1>
    <p class="confirmation-text" *ngIf="data?.result">{{ "googleAds.uploadSuccessDescription" | translate }}
        <span style="font-weight: 500;">Library > Library Assets</span>
        {{ "googleAds.uploadSuccessDescription1" | translate }}
    </p>
    <p class="failure-text" *ngIf="!data?.result">{{ "googleAds.uploadFailureDescription" | translate}}</p>
    <div class="btns-container">
        <button (click)="closeModal()" class="cancel-button" *ngIf="!data?.result">{{ "buttons.cancel" |
            translate}}</button>
        <button (click)="closeModal(true)" class="confirm-button" *ngIf="!data?.result">{{ "buttons.retry" | translate}}</button>
        <button (click)="closeModal()" class="confirm-button" *ngIf="data?.result">
            {{ "buttons.finished" | translate}}
        </button>
    </div>
</div>