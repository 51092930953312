<div class="settings">
  <div class="settings__inputs">
    <div class="settings__input">
      <label for="settings-width">
        {{ 'property_panel.page.dimensions_page.label_width' | translate }}
      </label>
      <trakto-input-dimensions
        name="settings-width"
        [(value)]="width"
        (onChangeEmit)="changeDimensions($event, 'width')"
      ></trakto-input-dimensions>
      <img src="assets/images/panel/width.svg" alt="" />
    </div>

    <div
      class="settings__icon"
      (click)="toggleLock()"
      [ngClass]="{ 'settings__icon--selected': pageSelected?.lockSize }"
    >
      <img
        [src]="
          pageSelected?.lockSize
            ? 'assets/images/panel/locked.svg'
            : 'assets/images/panel/unlocked.svg'
        "
        alt=""
      />
    </div>

    <div class="settings__input">
      <label for="settings-height">
        {{ 'property_panel.page.dimensions_page.label_height' | translate }}
      </label>
      <trakto-input-dimensions
        name="settings-height"
        [(value)]="height"
        (onChangeEmit)="changeDimensions($event, 'height')"
      ></trakto-input-dimensions>
      <img src="assets/images/panel/height.svg" alt="" />
    </div>
  </div>

  <div class="settings__dimensions">
    <div class="settings__orientation">
      <span>
        {{ 'property_panel.page.dimensions_page.aspect_ratio' | translate }}
      </span>
      <div class="settings__toggle">
        <div
          (click)="invertDimensions(orientations.HORIZONTAL)"
          class="settings__option"
          [ngClass]="{
            'settings__option--selected': orientation == 'horizontal'
          }"
        >
          <img src="assets/images/panel/page.svg" alt="" />
        </div>
        <div
          (click)="invertDimensions(orientations.VERTICAL)"
          class="settings__option settings__option--portrait"
          [ngClass]="{
            'settings__option--selected': orientation == 'vertical'
          }"
        >
          <img src="assets/images/panel/page.svg" alt="" />
        </div>
      </div>
    </div>

    <trakto-dropdown
      [options]="unitOptions"
      [pageSelected]="pageSelected"
      (onChangeUnit)="changeUnit($event)"
    ></trakto-dropdown>
  </div>
</div>

<div class="apply-all" (click)="onApplyAll.emit()">
  {{ 'property_panel.page.dimensions_page.apply_all' | translate }}
</div>
