export class HotkeyService {
  private _active: boolean;

  public codeKeys: (p?: any) => any;
  public headerKeys: (p?: any) => any;
  public toolbarKeys: (p?: any) => any;
  public previewKeys: (p?: any) => any;
  public propertiesPanelKeys: (p?: any) => any;

  public context: any;
  public contextStatus: any;

  public set active(active: boolean) {
    this._active = active;
  }

  public get active(): boolean {
    return this._active;
  }

  constructor() {
    this.context = {};
    this.contextStatus = {};
    this.active = true;
  }

  public addContext(key: string, fn: (p?: any) => any): void {
    this.context[key] = fn;
    this.enableContext(key);
  }

  public removeContext(key: string): void {
    delete this.context[key];
  }

  /**
   * Habilita ou desabilita os contextos com base no parametro passado
   *
   * @param isEnabled Define se ira habilitar/desabilitar o contexto
   */
  public toggleAllContextTo(isEnabled: boolean): void {
    const contexts = Object.keys(this.context);

    for (const context of contexts) {
      if (isEnabled === undefined) {
        break;
      } else {
        isEnabled ? this.enableContext(context) : this.disableContext(context);
      }
    }
  }

  public enableContext(key: string): void {
    this.contextStatus[key] = true;
  }

  public disableContext(key: string): void {
    this.contextStatus[key] = false;
  }

  setCombination(event: KeyboardEvent): void {
    const target: any = event.target;
    if (this.active) {
      if (
        target.localName === 'body' ||
        target.localName === 'button' ||
        target.localName === 'input'
      ) {
        Object.keys(this.context).map((value: string, index: number) => {
          if (
            typeof this.context[value] === 'function' &&
            this.contextStatus[value] === true
          ) {
            this.context[value](event);
          }
        });
      }
    }
  }

  stopPropagation(event: KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  CMD_ALT_SHIFT_KEY(event: KeyboardEvent): boolean {
    return this.useMetaOrCtrl(event) && event.shiftKey && event.altKey;
  }
  CMD_SHIFT_KEY(event: KeyboardEvent): boolean {
    return this.useMetaOrCtrl(event) && event.shiftKey && !event.altKey;
  }
  CMD_ALT_KEY(event: KeyboardEvent): boolean {
    return this.useMetaOrCtrl(event) && !event.shiftKey && event.altKey;
  }
  CMD_KEY(event: KeyboardEvent) {
    return this.useMetaOrCtrl(event) && !event.shiftKey && !event.altKey;
  }
  SHIFT_KEY(event: KeyboardEvent) {
    return !this.useMetaOrCtrl(event) && event.shiftKey && !event.altKey;
  }
  ALT_KEY(event: KeyboardEvent) {
    return !this.useMetaOrCtrl(event) && !event.shiftKey && event.altKey;
  }
  KEY(event: KeyboardEvent) {
    return !this.useMetaOrCtrl(event) && !event.shiftKey && !event.altKey;
  }

  isOSX(): boolean {
    return navigator.userAgent.indexOf('Mac') !== -1;
  }

  isSafari(): boolean {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1
    );
  }

  isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') !== -1;
  }

  useMetaOrCtrl(event: KeyboardEvent): boolean {
    return this.isOSX() ? event.metaKey : event.ctrlKey;
  }

  enableHotkeys(): void {
    this.active = !this.active ? true : this.active;
  }

  disableHotkeys(): void {
    this.active = this.active ? false : this.active;
  }

  isGroupSelection($event) {
    return (
      this.SHIFT_KEY($event as KeyboardEvent) ||
      this.CMD_SHIFT_KEY($event as KeyboardEvent)
    );
  }
}
