import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../../button/button.module';

import { DropdownSelectComponent } from './dropdown-select.component';
import { DropdownSelectItemComponent } from './item/dropdown-select-item.component';

@NgModule({
  declarations: [DropdownSelectComponent, DropdownSelectItemComponent],
  imports: [FormsModule, CommonModule, ButtonModule],
  exports: [DropdownSelectComponent, DropdownSelectItemComponent],
})
export class DropdownSelectModule {}
