import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipModule } from '@app/shared/tooltip/tooltip.module';
import { ZoomComponent } from './zoom.component';

@NgModule({
  declarations: [ZoomComponent],
  imports: [CommonModule, TooltipModule],
  exports: [ZoomComponent],
})
export class ZoomModule {}
