import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductRepository } from '@app/editor/repository/product.repository';

import { GoSquaredService } from '@app/editor/services/gosquared/gosquared.service';
import { LocaleService } from '@app/editor/services/locale.service';
import { PremiumService } from '@app/editor/services/premium.service';
import { ModalConfirmationComponent } from '@app/shared/modal-confirmation/modal-confirmation.component';

import { ModalSendingComponent } from '@app/shared/modal-sending/modal-sending.component';
import { ThumbService } from '@app/shared/svg-viewer/shared/thumb.service';
import { TraktoUser } from '@auth/shared/auth.model';
import { AuthService } from '@auth/shared/auth.service';
import { PanelPublishComponent } from '@editor/components/properties-panel/panel-publish/panel-publish.component';
import { PanelMagicTemplatesComponent } from '../properties-panel/panel-magic-templates/panel-magic-templates.component';
import { AutoSaveIndicatorComponent } from '@editor/components/save-indicator/auto-save-indicator/auto-save-indicator.component';
import { KeyEnum } from '@editor/services/hotkeys/hotkeys.enum';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DocumentManagerService } from '@services/document-manager.service';
import { DocumentService } from '@services/document.service';

import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { TraktoLinksService } from '@services/traktoLinks/trakto-link.service';
import { UserSnap } from '@services/userSnap/userSnap.service';
import { BaseComponent } from '@shared/base/base.component';
import { ModalProgressComponent } from '@shared/modal-progress/modal-progress.component';
import { ModalService } from '@shared/modal/modal.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { PlanConfigService } from '@shared/subscription/plan-config.service';
import { SubscriptionService } from '@shared/subscription/subscription.service';
import {
  FileQualityEnum,
  IAvalableLocales,
  IDocumentModel,
  idUserSnapEnum,
  IElementModel,
  IEventModel,
  PageModel,
} from '@trakto/models';
import { KmlModalService } from '@trakto/trakto-kamaleon';
import {
  EnumModalComponent,
  SubscriptionService as TraktoPremiumSubscriptionService,
  TraktoPremiumTrackingService,
} from '@trakto/trakto-premium';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PanelDownloadComponent } from '../properties-panel/panel-download/panel-download.component';
import { TraktoLinksAnalyticsService } from '@app/editor/services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { DesignDownloadAnalyticsService } from '../../services/analytics/wrapper/designDownload.analytics-wrapper.service';
import { UserAnalyticsService } from '../../services/analytics/wrapper/user.analytics-wrapper.service';
import { DocumentStateManagerService } from '@services/document-state-manager.service';
import { UserService } from '@app/editor-v3/services/user.service';


moment.locale('pt-BR');

const IMAGE_TRAKTO_ORAGE = '/assets/images/logo-trakto-orange.svg';
const IMAGE_TRAKTO_PREMIUM = '/assets/images/logo-premium.svg';

@Component({
  selector: 'trakto-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
// @ts-ignore
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() document: IDocumentModel;
  @Input() pageSelected: PageModel;
  @Input() elementSelected: IElementModel;
  @Input() b2c: boolean;

  @Output() onDownloadPng: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadPdf: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadVideo: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadGif: EventEmitter<any> = new EventEmitter<any>();

  @Output() onShare: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onClosePanelColor: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onShowPrintModal: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(AutoSaveIndicatorComponent, { static: true })
  private _autoSaveIndicatorComponent: AutoSaveIndicatorComponent;
  @ViewChild(ModalProgressComponent, { static: true })
  private _modalProgressComponent: ModalProgressComponent;

  private unsubscribe$ = new Subject<void>();
  private hasExportRequestPending = false;
  private _exportResolutions: FileQualityEnum[];
  private _exportFileType: string;

  public user: TraktoUser;
  public documentModel: IDocumentModel;

  public supportAvailable = false;
  public shareAsTemplateAvailable = false;
  public shareAsLinkAvailable = false;
  public canCreateLink = false;
  public shareAsPresentation = false;
  public exportAvailable = false;

  public logo: string;
  public dashboardURL: string;
  public imageTrakto: string;
  public traktoPremiumLogo: string;
  public loginPage: string;

  public productId: string;

  public modalOpen: boolean = false;

  public isPremiumUser = true;
  public isAutoSaveProgress = false;
  public isExportThumbProgress = false;
  public renewPlan = false;
  public isEmbeddedInstance = false;
  public isDropboxInstance = false;
  public availableLocales: IAvalableLocales[];
  public currentLang: string;
  public form: FormGroup;
  public focusIcon: boolean;
  public emptyInput = false;

  public translatedTexts: any;

  private _isLinksOpen = false;

  public embedDownloadType;
  public embedSaveButtonTitle;
  public saveButtonMessage;

  public isMagicTemplate = false;

  /**
   * get isLinksOpen
   */
  public get isLinksOpen(): boolean {
    return this._isLinksOpen;
  }

  public set isLinksOpen(val: boolean) {
    this._isLinksOpen = val || false;
  }

  constructor(
    public elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private modalService: ModalService,
    private hotkeyService: HotkeyService,
    private documentService: DocumentService,
    private _userService: UserService,
    private planConfigService: PlanConfigService,
    private dialog: MatDialog,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private signalsService: SignalsService,
    private _panelStackService: PanelStackService,
    private _localeService: LocaleService,
    private _translateService: TranslateService,
    private _thumbService: ThumbService,
    private _subscriptionService: SubscriptionService,
    private _userSnapService: UserSnap,
    private _kmlModal: KmlModalService,
    private _formBuilder: FormBuilder,
    private _traktoLinksService: TraktoLinksService,
    private _productService: WhitelabelProductService,
    private _premiumService: PremiumService,
    private _tktPremiumSubscriptionService: TraktoPremiumSubscriptionService,
    private _checkoutTrackService: TraktoPremiumTrackingService,
    private _goSquaredService: GoSquaredService,
    private _productRepository: ProductRepository,
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
    private _designDownloadAnalytics: DesignDownloadAnalyticsService,
    private _userAnalyticsService: UserAnalyticsService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    super(authService, platformId);

    this._productService.featuresAvailable.pipe(takeUntil(this.unsubscribe$)).subscribe(features => {
      if (features) {
        this._identifyFeaturesAvailable(features);
      }
    });

    this.currentLang = this._localeService.currentLang;

    this.dashboardURL = environment.externalDashboardPage;
    this.loginPage = environment.externalLoginPage;

    this.isEmbeddedInstance = this.authService.isEmbeddedInstance();
    if (this.isEmbeddedInstance) {
      this._definesEmbedDownloadType();
    }

    this.isDropboxInstance = this.authService.isDropboxInstance();

    this._translateService.get('modal_download').pipe(takeUntil(this.unsubscribe$)).subscribe(texts => {
      this.translatedTexts = texts;
    });

    this._translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.translatedTexts = event.translations['modal_download'];
    });

    this._checkoutTrackService.traktoPremiumTrackEventEmiter.pipe(takeUntil(this.unsubscribe$)).subscribe(() => { });

    this._tktPremiumSubscriptionService.paymentSuccess.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: any) => {
        this._subscriptionService.updateUserSubscriptionData({
          userId: this._userService.user.id,
          ...data,
        });
      },
    );
  }

  /**
   * Baseado nas permissões de uso de funcionalidades presentes no produto,
   * define se os componentes que dão acesso a essas features estarão disponíveis
   * @private
   */
  private _identifyFeaturesAvailable(features) {
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.shareAsTemplateAvailable = features[ProductFeatures.SHARE_AS_TEMPLATE];
    this.shareAsLinkAvailable = features[ProductFeatures.LINKS_PREVIEW];
    this.shareAsPresentation = features[ProductFeatures.PRESENTATION_PREVIEW];
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
    this.exportAvailable =
      features[ProductFeatures.CUSTOMER_SUPPORT] ||
      features[ProductFeatures.EXPORT_PDF] ||
      features[ProductFeatures.EXPORT_PNG] ||
      features[ProductFeatures.EXPORT_VIDEO];
  }

  ngOnInit(): void {
    this._mapSDKDownloadResolutions();
    this._createEventsSubscribers();
    this._refreshPremiumStatus();
    this._initFormInput();
    this._insertTitleDoc();

    this.authService.getCurrentTraktoUser().pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
      this.user = user;
    })
    this._userSnapService.showWidget(idUserSnapEnum.templatesRequestWidget);
    this.hotkeyService.addContext('headerkeys', (e: KeyboardEvent) => {
      if (this.hotkeyService.CMD_KEY(e)) {
        if (e.which === KeyEnum.p) {
          this.hotkeyService.stopPropagation(e);
          this.goToPreview();
        } else if (e.which === KeyEnum.s) {
          this.hotkeyService.stopPropagation(e);
          this.manualSave();
        }
      }
    });
    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (!this._documentManagerService.savedChangeLast) {
        setTimeout(async () => {
          await this._thumbService.changeAndPersistThumbnail(this.document);
          this.manualSave(false);
        }, 0);
        event.returnValue = true;
        return true;
      }
    });

    this._shortcutKeyActivatePublicationPanel();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public _shortcutKeyActivatePublicationPanel(): void {
    setTimeout(() => {
      if (this.isAdmin || this.isGlobalUser) {
        this.hotkeyService.addContext('headerKeys', (e: KeyboardEvent) => {
          if (this.hotkeyService.CMD_KEY(e)) {
            if (e.which === KeyEnum.e) {
              this.openPanelStack('publish');
            }
          }
        });
      }
    }, 2000);
  }

  private _initFormInput(): void {
    this.form = this._formBuilder.group({
      title: [null, [Validators.required]],
    });
  }

  private _insertTitleDoc(): void {
    setTimeout(() => {
      if (!this.document) {
        return;
      }

      const { title } = this.document;
      this.getIsMagicTemplate();

      this.form.controls.title.setValue(title || 'Edite seu documento aqui');
    }, 4000);
  }

  public renameDoc(title?: string): void {
    if (!title) {
      this.emptyInput = true;
      return;
    }

    const titleDocument = title.trim();

    this._documentManagerService.rename(titleDocument);
    this.emptyInput = false;
  }

  public enableHotkeys(event): void {
    this.focus(event);
    this.hotkeyService.enableHotkeys();
  }

  public focus(event): void {
    event && event.type === 'focus'
      ? (this.focusIcon = true)
      : (this.focusIcon = false);
  }

  private _refreshPremiumStatus() {
    this.authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user: any) => {
        if (!user) {
          Promise.resolve().then(() => (location.href = this.loginPage));
        }

        this._userAnalyticsService.makeIdentifyUser();

        const premiumPlans = await this.planConfigService.getPlanPremium();
        this._checkPaymentStatus(user.id);
        const plan = await this.planConfigService.getUserPlanId();
        this.isPremiumUser = premiumPlans.includes(plan);

        this.useOfPremiumTemplate();

        let productId = '';

        if (user.current_profile) {
          const { product } = user.current_profile;

          productId =
            product.id ||
            user.products[0].id ||
            environment.productIdB2C;
        } else {
          productId =
            user.products[0].id ||
            environment.productIdB2C;
        }

        const productFromDB = await this._productRepository.findById(productId);
        const { logo, id } = productFromDB;

        this.productId = id;
        this.b2c = productFromDB.id === environment.productIdB2C;

        this.logo = this.b2c ? IMAGE_TRAKTO_ORAGE : logo.colored.medium.url;
        this.imageTrakto = this.b2c
          ? IMAGE_TRAKTO_ORAGE
          : logo.colored.medium.url;
        this.traktoPremiumLogo = this.b2c
          ? IMAGE_TRAKTO_PREMIUM
          : logo.colored.medium.url;

        this.availableLocales = await this._localeService.getAvailableLocales(
          productFromDB,
        );
      });
  }

  /**
   * Função que verifica se o usuário realizou ou não o último pagamento da assinatura
   * @param userID ID do usuário
   */
  private async _checkPaymentStatus(userID: string) {
    const paymentPaid: any =
      this.planConfigService.getStatusSubscriptionPayment();
    this.renewPlan = paymentPaid;
  }

  public stripePlanRenewalPortal() {
    this.authService
      .getCurrentTraktoUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user: any) => {
        if (user && !user?.customer_id) {
          return 'Usuário sem customer ID';
        }

        const { customer_id } = user;
        const currentUrl = this.router.url;

        const { portal_url } =
          await this.planConfigService.getSubscriptionPortalUrl(
            customer_id,
            currentUrl,
          );
        return window.open(portal_url, '_blank');
      });
  }

  /**
   * Método que verifica se o plano do usuário permite abrir template premium,
   * caso não permita, deve abrir a modal de template premium
   */
  public async useOfPremiumTemplate() {
    this._documentStateManagerService.document$.pipe(takeUntil(this.unsubscribe$)).subscribe(async (document: any) => {
      if (!document?.is_premium) {
        return 'Não foi possivel verificar se o template é premium';
      }

      const { is_premium } = document as any;

      if (!is_premium) {
        return;
      }

      const hasPermission = await this.planConfigService.getPermissionPromise('use_template_premium');
      const checkoutData = await this._premiumService.getCheckoutConfig();

      if (!hasPermission && !this.authService.isDropboxInstance()) {

        this._premiumService.openModalBlocking(
          EnumModalComponent.MODAL_TEMPLATES,
          checkoutData
        );
      }
    });
  }

  private _createEventsSubscribers() {
    /**
     * Subscriber for use the document JSON, used for save content
     */
    this._documentStateManagerService.document$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      doc => (this.documentModel = doc),
    );

    /**
     * Subscriber used for create thumbnails for page navigator on Editor and design managment on Dashboard
     */
    this.signalsService.connect(
      enumSignals.ON_DOCUMENT_GENERATE_THUMB,
      event => {
        this.isExportThumbProgress = event.data;

        if (this.hasExportRequestPending) {
          this.exportToClient();
        }
      },
    );

    /**
     * Subscriber used for recognize that status of design save. Action helps export design process
     */
    this.signalsService.connect(enumSignals.ON_AUTO_SAVE_PROGRESS, event => {
      this.isAutoSaveProgress = event.data;

      if (this.hasExportRequestPending) {
        this.exportToClient();
      }
    });

    /**
     * Subscriber used for update view and user profile after subscription changes
     */
    this._subscriptionService.planChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this._refreshPremiumStatus();
    });
  }

  private _mapSDKDownloadResolutions() {
    if (this.isEmbeddedInstance) {
      const resolutionsParam =
        this.activatedRoute.snapshot.queryParamMap.get('resolutions');

      this._exportResolutions = resolutionsParam
        ? (resolutionsParam.split(',') as FileQualityEnum[])
        : [FileQualityEnum.medium];

      this._exportFileType =
        this.activatedRoute.snapshot.queryParamMap.get('filetype') || 'url';
    }
  }

  undo() {
    this._documentStateManagerService.undo();
  }

  public openToPreview() {
    const url = environment.documentPreviewURL;
    window.open(
      `${url}/p/${this.document.slug || this.document.firebaseId}`,
      '_blank',
    );
  }

  public closePanel() {
    this.onClosePanelColor.emit();
  }

  public manualSave(requestThumbGeneration = true): void {
    this._documentManagerService
      .save(true, requestThumbGeneration)
      .then()
      .catch(err => console.error(err));

    if (this.autoSaveIndicatorComponent) {
      this.autoSaveIndicatorComponent.restart();
    }
  }

  public goToPreview(): void {
    if (this.document.body.length) {
      this.router.navigate(['/editor/preview'], {
        skipLocationChange: true,
        queryParams: { template: JSON.stringify(this.document) },
      });
    } else {
      console.warn('Não existe elementos para a pré-visualização');
    }
  }

  public intentExportToClient(): void {
    this.exportToClient();
  }

  public async exportToClient(): Promise<any> {
    if (this.isEmbeddedInstance) {
      switch (this.embedDownloadType) {
        case 'png':
          this._embedDownloadPng();
          break;
        case 'pdf':
          this._embedDownloadPDF();
          break;
        case 'mp4':
          this._embedDownloadMp4();
          break;
        default:
          this._embedDownloadPng();
      }
    }
  }

  public get autoSaveIndicatorComponent(): AutoSaveIndicatorComponent {
    return this._autoSaveIndicatorComponent;
  }

  public saveInDropbox() {
    const { fileId, integrationToken } = this.activatedRoute.snapshot.params;
    const modalRef = this._kmlModal.open(
      ModalSendingComponent,
      {
        data: {
          fileId: `${fileId}`,
          integrationToken: `${integrationToken}`,
          dataDoc: this.document.body[0],
        },
      },
      { width: '524px', height: '370px' },
    );

    modalRef.afterClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(modalData => {
      if (modalData) {
        window.location.href = 'https://www.dropbox.com/';
      }
    });
  }

  public openPanelStack(context: string): any {
    switch (context) {
      case 'download':
        this._panelStackService.closeOthers();
        this._panelStackService.stack(PanelDownloadComponent, {
          inputs: {
            b2c: this.b2c,
            document: this.document,
            pageSelected: this.pageSelected,
            elementSelected: this.elementSelected,
          },
        });
        break;
      case 'publish':
        this._panelStackService.closeOthers();
        this._panelStackService.stack(PanelPublishComponent, {
          inputs: {
            user: this.user,
            document: this.documentModel,
          },
        });
        break;
      case 'magic_template':
        this._panelStackService.closeOthers();
        this._panelStackService.stack(PanelMagicTemplatesComponent, {
          inputs: {
          },
        });
        break;
      default:
        break;
    }
  }



  public closeModal(): void {
    this.modalService.closeAll();
    this.hotkeyService.enableHotkeys();
  }

  public onClickShareOption(event: MouseEvent): any {
    this.onShare.emit(event);
  }

  public logout(): void {
    this.authService.redirectToLogoutPage();
  }

  private publishSuccessMessageOnEmbed(docPublished: any) {
    this._modalProgressComponent.hide({
      data: { success: true },
    } as IEventModel);
    window.parent.postMessage(
      JSON.stringify({
        type: 'trakto:published',
        value: docPublished,
        origin: 'https://trakto.io',
      }),
      '*',
    );
  }

  /**
   * handleClick
   */
  public handleClick() { }

  /**
   * openChat
   */
  public handleOpenChat() {
    this._goSquaredService.startConversation();
  }
  /**
   * openChat
   */
  public handleUserSnapFeedBack() {
    this._userSnapService.showWidget(
      idUserSnapEnum.keyFeedBack,
      this._translateService.currentLang,
    );
  }
  /**
   * openChat
   */
  public handleUserSnapBugReport() {
    this._userSnapService.showWidget(
      idUserSnapEnum.bugReportWidget,
      this._translateService.currentLang,
    );
  }
  /**
   * openChat
   */
  public handleUserSnapTemplateRequest() {
    this._userSnapService.showWidget(
      idUserSnapEnum.templatesRequestWidget,
      this._translateService.currentLang,
    );
  }

  public disablehotkeys() {
    const PREVIEW_KEY_CONTEXT = 'previewkeys';

    if (this.modalOpen) {
      this.hotkeyService.disableHotkeys();
      this.hotkeyService.disableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.disableContext('toolbarkeys');
    } else {
      this.hotkeyService.enableHotkeys();
      this.hotkeyService.enableContext(PREVIEW_KEY_CONTEXT);
      this.hotkeyService.enableContext('toolbarkeys');
    }
  }

  shareModalChange(event) {
    this.modalOpen = event;
  }

  /**
   * Toggle trakto link mode
   */
  public toggleTraktoLinks() {
    // this.isLinksOpen = !this.isLinksOpen;
    // const PREVIEW_KEY_CONTEXT = 'previewkeys';

    // if (this.isLinksOpen) {
    //   this.hotkeyService.disableHotkeys();
    //   this.hotkeyService.disableContext(PREVIEW_KEY_CONTEXT);
    //   this.hotkeyService.disableContext('toolbarkeys');
    // } else {
    //   this.hotkeyService.enableHotkeys();
    //   this.hotkeyService.enableContext(PREVIEW_KEY_CONTEXT);
    //   this.hotkeyService.enableContext('toolbarkeys');
    // }

    // this._tktLinksAnalytics.traktoLinksOpenend(this.isLinksOpen);
  }

  public async publishTraktoLink() {

    const hasPermission = await this.planConfigService.getPermissionPromise('document_publish_online');

    if (hasPermission) {
      this.toggleTraktoLinks();
      this.signalsService.emit(enumSignals.ON_TRAKTO_LINK_PUBLISH, {
        data: this._traktoLinksService.metadata
      });
      return;
    }

    const checkoutData = await this._premiumService.getCheckoutConfig();

    if (checkoutData) {
      this._premiumService.openModalBlocking(
        EnumModalComponent.MODAL_PLANS,
        checkoutData
      );
    }
  }

  /** CopyLink TraktoLinks */

  public copyLink() {
    const elem = document.createElement('input');
    elem.value = `${this._traktoLinksService.link}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this._tktLinksAnalytics.traktoLinksCopiedLink({
      linkName: this._traktoLinksService.link,
    });
  }

  public backToEditor(evt): void {
    if (!this.isEmbeddedInstance) {
      evt.preventDefault();

      const modalRef = this._kmlModal.open(
        ModalConfirmationComponent,
        {
          data: {
            title: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.title',
            )}`,
            description: `${this.getTranslations(
              'trakto_links.modals-confirmation.description-modal',
            )}`,
            buttonPrimary: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.continue-edition',
            )}`,
            buttonSecondary: `${this.getTranslations(
              'trakto_links.modals-confirmation.modal-begin.go-to-start',
            )}`,
          },
        },
        { width: '624px', height: '220px' },
      );

      modalRef.afterClosed.subscribe(modalData => {
        if (modalData) {
          window.location.href = `${this.dashboardURL}`;
        }
      });
    }
  }

  public getTranslations(key: string): string {
    let translate = '';
    this._translateService.get(key).pipe(takeUntil(this.unsubscribe$)).subscribe(texts => (translate = texts));

    return translate;
  }

  public setImagesTrakto(): void {
    this.imageTrakto = IMAGE_TRAKTO_ORAGE;
    this.traktoPremiumLogo = IMAGE_TRAKTO_PREMIUM;
  }

  private async _dataTracking(type: string) {
    this._designDownloadAnalytics.designDownloaded(type);
  }

  private _definesEmbedDownloadType() {
    const acceptableFormats = [
      'png',
      'mp4',
      'pdf',
      'fullDownloadOnBrowser',
      'saveButtonAndFullDownload',
    ];
    const param =
      this.activatedRoute.snapshot.queryParamMap.get('downloadType');

    this.embedSaveButtonTitle =
      this.activatedRoute.snapshot.queryParamMap.get('saveButtonText');

    this.saveButtonMessage =
      this.activatedRoute.snapshot.queryParamMap.get('saveButtonMessage');

    this.embedDownloadType =
      !param || !acceptableFormats.includes(param)
        ? 'png'
        : this.activatedRoute.snapshot.queryParamMap.get('downloadType');

    this.embedSaveButtonTitle =
      this.activatedRoute.snapshot.queryParamMap.get('saveButtonText');

    this.saveButtonMessage =
      this.activatedRoute.snapshot.queryParamMap.get('saveButtonMessage');
  }

  /**
   * The function export the design in PNG format, used when the editor is opened via integration.
   */
  private async _embedDownloadPng() {
    this._modalProgressComponent.show(
      this.saveButtonMessage || this.translatedTexts.image_progress_title,
      false,
    );

    this._modalProgressComponent.enableBroadcastProgressEvent();

    const pages = this.document.body;
    const exportedPages = await this._thumbService.createImagesToSDK(
      pages,
      this._exportResolutions,
      this._exportFileType,
    );

    const exportedDocument =
      await this.documentService.castPublishedDocumentToEmbeddedPage(
        exportedPages, this.document.firebaseId
      );

    this.document.thumbs = exportedDocument.pages[0].thumbs;
    this.document.cover = exportedDocument.pages[0].thumbs;
    this.publishSuccessMessageOnEmbed(exportedDocument);
    this._dataTracking('png');
    this._designDownloadAnalytics.designDownloaded('png');
    await this._documentManagerService.save(true);
  }

  /**
   * The function export the design in PDF format, used when the editor is opened via integration.
   */
  private async _embedDownloadPDF() {
    this._mapSDKDownloadResolutions();
    this._exportResolutions.forEach(resolution => {
      const settings = {
        name: FileQualityEnum[resolution],
        marks: false,
        title: `${resolution}`,
        range: null,
        modalMessage: this.saveButtonMessage,
      };
      this.signalsService.emit(enumSignals.ON_DOWNLOAD_SIGNAL, {
        type: 'pdf',
        data: settings,
      });
      this._designDownloadAnalytics.designDownloaded('pdf');
      this._documentManagerService.save(true);
    });
  }

  /**
   * The function export the design in MP4 format, used when the editor is opened via integration.
   */
  private async _embedDownloadMp4() {
    this._modalProgressComponent.enableBroadcastProgressEvent();
    const settings = {
      name: FileQualityEnum.medium,
      title: `${FileQualityEnum.medium}`,
      range: null,
      modalMessage: this.saveButtonMessage,
    };
    this.signalsService.emit(enumSignals.ON_DOWNLOAD_SIGNAL, {
      type: 'mp4',
      data: settings,
    });
    this._designDownloadAnalytics.designDownloaded('mp4');
    this._documentManagerService.save(true);
  }

  getIsMagicTemplate(): void {
    const isMagic = this.document.is_magic ?? false;
    const isTemplate = this.document.is_template ?? false;

    this.isMagicTemplate = isMagic && isTemplate;
  }
}
