<section>
  <div class="modal-rename__container">
    <div class="modal-rename__header">
      <h3>{{ 'header.rename.modal.title' | translate }}</h3>
      <p>{{ 'header.rename.modal.subtitle' | translate }}</p>
    </div>

    <div class="modal-rename__input">
      <form [formGroup]="formGroup">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <mat-form-field
            floatLabel="never"
            appearance="outline"
            style="width: 100%;"
          >
            <input
              autocomplete="off"
              matInput
              placeholder="{{
                'header.rename.modal.document_name_placeholder' | translate
              }}"
              formControlName="title"
              type="input"
              (select)="disableHotkeys()"
              (focus)="disableHotkeys()"
              (blur)="enableHotkeys()"
            />
          </mat-form-field>
        </div>
      </form>
    </div>

    <div class="modal-rename__button">
      <button
        [style.marginRight.px]="16"
        class="button button--rename button--share tkt-button--min-width"
        [mat-dialog-close]="formGroup.controls['title'].value"
        cdkFocusInitial
      >
        {{ 'header.rename.modal.button_ok' | translate }}
      </button>
      <button
        class="button buttton--cancel button--outline button--primary tkt-button--min-width"
        [mat-dialog-close]="null"
      >
        {{ 'header.rename.modal.button_cancel' | translate }}
      </button>
    </div>
  </div>
</section>
