import { Injectable } from '@angular/core';

import { ImageUtilService } from '@services/image-util.service';
import { ElementModelFactory } from '@trakto/core-editor';
import { PageModel } from '@trakto/models';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root',
})
export class ElementFactoryService extends ElementModelFactory {
  constructor(
    private _localeService: LocaleService,
    imageUtilService: ImageUtilService,
  ) {
    super(imageUtilService.placeholderImage);
  }

  public getBadgeTrakto(page: PageModel) {
    return this.makeBadgeTraktoByLocale(page, this._localeService.currentLang);
  }
}
