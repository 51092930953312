import { Directive, HostBinding, Input } from '@angular/core';

@Directive()
export class TraktoElementDirective {
  @Input()
  zoomRatio = 1;

  @Input()
  ref: any;

  @HostBinding('attr.id')
  id: string;

  @HostBinding('class.not-printable')
  @Input()
  notPrintable = false;
}
