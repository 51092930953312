import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlValueAccessor, InputType } from './input.model';

import IMask from 'imask';

@Component({
  selector: 'trakto-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent
  implements OnInit, OnChanges, InputControlValueAccessor {
  private _id: string;
  private _name: string;
  private _type: InputType;
  private _value: any;
  private _placeholder: string;
  private _prefix: string;
  private _suffix: string;
  private _pattern: string;
  private _disabled: boolean;
  private _enableKeypress: boolean;
  private _typeOnLive: boolean;
  private _mask: boolean;
  private _url: boolean;
  private _delayTimer: any;

  @ViewChild('input', { static: true }) input;

  private baseFocusClass: string;

  @Input()
  public set value(value: any) {
    if (this.type === 'number') {
      this._value = this.isInt(value) ? value : parseFloat(value);
    } else {
      this._value = value || '';
    }
  }

  public get value() {
    return this._value || '';
  }

  @Input()
  public set typeOnLive(value: boolean) {
    this._typeOnLive = value !== undefined && value !== null ? value : false;
  }

  public get typeOnLive(): boolean {
    return this._typeOnLive;
  }

  @Input()
  public set maskPhone(value: boolean) {
    this._mask = value !== undefined && value !== null ? value : false;
  }

  public get maskPhone() {
    return this._mask;
  }
  @Input()
  public set maskURL(value: boolean) {
    this._url = value !== undefined && value !== null ? value : false;
  }

  public get maskURL() {
    return this._url;
  }

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || null;
  }

  @Input()
  public set id(id: string) {
    this._id = id;
  }

  public get id() {
    return this._id || '';
  }

  @Input()
  public set name(name: string) {
    this._name = name;
  }

  public get name() {
    return this._name || '';
  }

  @Input()
  public set type(type: InputType) {
    this._type = type;
  }

  public get type() {
    return this._type || InputType.text;
  }

  @Input()
  public set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  public get placeholder() {
    return this._placeholder || '';
  }

  @Input()
  public set prefix(prefix: string) {
    this._prefix = prefix;
  }

  public get prefix() {
    return this._prefix;
  }

  @Input()
  public set suffix(suffix: string) {
    this._suffix = suffix;
  }

  public get suffix() {
    return this._suffix;
  }

  @Input()
  public set enableKeypress(enableKeypress: boolean) {
    this._enableKeypress = enableKeypress || false;
  }

  public get enableKeypress() {
    return this._enableKeypress;
  }

  @Input()
  public set pattern(pattern: string) {
    this._pattern = pattern;
  }

  public get pattern() {
    return this._pattern || '^[A-Za-z0-9]*d+[A-Za-z0-9]*$';
  }

  @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  private _onChange = (value: any) => {};
  private _onTouched = () => {};

  constructor(private _elementRef: ElementRef) {
    this.baseFocusClass = '--has-focus';
  }

  ngOnInit() {
    this.toggleDisabled();

    if (!this.id) {
      throw new Error(
        `Input id is required. eg.: <trakto-input [id]="myId"></trakto-input>`,
      );
    }

    if (!this.name) {
      throw new Error(
        `Input name is required. eg.: <trakto-input [name]="myName"></trakto-input>`,
      );
    }
  }

  ngOnChanges() {
    this.toggleDisabled();
    this.applyMaskPhone();
    this.applyMaskURL();
  }

  public applyMaskPhone(): void {
    if (!this.maskPhone) {
      return;
    }
    const mask = IMask(this.input.nativeElement, { mask: '(00) 00000-0000' });
    mask.updateValue();
  }

  public applyMaskURL(): void {
    if (!this.maskURL) {
      return;
    }
    const mask = IMask(this.input.nativeElement, {
      mask: 'http://www.google.com.br',
    });
    mask.updateValue();
  }

  writeValue(value: any): void {
    this.change(value);
    this.onLoad.emit(this.value);
  }

  registerOnChange(fn: (p?: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (p?: any) => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  _getHostElement(): any {
    return this._elementRef.nativeElement;
  }

  change(value: any) {
    if (this.typeOnLive) {
      this.liveChange(value);
    }
    this.value = this.type === 'number' ? parseFloat(value) : value;
  }

  liveChange(value: any) {
    this.value = this.type === 'number' ? parseFloat(value) : value;
    this._onChange(this.value);
    this.onChange.emit(this.value);
  }

  focus(event: FocusEvent) {
    this.onFocus.emit(event);
    this._getHostElement().children[0].classList.add(this.baseFocusClass);
  }

  blur(event: FocusEvent) {
    if (!this.typeOnLive) {
      this.liveChange((event.target as any).value);
    }

    this.onBlur.emit(event);
    this._getHostElement().children[0].classList.remove(this.baseFocusClass);
  }

  keyChange(event: KeyboardEvent) {
    if (event.which === 13) {
      this.onKeyChange.emit(this.value);

      this._onChange(this.value);
      this.onChange.emit(this.value);
    }
  }

  keyPress(event: any) {
    if (this.enableKeypress) {
      clearTimeout(this._delayTimer);
      this._delayTimer = setTimeout(() => {
        this.onKeyChange.emit(this.value);

        this._onChange(this.value);
        this.onChange.emit(this.value);
      }, 300);
    }
  }

  toggleDisabled() {
    this.input.nativeElement.disabled = this.disabled ? true : false;
  }

  isInt(n: number) {
    return Number(n) === n && n % 1 === 0;
  }

  setFocus() {
    Promise.resolve().then(() => this.input.nativeElement.focus());
  }
}
