import { Injectable, OnDestroy } from '@angular/core';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import {
  IMediaOperations,
  IUploadImageModel,
  IUploadImageRequest,
  IUploadImageResult,
} from '@trakto/core-editor';
import { enumImageFolder } from '@trakto/graphics-resources';
import { ImageModel } from '@trakto/graphics-resources/dist/src/models/image.model';
import { IFontModel, IUser } from '@trakto/models';
import { Subject, from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { GalleryImageService } from './gallery/gallery.images.service';
import { GalleryIconsService } from './gallery/gallery.icons.service';
import { GalleryGifsService } from './gallery/gallery.gifs.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ItemRenderer } from '@shared/renderers/item-renderer.model';
import { FontRepository } from '@editor/repository/font.repository';
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable({ providedIn: 'root' })
export class MediaService implements IMediaOperations, OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    private _userService: UserService,
    private _uploadService: UploadStorageService,
    private _galleryImageService: GalleryImageService,
    private _galleryIconsService: GalleryIconsService,
    private _galleryGifsService: GalleryGifsService,
    private _http: HttpClient,
    private _fontRepository: FontRepository,
  ) { }

  public async downloadSVGByItemRenderer(item: ItemRenderer): Promise<string> {
    if (item.id) {
      return (await this._galleryIconsService.getSVGDownloadById(item.id)).svg;
    }
    return this.getSVG(item.svg);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  listEmojis(
    search: string,
    page?: number,
    limit?: number,
    lang?: string,
  ): Promise<any> {
    return this._galleryIconsService.listEmojis(search, page, limit);
  }

  listGifs(
    search: string,
    page?: any,
    limit?: number,
    lang?: string,
  ): Promise<any> {
    return this._galleryGifsService.listGifs(search, page, limit);
  }

  listImages(
    search: string,
    page?: number,
    limit?: number,
    lang?: string,
  ): Observable<any> {
    return this._galleryImageService.listImages(search, page, limit, lang);
  }

  listShapes(
    search: string,
    page?: number,
    limit?: number,
    lang?: string,
  ): Promise<any> {
    return this._galleryIconsService.listShapes(search, page, limit);
  }

  uploadImage(payload: IUploadImageModel): Promise<ImageModel> {
    return this._uploadService.uploadImage(payload);
  }

  public getSVG(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders();
      headers.set('Accept', 'image/svg+xml');
      this._http.get(url, { headers, responseType: 'text' }).pipe(takeUntil(this._destroy$)).subscribe(
        (data: any) => {
          resolve(data);
        },
        error => {
          console.error('Error 001', error);
        },
      );
    });
  }

  getSVGPathByUrl(url: string): Observable<string> {
    return from(this.getSVG(url)).pipe(
      map(svg => this.getPathFromSvg(svg as string)),
    );
  }

  public getPathFromSvg(svg: string): string {
    if (svg.indexOf('svg') === -1) {
      return svg;
    }
    const dp: DOMParser = new DOMParser();
    const xmlDoc = dp.parseFromString(svg, 'text/xml');

    const paths: HTMLCollectionOf<SVGPathElement> = xmlDoc.getElementsByTagName(
      'path',
    ) as HTMLCollectionOf<SVGPathElement>;

    const polygons: HTMLCollectionOf<SVGPolygonElement> =
      xmlDoc.getElementsByTagName(
        'polygon',
      ) as HTMLCollectionOf<SVGPolygonElement>;

    let pathString = ``;
    let pathStringPolygon = ``;
    let currentPath = ``;
    let currentPathPolygon = ``;

    for (let i = 0; i < paths.length; i += 1) {
      currentPath = paths.item(i).getAttribute('d');
      currentPath = globalThis.Snap.path
        .map(currentPath, new globalThis.Snap.Matrix().translate(0, 0))
        .toString();
      pathString += `${currentPath.trim()} `;
    }

    for (let i = 0; i < polygons.length; i += 1) {
      currentPathPolygon = polygons.item(i).getAttribute('points');
      currentPathPolygon = globalThis.Snap.path
        .map(currentPathPolygon, new globalThis.Snap.Matrix().translate(0, 0))
        .toString();
      pathStringPolygon += `${currentPathPolygon.trim()} `;
    }

    if (pathStringPolygon !== ``) {
      pathStringPolygon = `M${pathStringPolygon}z`;
    }

    return `${pathString.trim()}${pathStringPolygon}`;
  }

  async uploadImageByUrl(
    url: string,
  ): Promise<ImageModel> {
    const file = url;
    return this._uploadService.uploadImage({
      file,
      user: this._userService.user as IUser,
      folder: enumImageFolder.PREVENT,
    });
  }

  async uploadImageByFile(
    file: File,
  ): Promise<ImageModel> {
    return this._uploadService.uploadFile(file);
  }

  uploadImageByRequest(
    resource: IUploadImageRequest,
  ): Observable<IUploadImageResult> {
    const promise = new Promise(async (resolve, reject) => {
      const file = resource.high || resource.href;
      try {
        const imageData: any = await this._uploadService.uploadImage({
          file,
          user: this._userService.user as IUser,
          folder: enumImageFolder.PREVENT,
        });
        resolve(imageData.resolutions);
      } catch (e) {
        reject(e);
      }
    });

    return from(promise).pipe(
      map((result: any) => {
        return {
          low:
            result?.low?.secure_url ||
            result?.low?.url.replace('http://', 'https://'),
          medium:
            result?.medium?.secure_url ||
            result?.medium?.url.replace('http://', 'https://'),
          high:
            result?.high?.secure_url ||
            result?.high?.url.replace('http://', 'https://'),
          raw:
            result?.raw?.secure_url ||
            result?.raw?.url.replace('http://', 'https://'),
          href: result?.high?.secure_url || result?.result.secure_url,
        };
      }),
    );
  }

  getFontModelByFamily(fontFamily: string): Observable<IFontModel> {
    return from(this._fontRepository.findByFamily(fontFamily));
  }
}
