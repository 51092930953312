import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';



import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { PremiumService } from '@app/editor/services/premium.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { PlanConfigService } from '@shared/subscription/plan-config.service';
import { IFeatureBlockerData } from '@trakto/data-tracking/dist/interfaces/DataTracking.model';

import {
  downloadTypeEnum,
  IDocuments,
  IElementModel,
  PageModel,
  resolutionEnum,
} from '@trakto/models';
import { EnumModalComponent, } from '@trakto/trakto-premium';
import { DesignDownloadAnalyticsService } from '@services/analytics/wrapper/designDownload.analytics-wrapper.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { EventsTrack } from './download-event-names.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'trakto-panel-download',
  templateUrl: './panel-download.component.html',
})
export class PanelDownloadComponent implements OnInit, OnDestroy {
  public from: number;
  public to: number;
  public exportPngAvailable = true;
  public exportPdfAvailable = true;
  public exportVideoAvailable = true;

  @ViewChild('element', { static: true }) element: ElementRef;

  @Input() document: IDocuments;
  @Input() pageSelected: PageModel;
  @Input() elementSelected: IElementModel;
  @Input() b2c: boolean;

  public get isPDF(): boolean {
    return !!(
      this.currentDownload && this.currentDownload.type === downloadTypeEnum.pdf
    );
  }

  public get isPNG(): boolean {
    return !!(
      this.currentDownload && this.currentDownload.type === downloadTypeEnum.png
    );
  }

  public get isJPG(): boolean {
    return !!(
      this.currentDownload && this.currentDownload.type === downloadTypeEnum.jpg
    );
  }

  public get isMP4(): boolean {
    return !!(
      this.currentDownload && this.currentDownload.type === downloadTypeEnum.mp4
    );
  }

  public get isGIF(): boolean {
    return !!(
      this.currentDownload && this.currentDownload.type === downloadTypeEnum.gif
    );
  }

  public get isCurrentPage(): boolean {
    return !!(this.currentDownload && this.currentDownload.pages === 1);
  }

  public get isAllPages(): boolean {
    return !!(this.currentDownload && !this.currentDownload.pages);
  }

  public get isCustomPages(): boolean {
    return !!(this.currentDownload && this.currentDownload.pages === -1);
  }

  public get isLow(): boolean {
    return !!(
      this.currentDownload &&
      this.currentDownload.quality === resolutionEnum.low
    );
  }

  public get isMedium(): boolean {
    return !!(
      this.currentDownload &&
      this.currentDownload.quality === resolutionEnum.medium
    );
  }

  public get isHigh(): boolean {
    return !!(
      this.currentDownload &&
      this.currentDownload.quality === resolutionEnum.high
    );
  }

  public currentDownload: any;
  public userIsPremium = false;
  private _destroy$ = new Subject<void>();
  
  constructor(
    private _hotkeyService: HotkeyService,
    private _planConfigService: PlanConfigService,
    private _panelStackService: PanelStackService,
    private _signalsService: SignalsService,
    private _productService: WhitelabelProductService,
    private _premiumService: PremiumService,
    private _designDownloadAnalyticsService: DesignDownloadAnalyticsService,
  ) {
    this.from = 1;
    this.to = 1;

    this.currentDownload = {
      type: downloadTypeEnum.pdf,
      quality: resolutionEnum.medium,
      pages: null,
    };

    this._productService.featuresAvailable.pipe(takeUntil(this._destroy$)).subscribe(features => {
      if (features) {
        this._identifyFeaturesAvailable(features);
      }
    });

    this.checksUserIsPremium();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private _identifyFeaturesAvailable(features) {
    this.exportPngAvailable = features[ProductFeatures.EXPORT_PNG];
    this.exportVideoAvailable = features[ProductFeatures.EXPORT_VIDEO];
    this.exportPdfAvailable = features[ProductFeatures.EXPORT_PDF];
  }

  ngOnInit() {}

  private async checksUserIsPremium(): Promise<void> {
      const preminPlans = await this._planConfigService.getPlanPremium();
      const plan = await this._planConfigService.getUserPlanId();
      this.userIsPremium = preminPlans.includes(plan);
  }

  public selectType(type: string) {
    this.currentDownload.type = downloadTypeEnum[type];

    if (this.isPNG) {
      this.currentDownload.pages = 1;
    } else if (this.isPDF) {
      this.currentDownload.pages = null;
    }
  }

  public selectQuality(quality: string) {
    if (!this.isMP4) {
      this.currentDownload.quality = resolutionEnum[quality];
    }
  }

  public selectPages(pages: any) {
    if (
      this.isPDF ||
      (!this.isMP4 &&
        !this.isPNG &&
        this.isPNG &&
        (pages === -1 || pages === null))
    ) {
      this.currentDownload.pages = pages;
    }
  }

  public close(): void {
    this._panelStackService.pop(500);
  }

  public enableHotkeys() {
    this._hotkeyService.enableHotkeys();
  }

  public disableHotkeys() {
    this._hotkeyService.disableHotkeys();
  }

  public async download() {

    const resolutions = {
      '70': 'thumbnail',
      '96': 'low',
      '150': 'medium',
      '300': 'high',
    };

    let range: number[] = null;
    const title = (this.document.title || '').replace(/\./g, '_');

    const data = {
      resolution: this.currentDownload.quality,
      marks: false,
      title,
      name: resolutions[this.currentDownload.quality],
      range: null,
    };

    const payloadDataTracking = {
      document: this.document,
      metaData: data,
    };

    let action = '';
    let event = '';

    switch (this.currentDownload.type) {
      case 'pdf':
        event = EventsTrack.download_pdf;
        action = `export_${this.currentDownload.type}_${data.name}`;
        if (this.currentDownload.pages === 1) {
          range = [];
        } else if (this.currentDownload.pages === -1) {
          range = [this.from, this.to];
        }
        data.range = range;
        break;
      case 'png':
        event = EventsTrack.download_png;
        action = `export_${this.currentDownload.type}_${data.name}`;
        break;
      case 'mp4':
        event = EventsTrack.download_video;
        action = `export_video`;
        break;
      default:
        event = 'Editor - Gif exported' as EventsTrack;
        action = `export_gif_count`;
        break;
    }

    const hasPermission = await this._planConfigService.getPermissionPromise('use_template_premium');

    if (this.b2c && this.document.is_premium === true && !hasPermission) {
      const dataEvent: IFeatureBlockerData = {
        reason: 'feature blocker',
        actionBlocked: action,
        startedByBlocker: true,
      };
      this.openFeatureBlocker(dataEvent);
      return ;

    } else {
      this._signalsService.emit(enumSignals.ON_DOWNLOAD_SIGNAL, {
        type: this.currentDownload.type,
        data,
        payloadDataTracking,
      });
      this._designDownloadAnalyticsService.designDownloaded(
        this.currentDownload.type);
    }

  }

  verifyPermitionAndCallToAction(
    featureName: string,
    featureEmiter: () => any,
  ) {
    const { is_premium } = this.document;

    if (this.b2c) {
      if (is_premium && !this.userIsPremium) {
        this.openPremiumModalCheckout(EnumModalComponent.MODAL_PREMIUM);
      } else {
        this._planConfigService.getPermission(
          featureName,
          data => {
            if (data) {
              featureEmiter();
            } else {
              const dataEvent: IFeatureBlockerData = {
                reason: 'feature blocker',
                actionBlocked: featureName,
                startedByBlocker: true,
              };
              this.openFeatureBlocker(dataEvent);
            }
          },
          err => console.warn('err', err),
        );
      }
    } else {
      featureEmiter();
    }
  }

  openFeatureBlocker(dataEvent: IFeatureBlockerData) {
    this.openPremiumModalCheckout(EnumModalComponent.MODAL_TEMPLATES);
  }

  public async openPremiumModalCheckout(type?: EnumModalComponent) {
    const checkOutData = await this._premiumService.getCheckoutConfig();
    this._premiumService.openModalBlocking(
      type,
      checkOutData
    );
  }
}
