import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonPreviewComponent } from './button-preview.component';

@NgModule({
  declarations: [ButtonPreviewComponent],
  imports: [CommonModule],
  exports: [ButtonPreviewComponent],
})
export class ButtonPreviewModule {}
