import { Injectable, OnDestroy } from '@angular/core';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { IEventModel, PageModel } from '@trakto/models';
import { ReplaySubject, Subject } from 'rxjs';
import { DocumentManagerService } from './document-manager.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { takeUntil } from 'rxjs/operators';

export interface IBackgroundImageInfo {
  image?: string | null;
  high?: string | null;
  medium?: string | null;
  low?: string | null;
  raw?: string | null;
  opacity: number;
}

export interface IBackgroundInfo {
  color: string;
  filter: string;
  image: IBackgroundImageInfo;
}

@Injectable({
  providedIn: 'root',
})
export class ToggleTransparencyService implements OnDestroy{
  public backgroundInfo: IBackgroundInfo | null;
  public isToggleActive = false;
  public onToggleChange: Subject<boolean>;
  private _destroy$ = new Subject<void>();

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
  ) {
    this.onToggleChange = new ReplaySubject<boolean>(1);
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  initSubscriptions() {

    this._documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.resetBackgroundInfo();
    });

    this._signalsService
      .getSignal(enumSignals.PROPERTY_CHANGE)
      .pipe(takeUntil(this._destroy$))
      .subscribe((event: IEventModel) => {
        if (this.isBackgroundChange(event)) this.resetBackgroundInfo();
      });
  }

  isBackgroundChange(event: IEventModel) {
    const pageProperties = [
      'filter',
      'backgroundColor',
      'backgroundImageOpacity',
    ];

    const isPageProperty =
      event.data.obj?.type === 'page' &&
      pageProperties.includes(event.data.key);

    const isBackgroundImage = event.data.property === 'backgroundImage';

    const isToggleEvent = event.data.isToggleEvent;

    return !isToggleEvent && (isPageProperty || isBackgroundImage);
  }

  storeBackgroundInfo(page: PageModel): void {
    this.backgroundInfo = {
      filter: page.filter,
      image: {
        image: page.backgroundImage,
        low: page.backgroundImageLow,
        medium: page.backgroundImageMedium,
        high: page.backgroundImageHigh,
        raw: page.backgroundImageRaw,
        opacity: page.backgroundImageOpacity,
      },
      color: page.backgroundColor,
    };

    this.toggleChange(true);
  }

  toggleChange(value: boolean) {
    this.isToggleActive = value;
    this.onToggleChange.next(value);
  }

  resetBackgroundInfo() {
    this.backgroundInfo = null;
    this.toggleChange(false);
  }
}
