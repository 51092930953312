import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { downloadTypeEnum } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-swipe',
  templateUrl: './swipe-box.component.html',
  styleUrls: ['./swipe-box.component.scss'],
})
export class SwipeBoxComponent implements OnInit, OnDestroy {
  public translatePDF: string;
  public translateMP4: string;
  public translatePNG: string;

  @Input() actived: boolean;
  @Input() downloadMultiPages: boolean;
  @Input() downloadOnePage: boolean;
  @Input() page: any;

  @Output() typeDownload: EventEmitter<any> = new EventEmitter<any>();
  private _destroy$ = new Subject<void>();

  constructor(private _translateService: TranslateService) {}

  ngOnInit() {
    this._getTranslateSwipeOptionsDownload();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }


  private _getTranslateSwipeOptionsDownload() {
    this._translateService.get('mobile-presentation').pipe(takeUntil(this._destroy$)).subscribe(translates => {
      const { Download_in_MP4, Download_in_PDF, Download_in_PNG } = translates;

      this.translatePNG = Download_in_PNG;
      this.translatePDF = Download_in_PDF;
      this.translateMP4 = Download_in_MP4;
    });
  }

  public downloadType(type: string): void {
    switch (type) {
      case 'png':
        this.typeDownload.emit({
          type: downloadTypeEnum[type],
          page: this.page,
        });
        break;
      case 'pdf':
        this.typeDownload.emit({
          type: downloadTypeEnum[type],
          page: this.page,
        });
        break;
      case 'mp4':
        this.typeDownload.emit({
          type: downloadTypeEnum[type],
          page: this.page,
        });
        break;
    }
  }
}
