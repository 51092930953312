import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirebaseCloudFunctionsService } from './firebase-cloud-functions.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseCloudFunctionsServiceImpl extends FirebaseCloudFunctionsService {
  constructor(private fns: AngularFireFunctions) {
    super();
  }

  public callable(name: string): any {
    // this.fns.functions.useFunctionsEmulator('http://localhost:5001');
    return this.fns.httpsCallable(name);
  }

  public get createEmailPasswordUser(): any {
    return this.callable('createEmailPasswordUser');
  }
}
