<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/ajust_text.svg" label="Ajustes de texto"
    [showBackLabel]="true" (onClose)="closePanel()"></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="secondary-options">
      <trakto-panel-secondary-option-mobile icon="delete" (click)="deleteElement()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        image="auto-ajuste-icon"
        [active]="hasAutoScale"
        (click)="autoTuning()"
      >
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile image="background_color" (click)="setBackgroundColor()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile icon="block" [active]="!selectedElement?.edit" (click)="blockElement()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        image="text-underline"
        [active]="hasUnderline"
        (click)="setTextUnderline()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile icon="copy" (click)="duplicateElement()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        image="S"
        [active]="hasLineThrough"
        (click)="setTextStrike()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile icon="order" (click)="orderElement()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile icon="align" (click)="alignElement()">
      </trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile icon="transparency" (click)="setElementOpacity()">
      </trakto-panel-secondary-option-mobile>
    </div>

    <div class="primary-options">
      <div class="grid">
        <trakto-panel-option-mobile image="fonts" label="Fontes" (click)="changeFonts()"></trakto-panel-option-mobile>
        <trakto-panel-option-mobile image="font_size" label="Tamanho" (click)="changeFontsSize()"></trakto-panel-option-mobile>
        <trakto-panel-option-mobile image="font_style" label="Estilo de texto" (click)="changeFontsStyle()">
        </trakto-panel-option-mobile>
        <trakto-panel-option-mobile image="font_colors" label="Cor" (click)="changeFontsColors()">
        </trakto-panel-option-mobile>
        <trakto-panel-option-mobile image="font_group" label="Tipo de Texto" (click)="changeTypeText()">
        </trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          [active]="selectedElement?.filter === 'dropshadow'"
          image="font_shadow"
          label="Sombra"
          (click)="applyShadow()">
        </trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          [active]="selectedElement?.filter === 'gaussianblur'"
          image="font_blur"
          label="Desfocado"
          (click)="applyBlur()">
        </trakto-panel-option-mobile>
        <trakto-panel-option-mobile icon="link-element" label="Adicionar link" (click)="openLinksPanel()">
        </trakto-panel-option-mobile>
      </div>
    </div>
  </div>
</div>
