<div
  #textBackground
  *ngIf="!isPreviewDoc"
  [hidden]="!selectedTextElement"
  [style.transform]="'scale(' + zoomRatio + ')'"
  [style.width.px]="page.width"
  [style.height.px]="page.height"
  (mousedown)="localMousedown($event)"
  style="position: absolute; transform-origin: top left"
>
  <div
    traktoSvgTextEditorContainer
    [zoom]="zoomRatio"
    [hidden]="!selectedTextElement"
    [textElement]="selectedTextElement"
    [ref]="selection"
    (mousedown)="$event.stopPropagation()"
    (click)="$event.stopPropagation()"
    class="not-printable"
  >
    <div
      [attr.id]="page.id + '__text-editor-scrolling-container'"
      style="height: 100%; width: 100%"
    >
      <div
        #textEditor
        traktoSvgTextEditor
        [textElement]="selectedTextElement"
        [selection]="selection"
        [ref]="visibleElements"
        [attr.id]="page.id + '__text-editor'"
        (textUpdate)="
          selectionEngine?.resetSelection(selection?.getRawElementModels())
        "
        (mousedown)="$event.stopPropagation()"
        (click)="$event.stopPropagation()"
        (dblclick)="$event.stopPropagation()"
        class="click-ignore"
      ></div>
    </div>
  </div>
</div>

<svg
  #chart
  *ngIf="page"
  [traktoPageContainer]="page"
  [zoomRatio]="zoomRatio"
  [ref]="ref"
  [style.border-color]="
    pageFocused && !isPreviewDoc ? drawableConfigs.themeBorderColor : ''
  "
  (mousedown)="localMousedown($event)"
>
  <rect
    [traktoPageBgRect]="page"
    [ref]="ref"
    [zoomRatio]="zoomRatio"
  ></rect>

  <image
    *ngIf="page.backgroundImage"
    [traktoSvgFilter]="page.filter"
    [traktoPageBgImage]="page"
    [ref]="ref"
  ></image>

  <g
    trakto-drawables-container
    [page]="page"
    [isB2C]="isB2C"
    [elements]="elements"
    [hasWaterMask]="hasWaterMask"
    [visibleElements]="visibleElements"
    [zoomRatio]="zoomRatio"
    [selectedTextElement]="selectedTextElement"
    [moving]="moving"
    [resizing]="selectedResizePoint !== undefined"
    (clickedVideo)="openOnPreviewDoc($event.element)"
  ></g>

  <ng-container *ngFor="let metaElement of visibleElements">
    <!-- Região clicável pelo usuário para selecionar um elemento -->
    <path
      *ngIf="
        !moving &&
        !selectedResizePoint &&
        metaElement.hover &&
        !selection?.hasElement(metaElement.element) &&
        !isPreviewDoc
      "
      [traktoPath]="metaElement.hoverElement"
      [resizeMode]="true"
      [strokeColor]="metaElement.clickableBorderColor"
      [ref]="metaElement.hoverElement.finalMatrixStr"
      [attr.transform]="metaElement.hoverElement.finalMatrixStr"
    ></path>

    <path
      *ngIf="!moving && !selectedResizePoint && !isPreviewDoc"
      [traktoPath]="metaElement.clickableElement"
      [resizeMode]="true"
      [strokeColor]="metaElement.clickableBorderColor"
      [ref]="metaElement.clickableElement.finalMatrixStr"
      [attr.transform]="metaElement.clickableElement.finalMatrixStr"
      (mouseover)="metaElement.hover = true"
      (mouseleave)="metaElement.hover = false"
      (mousedown)="selectElementByClick($event, metaElement)"
    ></path>

    <!-- Região para iniciar o movimento dos elementos selecionados -->
    <path
      *ngIf="
        metaElement.selected &&
        selection?.element?.lockedByTemplate !== true &&
        !isPreviewDoc
      "
      [traktoPath]="metaElement.clickableElement"
      [resizeMode]="true"
      [strokeColor]="metaElement.themeBorderColor"
      [ref]="metaElement.clickableElement.finalMatrixStr"
      [attr.transform]="metaElement.clickableElement.finalMatrixStr"
      (dblclick)="showMore()"
      (mousedown)="initSelectionMove($event, metaElement)"
      (touchstart)="handleTap($event, metaElement)"
    ></path>
  </ng-container>

  <g *ngIf="!isPreviewDoc" [class.hidden]="selection?.isEmpty()">
    <!-- Box de suporte. Retângulo que envolve a seleção para manipulação -->
    <path
      [traktoPath]="selection?.element"
      [resizeMode]="true"
      [clickableMode]="false"
      [strokeColor]="getStrokeColorLocked()"
      [strokeDasharray]="getStrokeDasharrayLocked()"
      [ref]="selection?.element.supportPath"
      [attr.transform]="selection?.element?.finalMatrixStr"
    ></path>

    <g [class.hidden]="moving || selectedResizePoint">
      <ng-container *ngIf="selection?.element?.lockedByTemplate !== true">
        <ng-container *ngFor="let rp of selection?.resizeEngine.resizePoints">
          <path
            [traktoPath]="rp.backgroundShape"
            [attr.transform]="rp.backgroundShape.finalMatrixStr"
            [zoomRatio]="zoomRatio"
            [ref]="selection?.element.supportPath"
            [notPrintable]="true"
            [attr.resize-type]="rp.type"
            (mousedown)="selectResizePointByClick(rp)"
            (touchstart)="selectResizePointByClick(rp)"
            class="support-point"
          ></path>
          <path
            [traktoPath]="rp.shape"
            [attr.transform]="rp.shape.finalMatrixStr"
            [zoomRatio]="zoomRatio"
            [clickableMode]="false"
            [ref]="selection?.element.supportPath"
            [ngClass]="rp.className"
            [notPrintable]="true"
          ></path>
        </ng-container>
      </ng-container>

      <g
        *ngIf="
          selection?.element?.edit === false ||
          selection?.element?.lockedByTemplate === true
        "
        [attr.id]="page.id + '__locked-icon'"
        [attr.transform]="
          selection?.element?.positionMatrixStr +
          'translate(' +
          selection?.element.supportWidth * selection?.element.scaleX +
          ', 0) scale(' +
          1 / zoomRatio +
          ')'
        "
      >
        <!-- <circle [attr.cx]="0"
          [attr.cy]="0"
          [attr.r]="blockedPathBBox.height / 2"
          fill="white"
          class="not-printable"
          width="0">
        </circle> -->
        <path
          [attr.d]="blockedPathIcon"
          [attr.fill]="drawableConfigs.lockedEditionColor"
        ></path>
      </g>
    </g>
  </g>

  <rect
    *ngIf="mouseSelection"
    [attr.x]="mouseSelection.basePoint.x"
    [attr.y]="mouseSelection.basePoint.y"
    [attr.width]="mouseSelection.width"
    [attr.height]="mouseSelection.height"
    [attr.fill]="drawableConfigs.themeBorderColor"
    [attr.stroke]="drawableConfigs.themeBorderColor"
    [attr.stroke-width]="supportBorderSize"
    fill-opacity="0.4"
    stroke="transparent"
    class="not-printable"
  ></rect>

  <ng-container *ngIf="cutMarginConfig">
    <path
      [attr.d]="cutMarginConfig.path"
      class="security-mark"
      fill="none"
      stroke-opacity="0.25"
      stroke="#000000"
      stroke-width="1"
      vector-effect="non-scaling-stroke"
    ></path>
    <line
      *ngFor="let line of cutMarginConfig.lines"
      [attr.x1]="line.start.x"
      [attr.y1]="line.start.y"
      [attr.x2]="line.end.x"
      [attr.y2]="line.end.y"
      class="line-mark"
      stroke-opacity="1"
      stroke="#ff0000"
      stroke-width="1"
    ></line>
  </ng-container>

  <ng-container
    *ngIf="moving && selection?.guideLinesEngine.enabledGuideLines.length > 0"
  >
    <ng-container
      *ngFor="let guideLine of selection?.guideLinesEngine.enabledGuideLines"
    >
      <line
        *ngFor="let line of guideLine.drawableLines"
        [attr.x1]="line.start.x"
        [attr.y1]="line.start.y"
        [attr.x2]="line.end.x"
        [attr.y2]="line.end.y"
        [attr.stroke-dasharray]="guideLine.strokeStyle"
        [attr.stroke-width]="guideLine.strokeWidth"
        stroke="#F670F6"
        vector-effect="non-scaling-stroke"
      ></line>
    </ng-container>
  </ng-container>

  <text
    *ngIf="mousePosition?.supportPoint?.type === 'rotation'"
    [attr.x]="mousePosition.x + 10"
    [attr.y]="mousePosition.y"
    [attr.font-size]="supportFontSize * 1.5"
    [attr.fill]="drawableConfigs.themeBorderColor"
    class="text-rotate-angule"
  >
    {{ mousePosition.angule }}
  </text>
</svg>
<trakto-text-menu-inline
  *ngIf="!moving && !this.selectedResizePoint && selectedTextBySingleClick && !isMobile"
  traktoMenuInlinePositioner
  [textElement]="selectedTextBySingleClick"
  [ref]="selection?.transform"
  [selection]="selection"
  [ref1]="selection"
  [page]="page"
  class="click-ignore"
></trakto-text-menu-inline>

<trakto-text-edition-button
  *ngIf="!moving && !this.selectedResizePoint && selectedTextBySingleClick && isMobile"
  [changing]="selectedTextElement"
  [textElement]="selectedTextBySingleClick"
  [ref]="selection?.transform"
  [selection]="selection"
  (mousedown)="requestTextEdition($event)"
  (touchstart)="requestTextEdition($event)"
  class="click-ignore"
></trakto-text-edition-button>
