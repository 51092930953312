import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NewModelService } from '@services/new-model.service';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import { ITextElementModel } from '@trakto/models';

@Directive({
  selector: '[traktoSvgTextEditorContainer]',
})
export class SvgTextEditorContainerDirective implements OnInit, OnChanges {
  @Input()
  private textElement: ITextElementModel;

  @Input()
  private ref: any;

  @HostBinding('style.width.px')
  width: number;

  @HostBinding('style.height.px')
  height: number;

  @HostBinding('style.margin-top.px')
  marginTop = 0;

  @HostBinding('style.overflow')
  overflow = 'initial';

  @HostBinding('style.position')
  position = 'absolute';

  @Input()
  // @HostBinding('style.zoom')
  zoom = 1;

  // @HostBinding('style.transform')
  transform: string;

  overlay: any;

  constructor(
    private modelConverterService: NewModelService,
    private newElementTextService: TextElementService,
    private textService: TextService,
    private elementRef: ElementRef,
    private render2: Renderer2,
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.textElement) {
      if (this.overlay && !this.overlay.classList.contains('active')) {
        this.overlay.classList.add('active');
      }

      const halfLeading = this.textService.getFontHalfLeading(this.textElement);
      this.width = this.textElement.supportWidth;
      this.height = this.textElement.supportHeight + 2 * halfLeading;
      if (this.marginTop !== -halfLeading) {
        this.marginTop = -halfLeading;
      }
      this.transform = [
        `translate(
          ${this.textElement.cx - this.textElement.supportWidth / 2}px,
          ${this.textElement.cy - this.textElement.supportHeight / 2}px
        )`,
        `rotate(${this.textElement.rotation}deg) `,
        `scale(${this.textElement.scaleX}, ${this.textElement.scaleY})`,
      ].join('');
      this.render2.setStyle(
        this.elementRef.nativeElement,
        'transform',
        this.transform,
      );
    } else {
      if (this.overlay) {
        this.overlay.classList.remove('active');
      }

      this.width = undefined;
      this.height = undefined;
      this.transform = undefined;
    }
  }
}
