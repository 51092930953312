<div class="trakto-document-item-renderer">
  <div class="trakto-document-item-renderer__select">
    <trakto-checkbox
      id="1"
      name="select"
      [(ngModel)]="item.selected"
      (onChange)="actionHandler('select')"
    ></trakto-checkbox>
  </div>

  <div class="trakto-document-item-renderer__details">
    <h2 *ngIf="!editMode" (click)="actionHandler('click')">{{ item.title }}</h2>
    <input
      class="trakto-document-item-renderer__input"
      #editInput
      *ngIf="editMode"
      [value]="item.title"
      (blur)="editModeCompleted(editInput.value)"
      (keydown.enter)="editModeCompleted(editInput.value)"
      placeholder="Type your document name"
    />
    <span>{{ item.updated_at }}</span>
  </div>

  <div class="trakto-document-item-renderer__actions">
    <trakto-button
      button--icon
      without--margin
      without--padding
      traktoTooltip="Rename"
      (onClick)="editTitle()"
    >
      <i class="trakto-icon-edit"></i>
    </trakto-button>

    <trakto-button
      button--icon
      without--margin
      without--padding
      traktoTooltip="Visualize"
      (onClick)="actionHandler('visualize')"
      [disabled]="true"
    >
      <i class="trakto-icon-video"></i>
    </trakto-button>

    <trakto-button
      button--icon
      without--margin
      without--padding
      [disabled]="true"
      traktoTooltip="Copiar link para área de transfência"
      (onClick)="actionHandler('copyLink')"
    >
      <i class="trakto-icon-clothes"></i>
    </trakto-button>

    <trakto-button
      button--icon
      without--margin
      without--padding
      traktoTooltip="Download"
      (onClick)="actionHandler('download')"
      [disabled]="true"
    >
      <i class="trakto-icon-download"></i>
    </trakto-button>

    <trakto-button
      button--icon
      without--margin
      without--padding
      traktoTooltip="Excluir"
      (onClick)="actionHandler('remove')"
    >
      <i class="trakto-icon-trash"></i>
    </trakto-button>

    <trakto-button
      class="trakto-document-item-renderer__clone"
      button--outline
      button--light
      traktoTooltip="Duplicar o documento"
      (onClick)="actionHandler('clone')"
    >
      <i class="trakto-icon-clone"></i> Clone
    </trakto-button>
  </div>
</div>
