import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

/**
 * Uma diretiva auxiliar para focar os botões. Você só precisará disso se estiver usando um modelo personalizado
 */
@Directive({
  selector: '[traktoFocus]',
})
export class FocusDirective implements OnChanges {
  @Input() traktoFocus: boolean;

  constructor(private elm: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.traktoFocus && this.traktoFocus === true) {
      this.elm.nativeElement.focus();
    }
  }
}
