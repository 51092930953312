import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[traktoHoverUtil]',
})
export class HoverUtilDirective implements OnInit {
  @Input()
  private hoverColor: string;

  private lastColor: string;

  constructor(private ref: ElementRef, private rendered: Renderer2) {}

  ngOnInit() {
    this.lastColor = getComputedStyle(this.ref.nativeElement).getPropertyValue(
      'color',
    );
  }

  @HostListener('mouseover')
  onHover() {
    if (this.hoverColor) {
      this.rendered.setStyle(this.ref.nativeElement, 'color', this.hoverColor);
    }
  }

  @HostListener('mouseout')
  onOut() {
    if (this.hoverColor) {
      this.rendered.removeStyle(this.ref.nativeElement, 'color');
    }
  }
}
