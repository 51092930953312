import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperProgressComponent } from './stepper-progress.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';


@NgModule({
  declarations: [
    StepperProgressComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatProgressBarModule,
    MatStepperModule,
    CdkStepperModule,
  ],
  exports: [
    StepperProgressComponent,
  ]
})
export class StepperProgressModule { }
