import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadComponent } from './load/load.component';

import { LoaderDirective } from './loader.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderDirective, LoadComponent],
  exports: [LoaderDirective, LoadComponent],
})
export class LoaderModule {}
