<trakto-banner-discount *ngIf="showBannerToUser"></trakto-banner-discount>

<div class="trakto-editor" *ngIf="document" [ngClass]="{ 'trakto-editor--is_banner_visible': showBannerToUser }">
  <trakto-header
    #traktoHeader
    [hidden]="newHeader"
    [b2c]="b2c"
    [document]="document"
    [pageSelected]="pageSelected"
    [elementSelected]="elementSelected"
    (onShare)="onShare($event)">
  </trakto-header>

  <trakto-header-v2
    #traktoHeader2
    [hidden]="!newHeader"
    [b2c]="b2c"
    [document]="document"
    [pageSelected]="pageSelected"
    [elementSelected]="elementSelected">
  </trakto-header-v2>

  <div [style.height]="
      'calc(100% - ' +
      (traktoHeader.elementRef.nativeElement.offsetHeight || traktoHeader2.elementRef.nativeElement.offsetHeight) +
      'px)'
    " class="trakto-workspace">
    <trakto-toolbar
      [showBannerToUser]="showBannerToUser"
      >
    </trakto-toolbar>

    <trakto-preview-2 #traktoPreview>
    </trakto-preview-2>

    <trakto-properties-panel
      [b2c]="b2c">
    </trakto-properties-panel>
  </div>
</div>
<trakto-modal-bella *ngIf="b2c"></trakto-modal-bella>
