import { Injectable } from '@angular/core';
import { TraktoApiService } from '@services/trakto-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _currentProduct: any = null;

  constructor(private _traktoApiService: TraktoApiService) {}

  async getCurrentProduct(): Promise<any> {
    if (!this._currentProduct) {
      this._currentProduct = await this._traktoApiService.executeGet(
        'product/current',
      );
    }

    return this._currentProduct;
  }
}
