<header class="trakto-header-v2">
  <section class="trakto-header__logo" style="cursor: pointer">
    <a (click)="backToEditor($event)">
      <!-- B2B Logo  -->
      <ng-container *ngIf="!b2c || isDropboxInstance">
        <img [src]="logo" alt="Logo image" />
      </ng-container>

      <!-- if b2c && isPremiumUser && !isDropboxInstance // B2C Logo Premium -->
      <ng-container *ngIf="b2c && isPremiumUser && !isDropboxInstance">
        <img [src]="traktoPremiumLogo" alt="Logo Premium Trakto" />
      </ng-container>

      <!-- if b2c && !isPremiumUser // B2C Logo -->
      <ng-container *ngIf="b2c && !isPremiumUser && !isDropboxInstance">
        <img [src]="imageTrakto" alt="Logo image" />
      </ng-container>
    </a>
  </section>

  <div class="middle">
    <section class="bePro">
      <span
        *ngIf="b2c && !isPremiumUser"
        (click)="stripePlanRenewalPortal()"
        style="display: flex; align-items: center; cursor: pointer"
      >
        <p>Seja</p>
        &nbsp;<span>PRO</span>
        <img src="../../../../../assets/icons/seja-pro.svg" alt="" />
      </span>

      <section class="autosave">
        <trakto-auto-save-indicator #AutoSaveIndicator [b2c]="true">
        </trakto-auto-save-indicator>
      </section>

      <section class="autosave">
        <div class="history temp-hidden">
          <i
            class="icon icon-undo"
            (click)="toggleUndo()"
            [ngClass]="{ disabled: !isUndoAvailable }"
          ></i>
          <i
            class="icon icon-redo"
            (click)="toggleRedo()"
            [ngClass]="{ disabled: !isRedoAvailable }"
          ></i>
        </div>
      </section>
    </section>

    <section class="title">
      <input
        type="text"
        id="title"
        [(ngModel)]="title"
        [value]="title"
        [disabled]="isRequesting"
        (focus)="disableHotkeys($event)"
        (blur)="inputChange()"
        (keyup.enter)="inputChange()"
      />
    </section>

    <section class="publish" *ngIf="isGlobalUser || isTeamManager">
      <div *ngIf="false">
        <button class="btnIsMagic" (click)="openPanelStack('magic_template')">
          <img src="../../../../../assets/icons/star-linear.svg" alt="" />
        </button>
      </div>
      <div>
        <button class="btnPublish" (click)="openPanelStack('publish')">
          <img src="../../../../../assets/icons/uil_rocket.svg" alt="" />
          &nbsp;Publicar
        </button>
      </div>
    </section>
  </div>

  <div class="action">
    <section class="share" style="width: 25%">
      <button
        class="btnShare"
        (click)="modal.toggle(); modalOpen = true; disablehotkeys()"
      >
        <img src="../../../../../assets/icons/export.svg" alt="" />
        &nbsp;Compartilhar
      </button>
    </section>

    <section class="play" style="width: 25%">
      <button class="btnPlay" (click)="openToPreview()">
        <img src="../../../../../assets/icons/play-header-v2.svg" alt="" />
        &nbsp;Play
      </button>
    </section>

    <section class="download" style="width: 25%">
      <button class="btnDownload" (click)="openPanelStack('download')">
        <img src="../../../../../assets/icons/download-header-v2.svg" alt="" />
        &nbsp;Baixar
      </button>
    </section>
    <section class="download" style="width: 25%">
      <button class="btnDownload" (click)="validateRefreshToken()">
        <img src="../../../../../assets/icons/google-ads-md.svg" alt="" width="14px" height="14px"/>
        &nbsp;{{'buttons.sendToGoogleAds' | translate}}
      </button>
    </section>
  </div>
</header>

<trakto-trakto-link-modal
  #modal
  (onModalShareChange)="shareModalChange($event)"
></trakto-trakto-link-modal>
