import { Component, Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DrawableComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-gif]',
  template: `
    <svg:g
      [innerHTML]="gifSvg"
      [attr.opacity]="element.opacity"
      [attr.transform]="element.finalMatrixStr"
    ></svg:g>
  `,
})
export class DrawableGifComponent
  extends DrawableComponent
  implements OnChanges {
  public gifSvg: SafeHtml;

  constructor(public sanitizer: DomSanitizer) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (!this.element) {
      return;
    }
    this.gifSvg = this.sanitizer.bypassSecurityTrustHtml(this.normalizeSvg());
  }

  private normalizeSvg() {
    const { svg, supportWidth, supportHeight } = this.element;
    return svg.replace(
      '<svg',
      `<svg width="${supportWidth}" height="${supportHeight}"`,
    );
  }
}
