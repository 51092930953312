import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-color-panel-separator',
  template: `<div class="trakto-color-panel-separator">
    <hr />
    <span>{{ title }}</span>
  </div>`,
  styleUrls: ['./color-panel-separator.component.scss'],
})
export class ColorPanelSeparatorComponent {
  @Input()
  title: string;

  constructor() {}
}
