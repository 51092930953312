import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { ITextElementModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-size-mobile',
  templateUrl: './panel-size-mobile.component.html',
  styleUrls: ['./panel-size-mobile.component.scss']
})
export class PanelSizeMobileComponent implements OnInit {

  fontSize = 100;

  subscription: Subscription;

  constructor(
    private _documentStateManagerService: DocumentStateManagerService,
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this._documentStateManagerService.element$.subscribe((element: ITextElementModel) => {
      this.fontSize = element.fontSize;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  setFontSize(val: number) {
    this.fontSize = val;
    this._elementsService.changeFontSize(val);
  }

}
