import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

interface IPropDownOptions {
  value: any;
  text: string;
  selected: boolean;
}

@Component({
  selector: 'trakto-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],
})
export class DropdownSelectorComponent implements OnInit {
  @Input() opts: any[] = [];
  @Input() selected: number;
  @Input() className = 'dropdown-wrapper dropdown_selector';
  @Input() labelName: string;
  @Input() placeholder: string;
  @Input() activePosition = '';
  @Input() isReadOnly = false;
  @Output() optSelect = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  isOpen = false;
  selectedOption;
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  activeItem: any;
  hasActiveItem = false;

  constructor() {}

  ngOnInit() {
    // Place default value in dropdown
    this.activeItem = this.opts.filter(item => !!item.selected)[0];
    this.hasActiveItem = this.activeItem !== undefined;
  }

  /**
   * option selection
   * @param {IPropDownOptions} selectedOption - text
   * @param {number} idx - current index of item
   * @param {MouseEvent} event -
   */
  optionSelect(selectedOption: IPropDownOptions, e: MouseEvent) {
    e.stopPropagation();
    this.activeItem = selectedOption;
    this.isOpen = false;
    this.valueChange.emit(selectedOption);
  }

  /**
   * toggle the dropdown
   * @param {MouseEvent} event
   */
  toggle(e: MouseEvent) {
    e.stopPropagation();
    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');

    for (const elem of allElems) {
      elem.classList.remove('is-open');
    }

    this.isOpen = !this.isOpen;

    if (this.selected >= 0) {
      document.querySelector('#li' + this.selected).scrollIntoView(true);
    }
  }

  /**
   * dropdown click on outside
   */
  @HostListener('document: click', ['$event'])
  onClick() {
    this.isOpen = false;
  }
}
