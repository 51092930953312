import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trakto-preview-renderer',
  templateUrl: './preview-renderer.component.html',
  styleUrls: ['./preview-renderer.component.scss'],
})
export class PreviewRendererComponent {
  private _format: any;

  @Input()
  set format(value: any) {
    this._format = value;
  }

  get format(): any {
    return this._format;
  }

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  click(event: MouseEvent): void {
    this.onClick.emit(event);
  }
}
