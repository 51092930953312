import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit, OnDestroy {
  showOverlay = false;
  showDragIcon = false;

  private _onChangeTiers: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    public elementRef: ElementRef,
    private _panelService: PanelService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onChangeTiers = this._panelService.onChangeTiers.subscribe(tiers => {
      this.handleEffects();
      this.showDragIcon = tiers.length > 1;
      this.elementRef.nativeElement.style.height = tiers[0] + 'px';
    });

    this._subscriptions.push(this._onChangeTiers);
  }

  handleEffects() {
    this.elementRef.nativeElement.style.transition = 'height 0.1s ease-in 0s';

    setTimeout(() => {
      this.elementRef.nativeElement.style.transition = 'unset';
    }, 500);
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  toggleOverlay(show: boolean) {
    if (this.showOverlay !== show) this.showOverlay = show;
  }
}
