export enum InputType {
  text = 'text',
  email = 'email',
  phone = 'phone',
  number = 'number',
  password = 'password',
}

export interface InputControlValueAccessor {
  writeValue(obj: any): void;
  registerOnChange(fn: any): void;
  registerOnTouched(fn: any): void;
  setDisabledState(isDisabled: boolean): void;
}
