import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-panel-secondary-option-mobile',
  templateUrl: './panel-secondary-option-mobile.component.html',
  styleUrls: ['./panel-secondary-option-mobile.component.scss'],
})
export class PanelSecondaryOptionMobileComponent implements OnInit {
  @Input() icon: string = '';
  @Input() active: boolean = false;
  @Input() image: string = null;

  constructor() {}

  ngOnInit(): void {}
}
