import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadModalService } from '@app/editor-v3/services/loader-modal.service';

@Component({
  selector: 'trakto-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit {

  @Input() title: string = '';

  constructor() {}

  ngOnInit(): void {}
}
