import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const defer = true;

@Injectable({
  providedIn: 'root',
})
export class GoSquaredService {
  constructor() {}

  /**
   * To inject Go Squared Script on Application instance
   * @param token: GoSquared project's token
   * @param localhostMode: Defines if the project is running locally
   */
  public configure(token: string, productionMode: boolean = true) {
    if (this.isAvailable()) {
      let goSquaredTrackingScript = `
            !function(g,s,q,r,d){r=g[r]=g[r]||function(){(r.q=r.q||[]).push(arguments)};
            d=s.createElement(q);d.src='//d1l6p2sc9645hc.cloudfront.net/gosquared.js';q=
            s.getElementsByTagName(q)[0];q.parentNode.insertBefore(d,q)}(window,document
            ,'script','_gs');
            _gs('${token}');
            _gs('set', 'anonymizeIP', true);
          `;

      if (!productionMode) {
        goSquaredTrackingScript = `
              ${goSquaredTrackingScript}
              _gs('set', 'trackLocal', true);
            `;
      }
      const goSquared = document.createElement('script');
      goSquared.defer = defer;
      goSquared.innerHTML = goSquaredTrackingScript;
      document.head.appendChild(goSquared);
    }
  }

  /**
   * Identify the user on GoSquared Platform
   * @param userId: Unique id from user
   * @param email: account email used by user
   */
  public init(userId: string, userEmail: string) {
    if (this.isAvailable()) {
      this.showAssistant(false);
      const goSquaredInstance = this._gsInstance();
      if (goSquaredInstance) {
        goSquaredInstance('identify', {
          email: userEmail,
          userId,
        });
      }
    }
  }

  /**
   * Receive the GoSquared instanced on browser
   */
  private _gsInstance() {
    return window['_gs'];
  }

  /**
   * Enable the chat component on page
   * @param show: true for show and false for hide chat
   */
  public showAssistant(show: boolean = true) {
    if (this.isAvailable()) {
      this._gsInstance()('set', 'chat', {
        button: show,
      });
    }
  }

  /**
   * Opens chat and start a new conversation with support
   */
  public startConversation() {
    if (this.isAvailable()) {
      this._gsInstance()('chat', 'show');
    }
  }

  /**
   * Close the chat started previously
   */
  public endConversation() {
    if (this.isAvailable()) {
      this._gsInstance()('chat', 'hide');
    }
  }

  /**
   * Define the language on chat
   * @param language: Values supported are pt-BR, en, es-ES. The default language is pt-BR
   */
  public setAssistantLanguage(language: 'en' | 'pt-BR' | 'es' | 'ko' | 'de' = 'pt-BR') {
    if (this.isAvailable()) {
      this._gsInstance()('set', 'chat', {
        locale: language,
      });
    }

  }

  public isAvailable(): boolean {
    return environment.externalIntegrations.gosquared;
  }
}
