import { NgModule } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EditorV3Component } from './editor-v3.component';
import { HeaderComponent } from './components/header/header.component';
import { ButtonLinkComponent } from './components/shared/button-link/button-link.component';
import { SvgEditorModule } from '@editor/components/preview-2/svg-editor/svg-editor.module';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { ResizableDirective } from './directives/resizable.directive';
import { PanelComponent } from './components/panel/panel.component';
import { PageNavigatorComponent } from './components/workspace/components/page-navigator/page-navigator.component';
import { SliderComponent } from './components/shared/slider/slider.component';
import { PageGridComponent } from './components/workspace/components/page-navigator/components/page-grid/page-grid.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ConfirmationComponent } from './components/shared/confirmation/confirmation.component';
import { RenderSvgIconComponent } from './components/shared/render-svg-icon/render-svg-icon.component';
import { PanelHomeMobileComponent } from './components/panel/components/mobile/panel-home-mobile/panel-home-mobile.component';
import { PanelHeaderMobileComponent } from './components/shared/panel-header-mobile/panel-header-mobile.component';
import { PanelPageMobileComponent } from './components/panel/components/mobile/panel-page-mobile/panel-page-mobile.component';
import { PanelOptionMobileComponent } from './components/shared/panel-option-mobile/panel-option-mobile.component';
import { PanelAddElementsMobileComponent } from './components/panel/components/mobile/panel-add-elements-mobile/panel-add-elements-mobile.component';
import { PanelImageMobileComponent } from './components/panel/components/mobile/panel-image-mobile/panel-image-mobile.component';
import { SearchTabMobileComponent } from './components/panel/components/mobile/panel-image-mobile/components/search-tab-mobile/search-tab-mobile.component';
import { LibraryTabMobileComponent } from './components/panel/components/mobile/panel-image-mobile/components/library-tab-mobile/library-tab-mobile.component';
import { ButtonLoaderComponent } from './components/shared/button-loader/button-loader.component';
import { GridImagesComponent } from './components/shared/grid-images/grid-images.component';
import { PanelPropertiesImageMobileComponent } from './components/panel/components/mobile/panel-properties-image-mobile/panel-properties-image-mobile.component';
import { PanelSecondaryOptionMobileComponent } from './components/shared/panel-secondary-option-mobile/panel-secondary-option-mobile.component';
import { PanelOrderMobileComponent } from './components/panel/components/mobile/panel-order-mobile/panel-order-mobile.component';
import { PanelAlignMobileComponent } from './components/panel/components/mobile/panel-align-mobile/panel-align-mobile.component';
import { PanelOpacityMobileComponent } from './components/panel/components/mobile/panel-opacity-mobile/panel-opacity-mobile.component';
import { PanelActionMobileComponent } from './components/shared/panel-action-mobile/panel-action-mobile.component';
import { PanelMaskMobileComponent } from './components/panel/components/mobile/panel-mask-mobile/panel-mask-mobile.component';
import { InputComponent } from './components/shared/input/input.component';
import { TagListComponent } from './components/shared/tag-list/tag-list.component';
import { DragDropDirective } from './components/panel/components/mobile/panel-page-mobile/directives/drag-drop.directive';
import { PanelFiltersMobileComponent } from './components/panel/components/mobile/panel-filters-mobile/panel-filters-mobile.component';
import { SvgFilterDirective } from './directives/svg-filter.directive';
import { BackgroundRemovalCardComponent } from './components/panel/components/mobile/panel-properties-image-mobile/components/background-removal-card/background-removal-card.component';
import { PanelLinksMobileComponent } from './components/panel/components/mobile/panel-links-mobile/panel-links-mobile.component';
import { PanelWebsiteMobileComponent } from './components/panel/components/mobile/panel-website-mobile/panel-website-mobile.component';
import { PanelWhatsappMobileComponent } from './components/panel/components/mobile/panel-whatsapp-mobile/panel-whatsapp-mobile.component';
import { PanelEmailMobileComponent } from './components/panel/components/mobile/panel-email-mobile/panel-email-mobile.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { PanelElementsMobileComponent } from './components/panel/components/mobile/panel-elements-mobile/panel-elements-mobile.component';
import { InputSuggestionsComponent } from './components/shared/input-suggestions/input-suggestions.component';
import { SearchResultsComponent } from './components/panel/components/mobile/panel-elements-mobile/components/search-results/search-results.component';
import { FormatsResultsComponent } from './components/panel/components/mobile/panel-elements-mobile/components/formats-results/formats-results.component';
import { PacknamePipe } from './components/panel/components/mobile/panel-elements-mobile/pipes/packname.pipe';
import { ThemeResultsComponent } from './components/panel/components/mobile/panel-elements-mobile/components/theme-results/theme-results.component';
import { PanelShapeMobileComponent } from './components/panel/components/mobile/panel-shape-mobile/panel-shape-mobile.component';
import { ShapesGridComponent } from './components/panel/components/mobile/panel-shape-mobile/components/shapes-grid/shapes-grid.component';
import { PanelPropertiesPageMobileComponent } from './components/panel/components/mobile/panel-properties-page-mobile/panel-properties-page-mobile.component';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormatCardComponent } from './components/panel/components/mobile/panel-properties-page-mobile/components/format-card/format-card.component';
import { InputsSettingsComponent } from './components/panel/components/mobile/panel-properties-page-mobile/components/inputs-settings/inputs-settings.component';
import { InputDimensionsComponent } from './components/panel/components/mobile/panel-properties-page-mobile/components/input-dimensions/input-dimensions.component';
import { UnitsDropdownComponent } from './components/panel/components/mobile/panel-properties-page-mobile/components/units-dropdown/units-dropdown.component';
import { PanelFormatsPageMobileComponent } from './components/panel/components/mobile/panel-formats-page-mobile/panel-formats-page-mobile.component';
import { PanelTextMobileComponent } from './components/panel/components/mobile/panel-text/panel-text-mobile/panel-text-mobile.component';
import { PanelPropertiesTextMobileComponent } from './components/panel/components/mobile/panel-text/panel-properties-text-mobile/panel-properties-text-mobile.component';
import { PanelSizeMobileComponent } from './components/panel/components/mobile/panel-text/panel-size-mobile/panel-size-mobile.component';
import { PanelStyleMobileComponent } from './components/panel/components/mobile/panel-text/panel-style-mobile/panel-style-mobile.component';
import { PanelColorMobileComponent } from './components/panel/components/mobile/panel-text/panel-color-mobile/panel-color-mobile.component';
import { ColorModule } from '@app/shared/color/color.module';
import { PanelColorComponent } from '@app/editor/components/properties-panel/panel-color/panel-color.component';
import { PropertiesPanelModule } from '@app/editor/components/properties-panel/properties-panel.module';
import { PanelFontMobileComponent } from './components/panel/components/mobile/panel-text/panel-font-mobile/panel-font-mobile.component';
import { PanelTextComponent } from '@app/editor/components/properties-panel/panel-text/panel-text.component';
import { PanelTextFontModule } from '@app/editor/components/properties-panel/panel-text-font/panel-text-font.module';
import { PanelDownloadMobileComponent } from './components/panel/components/mobile/panel-download-mobile/panel-download-mobile.component';
import { PanelIconMobileComponent } from '@app/editor-v3/components/panel/components/mobile/panel-icon-mobile/panel-icon-mobile.component';
import { IconsGridComponent } from '@app/editor-v3/components/panel/components/mobile/panel-icon-mobile/components/shapes-grid/icons-grid.component';
import { PanelGifMobileComponent } from './components/panel/components/mobile/panel-gif-mobile/panel-gif-mobile.component';
import { SearchGifTabMobileComponent } from './components/panel/components/mobile/panel-gif-mobile/components/search-gif-tab-mobile/search-gif-tab-mobile.component';
import { LibraryGifTabMobileComponent } from './components/panel/components/mobile/panel-gif-mobile/components/library-gif-tab-mobile/library-gif-tab-mobile.component';
import { ModalLoaderComponent } from './components/shared/modal-loader/modal-loader.component';
import { PanelPropertiesGifMobileComponent } from './components/panel/components/mobile/panel-properties-gif-mobile/panel-properties-gif-mobile.component';
import { EmptyModule } from '@app/shared/empty/empty.module';
import { SearchStickersTabMobileComponent } from './components/panel/components/mobile/panel-gif-mobile/components/search-stickers-tab-mobile copy/search-stickers-tab-mobile.component';
import { PanelTraktoLinkMobileComponent } from './components/panel/components/mobile/panel-trakto-link-mobile/panel-trakto-link-mobile.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ButtonUploaderComponent } from './components/shared/button-uploader/button-uploader.component';
import { ElementsListComponent } from '@app/editor-v3/components/panel/components/mobile/panel-elements-mobile/components/elements-list/elements-list.component';
import { SharedDragDropModule } from '@app/shared/shared-drag-drop.module';

@NgModule({
  declarations: [
    EditorV3Component,
    HeaderComponent,
    ButtonLinkComponent,
    WorkspaceComponent,
    ResizableDirective,
    PanelComponent,
    PageNavigatorComponent,
    SliderComponent,
    PageGridComponent,
    TooltipDirective,
    ConfirmationComponent,
    RenderSvgIconComponent,
    PanelHomeMobileComponent,
    PanelHeaderMobileComponent,
    PanelPageMobileComponent,
    PanelOptionMobileComponent,
    PanelAddElementsMobileComponent,
    PanelImageMobileComponent,
    SearchTabMobileComponent,
    LibraryTabMobileComponent,
    SearchGifTabMobileComponent,
    LibraryGifTabMobileComponent,
    SearchStickersTabMobileComponent,
    ButtonLoaderComponent,
    GridImagesComponent,
    PanelPropertiesImageMobileComponent,
    PanelSecondaryOptionMobileComponent,
    PanelOrderMobileComponent,
    PanelAlignMobileComponent,
    PanelOpacityMobileComponent,
    PanelActionMobileComponent,
    PanelMaskMobileComponent,
    InputComponent,
    TagListComponent,
    DragDropDirective,
    PanelFiltersMobileComponent,
    SvgFilterDirective,
    BackgroundRemovalCardComponent,
    PanelLinksMobileComponent,
    PanelWebsiteMobileComponent,
    PanelWhatsappMobileComponent,
    PanelEmailMobileComponent,
    PanelElementsMobileComponent,
    ElementsListComponent,
    InputSuggestionsComponent,
    SearchResultsComponent,
    FormatsResultsComponent,
    PacknamePipe,
    ThemeResultsComponent,
    PanelShapeMobileComponent,
    ShapesGridComponent,
    PanelPropertiesPageMobileComponent,
    FormatCardComponent,
    InputsSettingsComponent,
    InputDimensionsComponent,
    UnitsDropdownComponent,
    PanelFormatsPageMobileComponent,
    PanelTextMobileComponent,
    PanelPropertiesTextMobileComponent,
    PanelSizeMobileComponent,
    PanelStyleMobileComponent,
    PanelColorMobileComponent,
    PanelFontMobileComponent,
    PanelDownloadMobileComponent,
    PanelIconMobileComponent,
    IconsGridComponent,
    PanelGifMobileComponent,
    ModalLoaderComponent,
    PanelTraktoLinkMobileComponent,
    PanelPropertiesGifMobileComponent,
    ButtonUploaderComponent,
  ],
  entryComponents: [
    PanelColorComponent,
    PanelTextComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SvgEditorModule,
    SharedDragDropModule,
    InlineSVGModule,
    SwiperModule,
    TranslateModule,
    ColorModule,
    PropertiesPanelModule,
    PanelTextFontModule,
    EmptyModule,
    QRCodeModule
  ],
  exports: [HeaderComponent],
  providers: [PacknamePipe, SlicePipe],
})
export class EditorV3Module {}
