import { Injectable } from '@angular/core';
import FacebookPixelService from './facebookPixel.service';

enum FBTrackingEventName {
  ACCOUNT_CREATED = 'Criou conta',
  OPEN_CHECKOUT = 'Abriu Checkout',
  USER_TURNS_PAYER = 'Virou Cliente',
  BILLET_CREATED = 'Gerou Novo Boleto',
  PRESENTATION_MODE_VIEWED = 'Visualizou Modo de Apresentação',
  TRAKTO_LINKS_CREATED = 'Publicou Trakto Links',
}
@Injectable({
  providedIn: 'root',
})
export class FBTraktoTracking {
  constructor(private readonly _fb: FacebookPixelService) {}

  public traktoLinksCreated() {
    this._fb.trackCustomEvent(FBTrackingEventName.TRAKTO_LINKS_CREATED);
  }
  public presentationModeViewed() {
    this._fb.trackCustomEvent(FBTrackingEventName.PRESENTATION_MODE_VIEWED);
  }

  public designDownload(type: string) {
    this._fb.trackCustomEvent(`Download de ${type}`);
  }

  public openCheckout() {
    this._fb.trackCustomEvent(FBTrackingEventName.OPEN_CHECKOUT);
  }

  public newCustomer(planPrice: number = 0.0) {
    this._fb.trackCustomEvent(FBTrackingEventName.USER_TURNS_PAYER);
    this._fb.trackPurchase(planPrice, 'BRL');
  }

  public billetCreated() {
    this._fb.trackCustomEvent(FBTrackingEventName.BILLET_CREATED);
  }
}
