import { Injectable } from '@angular/core';
import { ShapeSVGConversionService } from '@shared/svg-viewer/shared/shape-svg-conversion.service';
import { ShapeElementService } from '@services/shape-element.service';
import { YoutubeSVGConverter } from '@trakto/svg-converter';

@Injectable()
export class YoutubeSVGConversionService extends YoutubeSVGConverter {
  constructor(
    private shapeService: ShapeElementService,
    private shapeConversionService: ShapeSVGConversionService,
  ) {
    super(shapeService, shapeConversionService);
  }
}
