<div class="trakto-input-file" [ngClass]="{ '--loading': loading }">
  <label
    *ngIf="!loading && !uploaded"
    [for]="id"
    class="button button--fill button--center button--upload"
    (click)="open($event)"
  >
    <span class="trakto-input-file-label">
      <ng-content></ng-content>
    </span>
  </label>

  <label
    *ngIf="loading"
    class="button button--fill button--center button--upload"
  >
    <span class="trakto-input-file-loader"></span>
    <span class="trakto-input-file-name">{{ name }}</span>
  </label>

  <label
    *ngIf="uploaded"
    class="button button--fill button--center button--upload --uploaded"
  >
    <i class="trakto-icon-check"></i>
  </label>

  <input
    #input
    [id]="id"
    [disabled]="disabled"
    type="file"
    (change)="change($event)"
    [accept]="mimes"
  />
</div>
