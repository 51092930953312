<section
  class="trakto-modal-sending"
  [ngStyle]="{
    background:
      sendingDropbox || sendingDropbox === null
        ? 'linear-gradient(91.86deg, #4A9AFF 6.9%, #155BFF 115.69%)'
        : '#1DC763'
  }"
>
  <div
    class="trakto-modal-sending__header"
    [ngStyle]="{
      'background-image':
        sendingDropbox || sendingDropbox === null
          ? 'url(' + imageBackgroundBlue + ')'
          : 'url(' + imageBackgroundGreen + ')'
    }"
  >
    <h2>
      {{
        sendingDropbox || sendingDropbox === null
          ? isTranslateHeader.sending
          : isTranslateHeader.ready
      }}
    </h2>
    <!-- <img
      [src]="
        sendingDropbox || sendingDropbox === null
          ? imageBackgroundBlue
          : imageBackgroundGreen
      "
    /> -->
  </div>
  <div class="trakto-modal-sending__body">
    <div [ngSwitch]="sendingDropbox" [style.width]="'100%'">
      <section *ngSwitchCase="true">
        <kml-loader
          prefix="trakto-icon-modal-icon-trakto"
          suffix="trakto-icon-dropbox-modal"
          background="transparent"
          [status]="processStatus"
        >
        </kml-loader>
      </section>

      <section *ngSwitchCase="false">
        <div class="trakto-modal-sending__buttons">
          <button
            (click)="goToDropbox()"
            class="trakto-modal-sending__button dropbox-goto"
          >
            <i class="trakto-icon-dropbox-modal"></i
            ><span>{{ isTranslateButtons.dropbox }}</span>
          </button>

          <span>{{ isTranslateButtons.or }}</span>

          <button
            (click)="onClose()"
            class="trakto-modal-sending__button dropbox-close"
          >
            <span>{{ isTranslateButtons.editing }}</span>
          </button>
        </div>
      </section>

      <section *ngSwitchCase="null">
        <kml-card-notification
          icon="trakto-icon-info"
          title="{{ isTranslateNotification.title }}"
          description="{{ isTranslateNotification.description }}"
          background="transparent"
          colorDivider="#EFF2F9"
          colorIcon="#FE4A23"
        >
          <button
            (click)="sendingToDropbox()"
            class="trakto-modal-sending__button retry"
          >
            <i class="trakto-icon-retry"></i
            ><span>{{ isTranslateNotification.retry }}</span>
          </button>
        </kml-card-notification>
      </section>
    </div>
  </div>
</section>
