export interface IFilterData {
  label: string;
  value: string;
}

export const filters: IFilterData[] = [
  { label: 'Teal and Orange', value: 'tealorange' },
  { label: 'Orange', value: 'orange' },
  { label: 'Tumblr', value: 'tumblr' },
  { label: 'Vintage ', value: 'vintage' },
  { label: 'Sepia Light', value: 'sepialight' },
  { label: 'Sepia', value: 'sepia' },
  { label: 'B&W', value: 'blackandwhite' },
  { label: 'Cold', value: 'cold' },
  { label: 'Gaussian Blur', value: 'gaussianblur' },
  { label: 'Horizontal Blur', value: 'horizontalblur' },
  { label: 'Contrast', value: 'contrast' },
  { label: 'Hue', value: 'huerotate' },
  { label: 'Luminance', value: 'luminance' },
  { label: 'Darken', value: 'darken' },
  { label: 'Lighten', value: 'lighten' },
  { label: 'Invert', value: 'invert' },
  { label: 'Grainy', value: 'grainy' },
];

export const duotones: IFilterData[] = [
  { label: 'Candy', value: 'candy' },
  { label: 'Miracle', value: 'miracle' },
  { label: 'Moonlight', value: 'moonlight' },
  { label: 'Nature', value: 'nature' },
  { label: 'Party', value: 'party' },
  { label: 'Sunset', value: 'sunset' },
  { label: 'Supreme', value: 'supreme' },
  { label: 'Premium', value: 'premium' },
  { label: 'Baby', value: 'baby' },
  { label: 'Millennium', value: 'millennium' },
  { label: 'New Life', value: 'new-life' },
  { label: 'Show', value: 'show' },
  { label: 'Wood', value: 'wood' },
  { label: 'Purple', value: 'purple' },
  { label: 'Mars Party', value: 'mars-party' },
  { label: 'Trakto', value: 'trakto' },
  { label: 'Plus', value: 'plus' },
  { label: 'Extra', value: 'extra' },
  { label: 'Fashion', value: 'fashion' },
  { label: 'Elegance', value: 'elegance' },
  { label: 'Summer', value: 'summer' },
  { label: 'Kiss', value: 'kiss' },
  { label: 'Night Party', value: 'night-party' },
  { label: 'Happy Memories', value: 'happy-memories' },
  { label: 'Soft', value: 'soft' },
  { label: 'Snow', value: 'snow' },
  { label: 'Wild', value: 'wild' },
  { label: 'Dirty Beauty', value: 'dirty-beauty' },
  { label: 'Grapes', value: 'grapes' },
  { label: 'Green Tritone', value: 'green-tritone' },
];
