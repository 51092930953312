<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-filters.svg"
    label="Filtros"
    [ignoreBackClick]="true"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="grid">
      <div class="filter-card remove" (click)="removeFilter()">
        <div class="thumb">
          <i class="icon icon-null"></i>
        </div>
        <span class="label">Não aplicar</span>
      </div>

      <div
        *ngFor="let filter of filters"
        class="filter-card"
        (click)="applyFilter(filter.value)"
      >
        <div class="thumb">
          <svg viewBox="0 0 160 160">
            <image
              [traktoSvgFilter]="filter.value"
              [id]="'filter-image-' + filter.value"
              width="160"
              height="160"
              [attr.xlink:href]="
                pageSelected?.backgroundImage ||
                elementSelected?.href ||
                isEmoji
              "
              preserveAspectRatio="xMidYMin slice"
            ></image>
          </svg>
        </div>

        <span class="label">{{ filter.label }}</span>
      </div>
    </div>
  </div>
</div>
