import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { PanelAddElementsMobileComponent } from '../components/panel/components/mobile/panel-add-elements-mobile/panel-add-elements-mobile.component';
import { PanelAlignMobileComponent } from '../components/panel/components/mobile/panel-align-mobile/panel-align-mobile.component';
import { PanelDownloadMobileComponent } from '../components/panel/components/mobile/panel-download-mobile/panel-download-mobile.component';
import { FormatsResultsComponent } from '../components/panel/components/mobile/panel-elements-mobile/components/formats-results/formats-results.component';
import { SearchResultsComponent } from '../components/panel/components/mobile/panel-elements-mobile/components/search-results/search-results.component';
import { ThemeResultsComponent } from '../components/panel/components/mobile/panel-elements-mobile/components/theme-results/theme-results.component';
import { PanelElementsMobileComponent } from '../components/panel/components/mobile/panel-elements-mobile/panel-elements-mobile.component';
import { PanelEmailMobileComponent } from '../components/panel/components/mobile/panel-email-mobile/panel-email-mobile.component';
import { PanelFiltersMobileComponent } from '../components/panel/components/mobile/panel-filters-mobile/panel-filters-mobile.component';
import { PanelFormatsPageMobileComponent } from '../components/panel/components/mobile/panel-formats-page-mobile/panel-formats-page-mobile.component';
import { PanelImageMobileComponent } from '../components/panel/components/mobile/panel-image-mobile/panel-image-mobile.component';
import { PanelLinksMobileComponent } from '../components/panel/components/mobile/panel-links-mobile/panel-links-mobile.component';
import { PanelMaskMobileComponent } from '../components/panel/components/mobile/panel-mask-mobile/panel-mask-mobile.component';
import { PanelOpacityMobileComponent } from '../components/panel/components/mobile/panel-opacity-mobile/panel-opacity-mobile.component';
import { PanelOrderMobileComponent } from '../components/panel/components/mobile/panel-order-mobile/panel-order-mobile.component';
import { PanelPageMobileComponent } from '../components/panel/components/mobile/panel-page-mobile/panel-page-mobile.component';
import { PanelPropertiesImageMobileComponent } from '../components/panel/components/mobile/panel-properties-image-mobile/panel-properties-image-mobile.component';
import { PanelPropertiesPageMobileComponent } from '../components/panel/components/mobile/panel-properties-page-mobile/panel-properties-page-mobile.component';
import { ShapesGridComponent } from '../components/panel/components/mobile/panel-shape-mobile/components/shapes-grid/shapes-grid.component';
import { PanelShapeMobileComponent } from '../components/panel/components/mobile/panel-shape-mobile/panel-shape-mobile.component';
import { PanelColorMobileComponent } from '../components/panel/components/mobile/panel-text/panel-color-mobile/panel-color-mobile.component';
import { PanelFontMobileComponent } from '../components/panel/components/mobile/panel-text/panel-font-mobile/panel-font-mobile.component';
import { PanelPropertiesTextMobileComponent } from '../components/panel/components/mobile/panel-text/panel-properties-text-mobile/panel-properties-text-mobile.component';
import { PanelSizeMobileComponent } from '../components/panel/components/mobile/panel-text/panel-size-mobile/panel-size-mobile.component';
import { PanelStyleMobileComponent } from '../components/panel/components/mobile/panel-text/panel-style-mobile/panel-style-mobile.component';
import { PanelTextMobileComponent } from '../components/panel/components/mobile/panel-text/panel-text-mobile/panel-text-mobile.component';
import { PanelWebsiteMobileComponent } from '../components/panel/components/mobile/panel-website-mobile/panel-website-mobile.component';
import { PanelWhatsappMobileComponent } from '../components/panel/components/mobile/panel-whatsapp-mobile/panel-whatsapp-mobile.component';
import * as ScreenUtils from '../utils/screen';
import { IStackConfig } from '@services/panel-stack.service';
import { ZoomService } from '@app/editor-v3/services/zoom.service';
import { DOCUMENT } from '@angular/common';
import { PanelIconMobileComponent } from '@app/editor-v3/components/panel/components/mobile/panel-icon-mobile/panel-icon-mobile.component';
import { IconsGridComponent } from '@app/editor-v3/components/panel/components/mobile/panel-icon-mobile/components/shapes-grid/icons-grid.component';
import { PanelGifMobileComponent } from '../components/panel/components/mobile/panel-gif-mobile/panel-gif-mobile.component';
import { PanelPropertiesGifMobileComponent } from '../components/panel/components/mobile/panel-properties-gif-mobile/panel-properties-gif-mobile.component';
import { PanelTraktoLinkMobileComponent } from '../components/panel/components/mobile/panel-trakto-link-mobile/panel-trakto-link-mobile.component';

export type IPanelTypes =
  | 'add-elements'
  | 'align'
  | 'elements'
  | 'elements-formats-results'
  | 'elements-search-results'
  | 'elements-theme-results'
  | 'email'
  | 'filters'
  | 'formats'
  | 'image'
  | 'links'
  | 'mask'
  | 'opacity'
  | 'order'
  | 'page'
  | 'propertiesImage'
  | 'propertiesPage'
  | 'page-background-color'
  | 'icon'
  | 'icon-grid'
  | 'shape'
  | 'shape-grid'
  | 'shape-colors'
  | 'website'
  | 'whatsapp'
  | 'text'
  | 'propertiesText'
  | 'fonts'
  | 'fonts-size'
  | 'fonts-colors'
  | 'fonts-style'
  | 'fonts-background-colors'
  | 'download'
  | 'download-open'
  | 'gif'
  | 'propertiesGif'
  | 'trakto-link';

interface IPanelInfo {
  component?: any;
  tiers: number[];
  full?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class PanelService {
  panels = {
    'add-elements': {
      component: PanelAddElementsMobileComponent,
      tiers: [138, ScreenUtils.getViewportHeight() - 88],
    },
    align: {
      component: PanelAlignMobileComponent,
      tiers: [204, ScreenUtils.getViewportHeight() - 88],
    },
    elements: {
      component: PanelElementsMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'elements-formats-results': {
      component: FormatsResultsComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'elements-search-results': {
      component: SearchResultsComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'elements-theme-results': {
      component: ThemeResultsComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    email: {
      component: PanelEmailMobileComponent,
      tiers: [374, ScreenUtils.getViewportHeight() - 88],
    },
    filters: {
      component: PanelFiltersMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    formats: {
      component: PanelFormatsPageMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    image: {
      component: PanelImageMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    links: {
      component: PanelLinksMobileComponent,
      tiers: [138, ScreenUtils.getViewportHeight() - 88],
    },
    mask: {
      component: PanelMaskMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    opacity: {
      component: PanelOpacityMobileComponent,
      tiers: [204, ScreenUtils.getViewportHeight() - 88],
    },
    order: {
      component: PanelOrderMobileComponent,
      tiers: [204, ScreenUtils.getViewportHeight() - 88],
    },
    page: {
      component: PanelPageMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    propertiesImage: {
      component: PanelPropertiesImageMobileComponent,
      tiers: [222, ScreenUtils.getViewportHeight() - 88],
    },
    propertiesPage: {
      component: PanelPropertiesPageMobileComponent,
      tiers: [630, ScreenUtils.getViewportHeight() - 88],
    },
    'page-background-color': {
      component: PanelColorMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    icon: {
      component: PanelIconMobileComponent,
      tiers: [222, ScreenUtils.getViewportHeight() - 88],
    },
    'icon-grid': {
      component: IconsGridComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    shape: {
      component: PanelShapeMobileComponent,
      tiers: [222, ScreenUtils.getViewportHeight() - 88],
    },
    'shape-grid': {
      component: ShapesGridComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'shape-colors': {
      component: PanelColorMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    website: {
      component: PanelWebsiteMobileComponent,
      tiers: [204, ScreenUtils.getViewportHeight() - 88],
    },
    whatsapp: {
      component: PanelWhatsappMobileComponent,
      tiers: [304, ScreenUtils.getViewportHeight() - 88],
    },
    text: {
      component: PanelTextMobileComponent,
      tiers: [138, ScreenUtils.getViewportHeight() - 88],
    },
    propertiesText: {
      component: PanelPropertiesTextMobileComponent,
      tiers: [222, ScreenUtils.getViewportHeight() - 88],
    },
    'fonts': {
      component: PanelFontMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'fonts-size': {
      component: PanelSizeMobileComponent,
      tiers: [204, ScreenUtils.getViewportHeight() - 88],
    },
    'fonts-style': {
      component: PanelStyleMobileComponent,
      tiers: [138, ScreenUtils.getViewportHeight() - 88],
    },
    'fonts-colors': {
      component: PanelColorMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'download': {
      component: PanelDownloadMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'download-open': {
      component: PanelDownloadMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'fonts-background-colors': {
      component: PanelColorMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
    'gif': {
      component: PanelGifMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
    },
    'propertiesGif': {
      component: PanelPropertiesGifMobileComponent,
      tiers: [222, ScreenUtils.getViewportHeight() - 88],
    },
    'trakto-link': {
      component: PanelTraktoLinkMobileComponent,
      tiers: [724, ScreenUtils.getViewportHeight() - 88],
      full: true,
    },
  };

  private _tiers: number[] = [];
  private _panelsOpened: ComponentRef<any>[] = [];

  onChangeTiers: Subject<number[]>;

  constructor(
    private _appRef: ApplicationRef,
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _zoomService: ZoomService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.onChangeTiers = new ReplaySubject<number[]>(1);
  }

  setTiers(panelInfo: IPanelInfo) {
    const workspaceHeight = this.document.body.offsetHeight - 48;
    this._tiers = !panelInfo.full ? [...panelInfo.tiers].map((tier) => Math.min(workspaceHeight, tier)) : [workspaceHeight, ScreenUtils.getViewportHeight() - 88];
    this.onChangeTiers.next(this._tiers);
  }

  resetTiers() {
    this.setTiers({
      tiers: [90]
    });
  }

  getPanelToOpen(panel: IPanelTypes) {
    return this.panels[panel];
  }

  openPanel(panel: IPanelTypes, config?: IStackConfig) {
    const panelComponent = this.getPanelToOpen(panel);

    const panelComponentRef = this._resolver
      .resolveComponentFactory(panelComponent.component as any)
      .create(this._injector);

    this.setTiers(panelComponent);

    const configStackdefault = { inputs: {}, outputs: {}, ...config };

    this._attachConfig(configStackdefault, panelComponentRef);

    this._appRef.attachView(panelComponentRef.hostView);

    const container = document.querySelector('div#panelContainer');

    container.appendChild(
      (panelComponentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement,
    );

    this._panelsOpened.push(panelComponentRef);
    this._applyAutoZoom();
  }

  closeLastPanel() {
    const componentToRemove = this._panelsOpened.pop();
    this._appRef.detachView(componentToRemove.hostView);
    componentToRemove.destroy();
    this.applyLastPanelTier();
    this._applyAutoZoom();
  }

  applyLastPanelTier() {
    const length = this._panelsOpened.length;

    if (length === 0) {
      this.resetTiers();
    } else {
      const lastPanelOpened = this._panelsOpened[length - 1];

      const lastPanelInfos = this.findPanelInfo(lastPanelOpened);

      if (lastPanelInfos) this.setTiers(lastPanelInfos);
    }
  }

  findPanelInfo(panel: ComponentRef<any>) {
    try {
      const panelKey = Object.keys(this.panels)
        .map(key => ({
          key,
          component: this.panels[key].component,
        }))
        .find(infos => infos.component === panel.componentType).key;

      return this.panels[panelKey];
    } catch (error) {
      return false;
    }
  }

  closeAllPanels() {
    while (this._panelsOpened.length > 0) {
      const componentToRemove = this._panelsOpened.pop();
      this._appRef.detachView(componentToRemove.hostView);
      componentToRemove.destroy();
    }
    this._applyAutoZoom();
  }

  private _attachConfig(
    config: IStackConfig,
    componentRef: ComponentRef<any>,
  ): void {
    const { inputs, outputs } = config;

    for (const [key, value] of Object.entries(inputs)) {
      componentRef.instance[key] = value;
    }

    for (const [key, value] of Object.entries(outputs)) {
      componentRef.instance[key] = value;
    }
  }

  private _applyAutoZoom() {
    setTimeout(
      () => {
        this._zoomService.fitPage();
      },
      500
    );
  }
}
