import { Injectable } from '@angular/core';

import { ImageUtilService } from '@services/image-util.service';
import { ColorOperations } from '@trakto/core-editor';

@Injectable()
export class ColorService extends ColorOperations {
  constructor(imageUtilService: ImageUtilService) {
    super(imageUtilService);
  }

  convertHexToRGBA(hexCode: string, opacity: number) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  }
}
