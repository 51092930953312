import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TraktoUser } from '@app/auth/shared/auth.model';
import { AuthService } from '@app/auth/shared/auth.service';
import { AppRepository } from '@app/editor/repository/app.repository';
import { PageFormatRepository } from '@app/editor/repository/page-format.repository';
import { ProductRepository } from '@app/editor/repository/product.repository';
import { ThemeRepository } from '@app/editor/repository/theme.repository';
import { TemplateToPublish, TraktoAdminApiWhiteLabelService } from '@app/editor/services/admin-api-white-label.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { DocumentService } from '@app/editor/services/document.service';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { BaseComponent } from '@app/shared/base/base.component';
import { NotificationService } from '@app/shared/notification/notification.service';
import { UploadStorageService } from '@app/shared/storage/upload.storage.service';
import { TranslateService } from '@ngx-translate/core';
import { IApp, IAvalableLocales, IDocumentModel, IFormats, IProducts, IThemeData, MTSkillsType } from '@trakto/models';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { incrementCurrentStep, decrementCurrentStep } from '../store/stepper.actions';
import { selectCurrentStep } from '../store/stepper.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'trakto-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultTemplateComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('element', { static: true }) element: IDocumentModel;

  document: IDocumentModel;
  @Input() user: TraktoUser;

  @Output() onRename: EventEmitter<string> = new EventEmitter<string>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  currentStep$ = this.store.select(selectCurrentStep);

  public form: FormGroup = new FormGroup({});
  template;


  public image: string;
  public isPublic: boolean;
  public isMagic: boolean;
  public apps: IApp[] = [];
  public products: IProducts[] = [];
  public themes: IThemeData[] = [];
  public formats: IFormats[] = [];
  public availableLocales: IAvalableLocales[] = [];
  public lang: string = 'pt-BR';
  public isLoading: boolean = false;

  private _thumbnailsToPublish: {
    high: string;
    medium: string;
    low: string;
    raw: string;
  };
  private _fileUploading: boolean;
  private notifyMSGs: any;

  public set fileUploading(value: boolean) {
    this._fileUploading = value;
  }

  public get fileUploading(): boolean {
    return this._fileUploading || false;
  }

  public expertises: string[] = [];

  public bellaSkills: MTSkillsType[] = [
    MTSkillsType.bioLink,
    MTSkillsType.profileImage,
    MTSkillsType.instagramFeed,
    MTSkillsType.instagramStory,
    MTSkillsType.logo,
    'advertisement' as MTSkillsType,
    'visit_card' as MTSkillsType,
  ];

  public link = {
    copied: false,
    minlinkLength: 1,
    maxlinkLength: 100,
    success: false,
    typing: true,
  }

  public isB2C = false;
  public linkPlaceholder = this.isB2C ? 'https://trakto.link/' : 'https://olink.pro/';

  public productLabel: string;
  public localeLabel: string;
  public themeLabel: string;
  public authorLabel: string;
  public formatLabel: string;
  public titleLabel: string;
  public fillInTheFields: string;
  public keywords: string;
  public visualization: string;
  public publicationCompleted: string;

  constructor(
    authService: AuthService,
    private store: Store,
    public hotkeyService: HotkeyService,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _alertService: NotificationService,
    private _panelStackService: PanelStackService,
    private _documentService: DocumentService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentManagerService,
    private _appRepository: AppRepository,
    private _pageFormatRepository: PageFormatRepository,
    private _themeRepository: ThemeRepository,
    private _productRepository: ProductRepository,
    private _adminApiService: TraktoAdminApiWhiteLabelService,
    private _uploadService: UploadStorageService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    super(authService, platformId);
    this.loadTranslations();
    this.notifyMSGs = this._alertService.notificationsMSGs;
  }

  ngOnInit() {
    this.initForm();
    this.getDocument();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  loadTranslations(): void {
    this._translateService.get('sidebar_publish').pipe(takeUntil(this._destroy$)).subscribe(translations => {
      this.productLabel = translations.label['product'];
      this.localeLabel = translations.label['locale'];
      this.themeLabel = translations.label['theme'];
      this.authorLabel = translations.label['author'];
      this.formatLabel = translations.label['format'];
      this.titleLabel = translations.label['template_title'];
      this.fillInTheFields = translations['fill-in-the-fields'];
      this.keywords = translations['keywords'];
      this.visualization = translations['visualization'];
      this.publicationCompleted = translations['publication-completed'];
    });
  }

  initForm() {
    this.form = this._formBuilder.group({
      app_reference: [null],
      author: this._formBuilder.group({
        name: ['', [Validators.required]],
      }),
      created_at: [new Date(), [Validators.required]],
      expertises: [null],
      is_premium: [false, [Validators.required]],
      is_magic: [false],
      locale: [null, [Validators.required]],
      page_format_reference: [null],
      products: [null],
      published: [false],
      published_at: [null],
      tags: this._formBuilder.array([]),
      theme_reference: [null, [Validators.required]],
      title: [null, [Validators.required]],
      updated_at: [new Date(), [Validators.required]],
    });
  }

  private async getDocument(): Promise<void> {
    await this._documentStateManagerService.getSelectedDocument().then(async res => {
      this.document = res;

      if (this.document) {
        this.resetFormFromDocument(this.document);
        this.setExpertisesInForm();
        this.switchMagic();
      }

      if (this.document.id) {
        await this.getTemplateId(this.document.id);
      }
    })
  }

  private async getTemplateId(id: string): Promise<void> {
    this.template = await this._documentService.findById(id);
    this.resetFormFromTemplate(this.template);
  }

  private async resetFormFromDocument(document) {
    const tagsArray = this.form.get('tags') as FormArray;

    this.form.controls.app_reference.setValue(document.app_reference);
    this.form.controls.author.setValue(document.author);
    this.form.controls.created_at.setValue(document.created_at);
    this.form.controls.is_magic.setValue(this.document.is_magic ?? false);
    this.form.controls.locale.setValue(document.locale);
    this.form.controls.page_format_reference.setValue(document.page_format_reference);
    this.form.controls.products.setValue(document.products);
    this.form.controls.published.setValue(document.published);
    tagsArray.push(this._formBuilder.control(document.tags));
    this.form.controls.theme_reference.setValue(document.theme_reference);
    this.form.controls.title.setValue(document.title);
    this.form.controls.updated_at.setValue(document.updated_at);
  }

  private async resetFormFromTemplate(template) {
    if (!this.isGlobalUser && this.user) {
      this.form.get('app_reference').disable();
      if (this.isUser) {
        this.form.get('products').disable();
      }
    }

    this.form.controls.title.setValue(template.title);
    this.form.controls.created_at.setValue(template.created_at);
    this.form.controls.author['controls'].name.setValue(
      template.author ? template.author.name : null,
    );
    this.form.controls.published.setValue(
      template.published || false,
    );
    this.form.controls.published_at.setValue(template.published_at);

    this.form.controls.updated_at.setValue(
      template.updated_at || template.created_at,
    );

    this.setTagsInForm(this.template);

    this.image = template.thumbs ? template.thumbs.medium : null;
    this._thumbnailsToPublish = template.thumbs;

    this.form.controls.is_premium.setValue(
      template['is_premium'] || false,
    );

    this.form.controls.is_magic.setValue(
      this.template.is_magic ?? false
    );

    await this._initApps();
    const app = await this._appRepository.findByLoggedUser();
    this.form.controls.app_reference.setValue(app);

    await this.loadProductsInApp({ value: app });
    this.form.controls.products.setValue(this.currentUser.products);

    await this._initPageFormats();
    this.form.controls.page_format_reference.setValue(
      template.page_format_reference || null,
    );

    await this._loadAvailableLocales(this.products);
    this.form.controls.locale.setValue(template['locale']);

    await this.loadThemesInApp({ value: app });
    this.form.controls.theme_reference.setValue(
      template.theme_reference,
    );
  }

  async loadProductsInApp(event: any) {
    const appId = event?.value?.id || null;
    if (!appId) {
      return;
    }
    const products = await this._productRepository.findByAppId(appId);
    this.form.controls.products.setValue(null);
    this.products = products.map(document => ({ ...document }));
  }

  async loadThemesInApp(event?: any): Promise<any> {
    this.form.controls.theme_reference.setValue(null);
    this.lang = this._translateService.currentLang || 'pt-BR';
    this.themes = event && event.value ? await this._themeRepository.findByApp(event.value.id) : await this._themeRepository.findByLoggedUser();
  }

  async loadFormatsByProducts() {
    const productIds = this.form.get('products').value?.map(product => product.id);
    this.formats = await this._pageFormatRepository.findByLoggedUser(productIds, false);
  }

  private async _initApps() {
    this.apps = await this._appRepository.findAll()
  }

  private async _initPageFormats() {
    const productIds = this.form.get('products').value?.map(product => product.id);
    this.formats = await this._pageFormatRepository.findByLoggedUser(productIds, false);
  }

  private async _loadAvailableLocales(selectedProducts) {
    let locales = [];
    this.availableLocales = [];

    selectedProducts.forEach(product => {
      locales = locales.concat(product.locale.available);

    });
    this.availableLocales = [...new Set(locales)];
  }

  onChangeProducts(opened: boolean) {
    if (!opened && this.form.get('products').value) {
      this._loadAvailableLocales(this.form.get('products').value);
    }
  }

  change(control: string, changed: any): void {
    this.form.controls[control].setValue(
      changed.value ? changed.value : null,
    );
  }

  switchMagic(): any {
    this.isMagic = this.form.get('is_magic').value;
  }

  navigateToBackStep() {
    this.store.dispatch(decrementCurrentStep());
  }

  navigateToNextStep() {
    if (this.form.valid) {
      this.store.dispatch(incrementCurrentStep());
    }
  }

  close(): void {
    this._panelStackService.pop(500);
  }

  async uploadFile(event: any): Promise<void> {
    try {
      this.fileUploading = true;
      const file = event.target.files[0];
      const { resolutions } = await this._uploadService.uploadFile(file);
      this.image = resolutions.medium.url;
      this.form.controls.thumbs.setValue(resolutions);
      this.fileUploading = false;
      this._thumbnailsToPublish = {
        raw: resolutions.raw.secure_url,
        low: resolutions.low.secure_url,
        medium: resolutions.medium.secure_url,
        high: resolutions.high.secure_url,
      };
    } catch (error) {
      this.fileUploading = false;
    }
  }

  removeFile(): void {
    this.image = null;
    this.form.controls.thumbs.setValue(null);
  }

  public removePrefix(str: string, prefix: string | null = null): string {
    const remove =
      prefix === null ? (this.getActualPrefix() as string) : prefix;

    return str.replace(remove, '');
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      const hasTag: boolean = this.form.controls['tags'].value.indexOf(value.trim()) !== -1;

      if (!hasTag) {
        const tags = this.form.get('tags') as FormArray;
        tags.push(this._formBuilder.control(value.trim()));
        this.form.patchValue({ tags: tags.value });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as FormArray;

    if (index >= 0) {
      tags.removeAt(index);
      this.form.patchValue({ tags: tags.value });
    }
  }

  loadExpertisesInApp(): void {
    this.setExpertisesInForm();
    this.setTagsInForm(this.document);
  }

  /**
   * verifica se tag está dentro das expertises,
   * e seleciona ela nas áreas de atuação
   */
  setExpertisesInForm(): void {
    let previousExpertises = [];
    this.expertises = this.getActualExpertisesList();

    this.document.tags.forEach((tag: string) => {
      if (this.expertises.indexOf(tag) >= 0) {
        previousExpertises.push(tag);
      }
    });

    this.form.controls.expertises.setValue([...previousExpertises]);
  }

  /**
   * se tags não estiverem dentro das expertises,
   * são adicionadas como tags mesmo no form
   */
  setTagsInForm(template: IDocumentModel): void {
    this.form.controls.tags = this._formBuilder.array([]);

    if (template.tags) {
      let finalTags = [...template.tags];
      const allPrefixes = this.getActualPrefix(true) as string[];

      template.tags.forEach(tag => {
        allPrefixes.forEach(prefix => {
          if (this.expertises.indexOf(this.removePrefix(tag, prefix)) !== -1) {
            const index = finalTags.indexOf(tag);
            if (index > -1) finalTags.splice(index, 1);
          }
        });
      });

      finalTags.forEach(tag => {
        this.getFormData('tags').push(this._formBuilder.control(tag.trim()));
      });
    }
  }

  public copyLinkToClipboard() {
    const element = document.createElement('input');
    //element.value = this.url + `${this.data?.linkWeb}`;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 3000);
  }

  async submit(event: any) {
    this.form.controls.published_at.setValue(
      this.form.get('published').value ? new Date() : null,
    );
    const values = this.form.value;
    values.available_locales = values.locale;

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    try {
      if (this.form.valid) {
        const {
          author,
          tags,
          published,
          is_premium,
          products,
          theme_reference,
          available_locales,
          page_format_reference,
          is_magic,
          bella_skills = '',
          expertises = [],
        } = values;

        this.isLoading = true;
        await this._adminApiService.publishTemplate(this.document.id, new TemplateToPublish({
          author: author.name,
          tags: tags,
          published: published,
          isPremium: is_premium,
          products: products.map((product: any) => product.id),
          themeId: theme_reference.id,
          locales: available_locales,
          pageFormatId: page_format_reference.id || page_format_reference.objectID,
          isMagic: is_magic,
          thumbnail: this._thumbnailsToPublish,
          bella: {
            skill: bella_skills,
            tags: expertises
          }
        }))
          .then(res => {
            this.linkPlaceholder = res.thumbnail.high;
            this._documentManagerService.updateDocumentData(this.document)
            this.document.updated_at = new Date(res.updated_at);
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this._alertService.error('Houve um erro ao publicar o template.');
          });

        const rename = this.form.get('title').value;
        this._documentManagerService.rename(rename);
        this.onRename.emit(this.form.get('title').value);

        this._alertService.success(this.notifyMSGs.template_publication_success);
      }
    } catch (err) {
      this._alertService.error(this.notifyMSGs.template_publication_error);
    }
  }

  getFormData(name: string): any {
    return this.form.get(name) as FormArray;
  }

  getActualApp(): IApp {
    const actualId = this.form.get('app_reference').value?.id;
    return this.apps.filter(app => app.id === actualId)[0];
  }

  getActualPrefix(all = false): string | string[] {
    const prefixes = {
      [environment.api.WHITELABELS.ifood]: 'iFood: ',
      default: 'Bella: ',
    };

    if (all) return Object.keys(prefixes).map(key => prefixes[key]);

    const actualApp = this.getActualApp();

    return prefixes[actualApp?.id] || prefixes.default;
  }

  getActualExpertisesList(): string[] {
    const actualApp = this.getActualApp();
    const actualPrefix = this.getActualPrefix();

    const expertises = {
      [environment.api.WHITELABELS.ifood]: [
        'Pizzaria',
        'Hamburgueria',
        'Japonesa',
        'Chinesa',
        'Brasileira',
        'Doceria',
        'Padaria',
        'Churrascaria',
        'Lanchonete',
        'Cafeteria',
      ],
      default: [
        'Default',
        'Academia e fitness',
        'Administrador e Analista',
        'Advocacia',
        'Arquitetura e urbanismo',
        'Confeitaria e sorveteria',
        'Coach e palestrante',
        'Criador de conteúdo',
        'Educação e ensino',
        'Engenharia e Imobiliária',
        'Automotivos e seguro',
        'Estética e Cosméticos',
        'Salão de beleza e barbearia',
        'Fisioterapia',
        'Marketing digital',
        'Moda e Vestuário',
        'Nutrição',
        'Odontologia',
        'Pet Shop e veterinária',
        'Religiosos',
        'Restaurantes e alimentos',
        'Saúde e bem-estar',
        'South Summit',
        'Tecnologia',
        'Viva Technology',
        'Trakto Show',
        'Orange',
        'SAP',
        'Cisco',
        'Stellantis',
        'Fujitsu',
        'Decathlon',
        'Valeo',
        'JCDecaux',
        'Madeira Madeira',
        'Quinto Andar',
        'Quinto Andar - Inicial',
        'Google',
        'Magazine Luiza',
        'Madeira Madeira Display',
        'Magazine Luiza Produtos',
        'Nubank',
        'Gran Cursos',
        'Google In Feed',
        'Loja do Mecânico',
        'Livelo',
        'Santander',
        'Quero Passagem',
        'Oi',
        'Gran Cursos Preparatórios',
        'Gran Cursos Superior',
        'Gran Cursos - Pós Graduação',
        'Loja do Mecânico - Frete Grátis',
        'Santillana'
      ],
    };

    return (expertises[actualApp?.id] || expertises.default).map(
      (expertise: string) => actualPrefix + expertise,
    );
  }
}
