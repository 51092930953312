<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/tkt-gif-small.svg" label="GIFs" [showBackLabel]="true"
    [ignoreBackClick]="true" (onClose)="closePanel()"></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="tabs">
      <div class="tab" [ngClass]="{ active: activeTab === 'search' }" (click)="setActiveTab('search')">
        Pesquisar Gifs
      </div>
      <div class="tab" [ngClass]="{ active: activeTab === 'search-stickers' }"
        (click)="setActiveTab('search-stickers')">
        Pesquisar Stickers
      </div>
      <div class="tab" [ngClass]="{ active: activeTab === 'library' }" (click)="setActiveTab('library')">
        Minha biblioteca
      </div>
    </div>

    <trakto-search-gif-tab-mobile (clicked)="closePanel()"
      [ngStyle]="{ display: activeTab === 'search' ? 'block' : 'none' }"></trakto-search-gif-tab-mobile>

    <trakto-library-gif-tab-mobile (clicked)="closePanel()"
      [ngStyle]="{ display: activeTab === 'library' ? 'block' : 'none' }"></trakto-library-gif-tab-mobile>

    <trakto-search-stickers-tab-mobile (clicked)="closePanel()"
      [ngStyle]="{ display: activeTab === 'search-stickers' ? 'block' : 'none' }"></trakto-search-stickers-tab-mobile>
  </div>
</div>
