<div
  class="formats-results scroll-results"
  [ngStyle]="{ right: isActive ? '0px' : '-800px' }"
  infiniteScroll
  (scrolled)="scrollToNextPage()"
  [scrollWindow]="false"
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="100"
  [infiniteScrollContainer]="'.formats-results'"
  [fromRoot]="true"
  traktoScrollListener
>
  <div
    *ngIf="isSearching"
    class="blackout"
    [ngStyle]="{
      backgroundColor: isSearching ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)'
    }"
  ></div>
  <div class="formats-results__header">
    <div class="formats-results__back">
      <img
        src="assets/images/formats-elements/goback.png"
        alt="back"
        (click)="hidePanel()"
      />
    </div>
    <div class="formats-results__title" *ngIf="currentSearch">
      {{ currentSearch.name }}
    </div>
  </div>

  <trakto-searchbar
    (isSearchbarFocused)="isSearchbarFocused($event)"
    (searchTag)="searchTagInSearchBar($event)"
    [searchType]="searchType"
  ></trakto-searchbar>

  <trakto-tags-list
    [tags]="universeTags"
    [currentTag]="currentTag"
    [currentPanel]="searchType"
    (filterTag)="filterTags($event)"
  ></trakto-tags-list>

  <trakto-elements-list
    *ngIf="isActive"
    [currentSearch]="currentSearch"
    [isPanelExpanded]="isPanelExpanded"
    (requestElementInsertion)="assetSelected.emit($event)"
  >
  </trakto-elements-list>
</div>
