import { Drawable } from '@app/editor/components/preview-2/svg-editor/shared/drawable.model';
import { createAction, props } from '@ngrx/store';
import { IDocumentModel, IElementModel, PageModel } from '@trakto/models';

export const selectDocument = createAction(
  '[Document] Select Document',
  props<{ document: IDocumentModel }>()
);

export const persistDocumentChangesNoTrackable = createAction(
  '[Document] Persist document changes not trackable',
  props<{ document: IDocumentModel }>()
);

export const selectPageNoTrackable = createAction(
  '[Page] Select Page',
  props<{ pageId: string }>()
);

export const persistPageChanges = createAction(
  '[Page] Persist page changes',
  props<{ page: PageModel }>()
);

export const persistPageChangesNoTrackable = createAction(
  '[Page] Persist page changes not trackable',
  props<{ page: PageModel }>()
);

export const addPage = createAction(
  '[Document] Add Page',
  props<{ page: PageModel, toIndex?: number }>()
);

export const deletePage = createAction(
  '[Document] Delete Page',
  props<{ pageId: string }>()
);

export const movePageUp = createAction(
  '[Page] Move page up',
  props<{ pageId: string }>()
);


export const movePageDown = createAction(
  '[Page] Move down',
  props<{ pageId: string }>()
);

export const selectElementNoTrackable = createAction(
  '[Element] Select Element',
  props<{ element: IElementModel }>()
);

export const clickElementNoTrackable = createAction(
  '[Element] Click Element',
  props<{ element: IElementModel }>()
);

export const highlightElement = createAction(
  '[Highlight] Highlight Element',
  props<{ element: Drawable }>()
);

export const addElement = createAction(
  '[Document] Add Element',
  props<{ element: IElementModel, pageId: string }>()
);

export const deleteElement = createAction(
  '[Element] Delete Element',
  props<{ element: IElementModel, pageId: string }>()
);

export const deleteAndAddElements = createAction(
  '[Document] Delete and Add Elements',
  props<{ toDelete: IElementModel[], toAdd: IElementModel[], pageId: string }>()
);

export const persistElementsChanges = createAction(
  '[Element] Persist elements changes',
  props<{ elements: IElementModel[], pageId: string }>()
);

export const persistElementsChangesNotTrackable = createAction(
  '[Element] Persist elements changes not trackable',
  props<{ elements: IElementModel[], pageId: string }>()
);

export const moveElementUp = createAction(
  '[Element] Move element up',
  props<{ element: IElementModel, pageId: string }>()
);


export const moveElementDown = createAction(
  '[Element] Move element down',
  props<{ element: IElementModel, pageId: string }>()
);
