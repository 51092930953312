import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from './dialog.service';
import { YesOrCancelComponent } from './yes-or-cancel/yes-or-cancel.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class DialogServiceImpl extends DialogService implements OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(private snackBar: MatSnackBar, private mdDialog: MatDialog) {
    super();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public showSimpleSnackMessage(text: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
    });
  }

  public showSimpleOkMessage(text: string, afterClosed?: any): void {
    this.showOptionsMessage(
      text,
      [
        {
          value: DialogService.OK,
          text: 'Ok',
        },
      ],
      afterClosed,
    );
  }

  public showYesOrCancelMessage(text: string, afterClosed?: any): void {
    this.showOptionsMessage(
      text,
      [
        {
          value: DialogService.YES,
          text: 'Sim',
        },
        {
          value: DialogService.CANCEL,
          text: 'Não',
        },
      ],
      afterClosed,
    );
  }

  public showYesOrNoMessage(text: string, afterClosed?: any): void {
    this.showOptionsMessage(
      text,
      [
        {
          value: DialogService.YES,
          text: 'Sim',
        },
        {
          value: DialogService.NO,
          text: 'Não',
        },
      ],
      afterClosed,
    );
  }

  public showOptionsMessage(
    text: string,
    options: any,
    afterClosed?: any,
  ): void {
    this.mdDialog
      .open(YesOrCancelComponent, {
        data: {
          text,
          options,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(afterClosed);
  }

  public showModalWidth(
    component: any,
    width: string,
    data?: any,
    afterClosed?: any,
  ): void {
    this.mdDialog
      .open(component, {
        data,
        width,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(afterClosed);
  }
}
