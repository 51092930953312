import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ICampaign } from "@trakto/models";
import { Observable } from "rxjs";

export type ApiCampaignListResponse = {
    campaigns: ICampaign[];
    limit: number;
    page: number;
    total: number;
};

export type TagApiResponse = {
    created_at: string;
    updated_at: string;
    element_target: string;
    property_target: string;
    descriptor: {
        label: string;
        skills: string[];
        tagName: string;
    };
    team_campaign_id: string;
    is_featured: boolean;
};

type Pagination = {
    page: number;
    limit: number;
};

@Injectable({
    providedIn: 'root',
})
export class ApiCampaignService {
    constructor(
        private _httpClient: HttpClient,
    ) {}

    findById(campaignId: string): Observable<ICampaign> {
        return this._httpClient.get<ICampaign>(
            `${environment.api.templateUrl}campaigns/find/${campaignId}`);
    }

    findByTemplateId(templateId: string): Observable<ICampaign[]>{ 
        return this._httpClient.get<ICampaign[]>(
            `${environment.api.templateUrl}campaigns/template/${templateId}`);
    }

    findCampaignByTemplateId(templateId: string): Observable<ICampaign[]>{ 
        return this._httpClient.get<ICampaign[]>(
            `${environment.api.templateUrl}campaigns/template/by_id/${templateId}`);
    }

    findAll(pagination: Pagination): Observable<ApiCampaignListResponse> {
        const queryParams = {
            page: pagination.page.toString(),
            limit: pagination.limit.toString(),
            status: 'published',
        };
        return this._httpClient.get<ApiCampaignListResponse>(
            `${environment.api.templateUrl}campaigns/list`, { params: queryParams });
    }

    getTags(
        pagination: Pagination,
        campaignId: string
    ): Observable<Array<TagApiResponse>> {
        const queryParams = {
            page: pagination.page.toString(),
            limit: pagination.limit.toString(),
        };
        return this._httpClient.get<Array<TagApiResponse>>(
            `${environment.api.templateUrl}tags/list/campaign/${campaignId}`,
            { params: queryParams });
    }
}