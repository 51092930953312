import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ImageService } from '@app/editor/services/image.service';
import { DropboxService } from '@app/integrations/dropbox/dropbox.service';
import { TranslateService } from '@ngx-translate/core';
import { KmlModalConfig, KmlModalRef } from '@trakto/trakto-kamaleon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-modal-sending',
  templateUrl: './modal-sending.component.html',
  styleUrls: ['./modal-sending.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalSendingComponent implements OnInit, OnDestroy {
  public imageBackgroundBlue = 'assets/images/loading-svg-blue.svg';
  public imageBackgroundGreen = 'assets/images/loading-verde.svg';

  public sendingDropbox = true;
  public processStatus: string;
  public isTranslateHeader: any;
  public isTranslateButtons: any;
  public isTranslateNotification: any;
  public fileUploadedUrl: string;
  private _destroy$ = new Subject<void>();

  constructor(
    public kmlData: KmlModalConfig,
    private _kmlModalRef: KmlModalRef,
    private _dropboxService: DropboxService,
    private _imageService: ImageService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit() {
    this._getTranslations();
    this.sendingToDropbox();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public async sendingToDropbox() {
    try {
      this.sendingDropbox = true;

      const { dataDoc, fileId, integrationToken } = this.kmlData.data;
      const translations = await this._getTranslations();

      this.processStatus = translations?.sending;

      const file = await this._imageService
        .createImageBlob(dataDoc)
        .toPromise();
      const preview_url: any = await this._dropboxService.uploadFile(
        integrationToken,
        fileId,
        file,
      );
      this.fileUploadedUrl = preview_url;
      this.sendingDropbox = false;
    } catch (error) {
      this.sendingDropbox = null;
    }
  }

  private _getTranslations(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isTranslateHeader = { sending: '', ready: '' };
      this.isTranslateButtons = { dropbox: '', editing: '', or: '' };
      this.isTranslateNotification = { title: '', description: '', retry: '' };

      this._translateService.get('modal_dropbox').pipe(takeUntil(this._destroy$)).subscribe(texts => {
        const {
          header: { sending, ready },
          buttons: { button_dropbox, button_editing, or },
          notification: { title, description, retry },
          loading,
        } = texts;

        this.processStatus = loading?.generating;

        this.isTranslateHeader.sending = sending;
        this.isTranslateHeader.ready = ready;
        this.isTranslateButtons.dropbox = button_dropbox;
        this.isTranslateButtons.editing = button_editing;
        this.isTranslateButtons.or = or;
        this.isTranslateNotification.title = title;
        this.isTranslateNotification.description = description;
        this.isTranslateNotification.retry = retry;

        resolve(loading);
        reject('Tradução não encontrada');
      });
    });
  }

  public goToDropbox(): void {
    window.location.href = this.fileUploadedUrl;
  }

  public onClose(): void {
    this._kmlModalRef.close(false);
  }
}
