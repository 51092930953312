import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateString',
})
export class DateStringPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, args = null) {
    if (value) {
      const date = moment.unix(value.seconds);

      if (date.isValid()) {
        switch (date.diff(new Date(), 'days')) {
          case 0:
            return `Today at ${date.format('HH:mm')}`;
          case -1:
            return `Yesterday at ${date.format('HH:mm')}`;
          default:
            return this.datePipe.transform(date.toString(), args);
        }
      }
    }

    return value;
  }
}
