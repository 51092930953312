import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInputElementPanel } from '@trakto/models';

@Component({
  selector: 'trakto-magic-input',
  templateUrl: './magic-input.component.html',
  styleUrls: ['./magic-input.component.scss'],
})
export class MagicInputComponent implements OnInit {
  @Input() input: IInputElementPanel;
  @Input() isInputAlreadyTagged: boolean;
  @Input() isInputSelected: boolean;
  @Input() isDeleteSpecific: boolean;
  @Output() clearElement: EventEmitter<IInputElementPanel> =
    new EventEmitter<IInputElementPanel>();

  constructor() {}

  ngOnInit(): void {}

  clearElementEmitter(input: IInputElementPanel) {
    this.clearElement.emit(input);
  }
}
