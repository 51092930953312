<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/font_size_page.svg"
    label="Tamanho"
    [showBackLabel]="true"
    (onClose)="closePanel()"
    class="click-ignore"
  ></trakto-panel-header-mobile>

  <div class="action click-ignore">
    <span class="label">Altura</span>
    <div class="slider">
      <trakto-slider
        [min]="5"
        [max]="200"
        [value]="fontSize"
        (onChange)="setFontSize($event)"
        [ngStyle]="{ flexGrow: 1 }"
      ></trakto-slider>

      <div class="value">
        <span class="text">
          {{ fontSize }}
        </span>
      </div>
    </div>
  </div>
</div>
