import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZoomService } from '@app/editor-v3/services/zoom.service';
import { ZoomService as ZoomServiceV2 } from '@app/editor/services/zoom.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-page-navigator',
  templateUrl: './page-navigator.component.html',
  styleUrls: ['./page-navigator.component.scss'],
})
export class PageNavigatorComponent implements OnInit, OnDestroy {
  isPageGridOpen = false;
  pageView: 'line' | 'grid' = 'line';

  zoomPercentage = 100;
  _onZoomPergentageChange: Subscription = null;
  _subscriptions: Subscription[] = [];

  constructor(
    private _zoomService: ZoomService,
    private _zoomServiceV2: ZoomServiceV2,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onZoomPergentageChange =
      this._zoomServiceV2.onCurrentZoomSizeChange.subscribe(
        zoom => (this.zoomPercentage = zoom),
      );

    this._subscriptions.push(this._onZoomPergentageChange);
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  togglePageGrid() {
    this.isPageGridOpen = !this.isPageGridOpen;
  }

  setPageView(view: 'line' | 'grid') {
    this.pageView = view;
    this.isPageGridOpen = true;
  }

  fitPage() {
    this._zoomService.fitPage();
  }

  setZoomPercentage(percentage: number) {
    this._zoomService.applyZoomPercentage(percentage / 100);
  }

  toggleFullscreen() {
    this._zoomService.toggleFullscreen();
  }
}
