<div *ngIf="elements && elements.length > 0"
  class="formats-results__grid"
  [ngClass]="{
    'formats-results__grid--expanded': expandGrid,
    'formats-results__grid--bigger': hasBigGrid
  }"
>
  <div
    class="formats-results__element"
    *ngFor="let element of elements"
    (click)="emitElement(element)"
  >
    <span
      *ngIf="element.contentType?.toLowerCase() === 'path'"
      [class.svg-with-path]="element.contentType?.toLowerCase() === 'path'"
      [inlineSVG]="element.thumbUrls.low || element.elementUrl"
    >
    </span>
    <img
      *ngIf="element.contentType?.toLowerCase() !== 'path'"
      [src]="element.thumbUrls.low || element.elementUrl"
      [alt]="element.originalFilename"
      crossorigin="anonymous"
    />
  </div>
</div>

<div *ngIf="loading" class="formats-results__loading">
  <i class="trakto-icon-stream-cog" [ngStyle]="{ color: primaryColor }"></i>
</div>

<div *ngIf="!loading && elements?.length === 0" class="formats-results__empty">
  <trakto-empty-state></trakto-empty-state>
</div>

<div
  *ngIf="hasNextPage && !loading"
  (click)="loadMore()"
  class="formats-results__load_more"
>
  <trakto-see-more></trakto-see-more>
</div>
