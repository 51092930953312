import { IElementModel, PageModel } from '@trakto/models';

export enum DirectionEnum {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum AxisEnum {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export interface IAlignDataEvent {
  axis: AxisEnum;
  direction: DirectionEnum;
  toPage: boolean;
  applyInElements: boolean;
  property: string;
}

export class AlignInfo {
  constructor(
    public page: PageModel,
    public elements: IElementModel[],
    public axis: AxisEnum,
    public direction: DirectionEnum,
    public toPage: boolean,
  ) {}
}
