import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';
import { ImageUtilService } from '@services/image-util.service';
import { StorageService } from '@shared/storage/storage-service.service';
import { UploadStorageService } from '@shared/storage/upload.storage.service';
import { DimensionModel } from '@trakto/core-editor';
import Dexie from 'dexie';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const ONE_WEEK_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 7;

@Injectable({
  providedIn: 'root',
})
export class Base64CacheService extends ImageUtilService {
  private dexie: any;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    authService: AuthService,
    storageService: StorageService,
    uploadStorageService: UploadStorageService,
    hotkeyService: HotkeyService,
    httpClient: HttpClient,
  ) {
    super(
      authService,
      storageService,
      uploadStorageService,
      hotkeyService,
      httpClient,
    );
    if (isPlatformBrowser(platformId)) {
      this.initDexie();
    }
  }

  public loadAndMakeBase64(
    url: string,
    dimension?: DimensionModel,
  ): Observable<string> {
    if (url.indexOf('svg+xml') !== -1) {
      // evita fazer cache do svg
      return super.loadAndMakeBase64(url, dimension);
    }
    const key = url + dimension.toQueryParams();
    return from(this.fetchImgBase64(key)).pipe(
      switchMap((result: { base64 }) => {
        if (result) {
          return of(result.base64);
        }
        return super
          .loadAndMakeBase64(url, dimension)
          .pipe(switchMap(base64 => from(this.addImgBase64(key, base64))));
      }),
    );
  }

  public fetchImgBase64(
    url: string,
  ): Observable<{ base64: string; id: string }> {
    return from(this.dexie.imgBase64.get(url)).pipe(
      tap(result => {
        if (result) {
          this.updateLastAccessFromImgBase64(url);
        }
      }),
      map(result => result as { base64; id }),
    );
  }

  public fetchFontBase64(
    font: string,
  ): Observable<{ base64: string; id: string }> {
    return from(this.dexie.fontBase64.get(font)).pipe(
      tap(result => {
        if (result) {
          this.updateLastAccessFromFontBase64(font);
        }
      }),
      map(result => result as { base64; id: string }),
    );
  }

  public addImgBase64(url: string, base64: string): Promise<string> {
    return this.dexie.imgBase64.add({ base64, id: url, lastRead: Date.now() })
      .then(() => base64)
      .catch(() => base64);
  }

  public addFontBase64(font: string, base64: string) {
    this.dexie.fontBase64
      .add({ base64, id: font, lastRead: Date.now() })
      .then();
  }

  public clearIndexedDB() {
    if (!this.dexie) {
      this.dexie = new Dexie('trakto-db');
    }

    this.dexie.imgBase64.clear();
    this.dexie.fontBase64.clear();
  }

  private initDexie() {
    this.dexie = new Dexie('trakto-db');
    this.dexie.version(1).stores({
      imgBase64: '++id,base64,lastRead',
      fontBase64: '++id,base64,lastRead',
    });
    this.updateCache();
  }

  private updateCache() {
    // NOTE: Talvez, com a criação da função 'clearIndexedDB' esta função deixa de fazer sentido, uma vez que o cache é limpo sempre que o editor abre e não a cada 1 semana.
    // Porém vou deixar o código afim de garantir a integridade da aplicação.
    this.dexie.imgBase64
      .where('lastRead')
      .below(Date.now() - ONE_WEEK_IN_MILLISECONDS)
      .delete();
    this.dexie.fontBase64
      .where('lastRead')
      .below(Date.now() - ONE_WEEK_IN_MILLISECONDS)
      .delete();
  }

  private updateLastAccessFromImgBase64(key: string) {
    // bug no chrome aumenta o tamanho do armazenamento a cada update.
    // desabilitado enquanto persistir o problema.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=795735
    // this.dexie.imgBase64.update(key, { lastRead: Date.now() }).then();
  }

  private updateLastAccessFromFontBase64(key: string) {
    // this.dexie.fontBase64.update(key, { lastRead: Date.now() }).then();
  }
}
