import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalShareComponent } from './components/share/modal-share.component';

import { KeyEnum } from '@services/hotkeys/hotkeys.enum';
import { IDocumentModel, IElementModel, PageModel, } from '@trakto/models';

import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { DocumentManagerService } from '@services/document-manager.service';
import { PlanConfigService } from '@shared/subscription/plan-config.service';

import { environment } from '@env/environment';

import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '@services/locale.service';
import { PremiumService } from '@services/premium.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { SubscriptionService } from '@shared/subscription/subscription.service';
import { KmlModalService } from '@trakto/trakto-kamaleon';
import { GoSquaredService } from './services/gosquared/gosquared.service';
import { CoverService } from '@services/design/cover.service';
import { PlatformLoadingService } from '@services/platform-loading.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  public b2c = false;
  public newHeader = true;

  document: IDocumentModel;

  pageSelected: PageModel;
  elementSelected: IElementModel;

  public showBannerToUser = false;

  @Input() panelPublish: any;

  private _destroy$ = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) public DOM: Document,
    private cd: ChangeDetectorRef,
    private readonly router: Router,
    private readonly hotkeyService: HotkeyService,
    public readonly documentManagerService: DocumentManagerService,
    public readonly documentStateManagerService: DocumentStateManagerService,
    private planConfigService: PlanConfigService,
    public _productService: WhitelabelProductService,
    private _subscriptionService: SubscriptionService,
    private _premiumService: PremiumService,
    private _kmlModal: KmlModalService,
    private _translateService: TranslateService,
    private _localeService: LocaleService,
    private _goSquaredService: GoSquaredService,
    private _coverService: CoverService,
    private _loaderPlatformService: PlatformLoadingService,
    private _userService: UserService,
  ) {
    this._goSquaredService.showAssistant(false);
    this._subscriptionService.planChanged.pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      this.loadUserPlanConfig();
    });
    this.loadUserPlanConfig();
    this._premiumService.configure();
  }


  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this._setupUser();
    this._startDocumentOnEditor();
    this._startContext();
  }


  async configBanner() {
    const topBannerEnnable = environment.topBannerConfig.ennable || false;
    if (!topBannerEnnable) {
      this.showBannerToUser = false;
      return;
    }
    const { subscription_plan_name } = this._userService.user;
    const isB2c = this._userService.isB2c;
    const isPt = this._translateService.currentLang === 'pt-BR';
    const isFreeUser = subscription_plan_name === 'free';
    this.showBannerToUser = isB2c && isPt && isFreeUser;
  }

  private _startDocumentOnEditor() {
    this.documentManagerService.loadDocument(this.activatedRoute.snapshot.params['document']);
  }

  private async _setupUser() {
    this._userService.getProfile().subscribe((user) => {
      const userLang =
        this.activatedRoute.snapshot.queryParamMap.get('lang') ||
        this._localeService.getLocaleFromUser(user);
      this._localeService.init(userLang);
      this._translateService.currentLang = userLang;
      this.b2c = this._userService.isB2c;
      this.configBanner();
    });
  }

  private _startContext() {
    this.documentStateManagerService.document$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(async document => {
      this.document = document;
      this._loaderPlatformService.hide();
    });

    this.documentManagerService.onSaveFinished.pipe(takeUntil(this._destroy$),).subscribe(event => {
      if (!event.saved) {
        return;
      }

      if (event.isNew) {
        this.router.navigate(['./', event.document.firebaseId], {
          relativeTo: this.activatedRoute,
        });
      }

      if (!event.firstPageChanged || !event.requestCoverGeneration) {
        return;
      }

      try {
        this._coverService.generateThumb(this.document).catch(error => {
          console.error(error);
        });
      } catch (error) {
        console.error(error);
      }
    });

    this.hotkeyService.addContext('codekeys', (e: KeyboardEvent) => {
      if (this.hotkeyService.CMD_SHIFT_KEY(e)) {
        if (e.which === KeyEnum.s) {
          this.hotkeyService.stopPropagation(e);
          this.documentManagerService
            .save(true)
            .then()
            .catch(err => console.error(err));
        }
      }
    });
  }

  loadUserPlanConfig(): void {
    this.planConfigService
      .loadUserPlanConfig()
      .then()
      .catch(err => console.error(err));
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this.documentManagerService.reset();
  }

  /**
   * Método para realizar a abertura do modal de compartilhamento do documento
   * @param event {MouseEvent}
   */
  public onShare(_event: MouseEvent): void {
    this.hotkeyService.disableHotkeys();

    const modalRef = this._kmlModal.open(
      ModalShareComponent,
      {
        data: { docId: this.document.firebaseId, ...this.document },
      },
      { width: '480px', height: 'auto' },
    );

    modalRef.afterClosed.pipe(takeUntil(this._destroy$),).subscribe(props => {
      if (!props) {
        return;
      }

      if (!props.exists) {
        this.documentStateManagerService.persistDocumentChangesNoTrackable({ slug: props.slug })
        this.document.slug = props.slug;
      }

      if (this.document.allow_preview !== props.allow_preview) {
        this.documentStateManagerService.persistDocumentChangesNoTrackable({ allow_preview: props.allow_preview })
      }
      this.documentManagerService
        .save(false)
        .then(() => this.hotkeyService.enableHotkeys())
        .catch(() => this.hotkeyService.enableHotkeys());
    });
  }

}
