import { IDocumentModel, IEventModel, PageModel } from '@trakto/models';

import { ExportService } from '@services/export/export.service';

import {
  PageSVGConversionService
} from '@app/shared/svg-viewer/shared/page-svg-conversion.service';
import {
  ConversionParams,
  DEFAULT_STYLES_CLASSES,
} from '@trakto/svg-converter';
import { Injectable } from '@angular/core';
import { TraktoApiService } from '@services/trakto-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

declare var download: any;

@Injectable()
export class ExportUtilModel {
  private busy: boolean;

  constructor(
    private _exportService: ExportService,
    private _pageConversionService: PageSVGConversionService,
    private _apiService: TraktoApiService,
    private _httpClient: HttpClient
  ) {
    this.busy = false;
  }

  async saveAsVideo(
    currentDocument: IDocumentModel,
    currentPage: PageModel,
  ): Promise<IEventModel> {
    try {
      const results: { result: string }[] = await this._apiService.executeBellaPost(`generate-mp4`, {
        pages: [ currentPage ]
      });
      const url = `${ environment.api.bellaUrl }${ results[0].result }`;
      const data = await this._httpClient.get(url, { responseType: 'blob' }).toPromise();

      download(data, `${ currentDocument.title || 'trakto-video' }.mp4`, 'mp4');
      return this.downloadComplete();
    } catch (error) {
      return this.downloadError(error);
    }
  }

  public downloadComplete(): IEventModel {
    return {
      event: 'DownloadSuccess',
      data: {
        success: true,
      },
      bubbles: false,
    };
  }

  public downloadError(error: Error = null): IEventModel {
    return {
      event: 'DownloadError',
      data: {
        success: false,
        error,
      },
      bubbles: false,
    };
  }

  private _getSvgPage(document?: IDocumentModel, page?: PageModel): string {
    const params = ConversionParams.makeConversionParams(
      !!document?.is_printable,
      1,
    );
    params.classes = params.classes || DEFAULT_STYLES_CLASSES.join(' ');
    return this._pageConversionService.toSVG(
      page,
      params,
    );
  }
}
