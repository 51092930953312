<div class="trakto-modal">
  <div class="--anchor"></div>

  <div
    class="trakto-modal__side-nav"
    [class.--custom-modal]="customModal"
    [style.max-width.px]="sideNavWidth"
    [style.min-width.px]="sideNavWidth"
  >
    <ng-content select="[side-nav]"></ng-content>
  </div>

  <div class="trakto-modal__body">
    <div
      class="trakto-modal__header"
      [class.--custom-modal]="customModal"
      [style.border-bottom.px]="borderHeight"
    >
      <div class="trakto-modal__input">
        <ng-content select="[body-header]"></ng-content>
      </div>
      <div class="trakto-modal__icons">
        <i class="trakto-icon-maximize" *ngIf="false" (click)="maximize()"></i>
        <i class="trakto-icon-minimize" *ngIf="false" (click)="minimize()"></i>
        <i
          class="trakto-icon-tkt-close"
          *ngIf="allowClose"
          (click)="close()"
        ></i>
      </div>
    </div>

    <div class="trakto-modal__content" [class.--custom-modal]="customModal">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>

<div class="trakto-modal__overlay" *ngIf="allowClose" (click)="close()"></div>
<div class="trakto-modal__overlay --disabled" *ngIf="!allowClose"></div>
