import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';

import { IFontModel, ITextElementModel, PageModel } from '@trakto/models';

import { PlanConfigService } from '@app/shared/subscription/plan-config.service';
import { SearchFontPipe } from '@editor/components/properties-panel/panel-text-font/search-font.pipe';
import { FontsService } from '@editor/services/fonts.service';
import { SignalsService } from '@shared/signals/signals.service';

import { EnumModalComponent } from '@trakto/trakto-premium';

import { PremiumService } from '@app/editor/services/premium.service';
import { TextEditorService } from '@services/text-editor.service';
import { Subject, Subscription } from 'rxjs';
import { AssetsGalleryAnalyticsService } from '@app/editor/services/analytics/wrapper/elements/assetsGallery.analytics-wrapper.service';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-font-list',
  templateUrl: './font-list.component.html',
  styleUrls: ['./font-list.component.scss'],
})
export class FontListComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  public fonts;

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Input()
  private searchFontText: string;

  @Input()
  container: any;

  @Input()
  selectorColor: string;

  @Output()
  selectedFont: EventEmitter<IFontModel> = new EventEmitter<IFontModel>();

  public visibleFonts = [];
  public userIsPremium = false;
  public isFavorite = false;
  public fontSelected: any;

  private filteredFonts = [];
  private fontPageSize = 5;
  private viewedFontLastIndex = -1;
  private _delayDataTracking: any;
  private unsubscribe$ = new Subject<void>();
  private scrollFunc: (p?: any) => any;
  public selectedFontFamily: string;
  private _changeListener: Subscription;

  constructor(
    private _elementRef: ElementRef,
    private _userService: UserService,
    private _planConfigService: PlanConfigService,
    private _fontsService: FontsService,
    private _premiumService: PremiumService,
    private _signalsService: SignalsService,
    private _textEditorService: TextEditorService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    private _assetsAnalyticsService: AssetsGalleryAnalyticsService
  ) {
    this._checksUserIsPremium();
    this.scrollFunc = this._checkMorePage.bind(this);
  }

  ngOnInit() {
    this.container.addEventListener('scroll', this.scrollFunc);
    // this._resetPagination();
    this._changeListener = this._textEditorService.selectionChange.subscribe(
      () => {
        this._initSelectedFontFamily();
      },
    );
  }

  ngOnChanges(changes) {
    if (changes.searchFontText && this.searchFontText !== undefined) {
      this.searchFontText = this.searchFontText.replace(/^\s+/g, '');
      this._resetPagination();
    }

    if (changes.fonts) {
      this._resetPagination(
        changes.fonts?.previousValue?.length !==
          changes?.fonts?.currentValue?.length,
      );
    }

    this._initSelectedFontFamily();
  }

  ngOnDestroy() {
    this.container.removeEventListener('scroll', this.scrollFunc);
    this._changeListener.unsubscribe();
  }

  public async openPremiumCheckoutFonts() {

    const checkoutData = await this._premiumService.getCheckoutConfig();

    this._premiumService.openModalBlocking(
      EnumModalComponent.MODAL_FONTS,
      checkoutData
    );
  }

  /**
   * Seleciona a fonte do elemento texto.
   */
  public async selectFont(font: IFontModel) {

    // FIXME: Criar a permissão correta.
    const hasPermission: boolean = await this._planConfigService.getPermissionPromise('use_template_premium');

    if (font.premium && !hasPermission) {
      this.openPremiumCheckoutFonts();
      return ;
    } else if (this.elementSelected && font.family !== this.elementSelected.fontFamily) {
      this._elementChangeFacadeService.changeTextFontFamily(this.elementSelected, {...font});
      this.selectedFont.emit({ ...font });
      this._initSelectedFontFamily();
    }
  }

  private _checksUserIsPremium(): void {
    this._planConfigService.getPlanPremium().then(async (premiumPlans) => {
      const plan = await this._planConfigService.getUserPlanId();
      this.userIsPremium = premiumPlans.includes(plan);
    });
  }

  private _initSelectedFontFamily() {
    if (!this.elementSelected) {
      return;
    }
    this.selectedFontFamily = this._textEditorService.getSelectedFontFamily(
      this.elementSelected as ITextElementModel,
    );
  }

  /**
   * Reinicia a paginação.
   */
  private _resetPagination(force = false) {
    this.viewedFontLastIndex = 0;
    this._showNextFontsPage(force);
    setTimeout(() => this._checkMorePage());
  }

  /**
   * Verifica a existência de mais páginas.
   */
  private _checkMorePage() {
    if (this._isContainerScrollAfterMiddle() && this._hasNextPage()) {
      this._showNextFontsPage();
      /**
       * Se as fontes atuais não forem suficientes para ocupar
       * o restante do container, é realizada uma
       * verificação recursiva.
       */
      setTimeout(() => this._checkMorePage(), 0);
    }
  }

  private _showNextFontsPage(forceClean = false) {
    this.filteredFonts = new SearchFontPipe()
      .transform(this.fonts, this.searchFontText)
      .map(font => ({ ...font }));

    const oldCount = this.viewedFontLastIndex;

    this.viewedFontLastIndex = Math.min(
      this.filteredFonts.length,
      this.viewedFontLastIndex + this.fontPageSize,
    );

    const newVisibleFonts = this.filteredFonts
      .slice(oldCount, this.viewedFontLastIndex)
      .map(font => ({ ...font }));

    newVisibleFonts.forEach(newFont => {
      this.fonts.forEach(font => {
        if (
          font.family === newFont.family &&
          !font.loaded &&
          !font.donwloadError
        ) {
          this._fontsService.loadByFontModel(font).pipe(takeUntil(this.unsubscribe$)).subscribe(
            success => (font.loaded = true),
            error => (font.donwloadError = true),
          );
        }
      });
    });

    if (this.searchFontText || forceClean) {
      this.visibleFonts = newVisibleFonts;
    } else {
      // this.visibleFonts = this.visibleFonts.concat(newVisibleFonts);
      newVisibleFonts.forEach(font => {
        const index = this.visibleFonts.findIndex(
          item => item.family === font.family,
        );
        if (index === -1) {
          this.visibleFonts = [...this.visibleFonts, ...[font]];
        } else {
          this.visibleFonts[index] = font;
        }
      });
    }

    this._showNextFontsPageDataTrackint();
  }

  private _showNextFontsPageDataTrackint() {
    clearTimeout(this._delayDataTracking);
    this._delayDataTracking = setTimeout(() => {
      const lastText = this._assetsAnalyticsService.lastSearchedTextEvent;
      const dirty = this.searchFontText === lastText;
      this._assetsAnalyticsService.lastSearchedTextEvent = dirty
        ? lastText
        : this.searchFontText;

      if (dirty) {
        this._assetsAnalyticsService.trackProjectAssetSearch({
          search_query: this.searchFontText,
          element_type: 'text',
          success: true,
          total_displayed: this.visibleFonts.length,
          total_found: this.filteredFonts.length,
        });
      }
    }, 800);
  }

  private _hasNextPage() {
    return this.viewedFontLastIndex !== this.filteredFonts.length;
  }

  public favoriteFont(font) {
    this._updateLocaly(font);
    this._fontsService.favoriteTypography(font);
  }
  public unFavoriteFont(font) {
    this._updateLocaly(font);
    this._fontsService.deleteFavoriteTypography(font);
  }

  private _updateLocaly(font) {
    const visibleIndex = this.visibleFonts.findIndex(
      item => item?.id === font?.id,
    );
    const fontsIndex = this.fonts.findIndex(item => item?.id === font?.id);

    if (visibleIndex > -1) {
      this.visibleFonts[visibleIndex].is_favorite =
        !this.visibleFonts[visibleIndex].is_favorite;
    }

    if (fontsIndex > -1) {
      this.fonts[fontsIndex].is_favorite = !this.fonts[fontsIndex].is_favorite;
    }
  }

  public deleteFont(font) {
    if (font.provider === 'user' || font.provider === 'custom') {
      this._fontsService.deleteUserTypography(font);
    }
  }

  private _isContainerScrollAfterMiddle() {
    const scrollBottom = this.container.scrollTop + this.container.offsetHeight;
    const fontSamples =
      this._elementRef.nativeElement.querySelectorAll('.font-list__item');
    const lastFontSample = fontSamples[fontSamples.length - 1];
    if (lastFontSample) {
      const lastFontSampleOffsetBottom =
        lastFontSample.offsetTop + lastFontSample.offsetHeight;
      return scrollBottom > lastFontSampleOffsetBottom / 1.3;
    }
  }
}
