import { Injectable } from '@angular/core';
import { IFontModel } from '@trakto/models';
import { FontModelUtil } from '@trakto/core-editor';
import { AuthService } from '@auth/shared/auth.service';
import { TraktoApiService } from '@services/trakto-api.service';

@Injectable({
  providedIn: 'root',
})
export class FontRepository {
  constructor(
    private _authService: AuthService,
    private _traktoApiService: TraktoApiService,
  ) {}

  async findByLoggedUser(): Promise<IFontModel[]> {
    return this._convertApiFontToFontModels(
      await this._traktoApiService.executeGet<any[]>('editor/typography/from_user').catch(e => [])
    );
  }

  async findByCurrentProduct(): Promise<IFontModel[]> {
    return this._convertApiFontToFontModels(
      await this._traktoApiService.executeGet<any[]>('editor/typography').catch(e => [])
    );
  }

  async findByFamily(fontFamily: string): Promise<IFontModel> {
    return this._convertApiFontToFontModels(
      [await this._traktoApiService.executeGet<any>(`editor/typography/search/${fontFamily}`)]
    )[0];
  }

  /**
   * Função de upload de fonts para a API.
   * @param files Arquivos ttf ou otfs
   * @returns Success or Failure
   */
  public async uploadFonts(files: File[]): Promise<IFontModel[]> {
    const formData: FormData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
    const fontUploaded: any[] = await this._traktoApiService.executePost(`editor/typography/from_user`, formData, false);
    return this._convertApiFontToFontModels(fontUploaded);
  }

  private _convertApiFontToFontModels(rawData: any[]): IFontModel[] {
    return rawData
      .map(rawFont => {
        const toBuild = {
          font_provider: rawFont?.provider || rawFont?.font_provider,
          thumb: '',
          is_premium: rawFont?.is_premium || false,
          [rawFont.family_name]: {
            font_provider: rawFont?.provider || rawFont?.font_provider,
            thumb: '',
            is_premium: rawFont?.is_premium || false,
            ...rawFont,
          },
          ...rawFont,
        };
        const builtFont = FontModelUtil.buildFont(toBuild);
        return this._normalizeFontFamily({
          id: rawFont?.id || null,
          ...builtFont,
        } as IFontModel);
      })
      .filter(font => FontModelUtil.isValid(font));
  }

  private _normalizeFontFamily(font: IFontModel): IFontModel {
    font.family = this._trimEnd(font.family);
    font.options.forEach(option => {
      option.font = this._trimEnd(option.font);
    });
    return font;
  }

  private _trimEnd(text: string) {
    return text?.replace(/\s+$/, '');
  }
}
