import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TraktoLinksAnalyticsService } from '@app/editor/services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { IMetadata, TraktoLink, TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { Store, select } from '@ngrx/store';
import html2canvas from 'html2canvas';
import { TraktoLinkState } from '../store/trakto-link.reducer';
import { Observable, Subject } from 'rxjs';
import * as fromTraktoLink from '../store/trakto-link.selectors';
import * as fromTraktoLinkActions from '../store/trakto-link.actions';
import { takeUntil } from 'rxjs/operators';
import { Metadata } from 'libphonenumber-js';

@Component({
  selector: 'trakto-trakto-link-panel',
  templateUrl: './trakto-link-panel.component.html',
  styleUrls: ['./trakto-link-panel.component.scss']
})
export class TraktoLinkPanelComponent implements OnInit, OnDestroy {

  @Input() doc: any;
  @Input() isB2C: any;
  public traktolink$: Observable<TraktoLink>;
  public traktoLink: any;

  public linkPlaceholder = 'https://trakto.link/';
  public oldTraktoLink: string = '';
  public inputValue = '';
  public link = {
    status: false,
    url: 'www.trakto.io',
    copied: false,
    loading: false,
    success: false,
    typing: true
  }
  public inputMask = {
    mask: /^[\w-_]+$/,
  };
  public downloadLoading: boolean = false;
  @ViewChild('qrCodeTemplate', { static: true })
  qrCodeTemplate: ElementRef<any>;
  private _inputTyping = {
    timer: undefined,
    intervalSecond: 1,
  };
  public linkLength = {
    min: 1,
    max: 100,
  };
  public traktoLinkPaused: boolean = false;
  private _destroy$ = new Subject<void>();
  @Input() loading: boolean;

  constructor(
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
    private _traktoLinksService: TraktoLinksService,
    private _traktoLinkStore: Store<TraktoLinkState>,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {

    this.traktolink$ = this._traktoLinkStore.pipe(select(fromTraktoLink.selectTraktoLink));

    this.traktolink$.pipe(takeUntil(this._destroy$)).subscribe((link) => {

      if (link === undefined || link === null) {
        return;
      }
      this.traktoLink = link;
      this.inputValue = link.link_name ? link.link_name : link.link_web;
      this.oldTraktoLink = link.link_name ? link.link_name : link.link_web;
      this.traktoLinkPaused = link.pausedLink ? link.pausedLink : false;
    });

    this.linkPlaceholder = this.isB2C ? 'https://trakto.link/' : 'https://olink.pro/';
  }


  public tooglePauseTraktoLink() {
    this.traktoLinkPaused = !this.traktoLinkPaused;
    this._traktoLinksService.pauseTraktoLink(this.traktoLink.link_name ? this.traktoLink.link_name : this.traktoLink.slug, this.traktoLinkPaused).then(
      (data) => {

        this.traktoLink = {
          ...this.traktoLink,
          pausedLink: this.traktoLinkPaused
        }
        this._traktoLinkStore.dispatch(fromTraktoLinkActions.editTraktoLink({ traktoLink: this.traktoLink }));
      }
    ).then(
      () => {
        this._traktoLinksService.createOrUpdateMetaData(this.traktoLink).then();
      }
    ),
      (erro) => {
        this.traktoLinkPaused = !this.traktoLinkPaused;
      }
  }

  public downloadQRCode() {
    let successDownload = false;
    this.downloadLoading = true;

    html2canvas(this.qrCodeTemplate.nativeElement)
      .then(canvas => {
        document.body.appendChild(canvas);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `qrcode_${this.linkPlaceholder + (this.traktoLink.link_name ? this.traktoLink.link_name : this.traktoLink.slug)}.png`;
        a.click();
        successDownload = true;
      })
      .catch(error => {
        successDownload = false;
        console.error('error:', error);
      })
      .finally(() => {
        this._tktLinksAnalytics.traktoLinksDownloadQR({
          link_name: this.linkPlaceholder + (this.traktoLink.link_name ? this.traktoLink.link_name : this.traktoLink.slug),
          success: successDownload,
        });

        setTimeout(() => {
          this.downloadLoading = false;
        }, 500);
      });
  }


  public copyLinkToClipboard() {
    const elem = document.createElement('input');
    elem.value = `${this.linkPlaceholder + (this.traktoLink.link_name ? this.traktoLink.link_name : this.traktoLink.link_web)}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 2000);

    this._tktLinksAnalytics.traktoLinksCopiedLink({
      linkName: this.linkPlaceholder + (this.traktoLink.link_name ? this.traktoLink.link_name : this.traktoLink.linkWeb),
    });
  }

  public onLinkTyping(event: any) {
    const linkName: string = event.target.value;
    this.link.typing = true;

    if (!this._isValidKey(event.keyCode)) {
      this.link.typing = false;
      this.link.success = false;
      return false;
    }

    clearTimeout(this._inputTyping.timer);

    if (
      (linkName.length > this.linkLength.min &&
        linkName.length <= this.linkLength.max) ||
      linkName.length === 0
    ) {
      this._waitingToSend(linkName);
    }
  }

  private _isValidKey(keyCode: any) {
    const validFastKeys = [8, 13]; // backspace && enter
    if (validFastKeys.indexOf(keyCode) > -1) {
      return true;
    }

    if (
      !(keyCode > 47 && keyCode < 58) && // numeric (0-9)
      !(keyCode > 64 && keyCode < 91) && // upper alpha (A-Z)
      !(keyCode > 96 && keyCode < 123) && // lower alpha (a-z)
      !(keyCode > 189) // - and _
    ) {
      return false;
    }

    return true;
  }

  private _waitingToSend(linkName: string) {
    this._inputTyping.timer = setTimeout(() => {
      this._updateLink(linkName);
    }, this._inputTyping.intervalSecond * 1000);
  }

  private async _updateLink(linkName: string) {
    if (this._hasChanged(linkName)) {
      const isEdition = !!this._traktoLinksService.linkName;
      let succesChange = false;

      this.link.loading = true;
      this.inputValue = linkName;

      let updateLink: TraktoLink = new TraktoLink({
        slug: this.inputValue,
        link_web: this.inputValue,
        seo: new TraktoLinkSeo({
          thumbUrl: this.traktoLink.image,
          title: this.traktoLink.title,
          description: this.traktoLink.description
        }),
        pausedLink: this.traktoLinkPaused
      });

      this._traktoLinksService.createMetadataAndPublish(updateLink, this.isB2C).then(
        async (link) => {
          //success
          this.link.typing = false;
          this.link.success = true;

          this._traktoLinkStore.dispatch(fromTraktoLinkActions.editTraktoLink({ traktoLink: updateLink }));
        }
      ).catch(
        async (err) => {
          this.link.typing = false;
          this.link.success = false;
          let updateLink: TraktoLink = new TraktoLink({
            slug: this.oldTraktoLink,
            link_web: this.oldTraktoLink,
            seo: new TraktoLinkSeo({
              thumbUrl: this.traktoLink.image,
              title: this.traktoLink.title,
              description: this.traktoLink.description
            }),
            pausedLink: this.traktoLinkPaused
          });

          let metadata: IMetadata = {
            link_name: this.oldTraktoLink,
            link_web: this.oldTraktoLink,
            pausedLink: this.traktoLinkPaused,
            title: this.traktoLink.title,
            description: this.traktoLink.description,
            image: this.traktoLink.image
          }

          await this._traktoLinksService.createOrUpdateMetaData(metadata)
          this._traktoLinkStore.dispatch(fromTraktoLinkActions.editTraktoLink({ traktoLink: updateLink }));
        }
      )

      this._tktLinksAnalytics.traktoLinksEditedOrCreationLink({
        old_link_name: this.oldTraktoLink,
        new_link_name: this._traktoLinksService.link,
        success: succesChange,
        is_edition: isEdition,
      });
    }
  }


  selectionRange() {
    document.getElementById('subdomain').focus();
  }

  private _hasChanged(linkName) {
    if (this.traktoLink.link_web === linkName) {
      return false;
    }
    return true;
  }

}
