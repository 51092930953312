import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare const jivo_api: any;

@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnDestroy {
  public userStore: any;
  public limit = 20;
  public currentProduct: any;
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _productService: WhitelabelProductService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public async configChat(user: any = null) {
    this.userStore = user;
    this.currentProduct = await this._productService.getCurrentProduct();

    if (environment.externalIntegrations.jivochat) {
      this._productService.featuresAvailable.pipe(takeUntil(this._destroy$)).subscribe(features => {
        if (features) {
          const supportAvailable = features[ProductFeatures.CUSTOMER_SUPPORT];
          if (supportAvailable) {
            this.addChatToDom();
          }
        }
      });
    }
  }

  public open(): void {
    jivo_api.open();
  }

  public addChatToDom(): void {
    const chatScript = document.createElement('script');
    const chatSrc = environment.api.jivoChat.src;
    const chatCode = environment.api.jivoChat.code;

    chatScript.async = false;
    chatScript.defer = true;
    this._document.head.appendChild(chatScript);

    chatScript.onload = () => {
      if (this.userStore) {
        this.setContactInfo({
          name: `${this.userStore.firstname} ${this.userStore.lastname}`,
          email: this.userStore.email,
          phone: null,
          description: null,
        });
      }
    };

    chatScript.src = `${chatSrc}${chatCode}`;
  }

  public setContactInfo(user: any): void {
    if (window['jivo_api']) {
      jivo_api.setContactInfo(user);
      return;
    }

    if (this.limit > 0) {
      this.limit -= 1;
      setTimeout(() => {
        if (this.userStore) {
          this.setContactInfo(user);
        }
      }, 1000);
    }
  }
}
