export enum ProductFeatures {
  CUSTOMER_SUPPORT = 'customer_support',
  EXPORT_PDF = 'design_export_pdf',
  EXPORT_PNG = 'design_export_png',
  EXPORT_VIDEO = 'design_export_video',
  LINKS_PREVIEW = 'design_links_preview',
  PRESENTATION_PREVIEW = 'design_presentation_preview',
  SHARE_AS_TEMPLATE = 'design_share_as_template',
  ELEMENT_FILTERS = 'element_filters',
  ELEMENT_LINKS = 'element_links',
  PAGE_LAYOUT = 'element_page_layout',
  GIF_GALLERY = 'gif_gallery',
  TEXT_GALLERY = 'text_gallery',
  ICON_GALLERY = 'icon_gallery',
  VIDEO_GALLERY = 'video_gallery',
  IMAGE_GALLERY = 'image_gallery',
  SHAPE_GALLERY = 'shape_gallery',
  REMOVE_PHOTO_BG = 'remove_photo_bg',
  UNDEFINED = 'undefined',
}
