import { IDocumentModel } from '@trakto/models';

export class SaveStaredEventModel {
  constructor(
    public document: IDocumentModel,
    public savedChangeLast: boolean,
    public forcedSave: boolean,
  ) {}

  public isSaving() {
    return !this.savedChangeLast || this.forcedSave;
  }
}
