import { Component, Input, ViewChild } from '@angular/core';

import { IDropdownSelectConfig, PageModel } from '@trakto/models';

import { YoutubeService } from '@app/editor/services/youtube/youtube.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';

@Component({
  selector: 'trakto-panel-video-settings',
  templateUrl: './panel-video-settings.component.html',
})
export class PanelVideoSettingsComponent {
  public dropdownBackgroundImagePosition: IDropdownSelectConfig;
  public preview: string;
  public url: string;

  @ViewChild('buttonYoutubePreview', { static: true })
  buttonYoutubePreview: any;
  @ViewChild('element', { static: true }) element: any;
  @ViewChild('input', { static: true }) input: any;

  @Input() pageSelected: PageModel;

  // @Output() onChangeProperty: EventEmitter<string> = new EventEmitter<string>();
  // @Output() onOpenVideoSettings: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() onSetYoutubeButtonPreview: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  constructor(
    private hotkeyService: HotkeyService,
    private youtubeService: YoutubeService,
  ) {
    // TODO:Inject translate service
    this.dropdownBackgroundImagePosition = {
      options: [
        { label: 'Topo esq.', value: 'topLeft', selected: false },
        { label: 'Topo dir.', value: 'topRight', selected: false },
        { label: 'Topo centro', value: 'topCenter', selected: false },

        { label: 'Centro esq.', value: 'centerLeft', selected: false },
        { label: 'Centro dir.', value: 'centerRight', selected: false },
        { label: 'Centro', value: 'centerCenter', selected: true },

        { label: 'Rodapé esq.', value: 'bottomLeft', selected: false },
        { label: 'Rodapé dir.', value: 'bottomRight', selected: false },
        { label: 'Rodapé centro', value: 'bottomCenter', selected: false },
      ],
      buttonConfig: {
        classes: ['button--outline', 'button--light'],
      },
    };
  }

  public changeProperty(property: string): void {
    // this.onChangeProperty.emit(property);
  }

  public removeBackgroundOnPage() {
    if (this.pageSelected) {
      this.pageSelected.backgroundVideo = null;
      this.changeProperty('backgroundVideo');
    }
  }

  public setYoutubeImagePreview(value: string): void {
    const url: string = value
      ? this.youtubeService.getYoutubeThumb(value)
      : null;
    this.url = value;
    this.preview = url;
  }

  public setYoutubeButtonPreview(): void {
    this.preview = null;
    this.input.value = '';

    this.pageSelected.backgroundVideo = this.url;
    this.changeProperty('backgroundVideo');

    // this.onSetYoutubeButtonPreview.emit({
    //   event: 'SetYoutubeBackground',
    //   data: { value: this.url, element: this.buttonYoutubePreview.button.nativeElement },
    //   bubbles: false,
    // });
  }

  public enableHotkeys(): void {
    this.hotkeyService.enableHotkeys();
  }

  public disableHotkeys(): void {
    this.hotkeyService.disableHotkeys();
  }

  public openVideoSettings(value: boolean) {
    // this.onOpenVideoSettings.emit(value);
  }
}
