<div #element id="panel-video-settings" class="panel">
  <div class="panel-background">
    <div class="panel-header">
      <h2>{{ 'property_panel.video.title' | translate }}</h2>

      <button (click)="openVideoSettings(false)">
        <i class="trakto-icon-close"></i>
      </button>
    </div>

    <div class="panel-video-settings__content" *ngIf="pageSelected">
      <trakto-button-preview
        #buttonYoutubePreview
        [url]="pageSelected.backgroundVideo"
      >
      </trakto-button-preview>

      <div>
        <span>Position</span>

        <trakto-dropdown-select
          [config]="dropdownBackgroundImagePosition"
          [disabled]="true"
        >
        </trakto-dropdown-select>

        <trakto-button
          button--fill
          button--white
          (onClick)="removeBackgroundOnPage()"
          [style.marginTop.px]="10"
        >
          <i class="trakto-icon-empty"></i>
          {{ 'property_panel.video.remove' | translate }}
        </trakto-button>
      </div>
    </div>
  </div>

  <div class="panel-background">
    <div class="panel-video-settings__preview">
      <span>{{ 'property_panel.video.add_youtube' | translate }}</span>

      <img
        *ngIf="preview"
        [src]="preview"
        [style.maxWidth.%]="100"
        [style.marginTop.px]="10"
        [style.marginBottom.px]="10"
      />

      <div class="panel-video-settings__group">
        <trakto-input
          #input
          id="backgroundVideo"
          name="backgroundVideo"
          placeholder="Video url"
          (onBlur)="enableHotkeys()"
          (onFocus)="disableHotkeys()"
          (onChange)="
            changeProperty('backgroundVideo'); setYoutubeImagePreview($event)
          "
        >
        </trakto-input>

        <trakto-button
          button--fill
          button--secundary
          (onClick)="setYoutubeButtonPreview()"
        >
          Add
        </trakto-button>
      </div>
    </div>
  </div>
</div>
