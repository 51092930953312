import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DemoModeService {
  private _productId: string;
  public themed: boolean;

  public set productId(id: string) {
    this._productId = id;
  }

  public get productId(): string {
    return this.route.snapshot.queryParams.productId || '';
  }

  constructor(private readonly route: ActivatedRoute) {}

  public isDemoMode(): boolean {
    this._productId = this.route.snapshot.queryParams.productId || '';
    this.themed =
      (this.route.snapshot.queryParams.themed || 'false') === 'true';

    return this.productId !== '' && this.themed === true;
  }
}
