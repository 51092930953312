<div class="panel-full-color">
  <div class="trakto-color-panel">
    <div [style.marginBottom.px]="36" class="trakto-color-panel__selector">
      <trakto-colorpicker
        (onChange)="colorpickerChanged($event)"
        [color]="color"
      ></trakto-colorpicker>
    </div>

    <ng-container *ngFor="let provider of colorsProvider">
      <div
        class="trakto-color-panel__section"
        *ngIf="provider.palettes.length > 0"
      >
        <trakto-color-panel-separator
          *ngIf="provider.titlePalette"
          title="{{
            !!provider.titles
              ? provider.titles[currentLang]
              : provider.titlePalette
          }}"
        ></trakto-color-panel-separator>
        <div *ngFor="let palette of provider.palettes">
          <h1 class="trakto-color-panel__title">
            {{
              palette.titles
                ? palette.titles[currentLang] || palette.title
                : palette.title
            }}
          </h1>
          <div class="trakto-color-panel__grid">
            <!-- TODO refact this component -->
            <trakto-color-item-renderer
              *ngFor="let item of palette.colors"
              [item]="{ type: 'color', value: item }"
              [selected]="
                item?.toLowerCase() === color?.hexadecimal?.toLowerCase()
              "
              (click)="selectColor({ type: 'color', value: item })"
            >
            </trakto-color-item-renderer>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
