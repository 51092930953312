<div class="search">
  <trakto-input
    [value]="inputText"
    placeholder="Pesquise por algo"
    (onChange)="setInputText($event)"
    (onSelect)="selectTag($event)"
  ></trakto-input>
</div>

<trakto-tag-list
  [tags]="tags"
  [selected]="query"
  (onSelect)="selectTag($event)"
></trakto-tag-list>

<div class="image-grid" [ngClass]="{ loading: loading }">
  <trakto-grid-images
    (action)="insertImage($event)"
    [images]="images"
  ></trakto-grid-images>

  <div class="action" *ngIf="loading || page < maxPages">
    <trakto-button-loader
      [isLoading]="loading"
      (action)="loadNextPage()"
      label="Carregar mais"
    ></trakto-button-loader>
  </div>
</div>
