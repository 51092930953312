import { Component, Input } from '@angular/core';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';

import { TranslateService } from '@ngx-translate/core';

import {
  elementTypeEnum,
  IElementModel,
  IGroupElementModel,
  PageModel,
} from '@trakto/models';

import { AuthService } from '@auth/shared/auth.service';
import {
  DocumentManagerService
} from '@editor/services/document-manager.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';

import {
  AlignInfo,
  AxisEnum,
  DirectionEnum,
} from '@editor/components/preview-2/shared/alignment.model';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { copyObject } from '@app/state/state.util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-panel-actions',
  templateUrl: './panel-actions.component.html',
})
export class PanelActionsComponent {
  private _elementSelected: IElementModel | IGroupElementModel;

  public alignToPage: boolean;
  public showIcon: boolean;

  public buttonValidation: boolean;

  public textLock: string;
  public textUnlock: string;

  public isDisableClipAsMask: boolean;
  public isEmbeddedInstance: boolean;

  public get isVideo(): boolean {
    return (
      this._elementSelected &&
      this._elementSelected.type === elementTypeEnum.youtube
    );
  }

  public get isGroup(): boolean {
    return (
      this.elementSelected &&
      this.elementSelected.type === elementTypeEnum.group
    );
  }

  public get inGroup(): boolean {
    return (
      this.groupContainer && this.groupContainer.type === elementTypeEnum.group || false
    );
  }

  @Input() b2c: boolean;

  @Input()
  public set elementSelected(value: IElementModel) {
    this._elementSelected = value;
    this.disableClipShapeAsMask();
  }

  public get elementSelected(): IElementModel {
    return this._elementSelected;
  }

  @Input() groupContainer: IElementModel;
  @Input() pageSelected: PageModel;
  @Input() isTextEditable: boolean;
  @Input() isClipEditable: boolean;
  @Input() allowClip: boolean;
  @Input() allowLockElementsByTemplate: boolean;
  private _destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private signalsService: SignalsService,
    public _hotkeyService: HotkeyService,
    private _documentManagerService: DocumentManagerService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
  ) {
    this.showIcon = true;
    this.alignToPage = false;
    this.isTranslateBlocked();
    this.isEmbeddedInstance = this.authService.isEmbeddedInstance();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public isTranslateBlocked(): void {
    this.translateService.get('property_panel').pipe(takeUntil(this._destroy$)).subscribe((translated: any) => {
      this.textLock = translated.general.lock;
      this.textUnlock = translated.general.unlock;
    });
  }

  public lock(): void {
    if (this.elementSelected) {
      this._elementChangeFacadeService.lockElement(this.elementSelected);
    }
  }

  public moveUp(): void {
    if (this.elementSelected) {
      this._documentManagerService.moveUp(this.elementSelected);
    }
  }

  public moveDown(): void {
    if (this.elementSelected) {
      this._documentManagerService.moveDown(this.elementSelected);
    }
  }

  public deleteElement(): void {
    if (this.elementSelected) {
      this._documentManagerService.deleteElement(this.elementSelected);
    }
  }

  public async alignTo(axis: string, direction: string){
    const _axis = AxisEnum[axis];
    const _direction = DirectionEnum[direction];
    const _toPage = this.alignToPage;
    const _elements = this.elementSelected['elements'] || [
      this.elementSelected,
    ];

    await this._elementChangeFacadeService.alignElements(
      new AlignInfo(this.pageSelected, copyObject(_elements), _axis, _direction, _toPage),
    );
  }

  /**
   * Verify if selected items is cippable;
   */
  public disableClipShapeAsMask(): void {
    const elements =
      this._elementSelected?.type === elementTypeEnum.group
        ? (this._elementSelected as IGroupElementModel)?.elements
        : [];

    const blackList = [
      elementTypeEnum.page,
      elementTypeEnum.title,
      elementTypeEnum.text,
      elementTypeEnum.emoji,
      elementTypeEnum.youtube,
      elementTypeEnum.button,
      elementTypeEnum.group,
      elementTypeEnum.clip,
    ];

    const qtdImages = elements.filter(e => e.type === 'image').length;
    const qtdGifs = elements.filter(e => e.type === 'gif').length;

    this.isDisableClipAsMask =
      elements.length !== 2 ||
      elements.filter(e => e['clipped']).length > 0 ||
      elements.filter(e => blackList.indexOf(e.type) > -1).length > 0 ||
      elements.filter(e => e.type === 'shape').length > 1 ||
      qtdImages > 1 ||
      qtdGifs > 1 ||
      (qtdImages >= 1 && qtdGifs >= 1);
  }

  /**
   * Emite evento que desbloqueia o(s) elemento(s) selecionado(s) para edição pelo usuário final.
   */
  public lockElement(lock = true) {
    const targetElement = this.groupContainer || this.elementSelected;
    this._elementChangeFacadeService.lockElement(targetElement, lock);
  }

  changeOpacity($event: number) {
    this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'opacity', $event);
  }

  public changeProperty(property: string): void {
    if (property) {
      this.signalsService.emit(enumSignals.PROPERTY_CHANGE, {
        elementId: this.elementSelected.id,
        key: property,
        value: this.elementSelected[property],
        obj: this.elementSelected,
      });
    }
  }

  disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }
}
