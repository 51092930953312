import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IElementModel, IGroupElementModel, PageModel } from '@trakto/models';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subscription } from 'rxjs';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';

@Component({
  selector: 'trakto-panel-group',
  templateUrl: './panel-group.component.html',
})
export class PanelGroupComponent extends AbstractComponent<IGroupElementModel> {
  @Input() b2c: boolean;
  @Input() allowLockElementsByTemplate: boolean;
  @Input() isEmbeddedInstance: boolean;

  constructor(
    documentStateManagerService: DocumentStateManagerService
  ) {
    super(documentStateManagerService)
  }
}
