import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TextService } from '@services/text.service';
import { ElementModelGroupEngine } from '@trakto/core-editor';
import { ITextElementModel } from '@trakto/models';

const DIFF_BETWEEN_TEXT_INLINE_MENU_WIDTH = 10; // Em px

@Directive({
  selector: '[traktoMenuInlinePositioner]',
})
export class MenuInlinePositionerDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  private textElement: ITextElementModel;

  @Input()
  private ref: any;

  @Input()
  private selection: ElementModelGroupEngine;

  @HostBinding('style.position')
  position = 'fixed';

  @HostBinding('style.top.px')
  top = 0;

  @HostBinding('style.left.px')
  left = 0;

  @HostBinding('style.display')
  display = 'block';

  onScroll: EventListenerObject;

  constructor(
    private textService: TextService,
    private elementRef: ElementRef,
    private render2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.onScroll = this.calcTransform.bind(this);
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngAfterViewInit(): void {
    this.top = -1000;
    setTimeout(() => this.calcTransform(), 0);
  }

  ngOnChanges() {
    this.calcTransform();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  private calcTransform() {
    this.display = 'display';
    if (!this.textElement || !this._getElementRect() || !this._getTextMenuInlineRect() || !this._getTraktoToolbarRect()) {
      this.display = 'none';
      return;
    }

    const { x, y } = this._calcBoundBox();

    this.left = Math.min(Math.max(x, this._calcMinX()), this._calcMaxX());
    this.top = Math.max(y, this._calcMinY());

    this.render2.setStyle(
      this.elementRef.nativeElement,
      'left',
      `${this.left}px`,
    );
    this.render2.setStyle(
      this.elementRef.nativeElement,
      'top',
      `${this.top}px`,
    );
  }

  private _calcBoundBox(): {x, y} {
    const textBoundBox = this._getElementRect();
    const rotationBoundBox = this._getResizePointRect();
    const y = textBoundBox.y < rotationBoundBox.y ? textBoundBox.y : rotationBoundBox.y;
    return { x: textBoundBox.x, y: y - this._getTextMenuInlineRect().height - DIFF_BETWEEN_TEXT_INLINE_MENU_WIDTH };
  }

  private _calcMinX() {
    const toolbarRect = this._getTraktoToolbarRect();
    return toolbarRect.x + toolbarRect.width + DIFF_BETWEEN_TEXT_INLINE_MENU_WIDTH;
  }

  private _calcMaxX() {
    const propertyPanelRect = this._getPropertyPanelRect();
    const menuInlineRect = this._getTextMenuInlineRect();
    const calculatedMaxX = propertyPanelRect.x - menuInlineRect.width - DIFF_BETWEEN_TEXT_INLINE_MENU_WIDTH;
    const minX = this._calcMinX();

    return (calculatedMaxX < minX) ? minX : calculatedMaxX
  }

  private _calcMinY() {
    return this._getTraktoHeaderRect().y + this._getTraktoHeaderRect().height +  5;
  }

  private _getResizePointRect(): DOMRect {
    return this._getRect('[resize-type=rotation]');
  }

  private _getElementRect(): DOMRect {
    return this._getRect(`#${ this.textElement.id }__container`);
  }

  private _getTraktoToolbarRect(): DOMRect {
    return this._getRect('.trakto-toolbar');
  }

  private _getTraktoHeaderRect(): DOMRect {
    return this._getRect('.trakto-header');
  }

  private _getPropertyPanelRect(): DOMRect {
    return this._getRect('trakto-properties-panel');
  }

  private _getTextMenuInlineRect(): DOMRect {
    return this._getRect('trakto-text-menu-inline');
  }

  private _getRect(query: string): DOMRect {
    const ref = this.document.querySelector(query);
    return ref ? ref.getBoundingClientRect() : undefined;
  }
}
