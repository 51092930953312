import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  PlatformLoadingService
} from '@app/editor/services/platform-loading.service';
import { DocumentManagerService } from '@services/document-manager.service';
import {
  IDocumentModel,
  IElementModel,
  IEventModel,
  PageModel,
} from '@trakto/models';
import { ZoomService } from '@services/zoom.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  SvgEditorComponent
} from '@editor/components/preview-2/svg-editor/svg-editor.component';
import { PageService } from '@services/page.service';

@Component({
  selector: 'trakto-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit, OnDestroy {

  @ViewChild(SvgEditorComponent)
  private svgEditorComponent: SvgEditorComponent;

  document: IDocumentModel;
  selectedPage: PageModel;
  hasWaterMask = false;

  zoomRatio = 1;
  ref: number;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _zoomService: ZoomService,
    private _activatedRoute: ActivatedRoute,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _pageService: PageService,
    private _platformLoadingService: PlatformLoadingService,
    private _elementsService: ElementsService,
  ) {}

  ngOnInit(): void {
    this.setZoomPadding();
    this.loadDocument();
    this.initSubscriptions();
    this._elementsService.init();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    const onSelectDocument =
      this._documentStateManagerService.document$.subscribe(async doc => {
        this.document = doc;
        this._platformLoadingService.hide();
        this._zoomService.handleZoomFit(this.selectedPage, this.document);
      });

    const onSelectDocumentUndoAndRedo = this._documentStateManagerService.undoAndRedo$.subscribe(async (data) => {
      this.document = data.document;
      if (this.selectedPage && this.document?.body) {
        const page = await this._documentStateManagerService.pageSnapshot$.toPromise();
        if (page) {
          this.selectedPage = page;
          this.ref = Math.random();
          setTimeout(() => this.svgEditorComponent.selectionEngine.resetReference(this.selectedPage.elements));
        }
      }
    });

    const onSelectPage = this._documentStateManagerService.page$.subscribe(
      page => {
        if (!page || (page && page?.id === this.selectedPage?.id)) {
          this.ref = Math.random();
          return;
        }
        this.selectedPage = page;
      },
    );

    const onZoomRatioChange = this._zoomService.onZoomRatioChange.subscribe(
      zoomRatio => {
        this.zoomRatio = zoomRatio;
      },
    );

    const onChange = this._documentStateManagerService.change$.subscribe(() => {
      this.ref = Math.random();
    })

    this._subscriptions.push(
      onZoomRatioChange,
      onSelectDocumentUndoAndRedo,
      onSelectDocument,
      onSelectPage,
      onChange,
    );
  }

  setZoomPadding() {
    this._zoomService.setPadding('width', 32);
    this._zoomService.setPadding('height', 32);
  }

  loadDocument() {
    const documentId = this._activatedRoute.snapshot.params['document'];
    this._documentManagerService.loadDocument(documentId);
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  addPage() {}

  deletePage() {}

  clonePage() {}

  openPagePanelProperties() {}
  selectElement(element: IElementModel) {
    this._documentManagerService.selectElement(element);
  }

  focusElement(element: IElementModel) {}

  deleteElement(element: IElementModel) {}

  historyUndo($event: IEventModel) {}

  persistElementsChanges($event: { elements: IElementModel[] }) {
    this._documentStateManagerService.persistElementChanges(this.selectedPage, $event.elements);
  }

  public drop($event: DragEvent, page: PageModel) {
    $event.preventDefault();
  }

  public dragOver($event: DragEvent) {
    $event.preventDefault();
  }
}
