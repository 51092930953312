import { Component, OnDestroy, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentService } from '@app/editor/services/document.service';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ICountry {
  name: string;
  code: string;
}

@Component({
  selector: 'trakto-panel-whatsapp-mobile',
  templateUrl: './panel-whatsapp-mobile.component.html',
  styleUrls: ['./panel-whatsapp-mobile.component.scss'],
})
export class PanelWhatsappMobileComponent implements OnInit, OnDestroy {
  countries: ICountry[] = [];
  selectedCountry: string = 'BR';

  number: string = '';
  numberObject: PhoneNumber = null;
  message: string = '';

  isFormValid: boolean = false;
  private _destroy$ = new Subject<void>();

  constructor(
    private _panelService: PanelService,
    private _documentService: DocumentService,
    private _elementsService: ElementsService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this.fetchCountryList();
    const link = (this._elementsService.selectedElement.whatsapp || '').replace('https://wa.me/', '');
    const splitedLink = link.split('?text=');
    this.formatPhone(splitedLink[0] || '');
    this.changeMessageValue(decodeURIComponent(splitedLink[1] || ''));
  }

  fetchCountryList(): void {
    this._documentService.getCountries().pipe(takeUntil(this._destroy$)).subscribe((countries: ICountry[]) => {
      this.countries = countries;
    });
  }

  formatPhone(value: string) {
    const inputtedNumber = value;
    const maskedNumberObject = parsePhoneNumberFromString(
      inputtedNumber,
      this.selectedCountry as any,
    );

    if (maskedNumberObject) {
      this.number = maskedNumberObject.formatInternational();
      this.numberObject = maskedNumberObject;
    } else {
      this.numberObject = null;
    }

    this.setIsFormValid();
  }

  changeMessageValue(val: string) {
    this.message = val;
    this.setIsFormValid();
  }

  setIsFormValid() {
    const isNumberValid = this.numberObject
      ? this.numberObject.isValid()
      : false;
    const isMessageValid = this.message.length > 0;

    this.isFormValid = isNumberValid && isMessageValid;
  }

  getWhatsappLink(): string | null {
    if (this.numberObject) {
      const tel = this.numberObject.getURI();
      const message = encodeURIComponent(this.message);

      return `https://wa.me/${tel}?text=${message}`;
    }

    return null;
  }

  applyLinkToElement() {
    if (this.isFormValid) {
      const link = this.getWhatsappLink();
      if (link) this._elementsService.applyWhatsAppLinkToElement(link);
    }
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
