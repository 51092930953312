<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-elements.svg"
    label="Elementos"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="search click-ignore">
    <trakto-input
      [value]="searchText"
      [clearIcon]="true"
      placeholder="Pesquise por algo"
      (onChange)="setInputText($event)"
      (onBlur)="setShowSuggestions(false)"
      (onSelect)="selectTag($event)"
    ></trakto-input>
  </div>

  <div class="options-grid">
    <trakto-panel-option-mobile
      *ngFor="let family of families"
      [url]="family.thumbUrl"
      [label]="family.name"
      (click)="selectFamily(family)"
    ></trakto-panel-option-mobile>
  </div>

  <div class="packs">
    <div *ngIf="isPacksLoading; else packsGrid" class="loading">
      <i class="icon icon-loading"></i>
    </div>
    <ng-template #packsGrid>
      <div *ngFor="let pack of packs" class="pack" (click)="selectPack(pack)">
        <div
          class="card"
          [ngStyle]="{ backgroundColor: 'aliceblue' }"
        >
          <div class="content">
            <div class="image">
              <img [src]="pack.thumbUrl" [alt]="pack | packname" />
            </div>

            <div class="title">
              <p [ngStyle]="{ color: '#555555' }">
                {{ pack | packname }}
              </p>
            </div>
          </div>
        </div>

        <div class="author">
          <p>
            By <strong>{{ pack.author | slice: 0:30 }}</strong>
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
