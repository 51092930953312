import { Injectable } from '@angular/core';
import {
  DocumentManagerService
} from '@app/editor/services/document-manager.service';
import {
  elementTypeEnum,
  IElementModel,
  IFontOptions,
  ImageElementModel,
  IShapeElementModel,
  ITextElementModel,
  PageModel,
} from '@trakto/models';
import { IPanelTypes, PanelService } from './panel.service';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  AlignInfo,
  AxisEnum,
  DirectionEnum,
} from '@editor/components/preview-2/shared/alignment.model';
import { IElementNewAPI } from '@shared/elements/element.entity';
import { ResolutionsModel } from '@trakto/graphics-resources';
import {
  ElementFactoryFacadeService
} from '@services/element-factory-facade.service';
import { debounce } from 'lodash';
import {
  ISearchElementType
} from '@app/editor/types/elements/element-search.type';
import {
  ElementDatasource
} from '@editor/enums/editor-elements/elements-datatasource.enum';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

export enum EnumOrderActions {
  toFront = 'TO_FRONT',
  toBack = 'TO_BACK',
}

@Injectable({
  providedIn: 'root',
})
export class ElementsService {
  private _selectedPage: PageModel = null;
  selectedElement: IElementModel = null;

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _elementsFactoryService: ElementFactoryFacadeService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    private _panelService: PanelService,
  ) {
    this.openPropertiesPanel = debounce(this.openPropertiesPanel, 100);
  }

  init() {
    this.initSubscriptions();
  }

  initSubscriptions() {
    this._documentStateManagerService.page$.subscribe(page => {
      this._selectedPage = page;
    });
    this._documentStateManagerService.element$.subscribe(el => {
      if (el && el?.id !== this.selectedElement?.id) {
        this.openPropertiesPanel(el);
      }
      this.selectedElement = el;
    });
  }

  async openPropertiesPanel(element: IElementModel): Promise<boolean> {
    const panelIds = {
      image: 'propertiesImage',
      shape: 'shape',
      text: 'propertiesText',
      emoji: 'icon',
      gif: 'propertiesGif',
    };

    await this.resetPanels();

    if (element) {
      if (element.type === elementTypeEnum.image && (element as any).source === ElementDatasource.FLATICON) {
        this._panelService.openPanel('icon');
      } else if (panelIds[element.type]) {
        this._panelService.openPanel(panelIds[element.type] as IPanelTypes);
      }
      return true;
    }

    return false;
  }

  async resetPanels(): Promise<boolean> {
    try {
      this._panelService.closeAllPanels();
      this._panelService.resetTiers();
    } finally {
      return true;
    }
  }

  async createByElement(asset: IElementNewAPI) {
    switch (asset.contentType) {
      case ISearchElementType.SVG:
        await this._elementsFactoryService.createAndAddEmojiByUrl(
          asset.elementUrl,
        );
        break;
      case ISearchElementType.PATH:
        await this._elementsFactoryService.createAndAddShapeByUrl(
          asset.elementUrl,
        );
        break;
      case ISearchElementType.IMAGE:
        await this._elementsFactoryService.createAndAddImageByUrl(
          asset.thumbUrls.high,
          asset.source,
        );
        break;
    }
  }

  applyMaskByElement(element: IElementNewAPI): Promise<ImageElementModel> {
    const image = this.selectedElement as ImageElementModel;
    return this._elementChangeFacadeService.changeImageSubmaskByUrl(
      image,
      element.elementUrl,
    );
  }

  applyMaskByPath(path: string) {
    const image = this.selectedElement as ImageElementModel;
    this._elementChangeFacadeService.changeImageSubmaskByPath(image, path);
  }

  resetMask() {
    this._elementChangeFacadeService.changeImageSubmaskByPath(
      this.selectedElement as ImageElementModel,
      this.selectedElement.supportPath,
    );
  }

  moveUp() {
    this._documentManagerService.moveUp(this.selectedElement);
  }

  moveDown() {
    this._documentManagerService.moveDown(this.selectedElement);
  }

  async alignElement(axis: string, direction: string, alignToPage = false) {
    const _axis = AxisEnum[axis];
    const _direction = DirectionEnum[direction];
    const _elements = this.selectedElement['elements'] || [
      this.selectedElement,
    ];

    await this._elementChangeFacadeService.alignElements(
      new AlignInfo(
        this._selectedPage,
        _elements,
        _axis,
        _direction,
        alignToPage,
      ),
    );
  }

  changeElementOpacity(opacity: number) {
    this._elementChangeFacadeService.changeElementOpacity(
      this.selectedElement,
      opacity,
    );
  }

  deleteElement() {
    this._documentManagerService.deleteElement(this.selectedElement);
  }

  blockElement() {
    this._elementChangeFacadeService.lockElement(this.selectedElement);
  }

  duplicateElement() {
    this._documentManagerService.cloneElementsAndAdd([this.selectedElement]);
  }

  cropImage() {
    this._documentManagerService.requestClipMode();
  }

  setPageBackground(): Promise<PageModel> {
    const image = this.selectedElement as ImageElementModel;
    this._documentManagerService.deleteElement(image);
    return this._elementChangeFacadeService.changePageBackgroundUrlByUrl(
      this._selectedPage,
      image.raw,
    );
  }

  changeImageLink(resolutions: ResolutionsModel): Promise<ImageElementModel> {
    return this._elementChangeFacadeService.changeImageUrlByResolutions(
      this.selectedElement as ImageElementModel,
      resolutions,
    );
  }

  async changeAsset(
    elementAlgolia: IElementNewAPI,
  ): Promise<IElementModel> {
    return this._elementChangeFacadeService.changeElementAsset(
      this.selectedElement,
      elementAlgolia,
    );
  }

  applyWebsiteLinkToElement(link: string) {
    this._elementChangeFacadeService.changeLink(this.selectedElement, link);
  }

  applyEmailLinkToElement(sender: string, subject: string, message: string) {
    this._elementChangeFacadeService.changeEmail(
      this.selectedElement,
      sender,
      subject,
      message,
    );
  }

  applyWhatsAppLinkToElement(link: string) {
    this._elementChangeFacadeService.changeWhatsappLink(
      this.selectedElement,
      link,
    );
  }

  changeFontSize(fontSize: number) {
    this._elementChangeFacadeService.changeTextFontSize(
      this.selectedElement as ITextElementModel,
      fontSize,
    );
  }

  changeFontStyle(style: IFontOptions) {
    this._elementChangeFacadeService.changeTextStyle(
      this.selectedElement as ITextElementModel,
      style,
    );
  }

  changeFontStyleByWeight(weight: number) {
    this._elementChangeFacadeService.changeTextStyleByWeight(
      this.selectedElement as ITextElementModel,
      weight,
    );
  }

  changeColor(color: string, isBackground = false) {
    if (this.selectedElement?.type === elementTypeEnum.text) {
      if (!isBackground) {
        this._elementChangeFacadeService.changeTextColor(
          this.selectedElement as ITextElementModel,
          color,
        );
      } else {
        this._elementChangeFacadeService.changeTextBackgroundColor(
          this.selectedElement as ITextElementModel,
          color,
        );
      }
    } else if (this.selectedElement?.type === elementTypeEnum.shape) {
      this._elementChangeFacadeService.changeShapeColor(
        this.selectedElement as IShapeElementModel,
        color,
      );
    } else if (!this.selectedElement && this._selectedPage) {
      this._elementChangeFacadeService.changePageBackgroundColor(this._selectedPage, color);
    }
  }

  toggleTextUnderline() {
    this._elementChangeFacadeService.toggleTextUnderline(
      this.selectedElement as ITextElementModel,
    );
  }

  toggleTextLineThrough() {
    this._elementChangeFacadeService.toggleTextLineThrough(
      this.selectedElement as ITextElementModel,
    );
  }

  toggleTextScaleMode() {
    this._elementChangeFacadeService.toggleTextScaleMode(
      this.selectedElement as ITextElementModel,
    );
  }
}
