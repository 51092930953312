import { Injectable } from '@angular/core';
import { TraktoApiService } from '@app/editor/services/trakto-api.service';
import { ReplaySubject, Subject } from 'rxjs';

export interface IChildFormat {
  canva_dimension: {
    width: number;
    height: number;
    unit: string;
  };
  file: string;
  id: string;
  locale?: string[];
  order: number;
  products?: string[];
  thumbs?: any;
  slug: string;
  title: string;
  titles?: any;
}

export interface IParentFormat {
  created_at: string;
  formats: IChildFormat[];
  icon: string;
  is_new: boolean;
  is_public: boolean;
  locale: string[];
  order: number;
  products?: string[];
  title: string;
  titles: any;
  updated_at: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormatsService {
  formatsCache: IParentFormat[] = [];

  loading = false;
  onLoadingChange: Subject<boolean>;

  constructor(private _traktoApiService: TraktoApiService) {
    this.onLoadingChange = new ReplaySubject<boolean>(1);
  }

  async getFormats(): Promise<IParentFormat[]> {
    return this._traktoApiService.executeGet(`menu-of-formats`);
  }

  async getOrdenedFormats(): Promise<IParentFormat[]> {
    if (this.formatsCache.length > 0) return this.formatsCache;

    this.changeLoadingStatus(true);

    const request = await this.getFormats();
    const sorted = this.sortFormats(request);

    this.changeLoadingStatus(false);
    this.formatsCache = sorted;

    return sorted;
  }

  async getFormat(id: string): Promise<IParentFormat> {
    return this._traktoApiService.executeGet(`format/${id}`);
  }

  sortFormats(request: IParentFormat[]): IParentFormat[] {
    const formats = request.map(parent => {
      const childrenFormats = [...parent.formats];

      const sortedFormats = [...childrenFormats].sort(
        (prevFormat, nextFormat) => prevFormat.order - nextFormat.order,
      );

      return {
        ...parent,
        formats: sortedFormats,
      };
    });

    return formats.sort(
      (childFormatPrev, childFormatNext) =>
        childFormatPrev.order - childFormatNext.order,
    );
  }

  changeLoadingStatus(value: boolean) {
    this.loading = value;
    this.onLoadingChange.next(this.loading);
  }
}
