<div class="expansion-panel">
  <div class="expansion-panel-align">
    <p class="expansion-panel-text">{{ 'sidebar_publish.select-by-layers' | translate }}</p>
    <div class="expansion-panel-align">
      <img class="expansion-panel-img-list-type"
        src="../../../../assets/icons/list-details-{{selectedIcons['list-details'] ? 'on' : 'off'}}.svg" alt=""
        [ngClass]="{ 'selected-icon': selectedIcons['list-details'] }"
        (click)="accordion.openAll(); handleIconClick('list-details')">
      <div class="expansion-panel-line"></div>
      <img class="expansion-panel-img-list-type"
        src="../../../../assets/icons/list-blocks-{{selectedIcons['list-blocks'] ? 'on' : 'off'}}.svg" alt=""
        [ngClass]="{ 'selected-icon': selectedIcons['list-blocks'] }"
        (click)="accordion.closeAll(); handleIconClick('list-blocks')">
    </div>
  </div>
  <div class="mat-accordion">
    <ng-container *ngFor="let item of list">
      <ng-container *ngIf="item.type === 'text'; else other">
        <mat-accordion multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="expansion-panel-panel-tumb">
                  <ng-container *ngIf="item?.text">
                    <span class="expansion-panel-panel-text">{{ item?.text?.charAt(0) }}</span>
                  </ng-container>

                  <ng-container *ngIf="item?.low">
                    <img class="expansion-panel-panel-img" src="{{item?.low}}" alt="">
                  </ng-container>

                  <ng-container *ngIf="item?.path">
                    <svg width="32" height="32" viewBox="0 0 50 50">
                      <path [attr.d]="item.path" [attr.fill]="item.backgroundColor"></path>
                    </svg>
                  </ng-container>
                </div>
                <p class="expansion-panel-title">{{item.tagType.title}}</p>
                <div class="mat-accordion-badge">0/{{ textStyles.length }}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let item of textStyles">
              <div class="expansion-panel-content-border">
                <mat-checkbox class="example-margin">{{item.title}}</mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
      <ng-template #other>
        <div class="expansion-panel-panel-single">
          <div class="expansion-panel-panel-tumb">
            <ng-container *ngIf="item?.text">
              <span class="expansion-panel-panel-text">{{ item?.text?.charAt(0) }}</span>
            </ng-container>
  
            <ng-container *ngIf="item?.low">
              <img class="expansion-panel-panel-img" src="{{item?.low}}" alt="">
            </ng-container>
  
            <ng-container *ngIf="item?.path">
              <svg width="32" height="32" viewBox="0 0 50 50">
                <path [attr.d]="item.path" [attr.fill]="item.backgroundColor"></path>
              </svg>
            </ng-container>
          </div>
          <p class="expansion-panel-title">{{item?.tagType?.title}}</p>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>