import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'trakto-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() min = 10;
  @Input() max = 300;
  @Input() value = 100;

  @Output() onChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private _sliderElement: ElementRef) {}

  ngOnInit(): void {
    this._sliderElement.nativeElement.addEventListener(
      'input',
      (e: InputEvent) =>
        e.target && this.handleBackground(e.target as HTMLInputElement),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const input = this._sliderElement.nativeElement.querySelector('input');
      this.handleBackground(input);
    }
  }

  ngAfterViewInit(): void {
    const input = this._sliderElement.nativeElement.querySelector('input');
    this.handleBackground(input);
  }

  handleBackground(target: HTMLInputElement | null) {
    if (!target) return false;

    const min = this.min;
    const max = this.max;
    const val = this.value;

    target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';

    return true;
  }

  change(val: number) {
    this.onChange.emit(val);
  }
}
