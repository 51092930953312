<div (click)="closeModal()" class="tkt-modal__close">
  <i class="trakto-icon-stream-plus00"></i>
</div>
<section class="tkt-modal">
  <div class="tkt-modal__container">
    <div class="tkt-modal__banner">
      <div class="tkt-modal__banner-image"></div>
      <h2>{{ 'modal_share.title' | translate }}</h2>
    </div>
    <div class="tkt-modal__form">
      <div class="tkt-modal__hack">
        <input
          #inputSlug
          readonly
          style="opacity: 0; position: absolute; z-index: -1;"
          autocomplete="off"
          matInput
          [placeholder]="kmlData.data.docId"
          (blur)="disableHotkeys()"
          (onFocus)="disableHotkeys()"
          (select)="disableHotkeys()"
          [value]="
            formGroup.get('slug').value
              ? mainURL + '/' + formGroup.get('slug').value
              : null
          "
          type="input"
        />
      </div>

      <div [formGroup]="formGroup" class="tkt-modal__input">
        <div class="tkt-modal__input-content">
          <mat-form-field
            floatLabel="never"
            appearance="outline"
            style="width: 100%;"
          >
            <span matPrefix>{{ mainURL }}/</span>

            <input
              #input
              autocomplete="off"
              matInput
              placeholder="{{ 'modal_share.link_placehorlder' | translate }}"
              formControlName="slug"
              type="input"
              (blur)="disableHotkeys()"
              (onFocus)="disableHotkeys()"
              (input)="getCleanWord(input.value)"
              (select)="disableHotkeys()"
              [style.color]="'#16C680'"
            />
          </mat-form-field>

          <button
            [ngClass]="{ '--copied': linkCopied }"
            class="tkt-button tkt-button-submit"
            (click)="createAndCopyLink()"
          >
            <p>
              {{
                linkCopied
                  ? ('modal_share.copied' | translate)
                  : ('modal_share.button_ok' | translate)
              }}
            </p>
          </button>
        </div>
      </div>

      <div class="tkt-modal__notification">
        <i class="trakto-icon-info"></i>
        <p>{{ 'modal_share.notification' | translate }}</p>
      </div>
    </div>
  </div>
</section>
