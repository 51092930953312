import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { MaterialModule } from '@app/editor/shared/material.module';
import { ModalShareComponent } from './modal-share.component';

@NgModule({
  declarations: [ModalShareComponent],
  imports: [CommonModule, SharedModule, MaterialModule, ReactiveFormsModule],
  exports: [ModalShareComponent],
  entryComponents: [ModalShareComponent],
})
export class ModalShareModule {}
