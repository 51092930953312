import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  exports: [TooltipComponent, TooltipDirective],
  entryComponents: [TooltipComponent],
})
export class TooltipModule {}
