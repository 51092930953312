import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IColorEvent } from './../../events/color.event';
import { GradientSafePipe } from './../../pipes/gradient-safe.pipe';

import { NgColorModel } from '@shared/color/classes/color.model';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'trakto-color-button',
  templateUrl: './color-button.component.html',
  styleUrls: ['./color-button.component.scss'],
  providers: [ColorService],
})
export class ColorButtonComponent {
  @ViewChild('button', { static: true }) button: any;

  hexaLabelColor: string;

  private _color: string;
  private _internalColor: NgColorModel;
  private _disabled: boolean;
  private _id: any;
  private _icon: any;
  private _openPanel: boolean;
  private _height: number;

  private _hasInitialized: boolean;

  @Input() content: string;

  @Input() public set id(value: any) {
    if (this._id !== value) {
      this.openPanel = false;
    }

    this._id = value;
  }

  public get id(): any {
    return this._id;
  }

  @Input()
  public set height(height: number) {
    this._height = height;
  }

  public get height(): number {
    return this._height || null;
  }

  @Input() public set openPanel(value: boolean) {
    this._openPanel = value;
  }

  public get openPanel(): boolean {
    return this._openPanel;
  }

  @Input()
  set color(value: string) {
    this._color = value;

    if (this._colorService.isGradient(value)) {
      this.internalColor = new NgColorModel();
      this.internalColor.gradient = value;
      this.internalColor.gradientSafe = this._gradientSafePipe.transform(value);
    } else {
      this.internalColor = new NgColorModel(null, value);
    }
  }

  get color(): string {
    return this._color || null;
  }

  set internalColor(value: NgColorModel) {
    if (value) {
      this.hexaLabelColor = this._colorService.getContrastColor(value);
    }

    this._internalColor = value;
  }

  get internalColor(): NgColorModel {
    return this._internalColor;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon(): string {
    return this._icon;
  }

  @Output()
  colorChange: EventEmitter<string> = new EventEmitter(true);

  @Output()
  openColorPanel: EventEmitter<IColorEvent> = new EventEmitter();

  constructor(
    private _colorService: ColorService,
    private _gradientSafePipe: GradientSafePipe,
  ) {
    this._hasInitialized = true;
  }

  changeColor(color: NgColorModel) {
    if (color) {
      if (
        color.hexadecimal &&
        color.hexadecimal !== this.internalColor.hexadecimal
      ) {
        this._color = color.hexadecimal;
        this.colorChange.emit(this.color);
        this.internalColor = color;
      } else if (
        color.gradient &&
        color.gradient !== this.internalColor.gradient
      ) {
        this._color = color.gradient;
        this.colorChange.emit(this.color);
        this.internalColor = color;
        // this.internalColor.gradientSafe = this._gradientSafePipe.transform(color.gradient);
      } else if (!color.hexadecimal && !color.gradient) {
        this._color = color.hexadecimal;
        this.colorChange.emit(this.color);
        this._internalColor = color;
      }
    }
  }

  onClickButtonPreview(): void {
    if (!this.disabled) {
      this.openColorPanel.emit({
        openStatus: true,
        currentService: this._colorService,
        currentColor: this.internalColor,
      });
    }
  }
}
