import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-panel-option-mobile',
  templateUrl: './panel-option-mobile.component.html',
  styleUrls: ['./panel-option-mobile.component.scss'],
})
export class PanelOptionMobileComponent implements OnInit {
  @Input() image: string = null;
  @Input() url: string = null;
  @Input() icon: string = null;
  @Input() label: string = null;
  @Input() disabled: boolean = false;
  @Input() active: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
