import { Injectable } from '@angular/core';
import { IApp } from '@trakto/models';
import { TraktoApiService } from '@services/trakto-api.service';

@Injectable({
  providedIn: 'root',
})
export class AppRepository {
  constructor(
    private _traktoApiService: TraktoApiService,
  ) {}

  public findByLoggedUser(): Promise<IApp> {
    return this._traktoApiService.executeGet<IApp>('app/current');
  }

  public findById(id: string): Promise<IApp> {
    return this._traktoApiService.executeGet<IApp>(`app/${id}`);
  }

  public findAll(): Promise<IApp[]> {
    return this._traktoApiService.executeGet<IApp[]>('app');
  }
}
