import { Injectable } from '@angular/core';

import {
  ElementModelFinderService
} from '@services/element-model-finder.service';
import { FontsService } from '@services/fonts.service';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import {
  DimensionModel,
  ElementModelGroupEngine,
  ElementModelOperations,
} from '@trakto/core-editor';
import {
  removeUndefinedProperties
} from '@trakto/core-editor/dist/operations/util.operations';
import {
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  IGroupElementModel,
  ITextElementModel,
  PageModel
} from '@trakto/models';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ElementFactoryService } from './element-factory.service';
import { IElementModelChange } from '@editor/model/element-model-change.model';
import { DeviceDetectorService } from 'ngx-device-detector';


/**
 * Métodos úteis para conversão do ElementModel para o novo modelo considerando rotação
 */
@Injectable({
  providedIn: 'root',
})
export class ElementModelService extends ElementModelOperations {
  private mainAttributes = [
    { name: 'cx', type: 'number' },
    { name: 'cy', type: 'number' },
    { name: 'rotation', type: 'number' },
    { name: 'supportWidth', type: 'number' },
    { name: 'supportHeight', type: 'number' },
    { name: 'fontSize', type: 'number' },
    { name: 'supportPath' },
    { name: 'finalWidth', type: 'number' },
    { name: 'finalHeight', type: 'number' },
  ];

  constructor(
    elementsModelService: ElementFactoryService,
    private _textElementService: TextElementService,
    private _fontService: FontsService,
    private _elementModelFinderService: ElementModelFinderService,
    private _textService: TextService,
    private _deviceDetectorService: DeviceDetectorService,
  ) {
    super(elementsModelService, _textElementService, _elementModelFinderService);
  }

  public updateGroup(group: IGroupElementModel): void {
    Object.assign(
      group,
      this.newGroupElement(group.elements, group.id),
    );
  }

  public initFontModelsByDocument(document: IDocumentModel): Observable<boolean> {
    const notConvertedPages = document.body.filter(page => !page.fontModels);
    if (notConvertedPages.length === 0) {
      return of(this._textService.setDocFontList(document));
    }
    return forkJoin(
      notConvertedPages.map(page =>
        this.textElementOperations.initPageFontModels(page),
      ),
    ).pipe(
      tap(() => notConvertedPages.forEach(page => page.fontModels.forEach(fm => removeUndefinedProperties(fm)))),
      map(() => this._textService.setDocFontList(document)),
      catchError(() => of(true)),
    );
  }

  public initFontModelsByPage(page: PageModel): Observable<boolean> {
    if (page.fontModels && page.fontModels.length > 0) {
      return this.checkFontModels(page)
        .pipe(
          map(() => this._textService.appendFontList(page)),
        );
    }
    return this.textElementOperations.initPageFontModels(page)
      .pipe(
        tap(() => page => page.fontModels.forEach(fm => removeUndefinedProperties(fm))),
        map(() => this._textService.appendFontList(page)),
        catchError(() => of(true)),
      );
  }

  public checkFontModels(page: PageModel): Observable<PageModel> {
    const textElements = page.elements.filter((element) => element.type === elementTypeEnum.text).map(el => el as ITextElementModel);
    const notFoundFamilies = Array.from(
      new Set(
        textElements
          .filter(text => !page.fontModels.find(fm => fm.family === text.fontFamily))
          .map(text => text.fontFamily),
      ),
    );
    return forkJoin(
      notFoundFamilies.map(family => this._fontService.findFont(family))
    ).pipe(
      tap(fontModels => {
        fontModels.forEach(fm => this._textElementService.addFontModel(page, fm));
      }),
      map(() => page),
    );
  }

  public initAllQuillModel(document: IDocumentModel): Observable<boolean> {
    const texts = this._elementModelFinderService
      .getAllTextElementsByDocument(document)
      .filter(text => !text.textConverted);
    if (texts.length === 0) {
      return of(true);
    }
    return forkJoin(
      document.body.map(page => this._fontService.loadByPage(page)),
    ).pipe(
      tap(() =>
        texts.forEach(text => {
          this._textService.updateTextEncoded(text);
          this.textElementOperations.fitTextDimension(text);
        }),
      ),
      map(() => true),
      catchError(() => of(true)),
    );
  }

  public getChangesBySelection(selection: ElementModelGroupEngine): IElementModelChange[]  {
    const beforeUpdate = this.getAllElements(
      selection.elementCopy['elements'],
      true,
      true,
    );
    const afterUpdate = this.getAllElements(
      selection.element['elements'],
      true,
      true,
    );
    return this.getChangesByElements(beforeUpdate, afterUpdate);
  }

  public getChangesByElements(before: IElementModel[], after: IElementModel[]): IElementModelChange[]  {
    if (!after) {
      return [];
    }
    return after.reduce((result, afterElement: IElementModel) => {
      const beforeElement = before.find(e => e.id === afterElement.id);
      return result.concat(this.getChanges(beforeElement, afterElement));
    }, []);
  }

  private getChanges(
    before: IElementModel,
    after: IElementModel,
    targetAttributes = this.mainAttributes,
  ): IElementModelChange[] {
    const changes: IElementModelChange[] = [];
    if (!before || !after) {
      return changes;
    }
    targetAttributes.forEach(attribute => {
      const { name, type } = attribute;
      if (
        (type === 'number' &&
          Math.abs(before[name] - after[name]) > 0.000001) ||
        (type !== 'number' && before[name] !== after[name])
      ) {
        changes.push({
          property: name,
          oldValue: before[name],
          newValue: after[name],
          elementId: before.id,
          before,
          after,
        });
      }
    });
    return changes;
  }

  canHandleElement(element: IElementModel) {
    return element && element.edit === true && element.lockedByTemplate !== true;
  }

  isValidGroup(element: IElementModel): boolean {
    const selectedElements = element['elements'];
    return element && selectedElements && selectedElements.length > 1;
  }

  isValidUngroup(element: IElementModel): boolean {
    const selectedElements = element['elements'];
    return (
      element &&
      selectedElements &&
      selectedElements.length === 1 &&
      this.isGroup(selectedElements[0])
    );
  }


  copySelectedElement(page: PageModel, source: IElementModel): string {
    const pageDimension = new DimensionModel(
      page.width,
      page.height,
    );
    const copy: IElementModel = {
      ...source,
      pageDimension,
    } as IElementModel;

    if (copy.type === elementTypeEnum.group) {
      copy['elements'] = [];
      [...page.elements].reverse().forEach(element => {
        if (
          source['elements'] &&
          source['elements'].findIndex(
            el => element.id === el.id,
          ) !== -1
        ) {
          copy['elements'].push(element);
        }
      });
    }
    return JSON.stringify(copy);
  }

  public configInitialScale(target: IElementModel, container: PageModel) {
    if (!this._deviceDetectorService.isMobile()) {
      super.configInitialScale(target, container);
      return;
    }
    const scale = this._getNewScaleByContainer(target, container);
    if (target.type !== elementTypeEnum.text) {
      target.scaleX = scale.xScale || 0;
      target.scaleY = scale.yScale || 0;
    } else {
      target.supportWidth = target.supportWidth * scale.xScale || 0;
      (target as ITextElementModel).fontSize *= scale.yScale;
    }
  }

  private _getNewScaleByContainer(
    target: IElementModel,
    container: PageModel,
  ): { xScale: number; yScale: number } {
    const pageScale: number = (container.width - container.width * 0.2) / target.supportWidth;

    return { xScale: pageScale, yScale: pageScale };
  }
}
