<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-images.svg"
    label="Personalizar imagem"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="secondary-options">
      <trakto-panel-secondary-option-mobile
        icon="delete"
        (click)="deleteElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="block"
        [active]="!selectedElement?.edit"
        (click)="blockElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="copy"
        (click)="duplicateElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="crop"
        (click)="cropImage()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="maximize"
        (click)="setPageBackground()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="order"
        (click)="orderElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="align"
        (click)="alignElement()"
      ></trakto-panel-secondary-option-mobile>
      <trakto-panel-secondary-option-mobile
        icon="transparency"
        (click)="setElementOpacity()"
      ></trakto-panel-secondary-option-mobile>
    </div>

    <div class="primary-options">
      <div class="grid">
        <trakto-panel-option-mobile
          icon="change"
          label="Trocar imagem"
          (click)="changeImage()"
        ></trakto-panel-option-mobile>
        <trakto-background-removal-card></trakto-background-removal-card>
        <trakto-panel-option-mobile
          icon="mask"
          label="Máscara"
          (click)="openMaskPanel()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          [active]="selectedElement?.filter === 'gaussianblur'"
          icon="blur"
          label="Desfocado"
          (click)="applyBlur()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          icon="filters"
          label="Filtros"
          (click)="openFiltersPanel()"
        ></trakto-panel-option-mobile>
        <trakto-panel-option-mobile
          icon="link-element"
          label="Adicionar link"
          (click)="openLinksPanel()"
        ></trakto-panel-option-mobile>
      </div>
    </div>
  </div>
</div>
