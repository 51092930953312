import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-template-type-button',
  templateUrl: './template-type-button.component.html',
  styleUrls: ['./template-type-button.component.scss']
})
export class TemplateTypeButtonComponent implements OnInit {

  @Input() iconUrl: string;
  @Input() title: string;
  @Input() text: string;
  @Input() value: string;
  @Input() color: string;

  public isSelected: boolean;

  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.buttonClick.emit();
  }

}
