import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MAGIC_ELEMENT_TEXT_STYLES } from './magic-element-text-styles';

@Component({
  selector: 'trakto-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpansionPanelComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  
  @Input() title: string;
  @Input() list: Array<[]>;
  
  selectedIcons: { [iconName: string]: boolean } = {
    'list-details': false,
    'list-blocks': false
  };

  public textStyles: Array<{
    id: number;
    title: string;
  }> = [];

  ngOnInit(): void {
    this.textStyles = MAGIC_ELEMENT_TEXT_STYLES;
  }

  handleIconClick(iconName: string): void {
    this.disableOtherIcon(iconName);
    this.selectedIcons[iconName] = !this.selectedIcons[iconName];
  }

  disableOtherIcon(iconName: string): void {
    for (const icon in this.selectedIcons) {
      if (icon !== iconName) {
        this.selectedIcons[icon] = false;
      }
    }
  }
}
