<section #previewPage class="mobile-presentation">
  <div class="mobile-presentation__brand">
    <!-- <img src="assets/images/trakto-extense.svg" alt="Brand" /> -->
  </div>

  <div #containerPages class="mobile-presentation__container">
    <div
      *ngFor="let page of document?.body; let i = index"
      class="mobile-presentation__container-pages"
    >
      <trakto-svg-viewer
        [fullViewMode]="true"
        [class.svg-editor]="document.body[currentDoc.index] === page"
        [class.active]="document.body[currentDoc.index] === page"
        [page]="page"
        [zoomRatio]="
          previewPage.offsetWidth > 768 || !zoomRatioByDoc[page.id]
            ? zoomRatioDoc / 100
            : zoomRatioByDoc[page.id] / 100
        "
      >
      </trakto-svg-viewer>
      <div
        *ngIf="document?.body.length > 1"
        class="mobile-presentation__buttons"
      >
        <div class="slide">
          <button
            class="slide_up"
            [disabled]="i == 0"
            (click)="previewSlide($event.target)"
          >
            <i class="trakto-icon-arrow-up"></i>
          </button>
          <button
            class="slide_down"
            [disabled]="i == document?.body.length - 1"
            (click)="nextSlide($event.target)"
          >
            <i class="trakto-icon-arrow-down"></i>
          </button>
        </div>

        <button
          #singleDownload
          class="mobile-presentation__single-download"
          (click)="showSwipe(true, page)"
        >
          <i class="trakto-icon-import"></i>
          {{ 'mobile-presentation.button-download' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    #buttonDownload
    class="mobile-presentation__download"
    (click)="showSwipe()"
  >
    <i class="trakto-icon-download"></i>
    <p>
      {{
        downloadMultiPages
          ? ('mobile-presentation.Download_all_pages' | translate)
          : ('mobile-presentation.Download' | translate)
      }}
    </p>
  </div>

  <trakto-swipe
    [actived]="showContainer"
    [downloadMultiPages]="downloadMultiPages"
    [downloadOnePage]="downloSinglePage"
    [page]="pageRef"
    (typeDownload)="downloadType($event)"
  >
  </trakto-swipe>
</section>

<trakto-modal-app></trakto-modal-app>
