<div
  class="trakto-modal-menu-item"
  [ngClass]="{ '--active': active, '--custom-modal': customModal }"
  [ngStyle]="{
    'animation-delay': itemIndex * 0.1 + 's'
  }"
>
  <i
    class="trakto-modal-menu-item__icon {{ value.icon }}"
    *ngIf="value.icon && !value.asset_icon"
  ></i>
  <img
    class="trakto-modal-menu-item__icon-asset"
    *ngIf="value.asset_icon"
    [src]="value.asset_icon"
    alt="Icon"
  />
  <span class="trakto-modal-menu-item__label">{{ value.label }}</span>
  <span
    class="trakto-badge trakto-modal-menu-item__badge"
    *ngIf="value.badge"
    >{{ value.badge }}</span
  >
</div>
