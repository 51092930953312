import { Component, Input, OnInit } from '@angular/core';

import { PremiumService } from '@app/editor/services/premium.service';

import { AudioService } from '@app/shared/audio/audio.service';
import {
  ElementsGalleryService
} from '@app/shared/elements-gallery/elements-gallery.service';

import {
  NotificationService
} from '@app/shared/notification/notification.service';

import { TraktoUser } from '@auth/shared/auth.model';
import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { ImageService } from '@editor/services/image.service';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { PlanConfigService } from '@shared/subscription/plan-config.service';

import {
  IFeatureBlockerData
} from '@trakto/data-tracking/dist/interfaces/DataTracking.model';

import { elementTypeEnum, ImageElementModel, } from '@trakto/models';
import {
  PanelDuotoneComponent
} from '../panel-duotone/panel-duotone.component';
import {
  PanelElementsComponent
} from '../panel-elements/panel-elements.component';
import { PanelFilterComponent } from '../panel-filter/panel-filter.component';
import {
  PanelLinkEmailComponent
} from '../panel-link-email/panel-link-email.component';
import {
  PanelLinkWebsiteComponent
} from '../panel-link-website/panel-link-website.component';
import {
  PanelLinkWhatsappComponent
} from '../panel-link-whatsapp/panel-link-whatsapp.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { ResolutionsModel } from '@trakto/graphics-resources';
import {
  SearchTypeEnum
} from '@app/editor/enums/editor-elements/search-element.enum';
import {
  ElementDatasource
} from '../../../enums/editor-elements/elements-datatasource.enum';
import { DEFAULT_SHAPES } from '@services/shape-element.service';
import {
  ISearchElementType
} from '@app/editor/types/elements/element-search.type';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import { UserService } from '@app/editor-v3/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-image',
  templateUrl: './panel-image.component.html',
})
export class PanelImageComponent extends AbstractComponent<ImageElementModel> implements OnInit {
  public allowedCutBg: boolean;
  public _removeBgCount: number;
  public _remainingRemoveBg: number;
  public user: TraktoUser;
  public isGlobal: boolean;
  public isRemoving = false;

  @Input() b2c: boolean;
  @Input() isImage: boolean;
  @Input() isEmoji: boolean;
  @Input() allowLockElementsByTemplate: boolean;

  @Input() panelDuotone: any;
  @Input() panelFilter: any;

  public isGif: boolean;
  public isTranslate: any;

  public isEmbeddedInstance: boolean;

  public isErrorBGRemoveTranslate: string;

  public isPremiumUser: boolean;

  public removeBgIsAvailable = false;
  public effectsIsAvailable = true;
  public elementLinkIsAvailable = true;
  public panelHeaderTitle: string;
  private _panelHeaderTitleIcon: string;

  public set removeBgCount(removeBgCount: number) {
    this._removeBgCount = removeBgCount;
  }

  public get removeBgCount(): number {
    return this._removeBgCount || 0;
  }

  public set remainingRemoveBg(remainingRemoveBg: number) {
    this._remainingRemoveBg = remainingRemoveBg;
  }

  public get remainingRemoveBg(): number {
    return this._remainingRemoveBg || 0;
  }

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _imageService: ImageService,
    private _hotkeyService: HotkeyService,
    private _signalsService: SignalsService,
    private _translateService: TranslateService,
    private _planConfigService: PlanConfigService,
    private _panelStackService: PanelStackService,
    private _audioService: AudioService,
    private _premiumService: PremiumService,
    private _notificationService: NotificationService,
    private _productService: WhitelabelProductService,
    private _elementsGalleryService: ElementsGalleryService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
    this.makeTranslate();

    this.isEmbeddedInstance = this._authService.isEmbeddedInstance();
    this._productService.featuresAvailable.pipe(takeUntil(this._destroy$)).subscribe(features => {
      if (features) {
        this._identifyFeaturesAvailable(features);
      }
    });

    this.makeTranslateBGRemoveWarning();
  }

  private _identifyFeaturesAvailable(features) {
    this.removeBgIsAvailable = features[ProductFeatures.REMOVE_PHOTO_BG];
    this.effectsIsAvailable = features[ProductFeatures.ELEMENT_FILTERS];
    this.elementLinkIsAvailable = features[ProductFeatures.ELEMENT_LINKS];
  }

  ngOnInit() {
    super.ngOnInit();
    this._userService.getProfile().subscribe(user => {
      this.user = this._userService.user;
      this.isGlobal = this._userService.isGlobalAdmin;

      this._planConfigService.getUserPlanId().then(async () => {
        const premiumPlans = await this._planConfigService.getPlanPremium();
        const plan = await this._planConfigService.getUserPlanId();
        this.isPremiumUser = premiumPlans.includes(plan);
      });

      if (this.b2c && !this.isPremiumUser) {
        this.getLimitsRemoveBg(user);
      }
    });

  }

  public onElementSelected(value: ImageElementModel) {
    if (value.type !== elementTypeEnum.image && value.type !== elementTypeEnum.gif) {
      return;
    }
    if (this.elementSelected) {
      this.isGif =
        this.elementSelected.type !== elementTypeEnum.image &&
        this.elementSelected.type !== elementTypeEnum.emoji;
      this._checkImage();
      const elementIsEmoji = (value as any).source === ElementDatasource.FLATICON || value.type !== elementTypeEnum.image;
      if (elementIsEmoji) {
        this.panelHeaderTitle = this._panelHeaderTitleIcon
      }
    }
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public async getLimitsRemoveBg(user: TraktoUser) {
    if (user) {
      const data = await this._planConfigService.getLimitRemoveBg();
      this.removeBgCount = data.total;
      this.remainingRemoveBg = data.remaining;
    }
  }

  public async openModalBackgroundRemoval(callback: any) {
    const removeBg = await this._planConfigService.getLimitRemoveBg();
    const checkoutData = await this._premiumService.getCheckoutConfig();

    this._premiumService.openModalBlockingRemoveBG(
      removeBg,
      checkoutData,
      callback,
    );
  }

  verifyPermitionAndCallToAction(
    featureName: string,
    featureEmiter: () => Promise<any>,
  ) {
    if (this.b2c) {
      const dataEvent: IFeatureBlockerData = {
        reason: 'feature blocker',
        actionBlocked: featureName,
        startedByBlocker: true,
      };

      this.openModalBackgroundRemoval(() => {
        featureEmiter()
          .then(() => {
            this._planConfigService.getPermission(
              featureName,
              // data => (data ? featureEmiter() : this.openModalBackgroundRemoval()),
              data => null,
              err => this.openModalBackgroundRemoval(featureEmiter),
            );
          })
          .catch(() =>
            this._notificationService.error(this.isErrorBGRemoveTranslate),
          );
      });
    } else {
      featureEmiter().catch(() =>
        this._notificationService.error(this.isErrorBGRemoveTranslate),
      );
    }
  }

  public onChangeProperty(property: string): void {
    if (property) {
      this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
        elementId: this.elementSelected.id,
        key: property,
        value: this.elementSelected[property],
        obj: this.elementSelected,
      });
    }
  }

  public makeTranslateBGRemoveWarning(): void {
    this._translateService
      .get('property_panel.image.remove_background.message_error')
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.isErrorBGRemoveTranslate = value));
  }

  public addQuickMask(mask: string): void {
    this._elementChangeFacadeService.changeImageSubmaskByPath(this.elementSelected, DEFAULT_SHAPES[mask]);
  }

  public resetMask(): void {
    this._elementChangeFacadeService.changeImageSubmaskByPath(this.elementSelected, this.elementSelected.supportPath);
  }

  public openModal(event: any): void {
    const featureType = {
      'trakto-modal-gif': 'gif_stock',
      'trakto-modal-emoji': 'icon_stock',
      'trakto-modal-image': 'image_stock',
      'trakto-modal-shape': 'shape_stock',
      'trakto-modal-sticker': 'sticker_stock',
    };

    if (featureType[event.id]) {
      this._signalsService.emit(enumSignals.ON_OPEN_MODAL, { ...event });
    }
  }
  public setFilter(filter: string): void {
    if (this.elementSelected) {
      this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'filter', filter || 'empty')
    }
  }

  public async removeBg() {
    if (
      this.elementSelected &&
      this.elementSelected.type === elementTypeEnum.image
    ) {
      if (this.b2c && !this.isPremiumUser) {
        this.verifyPermitionAndCallToAction('remove_bg_count', async () => {
          await this.cutImageBackground();
        });
      } else {
        await this.cutImageBackground().catch(() =>
          this._notificationService.error(this.isErrorBGRemoveTranslate),
        );
      }
    }
  }

  public async cutImageBackground(): Promise<any> {
    this.isRemoving = true;

    if (this.user) {
      try {
        const data: ResolutionsModel =
          await this._imageService.cutImageBackground(
            this.elementSelected.href,
          );
        await this._elementChangeFacadeService.changeImageUrlByResolutions(
          this.elementSelected,
          data
        );
        await this.loadNewImage(data.medium.secure_url);

        this.onElementSelected(this.elementSelected);
        this.onChangeProperty('href');

        if (!this.isPremiumUser) {
          this._planConfigService.limits.remove_bg_count = this
            .remainingRemoveBg--;
          this._planConfigService.limits.remove_bg =
            this.remainingRemoveBg !== 0 ? true : false;
        }

        this._audioService.play('removefundo');
      } finally {
        this.isRemoving = false;
      }
    }
  }

  private loadNewImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.addEventListener('load', () => resolve(img), false);
      img.addEventListener('error', err => reject(err), false);
      img.src = url;
    });
  }

  public get title(): string {
    if (this.elementSelected?.type === elementTypeEnum.image) {
      return this.isTranslate.image;
    } else if (this.elementSelected?.type === elementTypeEnum.emoji) {
      return this.isTranslate.icon;
    } else {
      return this.isTranslate.gif;
    }
  }

  public get icon(): string {
    if (this.elementSelected?.type === elementTypeEnum.image) {
      return 'image';
    } else if (this.elementSelected?.type === elementTypeEnum.emoji) {
      return 'emoji';
    } else {
      return 'gifs';
    }
  }

  public makeTranslate(): void {
    this.isTranslate = {
      image: '',
      icon: '',
      gif: '',
    };
    this._translateService.get('property_panel').pipe(takeUntil(this._destroy$)).subscribe(
      value => {
        this.isTranslate.image = value['image']['name'];
        this.isTranslate.icon = value['icon']['name'];
        this.isTranslate.gif = value['gif']['name'];
        this.panelHeaderTitle = value['image']['title'];
        this._panelHeaderTitleIcon = value['icon']['title'];
      },
      err => console.error(err),
    );
  }

  private _checkImage() {
    this.allowedCutBg =
      this._imageService.getExtensionFile(this.elementSelected?.href) !== 'gif';
  }

  public openPanelStack(context: string): void {
    const actionComponent = {
      duotone: PanelDuotoneComponent,
      filter: PanelFilterComponent,
      'panel-website': PanelLinkWebsiteComponent,
      'panel-email': PanelLinkEmailComponent,
      'panel-whatsapp': PanelLinkWhatsappComponent,
    };
    if (Object.keys(actionComponent).indexOf(context) > -1) {
      // Se o contexto existir no objeto
      this._panelStackService.stack(actionComponent[context], {
        inputs: { elementSelected: this.elementSelected },
      });
    }
  }

  public openElementsPanel(type: string) {
    let panelStore = {};

    if (type === 'emoji')
      panelStore = { elementType: ISearchElementType.SVG, name: 'emoji' };
    if (type === 'shape')
      panelStore = { elementType: ISearchElementType.PATH, name: 'shape' };

    this._elementsGalleryService.setElementsPanelStore({
      ...panelStore,
      type: SearchTypeEnum.FOLDER,
      query: null,
      backComponentInfo: {
        component: PanelImageComponent,
        params: {
          inputs: {
            b2c: this.b2c,
            isImage: this.isImage,
            isEmoji: this.isEmoji,
            allowLockElementsByTemplate: this.allowLockElementsByTemplate,
            panelDuotone: this.panelDuotone,
            panelFilter: this.panelFilter,
          },
        },
      },
    });
    this._panelStackService.popAll(true);
    this._panelStackService.stack(PanelElementsComponent);
  }
}
