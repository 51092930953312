<div class="panel-mobile">
  <trakto-panel-header-mobile img="assets/editor-v3/images/icon-tratko-link.svg" label="Trakto Link"
    [showBackLabel]="false" [ignoreBackClick]="true" (onClose)="closePanel()"></trakto-panel-header-mobile>

  <div class="content" *ngIf="bellaDisplay && !isActive && !published">
    <div class="bella">
      <img src="assets/editor-v3/images/Bella-trakto-link.svg" alt="">
    </div>
    <h3>Um link para chamar de seu!</h3>
    <p>Crie mini-sites em poucos cliques e compartilhe em qualquer lugar</p>
    <button class="btn-start" (click)="startLink()">Começar</button>
    <button class="btn-back" (click)="closePanel()">Voltar ao editor</button>
  </div>

  <div class="content-trakto-link" *ngIf="!bellaDisplay">
    <div class="back" (click)="closePanel()">
      <div class="border-image-clip-path">
        <span>Voltar ao editor</span>
      </div>
    </div>
    <div class="steps" *ngIf="!published">
      <div class="circle1"
        [ngStyle]="{'background': isActive ? '#FCFCFE' : 'linear-gradient(261.84deg, #0095FF -67.56%, #FF4FB8 121.44%), #FFFFFF'}">
        <h3 [ngStyle]="{'color': isActive ? '#354564' : '#FFFFFF'}">1</h3>
        <img *ngIf="isActive" src="../../../../../../../assets/editor-v3/icons/svg/check-step-trakto-link.svg" alt=""
          style="position: absolute;
        margin-bottom: 25px;
        margin-left: 25px;">
      </div>
      <div [ngClass]="continueEdit ? 'lineEdit' : 'line' ">
      </div>
      <div class="circle2"
        [ngStyle]="{'background': !continueEdit ? '#FCFCFE' : 'linear-gradient(261.84deg, #0095FF -67.56%, #FF4FB8 121.44%), #FFFFFF'}">
        <h3 [ngStyle]="{'color': !continueEdit ? '#354564' : '#FFFFFF'}">2</h3>
      </div>
    </div>
    <div class="steps2" [ngClass]="continueEdit ? 'steps2Edit' : 'steps2' " *ngIf="!published">
      <div class="createdLink">
        <p>Crie o link da sua página</p>
      </div>
      <div class="space">
      </div>
      <div class="customizeView">
        <p>Personalize a visualização</p>
      </div>
    </div>

    <section class="tlinks-personalize__create-link" *ngIf="!continueEdit && !published">
      <p>
        Esse será seu Trakto Link
      </p>


      <label>
        <span>
          {{ linkPlaceholder }}
        </span>
        <input #inputlink type="text" [(ngModel)]="inputValue"
          [ngStyle]="{'color': link.status === 'link_failed' ? '#FE4A23' : (link.status === 'link_success' ? '#1DC763' : '#7251E7')}"
          [placeholder]="'trakto_links.personalize.input_placeholder' | translate" (keyup)="onLinkTyping($event)"
          [imask]="inputMask" (focusout)="disableInputLink()" maxlength="linkLength.max" />
      </label>
      <span id="link-trakto">
        <!-- Empty  -->
        <p *ngIf="link.status === panelsEnum.LINK_EMPTY && !link.loading">
          <img src="../../../../../../../assets/editor-v3/icons/svg/lamp-on.svg" alt="">
          Esse será o link da sua página Trakto.
        </p>

        <!-- Success -->
        <p *ngIf="link.status === panelsEnum.LINK_SUCCESS && !link.loading && !isActive">
          <i class="trakto-icon-success-ball" style="color: #1DC763;"></i>
          Eba, esse link está disponível!
        </p>

        <!-- Active -->
        <p *ngIf="link.status === panelsEnum.LINK_SUCCESS && !link.loading && isActive">
          <i class="trakto-icon-success-ball" style="color: #1DC763;"></i>
          Eba, seu link foi ativado!
        </p>

        <!-- Failed -->
        <p *ngIf="link.status === panelsEnum.LINK_FAILED && !link.loading">
          <i class="trakto-icon-failed-ball" style="color: #FE4A23;"></i>
          Poxa, esse link está indisponível, tente outro.
        </p>
      </span>


      <!-- Button active link -->
      <section class="tlinks-personalize__button-edit" *ngIf="!isActive && !continueEdit">
        <button class="btn-active" [disabled]="link.status !== 'link_success' || link.loading" (click)="activeLink()"><i
            class="trakto-icon-link-drop"></i> Ativar link</button>
      </section>

      <!-- Button copy link -->
      <section class="tlinks-personalize__button-edit" *ngIf="isActive">
        <button class="btn-copy" (click)="copyLinkToClipboard()"
          [ngStyle]="{'color': link.copied ? '#1DC763' : '#7183A6', 'border-color': link.copied  ? '#1DC763' : '#7183A6'}"><i
            class="trakto-icon-link-drop"></i> {{link.copied ? 'Copiado com sucesso' : 'Copiar link'}}</button>
      </section>

      <!-- Button personalize link -->
      <section class="tlinks-personalize__button-edit" *ngIf="isActive">
        <button class="btn-active" (click)="continue()"> Continuar</button>
      </section>

      <div class="tlinks__qrcode-page" #qrCodeTemplate id="qrCodeTemplate">
        <qrcode style="display: flex; justify-content: center;align-items: center;" *ngIf="!continueEdit && !published && isActive" [qrdata]="link.url" [width]="246"
          [margin]="2" [errorCorrectionLevel]="'L'"></qrcode>
      </div>
        <div class="icon" *ngIf="!continueEdit && !published && isActive" style="display: flex; align-items: center; justify-content: center;" (click)="downloadQRCode()">
          <img src="../../../../../../../assets/editor-v3/icons/svg/download-icon.svg" alt="">
          <p style="font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #0095FF;
          margin-bottom: 0px;
          margin-left: 10px;
          ">
            Baixar QR code
          </p>
        </div>
    </section>

    <section class="tlinks-personalize__create-link" *ngIf="continueEdit && isActive || published" style="padding-bottom: 0px;">
      <div class="title_copy" style="display: flex; justify-content: space-between;">
        <p>
          Link
        </p>
        <p style="font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #0095FF;
        margin-bottom: 0px;
        margin-left: 0px;
        cursor: pointer;"
        (click)="copyLinkToClipboard()"
        >
          <i class="trakto-icon-link-drop" style="color: #0095FF;"></i>Copiar link
        </p>
      </div>
      <label>
        <span>
          {{ linkPlaceholder }}
        </span>
        <input #inputlink type="text" [(ngModel)]="inputValue"
          [ngStyle]="{'color': link.status === 'link_failed' ? '#FE4A23' : (link.status === 'link_success' ? '#1DC763' : '#7251E7')}"
          [placeholder]="'trakto_links.personalize.input_placeholder' | translate" (keyup)="onLinkTyping($event)"
          [imask]="inputMask" (focusout)="disableInputLink()" maxlength="linkLength.max"/>
      </label>
      <span id="link-trakto">
        <!-- Empty  -->
        <p *ngIf="link.status === panelsEnum.LINK_EMPTY && !link.loading">
          <img src="../../../../../../../assets/editor-v3/icons/svg/lamp-on.svg" alt="">
          Esse será o link da sua página Trakto.
        </p>

        <!-- Success -->
        <p *ngIf="link.status === panelsEnum.LINK_SUCCESS && !link.loading && !isActive">
          <i class="trakto-icon-success-ball" style="color: #1DC763;"></i>
          Seu link está ativo
        </p>

        <!-- Active -->
        <p *ngIf="link.status === panelsEnum.LINK_SUCCESS && !link.loading && isActive">
          <i class="trakto-icon-success-ball" style="color: #1DC763;"></i>
          Eba, seu link foi ativado!
        </p>

        <!-- Failed -->
        <p *ngIf="link.status === panelsEnum.LINK_FAILED && !link.loading">
          <i class="trakto-icon-failed-ball" style="color: #FE4A23;"></i>
          Poxa, esse link está indisponível, tente outro.
        </p>
      </span>
    </section>


    <section class="personalize_link" *ngIf="continueEdit && isActive || published" style="padding-top: 0px !important;">

      <div class="title" style="width: 100%;">
        <div class="subTitle" style="width: 100%;">
          <p style="width: 100%;">
            Título
          </p>
        </div>
        <nz-input-group style="width: 100%;">
          <input type="text" nz-input [(ngModel)]="metaTitle" [placeholder]="'Meu portfólio'" style="width: 100%;"/>
        </nz-input-group>
      </div>

      <div class="description" style="margin-top: 16px; width: 100%;">
        <p style="width: 100%;">
          Descrição do link
        </p>
        <nz-input-group [nzSuffix]="suffixIconEdit" style="width: 100%;">
          <input type="text" nz-input [(ngModel)]="metaDescription" [placeholder]="'Conheça meu trabalho'" style="width: 100%;"/>
        </nz-input-group>
        <ng-template #suffixIconEdit>
          <span nz-icon nzType="edit" nzTheme="outline"></span>
        </ng-template>
      </div>

      <div class="cover">
        <div [ngClass]="published ? 'description-publisher' : 'description'"
          style="margin-top: 16px;margin-bottom: 5px">
          <p>
            Capa personalizada
          </p>
          <div class="container" (click)=clickButton() [ngStyle]="{'height': imageSrcPreview === '' ? '48px' : '220px'}">
            <p *ngIf="imageSrcPreview === ''"><span nz-icon nzType="cloud-upload" nzTheme="outline"
                ></span> Enviar capa personalizada</p>
            <img src="{{imageSrcPreview}}" alt="" srcset="" *ngIf="imageSrcPreview !== ''"
              [ngStyle]="{'height': imageSrcPreview === '' ? '0px' : '215px'}">
          </div>
          <input id="file1" [ngStyle]="{'width': imageSrcPreview === '' ? '50%' : '100%'}" type="file" name="img" accept="image/png, image/jpeg"
            (change)="temporaryPreviewImage($event)" />
          <div class="size_upload" style="margin-top: -15px;">
            <p>1920x1080 | PNG ou JPG</p>
          </div>
        </div>


        <div class="qrcode" *ngIf="published">
          <p style="margin-bottom: 8px;">
            QR Code
          </p>
          <div class="tlinks__qrcode-page" #qrCodeTemplate id="qrCodeTemplate">
            <qrcode style="display: flex; justify-content: center;"
              *ngIf="published" [qrdata]="link.url" [width]="88" [margin]="2" [errorCorrectionLevel]="'L'"></qrcode>
          </div>

          <div class="icon" style="display: flex; align-items: center" (click)="downloadQRCode()">
            <img src="../../../../../../../assets/editor-v3/icons/svg/download-icon.svg" alt="">
            <p style="font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #0095FF;
            margin-bottom: 0px;
            margin-left: 0px;
            ">
              Baixar QR code
            </p>
          </div>
        </div>
      </div>

      <button [ngClass]="isLoading ? 'button--loading' : 'btn-active'" (click)="handleSaveMetaTags()" [disabled]="isLoading || !isAvaliable"  style="width: 100%;">
        <i class="trakto-icon-link-drop" *ngIf="!isLoading"></i>
        Publicar
      </button>

      <p *ngIf="!published">
        <img src="../../../../../../../assets/editor-v3/icons/svg/lamp-on.svg" alt="">
        Você pode personalizar seu link a qualquer momento
      </p>

      <button class="btn-disabled" *ngIf="published" (click)="toggle()">
        <span nz-icon nzType="close-circle" nzTheme="outline"></span>
        Desativar link
      </button>
    </section>

  </div>
</div>

<div class="modal" *ngIf="showModal">
  <img src="../../../../../../../assets/editor-v3/images/bella-sad.svg" alt="">
  <h3>Tem certeza disso?</h3>
  <p>Se desativar seu Trakto Link, ninguém vai poder visitar ele até ser ativado de novo.</p>
  <button class="btn-active" (click)="closeModal()" style="width: 100%;">
    Não
  </button>
  <button class="btn-disabled" (click)="deleteLink()" style="width: 100%;">
    <span nz-icon nzType="close-circle" nzTheme="outline"></span>
    Sim, quero desativar
  </button>
</div>
<div class="overlay" *ngIf="showModal"></div>

<trakto-modal-loader *ngIf="isLoading"></trakto-modal-loader>
