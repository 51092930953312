import { Component, OnDestroy, OnInit } from '@angular/core';
import { FiltersService } from '@app/editor-v3/services/filters.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { IFilterData } from '@app/editor-v3/utils/filters';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { IElementModel, PageModel } from '@trakto/models';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-panel-filters-mobile',
  templateUrl: './panel-filters-mobile.component.html',
  styleUrls: ['./panel-filters-mobile.component.scss'],
})
export class PanelFiltersMobileComponent implements OnInit, OnDestroy {
  filters: IFilterData[] = [];
  pageSelected: PageModel = null;
  elementSelected: IElementModel = null;
  isEmoji: string = null;

  subscriptions: Subscription[] = [];

  constructor(
    private _panelService: PanelService,
    private _filtersService: FiltersService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
  ) {}

  ngOnInit(): void {
    this.getSelectedEntities();
    this.getFiltersList();
    this.makeSvgEmoji();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getSelectedEntities() {
    this.subscriptions.push(
      this._documentStateManagerService.page$.subscribe(page => this.pageSelected = page)
    );
    this.subscriptions.push(
      this._documentStateManagerService.element$.subscribe(element => this.elementSelected = element)
    );
  }

  getFiltersList() {
    this.filters = [...this._filtersService.filters];
  }

  makeSvgEmoji(): any {
    if (this.elementSelected) {
      const parseSvg = new DOMParser();
      const svgElement = parseSvg.parseFromString(
        this.elementSelected.svg,
        'image/svg+xml',
      );

      const xml = new XMLSerializer().serializeToString(
        svgElement.documentElement,
      );
      const svg64 = btoa(xml);
      const b64start = 'data:image/svg+xml;base64,';
      const image64 = b64start + svg64;

      this.isEmoji = image64;
    }
  }

  applyFilter(filter: string) {
    this._filtersService.setFilter(filter);
    this.closePanel();
  }

  removeFilter() {
    this._filtersService.removeFilter();
    this.closePanel();
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
