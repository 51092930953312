import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleWithLineComponent } from './title-with-line.component';



@NgModule({
  declarations: [
    TitleWithLineComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TitleWithLineComponent,
  ]
})
export class TitleWithLineModule { }
