import { Component, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/shared/auth.service';

@Component({
  selector: 'trakto-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent {
  private _messageHandler: any;
  private _origin = 'https://trakto.io';

  constructor(
    private _authService: AuthService,
    private _zone: NgZone,
    private _route: ActivatedRoute,
  ) {
    this._zone.run(() => {
      this._messageHandler = async event => {
        if (typeof event.data === 'string') {
          const eventData = JSON.parse(event.data);

          if (
            eventData &&
            eventData.origin === this._origin &&
            eventData.type === 'trakto:authenticate'
          ) {
            try {
              this._authService.invalidateAuthCredentialsFromStorage();
              const { token_type = '' } = this._route.snapshot.queryParams;

              const success = token_type === 'id_token' ?
                await this._authService.login(eventData.value).toPromise() :
                await this._authService.signinByAccessToken(eventData.value);

              this._sendAuthorizationToIframe(success);
              window.removeEventListener('message', this._messageHandler);
            } catch (error) {
              this._sendAuthorizationToIframe(false);
            }
          }
        }
      };

      window.addEventListener('message', this._messageHandler);
    });
  }

  private _sendAuthorizationToIframe(authorized: boolean) {
    window.parent.postMessage(
      JSON.stringify({
        type: 'trakto:authorized',
        value: authorized,
        origin: this._origin,
      }),
      '*',
    );
    // window.parent.postMessage(JSON.stringify({ type: 'authorized', value: authorized, origin: this._origin }), '*');
  }
}
