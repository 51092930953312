import { Injectable } from '@angular/core';
import { UtilMagicTemplateService } from '@shared/magic-template/shared/util-magic-template.service';
import { MediaService } from '@services/media.service';
import { ImageElementService } from '@services/image-element.service';
import { ImageMagicTemplateGenerator } from '@trakto/magic-templates';

@Injectable()
export class ImageMagicTemplateService extends ImageMagicTemplateGenerator {
  constructor(
    newImageService: ImageElementService,
    utilMagicTemplateService: UtilMagicTemplateService,
    mediaService: MediaService,
  ) {
    super (utilMagicTemplateService, newImageService, mediaService);
  }

}
