const colors = [
  '#0095FF',
  '#7251E7',
  '#FE43D1',
  '#FE6E46',
  '#1DC763',
  '#FFC13D',
];

export function getColor(idx: number): string {
  return idx <= colors.length - 1 ? colors[idx] : colors[idx % colors.length];
}
