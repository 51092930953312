import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  template: `<div *ngIf="error">Erro</div>`,
})
export class CallbackComponent implements OnInit {
  error = false;
  private _destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this._destroy$),
      switchMap(params => {
        const authToken = params['authToken'];
        if (authToken) {
          return this.authService.login(authToken)
        } else {
          this.error = true;
        }
      })
    ).subscribe(
      () => {
        this.authService.redirectToIndex();
        this.error = false;
      },
      error => {
        console.error(error);
        this.error = true;
      },
    );
  };
}
