import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImagesService } from '@app/editor-v3/services/images.service';
import {
  EnumSizeProperty,
  PageService,
} from '@app/editor-v3/services/page.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import {
  FormatsService,
  IChildFormat,
} from '@app/editor/components/properties-panel/panel-page/services/formats.service';
import { PageModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import {
  IUnitOptions
} from './components/inputs-settings/inputs-settings.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-properties-page-mobile',
  templateUrl: './panel-properties-page-mobile.component.html',
  styleUrls: ['./panel-properties-page-mobile.component.scss'],
})
export class PanelPropertiesPageMobileComponent implements OnInit, OnDestroy {
  @ViewChild('swiperInstance', { static: false })
  swiperInstance?: SwiperComponent;

  pageSelected: PageModel = null;
  allFormats: IChildFormat[] = [];
  lang = 'pt-BR';

  width: number = 0;
  height: number = 0;

  public loading = false;
  private _onLoadingChange: Subscription = null;
  private _onPageSelect: Subscription = null;
  private _onWidthChange: Subscription = null;
  private _onHeightChange: Subscription = null;
  private _onPageChange: Subscription = null;
  private _subscriptions: Subscription[] = [];

  swiperConfig: SwiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 24,
    centeredSlides: true,
    direction: 'horizontal',
    loop: true,
    navigation: true,
    preloadImages: true,
  };

  constructor(
    private _panelService: PanelService,
    private _formatsService: FormatsService,
    private _documentStateManagerService: DocumentStateManagerService,

    private _pageService: PageService,
    private _imagesService: ImagesService,
  ) {}

  async ngOnInit(): Promise<void> {
    this._imagesService.isImageToPageBackground = true;
    this.initSubscriptions();
    this.allFormats = await this._pageService.getAllFormats();
    this.updateCarousel();
  }

  ngOnDestroy() {
    this._imagesService.isImageToPageBackground = false;
  }

  initSubscriptions() {
    this._onLoadingChange = this._formatsService.onLoadingChange.subscribe(
      isLoading => {
        this.loading = isLoading;
      },
    );

    this._onPageSelect = this._documentStateManagerService.page$.subscribe(
      page => {
        this.pageSelected = page;
      },
    );

    this._onPageChange = this._pageService.onPageUpdated.subscribe(() =>
      this.updateCarousel(),
    );

    this.width = this._pageService.convertedWidth;
    this.height = this._pageService.convertedHeight;

    this._onWidthChange = this._pageService.onWidthChange.subscribe(
      width => (this.width = width),
    );

    this._onHeightChange = this._pageService.onHeightChange.subscribe(
      height => (this.height = height),
    );

    this._subscriptions.push(
      this._onLoadingChange,
      this._onPageSelect,
      this._onPageChange,
      this._onWidthChange,
      this._onHeightChange,
    );
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  updateCarousel() {
    const pageFormat = this._pageService.getPageSelectedFormat();
    this.swiperInstance?.swiperRef.update();

    const index = pageFormat
      ? this.allFormats.findIndex(child => child.id === pageFormat) + 1
      : 0;

    setTimeout(() => {
      this.swiperInstance?.swiperRef.slideToLoop(index);
    }, 100);
  }

  async changeFormat(format: IChildFormat) {
    await this._pageService.changePageFormat(format);
    this.updateCarousel();
  }

  async changeDimensions(event: { property: EnumSizeProperty; value: string }) {
    await this._pageService.changePageDimensions(event);
    this.updateCarousel();
  }

  toggleLockPageProportion() {
    this._pageService.toggleLockPageProportion();
  }

  async invertDimensions() {
    await this._pageService.invertPageDimensions();
    this.updateCarousel();
  }

  changeUnit(unit: IUnitOptions) {
    this._pageService.changePageDimensionsUnit(unit);
  }

  applyToAll() {
    this._pageService.changeAllPageSizes();
  }

  openFormatsPanel() {
    this._panelService.openPanel('formats');
  }

  openImagesPanel() {
    this._panelService.openPanel('image');
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  changePageBackground() {
    this._panelService.openPanel('page-background-color');
  }
}
