import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { DialogServiceImpl } from './dialog.service.impl';
import { YesOrCancelComponent } from './yes-or-cancel/yes-or-cancel.component';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  exports: [MatDialogModule],
  declarations: [YesOrCancelComponent],
  providers: [
    {
      provide: DialogService,
      useClass: DialogServiceImpl,
    },
  ],
})
export class DialogModule {}
