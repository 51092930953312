<div class="zoom_buttons">
  <div id="extends" class="button-group">
    <button
      class="tkt-button__DS hover-silver"
      (click)="handleZoomFit($event)"
      width="80"
    >
      <i class="trakto-icon-stream-fit-screen"></i>
      <span class="tooltip_text">{{ translatedTexts.zoombar.fit_screen }}</span>
    </button>

    <button
      class="tkt-button__DS hover-silver"
      (click)="changeFullscreen($event)"
      width="80"
    >
      <i class="trakto-icon-stream-full-screen"></i>
      <span class="tooltip_text">{{
        translatedTexts.zoombar.full_screen
      }}</span>
    </button>

    <div class="button-group_divider"></div>

    <button
      class="tkt-button__DS hover-silver"
      (click)="handleZoomOut($event)"
      width="80"
    >
      <i class="trakto-icon-stream-zoom-out"></i>
      <span class="tooltip_text">{{ translatedTexts.zoombar.zoom_out }}</span>
    </button>

    <button
      class="tkt-button__DS hover-silver"
      (click)="handleZoomIn($event)"
      width="80"
    >
      <i class="trakto-icon-stream-zoom-in"></i>
      <span class="tooltip_text">{{ translatedTexts.zoombar.zoom_in }}</span>
    </button>

    <div class="button-group_divider"></div>
  </div>
  <div class="button-group">
    <i class="trakto-icon-stream-magnifier"></i>
    <span>{{ zoomRatio }}%</span>
  </div>
</div>
