import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
export class LogoutComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(private authService: AuthService) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this.authService.logout().pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      () => this.authService.redirectToLoginPage(),
      error => this.authService.redirectToLoginPage(),
    );
  }
}
