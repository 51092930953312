import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollListenerService {
  isScrollUpVisible = false;
  public onVisibilityToggle: Subject<boolean>;

  constructor() {
    this.onVisibilityToggle = new ReplaySubject<boolean>(1);
  }

  toggleScrollUpVisibility(isVisible: boolean) {
    if (this.isScrollUpVisible !== isVisible) {
      this.isScrollUpVisible = isVisible;
      this.onVisibilityToggle.next(this.isScrollUpVisible);
    }
  }
}
