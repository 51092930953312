import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'trakto-file-uploading',
  templateUrl: './file-uploading.component.html',
  styleUrls: ['./file-uploading.component.scss'],
})
export class FileUploadingComponent {
  @Output() fileDropped = new EventEmitter<any>();

  private _extensions = ['ttf', 'otf'];

  constructor() {}

  private _filterExtesion(files: any[]) {
    return files.filter(file => {
      const splitType = file.type.split('/');
      const checkType = this._extensions.includes(
        splitType[splitType.length - 1],
      );
      if (checkType) {
        return file;
      }
      const splitDot = file.name.split('.');
      const checkName = this._extensions.includes(
        splitDot[splitDot.length - 1],
      );

      if (checkName) {
        return file;
      }
      return;
    });
  }

  public onFileDropped(files): void {
    if (files.length <= 0) {
      return;
    }
    const fileFilterd = this._filterExtesion([...files]);

    if (fileFilterd.length > 0) {
      this.fileDropped.emit(fileFilterd);
    }
  }
}
