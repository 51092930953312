import { Component, Input } from '@angular/core';

@Component({
  selector: 'trakto-links-qrcode-empty',
  templateUrl: './qrcode-empty.component.html',
  styleUrls: ['./qrcode-empty.component.scss'],
})
export class QRCodeEmptyComponent {
  constructor() {}
}
