import { Component, OnInit } from '@angular/core';
import { ElementsService } from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { ISearchEvent } from '@app/editor/components/properties-panel/panel-elements/model/model';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { ElementNewApiService } from '@services/element-new-api.service';
import { GalleryElementType } from '@app/editor/enums/editor-elements/gallery-type.enum';

@Component({
  selector: 'trakto-shapes-grid',
  templateUrl: './shapes-grid.component.html',
  styleUrls: ['./shapes-grid.component.scss'],
})
export class ShapesGridComponent implements OnInit {
  searchText: string = '';
  tags: string[] = [];

  filteredTags: string[] = [];
  filteredText: string = '';
  showSuggestions: boolean = false;

  search: ISearchEvent;

  constructor(
    private _panelService: PanelService,
    private _elementsGalleryService: ElementNewApiService,
    private _elementsService: ElementsService,
  ) {}

  async ngOnInit() {
    this.resetSearch();
    await this._initTags();
  }

  async _initTags(): Promise<boolean> {
    try {
      const tags = await this._elementsGalleryService.getTags({
        elementType: this._elementsGalleryService.getElementTypeByContentType(GalleryElementType.SHAPE),
      });

      this.tags = tags.map(tag => tag.name);
    } catch (error) {
      this.tags = [];
    } finally {
      this.filteredTags = [];
    }

    return true;
  }

  private resetSearch() {
    this.search = {
      elementType: this._elementsGalleryService.getElementTypeByContentType(GalleryElementType.SHAPE),
    };
    if (this.filteredText.length > 0) {
      this.search.tags = [{
        name: this.filteredText,
        languageISOCode: 'pt-BR',
      }];
    }
  }
  closePanel() {
    this._panelService.closeLastPanel();
  }

  setInputText(val: string) {
    this.searchText = val;
    this.setFilteredTags();
    this.showSuggestions = val.length > 0;

    if (val.length === 0) this.selectTag('');
  }

  setShowSuggestions(val: boolean) {
    setTimeout(() => {
      this.showSuggestions = val;
    }, 50);
  }

  setFilteredTags() {
    this.filteredTags = this.tags.filter(
      tag => tag.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1,
    );
  }

  selectTag(tag: string) {
    this.searchText = this.filteredText === tag ? '' : tag;
    this.filteredText = this.searchText;
    this.setShowSuggestions(false);
    this.resetSearch();
  }

  async changeShape(element: IElementNewAPI) {
    await this._elementsService.changeAsset(element);
    this.closePanel();
  }
}
