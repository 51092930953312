import { Component, OnInit } from '@angular/core';

import { KmlModalConfig, KmlModalRef } from '@trakto/trakto-kamaleon';

const IMAGE_BANNER = '/assets/images/ilustracao.png';

@Component({
  selector: 'trakto-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent implements OnInit {
  public title: string;
  public description: string;
  public buttonPrimary: string;
  public buttonSecondary: string;
  public imageBanner: string;

  constructor(
    public kmlData: KmlModalConfig,
    private _kmlModalRef: KmlModalRef,
  ) {}

  ngOnInit(): void {
    this.getModalData();

    this.imageBanner = IMAGE_BANNER;
  }

  public getModalData(): void {
    const {
      title,
      description,
      buttonPrimary,
      buttonSecondary,
    } = this.kmlData.data;

    this.title = title;
    this.description = description;
    this.buttonPrimary = buttonPrimary;
    this.buttonSecondary = buttonSecondary;
  }

  public onCloseModal(): void {
    this._kmlModalRef.close(false);
  }

  public onBackEditor(): void {
    this._kmlModalRef.close(true);
  }
}
