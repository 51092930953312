import { Component, Input } from '@angular/core';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { ISearchEvent } from '../model/model';

@Component({
  selector: 'trakto-scroll-up',
  templateUrl: './scroll-up.component.html',
  styleUrls: ['./scroll-up.component.scss'],
})
export class ScrollUpComponent {
  @Input() currentSearch: ISearchEvent;

  isVisible = false;

  scrollUp() {
    this.getElement(this.currentSearch.type).scroll(0, 0);
  }

  getElement(type: SearchTypeEnum): HTMLElement {
    const types = {
      [SearchTypeEnum.FOLDER]: '.formats-results',
      [SearchTypeEnum.MAIN]: '.search-results',
      [SearchTypeEnum.THEME]: '.theme-results',
    };

    return document.querySelector(types[type]);
  }
}
