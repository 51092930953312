import { Component } from '@angular/core';

@Component({
  selector: 'trakto-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

}
