import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { TooltipPlacementEnum } from './tooltip-placement.enum';

@Component({
  selector: 'trakto-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @ViewChild('tooltip', { static: true })
  private _elementRef: ElementRef;
  @ViewChild('tooltipArrow', { static: true })
  private _elementRefArrow: ElementRef;

  private _text: string;
  private _width: number;
  private _baseClass: string;

  @Input()
  public set text(value: string) {
    this._text = value;
  }

  public get text(): string {
    return this._text;
  }

  @Input()
  public set width(value: number) {
    this._width = value;
  }

  public get width(): number {
    return this._width;
  }

  constructor(private _renderer: Renderer2) {
    this._baseClass = 'trakto-tooltip--';
  }

  renderTooltip(
    leftPosition: string,
    topPosition: string,
    tooltipElement: TooltipComponent,
  ) {
    this._renderer.setStyle(tooltipElement, 'left', leftPosition);
    this._renderer.setStyle(tooltipElement, 'top', topPosition);
    this._renderer.setStyle(tooltipElement, 'opacity', 1);
  }

  public setPosition(options: {
    placement: TooltipPlacementEnum;
    width: string;
    targetElement: HTMLElement;
    backgroundColor: string;
    color: string;
    padding: string;
  }) {
    const {
      placement,
      width,
      targetElement,
      backgroundColor,
      color,
      padding,
    } = options;
    const distance = 11;
    const scrollY = window.pageYOffset;
    const tooltipElement = this._elementRef.nativeElement;
    const tooltipArrowElement = this._elementRefArrow.nativeElement;

    this._renderer.addClass(tooltipElement, `${this._baseClass}${placement}`);
    this._renderer.setStyle(tooltipElement, 'min-width', `${width}px`);
    this._renderer.setStyle(tooltipElement, 'max-width', `${width}px`);
    this._renderer.setStyle(
      tooltipElement,
      'background-color',
      `${backgroundColor}`,
    );
    this._renderer.setStyle(tooltipElement, 'color', `${color}`);
    this._renderer.setStyle(tooltipElement, 'padding', `${padding}`);
    this._renderer.setStyle(
      tooltipArrowElement,
      'background',
      `${backgroundColor}`,
    );
    this._renderer.setStyle(tooltipArrowElement, 'color', `${color}`);

    Promise.resolve().then(() => {
      const targetPosition = targetElement.getBoundingClientRect();
      const tooltipWidth = tooltipElement.clientWidth;
      const tooltipHeight = tooltipElement.clientHeight;

      if (placement === TooltipPlacementEnum.top) {
        const top = `${
          targetPosition.bottom +
          scrollY -
          tooltipHeight -
          targetPosition.height -
          distance
        }px`;
        const left = `${
          targetPosition.left + targetPosition.width / 2 - tooltipWidth / 2
        }px`;

        this.renderTooltip(left, top, tooltipElement);
        return;
      }

      if (placement === TooltipPlacementEnum.right) {
        const top = `${
          targetPosition.top +
          scrollY +
          targetPosition.height / 2 -
          tooltipHeight / 2 -
          4
        }px`;
        const left = `${targetPosition.right + distance}px`;

        this.renderTooltip(left, top, tooltipElement);
        return;
      }

      if (placement === TooltipPlacementEnum.left) {
        const top = `${
          targetPosition.bottom +
          scrollY -
          targetPosition.height / 2 -
          tooltipHeight / 2 -
          4
        }px`;
        const left = `${targetPosition.left - tooltipWidth - distance}px`;

        this.renderTooltip(left, top, tooltipElement);
        return;
      }

      if (placement === TooltipPlacementEnum.bottom) {
        const top = `${targetPosition.bottom + scrollY + distance}px`;
        const left = `${
          targetPosition.left + targetPosition.width / 2 - tooltipWidth / 2
        }px`;

        this.renderTooltip(left, top, tooltipElement);
        return;
      }
    });
  }
}
