import { Injectable, OnDestroy } from '@angular/core';
import { MediaService } from '@services/media.service';
import { Base64CacheService } from '@shared/base64-cache/base64-cache.service';
import {
  FontModelUtil,
  FontOptionUtilModel,
  IFontOperations,
} from '@trakto/core-editor';
import {
  forkJoin,
  from,
  Observable,
  Observer,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';

import { IFontModel, IFontOptions, PageModel } from '@trakto/models';

import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TraktoApiService } from '@services/trakto-api.service';
import { FontRepository } from '@editor/repository/font.repository';

const FONT_DEFAULT = 'Montserrat';

@Injectable()
export class FontsService implements IFontOperations, OnDestroy {
  private userFontsObservable: Subject<IFontModel[]>;
  private traktoFontsObservable: Subject<IFontModel[]>;
  private allFontsObserver: Subject<IFontModel[]>;

  public collectionsFonts = {
    userFonts: [],
    traktoFonts: [],
    // googleFonts: [],
    allFonts: [],
  };

  private downloadedFonts = [];
  private _destroy$ = new Subject<void>();

  constructor(
    private _fontRepository: FontRepository,
    private _mediaService: MediaService,
    private _base64CacheService: Base64CacheService,
    private _traktoApiService: TraktoApiService
  ) {
    this.userFontsObservable = new ReplaySubject<IFontModel[]>(1);
    this.traktoFontsObservable = new ReplaySubject<IFontModel[]>(1);
    this.allFontsObserver = new ReplaySubject<IFontModel[]>(1);
    this._getTypographyFromUser().then();
    this.fetchFonts();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
  public loadTypographies(): Observable<IFontModel[]> {
    return forkJoin(
      [
        this.listTraktoFonts(),
        this.listUserFonts(),
      ])
      .pipe(map(result => {
        const allFonts = [...result[0], ...result[1]];
        this.collectionsFonts.allFonts = allFonts;
        this.collectionsFonts.traktoFonts = result[0];
        this.collectionsFonts.userFonts = result[1];
        return allFonts;
      }));
  }

  public listTraktoFontsFamilies(): Observable<string[]> {
    return this.listTraktoFonts().pipe(
      map(fonts => fonts.map(font => font.family)),
      take(1),
    );
  }

  public listUserFontsFamilies(): Observable<string[]> {
    return this.listUserFonts().pipe(
      map(fonts => fonts.map(font => font.family)),
      take(1),
    );
  }

  public listAllFontsFamilies(): Observable<string[]> {
    return this.listAllFonts().pipe(
      map(fonts => fonts.map(font => font.family)),
    );
  }

  public getDefaultFontModel(): Observable<IFontModel> {
    return this.listTraktoFonts().pipe(
      map(fonts => this._findFontFamily(fonts, FONT_DEFAULT)),
      take(1),
    );
  }

  public listTraktoFonts(): Observable<IFontModel[]> {
    return this.traktoFontsObservable.pipe(take(1));
  }

  public listUserFonts(): Observable<IFontModel[]> {
    return this.userFontsObservable.pipe(take(1));
  }

  public listAllFonts(): Observable<IFontModel[]> {
    return this.allFontsObserver;
  }

  public listFontOptions(fontFamily: string): Observable<IFontOptions[]> {
    if (!fontFamily) { return; }
    return this.findFont(fontFamily).pipe(
      map(font => {
        return font ? font.options : undefined;
      }),
      take(1),
    );
  }

  public getRegularOrFirstFont(options: IFontOptions[]): any {
    const listRegular: any = options.filter((option: any) =>
      FontOptionUtilModel.isRegular(option),
    );
    return listRegular.length > 0 ? listRegular[0] : options[0];
  }

  public findFont(fontFamily: string): Observable<IFontModel> {
    return this.allFontsObserver.pipe(
      take(1),
      map(fonts => fonts.find(font => font.family === fontFamily)),
      switchMap(font => {
        return font ? of(font) : this.findFontInFirebase(fontFamily);
      }),
    );
  }

  public loadByFontFamily(fontFamily: string): Observable<boolean> {
    return new Observable(observer => {
      this.findFont(fontFamily).pipe(takeUntil(this._destroy$)).subscribe(font => {
        if (!font) {
          font = this.collectionsFonts.userFonts.find(
            item => item.family === fontFamily,
          );
        }
        this.downloadFont(font).pipe(takeUntil(this._destroy$)).subscribe(observer);
      });
    });
  }

  public loadByFontModel(fontModel: IFontModel): Observable<boolean> {
    return this.downloadFont(fontModel);
  }

  public loadByPage(page: PageModel): Observable<boolean> {
    if (!page.fontModels || page.fontModels.length === 0) {
      return of(true);
    }
    return forkJoin(
      page.fontModels.map(fontModel => this.downloadFont(fontModel)),
    ).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public fetchFontFaceWithBase64ByFontFamily(
    fontFamily: string,
  ): Observable<string> {
    if (!fontFamily) {
      return of('');
    }
    return this.fetchOrCreateFontBase64(fontFamily);
  }

  public fetchFontFaceWithBase64ByFontModel(
    fontModel: IFontModel,
  ): Observable<string> {
    if (!fontModel) {
      return of('');
    }
    return this._fetchOrCreateFontBase64ByFontModel(fontModel);
  }

  fetchFontFaceWithBase64ByPage(page: PageModel): Observable<string[]> {
    if (!page.fontModels) {
      return of([]);
    }
    return forkJoin(
      (page.fontModels || []).map(fontModel =>
        this._fetchOrCreateFontBase64ByFontModel(fontModel),
      ),
    );
  }

  private fetchOrCreateFontBase64(fontFamily: string): Observable<string> {
    return this._base64CacheService.fetchFontBase64(fontFamily).pipe(
      switchMap((result: any) => {
        if (result) {
          return of(result.base64);
        }
        return this.findFont(fontFamily)
          .pipe(switchMap(font => this.makeAndCacheFontBase64(font)))
          .pipe(catchError(() => of('')));
      }),
    );
  }

  private _fetchOrCreateFontBase64ByFontModel(
    fontModel: IFontModel,
  ): Observable<string> {
    // FIXME ver melhor modo de fazer cache quando usando fontmodel
    return this._base64CacheService.fetchFontBase64(fontModel.family).pipe(
      switchMap((result: any) => {
        if (result) {
          return of(result.base64);
        }
        return this.makeAndCacheFontBase64(fontModel).pipe(
          catchError(() => of('')),
        );
      }),
    );
  }

  private makeAndCacheFontBase64(font: IFontModel): Observable<string> {
    if (!font.options) {
      return of('');
    }
    return this.makeFontBase64(font).pipe(
      tap(base64 =>
        this._base64CacheService.addFontBase64(font.family, base64),
      ),
    );
  }

  private makeFontBase64(font: IFontModel): Observable<string> {
    if (font.provider !== 'google') {
      return from(this.fontFacesURLsToBase64(FontModelUtil.makeFontFaces(font)));
    }
    return from(
      this.fetchGoogleFontFaces(font).then(this.fontFacesURLsToBase64),
    );
  }

  private async fetchFonts() {
    const fonts = await this._fontRepository.findByCurrentProduct();
    this.traktoFontsObservable.next(fonts);
    this._concatFonts(fonts, 'traktoFonts');
    this._concatFonts(fonts, 'allFonts');
    this.allFontsObserver.next(this.collectionsFonts.allFonts);
  }

  private findFontInFirebase(fontFamily: string): Observable<any> {
    const downloadedFont = this.findDownloadedFonts(fontFamily);
    if (downloadedFont) {
      return of(downloadedFont);
    }
    if (!fontFamily) { return; }
    return this._mediaService.getFontModelByFamily(fontFamily);
  }

  private downloadFont(font: IFontModel): Observable<boolean> {
    const fontFamily = font.family;
    return new Observable(observer => {
      if (this.findDownloadedFonts(font.family)) {
        observer.next(true);
        observer.complete();
        return;
      }
      if (!font) {
        observer.error(`Font family ${fontFamily} not founded`);
        observer.complete();
        return;
      }
      if (!font.provider) {
        observer.error(`Provider ${font.provider} not founded`);
        observer.complete();
        return;
      }

      if (font.provider === 'system') {
        observer.next(true);
        observer.complete();
        return;
      }
      const provider = font.provider === 'google' ? 'google' : 'custom';

      if (provider === 'custom') {
        this.appendCustomFont(font);
      }

      this.watchFontLoading(font, provider).pipe(takeUntil(this._destroy$)).subscribe(
        () => {
          this.downloadedFonts.push(font);
          observer.next(true);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        },
      );
    });
  }

  private findDownloadedFonts(fontFamily: string): IFontModel {
    return this.downloadedFonts.find(f => f.family === fontFamily);
  }

  public _findFontFamily(fonts: IFontModel[], fontFamily: string): IFontModel {
    return fonts.find(font => font.family === fontFamily);
  }

  private appendCustomFont(font: IFontModel) {
    const fontFaces = FontModelUtil.makeFontFaces(font);

    const node = document.createElement('style');
    node.innerHTML = fontFaces;
    document.body.appendChild(node);

    return true;
  }

  private watchFontLoading(
    font: IFontModel,
    provider: string,
  ): Observable<string> {
    const families = this.getFontFamilies(font);
    const sourcesQuantity = families.split(',').length;
    let finishedSourceCounter = 0;
    return new Observable((observer: Observer<string>) => {
      globalThis.WebFont.load({
        timeout: 10000,
        fontactive: (loadedFamilyName, fvd) => {
          finishedSourceCounter++;
          if (
            loadedFamilyName === font.family &&
            finishedSourceCounter === sourcesQuantity
          ) {
            observer.next(font.family);
            observer.complete();
          }
        },
        fontinactive: err => {
          observer.error(err);
        },
        [provider]: {
          families: [families],
        },
      });
    });
  }

  private getFontFamilies(font: IFontModel): string {
    return `${font.family}:${this.formatWebFontFontVariations(font)}`;
  }

  private formatWebFontFontVariations(font: IFontModel): string {
    let fontOptions = '';

    font.options.forEach((value: IFontOptions, index: number) => {
      fontOptions = fontOptions + value.weight;
      if (index < font.options.length - 1) {
        fontOptions += ',';
      }
    });

    return fontOptions;
  }

  private fetchGoogleFontFaces(font: IFontModel): Promise<string> {
    const families = this.getFontFamilies(font);
    const googleUrl = `https://fonts.googleapis.com/css?family=${families}`;
    return fetch(googleUrl).then(res => res.text());
  }

  private fontFacesURLsToBase64(fontFaces): Promise<string> {
    const fontURLs = fontFaces.match(/https:\/\/[^)]+/g);
    const fontLoadedPromises = fontURLs.map(fontURL => {
      return new Promise((resolve, reject) => {
        fetch(fontURL)
          .then(res => res.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.addEventListener('load', function () {
              fontFaces = fontFaces.replace(fontURL, this.result);
              resolve([fontURL, this.result]);
            });
            reader.readAsDataURL(blob);
          })
          .catch(reject);
      });
    });
    return Promise.all(fontLoadedPromises).then(() => {
      return fontFaces;
    });
  }

  /**
   * Busca as fontes upadas pelos usuarios.
   */
  private async _getTypographyFromUser() {
    const fonts = await this._fontRepository.findByLoggedUser();
    this._concatFonts(fonts, 'userFonts');
    this._concatFonts(fonts, 'allFonts');
    this.userFontsObservable.next(this.collectionsFonts.userFonts);
    this.allFontsObserver.next(this.collectionsFonts.allFonts);
    // Depois de armazenar as fonts na collection de fonts local, buscamos aquelas que são favoritas.
    await this._getFavoriteTypography();
  }

  /**
   * Lista todas as fontes favoritas e atualiza na collection de fonts local.
   */
  private async _getFavoriteTypography() {
    const favoriteList: any = await this._traktoApiService.executeGet('editor/typography/favorite');
    this._updateFavoriteFonts(favoriteList?.favorites || [])
  }

  /**
   * Adiciona a fonte a lista de favoritos e atualiza na collection de fonts local.
   * @param font : ;
   */
  public async favoriteTypography(font) {
    const body = {
      font_id: font?.id,
      font_family: font?.family,
      provider: font?.provider,
    };
    this._updateFavoriteFonts([font]);
    await this._traktoApiService.executePost('editor/typography/favorite', body);
  }

  /**
   * Remove a fonte da lista de favoritos e atualiza na collection de fonts local.
   * @param font : ;
   */
  public async deleteFavoriteTypography(font) {
    this._updateFavoriteFonts([{ ...font }], false);
    await this._traktoApiService.executeDelete(`editor/typography/favorite/${font?.id}`);
  }

  /**
   * Função de upload de fonts para a API.
   * @param files Arquivos ttf ou otfs
   * @returns Success or Failure
   */
  public async uploadFonts(files: File[]): Promise<any> {
    const fonts = await this._fontRepository.uploadFonts(files);
    fonts.forEach(font => this.downloadFont(font).toPromise());

    this._concatFonts(fonts, 'userFonts');
    this._concatFonts(fonts, 'allFonts');

    this.userFontsObservable.next(this.collectionsFonts.userFonts);
    this.allFontsObserver.next(this.collectionsFonts.allFonts);

    return fonts;
  }

  /**
   * Deleta a fonte do usuario
   * @param font : ;
   */
  public async deleteUserTypography(font: IFontModel) {
    const indexAll = this.collectionsFonts.allFonts.findIndex(
      item => item?.id === font?.id,
    );
    if (indexAll !== -1) {
      this.collectionsFonts.allFonts.splice(indexAll, 1);
    }

    const indexUser = this.collectionsFonts.userFonts.findIndex(
      item => item?.id === font?.id,
    );
    if (indexUser !== -1) {
      this.collectionsFonts.userFonts.splice(indexUser, 1);
    }
    this.allFontsObserver.next(this.collectionsFonts.allFonts);
    this.userFontsObservable.next(this.collectionsFonts.userFonts);
    await this._traktoApiService.executeDelete(`editor/typography/from_user/${font?.id}`);
  }


  /**
   * Essa função atualiza ou concatena as fontes na collection de fonts local.
   * Impedindo duplicidade ao atulizar metodos.
   * @param fonts Array de fontes
   * @param collection Collection de fonts a ser atualizada
   */
  private _concatFonts(
    fonts: IFontModel[],
    collection: 'userFonts' | 'allFonts' | 'traktoFonts',
  ) {
    fonts.forEach((font: IFontModel) => {
      const index = this.collectionsFonts[collection].findIndex(
        item => item.family === font.family,
      );
      if (index === -1) {
        this.collectionsFonts[collection] = [
          ...this.collectionsFonts[collection],
          ...[font],
        ];
      } else {
        this.collectionsFonts[collection][index] = font;
      }
    });
  }

  /**
   * Essa função atualiza a collection de fonts local de fonts, atualizando o metodo 'is_favorite'
   * @param favorites Lista de Fontes Favoritas
   * @param is_favorite Boolean para definir o novo estado favorito da fonte
   */
  private _updateFavoriteFonts(favorites: any[], is_favorite = true) {
    favorites.forEach((item: any) => {
      const collection =
        item?.provider === 'google' || item?.provider === 'trakto'
          ? 'traktoFonts'
          : 'userFonts';
      const array = this.collectionsFonts[collection];
      const index = array.findIndex(obj => obj.id === item.id);
      if (index !== -1) {
        array[index].is_favorite = is_favorite;
      }
      this._concatFonts([{ ...item, is_favorite }], 'allFonts');
    });
    this.allFontsObserver.next(this.collectionsFonts.allFonts);
  }

}
