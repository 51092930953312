<div class="panel-grid">
  <trakto-panel-option-mobile
    image="panel-add"
    label="Adicionar"
    (click)="openPanel('add-elements')"
  ></trakto-panel-option-mobile>
  <trakto-panel-option-mobile
    class="page-counter"
    image="panel-pages"
    [label]="pagesLabel"
    (click)="openPanel('page')"
  >
    <span class="page-counter-label"
    [class.more-than-10]="pages > 9">
      {{ pages }}
    </span>
  </trakto-panel-option-mobile>
  <trakto-panel-option-mobile
    image="links"
    label="Trakto Link"
    (click)="openPanel('trakto-link')"
  ></trakto-panel-option-mobile>
  <trakto-panel-option-mobile
    image="panel-bella"
    label="Bella"
    [disabled]="true"
  ></trakto-panel-option-mobile>
</div>
