<div
  class="trakto-toolbar"
  [ngClass]="{ 'trakto-toolbar--is_banner_visible': showBannerToUser }"
>
  <button
    id="toolbar-button-text"
    *ngIf="textElementIsAvailable"
    class="trakto-toolbar__button --color-title"
    (click)="createAndAddTextElement()"
  >
    <i class="trakto-icon-tkt-texto"></i>
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.text.title' | translate
    }}</span>
  </button>

  <button
    id="toolbar-button-image"
    *ngIf="imageElementIsAvaliable"
    class="trakto-toolbar__button --color-image"
    (click)="
      openModal(
        'trakto-modal-image',
        highlightElementId,
        'toolbar-button-image'
      )
    "
  >
    <i class="trakto-icon-tkt-imagem"></i>
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.image.title' | translate
    }}</span>
  </button>

  <button
    *ngIf="gifElementIsAvailable"
    id="toolbar-button-gif"
    class="trakto-toolbar__button --color-gif"
    (click)="
      openModal(
        'trakto-modal-sticker',
        highlightElementId,
        'toolbar-button-gif'
      )
    "
    traktoBadge="Novo"
    size="small"
  >
    <!-- <i class="trakto-icon-gif-kml"></i> -->
    <img src="assets/icons/tkt-gif.svg" alt="Gif" />
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.gif.title' | translate
    }}</span>
  </button>

  <button
    (click)="addYoutubeElement()"
    *ngIf="videoElementIsAvailable"
    class="trakto-toolbar__button --color-video"
  >
    <i class="trakto-icon-tkt-video"></i>
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.video.title' | translate
    }}</span>
  </button>

  <button
    id="toolbar-button-shape"
    *ngIf="shapeElementIsAvailable"
    class="trakto-toolbar__button --color-shape"
    (click)="openElementsPanel('shape')"
  >
    <i class="trakto-icon-tkt-formas"></i>
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.shape.title' | translate
    }}</span>
  </button>

  <button
    id="toolbar-button-emoji"
    *ngIf="iconElementIsAvailable"
    class="trakto-toolbar__button --color-emoji"
    (click)="openElementsPanel('emoji')"
  >
    <i class="trakto-icon-tkt-icone"></i>
    <span class="trakto-toolbar__tooltip">{{
      'toolbar.icon.title' | translate
    }}</span>
  </button>

  <input id="auxiliary-field" class="trakto-toolbar__hotkey" readonly />
</div>

<!-- Shape Modal -->
<trakto-modal
  id="trakto-modal-shape"
  [selectedElement]="highlightElementId"
  (onMaximize)="setConfigColumnGap(10, 10)"
  (onMinimize)="setConfigColumnGap(6, 10)"
  (onOpen)="disableHotkeys(); setConfigColumnGap(6, 10)"
  (onClose)="enableHotkeys(); onRemoveHighlight()"
>
  <div side-nav>
    <trakto-modal-menu
      [dataProvider]="menuProviderShape"
      (onChange)="setDataShapeCategory($event.value)"
      [selected]="dataCategoryShape"
    >
    </trakto-modal-menu>
  </div>

  <div body-header>
    <trakto-input
      #searchShape
      *ngIf="hasPermissionBySearch.shape"
      id="modal-shape-input"
      name="name-shape-input"
      prefix="trakto-icon-search"
      (onKeyChange)="setDataShapeCategory($event)"
      placeholder="{{ 'property_panel.shape.modal.search' | translate }}"
    >
      <i
        class="trakto-icon-close clean-input"
        *ngIf="searchShape.value.length > 0"
        (click)="
          searchShape.value = ''; setDataShapeCategory(searchShape.value)
        "
      >
      </i>
    </trakto-input>
  </div>

  <div
    class="trakto-modal__wrapper"
    body
    [traktoLoader]="false"
    traktoLoaderMessage="{{ 'toolbar.shape.search_image' | translate }}"
  >
    <div
      *ngIf="loadingContent && !shapesDataProvider?.hasNextPage"
      class="item"
    >
      <div *ngFor="let item of [0, 1, 3, 4, 5, 6, 7, 8, 9, 10]">
        <ngx-skeleton-loader
          count="10"
          [ngStyle]="{
            display: 'grid',
            'grid-gap': '10px',
            'grid-template-columns': '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
          }"
          [theme]="{
            'border-radius': '4px',
            height: '100px',
            width: '100%',
            'max-width': '100px',
            'margin-right': '5px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <trakto-grid-flex
      [hidden]="shapesDataProvider?.list.length === 0 && !loadingContent"
    >
      <trakto-item-renderer
        *ngFor="let item of shapesDataProvider?.list"
        [(columns)]="modalColumns"
        [(gap)]="modalGap"
        [item]="item"
        (onClick)="closeModal('trakto-modal-shape', $event)"
      >
      </trakto-item-renderer>
    </trakto-grid-flex>

    <div
      actions
      [style.marginTop.px]="modalGap"
      [style.marginBottom.px]="modalGap"
    >
      <trakto-button
        button--fill
        button--loader
        button--primary
        [isLoading]="loadingContent"
        *ngIf="
          shapesDataProvider?.list.length &&
          shapesDataProvider.hasNextPage === true
        "
        (onClick)="setShapeData(dataCategoryShape, false)"
      >
        {{ 'property_panel.general.load_more_modal' | translate }}
      </trakto-button>
    </div>

    <trakto-empty
      *ngIf="shapesDataProvider?.list.length === 0 && !loadingContent"
      image="/assets/images/no-results.svg"
      [margin]="35"
      [style.marginTop.px]="'30'"
      title="{{ 'property_panel.general.empty_results' | translate }}"
      description="{{
        'property_panel.general.description_results' | translate
      }}"
    >
    </trakto-empty>
  </div>
</trakto-modal>

<!-- Image Modal -->
<trakto-modal
  id="trakto-modal-image"
  [selectedElement]="highlightElementId"
  (onMaximize)="setConfigColumnGap(3, 10)"
  (onMinimize)="setConfigColumnGap(2, 10)"
  (onOpen)="disableHotkeys(); setConfigColumnGap(2, 10)"
  (onClose)="enableHotkeys(); onRemoveHighlight()"
>
  <div side-nav>
    <trakto-modal-menu
      [dataProvider]="menuProviderImage"
      (onChange)="setDataImageCategory($event.value)"
      [selected]="dataCategoryImage"
    >
      <trakto-input-file
        id="upload-image"
        *ngIf="hasUser"
        [disabled]="!hasUser"
        (onChange)="onInputFileChange($event)"
        mimes="image/png,image/jpeg"
      >
        <i class="trakto-icon-upload"></i>
        {{ 'toolbar.image.modal.upload_button' | translate }}
      </trakto-input-file>
    </trakto-modal-menu>
  </div>

  <div body-header class="flex-center flex-center-vertical">
    <trakto-input
      *ngIf="hasPermissionBySearch.image"
      #searchImage
      id="modal-image-input"
      name="name-image-input"
      prefix="trakto-icon-search"
      (onKeyChange)="setDataImageCategory($event)"
      placeholder="{{ 'property_panel.image.modal.search' | translate }}"
    >
      <i
        class="trakto-icon-close clean-input"
        *ngIf="searchImage.value.length > 0"
        (click)="resetSearch(searchImage)"
      >
      </i>
    </trakto-input>
  </div>

  <div
    body
    class="trakto-modal__wrapper"
    [traktoLoader]="false"
    traktoLoaderMessage="{{ 'toolbar.image.search_image' | translate }}"
  >
    <div
      class="trakto-tags"
      *ngIf="
        !loadingContent &&
        modalTags?.length > 0 &&
        isCategory(dataCategoryImage)
      "
    >
      <button
        class="trakto-tags__item"
        *ngFor="let tag of modalTags"
        (click)="setDataImageCategory(tag.name)"
      >
        {{ tag.name }}
      </button>
    </div>
    <div *ngIf="loadingContent" class="trakto-tags">
      <div *ngFor="let item of [0]">
        <ngx-skeleton-loader
          count="5"
          [theme]="{
            'border-radius': '14px',
            height: '29px',
            width: '73px',
            'max-width': '100%',
            'margin-right': '10px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <div *ngIf="loadingContent" class="item">
      <div *ngFor="let item of [0, 1, 3]">
        <ngx-skeleton-loader
          count="3"
          [ngStyle]="{
            display: 'grid',
            'grid-gap': '10px',
            'grid-template-columns': '1fr 1fr 1fr'
          }"
          [theme]="{
            'border-radius': '4px',
            height: '274px',
            width: '100%',
            'max-width': '365px',
            'margin-right': '10px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <trakto-grid-layout
      #gridImage
      [hidden]="imagesDataProvider?.list.length === 0 && !loadingContent"
      [gap]="modalGap"
      [columns]="modalColumns"
    >
      <trakto-media
        content
        *ngFor="let item of imagesDataProvider?.list"
        [item]="item"
        [gap]="modalGap"
        (onClick)="closeModal('trakto-modal-image', $event)"
        (onRemoveImage)="removeImage($event)"
      >
      </trakto-media>

      <div actions [style.marginTop.px]="modalGap">
        <trakto-button
          button--fill
          button--loader
          button--primary
          *ngIf="
            imagesDataProvider?.list.length && imagesDataProvider?.hasNextPage
          "
          [isLoading]="loadingContent"
          (onClick)="loadMoreImages(dataCategoryImage)"
        >
          {{ 'property_panel.general.load_more_modal' | translate }}
        </trakto-button>
      </div>
    </trakto-grid-layout>

    <ng-container
      *ngIf="imagesDataProvider?.list.length === 0 && !loadingContent"
    >
      <trakto-empty
        *ngIf="isCategory()"
        image="/assets/images/no-results.svg"
        [margin]="35"
        [style.marginTop.px]="'30'"
        title="{{ 'property_panel.general.empty_results' | translate }}"
        description="{{
          'property_panel.general.description_results' | translate
        }}"
      >
      </trakto-empty>

      <trakto-empty
        *ngIf="!isCategory()"
        image="/assets/images/empty-gallery.svg"
        title="{{ 'toolbar.modal.empty_noncategory.title' | translate }}"
        description="{{
          'toolbar.modal.empty_noncategory.description' | translate
        }}"
      >
      </trakto-empty>
    </ng-container>
  </div>
</trakto-modal>

<!-- Sticker modal -->
<trakto-modal
  id="trakto-modal-sticker"
  [selectedElement]="highlightElementId"
  [customModal]="customModal"
  (onMaximize)="setConfigColumnGap(5, 10)"
  (onMinimize)="setConfigColumnGap(2, 10)"
  (onOpen)="
    disableHotkeys(); setConfigColumnGap(2, 10); toggleCustomModal(true)
  "
  (onClose)="enableHotkeys(); onRemoveHighlight(); toggleCustomModal(false)"
>
  <div side-nav>
    <trakto-modal-menu
      [customModal]="customModal"
      [dataProvider]="isStickerOrGifs ? menuProviderGif : menuProviderSticker"
      (onChange)="
        isStickerOrGifs
          ? setDataGifCategory($event.value)
          : setDataStickerCategory($event.value)
      "
      [selected]="isStickerOrGifs ? dataCategoryGif : dataCategorySticker"
    >
      <trakto-input-file
        id="upload-sticker"
        *ngIf="hasUser"
        (click)="checkUser()"
        (onChange)="onInputFileChange($event)"
        mimes="image/gif"
      >
        <div class="trakto-modal-sticker__upload">
          <i class="trakto-icon-tkt-upload"></i>
          <p>{{ 'toolbar.image.modal.upload_button' | translate }}</p>
        </div>
      </trakto-input-file>
    </trakto-modal-menu>
  </div>

  <div class="trakto-modal-grid__content" body-header>
    <trakto-button-toggle
      (changed)="toggleStickerOrGif($event)"
    ></trakto-button-toggle>

    <div class="trakto-modal-grid__input">
      <trakto-input
        *ngIf="hasPermissionBySearch.sticker"
        #searchGif
        #searchSticker
        id="modal-sticker-input"
        name="name-sticker-input"
        prefix="trakto-icon-tkt-search"
        (onKeyChange)="
          isStickerOrGifs
            ? setDataGifCategory($event)
            : setDataStickerCategory($event)
        "
        placeholder="{{ 'property_panel.sticker.modal.search' | translate }}"
      >
        <i
          class="trakto-icon-close clean-input"
          *ngIf="searchSticker.value.length > 0"
          (click)="
            searchSticker.value = '';
            setDataStickerCategory(searchSticker.value)
          "
        >
        </i>
      </trakto-input>
    </div>
    <div class="trakto-modal-grid__brad-image">
      <img src="assets/images/logogiphy.svg" alt="Giphy" />
    </div>
  </div>

  <div
    body
    class="trakto-modal__wrapper"
    [traktoLoader]="false"
    traktoLoaderMessage="{{ 'toolbar.sticker.search_image' | translate }}"
  >
    <div *ngIf="loadingContent" class="item">
      <div *ngFor="let item of [0, 1, 3, 4, 5]">
        <ngx-skeleton-loader
          [class.--custom-modal]="customModal"
          count="5"
          [ngStyle]="{
            display: 'grid',
            'grid-gap': '10px',
            'grid-template-columns': '1fr 1fr 1fr 1fr 1fr'
          }"
          [theme]="{
            'background-color': '0 0/200px 100% no-repeat #071e49',
            'border-radius': '4px',
            height: '200px',
            width: '100%',
            'max-width': '218px',
            'margin-right': '10px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <trakto-grid-layout
      #gridGif
      [gap]="modalGap"
      [columns]="modalColumns"
      [hidden]="
        isStickerOrGifs
          ? gifsDataProvider?.list.length === 0 && !loadingContent
          : stickersDataProvider?.list.length === 0 && !loadingContent
      "
    >
      <trakto-media
        [class.--custom-modal]="customModal"
        content
        *ngFor="
          let item of isStickerOrGifs
            ? gifsDataProvider?.list
            : stickersDataProvider?.list
        "
        [item]="item"
        [customModal]="customModal"
        [gap]="modalGap"
        (onClick)="closeModal('trakto-modal-sticker', $event)"
      >
      </trakto-media>

      <div actions [style.marginTop.px]="modalGap">
        <trakto-button
          button--fill
          button--loader
          button--primary
          *ngIf="
            isStickerOrGifs
              ? gifsDataProvider?.list.length && gifsDataProvider?.hasNextPage
              : stickersDataProvider?.list.length &&
                stickersDataProvider?.hasNextPage
          "
          [isLoading]="loadingContent"
          (onClick)="
            isStickerOrGifs
              ? setGifData(dataCategoryGif)
              : setStickerData(dataCategorySticker)
          "
        >
          {{ 'property_panel.general.load_more_modal' | translate }}
        </trakto-button>
      </div>
    </trakto-grid-layout>

    <ng-container
      *ngIf="
        isStickerOrGifs
          ? gifsDataProvider?.list.length === 0 && !loadingContent
          : stickersDataProvider?.list.length === 0 && !loadingContent
      "
    >
      <trakto-empty
        *ngIf="isCategory()"
        image="/assets/images/no-results.svg"
        [margin]="35"
        [style.marginTop.px]="'30'"
        title="{{ 'property_panel.general.empty_results' | translate }}"
        description="{{
          'property_panel.general.description_results' | translate
        }}"
      >
      </trakto-empty>

      <trakto-empty
        *ngIf="!isCategory()"
        image="/assets/images/empty-gallery.svg"
        title="{{ 'toolbar.modal.empty_noncategory.title' | translate }}"
        description="{{
          'toolbar.modal.empty_noncategory.description' | translate
        }}"
      >
      </trakto-empty>
    </ng-container>
  </div>
</trakto-modal>

<!-- Sticker modal -->
<trakto-modal
  id="trakto-modal-gif"
  [selectedElement]="highlightElementId"
  (onMaximize)="setConfigColumnGap(3, 10)"
  (onMinimize)="setConfigColumnGap(2, 10)"
  (onOpen)="disableHotkeys(); setConfigColumnGap(2, 10)"
  (onClose)="enableHotkeys(); onRemoveHighlight()"
>
  <div side-nav>
    <trakto-modal-menu
      [dataProvider]="menuProviderGif"
      (onChange)="setDataGifCategory($event.value)"
      [selected]="dataCategoryGif"
    >
      <trakto-input-file
        id="upload-gif"
        *ngIf="hasUser"
        (click)="checkUser()"
        (onChange)="onInputFileChange($event, true)"
        mimes="image/gif"
      >
        <i class="trakto-icon-upload"></i> Upload
      </trakto-input-file>
    </trakto-modal-menu>
  </div>

  <div class="trakto-modal-grid__content" body-header>
    <div class="trakto-modal-grid__input">
      <trakto-input
        *ngIf="hasPermissionBySearch.gif"
        #searchGif
        id="modal-gif-input"
        name="name-gif-input"
        prefix="trakto-icon-search"
        (onKeyChange)="setDataGifCategory($event)"
        placeholder="{{ 'property_panel.gif.modal.search' | translate }}"
      >
        <i
          class="trakto-icon-close clean-input"
          *ngIf="searchGif.value.length > 0"
          (click)="searchGif.value = ''; setDataGifCategory(searchGif.value)"
        >
        </i>
      </trakto-input>
    </div>
    <div class="trakto-modal-grid__brad-image">
      <img src="assets/images/brand-giphy.svg" alt="Giphy" />
    </div>
  </div>

  <div
    body
    class="trakto-modal__wrapper"
    [traktoLoader]="false"
    traktoLoaderMessage="{{ 'toolbar.gif.search_image' | translate }}"
  >
    <div *ngIf="loadingContent" class="item">
      <div *ngFor="let item of [0, 1, 3]">
        <ngx-skeleton-loader
          count="3"
          [ngStyle]="{
            display: 'grid',
            'grid-gap': '10px',
            'grid-template-columns': '1fr 1fr 1fr'
          }"
          [theme]="{
            'border-radius': '3px',
            height: '368px',
            width: '100%',
            'max-width': '674px',
            'margin-right': '10px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <trakto-grid-layout
      #gridGif
      [hidden]="gifsDataProvider?.list.length === 0 && !loadingContent"
      [gap]="modalGap"
      [columns]="modalColumns"
    >
      <trakto-media
        content
        *ngFor="let item of gifsDataProvider?.list"
        [item]="item"
        [gap]="modalGap"
        (onClick)="closeModal('trakto-modal-gif', $event)"
      >
      </trakto-media>

      <div actions [style.marginTop.px]="modalGap">
        <trakto-button
          button--fill
          button--loader
          button--primary
          *ngIf="gifsDataProvider?.list.length && gifsDataProvider?.hasNextPage"
          [isLoading]="loadingContent"
          (onClick)="setGifData(dataCategoryGif)"
        >
          {{ 'property_panel.general.load_more_modal' | translate }}
        </trakto-button>
      </div>
    </trakto-grid-layout>

    <ng-container
      *ngIf="gifsDataProvider?.list.length === 0 && !loadingContent"
    >
      <trakto-empty
        *ngIf="isCategory()"
        image="/assets/images/no-results.svg"
        [margin]="35"
        [style.marginTop.px]="'30'"
        title="{{ 'property_panel.general.empty_results' | translate }}"
        description="{{
          'property_panel.general.description_results' | translate
        }}"
      >
      </trakto-empty>

      <trakto-empty
        *ngIf="!isCategory()"
        image="/assets/images/empty-gallery.svg"
        title="{{ 'toolbar.modal.empty_noncategory.title' | translate }}"
        description="{{
          'toolbar.modal.empty_noncategory.description' | translate
        }}"
      >
      </trakto-empty>
    </ng-container>
  </div>
</trakto-modal>

<!-- Emoji Modal -->
<trakto-modal
  id="trakto-modal-emoji"
  [selectedElement]="highlightElementId"
  (onMaximize)="setConfigColumnGap(10, 10)"
  (onMinimize)="setConfigColumnGap(8, 10)"
  (onOpen)="disableHotkeys(); setConfigColumnGap(8, 10)"
  (onClose)="enableHotkeys(); onRemoveHighlight()"
>
  <div side-nav>
    <trakto-modal-menu
      [dataProvider]="menuProviderEmoji"
      (onChange)="setDataEmojiCategory($event.value)"
      [selected]="dataCategoryEmoji"
    >
    </trakto-modal-menu>
  </div>

  <div body-header>
    <trakto-input
      *ngIf="hasPermissionBySearch.emoji"
      #searchEmoji
      id="modal-emoji-input"
      name="name-emoji-input"
      prefix="trakto-icon-search"
      (onKeyChange)="setDataEmojiCategory($event)"
      placeholder="{{ 'property_panel.icon.modal.search' | translate }}"
    >
      <i
        class="trakto-icon-close clean-input"
        *ngIf="searchEmoji.value.length > 0"
        (click)="searchEmoji.value = ''; setDataEmojiCategory('favorite')"
      >
      </i>
    </trakto-input>
  </div>

  <div
    body
    class="trakto-modal__wrapper"
    [traktoLoader]="false"
    traktoLoaderMessage="{{ 'toolbar.icon.search_image' | translate }}"
  >
    <div
      *ngIf="loadingContent && !emojisDataProvider?.hasNextPage"
      class="item"
    >
      <div *ngFor="let item of [0, 1, 3, 4, 5, 6, 7, 8, 9, 10]">
        <ngx-skeleton-loader
          count="10"
          [ngStyle]="{
            display: 'grid',
            'grid-gap': '10px',
            'grid-template-columns': '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
          }"
          [theme]="{
            'border-radius': '4px',
            height: '100px',
            width: '100%',
            'max-width': '100px',
            'margin-right': '5px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <trakto-grid-flex [hidden]="modalDataEmoji.length === 0 && !loadingContent">
      <trakto-item-renderer
        *ngFor="let item of modalDataEmoji"
        [(gap)]="modalGap"
        [(columns)]="modalColumns"
        [item]="item"
        (onClick)="closeModal('trakto-modal-emoji', $event)"
      >
      </trakto-item-renderer>
    </trakto-grid-flex>

    <trakto-grid-flex
      [hidden]="emojisDataProvider?.list.length === 0 && !loadingContent"
    >
      <trakto-item-renderer
        *ngFor="let item of emojisDataProvider?.list"
        [(gap)]="modalGap"
        [(columns)]="modalColumns"
        [item]="item"
        (onClick)="closeModal('trakto-modal-emoji', $event)"
      >
      </trakto-item-renderer>
    </trakto-grid-flex>

    <div
      actions
      [style.marginTop.px]="modalGap"
      [style.marginBottom.px]="modalGap"
    >
      <trakto-button
        button--fill
        button--loader
        button--primary
        [isLoading]="loadingContent"
        *ngIf="emojisDataProvider?.hasNextPage === true"
        (onClick)="setEmojiData(dataCategoryEmoji, false)"
      >
        {{ 'property_panel.general.load_more_modal' | translate }}
      </trakto-button>
    </div>

    <trakto-empty
      *ngIf="
        modalDataEmoji.length === 0 &&
        emojisDataProvider &&
        emojisDataProvider.list.length === 0 &&
        !loadingContent
      "
      image="/assets/images/no-results.svg"
      [margin]="35"
      [style.marginTop.px]="'30'"
      title="{{ 'property_panel.general.empty_results' | translate }}"
      description="{{
        'property_panel.general.description_results' | translate
      }}"
    >
    </trakto-empty>
  </div>
</trakto-modal>
