import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { ElementsGalleryService } from '@app/shared/elements-gallery/elements-gallery.service';
import { Subscription } from 'rxjs';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';
import { ILocaleTag } from '@app/shared/elements/locale-tag.entity';
import { getColor } from '@app/editor/shared/colors.util';
import { ElementsListComponent } from '../elements-list/elements-list.component';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { ISearchElementType } from '@app/editor/types/elements/element-search.type';

export enum enumTabs {
  SHAPES = 'shape',
  ICONS = 'icon',
  ILLUSTRATIONS = 'illustration',
}
@Component({
  selector: 'trakto-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: [
    './search-results.component.scss',
    '../tags-list/tags-list.component.scss',
  ],
})
export class SearchResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isActive: boolean = false;
  @Input() currentSearch: ISearchEvent;

  @Output() requestClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assetSelected: EventEmitter<IElementNewAPI> =
    new EventEmitter<IElementNewAPI>();

  @ViewChild(ElementsListComponent)
  private _elementsListComponent: ElementsListComponent;

  public enumTabs = enumTabs;
  public onExpandPanelChange: Subscription;
  public isPanelExpanded = false;
  public currentTag: ILocaleTag = undefined;
  public searchType = SearchTypeEnum.MAIN;
  public universeTags: ILocaleTag[];

  constructor(
    private _panelStackService: PanelStackService,
    private _elementsGalleryService: ElementsGalleryService,
  ) {}

  /**
   * Modifica valor da variável isPanelExpanded
   * quando painel é expandido ou retraído
   */
  ngOnInit(): void {
    this.onExpandPanelChange =
      this._panelStackService.onExpandPanelChange.subscribe(isExpanded => {
        this.isPanelExpanded = isExpanded;
      });
  }

  /**
   * Quando o painel é exibido, os elementos pesquisados
   * são filtrados de acordo com a guia a ser exibida.
   * Também verifica a tag pesquisada anteriormente,
   * e a aplica na pesquisa atual
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this._elementsGalleryService.compareChanges(changes.isActive)) {
      //painel ativo
      this.universeTags = this._elementsGalleryService.findDefaultTags();
    }
  }

  ngOnDestroy(): void {
    this.onExpandPanelChange?.unsubscribe();
  }

  filterTags(tag: ILocaleTag = null) {

    if (tag?.id === this.currentTag?.id || tag === null) {
      this.currentTag = null;
    } else {
      this.currentTag = tag;
    }

    let elementType: ISearchElementType = ISearchElementType.SVG;
    switch (tag.name) {
      case enumTabs.ILLUSTRATIONS: elementType = ISearchElementType.SVG; break;
      case enumTabs.ICONS: elementType = ISearchElementType.SVG; break;
      case enumTabs.SHAPES: elementType = ISearchElementType.PATH; break;
      default: elementType = ISearchElementType.SVG; break;
    }

    this.currentSearch = {
      ...this.currentSearch,
      elementType,
    };

    this._elementsGalleryService.setElementsPanelStore(this.currentSearch);
    return true;
  }

  /**
   * Esconde o painel de resultados de pesquisa.
   */
  hideSearch() {
    this.requestClose.emit(true);
  }

  /**
   * Insere o elemento desejado no design.
   *
   * @param element any - Elemento a ser inserido
   */
  emitElement(element: IElementNewAPI) {
    this.assetSelected.emit(element);
  }

  getColor(idx: number): string {
    return getColor(idx);
  }

  scrollToNextPage() {
    this._elementsListComponent.scrollToNextPage();
  }
}
