import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DrawableComponent } from '@editor/components/preview-2/svg-editor/drawables-container/drawables/drawable.component';
import { TextElementService } from '@services/text-element.service';
import { TextService } from '@services/text.service';
import { ITextElementModel } from '@trakto/models';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[drawable-text]',
  template: `
    <svg:path
      [attr.d]="element.supportPath"
      [attr.fill]="element['backgroundColor'] || 'transparent'"
      [attr.transform]="element.finalMatrixStr"
    ></svg:path>
    <svg:foreignObject
      [attr.transform]="element.finalMatrixStr"
      [attr.y]="marginTop"
      [attr.width]="element.supportWidth"
      [attr.height]="height"
      [attr.opacity]="element.opacity"
      style="overflow-wrap: break-word; white-space: pre-wrap; overflow: visible;"
    >
      <xhtml:div
        #textContainer
        [innerHTML]="html | safeHtml"
        [style.font-size.pt]="element['fontSize']"
        [class.hidden-text]="hiddenText"
        class="ql-editor"
        [style]="'--bullet-color: ' + element['color']"
      ></xhtml:div>
    </svg:foreignObject>
  `,
  styles: [
    `
      div ::ng-deep * {
        font-size: inherit !important;
      }
    `,
    `
      div.hidden-text ::ng-deep * {
        color: transparent !important;
      }
    `,
  ],
})
export class DrawableTextComponent
  extends DrawableComponent
  implements OnChanges {
  @Input()
  public ref1: any;

  @Input()
  public hiddenText = false;

  browser: string;

  html: string;
  fontWeight: string;
  fontStyle: string;
  marginTop: number;
  height: number;

  get clipId() {
    return `${this.element.id}__text_mask`
  }

  constructor(
    private _el: ElementRef,
    private _textElementService: TextElementService,
    private _textService: TextService,
    private _deviceService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!this.element) {
      return;
    }

    const textElement = this.element as ITextElementModel;

    if (changes.ref1) {
      this.marginTop = -this._textService.getFontHalfLeading(textElement);
      this.height = this.element.supportHeight + 2 * -this.marginTop;
    }

    if (!changes.ref && !changes.hiddenText) {
      return;
    }
    const decodedFontStyle = this._textElementService.getFontStyle(textElement);
    this.fontWeight = decodedFontStyle.weight;
    this.fontStyle = decodedFontStyle.style;
    this.browser = this._deviceService.browser;
    this.html = this._textService.getParsedHTML(textElement, false);
  }

}