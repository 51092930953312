import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { NgColorModel } from '@shared/color/classes/color.model';
import { ColorService } from '../../../services/color.service';
import { HexadecimalValidator } from '../../../validators/color.validator';
import { GradientSafePipe } from './../../../../color/pipes/gradient-safe.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'trakto-colorpicker-input',
  templateUrl: './colorpicker-input.component.html',
  styleUrls: ['./colorpicker-input.component.scss'],
})
export class ColorPickerInputComponent implements OnInit, OnDestroy {
  hexaLabelColor: string;
  hexaLabelColorControl: FormControl;

  private _color: NgColorModel;

  @Input()
  set color(value: NgColorModel) {
    this.setColor(value);
  }

  get color(): NgColorModel {
    return this._color;
  }

  @Output()
  onChange: EventEmitter<NgColorModel> = new EventEmitter();
  private _destroy$ = new Subject<void>();

  constructor(
    private _colorService: ColorService,
    private _gradientSafePipe: GradientSafePipe,
  ) {}

  ngOnInit() {
    this._setupFormControl();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private _setupFormControl() {
    const reg = /([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
    const subst = `#$1`;

    // The substituted value will be contained in the result variable

    this.hexaLabelColorControl = new FormControl(this.hexaLabelColor, [
      Validators.required,
      HexadecimalValidator,
    ]);

    this.hexaLabelColorControl.statusChanges.pipe(takeUntil(this._destroy$)).subscribe(status => {
      if (status === 'VALID') {
        this.setColor(new NgColorModel(null, this.hexaLabelColorControl.value));
        this.onChange.emit(this._color);
      } else if (
        this.hexaLabelColorControl.value.indexOf('#') < 0 &&
        this.hexaLabelColorControl.value.length === 6
      ) {
        this.hexaLabelColorControl.setValue(
          this.hexaLabelColorControl.value.replace(reg, subst),
          { emitEvent: true },
        );
      }
    });
  }

  private setColor(color: NgColorModel) {
    if (
      color &&
      color.gradient &&
      typeof color.gradient === 'string' &&
      !color.gradientSafe
    ) {
      color.gradientSafe = this._gradientSafePipe.transform(color.gradient);
    } else if (color) {
      this.hexaLabelColor = this._colorService.getContrastColor(color);
    }

    this._color = color;
  }
}
