<div class="tab" (click)="togglePageGrid()">
  <i
    class="icon"
    [ngClass]="{ 'icon-up': !isPageGridOpen, 'icon-down': isPageGridOpen }"
  ></i>
</div>

<trakto-page-grid
  [isPageGridOpen]="isPageGridOpen"
  [pageView]="pageView"
></trakto-page-grid>

<div class="bottom">
  <div class="options">
    <div class="zoom">
      <i class="icon icon-search"></i>
      <span class="percentage">{{ zoomPercentage }}%</span>
      <trakto-slider
        [min]="10"
        [max]="300"
        [value]="zoomPercentage"
        (onChange)="setZoomPercentage($event)"
      ></trakto-slider>
      <i
        class="icon icon-expand"
        traktoTooltip
        tooltipText="Ajustar à tela"
        tooltipPosition="up"
        (click)="fitPage()"
      ></i>
      <i
        class="icon icon-fullscreen"
        traktoTooltip
        tooltipText="Tela cheia"
        tooltipPosition="up"
        (click)="toggleFullscreen()"
      ></i>
    </div>
    <div class="divider"></div>
    <div class="pages-view">
      <i
        class="icon icon-line-view"
        (click)="setPageView('line')"
        traktoTooltip
        tooltipText="Visualizar páginas em linha"
        tooltipPosition="up"
      ></i>
      <i
        class="icon icon-grid-view"
        (click)="setPageView('grid')"
        traktoTooltip
        tooltipText="Visualizar páginas em grade"
        tooltipPosition="up"
      ></i>
    </div>
  </div>
</div>
