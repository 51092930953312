import { Injectable } from '@angular/core';
import { Theme } from '@trakto/models';
import { TranslateService } from '@ngx-translate/core';
import { TraktoApiService } from "@services/trakto-api.service";
import { UserService } from '@app/editor-v3/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeRepository {

  private _themes: Theme[];

  constructor(
    private _translateService: TranslateService,
    private _traktoApiService: TraktoApiService,
    private _userService: UserService,
  ) {}

  public async findByLoggedUser(): Promise<Theme[]> {
    try {
      if (this._themes) {
        return this._themes;
      }
      const lang = this._translateService.currentLang || 'pt-BR';
      let urlPath = `themes?total_per_page=1000`;

      if (!this._userService.isGlobalAdmin) {
        urlPath += `&products=${this._userService.user.current_profile.product.id}`
      }

      const themes = await this._traktoApiService.executeTemplateApiGet<any>(urlPath);
  
      this._themes = themes.map(theme => ({
        ref: null,
        app_reference: null,
        created_at: theme.created_at,
        icon: null,
        is_deleted: false,
        is_featured: (theme.is_featured === true),
        is_public: true,
        locale: theme.locales,
        products: [],
        titles: null,
        updated_at: theme.created_at,
        user_reference: null,
        title: (theme.titles || []).find((value: any, i: number) => value.iso_locale === lang)?.title || theme.title,
      }));
      return this._themes;
    } catch(error) {
      console.log(error);
      return [];
    }
  }

  // TODO chamar endpoint pra buscar por id. Endpoint ainda não foi criado.
  public async findByApp(id: string): Promise<Theme[]> {
    // return this.findByLoggedUser().then(themes => themes.filter(theme => theme.app_reference?.id === id));
    return this.findByLoggedUser().then(themes => themes);
  }
}
