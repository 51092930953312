import { Injectable } from "@angular/core";
import { DataTracking } from "@trakto/data-tracking";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { FBTraktoTracking } from "../facebook/fbTraktoTracking.analytics";
@Injectable({
    providedIn: 'root',
})
export class TraktoLinksAnalyticsService extends DataTracking {

    constructor(
        private _googleAnalyticsService: GoogleAnalyticsService,
        private _fbTrack: FBTraktoTracking,
    ) {
        super();
    }

    /**
    * traktoLinksOpenend
    */
    public traktoLinksOpenend(value: boolean) {
        //
        if (value) {
            this._googleAnalyticsService.trackEvent(
                'Painel da Trakto Links foi aberto',
                {
                    label: 'O painel de configuração da Trakto Links foi aberto pelo usuário',
                    category: 'Trakto Links',
                    value: 1,
                },
                () => { });
        } else {
            this._googleAnalyticsService.trackEvent(
                'Painel da Trakto Links foi fechado',
                {
                    label: 'O painel de configuração da Trakto Links foi fechado pelo usuário',
                    category: 'Trakto Links',
                    value: 1,
                },
                () => { });
        }
    }

    /**
     * traktoLinksCopiedLink
     */
    public traktoLinksCopiedLink({ linkName }: { linkName: string }) {
        this._googleAnalyticsService.trackEvent(
            'Link copiado',
            {
                label: 'O usuário copiou o link a partir do painel da Trakto Links',
                category: 'Trakto Links',
                value: 1,
            },
            () => { });
    }

    /**
     * traktoLinksEditedOrCreationLink
     */
    public traktoLinksEditedOrCreationLink(payload: {
        old_link_name: string;
        new_link_name: string;
        success: boolean;
        is_edition: boolean;
    }) {
        const { is_edition } = payload;
        if (is_edition) {
            this._googleAnalyticsService.trackEvent(
                'Link foi editado',
                {
                    label: 'O usuário alterou o link a partir do painel da Trakto Links',
                    category: 'Trakto Links',
                    value: 1,
                },
                () => { });
        }
    }

    /**
     * traktoLinksDownloadQR
     */
    public traktoLinksDownloadQR(payload) {
        this._googleAnalyticsService.trackEvent(
            'Baixou o QR Code referente ao link',
            {
                label: 'O usuário realizou o download do QR Code disponibilizado pelo painel da Trakto Links',
                category: 'Trakto Links',
                value: 1,
            },
            () => { });
    }

    public traktoLinksPublished() {
        this._fbTrack.traktoLinksCreated();
        this._googleAnalyticsService.trackEvent(
            'O link da Trakto Links foi publicado',
            {
                label: 'O usuário finalizou a operação de publicação da Trakto Links',
                category: 'Trakto Links',
                value: 1,
            },
            () => { });
    }
}
