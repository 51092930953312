<div class="panel-mobile" [ngStyle]="{ zIndex: 1 }">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-formats.svg"
    label="Todos os formatos"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="grid">
      <div class="format" *ngFor="let parentFormat of formats">
        <div class="format-label">
          <trakto-render-svg-icon
            [ngStyle]="{ width: '16px' }"
            [svg]="parentFormat.icon"
          ></trakto-render-svg-icon>
          <span class="text">{{
            parentFormat.titles ? parentFormat.titles[lang] : parentFormat.title
          }}</span>
        </div>

        <div class="format-grid">
          <ng-container *ngFor="let childFormat of parentFormat.formats">
            <trakto-format-card
              [label]="
                childFormat.titles
                  ? childFormat.titles[lang]
                  : childFormat.title
              "
              [icon]="childFormat.file"
              [width]="68"
              [selected]="pageSelected.format?.id === childFormat.id"
              (click)="selectFormat(childFormat)"
            ></trakto-format-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
