<div id="panel-zero" class="panel">
  <div class="panel-simple">
    <div class="panel-default">
      <div>
        <img
          class="panel-default__image"
          src="./assets/images/welcome-editor.svg"
          [style.marginBottom.px]="-20"
        />

        <h3>Bem-vindo!</h3>
        <p>
          Esse é o <span>PIX</span>, o novo editor do Trakto. Nova galeria,
          novas features e formatos de exportação.
          <strong>Aproveite e seja um dos primeiros a testar!</strong>
        </p>
      </div>
    </div>
  </div>
</div>
