import { createAction, props } from '@ngrx/store';

export const setCurrentStep = createAction(
  '[Stepper] Set Current Step',
  props<{ step: number }>()
);

export const setTemplateType = createAction(
  '[Stepper] Set Template Type',
  props<{ templateType: 'default' | 'magic' | null }>()
);

export const setTemplateSubType = createAction(
  '[Stepper] Set Template SubType',
  props<{ templateSubType: 'upload' | 'trakto' | null }>()
);

export const setTotalSteps = createAction(
  '[Stepper] Set Total Steps',
  props<{ totalSteps: number }>()
);

export const incrementCurrentStep = createAction(
  '[Stepper] Increment Current Step'
);

export const decrementCurrentStep = createAction(
  '[Stepper] Decrement Current Step'
);