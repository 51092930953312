import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  NotificationService
} from '@app/shared/notification/notification.service';
import { HotkeyService } from '@services/hotkeys/hotkeys.service';

import { ImageElementModel, INotificationMessage } from '@trakto/models';
import { isUrlValid } from './link.validator';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-link-website',
  templateUrl: './panel-link-website.component.html',
})
export class PanelLinkWebsiteComponent extends AbstractComponent<ImageElementModel> {
  public form: FormGroup;
  public buttonValidation: boolean;
  public notifyMSGs: INotificationMessage;

  constructor(
    private _formBuilder: FormBuilder,
    private _hotkeyService: HotkeyService,
    private _panelStackService: PanelStackService,
    private _notificationService: NotificationService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super(documentStateManagerService);
    this.notifyMSGs = this._notificationService.notificationsMSGs;
  }

  ngOnInit() {
    super.ngOnInit();
    this.configFormBuild();
  }

  private configFormBuild(): void {
    this.form = this._formBuilder.group({
      link: [null, [Validators.required, this._validateRegex.bind(this)]],
    });
  }

  _validateRegex(url: AbstractControl): any {
    const inputValue: string = !!url.value ? url.value : null;
    if (isUrlValid(inputValue) && inputValue) {
      return null;
    }

    return {
      inputValue: {
        valid: false,
      },
    };
  }

  public handleSubmit(): void {
    const {
      value: { link },
    } = this.form;
    if (!isUrlValid(link)) {
      return;
    }
    this._elementChangeFacadeService.changeElementProperty(
      this.elementSelected,
      'link',
      !this.elementSelected.link && link ? link : null,
    );

    this.messageFeedback();
  }

  public addMaskUrl(url): void {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
      this.form.controls[`link`].setValue(`http://`);
    }
  }

  public messageFeedback(): void {
    if (this.elementSelected.link) {
      this._notificationService.success(this.notifyMSGs.link_added_success, {
        isSimple: false,
        hasIcon: false,
        time: 3000,
      });
    } else {
      this._notificationService.success(
        this.notifyMSGs.link_removed_successfully,
        { isSimple: false, hasIcon: false, time: 2000 },
      );
    }
  }

  public validateUrl(url: string): void {
    isUrlValid(url)
      ? (this.buttonValidation = false)
      : (this.buttonValidation = true);
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public closePanelLink(): void {
    this._panelStackService.pop(500);
  }
}
