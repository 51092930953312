<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-shape.svg"
    label="Ícones"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="search click-ignore">
    <trakto-input
      [value]="searchText"
      [clearIcon]="true"
      placeholder="Pesquise por algo"
      (onChange)="setInputText($event)"
      (onBlur)="setShowSuggestions(false)"
      (onSelect)="selectTag($event)"
    ></trakto-input>
  </div>

  <trakto-tag-list
    [tags]="tags"
    [selected]="filteredText"
    (onSelect)="selectTag($event)"
    class="click-ignore"
  ></trakto-tag-list>

  <trakto-elements-list
    [searchEvent]="search"
  ></trakto-elements-list>
</div>
