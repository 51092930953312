import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-links-qrcode-download',
  templateUrl: './qrcode-download.component.html',
  styleUrls: ['./qrcode-download.component.scss'],
})
export class QRCodeDownloadComponent implements OnInit {
  @Input() profileImage = '';
  @Input() qrcodeLink = '';
  @Input() loading = false;

  @Output()
  public download = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}

  public startDownload(event: MouseEvent) {
    this.download.emit(event);
  }
}
