import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'trakto-panel-header-mobile',
  templateUrl: './panel-header-mobile.component.html',
  styleUrls: ['./panel-header-mobile.component.scss'],
})
export class PanelHeaderMobileComponent implements OnInit {
  @Input() img: string = null;
  @Input() label: string = null;
  @Input() showBackLabel: boolean = false;
  @Input() ignoreBackClick = false;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  emitClose() {
    this.onClose.emit();
  }
}
