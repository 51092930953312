import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IElementDropdown } from '../dropdown';
import { DropdownService } from '../dropdown.service';

import { IButtonConfig } from '../button.model';
import { PlacementEnum } from '../placement.model';

@Component({
  selector: 'trakto-dropdown-menu-header',
  templateUrl: './dropdown-menu-header.component.html',
  styleUrls: ['./dropdown-menu-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownMenuHeaderComponent implements OnInit, IElementDropdown {
  private _title: string;
  private _icon: string;
  private _caret: boolean;
  private _width: number;
  private _placement: PlacementEnum;
  private _button: IButtonConfig;

  public isOpen: boolean;
  public elementNative: any;
  public baseClass: string;

  @Input() public set title(title: string) {
    this._title = title;
  }

  public get title(): string {
    return this._title;
  }

  @Input() public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon(): string {
    return this._icon;
  }

  @Input() public set caret(caret: boolean) {
    this._caret = caret;
  }

  public get caret(): boolean {
    return this._caret === undefined ? true : this._caret;
  }

  @Input() public set placement(placement: PlacementEnum) {
    this._placement = placement;
  }

  public get placement(): PlacementEnum {
    return this._placement || PlacementEnum.right;
  }

  @Input() public set width(width: number) {
    this._width = width;
  }

  public get width(): number {
    return this._width || 160;
  }

  @Input() public set button(button: IButtonConfig) {
    this._button = button;
  }

  public get button(): IButtonConfig {
    return this._button || { classes: ['button--white'] };
  }

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _elementRef: ElementRef,
    private dropdownService: DropdownService,
  ) {
    this.isOpen = false;
    this.baseClass = 'trakto-dropdown-menu-header';
  }

  @HostListener('document:click', ['$event'])
  out(event: Event) {
    if (!this._elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.close();
    }
  }

  ngOnInit() {
    this.elementNative = this._elementRef.nativeElement.children[0];
    this.loadPlacement();
    this.setClass(this.button);
  }

  toggle(): void {
    !this.isOpen ? this.open() : this.close();
  }

  open(): void {
    this.dropdownService.disable();

    this.isOpen = true;
    this.elementNative.classList.add('--active');
    this.onOpen.emit(this.isOpen);

    this.dropdownService.active(this);
  }

  close(): void {
    this.isOpen = false;
    this.elementNative.classList.remove('--active');
    this.onClose.emit(this.isOpen);
  }

  select(): void {
    this.onClick.emit();
    // this.close();
  }

  loadPlacement(): void {
    switch (this.placement) {
      case PlacementEnum.center:
        this.setPlacement(PlacementEnum.center);
        break;
      case PlacementEnum.left:
        this.setPlacement(PlacementEnum.left);
        break;
      default:
        this.setPlacement(PlacementEnum.right);
        break;
    }
  }

  setPlacement(position: PlacementEnum): void {
    this.elementNative.removeAttribute('class');
    this.elementNative.setAttribute('class', `${this.baseClass} --${position}`);
  }

  setClass(button: IButtonConfig): void {
    const element = this._elementRef.nativeElement;
    if (button.classes) {
      element.querySelector('button').removeAttribute('class');
      element
        .querySelector('button')
        .setAttribute('class', `${button.classes[0]}`);
    }
    if (button.icon) {
      element.querySelector('button>i').removeAttribute('class');
      element.querySelector('button>i').setAttribute('class', `${button.icon}`);
    }
  }
}
