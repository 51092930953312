import { Injectable } from '@angular/core';
import { IProducts } from '@trakto/models';
import { TraktoApiService } from '@services/trakto-api.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Product is a "whitelabel" from App. For example: The "Coca-Cola" app has products like "Fanta", "Sprite" and etc.
 */
export class ProductRepository {
  constructor(
    private _traktoApiService: TraktoApiService,
  ) {}

  public async findById(id: string): Promise<IProducts> {
    return this._traktoApiService.executeGet<IProducts>(`product/${id}`);
  }

  public findByAppId(appId: string): Promise<IProducts[]> {
    return this._traktoApiService.executeGet<IProducts[]>(`app/${appId}/products`);
  }
}
