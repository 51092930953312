import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '@app/editor-v3/services/DocumentService';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { IDocumentModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-page-grid',
  templateUrl: './page-grid.component.html',
  styleUrls: ['./page-grid.component.scss'],
})
export class PageGridComponent implements OnInit, OnDestroy {
  @Input() isPageGridOpen: boolean = false;
  @Input() pageView: 'line' | 'grid' = 'line';

  imgUrl;

  document: IDocumentModel;
  pageSelectedIndex = 0;
  private _onSelectDocument: Subscription = null;
  private _onPageSelectedIndexChange: Subscription = null;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _documentService: DocumentService,
    private _sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onSelectDocument =
      this._documentStateManagerService.document$.subscribe(doc => {
        this.document = doc;
      });

    this._onPageSelectedIndexChange =
      this._documentService.onPageSelectedIndexChange.subscribe(
        index => (this.pageSelectedIndex = index),
      );

    this._subscriptions.push(
      this._onSelectDocument,
      this._onPageSelectedIndexChange,
    );
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  defineUrl(base64: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(base64);
  }

  selectPage(index: number) {
    this._documentService.selectPageIndex(index);
  }

  addPage() {
    this._documentService.addPageEnd();
  }
}
