import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TraktoLinksAnalyticsService } from '@app/editor/services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { TraktoLinkState } from '../store/trakto-link.reducer';
import { Store, select } from '@ngrx/store';
import * as fromTraktoLink from '../store/trakto-link.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-link-editable-panel',
  templateUrl: './trakto-link-editable-panel.component.html',
  styleUrls: ['./trakto-link-editable-panel.component.scss']
})
export class TraktoLinkEditablePanelComponent implements OnInit, OnDestroy {

  @Input() doc: any;
  public traktoLink: any;
  public traktolink$: any;
  @Input() isB2C: any;
  public inputValue = '';
  public linkPlaceholder = '';
  public link = {
    status: false,
    url: 'www.trakto.io',
    copied: false
  }
  public linkLength = {
    min: 1,
    max: 100,
  };
  private _inputTyping = {
    timer: undefined,
    intervalSecond: 1,
  };
  public isAvaliable: boolean = false;
  public mainURL: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
    private _traktoLinkStore: Store<TraktoLinkState>) { }

  ngOnInit(): void {
    this.mainURL = `${window.location.origin}/design/copy`;
    this.inputValue = this.mainURL + '/' + this.doc.id;

    this.traktolink$ = this._traktoLinkStore.pipe(select(fromTraktoLink.selectTraktoLink));

    this.traktolink$.pipe(takeUntil(this._destroy$)).subscribe((link) => {
      this.traktoLink = link;
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }


  public copyLinkToClipboard() {
    const elem = document.createElement('input');
    elem.value = `${this.inputValue}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 2000);

    this._tktLinksAnalytics.traktoLinksCopiedLink({
      linkName: this.inputValue,
    });
  }
}
