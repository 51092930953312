<button
  class="auto-save-button tkt-button__DS btn-link btn-icon-left"
  (click)="handleSave()"
  [ngStyle]="{'color': b2c ? '#7183A6' : '#fff'}"
>
  <!-- hovered-->
  <div class="show-hover">
    <i
      [ngClass]="{
        'trakto-icon-stream-saving': loading,
        'trakto-icon-stream-check-rounded': !loading
      }"
      [ngStyle]="{'color': b2c ? '#7183A6' : '#fff'}"
      [style.opacity]="1"
    ></i>
    <span [ngStyle]="{'color': b2c ? '#7183A6' : '#fff'}">{{
      loading ? langTexts.saving : langTexts.save
    }}</span>
  </div>

  <div class="hide-hover">
    <i
      [ngClass]="{
        'trakto-icon-stream-saving': loading,
        'trakto-icon-stream-saved': !loading
      }"
      [ngStyle]="{ 'font-size': !loading ? '16px' : '1em' }"
    ></i>
    <span>{{ loading ? langTexts.saving : langTexts.saved }}</span>
  </div>

  <!-- Unsaved -->
  <!-- <i *ngIf="!loading" class="trakto-icon-stream-unsaved"></i>
  <span *ngIf="!loading">Unsaved</span> -->
</button>
<!-- <span *ngIf="!message && selectedDocument?.updated_at" class="timestamp">
  <span> {{ 'auto_save.saved_changes_with_time' | translate }} </span>:
  {{ selectedDocument?.updated_at | date: 'HH:mm' }}
</span>
<span *ngIf="message">{{ message }}</span>
<span *ngIf="selectedDocument && !message && !selectedDocument.updated_at">
  {{ 'auto_save.waiting_changes' | translate }}
</span> -->
