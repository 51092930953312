import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-panel-action-mobile',
  templateUrl: './panel-action-mobile.component.html',
  styleUrls: ['./panel-action-mobile.component.scss'],
})
export class PanelActionMobileComponent implements OnInit {
  @Input() height: number = 56;
  @Input() icon: string = '';
  @Input() label: string = '';

  constructor() {}

  ngOnInit(): void {}
}
