import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { PanelTextFontComponent } from '@app/editor/components/properties-panel/panel-text-font/panel-text-font.component';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { SignalsService } from '@app/shared/signals/signals.service';
import { TextEditorService } from '@services/text-editor.service';
import {
  alignEnum,
  fontSizeScaleModeEnum,
  ITextElementModel,
  PageModel
} from '@trakto/models';
import { Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AutoScaleHelpComponent } from '@editor/components/preview-2/svg-editor/text-menu-inline/auto-scale-help-modal/auto-scale-help.component';
import { takeUntil } from 'rxjs/operators';

enum textOptionsEnum {
  title = 'title',
  subtitle = 'subtitle',
  paragraph = 'paragraph',
  normal = 'normal',
  list = 'list',
}

interface ITextOptionConfig {
  isBold: boolean;
  fontSize: number;
  lineHeight: number;
  opacity: number;
  bullets: boolean;
}

@Component({
  selector: 'trakto-text-menu-inline',
  templateUrl: './text-menu-inline.component.html',
  styleUrls: ['./text-menu-inline.component.scss'],
})
export class TextMenuInlineComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  private textElement: ITextElementModel;

  @Input()
  private ref1: any;

  @Input()
  page: PageModel;

  public menuExpanded: string | boolean = false;
  public fontSelected = '';
  public textOptionSelected: textOptionsEnum | false = textOptionsEnum.title;
  public textOptionsEnumValues = textOptionsEnum;
  public alignmentOptionSelected = 'left';
  public weightOptionsSelected: string[] = [];
  public fontSizeScaleMode: fontSizeScaleModeEnum = undefined;
  public mainColorSelected = '#7251E7';
  public backgroundColorSelected: string | boolean = false;

  public canApplyItalic = true;
  public canApplyBold = true;

  private _destroy$ = new Subject<void>();

  constructor(
    private _textEditorService: TextEditorService,
    private _signalsService: SignalsService,
    private _panelStackService: PanelStackService,
    private _dialog: MatDialog,
  ) {}

  ngOnInit() {
    this._textEditorService.selectionChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._initState();
    });
  }

  ngOnChanges(changes): void {
    if (changes.textElement) {
      this._textEditorService.configureSelection(this.textElement);
    }
    this._initState();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  changeMenuExpanded(menu: string | boolean) {
    this.menuExpanded = menu === this.menuExpanded ? false : menu;
    if (this._panelStackService.stacks.length > 2) {
      this._panelStackService.pop();
    }
  }

  openPanelFont() {
    this.changeMenuExpanded(false);

    this._panelStackService.stack(PanelTextFontComponent, {
      inputs: { elementSelected: this.textElement, pageSelected: this.page },
    });
  }

  changeTextOptionSelected(option: textOptionsEnum) {
    this.textOptionSelected = option;

    const textOptionConfig = this.getTextOptionsConfigs(option);

    if (textOptionConfig) {
      if (this.canApplyBold) {
        this._textEditorService.toggleStyles(textOptionConfig.isBold, false);
      }

      const fontSize =
        +Number(textOptionConfig.fontSize).toFixed(2) / this.textElement.scaleY;
      this._textEditorService.changeFontSize(fontSize, this.textElement);

      this._textEditorService.changeLineHeight(
        textOptionConfig.lineHeight,
        this.textElement,
      );

      this.textElement.opacity = textOptionConfig.opacity;

      this._textEditorService.changeBullets(
        textOptionConfig.bullets,
        this.textElement,
      );
      return true;
    }

    return false;
  }

  changeAlignmentOptionSelected(alignment: string) {
    this.alignmentOptionSelected = alignment;
    this._textEditorService.changeAlign(alignEnum[alignment]);
    this._initState();
  }

  async changeWeightOptionsSelected(option: string) {
    this._initState();
    const index = this.weightOptionsSelected.indexOf(option);

    index !== -1
      ? this.weightOptionsSelected.splice(index, 1)
      : this.weightOptionsSelected.push(option);

    const updated = await this._textEditorService.toggleStyles(
      this.weightOptionsSelected.includes('bold'),
      this.weightOptionsSelected.includes('italic'),
    );

    this._textEditorService.changeUnderline(
      this.weightOptionsSelected.includes('underline'),
      updated,
    );

    setTimeout(() => this._initState(), 0);
  }

  changeMainColorSelected(color: string) {
    this.mainColorSelected = color;
    this._textEditorService.changeColor(color);
    this._initState();
  }

  changeFontSizeScaleMode() {
    const mode = this.fontSizeScaleMode === 'auto' ? 'manual' : 'auto';
    this._textEditorService.changeFontSizeScaleMode(mode as fontSizeScaleModeEnum);
    this._initState();
  }

  changeBackgroundColorSelected(color: string) {
    this.backgroundColorSelected = color;
    this._textEditorService.changeBackgroundColor(color);
  }

  private _initState() {
    this.textOptionSelected = this.getCurrentTextOption();
    this.alignmentOptionSelected = this.textElement.align;
    this.backgroundColorSelected = this.textElement.backgroundColor;
    this.mainColorSelected = this._textEditorService.getSelectedColor();
    this.fontSizeScaleMode = this.textElement.fontSizeScaleMode;
    const style = this._textEditorService.getSelectedFontStyle();
    this.weightOptionsSelected = [];
    if (style && style.hasBold) {
      this.weightOptionsSelected.push('bold');
    }
    if (style && style.italic) {
      this.weightOptionsSelected.push('italic');
    }
    if (this._textEditorService.hasUnderline()) {
      this.weightOptionsSelected.push('underline');
    }

    this._textEditorService.getAllowedStylesToEdition(this.textElement).pipe(takeUntil(this._destroy$)).subscribe(result => {
      this.canApplyBold = result.canBold;
      this.canApplyItalic = result.canItalic;
    });

    const family = this._textEditorService.getSelectedFontFamily();

    if (family.length !== 0) {
      this.fontSelected = family;
    }
  }

  applyLink(link: string | null): void {
    this._textEditorService.changeLink(link);
    if (link) {
      this.menuExpanded = false;
    }
  }

  getTextOptionsConfigs(option: textOptionsEnum): ITextOptionConfig | false {
    switch (option) {
      case textOptionsEnum.title:
        return {
          isBold: true,
          fontSize: 120,
          lineHeight: 1.17,
          opacity: 1,
          bullets: false,
        };
      case textOptionsEnum.subtitle:
        return {
          isBold: true,
          fontSize: 40,
          lineHeight: 1.15,
          opacity: 1,
          bullets: false,
        };
      case textOptionsEnum.paragraph:
        return {
          isBold: false,
          fontSize: 24,
          lineHeight: 1.16,
          opacity: 0.84,
          bullets: false,
        };
      case textOptionsEnum.normal:
        return {
          isBold: false,
          fontSize: 32,
          lineHeight: 1.16,
          opacity: 1,
          bullets: false,
        };
      case textOptionsEnum.list:
        return {
          isBold: false,
          fontSize: 32,
          lineHeight: 1.2,
          opacity: 1.25,
          bullets: true,
        };

      default:
        return false;
    }
  }

  getCurrentTextOption(): textOptionsEnum | false {
    const fontSizeString = (
      this.textElement.fontSize * this.textElement.scaleY
    ).toFixed(0);

    const current: ITextOptionConfig = {
      isBold: this.textElement.fontStyle.label === 'Bold',
      fontSize: parseInt(fontSizeString, 10),
      lineHeight: this.textElement.lineSpace,
      opacity: this.textElement.opacity,
      bullets: this._textEditorService.getSelectedBullets(this.textElement),
    };

    let out: textOptionsEnum | false = false;

    Object.keys(this.textOptionsEnumValues).forEach(
      (option: textOptionsEnum) => {
        const optionConfig = this.getTextOptionsConfigs(option);

        if (JSON.stringify(current) === JSON.stringify(optionConfig)) {
          out = option;
        }
      },
    );

    return out;
  }

  showMenuInlineInfo() {
    return this._dialog.open(AutoScaleHelpComponent, {
      width: '700px',
      minWidth: '700px',
      height: '500px',
      panelClass:'icon-outside',
      data: {  },
    } as MatDialogConfig);
  }
}
