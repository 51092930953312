<div class="panel-mobile">
  <trakto-panel-header-mobile
    [img]="searchEvent?.family?.thumbUrl"
    [label]="searchEvent?.family?.name"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="search click-ignore">
    <trakto-input
      [value]="searchText"
      [clearIcon]="true"
      placeholder="Pesquise por algo"
      (onChange)="setInputText($event)"
      (onBlur)="setShowSuggestions(false)"
      (onSelect)="selectTag($event)"
    ></trakto-input>
  </div>

  <trakto-tag-list
    [tags]="tags"
    [selected]="queryText"
    (onSelect)="selectTag($event)"
    class="click-ignore"
  ></trakto-tag-list>

  <trakto-elements-list
    [searchEvent]="searchEvent"
  ></trakto-elements-list>
</div>
