import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IDocumentModel } from "@trakto/models";

export interface NewBlankDesignDTO {
    width: number;
    height: number;
    backgroundImage?: string;
    elementImageUrl?: string;
}

@Injectable({
    providedIn: 'root'
})
export class NewDesignAPIService {

    constructor(
        private _httpClient: HttpClient,
    ) { }
    
    fromBlankDesign(blankDesign: NewBlankDesignDTO): Promise<IDocumentModel> {
        return this._httpClient.post<IDocumentModel>(
            `${environment.api.baseUrl}document/create-blank`,
            blankDesign
        ).toPromise();
    }
}