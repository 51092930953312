<ng-container>
    <div class="container-modal-google-ads">
        <div class="progress-container">
        </div>
        <div class="ellipse"></div>
        <div class="header">
            <div class="close" (click)="closeModal()">
                <img src="../../../../../assets/icons/close-modal.svg" alt="Botão para fechar modal">
            </div>
            <img src="../../assets/images/send-google-ads-group.svg" alt="Logo do Google ADS" class="google-ads-icon">

            <!-- Seleção de contas e campanhas -->
            <h1 *ngIf="!loading && step === 0">{{ 'googleAds.chooseAccount' | translate }}
            </h1>

            <h2 *ngIf="!loading && step === 0">
                <span>{{ "googleAds.toProceedTo" | translate }}</span>
                <strong>&nbsp;{{ "googleAds.title" | translate }}</strong>
                <span *ngIf="step === 0">
                    {{ 'googleAds.selectAccount' | translate | lowercase}}
                </span>
                <!-- <span *ngIf="step === 1">
                    &nbsp;{{ 'campaigns.googleAds.selectAcampaign' | translate | lowercase}}
                </span> -->
            </h2>

            <!-- Etapa de finalização -->
            <h1 *ngIf="step === 2">{{ "googleAds.sendingAds" | translate }} </h1>
            <h2 *ngIf="loading && step === 2">{{ "googleAds.waitingForSendingMessage" | translate }}
            </h2>
        </div>

        <div class="search-container" *ngIf="step === 0 && accounts.length > 0">
            <span class="search-icon">
                <img src=".../../assets/icons/search-normal.svg" alt="Lupa">
            </span>
            <input type="text" placeholder="Buscar" class="input" (focus)="disablehotkeys($event)"
                (input)="filterAccounts($event.target.value)">
        </div>

        <!-- Lista de contas -->
        <div class="accounts-list" *ngIf="step === 0">
            <div class="scroll-area" *ngIf="newArrayAccounts.length > 0">
                <div *ngFor="let account of newArrayAccounts; let index = index">
                    <label class="label-account">
                        <input type="radio" name="option-account" [value]="account.id" (change)="selectAcount(index)"
                            [disabled]="loadingButton">
                        <div class="description-account">
                            <span class="email">
                                {{ account.descriptive_name ? account.descriptive_name : 'Sem nome'}}
                            </span>
                            <span class="name">{{ "googleAds.fromTrakto" | translate }}</span>
                        </div>
                        <div class="account-id">
                            {{account.id}}
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <!-- Lista de campanhas -->
        <!-- <div class="accounts-list" *ngIf="step === 1">
            <div class="scroll-area">
                <div *ngFor="let campaign of newArraycampaigns; let index = index">
                    <label class="label-campaign">
                        <input type="radio" name="option-account" [value]="campaign.id"
                            (change)="selectCampaign(index)">
                        <div class="description-account">
                            <span class="email">{{ campaign.title }}</span>
                        </div>
                    </label>
                </div>
            </div>
        </div> -->

        <div class="no-accounts" *ngIf="newArrayAccounts.length === 0">
            {{ 'googleAds.noAccountsFound' | translate }}
        </div>

        <div class="loader-container" *ngIf="loading">
            <div class="loader"></div>
        </div>

        <!-- Botões de ações -->
        <div class="actionsBtn">
            <!-- <button class="backBtn" *ngIf="step > 0 && !isSending" (click)="backStep()">
                {{ "buttons.back" | translate}}
            </button> -->
            <button [disabled]="buttomDisabled || loadingButton" (click)="finish()" [ngClass]="{
                'disabled': buttomDisabled || loadingButton
            }" *ngIf="step < 2 && !loading">
                {{ "buttons.next" | translate}}
            </button>
            <!-- <button [disabled]="!selectedAccount" (click)="finish()" [ngClass]="{
                'disabled': !selectedAccount
            }" *ngIf="step === 2 && newArrayAccounts.length > 0 &&!loading">
                <span *ngIf="!loadingButton">{{ "buttons.finish" | translate}}</span>
                <span *ngIf="loadingButton">
                    <i class="spinner"></i> {{ "assistant.loading" | translate}}
                </span>
            </button> -->
            <button class="backBtn" (click)="cancelShipping()" *ngIf="loading">
                {{ "buttons.cancel" | translate}}
            </button>
        </div>
    </div>
</ng-container>