import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { GalleryIconsService } from '@app/editor/services/gallery/gallery.icons.service';
import { elementTypeEnum } from '@trakto/models';
import { ItemRenderer } from '../item-renderer.model';

@Component({
  selector: 'trakto-item-renderer',
  templateUrl: './item-renderer.component.html',
  styleUrls: ['./item-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemRendererComponent implements OnChanges {
  @ViewChild('render', { static: true }) render;
  public mediaLoaded: boolean;

  private _gap: number;
  private _columns: number;
  private _item: ItemRenderer;
  private _internalItem: ItemRenderer;

  @Input()
  set item(value: any) {
    this._item = value;
    this.internalItem = Object.assign({}, value);
  }

  get item(): any {
    return this._item;
  }

  @Input()
  set columns(value: number) {
    this._columns = value;
  }

  get columns(): number {
    return this._columns || 3;
  }

  @Input()
  set gap(value: number) {
    this._gap = value;
  }

  get gap(): number {
    return this._gap || 10;
  }

  set internalItem(item: ItemRenderer) {
    if (item.type === 'emoji-svg') {
      item.value = this._sanitizer.bypassSecurityTrustHtml(item.value as string);
    } else {
      item.value = this._sanitizer.bypassSecurityTrustUrl(item.value as string);
    }
    this._internalItem = item;
  }

  get internalItem(): ItemRenderer {
    return this._internalItem;
  }

  @Output()
  onClick: EventEmitter<ItemRenderer> = new EventEmitter<ItemRenderer>();

  constructor(
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer,
    private _elementRef: ElementRef,
    private _galleryIconsService: GalleryIconsService
  ) {}

  onLoadMedia() {
    this.mediaLoaded = true;
  }

  ngOnChanges() {
    this.setColumnGap();
  }

  setColumnGap() {
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'width',
      `${100 / this.columns}%`,
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'padding',
      `${this.gap}px`,
    );
  }

  click() {
    this.onClick.emit(this.item);
  }

  select(item: ItemRenderer): void {
    this.item = item;
    this.click();
  }
}
