import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FontsService } from '@app/editor/services/fonts.service';

import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';
import { IFontModel, ITextElementModel, PageModel } from '@trakto/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'trakto-font-favorite',
  templateUrl: './font-favorite.component.html',
  styleUrls: ['./font-favorite.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FontFavoriteComponent implements OnDestroy{
  public searchFontText: string;

  @Input()
  elementSelected: ITextElementModel;

  @Input()
  pageSelected: PageModel;

  @Output()
  selectedFont: EventEmitter<IFontModel> = new EventEmitter<IFontModel>();

  public fonts: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private hotkeyService: HotkeyService,
    private _fontsService: FontsService,
  ) {
    this.listFonts();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public enableHotkeys() {
    this.hotkeyService.enableHotkeys();
  }

  public disableHotkeys() {
    this.hotkeyService.disableHotkeys();
  }

  public listFonts() {
    this._fontsService.listAllFonts().pipe(takeUntil(this._destroy$)).subscribe(
      fonts => this.fonts = fonts.filter((font: any) => font?.is_favorite),
      error => console.error(error),
    );
  }
}
