<section class="trakto-modal-confirmation">
  <div
    [style.backgroundImage]="'url(' + imageBanner + ')'"
    class="trakto-modal-confirmation__banner"
  ></div>
  <div class="trakto-modal-confirmation__container">
    <div class="trakto-modal-confirmation__header">
      <h2>{{ title || 'Seu titulo' }}</h2>
      <span>{{ description || 'Sua descrição' }}</span>
    </div>
    <div class="trakto-modal-confirmation__actions">
      <kml-button
        class="trakto-button-secondary"
        type="secondary"
        label="{{ buttonPrimary || 'Continuar editando' }}"
        (click)="onCloseModal()"
      ></kml-button>

      <kml-button
        type="primary"
        label="{{ buttonSecondary || 'Voltar para editor' }}"
        (click)="onBackEditor()"
      ></kml-button>
    </div>
  </div>
</section>
