<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/add.svg"
    label="Adicionar"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="options-grid">
    <trakto-panel-option-mobile
      image="elements"
      label="Elementos"
      (click)="openPanel('elements')"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      image="panel-text"
      label="Texto"
      (click)="openPanel('text')"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      image="panel-images"
      label="Imagem"
      (click)="openPanel('image')"
    ></trakto-panel-option-mobile>
    <trakto-panel-option-mobile
      image="tkt-gif"
      label="Gif"
      (click)="openPanel('gif')"
    ></trakto-panel-option-mobile>
  </div>
</div>
