import { Component, OnDestroy, OnInit } from '@angular/core';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'trakto-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  public isPanelExpanded = false;
  public onExpandIconChange: Subscription;

  constructor(private panelStackService: PanelStackService) {}

  ngOnInit(): void {
    this.onExpandIconChange =
      this.panelStackService.onExpandPanelChange.subscribe(isPanelExpanded => {
        this.isPanelExpanded = isPanelExpanded;
      });
  }

  ngOnDestroy(): void {
    this.onExpandIconChange?.unsubscribe();
  }
}
