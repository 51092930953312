import { Injectable } from "@angular/core";
import { DataTracking } from "@trakto/data-tracking";
import { IUser } from "@trakto/models";
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import TKTAnalyticsUserFactory from "../custom/tkt-analytics-user.factory";
import { TKTAnalytics } from "../custom/tkt-analytics.service";
import ProductAnalyticsFactory from "./analyticsProduct.factory.service";
import { UserService } from "@app/editor-v3/services/user.service";

@Injectable({
    providedIn: 'root',
})
export class UserAnalyticsService extends DataTracking {
    constructor(
        private _tktAnalytics: TKTAnalytics,
        private _whitelabelService: WhitelabelProductService,
        private _userService: UserService,
    ) {
        super();
    }

    public async makeIdentifyUser() {
        const data: any = {
            email: this._userService.user.email,
            firstname: this._userService.user.firstname,
            subscription_plan_reference: this._userService.user.subscription_plan_reference,
            subscription_plan_name: this._userService.user.subscription_plan_name,
            id: this._userService.user.id,
            products: this._whitelabelService.productsData,
        };
        const payload = TKTAnalyticsUserFactory.createIdentifyPayload(this._userService.user as IUser);
        this._tktAnalytics.identify(this._userService.user.id, payload);
        this.identify(data, {
            ...ProductAnalyticsFactory.applicationModel(
                this._whitelabelService.currentProduct,
                this._whitelabelService.currentApp
            ) });
    }
}