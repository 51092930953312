import '@svgdotjs/svg.filter.js';
import { Svg } from '@svgdotjs/svg.js';
import { filterTypeEnum, IFilterModel } from '@trakto/models';
import { filtersModel } from '@trakto/core-editor';

export class FilterModel {
  public filters: IFilterModel[] = filtersModel;

  private _render: Svg;

  constructor(render: any) {
    this._render = render;
  }

  public apply(name: string): void {
    const index = this.filters.findIndex((f: IFilterModel) => f.name === name);
    let filter: IFilterModel = this.filters[index];

    if (!filter) {
      filter = this.filters[0];
    }

    this.unfilter();

    switch (filter.type) {
      case filterTypeEnum.duotone:
        this.setDuotone(filter);
        break;
      case filterTypeEnum.matrix:
        this.setMatrix(filter);
        break;
      case filterTypeEnum.gaussianblur:
        this.setGaussianBlur(filter);
        break;
      case filterTypeEnum.horizontalblur:
        this.setHorizontalBlur(filter);
        break;
      case filterTypeEnum.contrast:
        this.setContrast(filter);
        break;
      case filterTypeEnum.huerotate180:
        this.setHueRotate(filter);
        break;
      case filterTypeEnum.luminance:
        this.setLuminance(filter);
        break;
      case filterTypeEnum.darken:
        this.setDarken(filter);
        break;
      case filterTypeEnum.lighten:
        this.setLighten(filter);
        break;
      case filterTypeEnum.invert:
        this.setInvert(filter);
        break;
      case filterTypeEnum.dropshadow:
        this.setDropShadow(filter);
        break;
      case filterTypeEnum.image:
        this.setImage(filter);
        break;
      case filterTypeEnum.turbulence:
        this.setTurbulence(filter);
        break;
      case filterTypeEnum.empty:
        break;
      default:
        console.warn(`O tipo de filtro ${name} definido não foi encontrado`);
        break;
    }
  }

  public unfilter(): void {
    this._render.unfilter();
  }

  protected setDuotone(filterModel: IFilterModel): void {
    this._render.filterWith(add => {
      add.attr('color-interpolation-filters', 'sRGB');
      add.colorMatrix('saturate', '0').attr({ result: 'desaturate' });
      add
        .componentTransfer(a => {
          a.funcR({ type: 'table', tableValues: filterModel.value[0] });
          a.funcG({ type: 'table', tableValues: filterModel.value[1] });
          a.funcB({ type: 'table', tableValues: filterModel.value[2] });
          a.funcA({ type: 'table', tableValues: filterModel.value[3] });
        })
        .attr({ in: 'desaturate' });
    });
  }

  protected setMatrix(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.colorMatrix('matrix', filter.value[0]);
    });
  }

  protected setGaussianBlur(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.gaussianBlur(+filter.value[0], undefined);
    });
  }

  protected setHorizontalBlur(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.gaussianBlur(+filter.value[0], +filter.value[1]);
    });
  }

  protected setContrast(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.componentTransfer(a => {
        const args = {
          type: 'linear',
          slope: filter.value[0],
          intercept: -(0.3 * parseInt(filter.value[0], 10)) + 0.3,
        };
        a.funcR(args);
        a.funcG(args);
        a.funcB(args);
      });
    });
  }

  protected setHueRotate(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.colorMatrix('hueRotate', filter.value[0]);
    });
  }

  protected setLuminance(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.colorMatrix('luminanceToAlpha', undefined);
    });
  }

  protected setDarken(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.componentTransfer(a => {
        const args = {
          type: 'linear',
          slope: filter.value[0],
        };
        a.funcR(args);
        a.funcG(args);
        a.funcB(args);
      });
    });
  }

  protected setLighten(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.componentTransfer(a => {
        const args = {
          type: 'linear',
          slope: filter.value[0],
          intercept: filter.value[1],
        };
        a.funcR(args);
        a.funcG(args);
        a.funcB(args);
      });
    });
  }

  protected setInvert(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.componentTransfer(a => {
        const args = {
          type: 'table',
          tableValues: [filter.value],
        };
        a.funcR(args);
        a.funcG(args);
        a.funcB(args);
      });
    });
  }

  protected setDropShadow(filter: IFilterModel): void {
    this._render.filterWith(add => {
      const blur = add
        .offset(+filter.value[0], +filter.value[1])
        .in(add.$sourceAlpha)
        .gaussianBlur(1, 1);
      add.blend(add.$source, blur, undefined);
    });
  }

  protected setImage(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.image(filter.value[0]);
    });
  }

  protected setTurbulence(filter: IFilterModel): void {
    this._render.filterWith(add => {
      add.turbulence(
        +filter.value[0],
        +filter.value[1],
        +filter.value[2],
        filter.value[3],
        filter.value[4],
      );
      add.blend(add.$source, add.$source, 'multiply');
    });
  }

  private _buildDuotoneFilterModel(name: string, hexColors: string[]) {
    return {
      type: filterTypeEnum.duotone,
      name,
      value: this.convert255(
        hexColors.map(color => this.convertHexToRgb(color)),
      ),
    };
  }

  protected convertHexToRgb(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
          rgb: [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
          ],
        }
      : null;
  }

  protected convert255(colors: any[]): any[] {
    const output = [];
    const color_one = colors[0];
    const color_two = colors[1];
    const color_three = colors[2] || { rgb: [null, null, null] };

    output.push(
      this.colorTo255(color_one.rgb[0], color_two.rgb[0], color_three.rgb[0]),
    );
    output.push(
      this.colorTo255(color_one.rgb[1], color_two.rgb[1], color_three.rgb[1]),
    );
    output.push(
      this.colorTo255(color_one.rgb[2], color_two.rgb[2], color_three.rgb[2]),
    );
    output.push('0, 1');

    return output;
  }

  protected colorTo255(one: number, two: number, three?: number): string {
    return !three
      ? `${(one / 255).toString()}, ${(two / 255).toString()}`
      : `${(one / 255).toString()}, ${(two / 255).toString()}, ${(
          three / 255
        ).toString()}`;
  }
}
