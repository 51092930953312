<div class="settings">
  <div class="inputs">
    <div class="input">
      <label for="settings-width">
        {{ 'property_panel.page.dimensions_page.label_width' | translate }}
      </label>
      <trakto-input-dimensions
        name="settings-width"
        [(value)]="width"
        (onChangeEmit)="changeDimensions($event, 'width')"
      ></trakto-input-dimensions>
      <img src="assets/editor-v3/images/input-width.svg" alt="" />
    </div>

    <div
      class="block"
      (click)="toggleLock()"
      [ngClass]="{ selected: pageSelected?.lockSize }"
    >
      <img
        [src]="
          pageSelected?.lockSize
            ? 'assets/images/panel/locked.svg'
            : 'assets/images/panel/unlocked.svg'
        "
        alt=""
      />
    </div>

    <div class="input">
      <label for="settings-width">
        {{ 'property_panel.page.dimensions_page.label_height' | translate }}
      </label>
      <trakto-input-dimensions
        name="settings-height"
        [(value)]="height"
        (onChangeEmit)="changeDimensions($event, 'height')"
      ></trakto-input-dimensions>
      <img src="assets/editor-v3/images/input-height.svg" alt="" />
    </div>
  </div>

  <div class="dimensions">
    <div class="orientation">
      <span>
        {{ 'property_panel.page.dimensions_page.aspect_ratio' | translate }}
      </span>

      <div class="toggle">
        <div
          (click)="invertDimensions(orientations.HORIZONTAL)"
          class="option"
          [ngClass]="{
            selected: orientation == 'horizontal'
          }"
        >
          <img src="assets/editor-v3/images/page.svg" alt="" />
        </div>
        <div
          (click)="invertDimensions(orientations.VERTICAL)"
          class="option portrait"
          [ngClass]="{
            selected: orientation == 'vertical'
          }"
        >
          <img src="assets/editor-v3/images/page.svg" alt="" />
        </div>
      </div>
    </div>

    <trakto-units-dropdown
      [options]="unitOptions"
      [pageSelected]="pageSelected"
      (onChangeUnit)="changeUnit($event)"
    ></trakto-units-dropdown>
  </div>
</div>
