import { Component, OnInit } from '@angular/core';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { ElementsGalleryService } from '@shared/elements-gallery/elements-gallery.service';
import { ISearchEvent } from '@editor/components/properties-panel/panel-elements/model/model';

@Component({
  selector: 'trakto-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  searchEvent: ISearchEvent = null;
  panelLabel: string = '';

  constructor(
    private _panelService: PanelService,
    private _elementsGalleryService: ElementsGalleryService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.searchEvent = this._elementsGalleryService.store;
  }

  closePanel() {
    this._elementsGalleryService.resetPanelStore();
    this._panelService.closeLastPanel();
  }
}
