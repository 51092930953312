import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ErrorClientHandlerService
} from '@app/editor-v3/services/erro-handler-client.service';

import { AuthService } from '@auth/shared/auth.service';
import { CustomTokenService } from '@auth/shared/custom-token.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-app-auth',
  templateUrl: './app-auth.component.html',
  styleUrls: ['./app-auth.component.scss'],
})
export class AppAuthComponent implements OnInit, OnDestroy {

  private authToken: string;
  private documentId: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private _customTokenService: CustomTokenService,
    private route: ActivatedRoute,
    private router: Router,
    private _errorHandler: ErrorClientHandlerService
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      takeUntil(this._destroy$),
      switchMap(params => {
        this.authToken = params['token'];
        this.documentId = params['id'];
        this._customTokenService.setCustomToken(this.authToken);
        return this.authService.login(this.authToken)
      })
    ).subscribe(
      result => {
        this.router.navigateByUrl(`/editor/${this.documentId}`);
      },
      error => {
        const message = 'AuthToken Error';
        this._errorHandler.triggerError({
          message,
          stack: error
        });
      }
    );
  };
}
