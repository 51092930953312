import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KmlButtonModule } from '@trakto/trakto-kamaleon';

import { ModalConfirmationComponent } from './modal-confirmation.component';

@NgModule({
  imports: [CommonModule, KmlButtonModule],
  declarations: [ModalConfirmationComponent],
  exports: [ModalConfirmationComponent],
})
export class ModalConfirmationModule {}
