<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/download.svg"
    label="Baixar"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()">
  </trakto-panel-header-mobile>
  <div class="click-ignore">
    <div class="wrapper">
      <div class="option lilac"
      [ngStyle]="{'background-color': downloadTarget === 'png' ? '#7251E7' : 'rgba(114, 81, 231, 0.08)', 'color': downloadTarget === 'png' ? '#E4EBFB' : '#7251E7'}"
      (click)="selectTab('png')">
        <img src="{{downloadTarget === 'png' ? 'assets/editor-v3/icons/svg/gallery-active.svg' : 'assets/editor-v3/icons/svg/image_png.svg'}}" class="element-image" height="16px" />
        <span class="label">Imagem PNG</span>
      </div>
      <div class="option pink"
        [ngStyle]="{'background-color': downloadTarget === 'pdf' ? '#FE43D1' : 'rgba(254, 67, 209, 0.08)', 'color': downloadTarget === 'pdf' ? '#E4EBFB' : '#FE43D1'}"
        (click)="selectTab('pdf')">
        <img src="{{downloadTarget === 'pdf' ? 'assets/editor-v3/icons/svg/document-text-active.svg' : 'assets/editor-v3/icons/svg/document-pdf.svg'}}" class="element-image" height="16px" />
        <span class="label">Documento PDF</span>
      </div>
      <div class="option orange"
        [ngStyle]="{'background-color': downloadTarget === 'mp4' ? '#FE6E46' : 'rgba(254, 148, 116, 0.08)', 'color': downloadTarget === 'mp4' ? '#E4EBFB' : '#fe6e46'}"
        (click)="selectTab('mp4')">
        <img src="{{downloadTarget === 'mp4' ? 'assets/editor-v3/icons/svg/video-play-active.svg' : 'assets/editor-v3/icons/svg/video-play.svg'}}" class="element-image" height="16px" />
        <span class="label">Vídeo MP4</span>
      </div>
    </div>

    <div class="tips">
      <img src="assets/editor-v3/icons/svg/lamp-on.svg" class="element-image" height="14px" />
      <p *ngIf="downloadTarget === 'mp4'">Formato de vídeo compatível com Youtube, WhatsApp,
        Instagram...</p>
      <p *ngIf="downloadTarget === 'pdf'">Formato de arquivo perfeito para apresentações, e-books,
        cartões virtuais, impressos...</p>
      <p *ngIf="downloadTarget === 'png'">Formato de imagem de ótima qualidade perfeito para redes
        sociais, que permite utilizar transparência</p>
    </div>

    <hr style="border: 1px solid #E4EBFB; margin-left: 16px; margin-right: 16px; margin-bottom: 16px;">

    <div class="actions" *ngIf="downloadTarget === 'pdf' || downloadTarget === 'png'">
      <div *ngIf="downloadTarget === 'pdf'" class="selectPages">
        <div class="title">
          <img src="assets/editor-v3/icons/svg/layer.svg" class="element-image" height="16px" />
          <h5>Seleção de Páginas</h5>
        </div>

        <div class="form-check">
          <input
            (change)="pageSelectionType = 'current'"
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1">
          <label class="form-check-label" for="flexRadioDefault1">
            Página atual
          </label>
        </div>
        <div class="form-check">
          <input
            [defaultChecked]="true"
            (change)="pageSelectionType = 'all'"
            class="form-check-input"
            type="radio"
            name="flexRadioDefault" id="flexRadioDefault2">
          <label class="form-check-label" for="flexRadioDefault2">
            Todas as páginas
          </label>
        </div>
        <div class="form-check">
          <input
            (change)="pageSelectionType = 'selected'"
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault3">
          <label class="form-check-label" for="flexRadioDefault3">
            <input
              [(ngModel)]="startPage"
              min="1"
              type="numer"
              class="pagesNumbers"
              style="margin-left: 13px; margin-right: 10px;text-align: center;">
            até
            <input
              [(ngModel)]="endPage"
              [max]="document?.body?.length"
              style="margin-left: 10px; text-align: center;"
              type="numer"
              class="pagesNumbers"
              >
          </label>
        </div>
      </div>

      <hr *ngIf="downloadTarget === 'pdf'" style="border: 1px solid #E4EBFB; margin-bottom: 16px;">

      <div class="quality">
        <div class="title">
          <img src="assets/editor-v3/icons/svg/flash-circle.svg" class="element-image" height="16px" />
          <h5>Qualidade</h5>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault2"
            id="flexRadioDefault4"
            [defaultChecked]="true"
            (change)="quality = 'medium'"
            (change)="quality = 'medium'"
          >
          <label class="form-check-label" for="flexRadioDefault1">
            Média
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault2"
            id="flexRadioDefault5"
            (change)="quality = 'high'"
          >
          <div class="premium" style="width: 100%; display: flex; align-items: center;">
            <label class="form-check-label" for="flexRadioDefault2">HD </label>
            <h5 style="font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #F6AE2B;
            margin-left: 10px;
            margin-right: 5px;">Premium</h5> <img src="assets/editor-v3/icons/svg/diamond.svg" class="element-image"
              height="12px" />
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault2"
            id="flexRadioDefault6"
            (change)="quality = 'raw'"
          >
          <div class="premium" style="width: 100%; display: flex; align-items: center;">
            <label class="form-check-label" for="flexRadioDefault2">Impressão </label>
            <h5 style="font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #F6AE2B;
            margin-left: 10px;
            margin-right: 5px;">Premium</h5> <img src="assets/editor-v3/icons/svg/diamond.svg" class="element-image"
              height="12px" />
          </div>
        </div>
      </div>
    </div>

    <div class="button">
      <button
        class="btn"
        nz-button
        nzType="primary"
        nzBlock
        (click)="downloadFile()"
      >
        <span *ngIf="!loading && !error">Baixar</span>
        <span *ngIf="!loading && error">Erro ao baixar arquivo</span>

        <div *ngIf="loading">
          <div class="loader"  style="display: inline-block">
            <i class="icon icon-loading"></i>
          </div>
          Baixando...
        </div>
      </button>
    </div>
  </div>
</div>
