export const SlideStepsConfig = {
  config: {
    autoplay: true,
  },
  data: [
    {
      background: 'assets/images/trakto-links/social-cards/whatsapp.png',
      icon: 'trakto-icon-social-link-whatsapp',
      iconBg: '#25D366',
      cardType: 'whatsapp',
    },
    {
      background: 'assets/images/trakto-links/social-cards/facebook.png',
      icon: 'trakto-icon-social-link-facebook',
      iconBg: '#1877F1',
      cardType: 'facebook',
    },
    {
      background: 'assets/images/trakto-links/social-cards/linkedin.png',
      icon: 'trakto-icon-social-link-linkedin',
      iconBg: '#0280BD',
      cardType: 'linkedin',
    },
    {
      background: 'assets/images/trakto-links/social-cards/twitter.png',
      icon: 'trakto-icon-social-link-twitter',
      iconBg: '#1BA2EF',
      cardType: 'twitter',
    },
  ],
};
