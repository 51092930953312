import { IDocumentModel } from "@trakto/models";
import { UpdateDesignApi } from "./api-update-design.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UpdateDesignService {
    constructor(
        private _api: UpdateDesignApi,
    ) {}

    async update(id: string, designToUpdate: {
        title: string;
        pageFormatId: string;
        author?: string;
        body: any;
    }): Promise<IDocumentModel> {
        const updatedDesign = await this._api.updateDesign(id, designToUpdate);
        if (!updatedDesign) console.warn(`Update field of design is not identified.`);
        return updatedDesign;
    }
}