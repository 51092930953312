<div class="panel-mobile click-ignore">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-links.svg"
    label="Adicionar link"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper">
    <div class="option blue" (click)="openWebsitePanel()">
      <i class="icon icon-link-element"></i>
      <span class="label">Website</span>
    </div>
    <div class="option green" (click)="openWhatsappPanel()">
      <i class="icon icon-whatsapp"></i>
      <span class="label">WhatsApp</span>
    </div>
    <div class="option orange" (click)="openEmailPanel()">
      <i class="icon icon-email"></i>
      <span class="label">Email</span>
    </div>
  </div>
</div>
