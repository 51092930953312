import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'trakto-video-modal',
  templateUrl: './video-modal.component.html',
  styles: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  public video: any;

  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (this.data && this.data.url) {
      this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
      return;
    }

    console.warn(
      'Video Modal Component required url. Eg. { data: { url: url } }',
    );
  }
}
