import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTokenService } from '../shared/custom-token.service';
import { AuthFirebaseService } from '@auth/shared/auth-firebase.service';
import { AuthService } from '@auth/shared/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-app-auth-v3-component',
  templateUrl: './app-auth-v3-component.component.html',
  styleUrls: ['./app-auth-v3-component.component.scss']
})
export class AppAuthV3ComponentComponent implements OnInit {


  private authToken: string;
  private customToken: string;
  private documentId: string;
  private _destroy$ = new Subject<void>();

  constructor(
    private _authService: AuthService,
    private _authFirebaseService: AuthFirebaseService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _customTokenService: CustomTokenService,
  ) { }


  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {

    this._route.queryParams.pipe(
      takeUntil(this._destroy$),
    ).subscribe(async params => {

      this.authToken = params['access_token'];
      this.customToken = params['custom_token'];
      this.documentId = params['documentId'];
      try {
        if (!this.documentId) throw new Error('No param documentId provided to access editor on V3 version');
        const createdCustomToken = this.customToken || await this._authFirebaseService.createCustomTokenFromAccessToken(this.authToken).toPromise();
        this._customTokenService.setCustomToken(createdCustomToken);
        await this._authService.login(createdCustomToken).toPromise();
        await this._router.navigateByUrl(`/editor/v3/${this.documentId}`);
      } catch (error) {
        console.log('ERROR: ', error);
      }
    });
  }
}
