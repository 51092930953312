<div class="tkt-links-seo tkt-link-panel-container">
  <section
    class="tkt-links-seo__cover"
    [ngClass]="{ '--delete': imageSrcPreview }"
  >
    <label *ngIf="!imageSrcPreview" for="tkt-links-cover-upload">
      <i class="trakto-icon-upload-cloud"></i>
      <p>
        <strong>
          {{ 'trakto_links.seo.cover_text_click' | translate }}
        </strong>
        {{ 'trakto_links.seo.cover_text' | translate }}
      </p>
      <span>
        {{ 'trakto_links.seo.cover_image_format' | translate }}
      </span>
    </label>

    <input
      id="tkt-links-cover-upload"
      type="file"
      name="img"
      accept="image/png, image/jpeg"
      [disabled]="!hasMobileLink"
      (change)="temporaryPreviewImage($event)"
    />

    <div
      *ngIf="imageSrcPreview"
      [style.backgroundImage]="'url(' + imageSrcPreview + ')'"
      class="tkt-links-seo__preview"
    >
      <ng-container *ngIf="isLoadingImage">
        <div class="tkt-links-seo__linear-progress-container">
          <div class="tkt-links-seo__linear-progress --small">
            <div class="tkt-links-seo__linear-progress-bar progress"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="imageSrcPreview && !isLoadingImage">
        <div class="tkt-links-seo__actions">
          <button
            (click)="removeTemporaryPreviewImage()"
            class="tkt-links-seo__remove-image"
          >
            <i class="trakto-icon-trash"></i>
            <p>{{ 'trakto_links.configure.remove_image' | translate }}</p>
          </button>
        </div>
      </ng-container>
    </div>
  </section>

  <form [formGroup]="formSeo" (ngSubmit)="handleSaveMetaTags()">
    <section #hasFocus class="tkt-links-seo__title">
      <p>
        {{ 'trakto_links.seo.input_title' | translate }}
      </p>
      <input
        type="text"
        formControlName="metaTitle"
        (input)="hasChange($event)"
        (focus)="focus($event, true)"
        (blur)="blur($event, true)"
        [placeholder]="'trakto_links.seo.input_placeholder' | translate"
      />

      <div
        *ngIf="!hasMobileLink"
        [ngClass]="{
          'tkt-links-seo__link-status': true,
          failed: !hasMobileLink
        }"
      >
        <p>
          {{ 'trakto_links.configure.link_alert_empty' | translate }}
        </p>
      </div>
    </section>

    <section #hasFocusDescription class="tkt-links-seo__description">
      <p>
        {{ 'trakto_links.seo.textarea_title' | translate }}
      </p>
      <textarea
        name="description"
        cols="30"
        (input)="hasChange($event)"
        (focus)="focus($event, false)"
        (blur)="blur($event, false)"
        formControlName="metaDescription"
        [placeholder]="'trakto_links.seo.textarea_placeholder' | translate"
      >
      </textarea>
    </section>

    <!-- Button Save -->
    <section class="tkt-links-seo__button-save">
      <button [disabled]="isLoadingImage || !hasMobileLink || !formSeo.valid">
        <ng-container
          *ngIf="
            (!isLoadingSave && hasChangingField) ||
            hasChangeImage ||
            hasTexto === ''
          "
        >
          <p [ngStyle]="{ opacity: isLoadingSave ? '0' : '1' }">
            {{ 'trakto_links.seo.button_save' | translate }}
          </p>
        </ng-container>
        <ng-container
          *ngIf="
            !isLoadingSave &&
            !hasChangingField &&
            !hasChangeImage &&
            status === panelsEnums.LINK_SUCCESS
          "
        >
          <i class="trakto-icon-success-ball"></i>
        </ng-container>
        <ng-container *ngIf="isLoadingSave">
          <div class="tkt-links-seo__button-save spinner"></div>
        </ng-container>
      </button>
    </section>
  </form>
</div>
