
<div class="grid" [ngClass]="{ loading: loading }">
  <ng-container *ngFor="let element of elements">
    <div
      *ngIf="element.elementUrl.length > 0"
      class="shape"
      (click)="insertElement(element)"
    >
        <span
          *ngIf="element.contentType.toLowerCase() === 'path'"
          class="svg-with-path"
          [inlineSVG]="element.elementUrl"
        >
        </span>
      <img
        *ngIf="element.contentType.toLowerCase() !== 'path'"
        [src]="element.thumbUrls.low"
        crossorigin="anonymous"
      />
    </div>
  </ng-container>
</div>

<div class="action">
  <trakto-button-loader
    *ngIf="loading || hasNextPage"
    [isLoading]="loading"
    (action)="loadNextPage()"
    label="Carregar mais"
  ></trakto-button-loader>
</div>
