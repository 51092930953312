<div class="font-group-list" *ngIf="fonts">
  <div *ngIf="(fonts | searchFont: searchFontText).length < 1">
    <p class="search-noresult">...</p>
  </div>
  <ng-container *ngFor="let font of visibleFonts; let i = index">
    <div
      *ngIf="!font.donwloadError && font.family"
      (click)="selectFont(font)"
      class="font-list__item"
      [ngClass]="{
        '--active --box': elementSelected?.fontFamily === font.family
      }"
      [style]="'--selectorColor:' + selectorColor"
    >
      <div
        class="font-list__item-favorite"
        *ngIf="!font.is_favorite"
        (click)="favoriteFont(font)"
      >
        <i class="trakto-icon-trakto-favorite"></i>
      </div>
      <div
        class="font-list__item-favorite-fill"
        *ngIf="font.is_favorite"
        (click)="unFavoriteFont(font)"
      >
        <i class="trakto-icon-trakto-favorite-fill"></i>
      </div>
      <span [style.font-family]="font.family" class="family-sample">{{
        font.family
      }}</span>
      <div *ngIf="font?.premium" class="font-list__item-premium">
        <div class="font-list__item-diamond"></div>
      </div>
      <div
        *ngIf="font?.provider === 'user'"
        (click)="deleteFont(font)"
        class="font-list__item-trash"
      >
        <i class="trakto-icon-trakto-trash"></i>
      </div>
    </div>
  </ng-container>
</div>
