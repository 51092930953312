import { AbstractControl } from '@angular/forms';

export function isHexadecimalValid(value: string): boolean {
  const regex = /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

  return regex.test(value);
}

export function HexadecimalValidator(control: AbstractControl) {
  if (!isHexadecimalValid(control.value)) {
    return {
      incorrect: true,
    };
  }
  return null;
}
