import { Component, OnInit } from '@angular/core';
import {
  ElementsService,
  EnumOrderActions,
} from '@app/editor-v3/services/elements.service';
import { PanelService } from '@app/editor-v3/services/panel.service';

@Component({
  selector: 'trakto-panel-order-mobile',
  templateUrl: './panel-order-mobile.component.html',
  styleUrls: ['./panel-order-mobile.component.scss'],
})
export class PanelOrderMobileComponent {
  constructor(
    private _panelService: PanelService,
    private _elementsService: ElementsService,
  ) {}

  closePanel() {
    this._panelService.closeLastPanel();
  }

  orderToFront() {
    this._elementsService.moveUp();
  }

  orderToBack() {
    this._elementsService.moveDown();
  }
}
