import { GoogleAnalyticsService } from '../../google/google-analytics.service';
import { DataTracking } from '@trakto/data-tracking';
import { Injectable } from '@angular/core';
import { TKTAnalytics } from '../../custom/tkt-analytics.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';

@Injectable({
    providedIn: 'root',
})
export class AssetsGalleryAnalyticsService extends DataTracking {
    private _lastSearchedTextProps: string;

    constructor(
        private _googleAnalyticsService: GoogleAnalyticsService,
        private _tktAnalytics: TKTAnalytics,
        private _userService: UserService,
        private _whitelabelService: WhitelabelProductService,
    ) {
        super();
    }

    /**
     * Usado para registrar a busca de fonts,
     * impede que a busca seja registrada mais de uma vez no segment
     */
    public get lastSearchedTextEvent(): string {
        return this._lastSearchedTextProps;
    }

    public set lastSearchedTextEvent(val: string) {
        this._lastSearchedTextProps = val;
    }

    public async trackProjectAssetSearch(props: {
        element_type:
        | 'image'
        | 'shape'
        | 'emoji'
        | 'text'
        | 'icon'
        | 'gif'
        | 'sticker';
        last_error?: any;
        search_query: string;
        success: boolean;
        total_found?: number;
        total_displayed?: number;
    }) {

        this._googleAnalyticsService.trackEvent(
            `Busca realizada na galeria de  ${props.element_type}`,
            {
                category: `Galeria de assets`,
                label: props.search_query,
                value: 1,
            },
            () => { }
        );
    }

    public searchStartedOnElementGallery(properties: {
        termo_da_busca: string,
        quantidade_de_tags_retornadas: number,
        possui_correspondencia_exata: boolean
    }) {
        this._tktAnalytics.track({
            user_id: this._userService.user.id || 'anonymous',
            event_type: "Busca realizada na galeria de elementos",
            event_properties: {
                ...properties,
                id_produto: this._whitelabelService.currentProductId || 'unknown',
            }
        });
    }
}
