import { Injectable } from '@angular/core';
import {
  TemplateCampaignPublished,
  TemplateToPublish,
  TraktoAdminApiWhiteLabelService
} from '@app/editor/services/admin-api-white-label.service';

import { IDocumentModel } from '@trakto/models';
import { ApiTeamTemplatePublishService } from './api-team-template-publish.service';
import { DocumentManagerService } from "@app/editor/services/document-manager.service";
import { Observable } from "rxjs";
import { ApiCampaignService } from '../campaign/api-team-campaign.service';

export type LastTemplatePublished = {
    design: IDocumentModel;
    campaignId: string;
    thumbnailUrl?: string;
};

@Injectable({
    providedIn: 'root',
})
export class TeamTemplateService {
    private _lastTemplatePublished: LastTemplatePublished;

    constructor(
        private _documentManagerService: DocumentManagerService,
        private _apiTeam: ApiTeamTemplatePublishService,
        private _apiWhiteLabel: TraktoAdminApiWhiteLabelService,
        private _apiCampaignService: ApiCampaignService,
    ) { }

    get lastTemplatePublished(): LastTemplatePublished {
        return this._lastTemplatePublished;
    }

    getTemplate(templateId: string) {
        return new Observable((observer) => {
            this._apiCampaignService.findByTemplateId(templateId).subscribe((templates) => {
                if (templates.length > 0) {
                    observer.next(templates[0]);
                    observer.complete();
                }
            });
        });
    }

    findCampaignByTemplateId(templateId: string) {
        return new Observable((observer) => {
            this._apiCampaignService.findCampaignByTemplateId(templateId).subscribe((templates) => {
                if (templates.length > 0) {
                    observer.next(templates);
                    observer.complete();
                }
            });
        });
    }

    async publishOnCampaign(
        design: IDocumentModel,
        campaignId: string,
        thumbnailUrl?: string,
    ): Promise <TemplateCampaignPublished> {
        const [
            templatePublished,
        ] = await Promise.all([
            this._apiTeam.publish(design, campaignId, thumbnailUrl).toPromise(),
            this._documentManagerService.updateDocumentData(design),
        ]);
        this._lastTemplatePublished = {
            design,
            campaignId,
            thumbnailUrl
        };
        return templatePublished;
    }

}
