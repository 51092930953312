import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BlurService {
  blur: number;

  panel: HTMLElement;
  header: HTMLElement;
  toolbar: HTMLElement;
  preview: HTMLElement;
  presentation: HTMLElement;
  elements: HTMLElement[];

  constructor() {
    this.blur = 2;
  }

  getElements() {
    this.panel = this.getElementNative('.trakto-panel');
    this.header = this.getElementNative('.trakto-header');
    this.toolbar = this.getElementNative('.trakto-toolbar');
    this.preview = this.getElementNative('.trakto-preview');
    this.presentation = this.getElementNative('.preview-doc');

    this.elements = [
      this.panel,
      this.header,
      this.toolbar,
      this.preview,
      this.presentation,
    ];
  }

  getElementNative(selector: string): HTMLElement {
    return document.querySelector(selector);
  }

  apply(): void {
    this.getElements();

    this.elements.map((element: HTMLElement) => {
      if (element) {
        element.style.filter = `blur(${this.blur}px)`;
      }
    });
  }

  remove(): void {
    this.getElements();

    this.elements.map((element: HTMLElement) => {
      if (element && element.hasAttribute('style')) {
        element.style.filter = null;
      }
    });
  }
}
