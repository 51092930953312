import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'gradientSafe',
})
export class GradientSafePipe implements PipeTransform {
  constructor(private _domSanitizer?: DomSanitizer) {}

  transform(value: string, args?: any): any {
    if (value && value.indexOf('linear-gradient') > -1) {
      return this._domSanitizer.bypassSecurityTrustStyle(value);
    }

    return value;
  }
}
