import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SaveStaredEventModel } from '@editor/model/document-manager/editor-events.model';
import { SaveFinishedEventModel } from '@editor/model/document-manager/save-finished-event.model';
import { DemoModeService } from '@editor/services/demomode.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DocumentManagerService } from '@services/document-manager.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { IDocumentModel } from '@trakto/models';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-auto-save-indicator',
  templateUrl: './auto-save-indicator.component.html',
  styleUrls: ['./auto-save-indicator.component.scss'],
})
export class AutoSaveIndicatorComponent implements OnInit, OnDestroy {

  public selectedDocument: IDocumentModel;
  public message = '';
  public hovered = false;
  public langTexts = {
    saving: '',
    saved: '',
    save: '',
  };

  public running = false;

  private timeCounter = 0;
  private saveTime = 15000;
  private forcedSaveTime = 15000 * 8;
  private marginError = 3000;
  private timerId: any;
  public translatedTexts: any;
  public currentLang: any;

  private _loading: boolean;
  private _isSaved: boolean;
  private _destroy$ = new Subject<void>();
  @Input() b2c: boolean;
  /**
   * get isSaved
   */
  public get isSaved(): boolean {
    return this._isSaved;
  }
  public set isSaved(value: boolean) {
    this._isSaved = value;
  }

  /**
   * get loading
   */
  public set loading(value: boolean) {
    this._loading = value;
  }
  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _signalsService: SignalsService,
    private _demoModeService: DemoModeService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _translateService: TranslateService,
  ) {
    this.loading = false;
    this.translatedTexts = this._translateService.translations;
    this.currentLang = this._translateService.currentLang;
    this.langTexts = {
      save: this.translatedTexts[this.currentLang].auto_save.save,
      saving: this.translatedTexts[this.currentLang].auto_save.saving,
      saved: this.translatedTexts[this.currentLang].auto_save.saved_changes,
    };

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.translatedTexts = event.translations;
      this.langTexts = {
        save: event.translations.auto_save.save,
        saving: event.translations.auto_save.saving,
        saved: event.translations.auto_save.saved_changes,
      };
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    if (
      this._activatedRoute.snapshot.params['document'] &&
      !this._demoModeService.isDemoMode()
    ) {      
      this._signalsService.connect(
        enumSignals.ON_APPLICATION_IS_PROCESSING,
        event => (event.data ? this.start() : this.stop()),
      );
      this.start();
      this.startForcedSave();     
    }
    this.listenEvents();
  }
  /**
   * handleSave
   */
  public handleSave() {
    if(this.loading) return;
    this.save(true);
  }

  public startForcedSave() {
    setInterval(() => {
      this.timeCounter += this.forcedSaveTime;
      if (!this.selectedDocument) {
        return;
      }
      const currentDate: Date = new Date();
      const timeDiff =
        currentDate.getTime() -
        (new Date(this.selectedDocument.updated_at).getTime() || new Date().getTime());
      const saveTime = this.forcedSaveTime - this.marginError;

      if (!this.selectedDocument.updated_at || timeDiff >= saveTime) {
        this.save(true);
      }
    }, this.forcedSaveTime);
  }

  public restart() {
    if (!this._demoModeService.isDemoMode()) {
      this.stop();
      this.start();
    }
  }

  public start() {
    if (!this.running) {
      this.running = true;
      this.timerId = setInterval(() => {
        if (!this._documentManagerService.savedChangeLast) {
          this.save(true);
        }
      }, this.saveTime);
    }
  }

  public stop() {
    this.running = false;
    clearInterval(this.timerId);
  }

  private save(forcedSave = false) {
    this.stop();
    this.loading = true;
    this._signalsService.emit(enumSignals.ON_AUTO_SAVE_PROGRESS, {
      status: 'request_save',
    });
    const done = () => {
      this._signalsService.emit(enumSignals.ON_AUTO_SAVE_PROGRESS, {
        status: 'finished_save',
      });
      this.start();
      this.loading = false;
    };
    this._documentManagerService.save(forcedSave).finally(() => done());
  }

  private listenEvents() {
    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(document => {
      this.selectedDocument = document;
    });

    this._documentManagerService.onSaveStarted.pipe(takeUntil(this._destroy$)).subscribe(
      (event: SaveStaredEventModel) => {
        if (event.isSaving()) {
          this.message = this.translatedTexts.saving;
          this.loading = true;
        } else {
          this.message = this.translatedTexts.without_changes;
          this.loading = false;
        }
      },
    );

    this._documentManagerService.onSaveFinished.pipe(takeUntil(this._destroy$)).subscribe(
      (saveFinishedEvent: SaveFinishedEventModel) => {
        if (saveFinishedEvent.saved) {
          this.message = this.translatedTexts.saved_changes;
          this.loading = false;
        }

        setTimeout(() => (this.message = ''), 2000);
      },
    );
  }
}
