<h1 matDialogTitle>{{ data.text }}</h1>
<div mat-dialog-actions align="center">
  <button
    *ngFor="let option of data.options"
    mat-button
    [matDialogClose]="option.value"
  >
    {{ option.text }}
  </button>
</div>
