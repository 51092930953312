import { Injectable } from '@angular/core';
import { ThumbService } from '@app/shared/svg-viewer/shared/thumb.service';
import { DocumentService } from '@services/document.service';
import { IDocumentModel } from '@trakto/models';
import { DataTrackingService } from '@services/analytics/wrapper';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { DataTrackingEvents as DTEventTypes } from '@trakto/data-tracking/dist/Events';
import { AuthService } from '@auth/shared/auth.service';
import { DocumentManagerService } from '@services/document-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CoverService {
  /**
   * @private _processingCover Responsible to block or not news covers requests creation.
   */
  private _processingCover = false;
  private _generatingCover = false;
  private _currentDocument: IDocumentModel;

  constructor(
    private _thumbService: ThumbService,
    private _documentService: DocumentManagerService,
    private _authService: AuthService,
    private _dataTrackingService: DataTrackingService,
    private _signalsService: SignalsService,
  ) {
    this._init();
  }

  /**
   * @description Captura o PNG com base na primeira página do documento
   *              e salva no documnto existente
   * @returns {Promise<any>}
   * @memberof Preview2Component
   */
  public generateThumb(document: IDocumentModel): Promise<any> {
    this._dataTrackingService.elapsedTime(true);
    this._signalsService.emit(enumSignals.ON_DOCUMENT_GENERATE_THUMB, true);
    return this
      .updateCover(document)
      .then(() => {
        const time = this._dataTrackingService.elapsedTime(false);
        this._dataTrackingService.track(
          DTEventTypes.EDITOR_PROJECT_GENERATE_COVER,
          {
            elapsed_time: time.stop - time.start,
            last_error: '',
            success: true,
          },
        );
        this._signalsService.emit(enumSignals.ON_DOCUMENT_GENERATE_THUMB, false);
      })
      .catch(err => {
        const time = this._dataTrackingService.elapsedTime(false);
        this._dataTrackingService.track(
          DTEventTypes.EDITOR_PROJECT_GENERATE_COVER,
          {
            elapsed_time: time.stop - time.start,
            last_error: err || '',
            success: false,
          },
        );
        this._signalsService.emit(enumSignals.ON_DOCUMENT_GENERATE_THUMB, false);
      });
  }

  public async updateCover(document: IDocumentModel) {
    if (this._processingCover) {
      return document;
    }
    try {
      if (!document.firebaseId) {
        throw new Error(`The design haven't an ID`);
      }
      await this._createOrUpdateCover(document);
      return document;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Create or Update on cover to design in edition.
   * The cover represents an thumbnail from design's first page. Generraly used like a user's design representation.
   * @param document design model in edition
   */
  private async _createOrUpdateCover(document: IDocumentModel) {
    try {
      if (this._processingCover) {
        return;
      } else {
        this._processingCover = true;
        this._currentDocument = document;
        /**
         * Create an forced pause to generate the cover/thumbnail.
         * It's is important for not overload browser's memory and save costs structure.
         */
        await this._updateThumbnail(document);
        return document;
      }
    } catch (error) {
      this._processingCover = false;
      throw new Error(error);
    }
  }

  private _init() {
    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (this._processingCover || this._generatingCover) {
        setTimeout(() => this._updateThumbnail(this._currentDocument), 0);
        event.returnValue = true;
        return true;
      }
    });
  }

  private async _updateThumbnail(document: IDocumentModel) {
    if (this._generatingCover) {
      return document;
    }
    this._generatingCover = true;
    try {
      await this._thumbService.changeAndPersistThumbnail(document);
      await this._documentService.save(true);
      this._generatingCover = false;
      this._processingCover = false;
    } catch (e) {
      this._generatingCover = false;
      this._processingCover = false;
      console.log(e);
      throw new Error(e);
    }
  }

  private _pause(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
