import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { PanelStackService } from '@editor/services/panel-stack.service';
import { ColorService } from '@shared/color/services/color.service';
import { IElementModel, IShapeElementModel, PageModel } from '@trakto/models';
import { NgColorModel } from '@shared/color/classes/color.model';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-color',
  templateUrl: './panel-color.component.html',
})
export class PanelColorComponent extends AbstractComponent<IShapeElementModel> {
  @ViewChild('element', { static: true }) element: any;

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input() currentService: ColorService;
  @Input() headerIcon: string;
  @Input() headerTitle: string;
  @Input() isMobile: boolean = false;

  @Output()
  colorChange: EventEmitter<string> = new EventEmitter(true);

  private _disabled: boolean;

  constructor(
    private _panelStackService: PanelStackService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
  }

  public close() {
    this._panelStackService.pop(500);
  }

  public changeColor(color: NgColorModel) {
    if (color && color.hexadecimal) {
      this.colorChange.emit(color.hexadecimal);
    } else {
      this.colorChange.emit(
        (color || { gradient: undefined }).gradient || '#00000000',
      );
    }
  }
}
