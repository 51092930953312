import { Injectable } from '@angular/core';
import { ItemRenderer } from '@app/shared/renderers/item-renderer.model';
import {
  BoundBoxModel,
  IPathSvgOperations,
  PointModel,
  ROTATION_ICON
} from '@trakto/core-editor';

@Injectable({
  providedIn: 'root',
})
export class PathSvgService implements IPathSvgOperations {

  public normalizePathByDimension(path: string, finalDimension = 50): { path: string, width, height } {
    const bbox = globalThis.Snap.path.getBBox(path);
    const scale = finalDimension / bbox.width;
    const normalizedPath = globalThis.Snap.path
      .map(
        path,
        new globalThis.Snap.Matrix()
          .scale(scale, scale)
          .translate(bbox.x * -1, bbox.y * -1),
      )
      .toString();
    return {
      path: normalizedPath,
      width: bbox.width * scale,
      height: bbox.height * scale,
    };
  }


  public normalizePath(path?: string): string {
    const pathBBox = globalThis.Snap.path.getBBox(path);
    const normalizationMatrix = new globalThis.Snap.Matrix().translate(
      -pathBBox.x,
      -pathBBox.y,
    );
    return globalThis.Snap.path.map(path, normalizationMatrix).toString();
  }

  public toCenter(path: string): string {
    const pathBBox = globalThis.Snap.path.getBBox(path);
    const toCenterMatrix = new globalThis.Snap.Matrix().translate(
      -pathBBox.width / 2,
      -pathBBox.height / 2,
    );
    return globalThis.Snap.path.map(path, toCenterMatrix).toString();
  }

  public getBBox(path: string):  BoundBoxModel {
    const { x, y, width, height } = globalThis.Snap.path.getBBox(path);
    return BoundBoxModel.make(x, y, width, height);
  }

  public makeRotationIconPath(x: number, y: number): string {
    const matrix = new globalThis.Snap.Matrix().translate(x, y);
    return globalThis.Snap.path.map(ROTATION_ICON, matrix).toString();
  }

  public inPath(shape: string, point: PointModel): boolean {
    return globalThis.Snap.path.isPointInside(shape, point.x, point.y);
  }

  public isValidPath(value: string): boolean {
    const path: string = value ? value.toString() : 'null';
    return (
      !path.includes('NaN') &&
      !path.includes('null') &&
      !path.includes('undefined')
    );
  }


  transformPath(xTranslate: number, yTranslate: number, xScale: number, yScale: number, sourcePath: string): string {
    const matrix = new globalThis.Snap.Matrix()
      .translate(xTranslate, yTranslate)
      .scale(xScale, yScale);
    return globalThis.Snap.path.map(sourcePath, matrix).toString();
  }

  public makeTempSvg(item: ItemRenderer) {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${ item.width } ${ item.height }">
                        <image
                            href="${ item.svg }"
                            width="${ item.width }"
                            height="${ item.width }"
                            x="0"
                            y="0"
                            fill="#000000'"
                        ></image>
                      </svg>`;
  }
}
