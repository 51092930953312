<section class="trakto-auth">
  <div class="trakto-auth__content">
    <div class="trakto-auth__header">
      <img src="assets/images/dropbox-logo.svg" alt="" />
      <i class="trakto-icon-arrow-right-dropbox"></i>
      <img src="assets/images/trakto-default.svg" alt="" />
    </div>
    <div class="trakto-auth__loading">
      <div>
        <section *ngIf="isAuthValid === 'authenticate'">
          <kml-loader
            prefix="trakto-icon-dropbox-new-white"
            suffix="trakto-icon-trakto-new-blue"
            [status]="processStatus"
          >
          </kml-loader>
        </section>

        <section *ngIf="isAuthValid === 'errorAuthenticate'">
          <kml-card-notification
            icon="trakto-icon-info"
            title="{{ 'dropbox.error_autenticate.title' | translate }}"
            description="{{
              'dropbox.error_autenticate.description' | translate
            }}"
            background="#FE4A23"
            colorDivider="rgba(239,242,249,0.2)"
            width="373px"
          >
            <trakto-button
              (click)="startDropboxProcess()"
              button--white
              button--white-hover
              button--fill
            >
              <i class="trakto-icon-retry"></i>
              <span>{{
                'dropbox.error_autenticate.button_retry' | translate
              }}</span>
            </trakto-button>
          </kml-card-notification>
        </section>

        <section *ngIf="isAuthValid === 'errorCreateDoc'">
          <kml-card-notification
            icon="trakto-icon-info"
            title="{{ 'dropbox.error_create_document.title' | translate }}"
            description="{{
              'dropbox.error_create_document.description' | translate
            }}"
            width="373px"
          >
            <trakto-button
              (click)="goToDropbox()"
              button--white
              button--white-hover
              button--fill
            >
              <span>{{
                'dropbox.error_create_document.button_back' | translate
              }}</span>
            </trakto-button>
          </kml-card-notification>
        </section>
      </div>
    </div>
  </div>
  <div class="trakto-auth__banner" [inlineSVG]="imageBackground"></div>
</section>
