import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from '@app/editor-v3/services/confirmation.service';
import { DocumentService } from '@app/editor-v3/services/DocumentService';
import { PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { PageService } from '@app/editor/services/page.service';
import { PageSVGConversionService } from '@app/shared/svg-viewer/shared/page-svg-conversion.service';
import { IDocumentModel, PageModel } from '@trakto/models';
import { ConversionParams } from '@trakto/svg-converter';
import { Subscription } from 'rxjs';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Component({
  selector: 'trakto-panel-page-mobile',
  templateUrl: './panel-page-mobile.component.html',
  styleUrls: ['./panel-page-mobile.component.scss'],
})
export class PanelPageMobileComponent implements OnInit, OnDestroy {
  private _onSelectDocument: Subscription = null;
  private _onPageSelectedIndexChange: Subscription = null;
  private _onChangeTiers: Subscription = null;
  private _subscriptions: Subscription[] = [];

  document: IDocumentModel = null;
  pageSelectedIndex: number = null;
  thumbs: string[] = [];
  height = null;

  pageSelectMode = false;
  pagesSelected: number[] = [];

  constructor(
    private _element: ElementRef,
    private _panelService: PanelService,
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _documentService: DocumentService,
    private _pageConversionService: PageSVGConversionService,
    private _confirmationService: ConfirmationService,
    private _pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.handleActiveClass();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this._onSelectDocument =
      this._documentStateManagerService.document$.subscribe(doc => {
        this.document = doc;
        this.generateDocumentThumbs(this.document);
      });

    this._onPageSelectedIndexChange =
      this._documentService.onPageSelectedIndexChange.subscribe(index => {
        this.pagesSelected = [index];
        this.pageSelectedIndex = index;
        this.generateDocumentThumbs(this.document);
      });

    this._onChangeTiers = this._panelService.onChangeTiers.subscribe(tiers => {
      if (tiers.length > 0) this.height = tiers[tiers.length - 1];
    });

    this._subscriptions.push(
      this._onSelectDocument,
      this._onPageSelectedIndexChange,
      this._onChangeTiers,
    );
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }

  handleActiveClass() {
    setTimeout(() => {
      this._element.nativeElement
        .querySelector('.panel-mobile')
        .classList.add('active');
    }, 50);
  }

  convertPageToSvg(page: PageModel): string {
    const maxHeight = 147;

    return this._pageConversionService.toSVG(
      page,
      ConversionParams.makeConversionParams(false, maxHeight / page.height),
    );
  }

  generateDocumentThumbs(document: IDocumentModel) {
    this.thumbs = document.body.map(page => this.convertPageToSvg(page));
  }

  selectPage(index: number) {
    this.pageSelectMode
      ? this.togglePageInSelection(index)
      : this._documentService.selectPageIndex(index);
  }

  togglePageSelectMode() {
    this.pageSelectMode = !this.pageSelectMode;
    this.pagesSelected = [this.pageSelectedIndex];
  }

  togglePageInSelection(pageNumber: number) {
    const prevIndex = this.pagesSelected.indexOf(pageNumber);

    if (prevIndex > -1) {
      if (this.pagesSelected.length === 1) {
        this.pagesSelected = [this.pageSelectedIndex];
        this.pageSelectMode = false;
      } else {
        this.pagesSelected.splice(prevIndex, 1);
      }
    } else {
      this.pagesSelected.push(pageNumber);
    }
  }

  deleteConfirmation() {
    const inputs = {
      icon: 'assets/editor-v3/images/delete.svg',
      title: 'Você tem certeza que deseja excluir?',
      description: 'Não é possível desfazer essa ação.',
      cancel: 'Não',
      confirm: 'Sim, quero excluir',
    };

    const outputs = {
      action: {
        emit: () => this.handleDelete(),
      },
    };

    this._confirmationService.show(inputs, outputs);
  }

  getPagesSelected(): number[] {
    return this.pageSelectMode
      ? [...this.pagesSelected]
      : [this.pageSelectedIndex];
  }

  handleDelete() {
    const pagesIndexes = this.getPagesSelected();
    this._documentService.deletePagesIndexes(pagesIndexes);
    this.pageSelectMode = false;
    this.pageSelectedIndex = 0;
    this.pagesSelected = [this.pageSelectedIndex];
    this.generateDocumentThumbs(this.document);
  }

  handleDuplicate() {
    const pagesIndexes = this.getPagesSelected();
    this._documentService.duplicatePagesIndexes(pagesIndexes);
    this.pageSelectMode = false;
    this.pagesSelected = [this.pageSelectedIndex];
    this.generateDocumentThumbs(this.document);
  }

  openPropertiesPagePanel() {
    this._panelService.openPanel('propertiesPage');
  }

  async addPage() {
    const newPage = this._pageService.cloneClearPage(
      await this._documentManagerService.getSelectedPage(),
    );

    this._documentManagerService.addPage(newPage);
  }
}
