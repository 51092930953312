import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  ElementsGalleryService,
  enumTextType,
} from '@app/shared/elements-gallery/elements-gallery.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import {
  Authors,
  ISearchEvent,
} from '@editor/components/properties-panel/panel-elements/model/model';
import { enumCardTypes } from '@shared/elements/pack.entity';
import { ILocaleTag } from '@shared/elements/locale-tag.entity';
import { ElementsListComponent } from '../elements-list/elements-list.component';
import { IElementNewAPI } from '@app/shared/elements/element.entity';
import { ElementNewApiService } from '@services/element-new-api.service';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-theme-results',
  templateUrl: './theme-results.component.html',
  styleUrls: ['./theme-results.component.scss'],
})
export class ThemeResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isActive: boolean = false;
  @Input() currentSearch: ISearchEvent;

  @Output() requestClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assetSelected: EventEmitter<IElementNewAPI> =
    new EventEmitter<IElementNewAPI>();

  @ViewChild(ElementsListComponent)
  private _elementsListComponent: ElementsListComponent;

  private _onExpandPanelChange: Subscription = null;
  private _subscriptions: Subscription[] = [];

  public enumCardTypes = enumCardTypes;
  public enumTextType = enumTextType;

  public universeTags: ILocaleTag[] = [];
  public isPanelExpanded = false;
  public searchType = SearchTypeEnum.THEME;
  public currentLang = 'pt-BR';
  private _destroy$ = new Subject<void>();

  constructor(
    private _elementsGalleryService: ElementsGalleryService,
    private _elementNewApiService: ElementNewApiService,
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = event.lang;
      },
    );
  }

  /**
   * Quando painel é exibido, as informações
   * do tema vigente e a lista de tags
   * desse tema são obtidas. Também verifica
   * a tag pesquisada anteriormente, e
   * a aplica na pesquisa atual
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (
      this._elementsGalleryService.compareChanges(changes.isThemeSearchActive)
    ) {
      this.fetchTheme();

      const storeTag = this.currentSearch?.tags[0];
      this.universeTags = await this._elementNewApiService.getTags({
        query: storeTag?.name,
      });
      storeTag && this.filterTags(storeTag);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  /**
   * Filtra, localmente, os elementos exibidos,
   * com base na tag desejada
   *
   * @param tag string - Tag a ser filtrada
   */
  filterTags(tag: ILocaleTag = null) {
    this.currentSearch = {
      ...this.currentSearch,
      tags: [tag],
    };
    this._elementsGalleryService.setElementsPanelStore(this.currentSearch);
  }

  /**
   * Obtém o idioma vigente, para realizar as
   * buscas corretamente dentro dos temas, e modifica
   * o valor da variável isPanelExpanded quando
   * painel é expandido ou retraído
   */
  initSubscriptions() {
      this._panelStackService.onExpandPanelChange.pipe(takeUntil(this._destroy$)).subscribe(isExpanded => {
        this.isPanelExpanded = isExpanded;
      });
  }

  /**
   * Obtém os detalhes do tema de acordo com o nome
   * do tema e seu respectivo código de idioma.
   *
   * @returns boolean - Sucesso da busca
   */
  fetchTheme(): boolean {
    if (this.currentSearch?.pack) return false;

    return true;
  }

  /**
   * Esconde o painel temático de elementos.
   */
  hideSearch() {
    this.requestClose.emit(true);
  }

  /**
   * Insere o elemento desejado no design.
   *
   * @param element any - Elemento a ser inserido
   */
  emitElement(element: IElementNewAPI) {
    this.assetSelected.emit(element);
  }

  scrollToNextPage() {
    this._elementsListComponent.scrollToNextPage();
  }

  getAuthorsName(authors: Authors[]): string {
    return authors.map(p => p.name).join(', ');
  }
}
