<div class="inline-blur"></div>
<div class="inline-menu" [style]="'--inlineMainColor:' + mainColorSelected">
  <div class="inline-menu__element">
    <div class="inline-menu__dropdown" (click)="openPanelFont()">
      <span>{{ fontSelected }}</span>
      <i class="trakto-icon-inline-arrow-down"></i>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.font' | translate }}
    </span>
  </div>
  <div class="inline-menu__divider"></div>
  <div class="inline-menu__element">
    <div
      class="inline-menu__dropdown"
      (click)="changeMenuExpanded('textoptions')"
    >
      <span>{{
        'text-menu-inline.styles.' + textOptionSelected | translate
      }}</span>
      <i class="trakto-icon-inline-arrow-down"></i>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.text_style' | translate }}
    </span>

    <!-- início do text options -->
    <div class="options text-options" *ngIf="menuExpanded === 'textoptions'">
      <div
        class="text-options__element"
        (click)="changeTextOptionSelected(textOptionsEnumValues.title)"
        [ngClass]="{
          'text-options__element--selected':
            textOptionSelected === textOptionsEnumValues.title
        }"
      >
        {{ 'text-menu-inline.styles.title' | translate }}
        <i class="trakto-icon-inline-selected"></i>
      </div>
      <div
        class="text-options__element"
        (click)="changeTextOptionSelected(textOptionsEnumValues.subtitle)"
        [ngClass]="{
          'text-options__element--selected':
            textOptionSelected === textOptionsEnumValues.subtitle
        }"
      >
        {{ 'text-menu-inline.styles.subtitle' | translate }}
        <i class="trakto-icon-inline-selected"></i>
      </div>
      <div
        class="text-options__element"
        (click)="changeTextOptionSelected(textOptionsEnumValues.paragraph)"
        [ngClass]="{
          'text-options__element--selected':
            textOptionSelected === textOptionsEnumValues.paragraph
        }"
      >
        {{ 'text-menu-inline.styles.paragraph' | translate }}
        <i class="trakto-icon-inline-selected"></i>
      </div>
      <div
        class="text-options__element"
        (click)="changeTextOptionSelected(textOptionsEnumValues.normal)"
        [ngClass]="{
          'text-options__element--selected':
            textOptionSelected === textOptionsEnumValues.normal
        }"
      >
        {{ 'text-menu-inline.styles.normal' | translate }}
        <i class="trakto-icon-inline-selected"></i>
      </div>
      <div
        class="text-options__element text-options__element--list"
        (click)="changeTextOptionSelected(textOptionsEnumValues.list)"
        [ngClass]="{
          'text-options__element--selected':
            textOptionSelected === textOptionsEnumValues.list
        }"
      >
        {{ 'text-menu-inline.styles.list' | translate }}
        <i class="trakto-icon-inline-selected"></i>
      </div>
    </div>
    <!-- fim do text options -->
  </div>
  <div class="inline-menu__divider"></div>
  <div class="inline-menu__element">
    <div
      class="inline-menu__button"
      (click)="changeMenuExpanded('alignments')"
      [style.width]="'25px'"
    >
      <i
        [class]="'trakto-icon-inline-align-' + alignmentOptionSelected"
        [style.fontSize]="'16px'"
        [style.padding]="'4px'"
      ></i>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.align' | translate }}
    </span>

    <!-- início do icons options -->
    <div class="options icons-options" *ngIf="menuExpanded === 'alignments'">
      <div
        class="icons-options__element"
        (click)="changeAlignmentOptionSelected('left')"
        [ngClass]="{
          'icons-options__element--selected': alignmentOptionSelected === 'left'
        }"
      >
        <i class="trakto-icon-inline-align-left"></i>
      </div>
      <div
        class="icons-options__element"
        (click)="changeAlignmentOptionSelected('center')"
        [ngClass]="{
          'icons-options__element--selected':
            alignmentOptionSelected === 'center'
        }"
      >
        <i class="trakto-icon-inline-align-center"></i>
      </div>
      <div
        class="icons-options__element"
        (click)="changeAlignmentOptionSelected('right')"
        [ngClass]="{
          'icons-options__element--selected':
            alignmentOptionSelected === 'right'
        }"
      >
        <i class="trakto-icon-inline-align-right"></i>
      </div>
      <div
        class="icons-options__element"
        (click)="changeAlignmentOptionSelected('justify')"
        [ngClass]="{
          'icons-options__element--selected':
            alignmentOptionSelected === 'justify'
        }"
      >
        <i class="trakto-icon-inline-align-justify"></i>
      </div>
    </div>
    <!-- fim do icons options -->
  </div>
  <div class="inline-menu__element">
    <div class="inline-menu__button" (click)="changeMenuExpanded('weights')">
      <i class="trakto-icon-inline-weight-bold-main"></i>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.font_style' | translate }}
    </span>

    <!-- início do icons options -->
    <div class="options icons-options" *ngIf="menuExpanded === 'weights'">
      <div
        class="icons-options__element"
        (click)="changeWeightOptionsSelected('bold')"
        [ngClass]="{
          'icons-options__element--selected':
            weightOptionsSelected.indexOf('bold') !== -1,
          'icons-options__element--disabled': !canApplyBold
        }"
      >
        <i class="trakto-icon-inline-weight-bold"></i>
      </div>
      <div
        class="icons-options__element"
        (click)="changeWeightOptionsSelected('italic')"
        [ngClass]="{
          'icons-options__element--selected':
            weightOptionsSelected.indexOf('italic') !== -1,
          'icons-options__element--disabled': !canApplyItalic
        }"
      >
        <i class="trakto-icon-inline-weight-italic"></i>
      </div>
      <div
        class="icons-options__element"
        (click)="changeWeightOptionsSelected('underline')"
        [ngClass]="{
          'icons-options__element--selected':
            weightOptionsSelected.indexOf('underline') !== -1
        }"
      >
        <i class="trakto-icon-inline-weight-underline"></i>
      </div>
    </div>
    <!-- fim do icons options -->
  </div>
  <div class="inline-menu__element">
    <div class="inline-menu__button" (click)="changeMenuExpanded('links')">
      <i class="trakto-icon-inline-link"></i>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.link' | translate }}
    </span>
  </div>

  <div class="inline-menu__divider"></div>
  <div class="inline-menu__element inline-menu__element--spacing">
    <div class="inline-menu__button" (click)="changeMenuExpanded('mainColor')">
      <div class="color-picker color-picker-main">
        <div class="color-picker-main__background"></div>
        <div class="color-picker-main__outside"></div>
        <div class="color-picker-main__inside"></div>
      </div>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.text_color' | translate }}
    </span>
  </div>
  <div
    class="inline-menu__element inline-menu__element--spacing"
    style="margin-right: 0px"
  >
    <div
      class="inline-menu__button"
      (click)="changeMenuExpanded('backgroundColor')"
    >
      <div class="color-picker color-picker-background">
        <div
          class="color-picker-background__box"
          [ngStyle]="{
            'background-color': backgroundColorSelected
              ? backgroundColorSelected
              : 'white'
          }"
        >
          <img
            src="assets/images/inline-menu/background.png"
            *ngIf="!backgroundColorSelected"
          />
        </div>
        <div class="color-picker-background__main">
          <i
            class="trakto-icon-inline-color-main-box"
            [style]="'color:' + mainColorSelected"
          ></i>
        </div>
      </div>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.background_color' | translate }}
    </span>
  </div>

  <div class="inline-menu__divider"></div>

  <div class="inline-menu__element">
    <div class="inline-menu__button inline-menu__auto-scale__button"
         [class.auto-scale__enabled]="fontSizeScaleMode === 'auto'"
         (click)="changeFontSizeScaleMode()"
    >
      <i class="trakto-icon-auto-scale"></i>
      <span>{{ 'text-menu-inline.tooltips.font_size_scale_mode' | translate }}</span>
    </div>

    <span class="inline-menu__element__tooltip">
      {{ 'text-menu-inline.tooltips.font_size_scale_mode' | translate }}
    </span>
  </div>

  <div
    class="inline-menu__button inline-menu__auto-scale__help"
    (click)="showMenuInlineInfo()"
  >
    <i class="trakto-icon-help"></i>
  </div>

  <trakto-inline-link-input
    class="options options__unanchored small-upper"
    *ngIf="menuExpanded === 'links'"
    (apply)="applyLink($event)"
    [textElement]="textElement"
  ></trakto-inline-link-input>

  <trakto-inline-color-picker
    class="options options__unanchored big-upper"
    *ngIf="menuExpanded === 'mainColor'"
    (changed)="changeMainColorSelected($event)"
    [current]="mainColorSelected"
    [textElement]="textElement"
    [page]="page"
  ></trakto-inline-color-picker>

  <trakto-inline-color-picker
    class="options options__unanchored big-upper"
    *ngIf="menuExpanded === 'backgroundColor'"
    (changed)="changeBackgroundColorSelected($event)"
    [current]="backgroundColorSelected ? backgroundColorSelected : '-'"
    [showNullColor]="true"
    [textElement]="textElement"
    [page]="page"
  ></trakto-inline-color-picker>
</div>
