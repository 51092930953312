<trakto-panel-option-mobile
  *ngIf="!loading && !success"
  icon="magic"
  label="Remover fundo"
  (click)="removeImageBackground()"
></trakto-panel-option-mobile>

<div *ngIf="loading" class="card loading">
  <i class="icon icon-loading"></i>
  <span class="label">Por favor aguarde</span>
</div>

<div *ngIf="success" class="card success">
  <i class="icon icon-magic"></i>
  <span class="label">Fundo removido</span>
</div>
