/**
 * Local de execução da pesquisa de tags
 * MAIN - painel inicial
 * FOLDER - dentro do painel de formatos fixos
 * THEME - dentro do painel temático
 */
export enum SearchTypeEnum {
    MAIN = 'main',
    FOLDER = 'folder',
    THEME = 'theme',
}