import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HotkeyService } from '@app/editor/services/hotkeys/hotkeys.service';

@Component({
  selector: 'trakto-input-dimensions',
  templateUrl: './input-dimensions.component.html',
  styleUrls: ['./input-dimensions.component.scss'],
})
export class InputDimensionsComponent {
  @Input() name = '';

  private _value: any;

  @Input()
  public set value(value: any) {
    this._value = value || '';
  }

  public get value() {
    return this._value || '';
  }

  @Output() onChangeEmit: EventEmitter<string> = new EventEmitter<string>();

  constructor(public hotkeyService: HotkeyService) {}

  change(value: any) {
    this.value = value;
  }

  blur() {
    this.hotkeyService.enableHotkeys();
    this.onChangeEmit.emit(this.value);
  }

  keyChange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onChangeEmit.emit(this.value);
    }
  }
}
