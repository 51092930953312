import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trakto-modal-remove-bg',
  templateUrl: './modal-remove-bg.component.html',
  styleUrls: ['./modal-remove-bg.component.scss']
})
export class ModalRemoveBgComponent implements OnInit {

  public imageBackgroundBlue = 'assets/images/loading-svg-blue.svg';

  constructor() { }

  ngOnInit(): void {
  }

}
