import { Injectable } from '@angular/core';
import { ShapeElementService } from '@services/shape-element.service';
import { ShapeSVGConverter } from '@trakto/svg-converter';

@Injectable()
export class ShapeSVGConversionService extends ShapeSVGConverter {
  constructor(shapeService: ShapeElementService) {
    super(shapeService);
  }
}
