import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from './../button/button.module';
import { CheckboxModule } from './../forms/checkbox/checkbox.module';
import { ItemRendererdModule } from './../renderers/item-renderer.module';
import { DocumentListComponent } from './document-list/document-list.component';

@NgModule({
  imports: [
    CommonModule,
    ItemRendererdModule,
    CheckboxModule,
    ButtonModule,
    FormsModule,
  ],
  declarations: [DocumentListComponent],
  exports: [DocumentListComponent],
})
export class ListModule {}
