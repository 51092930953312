function toggleHover(el: HTMLElement, hovered: boolean): boolean {
  const img = el.querySelector('img');
  const src = img.getAttribute('src');

  const newsrc = hovered
    ? src.split('.')[0] + '_hover.' + src.split('.')[1]
    : src.replace('_hover', '');

  img.setAttribute('src', newsrc);
  return true;
}

export function watchHover(elements: HTMLElement[]) {
  elements.forEach(item => {
    item.addEventListener('mouseenter', () => toggleHover(item, true));
  });

  elements.forEach(item => {
    item.addEventListener('mouseleave', () => toggleHover(item, false));
  });
}
