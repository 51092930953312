import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { PanelColorComponent } from '@app/editor/components/properties-panel/panel-color/panel-color.component';
import { PanelImageSettingsComponent } from '@app/editor/components/properties-panel/panel-image-settings/panel-image-settings.component';
import { DocumentManagerService } from '@app/editor/services/document-manager.service';
import { LocaleService } from '@app/editor/services/locale.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  IBackgroundImageInfo,
  IBackgroundInfo,
  ToggleTransparencyService,
} from '@app/editor/services/toggle-transparency.service';
import { ColorService } from '@app/shared/color/services/color.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { ColorModel } from '@trakto/core-editor';
import { IElementModel, PageModel } from '@trakto/models';
import { Subscription } from 'rxjs';
import { watchHover } from '../hover.util';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-lateral-page-options',
  templateUrl: './lateral-page-options.component.html',
  styleUrls: ['./lateral-page-options.component.scss'],
})
export class LateralPageOptionsComponent extends AbstractComponent<IElementModel>
  implements AfterViewInit
{
  @ViewChildren('action') action: QueryList<ElementRef>;

  @Input() selectedPageIndex: number;
  @Input() docLength: number;
  @Input() allowNewPages: boolean;

  @Output()
  onSelectedPage: EventEmitter<boolean> = new EventEmitter();

  public onToggleChange: Subscription;
  isTransparencyToggleActive = false;
  pageLabels: any;
  optionsTooltips: any;

  constructor(
    private _colorService: ColorService,
    private _panelStackService: PanelStackService,
    private _localeService: LocaleService,
    private _signalsService: SignalsService,
    private _documentManagerService: DocumentManagerService,
    private _toggleTransparencyService: ToggleTransparencyService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    _documentStateManagerService: DocumentStateManagerService,
    ) {
      super (_documentStateManagerService);
    }

  ngOnInit(): void {
    super.ngOnInit();
    this.pageLabels = this._localeService.translations['property_panel'].page;
    this.optionsTooltips = this._localeService.translations['page-options'];

    this.onToggleChange =
      this._toggleTransparencyService.onToggleChange.pipe(takeUntil(this._destroy$)).subscribe(
        isTransparencyToggleActive => {
          this.isTransparencyToggleActive = isTransparencyToggleActive;
        },
      );
  }

  onPageSelected() {
    if (this.pageSelected.backgroundColor === '#00000000') {
      this._toggleTransparencyService.toggleChange(true);
      this._toggleTransparencyService.backgroundInfo = {
        filter: 'empty',
        color: '#FFFFFFFF',
        image: {
          opacity: 1,
        }
      };
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onToggleChange.unsubscribe();
  }

  ngAfterViewInit(): void {
    const elements = this.action.map(el => el.nativeElement);
    watchHover(elements);
  }

  changeProperty(property: string, isToggleEvent = true): void {
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: this.pageSelected.id,
      key: property,
      value: this.pageSelected[property],
      obj: this.pageSelected,
      isToggleEvent,
    });
  }

  openPageBackgroundColorPanel() {
    this._colorService.defaultColor = new ColorModel(
      null,
      this.pageSelected.backgroundColor,
    );

    this._panelStackService.stack(PanelColorComponent, {
      inputs: {
        currentService: this._colorService,
        headerIcon: null,
        headerTitle: this.pageLabels.panel_color.title,
      },
      outputs: {
        colorChange: {
          emit: (color: string) => {
            this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'backgroundColor', color);
          },
        },
      },
    });
  }

  openPageBackgroundImagePanel() {
    this._panelStackService.stack(PanelImageSettingsComponent, {
      inputs: { pageSelected: this.pageSelected },
    });
  }

  movePageUp() {
    this._documentManagerService.movePageUp(this.pageSelected);
  }

  movePageDown() {
    this._documentManagerService.movePageDown(this.pageSelected);
  }

  private _changePageBackgroundImageFilters(filter = 'empty') {
    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'filter', filter);
  }

  private _changePageBackgroundImage(image: IBackgroundImageInfo = null) {
    const fakePage = {
      id: this.pageSelected.id,
      backgroundImage: image?.image ?? null,
      backgroundImageLow: image?.low ?? null,
      backgroundImageMedium: image?.medium ?? null,
      backgroundImageHigh: image?.high ?? null,
      backgroundImageRaw: image?.raw ?? null,
      backgroundImageOpacity: image?.opacity ?? 1,
    } as PageModel;
    this._elementChangeFacadeService.changePage(fakePage);
  }

  private _changePageBackgroundColor(color = '#00000000') {
    this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'backgroundColor', color);
  }

  toggleTransparentBackgroundInPage(): void {
    this.isTransparencyToggleActive
      ? this._restoreBackground(this._toggleTransparencyService.backgroundInfo)
      : this._clearBackground();
  }

  emitNewSelectedPage(): void {
    this.onSelectedPage.emit(true);
  }

  private _clearBackground(): void {
    this._toggleTransparencyService.storeBackgroundInfo(this.pageSelected);
    this._changePageBackgroundImageFilters();
    this._changePageBackgroundImage();
    this._changePageBackgroundColor();
  }

  private _restoreBackground(infos: IBackgroundInfo): void {
    this._toggleTransparencyService.resetBackgroundInfo();
    this._changePageBackgroundImageFilters(infos.filter);
    this._changePageBackgroundImage(infos.image);
    this._changePageBackgroundColor(infos.color);
  }
}
