<div id="panel-magic-templates" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-star"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">Marcação de templates mágicos</h2>
    </div>

    <div class="panel-scroller-close" (click)="close()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-full__container">
    <div class="panel-background">
      <div class="panel-space">
        <div>
          <mat-form-field class="panel-space__field">
            <mat-select
              placeholder="{{
                'sidebar_publish.label.bella_skills.label' | translate
              }}"
              multiple="false"
              [value]="selectedBellaSkill"
              (selectionChange)="setBellaSkill($event)"
              [disabled]="elementSelected !== undefined"
            >
              <mat-option [value]="null">
                {{ 'sidebar_publish.none_options' | translate }}
              </mat-option>
              <mat-option *ngFor="let skill of bellaSkills" [value]="skill">
                {{ 'sidebar_publish.label.bella_skills.' + skill | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            class="panel-space__description"
            *ngIf="elementSelected === undefined; else elementType"
          >
            <strong>Observação:</strong> Ao modificar a Skill da Bella para esse
            template, as tags inseridas anteriormente serão perdidas.
          </div>
          <ng-template #elementType>
            <div class="panel-space__description">
              Elemento selecionado é do tipo <strong>{{ elementSelected.type }}</strong>
            </div>
          </ng-template>
        </div>
      </div>

      <h2 class="panel__title" *ngIf="selectedBellaSkill !== null">
        <i class="trakto-icon-star"></i>
        <span *ngIf="elementSelected === undefined; else hasElement">
          Tags para
          {{
            'sidebar_publish.label.bella_skills.' + selectedBellaSkill
              | translate
          }}
        </span>
        <ng-template #hasElement
          >Tags disponíveis para o elemento atual</ng-template
        >
      </h2>

      <div class="panel-space" *ngIf="selectedBellaSkill !== null">
        <div
          class="panel-space__description --links"
          *ngIf="elementSelected === undefined"
        >
          <div
            class="link"
            (click)="setIsApplyingTagsInPage(true)"
            [ngClass]="{ '--selected': isApplyingTagsInPage }"
          >
            Aplicar tags na página
          </div>
          <div class="separator"></div>
          <div
            class="link"
            (click)="setIsApplyingTagsInPage(false)"
            [ngClass]="{ '--selected': !isApplyingTagsInPage }"
          >
            Ver acervo de tags
          </div>
        </div>

        <div class="panel-space__description" *ngIf="elementSelected !== undefined">
          Para inserir uma tag, você deve selecioná-la, e clicar em
          <strong>Salvar</strong> em seguida.
        </div>

        <trakto-magic-input
          *ngFor="let input of inputs"
          [input]="input"
          [isInputAlreadyTagged]="input.isTagged"
          [isInputSelected]="
            selectedInput && selectedInput.userProperty === input.userProperty
          "
          [isDeleteSpecific]="elementSelected !== undefined || isApplyingTagsInPage"
          (click)="selectTagForElement(input)"
          (clearElement)="clearTagFromDocument($event)"
        ></trakto-magic-input>
      </div>

      <div
        class="panel-space"
        *ngIf="elementSelected !== undefined || isApplyingTagsInPage"
      >
        <div class="tags-actions">
          <div
            class="tags-actions__action"
            *ngIf="inputs.length > 0"
            (click)="saveTagSelection()"
            [ngClass]="{ '--disabled': this.selectedInput === null }"
          >
            Salvar
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
