import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { YoutubeOperations } from '@trakto/core-editor';

@Injectable()
export class YoutubeService extends YoutubeOperations {
  constructor() {
    super(`${environment.api.export_service}/youtube/thumbnail?video_id=`, '');
  }
}
