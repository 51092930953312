import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  enumImageAlternativeFolder,
  UploadStorageService,
} from '@shared/storage/upload.storage.service';
import { PageIMGConversionService } from '@shared/svg-viewer/shared/page-img-conversion.service';
import { PageUtilModel } from '@trakto/core-editor';
import {
  FileQualityEnum,
  IDocumentCoverModel,
  IDocumentModel,
  PageModel,
} from '@trakto/models';
import { ConversionParams } from '@trakto/svg-converter';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';

@Injectable()
export class ThumbService {
  constructor(
    private _afFunctions: AngularFireFunctions,
    private _pageIMGConversionService: PageIMGConversionService,
    private _uploadStorageService: UploadStorageService,
    private _documentStateManagerService: DocumentStateManagerService,
  ) {}

  /**
   * Responsável por exportar o design para o cliente que está utilizando SDK
   * @param pages
   * @param resolutions
   * @param fileType
   */
  public createImagesToSDK(
    pages: PageModel[],
    resolutions: FileQualityEnum[],
    fileType: string = 'url',
  ): Promise<any[]> {
    const exportedPages = pages.map(async page => {
      if (fileType === 'base64') {
        const exported = await this._exportPageToBase64(page, resolutions);
        const mappedExport: any = {};
        mappedExport.resolutions = {};
        /**
         * Realiza a criação do objeto de saída esperada pelos clientes do SDK
         */
        resolutions.forEach(resolution => {
          mappedExport.resolutions[resolution] = exported.filter(
            value => value.resolution === resolution,
          )[0];
        });
        return mappedExport;
      } else {
        return this._exportPagesToFileURL(page);
      }
    });

    return Promise.all(exportedPages);
  }

  public async changeAndPersistThumbnail(document: IDocumentModel) {
    await this.changeThumbnail(document);
    this._documentStateManagerService.persistDocumentChangesNoTrackable({
      cover: { ...document.cover },
      thumbs: { ...document.thumbs },
    });
  }

  public async changeThumbnail(document: IDocumentModel) {
    const newThumbnail = await this.generateThumbnail(document.body[0]);
    const finalThumbnail = {
      high: newThumbnail.high.secure_url,
      medium: newThumbnail.medium.secure_url,
      low: newThumbnail.low.secure_url,
      raw: newThumbnail.raw.secure_url,
    };
    document.cover = { ...finalThumbnail };
    document.thumbs = { ...finalThumbnail };
    return finalThumbnail;
  }

  /**
   * Generate em upload thumbnail
   *
   * @param page PageModel
   */
  public async generateThumbnail(page: PageModel): Promise<any> {
    const conversionParams = ConversionParams.makeConversionParams(false, 2);
    const imgBase64 = await this._pageIMGConversionService
      .toBase64(page, conversionParams)
      .toPromise();
    const response = await this._uploadStorageService.uploadImage({
      file: imgBase64,
      folder: enumImageAlternativeFolder.THUMBNAIL,
    });

    return this._exportCoverData(response);
  }

  /**
   * Remove thumbnail from repository
   * @param public_id: Image ID
   */
  public async deleteThumbnail(public_id: string) {
    return await this._uploadStorageService.removeImage({
      public_id,
    });
  }

  /**
   * Return a Cover data pattern
   *
   * @param data image upload response from @trakto/graphics-resource
   */
  private _exportCoverData(data: any): IDocumentCoverModel {
    const { public_id, resolutions } = data;
    return {
      high: resolutions.high,
      low: resolutions.low,
      medium: resolutions.medium,
      provider: resolutions.raw.provider,
      raw: resolutions.raw,
      public_id,
    };
  }
  private async _exportPageToBase64(
    page: PageModel,
    resolutions: FileQualityEnum[],
  ): Promise<any> {
    const exportedPage = resolutions.map(async resolution => {
      const conversionParams =
        ConversionParams.makeConversionParamsByResolution(
          false,
          FileQualityEnum[resolution],
        );
      const imgBase64 = await this._pageIMGConversionService
        .toBase64(page, conversionParams)
        .toPromise();
      const dimensions = PageUtilModel.getDimension(
        page,
        conversionParams.zoomRatio,
      );
      const bytes = new Blob([imgBase64]).size;

      return {
        resolution,
        dimensions,
        url: imgBase64,
        bytes,
      };
    });

    return Promise.all(exportedPage);
  }

  private async _exportPagesToFileURL(page: PageModel): Promise<any> {
    const conversionParams = ConversionParams.makeConversionParams(false, 2);
    const imgBase64 = await this._pageIMGConversionService
      .toBase64(page, conversionParams)
      .toPromise();

    return await this._uploadStorageService.uploadImage({ file: imgBase64 });
  }
}
