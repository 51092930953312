<div class="content-loader" *ngIf="fileUploading">
  <div class="tkt-loader__spinner"></div>
</div>
<div class="content" *ngIf="!fileUploading">
  <trakto-title-with-line [title]="uploadYourFile"></trakto-title-with-line>
  <div class="content-dashed">
    <div class="content-align">
      <img src="../../../../assets/icons/upload-cloud-duo.svg" alt="">
    </div>
    <div class="content-text">
      <p class="content-text-info">{{ 'sidebar_publish.upload_file.file-type' | translate }}</p>
    </div>
    <div class="content-align">
      <trakto-input-file
        id="upload-image-template"
        mimes="application/pdf"
        [loading]="fileUploading"
        (onChange)="uploadFile($event)"
      >
        <span>{{ 'sidebar_publish.upload_file.choose-your-file' | translate }}</span>
      </trakto-input-file>
    </div>
  </div>
  <div class="content-text">
    <!-- TODO - Adicionar link em outra task -->
    <!-- <p>{{ 'sidebar_publish.upload_file.any-questions-about' | translate }}</p>
    <p class="content-text-link">{{ 'sidebar_publish.we-help-you' | translate }}</p> -->
  </div>
</div>
