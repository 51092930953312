<div class="trakto-modal-progress__overlay"></div>

<div class="trakto-modal-progress">
  <div class="trakto-modal-progress__container">
    <div *ngIf="error === undefined">
      <h3 class="trakto-modal-progress__title">{{ title }}</h3>
      <span class="trakto-modal-progress__description">
        {{ 'modal_download.progress.description' | translate }}
      </span>
    </div>

    <div [ngSwitch]="error">
      <div class="trakto-modal-progress__content" *ngSwitchCase="true">
        <h3 class="trakto-modal-progress__title">{{ errorMsg }} 😔</h3>
        <span class="trakto-modal-progress__description">
          {{ 'modal_download.error.description' | translate }}
        </span>
      </div>
      <div class="trakto-modal-progress__content" *ngSwitchCase="false">
        <h3 class="trakto-modal-progress__title">
          {{ 'modal_download.success.title' | translate }}
        </h3>
        <span class="trakto-modal-progress__description">
          {{ 'modal_download.success.description' | translate }}
        </span>
      </div>
    </div>

    <div class="trakto-modal-progress__progress-bar">
      <span class="trakto-modal-progress__bar">
        <span
          [ngStyle]="{
            background: error ? '#F54337' : '#12CE66',
            'box-shadow': error
              ? '0 2px 9px rgba(245, 67, 55, 0.5)'
              : '0 2px 9px rgba(18, 206, 102, 0.5)'
          }"
          class="trakto-modal-progress__progress"
          [style.width.%]="progress"
        ></span>
      </span>
    </div>
  </div>
</div>
