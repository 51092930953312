import { Injectable, OnDestroy } from '@angular/core';

import {
  AlignInfo,
  AxisEnum,
  DirectionEnum,
} from '@editor/components/preview-2/shared/alignment.model';
import { ElementFactoryService } from '@services/element-factory.service';
import { ElementModelService } from '@services/element-model.service';
import { ShapeElementService } from '@services/shape-element.service';
import { TextElementService } from '@services/text-element.service';
import {
  BoundBoxModel,
  DocumentUtilModel,
  ElementModelGroupEngine,
  generateUUID,
  lightweightElementUpdate,
} from '@trakto/core-editor';
import {
  backgroundAspectEnum,
  backgroundPositionEnum,
  elementTypeEnum,
  IDocumentModel,
  IElementModel,
  inputTypeEnum,
  PageModel,
} from '@trakto/models';
import { FontsService } from './fonts.service';
import { assignObject, copyObject } from '@app/state/state.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const CLONE_SPACE = 10;

@Injectable({
  providedIn: 'root',
})
export class PageService implements OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    private newShapeService: ShapeElementService,
    private newFontFontService: TextElementService,
    private elementsModelService: ElementFactoryService,
    private newElementService: ElementModelService,
    private fonts: FontsService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  mergePageChange(targetPage: PageModel, sourcePage: PageModel): PageModel {
    if (!targetPage) {
      return;
    }
    if (this._hasElementsChange(sourcePage, targetPage)) {
      targetPage.elements.forEach(element => {
        const source = sourcePage.elements.find(el => el.id === element.id);
        assignObject(element, source);
      });
    } else {
      targetPage.elements = copyObject(sourcePage.elements);
    }
    if (
      this._hasPageChange(sourcePage, targetPage)
    ) {
      return { ...sourcePage, elements: targetPage.elements, };
    }
    return targetPage;
  }

  public copyDocument(doc: IDocumentModel): IDocumentModel {
    if (!doc.body) {
      doc.body = [];
    }

    const pages: PageModel[] = [];

    pages.map((page: PageModel) => {
      this.fonts.loadByPage(page).pipe(takeUntil(this._destroy$)).subscribe();
    });

    const newDocument = Object.assign({} as IDocumentModel, {
      ...this.elementsModelService.makeDocumentModel(),
      ...doc,
      allow_preview:
        doc.allow_preview !== undefined ? doc.allow_preview : false,
      title: doc.title ? doc.title : 'Template',
      body: [...doc.body],
    });

    DocumentUtilModel.normalizeDates(newDocument);

    newDocument.body.map((page: PageModel, index: number) => {
      if (!page.id) {
        page.id = generateUUID();
      }
      if (!page.elements) {
        page.elements = [];
      }

      page.type = elementTypeEnum.page;
      page.format =
        doc.pages && doc.pages[index] && doc.pages[index].format
          ? doc.pages[index].format || null
          : null;
    });

    return newDocument;
  }

  public addPage(
    doc: IDocumentModel,
    page: PageModel,
    targetIndex: number = -1,
  ): boolean {
    if (!doc.body[targetIndex]) {
      doc.body.push(page);
    } else {
      doc.body.splice(targetIndex, 0, page);
    }

    this.updatePagesOrders(doc);
    return true;
  }

  public cloneClearPage(page: PageModel): PageModel {
    const clonedPage = Object.assign({}, page, {
      title: '',
      backgroundColor: '#FFFFFF',
      backgroundImage: null,
      backgroundImageLow: null,
      backgroundImageMedium: null,
      backgroundImageHigh: null,
      backgroundImageRaw: null,
      backgroundImageAspect: backgroundAspectEnum.slice,
      backgroundImagePosition: backgroundPositionEnum.centerCenter,
      backgroundImageOpacity: 1,
      backgroundVideo: null,
      elements: [],
      filter: 'empty',
      id: generateUUID(),
      fontModels: [],
    });
    delete clonedPage.base64;
    return clonedPage;
  }

  public clonePage(doc: IDocumentModel, page: PageModel): PageModel {
    const newPage: PageModel = Object.assign({}, page, {
      elements: [...page.elements],
      id: generateUUID(),
    });

    if (!newPage.filter) {
      newPage.filter = 'empty';
    }

    if (newPage.elements.length > 0) {
      newPage.elements = newPage.elements.map((element: IElementModel) =>
        this.cloneElement(doc, page, element, {diffX: 0, diffY: 0}),
      );
    }
    return newPage;
  }

  public deletePage(doc: IDocumentModel, page: PageModel): boolean {
    if (!this.canDeletePage(doc)) {
      return false;
    }

    const pageIndex = this.getPageIndex(doc, page);
    doc.body.splice(pageIndex, 1);
    this.updatePagesOrders(doc);
    return true;
  }

  public movePageUp(doc: IDocumentModel, page: PageModel): boolean {
    const pageIndex = this.getPageIndex(doc, page);
    const source: PageModel = doc.body[pageIndex];
    const destination: PageModel = doc.body[pageIndex - 1];

    if (pageIndex > 0) {
      doc.body[pageIndex - 1] = source;
      doc.body[pageIndex] = destination;
      this.updatePagesOrders(doc);
      return true;
    }
    return false;
  }

  public canMovePageUp(doc: IDocumentModel, page: PageModel): boolean {
   return this.getPageIndex(doc, page) > 0;
  }

  public movePageDown(
    doc: IDocumentModel,
    page: PageModel,
    emit = true,
  ): boolean {
    const indexPage = this.getPageIndex(doc, page);
    const source: PageModel = doc.body[indexPage];
    const destination: PageModel = doc.body[indexPage + 1];

    if (indexPage < doc.body.length - 1) {
      doc.body[indexPage + 1] = source;
      doc.body[indexPage] = destination;

      this.updatePagesOrders(doc);
      return true;
    }
    return false;
  }

  public canMovePageDown(
    doc: IDocumentModel,
    page: PageModel,
  ): boolean {
    return this.getPageIndex(doc, page) < doc.body.length - 1;
  }

  public addNewElement(page: PageModel, element: IElementModel): boolean {
    this.newElementService.checkId(element);

    element.pageId = page.id;
    if (this.newElementService.isClip(element)) {
      element['submask'].pageId = page.id;
    }
    page.elements.unshift(element);
    this.updateElementDepth(page, element);
    return true;
  }

  public cloneElement(
    doc: IDocumentModel,
    page: PageModel,
    element: IElementModel,
    diff?: { diffX; diffY },
  ): IElementModel {
    let clonedElement: IElementModel;

    if (this.newElementService.isGroup(element)) {
      diff = diff || this.getGroupClonePositionDiff(doc, page, element);
      const elements = [];
      element['elements'].forEach(e => {
        if (this.newElementService.isGroup(e)) {
          elements.push(this.cloneElement(doc, page, e, diff));
        } else {
          elements.push(this.cloneSingleElement(e, diff));
        }
      });
      clonedElement = Object.assign(this.copyElement(element, diff), {
        elements,
      });
    } else {
      clonedElement = this.cloneSingleElement(
        element,
        diff || this.getClonePositionDiff(doc, page, element),
      );
    }

    return clonedElement;
  }

  public deleteElement(page: PageModel, element: IElementModel): boolean {
    if (page && element.edit === true) {
      const index = this.getElementIndex(page, element);
      if (index !== -1) {
        page.elements.splice(index, 1);
        return true;
      }
    }
    return false;
  }

  public moveElementUp(
    page: PageModel,
    element: IElementModel,
    emit = true,
  ): boolean {
    const index: number = this.getElementIndex(page, element);

    if (index > 0) {
      const source: IElementModel = page.elements[index];
      const destination: IElementModel = page.elements[index - 1];

      const sourceIsYoutube: boolean = source.type === elementTypeEnum.youtube;
      const destinationIsYoutube: boolean =
        destination.type === elementTypeEnum.youtube;

      if (
        (sourceIsYoutube && destinationIsYoutube) ||
        (!sourceIsYoutube && !destinationIsYoutube)
      ) {
        page.elements[index] = destination;
        page.elements[index - 1] = source;
        this.updateElementsOrders(page);
        return true;
      }
    }

    return false;
  }

  public canMoveElementUp(
    page: PageModel,
    element: IElementModel,
  ): boolean {
    const index: number = this.getElementIndex(page, element);

    if (index > 0) {
      const source: IElementModel = page.elements[index];
      const destination: IElementModel = page.elements[index - 1];

      const sourceIsYoutube: boolean = source.type === elementTypeEnum.youtube;
      const destinationIsYoutube: boolean =
        destination.type === elementTypeEnum.youtube;

      if (
        (sourceIsYoutube && destinationIsYoutube) ||
        (!sourceIsYoutube && !destinationIsYoutube)
      ) {
        return true;
      }
    }

    return false;
  }

  public moveElementDown(page: PageModel, element: IElementModel): boolean {
    const index: number = this.getElementIndex(page, element);

    if (index < page.elements.length - 1) {
      const source: IElementModel = page.elements[index];
      const destination: IElementModel = page.elements[index + 1];

      const sourceIsYoutube: boolean = source.type === elementTypeEnum.youtube;
      const destinationIsYoutube: boolean =
        destination.type === elementTypeEnum.youtube;

      if (
        (sourceIsYoutube && destinationIsYoutube) ||
        (!sourceIsYoutube && !destinationIsYoutube)
      ) {
        page.elements[index] = destination;
        page.elements[index + 1] = source;
        this.updateElementsOrders(page);
        return true;
      }
    }

    return false;
  }

  public canMoveElementDown(page: PageModel, element: IElementModel): boolean {
    const index: number = this.getElementIndex(page, element);

    if (index < page.elements.length - 1) {
      const source: IElementModel = page.elements[index];
      const destination: IElementModel = page.elements[index + 1];

      const sourceIsYoutube: boolean = source.type === elementTypeEnum.youtube;
      const destinationIsYoutube: boolean =
        destination.type === elementTypeEnum.youtube;

      if (
        (sourceIsYoutube && destinationIsYoutube) ||
        (!sourceIsYoutube && !destinationIsYoutube)
      ) {
        return true;
      }
    }

    return false;
  }

  public updateElementsOrders(page: PageModel) {
    if (!page || !page.elements) {
      return;
    }
    page.elements.forEach((element: IElementModel, index: number) => {
      element.depth = index + 1;
    });
  }

  public lockElement(element: IElementModel): boolean {
    element.edit = element.edit ? false : true;

    if (element.type === elementTypeEnum.group) {
      this.newElementService
        .getAllElements([element], true)
        .forEach((e: IElementModel) => (e.edit = element.edit));
    }
    return true;
  }

  /**
   * Método para realizar o alinhamento de elemento/elementos baseado(s) em um
   * eixo, direção e com relação a página
   * @param axis {AxisEnum}
   * @param direction {DirectionEnum}
   * @param toPage {Boolean=}
   */
  public align(alignInfo: AlignInfo): void {
    const elements: IElementModel[] = alignInfo.elements;
    const engine: ElementModelGroupEngine = ElementModelGroupEngine.build(
      elements,
      alignInfo.page,
      this.newElementService,
      this.newShapeService,
      this.newFontFontService,
    );
    const isElement: boolean = elements.length === 1;

    if (elements.length > 1) {
      this.applyToSelection(engine, alignInfo);
    }

    if (alignInfo.toPage || isElement) {
      this.applyToPage(engine, alignInfo);
    }
  }

  public getElementIndex(page: PageModel, element: IElementModel): number {
    return page.elements.findIndex((p: IElementModel) => p.id === element.id);
  }

  public getPageIndex(doc: IDocumentModel, page: PageModel): number {
    return doc.body.findIndex((p: PageModel) => p.id === page.id);
  }

  private updateElementDepth(page: PageModel, element: IElementModel): void {
    const elements: IElementModel[] = page.elements;
    const videos: IElementModel[] = elements.filter(
      el => el.type === elementTypeEnum.youtube,
    );
    const elementIndex = this.getElementIndex(page, element);

    if (
      element.type !== elementTypeEnum.youtube &&
      element.type !== elementTypeEnum.group &&
      videos.length !== 0
    ) {
      const newIndex = videos.length;
      elements.splice(elementIndex, 1);
      elements.splice(newIndex, 0, element);
    }
  }

  private getPageByElement(
    doc: IDocumentModel,
    element: IElementModel,
  ): PageModel {
    let foundPage;
    doc.body.some((page: PageModel) => {
      if (page.elements.find(e => element.id === e.id)) {
        foundPage = page;
      }
      return foundPage !== undefined;
    });
    return foundPage;
  }

  private cloneSingleElement(
    element: IElementModel,
    positionDiff: { diffX; diffY },
  ): IElementModel {
    const clonedElement = this.copyElement(element, positionDiff);

    if (this.newElementService.isClip(clonedElement)) {
      clonedElement['submask'] = this.copyElement(
        clonedElement['submask'],
        positionDiff,
      );
    }

    if (!clonedElement.inputType) {
      clonedElement.inputType = inputTypeEnum.clone;
    }
    return clonedElement;
  }

  private copyElement(
    element: IElementModel,
    diff: { diffX; diffY },
  ): IElementModel {
    const copy = Object.assign(
      {} as IElementModel,
      element,
      element['submask'] ? { submask: { ...element['submask'] }} : {},
      {
        id: generateUUID(),
        cx: element.cx + diff.diffX,
        cy: element.cy + diff.diffY,
      }
    );
    return lightweightElementUpdate(copy);
  }

  updatePagesOrders(doc: IDocumentModel) {
    if (!doc.body) {
      return;
    }
    doc.body.forEach((pageModel: PageModel, index: number) => {
      pageModel.order = index + 1;
    });
  }

  private getGroupClonePositionDiff(
    doc: IDocumentModel,
    targetPage: PageModel,
    group: IElementModel,
  ): { diffX; diffY } {
    const groupElements = this.newElementService.getAllElements([group]);
    const sortedElements = [...groupElements].sort(
      (e1, e2) => e2.scaleX * e2.scaleY - e1.scaleX * e1.scaleY,
    );
    return this.getClonePositionDiff(doc, targetPage, sortedElements[0], group);
  }

  private getClonePositionDiff(
    doc: IDocumentModel,
    targetPage: PageModel,
    topCopy: IElementModel,
    sourceElement?: IElementModel,
  ): { diffX; diffY } {
    const sourcePage: PageModel = this.getPageByElement(
      doc,
      sourceElement || topCopy,
    );
    const space: { diffX; diffY } = { diffX: 0, diffY: 0 };

    if (sourcePage && sourcePage.id === targetPage.id) {
      const targetElement = topCopy['submask'] ? topCopy['submask'] : topCopy;
      space.diffX = Math.abs(CLONE_SPACE * targetElement.scaleX);
      space.diffY = Math.abs(CLONE_SPACE * targetElement.scaleY);
    }

    return space;
  }

  /**
   * Método para realizar o alinhamento de elementos baseados em um eixo,
   * direção e com relação a página
   * @param engine {ElementModelGroupEngine}
   * @param axis {AxisEnum}
   * @param direction {DirectionEnum}
   */
  private applyToSelection(
    engine: ElementModelGroupEngine,
    alignInfo: AlignInfo,
  ) {
    const selection: BoundBoxModel = engine.transform.getBoundBox();

    engine.getElementModels().map((element: IElementModel) => {
      const elementEngine: ElementModelGroupEngine = ElementModelGroupEngine.build(
        [element],
        alignInfo.page,
        this.newElementService,
        this.newShapeService,
        this.newFontFontService,
      );
      const elementBBox = elementEngine.transform.getBoundBox();
      const { width, height } = elementBBox;

      let newCenter: { cx?: number; cy?: number };

      switch (alignInfo.direction) {
        case DirectionEnum.left:
          newCenter = { cx: selection.x + width / 2 };
          break;
        case DirectionEnum.right:
          newCenter = { cx: selection.x2 - width / 2 };
          break;
        case DirectionEnum.center:
          newCenter =
            alignInfo.axis === AxisEnum.horizontal
              ? { cx: engine.element.cx }
              : { cy: engine.element.cy };
          break;
        case DirectionEnum.top:
          newCenter = { cy: selection.y + height / 2 };
          break;
        case DirectionEnum.bottom:
          newCenter = { cy: selection.y2 - height / 2 };
          break;
        default:
          console.warn(
            `Não existe a direção ${alignInfo.direction} no DirectionEnum`,
          );
          break;
      }

      elementEngine.move(newCenter);
      elementEngine.commitChanges();
    });
  }

  /**
   * Método para realizar o alinhamento de elemento(s) com relação a página
   * @param engine {ElementModelGroupEngine}
   * @param axis {AxisEnum}
   * @param direction {DirectionEnum}
   */
  private applyToPage(
    engine: ElementModelGroupEngine,
    alignInfo: AlignInfo,
  ): void {
    const { width, height }: BoundBoxModel = engine.transform.getBoundBox();

    let newCenter: { cx?: number; cy?: number };

    switch (alignInfo.direction) {
      case DirectionEnum.left:
        newCenter = { cx: width / 2 };
        break;
      case DirectionEnum.right:
        newCenter = { cx: alignInfo.page.width - width / 2 };
        break;
      case DirectionEnum.center:
        newCenter =
          alignInfo.axis === AxisEnum.horizontal
            ? { cx: alignInfo.page.width / 2 }
            : { cy: alignInfo.page.height / 2 };
        break;
      case DirectionEnum.top:
        newCenter = { cy: height / 2 };
        break;
      case DirectionEnum.bottom:
        newCenter = { cy: alignInfo.page.height - height / 2 };
        break;
      default:
        console.warn(
          `Não existe a direção ${alignInfo.direction} no DirectionEnum`,
        );
        break;
    }

    engine.move(newCenter);
    engine.commitChanges();
  }

  canDeletePage(doc: IDocumentModel): boolean {
    return doc && doc.body && doc.body.length > 1;
  }

  private _hasPageChange(source: PageModel, target: PageModel) {
    return target.width != source.width || target.height != source.height ||
      target.backgroundImage != source.backgroundImage || target.backgroundColor != source.backgroundColor ||
      target.backgroundImageAspect != source.backgroundImageAspect || target.backgroundImagePosition != source.backgroundImagePosition ||
      target.backgroundImageOpacity != source.backgroundImageOpacity || target.filter != source.filter;
  }

  private _hasElementsChange(source: PageModel, target: PageModel): boolean {
    return source
      && source.elements.length === target.elements.length
      && source.elements.every((e, i) => {
        const element = target.elements[i];
        return element
          && e.id === element.id
          && e.cx === element.cx
          && e.cy === element.cy
          && e.edit === element.edit
          && e.lockedByTemplate === element.lockedByTemplate
      });
  }
}
