import { Injectable, OnDestroy } from "@angular/core";
import { ICampaign, MTTag } from "@trakto/models";
import { Observable, Subject } from "rxjs";
import { ApiCampaignListResponse, ApiCampaignService, TagApiResponse } from "./api-team-campaign.service";
import { takeUntil } from "rxjs/operators";



@Injectable({
  providedIn: 'root',
})
export class CampaignService implements OnDestroy {

  private _destroy$ = new Subject<void>();

  constructor(
    private _api: ApiCampaignService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  findCampaignByTemplateId(templateId: string): Observable<ICampaign[]> {
    return this._api.findByTemplateId(templateId);
  }

  findCampaignById(campaignId: string): Observable<ICampaign> {
    return this._api.findById(campaignId);
  }

  listCampaigns(pageIndex: number = 0, limit: number = 50): Observable<ApiCampaignListResponse> {
    return this._api.findAll({ page: pageIndex, limit: limit, });
  }

  getTags(
    campaignId: string,
    pageIndex: number = 0,
    limit: number = 50
  ): Observable<MTTag[]> {
    const queryParams = {
      page: pageIndex.toString(),
      limit: limit.toString(),
    };
    return new Observable((observer) => {
      this._api.getTags(
        {
          page: pageIndex,
          limit
        },
        campaignId
      ).pipe(takeUntil(this._destroy$)).subscribe((tagList: Array<TagApiResponse>) => {
        observer.next(tagList.map((tag: any) => ({
          createdAt: tag.created_at,
          updatedAt: tag.updated_at,
          elementTarget: tag.element_target,
          propertyTarget: tag.property_target,
          descriptor: {
            label: tag.descriptor.label,
            skills: tag.descriptor.skills,
            tagName: tag.descriptor.tag_name,
          },
          teamCampaignId: tag.team_campaign_id,
          isFeatured: tag.is_featured,
        } as MTTag)));
      });
    });
  }
}
