<div class="colors-options">
  <input
    type="text"
    [value]="current"
    class="colors-options__input"
    maxlength="7"
    (focus)="inputLinkFocus()"
    (blur)="inputLinkBlur()"
    (keyup)="changeColor($event)"
  />
  <div
    *ngFor="let color of colors"
    class="colors-options__color"
    [style]="'background-color: ' + color.color"
    (click)="changed.emit(color.color)"
  ></div>
  <div
    class="colors-options__color"
    style="background-color: #ffffff; border-color: #d0daef"
    (click)="changed.emit('#ffffff')"
  ></div>
  <div
    class="colors-options__image"
    *ngIf="showNullColor"
    (click)="changed.emit(undefined)"
  >
    <img src="assets/images/inline-menu/null.svg" />
  </div>
  <div class="colors-options__image" (click)="openColorPanel()">
    <img src="assets/images/inline-menu/gradient.png" />
  </div>
</div>
