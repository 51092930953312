import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../button/button.module';

import { DropdownMenuComponent } from './dropdown-menu.component';
import { DropdownMenuItemComponent } from './item/dropdown-menu-item.component';

@NgModule({
  declarations: [DropdownMenuComponent, DropdownMenuItemComponent],
  imports: [CommonModule, ButtonModule],
  exports: [DropdownMenuComponent, DropdownMenuItemComponent],
})
export class DropdownMenuModule {}
