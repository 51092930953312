import { Injectable } from '@angular/core';
import { FacebookPixel } from './facebookPixel.abstract';

@Injectable({
  providedIn: 'root',
})
export default class FacebookPixelService extends FacebookPixel {
  constructor() {
    super();
  }

  trackCustomEvent(eventName: string): void {
    const fbq = super.getInstance();
    if (fbq) {
      fbq('trackCustom', eventName);
    }
  }

  trackPageView(): void {
    const fbq = this.getInstance();
    if (fbq) {
      fbq('track', 'PageView');
    }
  }

  trackPurchase(value: number, currency: string) {
    const fbq = super.getInstance();
    if (fbq) {
      fbq('track', 'Purchase', {
        value,
        currency,
      });
    }
  }
}
