import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPanelTypes, PanelService } from '@app/editor-v3/services/panel.service';
import { DocumentManagerService } from "@services/document-manager.service";
import { IDocumentModel } from "@trakto/models";
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-home-mobile',
  templateUrl: './panel-home-mobile.component.html',
  styleUrls: ['./panel-home-mobile.component.scss']
})
export class PanelHomeMobileComponent implements OnInit, OnDestroy {

  selectedDocument: IDocumentModel;
  private _destroy$ = new Subject<void>();

  get pages() {
    return this.selectedDocument?.body?.length || 0;
  }

  get pagesLabel() {
    return `${this.pages > 1 ? ' Páginas' : ' Página'}`;
  }

  constructor(
    private _panelService: PanelService,
    private _documentManager: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this._documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(document => {
      this.selectedDocument = document;
    })
  }

  openPanel(panel: IPanelTypes) {
    this._panelService.openPanel(panel)
  }

}
