import { IDocumentModel } from "@trakto/models";
import { NewDesignAPIService } from "./api-new-design.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class NewDesignService {
    constructor(
        private _api: NewDesignAPIService,
    ) {}

    async createBlankDesign(
        width: number,
        height: number,
        backgroundImage = "",
        elementImageUrl = ""
    ): Promise<IDocumentModel> {
        const blankDesignPayload = {
            width,
            height,
            backgroundImage,
            elementImageUrl
        };
        const blankDesign = await this._api.fromBlankDesign(blankDesignPayload);
        if (!blankDesign?.id) throw `Error creating blank design. Id is not identified.`
        return blankDesign;
    }
}