export interface IPopoverOptionsInterface {
  popoverTitle?: string;
  popoverMessage?: string;
  confirmText?: string;
  cancelText?: string;
  confirmButtonType?: string;
  cancelButtonType?: string;
  placement?: string;
  focusButton?: string;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  popoverClass?: string;
  appendToBody?: boolean;
  reverseButtonOrder?: boolean;
  closeOnOutsideClick?: boolean;
}

export class PopoverOptions implements IPopoverOptionsInterface {
  popoverTitle: string;
  popoverMessage: string;
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  confirmButtonType = 'success';
  cancelButtonType = 'default';
  placement = 'top';
  focusButton: string;
  hideConfirmButton = false;
  hideCancelButton = false;
  popoverClass = '';
  appendToBody = false;
  reverseButtonOrder = false;
  closeOnOutsideClick = true;
}
