import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor() {}

  public play(action: string = 'editor'): void {
    try {
      const a = new Audio(`assets/audio/${action}.mp3`);
      a.load();
      a.play();
    } catch (err) {
      console.error(err);
    }
  }
}
