import { Injectable } from '@angular/core';
import {
  alignEnum,
  elementTypeEnum,
  fontSizeScaleModeEnum,
  IElementModel,
  IFontModel,
  IFontOptions,
  IGroupElementModel,
  ImageElementModel,
  INotificationMessage,
  IShapeElementModel,
  ISvgElementModel,
  ITextElementModel,
  IYoutubeElementModel,
  PageModel,
} from '@trakto/models';
import { DocumentManagerService } from '@services/document-manager.service';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { MediaService } from '@services/media.service';
import { NotificationService } from '@shared/notification/notification.service';
import { ResolutionsModel } from '@trakto/graphics-resources';
import { ImageElementService } from '@services/image-element.service';
import { ImageUtilService } from '@services/image-util.service';
import { NewEmojiElementService } from '@services/new-emoji-element.service';
import { ShapeElementService } from '@services/shape-element.service';
import { IElementNewAPI } from '@shared/elements/element.entity';
import { TextEditorService } from '@services/text-editor.service';
import { FontsService } from '@services/fonts.service';
import { ElementFactoryService } from './element-factory.service';
import { ElementFactoryFacadeService } from './element-factory-facade.service';
import { NewModelService } from './new-model.service';
import { lightweightElementUpdate, makeRectPath } from '@trakto/core-editor';
import {
  ElementDatasource
} from '@app/editor/enums/editor-elements/elements-datatasource.enum';
import {
  GalleryElementType
} from '@editor/enums/editor-elements/gallery-type.enum';
import { ElementNewApiService } from '@services/element-new-api.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import { AlignInfo } from '@editor/components/preview-2/shared/alignment.model';
import { PageService } from '@services/page.service';

@Injectable({
  providedIn: 'root',
})
export class ElementChangeFacadeService {
  public notifyMSGs: INotificationMessage;
  constructor(
    private _documentManagerService: DocumentManagerService,
    private _documentStateManagerService: DocumentStateManagerService,
    private _signalsService: SignalsService,
    private _mediaService: MediaService,
    private _notificationService: NotificationService,
    private _imageElementService: ImageElementService,
    private _imageUtilService: ImageUtilService,
    private _newEmojiElementService: NewEmojiElementService,
    private _shapeElementService: ShapeElementService,
    private _textEditorService: TextEditorService,
    private _fontsService: FontsService,
    private _elementsModelService: ElementFactoryService,
    private _newModelService: NewModelService,
    private _elementService: ElementNewApiService,
    private _pageService: PageService,
  ) {
    this.notifyMSGs = this._notificationService.notificationsMSGs;
  }

  async persistElementChanges(element: IElementModel) {
    await this.persistElementsChanges([{ ...element }]);
  }

  async persistElementsChanges(elements: IElementModel[]) {
    this._documentStateManagerService.persistElementChanges(
      await this._documentManagerService.getSelectedPage(),
      elements,
    );
  }


  async persistPageChangesNoTrackable(page: PageModel) {
    this._documentStateManagerService.persistPageChangesNoTrackable(page);
  }

  async changeElementProperty(element: IElementModel, property: string, value: any) {
    this._documentStateManagerService.persistElementChanges(
      await this._documentManagerService.getSelectedPage(),
      [{ ...element, [property]: value }],
    );
    this._onChangeProperty(element, 'link');
  }

  async changePageProperty(page: PageModel, property: string, value: any, ignoreHistory = false) {
    if (!ignoreHistory) {
      this._documentStateManagerService.persistPageChanges(
        { ...page, [property]: value }
      );
    } else {
      this._documentStateManagerService.persistPageChangesNoTrackable(
        { ...page, [property]: value }
      );
    }
  }

  async changePage(page: PageModel, ignoreHistory = false) {
    if (!ignoreHistory) {
      this._documentStateManagerService.persistPageChanges(
        { ...page }
      );
    } else {
      this._documentStateManagerService.persistPageChangesNoTrackable(
        { ...page }
      );
    }
  }

  async changeLink(element: IElementModel, link: string) {
    await this.changeElementProperty(element, 'link', link);
  }

  async changeWhatsappLink(element: IElementModel, link: string) {
    await this.changeElementProperty(element, 'whatsapp', link);
  }

  async changeEmail(
    element: IElementModel,
    sender: string,
    subject: string,
    message: string,
  ) {
    const link = [
      `subject=${encodeURI(subject)}`,
      `body=${encodeURI(message)}`,
    ];
    await this.changeElementProperty(element, 'email', `mailto:${sender}?${link.join('&')}`);
  }

  async changeElementOpacity(element: IElementModel, opacity: number): Promise<IElementModel> {
    await this.changeElementProperty(element, 'opacity', opacity);
    return element;
  }

  async changeFilter(element: IElementModel, filter: string): Promise<IElementModel> {
    await this.changeElementProperty(element, 'filter', filter);
    return element;
  }

  async changePageBackgroundUrlByUrl(
    page: PageModel,
    url: string,
  ): Promise<PageModel> {
    const resolutions = this._imageUtilService.createTempResolutions(url);
    await this.changePageBackgroundUrlByUrls(page, resolutions);
    const result = await this._mediaService.uploadImageByUrl(url);
    return this.changePageBackgroundUrlByUrls(page, result.resolutions, true);
  }

  async changePageBackgroundUrlByFile(
    page: PageModel,
    file: File,
  ): Promise<PageModel> {
    const result = await this._mediaService.uploadImageByFile(file);
    this._onChangeProperty(page, 'backgroundImage');
    return this.changePageBackgroundUrlByUrls(page, result.resolutions);
  }

  async changePageBackgroundUrlByUrls(
    page: PageModel,
    urls: ResolutionsModel,
    ignoreHistory = false,
  ): Promise<PageModel> {
    const fakePage = {
      id: page.id,
      backgroundImage: urls.medium.secure_url,
      backgroundImageHigh: urls.high.secure_url,
      backgroundImageLow: urls.low.secure_url,
      backgroundImageRaw: urls.raw.secure_url,
    } as PageModel;
    await this.changePage(fakePage, ignoreHistory);
    return page;
  }

  async changeGifUrl(
    gif: ImageElementModel,
    url: string,
  ): Promise<ImageElementModel> {
    await this._changeTempImageUrlByResolutions(gif, url);
    const newGif = await this._documentStateManagerService.changeImageUrl(
      await this._documentManagerService.getSelectedPage(),
      gif,
      url
    );
    this._onChangeProperty(gif, 'href');
    return newGif;
  }

  async changeYoutubeUrl(
    youtubeElementModel: IYoutubeElementModel,
    url: string,
  ): Promise<IYoutubeElementModel> {
    youtubeElementModel.href = url;
    await this._imageElementService
      .initYoutubeModel(
        youtubeElementModel,
        await this._documentManagerService.getSelectedPage(),
        false,
        true,
      ).toPromise();
    this._onChangeProperty(youtubeElementModel, 'href');
    return youtubeElementModel;
  }

  async changeImageUrl(
    image: ImageElementModel,
    url: string,
  ): Promise<ImageElementModel> {
    await this._changeTempImageUrlByResolutions(image, url);
    const newImage = await this._documentStateManagerService.changeImageUrl(
      await this._documentManagerService.getSelectedPage(),
      image,
      url
    );
    this._onChangeProperty(image, 'href');
    return newImage;
  }

  async changeImageUrlByResolutions(
    image: ImageElementModel,
    urls: ResolutionsModel,
  ): Promise<ImageElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    const fakeImage: ImageElementModel = { ...image, submask: { ...image.submask } };
    await this._imageElementService.changeImageUrlByResolutions(fakeImage, urls);
    fakeImage.loading = false;
    this._onChangeProperty(fakeImage, 'href');
    this._documentStateManagerService.persistElementChanges(page, [fakeImage]);
    return fakeImage;
  }

  private async _changeTempImageUrlByResolutions(
    image: ImageElementModel,
    url: string,
  ): Promise<ImageElementModel> {
    const newImage = {
      ...image,
      loading: true,
      low : url,
      medium : url,
      high : url,
      raw : url,
      href : url,
    };
    this._documentStateManagerService.persistElementChanges(
      await this._documentManagerService.getSelectedPage(),
      [newImage]
    );
    this._onChangeProperty(image, 'href');
    return image;
  }

  async changeImageSubmaskByUrl(
    image: ImageElementModel,
    url: string,
  ): Promise<ImageElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    const fakeImage: ImageElementModel = { ...image, submask: { ...image.submask } };
    await this._imageElementService.changeSubmaskBySvgUrl(fakeImage, url);
    this._documentStateManagerService.persistElementChanges(page, [fakeImage]);
    this._onChangeProperty(fakeImage, 'subsmask');
    return fakeImage;
  }

  async changeImageSubmaskByPath(
    image: ImageElementModel,
    path: string,
  ): Promise<ImageElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    const fakeImage: ImageElementModel = { ...image, submask: { ...image.submask } };
    this._imageElementService.resetClip(fakeImage, path);
    this._documentStateManagerService.persistElementChanges(page, [fakeImage]);
    this._onChangeProperty(fakeImage, 'submask');
    return fakeImage;
  }

  async changeShapePath(
    shape: IShapeElementModel,
    elementAlgolia: IElementNewAPI,
  ): Promise<IShapeElementModel> {
    const fakeShape: IShapeElementModel = { ... shape };
    await this._shapeElementService.changeShapePathByUrl(
      fakeShape,
      elementAlgolia.elementUrl,
    );
    await this.persistElementChanges(fakeShape);
    this._onChangeProperty(fakeShape, 'path');
    return fakeShape;
  }

  async changeShapeColor(shape: IShapeElementModel, color: string): Promise<IElementModel> {
    await this.changeElementProperty(shape, 'backgroundColor', color);
    return shape;
  }

  async changePageBackgroundColor(page: PageModel, color: string): Promise<PageModel> {
    await this.changePageProperty(page, 'backgroundColor', color);
    return page;
  }

  async changeElementAsset(
    element: IElementModel,
    asset: IElementNewAPI,
  ) {
    const newType = this._elementService.getGalleryTypeOfElement(asset.contentType);
    const oldSource = (element as any).source || ElementDatasource.TRAKTO;
    const newSource = asset.source || ElementDatasource.TRAKTO;

    if (element.type === elementTypeEnum.shape && newType === GalleryElementType.IMAGE) {
      await this._turnsShapeIntoIconsImage(
        element as IShapeElementModel, asset.elementUrl)
      return element;
    }

    if ((element.type === elementTypeEnum.image || element.type === elementTypeEnum.emoji)
      && newType=== GalleryElementType.SHAPE) {
      await this._turnsIconIntoShape(
        element as ISvgElementModel, asset.elementUrl);
      return element;
    }

    switch (newType) {
      case GalleryElementType.EMOJI:
        if (oldSource === ElementDatasource.FLATICON && newSource === ElementDatasource.TRAKTO) {
          await this._turnsIconFromImageToSVG(
            element as ImageElementModel,
            asset.elementUrl,
          );
          break;
        }
        await this.changeIconSvg(
          element as ISvgElementModel,
          asset,
        );
        break;
      case GalleryElementType.SHAPE:
        await this.changeShapePath(
          element as IShapeElementModel,
          asset,
        );
        break;
      case GalleryElementType.IMAGE:
        if (oldSource === ElementDatasource.TRAKTO && newSource === ElementDatasource.FLATICON) {
          await this._turnsIconFromSVGToImage(
            element,
            asset.elementUrl,
            ElementDatasource.FLATICON,
          );
          break;
        }
        await this.changeImageUrl(
          element as ImageElementModel, asset.elementUrl);
        break;
    }
    return element;
  }

  async changeIconSvg(
    icon: ISvgElementModel,
    elementAlgolia: IElementNewAPI,
  ): Promise<ISvgElementModel> {
    const fakeIcon: ISvgElementModel = { ...icon };
    await this._newEmojiElementService.changeIconSvgByUrl(
      fakeIcon,
      elementAlgolia.elementUrl,
    );
    this._onChangeProperty(fakeIcon, 'svg');
    await this.persistElementChanges(fakeIcon);
    return fakeIcon;
  }

  async changeTextFontFamily(text: ITextElementModel, font: IFontModel): Promise<ITextElementModel> {
    const page = await this._documentManagerService.getSelectedPage();
    this._textEditorService.changeFontFamily(font, text, page, true);
    this._onChangeProperty(text, 'font');
    return text;
  }

  async changeTextFontSize(text: ITextElementModel, fontSize: number): Promise<ITextElementModel> {
    this._textEditorService.changeFontSize(fontSize, text, false);
    this._onChangeProperty(text, 'fontSize');
    return text;
  }

  async changeLineHeight(text: ITextElementModel, lineHeight: number): Promise<ITextElementModel> {
    this._textEditorService.changeLineHeight(lineHeight, text);
    this._onChangeProperty(text, 'lineSpace');
    return text;
  }

  async changeTextStyle(text: ITextElementModel, fontStyle: IFontOptions): Promise<IElementModel> {
    this._textEditorService.changeFontStyle(fontStyle, text, false);
    this._onChangeProperty(text, 'fontStyle');
    return text;
  }

  async changeTextStyleByWeight(text: ITextElementModel, fontWeight: number): Promise<ITextElementModel> {
    const fontModel = await this._fontsService.getDefaultFontModel().toPromise();
    const style = fontModel.options.find(o => o.weight === `${fontWeight}`) || fontModel.options[0];
    this._textEditorService.changeFontStyle(style, text, false);
    this._onChangeProperty(text, 'fontStyle');
    return text;
  }

  async changeTextColor(text: ITextElementModel, color: string): Promise<IElementModel> {
    this._textEditorService.changeColor(color, text, false);
    this._onChangeProperty(text, 'color');
    return text;
  }

  async changeTextBackgroundColor(text: ITextElementModel, color: string): Promise<IElementModel> {
    this._textEditorService.changeBackgroundColor(color, text, false);
    this._onChangeProperty(text, 'color');
    return text;
  }

  async toggleTextUnderline(text: ITextElementModel): Promise<IElementModel> {
    this._textEditorService.changeUnderline(!this._textEditorService.hasUnderline(text), text, false);
    this._onChangeProperty(text, 'underline');
    return text;
  }

  async toggleTextLineThrough(text: ITextElementModel): Promise<IElementModel> {
    this._textEditorService.changeLineThrough(!this._textEditorService.hasLineThrough(text), text, false);
    this._onChangeProperty(text, 'underline');
    return text;
  }

  async changeAlign(text: ITextElementModel, align: alignEnum): Promise<IElementModel> {
    this._textEditorService.changeAlign(align, text);
    this._onChangeProperty(text, 'align');
    return text;
  }

  async changeFontStyle(text: ITextElementModel, fontStyle: IFontOptions,): Promise<IElementModel> {
    this._textEditorService.changeFontStyle(fontStyle, text);
    this._onChangeProperty(text, 'fontStyle');
    return text;
  }

  async toggleTextScaleMode(text: ITextElementModel): Promise<IElementModel> {
    const newMode = text.fontSizeScaleMode === fontSizeScaleModeEnum.manual ? fontSizeScaleModeEnum.auto : fontSizeScaleModeEnum.manual;
    this._textEditorService.changeFontSizeScaleMode(newMode, text, false);
    this._onChangeProperty(text, 'underline');
    return text;
  }

  private async _turnsIconFromImageToSVG(previousElement: ImageElementModel, svgUrlToDownload: string): Promise<void> {
      const svg = this._newEmojiElementService.normalizeSVG(await this._mediaService.getSVG(svgUrlToDownload));
      const emojiElement = Object.assign(this._elementsModelService.makeEmojiModel(), {
        svg,
        x: previousElement.x,
        y: previousElement.y,
        cx: previousElement.cx,
        cy: previousElement.cy,
        rotation: previousElement.rotation,
      });

      this._documentManagerService.deleteElement(previousElement);
      const newElement = this._newModelService.convertElementModel(
        await this._documentManagerService.getSelectedPage(),
        emojiElement
      ) as ISvgElementModel;
      newElement.scaleX = previousElement.finalWidth/newElement.supportWidth;
      newElement.scaleY = previousElement.finalHeight/newElement.supportHeight;

      lightweightElementUpdate(newElement);
      this._documentManagerService.addNewElement(newElement);
  }

  private async _turnsShapeIntoIconsImage(shape: IShapeElementModel, imageUrlToDownload: string): Promise<void> {
    this._documentManagerService.deleteElement(shape);
    const resolutions = this._imageUtilService.createTempResolutions(imageUrlToDownload);
    const imageElement = await this._imageElementService.createClippedImageByUrls(
      resolutions,
      await this._documentManagerService.getSelectedPage()
    );

    imageElement.scaleX = shape.finalWidth / imageElement.supportWidth;
    imageElement.scaleY = shape.finalHeight / imageElement.supportHeight;
    imageElement.rotation = shape.rotation;
    imageElement.cx = shape.cx;
    imageElement.cy = shape.cy;
    (imageElement as any).source = ElementDatasource.FLATICON;
    lightweightElementUpdate(imageElement);
    const newMaskPath = makeRectPath(shape.finalWidth, shape.finalHeight);
    this._imageElementService.resetClip(imageElement, newMaskPath);
    this._documentManagerService.addNewElement(imageElement);
    await this._imageElementService.changeImageUrl(imageElement, imageUrlToDownload, true);
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      elementId: imageElement.id,
      key: 'href',
      obj: imageElement,
    });
  }

  private async _turnsIconIntoShape(icon: IElementModel, shapeUrl: string): Promise<void> {
    this._documentManagerService.deleteElement(icon);
    const path = await this._mediaService.getSVG(shapeUrl);
    const shape = Object.assign(this._elementsModelService.makeShapeModel(), {
      path,
      x: icon.x,
      y: icon.y,
      cx: icon.cx,
      cy: icon.cy,
      rotation: icon.rotation,
    });
    const newElement = this._newModelService.convertElementModel(await this._documentManagerService.getSelectedPage(), shape);
    newElement.scaleX = icon.finalWidth / newElement.supportWidth;
    newElement.scaleY = icon.finalHeight / newElement.supportHeight;
    lightweightElementUpdate(newElement);
    this._documentManagerService.addNewElement(newElement);
  }

  private async _turnsIconFromSVGToImage(previousElement: IElementModel, imageUrlToDownload: string, source: any = 'trakto'): Promise<void> {
    this._documentManagerService.deleteElement(previousElement);
    const elementModel = Object.assign(this._elementsModelService.makeImageModel(), {
      href: imageUrlToDownload,
      x: previousElement.x,
      y: previousElement.y,
      cx: previousElement.cx,
      cy: previousElement.cy,
      width: previousElement.width,
      height: previousElement.height,
      rotation: previousElement.rotation,
    });
    const newElement = this._newModelService.convertElementModel(
      await this._documentManagerService.getSelectedPage(),
      elementModel,
    );

    (newElement as any).source = source;

    newElement.scaleX = previousElement.finalWidth / previousElement.supportWidth;
    newElement.scaleY = previousElement.finalHeight / previousElement.supportHeight;

    lightweightElementUpdate(newElement);
    this._documentManagerService.addNewElement(newElement);
    newElement.loading = false;
  }

  private _onChangeProperty(element: IElementModel | PageModel, prop: string) {
    const model = {
      elementId: element.id,
      key: prop,
      obj: element,
    };
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, model);
  }

  public async alignElements(alignInfo: AlignInfo) {
    alignInfo.elements = alignInfo.elements.map(e => ({...e, submask: e['submask'] || null}));
    this._pageService.align(alignInfo);
    await this.persistElementsChanges(alignInfo.elements);
  }

  public async lockElement(element: IElementModel, lockByTemplate?: boolean): Promise<void> {
    await this.persistElementChanges(this._doLockElement(element, lockByTemplate));
  }

  private _doLockElement(element: IElementModel, lockByTemplate?: boolean, canEdit?: boolean): IElementModel {
    const fakeElement: IElementModel = {
      ...element,
      id: element.id,
      edit: canEdit === undefined ? (element.edit ? false : true) : canEdit,
      lockedByTemplate: lockByTemplate === undefined ? element.lockedByTemplate : lockByTemplate,
    } as IElementModel;

    if (fakeElement.type === elementTypeEnum.group) {
      const fakeGroup = fakeElement as IGroupElementModel;
      fakeGroup.elements = [];
      (element as IGroupElementModel).elements.forEach(e => {
        fakeGroup.elements.push(this._doLockElement(e, lockByTemplate, fakeElement.edit));
      });
    }
    return fakeElement;
  }

}
