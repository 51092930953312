import { Injectable } from '@angular/core';
import {
  GoogleAuthResponse,
  GoogleUploadPayload,
} from '@app/editor/model/google-ads.interface';
import { GoogleApiService } from './google-api.service';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AuthFirebaseService } from '@app/auth/shared/auth-firebase.service';
import { error } from 'console';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleAdsService {
  private baseUrl: string;
  private _localStorageKey = 'googleService:refreshToken';

  private cancelUpload$ = new Subject<void>();

  constructor(
    private _googleApiServce: GoogleApiService,
    private _httpClient: HttpClient,
    private _authFirebaseService: AuthFirebaseService,
  ) {
    this.baseUrl = environment.googleAdsService;
  }

  /**
   * Retornar a URL a ser acessada para permitir com que o usuário autotize para utilizar os serviços do Google
   */
  public getUrlAuthorizeUser(): Promise<GoogleAuthResponse> {
    return this._googleApiServce.executeGet<GoogleAuthResponse>(
      `auth/authorize`,
    );
  }

  public getAccounts(token: string): Promise<any> {
    return this._googleApiServce.executeGet(
      `ads/list_of_customers?refresh_token=${token}`,
    );
  }

  public getRefreshToken(): Promise<any> {
    return this._googleApiServce.executeGet<any>(`auth/refreshtoken`);
  }

  /**
   * Retorna a URL de redirecionamento após autorização de uso da API com as aplicações do Google
   */
  public getUrlredirection(code: string, state: string): Promise<string> {
    return this._googleApiServce.executeGet<string>(`auth/oauth2callback`, {
      code,
      state,
    });
  }

  public getProducts(params: GoogleUploadPayload): Promise<any> {
    return this._googleApiServce.executeGet<any>(
      `gmc/list_products?refresh_token=${params.refresh_token}&customer_id=${params.customer_id}`,
    );
  }

  /**
   * Faz upload de arquivos estáticos
   */
  public uploadFile(
    params: GoogleUploadPayload,
    formData: FormData,
  ): Observable<any> {
    return new Observable(observer => {
      const headers = {};
      this.getToken()
        .then(token => {
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }

          this._httpClient
            .post(
              `${this.baseUrl}ads/upload_image?refresh_token=${params.refresh_token}&customer_id=${params.customer_id}`,
              formData,
              { headers: headers },
            )
            .pipe(takeUntil(this.cancelUpload$))
            .subscribe(
              response => observer.next(response),
              error => observer.error(error),
              () => observer.complete(),
            );
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  public cancelUpload(): void {
    this.cancelUpload$.next(); // Emitir um sinal para cancelar a requisição
  }

  setRefreshToken(value: string) {
    localStorage.setItem(this._localStorageKey, value);
  }

  getLocalRefreshToken(): string {
    const refreshToken = localStorage.getItem(this._localStorageKey) ?? '';

    return refreshToken;
  }

  private async getToken(): Promise<string> {
    const token = await this._authFirebaseService.getAccessToken();
    return token;
  }
}
