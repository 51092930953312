<div class="trakto-dropdown-menu-header">
  <button (click)="toggle()">
    <i></i>
    <span>{{ title }}</span>
  </button>

  <div class="trakto-dropdown-menu-header__content" (click)="select()">
    <ng-content></ng-content>
  </div>
</div>
