import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TraktoLinkModalComponent } from './trakto-link-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { TraktoLinksModule } from '../trakto-links-preview/trakto-links.module';
import { TraktoLinkPanelComponent } from './trakto-link-panel/trakto-link-panel.component';
import { TraktoLinkEditablePanelComponent } from './trakto-link-editable-panel/trakto-link-editable-panel.component';
import { TraktoLinkConfigurePanelComponent } from './trakto-link-configure-panel/trakto-link-configure-panel.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SocialSliderModule } from '../properties-panel/panel-trakto-links/panel-personalize/social-slider/social-slider.module';
import { ConfirmationDialogComponent } from './trakto-link-configure-panel/confirmation-dialog/confirmation-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { PublishTraktoLinkComponent } from './publish-trakto-link/publish-trakto-link.component';
import { QrcodeDownloadComponent } from './trakto-link-panel/qrcode-download/qrcode-download.component';
import { StoreModule } from '@ngrx/store';
import { traktoLinkReducer } from './store/trakto-link.reducer';

@NgModule({
  declarations: [
    TraktoLinkModalComponent,
    TraktoLinkPanelComponent,
    TraktoLinkEditablePanelComponent,
    TraktoLinkConfigurePanelComponent,
    ConfirmationDialogComponent,
    PublishTraktoLinkComponent,
    QrcodeDownloadComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TraktoLinksModule,
    QRCodeModule,
    SocialSliderModule,
    MatIconModule,
    StoreModule.forFeature('traktoLink', traktoLinkReducer)
  ],
  exports: [
    TraktoLinkModalComponent,
    PublishTraktoLinkComponent,
  ]
})
export class TraktoLinkModalModule {

}
