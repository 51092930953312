import { TraktoLink } from "@app/editor/services/traktoLinks/trakto-link.service";
import { createAction, props } from "@ngrx/store";

export const addTraktoLink = createAction(
  "[TraktoLink] Add TraktoLink",
  props<{ traktoLink: TraktoLink }>()
);

export const editTraktoLink = createAction(
  "[TraktoLink] Edit TraktoLink",
  props<{ traktoLink: TraktoLink }>()
);
