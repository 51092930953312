import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgColorModel } from '@shared/color/classes/color.model';
import { ColorService } from '../../../services/color.service';

@Component({
  selector: 'trakto-colorpicker-palette',
  templateUrl: './colorpicker-palette.component.html',
  styleUrls: ['./colorpicker-palette.component.scss'],
})
export class ColorPickerPaletteComponent implements AfterViewInit {
  @ViewChild('canvasPalette')
  private _canvas: ElementRef<HTMLCanvasElement>;

  private _ctx: CanvasRenderingContext2D;
  private _hue: NgColorModel = new NgColorModel('rgb(255,255,255)');
  private _selectedPosition: { x: number; y: number };
  private _mousedown = false;
  private _thumbRadius = 4;
  private _canvasWidth: number;
  private _canvasHeight: number;
  private _defaultPalette: NgColorModel = new NgColorModel(
    'rgba(255,255,255,1)',
  );

  private _color: NgColorModel;

  get color() {
    return this._color;
  }

  @Input()
  set color(color: NgColorModel) {
    if (color !== this._color) {
      this._color = color;
      this._onChangeSelectedColor(this._color);
    }
  }

  @Output()
  onChange: EventEmitter<NgColorModel> = new EventEmitter();

  constructor(private _colorService: ColorService) {}

  ngAfterViewInit() {
    if (!this._ctx) {
      this._ctx = this._canvas.nativeElement.getContext('2d');
      this._canvasWidth = this._canvas.nativeElement.width;
      this._canvasHeight = this._canvas.nativeElement.height;
      this._onChangeSelectedColor(this.color);
    }
    this._draw();
  }

  public changeHue(hue: NgColorModel) {
    this._hue = hue;
    this._draw();

    if (this._selectedPosition) {
      this._updateColorAtPosition(
        this._selectedPosition.x,
        this._selectedPosition.y,
      );
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent) {
    this._mousedown = false;
  }

  onMouseUp(event: MouseEvent) {
    this._mousedown = false;
    this._updateColorAtPosition(event.offsetX, event.offsetY);
  }

  onMouseDown(event: MouseEvent) {
    this._mousedown = true;
    this._onChangePaletteSelection(event);
  }

  onMouseMove(event: MouseEvent) {
    if (this._mousedown) {
      this._onChangePaletteSelection(event);
    }
  }

  private _onChangeSelectedColor(hue) {
    this._hue = hue && hue.rgba && !hue.gradient ? hue : this._defaultPalette;

    this._selectedPosition = {
      x: this._hue.hsv[1] * 160,
      y: (1 - this._hue.hsv[2]) * 90,
    };

    this._draw();
  }

  private _draw() {
    if (this._ctx) {
      this._ctx.fillStyle = this._hue.rgba;
      this._ctx.fillRect(0, 0, this._canvasWidth, this._canvasHeight);

      const whiteGrad = this._ctx.createLinearGradient(
        0,
        0,
        this._canvasWidth,
        50,
      );
      whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
      whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

      this._ctx.fillStyle = whiteGrad;
      this._ctx.fillRect(0, 0, this._canvasWidth, this._canvasHeight);

      const blackGrad = this._ctx.createLinearGradient(
        0,
        0,
        0,
        this._canvasHeight,
      );
      blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
      blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

      this._ctx.fillStyle = blackGrad;
      this._ctx.fillRect(0, 0, this._canvasWidth, this._canvasHeight);
      this._ctx.save();

      if (this._selectedPosition) {
        this._renderThumb();
      }

      this._ctx.restore();
    }
  }

  private _renderThumb() {
    this._ctx.beginPath();
    this._ctx.arc(
      this._selectedPosition.x,
      this._selectedPosition.y,
      this._thumbRadius,
      0,
      360,
    );
    this._ctx.strokeStyle = 'rgba(255,255,255,1)';
    this._ctx.lineWidth = 2;
    this._ctx.fillStyle = 'rgba(255,255,255,1)';
    this._ctx.shadowColor = 'rgba(0,0,0,.4)';
    this._ctx.shadowBlur = 2;
    this._ctx.shadowOffsetX = 0;
    this._ctx.shadowOffsetY = 1;
    this._ctx.imageSmoothingEnabled = true;
    this._ctx.stroke();
  }

  private _onChangePaletteSelection(event: MouseEvent) {
    if (this._checkCanvasLimits(event)) {
      this._selectedPosition = { x: event.offsetX, y: event.offsetY };
      this._draw();
    }
  }

  private _updateColorAtPosition(x: number, y: number) {
    const imageData: Uint8ClampedArray = this._ctx.getImageData(
      x,
      y,
      1,
      1,
    ).data;
    const rgbaColor = `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]}, 1)`;
    this._color = new NgColorModel(rgbaColor);
    this.onChange.emit(this._color);
  }

  private _checkCanvasLimits(event: MouseEvent): boolean {
    const limitX = this._canvasWidth - 1;

    if (
      event.offsetX >= 0 &&
      event.offsetX <= limitX &&
      event.offsetY >= 0 &&
      event.offsetY <= this._canvasHeight
    ) {
      return true;
    }

    return false;
  }
}
