import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TraktoUser } from '@auth/shared/auth.model';

import { AudioService } from '@app/shared/audio/audio.service';
import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import {
  PageSVGConversionService
} from '@app/shared/svg-viewer/shared/page-svg-conversion.service';
import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DocumentManagerService } from '@services/document-manager.service';
import { NotificationService } from '@shared/notification/notification.service';

import {
  IDocumentModel,
  IElementModel,
  IEventModel,
  INotificationMessage,
  ITranslatePopoverDelete,
  PageModel,
} from '@trakto/models';

import {
  ExportUtilModel
} from '@editor/components/preview-2/shared/export-util.model';

import {
  ModalProgressComponent
} from '@app/shared/modal-progress/modal-progress.component';
import {
  ModalLoaderComponent
} from '@shared/modal-loader/modal-loader.component';
import { environment } from '@env/environment';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import {
  PageIMGConversionService
} from '@app/shared/svg-viewer/shared/page-img-conversion.service';
import { ConversionParams } from '@trakto/svg-converter';
import {
  SaveErrorEventModel
} from '../../model/document-manager/save-error-event.model';

import {
  PagePDFConversionService
} from '@app/shared/svg-viewer/shared/page-pdf-conversion.service';

import { UserSnap } from '@app/editor/services/userSnap/userSnap.service';
import { PageService } from '@services/page.service';
import { merge, of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PlanConfigService
} from '@app/shared/subscription/plan-config.service';
import { FontsService } from '@services/fonts.service';
import { generateUUID } from '@trakto/core-editor';
import {
  TraktoLinksService
} from '@app/editor/services/traktoLinks/trakto-link.service';
import { ZoomService } from '@app/editor/services/zoom.service';
import {
  TraktoLinksAnalyticsService
} from '@app/editor/services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import {
  ElementFactoryFacadeService
} from '@services/element-factory-facade.service';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  SvgEditorComponent
} from '@editor/components/preview-2/svg-editor/svg-editor.component';
import { UserService } from '@app/editor-v3/services/user.service';

moment.locale('pt-BR');

const PREVIEW_KEY_CONTEXT = 'previewkeys';

@Component({
  selector: 'trakto-preview-2',
  templateUrl: './preview2.component.html',
  styleUrls: ['./preview2.component.scss'],
})
export class Preview2Component implements OnInit, AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  public refToUpdateSvgEditor = -1;

  public notifyMsgs: INotificationMessage;

  private _subscriptions: Subscription[] = [];

  public document: IDocumentModel;
  public selectedPage: PageModel;
  public selectedPageIndex: number;
  public allowNewPages = true;
  public currentUser: TraktoUser;
  public isEmbeddedInstance = false;
  public hasWaterMask = false;

  public isDropboxInstance = false;
  public isB2C = false;

  public isUserPremium = true;

  @ViewChild(ModalLoaderComponent)
  private modalLoaderComponent: ModalLoaderComponent;

  @ViewChild(ModalProgressComponent)
  private modalProgressComponent: ModalProgressComponent;

  @ViewChild(SvgEditorComponent)
  private svgEditorComponent: SvgEditorComponent;

  private maxUploadSize: number;
  public popoverDeleteInfo: ITranslatePopoverDelete;

  private translatedTexts: any;

  private onPropertChangeSignal: Subscription;
  private onDownloadSignal: Subscription;
  private onTraktoLinkPublishSignal: Subscription;

  public hiddenPage = false;
  private _destroy$ = new Subject<void>();

  constructor(
    public documentManagerService: DocumentManagerService,
    public documentStateManagerService: DocumentStateManagerService,
    public zoomService: ZoomService,
    private _translateService: TranslateService,
    private _userSnapService: UserSnap,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _audioService: AudioService,
    private _authService: AuthService,
    private _hotkeyService: HotkeyService,
    private _fontService: FontsService,
    private _notificationService: NotificationService,
    private _pageConversionService: PageSVGConversionService,
    private _pageImgConversionService: PageIMGConversionService,
    private _pageService: PageService,
    private _signalsService: SignalsService,
    private _exportUtil: ExportUtilModel,
    private _pagePdfConversionService: PagePDFConversionService,
    private _planConfigService: PlanConfigService,
    private _traktoLinkService: TraktoLinksService,
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
  ) {
    this.selectedPageIndex = -1;
    this.checkIfNewPagesAllowed();
    this.setNotifications();
    this.makeTranslate();
    this.isDropboxInstance = this._authService.isDropboxInstance();

    this._translateService
      .get([
        'auto_save',
        'artboard',
        'modal_download',
        'brand_trakto',
        'trakto_links',
      ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(texts => {
        this.translatedTexts = texts;
      });

    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      const {
        auto_save,
        artboard,
        modal_download,
        brand_trakto,
        trakto_links,
      } = event.translations;

      this.makeTranslate();
      this.translatedTexts = {
        auto_save,
        artboard,
        modal_download,
        brand_trakto,
        trakto_links,
      };

      this.forceSvgEditorUpdate();
    });
  }

  public ngOnInit() {
    this.setZoomPadding();

    this.maxUploadSize = environment.maxUploadFile || 3.5;
    this.currentUser = this._userService.user;
    this.isB2C = this._userService.isB2c;
    this._initWaterMask();
    this.isEmbeddedInstance = this._authService.isEmbeddedInstance();

    this.onPropertChangeSignal = this._signalsService.connect(
      enumSignals.PROPERTY_CHANGE,
      (change: any) => {
        if (change.data) {
          this.propertyChange(change.data);
        }
      },
    );

    this.onDownloadSignal = this._signalsService.connect(
      enumSignals.ON_DOWNLOAD_SIGNAL,
      (signal: any) => {
        switch (signal.data.type) {
          case 'mp4':
            this.saveAsVideo(signal.data);
            break;
          case 'png':
            this.saveAsPng(signal.data);
            break;
          case 'pdf':
            this.saveAsPdf(signal.data);
            break;
        }
      },
    );

    this.onTraktoLinkPublishSignal = this._signalsService.connect(
      enumSignals.ON_TRAKTO_LINK_PUBLISH,
      () => {
        this.publishTraktoLink();
      },
    );

    this._pageConversionService.waterMaskValueChange.pipe(takeUntil(this._destroy$)).subscribe(val => {
      this.hasWaterMask = val;
      this.forceSvgEditorUpdate();
    });

    this.zoomService.fitWidthZoom(this.selectedPage, this.document);

    this._subscriptions.push(
      this.onPropertChangeSignal,
      this.onDownloadSignal,
      this.onTraktoLinkPublishSignal,
    );

    merge(
      this.zoomService.onPageZoomChange,
      this.zoomService.onZoomRatioChange,
      this.zoomService.onCurrentZoomSizeChange,
    ).subscribe(() => {
      this.forceSvgEditorUpdate();
    });
  }

  ngOnDestroy() {
    this.destroySubscriptions();
    this._destroy$.next();
  }

  destroySubscriptions(): void {
    this._subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  setZoomPadding() {
    this.zoomService.setPadding('width', 115);
    this.zoomService.setPadding('height', 50);
  }

  private async _initWaterMask() {
    this.isUserPremium = await this._planConfigService.isPremiumUser();
    this._pageConversionService.setWaterMask(
      !(this.isUserPremium || this.isEmbeddedInstance || !this.isB2C),
    );
    this.forceSvgEditorUpdate();
  }

  setNotifications() {
    this.notifyMsgs = this._notificationService.notificationsMSGs;
  }

  public ngAfterViewInit() {
    this.documentStateManagerService.document$.pipe(takeUntil(this._destroy$)).subscribe(
      doc => {
        if (this.document && doc && this.document.id === doc.id) {
          this.document = doc;
          this.selectedPageIndex = this.document.body.findIndex(p => p.id === this.selectedPage?.id);
        } else {
          this.documentSelected(doc)
        }
      }
    );

    this.documentStateManagerService.undoAndRedo$.pipe(takeUntil(this._destroy$)).subscribe(async (data) => {
      this.document = data.document;
      if (this.selectedPage && this.document?.body) {
        const page = await this.documentStateManagerService.pageSnapshot$.toPromise();
        if (page) {
          this.pageSelected(page);
          setTimeout(() => this.svgEditorComponent.selectionEngine.resetReference(this.selectedPage.elements));
        }
      }
    });

    this.documentStateManagerService.page$.pipe(takeUntil(this._destroy$)).subscribe(pageChanged => {
      if (pageChanged && pageChanged?.id === this.selectedPage?.id) {
        this.forceSvgEditorUpdate();
        return;
      }
      this.hiddenPage = true;
      setTimeout(() => {
        this.hiddenPage = false;
        this.pageSelected(pageChanged);
      }, 0);
    });

    this.documentManagerService.onSaveError.pipe(takeUntil(this._destroy$)).subscribe(event =>
      this.showFatalSaveErrorDocument(event),
    );
  }

  private checkIfNewPagesAllowed() {
    this.allowNewPages =
      this._activatedRoute.snapshot.queryParamMap.get('allowNewPages') ===
      'true' ||
      !this._activatedRoute.snapshot.queryParamMap.get('allowNewPages');
  }

  public addPage(order: number = -1) {
    this.documentManagerService.addPage(
      this.cloneClearPage(
        this.document.body[order] || this.document.body[order - 1],
      ),
      order,
    );
  }

  private documentSelected(document: IDocumentModel, initZoom = true): void {
    this.document = document;
    this.selectedPageIndex = 0;
    this.documentManagerService.selectPage(
      this.document.body[this.selectedPageIndex],
    );

    if (!initZoom) {
      return;
    }

    setTimeout(() =>
      this.zoomService.initZoomRatio(
        this.document.body[this.selectedPageIndex],
        this.document,
      ),
    );
  }

  public pageSelected(page: PageModel): void {
    this.selectedPage = page;
    this.selectedPageIndex = this.document.body.findIndex(
      p => p.id === page.id,
    );
    this._hotkeyService.enableContext(PREVIEW_KEY_CONTEXT);
    this._hotkeyService.enableContext('toolbarkeys');
    this.loadPageFonts(page);
    this.zoomService.fitZoom(page, this.document);
    this.forceSvgEditorUpdate();
  }

  public async openBasePanel(page: PageModel, showPanelPage = false) {
    if (page) {
      await this.documentManagerService.selectElement(undefined);
      await this.documentManagerService.clickElement(undefined);
      this.documentManagerService.showPagePanel(page, showPanelPage);
      this.popoverDeleteInfo.selectedPage = undefined;
    }
  }

  public clonePage(page: PageModel): PageModel {
    const pageCloned = Object.assign({}, page);
    pageCloned.id = generateUUID();
    return pageCloned;
  }

  public cloneClearPage(page: PageModel): PageModel {
    return this._pageService.cloneClearPage(page);
  }

  public propertyChange(changeEvent: any): void {
    this.forceSvgEditorUpdate();
  }

  private makeTranslate(): void {
    this.popoverDeleteInfo = {
      descriptionTranslate: '',
      buttonConfirm: '',
      buttonCancel: '',
      selectedPage: undefined,
    };

    this._translateService.get('popover').pipe(takeUntil(this._destroy$)).subscribe(key => {
      this.popoverDeleteInfo.descriptionTranslate = key['message'];
      this.popoverDeleteInfo.buttonConfirm = key['button_confirm'];
      this.popoverDeleteInfo.buttonCancel = key['button_cancel'];
    });
  }

  public drop($event: DragEvent, _page: PageModel) {
    $event.preventDefault();

    if ($event.dataTransfer.items) {
      for (let i = 0; i < $event.dataTransfer.items.length; i++) {
        if ($event.dataTransfer.items[i].kind === 'file') {
          const file = $event.dataTransfer.items[i].getAsFile();
          const fileSize = $event.dataTransfer.files[i].size || 0;
          const fileSizeInMb = fileSize / 1024 / 1024;

          if (fileSizeInMb <= this.maxUploadSize) {
            if (!file.type.includes('gif') && !file.type.includes('svg')) {
              this._elementFactoryFacadeService.createAndAddImageByFile(file);
            } else if (!file.type.includes('svg')) {
              this._elementFactoryFacadeService.createAndAddGifByFile(file);
            } else {
              this._elementFactoryFacadeService.createAndAddEmojiByFile(file);
            }
          } else {
            this._notificationService.warn(
              `${this.notifyMsgs.preview_file_size_exceeded} (${this.maxUploadSize}MB).`,
            );
          }
        }
      }
    }

    $event.preventDefault();
  }

  public dragOver($event: DragEvent) {
    $event.preventDefault();
  }

  public showFatalSaveErrorDocument(event: SaveErrorEventModel): void {
    if (event.isFatal) {
      this.modalLoaderComponent.show(event.errorMessage);
    }
  }

  public saveAsPng(data: any = null): any {
    const mode = data.data;
    const page: PageModel = this.selectedPage;
    if (!page) {
      this._notificationService.warn(
        this.notifyMsgs.page_download_selecton_info,
      );
      return;
    }

    this.modalProgressComponent.show(
      mode.modalMessage ||
        this.translatedTexts.modal_download.image_progress_title,
    );
    this._pageImgConversionService
      .downloadPGN(
        page,
        ConversionParams.makeConversionParamsByResolution(
          this.document.is_printable,
          mode.name,
        ),
        mode.title,
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.modalProgressComponent.hide(this._exportUtil.downloadComplete());
          this._userSnapService.showReviewModal(
            this._translateService.currentLang,
          );
          this._audioService.play('download');
        },
        error: err => {
          this.modalProgressComponent.hide(this._exportUtil.downloadError(err));
        },
      });
  }

  public saveAsPdf(data: any = null): void {
    const mode = data.data;
    const conversionParam = ConversionParams.makeConversionParamsByResolution(
      this.document.is_printable,
      mode.name,
    );

    this.modalProgressComponent.show(
      mode.modalMessage ||
        this.translatedTexts.modal_download.pdf_progress_title,
    );

    this._pagePdfConversionService
      .downloadPDF(
        this.document,
        conversionParam,
        mode.title,
        this._pagePdfConversionService.formatPrintRange(
          mode.range,
          this.document,
          this.selectedPage,
        ),
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          const event = this._exportUtil.downloadComplete();
          this.modalProgressComponent.hide(event);
          this._userSnapService.showReviewModal(
            this._translateService.currentLang,
          );
          this._audioService.play('download');
        },
        error: error => {
          const event = this._exportUtil.downloadError(error);
          this.modalProgressComponent.hide(event);
        },
      });
  }

  public async publishTraktoLink(): Promise<void> {
    const metadata = this.document.metadata;

    if (!metadata?.link_name || !metadata?.title) {
      this._notificationService.error(
        this.translatedTexts.trakto_links.publish_missing_data,
      );
      return;
    }

    this.modalProgressComponent.show(
      this.translatedTexts.trakto_links.waiting_publish,
    );

    try {
      await this._traktoLinkService
        .publishTraktoLInk(this._traktoLinkService.linkName, this.isB2C)
        .then(() => {
          this.modalProgressComponent.hide({
            event: 'PublishSuccess',
            data: {
              success: true,
            },
            bubbles: false,
          });
        });
      this._tktLinksAnalytics.traktoLinksPublished();
    } catch (error) {
      this.modalProgressComponent.hide({
        event: 'PublishError',
        data: {
          success: false,
          error: {
            message: this.translatedTexts.trakto_links.publish_error,
            description: '',
          },
        },
        bubbles: false,
      });
    }
  }

  public saveAsVideo(data: any): void {

    this.modalProgressComponent.show(
      data.data.modalMessage ||
        this.translatedTexts.modal_download.video_progress_title,
    );
    this._exportUtil
      .saveAsVideo(this.document, this.selectedPage)
      .then((event: IEventModel) => {
        this.modalProgressComponent.hide(event);
        this._userSnapService.showReviewModal(
          data.data.modalMessage || this._translateService.currentLang,
        );
        this._audioService.play('download');
      })
      .catch((error: IEventModel) => {
        this.modalProgressComponent.hide(error);
      });
  }

  initZoomRatio() {
    this.zoomService.initZoomRatio(this.selectedPage, this.document);
  }

  public newSelection(element: IElementModel) {
    this.documentManagerService.selectElement(element);
  }

  handleFocusedElement(element: IElementModel) {
    this.documentManagerService.clickElement(element)
  }

  persistElementsChanges($event: { elements: IElementModel[] }) {
    this.documentStateManagerService.persistElementChanges(this.selectedPage, $event.elements);
  }

  /**
   * Método para adicionar uma imagem no preview, enviar para o storage e
   * atualizar no modelo do documento o novo href
   * @param element {ElementModel}
   * @param isNew {Boolean}
   * @param addHistory {Boolean=}
   */


  private loadPageFonts(page: PageModel): void {
    this._fontService.loadByPage(page);
  }

  public historyUndo(): void {
    this.documentStateManagerService.undo();
  }

  public historyRedo(): void {
    this.documentStateManagerService.redo();
  }

  forceSvgEditorUpdate() {
    this.refToUpdateSvgEditor = Math.random();
  }

  public goToPage(index: number): void {
    this.zoomService.fitZoom(this.document.body[index], this.document);

    if (
      index >= 0 &&
      index <= this.document.body.length - 1 &&
      index !== this.selectedPageIndex
    ) {
      this.selectedPageIndex = -1;
      this.selectedPage = null;

      const delay_observable = of('').pipe(takeUntil(this.unsubscribe$));
      delay_observable.subscribe(() => {
        this.selectedPageIndex = index;
        this.documentManagerService.selectPage(
          this.document.body[this.selectedPageIndex],
        );

        this.zoomService.fitZoom(this.selectedPage, this.document);
      });
    }
  }

}
