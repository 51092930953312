<div class="overlay" *ngIf="isBellaVisible"></div>

<div
  class="bella"
  [ngClass]="{
    'bella--has-page-navigation': pagesCount > 1,
    'bella--was-above-toolbar': windowHeight < windowLimit
  }"
>
  <img
    src="assets/images/modal-bella/waiting.svg"
    alt="Bella"
    class="bella__icon"
    [ngClass]="{ 'bella__icon--inactive': !isCtaVisible && !isBellaVisible }"
    (click)="!isBellaVisible && setIsCtaVisible(true)"
  />

  <div class="bella__cta" *ngIf="isCtaVisible">
    <h3 *ngIf="username; else default">Deixa eu te ajudar, {{ username }}?</h3>
    <ng-template #default>Deixa eu te ajudar?</ng-template>
    <p>
      Oi, eu sou a Bella, a inteligência artificial da Trakto. Posso criar pra
      você legendas, conteúdos, hashtags e paleta de cores.
    </p>
    <button (click)="handleBellaVisibility(true)">
      <img src="assets/images/modal-bella/open-bella.svg" alt="Open" />
      <span>Abrir o menu da Bella</span>
    </button>
  </div>

  <div class="bella__modal" *ngIf="isBellaVisible">
    <iframe
      [ngStyle]="{ display: bellaAppUrl.length > 0 ? 'inline' : 'none' }"
      title="home-content"
      width="100%"
      height="100%"
      [src]="bellaAppUrl | safeUrl"
    ></iframe>
  </div>

  <div
    class="bella__close"
    (click)="handleBellaVisibility(false)"
    *ngIf="(isCtaVisible || isBellaVisible) && !isBellaWorking"
  >
    <i class="trakto-icon-trakto-close"></i>
  </div>
</div>
