<div
  class="tlinks-download-container"
  [style.backgroundImage]="'url(' + imageBackground + ')'"
>
  <!-- QRCode container -->
  <div class="tlinks-download-container__qrcode">
    <!-- QRCode -->
    <qrcode
      *ngIf="qrcodeLink"
      [qrdata]="qrcodeLink"
      [width]="308"
      [margin]="2"
      [errorCorrectionLevel]="'L'"
    ></qrcode>

    <!-- Profile image -->
    <i  *ngIf="profileImage" class="tlinks-download-container__profile">
      <div [style.backgroundImage]="'url(' + profileImage + ')'" alt=""></div>
    </i>
  </div>

  <!-- Link Container-->
  <div class="tlinks-download-container__links-container">
    <div>
      <img *ngIf="isB2C" src="assets/images/links-logo.svg" />
      <span>
        {{ mainLink }}
      </span>
      <b>{{ qrcodeName }}</b>
    </div>
    <img src="assets/images/links-verify.svg" />
  </div>

  <!-- Brand Container-->
  <div *ngIf="isB2C" class="tlinks-download-container__brand-container">
    <img src="assets/images/links-brand.svg" />
  </div>
</div>
