import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ImagesService } from '@app/editor-v3/services/images.service';
import { ImageElementModel } from '@trakto/models';
import { DocumentManagerService } from '@services/document-manager.service';
import { ElementFactoryFacadeService } from '@services/element-factory-facade.service';
import { ElementChangeFacadeService } from '@services/element-change-facade.service';
import { ResolutionsModel } from '@trakto/graphics-resources';
import { FileRepository } from '@editor/repository/file.repository';

@Component({
  selector: 'trakto-library-tab-mobile',
  templateUrl: './library-tab-mobile.component.html',
  styleUrls: ['./library-tab-mobile.component.scss'],
})
export class LibraryTabMobileComponent implements OnInit {
  loading = false;
  images = [];
  nextPage: any = undefined;
  hasNextPage = false;

  @Output()
  private clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _imagesService: ImagesService,
    private _filesRepository: FileRepository,
    private _documentManagerService: DocumentManagerService,
    private _elementFactoryFacadeService: ElementFactoryFacadeService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
  ) {}

  ngOnInit(): void {
    this.resetParameters();
    this.loadUserImages();
  }

  async loadUserImages() {
    this.loading = true;
    try {
      const nextPage = this.nextPage;
      this.hasNextPage = false;
      this.nextPage = undefined;
      const result = await this._filesRepository.findImagesByLoggedUser(20, nextPage);
      this.images = [...this.images, ...result.data];
      this.hasNextPage = result.hasNextPage;
      this.nextPage = result.nextCursor;
    } catch (e) {
      console.log('error', e);
    } finally {
      this.loading = false;
    }
  }

  resetParameters() {
    this.images = [];
  }

  insertImage(image: any) {
    this._insertImage(image).then();
    this.clicked.emit(true);
  }

  async _insertImage(image: { url: ResolutionsModel }) {
    if (this._imagesService.isImageToPageBackground) {
      this._imagesService.insertImageUrlsToPageBackground(image.url);
      return true;
    }

    const selectedElement = await this._documentManagerService.getSelectedElement();
    if (selectedElement) {
      await this._elementChangeFacadeService.changeImageUrlByResolutions(
        selectedElement as ImageElementModel,
        image.url,
      );
    } else {
      await this._elementFactoryFacadeService.createAndAddImageByUrls(
        image.url,
      );
    }
  }
}
