import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { AuthService } from "@app/auth/shared/auth.service";
import { environment } from "@env/environment";
import { TranslationService } from "../translation.service";
import { ItemRenderer } from '@shared/renderers/item-renderer.model';
import { TraktoApiService } from '@services/trakto-api.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class GalleryIconsService implements OnDestroy{
  private _token = '';
  private _destroy$ = new Subject<void>();

  constructor(
    private _httpClient: HttpClient,
    private _translationService: TranslationService,
    private _apiService: TraktoApiService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public async listEmojis(
    search: string,
    page?: number,
    limit?: number,
  ): Promise<any> {
    const query = await this._translationService
      .translate(search)
      .toPromise();

    if (!query) {
      throw new Error('No query to search');
    }

    const perPage = (limit > 50 || limit <= 0) ? 50 : limit;
    const reqParams = {
      keyword: query,
      per_page: `${perPage}`,
      page: `${page}`,
      to_download: 'false',
      preview_size: '100',
      provider: 'icons8'
    };
    const url = `${environment.api.baseUrl}integrations/assets/icon`;

    const result = await this._apiService.executeGet(url, reqParams);
    if (result) {
      return this._convertData(result);
    }
    return {};
  }

  public async listShapes(
    search: string,
    page?: number,
    limit?: number,
  ): Promise<any> {
    const query = await this._translationService
      .translate(search)
      .toPromise();

    if (!query) {
      throw new Error('No query to search');
    }

    const perPage = (limit > 50 || limit <= 0) ? 50 : limit;
    const reqParams = {
      keyword: query,
      per_page: `${perPage}`,
      page: `${page}`,
      provider: 'icons8',
    };

    const url = `${environment.api.baseUrl}integrations/assets/shape`;
    const result = await this._apiService.executeGet(url, reqParams);

    if (result) {
      return this._convertData(result);
    }
    return {};
  }

  public async getSVGDownloadById(id: string): Promise<ItemRenderer> {
    const body = {
      "provider": "icons8",
      "id": id
    };

    const url = `${environment.api.baseUrl}integrations/assets/icon/svg`;
    return await this._apiService.executePost<ItemRenderer>(
      url, body
    );
  }

  public listEmojisHighlights(page: number = 1, limit: number = 50) {
    return new Promise((resolve, reject) => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      };
      let getUrl = `${environment.api.icons.v2}/icons?page=${page}&limit=${limit}`;

      if (this._token) {
        getUrl = getUrl.concat(`&authorization=${this._token}`);
      }

      const httpOptions = {
        headers: new HttpHeaders(headers),
      };

      this._httpClient.get(getUrl).pipe(takeUntil(this._destroy$)).subscribe(
        (data: any) => {
          if (data.token) {
            this._token = data.token;
          }
          resolve(data);
        },
        error => {
          console.error(error);
        },
      );
    });
  }

  private _convertData(result: any) {
    return {
      ...result,
      metadata: {
        ...result.metadata,
        count: result.metadata.amount,
        page: result.metadata.offset,
        total: result.metadata.countAll,
      }
    };
  }


}
