import { 
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthFirebaseService } from "@app/auth/shared/auth-firebase.service";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(
        private _authenticationService: AuthFirebaseService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (
            req.url.includes(environment.api.baseUrl) ||
            req.url.includes(environment.api.templateUrl) ||
            req.url.includes(environment.api.bellaUrl)) {
                const token = this._getToken()
                if (!token) return next.handle(req);
        
                const clonedRequest = req.clone({
                    setHeaders: { Authorization: `Bearer ${token}` }
                });
                return next.handle(clonedRequest);
            }
            return next.handle(req);
        
    }

    private _getToken(): string {
        return this._authenticationService.accessToken;
    }
    
}