<ng-container *ngIf="showModal">
  <div [ngClass]="currentPanel === panelPageEnums.CONFIGURE ? 'modal_configure': 'modal'">
    <!-- Navbar -->
    <nav class="tlinks-sidebar__navbar" style="margin-bottom: 16px;">
      <ul>
        <li [ngClass]="{ active: currentPanel === panelPageEnums.PERSONALIZE }"
          (click)="changePanel(panelPageEnums.PERSONALIZE)">
          <i class="trakto-icon-link-drop"></i>
          <span>
            <!-- {{ 'trakto_links.navbar.button_personalize' | translate }} -->
            Link
          </span>
        </li>
        <li [ngClass]="{ active: currentPanel === panelPageEnums.SEO }" (click)="changePanel(panelPageEnums.SEO)">
          <i class="trakto-icon-arrow-selection"></i>
          <span>
            <!-- {{ 'trakto_links.navbar.button_SEO' | translate }} -->
            Doc editável
          </span>
        </li>
        <li [ngClass]="{ active: currentPanel === panelPageEnums.CONFIGURE }"
          (click)="changePanel(panelPageEnums.CONFIGURE)">
          <i class="trakto-icon-settings-2"></i>
          <span>
            <!-- {{ 'trakto_links.navbar.button_configure' | translate }} -->
            Configurações
          </span>
        </li>
      </ul>
    </nav>


    <section class="tlinks-sidebar__painel"
      [ngStyle]="{'height': currentPanel !== panelPageEnums.CONFIGURE ? '100%' : ''}">
      <!-- Personalize Panel -->
      <trakto-trakto-link-panel [doc]="doc" [isB2C]="isB2C"
        *ngIf="currentPanel === panelPageEnums.PERSONALIZE" [loading]="loading"></trakto-trakto-link-panel>

      <!-- SEO Panel -->
      <trakto-link-editable-panel [doc]="doc" [isB2C]="isB2C" [traktoLink]="traktoLink"
        *ngIf="currentPanel === panelPageEnums.SEO"></trakto-link-editable-panel>

      <!-- Configure Panel -->
      <trakto-trakto-link-configure-panel [doc]="doc" [isB2C]="isB2C" [traktoLink]="traktoLink" (onDeleteTraktoLink)="deleteTraktoLink($event)"
        *ngIf="currentPanel === panelPageEnums.CONFIGURE"></trakto-trakto-link-configure-panel>
    </section>


  </div>
  <div (click)="toggle()" class="overlay"></div>
</ng-container>
