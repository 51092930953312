import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {
  DocumentManagerService
} from '@app/editor/services/document-manager.service';
import { PanelStackService } from '@app/editor/services/panel-stack.service';
import {
  MagicTemplateService
} from '@app/shared/magic-template/shared/magic-template.service';
import {
  NotificationService
} from '@app/shared/notification/notification.service';
import { MagicTemplateGenerator } from '@trakto/magic-templates';
import {
  elementTypeEnum,
  IElementModel,
  IInputElementPanel,
  IMagicTemplateModelParam,
  MTSkillsType,
  MTTag,
  PageModel,
} from '@trakto/models';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  ElementModelFinderService
} from '@services/element-model-finder.service';
import { copyObject } from '@app/state/state.util';

interface IMagicTemplateChanges {
  id: string;
  magicTemplate: IMagicTemplateModelParam;
}

@Component({
  selector: 'trakto-panel-magic-templates',
  templateUrl: './panel-magic-templates.component.html',
  styleUrls: ['./panel-magic-templates.component.scss'],
})
export class PanelMagicTemplatesComponent extends AbstractComponent<IElementModel> {
  public selectedBellaSkill: MTSkillsType | null = null;
  public inputs: IInputElementPanel[] = [];
  public selectedTag: MTTag = null;
  public documentTags: MTTag[] = [];
  public selectedInput: IInputElementPanel = null;
  public isApplyingTagsInPage = false;

  public bellaSkills: MTSkillsType[] = [
    MTSkillsType.bioLink,
    MTSkillsType.profileImage,
    MTSkillsType.instagramFeed,
    MTSkillsType.instagramStory,
    MTSkillsType.logo,
    'advertisement' as MTSkillsType,
    'visit_card' as MTSkillsType,
  ];

  notifyMSGs: any;

  constructor(
    private _panelStackService: PanelStackService,
    private _documentManagerService: DocumentManagerService,
    private _magicTemplateService: MagicTemplateService,
    private _alertService: NotificationService,
    private _elementModelFinderService: ElementModelFinderService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
    this.notifyMSGs = this._alertService.notificationsMSGs;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initDocumentMetadata();
    this.initInputsPanel();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._documentManagerService.selectElement(this.groupSelected || this.elementSelected);
  }

  close(): void {
    this._panelStackService.pop(500);
  }

  onElementSelected(element: IElementModel) {
    if (element) this.isApplyingTagsInPage = false;
    this.initInputsPanel();
  }

  onPageSelected(pageSelected: PageModel) {
    this.initInputsPanel();
  }

  /**
   * Obtém a skill da Bella aplicada ao documento
   */
  initDocumentMetadata(): void {
    const bellaSkill = this.document.bella_skills as MTSkillsType;
    this.selectedBellaSkill = bellaSkill;
  }

  /**
   * Troca a skill da Bella do documento, limpa as tags aplicadas
   * anteriormente, e redefine a lista de inputs do painel
   *
   * @param event Skill da Bella selecionada no painel
   */
  setBellaSkill(event: MatSelectChange): void {
    this.selectedBellaSkill = event.value;
    this.document.bella_skills = event.value;
    this.clearPreviousTags();
    this._documentManagerService.save();
    this.initInputsPanel();

    this._alertService.success(
      'Skill da Bella modificada, e tags anteriores removidas!',
    );
  }

  /**
   * Obtém a listagem de inputs com base na skill da bela
   * e no elemento/página sendo manipulado & obtém a lista
   * de tags já existentes no documento
   */
  async initInputsPanel() {
    this.inputs = await this._magicTemplateService.getMetaInputsByBellaSkill(
      this.selectedBellaSkill,
      this.elementSelected,
      this.isApplyingTagsInPage,
    );

    this.documentTags = this._magicTemplateService.getTagsInDocument(
      this.document,
    );

    this.inputs = this.sortingInputs(this.inputs);
    this.selectedInput = null;
    this.selectedTag = null;
  }

  /**
   * Ordena os inputs, priorizando aqueles já aplicados ao documento
   *
   * @param inputs Lista de inputs a serem ordenados
   * @returns Array de inputs já ordenados
   */
  sortingInputs(inputs: IInputElementPanel[]) {
    return inputs
      .map((input: IInputElementPanel) => ({
        ...input,
        isTagged: this.isInputAlreadyTagged(input),
      }))
      .sort((x, y) => {
        return x.isTagged === y.isTagged ? 0 : x.isTagged ? -1 : 1;
      });
  }

  /**
   * Com base no input clicado e no elemento/página selecionado,
   * obtém a tag a ser salva e armazena ela na propriedade `this.selectedTag`
   * dentro dessa classe
   *
   * @param input Input clicado no painel
   * @returns boolean - Status de sucesso da ação
   */
  async selectTagForElement(input: IInputElementPanel): Promise<boolean> {
    if (this.elementSelected === undefined && !this.isApplyingTagsInPage) {
      this._alertService.error(
        'Você deve selecionar um elemento (ou clicar em "Aplicar tags na página"), com o painel de marcação de templates aberto, para inserir as tags.',
      );
      return false;
    }

    if (this.isInputAlreadyTagged(input)) return false;

    if (this.isPropertyAlreadyTagged(input)) {
      this._alertService.error(
        'Você está tentando inserir uma tag que modifica uma propriedade do elemento que já está sendo alterada por outra tag.',
      );
      return false;
    }

    const finalTag = await this._magicTemplateService.selectTagForElement(
      input,
      this.elementSelected,
      this.isApplyingTagsInPage,
    );

    this.selectedTag = finalTag;
    this.selectedInput = input;

    return true;
  }

  /**
   * Aplica a tag selecionada, que está armazenada em `this.selectedTag`,
   * no elemento/página selecionados
   *
   * @returns boolean - Status de sucesso da ação
   */
  saveTagSelection(): boolean {
    if (
      (this.elementSelected === undefined && !this.isApplyingTagsInPage) ||
      this.selectedTag === null
    ) {
      this._alertService.error(
        'Você deve selecionar um elemento (ou clicar em "Aplicar tags na página") e uma tag para concluir a ação.',
      );
      return false;
    }

    this.isApplyingTagsInPage
      ? this.insertTag(this.pageSelected, this.selectedTag)
      : this.insertTag(this.elementSelected, this.selectedTag);

    this._documentManagerService.save();
    this._alertService.success('Tag salva com sucesso!');
    this.initInputsPanel();

    return true;
  }

  /**
   * Insere a tag no local apropriado do documento,
   * seja no elemento ou na página
   *
   * @param item Elemento ou página selecionados
   * @param tag Tag a ser inserida
   */
  insertTag(item: PageModel | IElementModel, tag: MTTag) {
    const changes: IMagicTemplateChanges = {
      id: item.id,
      magicTemplate: copyObject(!item.magicTemplate || !item.magicTemplate?.tags ? { tags: [] } : item.magicTemplate),
    } as IMagicTemplateChanges;

    changes.magicTemplate.tags.push({ ...tag });
    this._updateTags(item, changes);
  }

  setTags(item: PageModel | IElementModel, tags: MTTag[]) {
    const changes: IMagicTemplateChanges = {
      id: item.id,
      magicTemplate: !item.magicTemplate || !item.magicTemplate?.tags ? { tags: [] } : copyObject(item.magicTemplate),
    } as IMagicTemplateChanges;

    changes.magicTemplate.tags = tags;
    this._updateTags(item, changes);
  }

  /**
   * Com base no input e no elemento/página selecionados,
   * descobre se input já foi inserido
   *
   * @param input Input a ser verificado
   * @param onlyOne Se correspondência deve ser única
   * @returns boolean - Se correspondência foi encontrada ou não
   */
  isInputAlreadyTagged(
    input: IInputElementPanel,
    onlyOne: boolean = true,
  ): boolean {
    if (this.elementSelected) {
      const element = this.selectTaggedElementWithInput(
        input,
        false,
      ) as IElementModel[];

      const elementSearch = element.filter(el => this.elementSelected?.id === el?.id);

      return elementSearch.length > 0;
    }

    const searchOrigin = this.isApplyingTagsInPage
      ? (this.pageSelected.magicTemplate
        ? this.pageSelected.magicTemplate.tags
        : [])
      : this.documentTags;

    const search = searchOrigin.filter(tag =>
      MagicTemplateGenerator.compareTagAndInput(tag, input),
    );

    return onlyOne ? search.length === 1 : search.length > 0;
  }

  /**
   * Com base no input fornecido, encontrar elementos
   * que possuam tags correspondentes àquele input
   *
   * @param input Input a ser pesquisado
   * @param onlyOne Deve retornar apenas um elemento ou todos encontrados
   * @returns Elementos encontrados na pesquisa
   */
  selectTaggedElementWithInput(
    input: IInputElementPanel,
    onlyOne = true,
  ): IElementModel | IElementModel[] {
    const element = this._elementModelFinderService
      .getAllElements(this.pageSelected.elements, true, false)
      .filter(element => element.magicTemplate?.tags.length > 0)
      .filter(element =>
        this.filterTagsWithInput(element.magicTemplate.tags, input),
      );

    return onlyOne ? element[0] : element;
  }

  /**
   * Com base no input fornecido e no elemento/página selecionados,
   * remove as respectivas tags do documento
   *
   * @param input Input a ser limpo
   */
  clearTagFromDocument(input: IInputElementPanel): void {
    let documentItems: Array<IElementModel | PageModel> = [];
    let successMessage = '';

    if (!this.elementSelected && !this.isApplyingTagsInPage) {
      documentItems.push(...this._elementModelFinderService.getAllElements(this.pageSelected.elements, true, false));
      documentItems.push(this.pageSelected);

      successMessage =
        'A tag foi removida de todos os elementos que foi inserida!';
    } else {
      if (this.elementSelected) {
        const searchedElements = this.selectTaggedElementWithInput(
          input,
          false,
        ) as IElementModel[];

        documentItems.push(
          searchedElements.filter(
            el => el.id === this.elementSelected.id,
          )[0] as IElementModel,
        );
      }

      if (this.isApplyingTagsInPage) {
        documentItems.push(this.pageSelected);
      }

      successMessage = 'A tag foi removida do elemento/página selecionados!';
    }

    documentItems.forEach(item => {
      if (item.magicTemplate) {
        const tags = item.magicTemplate.tags.filter(
          (tag: MTTag) =>
            !MagicTemplateGenerator.compareTagAndInput(tag, input),
        );
        this.setTags(item, tags)
      }
    });

    this._documentManagerService.save();

    this._alertService.success(successMessage);

    this.initInputsPanel();
  }

  /**
   * Limpa todas as tags aplicadas no documento
   */
  clearPreviousTags() {
    this.document.body.forEach((page: PageModel) => {
      this.clearTagsFromItem(page);
      page.elements.forEach((element: IElementModel) => {
        this.clearTagsFromItem(element);
      });
    });
  }

  /**
   * Remove as tags aplicadas a um determinado elemento ou página
   *
   * @param item Elemento/página selecionados
   */
  clearTagsFromItem(item: PageModel | IElementModel) {
    if (item.magicTemplate && item.magicTemplate.tags) {
      item.magicTemplate.tags = [];
    }
  }

  /**
   * Com base no input fornecido e no elemento/página selecionados,
   * descobre se a propriedade que o input manipula já
   * está sendo manipulada por outra tag
   *
   * @param input Input a ser pesquisado
   * @returns Se propriedade do input fornecido já está sendo manipulada
   */
  isPropertyAlreadyTagged(input: IInputElementPanel): boolean {
    let filterOrigin: PageModel | IElementModel = this.elementSelected;

    if (this.isApplyingTagsInPage) {
      if (!this.pageSelected.magicTemplate) return false;

      filterOrigin = this.pageSelected;
    } else {
      if (!this.elementSelected.magicTemplate) return false;
    }

    const filter = this.filterTagsWithInput(
      filterOrigin.magicTemplate.tags,
      input,
      'type',
    );

    return filter;
  }

  /**
   * @param val Se página está sendo taggeada
   */
  setIsApplyingTagsInPage(val: boolean): void {
    this.isApplyingTagsInPage = val;
    this.initInputsPanel();
  }

  /**
   * Com o input fornecido e um array de tags, descobre
   * se as tags são contempladas pelo input em questão
   *
   * @param tags Tags a serem filtradas
   * @param input Input para comparação
   * @param compare {'type' | 'all'} - Comparar apenas tipo, ou code também
   * @returns boolean Se existem tags contempladas pelo input em questão
   */
  filterTagsWithInput(
    tags: MTTag[],
    input: IInputElementPanel,
    compare: 'type' | 'all' = 'all',
  ) {
    return (
      tags.filter((tag: MTTag) =>
        MagicTemplateGenerator.compareTagAndInput(tag, input, compare),
      ).length > 0
    );
  }

  private _updateTags(item: PageModel | IElementModel, changes: IMagicTemplateChanges) {
    if (item.type === elementTypeEnum.page) {
      this._documentStateManagerService.persistPageChangesNoTrackable(changes as PageModel);
    } else {
      const target = copyObject(this.groupSelected || item);
      const allElements = this._elementModelFinderService.getAllElements([ target ], true);
      Object.assign(allElements.find(el => item.id === el.id), changes);
      this._documentStateManagerService.persistElementChangesNoTrackable(this.pageSelected, [ target ]);
    }
  }

}
