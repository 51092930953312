import { Injectable } from '@angular/core';
import { ElementModelService } from '@services/element-model.service';
import { ShapeElementOperations } from '@trakto/core-editor';
import { PathSvgService } from '@services/path-svg.service';
import { IShapeElementModel } from '@trakto/models';
import { enumSignals, SignalsService } from '@shared/signals/signals.service';
import { DataTrackingService } from '@services/analytics/wrapper';
import { MediaService } from '@services/media.service';

export const DEFAULT_SHAPES = {
  triangle: 'M25,0L0,50h50L25,0z',
  square: 'M 0 0 L 50 0 L 50 50 L 0 50 L 0 0 Z',
  star: 'M25,0l7.7,16.5L50,19.1L37.5,31.9l3,18.1L25,41.5L9.6,50l2.9-18.1L0,19.1l17.3-2.6L25,0z',
  hexagon: 'M39.1,50H10.9L0,25L10.9,0h28.2L50,25L39.1,50z',
  circle: 'M25,0c13.8,0,25,11.2,25,25S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z',
  diamond: 'M25,0l25,25L25,50L0,25L25,0z',
};


@Injectable({
  providedIn: 'root',
})
export class ShapeElementService extends ShapeElementOperations {
  constructor(
    private _newElementService: ElementModelService,
    private _pathSvgService: PathSvgService,
    private _mediaService: MediaService,
    private _signalsService: SignalsService,
    private _dataTrackingService: DataTrackingService,
  ) {
    super(_newElementService, _pathSvgService);
  }

  public async changeShapePathByUrl(shape: IShapeElementModel, url: string): Promise<void> {
    const path = await this._mediaService.getSVGPathByUrl(url).toPromise();
    this.changeShapePath(shape, path);
    this._newElementService.forceUpdate(shape);
    this._signalsService.emit(enumSignals.PROPERTY_CHANGE, {
      obj: shape,
      property: 'path',
    });
  }
}
