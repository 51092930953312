import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ItemRenderer } from '../item-renderer.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaComponent implements OnInit, OnDestroy {
  @ViewChild('render', { static: true }) render: any;
  @ViewChild('empty', { static: true }) empty: any;

  public mediaLoaded: boolean;
  public translated: any;

  private _item: ItemRenderer;
  private _gap: number;

  private _destroy$ = new Subject<void>();

  @Input()
  set item(value: ItemRenderer) {
    this._item = value;
  }

  get item(): ItemRenderer {
    return this._item;
  }

  @Input()
  set gap(value: number) {
    this._gap = value;
  }

  get gap(): number {
    return this._gap || 20;
  }

  @Input() customModal: boolean;

  @Output() onClick: EventEmitter<ItemRenderer> =
    new EventEmitter<ItemRenderer>();
  @Output() onRemoveImage: EventEmitter<ItemRenderer> =
    new EventEmitter<ItemRenderer>();

  constructor(private translateService: TranslateService) {
    this.mediaLoaded = false;
  }

  ngOnInit() {
    this.render.nativeElement.style.backgroundImage = `url(${
      this.item.thumb || this.item.value
    })`;
    this.render.nativeElement.style.height = `${this.render.nativeElement.offsetWidth}px`;
    this.translateService
      .get('media_modal')
      .pipe(takeUntil(this._destroy$))
      .subscribe((translated: any) => (this.translated = translated));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  onLoadMedia() {
    this.mediaLoaded = true;
    this.render.nativeElement.removeChild(this.empty.nativeElement);
  }

  openExternalLink() {
    window.open(this.item.link, '_blank');
  }

  removeImageByMyGallery(item: ItemRenderer) {
    this.onRemoveImage.emit(item);
  }

  click() {
    this.onClick.emit(this.item);
  }
}
