import { elementTypeEnum, IElementModel } from '@trakto/models';
import { EditorConfigs } from './EditorConfigs';

export class Drawable {
  /**
   * Elementos clipados possuem uma mascara, nesse caso o elemento clicável é ela. Caso contrário é o próprio elemento.
   */
  public clickableElement: IElementModel;
  public hoverElement: IElementModel;
  public hover: boolean;
  public svg: string;
  public selected = false;
  public hasLink: boolean;
  public hasWhatsapp: boolean;
  public hasEmail: boolean;
  public hasFlag: boolean;

  constructor(
    public element: IElementModel,
    public clipped: boolean,
    public config: EditorConfigs,
    public group?: IElementModel,
    public isPreviewDoc?: boolean,
  ) {
    this.updateClickableElement(true);
    this.hoverElement = this.group || this.clickableElement;
    this.hasLink =
      (element.link !== undefined && element.link !== null) ||
      (element.type === elementTypeEnum.youtube && !this.isPreviewDoc);
    this.hasWhatsapp =
      element.whatsapp !== undefined && element.whatsapp !== null;
    this.hasEmail = element.email !== undefined && element.email !== null;
    this.hasFlag = !!element.flagCountry;
  }

  public updateClickableElement(singleGroupSelection: boolean) {
    if (!singleGroupSelection && this.group) {
      this.clickableElement = this.group;
    } else if (this.clipped) {
      this.clickableElement = this.element['submask'];
    } else {
      this.clickableElement = this.element;
    }
  }

  get clickableBorderColor() {
    return this.hover
      ? this.element.edit && !this.element.lockedByTemplate
        ? this.config.themeBorderColor
        : this.config.lockedEditionColor
      : '';
  }

  get themeBorderColor() {
    return this.config.themeBorderColor;
  }

  get finalWidth() {
    return this.clickableElement.supportWidth * this.clickableElement.scaleX;
  }

  get finalHeight() {
    return this.clickableElement.supportHeight * this.clickableElement.scaleY;
  }
}
