import { Component, OnInit } from '@angular/core';
import {
  IPanelTypes,
  PanelService,
} from '@app/editor-v3/services/panel.service';

@Component({
  selector: 'trakto-panel-add-elements-mobile',
  templateUrl: './panel-add-elements-mobile.component.html',
  styleUrls: ['./panel-add-elements-mobile.component.scss'],
})
export class PanelAddElementsMobileComponent implements OnInit {
  constructor(private _panelService: PanelService) {}

  ngOnInit(): void {}

  openPanel(panel: IPanelTypes) {
    this._panelService.openPanel(panel);
  }

  closePanel() {
    this._panelService.closeLastPanel();
  }
}
