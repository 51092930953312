<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-images.svg"
    label="Imagem"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="wrapper click-ignore">
    <div class="tabs">
      <div
        class="tab"
        [ngClass]="{ active: activeTab === 'search' }"
        (click)="setActiveTab('search')"
      >
        Pesquisar imagem
      </div>
      <div
        class="tab"
        [ngClass]="{ active: activeTab === 'library' }"
        (click)="setActiveTab('library')"
      >
        Minha biblioteca
      </div>
    </div>

    <trakto-search-tab-mobile
      (clicked)="closePanel()"
      [ngStyle]="{ display: activeTab === 'search' ? 'block' : 'none' }"
    ></trakto-search-tab-mobile>

    <trakto-library-tab-mobile
      (clicked)="closePanel()"
      [ngStyle]="{ display: activeTab === 'library' ? 'block' : 'none' }"
    ></trakto-library-tab-mobile>
  </div>
</div>
