import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { DropboxComponent } from './dropbox/dropbox.component';
import { IntegrationsRoutingModule } from './integrations-routing.module';

import {
  KmlCardNotificationModule,
  KmlLoaderModule,
} from '@trakto/trakto-kamaleon';
import { ExternalAuthComponent } from './external-auth/external-auth.component';

@NgModule({
  declarations: [
    DropboxComponent,
    ExternalAuthComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IntegrationsRoutingModule,
    KmlLoaderModule,
    KmlCardNotificationModule,
    InlineSVGModule.forRoot(),
  ],
})
export class IntegrationsModule {}
