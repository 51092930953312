import { ElementModelGroupEngine } from '@trakto/core-editor';

export enum SelectionEventType {
  REFERENCE_CHANGE = 'REFERENCE_CHANGE',
  RESET = 'RESET',
  ADDED_NEW_ELEMENT = 'ADDED_NEW_ELEMENT',
  REMOVED_ELEMENT = 'REMOVED_ELEMENT',
  SELECTED_ELEMENT = 'SELECTED_ELEMENT',
  DESELECTED_ELEMENT = 'DESELECTED_ELEMENT',
}

export class SelectionEvent {
  constructor(
    public type: SelectionEventType,
    public selection: ElementModelGroupEngine,
    public oldSelection?: ElementModelGroupEngine,
  ) {}

  /**
   * Verifica se houve a adicao ou remocao de um elemento.
   */
  public isSelectionChanged() {
    return (
      this.type === SelectionEventType.SELECTED_ELEMENT ||
      this.type === SelectionEventType.DESELECTED_ELEMENT
    );
  }

  public isElementsChanged() {
    return (
      this.type === SelectionEventType.ADDED_NEW_ELEMENT ||
      this.type === SelectionEventType.REMOVED_ELEMENT
    );
  }

  /**
   * Verifica se foi um evento de apenas mudanca de referencia da selecao (nao houve adicao nem remocao).
   */
  public isReferenceChanged() {
    return this.type === SelectionEventType.REFERENCE_CHANGE;
  }
}
