<div #element id="panel-link-website" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-email"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ 'property_panel.link.panel-email.header' | translate }}
      </h2>
    </div>

    <div class="panel-scroller-close" (click)="closePanelLink()">
      <i class="trakto-icon-arrow-right"></i>
    </div>
  </div>

  <div class="panel-full__container">
    <div class="panel-full__content">
      <div class="panel-full__header" [class.--email]="true">
        <h1 [ngStyle]="{ width: '70%' }">
          {{ 'property_panel.link.panel-email.subtitle' | translate }}
        </h1>
      </div>

      <div class="panel-full__link">
        <form [formGroup]="form" (submit)="handleSubmit()">
          <div class="panel-full__field">
            <label for="Email">
              {{
                'property_panel.link.panel-email.input_email.label' | translate
              }}
            </label>
            <trakto-input
              [class.is-invalid]="
                form.get('email').invalid && form.get('email').touched
              "
              #inputEmail
              id="emailTo"
              name="emailTo"
              type="url"
              placeholder="email@email.com"
              typeOnLive="true"
              formControlName="email"
              [ngModel]="replaceEmailTo"
              (onBlur)="enableHotkeys()"
              (onFocus)="disableHotkeys()"
            >
            </trakto-input>
            <div *ngIf="(email.invalid && email.touched) || email.dirty">
              <small
                [ngClass]="{ '--hide': !email.errors?.required }"
                class="text-danger"
              >
                {{
                  'property_panel.link.panel-email.input_email.hint' | translate
                }}
              </small>
              <small
                [ngClass]="{ '--hide': !email.errors?.pattern }"
                class="text-danger"
              >
                {{
                  'property_panel.link.panel-email.input_email.invalid'
                    | translate
                }}
              </small>
            </div>
          </div>

          <div class="panel-full__field">
            <label for="Email">
              {{
                'property_panel.link.panel-email.input_subject.label'
                  | translate
              }}
            </label>
            <trakto-input
              #inputSubject
              id="subject"
              name="subjectMatter"
              type="text"
              placeholder="{{
                'property_panel.link.panel-email.input_subject.placeholder'
                  | translate
              }}
"
              typeOnLive="false"
              formControlName="subject"
              [ngModel]="replaceSubject"
              (onBlur)="enableHotkeys()"
              (onFocus)="disableHotkeys()"
            >
            </trakto-input>

            <small [ngClass]="{ '--hide': form.controls.subject.valid }">
              {{
                'property_panel.link.panel-email.input_subject.label'
                  | translate
              }}
            </small>
          </div>

          <div class="panel-full__field">
            <label for="Email">
              {{
                'property_panel.link.panel-email.input_email_content.label'
                  | translate
              }}
            </label>
            <textarea
              [ngModel]="replaceBody"
              formControlName="messageBody"
              placeholder="{{
                'property_panel.link.panel-email.input_email_content.placeholder'
                  | translate
              }}"
              cols="30"
              rows="10"
            ></textarea>
            <small [ngClass]="{ '--hide': form.controls.messageBody.valid }">
              {{
                'property_panel.link.panel-email.input_email_content.hint'
                  | translate
              }}
            </small>
          </div>

          <trakto-button
            button--custom
            button--paddin-link
            button--xxlarge
            type="submit"
            [disabled]="form.invalid"
          >
            <span>
              {{
                (elementSelected && elementSelected.email
                  ? 'property_panel.link.remove_link_button'
                  : 'property_panel.link.generate_link_button'
                ) | translate
              }}
            </span>
          </trakto-button>
        </form>
      </div>
    </div>
  </div>
</div>
