<div class="panel-mobile">
  <trakto-panel-header-mobile
    img="assets/editor-v3/images/panel-opacity.svg"
    label="Transparência"
    [showBackLabel]="true"
    [ignoreBackClick]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <div class="action click-ignore">
    <span class="label">Intensidade</span>
    <div class="slider">
      <trakto-slider
        [min]="0"
        [max]="100"
        [value]="elementOpacity"
        (onChange)="setOpacity($event)"
        [ngStyle]="{ flexGrow: 1 }"
      ></trakto-slider>

      <div class="value">
        <span class="text">
          {{ elementOpacity }}
        </span>
      </div>
    </div>
  </div>
</div>
