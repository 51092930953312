import { Component, ElementRef, ViewChild } from '@angular/core';

import { FilterModel } from '@editor/model/filter.model';

import { IDuotonesFilters, ImageElementModel, } from '@trakto/models';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-filter',
  templateUrl: './panel-filter.component.html',
})
export class PanelFilterComponent extends AbstractComponent<ImageElementModel> {
  public filters: IDuotonesFilters[];
  public filter: FilterModel;
  public isTranslate: any;
  public elementEmoji: string;

  @ViewChild('element', { static: true }) element: ElementRef;

  public set isEmoji(value: string) {
    this.elementEmoji = value;
  }

  public get isEmoji(): string {
    return this.elementEmoji;
  }

  constructor(
    private _translateService: TranslateService,
    private _panelStackService: PanelStackService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
    this.filters = [
      { label: 'Teal and Orange', value: 'tealorange' },
      { label: 'Orange', value: 'orange' },
      { label: 'Tumblr', value: 'tumblr' },
      { label: 'Paulo ', value: 'paulo' },
      { label: 'Vintage ', value: 'vintage' },
      { label: 'Sepia Light', value: 'sepialight' },
      { label: 'Sepia', value: 'sepia' },
      { label: 'B&W', value: 'blackandwhite' },
      { label: 'Cold', value: 'cold' },
      { label: 'Gaussian Blur', value: 'gaussianblur' },
      { label: 'Horizontal Blur', value: 'horizontalblur' },
      { label: 'Contrast', value: 'contrast' },
      { label: 'Hue', value: 'huerotate' },
      { label: 'Luminance', value: 'luminance' },
      { label: 'Darken', value: 'darken' },
      { label: 'Lighten', value: 'lighten' },
      { label: 'Invert', value: 'invert' },
      { label: 'Grainy', value: 'grainy' },
    ];

    this.makeTranslate();
  }

  ngOnInit() {
    super.ngOnInit();
    this.makeSvgEmoji();
  }

  public makeSvgEmoji(): any {
    if (this.elementSelected) {
      const parseSvg = new DOMParser();
      const svgElement = parseSvg.parseFromString(
        this.elementSelected.svg,
        'image/svg+xml',
      );

      const xml = new XMLSerializer().serializeToString(
        svgElement.documentElement,
      );
      const svg64 = btoa(xml);
      const b64start = 'data:image/svg+xml;base64,';
      const image64 = b64start + svg64;

      this.isEmoji = image64;
    }
  }

  public close() {
    this._panelStackService.pop(500);
  }

  private makeTranslate(): void {
    this.isTranslate = {
      title_config_image: '',
      title_config_page: '',
    };

    this._translateService.get('property_panel').pipe(takeUntil(this._destroy$)).subscribe((key: any) => {
      this.isTranslate.title_config_image =
        key['image']['effects']['gallery_title_image'];
      this.isTranslate.title_config_page = key['page']['title'];
    });
  }

  public setFilter(filter: string): void {
    if (!this.elementSelected && !this.pageSelected) {
      return;
    }
    if (this.elementSelected) {
      this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'filter', filter || 'empty');
    } else {
      this._elementChangeFacadeService.changePageProperty(this.pageSelected, 'filter', filter || 'empty');
    }
  }
}
