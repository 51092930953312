import { Component, Input, OnInit } from '@angular/core';

import { PanelColorComponent } from '../panel-color/panel-color.component';
import { PanelLinkEmailComponent } from '../panel-link-email/panel-link-email.component';
import { PanelLinkWebsiteComponent } from '../panel-link-website/panel-link-website.component';
import { PanelLinkWhatsappComponent } from '../panel-link-whatsapp/panel-link-whatsapp.component';
import { PanelElementsComponent } from '../panel-elements/panel-elements.component';

import {
  enumSignals,
  SignalsService,
} from '@app/shared/signals/signals.service';
import { AuthService } from '@auth/shared/auth.service';
import { HotkeyService } from '@editor/services/hotkeys/hotkeys.service';
import { PanelStackService } from '@editor/services/panel-stack.service';
import { TranslateService } from '@ngx-translate/core';

import {
  IShapeElementModel,
} from '@trakto/models';

import { WhitelabelProductService } from '@app/editor/services/whitelabel-config/whitelabel-product.service';
import { ProductFeatures } from '@app/editor/services/whitelabel-config/product-features.enum';
import { ColorModel } from '@trakto/core-editor';
import { ElementsGalleryService } from '@app/shared/elements-gallery/elements-gallery.service';
import { ElementNewApiService } from '@services/element-new-api.service';
import { SearchTypeEnum } from '@app/editor/enums/editor-elements/search-element.enum';
import { GalleryElementType } from '@app/editor/enums/editor-elements/gallery-type.enum';
import {
  AbstractComponent
} from '@editor/components/abstract-component.component';
import {
  DocumentStateManagerService
} from '@services/document-state-manager.service';
import {
  ElementChangeFacadeService
} from '@services/element-change-facade.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trakto-panel-shape',
  templateUrl: './panel-shape.component.html',
})
export class PanelShapeComponent extends AbstractComponent<IShapeElementModel> {

  public isEmbeddedInstance: boolean;
  public headerTitle: string;
  public get svg(): string {
    if (!this.elementSelected.path) {
      return '';
    }
    const { finalHeight, finalWidth, scaleX, scaleY } = this.elementSelected;
    const vbH = (finalHeight / scaleX) * 2; // viewBox Height
    const vbW = (finalWidth / scaleY) * 2; // viewBox width
    const tH = vbH * 0.15; // translate Height
    const tW = vbW * 0.25; // translate Width

    return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${vbW} ${vbH} ">
        <path fill="#8492A6"
          transform="translate(${tW}, ${tH})"
          d="${this.elementSelected.path}">
        </path>
      </svg>
    `;
  }

  public elementLinkIsAvailable = false;

  @Input() b2c: boolean;
  @Input() isCliping: boolean;
  @Input() allowLockElementsByTemplate: boolean;

  constructor(
    private _authService: AuthService,
    private _translate: TranslateService,
    private _hotkeyService: HotkeyService,
    private _panelStackService: PanelStackService,
    private _signalsService: SignalsService,
    private _productService: WhitelabelProductService,
    private _elementsGalleryService: ElementsGalleryService,
    private _newElementApiService: ElementNewApiService,
    private _elementChangeFacadeService: ElementChangeFacadeService,
    documentStateManagerService: DocumentStateManagerService,
  ) {
    super (documentStateManagerService);
    this.makeTranslatePanelColor();

    this.isEmbeddedInstance = this._authService.isEmbeddedInstance();

    this._productService.featuresAvailable.pipe(takeUntil(this._destroy$)).subscribe(features => {
      if (features) {
        this._identifyFeaturesAvailable(features);
      }
    });
  }
  private _identifyFeaturesAvailable(features) {
    this.elementLinkIsAvailable = features[ProductFeatures.ELEMENT_LINKS];
  }

  public disableHotkeys(): void {
    this._hotkeyService.disableHotkeys();
  }

  public enableHotkeys(): void {
    this._hotkeyService.enableHotkeys();
  }

  public makeTranslatePanelColor(): void {
    this._translate
      .get('property_panel.shape.panel_color.title')
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.headerTitle = value));
  }

  public openModal(id: string, addSubmask?: boolean): void {
    this._signalsService.emit(enumSignals.ON_OPEN_MODAL, { id, addSubmask });
  }

  public openPanelStack(context: string, event?: any): void {
    switch (context) {
      case 'color':
        event.targetSettings = {
          title: `${this.headerTitle}`,
          icon: 'shape',
        };

        event.currentService.defaultColor = new ColorModel(
          null,
          this.elementSelected.backgroundColor,
        );
        this._panelStackService.stack(PanelColorComponent, {
          inputs: {
            currentService: event.currentService,
            headerIcon: null,
            headerTitle: this.headerTitle,
          },
          outputs: {
            colorChange: {
              emit: (color: string) => {
                this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'backgroundColor', color);
              },
            },
          },
        });
        break;
      case 'panel-website':
        this._panelStackService.stack(PanelLinkWebsiteComponent, {});
        break;
      case 'panel-email':
        this._panelStackService.stack(PanelLinkEmailComponent, {});
        break;

      case 'panel-whatsapp':
        this._panelStackService.stack(PanelLinkWhatsappComponent, {});
        break;
      default:
        break;
    }
  }

  public setFilter(filter: string): void {
    if (this.elementSelected) {
      this._elementChangeFacadeService.changeElementProperty(this.elementSelected, 'filter', filter || 'empty');
    }
  }

  openElementsPanel() {
    this._elementsGalleryService.setElementsPanelStore({
      type: SearchTypeEnum.FOLDER,
      query: null,
      elementType: this._newElementApiService.getElementTypeByContentType(GalleryElementType.SHAPE),
      name: GalleryElementType.SHAPE,
      backComponentInfo: {
        component: PanelShapeComponent,
        params: {
          inputs: {
            b2c: this.b2c,
            isCliping: this.isCliping,
            allowLockElementsByTemplate: this.allowLockElementsByTemplate,
          },
        }
      },
    })
    this._panelStackService.popAll(true);
    this._panelStackService.stack(PanelElementsComponent);
  }
}
