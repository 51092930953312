export const DEFAULT_THUMB_COVER = {
  low: {
    url: 'https://firebasestorage.googleapis.com/v0/b/trakto-production.appspot.com/o/application_assets%2Fthumbs%2Fdefault%20thumb.png?alt=media&token=09347fea-4581-4e9e-aa14-ca018559dc25',
    bytes: 2000,
    provider: 'external',
    directory: '/application_assets/thumbs',
  },

  medium: {
    url: 'https://firebasestorage.googleapis.com/v0/b/trakto-production.appspot.com/o/application_assets%2Fthumbs%2Fdefault%20thumb.png?alt=media&token=09347fea-4581-4e9e-aa14-ca018559dc25',
    bytes: 2000,
    provider: 'external',
    directory: '/application_assets/thumbs',
  },

  hight: {
    url: 'https://firebasestorage.googleapis.com/v0/b/trakto-production.appspot.com/o/application_assets%2Fthumbs%2Fdefault%20thumb.png?alt=media&token=09347fea-4581-4e9e-aa14-ca018559dc25',
    bytes: 2000,
    provider: 'external',
    directory: '/application_assets/thumbs',
  },

  raw: {
    url: 'https://firebasestorage.googleapis.com/v0/b/trakto-production.appspot.com/o/application_assets%2Fthumbs%2Fdefault%20thumb.png?alt=media&token=09347fea-4581-4e9e-aa14-ca018559dc25',
    bytes: 2000,
    provider: 'external',
    directory: '/application_assets/thumbs',
  },
};

export const PRODUCT_B2C_ID = 'fbvZngcBbaFZeUK8h0il';

export const GOOGLE_PRODUCT_IDS = ['a5PpaX9hX5zV4HYeTqsm'];

export const baseUrl = 'https://api.trakto.io/';

export const templateUrl = 'https://design-api.trakto.io/';

export const bellaUrl = 'https://bella.api.trakto.io';

export const BASE_URL_TOKEN_AUTH_API = 'https://firebase-auth.api.trakto.io';

export const MAX_UPLOAD_SIZE = 10;

export const MAX_COVER_DIMENSION_PX = 2000;

export const googleTagManagerCode = 'GTM-PLR324P';

export const DROPBOX = {
  urls: {
    v1: 'https://content.dropboxapi.com/2',
    v2: 'https://api.dropboxapi.com/2',
  },
};

export const USERSNAP = {
  apiKey: 'e94975a4-c73c-433f-9ed4-54ab8d4fd1a9',
  keyEditorStarReview: {
    'en-US': '3c536a8f-a0ce-487d-a916-4860defcf520',
    'pt-BR': 'ad73ecad-13ca-4a5c-9f12-33d5cd4e3920',
  },
  templatesRequestWidget: {
    'en-US': '0eb7d962-b676-469a-bdf5-921b594a034b',
    'pt-BR': 'a9a5eda8-d13b-469e-80f4-dac5e2a5cbec',
  },
  bugReportWidget: {
    'en-US': '77e24a73-be76-43f4-908c-bc98925bcc29',
    'pt-BR': 'b18bc8e0-2d00-45fb-b2b2-920066247c7d',
  },
  traktoLinksConfigureWidget: {
    'en-US': 'c9436024-2eeb-4bea-9d6c-98879f56d502',
    'pt-BR': '4e5e947a-b47c-4c05-82de-be17538f8af6',
  },
  keyFeedBack: {
    'en-US': 'ef99ac77-b775-404f-a241-f774f5cc3382',
    'pt-BR': '9a29581d-ae2b-4d7b-b21c-a621c984e151',
  },
};

export const SEGMENTIO_KEY = 'QMWcybDKzxSIxo5CvAw0rrfbVM5UltnP';

export const BASEURLFUNCTIONS =
  'https://us-central1-trakto-production.cloudfunctions.net';

export const IMAGES_API = {
  stock_images_api: {
    url: 'https://lb.photos.trakto.io/v1',
    sdk_token: '7e8bd2a1ccaa90f5d05084732d99586b',
  },
  pixabay: {
    api: 'https://pixabay.com/api/',
    token: '9772210-d2789e82910308773f572b8a1',
  },
  trakto: {
    url: 'https://us-central1-trakto-production.cloudfunctions.net/imagesApi/api/v1/',
  },
};

export const SHAPES_API = {
  v1: 'https://api.icons8.com/api/iconsets/v3/',
  v3: 'https://us-central1-trakto-production.cloudfunctions.net/icons8api/api/v1/',
  v4: 'https://us-central1-trakto-production.cloudfunctions.net/icons8api/api/v1',
  tokens: {
    v3: '0612bf04640b8c0d636bd9fe49850164299a32ce',
    v4: 'dcb28a983c06465bb3451066213f56fb',
  },
};

export const ICONS_API = {
  v2: 'https://us-central1-trakto-production.cloudfunctions.net/iconsApiV2/api/v2',
};

export const AZURE_TRANSLATION_API = {
  azure: {
    url: 'https://api.cognitive.microsofttranslator.com/',
    secret_key: '2bd1afff6f5749e7ab77472e1b7baa88',
  },
};

export const GOOGLE_API = {
  maps: {},
  firebase: {
    apiKey: 'AIzaSyBit6oG12mYKtj1_Jo5L89-8CHuTgix9nA',
    authDomain: 'trakto-production.firebaseapp.com',
    databaseURL: 'https://trakto-production.firebaseio.com',
    projectId: 'trakto-production',
    storageBucket: 'trakto-production.appspot.com',
    messagingSenderId: '849224233552',
  },
  translation: {
    url: 'https://translation.googleapis.com/language/translate/v2',
    apiKey: 'AIzaSyCIi_q9ueh9vPsirOkTIjgMKVfdlpYxMSw',
  },
};

export const REMOVEBG_API = {
  url: 'https://api.remove.bg/v1.0/removebg',
  apiKey: '2i58K9fxLahqLt5UWWME4CWs',
};

export const CRISP_API = {
  url: 'https://client.crisp.chat/l.js',
  apiKey: '26b3d1dd-9939-414c-baaf-ca80ea400507',
};

export const PREMIUM_PLANS = {
  'en-US': {
    monthlyPlanId: 'l9khhP4fDgZmHOJAw3zQ',
    yearlyPlanId: 'eHmz3Gl9gcgKWQWg0tsR',
  },
  'pt-BR': {
    monthlyPlanId: 'XTLgwo5xdqOVAnTZPPi5',
    yearlyPlanId: 'vWX5fnu0sh8w6ObPR4Rh',
  },
};
export const COLLECTIONS_NOMENCLATURE = {
  documentDoc: 'document',
  appDoc: 'app',
  appProductDoc: 'app_product',
  userDoc: 'user',
  emptyFormatDoc: 'page_format',
};

export const DEFAULT_LOCALES = [
  { title: 'English (USA)', iso_lang: 'en-US' },
  { title: 'Português (Brasil)', iso_lang: 'pt-BR' },
];

export const DEFAULT_LOCALE = 'pt-BR';

export const COOKIE_STRUCTURE = {
  cookieSessionKey: 'trakto-session',
  cookiePath: '/',
  cookieDomain: '.trakto.io',
};

export const UNSPLASH_API = {
  url: 'https://us-central1-trakto-dev.cloudfunctions.net/unsplashApi/api/v1/photos/',
};

export const STRIPE_API = {
  url: `https://subscription-api.trakto.io`,
  apiKey: 'pk_live_auiuQ9IU1PPyIkoEnXpMAuJf',
};

export const STARTER_PLANS = {
  freePlanId: 'rchSHdtyUNkwYLClDku8',
  monthlyPlanId: 'XTLgwo5xdqOVAnTZPPi5',
  yearlyPlanId: 'vWX5fnu0sh8w6ObPR4Rh',
};

export const COHERE_KEY = 'kSMgJNnTo_5dhYodMzXd4yiD';

export const ELASTIC = {
  apm: {
    serviceName: 'trakto-dashboard',
    serverUrl:
      'https://e6043dba6bf24795b0fcb19db1a5b140.apm.us-central1.gcp.cloud.es.io:443',
  },
};

export const GO_SQUARED = {
  projectToken: 'GSN-411406-O',
};

export const BASE_URL_TRAKTO_LINK_PUBLISH_API = 'https://trakto-links-2wid3to5cq-uc.a.run.app';

export const PRODUCT_FRUITS = {
  ennabled: false,
  code: 'cyI-VmsKOv0kL3OU',
  logger: false,
};

export const WHITELABELS = {
  ifood: 'e3KWr1g949csMYv7xouN',
};

export const JIVO_CHAT = {
  ennabled: true,
  code: 'cxvbkgLJlj',
  src: '//code-sa1.jivosite.com/widget/',
};

export const CHECKOUT = {
  prefix: 'https://checkout.trakto.io/index.html',
  name_param: 'name',
  email_param: 'email',
};

export const TKT_ANALYTICS = {
  user: '$2b$10$iGmdPAlK7MR.zDYBs0TZZe4Otc21iA9jRkVmydSVn1L7Vq5MKJcCe',
  pass: 'ov3s3x3wY_PMuCKRr',
  url: 'https://analytics-collector-api-production-2wid3to5cq-uc.a.run.app',
};

export const TOP_BANNER_CONFIG = {
  ennable: false,
  url: 'https://firebasestorage.googleapis.com/v0/b/trakto-production.appspot.com/o/coupon_bar%2Fbanner-tech.jpg?alt=media&token=162ac33a-02d3-4005-8845-1573a50c658b',
  link: 'https://olink.pro/traktotech',
};

export const googleServiceUrl = 'https://gservice-trakto-ads.web.app/';
export const googleAdsUrl = 'https://ads.google.com/'
