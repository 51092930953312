<p class="info" style="margin-top: 24px;">Aqui você consegue enviar o link para que outro usuário visualize seu material
  de forma mais simples. É
  só copiar!
</p>

<div class="input_button">
  <span>
    <div style="width: 300px !important;">
      <label *ngIf="!loading" for="subdomain"><i class="trakto-icon-link-drop" style="margin-left: 10px;"
          [ngClass]="{'success': !link.typing && link.success, 'typing': link.typing, 'error': !link.typing && !link.success}"></i>
        trakto.link/</label>
      <input *ngIf="!loading"
        [ngClass]="{'success': !link.typing && link.success, 'typing': link.typing, 'error': !link.typing && !link.success}"
        type="text" placeholder="" id="subdomain" [value]="inputValue" (keyup)="onLinkTyping($event)"
        style="width: 185px;" />

      <div class="loading" *ngIf="loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <img style="margin-left: 10px; cursor: pointer;" *ngIf="link.typing"
      src="../../../../../assets/icons/edit-modal.svg" alt="" (click)="selectionRange()">
    <img style="margin-left: 10px;" *ngIf="!link.typing && !link.success"
      src="../../../../../assets/icons/info-circle.svg" alt="">
    <img style="margin-left: 10px;" *ngIf="!link.typing && link.success"
      src="../../../../../assets/icons/tick-circle.svg" alt="">
  </span>

  <button (click)="copyLinkToClipboard()" [ngClass]="link.copied === true ? 'copied' : 'copy'">{{link.copied === true ?
    'Copiado!' : 'Copiar Link' }}</button>
</div>



<div class="warning">
  <span class="warning_success" *ngIf="!link.typing && link.success">
    <p>Eba! Este domínio está disponível!</p>
  </span>
  <span class="warning_error" *ngIf="!link.typing && !link.success">
    <p>Ops! Esse domínio já está sendo utilizado. Tente outra opção!</p>
  </span>
</div>

<hr>


<div class="active_qrcode">

  <div class="toogle">
    <div class="link_publish">
      <label class="switch">
        <input type="checkbox" [checked]="!traktoLinkPaused" (click)="tooglePauseTraktoLink()">
        <span class="slider round"></span>
      </label>

      <p style="margin-left: 4px;">Link Publicado</p>
      <div class="tooltip">
        <img src="../../../../../assets/icons/information.svg" alt="" style="cursor: pointer;">
        <span class="tooltiptext">
          <span style="margin-bottom: 10px;">
            O Link Publicado possibilita outros usuários a verem as alterações no seu arquivo.
            Caso não queira deixar isto visível em tempo real, basta desligar esta opção.
          </span>
          <span>
            Você poderá realizar esta ação quantas vezes quiser, mas não esqueça de manter o seu documento atualizado!
            :)
          </span>
        </span>
      </div>

    </div>

    <div class="date" style="margin-top: 6px;">
      <p>Atualizado em {{doc?.updated_at | date: 'dd/MM/yyyy'}}</p>
    </div>
  </div>


  <div class="qrCode">
    <div class="tlinks__qrcode-page">
      <qrcode style="display: flex; justify-content: center;align-items: center;"
        [qrdata]="this.linkPlaceholder + (this.traktoLink?.link_name ? this.traktoLink?.link_name : this.traktoLink?.linkWeb)"
        [width]="80" [margin]="2" [errorCorrectionLevel]="'L'"></qrcode>
    </div>
    <p (click)="downloadQRCode()" style="cursor: pointer;text-decoration:underline; text-underline-offset: 8px; ">Baixar
      QR Code</p>
  </div>

</div>

<trakto-modal-loader *ngIf="downloadLoading"></trakto-modal-loader>



<div class="qrcode-page" #qrCodeTemplate id="qrCodeTemplate">
  <trakto-qrcode-download [qrcodeLink]="traktoLink?.link_name ? traktoLink?.link_name : traktoLink?.linkWeb"
    [qrcodeName]="traktoLink?.link_name ? traktoLink?.link_name : traktoLink?.linkWeb" [isB2C]="isB2C"
    [mainLink]="linkPlaceholder"></trakto-qrcode-download>
</div>
