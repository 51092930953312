<div class="panel-mobile">
  <trakto-panel-header-mobile
    [label]="panelLabel"
    [showBackLabel]="true"
    (onClose)="closePanel()"
  ></trakto-panel-header-mobile>

  <trakto-elements-list [searchEvent]="searchEvent">
  </trakto-elements-list>
</div>
