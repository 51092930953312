import { Injectable } from '@angular/core';
import { IUnfold, UnfoldApiService } from './api-unfold.service';
import { NewDesignService } from '../new-design/new-design.service';
import { UpdateDesignService } from '../update-design/update-design-service';
import { omit } from 'lodash';
import { IDocumentModel, PageModel } from '@trakto/models';
import { ThumbService } from '@shared/svg-viewer/shared/thumb.service';
import { environment } from '@env/environment';

export interface ToUnfold {
    pageFormatId: string;
    width: number;
    height: number;
    page: PageModel;
}

@Injectable({
    providedIn: 'root'
})
export class UnfoldDesignService {

    constructor(
        private _apiService: UnfoldApiService,
        private _newDesignService: NewDesignService,
        private _updateDesignService: UpdateDesignService,
        private _thumbService: ThumbService,
    ) { }

    async unfold(unfold: ToUnfold, designTitle: string, author: string): Promise<{
        design: IDocumentModel,
        cover: string,
    }> {
        if (!unfold?.page) throw new Error('Page is required to unfold');

        const newDimension = omit(unfold, ['page', 'pageFormatId']);
        const { page, pageFormatId } = unfold;

        const unfoldDesign = await this._apiService.unfold({
            title: designTitle,
            page_format_reference: pageFormatId,
            newDimension,
            page,
            author,
        } as IUnfold);
        const blankDesign = await this._newDesignService.createBlankDesign(
            newDimension.width,
            newDimension.height
        );
        const finalDesign = await this._updateDesignService.update(
            blankDesign.id,
            {
                title: designTitle,
                pageFormatId: unfoldDesign.page_format_reference,
                body: unfoldDesign.newDocument.body,
                author,
            }
        );
        finalDesign.body = unfoldDesign.newDocument.body;
        await this._thumbService.changeThumbnail(finalDesign);

        return {
            design: finalDesign,
            cover: `${environment.api.bellaUrl}/${unfoldDesign.medias[0].png}`,
        };
    }
}
