<div class="trakto-collapse">
  <div class="trakto-collapse__header" (click)="toggle()">
    <h1 class="trakto-collapse__title">
      <i *ngIf="icon" [class]="icon"></i>
      {{ title }}
    </h1>

    <div class="trakto-collapse__controller">
      <i class="trakto-icon-arrow-up"></i>
    </div>
  </div>

  <div class="trakto-collapse__body" #body [style.maxHeight.px]="maxHeight">
    <div #bodyContent>
      <ng-content></ng-content>
    </div>
  </div>
</div>
