import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { TraktoLinksService } from '@services/traktoLinks/trakto-link.service';
import { traktoLinksPanelsEnum as panelsEnum } from '@trakto/models';
import html2canvas from 'html2canvas';
import { TraktoLinksAnalyticsService } from '@services/analytics/wrapper/traktoLink.analytics-wrapper.service';
import { UserService } from '@app/editor-v3/services/user.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-links-panel-personalize',
  templateUrl: './panel-personalize.component.html',
  styleUrls: ['./panel-personalize.component.scss'],
})
export class PanelPersonalizeComponent implements OnInit, OnDestroy {
  @ViewChild('qrCodeTemplate', { static: true })
  qrCodeTemplate: ElementRef<any>;
  @ViewChild('inputlink') inputLinkElement: ElementRef;

  @Input()
  public canCreateLink: boolean;

  public isB2C = false;
  public inputValue = '';
  public panelsEnum = panelsEnum;
  public isQRCode = false;
  public profileImage: any = undefined;
  public downloadLoading = false;
  public linkPlaceholder = '';

  public get disableCopyLink() {
    if (
      this.button.copy.disabled ||
      this.link.loading ||
      this.link.status !== this.panelsEnum.LINK_SUCCESS
    ) {
      return true;
    }
    return false;
  }

  // FIXME: Não consegui fazer o - e o _ serem adicionados
  public inputMask = {
    mask: /^[\w-_]+$/,
  };

  public link = {
    url: '',
    name: '',
    status: panelsEnum.LINK_EMPTY,
    loading: false,
    copied: false,
  };

  public linkLength = {
    min: 1,
    max: 100,
  };

  public button = {
    copy: {
      disabled: false,
    },
    edit: {
      show: false,
      disabled: false,
    },
  };

  private _inputTyping = {
    timer: undefined,
    intervalSecond: 1,
  };

  private TRAKTO_LOGO = '';
  private _destroy$ = new Subject<void>();


  constructor(
    private _traktoLinksService: TraktoLinksService,
    private _userService: UserService,
    private _tktLinksAnalytics: TraktoLinksAnalyticsService,
  ) {
      this.isB2C = this._userService.isB2c;
      this.linkPlaceholder = this.isB2C
        ? 'https://trakto.link/'
        : 'https://olink.pro/';
      this.TRAKTO_LOGO = this.isB2C ? environment.static.traktoIsotipo : '';
      this._getUserProfilePicture().then(
        profileImage => (this.profileImage = profileImage),
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit() {
    this._traktoLinksService.init().then(() => {
      if (this._traktoLinksService.link
          && this._traktoLinksService.linkName
          && this._traktoLinksService.linkName !== '') {
        this._successLink(
          this._traktoLinksService.linkName,
          this._traktoLinksService.link,
        );

        this.inputValue = this._traktoLinksService.linkName;

        this.button.edit.show = true;
        this.button.edit.disabled = false;
      } else {
        this.button.edit.disabled = true;
        this.button.copy.disabled = true;
      }
    });
  }

  public onLinkTyping(event: any) {
    const linkName: string = event.target.value;

    if (!this._isValidKey(event.keyCode)) {
      return false;
    }

    clearTimeout(this._inputTyping.timer);

    if (
      (linkName.length > this.linkLength.min &&
        linkName.length <= this.linkLength.max) ||
      linkName.length === 0
    ) {
      this._waitingToSend(linkName);
    }
  }

  public enableInputLink() {
    this.button.edit.disabled = true;
    this.button.copy.disabled = true;
    setTimeout(() => {
      this.inputLinkElement.nativeElement.focus();
    }, 0);
  }

  public disableInputLink() {
    if (this._traktoLinksService.linkName) {
      this.button.edit.show = true;
      this.button.edit.disabled = false;
    }

    this.button.copy.disabled = false;
  }

  public downloadQRCode() {
    let successDownload = false;
    this.downloadLoading = true;

    html2canvas(this.qrCodeTemplate.nativeElement)
      .then(canvas => {
        document.body.appendChild(canvas);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `qrcode_${this._traktoLinksService.linkName}.png`;
        a.click();
        successDownload = true;
      })
      .catch(error => {
        successDownload = false;
        console.error('error:', error);
      })
      .finally(() => {
        this._tktLinksAnalytics.traktoLinksDownloadQR({
          link_name: this._traktoLinksService.link,
          success: successDownload,
        });

        setTimeout(() => {
          this.downloadLoading = false;
        }, 500);
      });
  }

  public copyLinkToClipboard() {
    const elem = document.createElement('input');
    elem.value = `${this._traktoLinksService.link}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.link.copied = true;
    const self = this;
    setTimeout(() => {
      self.link.copied = false;
    }, 2000);

    this._tktLinksAnalytics.traktoLinksCopiedLink({
      linkName: this._traktoLinksService.link,
    });
  }

  private _waitingToSend(linkName: string) {
    this._inputTyping.timer = setTimeout(() => {
      this._updateLink(linkName);
    }, this._inputTyping.intervalSecond * 1000);
  }

  private async _updateLink(linkName: string) {
    if (this._hasChanged(linkName)) {
      const oldLinkName = this._traktoLinksService.link;
      const isEdition = !!this._traktoLinksService.linkName;
      let succesChange = false;

      this.link.loading = true;
      this.inputValue = linkName;

      const link: any = await this._traktoLinksService.updateLink(linkName);

      if (link) {
        this._successLink(linkName, link);
        succesChange = true;
      } else {
        this._failedLink();
        succesChange = false;
      }

      this._tktLinksAnalytics.traktoLinksEditedOrCreationLink({
        old_link_name: oldLinkName,
        new_link_name: this._traktoLinksService.link,
        success: succesChange,
        is_edition: isEdition,
      });
    }
  }

  private _hasChanged(linkName) {
    if (this.link.name === linkName) {
      return false;
    }
    return true;
  }

  private _isValidKey(keyCode: any) {
    const validFastKeys = [8, 13]; // backspace && enter
    if (validFastKeys.indexOf(keyCode) > -1) {
      return true;
    }

    if (
      !(keyCode > 47 && keyCode < 58) && // numeric (0-9)
      !(keyCode > 64 && keyCode < 91) && // upper alpha (A-Z)
      !(keyCode > 96 && keyCode < 123) && // lower alpha (a-z)
      !(keyCode > 189) // - and _
    ) {
      return false;
    }

    return true;
  }

  private _successLink(linkName, link) {
    this.link.url = link;
    this.link.name = linkName;
    this.isQRCode = true;
    this.link.status = panelsEnum.LINK_SUCCESS;
    this.link.loading = false;
    this.inputValue = linkName;

    this.disableInputLink();
  }

  private _failedLink() {
    this.link.url = '';
    this.isQRCode = false;
    this.link.status = panelsEnum.LINK_FAILED;
    this.link.loading = false;
  }

  private async _getUserProfilePicture() {
    const profile = this._userService.user.logo?.low?.url;

    if (!profile) {
      return this.TRAKTO_LOGO;
    }

    return await this._convertImageToBase64(profile);
  }

  private _convertImageToBase64(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }
}
