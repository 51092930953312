import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialSliderComponent } from './social-slider.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SocialSliderComponent],
  exports: [SocialSliderComponent],
})
export class SocialSliderModule {}
