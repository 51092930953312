import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { SlideStepsConfig } from './slideStepsConfigs';
import { UserService } from '@app/editor-v3/services/user.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'trakto-link-social-slider',
  templateUrl: './social-slider.component.html',
  styleUrls: ['./social-slider.component.scss'],
})
export class SocialSliderComponent implements OnInit, OnChanges, OnDestroy {
  public metaImage: string;
  public metaTitle: string;
  public metaDescription: string;
  public link: string;

  public defaultWPPMetaImage: string;
  public defaultFBMetaImage: string;
  public defaultLinkedInMetaImage: string;
  public defaultTwitterMetaImage: string;

  @ViewChild('socialSliderContent') socialSliderContent: ElementRef;

  @Input() public slidConfigs: any = SlideStepsConfig;
  @Input() public slideSteps: any[] = this.slidConfigs.data;
  @Input() public metaData: any;

  public currentStep: number;
  public totalSteps: number;
  public timeOut: any;

  public title = '';
  public description = '';
  private _destroy$ = new Subject<void>();

  constructor(
    private _traktoLinksService: TraktoLinksService,
    private _userService: UserService,
  ) {
    this._getTranslateSocialSliders();
  }

  ngOnInit() {
    this._getInitialConfigs();
    this.getMeta();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnChanges() {
    this.getTemplateMetaTags(this.metaData);
  }

  private async _getTranslateSocialSliders() {
    this._configureDefaultImages(this._userService.isB2c);
  }

  private getTemplateMetaTags(metaData: any) {
    this.metaImage = this._traktoLinksService.metaImage;
    this.metaTitle = this._traktoLinksService.metaTitle || 'Lorem Ipsum Dolor';
    this.metaDescription =
      this._traktoLinksService.metaDescription ||
      'Cras id fringilla erat. Integer ante quam, porta eget vulputate ut, condimentum nec velit.';
  }

  private getMeta() {
    this.metaImage = this._traktoLinksService.metaImage || '';
    this.metaTitle = this._traktoLinksService.metaTitle || 'Lorem Ipsum Dolor';
    this.metaDescription =
      this._traktoLinksService.metaDescription ||
      'Cras id fringilla erat. Integer ante quam, porta eget vulputate ut, condimentum nec velit..';
  }

  private _getInitialConfigs() {
    this._getActiveStep();
    this._setTotalSteps();
    this._startAutoPlay();
  }

  private _startAutoPlay() {
    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(() => {
      if (this.currentStep === this.totalSteps) {
        this.goToStep(this.currentStep - this.totalSteps);
      } else {
        this.goToStep(this.currentStep + 1);
      }

      this._startAutoPlay();
    }, 3500);
  }

  private _setTotalSteps() {
    this.totalSteps = this.slideSteps.length - 1;
  }

  private _getActiveStep() {
    const { activeStep } = this.slidConfigs.config;
    this.currentStep = activeStep ? activeStep : 0;
  }

  public handleSliderIconClick(idx) {
    this.goToStep(idx);
  }

  public handleSliderClickTrack(cardType, controller = false) {}

  public next() {
    if (this.currentStep !== this.totalSteps) {
      this.goToStep(this.currentStep + 1);
    }
  }

  public prev() {
    if (this.currentStep !== 0) {
      this.goToStep(this.currentStep - 1);
    }
  }

  public goToStep(stepNumber) {
    const stepElements =
      this.socialSliderContent.nativeElement.querySelectorAll(
        '.tkt-social-slider__content__step',
      );

    const targetTop = stepElements[stepNumber].offsetTop;

    const newTop = targetTop * -1;
    this.socialSliderContent.nativeElement.style.top = `calc(${newTop}px + 15%)`;

    stepElements[this.currentStep].classList.remove('active-step');
    stepElements[stepNumber].classList.add('active-step');
    this.currentStep = stepNumber;
    this._startAutoPlay();
  }

  private _configureDefaultImages(isB2C: boolean) {
    const src = './assets/images/trakto-links/social-cards';

    this.defaultWPPMetaImage = isB2C
      ? `${src}/whatsap_thumb_msg.png`
      : `${src}/whatsap_thumb_msg_b2b.png`;
    this.defaultFBMetaImage = isB2C
      ? `${src}/facebook_thumb_msg.png`
      : `${src}/facebook_b2b.png`;
    this.defaultLinkedInMetaImage = isB2C
      ? `${src}/facebook_thumb_msg.png`
      : `${src}/linkedin_b2b.png`;
    this.defaultTwitterMetaImage = isB2C
      ? `${src}/whatsap_thumb_msg.png`
      : `${src}/twitter_b2b.png`;
  }
}
