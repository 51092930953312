<div style="height: 100%; overflow: auto" #fontListContainer>
  <div class="searchbar-fonts">
    <trakto-input
      prefix="trakto-icon-tkt-icon"
      enableKeypress="true"
      id="my-fonts"
      name="fillFont"
      placeholder="{{
        'property_panel.text.text_style.gallery.search_fonts_placehorlder'
          | translate
      }}"
      type="search"
      (onBlur)="enableHotkeys()"
      (onFocus)="disableHotkeys()"
      [(value)]="searchFontText"
      [(ngModel)]="searchFontText"
    ></trakto-input>
  </div>

  <div class="panel-full__container">
    <div class="panel-full__uploading">
      <trakto-file-uploading
        (fileDropped)="onFile($event)"
      ></trakto-file-uploading>
    </div>

    <div *ngIf="fileDropped" class="panel-full__progress">
      <trakto-font-progress></trakto-font-progress>
    </div>

    <div *ngIf="fonts.length === 0" class="panel-full__empty-image">
      <img src="assets/images/empty-fonts.svg" alt="Empty" />
      <p>Você ainda não possui <br />fontes instaladas.</p>
      <div class="panel-full__divider"></div>
      <div class="panel-full__player-movie">
        <i class="trakto-icon-trakto-player"></i>
        <p>Assistir Tutorial</p>
      </div>
    </div>

    <div class="panel-simple" *ngIf="fonts.length > 0">
      <trakto-font-list
        *ngIf="fonts.length > 0"
        [fonts]="fonts"
        [searchFontText]="searchFontText"
        [elementSelected]="elementSelected"
        [pageSelected]="pageSelected"
        [container]="fontListContainer"
        [selectorColor]="'#0095FF'"
        (selectedFont)="selectedFont.emit($event)"
      >
      </trakto-font-list>
    </div>
  </div>
</div>
