import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TraktoLink, TraktoLinkSeo, TraktoLinksService } from '@app/editor/services/traktoLinks/trakto-link.service';
import { NotificationService } from '@app/shared/notification/notification.service';
import { UserSnap } from '@services/userSnap/userSnap.service';
import { idUserSnapEnum, traktoLinksPanelsEnum } from '@trakto/models';

import { environment } from '@env/environment';
import { UserService } from '@app/editor-v3/services/user.service';

@Component({
  selector: 'trakto-links-panel-seo',
  templateUrl: './panel-seo.component.html',
  styleUrls: ['./panel-seo.component.scss'],
})
export class PanelSEOComponent implements OnInit, AfterContentInit {

  @ViewChild('hasFocus', { static: true })
  focusLabel: ElementRef;

  @ViewChild('hasFocusDescription', { static: true })
  focusLabelDescription: ElementRef;

  @Output() onPreviewMeta = new EventEmitter<any>();

  public status = '';
  public isLoadingImage = false;
  public isLoadingSave = false;
  public hasMetaTitle = false;
  public hasMobileLink = false;
  public hasMetaDescription = false;
  public hasChangingField = false;
  public hasChangeImage = false;
  public hasTexto = '';
  public hasImageSrc: any;
  public imageSrcPreview: any;
  public formSeo: FormGroup;
  public panelsEnums = traktoLinksPanelsEnum;

  private _maxUploadImageSize: number;

  get metaTitle(): any {
    return this.formSeo.get('metaTitle');
  }

  get metaDescription(): any {
    return this.formSeo.get('metaDescription');
  }

  constructor(
    private _userSnap: UserSnap,
    private _renderer2: Renderer2,
    private _userService: UserService,
    private _alertService: NotificationService,
    private _traktoLinksService: TraktoLinksService,
  ) {
    this._maxUploadImageSize = environment.maxUploadFile || 3.5;
    this._loadConfigForm();
  }

  ngOnInit() {
    this.updateInputValues();
  }

  ngAfterContentInit() {
    this.addClassDisabledInputs();
  }

  private _loadConfigForm(): void {
    this.formSeo = new FormGroup({
      metaTitle: new FormControl('', [
        Validators.minLength(2),
        Validators.required,
      ]),
      metaDescription: new FormControl('', [
        Validators.minLength(2),
        Validators.required,
      ]),
    });
  }

  /**
   * Responsible method to obtain input
   * data and preview image.
   */
  public updateInputValues() {
    this.formSeo.setValue({
      metaTitle: !!this._traktoLinksService.metaTitle
        ? this._traktoLinksService.metaTitle
        : '',
      metaDescription: !!this._traktoLinksService.metaDescription
        ? this._traktoLinksService.metaDescription
        : '',
    });

    this.imageSrcPreview = !!this._traktoLinksService.metaImage
      ? this._traktoLinksService.metaImage
      : '';

    this.hasMobileLink = !!this._traktoLinksService.linkName;
    this.hasMetaTitle = !!this._traktoLinksService.metaTitle;
    this.hasTexto = this._traktoLinksService.metaTitle;
    this.hasMetaDescription = !!this._traktoLinksService.metaDescription;

    if (!this.hasMobileLink) {
      this.formSeo.disable();
    }

    if (this.hasMobileLink && this.hasMetaTitle && this.hasMetaDescription) {
      this.status = this.panelsEnums.LINK_SUCCESS;
    }
  }

  /**
   * Responsible method of obtaining the
   * image and rendering in the preview.
   * @param event
   */
  public temporaryPreviewImage(event: any): void {
    if (event.target.files) {
      this.isLoadingImage = true;
      this.hasChangeImage = true;
      const targetInput: any = event.target;
      const file: File = targetInput.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrcPreview = reader.result);

      reader.readAsDataURL(file);

      this._checksImageUploadSize(file);
    }
  }

  public removeTemporaryPreviewImage() {
    this.imageSrcPreview = '';
    this.hasChangeImage = true;
  }

  /**
   * Checks whether the image to be uploaded
   * to the bank has not exceeded the maximum limit
   * @p0aram file File
   */
  private async _checksImageUploadSize(file: File) {
    if (file) {
      const fileSize = file.size || 0;
      const fileSizeMb = fileSize / 1024 / 1024;

      if (fileSizeMb <= this._maxUploadImageSize) {
        this._traktoLinksService
            .preparingImageForUpload(file)
            .then(urlImage => {
              this.hasImageSrc = urlImage;
              this.onPreviewMeta.emit({
                ...this._traktoLinksService.metadata,
                image: urlImage,
              });
              this.isLoadingImage = false;
            });
      } else {
        this._alertService.warn('Excedeu limite máximo para upload de imagem');
        this.isLoadingSave = false;
        this.hasImageSrc = '';
        this.imageSrcPreview = '';
      }
    }
  }

  async handleSaveMetaTags() {
    if (this.formSeo.valid) {
      this.isLoadingSave = true;
      const { metaTitle, metaDescription } = this.formSeo.value;
      const seo = new TraktoLinkSeo({
        title: this.metaTitle.value,
        description: this.metaDescription.value,
        thumbUrl: this.hasImageSrc || this.imageSrcPreview,
      });

      const link = new TraktoLink({
        slug: this._traktoLinksService.linkName,
        linkWeb: this._traktoLinksService.linkWeb,
        seo,
      });

      await this._traktoLinksService.createOrUpdateMetaData(link);

      this.onPreviewMeta.emit({
        title: seo.title,
        description: seo.description,
        image: seo.thumbUrl,
      });

      this.status = this.panelsEnums.LINK_SUCCESS;
      this.hasChangingField = false;
      this.hasTexto = metaTitle;
      this.hasChangeImage = false;

      this.isLoadingSave = false;
    }
  }

  public showUserSnap() {
    this._userSnap.showWidget(idUserSnapEnum.traktoLinksConfigureWidget);
  }

  /**
   * trackConfigClick
   */
  public trackConfigClick(fieldName: 'cover' | 'description' | 'title') {}

  public hasChange(evt: any): void {
    this.hasChangingField = true;
  }

  public focus(event, props: boolean): void {
    this._renderer2.addClass(
      props
        ? this.focusLabel.nativeElement
        : this.focusLabelDescription.nativeElement,
      '--has-focus',
    );
  }

  public blur(event, props: boolean): void {
    this._renderer2.removeClass(
      props
        ? this.focusLabel.nativeElement
        : this.focusLabelDescription.nativeElement,
      '--has-focus',
    );
  }

  private addClassDisabledInputs(): void {
    !this.hasMobileLink
      ? this._renderer2.addClass(
          this.focusLabel.nativeElement,
          'input-disabled',
        )
      : this._renderer2.removeClass(
          this.focusLabelDescription.nativeElement,
          'input-disabled',
        );

    !this.hasMobileLink
      ? this._renderer2.addClass(
          this.focusLabelDescription.nativeElement,
          'input-disabled',
        )
      : this._renderer2.removeClass(
          this.focusLabelDescription.nativeElement,
          'input-disabled',
        );
  }
}
