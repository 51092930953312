import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { YoutubeService } from '../../editor/services/youtube/youtube.service';

@Component({
  selector: 'trakto-button-preview',
  templateUrl: './button-preview.component.html',
  styleUrls: ['./button-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonPreviewComponent implements OnChanges {
  @ViewChild('button', { static: true }) button;

  private _isYoutube: boolean;
  private _isColor: boolean;
  private _isSVG: boolean;
  private _isGif: boolean;

  private _disabled: boolean;
  private _content: string;
  private _icon: string;
  private _iconColor: string;
  private _url: string;
  private _height: number;

  @Input()
  public set url(url: string) {
    this._url = url;
  }

  public get url(): string {
    return this._url;
  }

  @Input()
  public set icon(icon: string) {
    this._icon = icon;
  }

  public get icon(): string {
    return this._icon;
  }

  @Input()
  public set height(height: number) {
    this._height = height;
  }

  public get height(): number {
    return this._height || null;
  }

  @Input()
  public set iconColor(iconColor: string) {
    this._iconColor = iconColor;
  }

  public get iconColor(): string {
    return this._iconColor;
  }

  @Input()
  public set content(content: string) {
    this._content = content;
  }

  public get content(): string {
    return this._content;
  }

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  public get disabled() {
    return this._disabled || false;
  }

  public set isYoutube(isYoutube: boolean) {
    this._isYoutube = isYoutube;
  }

  public get isYoutube() {
    return this._isYoutube || false;
  }

  @Input()
  public set isColor(isColor: boolean) {
    this._isColor = isColor;
  }

  public get isColor() {
    return this._isColor;
  }

  @Input()
  public set isGif(isGif: boolean) {
    this._isGif = isGif;
  }

  public get isGif() {
    return this._isGif;
  }

  public set isSVG(isSVG: boolean) {
    this._isSVG = isSVG;
  }

  public get isSVG() {
    return this._isSVG || false;
  }

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _elementRef: ElementRef,
    private youtubeService: YoutubeService,
  ) {}

  ngOnChanges() {
    this._getHostElement().children[0].disabled = this.disabled ? true : false;

    const element: HTMLElement = this.button.nativeElement;
    this.isYoutube =
      this.url && this.youtubeService.getYoutubeIdFromUrl(this.url)
        ? true
        : false;
    this.isSVG =
      this.url && this.url.match('xml') && this.url.match('xml').length > 0
        ? true
        : false;

    const isGif: boolean = this.url && this.url.match('.gif') ? true : false;

    if (this.isYoutube) {
      const thumbUrl: string = this.youtubeService.getYoutubeThumb(this.url);
      element.style.backgroundImage = `url(${thumbUrl})`;
      element.style.backgroundRepeat = 'no-repeat';
      element.style.backgroundSize = 'auto 100%';
      return;
    }

    if (this.isSVG) {
      const src = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(this.url)))}`;
      element.style.backgroundImage = `url(${src})`;
      element.style.backgroundRepeat = 'no-repeat';
      element.style.backgroundSize = 'auto 100%';
      return;
    }

    if (this.isColor) {
      element.style.backgroundImage = 'none';
      element.style.backgroundColor = this.url || '';
      return;
    }

    if (this.url && ((isGif && this.isGif) || (!isGif && !this.isGif))) {
      element.style.backgroundImage = `url(${this.url})`;
      element.style.backgroundRepeat = 'no-repeat';
      element.style.backgroundSize = 'auto 100%';
      return;
    }

    element.style.backgroundImage = '';
    element.style.backgroundColor = '';
    element.style.backgroundRepeat = 'repeat';
    element.style.backgroundSize = '10px 10px';
  }

  _getHostElement(): any {
    return this._elementRef.nativeElement;
  }

  public press(event: MouseEvent) {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
