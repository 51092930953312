import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropListComponent } from './drag-drop-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedDragDropModule } from '@app/shared/shared-drag-drop.module';



@NgModule({
  declarations: [
    DragDropListComponent,
  ],
  imports: [
    CommonModule,    
    ReactiveFormsModule,
    SharedDragDropModule,
    MatIconModule,
  ],
  exports: [
    DragDropListComponent,
  ]
})
export class DragDropListModule { }