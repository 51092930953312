<div *ngIf="elementSelected" id="panel-image" class="panel">
  <div class="panel-fixed__header">
    <div class="panel-header">
      <i class="trakto-icon-{{ icon }}"></i>
      <i class="trakto-icon-seta-breadcrumb breadcrumb"></i>
      <h2 class="panel-header__title">
        {{ panelHeaderTitle }}
      </h2>
    </div>
  </div>

  <div class="flex flex-1 flex-direction-column overflow-hidden">
    <trakto-panel-actions
      [b2c]="b2c"
      [allowClip]="false"
      [isClipEditable]="false"
      [pageSelected]="pageSelected"
      [elementSelected]="elementSelected"
      [groupContainer]="groupSelected"
      [allowLockElementsByTemplate]="allowLockElementsByTemplate"
    >
    </trakto-panel-actions>

    <div class="panel-scroll__container">
      <div class="panel-background panel-scroll__space">
        <div style="padding: 16px 20px">
          <div class="flex">
            <div [style.width.px]="100" class="margin-right-10">
              <trakto-button-preview
                *ngIf="isEmoji"
                height="109px"
                [url]="elementSelected['svg'] || elementSelected['low'] || elementSelected['href']"
                [disabled]="
                  !elementSelected.edit || elementSelected.lockedByTemplate
                "
                [content]="'property_panel.image.change' | translate"
                (onClick)="openElementsPanel('emoji')"
              >
                <i class="trakto-icon-image-simple"></i>
                <span>Insert</span>
              </trakto-button-preview>
              <trakto-button-preview
                *ngIf="isImage"
                height="109px"
                [url]="elementSelected.href"
                [disabled]="
                  !elementSelected.edit || elementSelected.lockedByTemplate
                "
                [content]="'property_panel.image.change' | translate"
                (onClick)="openModal({ id: 'trakto-modal-image' })"
              >
                <i class="trakto-icon-image-simple"></i>
                <span>Insert</span>
              </trakto-button-preview>
              <trakto-button-preview
                *ngIf="!isImage && !isEmoji"
                height="109px"
                [isGif]="true"
                [url]="elementSelected.href"
                [disabled]="
                  !elementSelected.edit || elementSelected.lockedByTemplate
                "
                [content]="'property_panel.shape.change' | translate"
                (onClick)="openModal({ id: 'trakto-modal-sticker' })"
              >
                <i class="trakto-icon-image-simple"></i>
                <span>Insert</span>
              </trakto-button-preview>
            </div>

            <div
              *ngIf="!isGif && !isEmoji"
              class="flex flex-1 flex-direction-column flex-space-between"
            >
              <h3 class="panel-image__quick__title">
                {{ 'property_panel.image.add_mask' | translate }}
              </h3>

              <div class="flex flex-direction-column">
                <div class="panel-image__quick" [style.marginBottom.px]="10">
                  <div>
                    <button (click)="resetMask('')">
                      <i class="trakto-icon-empty"></i>
                    </button>
                  </div>
                  <div>
                    <button (click)="addQuickMask('square')">
                      <img src="/assets/images/shapes/rect.svg" />
                    </button>
                  </div>
                  <div>
                    <button (click)="addQuickMask('star')">
                      <img src="/assets/images/shapes/start.svg" />
                    </button>
                  </div>
                  <div>
                    <button (click)="addQuickMask('hexagon')">
                      <img src="/assets/images/shapes/hexagon.svg" />
                    </button>
                  </div>
                </div>

                <div class="panel-image__quick">
                  <div>
                    <button (click)="addQuickMask('circle')">
                      <img src="/assets/images/shapes/elipse.svg" />
                    </button>
                  </div>
                  <div>
                    <button (click)="addQuickMask('triangle')">
                      <img src="/assets/images/shapes/triangle.svg" />
                    </button>
                  </div>
                  <div>
                    <button (click)="addQuickMask('diamond')">
                      <img src="/assets/images/shapes/diamond.svg" />
                    </button>
                  </div>
                  <div>
                    <button
                      class="--special"
                      (click)="openElementsPanel('shape')"
                    >
                      <i class="trakto-icon-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="isGif"
              class="
                message__info
                flex flex-1 flex-direction-column flex-justify-center
              "
            >
              <h3>
                <img src="/assets/images/warning.svg" />
                {{
                  'property_panel.general.gif_notification.title' | translate
                }}
              </h3>
              <p>
                {{ 'property_panel.general.gif_notification.msg' | translate }}
              </p>
            </div>

            <div
              *ngIf="isEmoji"
              class="
                message__info
                flex flex-1 flex-direction-column flex-justify-center
              "
            >
              <h3>
                <img src="/assets/images/idea.svg" />
                {{
                  'property_panel.general.icon_notification.title' | translate
                }}
              </h3>
              <p>
                {{ 'property_panel.general.icon_notification.msg' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="isImage && removeBgIsAvailable">
          <h2 class="panel__title">
            <i class="trakto-icon-remove-background"></i>
            {{ 'property_panel.image.background_remover.title' | translate }}
          </h2>

          <div id="panel-background__remove" class="panel-background">
            <div class="flex flex-1 flex-space-between">
              <div
                class="margin-right-10"
                [style.width.px]="100"
                [style.height.px]="71"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="99"
                  height="70.714"
                  viewBox="0 0 99 70.714"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Retângulo_137307"
                        data-name="Retângulo 137307"
                        width="99"
                        height="70"
                        rx="5"
                        transform="translate(-0.286 0.302)"
                        fill="#fff"
                        stroke="#e0e6ed"
                        stroke-width="1"
                      />
                    </clipPath>
                    <clipPath id="clip-path-2">
                      <rect width="98" height="69" fill="none" />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_73060"
                    data-name="Grupo 73060"
                    transform="translate(-19 -330.698)"
                  >
                    <g
                      id="Retângulo_137300"
                      data-name="Retângulo 137300"
                      transform="translate(19 331)"
                      fill="#fff"
                      stroke="#e0e6ed"
                      stroke-width="1"
                    >
                      <rect width="99" height="70" rx="5" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="98"
                        height="69"
                        rx="4.5"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Grupo_de_máscara_1"
                      data-name="Grupo de máscara 1"
                      transform="translate(19.286 330.698)"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="Repetição_de_grade_4"
                        data-name="Repetição de grade 4"
                        transform="translate(-0.286 1.302)"
                        clip-path="url(#clip-path-2)"
                      >
                        <g transform="translate(-17 -127)">
                          <rect
                            id="Retângulo_137301"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(15 -127)">
                          <rect
                            id="Retângulo_137301-2"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-2"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-2"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-2"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-2"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-2"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(47 -127)">
                          <rect
                            id="Retângulo_137301-3"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-3"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-3"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-3"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-3"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-3"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(79 -127)">
                          <rect
                            id="Retângulo_137301-4"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-4"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-4"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-4"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-4"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-4"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(-17 -111)">
                          <rect
                            id="Retângulo_137301-5"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-5"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-5"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-5"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-5"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-5"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(15 -111)">
                          <rect
                            id="Retângulo_137301-6"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-6"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-6"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-6"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-6"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-6"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(47 -111)">
                          <rect
                            id="Retângulo_137301-7"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-7"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-7"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-7"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-7"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-7"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(79 -111)">
                          <rect
                            id="Retângulo_137301-8"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-8"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-8"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-8"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-8"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-8"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(-17 -95)">
                          <rect
                            id="Retângulo_137301-9"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-9"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-9"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-9"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-9"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-9"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(15 -95)">
                          <rect
                            id="Retângulo_137301-10"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-10"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-10"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-10"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-10"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-10"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(47 -95)">
                          <rect
                            id="Retângulo_137301-11"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-11"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-11"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-11"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-11"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-11"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(79 -95)">
                          <rect
                            id="Retângulo_137301-12"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-12"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-12"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-12"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-12"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-12"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(-17 -79)">
                          <rect
                            id="Retângulo_137301-13"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-13"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-13"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-13"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-13"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-13"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(15 -79)">
                          <rect
                            id="Retângulo_137301-14"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-14"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-14"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-14"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-14"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-14"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(47 -79)">
                          <rect
                            id="Retângulo_137301-15"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-15"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-15"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-15"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-15"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-15"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(79 -79)">
                          <rect
                            id="Retângulo_137301-16"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-16"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-16"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-16"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-16"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-16"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(-17 -63)">
                          <rect
                            id="Retângulo_137301-17"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-17"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-17"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-17"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-17"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-17"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(15 -63)">
                          <rect
                            id="Retângulo_137301-18"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-18"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-18"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-18"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-18"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-18"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(47 -63)">
                          <rect
                            id="Retângulo_137301-19"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-19"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-19"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-19"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-19"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-19"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                        <g transform="translate(79 -63)">
                          <rect
                            id="Retângulo_137301-20"
                            data-name="Retângulo 137301"
                            width="8"
                            height="8"
                            transform="translate(17 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137305-20"
                            data-name="Retângulo 137305"
                            width="8"
                            height="8"
                            transform="translate(25 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137303-20"
                            data-name="Retângulo 137303"
                            width="8"
                            height="8"
                            transform="translate(33 127)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137306-20"
                            data-name="Retângulo 137306"
                            width="8"
                            height="8"
                            transform="translate(41 135)"
                            fill="#e0e6ed"
                          />
                          <rect
                            id="Retângulo_137302-20"
                            data-name="Retângulo 137302"
                            width="8"
                            height="8"
                            transform="translate(25 127)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_137304-20"
                            data-name="Retângulo 137304"
                            width="8"
                            height="8"
                            transform="translate(33 135)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                    <path
                      id="panel-background__content-image"
                      data-name="Retângulo 137308"
                      d="M5,0H52.071a0,0,0,0,1,0,0V70.714a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
                      transform="translate(19.286 330.698)"
                      fill="#a069ff"
                    />
                    <g id="users" transform="translate(44.078 341.627)">
                      <path
                        id="Caminho_5008"
                        data-name="Caminho 5008"
                        d="M58.632,50.761,47.857,45.373A4.111,4.111,0,0,1,45.585,41.7V37.469a16.111,16.111,0,0,0,1.02-1.311,24.776,24.776,0,0,0,3.349-6.75,3.382,3.382,0,0,0,2.4-3.219V21.677a3.365,3.365,0,0,0-1.128-2.5V12.652S52.564,2.5,38.817,2.5,26.408,12.652,26.408,12.652v6.523a3.365,3.365,0,0,0-1.128,2.5v4.512a3.383,3.383,0,0,0,1.56,2.839,22.374,22.374,0,0,0,4.08,8.441v4.123A4.108,4.108,0,0,1,28.778,45.2L18.715,50.69A9.046,9.046,0,0,0,14,58.632v3.654H63.633V58.853A9.049,9.049,0,0,0,58.632,50.761Z"
                        transform="translate(-14 -2.5)"
                        fill="#263056"
                      />
                    </g>
                  </g>
                </svg>
              </div>

              <div class="flex flex-1 flex-direction-column flex-space-between">
                <div class="flex flex-align-center flex-space-between">
                  <div class="remove-bg__progress">
                    <div
                      *ngIf="b2c && !isEmbeddedInstance && !isPremiumUser"
                      [style.width.%]="
                        (remainingRemoveBg * 100) / removeBgCount
                      "
                    ></div>
                    <div
                      *ngIf="(!b2c && !isEmbeddedInstance) || isPremiumUser"
                      [style.width.%]="100"
                    ></div>
                  </div>
                  <span
                    *ngIf="b2c && !isEmbeddedInstance && !isPremiumUser"
                    class="remove-bg__counter"
                    ><span>{{ remainingRemoveBg }}</span> /
                    {{ removeBgCount }}</span
                  >
                  <span
                    *ngIf="(!b2c && !isEmbeddedInstance) || isPremiumUser"
                    class="remove-bg__counter"
                  >
                    {{
                      'property_panel.image.background_remover.unlimited_value'
                        | translate
                    }}
                  </span>
                </div>

                <button
                  class="button button--accent"
                  [disabled]="
                    !elementSelected.edit ||
                    elementSelected.lockedByTemplate ||
                    isRemoving
                  "
                  (click)="removeBg()"
                  [class.button--nopadding]="isRemoving"
                >
                  <trakto-light-loader *ngIf="isRemoving"></trakto-light-loader>

                  <span *ngIf="!isRemoving">
                    {{
                      'property_panel.image.background_remover.button'
                        | translate
                    }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="effectsIsAvailable">
          <h2
            class="panel__title panel__title--disabled"
            [class.panel__title--disabled]="isGif || isEmoji"
          >
            <i class="trakto-icon-icon-effect"></i>
            {{ 'property_panel.image.effects.title' | translate }}
          </h2>

          <div class="panel-background">
            <div class="panel__effects">
              <div class="panel-filter" [style.paddingBottom.px]="0">
                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="setFilter('empty')"
                  >
                    <i class="trakto-icon-empty"></i>
                    <span>{{
                      'property_panel.image.effects.types.no_filter' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="openPanelStack('duotone')"
                  >
                    <i class="trakto-icon-filter-duotone"></i>
                    <span>{{
                      'property_panel.image.effects.types.duotone' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="setFilter('blackandwhite')"
                  >
                    <i class="trakto-icon-filter-contrast"></i>
                    <span>Black/White</span>
                  </trakto-button>
                </div>
              </div>

              <div class="panel-filter" [style.paddingTop.px]="10">
                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="setFilter('gaussianblur')"
                  >
                    <i class="trakto-icon-filter-blur"></i>
                    <span>{{
                      'property_panel.image.effects.types.blur' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="setFilter('sepia')"
                  >
                    <i class="trakto-icon-filters"></i>
                    <span>{{
                      'property_panel.image.effects.types.vintage' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--outline
                    button--small
                    [disabled]="
                      !elementSelected.edit ||
                      elementSelected.lockedByTemplate ||
                      isGif ||
                      isEmoji
                    "
                    (onClick)="openPanelStack('filter')"
                  >
                    <i class="trakto-icon-plus"></i>
                    <span>{{
                      'property_panel.image.effects.types.filters' | translate
                    }}</span>
                  </trakto-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="elementLinkIsAvailable && (isGif || isEmoji || isImage)">
          <h2 class="panel__title">
            <i class="trakto-icon-link"></i>
            {{ 'property_panel.general.add_link_title' | translate }}
          </h2>

          <div class="panel-background">
            <div class="panel__effects">
              <div class="panel-filter" [style.paddingBottom.px]="20">
                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="
                      elementSelected.whatsapp || elementSelected.email
                    "
                    (onClick)="openPanelStack('panel-website')"
                  >
                    <i class="trakto-icon-worldwide"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.website' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="elementSelected.link || elementSelected.email"
                    (onClick)="openPanelStack('panel-whatsapp')"
                  >
                    <i class="trakto-icon-social-whatsapp"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.whatsapp' | translate
                    }}</span>
                  </trakto-button>
                </div>

                <div class="panel-filter__item">
                  <trakto-button
                    button--custom
                    button--small
                    button--outline
                    [disabled]="
                      elementSelected.link || elementSelected.whatsapp
                    "
                    (onClick)="openPanelStack('panel-email')"
                  >
                    <i class="trakto-icon-email"></i>
                    <span>{{
                      'property_panel.icon.panel.add_link.e-mail' | translate
                    }}</span>
                  </trakto-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
