import { Injectable } from '@angular/core';
import * as SafeJsonStringify from 'json-stringify-safe';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public setObject(key: string, data: any): void {
    localStorage.setItem(key, SafeJsonStringify(data, null, 1));
  }

  public getObject(key: string): any {
    const data: any = localStorage.getItem(key);
    return JSON.parse(data);
  }

  public removeObject(key: string): void {
    localStorage.removeItem(key);
  }
}
